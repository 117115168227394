var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "full-width",
      class: { "form-input-spacing": _vm.formSpacing !== false },
      attrs: { id: _vm.id },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "primary-text-color" }, [
            _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        ref: "sliderRef",
        staticClass: "form-range",
        attrs: {
          type: "range",
          min: _vm.min,
          max: _vm.max,
          step: _vm.step,
          disabled: _vm.disabled,
        },
        domProps: { value: _vm.value },
        on: { input: _vm.onChange, pointerup: _vm.onRelease },
      }),
      _vm._v(" "),
      !_vm._isFalsy(_vm.min) || !_vm._isFalsy(_vm.max)
        ? _c(
            "div",
            {
              staticClass: "position-relative",
              staticStyle: { "min-height": "25px" },
            },
            [
              !_vm._isFalsy(_vm.min)
                ? _c(
                    "span",
                    { staticClass: "min-label" },
                    [
                      _vm._t("min", function () {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.min) +
                              "\n            "
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              !_vm._isFalsy(_vm.max)
                ? _c(
                    "span",
                    { staticClass: "max-label" },
                    [
                      _vm._t("max", function () {
                        return [
                          _vm._v(
                            "\n                " +
                              _vm._s(_vm.max) +
                              "\n            "
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.hint
        ? _c(
            "label",
            {
              staticClass: "secondary-text-color",
              staticStyle: { "margin-top": "25px" },
            },
            [_vm._v("\n        " + _vm._s(_vm.hint) + "\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.inErrorState
        ? _c(
            "label",
            { staticClass: "danger-text-color" },
            [
              _vm.required !== false && _vm._isFalsy(_vm.value)
                ? [_vm._v("\n            Required\n        ")]
                : _vm._isTruthy(_vm.min) && _vm.value < _vm.min
                ? [
                    _vm._v(
                      "\n            Must be at least " +
                        _vm._s(_vm.min) +
                        "\n        "
                    ),
                  ]
                : _vm._isTruthy(_vm.max) && _vm.value > _vm.max
                ? [
                    _vm._v(
                      "\n            Must be at less than " +
                        _vm._s(_vm.max + 1) +
                        "\n        "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }