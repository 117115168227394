<template>
    <span :id=generatedId() class="dot">
        <b-button 
            v-bind:class="[(flagsArray.length || nptArray.length) > 0? 'dot-lg':'dot-md',{'dot-read': this.read},'dot-clickable', 'text-center']"
            :id=popoverId() 
            :style="[getMarkerSymbolStyle(),dotColor]"
            >{{getMarkerSymbol()}}</b-button>
        <b-popover :target=popoverId() class="popover-body-custom" ref="popover" triggers="click blur" placement="righttop" @show="onShow" @shown="onShown" @hidden="onHidden" >
            <template v:slot:default>
                <div v-if="hideListHeader === false" class="m-1 px-1" style="color: #FFFFFF">
                    <b>{{ timeString() }} </b>
                </div>
                <div v-if="nptArray && nptArray.length > 0" class="px-2">
                    <b style="color: #FFFFFF">NPT Events</b>
                    <div style="max-height:280px; overflow-y:auto" class="px-2 mt-3">
                        <div v-for="(npt, index) in nptArray" :key="index">
                            <div class="comment-container m-0 mb-2 p-2" :style="{'border-color': npt.subCategory == 'end'? 'red' : 'green'}">
                                <div>
                                    NPT
                                </div>
                                <div v-if="getNptPair(npt)">
                                    <div>
                                        Start Time: {{ convertTime(npt.subCategory == 'start'? npt.data.startTime : getNptPair(npt).data.startTime) }}
                                    </div>
                                    <div>
                                        End Time: {{ convertTime(npt.subCategory == 'end'? npt.data.endTime : getNptPair(npt).data.endTime) }}
                                    </div>
                                </div>
                                <div v-else>
                                    Time: {{ convertTime(npt.subCategory == 'start' ? npt.data.startTime : npt.data.endTime) }}
                                </div>
                                <div>
                                    Well Name: {{ getWellName(npt) }} ({{ npt.wellNumber }})
                                </div>
                                <div>
                                    Stage Number: {{ npt.stageNumber }}
                                </div>
                                <div>
                                    Description: {{ npt.data.timekeeper.description }}
                                </div>
                                <div v-if="npt.data.nptOutcomeId">
                                    NPT Outcome: {{ getNPTOutcome(npt.data.nptOutcomeId) }}
                                </div>
                                <div v-if="npt.data.customerVendorIds.length > 0">
                                    NPT Responsible Vendors: {{ getResponsibleVendors(npt.data.customerVendorIds) }}
                                </div>
                                <div v-if="npt.data.comment">
                                    Comment: {{ npt.data.comment }}
                                </div>
                                <div v-else>
                                    Comment: <span style="color:red">Not Set</span>
                                </div>
                                <div v-if="getNptPair(npt)">
                                    Duration: {{ getDuration(npt, getNptPair(npt)) }}
                                </div>
                                <div v-if="npt.data.nonFracNPT">
                                    NPT Type: Non Frac
                                </div>
                                <div v-else>
                                    NPT Type: Frac
                                </div>
                                <div class="d-flex pt-2">
                                    <div class="flex-grow-1" v-if="npt.showAddEvent">
                                        <button @click="addEndNPT(npt)" :disabled="isFlagDeleting || !hasEditPermission" class="btn btn-success" style="font-size: small">{{'Add End NPT'}}</button>
                                    </div>
                                    <div class="d-flex">
                                        <div class="pr-1">
                                            <button @click="editNPT(npt)" :disabled="isFlagDeleting || !hasEditPermission" class="btn btn-primary" style="font-size: small">{{'Edit'}}</button>
                                        </div>
                                        <div>
                                            <button @click="deleteNPT(npt)" :disabled="isFlagDeleting || !hasEditPermission" class="btn btn-danger" style="font-size: small">{{'Delete'}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="alertArray && alertArray.length > 0">
                    <b style="color: #FFFFFF">Threshold Alerts</b>
                    <div style="max-height:200px; overflow-y:auto" class="px-2 mt-3">
                        <div v-for="(alert) in alertArray" :key="alert.id">
                            <div class="comment-container m-0 mb-2 p-2" :style="{'border-color': + alert.color}">
                                <div>
                                    Tag: {{ alert.tagName }}
                                </div>
                                <div>
                                    Sensor Value: {{ alert.alertValue }}
                                </div>
                                <div>
                                    Threshold Limit: {{ alert.triggerLevel }}
                                </div>
                                <div class="badge badge-danger" :style="getAlertColors(alert.alertLevel, alert.color)">
                                    {{ getAlertLevelMessage(alert.alertLevel) }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="my-4" style="width:100%;height:1px;background-color:grey"></div>
                    <div class="d-flex justify-content-end"  v-if="isFeatureFlagged('DRAW_NPT')" >
                        <button class="btn btn-primary mb-1 mt-1" style="font-size: small" @click="toggleCommentInputs">{{commentDisplayText}}</button>
                    </div>
                </div>
                <div v-if="flagsArray && flagsArray.length > 0">
                    <b style="color: #FFFFFF">Wellbore Flags</b>
                    <div v-for="(flag) in flagsArray" :key="flag.id">
                        <div style="max-height:200px; overflow-y:auto" class="px-2 mt-3">
                            <div class="comment-container m-0 mb-2 p-2" :style="'border-color:' + getFlagColor(flag)">
                                <div>
                                    Category: {{ flag.category }}
                                </div>
                                <div>
                                    Sub-Category: {{ flag.subCategory }}
                                </div>
                                <div>
                                    Description: {{ flag.data.description }}
                                </div>
                                <div>
                                    Well Name: {{ getWellName(flag) }} ({{ flag.wellNumber }})
                                </div>
                                <div>
                                    Stage Number: {{ flag.stageNumber }} 
                                </div>
                                <div>
                                    Timestamp: {{ convertTime(flag.timestamp) }}
                                </div>
                                <div v-if="flag.subCategory=='placeFlag'">
                                    Target Depth (ft): {{ formatTargetDepthValue(flag) }}
                                </div>
                                <div v-if="flag.subCategory=='completed'">
                                    Duration (min): {{ formatFlagDurationMinutes(flag) }}
                                </div>
                            </div>
                        </div>
                        <div class="row mb-2 px-2 mt-3">
                            <div class="col-6">
                                <button v-if="flag.subCategory=='placeFlag'" @click="deleteFlag(flag)" :disabled="isFlagDeleting" class="btn btn-danger float-left mb-2 pb-2" style="font-size: small"> {{isFlagDeleting? 'Deleting...' : 'Delete Flag'}}</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div :id="String(popoverId() + '-msg-scroll-container')" class="scroll-container p-0 m-0" v-if="!isNoComments">
                    <div v-for="comment,index in commentsArray" :key="comment.id">
                        <div class="comment-container m-0 my-2 p-2">
                            <div class="row m-0 p-1 justify-content-between">
                                <div class="d-flex">
                                    <div v-if="comment.well_id" class="small">
                                        <div v-if="comment.well_id" class="badge badge-secondary" v-bind:style="{backgroundColor: getWellColor(comment)}">
                                            &nbsp;
                                        </div>
                                        {{ getWellName(comment) }}
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <template v-if="!readOnly">
                                        <i class="fas fa-pen mr-2" @click="editComment(comment)"></i>
                                        <i class="fas fa-trash-alt" @click="deleteComment(comment,index)"></i>
                                    </template>
                                </div>
                            </div>

                            <div class="m-1">
                                {{comment.text}}
                            </div>
                            <div class="m-0 row justify-content-between p-1">
                                <div class="small col-8 text-left m-0 p-0">
                                    {{ createdAtString(comment) }}
                                </div>
                                <div class="small text-right m-0 p-0">
                                    {{ comment.user ? (comment.user.first_name || comment.user.username) : 'NOT SET' }}
                                </div>
                            </div>
                            <div class="m-0 row mb-1"  v-if="getActivityType(comment) || getReasonType(comment)">
                                <div class="small col-6 pl-1"  v-if="getActivityType(comment)">
                                    <div :id="'tooltip-activity' + index" class="badge badge-success">
                                        {{ getActivityType(comment) }}
                                    </div>
                                    <b-tooltip triggers="hover" :target="'tooltip-activity' + index">
                                        {{ getActivityType(comment) }}
                                    </b-tooltip>
                                </div>
                                <div class="small col-6 pr-2" v-if="getReasonType(comment)">
                                    <div :id="'tooltip-reason' + index"  class="badge badge-primary float-right">
                                        {{ getReasonType(comment) }}
                                    </div>
                                    <b-tooltip triggers="hover" :target="'tooltip-reason' + index">
                                        {{ getReasonType(comment) }}
                                    </b-tooltip>
                                </div>
                            </div>
                            <div class="m-0 row mb-1" v-if="getContractorName(comment)">
                                <div class="small col-6 pl-1">
                                    <div :id="'tooltip-contractor' + index" class="badge badge-info">
                                        {{ getContractorName(comment) }}
                                    </div>
                                    <b-tooltip triggers="hover" :target="'tooltip-contractor' + index">
                                        {{ getContractorName(comment) }}
                                    </b-tooltip>
                                </div>
                            </div>
                        </div>
                        <div class="d-flex justify-content-end"  v-if="isFeatureFlagged('DRAW_NPT')" >
                            <button class="btn btn-primary mb-1 mt-1" style="font-size: small" @click="toggleCommentInputs">{{commentDisplayText}}</button>
                        </div>
                    </div>
                </div>
                <template v-if="!readOnly && !hideCommentInputs">
                    <div v-if="editingComment" class="input-group mb-2">
                        <div class="list-label my-auto text-white ml-3">
                            Edit Comment
                        </div>
                    </div>
                    <div class="input-group mb-3 mt-2">
                        <textarea v-model="commentText" style="resize: none;" rows="5" class="form-control" aria-label="With textarea" placeholder="Type your comment here..."></textarea>
                    </div>
                    <div class="input-group mb-3 text-white">
                        <div class="col-12 m-0 p-0">
                            <div class="list-label">
                                Well:
                            </div>
                            <select class="form-control form-control-sm" v-model="selectedWellId">
                                <option selected :value="null"> --No Well--</option>
                                <option v-for="(item) in wells" :value="item.id" v-bind:key="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="input-group mb-3 text-white">
                        <div class="col-12 m-0 p-0">
                            <div class="list-label text-white">
                                Code 1:
                            </div>
                            <select class="form-control form-control-sm" v-model="selectedActivityId" @change="onActivityChanged">
                                <option v-for="(item) in eventActivities" :value="item.id" v-bind:key="item.id">{{item.type}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="input-group mb-3 text-white">
                        <div class="col-12 m-0 p-0">
                            <div class="list-label">
                                Code 2:
                            </div>
                            <select class="form-control form-control-sm" v-model="selectedReasonId">
                                <option selected :value="null"> --Not Set--</option>
                                <option v-for="(item) in visibleEventReasons" :value="item.id" v-bind:key="item.id">{{item.type}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="input-group mb-3 text-white">
                        <div class="col-12 m-0 p-0">
                            <div class="list-label">
                                Contractor:
                            </div>
                            <select class="form-control form-control-sm" v-model="selectedContractorId">
                                <option selected :value="null"> --No Contractor Set--</option>
                                <option v-for="(item) in contractors" :value="item.id" v-bind:key="item.id">{{item.name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer d-flex flex-row pt-3 pb-2 pr-2" style="border-top-width:0px">
                        <button v-if="this.editingComment" class="btn btn-secondary btn-sm" v-on:click="cancelEditing()">Cancel</button>
                        <button class="btn btn-primary btn-sm ml-2" v-on:click="handleClick()" :disabled="isFlagDeleting"> {{this.editingComment ? 'Save' : 'Comment'}}</button>
                    </div>
                </template>
            </template>
        </b-popover>
    </span>
</template>


<script>
import moment from 'moment';
import GlobalFunctions from '../../GlobalFunctions.js';
import {wellboreFlagData} from '../../wellboreFlags.js';

export default {
    props: {
        isFlagDeleting: Boolean,
        jobNumber: String,
        comments: Array,
        jobHourOffset: Number,
        onCommentsUpdated: Function,
        onPopoverShown: Function,
        onPopoverHidden: Function,
        eventActivities: Array,
        eventReasons: Array,
        eventActivityEventReasonsLookup: Object,
        nptOutcomes: Array,
        customerVendors: Array,
        contractors: Array,
        onAllCommentsDeleted: Function,
        wells: Array,
        readOnly: Boolean,
        hideListHeader: Boolean,
        isAlert: Boolean,
        nptEvents: Array,
        flags: Array,
        onNPTAddEndClick: Function,
        onNPTEditClick: Function,
        onDeleteSuccess: Function,
        sharedState: Object,
        hasEditPermission: Boolean
    },
    data() {
        return {
            isFlagDeleting: false
        }
    },
    mounted() {
        if(this.comments.length > 0)
            this.read = this.comments[0].read_status > 0;
        else
            this.read = true;

        if (this.comments.length > 0 || this.alertArray.length > 0 || this.flagsArray.length > 0) {
            this.hideCommentInputs = true;
        }
    },
    computed: {
        dotColor() {
            const priority = { 'failed': 0, 'warning': 1, 'critical': 2};

            let style = '';
            if(this.alertArray?.length > 0) {
                const result = this.alertArray.reduce(function(c, n) {
                    return priority[c.alertLevel] > priority[n.alertLevel] ? c : n;
                });
                style = 'background-color:'+result.color;
            }
            if (this.flagsArray?.length > 0) {
                const color = this.getFlagColor(this.flagsArray[0]);
                if (this.flagsArray[0].subCategory == 'placeFlag') { //works with dot-clickable class to make a hollow dot
                    style = 'background-color: #000; ';
                } else {
                    style = `background-color:${color}; `; //works with dot-clickable class to make a solid dot
                }
                style += `outline: 2px solid ${color}; `; //border can push button text out of alignment, outline does not
                style += 'outline-offset: -1px; '  //remove gap between outline and div
                return style;
            } 
            if (this.nptArray?.length > 0) {
                if (this.nptArray.length > 1)
                {
                    style = 'background-color: #0f5cbf; ';
                }
                else
                {
                    if (this.nptArray[0].subCategory == 'end')
                        style = 'background-color: red; ';
                    else
                        style = 'background-color: green; ';
                }
            }
            return style;
        },
        visibleEventReasons: function()
        {
            return this.eventReasons.filter(r => 
            {
                const reasons = this.eventActivityEventReasonsLookup[this.selectedActivityId];
                if(reasons)
                    return reasons.includes(r.id);
                return false;
            });
        },
        commentsArray() {
            const comments = this.comments.filter((item)=>item?.type !== 'alert' && item?.type !== 'flag');
            return comments;
        },
        alertArray() {
            const alerts = this.comments.filter((item)=>item?.type === 'alert');
            return alerts.map((item)=>item.event);               
        },
        flagsArray() {
            const allRefs = [...new Set(this.flags.map((f) => f.reference))];
            const flag = this.comments.filter((item) => (item?.type == 'flag' && allRefs.includes(item.event.reference)));
            return flag.map((item)=>{ 
                return item.event;
            });
        },
        nptArray() {
            const nptEvents = this.sharedState.comments.filter((item) => item?.type == 'npt'); //all the comments
            const allRefs = [...new Set(nptEvents.map((comment) => comment.event.reference))];
            const npt = this.comments.filter((item) => (item?.type == 'npt' && allRefs.includes(item.event.reference))); //comments belongs to this marker
            return npt.map((item)=>{ 
                let showAddEvent = false;
                if(item.event.subCategory == 'start') {
                    showAddEvent = nptEvents.find((nptE) => nptE.event.subCategory == 'end' && nptE.event.reference == item.event.reference) ? false : true;
                }
                return {
                    ...item.event,
                    showAddEvent
                };
            });
        },
        isNoComments() {
            const comments = this.comments.filter((item)=>item?.type !== 'alert' && item?.type !== 'flag' && item?.type !== 'npt');
            return comments.length > 0 ? false : true;
        },
        commentDisplayText() {
            if (this.hideCommentInputs) {
                return 'Add Comment';
            }
            return 'Hide';
        }
    },
    watch: {
        comments:
        {
            handler: function(newComments, oldComments)
            {
                if(this.comments.length <= 0)
                    this.onAllCommentsDeleted();

                for(let i = 0; i < newComments.length; i++)
                {
                    if(newComments[i].read_status == 0)
                    {
                        this.read = false;
                        break;
                    }

                }
                setTimeout(() => this.scrollToBottom(), 10); // waiting a fraction of a second resolves a race condition where the ui has not fully re-rendered
            },
            deep: true
        }     
    },
    data: function () {
        return {
            allFlags: wellboreFlagData,
            commentText: '',
            read: false,
            editingComment: null,
            selectedActivityId: 3,
            selectedReasonId: null,
            selectedContractorId: null,
            selectedWellId: null,
            hideCommentInputs: false
        };
    },
    methods: {
        createdAtString: function(comment)
        {
            let output = moment.utc(comment.created_at)
                        .add({hours: this.jobHourOffset})
                        .format('MMMM Do, h:mm A')

            return output;
        },
        timeString: function() {
            if(this.comments.length <= 0)
                return '';

            let output = moment.utc(this.comments[0].start_time)
                        .add({hours: this.jobHourOffset})
                        .format('h:mm A');

            if(this.comments[0].end_time)
                output += " to " + moment.utc(this.comments[0].end_time)
                                    .add({hours: this.jobHourOffset})
                                    .format('h:mm A')

            return output;
        },
        getNptPair(npt) {
            const nptPair = this.nptEvents.filter((nptObject) => {
                return (nptObject.reference == npt.reference && nptObject.subCategory !== npt.subCategory)
            });
            return nptPair.length == 1? nptPair[0] : null;
        },
        addEndNPT(npt) {
            this.$refs['popover'].$emit('close');
            this.onNPTAddEndClick(npt);
        },
        editNPT(npt) {
            this.$refs['popover'].$emit('close');
            this.onNPTEditClick(npt);
        },
        convertTime(time) {
            return moment.utc(time).add({ hours: this.jobHourOffset }).format('MMMM Do YYYY, h:mm A');
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        deleteFlag: function(flag) {
            const self = this;
            if (!self.isFlagDeleting) {
                self.isFlagDeleting = true
            }
            const url = '/flags/delete';
            if (!flag) {
                alert('Error deleting flag.');
            } else {
                let date =  new Date().toISOString();

                $.ajax({
                    url: url,
                    method: "get",
                    data:
                    {
                        '_token': GlobalFunctions.getCSRFToken(),
                        'timestamp': date,
                        'reference': flag?.reference,
                        'jobNumber': flag?.jobNumber,
                        'wellNumber': flag?.wellNumber,
                        'stageNumber': flag?.stageNumber,
                        'tagName': flag.data?.tagName,
                        'description': flag?.data.description,
                        'shortDescription': flag?.data?.shortDescription,
                        'endDepth': flag?.data?.endDepth
                    },
                    dataType: 'json',
                }).then(function(data){						
                    if(data.error)
                    {
                        alert(JSON.stringify(data.message));
                    }
                    else
                    {
                        flag.subCategory = 'deleteFlag'; //filtered from ui (flagsArray) once delete goes through 
                        this.isFlagDeleting = false; 
                    }
                }).fail(function (jqXHR, textStatus, errorThrown) {
                    const errorMessage = 'Failed to delete flag';
                    console.warn(errorMessage, errorThrown);
                });
            }
        },
        deleteNPT(event){
            const url = '/time-keeper/' + event.jobNumber;
            const self = this;
            if(confirm('Do you really want to delete this event?')) {
                $.ajax({
                    url: url,
                    method: "delete",
                    data:
                    {
                        '_token': GlobalFunctions.getCSRFToken(),
                        'reference': event?.reference,
                    },
                    dataType: 'json',
                }).done(function(data){	                  				
                    if(data.error) {
                        self.handleResponse(data);
                    } else {
                        self?.$refs['popover']?.$emit('close');
                    }
                }).fail(function (jqXHR, textStatus, errorThrown) {
                    const errorMessage = 'Failed to delete NPT';
                    console.warn(errorMessage, errorThrown);
                });
            }
        },
        handleResponse(res) {
            if (res['error'] && res['fracSummaryPermission'] == true && confirm(`In order to update this NPT event, the Frac Stage Summary Report needs to be unapproved. Do you wish to proceed with unapproving stage ${res.fracInfo.stageNumber}, well ${res.fracInfo.wellName}, on the Frac Stage Summary Report?`)) {
                const jobId = res.fracInfo.jobId;
                const stageNumber = res.fracInfo.stageNumber;
                const wellNumber = res.fracInfo.wellNumber;
                const url = `/stage-summary/approval/${jobId}/${wellNumber}`;
                const self = this;
                
                $.ajax({
                    url: url,
                    method: 'POST',
                    data: {
                        stageNumbers: [stageNumber],
                        type: 'frac',
                        approvalStatus: false,
                        _token: GlobalFunctions.getCSRFToken()
                    },
                    dataType: 'json'
                }).done((data) => {	
                    alert('Report has been successfully unapproved. You may now delete the NPT event.');
                })
                
            } else if(res['error'] && res['fracSummaryPermission'] == false) {
                alert(`Please contact your company admin or an authorized Frac Stage Summary Report user to unapprove stage ${res.fracInfo.stageNumber}, well ${res.fracInfo.wellNumber}`);
            } else {
                if(res?.message){
                    alert(JSON.stringify(res.message));
                }else{
                    alert('An unknown error has occured please contact support.');
                }
            }
        },
        getFlagColor(flag) {
            return (this.allFlags.find(item => item.text == flag.data.description))?.color ?? 'red';
        },
        getMarkerSymbol() {
            if (this.flagsArray.length > 0) {
                return (this.allFlags.find(item => item.text == this.flagsArray[0].data.description))?.symbol ?? '';              
            }
            if (this.nptArray.length > 0) {
                return 'N';              
            }
            return ''; //leave marker dot blank if not a well flag
        },
        getMarkerSymbolStyle() {
            let style = '';
            const firstFlag = this.flagsArray? this.flagsArray[0] : null;
            if (firstFlag) {
                style += 'font-size:0.7em; ';
                if (firstFlag.subCategory == 'placeFlag') {
                    style += `color:${this.getFlagColor(firstFlag)}; `; //set symbol text color to the flag color for hollow dots
                } else {
                    style += 'color:#000; '; //give solid dots black symbol text
                }
            } else if (this.nptArray.length > 0) {
                style += 'font-size:0.7em; color: white; ';
            } else {
                return ''; //no symbol style needed
            }
            return style;
        },
        formatFlagDurationMinutes(flag) {
            if (flag.subCategory == 'completed') {
                //duration was changed to be stored under the key value, historically it is stored under 'duration'
                const duration = flag.data?.value? flag.data.value : flag.data.duration; 
                return Math.round(duration); //trim out decimal value (seconds)
            }
            return 'No duration recorded';           
        },
        formatTargetDepthValue(flag) {
            if (flag.subCategory == 'placeFlag') {
                return Intl.NumberFormat().format(flag.data.endDepth);
            }
            return 'No target depth recorded';
        },
        getAlertLevelMessage(level){
            switch(level){
                case 'okay':
                    return "Below Level";
                case 'alarm':
                    return "Alert"
                default:
                    return level;
            };
        },
        getAlertColors(level, color) {
            let style = '';
            style = 'background-color:' + color +';color:' + GlobalFunctions.getTitleColorForBG(color) +';';
            return style;
        },
        generatedId: function() {
            if(this.comments.length <= 0)
                return 'msg-' + this._uid;
            return 'msg-' + this._uid + '-'+ this.comments[0].id;
        },
        popoverId: function()
        {
            if(this.comments.length <= 0)
                return 'msg-popover-'+ this._uid;
            return 'msg-popover-' + this._uid+ '-' + this.comments[0].id;
        },
        handleClick: function(event)
        {
            if(!this.editingComment){
                this.postComment();
            }else{
                this.updateComment();
            }
        },
        scrollToBottom: function()
        {
            const element = document.getElementById(this.popoverId() + "-msg-scroll-container");
            if(element)
                element.scrollTop = element.scrollHeight;
        
        },
        toggleCommentInputs: function() {
            if (this.editingComment) {
                this.editingComment = false;
            }
            this.hideCommentInputs = !this.hideCommentInputs;
        },
        postComment: function ()
        {        
            if(this.commentText.length <= 0)
            {
                console.warn("Comment must have at least 1 character");
                return;
            }

            const {start_time, end_time} = this.comments[0];
            const self = this;

            $.post(
                '/chart-comments/' + this.jobNumber,
                {
                    "_token": $('meta[name="csrf-token"]').attr('content'),
                    jobNumber: this.jobNumber,
                    text: this.commentText,
                    start_time,
                    end_time,
                    eventActivity_id: this.selectedActivityId,
                    eventReason_id: this.selectedReasonId,
                    contractor_id: this.selectedContractorId,
                    well_id: this.selectedWellId
                    
                },
                function (data) {
                    // data returns an array we need to pass back up to the timeline component to re-render the timeline
                    // with the new post
                    if(data.error)
                    {
                        console.warn(data.error);
                    }
                    else
                    {
                        self.onCommentsUpdated(data);
                    }
                },
                'json'
            );

            this.commentText = ''
        },
        updateComment(){
            const {start_time, end_time} = this.comments[0];
            const self = this;

            const eventActivity_id = this.selectedActivityId;
            const eventReason_id = this.selectedReasonId;
            const contractor_id = this.selectedContractorId;

            $.ajax({
                url: '/chart-comments/' + this.jobNumber + '/update',
                method: 'PUT',
                data:
                {
                    "_token": GlobalFunctions.getCSRFToken(),
                    id: this.editingComment.id,
                    jobNumber: this.jobNumber,
                    text: this.commentText,
                    eventactivity_id: eventActivity_id,
                    eventreason_id: eventReason_id,
                    contractor_id: contractor_id,
                    well_id: this.selectedWellId
                },
                dataType: 'json'
            })
            .done(() => { 
                //Update locally
                this.editingComment.eventactivity_id = eventActivity_id;
                this.editingComment.eventreason_id = eventReason_id;
                this.editingComment.contractor_id = contractor_id;
                this.editingComment.text = this.commentText;
                this.editingComment.well_id = this.selectedWellId;

                //Clear editing flag and elements
                this.editingComment = null;
                this.commentText = null;
            })
            .fail((msg) => { /* handle failure if needed */ })
            .always((msg) => { /* always do this */ });
        },
        deleteComment(chartComment,index){
            if(confirm("Do you really want to delete this comment?")) {
                let self = this;

                //Should delete the actual comment
                $.ajax({
                    url: '/chart-comments/' + this.jobNumber + '/delete',
                    method: 'PUT',
                    data:
                    {
                        "_token": GlobalFunctions.getCSRFToken(),
                        comment_id: chartComment.id,
                    },
                    dataType: 'json'
                })
                .done(() => { 
                    let targetComment = self.comments.find(target => target.id == chartComment.id);
                    self.comments.splice(index,1);
                })
                .fail((msg) => { /* handle failure if needed */ })
                .always((msg) => { /* always do this */ });
            }
        },
        onShow(evt)
        {
            this.$nextTick(() => {
                this.scrollToBottom();
            });
        },
        onShown(evt)
        {
            if(this.onPopoverShown)
                this.onPopoverShown();
            
            //currently a NPT marker or flag marker
            //update read status not needed
            if (this.nptArray.length > 0 || this.flagsArray.length > 0)
                return;
           
            const {start_time, end_time} = this.comments[0];
            const self = this;
            const token = $('meta[name="csrf-token"]').attr('content');

            $.ajax({
                url: '/chart-comments/' + this.jobNumber + "/updateReadStatus",
                method: 'PUT',
                data:
                {
                    "_token": $('meta[name="csrf-token"]').attr('content'),
                    job_number: this.jobNumber,
                    start_time
                },
                dataType: 'json'
            })
            .done(() => { /* nothing to do on success */ })
            .fail((msg) => { /* handle failure if needed */ })
            .always((msg) => { /* always do this */ });

            this.read = true;
        },
        onHidden(evt)
        {
            if(this.onPopoverHidden)
                this.onPopoverHidden();
        },
        getActivityType(targetComment){
            let activity = this.eventActivities.find(eventactivity => eventactivity.id == targetComment.eventactivity_id);
            return activity ? activity.type : null;
        },
        getReasonType(targetComment){
            let reason = this.eventReasons.find(eventreason => eventreason.id == targetComment.eventreason_id);
            return reason ? reason.type : null;
        },
        getContractorName(targetComment){
            let contractor = this.contractors.find(contract => contract.id == targetComment.contractor_id);
            return contractor ? contractor.name : null;
        },
        getWellName(targetComment)
        {
            let well;
            if (targetComment.well_id)
            {
                well = this.wells.find(w => w.id.toLowerCase() == targetComment.well_id.toLowerCase());
            }
            else if (targetComment.wellId)
            {
                well = this.wells.find(w => w.id.toLowerCase() == targetComment.wellId.toLowerCase());
            }

            if (well)
            {
                return well.name;
            }
            else
            {
                return '';
            }
        },
        getWellColor(targetComment)
        {
            const well = this.wells.find(w => w.id == targetComment.well_id);
            if(well)
            {
                return well.color;
            }
            else
            {
                return 'black';
            }
        },
        getNPTOutcome(id)
        {
            const outcome = this.nptOutcomes.find(o => o.id.toUpperCase() == id.toUpperCase());
            return outcome? outcome.friendly_name : id;
        },
        getResponsibleVendors(ids)
        {
            let str = '';
            for(let i = 0; i < ids.length -1; i++)
            {
                const vendor = this.customerVendors.find(cv => cv.id.toUpperCase() == ids[i].toUpperCase());
                str +=  (vendor? vendor.friendly_name : ids[i]) + ', ';
            }

            const lastVendor = this.customerVendors.find(cv => cv.id.toUpperCase() == ids[ids.length-1].toUpperCase());
            str += (lastVendor? lastVendor.friendly_name : ids[ids.length-1]);

            return str;
        },
        editComment(targetChartComment){
            this.hideCommentInputs = false;
            this.editingComment = targetChartComment;

            //Fill in appropriate content
            this.selectedActivityId = targetChartComment.eventactivity_id;
            this.selectedReasonId = targetChartComment.eventreason_id;
            this.selectedContractorId = targetChartComment.contractor_id;
            this.selectedWellId = targetChartComment.well_id;

            this.commentText = targetChartComment.text;
        },
        cancelEditing(){
            this.editingComment = null;
            //Should clear the comment.
            this.commentText = "";
        },
        onActivityChanged()
        {
            this.selectedReasonId = null;

            //automatically set the reason if only 1 option
            const reasons = this.eventActivityEventReasonsLookup[this.selectedActivityId];
            if(reasons && reasons.length == 1) {
                this.selectedReasonId = reasons[0];
            }
        },
        getDuration(npt, nptPair)
        {
            let startTime = nptPair.subCategory == 'end'? npt.data.startTime : nptPair.data.startTime;
            let endTime = nptPair.subCategory == 'end'? nptPair.data.endTime : npt.data.endTime;
            return GlobalFunctions.timeDiff(startTime, endTime);
        }
    }
}
</script>

<style scoped>
    .not-allowed
    {
        cursor: not-allowed;
    }

    .scroll-container {
        max-height: 250px;
        min-height: 60px;
        color: #FFFFFF;
        background-color: #373A3C;
        overflow: scroll;
        scrollbar-color: #95989A #373A3C;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0px;
        scrollbar-width: thin;
    }

    .popover {
        background-color: #373A3C;
        padding: 0px;
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
        width: 490px;
        
    }

    .popover-body-custom {
        padding: 0px;
    }

    .comment-container {
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
        background-color: #333333;
        color: #FFFFFF;
    }

    .dot {
        height: 1rem;
        width: 1rem;
        border-radius: 50%;
        display: inline-block;
        position: absolute;
        top: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
    }

    .dot-clickable {
        padding: 0;
        margin: 0;
        border-radius: 50%;
        border: none;
        display: inline-block;
        position: absolute;
        top: 25%;
        bottom: 75%;
        transform: translate(-50%, -50%);
        background-color:#007bff;
    }
    .dot-md {
        height: 1rem;
        width: 1rem;
    }
    .dot-lg {
        height: 1.25rem;
        width: 1.25rem;
    }
    .dot-read {
        background-color: #95989A;
    }
    .badge {
        display: inline-block;
        max-width: 100px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
    }
</style>