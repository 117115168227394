<template>
	<div class="align-items-stretch mt-4">
		<div class="d-flex justify-content-center">
			<div>Stage number</div>
		</div>
			<div class="flex-wrap d-flex align-items-stretch align-content-start justify-content-center" v-bind:key="selectedStage">
				<div v-for="(n, index) in totalStageCount" v-on:click="changeShow(index)" v-bind:key="n" style="width:20px"  :class="{'m-1 d-flex justify-content-center awesome awesome-standard' : true,  'selected-color' : isShown(index), 'unselected-color' : !isShown(index) }" >
					<div>{{ index }}</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props : ['totalStages', 'initialSelect', 'onStageChanged'],
	created() {
		const allStages = Array.from(Array(this.totalStageCount).keys());
		this.initialState = allStages.map(() => false);
		this.show = [...this.initialState];
		this.changeShow(this.selectedStage);
	},
	computed: {
		selectedStage() {
			if(this.initialSelect){
				return this.initialSelect;
			} else {
				return 0;
			}
		},
		totalStageCount() {
			return (this.totalStages+1) // fixing the length of the array, 0-X, not 1-X
		}
	},
	methods: {
		onPress(index){
			this.selectedStage = index;
		},
		isShown(i) {
    	return this.show[i]
		},
		changeShow(i) {
			this.show = [...this.initialState];
			Vue.set(this.show, i, !this.show[i]);
			this.onStageChanged && this.onStageChanged(i)
		},
	},
	data() {
		return {
			show : []
		}
	}
}
</script>

<style>
	.awesome{
        display: inline-block;
        padding: 5px 10px 6px;
				color: #fff;
        text-decoration: none;
        font-weight: lighter;
        line-height: 1.2;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        position: relative;
        cursor: pointer;
        border-color: #5c666e;
    }

		.unselected-color {
			background-color:  #464c52;
		}

		.awesome-standard {
        -moz-box-shadow: 0 1px 2px #777;
        -webkit-box-shadow: 0 1px 2px #777;
        text-shadow: 0 -1px 1px #777;
        border-bottom: 1px solid #777;
    }
</style>