var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: {
        id: _vm.isFilterPlugs ? "conditionModal" : "missRunModal",
        tabindex: "-1",
        role: "dialog",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-lg primary-txt",
          attrs: { role: "document" },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "modal-content main-container div-border px-2 modal-bg",
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-header",
                  staticStyle: { "border-bottom-width": "0px" },
                },
                [
                  _c("job-info-header", {
                    attrs: {
                      jobData: _vm.jobData,
                      onChangeValues: _vm.getShotsHistory,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body py-0" }, [
                _c("label", [_vm._v(_vm._s(_vm.title))]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "scrollmenu scrolldiv pt-2 pr-2 pb-0 div-border modal-bg",
                  },
                  [
                    _vm.loading || _vm.displayData.length === 0
                      ? _c(
                          "div",
                          {
                            staticClass: "d-flex p-2",
                            staticStyle: { "background-color": "white" },
                          },
                          [
                            _vm.loading
                              ? _c("div", { staticClass: "dark-text" }, [
                                  _vm._v("Loading..."),
                                ])
                              : _vm.displayData.length === 0
                              ? _c("div", { staticClass: "dark-text" }, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.noResultMessage) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.displayData, function (shot, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "mb-2" },
                        [
                          _c("shot-plug-status-row", {
                            attrs: {
                              shot: shot,
                              shotValue: true,
                              isPlug: _vm.isFilterPlugs,
                              hourOffset: _vm.hourOffset,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal-footer justify-content-end",
        staticStyle: { "border-top-width": "0px" },
      },
      [
        _c(
          "button",
          {
            staticClass: "btn btn-secondary green-button",
            attrs: { type: "button", "data-dismiss": "modal" },
          },
          [_vm._v("\n          Done\n        ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }