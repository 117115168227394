var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("library-base", {
        attrs: {
          items: _vm.guns,
          filters: _vm.filters,
          columns: _vm.columns.map((c) => c.key),
          sortByCol: _vm.sortByCol,
          sortAsc: _vm.sortAsc,
          title: "Gun Library",
          createBtnTxt: "Add New Gun",
          breadCrumbPath: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "Gun Library",
              link: "/guns",
            },
          ],
        },
        on: {
          create: function ($event) {
            return _vm.editAction(_vm.emptyGun)
          },
          openFilters: _vm.openFilters,
        },
        scopedSlots: _vm._u([
          {
            key: "breadcrumbs",
            fn: function () {
              return [
                _c("iws-breadcrumbs", {
                  staticClass: "breadcrumbs-margins",
                  attrs: {
                    path: [
                      {
                        title: "Dashboard",
                        link: "/home",
                      },
                      {
                        title: "Gun Library",
                        link: "/guns",
                      },
                    ],
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "title",
            fn: function () {
              return [_vm._v("\n            Gun Library\n        ")]
            },
            proxy: true,
          },
          {
            key: "add-item-title",
            fn: function () {
              return [_vm._v("\n            Add New Gun\n        ")]
            },
            proxy: true,
          },
          {
            key: "no-item-body",
            fn: function () {
              return [
                _c("div", { staticClass: "title" }, [_vm._v("Add Guns")]),
                _vm._v(" "),
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(
                    'Start by adding a Gun. Click on the "Add New Gun" button'
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "card",
            fn: function ({ filteredItems: filteredGuns }) {
              return _vm._l(filteredGuns, function (gun, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "gun-card-container" },
                  [
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Name")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(gun.name))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Description")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(gun.description))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Size")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(gun.size))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Carrier")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(gun.carrier_make))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Charge")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(gun.charge_make))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Customer")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(gun.customerName))]),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _vm.enableRowActions
                      ? _c("library-crud-actions", {
                          attrs: {
                            id: gun.id,
                            "enable-actions": {
                              edit: true,
                              view: true,
                              delete: !gun.in_use,
                            },
                          },
                          on: {
                            edit: function ($event) {
                              return _vm.editAction(gun)
                            },
                            view: function ($event) {
                              return _vm.openAction(gun)
                            },
                            delete: function ($event) {
                              return _vm.deleteAction(gun)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              })
            },
          },
          {
            key: "table",
            fn: function ({ filteredItems: filteredGuns }) {
              return [
                _c("iws-table", {
                  attrs: {
                    items: filteredGuns,
                    columns: [..._vm.columns, ""],
                    sortByCol: _vm.sortByCol,
                    sortAsc: _vm.sortAsc,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sortAsc": function ($event) {
                      _vm.sortAsc = $event
                    },
                    "update:sort-asc": function ($event) {
                      _vm.sortAsc = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function () {
                          return _vm._l(filteredGuns, function (gun) {
                            return _c("tr", { key: gun.id }, [
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(gun.name) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(gun.description) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(gun.size) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(gun.carrier_make) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(gun.charge_make) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.enableCustomerCol
                                ? _c("td", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(gun.customerName) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _vm.enableRowActions
                                    ? _c("library-crud-actions", {
                                        attrs: {
                                          id: gun.id,
                                          "enable-actions": {
                                            edit: true,
                                            view: true,
                                            delete: !gun.in_use,
                                          },
                                        },
                                        on: {
                                          edit: function ($event) {
                                            return _vm.editAction(gun)
                                          },
                                          view: function ($event) {
                                            return _vm.openAction(gun)
                                          },
                                          delete: function ($event) {
                                            return _vm.deleteAction(gun)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          })
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("gun-library-filters-modal", {
        ref: "gunFiltersModal",
        attrs: {
          id: "gunFiltersModal",
          guns: _vm.guns,
          "enable-customer-col": _vm.enableCustomerCol,
        },
      }),
      _vm._v(" "),
      _c("gun-library-add-edit-gun", {
        ref: "gunAddEdit",
        attrs: {
          id: "gunAddEdit",
          "enable-customer-col": _vm.enableCustomerCol,
        },
      }),
      _vm._v(" "),
      _c("gun-library-view-gun", {
        ref: "gunView",
        attrs: { id: "gunView", "enable-customer-col": _vm.enableCustomerCol },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }