export default {
    data() {
        return {
        };
    },
    computed: {
        sortPadDropdownByKey() {
            if (this.sortByJobStartDate) {
                this.isReverseSort = true;
                return 'start';
            } else {
                this.isReverseSort = false;
                return 'location'
            }
        }
    },
    methods: {
    }
}