<template>
    <!-- Delay building the kpi until basic data is laid out -->
    <component v-if="!useNewKPi || (!!initalOptions && !!job?.id)" 
        :is="kpiComponentName"
        :item="item"
        :job="job"
        :initalOptions="initalOptions"
        :job-number="jobNumber"
        :customer="customer"
        :edit-mode="editMode"
        :dashboard-data="dashboardData"
        :height="height"
        :style="{ 'height': height ? `${useNewKPi ? height+15 : height}px`: 'auto' }"
    />
</template>
    
<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isFeatureFlagged } = GlobalFunctions;

import kpiSwapTime from './kpiSwapTime.vue';
import kpiMetricsPerStage from './kpiMetricsPerStage.vue';
import kpiTotal from './kpiTotal.vue';
import kpiProgress from './kpiProgress.vue';
import kpiLateral from './kpiLateral.vue';
import kpiPumpingHours from './kpiPumpingHours.vue';

export default {
    components: {
        kpiSwapTime,
        kpiMetricsPerStage,
        kpiTotal,
        kpiProgress,
        kpiLateral,
        kpiPumpingHours
    },

    props: {
        item: Object,
        job: Object,
        jobNumber: String,
        customer: Object,
        editMode: Boolean,
        dashboardData: Object,
        height: Number
    },

    data: () => ({
        initalOptions: null
    }),

    computed: {
        kpiComponentName() {
            // Temp, controls whether we use the new or old ui
            if (isFeatureFlagged('NEW_KPI_UI'))
                // the type happens to so far match the component names, if that's not the case though we can add an if/return statement here
                return this.item.options.type;
            return 'kpi-display-item-old';
        },
        useNewKPi() {
            return this.kpiComponentName !== 'kpi-display-item-old'
        }
    },

    mounted() {
        if (this.useNewKPi) {
            // Convert stringified values (should have been done on the backend but too late now)
            if (typeof this.item?.options?.analyticsType == 'string')
                this.item.options.analyticsType = JSON.parse(this.item?.options?.analyticsType || '[]');
            this.item.options.analyticsType = this.item.options.analyticsType || [];
            
            this.item.options.type = this.item.options.type || 'kpi-progress';

            // Make values "reactive" by adding Vues setters and getters
            // NOTE: Should do the same in the relevant file for any custom options properties (setDefaults method)
            Object.keys(this.item.options).forEach(_key => this.$set(this.item.options, _key, this.item.options[_key]));
            
            // Create a backup for reverting (Needs to be done here so we avoid remounting when KPI type changes (which is an intended feature))
            this.initalOptions = _.cloneDeep(this.item.options);
        } else if (typeof this.item?.options?.analyticsType == 'object') {
            // Tempo, ensures for safe backwards compatibility
            this.item.options.analyticsType = JSON.stringify(this.item?.options?.analyticsType || {});
        }
    }
}
</script>
