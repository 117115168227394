<template>
    <div id="JobListComponent" class="row no-gutters">
        <div class="d-flex flex-column col-md-3 order-2 order-md-1 no-gutters"
            :style="useSmallLayout ? 'height: 100%' : 'height: calc(100vh - 81px)'">
            <div class="d-flex d-md-none justify-content-around">
                <div class="rounded my-3" style="background-color: #666666; width: 32px; height: 8px;">
                </div>
            </div>
            <button class="d-md-none bg-primary text-white border-0" @click="showFilters = !showFilters">
                {{showFilters ? 'Hide Filters' : 'Show Filters'}}
            </button>
            <div v-if="showFilters" ref="filterContainer"
                :class="{
                    'justify-content-around': true,
                    'p-3': true,
                    'collapse': useSmallLayout && !showFilters}"
                style="background-color: #363A3D; border-bottom: 2px solid #666666; border-top: 2px solid #666666; font-size:large;">
                <div class="font-weight-light">Filter Jobs</div>

                <div class="container">
                    <div class="row p-1">
                        <div class="col-4">
                            <div class="font-weight-light">
                                Job Status
                            </div>
                        </div>
                        <div class="col-8">
                            <iws-select
                                :placeholder="!!selectedJobStatus?.length ? selectedJobStatus.map(_value => _value.status).join(', ') : 'Select Job Status'"
                                :value="selectedJobStatus"
                                :options="jobStatusOptions"
                                display-name="status"
                                :multiselect="true"
                                :maintain-on-select="true"
                                @change="jobStatusChange"
                                full-placeholder
                            />
                        </div>
                    </div>

                    <div class="row p-1">
                        <div class="col-4">
                            <div class="font-weight-light">
                                Job Type
                            </div>
                        </div>
                        <div class="col-8">
                            <iws-select
                                :placeholder="!!selectedJobType?.length ? `${selectedJobType.length} selected` : 'Select Job Type'"
                                :value="selectedJobType"
                                :options="jobTypeOptions"
                                display-name="type"
                                :multiselect="true"
                                :maintain-on-select="true"
                                @change="jobTypeChange"
                                full-placeholder
                            />
                        </div>
                    </div>

                    <div class="row p-1" v-if="customers.length > 0">
                        <div class="col-4">
                            <div class="font-weight-light">
                                Customer
                            </div>
                        </div>
                        <div class="col-8" >
                            <iws-select
                                :placeholder="'All Customer Jobs'"
                                :value.sync="filters.customer_id"
                                :options="[
                                    //add this only if isIwsUser is true
                                    checkForIwsUser,
                                    { name: 'All Jobs', id: 'allJobs' },
                                    ...customers
                                ]"
                                display-name="name"
                                value-name="id"
                                @change="searchChanged = true"
                                full-placeholder
                            />
                        </div>
                    </div>

                    <div class="row p-1">
                        <div class="col-4">
                            <div class="font-weight-light">
                                Settings
                            </div>
                        </div>
                        <div class="col-8">
                            <div class="d-flex">
                                <input id="check_filterActive" type="checkbox" v-model="showWellInfo" @change="onShowWellInfoPressed">
                                <div class="font-weight-light" style="padding-left: 7px; font-size:medium;">
                                    Show well information
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="mt-1">
                <div>
                    <b-button
                        size="sm"
                        block
                        :class="showAdvancedSettings ? null : 'collapsed'"
                        :aria-expanded="showAdvancedSettings ? 'true' : 'false'"
                        aria-controls="collapse-4"
                        @click="showAdvancedSettings = !showAdvancedSettings"
                        >Advanced Search
                        <i class="fas mx-1" :class="{'fa-caret-up': showAdvancedSettings, 'fa-caret-down': !showAdvancedSettings}" ></i>
                    </b-button>
                    <b-collapse id="collapse-4" v-model="showAdvancedSettings" class="mt-2">
                        <div v-if="showAdvancedSettings">
                            <div class="input-group input-group-sm">
                                <div class="container">
                                    <div class="row p-1">
                                        <div class="col-4">
                                            <div class="font-weight-light">
                                                Search
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <iws-input
                                                :value.sync="filters.searchFilter"
                                                placeholder="Pad location/name, job #, field system or fleet"
                                                @change="filtersChanged"
                                                @input="searchChanged = true"
                                                @enter="filtersChanged($event)"
                                            />
                                        </div>
                                    </div>
                                    <div class="row p-1">
                                        <div class="col-4">
                                            <div class="font-weight-light">
                                                Start
                                            </div>
                                        </div>

                                        <div class="col-8">
                                            <iws-date-time-picker
                                                :value.sync="filters.rangeStart"
                                                :disabled="!filters.active && !filters.completed"
                                                @input="rangeChanged($event)"
                                                @close="validateRange('rangeStart')"
                                            />
                                        </div>
                                    </div>
                                    <div class="row p-1">
                                        <div class="col-4">
                                            <div class="font-weight-light">
                                                End
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <iws-date-time-picker
                                                :value.sync="filters.rangeEnd"
                                                :disabled="!filters.active && !filters.completed"
                                                @input="rangeChanged($event)"
                                                @close="validateRange('rangeEnd')"
                                            />
                                        </div>
                                    </div>
                                    <div class="row p-1">
                                        <div class="col-4">
                                            <div class="font-weight-light">
                                                Jobs
                                            </div>
                                        </div>
                                        <div class="col-8">
                                            <div class="d-flex">
                                                <input id="check_filterActive" type="checkbox" v-model="filters.showTestJobs" @change="searchChanged = true">
                                                <div class="font-weight-light" style="padding-left: 7px; font-size:medium;">
                                                    Show test jobs
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="alert alert-warning mt-2" v-show="rangeError">
                                        End date must be after start date.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </b-collapse>
                    <div style="width:100%;padding-top:10px;">
                        <div style="float:right;">
                            <b-button  type="reset" size="sm"  @click="resetFilter($event)">Reset</b-button>
                            <b-button class="pr-2 pl-2" type="submit" :variant="searchChanged ? 'primary' : 'secondary'" size="sm" :disabled="!searchChanged" @click="filtersChanged($event)">Search</b-button>
                        </div>
                    </div>
                </div>
            </div>
            <div id="jobs-list-container" ref="jobsListContainer" class="flex-grow-1" >
                <job-summary-card v-if="!isLoadingFilters" v-for="job in jobsData" :key="job.id"
                             :job="job" :is-admin-or-iws="isAdminOrIws" :show-well-info="showWellInfo" />
                <div class="d-flex justify-content-center py-2">
                    <button v-if="currentPage!==totalPages && !loading &&!isLoadingFilters" @click="onLoadMoreClicked" class="btn btn-primary btn-sm small">Load more</button>
                    <div v-if="loading"> Loading... </div>
                    <div v-if="isLoadingFilters">
                        <div class="spinner-border spinner-border-sm" role="status" style="color:white;"></div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-9 order-1 order-md-2">
            <div id="text-width-calculator"></div>

            <div class="d-flex flex-column text-md-center popover-positioning">
                <div class="p-2">
                    <b-button id="popover-button-sync" class="popover-design" variant="secondary">
                        <div v-if="this.onlyActiveFilter">Active Jobs: {{totalJobCount}} </div>
                        <div  v-else> Filtered Jobs: {{totalJobCount}} </div>
                    </b-button>
                </div>

                <div v-if="areJobsReturned" class="p-4">
                    <b-popover :show.sync="show"
                        target="popover-button-sync" triggers="hover click" placement='bottom' class=".popover.b-popover"
                        >
                        <div class="popover-content">
                            <div v-if="standardFracJobCount > 0" class="row mb-2">
                                <div class="col-7 pr-0">
                                    {{standardFracName}}
                                </div>
                                <div class="col-4 pr-0 text-right">
                                    {{standardFracJobCount}}
                                </div>
                            </div>
                            <div v-if="simultaneousFracJobCount > 0" class="row mb-2">
                                <div class="col-8 pr-0">
                                    {{simultaneousFracName}}
                                </div>
                                <div class="col-3 p-0 text-right">
                                    {{simultaneousFracJobCount}}
                                </div>
                            </div>
                            <div v-if="continuousFracJobCount > 0" class="row mb-0 pb-0">
                                <div class="col-7 pr-0">
                                    {{continuousFracName}}
                                </div>
                                <div class="col-4 pr-0 text-right">
                                    {{continuousFracJobCount}}
                                </div>
                            </div>
                            <div v-if="trimulFracJobCount > 0" class="row mb-0 pb-0">
                                <div class="col-7 pr-0">
                                    {{trimulFracName}}
                                </div>
                                <div class="col-4 pr-0 text-right">
                                    {{trimulFracJobCount}}
                                </div>
                            </div>
                            <div v-if="cameraModuleJobCount > 0" class="row mb-0 pb-0">
                                <div class="col-7 pr-0">
                                    {{cameraModuleName}}
                                </div>
                                <div class="col-4 pr-0 text-right">
                                    {{cameraModuleJobCount}}
                                </div>
                            </div>
                            <div v-if="filters.showTestJobs && testJobCount > 0" class="row mb-0 pb-0">
                                <div class="col-7 pr-0">
                                    {{testJobName}}
                                </div>
                                <div class="col-4 pr-0 text-right">
                                    {{testJobCount}}
                                </div>
                            </div>
                        </div>
                    </b-popover>
                </div>
            </div>

            <gmap-map
                ref="map"
                :center="{lat:39.8097343, lng:-98.5556199}"
                :zoom="4.5"
                @click="markerClicked(null)"
                map-type-id="terrain"
                :options="{
                    mapTypeControl: true,
                      mapTypeControlOptions: {
                        position: this.googleMapLayerControlTopRightPosition
                      },
                      }"
                :style="useSmallLayout ? 'width: 100%; height: calc(80vh - 81px)' : 'width: 100%; height: calc(100vh - 81px)'">
                <gmap-cluster
                    :zoom-on-click="true"
                    :styles="[ {
                        textColor: 'white',
                        url: '../images/cluster/blueMarker70px.png',
                        width : 70,
                        height : 70,
                        textSize : 20
                        }]"
                    >
                    <gmap-marker :key="index"
                        v-for="(m, index) in allMarkers"
                        :position="m.position"
                        :label="m.label"
                        :icon="getMarkerIcon(m)"
                        @click="markerClicked(m)"
                        :zIndex="100+index">
                    </gmap-marker>

                </gmap-cluster>
                <gmap-info-window
                    v-if="activeMarkerJobId && markers.length > 0"
                    :position="activeMarker.position"
                    :options="{
                             padding: 0,
                        }">
                    <div class="p-1 mb-2" >
                        <div class="row no-gutters label-header" >
                            {{activeMarker.label.text}}
                        </div>

                        <div v-if="activeTech && activeTech.email"
                             v-on:click="goToURL('mailto:' + activeTech.email)"
                             class="clickable rounded border-primary row no-gutters align-items-center ml-1 mr-1 p-1">
                            <div class="col-2">
                                <span class="text-primary">
                                    <i class="fas fa-envelope fa-2x"></i>
                                </span>
                            </div>
                            <div class="col-10 pl-2">
                                <div class="font-weight-light" >Email {{activeTech.first_name}} {{activeTech.last_name}}</div>
                                <div class="text-muted font-weight-light" style="font-size:small;">{{activeTech.email}}</div>
                            </div>
                        </div>

                        <div v-if="activeTech && activeTech.phone_number"
                             v-on:click="goToURL('tel:' + activeTech.phone_number)"
                             class="clickable rounded border-primary row no-gutters align-items-center ml-1 mr-1 p-1">
                            <div class="col-2">
                                <span class="text-primary">
                                    <i class="fas fa-phone-alt fa-2x"></i>
                                </span>
                            </div>
                            <div class="col-10 pl-2">
                                <div class="font-weight-light">Call {{activeTech.first_name}} {{activeTech.last_name}}</div>
                                <div class="text-muted font-weight-light" style="font-size:small;">{{activeTech.phone_number}}</div>
                            </div>
                        </div>

                        <div v-on:click="goToURL(getMarkerGoogleURL(activeMarker))"
                             class="clickable rounded border-primary row no-gutters align-items-center ml-1 mr-1 p-1">
                            <div class="col-2">
                                <span class="text-primary">
                                    <i class="fas fa-directions fa-2x"></i>
                                </span>
                            </div>
                            <div class="col-10 pl-2 font-weight-light">
                                Get Directions
                            </div>
                        </div>

                    </div>
                </gmap-info-window>
            </gmap-map>
        </div>

        <auth-fail-component v-show="hasAuthError"></auth-fail-component>
    </div>
</template>

<style scoped>
.popover-positioning {
    position: absolute;
    z-index: 2;
}
.popover-design {
    background: white;
    color: #0b0f18;
    border: none;
    width: 160px;
    height: fit-content;
    font-size:16px;
}
.popover-content {
    color: white;
    font-size: 14px;
    justify-content: center;
}

.clickable {
  cursor: pointer;
  font-size: medium;
  text-decoration: none;
  color: #eeeeee;
  border: 1px solid #97d5ff;
  font-weight: bold;
}

.clickable:hover {
  color: #59acff;
  border: 1px solid #97d5ff;
  background-color: #333333;
}

.label-header {
  font-size: large;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  padding-bottom: 2px;
}

/* smooth scrolling effect on marker click */
#jobs-list-container {
  overflow: auto;
  scroll-behavior: smooth;
}

#text-width-calculator {
  position: absolute;
  visibility: hidden;
  height: auto;
  width: auto;
}

</style>

<style>
.gm-style .gm-style-iw {
  background-color: #222222 !important;
  width: 250px;
  font-family: Roboto,Arial,sans-serif;
  padding: 0px;
}

.gm-style-iw > button {
  display: none !important;
}

/*style the arrow*/
.gm-style div div div div div div div div {
  overflow: auto !important;
}

.gm-style .gm-style-iw-t::after {
  background: linear-gradient(45deg,#222222 50%,rgba(255,255,255,0) 58%,rgba(255,255,255,0) 100%);
}
</style>

<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';
import {gmapApi} from 'vue2-google-maps';
import JobMixin from '../mixins/JobMixin.js';
import JobSummaryCard from "./JobSummaryCard.vue";
import iwsDateTimePicker from "./Common/iwsDateTimePicker.vue";

const ALL_CUSTOMER_JOBS = 'allCustomerJobs';
const ALL_JOBS = 'allJobs';

export default {
    components: {
        iwsDateTimePicker,
        JobSummaryCard
    },
    data() {
        const {
            current_page,
            last_page,
            per_page
        } = this.pagination;

        let standardFrac = 'Standard Frac (FS)';
        let simultaneousFrac = 'Simultaneous Frac (SF)';
        let continuousFrac = 'Continuous Frac (CF)';
        let trimulfrac = 'Trimul Frac (TF)';
        let cameraModule = 'Camera Module (CM)';
        let testJob = 'Test Job (TS)';

        return {
            googleMapLayerControlTopRightPosition: 3,
            activeItem: { id: 1, status: 'Active' },
            completedItem: { id: 2, status: 'Completed' },
            projectedItem: { id: 3, status: 'Projected' },
            standardFracName: standardFrac,
            simultaneousFracName: simultaneousFrac,
            continuousFracName: continuousFrac,
            trimulFracName: trimulfrac,
            cameraModuleName: cameraModule,
            testJobName: testJob,
            fsJobItem: { id: 1, type: standardFrac },
            sfJobItem: { id: 2, type: simultaneousFrac },
            cfJobItem: { id: 3, type: continuousFrac },
            tfJobItem: { id: 4, type: trimulfrac },
            cmJobItem: { id: 5, type: cameraModule},
            trimulFracJobCount: 0,
            cameraModuleJobCount: 0,
            jobStatusOptions: [],
            selectedJobStatus: [],
            jobTypeOptions: [],
            selectedJobType: [],
            onlyActiveFilter: true,
            markers: [],
            dashboardPermissions: [],
            showWellInfo: true,
            perPage: per_page,
            activeMarkerJobId: null,
            goldStar: null,
            mapData: this.mapdata,
            jobsData: this.jobs,
            jobTypeCounts: this.jobCounts,
            standardFracJobCount: null,
            simultaneousFracJobCount: null,
            continuousFracJobCount: null,
            testJobCount: null,
            totalJobCount: null,
            paginationData: this.pagination,
            searchChanged: false,
            rangeError: false,
            showAdvancedSettings: this.params.searchFilter || this.params.rangeStart || this.params.rangeEnd,
            filters: {
                active: this.params.active,
                completed: this.params.completed,
                projected: this.params.projected,
                customer_id: this.defaultCustomerId,
                rangeStart: this.params.rangeStart,
                rangeEnd: this.params.rangeEnd,
                searchFilter: this.params.searchFilter,
                standardFrac: this.params.standardFrac,
                simultaneousFrac: this.params.simultaneousFrac,
                continuousFrac: this.params.continuousFrac,
                trimulFrac: this.params.trimulFrac,
                cameraModule: this.params.cameraModule,
                showTestJobs: this.params.showTestJobs
            },
            isLoadingFilters: false,
            loading: false,
            currentPage: current_page,
            totalPages: last_page,
            windowWidth: window.innerWidth,
            showFilters: true,
            hasAuthError: false,
            show: false
        };
    },
    mixins: [JobMixin],
    props: ['jobs', 'role', 'params', 'customers', 'pagination', 'jobCounts','mapdata', 'isDebug', 'currentUser', 'isAdminOrIws', 'isIwsUser'],

    methods: {
        validateRange(field) {
            const value = this.filters[field];
            const unixTime0 = moment.unix(0).utc();
            const maxDate = moment('9999-01-01T23:59', 'YYYY-MM-DDTHH:mm:ss'); //beginning of year 9999
            const selectedDate = moment(value, 'YYYY-MM-DDTHH:mm:ss', true);

            if (!selectedDate.isValid() || selectedDate.isAfter(maxDate) || selectedDate.isBefore(unixTime0)) {
                this.filters[field] = null;
            }
        },
        onShowWellInfoPressed: function() {
            localStorage.showJobPageWellInfo = this.showWellInfo;
        },

        markerClicked: async function(marker) {
            if(!marker) {
                this.activeMarkerJobId = null;
                return;
            }

            this.setJobsByID([marker.id], false);

            this.activeMarkerJobId = marker.id;

            //scroll to div
            const jobListDivRef = 'jobListDiv-' + marker.id;
            const jobsListDiv = this.$refs[jobListDivRef][0];
            this.$refs.jobsListContainer.scrollTop = jobsListDiv.offsetTop - this.$refs.filterContainer.offsetHeight;
        },
        getMarkerGoogleURL: function(marker) {
            const api = 'https://www.google.com/maps/search/?api=1&query=';
            return api + marker.position.lat + ',' + marker.position.lng;
        },
        goToURL: function(url) {
            window.location.href = url;
        },
        rangeChanged: function(event) {
                this.rangeError = this.filters.rangeStart > this.filters.rangeEnd;
                this.searchChanged = !this.rangeError;
                if (this.rangeError)
                    return;

            this.searchChanged = true;
        },
        checkJobProperty: function(property, selectedJob) {
            // used to check if the id for a certain property is in the id list of all the selected properties
            let isSelected = false;
            if (selectedJob.includes(property)) {
                isSelected = true;
            }
            return isSelected;
        },
        setDefaultFilters() {
            // resets to default values
            // by default, only active is selected as job status
            // by default, all 3 job types are selected
            this.filters.standardFrac = true;
            this.filters.simultaneousFrac = true;
            this.filters.continuousFrac = true;
            this.filters.trimulFrac = true;
            this.filters.cameraModule = true;
            this.filters.showTestJobs = false;
            this.filters.active = true;
            this.filters.completed = false;
            this.filters.projected = false;
            this.filters.searchFilter = '';
            this.filters.rangeEnd = null;
            this.filters.rangeStart = null;
            this.filters.customer_id = this.defaultCustomerId;
            this.rangeError = false;
        },
        setJobStatus: function() {
            // bolds items that are selected in the dropdown list
            this.selectedJobStatus = [];
            if (this.filters.active) {
                this.selectedJobStatus.push(this.activeItem);
            }
            if (this.filters.completed) {
                this.selectedJobStatus.push(this.completedItem);
            }
            if (this.filters.projected) {
                this.selectedJobStatus.push(this.projectedItem);
            }
        },
        setJobType: function() {
            this.selectedJobType = [];

            if (this.filters.standardFrac) {
                this.selectedJobType.push(this.fsJobItem);
            }
            if (this.filters.simultaneousFrac) {
                this.selectedJobType.push(this.sfJobItem);
            }
            if (this.filters.continuousFrac) {
                this.selectedJobType.push(this.cfJobItem);
            }
            if (this.filters.trimulFrac) {
                this.selectedJobType.push(this.tfJobItem);
            }
            if (this.filters.cameraModule) {
                this.selectedJobType.push(this.cmJobItem);
            }
        },
        setJobCounts: function() {
            // Get the job counts
            this.standardFracJobCount = this.getJobTypeCount('standardFrac');
            this.simultaneousFracJobCount = this.getJobTypeCount('simultaneousFrac');
            this.continuousFracJobCount = this.getJobTypeCount('continuousFrac');
            this.trimulFracJobCount = this.getJobTypeCount('trimulFrac');
            this.cameraModuleJobCount = this.getJobTypeCount('cameraModule');
            this.testJobCount = this.getJobTypeCount('test');
            this.totalJobCount = this.getJobTypeCount('total');
        },
        jobStatusChange: function(newValue) {
            // set job status to their id number in options dictionary
            const active = 1;
            const completed = 2;
            const projected = 3;
            this.searchChanged = true;

            // updates the selected array with the new job statuses that were selected/removed
            const _index = this.selectedJobStatus.findIndex(_value => _value.status == newValue.status);

            if (_index < 0) {
                this.selectedJobStatus.push(newValue);
            } else {
                this.selectedJobStatus.splice(_index, 1);
            }

            // set the filters to true/false depending on if they are selected
            const selectedStatusIds = this.selectedJobStatus.map(function(eachJobStatus) {
                return eachJobStatus.id;
            });
            this.filters.active = this.checkJobProperty(active, selectedStatusIds);
            this.filters.completed = this.checkJobProperty(completed, selectedStatusIds);
            this.filters.projected = this.checkJobProperty(projected, selectedStatusIds);
        },
        jobTypeChange: function(newValue) {
            // display only selected job types in joblist
            // set job type to their id number in options dictionary
            const fs = 1;
            const sf = 2;
            const cf = 3;
            const tf = 4;
            const cm = 5;
            this.searchChanged = true;

            // updates the selected array with the new job statuses that were selected/removed
            const _index = this.selectedJobType.findIndex(_value => _value.type == newValue.type);

            if (_index < 0) {
                this.selectedJobType.push(newValue);
            } else {
                this.selectedJobType.splice(_index, 1);
            }

            // set the filters to true/false depending on if they are selected
            const selectedTypeIds = this.selectedJobType.map(function(eachJobType) {
                return eachJobType.id;
            });
            this.filters.standardFrac = this.checkJobProperty(fs, selectedTypeIds);
            this.filters.simultaneousFrac = this.checkJobProperty(sf, selectedTypeIds);
            this.filters.continuousFrac = this.checkJobProperty(cf, selectedTypeIds);
            this.filters.trimulFrac = this.checkJobProperty(tf, selectedTypeIds);
            this.filters.cameraModule = this.checkJobProperty(cm, selectedTypeIds);
        },
        getJobTypeCount: function(jobType) {
            // returns the number of jobs of a specific job type
            if (this.jobTypeCounts.hasOwnProperty(jobType)) {
                return this.jobTypeCounts[jobType];
            }
        },
        filtersChanged: function(event) {
            // update job list by applying filters selected
            if(this.filters.searchFilter == null) {
                this.filters.searchFilter = '';
            }

            // set the label on the job count popover
            if (!this.filters.completed && !this.filters.projected && this.filters.active) {
                this.onlyActiveFilter = true;
            } else {
                this.onlyActiveFilter = false;
            }

            this.filterJobs();
            this.searchChanged = false;
            this.markers = [];
            this.activeMarkerJobId = null;
        },
        resetFilter: function(event) {
            this.setDefaultFilters();
            this.setJobStatus();
            this.setJobType();
            this.setJobCounts();
            this.filtersChanged();
        },
        filterJobs() {
            // calls url to get new jobs with applied filters
            this.currentPage = 1;
            const url = '/filterJobs?' + this.dictToURLParams(this.filters) + `&page=${this.currentPage}`;
            const self = this;
            self.isLoadingFilters = true;

            $.get(
                url,
                {
                    validateDate: true
                },
                function(data) {
                    self.isLoadingFilters = false;
                    if (data.error) {
                        alert(data.message);
                    } else {
                        // update jobs in job list
                        self.jobsData = [...data['jobs']];

                        // update page numbers
                        const page = data['pagination'];
                        self.currentPage = page.current_page;
                        self.totalPages = page.last_page;

                        // update job map data
                        self.mapData =  data['mapData'];
                        for(const index in self.mapData) {
                            self.addMarkerForJob(self.mapData[index]);
                        }
                        let jobCounts = data['jobCounts'];
                        if (jobCounts) {
                            self.standardFracJobCount = jobCounts.standardFrac;
                            self.simultaneousFracJobCount = jobCounts.simultaneousFrac;
                            self.continuousFracJobCount = jobCounts.continuousFrac;
                            self.trimulFracJobCount = jobCounts.trimulFrac;
                            self.cameraModuleJobCount = jobCounts.cameraModule;
                            self.testJobCount = jobCounts.test;
                            self.totalJobCount = jobCounts.total;

                            if (!self.areJobsReturned) {
                                self.show = false;
                            }
                        }
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.log('unauthorized');
                    self.hasAuthError = true;
                }
                else {
                    setTimeout(self.updateJobs, 5000);
                }
            });
        },
        dictToURLParams: function(dict) {
            const str = [];
            for(const p in dict) {
                // Backend needs seconds included but we don't take seconds in the form
                // If is a date field and the filter is set, reformat it to include seconds (always 00)
                if ((p == 'rangeStart' || p == 'rangeEnd') && !!dict[p]) {
                    const temp = moment(dict[p]);
                    if (temp.isValid())
                        dict[p] = temp.format('YYYY-MM-DDThh:mm:00');
                }

                if(dict[p]){
                    str.push(encodeURIComponent(p) + '=' + encodeURIComponent(dict[p]));
                }
            }
            return str.join('&');
        },
        numberOfStagesAllWells: function (job) {
            if(job.jobNumber.includes('CM')){
                return 0;
            }
            return job.wells.reduce((a, b) => a + b.numberOfStages, 0);
        },
        completedPercent: function (job) {
            return (job.completedStages / this.numberOfStagesAllWells(job) * 100).toFixed(1);
        },
        getTitleColorForBG: function(bgColor) {
            return GlobalFunctions.getTitleColorForBG(bgColor);
        },
        indexFrac: function (job) {
            let index = -1;
            for (let i = 0; i < job.wells.length; i++) {
                if (job.wells[i].activity == 'frac')
                {index = i;}
            }
            return index;
        },
        wellInfo(job) {
            if (job.jobNumber.includes('CM')){
                return {}
            }
            const detailedWellInfo = job.wells.map((well)=>{
                return {
                    id: well.id,
                    name: well.name,
                    currentStage: well.currentStage && well.currentStage!==0? well.currentStage : '--',
                    completedStages: well.completedStages && well.completedStages!==0? well.completedStages : '--',
                    numberOfStages: well.numberOfStages && well.numberOfStages!==0? well.numberOfStages : '--',
                    color: well.color,
                    activityString: this.getActivityString(well.activity)
                };
            });
            return detailedWellInfo;
        },
        getActivityString(activityType) {
            if(activityType === 'frac') {
                return 'Frac';
            } else if(activityType === 'wireline') {
                return 'Wireline';
            } else {
                return 'Idle';
            }
        },
        addMarkerForJob: function (job) {
            this.markers.push({
                id: job.id,
                position: {
                    lat: job.latitude,
                    lng: job.longitude
                },
                opacity: 1,
                draggable: true,
                enabled: true,
                clicked: 0,
                rightClicked: 0,
                dragended: 0,
                ifw: true,
                ifw2text: job.jobNumber,
                label: {
                    text: job.location,
                    color: 'white',
                    fontSize: 'large',
                    fontWeight: 'bold'
                }
            });
            return this.markers[this.markers.length - 1];
        },
        getTextMetrics: function (text, fontSize = 'large') {
            // Get the width and height in pixels for a string of characters by using a hidden div element
            const calculator = document.getElementById('text-width-calculator');

            calculator.style.fontSize = fontSize;
            calculator.innerHTML = text;

            return {
                width: calculator.clientWidth + 1,
                height: calculator.clientHeight + 1
            };
        },
        getMarkerIcon: function (marker) {
            // Create the marker by drawing a poly path

            // Get font size metrics for the label name string
            const metrics = this.getTextMetrics(marker.label.text);
            const textWidth = metrics.width;
            const textHeight = metrics.height/2;

            // Adjust label padding
            const labelPaddingX = 4;
            const labelPaddingY = 4;

            const arrowHeight = 10;
            const arrowWidth = 18;

            //widthRadius
            const wr = (textWidth / 2) + labelPaddingX;

            //heightRadius
            const hr = (textHeight / 2) + labelPaddingY;

            //y goes from 0 at top, increasing towards bottom
            //abbreviating (t)op, (l)eft, (r)ight, (b)ottom, (p)oint
            const box = {
                blx: -wr, bly: -arrowHeight + (labelPaddingY/2),
                tlx: -wr, tly: -arrowHeight - 2*hr,
                trx: wr, try: -arrowHeight - 2*hr,
                brx: wr, bry: -arrowHeight + (labelPaddingY/2)
            };

            const arrow = {
                rx: arrowWidth/2, ry: -arrowHeight + (labelPaddingY/2),
                px: 0, py: 0,
                lx: -arrowWidth/2, ly: -arrowHeight + (labelPaddingY/2)
            };

            return {
                path: 'M '
                        +box.blx+','+box.bly+' '
                        +box.tlx+','+box.tly+' '
                        +box.trx+','+box.try+' '
                        +box.brx+','+box.bry+' '
                        +arrow.rx+','+arrow.ry+' '
                        +arrow.px+','+arrow.py+' '
                        +arrow.lx+','+arrow.ly+' z',
                fillColor: '#222222',
                fillOpacity: 0.9,
                strokeWeight: 2,
                strokeColor: '#222222',
                labelOrigin: {x: 0, y: -(arrowHeight + hr)}
            };
        },
        updateJobs: function() {
            const activeJobs = this.filterActiveJobs();
            const activeJobIDs = activeJobs.map(job=>job.id);
            activeJobIDs.length !==0 && this.setJobsByID(activeJobIDs, true);
        },
        setJobsByID: function(jobIDs, isUpdate) {
            const url = '/getJobsInfo?'+this.dictToURLParams(this.filters);
            const self = this;
            $.get(
                url,
                {
                    jobIDs: jobIDs,
                    removeMapData: true
                },
                function(data) {
                    if (!data.error) {
                        let matchFound = false;

                        //updating only active jobs
                        const updatedJobList = self.jobsData.map(job => {
                            const  updatedResponse  = data['jobs'].find(updatedJob => updatedJob.id === job.id);

                            if(updatedResponse) {
                                matchFound = true;
                                return { ...job, ...updatedResponse };
                            } else {
                                return job;
                            }
                        });

                        self.jobsData = updatedJobList;

                        if(isUpdate) {
                            setTimeout(self.updateJobs, 5000);
                        } else if(!matchFound && !isUpdate) {
                            //marker pressed and update the list if the job is not there
                            self.jobsData = [...data['jobs'] , ...self.jobsData];
                        }
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.log('unauthorized');
                    self.hasAuthError = true;
                }
                else {
                    setTimeout(self.updateJobs, 5000);
                }
            });
        },
        filterActiveJobs() {
            return this.jobsData.filter(job=>job.end===null);
        },
        onLoadMoreClicked: function() {
            this.currentPage = this.currentPage+1;
            this.loading = true;

            const url = '/getJobsInfo?'+this.dictToURLParams(this.filters) + `&page=${this.currentPage}`;
            const self = this;
            $.get(
                url,
                {},
                function(data) {
                    self.loading = false;

                    self.jobsData = [...self.jobsData, ...data['jobs']];
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.log('unauthorized');
                    self.hasAuthError = true;
                }
                else {
                    setTimeout(self.updateJobs, 5000);
                }
            });
        },
    },
    mounted() {
        const self = this;

        this.setJobStatus();
        this.setJobType();
        this.setJobCounts();

        // Add items as options for drop down filters
        this.jobStatusOptions.push(this.activeItem);
        this.jobStatusOptions.push(this.completedItem);
        this.jobStatusOptions.push(this.projectedItem);

        this.jobTypeOptions.push(this.fsJobItem);
        this.jobTypeOptions.push(this.sfJobItem);
        this.jobTypeOptions.push(this.cfJobItem);
        this.jobTypeOptions.push(this.tfJobItem);
        this.jobTypeOptions.push(this.cmJobItem);

        this.dashboardPermissions = this.currentUser?.dashboard_permissions? this.currentUser.dashboard_permissions: [];

        if(localStorage?.showJobPageWellInfo) {
            const showWellInfo = JSON.parse(localStorage?.showJobPageWellInfo);
            this.showWellInfo = showWellInfo;
        }



        this.$gmapApiPromiseLazy().then(() => {


            //setup the bounds of the map to zoom in on the job markers appropriately
            const bounds = new self.google.maps.LatLngBounds();

            for(const index in self.mapData) {
                const marker = self.addMarkerForJob(self.mapData[index]);

                const markerLatLng = new self.google.maps.LatLng({
                    lat: marker.position.lat,
                    lng: marker.position.lng });

                bounds.extend(markerLatLng);
            }

            //the following code creates additional points that must
            //be in bounds, in effect this prevents the maps from zooming in too much
            const center = bounds.getCenter();
            const offset = 0.01; //degrees of lat/lng that must be in bounds, controls zoom level
            const northEast = new self.google.maps.LatLng(
                center.lat() + offset,
                center.lng() + offset
            );
            const southWest = new self.google.maps.LatLng(
                center.lat() - offset,
                center.lng() - offset
            );
            const minBounds = new self.google.maps.LatLngBounds(southWest, northEast);
            self.$refs['map'].fitBounds(bounds.union(minBounds));
        });

        this.filters.customer_id = this.params.customer_id ? this.params.customer_id : this.defaultCustomerId;

        this.updateJobs();
        this.isLoadingFilters = false;
    },
    computed: {
        defaultCustomerId: function() {
            if(this.isIwsUser) {
                return this.params.customer_id || this.currentUser.customer_id;
            }
            return this.currentUser.customer_id;
        },
        allCustomerJobs: function() {
            return ALL_CUSTOMER_JOBS;
        },
        allJobs: function() {
            return ALL_JOBS;
        },
        allMarkers: function() {
            return this.markers.filter(marker => marker.id != this.activeMarkerJobId);
        },
        activeMarker: function() {
            return this.markers.find(marker => marker.id == this.activeMarkerJobId);
        },
        activeTech: function() {
            const activeJob = this.jobsData.find(job => job.id == this.activeMarkerJobId);
            const now = moment.utc();
            now.add({hours: activeJob.hourOffset});
            const nowHours = parseFloat(now.format('HH'));
            const nowMinutesPer60 = parseFloat(now.format('mm')) / 60;
            const nowHoursDecimal = nowHours + nowMinutesPer60;

            if(nowHoursDecimal < activeJob.shiftStart || nowHoursDecimal > activeJob.shiftStart + 12) {
                //current adjust time is outside the daytime shift, so it's night shift
                return activeJob.techUserNight;
            }

            return activeJob.techUserDaytime;
        },
        useSmallLayout: function() {
            return this.windowWidth <= 768;
        },
        areJobsReturned: function()  {
            return this.standardFracJobCount > 0 || this.simultaneousFracJobCount > 0 || this.continuousFracJobCount > 0 || this.trimulFracJobCount > 0 || this.cameraModuleJobCount > 0 || this.testJobCount > 0;
        },
        checkForIwsUser(){
            return this.isIwsUser ? { name: 'All Customer Jobs', id: 'allCustomerJobs' } : '';
        },
        google: gmapApi
    }
};
</script>

