<template>
	<div class="d-flex flex-row align-items-center">
		<div class="d-flex flex-grow-1 dark-text align-items-center px-2 border border-dark" style="background-color:white;height:34px">
			<div>
				{{ shot.type==="shot" ? 'Shot fired' : 'Plug set'}} at {{ shot.depth }}ft KB, time: {{ convertDateTimeWithOffset(shot.created_at) }}
			</div>
		</div>
		<div class="px-2 align-items-center">
			<iws-switch :onPress="onToggle" :value="shotStatus"/>
		</div>
		<div class="primary-txt align-items-center status-container">
			<span>{{ shotStatus? trueStatusText: falseStatusText }}</span>
		</div>	
	</div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
import moment from 'moment';
export default {
	name: "ShotsListItem",
	props : ['shot', 'isPlug', 'hourOffset'],
	created() {
		this.trueStatusText = this.isPlug? 'NEW' : 'SHOT OK';
		this.falseStatusText = this.isPlug? 'USED' : 'SHOT MISFIRED!'
	},
	methods : {	
		onToggle : function (){
			this.shotStatus = this.shotStatus? false : true;
			this.updateDatabase()
		},
		convertDateTimeWithOffset(datetimeStr){
			let date = moment(datetimeStr);
			date.add({hours: this.hourOffset });
			return date.format("YYYY-MM-DD HH:mm:ss");
		},
		updateDatabase(){
			const data = {
				"_token": GlobalFunctions.getCSRFToken(),
				status : this.shotStatus? 1 : 0
			};
			
			let baseURL = '/wireline-history/shotStatus/';

			if(this.isPlug){
				baseURL =  '/wireline-history/plugCondition/'
			}


			$.ajax({
					method: "PUT",
					url: baseURL + this.shot.id,
					data,
					dataType: 'json'
			}).done((res)=>{
				// console.log("TCL: res", res)
			})

		} 

	},
	data(){
		return {
			shotStatus : this.isPlug? this.shot.plugCondition : this.shot.shotStatus,
			loading : false
		}
	},
}
</script>

<style>

	.status-container {
		min-width: 125px;
	}
	.dark-text { 
		color : #343a40;
	}
</style>