var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: `relative ${
        _vm.dropdownActive ? "light-blue-border" : "grey-border"
      } input-box rounded`,
    },
    [
      _c(
        "div",
        {
          staticClass:
            "w-100 h-100 d-flex justify-content-between align-items-center px-2",
          on: { click: _vm.toggleDropown },
        },
        [
          _vm.value == null
            ? _c("div", { staticClass: "text-muted" }, [
                _vm._v(_vm._s(this.formatValue(_vm.defaultValue))),
              ])
            : _c("div", { ref: "formattedValue", staticClass: "text-white" }, [
                _vm._v(_vm._s(this.formatValue(_vm.value))),
              ]),
          _vm._v(" "),
          _c(
            "div",
            {
              class: `${
                _vm.dropdownActive ? "upside-down" : ""
              } d-flex align-items-center justify-content-center`,
            },
            [
              _c("img", {
                attrs: { src: "/images/icons/chevron-down-small.svg" },
              }),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.dropdownActive
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "on-clickaway",
                  rawName: "v-on-clickaway",
                  value: _vm.deactivateDropdown,
                  expression: "deactivateDropdown",
                },
              ],
              staticClass:
                "position-absolute overflow-auto grey-border rounded clickable dd",
            },
            [
              _vm._l(_vm.values, function (value) {
                return [
                  _c(
                    "div",
                    {
                      staticClass:
                        "w-100 d-flex justify-content-center align-items-center dd-item white",
                      on: {
                        click: function ($event) {
                          return _vm.valueSelected(value)
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "py-1" }, [
                        _vm._v(_vm._s(value)),
                      ]),
                    ]
                  ),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }