<!--
    * Component Description
    The base component for building tool libraries.
    Using slots and props this supports searching, filtering, table

    * Example Usage
    See
        1. GunLibraryComponent.vue
        2. PlugLibraryComponent.vue
        3. TicketStatus/TicketStatusComponent.vue
-->

<template>
    <div id="LibraryBase">
        <base-table-view
            :title="title"
            :items="items"
            :columns="columns"
            :filters="filters"
            :filter.sync="filterKey"
            @filter="$emit('openFilters')"
            :breadCrumbPath="breadCrumbPath"
        >
            <template #right-option>
                <template v-if="enableCreate">
                    <button class="btn btn-primary primary-color" @click="$emit('create')">
                        <i class="fas fa-plus"></i> {{ createBtnTxt }}
                    </button>
                    <slot name="additional-actions" :filteredItems="filteredItems"></slot>
                </template>
                <template v-else-if="quickFilters">
                    <div class="quick-filters-container">
                        Quick filters
                        <button
                            v-for="quickFilter in quickFilters"
                            :key="quickFilter.name"
                            @click="setFilters(quickFilter.filters)"
                            class="quick-filter"
                        >
                            {{ quickFilter.name }}
                        </button>
                        <slot name="additional-actions" :filteredItems="filteredItems"></slot>
                    </div>
                </template>
            </template>

            <template #content>
                <div>
                    <slot name="tabs"></slot>
                </div>
                <div v-if="_isNullOrEmpty(items)" class="no-results-container">
                    <i v-if="enableCreate" class="fas fa-plus"></i>
                    <slot name="no-item-body"> </slot>
                </div>
                <div v-else-if="_isNullOrEmpty(filteredItems)" class="no-results-container">
                    <i class="fas fa-search"></i>

                    <div class="title">No results found</div>
                    <div class="subtitle">Your filters did not match any records. Please try again.</div>
                </div>
                <template v-else-if="isMobile && hasMobileView">
                    <slot name="card" :filteredItems="filteredItems"></slot>
                </template>
                <template v-else>
                    <slot name="table" :filteredItems="filteredItems"></slot>
                </template>
            </template>
        </base-table-view>
    </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isTruthy, isNullOrEmpty } = GlobalFunctions;
import _ from 'lodash';
import BaseTableViewComponent from './BaseTableViewComponent.vue';

export default {
    props: {
        ...BaseTableViewComponent.props,
        createBtnTxt: String,
        columns: Array,
        hasMobileView: {
            type: Boolean,
            required: false,
            default: true
        },
        sortByCol: String,
        sortAsc: Boolean,
        quickFilters: [],
        enableCreate: {
            type: Boolean,
            required: false,
            default: true,
        }
    },
    components: {
        BaseTableViewComponent
    },
    data: () => ({
        isMobile: false,
        filterKey: null
    }),
    computed: {
        filteredItems() {
            if (isNullOrEmpty(this.items))
                return [];

            // Search bar will search for a match in all columns visible in the table (ignoring case)
            return this.items.filter(_item => {
                // If search bar is in use, match against all visible columns
                if (isTruthy(this.filterKey)) {
                    if (!!!this.columns.find(_col => isTruthy(_item[_col]) && (''+_item[_col]).toLowerCase().includes(this.filterKey)))
                        return false;
                }

                // Apply all active filters
                for (const _key in this.filters) {
                    const _currentList = this.filters[_key];

                    // If the list for this filter is nullOrEmpty, it means apply no filtering here
                    if (!isNullOrEmpty(_currentList)) {
                        // Since the options are populated by the guns themselves, we look for exact matches here
                        if (!!!_currentList.find(_value => _item[_key] == _value))
                            return false;
                    }
                }

                // Passed all filters, show the gun
                return true
            }).sort((a, b) => {
                const _priority = a[this.sortByCol] > b[this.sortByCol] ? 1 : -1;

                // Flip priority is sorting by descending
                return this.sortAsc ? _priority : (-1 * _priority);
            });
        }
    },
    methods: {
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },
        onResize() {
            // Magic number we agreed upon where desktop view stopped being viable
            this.isMobile = window.innerWidth <= 768;
        },
        setFilters(filters) {
            this.$emit('setFilters', _.cloneDeep(filters));
        },
    },
    mounted() {
        this.onResize();
        this.filterKey = this.filter;
        window.addEventListener('resize', this.onResize, { passive: true });
    }
}
</script>

<style>
    #LibraryBase .no-results-container {
        text-align: center;

        width: 100%;
        padding: 20px 10px;

        border: #7B8A98 1px solid;
        border-radius: 4px;
    }
    #LibraryBase .no-results-container i {
        font-size: 36px;
        color: var(--primary-text-color);

        margin-bottom: 20px;

        background: rgba(217, 215, 215, 0.1);
        border: 6px solid rgba(240, 240, 240, 0.1);
        border-radius: 50%;
        padding: 20px;
    }
    #LibraryBase .no-results-container .title {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: var(--primary-text-color);
    }
    #LibraryBase .no-results-container .subtitle {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: #667085;
    }

    #LibraryBase .gun-card-container {
        border: 1px solid #7B8A98;
        border-radius: 4px;
        background: #242A30 !important;
        padding: 16px 12px 16px 12px;
        margin-bottom: 16px;
    }
    #LibraryBase .gun-card-container label {
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 100%;
        color: var(--primary-text-color);
        margin-bottom: 0px !important;
    }
    #LibraryBase .row span {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 100%;
        color: var(--primary-text-color);
    }
    #LibraryBase .row.fixed-width {
        margin-left: 0px !important;
        margin-right: 0px !important;
    }
    #LibraryBase .row.fixed-width:not(:first-child) {
        margin-top: 10px;
    }
    #LibraryBase .gun-card-container .row label {
        display: block;
    }
    #LibraryBase .quick-filters-container {
        font-size: 14px;
    }
    #LibraryBase .quick-filter {
        background: #16181b;
        border: 1px solid #9FAABF;
        border-radius: 30px;
        padding: 1px 12px;
        color: #fff;
        margin-left: 6px;
        margin-top: 12px;
    }
</style>
