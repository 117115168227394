var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column chart-container flex-fill",
      style: { height: _vm.height ? _vm.height + "px" : "auto" },
    },
    [
      _c(
        "div",
        {
          staticClass: "d-flex",
          style: { height: _vm.headerHeight + "px" },
          attrs: { id: "kpiDiv" + _vm.componentId },
        },
        [
          _c(
            "div",
            {
              staticClass: "mr-auto",
              staticStyle: { overflow: "hidden", "white-space": "nowrap" },
            },
            [
              _c("div", { staticClass: "d-flex align-items-center" }, [
                _vm.showNotEnoughDataWarning
                  ? _c(
                      "div",
                      {
                        staticClass: "alert alert-warning m-4 absolute-center",
                        attrs: { role: "alert" },
                      },
                      [
                        _vm._v(
                          "\n                    Not enough data to generate this KPI summary!\n                "
                        ),
                      ]
                    )
                  : _vm.isCombinedPumpHoursAndStagesChart
                  ? _c("h4", [
                      _vm._v(
                        "\n                    Total Stages & Pumping Hours/Day\n                "
                      ),
                    ])
                  : _vm.selectedType == "kpi-total"
                  ? _c("h4", [
                      _vm._v(
                        "\n                    Total Stages/Day\n                "
                      ),
                    ])
                  : _vm.selectedType == "kpi-progress"
                  ? _c("h4", [
                      _vm._v(
                        "\n                    Completion Progress\n                "
                      ),
                    ])
                  : _vm.selectedType == "kpi-lateral"
                  ? _c("h4", [
                      _vm._v(
                        "\n                    Lateral Length/Day\n                "
                      ),
                    ])
                  : _vm.selectedType == "kpi-pumpingHours"
                  ? _c("h4", [
                      _vm._v(
                        "\n                    Pumping Hours/" +
                          _vm._s(_vm.isDayTypeStage ? "Stage" : "Day") +
                          "\n                "
                      ),
                    ])
                  : _vm.selectedType == "kpi-swapTime"
                  ? _c("h4", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.swapTypeLabel?.upper) +
                          " Times\n                "
                      ),
                    ])
                  : _vm.selectedType == "kpi-metricsPerStage"
                  ? _c("h4", [
                      _vm._v(
                        "\n                    Metrics Per Stage\n                "
                      ),
                    ])
                  : _c("h4", [
                      _vm._v(
                        "\n                    Pump Time/Day\n                "
                      ),
                    ]),
                _vm._v(" "),
                _vm.selectedType
                  ? _c(
                      "div",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.showNotEnoughDataWarning && !_vm.editMode,
                            expression:
                              "!showNotEnoughDataWarning && !editMode",
                          },
                        ],
                      },
                      [
                        _c("tooltip-component", {
                          attrs: {
                            tooltip_key: _vm.getTooltipKeyFromType(
                              _vm.selectedType
                            ),
                            customer_id: _vm.job.customer_id,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip:top",
                  value: "Export",
                  expression: "'Export'",
                  arg: "top",
                },
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.editMode && !_vm.exportOverlayMode,
                  expression: "!editMode && !exportOverlayMode",
                },
              ],
            },
            [
              _c("i", {
                staticClass: "fas fa-download clickable",
                on: {
                  click: function ($event) {
                    _vm.exportOverlayMode = true
                  },
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.editMode,
                  expression: "editMode",
                },
              ],
              staticStyle: { "z-index": "10000" },
            },
            [
              _vm.selectedType !== "kpi-swapTime" &&
              _vm.selectedType !== "kpi-metricsPerStage"
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", { staticClass: "input-group-text px-1" }, [
                      _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                        _vm._v("Time Range:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedDayType,
                              expression: "selectedDayType",
                            },
                          ],
                          ref: "dayType",
                          staticClass:
                            "form-control kpi-select float-right ml-auto",
                          attrs: { id: "inputGroupSelect02" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedDayType = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.onTimeRangeChanged,
                            ],
                          },
                        },
                        _vm._l(_vm.dayTypes, function (dayType) {
                          return _c(
                            "option",
                            {
                              key: dayType,
                              domProps: { value: dayType },
                              on: {
                                change: function ($event) {
                                  dayType = _vm.$refs["dayType"].value
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(dayType) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1 text-center" }, [
                _c(
                  "label",
                  { staticClass: "input-group-text px-1 text-center" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "float-left pr-1 ml-1",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v("KPI Type:")]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedType,
                            expression: "selectedType",
                          },
                        ],
                        ref: "kpiType",
                        staticClass:
                          "form-control kpi-select float-right ml-auto",
                        attrs: { id: "inputGroupSelect01" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.onDataChanged,
                          ],
                        },
                      },
                      [
                        _vm._l(_vm.kpiTypes, function (kpiType) {
                          return [
                            kpiType != "kpi-swapTime" || _vm.isSwapTimeFeature
                              ? _c(
                                  "option",
                                  {
                                    key: kpiType,
                                    domProps: { value: kpiType },
                                    on: {
                                      change: function ($event) {
                                        kpiType = _vm.$refs["kpiType"].value
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.kpiTypeLabels[kpiType]) +
                                        "\n                            "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]
                        }),
                      ],
                      2
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.selectedType == "kpi-pumpingHours" &&
              _vm.isFeatureFlagged("PUMPTIME_ADX_DATA_SOURCE")
                ? _c("div", { staticClass: "mb-1" }, [
                    _c("label", { staticClass: "input-group-text px-1" }, [
                      _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                        _vm._v("Data Source:"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedPumpTimeSource,
                              expression: "selectedPumpTimeSource",
                            },
                          ],
                          ref: "dayType",
                          staticClass:
                            "form-control kpi-select float-right ml-auto",
                          attrs: { id: "inputGroupSelect02" },
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedPumpTimeSource = $event.target
                                  .multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.onDataChanged,
                            ],
                          },
                        },
                        _vm._l(_vm.dataSources, function (dataSource) {
                          return _c(
                            "option",
                            {
                              key: dataSource.value,
                              domProps: { value: dataSource.value },
                            },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(dataSource.label) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.isSwapTimeFeature && _vm.selectedType == "kpi-swapTime"
                ? _c("div", { staticClass: "mb-1 text-center" }, [
                    _c(
                      "label",
                      { staticClass: "input-group-text px-1 text-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "float-left pr-1 ml-1",
                            staticStyle: { "text-align": "center" },
                          },
                          [_vm._v("Swap Type:")]
                        ),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedSwapType,
                                expression: "selectedSwapType",
                              },
                            ],
                            staticClass:
                              "form-control kpi-select float-right ml-auto",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedSwapType = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.onDataChanged,
                              ],
                            },
                          },
                          _vm._l(_vm.swapTypes, function (swapType, key) {
                            return _c(
                              "option",
                              { key: key, domProps: { value: key } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(swapType) +
                                    "\n                        "
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedType != "kpi-progress"
                ? _c(
                    "div",
                    { staticClass: "mb-1 text-center" },
                    [
                      _c(
                        "label",
                        {
                          staticClass: "input-group-text px-1",
                          attrs: { for: "analytics-" + _vm._uid },
                        },
                        [
                          _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                            _vm._v("Analytics:"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              ref: "analyticType",
                              staticClass:
                                "form-control kpi-select float-right ml-auto",
                              staticStyle: {
                                "background-color": "#fff !important",
                              },
                              attrs: { id: "analytics-" + _vm._uid },
                              on: {
                                change: _vm.onDataChanged,
                                mousedown: function ($event) {
                                  $event.preventDefault()
                                  return _vm.onSelectAnalyticPressed.apply(
                                    null,
                                    arguments
                                  )
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { selected: "" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.updatedSelectedAnalytics.length
                                    ) +
                                    " selected"
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "analytics-" + _vm._uid,
                            triggers: "focus",
                            placement: "bottom",
                            "boundary-padding": 0,
                            delay: { show: 0, hide: 0 },
                            boundary: "viewport",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex analytics-dropdown" },
                            [
                              _c("checkbox-list", {
                                attrs: {
                                  label: "Select Metrics",
                                  height: "100",
                                  options: _vm.analyticTypes,
                                  selectedOptions: _vm.selectedAnalyticTypes,
                                },
                                on: {
                                  "update:selectedOptions": function ($event) {
                                    return _vm.onAnalyticsSelected($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedType == "kpi-swapTime" && _vm.requireWirelineCheck
                ? _c("div", { staticClass: "mb-1 text-center" }, [
                    _c("label", { staticClass: "input-group-text px-1" }, [
                      _c(
                        "div",
                        {
                          staticClass: "float-left pr-1 ml-1",
                          staticStyle: { "text-align": "center" },
                        },
                        [_vm._v("Wireline:")]
                      ),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.wirelineSwapFilter,
                              expression: "wirelineSwapFilter",
                            },
                          ],
                          staticClass:
                            "form-control kpi-select float-right ml-auto",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.wirelineSwapFilter = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.onDataChanged,
                            ],
                          },
                        },
                        _vm._l(
                          _vm.dashboardData.wirelineSystems,
                          function (wireline) {
                            return _c(
                              "option",
                              { domProps: { value: wireline.number } },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(wireline.name) +
                                    "\n                        "
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedType == "kpi-swapTime"
                ? _c(
                    "div",
                    { staticClass: "mb-1 text-center kpi-swap-filter" },
                    [
                      _c("label", { staticClass: "input-group-text px-1" }, [
                        _vm._m(0),
                        _vm._v(" "),
                        !_vm.customMinSwap
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.minSwapTimeFilter,
                                    expression: "minSwapTimeFilter",
                                  },
                                ],
                                staticClass:
                                  "form-control kpi-select float-right ml-auto",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.minSwapTimeFilter = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.onDataChanged,
                                  ],
                                },
                              },
                              _vm._l(
                                _vm.minSwapFilterOptions,
                                function (filterOption) {
                                  return _c(
                                    "option",
                                    {
                                      key: `${filterOption.value}_${filterOption.label}`,
                                      domProps: { value: filterOption.value },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(filterOption.label) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                min: "0",
                                placeholder: "In Minutes",
                                type: "number",
                              },
                              domProps: {
                                value: _vm.secondsToMinutes(
                                  _vm.minSwapTimeFilter
                                ),
                              },
                              on: {
                                input: function ($event) {
                                  _vm.minSwapTimeFilter = _vm.minutesToSeconds(
                                    $event?.target?.value
                                  )
                                },
                                change: _vm.onDataChanged,
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "5px" } },
                          [
                            _c("iws-button", {
                              staticClass: "clear-filter",
                              attrs: { text: "X", type: "outline-secondary" },
                              on: {
                                click: function ($event) {
                                  _vm.minSwapTimeFilter = null
                                },
                              },
                            }),
                            _vm._v(" "),
                            !_vm.customMinSwap
                              ? _c("iws-button", {
                                  staticClass: "switch-filter-type",
                                  attrs: {
                                    text: "Custom",
                                    type: "outline-secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.customMinSwap = true
                                      _vm.minSwapTimeFilter = null
                                    },
                                  },
                                })
                              : _c("iws-button", {
                                  staticClass: "switch-filter-type",
                                  attrs: {
                                    text: "Preset",
                                    type: "outline-secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.customMinSwap = false
                                      _vm.minSwapTimeFilter = null
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedType == "kpi-swapTime"
                ? _c(
                    "div",
                    { staticClass: "mb-1 text-center kpi-swap-filter" },
                    [
                      _c("label", { staticClass: "input-group-text px-1" }, [
                        _vm._m(1),
                        _vm._v(" "),
                        !_vm.customMaxSwap
                          ? _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.maxSwapTimeFilter,
                                    expression: "maxSwapTimeFilter",
                                  },
                                ],
                                staticClass:
                                  "form-control kpi-select float-right ml-auto",
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.maxSwapTimeFilter = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.onDataChanged,
                                  ],
                                },
                              },
                              _vm._l(
                                _vm.maxSwapFilterOptions,
                                function (filterOption) {
                                  return _c(
                                    "option",
                                    {
                                      key: `${filterOption.value}_${filterOption.label}`,
                                      domProps: { value: filterOption.value },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(filterOption.label) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            )
                          : _c("input", {
                              staticClass: "form-control",
                              attrs: {
                                min: "0",
                                placeholder: "In Minutes",
                                type: "number",
                              },
                              domProps: {
                                value: _vm.secondsToMinutes(
                                  _vm.maxSwapTimeFilter
                                ),
                              },
                              on: {
                                input: function ($event) {
                                  _vm.maxSwapTimeFilter = _vm.minutesToSeconds(
                                    $event?.target?.value
                                  )
                                },
                                change: _vm.onDataChanged,
                              },
                            }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticStyle: { "margin-left": "5px" } },
                          [
                            _c("iws-button", {
                              staticClass: "clear-filter",
                              attrs: { text: "X", type: "outline-secondary" },
                              on: {
                                click: function ($event) {
                                  _vm.maxSwapTimeFilter = null
                                },
                              },
                            }),
                            _vm._v(" "),
                            !_vm.customMaxSwap
                              ? _c("iws-button", {
                                  staticClass: "switch-filter-type",
                                  attrs: {
                                    text: "Custom",
                                    type: "outline-secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.customMaxSwap = true
                                      _vm.maxSwapTimeFilter = null
                                    },
                                  },
                                })
                              : _c("iws-button", {
                                  staticClass: "switch-filter-type",
                                  attrs: {
                                    text: "Preset",
                                    type: "outline-secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.customMaxSwap = false
                                      _vm.maxSwapTimeFilter = null
                                    },
                                  },
                                }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.selectedType == "kpi-metricsPerStage"
                ? [
                    !_vm._isNullOrEmpty(_vm.metadataTags)
                      ? [
                          _c("div", { staticClass: "mb-1 text-center" }, [
                            _c(
                              "label",
                              { staticClass: "input-group-text px-1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "float-left pr-1 ml-1",
                                    staticStyle: { "text-align": "center" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Scatter Tag:\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.scatterTag,
                                        expression: "scatterTag",
                                      },
                                    ],
                                    staticClass:
                                      "form-control kpi-select float-right ml-auto",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.scatterTag = $event.target
                                            .multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        _vm.onDataChanged,
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    [
                                      { displayName: " ", tagName: -2 },
                                      ..._vm.metadataTags,
                                    ],
                                    function (tag) {
                                      return _c(
                                        "option",
                                        {
                                          key: `scatter_tag_option_${tag.tagName}`,
                                          domProps: { value: tag.tagName },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                tag.displayName || tag.tagName
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-1 text-center" }, [
                            _c(
                              "label",
                              { staticClass: "input-group-text px-1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "float-left pr-1 ml-1",
                                    staticStyle: { "text-align": "center" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                Bar Tag:\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.barTag,
                                        expression: "barTag",
                                      },
                                    ],
                                    staticClass:
                                      "form-control kpi-select float-right ml-auto",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.barTag = $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        },
                                        _vm.onDataChanged,
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    [
                                      { displayName: " ", tagName: -2 },
                                      ..._vm.metadataTags,
                                    ],
                                    function (tag) {
                                      return _c(
                                        "option",
                                        {
                                          key: `bar_tag_option_${tag.tagName}`,
                                          domProps: { value: tag.tagName },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                tag.displayName || tag.tagName
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]
                      : _c("div", [
                          _vm._v(
                            "\n                    No Customer Tags Found\n                "
                          ),
                        ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "mb-1 text-center kpi-metrics-filter" },
                      [
                        _c("label", { staticClass: "input-group-text px-1" }, [
                          _c(
                            "div",
                            {
                              staticClass: "float-left pr-1 ml-1",
                              staticStyle: { "text-align": "left" },
                            },
                            [
                              _vm._v(
                                "\n                            Stages Shown:\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.showLimitStageMetrics
                            ? _c(
                                "span",
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.limitStagesMetricsPerStage,
                                        expression:
                                          "limitStagesMetricsPerStage",
                                      },
                                    ],
                                    staticClass: "form-control",
                                    attrs: {
                                      min: "0",
                                      placeholder: "Limit the stages shown",
                                      type: "number",
                                    },
                                    domProps: {
                                      value: _vm.limitStagesMetricsPerStage,
                                    },
                                    on: {
                                      input: [
                                        function ($event) {
                                          if ($event.target.composing) return
                                          _vm.limitStagesMetricsPerStage =
                                            $event.target.value
                                        },
                                        _vm.onLimitStageMetricsPerStageChanged,
                                      ],
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("iws-button", {
                                    staticClass: "clear-filter",
                                    attrs: {
                                      text: "X",
                                      type: "outline-secondary",
                                    },
                                    on: { release: _vm.clearLimitMetrics },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticStyle: { margin: "auto" } },
                                [
                                  _c("iws-button", {
                                    attrs: {
                                      text: "Enable Limit",
                                      type: "outline-primary",
                                    },
                                    on: {
                                      click:
                                        _vm.onLimitStageMetricsButtonClicked,
                                    },
                                  }),
                                ],
                                1
                              ),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        staticClass: "input-group-text px-1",
                        attrs: { for: "pump_hours_plotline-" + _vm._uid },
                      },
                      [
                        _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                          _vm._v("Average Wells:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.averageWellDataMetricsPerStage,
                              expression: "averageWellDataMetricsPerStage",
                            },
                          ],
                          staticClass: "form-control float-right",
                          staticStyle: {
                            "background-color": "#fff !important",
                          },
                          attrs: {
                            type: "checkbox",
                            id: "average_well_data-" + _vm._uid,
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.averageWellDataMetricsPerStage
                            )
                              ? _vm._i(
                                  _vm.averageWellDataMetricsPerStage,
                                  null
                                ) > -1
                              : _vm.averageWellDataMetricsPerStage,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.averageWellDataMetricsPerStage,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.averageWellDataMetricsPerStage =
                                        $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.averageWellDataMetricsPerStage = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.averageWellDataMetricsPerStage = $$c
                                }
                              },
                              _vm.onAverageWellDataMetricsChecked,
                            ],
                          },
                        }),
                      ]
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.showZeroValueAnalyticField
                ? _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.updatedSelectedAnalytics.length > 0,
                          expression: "updatedSelectedAnalytics.length > 0",
                        },
                      ],
                      staticClass: "mb-1 text-center",
                    },
                    [
                      _c(
                        "label",
                        { staticClass: "input-group-text px-1 text-center" },
                        [
                          _vm._m(2),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedZeroStageType,
                                  expression: "selectedZeroStageType",
                                },
                              ],
                              ref: "zeroStageType",
                              staticClass:
                                "form-control kpi-select float-right ml-auto",
                              attrs: { id: "zeroStage-" + _vm._uid },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedZeroStageType = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  _vm.onDataChanged,
                                ],
                              },
                            },
                            _vm._l(
                              _vm.zeroStageTypes,
                              function (zeroStageType) {
                                return _c(
                                  "option",
                                  {
                                    key: zeroStageType,
                                    domProps: { value: zeroStageType },
                                    on: {
                                      change: function ($event) {
                                        zeroStageType =
                                          _vm.$refs["zeroStageType"].value
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(
                                          _vm.zeroStageTypeLabels[zeroStageType]
                                        ) +
                                        "\n                        "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.showPumpHourPlotlineOption
                ? _c("div", { staticClass: "mb-1 text-center" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-group-text px-1",
                        attrs: { for: "pump_hours_plotline-" + _vm._uid },
                      },
                      [
                        _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                          _vm._v("Include Pump Hours Plotline If Available:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.includePumpHoursPlotline,
                              expression: "includePumpHoursPlotline",
                            },
                          ],
                          ref: "pumpHoursPlotlineExport",
                          staticClass: "form-control float-right",
                          staticStyle: {
                            "background-color": "#fff !important",
                          },
                          attrs: {
                            type: "checkbox",
                            id: "pump_hours_plotline_export-" + _vm._uid,
                          },
                          domProps: {
                            checked: Array.isArray(_vm.includePumpHoursPlotline)
                              ? _vm._i(_vm.includePumpHoursPlotline, null) > -1
                              : _vm.includePumpHoursPlotline,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.includePumpHoursPlotline,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      (_vm.includePumpHoursPlotline =
                                        $$a.concat([$$v]))
                                  } else {
                                    $$i > -1 &&
                                      (_vm.includePumpHoursPlotline = $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1)))
                                  }
                                } else {
                                  _vm.includePumpHoursPlotline = $$c
                                }
                              },
                              _vm.onDataChanged,
                            ],
                            click: function ($event) {
                              return _vm.onSelectPumpHoursPlotline()
                            },
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              [_vm.kpiTypes[0], _vm.kpiTypes[3]].includes(_vm.selectedType) &&
              !_vm.isDayTypeStage
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-item-center justify-content-end",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex align-items-center pr-2" },
                        [_vm._v("Stack by Well")]
                      ),
                      _c("iws-switch", {
                        attrs: {
                          onPress: _vm.onStackByWellPressed,
                          value: _vm.isStackedByWell,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _vm.selectedType != _vm.initialSelectedType ||
                _vm.selectedDayType != _vm.initialSelectedDayType ||
                _vm.selectedZeroStageType != _vm.initialSelectedZeroStageType ||
                _vm.includePumpHoursPlotline !=
                  _vm.initialPumpHoursPlotlineVal ||
                _vm.onSelectedAnalyticsChanged ||
                _vm.onStackInitChanged ||
                (!!_vm.item.options.selectedSwapType &&
                  _vm.selectedSwapType != _vm.item.options.selectedSwapType) ||
                _vm.wirelineSwapFilter != _vm.item.options.wirelineSwapFilter ||
                _vm.minSwapTimeFilter != _vm.item.options.minSwapTimeFilter ||
                _vm.maxSwapTimeFilter != _vm.item.options.maxSwapTimeFilter ||
                _vm.initialSelectedPumpTimeSource !=
                  _vm.selectedPumpTimeSource ||
                _vm.item.options.scatterTag != _vm.scatterTag ||
                _vm.item.options.barTag != _vm.barTag ||
                _vm.item.options.averageWellDataMetricsPerStage !=
                  _vm.averageWellDataMetricsPerStage ||
                _vm.item.options.limitStagesMetricsPerStage !=
                  _vm.limitStagesMetricsPerStage
                  ? _c("div", { staticClass: "ml-auto float-right" }, [
                      _vm.hasDataChanged
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.revertConfig()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Revert\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.hasDataChanged
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-success ml-1",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.saveConfig()
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                        Save\n                    "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.exportOverlayMode,
                  expression: "exportOverlayMode",
                },
              ],
              staticStyle: { "z-index": "10000" },
            },
            [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.selectedType != "kpi-progress" &&
                        _vm.selectedType !== "kpi-swapTime" &&
                        _vm.selectedType !== "kpi-metricsPerStage",
                      expression:
                        "selectedType != 'kpi-progress' && selectedType !== 'kpi-swapTime' && selectedType !== 'kpi-metricsPerStage'",
                    },
                  ],
                  staticClass: "mb-1",
                },
                [
                  _c("label", { staticClass: "input-group-text px-1" }, [
                    _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                      _vm._v("Time Range:"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedDayType,
                            expression: "selectedDayType",
                          },
                        ],
                        ref: "dayType",
                        staticClass:
                          "form-control kpi-select float-right ml-auto",
                        attrs: { id: "exportSelect1" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedDayType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.onTimeRangeChanged,
                          ],
                        },
                      },
                      _vm._l(_vm.dayTypes, function (dayType) {
                        return _c(
                          "option",
                          {
                            key: dayType,
                            domProps: { value: dayType },
                            on: {
                              change: function ($event) {
                                dayType = _vm.$refs["dayType"].value
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            " +
                                _vm._s(dayType) +
                                "\n                        "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1" }, [
                _c("label", { staticClass: "input-group-text px-1" }, [
                  _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                    _vm._v("Export Type:"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedExportType,
                          expression: "selectedExportType",
                        },
                      ],
                      ref: "exportType",
                      staticClass:
                        "form-control kpi-select float-right ml-auto",
                      attrs: { id: "exportSelect2" },
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedExportType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          _vm.onTimeRangeChanged,
                        ],
                      },
                    },
                    _vm._l(_vm.exportTypes, function (exportType) {
                      return _c(
                        "option",
                        {
                          key: exportType,
                          domProps: { value: exportType },
                          on: {
                            change: function ($event) {
                              _vm.selectedExportType =
                                _vm.$refs["exportType"].value
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                            " +
                              _vm._s(exportType) +
                              "\n                        "
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "mb-1 text-center" }, [
                _c(
                  "label",
                  { staticClass: "input-group-text px-1 text-center" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "float-left pr-1 ml-1",
                        staticStyle: { "text-align": "center" },
                      },
                      [_vm._v("KPI Type:")]
                    ),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedType,
                            expression: "selectedType",
                          },
                        ],
                        ref: "kpiType",
                        staticClass:
                          "form-control kpi-select float-right ml-auto",
                        attrs: { id: "exportSelect3" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      _vm._l(_vm.kpiTypes, function (kpiType) {
                        return kpiType != "kpi-swapTime" ||
                          _vm.isSwapTimeFeature
                          ? _c(
                              "option",
                              {
                                key: kpiType,
                                domProps: { value: kpiType },
                                on: {
                                  change: function ($event) {
                                    kpiType = _vm.$refs["kpiType"].value
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(_vm.kpiTypeLabels[kpiType]) +
                                    "\n                        "
                                ),
                              ]
                            )
                          : _vm._e()
                      }),
                      0
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.showPumpHourPlotlineOption
                ? _c("div", { staticClass: "mb-1 text-center" }, [
                    _c(
                      "label",
                      {
                        staticClass: "input-group-text px-1",
                        attrs: { for: "pump_hours_plotline-" + _vm._uid },
                      },
                      [
                        _c("div", { staticClass: "float-left pr-1 ml-1" }, [
                          _vm._v("Include Pump Hours Plotline If Available:"),
                        ]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.includePumpHoursPlotline,
                              expression: "includePumpHoursPlotline",
                            },
                          ],
                          ref: "pumpHoursPlotline",
                          staticClass: "form-control float-right ml-auto",
                          staticStyle: {
                            "background-color": "#fff !important",
                          },
                          attrs: {
                            type: "checkbox",
                            id: "pump_hours_plotline-" + _vm._uid,
                          },
                          domProps: {
                            checked: Array.isArray(_vm.includePumpHoursPlotline)
                              ? _vm._i(_vm.includePumpHoursPlotline, null) > -1
                              : _vm.includePumpHoursPlotline,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.onSelectPumpHoursPlotline()
                            },
                            change: function ($event) {
                              var $$a = _vm.includePumpHoursPlotline,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.includePumpHoursPlotline = $$a.concat([
                                      $$v,
                                    ]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.includePumpHoursPlotline = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.includePumpHoursPlotline = $$c
                              }
                            },
                          },
                        }),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              [_vm.kpiTypes[0], _vm.kpiTypes[3]].includes(_vm.selectedType)
                ? _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-item-center justify-content-end",
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "d-flex align-items-center pr-2" },
                        [_vm._v("Stack by Well")]
                      ),
                      _c("iws-switch", {
                        attrs: {
                          onPress: _vm.onStackByWellPressed,
                          value: _vm.isStackedByWell,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "mt-2" }, [
                _c("div", { staticClass: "ml-auto float-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.revertConfig()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Cancel\n                    "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-success ml-1",
                      attrs: {
                        disabled: !_vm.isChartExportable(),
                        type: "button",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.exportChart()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Export\n                    "
                      ),
                    ]
                  ),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _vm.showAnalytics
        ? _c(
            "div",
            { staticClass: "d-flex flex-wrap" },
            _vm._l(_vm.analyticsData, function (analyticObject, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "px-4 col-wrapper",
                  style: {
                    "flex-basis": `calc(100% / ${_vm.columnCount})`,
                    "max-width": `calc(100% / ${_vm.columnCount})`,
                  },
                },
                [
                  _c("div", { staticClass: "mb-2" }, [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c("div", {
                        staticClass: "mr-1",
                        style:
                          "height:10px;width:10px;background-color:" +
                          analyticObject.backgroundColor,
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(analyticObject.label))]),
                    ]),
                  ]),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.showNotEnoughDataWarning,
              expression: "!showNotEnoughDataWarning",
            },
          ],
          staticClass: "rounded mt-2 py-2 px-2",
          style: { backgroundColor: "#575757", position: "relative" },
        },
        [
          _vm.selectedType == "kpi-swapTime"
            ? [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.swapTypes[_vm.selectedSwapType]) +
                    "\n\n            "
                ),
                _vm.requireWirelineCheck && !!_vm.selectedWireline
                  ? [
                      _vm._v(
                        "\n                (" +
                          _vm._s(_vm.selectedWireline?.name) +
                          ")\n            "
                      ),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.selectedType == "kpi-metricsPerStage"
            ? [
                !_vm._isFalsy(_vm.scatterTag) &&
                _vm.scatterTag != -1 &&
                _vm.scatterTag != -2
                  ? _c("div", [
                      _vm._v(
                        "\n                Scatter Plot: " +
                          _vm._s(_vm.findTagName(_vm.scatterTag)) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                !_vm._isFalsy(_vm.barTag) &&
                _vm.barTag != -1 &&
                _vm.barTag != -2
                  ? _c("div", [
                      _vm._v(
                        "\n                Bar Chart: " +
                          _vm._s(_vm.findTagName(_vm.barTag)) +
                          "\n            "
                      ),
                    ])
                  : _vm._e(),
              ]
            : [
                _vm._v(
                  "\n            Time Range: " +
                    _vm._s(_vm.getTimeRangeDescription) +
                    "\n        "
                ),
              ],
          _vm._v(" "),
          _vm.selectedType == "kpi-progress" &&
          _vm.averageStagesCompletedPerDay > 0
            ? _c("div", [
                _vm._v(
                  "\n            Average stages completed per day: " +
                    _vm._s(_vm.averageStagesCompletedPerDay) +
                    "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.filterInfoDetails != null ||
          (!!_vm.filteredItems && !!_vm.filteredItems.length)
            ? _c(
                "span",
                { staticStyle: { float: "right" } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "clickable",
                      attrs: { id: `popover-target-${_vm._uuid}` },
                    },
                    [
                      _vm._v(
                        "\n                " +
                          _vm._s(_vm.filterInfoDetails) +
                          "\n            "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-popover",
                    {
                      attrs: {
                        target: `popover-target-${_vm._uuid}`,
                        triggers: "hover",
                        placement: "top",
                        "boundary-padding": 0,
                        "custom-class": "filtered-popover",
                        variant: "dark",
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "title",
                            fn: function () {
                              return [
                                _vm._v(
                                  "Filtered " +
                                    _vm._s(_vm.swapTypeLabel.upper) +
                                    " Times"
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2638225296
                      ),
                    },
                    [
                      _vm._v(" "),
                      _vm._l(_vm.filteredItems, function (filteredItem, index) {
                        return _c("div", { class: { "pt-2": index > 0 } }, [
                          _c("div", [
                            _c("b", [
                              _vm._v(
                                _vm._s(_vm.swapTypeLabel.upper) +
                                  " Number: " +
                                  _vm._s(filteredItem.index)
                              ),
                            ]),
                          ]),
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.readableSeconds(filteredItem.swapTime)
                              ) +
                              "\n                    "
                          ),
                          _c("div", {
                            domProps: {
                              innerHTML: _vm._s(
                                _vm.formatStartEndTime(
                                  filteredItem.meta.from,
                                  filteredItem.meta.to
                                )
                              ),
                            },
                          }),
                        ])
                      }),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.showNotEnoughDataWarning
        ? _c("div")
        : _vm.selectedType == "kpi-total"
        ? _c(
            "div",
            { style: { height: this.chartContentHeight + "px" } },
            [
              !_vm.includePumpHoursPlotline
                ? _c("bar-chart", {
                    ref: "kpiTotalChart",
                    style: {
                      position: "relative",
                      height: "99%",
                      width: "100%",
                    },
                    attrs: {
                      "chart-data": _vm.kpi.completedStagesPerDay,
                      options: _vm.options.completedStagesPerDay,
                    },
                  })
                : _c("bar-chart", {
                    ref: "kpiTotalChart",
                    style: {
                      position: "relative",
                      height: "99%",
                      width: "100%",
                    },
                    attrs: {
                      "chart-data": _vm.kpi.pumpHoursAndStagesPerDay,
                      options: _vm.options.pumpHoursAndStagesPerDay,
                    },
                  }),
            ],
            1
          )
        : _vm.selectedType == "kpi-progress"
        ? _c(
            "div",
            {
              staticClass: "d-flex flex-column",
              style: { height: this.chartContentHeight + "px" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "d-flex flex-column flex-grow-1",
                  style: {
                    height:
                      this.chartContentHeight < _vm.progressHeightTrigger
                        ? this.chartContentHeight * 0.7 + "px"
                        : "auto",
                  },
                },
                [
                  _c("simple-line-chart", {
                    key: _vm.completionProgressChartKey,
                    ref: "completionProgressChart",
                    style: {
                      position: "relative",
                      width: "100%",
                      height: "100%",
                    },
                    attrs: {
                      "chart-data": _vm.kpi.completionProgress,
                      options: _vm.options.completionProgress,
                      customPlugins: _vm.chartPlugins,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-1 flex-column" }, [
                _c("div", { staticClass: "d-flex flex-1 flex-row" }, [
                  _c("div", { staticClass: "pl-3 pr-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "div",
                        { staticClass: "custom-control custom-checkbox mt-1" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.isFullJob,
                                expression: "isFullJob",
                              },
                            ],
                            staticClass: "custom-control-input",
                            attrs: {
                              type: "checkbox",
                              id: "full-job" + _vm.componentId,
                            },
                            domProps: {
                              checked: Array.isArray(_vm.isFullJob)
                                ? _vm._i(_vm.isFullJob, null) > -1
                                : _vm.isFullJob,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.isFullJob,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isFullJob = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isFullJob = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isFullJob = $$c
                                  }
                                },
                                _vm.onFullJobchange,
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "label",
                            {
                              staticClass: "custom-control-label font-size",
                              attrs: { for: "full-job" + _vm.componentId },
                            },
                            [_vm._v("Full Job")]
                          ),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "pl-4" }, [
                    _c("div", { staticClass: "row" }, [
                      _c(
                        "label",
                        {
                          staticClass: "col-form-label-sm small mr-2",
                          attrs: { for: "progress-per-day-input" },
                        },
                        [_vm._v("Projected Stages Per Day")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "d-flex flex-grow-1" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "pr-2" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.filters.completionProgress.progressPerDay,
                              expression:
                                "filters.completionProgress.progressPerDay",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          staticStyle: { "max-width": "75px" },
                          attrs: {
                            type: "number",
                            id: "progress-per-day-input",
                            min: "0",
                            step: "0.5",
                          },
                          domProps: {
                            value:
                              _vm.filters.completionProgress.progressPerDay,
                          },
                          on: {
                            input: [
                              function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(
                                  _vm.filters.completionProgress,
                                  "progressPerDay",
                                  $event.target.value
                                )
                              },
                              _vm.handleFilterchange,
                            ],
                          },
                        }),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "pr-2" }, [
                        _vm.job.stagescompletedprojection != null &&
                        _vm.hasProjectionSetPermissions &&
                        _vm.projectedValueUpdated
                          ? _c(
                              "button",
                              {
                                ref: "save-default-value",
                                staticClass: "btn btn-primary btn-sm",
                                attrs: { id: "save-default-value" },
                                on: { click: _vm.saveDefault },
                              },
                              [_vm._v("Save As Job Default")]
                            )
                          : _vm._e(),
                      ]),
                    ]),
                  ]),
                ]),
              ]),
            ]
          )
        : _vm.selectedType == "kpi-lateral"
        ? _c(
            "div",
            {
              key: _vm.renderLateralLengthChange,
              style: { height: this.chartContentHeight + "px" },
            },
            [
              _c("bar-chart", {
                ref: "kpi-lateral",
                style: { position: "relative", height: "99%", width: "100%" },
                attrs: {
                  "chart-data": _vm.kpi.lateralLengthPerDay,
                  options: _vm.options.lateralLengthPerDay,
                },
              }),
            ],
            1
          )
        : _vm.selectedType == "kpi-swapTime" && _vm.isSwapTimeFeature
        ? _c(
            "div",
            {
              key: _vm.renderSwapTimeChange,
              style: { height: (this.chartContentHeight || 100) + "px" },
            },
            [
              _c("scatter-line-chart", {
                ref: "kpiSwaptime",
                style: { position: "relative", height: "99%", width: "100%" },
                attrs: {
                  "chart-data": _vm.kpi.swapTimes,
                  options: _vm.options.swapTimes,
                },
              }),
            ],
            1
          )
        : _vm.selectedType == "kpi-metricsPerStage"
        ? _c(
            "div",
            {
              key: _vm.renderMetricsPerStageChange,
              style: { height: (this.chartContentHeight || 100) + "px" },
            },
            [
              _c("bar-chart", {
                ref: "kpiMetricsPerStage",
                style: { position: "relative", height: "99%", width: "100%" },
                attrs: {
                  "chart-data": _vm.kpi.metricsPerStage,
                  options: _vm.options.metricsPerStage,
                },
              }),
            ],
            1
          )
        : _c(
            "div",
            {
              key: _vm.renderPumpTimeHoursChange,
              style: { height: this.chartContentHeight + "px" },
            },
            [
              _c("bar-chart", {
                ref: "pumpTimeChart",
                style: { position: "relative", flex: 1, height: "99%" },
                attrs: {
                  "chart-data": _vm.kpi.pumpTimes,
                  options: _vm.options.pumpTimes,
                },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _vm.showNoPumpHourNotification
        ? _c(
            "div",
            [
              _c(
                "b-alert",
                { attrs: { show: "", dismissible: "", variant: "warning" } },
                [
                  _c("b", [
                    _vm._v(
                      "No pump hour data! Job has no clean rate stream to perform data processing."
                    ),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._isIterable(_vm.errors)
        ? _vm._l(_vm.errors, function (error) {
            return _c("div", { staticClass: "danger-text-color text-center" }, [
              _vm._v(
                "\n            " +
                  _vm._s(error?.message || error) +
                  "\n        "
              ),
            ])
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "float-left pr-1 ml-1",
        staticStyle: { "text-align": "center", "font-size": "smaller" },
      },
      [_vm._v("Swap Time"), _c("br"), _vm._v("Minimum:")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "float-left pr-1 ml-1",
        staticStyle: { "text-align": "center", "font-size": "smaller" },
      },
      [_vm._v("Swap Time"), _c("br"), _vm._v("Maximum:")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "float-left pr-1 ml-1",
        staticStyle: { "text-align": "center", "font-size": "smaller" },
      },
      [_vm._v("Treatment of Zero "), _c("br"), _vm._v(" Values in Analytics:")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }