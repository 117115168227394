<template>
    <div style="padding: 20px;"> 
        <!-- Filter Modal -->
        <!-- <modal title="Filter"  :showModal="filterModal"  @close="filterModal = false" style="width: 40% !important; max-width: 30em !important; max-height: 400px;">
            <template #content style="overflow: visible !important;  max-height: 200px !important; width: 40% !important; max-width: 30em !important; max-height: 400px;">
                <div>Select the date and time range you wish to filter for</div>
                <div class="d-flex my-2" style="border: 1px solid #626770; border-radius: 5px;">
                    
                    <div class="mr-2" style="margin-left:15px; margin-right:30px;   padding: 10px;">
                        <div>Start Date</div>
                        <input type="datetime-local" class="date-select pr-1" placeholder="Select start date and time"
                            :value="filterStartTime"
                            @input="event => filterStartTime = event.target.value"
                            style="width: 110% !important;padding: 10px"

                        />
                    </div>
                    <div  style="padding: 10px; margin-right: 30px;">
                        <div>End Date</div>
                        <input type="datetime-local" class="date-select pr-1"  placeholder="Select end date and time"
                            :value="filterEndTime"
                            @input="event => filterEndTime = event.target.value"
                            style="width: 110% !important;padding: 10px"
                            />
                    </div>
                </div>
                <div>Select Cameras</div>
            

                <multiselect  v-model="filterCams" :options="cameras" label="name" :multiple="true" style="background: #1d2025 !important; background-color: #1d2025 !important;"/>
            </template>
        </modal> -->
            
        <!-- Clip Modal-->
        <iws-modal
            :show-modal="modalShow"
            max-width="calc(550px + 3em)"
            max-height="100%"
            @close="modalShow = false"
        >
            <template #title>
                Preview  {{ currentClip.cameraName }} - {{ currentClip.description }}
            </template>
            <template #content>
                <video :key="currentClip.url" controls autoplay>
                    <source :src="currentClip.url" type="video/mp4">
                </video>

                <div v-if="currentClip.type == 'Manual'" class="badge badge-pill my-2 badge-clip" style="font-size: 16px;">
                    Clip
                </div>
                <div v-else class="badge badge-pill my-2 badge-alert" style="font-size: 16px;">
                    Redzone
                </div>

                <div style="font-size: 16px;">
                    <b>Camera</b>: {{ currentClip.cameraName }} <br>
                    <b>Date</b>: {{ getDate(currentClip) }} <br>
                    <b>Duration</b>: {{ getDuration(currentClip) }}
                </div>
            </template>
            <template #footer>
                <span style="position: absolute; left: 1rem;">
                    <iws-button
                        text="Delete"
                        type="danger"
                        prepend-icon="fas fa-trash-alt"
                        :click="() => deleteClip(currentClip)"
                    />
                    <iws-button 
                        :text="`${currentClip.type == falseAlarmCode ? 'Unflag' : 'Flag'} False Positive`" 
                        :type="currentClip.type == falseAlarmCode ? 'warning' : 'success'" 
                        prepend-icon="far fa-flag" 
                        :click="() => flagClip(currentClip)" 
                        class="ml-3"
                    />
                </span>

                <iws-button text="Close" type="outline-light" @click="modalShow = false" />   
            </template>
        </iws-modal>

        <camera-navigation :user="user" :device="device" :work-order-id="workOrderId" isClips style="width: calc(100vw - 25px)" is-home />

        <div class="full-width mb-4">
            <!--NOTE:  when implementing Filters uncomment -->
            <!-- <button @click="filterModal=true" class="btn  btn-outline-light d-inline-flex align-items-center mr-3"  style="background-color: #1d2025; border: solid 1px #626973; color: #f0f0f0;">
                <i class="fas fa-filter pr-2"></i> 
                <span>Filters</span>
            </button>  -->
            <iws-select
                :options="[
                    { title: 'Group by : Midnight to Midnight', value: 'midnight'},
                    { title: 'Group by : Shift to Shift', value: 'shift'}
                ]"
                :value.sync="groupBy"
                display-name="title"
                value-name="value"
                @change="mapClips()"
                style="width:300px; display: inline-block;"
                :searchable="false"
            />
            
            <iws-search
                :value.sync="searchInput" 
                @input="mapClips()"
                style="display: inline-block;"
                class="ml-3"
            />
    
            <iws-checkbox
                label="Show False Positives"
                :value.sync="showFalsePositives"
                @change="mapClips()"
                enable-click-label
                style="display: inline-block;"
                class="ml-3"
            />
        </div>

        <div v-if="mappedShifts == null" class="center-container">
            <b-spinner large class="align-middle"></b-spinner>
        </div>
        <h1 v-else-if="Object.keys(mappedShifts || {})?.length == 0" class="center-container danger-text-color">
            No Clips Recorded Yet
        </h1>
        <div v-else v-for="shift in Object.keys(mappedShifts)" class="shift" :key="`${shift}_${mappedShifts[shift]?.length}`">
            <div class="shift-title">
                {{ groupBy == 'shift' ? `${shift} Shift`: shift }} ({{ mappedShifts[shift]?.length || 0 }})
            </div>
            
            <div class="clipSet">    
                <div v-for="clip in mappedShifts[shift]" @click="openModal(clip)" class="card clip-card clickable position-relative">
                    <img class="clipImage" :src="clip.thumbnailURL" alt="Card image cap">

                    <div class="card-body">
                        <h6 class="card-title">
                            <b>{{ clip.cameraName }} - {{ clip.description }}</b>
                        </h6>
                        <p class="clip-details">
                            <b>Taken at</b>: {{ getDate(clip, false) }}
                        </p>
                        <p class="clip-details">
                            <b>Duration</b>: {{ getDuration(clip) }}
                        </p>
                        
                        <iws-badge v-if="clip.type == 'Manual'" type="success" text="Clip" class="position-absolute bottom-left" />
                        <iws-badge v-else type="danger" text="Redzone" class="position-absolute bottom-left" />

                        <iws-badge v-if="clip.type == falseAlarmCode" type="success" text="False Positive" class="position-absolute bottom-right" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
// import Multiselect from 'vue-multiselect'
import DateFunctions from '../../DateFunctions';
import GlobalFunctions from '../../GlobalFunctions.js';
const { toast, isFalsy, isNullOrEmpty } = GlobalFunctions;
const { applyTimeOffset } = DateFunctions;
const Moment = require('moment');

export default{
    // components: { Multiselect },
    props: [ 'user', 'device', 'workOrderId', 'devices','hourOffset'],

    data() {
        return {
            falseAlarmCode: 'AIRedzone_FalsePositive',

            modalShow: false,
            refreshInterval: null,
            allClips: [],       // [ <ClipData>, <ClipData>,... ]
            mappedShifts: null, // { <shiftDate>: [ <ClipData>, <ClipData>,...] }

            currentClip: null,

            groupBy: 'midnight',
            searchInput: null,
            showFalsePositives: false,

            filterModal: false,
            filterStartTime: null,
            filterEndTime: null,
            cameras: [],
            filterCams: null
        }
    },

    methods: {
        getDuration(clipData){
            const time = clipData.durationInMinutes
            const hours = Math.floor(time/60)
            const seconds = Math.round((time - Math.floor(time)) *  60)  // retrieve just the decimal form
            const minutes =  Math.floor(time) - (hours * 60) 

            const strHours = hours.toString().padStart(2,'0');
            const strMinutes = minutes.toString().padStart(2,'0');
            const strSeconds = seconds.toString().padStart(2,'0');

            return `${strHours}:${strMinutes}:${strSeconds}`
        },
        getDate(clipData, includeDate = true){
            if (includeDate)
                return applyTimeOffset(clipData.startTime, this.hourOffset, "MM-DD-YYYY [at] HH:mm:ss");
            return applyTimeOffset(clipData.startTime, this.hourOffset, "HH:mm:ss");
        },

        mapClips(clips = [ ...this.allClips ]) {
            if (isNullOrEmpty(clips))
                return {};

            const searchKey = this.searchInput?.toLowerCase();
            const dateFormat = this.groupBy == 'shift' ? 'MM/DD/YYYY A' : 'MM/DD/YYYY';
            const groupedClips = {};

            clips = clips.filter(clip => {
                // If not showing false positives (the default) but this clip is flagged, filter it
                if (!this.showFalsePositives && clip.type == this.falseAlarmCode)
                    return false;
                
                // If a search key is entered but exists in neither the name or description (non case sensative), filter it
                if (!isFalsy(searchKey) && !clip?.description?.toLowerCase()?.includes(searchKey) && !clip?.cameraName?.toLowerCase()?.includes(searchKey))
                    return false;
                
                // Passed conditions
                return true;
            });
            
            // Sort all clips pushing the most recent clips to the front
            clips.sort((x, y) => new Date(y.startTime) - new Date(x.startTime));
            
            // Split all provided clips by the shift they fall under
            clips.forEach(clip => {
                const shiftKey = applyTimeOffset(clip.startTime, this.hourOffset, dateFormat);
               
                if (shiftKey in groupedClips) {
                    groupedClips[shiftKey].push(clip);
                } else {
                    groupedClips[shiftKey] = [ clip ];
                }
            });

            this.mappedShifts = groupedClips;
            return groupedClips;
        },
        fetchClips() {
            return axios.get(`/cameras/jobClips/${this.workOrderId}`).then(response => {
                this.allClips = response?.data || [];
                this.mapClips();
            }).catch(error => {
                console.error(error);
            });
        },

        deleteClip(clip) {
            return GlobalFunctions.iwsConfirm({
                title: 'Confirm Clip Deletion',
                body: 'Are you sure you want to delete the current clip?'
            }).then(_answer => {
                if (_answer) {
                    return axios.delete(`/cameras/jobClips/${clip.workOrderId}/${clip.id}`).then(response => {
                        this.allClips = response?.data || [];
                        this.mapClips();
                        this.hideModal();

                        toast({
                            title: 'Clip deleted successfully',
                            variant: 'success'
                        });
                    }).catch(error => {
                        console.error(error);
                        toast({
                            title: error,
                            variant: 'danger'
                        });
                    });
                }
            })
        },
        flagClip(clip) {
            const toFalsePositive = clip.type != this.falseAlarmCode;

            return GlobalFunctions.iwsConfirm({
                title: 'Confirm Flag',
                body: `Are you sure you want to ${toFalsePositive ? 'flag' : 'unflag'} this clip as a false positive?`
            }).then(_answer => {
                if (_answer) {
                    return axios.post(`/cameras/flag/${clip.workOrderId}/${clip.id}/vodClips`, { flag: toFalsePositive }).then(_ => {
                        toast({
                            title: 'Clip flagged successfully',
                            variant: 'success'
                        });

                        // Reflect changes to clips list before closing modal
                        return this.fetchClips().then(this.hideModal);
                    }).catch(error => {
                        toast({
                            title: error,
                            variant: 'danger'
                        });
                    });
                }
            });
        },

        openModal(clipData){
            this.currentClip = clipData;
            this.modalShow = true;
        },
        hideModal(){
            this.modalShow = false;
        },

        startInterval() {
            if (this.refreshInterval == null)
                this.refreshInterval = setInterval(this.fetchClips, 10000);
        },
        stopInterval() {
            if (this.refreshInterval != null) {
                clearInterval(this.refreshInterval);
                this.refreshInterval = null;
            }
        },
    },

    created() {
        this.fetchClips();
        this.startInterval();
    },
    beforeDestroy() {
        this.stopInterval();
    },
    destroyed() {
        this.stopInterval();
    }
}
</script>

<style scoped>
.center-container {
    display:flex;
    height: 100%;
    width: auto;
    justify-content: center;
    margin-top: 130px;
}

.clip-search{
    background-color: #1d2025;
    border: solid 1px #626973;
    color: #ffffff;
}

.clipModal{
    background-color: #2e353d !important;
}

.shift-title{
    background-color: #2e353d;
    border-radius: 5px;
    padding: 10px;
    padding-left: 25px;
    font-family: Arial, Helvetica, sans-serif;
}

.clipSet {
    display: flex; 
    flex-wrap: wrap; 
    justify-content: flex-start; 
    align-items: flex-start; 
    margin: 20px;
}
.breadcrumb{
    font-size: 17px !important;
}
.clip-card{
    width: 190px;
    height: 235px;
    background-color:#1d2939;
    border-radius: 5px;
    border: solid 1px #3b414b;
    margin: 6px;
}
.clip-card>image {
    width: 100%;
    height: auto;
    border-radius: 5px 5px 0px 0px ;
}
.clip-card:hover{
    background-color: #002A58;
    box-shadow: rgba(0, 0, 0, 0.788) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
    transition: 0.5s;
    border: solid 1px #a0a4aa;
    border-radius: 5px;

}
.clip-details {
    font-size: 13px !important;
    margin-bottom: 2px;
    font-variant-numeric: tabular-nums;
    font-family: Arial, Helvetica, sans-serif;
}

.badge-clip {
    background-color: #166b4a;
    border: 1px solid #12b76a;
    padding-top: 2px;
    padding-bottom: 2px;
    font-family:Arial, Helvetica, sans-serif;
}
.badge-alert {
    background-color: #96241f;
    border: 1px solid #f04438;
    padding-top: 2px;
    padding-bottom: 2px;
    font-family:Arial, Helvetica, sans-serif;
}

.card-body{
	padding: 5px;
}

/* .date-select{
    padding: 8px;
    border-radius: 5px;
    color:white;
    background-color: #242a30;
    border: solid #626973 1px;
} */

video {
    border-radius: 5px;
    width: 100%;
}
</style>