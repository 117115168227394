var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.show
    ? _c(
        "div",
        {
          staticClass: "ticket-status-filter-modal dark-modal modal show",
          on: {
            click: function ($event) {
              if ($event.target !== $event.currentTarget) return null
              return _vm.close.apply(null, arguments)
            },
          },
        },
        [
          _c(
            "div",
            { staticClass: "modal-dialog", attrs: { role: "document" } },
            [
              _c("div", { staticClass: "modal-content" }, [
                _c("div", { staticClass: "modal-header" }, [
                  _c("h5", { staticClass: "modal-title" }, [
                    _vm._v("Filter Tickets"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "close",
                      attrs: { type: "button" },
                      on: { click: _vm.close },
                    },
                    [
                      _c("span", { attrs: { "aria-hidden": "true" } }, [
                        _vm._v("×"),
                      ]),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _vm.options
                  ? _c(
                      "div",
                      { staticClass: "modal-body pb-5" },
                      [
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.incidentNumber,
                            options: _vm.options.incidentNumber,
                            "filter-key": "incidentNumber",
                            label: "Incident Number",
                            "display-name": "label",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.title,
                            options: _vm.options.title,
                            "filter-key": "title",
                            label: "Ticket Title",
                            "display-name": "label",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.workOrder,
                            options: _vm.options.workOrder,
                            "filter-key": "workOrder",
                            label: "Work Order Number",
                            "display-name": "label",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.customerPadName,
                            options: _vm.options.customerPadName,
                            "filter-key": "customerPadName",
                            label: "Pad Name",
                            "display-name": "label",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.severity,
                            options: _vm.options.severity,
                            "display-name": "label",
                            "filter-key": "severity",
                            label: "Severity",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.priority,
                            options: _vm.options.priority,
                            "display-name": "label",
                            "filter-key": "priority",
                            label: "Priority",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.product,
                            options: _vm.options.product,
                            "display-name": "label",
                            "filter-key": "product",
                            label: "Product",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "px-3 pb-3 mt-3 border border-secondary rounded",
                          },
                          [
                            _c("div", { staticClass: "row" }, [
                              _c(
                                "div",
                                { staticClass: "col-md" },
                                [
                                  _c(
                                    "ticket-status-filter",
                                    {
                                      attrs: {
                                        filter: _vm.filters.start,
                                        "filter-key": "start",
                                        label: "Start Date",
                                      },
                                      on: {
                                        remove: function ($event) {
                                          _vm.startDate = null
                                        },
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: { mode: "date", "is-dark": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                inputValue,
                                                showPopover,
                                                hidePopover,
                                              }) {
                                                return [
                                                  _c("input", {
                                                    staticClass:
                                                      "form-control ticket-status-filter__input",
                                                    attrs: {
                                                      placeholder:
                                                        "Select Start Date",
                                                    },
                                                    domProps: {
                                                      value: inputValue,
                                                    },
                                                    on: {
                                                      focusin: showPopover,
                                                      focusout: hidePopover,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          683220011
                                        ),
                                        model: {
                                          value: _vm.startDate,
                                          callback: function ($$v) {
                                            _vm.startDate = $$v
                                          },
                                          expression: "startDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-md" },
                                [
                                  _c(
                                    "ticket-status-filter",
                                    {
                                      attrs: {
                                        filter: _vm.filters.end,
                                        "filter-key": "end",
                                        label: "End Date",
                                      },
                                      on: {
                                        remove: function ($event) {
                                          _vm.endDate = null
                                        },
                                      },
                                    },
                                    [
                                      _c("v-date-picker", {
                                        attrs: { mode: "date", "is-dark": "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "default",
                                              fn: function ({
                                                inputValue,
                                                showPopover,
                                                hidePopover,
                                              }) {
                                                return [
                                                  _c("input", {
                                                    staticClass:
                                                      "form-control ticket-status-filter__input",
                                                    attrs: {
                                                      placeholder:
                                                        "Select End Date",
                                                    },
                                                    domProps: {
                                                      value: inputValue,
                                                    },
                                                    on: {
                                                      focusin: showPopover,
                                                      focusout: hidePopover,
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          false,
                                          1266179012
                                        ),
                                        model: {
                                          value: _vm.endDate,
                                          callback: function ($$v) {
                                            _vm.endDate = $$v
                                          },
                                          expression: "endDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ]
                        ),
                        _vm._v(" "),
                        _c("ticket-status-filter", {
                          attrs: {
                            filter: _vm.filters.currentOwner,
                            options: _vm.options.currentOwner,
                            "display-name": "label",
                            "filter-key": "currentOwner",
                            label: "Current Owner",
                          },
                          on: {
                            change: _vm.handleChange,
                            remove: _vm.removeFilter,
                          },
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "row" }, [
                          _c(
                            "div",
                            { staticClass: "col-md" },
                            [
                              _c("ticket-status-filter", {
                                attrs: {
                                  filter: _vm.filters.createdBy,
                                  options: _vm.options.createdBy,
                                  "display-name": "label",
                                  "filter-key": "createdBy",
                                  label: "Created By",
                                },
                                on: {
                                  change: _vm.handleChange,
                                  remove: _vm.removeFilter,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-md" },
                            [
                              _c("ticket-status-filter", {
                                attrs: {
                                  filter: _vm.filters.reportedBy,
                                  options: _vm.options.reportedBy,
                                  "display-name": "label",
                                  "filter-key": "reportedBy",
                                  label: "Reported By",
                                },
                                on: {
                                  change: _vm.handleChange,
                                  remove: _vm.removeFilter,
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm.error
                  ? _c("div", { staticClass: "modal-body text-center p-4" }, [
                      _c("h4", [_vm._v("We've Encountered a Problem")]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(_vm.error))]),
                    ])
                  : _vm.loading
                  ? _c("div", { staticClass: "modal-body" }, [
                      _vm._v(
                        "\n                Loading data for fields...\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "modal-footer" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-outline btn-outline-light",
                      attrs: { type: "button" },
                      on: { click: _vm.close },
                    },
                    [_vm._v("\n                    Close\n                ")]
                  ),
                  _vm._v(" "),
                  _vm.error
                    ? _c(
                        "button",
                        {
                          staticClass: "btn primary-color btn-primary",
                          attrs: {
                            type: "button",
                            "data-toggle": "modal",
                            "data-target": "#supportModal",
                          },
                        },
                        [_vm._v("Contact Support")]
                      )
                    : _c(
                        "button",
                        {
                          staticClass: "btn primary-color btn-primary",
                          attrs: { type: "button" },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            "\n                    Apply Filters\n                "
                          ),
                        ]
                      ),
                ]),
              ]),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }