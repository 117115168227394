<template>
<div class="container-fluid flex-1" >
    <!-- <div class="h5">Perforation Report - Job# {{ jobNumber }}</div> -->
         <div class="page-title mt-2 mb-4 px-5 pt-0">
            Wireline Plug and Perf History
        </div>

        <div class="pt-3">
            <activity-alert-component
                :serverCall="updateAlerts"
            />
        </div>
        <div class="row justify-content-between pt-2 align-items-center" >
            <div class="col">
                <div style="display: inline-block;">
                    <select-component
                        :title="'Well: '"
                        :options="wells"
                        :on-change="onWellChanged"
                        :init-selected="wellName"
                        :isCustomClassEnabled="true"
                        :customClass="reportPageSelectStyle"
                    />
                </div>
                <div style="display: inline-block;" class="">
                    <b-button style='margin-top: 10px;' type="button" variant="primary" v-on:click="onClusterConfigPress">Spacing Alerts</b-button>
                </div>
            </div>
            <div class="col">
                <div style="display: inline-block;">
                    <select-component
                        ref="stageRangeStart"
                        title="Start:"
                        :options="validStages"
                        :on-change="onStartStageChanged"
                        :init-selected="startStage"
                        :isCustomClassEnabled="true"
                        :customClass="reportPageSelectStyle"
                    />
                </div>
                <div style="display: inline-block;">
                    <b-button style='margin-top: 10px;' type="button" variant="primary" :disabled="startStage == 1"
                               v-on:click="startStage = 1; onStartStageChanged(1); ">First stage</b-button>
                </div>
            </div>
            <div class="col">
                <div style="display: inline-block;">
                    <select-component
                        ref="stageRangeEnd"
                        title="End:"
                        :options="endStageCalculation"
                        :on-change="onEndStateChanged"
                        :init-selected="endStage"
                        :isCustomClassEnabled="true"
                        :customClass="reportPageSelectStyle"
                    />
                </div>
                <div style="display: inline-block;">
                    <b-button  style='margin-top: 10px;' type="button" variant="primary" :disabled="endStage == lastStage"
                               v-on:click="endStage = lastStage; onEndStateChanged(lastStage);">Last stage</b-button>
                </div>
            </div>
            <div class="col">
                <div class="row">
                    <div class="d-flex flex-column">
                        <div class="d-flex flex-row mt-2 w-100">
                            <a @click="()=>onExportPressed('exportPerf')" target="_blank" class="col">
                                <button class="border border-secondary rounded bg-transparent btn text-dark exportButton" style="line-height:1.2;height:60px;width:100%">
                                    Export Perforations
                                </button>
                            </a>
                            <a @click="()=>onExportPressed('exportPlug')" target="_blank" class="col">
                                <button class="border border-secondary rounded bg-transparent btn text-dark exportButton" style="line-height:1.2;height:60px;width:100%">
                                    Export Plugs
                                </button>
                            </a>
                            <a @click="()=>onExportPressed('exportBoth')" target="_blank" class="col">
                                <button class="border border-secondary rounded bg-transparent btn text-dark exportButton" style="line-height:1.2;height:60px;width:100%">
                                    Export Both
                                </button>
                            </a>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                            <select-component
                                ref="fileType"
                                title="Export File Type: "
                                :customTitleClass="fileExportTitleClass"
                                :options="fileExportOptions"
                                :on-change="onFileExportTypeChanged"
                                :init-selected="'XML'"
                                :isCustomClassEnabled="true"
                                :customClass="reportPageSelectStyle"
                                :displayPropName="'display'"
                                :propValue="'value'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="pb-2">
            <div class="h5 pb-1 pt-2">Job Information</div>
            <div class="row px-3 justify-content-between">
                <div class="col-4">
                    <div class="pl-3 row justify-content-start align-items-center">
                        <select-component
                            :title="'Contractor:'"
                            :options="['Select Contractor'].concat(contractors.map(c => c.name))"
                            :on-change="onContractorChanged"
                            :init-selected="defaultContractorId ? contractors.find((c) => c.id == defaultContractorId).name : 'Select Contractor'"
                            :isCustomClassEnabled="true"
                            :customClass="reportPageSelectStyle"
                        />
                        <div class="m-2 pt-1 align-items-center" v-show="isAdmin || isCompanyAdmin">
                            <input type="checkbox" id="defaultContractor" v-show="!sendingDefaultContractor" v-model="defaultContractorSelected" class="mr-1 ml-2" @change="onContractorDefaultChanged()">
                            <label v-show="!sendingDefaultContractor" for="defaultContractor">Default</label>
                            <span v-show="sendingDefaultContractor">Saving...</span>
                        </div>
                        <button type="button" class="btn btn-dark ml-3" @click="()=>onAddContractorPressed()"><i class="fas fa-plus"></i></button>
                    </div>
                </div>
                <div class="col-3 form-group" v-if="isAdmin || isIwsTech">
                    <div class="row justify-content-center flex flex-col w-full">
                        <button type="button" class="btn btn-primary" @click="checkMissedShots()">Check Missed Shots</button>
                        <button type="button" class="btn btn-primary mx-2" @click="clearMissedShotsAlerts()" v-if="isClearAlertsFeatureFlagEnabled">Clear Missing Shots Alerts</button>
                    </div>
                    <div class="row mt-2" style="height: 2vh;">
                        <div class="col">
                            <p v-show="checkMissedShotsSettings.showValidationBool"
                                :class="checkMissedShotsSettings.validationColorClass" class="validation-text">
                                {{checkMissedShotsSettings.validationText}}
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-2 px-1 text-right" style="margin-left: 8vw;">
                    <button type="button" :disabled="(!tableUpdated)" class="btn btn-secondary grey-button save-changes-buttons" v-on:click="()=>discardChanges()">Discard</button>
                </div>
                <div class="col-2 px-1 text-right" style="max-width: 160px;">
                    <button type="button" :disabled="(!tableUpdated)" class="btn btn-success green-button save-changes-buttons" v-on:click="()=>saveChanges()">Save changes</button>
                </div>
            </div>
        </div>

        <div v-if="!errorMessage"
            style="max-height: 70vh; display: flex; flexDirection: column">
            <div>
                <div class="pb-3">
                    <span>
                        <label class="mt-5 h5">Perforations</label>
                    </span>
                    <span v-if="!isFirstIntervalPerforationsPresent && !onFirstIntervalPressed && startStage == 1">
                        <button type="button" v-on:click="onFirstIntervalPressed = true" style="height: 50%" class="btn btn-primary ml-2 text-left">Enter 1st Interval Perforation Depths</button>
                    </span>
                    <span v-if="onFirstIntervalPressed">
                        <button type="button" v-on:click="onFirstIntervalPressed = false" style="height: 50%" class="btn btn-danger ml-2 text-left">Cancel 1st Interval Perforation Depths</button>
                    </span>
                    <span v-if="onFirstIntervalPressed">
                        <button type="button"  @click="saveWirelineRow" style="height: 100%" class="btn btn-primary ml-2 text-left">Save</button>
                    </span>
                </div>
            </div>
            <div v-if="onFirstIntervalPressed == true" class="data-table">
               <div class="d-flex flex-1">
                    <div ref="perforationsTableContainer" :style="{'max-height': maxTableHeight + 'px'}">
                        <table ref="perfTable" class="reportTable">
                            <thead ref="perfTableHead">
                                <tr style="z-index:1000; position:relative">
                                    <th v-for="(perfField, index) in updatedFirstPerfFields" v-bind:key="index" class="secondary-bg-dark">{{perfField}}</th>
                                    <th class="secondary-bg-dark">Depth Adjustment<br>(ft)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(perfRow, rowIndex) in rowPerfVals" ref="perfTableRow" v-bind:key="rowIndex">
                                    <td v-for="(perfValue, index) in perfRow.slice(0, EXTRA_INFO_INDEXES*-1)" v-bind:key="index">
                                        <div  class="disabled-cell" v-if="perfFields[index]==='Gun Left In Hole?'" style="background-color: white; border-top: 2px solid #919191; border-left: 2px solid #919191; border-right: 2px solid #dadada; border-bottom: 2px solid #dadada; ">
                                            <div style="right:0px">
                                                <select-component  :disabled="true"  :options="['Yes', 'No']" :on-change="(value)=>onGunDataChange( { target : { value : value }  } , index, rowIndex)" :init-selected="perfValue" style="padding: 1px;" :class="resolveStatusClass(perfRow)"/>
                                            </div>
                                        </div>
                                        <div v-else-if="perfFields[index]==='Alert'" class="d-flex flex-row" style="text-align:center;align-items:center;justify-content:center;">
                                            <span v-if="getAlertIconStatus(perfRow)===0" style="position:absolute;color:black;" class="p-1"><i class="fas fa-exclamation-circle" @click="()=>onCommentFocused({ value: perfValue, colIndex: index+1, rowIndex, type: 'shot', extraInfo: perfRow[perfRow.length-1], isAlertColumn: true})" ref="commentInputRef" style="cursor: pointer;"></i></span>
                                            <span v-if="getAlertIconStatus(perfRow)===1" style="position:absolute;color:black;" class="p-1"><i class="fas fa-check-circle" @click="()=>onCommentFocused({ value: perfValue, colIndex: index+1, rowIndex, type: 'shot', extraInfo: perfRow[perfRow.length-1], isAlertColumn: true})" ref="commentInputRef" style="cursor: pointer;"></i></span>
                                            <input :disabled='true' v-bind:value="perfValue" readonly @focus="()=>onCommentFocused({ value: perfValue, colIndex: index+1, rowIndex, type: 'shot', extraInfo: perfRow[perfRow.length-1], isAlertColumn: true})" ref="commentInputRef" :class="resolveStatusClass(perfRow)"/>
                                        </div>
                                        <div v-else-if="perfFields[index]==='Comment'" class="d-flex flex-row" style="text-align:center;align-items:center;justify-content:center;">
                                            <input :disabled="true" v-bind:value="perfValue" readonly @focus="()=>onCommentFocused({ value: perfValue, colIndex: index, rowIndex, type: 'shot'})" ref="commentInputRef" :class="resolveStatusClass(perfRow)"/>
                                        </div>
                                        <modified-input v-else-if="perfFields[index]==='Top Depth (ftKB)'" :modelModifiers="['round']" :modelValue="rowPerfVals[rowIndex][index]" v-on:update="(value)=>onValueInputChange(value, index, rowIndex)" :disabled="(gunDisabledFirstIntervalIndexes.includes(index) || perfRow[perfRow.length-1].alertType)" :class="resolveStatusClass(perfRow) && highlightFailingFields(rowIndex, index)"/>
                                        <input v-else v-model="rowPerfVals[rowIndex][index]" v-on:change="(value)=>onValueInputChange(value, index, rowIndex)" :disabled="(gunDisabledFirstIntervalIndexes.includes(index) || perfRow[perfRow.length-1].alertType)" :class="resolveStatusClass(perfRow) && highlightFailingFields(rowIndex, index)"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div v-if="perfValues.length > 0" class="data-table">
               <div class="d-flex flex-1">
                    <div ref="perforationsTableContainer" :style="{'max-height': maxTableHeight + 'px'}">
                        <table ref="perfTable" class="reportTable">
                            <thead ref="perfTableHead">
                                <tr style="z-index:1000; position:relative">
                                    <th v-for="(perfField, index) in perfFields" v-bind:key="index" class="secondary-bg-dark">{{perfField}}</th>
                                    <th class="secondary-bg-dark">Depth Adjustment<br>(ft)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(perfRow, rowIndex) in perfValues" ref="perfTableRow" v-bind:key="rowIndex" @click="()=>onRowClicked(perfRow, rowIndex, 'shot')">
                                    <td v-for="(perfValue, index) in perfRow.slice(0, EXTRA_INFO_INDEXES*-1)" v-bind:key="index">
                                        <div v-if="perfFields[index]==='Gun Left In Hole?'" style="background-color: white; border-top: 2px solid #919191; border-left: 2px solid #919191; border-right: 2px solid #dadada; border-bottom: 2px solid #dadada; ">
                                            <div style="right:0px">
                                                <select-component  :disabled="(perfRow[perfRow.length-1].alertType || perfRow[perfRow.length-1].alertType || onFirstIntervalPressed)"  :options="['Yes', 'No']" :on-change="(value)=>onGunDataChange( { target : { value : value }  } , index, rowIndex)" :init-selected="perfValue" style="padding: 1px;" :class="resolveStatusClass(perfRow)"/>
                                            </div>
                                        </div>
                                        <div v-else-if="perfFields[index]==='Alert' && !onFirstIntervalPressed" class="d-flex flex-row" style="text-align:center;align-items:center;justify-content:center;">
                                                <span v-if="getAlertIconStatus(perfRow)===0" style="position:absolute;color:black;" class="p-1"><i class="fas fa-exclamation-circle" @click="()=>onCommentFocused({ value: perfValue, colIndex: index+1, rowIndex, type: 'shot', extraInfo: perfRow[perfRow.length-1], isAlertColumn: true})" ref="commentInputRef" style="cursor: pointer;"></i></span>
                                                <span v-if="getAlertIconStatus(perfRow)===1" style="position:absolute;color:black;" class="p-1"><i class="fas fa-check-circle" @click="()=>onCommentFocused({ value: perfValue, colIndex: index+1, rowIndex, type: 'shot', extraInfo: perfRow[perfRow.length-1], isAlertColumn: true})" ref="commentInputRef" style="cursor: pointer;"></i></span>
                                                <input :disabled="onFirstIntervalPressed" v-bind:value="perfValue" readonly @focus="()=>onCommentFocused({ value: perfValue, colIndex: index+1, rowIndex, type: 'shot', extraInfo: perfRow[perfRow.length-1], isAlertColumn: true})" ref="commentInputRef" :class="resolveStatusClass(perfRow)"/>
                                        </div>
                                        <div v-else-if="perfFields[index]==='Comment'" class="d-flex flex-row" style="text-align:center;align-items:center;justify-content:center;">
                                                <input :disabled="onFirstIntervalPressed" v-bind:value="perfValue" readonly @focus="()=>onCommentFocused({ value: perfValue, colIndex: index, rowIndex, type: 'shot'})" ref="commentInputRef" :class="resolveStatusClass(perfRow)"/>
                                        </div>
                                        <modified-input v-else-if="perfFields[index]==='Top Depth (ftKB)' || perfFields[index]==='Bottom Depth (ftKB)'" :modelModifiers="['round']" :modelValue="perfValues[rowIndex][index]" v-on:update="(value)=>onGunDataChange(value, index, rowIndex)" :disabled="(gunDisabledIndexes.includes(index) || perfRow[perfRow.length-1].alertType || onFirstIntervalPressed)" :class="resolveStatusClass(perfRow)"/>
                                        <input v-else v-bind:value="perfValue" v-on:change="(value)=>onGunDataChange(value, index, rowIndex)" :disabled="(gunDisabledIndexes.includes(index) || perfRow[perfRow.length-1].alertType || onFirstIntervalPressed)" :class="resolveStatusClass(perfRow)"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else-if="perfValues.length < 1 && plugValues.length < 1" class="text-center">
                <label class="mt-5 h5 text-danger">No wireline activity found with current filters</label>
            </div>
            <div v-else>
                No perforations found
            </div>

            <div>
                <div class="pb-3">
                    <span>
                        <label class="mt-5 h5">Plugs</label>
                    </span>
                    <span v-if="!isFirstIntervalPlugsPresent && !onFirstIntervalPlugPressed && startStage == 1">
                        <button type="button" v-on:click="onFirstIntervalPlugPressed = true" style="height: 50%" class="btn btn-primary ml-2 text-left">Enter PBTD</button>
                    </span>
                    <span v-if="onFirstIntervalPlugPressed">
                        <button type="button" v-on:click="onFirstIntervalPlugPressed = false" style="height: 50%" class="btn btn-danger ml-2 text-left">Cancel PBTD</button>
                    </span>
                    <span v-if="onFirstIntervalPlugPressed">
                        <button type="button" @click="saveWirelineRowPlug" style="height: 100%" class="btn btn-primary ml-2 text-left">Save</button>
                    </span>
                </div>
            </div>
            <div v-if="onFirstIntervalPlugPressed == true" class="d-flex data-table">
                <div ref="plugTableContainer" :style="{'max-height': maxTableHeight + 'px'}">
                    <table ref="plugTable" class="reportTable">
                        <thead ref="plugTableHead">
                            <tr>
                                <th v-for="(plugField, index) in updatedFirstPlugFields" v-bind:key="index" class="secondary-bg-dark">{{plugField}}</th>
                                <th class="secondary-bg-dark">Depth Adjustment<br>(ft)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(plugRow, rowIndex) in rowPlugVals" ref="plugTableRow" v-bind:key="rowIndex">
                                <td v-for="(plugValue, index) in plugRow.slice(0, EXTRA_INFO_INDEXES*-1)" v-bind:key="index">
                                <div v-if="plugFields[index]==='Interval Number'" style="width:10em"></div>
                                    <input
                                        :class="findPlugClass(plugRow[plugRow.length-1])"
                                        v-if="plugFields[index]==='Comments'"
                                        v-bind:value="plugValue"
                                        v-on:change="(value)=>onPlugDataChange(value, index, rowIndex)"
                                        @focus="()=>onCommentFocused({ value: plugValue, colIndex: index, rowIndex, type: 'plug', extraInfo: plugRow[plugRow.length-1]})"
                                        :disabled="true"
                                        ref="commentInputRef"
                                        readonly
                                    />
                                    <modified-input v-else-if="plugFields[index]==='Top Depth (ftKB)' || plugFields[index]==='Bottom Depth (ftKB)'" :modelModifiers="['round']" :modelValue="rowPlugVals[rowIndex][index]" v-on:update="(value)=>onValueInputChangePlug(value, index, rowIndex)" :disabled="(plugDisabledFirstIntervalIndexes.includes(index) || plugRow[plugRow.length-1].alertType)" :class="findPlugClass(plugRow[plugRow.length-1]) && highlightFailingFieldsPlugs(rowIndex, index)"/>
                                    <input v-else :class="findPlugClass(plugRow[plugRow.length-1]) && highlightFailingFieldsPlugs(rowIndex, index)" v-model="rowPlugVals[rowIndex][index]" v-on:change="(value)=>onValueInputChangePlug(value, index, rowIndex)" :disabled="(plugDisabledFirstIntervalIndexes.includes(index) || plugRow[plugRow.length-1].alertType)"/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div v-if="plugValues.length > 0" class="flex-1 data-table">
                <div class="d-flex">
                    <div ref="plugTableContainer" :style="{'max-height': maxTableHeight + 'px'}">
                        <table ref="plugTable" class="reportTable">
                            <thead ref="plugTableHead">
                                <tr>
                                    <th v-for="(plugField, index) in plugFields" v-bind:key="index" class="secondary-bg-dark">{{plugField}}</th>
                                    <th class="secondary-bg-dark">Depth Adjustment<br>(ft)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(plugRow, rowIndex) in plugValues" ref="plugTableRow" v-bind:key="rowIndex" @click="onRowClicked(plugRow, rowIndex, 'plug')">
                                    <td v-for="(plugValue, index) in plugRow.slice(0, EXTRA_INFO_INDEXES*-1)" v-bind:key="index">
                                        <div v-if="plugFields[index]==='Interval Number'" style="width:10em"></div>
                                        <input
                                            :class="findPlugClass(plugRow[plugRow.length-1])"
                                            v-if="plugFields[index]==='Comments'"
                                            v-bind:value="plugValue"
                                            v-on:change="(value)=>onPlugDataChange(value, index, rowIndex)"
                                            @focus="()=>onCommentFocused({ value: plugValue, colIndex: index, rowIndex, type: 'plug', extraInfo: plugRow[plugRow.length-1]})"
                                            :disabled="plugDisabledIndexes.includes(index) || onFirstIntervalPlugPressed"
                                            ref="commentInputRef"
                                            readonly
                                        />
                                        <modified-input v-else-if="plugFields[index]==='Top Depth (ftKB)' || plugFields[index]==='Bottom Depth (ftKB)'" :modelModifiers="['round']" :modelValue="plugValues[rowIndex][index]" v-on:update="(value)=>onPlugDataChange(value, index, rowIndex)" :disabled="(plugDisabledIndexes.includes(index) || plugRow[plugRow.length-1].alertType || onFirstIntervalPlugPressed)" :class="findPlugClass(plugRow[plugRow.length-1])"/>
                                        <input v-else :class="findPlugClass(plugRow[plugRow.length-1])" v-bind:value="plugValues[rowIndex][index]" v-on:change="(value)=>onPlugDataChange(value, index, rowIndex)" :disabled="(plugDisabledIndexes.includes(index) || plugRow[plugRow.length-1].alertType || onFirstIntervalPlugPressed)"/>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <div v-else-if="!(perfValues.length < 1 && plugValues.length < 1)">
                No plugs found
            </div>
        </div>
        <div v-else-if="errorMessage" class="text-center">
            <label style="font-size: 1.5em;" class="mt-5 h6">{{errorMessage + ':'}}</label>
            <a :href="`/toolstring_config/${jobNumber}/${wellIndex}`" class="link-primary">
                <span style="text-decoration : underline; font-size: 1.5em;">set stage 1 toolstring to proceed</span>
            </a>
        </div>

        <cluster-spacing-config
            ref="clusterConfig"
            :wells="wellsData"
            :currentWell="selectedWellId"
            :isCompanyAdmin="isCompanyAdmin"
            :isAdmin="isAdmin"
            :iwsUser="iwsUser"
            :jobNumber="jobNumber"
            :currentWellData="selectedWell"
            :clusterInfo="allClusterInfo"
        />
        <wireline-comment-modal
            :jobNumber="jobNumber"
            :value="commentInfo.currentComment"
            :gunNumber="commentInfo.gunNumber"
            :stageNumber="commentInfo.stageNumber"
            :isTableChanged="isTableChanged"
            :wellIndex="commentInfo.wellIndex"
            :type="commentInfo.type"
            :rowIndex="commentInfo.rowIndex"
            :colIndex="commentInfo.colIndex"
            :wellName="wellName"
            :lastShotDepth="commentInfo.lastShotDepth"
            :clusterInfo="clusterInfo"
            :actualSpacing="commentInfo.actualSpacing"
            :wirelineId="commentInfo.wirelineId"
            :wirelineDepth="commentInfo.depth"
            :isAlert="commentInfo.isAlert"
            :shotSheetReferenced="commentInfo.shotSheetReferenced? true:false"
            ref="wirelineComment"
            @onSavePressed="onSavePressed"
        />
        <resolve-shot-modal :isVisible="showShotResolveModal" v-bind="shotResolveModalProps" @onDismiss="()=>{ showShotResolveModal = false }" @onSubmit="onShotResolved" @onDeclineSubmit="onShotDeclined"/>
        <critical-alert-modal-component :jobNumber="jobNumber"/>
    </div>
</template>
<style scoped>
    .critical-alert  {
        background-color: #ff3b3b !important;
    }

    .disabled-cell {
         background-color: #B8B9BA !important;
    }
    .data-table {
        overflow:auto;
        min-height: 100px;
        width:100%;
    }
    .exportButton {
        min-width:115px;
    }
    .validation-text {
        font-size: 0.7rem;
        white-space: wrap;
        text-align: center;
        margin: 0;
        padding: 0;
    }
    @media (max-width: 1280px) {
        .green-button {
            min-width: 70px;
        }
        .grey-button {
            min-width: 70px;
        }
    }

</style>

<style>
    select.form-control {
            cursor: pointer;
        }
</style>


<script>

import GlobalFunctions  from '../GlobalFunctions.js';
const { toast, isFeatureFlagged } = GlobalFunctions;
import _ from 'lodash';
import moment from 'moment';


let INIT_PREF_VALUES = [];
let INIT_PLUG_VALUES = [];

export default {
    data() {
        return {
            fileExportType: 'XML',
            fileExportOptions: [
                {
                    display: 'WVXML',
                    value: 'XML'
                },
                {
                    display: 'CSV',
                    value: 'CSV'
                }
            ],
            depthErrorIndices: [],
            dateErrorIndices: [],
            timeErrorIndices: [],
            depthErrorIndicesPlugs: [],
            dateErrorIndicesPlugs: [],
            timeErrorIndicesPlugs: [],
            fieldValidationFailed: null,
            fieldValidationFailedPlugs: null,
            isNewGunPlugData: false,
            onFirstIntervalPressed: false,
            onFirstIntervalPlugPressed: false,
            showShotResolveModal: false,
            shotResolveModalProps: {},
            EXTRA_INFO_INDEXES: 1, //last column provide wireline id, gun length and row color
            loadTimeUTC: moment.utc().valueOf(),
            commentInfo: {
                currentComment: '',
                gunNumber: null,
                stageNumber: null,
                wellIndex: null,
                type: null,
                lastShotDepth: null,
                stageSpacing: null,
                stageSpacingTolerance: null
            },
            changedValuesArray: [],
            messagesArray: null,
			visibleTableRows: 7,
			tableRowHeight: null,
            tableUpdated: false,
            resolveStatus: Vue.util.extend([], this.propResolveStatus),
            isVisibleCommentModal: false,
            wells: Vue.util.extend([], this.propWells),
            selectedWell: null,
            plugFields: Vue.util.extend([], this.propPlugFields),
            perfFields: Vue.util.extend([], this.propPerfFields),
            plugValues: Vue.util.extend([], this.propPlugValues),
            perfValues: Vue.util.extend([], this.propPerfValues),
            depthShift: Vue.util.extend([], this.propDepthShift),
            errorMessage: this.propErrorMessage,
            contractors: this.propContractors,
            defaultContractorSelected: false,
            sendingDefaultContractor: false,
            CLUSTER_SPACEING: this.indexOfColumnsPerf.INDEX_CLUSTER_SPACING_PERF,
            STAGE_NUMBER_COL_INDEX_SHOT: this.indexOfColumnsPerf.INDEX_STAGE_NUMBER_PERF,
            TOP_DEPTH_INDEX_SHOT: this.indexOfColumnsPerf.INDEX_TOP_DEPTH_PERF,
            GUN_NUMBER_COL_INDEX_SHOT: this.indexOfColumnsPerf.INDEX_GUN_NUMBER_PERF,
            STAGE_NUMBER_COL_INDEX_PLUG: this.indexOfColumnsPlug.INDEX_STAGE_NUMBER_PLUG,
            TOP_DEPTH_INDEX_PLUG: this.indexOfColumnsPlug.INDEX_TOP_DEPTH_PLUG,
            checkMissedShotsSettings: {
                showValidationBool: false,
                validationTextOptions: {
                    invalidFeedback:'Server error, unable to check missed shots',
                    validFeedback: 'Successfully checked missed shots'
                },
                validationText: '',
                validationColorClass: 'text-danger'
            },
        };
    },
    mounted() {
        if(this.newGun || this.newPlug){ //this means redirected from add new gun/plug library screen
                this.isNewGunPlugData = true;

        }
        this.selectedWell = this.wellsData.find(target => target.index == this.wellIndex);
        this.updateAlerts();
        if (this.$refs?.perfTableRow !== undefined) { //save px value of row height if table rows exist
            this.tableRowHeight = this.$refs.perfTableRow[0].offsetHeight;
        }
        this.isDefaultContractorSelected();
    },
    computed: {
        resolveStatusClass() {
            return this.findResolveStatusClass;
        },
        calculatedDateTimePerfs() {
            let dateAndTime=null;
            let dateTimePerfs=[]
            for (let n = 0; n < this.datePerfs.length; n++) {
              dateAndTime = this.datePerfs[n]+' '+this.timePerfs[n]
              let formattedDate = moment(dateAndTime).format('YYYY-MM-DD HH:mm:ss');
              dateTimePerfs.push(formattedDate)
            }
            return dateTimePerfs;
        },
        calculatedDateTimePlugs() {
            let dateAndTime=null;
            let dateTimePerfs=[]
            dateAndTime = this.datePlugs[0]+' '+this.timePlugs[0]
            let formattedDate = moment(dateAndTime).format('YYYY-MM-DD HH:mm:ss');
            dateTimePerfs.push(formattedDate)
            return dateTimePerfs;
        },
        updatedFirstPerfFields() {
            let perfFieldsFirstInterval = [...this.perfFields];
            perfFieldsFirstInterval[8] = 'Depth (ftKB)';
            return perfFieldsFirstInterval;
        },
        updatedFirstPlugFields() {
            let plugFieldsFirstInterval = [...this.plugFields];
            plugFieldsFirstInterval[5] = 'Depth (ftKB)';
            return plugFieldsFirstInterval;
        },
        rowPerfVals() {
            let array_all=[]
            for (let n = 0; n < this.gunNumberToolstring; n++) {
                array_all.push(Array(34).fill(""))
            }
            for (let n = 0; n < array_all.length; n++) {
                array_all[n][5]=(this.datePerfs[n])
                array_all[n][6]=(this.timePerfs[n])
                array_all[n][8]=(this.depthPerfs[n])
                array_all[n][3]=1
                array_all[n][4]=n+1
            }
            return array_all;
        },
        rowPlugVals() {
            let array_all=[];
            array_all.push(Array(14).fill(""))
            array_all[0][5]=(this.depthPlugs[0])
            array_all[0][7]=(this.datePlugs[0])
            array_all[0][8]=(this.timePlugs[0])
            return array_all;
        },
        gunNumberToolstring() {
            if (this.firstStageGunCount) {
                return this.firstStageGunCount;
            } else {
                return this.perfValues[1][this.perfValues[0].length-1].stageToolstring.gunCount
            }
        },
        datePerfs() {
            let datePerfs = []
            for (let n = 0; n < this.gunNumberToolstring; n++) {
                this.defaultDatesAndTimes(datePerfs, 'MM/DD/YYYY'); //pushes default perf dates to datePerfs
            }
            return datePerfs;
        },
        datePlugs() {
            let datePlugs = [];
            this.defaultDatesAndTimes(datePlugs, 'MM/DD/YYYY'); //pushes default plug dates to datePlugs
            return datePlugs;
        },
        timePerfs() {
            let timePerfs = []
            for (let n = 0; n < this.gunNumberToolstring; n++) {
                this.defaultDatesAndTimes(timePerfs, 'HH:mm'); //pushes default perf times to timePerfs
            }
            return timePerfs
        },
        timePlugs() {
            let timePlugs = [];
            this.defaultDatesAndTimes(timePlugs, 'HH:mm'); //pushes default plug times to timePlugs
            return timePlugs;
        },
        depthPerfs() {
            let depthPerfs = []
            for (let n = 0; n < this.gunNumberToolstring; n++) {
                depthPerfs.push('');
            }
            return depthPerfs;
        },
        depthPlugs() {
            let depthPlugs = [];
            depthPlugs.push('');
            return depthPlugs;
        },
        isFirstIntervalPerforationsPresent() {
            //checks if there are any 1st interval perf data already set
            if (this.perfValues.length > 0) {
                for (var i = 0; i < this.perfValues.length; i++) {
                    if (this.perfValues[i][3] == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }else {
                return false;
            }
        },
        isFirstIntervalPlugsPresent() {
            //checks if there are any 1st interval plug data already set
            if (this.plugValues.length > 0) {
                for (var i = 0; i < this.plugValues.length; i++) {
                    if (this.plugValues[i][1] == 1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }else {
                return false;
            }
        },
        selectedWellId() {
            return this.wellsData.find(well => well.index == this.wellIndex).id;
        },
        reportPageSelectStyle: function() {
            return {
                'form-control': true
            }
        },
        fileExportTitleClass: function() {
            return {
                'text-nowrap': true,
                'd-flex': true,
                'align-items-center':true
            }
        },
        isTableChanged() {
            return this.changedValuesArray.length > 0? true : false;
        },
        endStageCalculation() {
            return this.validStages.filter((item)=>{
                if(item <= this.currentStage) {
                    return true;
                } else {
                    return false;
                }
            });
        },
        lastStage() {
            return this.currentStage;
        },
        validStages() {
            const targetWell = this.wellsData.find(target => target.index == this.wellIndex);
            const stagesEnd = targetWell.numberOfStages;


            const stages = [];

            for (let stage = 1;  stage <= this.currentStage; stage++) {
                stages.push(stage);
            }

            return stages;
        },
        maxTableHeight() {
            if (this.tableRowHeight == null) { //while page is loading, tableRowHeight is null as $refs are unavailable
                return '';
            } else {
                //get the height in pixels of the table header
                const tableHeader =this.$refs.perfTableHead.offsetHeight;
                //tableRowHeight has already been filled from within mounted(), so calc total table height
                return this.tableRowHeight * this.visibleTableRows + tableHeader;
            }
        },
        isClearAlertsFeatureFlagEnabled() {
            return isFeatureFlagged('CLEAR_ALERTS');
        },
    },
    props: {
        jobNumber: String,
        propWells: [Array, Object],
        wellName: String,
        wellIndex: [Number, String],
        wellsData: [Array, Object],
        firstStageGunCount: [Number, String],
        propPlugFields: [Array, Object],
        propPerfFields: [Array, Object],
        propPlugValues: [Array, Object],
        propPerfValues: [Array, Object],
        propDepthShift: [Array, Object],
        job: [Array, Object],
        guns: [Array, Object],
        plugs: [Array, Object],
        propResolveStatus: [Array, Object],
        propErrorMessage: String,
        propContractors: [Array, Object],
        clusterInfo: [Array, Object],
        allClusterInfo: [Array, Object],
        defaultContractorId: [Number, String],
        startStage: [Number, String],
        currentStage: [Number, String],
        hourOffset: [Number, String],
        endStage: [Number, String],
        isCompanyAdmin: [Boolean, Number],
        isAdmin: [Boolean, Number],
        iwsUser: [Boolean, Number],
        isIwsTech: [Boolean, Number],
        padName: String,
        activeCompanyJobs: [Array, Object],
        indexOfColumnsPerf: [Array, Object],
        indexOfColumnsPlug: [Array, Object]
    },
    created() {
        //Set tab title
        document.title = "IWS - " + (this.padName || "Not Set") + " - Wireline Report";

        this.plugChangedVals = [];
        this.gunChangedVals = [];
        this.selectedContractor = this.defaultContractorId ? this.contractors.find((c) => c.id == this.defaultContractorId).name : '';
        this.plugDisabledIndexes = [1, 7,8, 13]; // table column index for the disabled plug entries
        this.gunDisabledIndexes = [0, 2, 3, 4, 5, 13, 14, 15, 31]; // table column index for the disabled shot entries
        this.gunDisabledFirstIntervalIndexes = [0, 1, 2, 3, 4, 7, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33]; // table column index for the disabled shot entries
        this.plugDisabledFirstIntervalIndexes = [0,1,2,3,4,6,9,10,11,12,13]
        INIT_PREF_VALUES = _.cloneDeep(this.perfValues);
        INIT_PLUG_VALUES = _.cloneDeep(this.plugValues);

        $(document).ready(function() {
            $('input').change(function() {
                $(this).css({'border-width': '2px', 'border-color': 'red' });
            });
        });
    },
    methods: {
        onRowClicked(rowData, rowIndex, type) {
            const extraData  = rowData[rowData.length - 1];
            if(extraData && extraData['alertType'] !== null) {
                let commentIndex = null;
                if(type == 'shot') {
                    commentIndex = this.perfFields.findIndex((name)=> name == 'Comment');
                } else {
                    commentIndex = this.plugFields.findIndex((name)=> name == 'Comments');
                }

                if(commentIndex >= 0) {
                    const value = rowData[commentIndex];
                    const alertData = {
                        value,
                        colIndex: commentIndex,
                        rowIndex,
                        type,
                        extraInfo: extraData,
                        isAlertColumn: true
                    }
                    this.onCommentFocused(alertData);
                }
            }
        },
        defaultDatesAndTimes(dateOrTimeArray, dateOrTimeFormat) {
            if (this.job?.start) {
                dateOrTimeArray.push(moment.utc(this.job.start).add(this.hourOffset, 'hours').format(dateOrTimeFormat));
            }
            else if (this.job?.scheduledStart) {
                dateOrTimeArray.push(moment.utc(this.job.scheduledStart).add(this.hourOffset, 'hours').format(dateOrTimeFormat));
            } else {
                dateOrTimeArray.push('');
            }
        },
        highlightFailingFields(rowIndex, index) {
            if (this.fieldValidationFailed == true) {
                if ((this.depthErrorIndices.includes(rowIndex) && index == 8)) {
                    return {
                        'bg-danger': true
                    };
                }
                if (this.dateErrorIndices.includes(rowIndex) && index == 5) {
                    return {
                        'bg-danger': true
                    };
                }
                if (this.timeErrorIndices.includes(rowIndex) && index == 6) {
                    return {
                        'bg-danger': true
                    };
                }
            }
        },
        highlightFailingFieldsPlugs(rowIndex, index) {
            if (this.fieldValidationFailedPlugs == true) {
                if ((this.depthErrorIndicesPlugs.includes(rowIndex) && index == 5)) {
                    return {
                        'bg-danger': true
                    };
                }
                if (this.dateErrorIndicesPlugs.includes(rowIndex) && index == 7) {
                    return {
                        'bg-danger': true
                    };
                }
                if (this.timeErrorIndicesPlugs.includes(rowIndex) && index == 8) {
                    return {
                        'bg-danger': true
                    };
                }
            }
        },
        validateInputs() {
            let errorString = '';
            if (this.depthErrorIndices.length > 0) {
                this.depthErrorIndices = [];
            }
            let depthError, dateError, timeError;
            for (let i = 0; i < this.depthPerfs.length; i++) {
                if (!this.depthPerfs[i] || isNaN(this.depthPerfs[i])) {
                    depthError = true;
                    //depth highlight error on save because blank on page load
                    this.depthErrorIndices.push(i);
                }
                if (!this.datePerfs[i]) {
                    dateError = true;
                }
                if (!this.timePerfs[i]) {
                    timeError = true;
                }
            }
            if (depthError) {
                errorString += 'Please enter valid depths for all fields.\n';
            }
            if (dateError) {
                errorString += 'Please enter valid dates for all fields.\n';
            }
            if (timeError) {
                errorString += 'Please enter valid times for all fields.\n ';
            }
            if (!errorString) {
                return true;
            } else {
                this.fieldValidationFailed = true;
                alert(errorString);
                return false;
            }
        },
        validateInputsPlugs() {
            let errorString = '';
            if (this.depthErrorIndicesPlugs.length > 0) {
                this.depthErrorIndicesPlugs = [];
            }
            let depthError, dateError, timeError;
            for (let i = 0; i < this.depthPlugs.length; i++) {
                if (!this.depthPlugs[i] || isNaN(this.depthPlugs)) {
                    depthError = true;
                    this.depthErrorIndicesPlugs.push(i);
                }
                if (!this.datePlugs[i]) {
                    dateError = true;
                }
                if (!this.timePlugs[i]) {
                    timeError = true;
                }
            }
            if (depthError) {
                errorString += 'Please enter depth.\n';
            }
            if (dateError) {
                errorString += 'Please enter date.\n';
            }
            if (timeError) {
                errorString += 'Please enter time.\n ';
            }
            if (!errorString) {
                return true;
            } else {
                this.fieldValidationFailedPlugs = true;
                alert(errorString);
                return false;
            }
        },
        calculatedDateTimeMethodInput(timeVals, dateVals) {
            let dateAndTime=null;
            let dateTimePerfs=[];
            for (let n = 0; n < dateVals.length; n++) {
              dateAndTime = dateVals[n]+' '+timeVals[n];
              let formattedDate = moment(dateAndTime).format('YYYY-MM-DD HH:mm:ss');
              dateTimePerfs.push(formattedDate);
            }
            return dateTimePerfs;
        },
        onToolStringPress(){
            this.$refs.jobConfigModal.changeModalVisibility(true)
        },
        getUpdatedJobData(){
            return {
                jobNumber: this.jobNumber,
                wellName: this.wellName,
                stage: 1,
                wellIndex: this.wellIndex,
                customerId: this.activeCompanyJobs[0]?.customer_id? this.activeCompanyJobs[0]?.customer_id: null,
                hourOffset: this.hourOffset
            };
        },
        onShotDeclined(data) {
            const url = '/messages-dismiss';
            const body = {
                '_token': GlobalFunctions.getCSRFToken(),
                'jobNumber': this.jobNumber,
                ...data
            };

            $.ajax({
                method: 'POST',
                url: url,
                data: body,
                dataType: 'json'
            }).done(()=>{
                location.reload();
            });

            this.showShotResolveModal = false;
        },
        onShotResolved(type, data) {
            if(type === 'missed-shot') {
                const url = '/wireline-history/missed-shot/' + this.jobNumber;
                const body = {
                    '_token': GlobalFunctions.getCSRFToken(),
                    ...data
                };

                $.ajax({
                    method: 'POST',
                    url: url,
                    data: body,
                    dataType: 'json'
                }).done((result) => {
                    this.handleAlertResponse(result);
                });
            } else if(type === 'additional-shot') {
                const url =  '/toolstring/missingGun/' + data.toolstringId;
                const body = {
                    '_token': GlobalFunctions.getCSRFToken(),
                    'jobNumber': this.jobNumber,
                    ...data
                };
                $.ajax({
                    method: 'POST',
                    url: url,
                    data: body,
                    dataType: 'json'
                }).done((result)=>{
                    this.handleAlertResponse(result);
                });
            } else if(type === 'missed-plug') {
                const url =  '/wireline-history/missed-plug/' + this.jobNumber;
                const body = {
                    '_token': GlobalFunctions.getCSRFToken(),
                    ...data
                };

                $.ajax({
                    method: 'POST',
                    url: url,
                    data: body,
                    dataType: 'json'
                }).done((result)=>{
                    this.handleAlertResponse(result);
                });
            }

            this.showShotResolveModal = false;
        },
        handleAlertResponse(result) {
            if(result.error) {
                setTimeout(()=>{ //setting a timeout to smooth the modal disappear animation
                    if(confirm(result.error) == true) {
                        location.reload();
                    }
                }, 1000);
            } else {
                location.reload();
            }
        },
        onTopDepthChange(rowIndex, topDepth) {
            const newRowValues = [...this.perfValues];
            const selectedRow = this.perfValues[rowIndex];
            const gunLength = selectedRow[selectedRow.length-1].gunLength;
            const appendChanges = this.calculateTopBottomDepth(gunLength, topDepth, 'depthtop');
            const bottomDepth = appendChanges['depthbtm'];
            const initValue = newRowValues[rowIndex][this.TOP_DEPTH_INDEX_SHOT];
            const wirelineId = selectedRow[selectedRow.length-1].wirelineId;
            const gunNumber = selectedRow[this.GUN_NUMBER_COL_INDEX_SHOT];

            newRowValues[rowIndex][this.TOP_DEPTH_INDEX_SHOT] = topDepth;
            newRowValues[rowIndex][this.TOP_DEPTH_INDEX_SHOT+1] = bottomDepth;
            Vue.set(this.perfValues, newRowValues);

            const topDepthUpdateMask = {
                wirelineId,
                gunNumber: gunNumber,
                appendComment: '',
                type: 'shot',
                jsonChanges: {
                    ...appendChanges
                }
            };

            const url = '/wireline-history-changes';
            const data = {
                '_token': GlobalFunctions.getCSRFToken(),
                wirelineChanges: [topDepthUpdateMask],
                loadTimeUTC: this.loadTimeUTC,
                jobNumber: this.jobNumber
            };

            $.ajax({
                method: 'PUT',
                url: url,
                data,
                dataType: 'json'
            }).done((data) => {
                if(data.error) {
                    alert(data.error);
                } else {
                    location.reload();
                }
            });
        },
        onClusterConfigPress() {
            this.$refs['clusterConfig'].changeModalVisibility(true);
        },
        discardChanges() {
            location.reload();
        },
        async saveChanges() {
            const url = '/wireline-history-changes';

            const changesbyIds = _.groupBy(this.changedValuesArray, 'wirelineId');

            const combinedChanges = [];


            for (const wirelindId in changesbyIds) {
                const changesCombined = changesbyIds[wirelindId].reduce((accumulator, currentValue) => {
                    return {
                        ...currentValue,
                        appendComment: accumulator['appendComment'] + '\n' + currentValue['appendComment'],
                        jsonChanges: {
                            ...accumulator.jsonChanges,
                            ...currentValue.jsonChanges
                        }
                    };
                });
                combinedChanges.push(changesCombined);
            }
            const data = {
                '_token': GlobalFunctions.getCSRFToken(),
                wirelineChanges: combinedChanges,
                loadTimeUTC: this.loadTimeUTC,
                jobNumber: this.jobNumber
            };

            $.ajax({
                method: 'PUT',
                url: url,
                data,
                dataType: 'json'
            }).done((data) => {
                if(data.error) {
                    alert(data.error);
                } else {
                    location.reload();
                }
            });
        },
        async saveWirelineRow() {
             const isValid = this.validateInputs();
             if (isValid) {
                let offsetDates = this.calculatedDateTimePerfs.map(x =>
                    moment.utc(x).subtract(this.hourOffset, 'hours').format('YYYY-MM-DD HH:mm:ss')
                ); //subtracts offset to get into utc time for database storage, backend converts it back to job local time in UI
                const data = {
                    wellIndex: this.wellIndex,
                    depths: this.depthPerfs,
                    '_token': GlobalFunctions.getCSRFToken(),
                    jobNumber: this.jobNumber,
                    dates: offsetDates
                };
                const url = '/new-wireline-history';

                axios({
                    method: 'PUT',
                    url: url,
                    data,
                    dataType: 'json',
                })
                .then((response) => {
                    if (response.data.success) {
                        location.reload();
                    }
                    if (response.data.error) {
                        alert(response.data.message)
                    }
                })
            }
        },
        async saveWirelineRowPlug() {
            const isValid = this.validateInputsPlugs();
            if (isValid) {
                let offsetDates = this.calculatedDateTimePlugs.map(x =>
                    moment.utc(x).subtract(this.hourOffset, 'hours').format('YYYY-MM-DD HH:mm:ss')
                );
                const data = {
                    wellIndex: this.wellIndex,
                    depths: this.depthPlugs[0],
                    '_token': GlobalFunctions.getCSRFToken(),
                    jobNumber: this.jobNumber,
                    dates: offsetDates[0]
                };
                const url = '/new-wireline-history-plugs';

                axios({
                    method: 'PUT',
                    url: url,
                    data,
                    dataType: 'json',
                })
                .then((response) => {
                    if (response.data.success) {
                        location.reload();
                    }
                    if (response.data.error) {
                        alert(response.data.message)
                    }
                })
            }
        },
        async updateAlerts () {
            const url = '/messagesForJob/'+this.jobNumber;
            const self = this;
            const data = await $.get(
                url,
                {
                    type: 'alert',
                    clusterAlertInfo: {
                        getAllAlerts: false,
                        onlyCluster: true,
                        wellIndex: this.wellIndex,
                        startStage: this.startStage,
                        endStage: this.endStage
                    }
                },
                'json'
            );

            function alertSort( a, b ) {
                if ( a.stageNumber > b.stageNumber ){
                    return -1;
                }else if ( a.stageNumber < b.stageNumber ){
                    return 1;
                }else {
                    if (a.gunNumber > b.gunNumber){
                        return -1;
                    }else if ( a.gunNumber < b.gunNumber ){
                        return 1;
                    }
                }

                return 0;
            }

            const messagesArray =  [ ...data.events, ...data.shotAlerts, ...data.plugAlerts, ...data.cluster];
            messagesArray.sort( alertSort );
            return messagesArray;
        },
        findPlugClass(extraData) {
            const alertLevel = extraData?.alertLevel;
            if (alertLevel == 'warning') {
                return {
                    'bg-warning': true
                };
            }  else {
                return {};
            }
        },
        findResolveStatusClass(row, padding = false) {
            const gunNumber = row[this.GUN_NUMBER_COL_INDEX_SHOT];
            const stageNumber = row[this.STAGE_NUMBER_COL_INDEX_SHOT];
            const resolveStatus = this.resolveStatus.find((statusObj)=>statusObj.gunNumber===gunNumber && statusObj.stageNumber===stageNumber);
            const alertLevel = row[row.length-1].alertLevel;

            if (alertLevel == 'warning') {
                return {
                    'pl-4': padding,
                    'bg-warning': true
                };
            } else if(resolveStatus?.status === 1) { //green
                return {
                    'pl-4': padding,
                    'bg-success': true
                };
            } else if(resolveStatus?.status === 0) { //yellow
                return {
                    'pl-4': padding,
                    'bg-warning': true
                };
            } else {
                return {
                    'pl-4': padding
                };
            }
        },
        getAlertIconStatus(row) {
            const gunNumber = row[this.GUN_NUMBER_COL_INDEX_SHOT];
            const stageNumber = row[this.STAGE_NUMBER_COL_INDEX_SHOT];
            const resolveStatus = this.resolveStatus.find((statusObj)=>statusObj.gunNumber===gunNumber && statusObj.stageNumber===stageNumber);
            return resolveStatus?.status;
        },
        onSavePressed(rowIndex, colIndex, newValue, type, isAlert, gunNumber, stageNumber, shotSheetReferenced) {
            if(type==='shot') {
                const newRowValues = [...this.perfValues];
                newRowValues[rowIndex][colIndex] = newValue;
                Vue.set(this.perfValues, newRowValues);
                if(isAlert) {
                    this.resolveStatus = this.resolveStatus.map((item)=>{
                        if(item.gunNumber===gunNumber && item.stageNumber===stageNumber) {
                            return {
                                ...item,
                                status: 1,
                                shotSheetReferenced: shotSheetReferenced
                            };
                        } else {
                            return item;
                        }
                    });
                }
            } else {
                const newRowValues = [...this.plugValues];
                newRowValues[rowIndex][colIndex] = newValue;
                Vue.set(this.plugValues, newRowValues);
            }
        },
        onCommentFocused({value, colIndex, rowIndex, type, extraInfo, isAlertColumn=false}) {
            if(extraInfo?.alertType) { // handle missedshots and additionalshots here
                const alertType = extraInfo.alertType;
                if(alertType === 'missed-plug') {
                    this.showShotResolveModal = true;
                    this.shotResolveModalProps = {
                        shot: extraInfo.shot,
                        toolstring: extraInfo.toolstring,
                        stageNumber: extraInfo.stageNumber,
                        wellName: this.wellName,
                        wellIndex: this.wellIndex,
                        gunNumber: extraInfo.gunNumber,
                        modalType: extraInfo.alertType,
                        guns: this.guns,
                        plugs: this.plugs,
                        hourOffset: parseFloat(this.hourOffset),
                        stageToolstring: extraInfo.stageToolstring
                    };
                    this.$refs['commentInputRef'].map((comment)=>comment.blur());
                    return;
                } else {
                    this.showShotResolveModal = true;
                    this.shotResolveModalProps = {
                        shot: extraInfo.shot,
                        toolstring: extraInfo.toolstring,
                        stageNumber: extraInfo.stageNumber,
                        wellName: this.wellName,
                        wellIndex: this.wellIndex,
                        gunNumber: extraInfo.gunNumber,
                        modalType: extraInfo.alertType,
                        guns: this.guns,
                        plugs: this.plugs,
                        hourOffset: parseFloat(this.hourOffset),
                        stageToolstring: extraInfo.stageToolstring
                    };
                    this.$refs['commentInputRef'].map((comment)=>comment.blur());
                    return;
                }
            }


            if(!this.isVisibleCommentModal) {
                this.isVisibleCommentModal = true;

                const rowDetails = this.perfValues[rowIndex];
                const rowPlugDetails = this.plugValues[rowIndex];
                const stageNumber = type==='shot'? rowDetails[this.STAGE_NUMBER_COL_INDEX_SHOT] : rowPlugDetails[this.STAGE_NUMBER_COL_INDEX_PLUG];
                const actualSpacing = parseInt(rowDetails[this.CLUSTER_SPACEING]);
                const lastShotDepth = this.perfValues[rowIndex-1] ? this.perfValues[rowIndex-1][this.TOP_DEPTH_INDEX_SHOT] : null;
                const gunNumber = rowDetails[this.GUN_NUMBER_COL_INDEX_SHOT];
                const topDepth = rowDetails[this.TOP_DEPTH_INDEX_SHOT];
                const resolveStatus = this.resolveStatus.find((statusObj)=>statusObj.gunNumber===gunNumber && statusObj.stageNumber===stageNumber);

                let alertStatus;
                if (isAlertColumn) {
                    alertStatus = (resolveStatus.status===null || type==='plug')? false : true;
                }
                else {
                    alertStatus = null;
                }

                let commentInfo;
                if (isAlertColumn && resolveStatus.status == null){
                    commentInfo = null;
                }
                else {
                    commentInfo = {
                        currentComment: value,
                        type: type,
                        gunNumber: gunNumber,
                        wirelineId: resolveStatus.wirelineId,
                        depth: resolveStatus.depth,
                        topDepth: topDepth,
                        stageNumber: stageNumber,
                        wellIndex: this.wellIndex,
                        lastShotDepth: lastShotDepth,
                        actualSpacing: actualSpacing,
                        shotSheetReferenced: resolveStatus.shotSheetReferenced,
                        rowIndex: rowIndex,
                        colIndex: colIndex,
                        isAlert: alertStatus
                    };
                this.commentInfo = commentInfo;
                this.$refs['wirelineComment'].changeModalVisibility(true);
                this.$refs['wirelineComment'].updateCommentValue(value);
                }
            } else {
                this.isVisibleCommentModal = false;
                this.$refs['commentInputRef'].map((comment)=>comment.blur());
            }
        },
        onWellChanged(well) {
            const localWellIndex = this.wells.indexOf(well);
            const wellIndex = this.wellsData[localWellIndex].index;
            window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + wellIndex + '/1/5';
        },
        onStartStageChanged(start) {
            if(start > this.endStage) {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + this.wellIndex + '/' + start + '/' + start;
            }
            else {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + this.wellIndex + '/' + start + '/' + this.endStage;
            }
        },
        onEndStateChanged(end) {
            if(end < this.startStage) {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + this.wellIndex + '/1/' + end;
            }
            else {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + this.wellIndex + '/' + this.startStage + '/' + end;
            }
        },
        onFileExportTypeChanged(value) {
            this.fileExportType = value;
        },
        onContractorChanged(value) {
            if(value == 'Select Contractor')
            {
                value = '';
            }
            this.selectedContractor = value;
            this.isDefaultContractorSelected();
        },
        setChangedContractorInfomation() {
            this.perfValues.map((item,index)=>{
                this.gunChangedVals.push({ row: index, columnTag: 'contractor', value: this.selectedContractor  });
            });
        },
        calculateTopBottomDepth(gunLength, changeValue, columnTag) {
            let appendChanges = {};
            const changeTagsToRemove = ['depthtop', 'depthbtm'];
            const tagToRemove = changeTagsToRemove.find(tag => tag !== columnTag);
            if(changeTagsToRemove.includes(columnTag)) {
                appendChanges = {
                    [columnTag]: changeValue,
                    [tagToRemove]: tagToRemove === 'depthtop'? (Number(changeValue) - gunLength).toFixed(2) : (Number(changeValue) + gunLength).toFixed(2)
                };
            }
            return appendChanges;
        },
        onPlugDataChange(event, index, rowIndex) {
            this.tableUpdated = true;
            const changedRow = this.plugValues[rowIndex];
            const columnName = this.plugFields[index];
            const arrayTitleKeys = ['com','intno', 'des', 'szidnom', 'szodnom', 'depthtop', 'depthbtm', null, null, 'conditionrun', 'make', 'model'];

            const newVal= event.target.value;
            this.$nextTick(()=>{
                this.plugValues[rowIndex][index] = newVal;
            })
            const columnTag = arrayTitleKeys[index];

            if (changedRow) {
                const wirelineId = changedRow[changedRow.length-1].wirelineId;
                const changeValue = newVal;
                const initValue = INIT_PLUG_VALUES[rowIndex][index];
                const changedWirelineIndex = this.changedValuesArray.findIndex((item)=>item.wirelineId === wirelineId);
                const gunLength = this.perfValues[rowIndex][this.perfValues[rowIndex].length-1].gunLength;
                const appendChanges = this.calculateTopBottomDepth(gunLength, changeValue, columnTag);
                if(changedWirelineIndex && changedWirelineIndex > 0) {
                    this.changedValuesArray[changedWirelineIndex].appendComment = `[Field Changed] ${columnName} - Changed From ${initValue} to ${changeValue}`,
                    this.changedValuesArray[changedWirelineIndex].jsonChanges = {
                        ...this.changedValuesArray[changedWirelineIndex].jsonChanges,
                        [columnTag]: changeValue,
                        ...appendChanges
                    };
                } else {
                    this.changedValuesArray.push({
                        wirelineId,
                        type: 'plug',
                        appendComment: `[Field Changed] ${columnName} - Changed From ${initValue} to ${changeValue}`,
                        gunNumber: null,
                        jsonChanges: {
                            [columnTag]: changeValue,
                            ...appendChanges
                        }
                    });
                };
            }

            if( columnTag ) {
                this.plugChangedVals.push({ row: rowIndex, columnTag: columnTag, value: newVal, stageNumber: this.plugValues[rowIndex][0]  });
            }
        },
        onValueInputChange(event, index, rowIndex){
            //for first interval perf input table
            const newVal= event.target.value;

            if (this.perfFields[index] == 'Top Depth (ftKB)' && this.perfValues) {
                if (!newVal) {
                    this.fieldValidationFailed = true;
                    this.depthErrorIndices.push(rowIndex);
                    alert('Invalid Input. Depth must have numerical value.')
                } else {
                    //remove depth error index from list because there is no longer in an error at that index
                    let indexOfChangedValue = this.depthErrorIndices.indexOf(rowIndex);
                    if (indexOfChangedValue  > -1) {
                        this.depthErrorIndices.splice(indexOfChangedValue , 1);
                    }
                }
                for (let i = 0; i < this.depthPerfs.length; i++) {
                    this.depthPerfs[rowIndex] = parseInt(newVal)
                }
            }

            if (this.perfFields[index] == 'Date (MM/DD/YYYY)') {
                if (!newVal) {
                    this.fieldValidationFailed = true;
                    this.dateErrorIndices.push(rowIndex);
                    alert('Invalid Input. Date is required and in MM/DD/YYYY format.')
                } else {
                    //remove date error index from list because there is no longer in an error at that index
                    let indexOfChangedValue = this.dateErrorIndices.indexOf(rowIndex);
                    if (indexOfChangedValue  > -1) {
                        this.dateErrorIndices.splice(indexOfChangedValue , 1);
                    }
                }
                for (let i = 0; i < this.datePerfs.length; i++) {
                    this.datePerfs[rowIndex] = newVal
                    this.calculatedDateTimePerfs[rowIndex]=this.calculatedDateTimeMethodInput(this.timePerfs, this.datePerfs)[rowIndex]
                }
            }

            if (this.perfFields[index] == 'Time (hh:mm)') {
                if (!newVal) {
                    this.fieldValidationFailed = true;
                    this.timeErrorIndices.push(rowIndex);
                    alert('Invalid Input. Time is required and in hh:mm format.')
                } else {
                    //remove time error index from list because there is no longer in an error at that index
                    let indexOfChangedValue = this.timeErrorIndices.indexOf(rowIndex);
                    if (indexOfChangedValue  > -1) {
                        this.timeErrorIndices.splice(indexOfChangedValue , 1);
                    }
                }
                for (let i = 0; i < this.timePerfs.length; i++) {
                    this.timePerfs[rowIndex] = newVal
                    this.calculatedDateTimePerfs[rowIndex]=this.calculatedDateTimeMethodInput(this.timePerfs, this.datePerfs)[rowIndex]
                }
            }
        },
        onValueInputChangePlug(event, index, rowIndex){
            //for first interval plug input table
            const newVal= event.target.value;

            if (this.plugFields[index] == 'Top Depth (ftKB)' && this.perfValues) {
                if (!newVal) {
                    this.fieldValidationFailedPlugs = true;
                    this.depthErrorIndicesPlugs.push(rowIndex);
                    alert('Invalid Input. Depth must have numerical value.')
                } else {
                    let indexOfChangedValue = this.depthErrorIndicesPlugs.indexOf(rowIndex);
                    if (indexOfChangedValue  > -1) {
                        this.depthErrorIndicesPlugs.splice(indexOfChangedValue , 1);
                    }
                }
                this.depthPlugs[rowIndex] = parseInt(newVal)
            }

            if (this.plugFields[index] == 'Run Date (MM/DD/YYYY)') {
                if (!newVal) {
                    this.fieldValidationFailedPlugs = true;
                    this.dateErrorIndicesPlugs.push(rowIndex);
                    alert('Invalid Input. Date is required and in MM/DD/YYYY format.')
                } else {
                    let indexOfChangedValue = this.dateErrorIndicesPlugs.indexOf(rowIndex);
                    if (indexOfChangedValue  > -1) {
                        this.dateErrorIndicesPlugs.splice(indexOfChangedValue , 1);
                    }
                }
                this.datePlugs[rowIndex] = newVal
                this.calculatedDateTimePlugs[rowIndex]=this.calculatedDateTimeMethodInput(this.timePlugs, this.datePlugs)[rowIndex]
            }

            if (this.plugFields[index] == 'Run Time (hh:mm)') {
                if (!newVal) {
                    this.fieldValidationFailedPlugs = true;
                    this.timeErrorIndicesPlugs.push(rowIndex);
                    alert('Invalid Input. Time is required and in hh:mm format.')
                } else {
                    let indexOfChangedValue = this.timeErrorIndicesPlugs.indexOf(rowIndex);
                    if (indexOfChangedValue  > -1) {
                        this.timeErrorIndicesPlugs.splice(indexOfChangedValue , 1);
                    }
                }
                this.timePlugs[rowIndex] = newVal
                this.calculatedDateTimePlugs[rowIndex]=this.calculatedDateTimeMethodInput(this.timePlugs, this.datePlugs)[rowIndex]
            }
        },
        onGunDataChange(event, index, rowIndex) {
            const changedRow = this.perfValues[rowIndex];
            const columnName = this.perfFields[index];
            this.tableUpdated = true;
            const arrayTitleKeys = ['cs', 'null', 'com', null, null, null, null, 'typ', 'depthtop', 'depthbtm',
                'shotdensity', 'shotplan', 'shottotal',  'szholeact', null, null, null, 'gundes', 'szgun', 'gunmetallurgy', 'guncentralize', 'gunleftinhole',
                'conveymeth', 'carrierdes', 'carriermake', 'chargetyp', 'chargesz', 'chargemake', 'explosivetyp', 'phasing',
                'orientation', 'orientmethod'
            ];

            const newVal= event.target.value;
            this.$nextTick(()=>{
                this.perfValues[rowIndex][index] = newVal;
            })
            const columnTag = arrayTitleKeys[index];
            const gunNumber = this.perfValues[rowIndex][this.GUN_NUMBER_COL_INDEX_SHOT];

            if (changedRow) {
                const wirelineId = changedRow[changedRow.length-1].wirelineId;
                const initValue = INIT_PREF_VALUES[rowIndex][index];
                const changeValue = newVal;
                const changedWirelineIndex = this.changedValuesArray.findIndex((item)=>item.wirelineId === wirelineId);
                const gunLength = this.perfValues[rowIndex][this.perfValues[rowIndex].length-1].gunLength;
                const appendChanges = this.calculateTopBottomDepth(gunLength, changeValue, columnTag);
                if(changedWirelineIndex && changedWirelineIndex > 0) {
                    this.changedValuesArray[changedWirelineIndex].appendComment = `[Field Changed] ${columnName} - Changed From ${initValue} to ${changeValue}`;
                    this.changedValuesArray[changedWirelineIndex].jsonChanges = {
                        ...this.changedValuesArray[changedWirelineIndex].jsonChanges,
                        [columnTag]: changeValue,
                        ...appendChanges
                    };
                } else {
                    this.changedValuesArray.push({
                        wirelineId,
                        gunNumber: gunNumber,
                        appendComment: `[Field Changed] ${columnName} - Changed From ${initValue} to ${changeValue}`,
                        type: 'shot',
                        jsonChanges: {
                            [columnTag]: changeValue,
                            ...appendChanges
                        }
                    });
                }
            }

            if( columnTag ) {
                this.gunChangedVals.push({ row: rowIndex, columnTag: columnTag, value: newVal, stageNumber: this.perfValues[rowIndex][0], gunNumber: gunNumber});
            }
        },
        onDepthShiftDataChange(event, stageNumber) {
            Vue.set(this.depthShift, stageNumber, event.target.value);
        },
        onAddContractorPressed()
        {
            window.open('/contractors/0/edit', '_self');
        },
        async onExportPressed(exportType) {
            if(this.selectedContractor == '')
            {
                alert('Select a contractor to continue.');
                return;
            }
            let changedData;
            let responseType;
            this.setChangedContractorInfomation();
            if (exportType === 'exportPerf') {
                changedData = this.gunChangedVals;
                responseType = `${this.fileExportType} Perforations`;
            } else if (exportType === 'exportPlug') {
                changedData = this.plugChangedVals;
                responseType = `${this.fileExportType} Plugs`;
            } else if (exportType === 'exportBoth') {
                changedData = [...this.gunChangedVals,...this.plugChangedVals];
                responseType = `${this.fileExportType} Both`;
            } else {
                console.error('Error: Unhandled export type: ' + responseType);
                return;
            }

            // this.setChangedContractorInfomation();
            const url =`/wireline-op/${this.jobNumber}/exportData/${this.wellIndex}`;
            const postData = {
                '_token': GlobalFunctions.getCSRFToken(),
                'changedData': JSON.stringify(changedData),
                'depthShift': JSON.stringify(this.depthShift),
                'stageStart': this.startStage,
                'stageEnd': this.endStage,
                'exportType': exportType,
                'fileType': this.fileExportType,
                'responseType': responseType
            };

            const form = document.createElement('form');
            form.method = 'POST';
            form.action = url;

            for (const key in postData) {
                if (postData.hasOwnProperty(key)) {
                    const hiddenField = document.createElement('input');
                    hiddenField.type = 'hidden';
                    hiddenField.name = key;
                    hiddenField.value = postData[key];

                    form.appendChild(hiddenField);
                }
            }

            document.body.appendChild(form);
            form.submit();
        },
        isDefaultContractorSelected: function() {
            if (this.selectedContractor == '') {
                this.defaultContractorSelected = false;
                return;
            }
            this.defaultContractorSelected =
                this.contractors.find(contractor => contractor.name == this.selectedContractor).id == this.defaultContractorId;
        },
        onContractorDefaultChanged: function() {
            if (!this.selectedContractor) {
                return;
            }
            this.sendingDefaultContractor = true;
            const self = this;
            const url = '/wireline-op/' + this.jobNumber + '/contractorDefaults';
            const currentContractor = this.contractors.find(contractor => contractor.name == this.selectedContractor);
            const body = {
                '_token': GlobalFunctions.getCSRFToken(),
                'id': currentContractor.id,
                'name': currentContractor.name,
                'customer_id': currentContractor.customer_id,
                'type': currentContractor.type,
                'isDefault': this.defaultContractorSelected
            };
            $.ajax({
                method: 'POST',
                url: url,
                data: body,
                dataType: 'json'
            }).done((result)=>{
                //if new default is set update the local id to match
                if (self.defaultContractorSelected) {
                    self.defaultContractorId = result.id;
                }
                self.sendingDefaultContractor = false;
            });
        },
        async checkMissedShots() {
            var current_stage = this.startStage > 1 ? this.startStage  : 1;
            while (current_stage <= this.endStage) {
                await axios.post(
                    `/wireline-removed/${this.jobNumber}/${current_stage}/${this.wellIndex}`,
                ).then((response) => {
                    if (response.data.error) {
                        console.error(response.data.error)
                        this.showCheckMissedShotsValidation(this.checkMissedShotsSettings.validationTextOptions.invalidFeedback, 'text-danger')
                    } else {
                        this.showCheckMissedShotsValidation(this.checkMissedShotsSettings.validationTextOptions.validFeedback, 'text-success')
                    }
                }).catch((error) => {
                    console.error(error);
                    this.showCheckMissedShotsValidation(this.checkMissedShotsSettings.validationTextOptions.invalidFeedback, 'text-danger')
                });
                current_stage += 1;
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            // Table data reload without reloading the page is to be implemented in the future
            location.reload();
        },
        async clearMissedShotsAlerts(){
            await axios.get(
                    `/clear-alerts/${this.jobNumber}/${this.startStage}/${this.endStage}/${this.wellIndex}`,
                ).then((response) => {
                  window.location.reload();
                }).catch((error) => {
                    new toast({
                    title: 'Error Clearing Alerts',
                    variant: 'danger'
                    });
                });

        },
        showCheckMissedShotsValidation(msg, color) {
            this.checkMissedShotsSettings.showValidationBool = true;
            this.checkMissedShotsSettings.validationText = msg;
            this.checkMissedShotsSettings.validationColorClass = color;
            setTimeout(() => {
                this.checkMissedShotsSettings.showValidationBool = false;
            }, 5000);
        },
    }
};
</script>
