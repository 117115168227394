var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "button",
        {
          staticClass: "btn btn-sm bg-light",
          on: {
            click: function ($event) {
              return _vm.refresh()
            },
          },
        },
        [
          _c("img", {
            staticClass: "img-circle",
            attrs: { src: "../images/refresh.png" },
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-sm bg-light",
          on: {
            click: function ($event) {
              return _vm.refresh()
            },
          },
        },
        [
          _c("img", {
            staticClass: "img-circle",
            attrs: { src: "../images/settings.png" },
          }),
        ]
      ),
      _vm._v(" "),
      _c("line-chart", {
        attrs: {
          height: 220,
          "chart-data": _vm.datacollection,
          options: _vm.options,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }