<template>
    <b-modal 
        ref="my-modal" 
        v-model="show"
        size="xl"
        hide-header-close
        header-class="border-bottom-0 justify-content-center  pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg"
        no-close-on-backdrop
    >
        <template #modal-header>
            <div class="font-large">{{modalTextOptions('New Project', dashboardInfo.name)}}</div>
        </template>
        <template #default>
            <b-card
                title="Enter Project Name"
                class="inner-bg round border-bottom-0"
                title-tag="h5"
            >
                <div class="text-left pb-2" v-if="error">
                    <span style="color:red;">Project name cannot be empty</span>
                </div>
                <input
                    v-model="projectName"
                    class="form-control"
                />
            </b-card>
            <div v-if="iwsUser">
                <b-card
                    title="Select Company Name"
                    class="inner-bg round border-bottom-0"
                    title-tag="h5"
                >
                    <select id="company" v-model="company" :class="{'form-control': true}">
                        <option v-for="cust in customers" :value="cust.id" :key="cust.id">{{cust.name}}</option>
                    </select>
                </b-card>
            </div>
        </template>
        <template #modal-footer>
            <div class="d-flex w-100 ">
                <div class="flex-grow-1"></div>
                <button type="button" class="btn btn-secondary grey-button" @click="dismissModal">{{modalTextOptions('Back', 'Cancel')}}</button>
                <div class="px-2"></div>
                <button type="button" class="btn btn-success green-button" @click.prevent="onCreateOrSavePressed" :disabled="isFetching">
                    <div class="d-flex">
                        <div class="col-4">
                            <span v-if="isFetching" class="spinner-border spinner-border-sm pr-2" role="status" aria-hidden="true"></span>
                        </div>
                        <div>
                            {{modalTextOptions('Create', 'Save')}}
                        </div>
                    </div>
                </button>
            </div>
        </template>
    </b-modal>
</template>

<style scoped>
    .my-style {
        border-radius: 4px
    }
</style>

<script>
export default {
    props: {
        show: {
            type: [Boolean],
            required: true
        },
        dashboardInfo: {
            type: [Object],
            required: true
        },
        customers: {
            type: [Array]
        },
        iwsUser: {
            type: [Boolean, Number]
    },
    },
    data() {
        return {
            error: false,
            isFetching: false,
            projectName: null,
            company: null,
        };
    },
    mounted() {
        if (this.dashboardInfo.name) {
            this.projectName = this.dashboardInfo.name;
        }
        if (this.dashboardInfo.customer_id) {
            this.company = this.dashboardInfo.customer_id;
        }
    },
    watch: { 
        show: function() { // watch it
            this.error = false;
        }
    },
    methods: {	
        modalTextOptions(newCreationText, postCreationText) {
            if (!this.dashboardInfo.name) {
                return newCreationText;
            } else {
                return postCreationText;
            }
        },
        dismissModal() {
            this.projectName = this.dashboardInfo.name; //reject any name input changes from dismissed modal
            this.company = this.dashboardInfo.customer_id;
            this.$emit('dismissModal', !!this.dashboardInfo.name, !!this.dashboardInfo.customer_id); //close the modal, if the project was not created return to the projects list
        },						
        onCreateOrSavePressed() {
            if(!!this.projectName) {
                const dashboardData = {
                    ...this.dashboardInfo,
                    name: this.projectName,
                    customer_id: this.company
                };
                this.$emit('onCreateOrSavePressed', dashboardData);
            } else {
                this.error = true;
            }
        }
    }
};
</script>