<template>
    <LibraryFiltersBaseModal 
        title="Filter"
        max-width="680px"
        :filters="filters" 
        ref="libraryFilters"
        id="timekeeper-filter"
        @clearFilters="clearFilters($event)">
        <template #body="{ removeFromFilter, handleEvent }">
            <div class="filter-container container-border">
                <label>Stage</label>
                <template v-if="stagesByWell">
                    <accordion v-for="(stages, well, index) in stagesByWell" :key="`well-${index}`" :title="well" :class="{'mb-20': index !== Object.keys(stagesByWell).length - 1}">
                        <template #body>
                            <div style="gap: 12px;" class="d-flex flex-wrap mb-20">
                                <template v-if="filters?.stages?.hasOwnProperty(well)">
                                    <div class="chip" style="width: 37px;" :class="{'selected-success': filters.stages[well][stage]}" v-for="(stage, index) in stages" :key="`stage-${index}`" @click="filters.stages[well][stage] = !filters.stages[well][stage]">
                                        {{ stage }}
                                    </div>
                                </template>
                            </div>
                            <div class="d-flex align-items-center justify-content-between  w-100">
                                <div class="d-flex">
                                    <span style="margin-right: 12px; font-size: 12px; font-weight: 400">Quick select:</span>
                                    <div class="d-flex" style="gap: 16px;">
                                        <div class="chip quick-select-chip" :class="{'selected-success': selectionState(well, true)}" @click="toggleStagesSelection(well, true)">
                                            <div>
                                                <img v-if="selectionState(well, true)" src="/images/icons/checkmark.svg">
                                                <span class="w-100">All</span>
                                            </div>
                                        </div>
                                        <div class="chip quick-select-chip" :class="{'selected-warning': selectionState(well, false)}" @click="toggleStagesSelection(well, false)">
                                            <div>
                                                <img v-if="selectionState(well, false)" src="/images/icons/checkmark.svg">
                                                <span class="w-100">None</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex" v-if="fromToWell?.hasOwnProperty(well)">
                                    <iws-select
                                        style="width: 120px;"
                                        :options="[...stages].sort((a, b) => {return a - b})"
                                        :value.sync="fromToWell[well]['from']"
                                        @update:value="handleQuickSelection(well, stages)"
                                        placeholder="From"
                                    />
                                    <iws-select
                                        style="margin-left: 20px; width: 120px;"
                                        :options="fromToWell[well].toOptions"
                                        :value.sync="fromToWell[well]['to']"
                                        :disabled="fromToWell[well].toDisabled"
                                        @update:value="handleQuickSelection(well, stages)"
                                        placeholder="To"
                                    />
                                </div>
                            </div>
                        </template>
                    </accordion>
                </template>
            </div>
            <template v-for="(filter, dIndex) in dynamicFilters">
                <div class="filter-container" v-if="filter.canFilter" :key="`${dIndex}-filter`">
                    <iws-select
                        :label="filter.header"
                        value-name="id"
                        :multiselect="true"
                        display-name="value"
                        :options="filter.options"
                        :maintain-on-select="true"
                        :dropdown-footer-height="40"
                        :value="filters[filter.name]"
                        :placeholder="`Select ${filter.placeholder}`"
                        @change="handleEvent(filters[filter.name], $event)"
                    >
                        <template v-if="filter.includeUncoded" #dropdown-footer="{ maintainOpenOptions }">
                            <div class="included-uncoded px-3 py-2" @click="maintainOpenOptions()">
                                <p>Include Uncoded</p>
                                <iws-switch size="medium" :onPress="maintainOpenOptions" :value.sync="includeUncoded[filter.name]"/>
                            </div>
                        </template>
                    </iws-select>
                    <div>
                        <span v-for="(option, index) in filters[filter.name]" :key="index" class="badge badge-outline">
                            {{ getDisplayValue(dIndex, option) }} <i class="fas fa-times pointer-hover" style="margin-right: 5px" @click="removeFromFilter(filters[filter.name], index)"></i>
                        </span>
                    </div>
                </div>
            </template>
            <div class="filter-container container-border">
                <label>Date / Time Range</label>
                <div class="row">
                    <div class="col-6">
                        <label for="start-time">Start time</label>
                        <input
                            step="1"
                            class="m-0"
                            id="start-time"
                            :min="minDate"
                            :max="maxDate"
                            type="datetime-local"
                            v-model="filters.startTime"
                        >
                    </div>
                    <div class="col-6">
                        <label for="end-time">End time</label>
                        <input
                            step="1"
                            class="m-0"
                            id="end-time"
                            :max="maxDate"
                            :min="minDate"
                            type="datetime-local"
                            v-model="filters.endTime"
                        >
                    </div>
                </div>
                <div>
                    <span v-if="filters.startTime || filters.endTime" class="badge badge-outline">
                        {{ formatDatetime(filters.startTime, filters.endTime) }}
                        <i class="fas fa-times" style="margin-right: 5px pointer-hover" @click="clearDatetime"></i>
                    </span>
                </div>
            </div>
        </template>
    </LibraryFiltersBaseModal>
</template>

<script>
import _ from 'lodash';

import GlobalFunctions from '../../GlobalFunctions';
import LibraryFiltersBaseModal from '../LibraryFiltersBaseModal.vue';

const { isTruthy } = GlobalFunctions;

export default {
    props: {
        dateMinMax: Array,
        stagesByWell: Object,
        uniqueStages: Object,
        formatDatetime: Function,
        getDisplayValue: Function,
        dynamicFilters: [Array, Object]
    },
    components: {
        LibraryFiltersBaseModal
    },
    data() {
        return {
            filters: {
                wells: [],
                codes: [],
                stages: {},
                endTime: null,
                activities: [],
                nptVendors: [],
                priorities: [],
                nptOutcomes: [],
                startTime: null,
                stepDescriptions: []
            },
            includeUncoded: {
                codes: false,
                wells: false,
                activities: false,
                nptVendors: false,
                nptOutcomes: false,
                stepDescriptions: false
            },
            fromToWell: {},
        }
    },
    methods: {
        toggleStagesSelection(well, selectAll) {
            Object.keys(this.filters.stages[well]).forEach((stage) => {
                this.filters.stages[well][stage] = selectAll;
            });
        },
        handleQuickSelection(well, stages) {
            stages = [...stages].sort((a, b) => { return a - b});
            const fromTo = this.fromToWell[well];
            const to = fromTo.to;
            const from = fromTo.from;
            if (isTruthy(from)) {
                const index = stages.findIndex(s => s == from);
                if (index > -1) {
                    fromTo.toOptions = stages.slice(index+1);
                    if (isTruthy(to)) {
                        if (to < from)
                            fromTo.to = index+1 == stages.length ? from : stages[index+1];
                        const toIndex = stages.findIndex(s => s == +fromTo.to);
                        const toSelect = stages.slice(index, toIndex+1);
                        toSelect.forEach((stage) => {
                            this.filters.stages[well][stage] = true;
                        });
                        [...stages.slice(0, index), ...stages.slice(toIndex+1)].forEach((stage) => {
                            this.filters.stages[well][stage] = false;
                        })
                    }
                }
                fromTo.toDisabled = false;
            }
            this.fromToWell = {...this.fromToWell, ...fromTo};
        },
        selectionState(well, allSelected) {
            if (this.filters.stages.hasOwnProperty(well))
                return Object.values(this.filters.stages[well]).every(stage => {
                    return stage == allSelected;
                });
            return false;
        },
        clearDatetime() {
            this.filters.endTime = null;
            this.filters.startTime = null;
        },
        clearFilters(clearedFilters) {
            clearedFilters.stages = _.cloneDeep(this.uniqueStages);
            this.filters = clearedFilters;
            this.setFromTo();
        },
        setFromTo() {
            Object.keys(this.stagesByWell).forEach(well => {
                this.fromToWell[well] = {
                    from: null,
                    to: null,
                    toDisabled: true,
                    toOptions: []
                };
            });
        },
        open(filters, includeUncoded) {
            this.setFromTo();
            this.filters = _.cloneDeep(filters);
            this.includeUncoded = _.cloneDeep(includeUncoded);
            return new Promise((resolve, reject) => {
                this.$refs.libraryFilters.open().then($event => {
                    if (!!$event)
                        resolve({filters: _.cloneDeep(this.filters), uncoded: _.cloneDeep(this.includeUncoded)});
                    resolve({filters: null, uncoded: null});
                })
            })
        }
    },
    computed: {
        minDate() {
            return `${this.dateMinMax[0]}T00:00:00`;
        },
        maxDate() {
            return `${this.dateMinMax[this.dateMinMax.length-1]}T23:59:00`;
        }
    }
}
</script>

<style scoped>
    .quick-select-chip {
        max-width: 59px;
        min-width: 38px;
    }

    .quick-select-chip > div {
        gap: 2px;
        display: flex;
        margin-left: 8px;
        margin-right: 8px;
    }

    .chip {
        height: 20px;
        font-size: 12px;
        cursor: pointer;
        font-weight: 400;
        text-align:center;
        border-radius: 30px;
        background: #242A30;
        border: 1px solid #626770;
    }

    .selected-success {
        border-color: #12B76A;
        background: rgba(2, 122, 72, 0.8);
    }

    .selected-warning {
        border-color: #F79009;
        background: rgba(181, 71, 8, 0.8);
    }

    .mb-20 {
        margin-bottom: 20px;
    }

    .included-uncoded {
        height: 40px;
        display: flex;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        justify-content: space-between;
    }

    input[type="datetime-local"] {
        height: 38px;
        width: 280px;
        font-size: 14px;
        margin-top: 10px;
        border-radius: 4px;
        color: #676E78;
        background: #343a40;
        padding: 0 10px;
        border: 0.5px solid #d0d5dd;
    }

    input[type="datetime-local"]::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
</style>