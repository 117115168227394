var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row no-gutters", attrs: { id: "JobListComponent" } },
    [
      _c(
        "div",
        {
          staticClass:
            "d-flex flex-column col-md-3 order-2 order-md-1 no-gutters",
          style: _vm.useSmallLayout
            ? "height: 100%"
            : "height: calc(100vh - 81px)",
        },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "d-md-none bg-primary text-white border-0",
              on: {
                click: function ($event) {
                  _vm.showFilters = !_vm.showFilters
                },
              },
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.showFilters ? "Hide Filters" : "Show Filters") +
                  "\n        "
              ),
            ]
          ),
          _vm._v(" "),
          _vm.showFilters
            ? _c(
                "div",
                {
                  ref: "filterContainer",
                  class: {
                    "justify-content-around": true,
                    "p-3": true,
                    collapse: _vm.useSmallLayout && !_vm.showFilters,
                  },
                  staticStyle: {
                    "background-color": "#363A3D",
                    "border-bottom": "2px solid #666666",
                    "border-top": "2px solid #666666",
                    "font-size": "large",
                  },
                },
                [
                  _c("div", { staticClass: "font-weight-light" }, [
                    _vm._v("Filter Jobs"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "container" }, [
                    _c("div", { staticClass: "row p-1" }, [
                      _vm._m(1),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("iws-select", {
                            attrs: {
                              placeholder: !!_vm.selectedJobStatus?.length
                                ? _vm.selectedJobStatus
                                    .map((_value) => _value.status)
                                    .join(", ")
                                : "Select Job Status",
                              value: _vm.selectedJobStatus,
                              options: _vm.jobStatusOptions,
                              "display-name": "status",
                              multiselect: true,
                              "maintain-on-select": true,
                              "full-placeholder": "",
                            },
                            on: { change: _vm.jobStatusChange },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row p-1" }, [
                      _vm._m(2),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "col-8" },
                        [
                          _c("iws-select", {
                            attrs: {
                              placeholder: !!_vm.selectedJobType?.length
                                ? `${_vm.selectedJobType.length} selected`
                                : "Select Job Type",
                              value: _vm.selectedJobType,
                              options: _vm.jobTypeOptions,
                              "display-name": "type",
                              multiselect: true,
                              "maintain-on-select": true,
                              "full-placeholder": "",
                            },
                            on: { change: _vm.jobTypeChange },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.customers.length > 0
                      ? _c("div", { staticClass: "row p-1" }, [
                          _vm._m(3),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-8" },
                            [
                              _c("iws-select", {
                                attrs: {
                                  placeholder: "All Customer Jobs",
                                  value: _vm.filters.customer_id,
                                  options: [
                                    //add this only if isIwsUser is true
                                    _vm.checkForIwsUser,
                                    { name: "All Jobs", id: "allJobs" },
                                    ..._vm.customers,
                                  ],
                                  "display-name": "name",
                                  "value-name": "id",
                                  "full-placeholder": "",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(
                                      _vm.filters,
                                      "customer_id",
                                      $event
                                    )
                                  },
                                  change: function ($event) {
                                    _vm.searchChanged = true
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "row p-1" }, [
                      _vm._m(4),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-8" }, [
                        _c("div", { staticClass: "d-flex" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.showWellInfo,
                                expression: "showWellInfo",
                              },
                            ],
                            attrs: {
                              id: "check_filterActive",
                              type: "checkbox",
                            },
                            domProps: {
                              checked: Array.isArray(_vm.showWellInfo)
                                ? _vm._i(_vm.showWellInfo, null) > -1
                                : _vm.showWellInfo,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.showWellInfo,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.showWellInfo = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.showWellInfo = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.showWellInfo = $$c
                                  }
                                },
                                _vm.onShowWellInfoPressed,
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "font-weight-light",
                              staticStyle: {
                                "padding-left": "7px",
                                "font-size": "medium",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                Show well information\n                            "
                              ),
                            ]
                          ),
                        ]),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("hr", { staticClass: "mt-1" }),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c(
                        "b-button",
                        {
                          class: _vm.showAdvancedSettings ? null : "collapsed",
                          attrs: {
                            size: "sm",
                            block: "",
                            "aria-expanded": _vm.showAdvancedSettings
                              ? "true"
                              : "false",
                            "aria-controls": "collapse-4",
                          },
                          on: {
                            click: function ($event) {
                              _vm.showAdvancedSettings =
                                !_vm.showAdvancedSettings
                            },
                          },
                        },
                        [
                          _vm._v("Advanced Search\n                    "),
                          _c("i", {
                            staticClass: "fas mx-1",
                            class: {
                              "fa-caret-up": _vm.showAdvancedSettings,
                              "fa-caret-down": !_vm.showAdvancedSettings,
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "b-collapse",
                        {
                          staticClass: "mt-2",
                          attrs: { id: "collapse-4" },
                          model: {
                            value: _vm.showAdvancedSettings,
                            callback: function ($$v) {
                              _vm.showAdvancedSettings = $$v
                            },
                            expression: "showAdvancedSettings",
                          },
                        },
                        [
                          _vm.showAdvancedSettings
                            ? _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "input-group input-group-sm" },
                                  [
                                    _c("div", { staticClass: "container" }, [
                                      _c("div", { staticClass: "row p-1" }, [
                                        _c("div", { staticClass: "col-4" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "font-weight-light",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            Search\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-8" },
                                          [
                                            _c("iws-input", {
                                              attrs: {
                                                value: _vm.filters.searchFilter,
                                                placeholder:
                                                  "Pad location/name, job #, field system or fleet",
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.filters,
                                                    "searchFilter",
                                                    $event
                                                  )
                                                },
                                                change: _vm.filtersChanged,
                                                input: function ($event) {
                                                  _vm.searchChanged = true
                                                },
                                                enter: function ($event) {
                                                  return _vm.filtersChanged(
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row p-1" }, [
                                        _c("div", { staticClass: "col-4" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "font-weight-light",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            Start\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-8" },
                                          [
                                            _c("iws-date-time-picker", {
                                              attrs: {
                                                value: _vm.filters.rangeStart,
                                                disabled:
                                                  !_vm.filters.active &&
                                                  !_vm.filters.completed,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.filters,
                                                    "rangeStart",
                                                    $event
                                                  )
                                                },
                                                input: function ($event) {
                                                  return _vm.rangeChanged(
                                                    $event
                                                  )
                                                },
                                                close: function ($event) {
                                                  return _vm.validateRange(
                                                    "rangeStart"
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row p-1" }, [
                                        _c("div", { staticClass: "col-4" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "font-weight-light",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            End\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-8" },
                                          [
                                            _c("iws-date-time-picker", {
                                              attrs: {
                                                value: _vm.filters.rangeEnd,
                                                disabled:
                                                  !_vm.filters.active &&
                                                  !_vm.filters.completed,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.filters,
                                                    "rangeEnd",
                                                    $event
                                                  )
                                                },
                                                input: function ($event) {
                                                  return _vm.rangeChanged(
                                                    $event
                                                  )
                                                },
                                                close: function ($event) {
                                                  return _vm.validateRange(
                                                    "rangeEnd"
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row p-1" }, [
                                        _c("div", { staticClass: "col-4" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "font-weight-light",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                            Jobs\n                                        "
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "col-8" }, [
                                          _c("div", { staticClass: "d-flex" }, [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.filters.showTestJobs,
                                                  expression:
                                                    "filters.showTestJobs",
                                                },
                                              ],
                                              attrs: {
                                                id: "check_filterActive",
                                                type: "checkbox",
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.filters.showTestJobs
                                                )
                                                  ? _vm._i(
                                                      _vm.filters.showTestJobs,
                                                      null
                                                    ) > -1
                                                  : _vm.filters.showTestJobs,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.filters
                                                          .showTestJobs,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.filters,
                                                            "showTestJobs",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.filters,
                                                            "showTestJobs",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.filters,
                                                        "showTestJobs",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    _vm.searchChanged = true
                                                  },
                                                ],
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "font-weight-light",
                                                staticStyle: {
                                                  "padding-left": "7px",
                                                  "font-size": "medium",
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                Show test jobs\n                                            "
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.rangeError,
                                              expression: "rangeError",
                                            },
                                          ],
                                          staticClass:
                                            "alert alert-warning mt-2",
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    End date must be after start date.\n                                "
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ]
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticStyle: { width: "100%", "padding-top": "10px" },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { float: "right" } },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { type: "reset", size: "sm" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.resetFilter($event)
                                    },
                                  },
                                },
                                [_vm._v("Reset")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "pr-2 pl-2",
                                  attrs: {
                                    type: "submit",
                                    variant: _vm.searchChanged
                                      ? "primary"
                                      : "secondary",
                                    size: "sm",
                                    disabled: !_vm.searchChanged,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.filtersChanged($event)
                                    },
                                  },
                                },
                                [_vm._v("Search")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            {
              ref: "jobsListContainer",
              staticClass: "flex-grow-1",
              attrs: { id: "jobs-list-container" },
            },
            [
              _vm._l(_vm.jobsData, function (job) {
                return !_vm.isLoadingFilters
                  ? _c("job-summary-card", {
                      key: job.id,
                      attrs: {
                        job: job,
                        "is-admin-or-iws": _vm.isAdminOrIws,
                        "show-well-info": _vm.showWellInfo,
                      },
                    })
                  : _vm._e()
              }),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex justify-content-center py-2" }, [
                _vm.currentPage !== _vm.totalPages &&
                !_vm.loading &&
                !_vm.isLoadingFilters
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-primary btn-sm small",
                        on: { click: _vm.onLoadMoreClicked },
                      },
                      [_vm._v("Load more")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.loading ? _c("div", [_vm._v(" Loading... ")]) : _vm._e(),
                _vm._v(" "),
                _vm.isLoadingFilters
                  ? _c("div", [
                      _c("div", {
                        staticClass: "spinner-border spinner-border-sm",
                        staticStyle: { color: "white" },
                        attrs: { role: "status" },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-9 order-1 order-md-2" },
        [
          _c("div", { attrs: { id: "text-width-calculator" } }),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass:
                "d-flex flex-column text-md-center popover-positioning",
            },
            [
              _c(
                "div",
                { staticClass: "p-2" },
                [
                  _c(
                    "b-button",
                    {
                      staticClass: "popover-design",
                      attrs: {
                        id: "popover-button-sync",
                        variant: "secondary",
                      },
                    },
                    [
                      this.onlyActiveFilter
                        ? _c("div", [
                            _vm._v(
                              "Active Jobs: " + _vm._s(_vm.totalJobCount) + " "
                            ),
                          ])
                        : _c("div", [
                            _vm._v(
                              " Filtered Jobs: " +
                                _vm._s(_vm.totalJobCount) +
                                " "
                            ),
                          ]),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.areJobsReturned
                ? _c(
                    "div",
                    { staticClass: "p-4" },
                    [
                      _c(
                        "b-popover",
                        {
                          staticClass: ".popover.b-popover",
                          attrs: {
                            show: _vm.show,
                            target: "popover-button-sync",
                            triggers: "hover click",
                            placement: "bottom",
                          },
                          on: {
                            "update:show": function ($event) {
                              _vm.show = $event
                            },
                          },
                        },
                        [
                          _c("div", { staticClass: "popover-content" }, [
                            _vm.standardFracJobCount > 0
                              ? _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.standardFracName) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-4 pr-0 text-right" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.standardFracJobCount) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.simultaneousFracJobCount > 0
                              ? _c("div", { staticClass: "row mb-2" }, [
                                  _c("div", { staticClass: "col-8 pr-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.simultaneousFracName) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-3 p-0 text-right" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.simultaneousFracJobCount) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.continuousFracJobCount > 0
                              ? _c("div", { staticClass: "row mb-0 pb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.continuousFracName) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-4 pr-0 text-right" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.continuousFracJobCount) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.trimulFracJobCount > 0
                              ? _c("div", { staticClass: "row mb-0 pb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.trimulFracName) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-4 pr-0 text-right" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.trimulFracJobCount) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.cameraModuleJobCount > 0
                              ? _c("div", { staticClass: "row mb-0 pb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.cameraModuleName) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-4 pr-0 text-right" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.cameraModuleJobCount) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.filters.showTestJobs && _vm.testJobCount > 0
                              ? _c("div", { staticClass: "row mb-0 pb-0" }, [
                                  _c("div", { staticClass: "col-7 pr-0" }, [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.testJobName) +
                                        "\n                            "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-4 pr-0 text-right" },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(_vm.testJobCount) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _c(
            "gmap-map",
            {
              ref: "map",
              style: _vm.useSmallLayout
                ? "width: 100%; height: calc(80vh - 81px)"
                : "width: 100%; height: calc(100vh - 81px)",
              attrs: {
                center: { lat: 39.8097343, lng: -98.5556199 },
                zoom: 4.5,
                "map-type-id": "terrain",
                options: {
                  mapTypeControl: true,
                  mapTypeControlOptions: {
                    position: this.googleMapLayerControlTopRightPosition,
                  },
                },
              },
              on: {
                click: function ($event) {
                  return _vm.markerClicked(null)
                },
              },
            },
            [
              _c(
                "gmap-cluster",
                {
                  attrs: {
                    "zoom-on-click": true,
                    styles: [
                      {
                        textColor: "white",
                        url: "../images/cluster/blueMarker70px.png",
                        width: 70,
                        height: 70,
                        textSize: 20,
                      },
                    ],
                  },
                },
                _vm._l(_vm.allMarkers, function (m, index) {
                  return _c("gmap-marker", {
                    key: index,
                    attrs: {
                      position: m.position,
                      label: m.label,
                      icon: _vm.getMarkerIcon(m),
                      zIndex: 100 + index,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.markerClicked(m)
                      },
                    },
                  })
                }),
                1
              ),
              _vm._v(" "),
              _vm.activeMarkerJobId && _vm.markers.length > 0
                ? _c(
                    "gmap-info-window",
                    {
                      attrs: {
                        position: _vm.activeMarker.position,
                        options: {
                          padding: 0,
                        },
                      },
                    },
                    [
                      _c("div", { staticClass: "p-1 mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "row no-gutters label-header" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.activeMarker.label.text) +
                                "\n                    "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.activeTech && _vm.activeTech.email
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "clickable rounded border-primary row no-gutters align-items-center ml-1 mr-1 p-1",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToURL(
                                      "mailto:" + _vm.activeTech.email
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("span", { staticClass: "text-primary" }, [
                                    _c("i", {
                                      staticClass: "fas fa-envelope fa-2x",
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-10 pl-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-light" },
                                    [
                                      _vm._v(
                                        "Email " +
                                          _vm._s(_vm.activeTech.first_name) +
                                          " " +
                                          _vm._s(_vm.activeTech.last_name)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-muted font-weight-light",
                                      staticStyle: { "font-size": "small" },
                                    },
                                    [_vm._v(_vm._s(_vm.activeTech.email))]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.activeTech && _vm.activeTech.phone_number
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "clickable rounded border-primary row no-gutters align-items-center ml-1 mr-1 p-1",
                                on: {
                                  click: function ($event) {
                                    return _vm.goToURL(
                                      "tel:" + _vm.activeTech.phone_number
                                    )
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "col-2" }, [
                                  _c("span", { staticClass: "text-primary" }, [
                                    _c("i", {
                                      staticClass: "fas fa-phone-alt fa-2x",
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-10 pl-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "font-weight-light" },
                                    [
                                      _vm._v(
                                        "Call " +
                                          _vm._s(_vm.activeTech.first_name) +
                                          " " +
                                          _vm._s(_vm.activeTech.last_name)
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-muted font-weight-light",
                                      staticStyle: { "font-size": "small" },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(_vm.activeTech.phone_number)
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "clickable rounded border-primary row no-gutters align-items-center ml-1 mr-1 p-1",
                            on: {
                              click: function ($event) {
                                _vm.goToURL(
                                  _vm.getMarkerGoogleURL(_vm.activeMarker)
                                )
                              },
                            },
                          },
                          [
                            _c("div", { staticClass: "col-2" }, [
                              _c("span", { staticClass: "text-primary" }, [
                                _c("i", {
                                  staticClass: "fas fa-directions fa-2x",
                                }),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-10 pl-2 font-weight-light" },
                              [
                                _vm._v(
                                  "\n                            Get Directions\n                        "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("auth-fail-component", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasAuthError,
            expression: "hasAuthError",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "d-flex d-md-none justify-content-around" },
      [
        _c("div", {
          staticClass: "rounded my-3",
          staticStyle: {
            "background-color": "#666666",
            width: "32px",
            height: "8px",
          },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "font-weight-light" }, [
        _vm._v(
          "\n                            Job Status\n                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "font-weight-light" }, [
        _vm._v(
          "\n                            Job Type\n                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "font-weight-light" }, [
        _vm._v(
          "\n                            Customer\n                        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-4" }, [
      _c("div", { staticClass: "font-weight-light" }, [
        _vm._v(
          "\n                            Settings\n                        "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }