<template>
    <div>
        <library-base
            :items="currentTab.tickets.map(ticket => ticket.values)"
            :quickFilters="quickFilters"
            @setFilters="applyFilters"
            :columns="columns.map((c) => c.key)"
            :sortByCol="sortByCol"
            :sortAsc="sortAsc"
            :enableCreate="false"
            title="Support Tickets"
            :breadCrumbPath="[
                {
                    title: 'Dashboard',
                    link: '/home',
                },
                {
                    title: 'Support Tickets',
                    link: '/tickets',
                },
            ]"
            @openFilters="$refs.ticketStatusFiltersModal.open()"
        >
            <template #no-item-body>
                <div v-if="currentTab.error" class="py-4">
                    <div class="title">We've Encountered a Problem</div>
                    <div class="subtitle">{{ currentTab.error }}</div>
                    <button type="button" class="btn primary-color btn-primary mt-4" data-toggle="modal" data-target="#supportModal" href="">Contact Support</button>
                </div>
                <div v-else-if="currentTab.loading || currentTab.loading === null">
                    <div class="title">Loading Tickets...</div>
                    <div class="subtitle">Fetching the latest data on your support items</div>
                </div>
                <div v-else>
                    <div class="title">You have no {{ showClosed ? 'closed' : 'active' }} tickets at the moment.</div>
                    <div class="subtitle">Any tickets created will be visible here.</div>
                </div>
            </template>

            <template #tabs>
                <div class="active-filters">
                    <span v-for="filter in activeFilters" class="badge badge-outline">
                        {{ filter.label }}: {{ filter.values.map(fil => fil.label).join(', ') }}
                        <i class="fas fa-times clickable" @click="removeFilter(filter.key)"></i>
                    </span>
                </div>

                <iws-tabs
                    :tabs="[ 
                        { title: 'Open tickets' },
                        { title: 'Closed tickets' }
                    ]"
                    @tab-change="showClosed = $event === 1"
                    :showBody="false"
                >
                </iws-tabs>
            </template>

            <template #additional-actions="{ filteredItems: filteredTickets }">
                <button class="btn export" @click="exportTickets(filteredTickets)">
                    <i class="fa fa-download"></i> Export
                </button>
            </template>

            <template #table="{ filteredItems: filteredTickets }">
                <iws-table
                    :items="currentTab.tickets.map(ticket => ticket.values)"
                    :columns="['', ...tableColumns]"
                    :sortByCol.sync="sortByCol"
                    :sortAsc.sync="sortAsc"
                    :busy="currentTab.loading"
                >
                    <template #body>
                        <template v-for="ticket in filteredTickets">
                            <tr @click="expandTicket(ticket.id)" class="expandable_row">
                                <td>
                                    <span class="expand">
                                        <i v-if="selectedTicketId === ticket.id"
                                            class="fas fa-chevron-down"
                                        ></i>
                                        <i class="fas fa-chevron-right" v-else></i>
                                    </span>
                                </td>
                                <td v-for="col in tableColumns">{{ formatCell(ticket, col.key) }}</td>
                            </tr>
                            <tr v-if="selectedTicketId === ticket.id">
                                <td colspan="15" class="additional-info">
                                    <dl>
                                        <template v-for="col in detailColumns">
                                            <dt>{{ col.label }}</dt>
                                            <dd>{{ formatCell(ticket, col.key) }}</dd>
                                        </template>
                                    </dl>
                                </td>
                            </tr>
                        </template>
                    </template>
                </iws-table>

                <div class="text-center py-4">                    
                    <button v-if="currentTab.next" 
                        @click="fetchTickets"
                        class="btn btn-outline-light"
                        :disabled="currentTab.loading"
                    >{{ currentTab.loading ? "Loading..." : "Load More" }}</button>
                </div>
            </template>

            <template #card="{ filteredItems: filteredTickets }">
                
                <div class="gun-card-container" v-for="ticket in filteredTickets">
                    <dl>
                        <template v-for="col, index in [...tableColumns, ...detailColumns]"
                            v-if="index <= 4 || selectedTicketId === ticket.id"
                            class=""
                        >
                            <dt>{{ col.label }}</dt>
                            <dd>{{ formatCell(ticket, col.key) }}</dd>
                        </template>
                    </dl>
                    <div class="pt-2">
                        <button class="btn btn-link" @click="expandTicket(ticket.id)">
                            <span v-if="selectedTicketId === ticket.id">
                                Show Less
                                <i class="fas fa-chevron-up"></i>
                            </span>
                            <span v-else>
                                Show More
                                <i class="fas fa-chevron-down"></i>
                            </span>
                        </button>
                    </div>
                </div>

                <div class="text-center py-4">                    
                    <button v-if="currentTab.next" 
                        @click="fetchTickets"
                        class="btn btn-secondary"
                        :disabled="currentTab.loading"
                    >{{ currentTab.loading ? "Loading..." : "Load More" }}</button>
                </div>
            </template>
        </library-base>

        <ticket-status-filters-modal
            :active-filters="filters"
            :loading="pageMetadata.loading"
            :error="pageMetadata.error"
            :options="pageMetadata.options ?? null"
            ref="ticketStatusFiltersModal"
            @submit="applyFilters"
        />
    </div>
</template>

<script>
import moment from 'moment';

import LibraryBase from '../LibraryBase.vue';
import TicketStatusFiltersModal from './TicketStatusFiltersModal.vue';
import mapTicketData from './mapTicketData';
import PlugLibraryView from '../PlugLibraryView.vue';
import LibraryCrudActions from '../LibraryCrudActions.vue';

const defaultFilters = {
    incidentNumber: [],
    title: [],
    customerPadName: [],
    workOrder: [],
    severity: [],
    status: [],
    createdBy: [],
    reportedBy: [],
    currentOwner: [],
    resolutionType: [],
    product: [],
    priority: [],
    start: [],
    end: [],
};
const dateFormat = 'M/D/YYYY';
const columnOrder = [
    'incidentNumber', 'title', 'workOrder', 'customerPadName', 'department', 'contact',
    'createdBy', 'reportedBy', 'currentOwner', 'openDate', 'closedDate', 'severity',
    'status', 'resolutionType', 'resolutionSteps', 'occurence', 'priority', 'assetCategory',
    'assetForTicket', 'callout', 'product', 'productCategory1', 'productCategory2', 
    'productCategory3', 'description', 'technicianSteps', 'supportedBy', 'supportSteps'
];

export default {
    props: {
        role: String,
        customer: Object,
    },

    components: {
        LibraryBase,
        TicketStatusFiltersModal,
        PlugLibraryView,
        LibraryCrudActions
    },

    data: () => ({
        windowWidth: document.documentElement.clientWidth,
        selectedTicketId: null,
        sortByCol: 'openDate',
        sortAsc: false,
        filters: defaultFilters,
        showClosed: false,
        openTickets: {
            tickets: [],
            next: null,
            lastQuery: null,
            loading: null,
            error: null,
        },
        closedTickets: {
            tickets: [],
            next: null,
            lastQuery: null,
            loading: null,
            error: null,
        },
        columnOrder: columnOrder,
        pageMetadata: {
            options: null,
            openCount: null,
            error: null,
            loading: true,
        },
    }),

    mounted() {
        // tickets and metadata requested separately (metadata is slightly slower query)
        this.fetchTickets();
        this.fetchPageMetadata();
        window.addEventListener('resize', this.getDimensions);
    },

    unmounted() {
        window.removeEventListener('resize', this.getDimensions);
    },

    computed: {
        tableColumns () {
            // columns to display in table rows
            const display = ['table'];
            const closedOnlyFields = ['resolutionType',  'closedDate'];

            if (this.showFullTable) display.push('table-lg')

            return this.columns.filter(col => display.indexOf(col.display) !== -1)
                .filter(col => !(!this.showClosed && closedOnlyFields.indexOf(col.key) !== -1));
        },
        detailColumns () {
            // columns to display in "show more" view
            const display = ['detail'];
            const closedOnlyFields = ['resolutionType', 'closedDate'];
            
            if (!this.showFullTable) display.push('table-lg')

            return this.columns.filter(col => display.indexOf(col.display) !== -1)
                .filter(col => !(!this.showClosed && closedOnlyFields.indexOf(col.key) !== -1));
        },
        columns() {
            return mapTicketData(null, this.columnOrder).columns;
        },
        currentTab() {
            return this.showClosed ? this.closedTickets : this.openTickets;
        },
        showFullTable() {
            return this.windowWidth > 1920;
        },
        quickFilters() {
            if (!this.pageMetadata || !this.pageMetadata.options) return [];

            const weekAgo = moment().subtract(7, 'days').startOf('day');

            return [
                {
                    name: 'High Priority',
                    filters: {
                        priority: this.getFiltersByLabel('priority', ['Urgent'])
                    }
                },
                {
                    name: 'High Severity',
                    filters: {
                        severity: this.getFiltersByLabel('severity', ['Important', 'Urgent'])
                    }
                },
                {
                    name: 'Past 7 Days',
                    filters: {
                        start: [{
                            id: weekAgo.format('YYYY-MM-DD'),
                            label: weekAgo.format('M/D/YYYY'),
                        }]
                    }
                }
            ]
        },
        activeFilters() {
            const schema = mapTicketData();

            return Object.keys(this.filters)
                .filter(key => this.filters[key] && this.filters[key].length > 0)
                .map(key => ({
                    ...schema.column(key),
                    values: this.filters[key]
                }))
        },
        queryString() {
            const params = this.activeFilters.map(filter => `${filter.filterKey}=${filter.values.map(val => val.id).join(',')}`);
            params.push(this.showClosed ? 'closed=true' : 'open=true');
            
            return params.join('&');
        }
    },

    methods: {
        getDimensions() {
            this.windowWidth = document.documentElement.clientWidth;
        },
        getFiltersByLabel(filterKey, labels) {
            const filters = [];

            labels.forEach(label => {
                if (!this.pageMetadata.options) return;
                
                const filter = this.pageMetadata?.options[filterKey]?.find(opt => opt.label === label)

                if (!filter) return;

                filters.push(filter);
            })
            
            return filters;
        },
        expandTicket(ticketId) {
            this.selectedTicketId = this.selectedTicketId && this.selectedTicketId === ticketId ? null : ticketId;
        },
        applyFilters(filters) {
            const newValue = { ...this.filters, ...filters };

            if (this.filters === newValue) return;

            this.filters = newValue;

            this.fetchTickets();
        },
        formatCell(ticket, key) {
            const column = this.columns.find(col => col.key === key);
            const format = column?.format ?? null;
            const cell = ticket[key];

            if (cell === null || cell === undefined) return 'N/A';
            
            if (format === 'date') return moment(cell).format(dateFormat);
            
            if (format === 'boolean') return cell === false ? 'No' : 'Yes';

            return cell;
        },
        exportTickets(tickets) {
            // Build CSV File
            const columns = [...this.tableColumns, ...this.detailColumns];
            const header = columns.map(col => col.label).join(',') + '\n';

            const rows = tickets.map(ticket => columns.map(col => {
                // escape commas and double quotes per csv spec
                return ticket[col.key] ? `"${ticket[col.key].replaceAll('"', '""')}"` : '';
            }).join(',') + '\n');
            
            const csvContent = header + rows.join('');
            const blob = new Blob([csvContent], {
                type: 'text/csv;charset=utf-8;',
            });

            // Request download
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute('download', `IWS_Tickets_${moment().format('YYYY-MM-DD')}.csv`);
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },
        async fetchTickets() {
            const schema = mapTicketData();

            // determine tab to update before awaiting request, in case user switches while loading
            const tab = this.showClosed ? this.closedTickets : this.openTickets;

            const url = '/tickets/get?' + this.queryString;
            let pagingQuery = null;

            if ((url === tab.lastQuery && !tab.next) || tab.loading === true) {
                return;
            } else if (url === tab.lastQuery && tab.next) {
                // request is the same as previous and more data available on next page
                pagingQuery = '&skiptoken=' + tab.next;
            }
            
            // If not fetching next page, reset current tab
            if (!pagingQuery) {
                tab.tickets = [];
                tab.next = null;
            }
            
            tab.error = null;
            tab.loading = true;

            try {
                const response = await $.get(url + (pagingQuery ?? ''));
                const tickets = response.tickets.map(ticket => mapTicketData(ticket, this.columnOrder));

                tab.tickets = tab.tickets.concat(tickets);
                tab.next = response.next;
                tab.lastQuery = url;
            } catch (error) {
                tab.error = error.responseJSON?.message ?? 'Error occured during data retrieval';
            }

            tab.loading = false;
        },
        async fetchPageMetadata () {
            // fetches metadata needed to use view like record counts and form field data

            try {
                const meta = await $.get('/tickets/meta');
                
                Object.keys(meta.options).forEach(optKey => {
                    const values = meta.options[optKey];

                    if (values[0] && typeof values[0] === 'object') return;

                    // consistent format for filters & filter form fields
                    meta.options[optKey] = values.map(val => ({
                        id: val,
                        label: val,
                    }));
                });

                this.pageMetadata = {
                    ...this.pageMetadata,
                    ...meta,
                    loading: false,
                };
            } catch (error) {
                this.pageMetadata.loading = false;
                this.pageMetadata.error = error.responseJSON?.message ?? 'Error occured fetching metadata';
            }
        },
        removeFilter(key, index) {
            if (index) this.filters[key].splice(index, 1)
            else this.filters[key] = [];
            this.fetchTickets();
        }
    },
    watch: {
        showClosed(newValue, oldValue) {
            if (oldValue === newValue) return;
            this.fetchTickets();
        }
    }
};
</script>

<style scoped>
.additional-info {
    background: #343b42;
}

dl {
    display: grid;
    grid-template-columns: max-content auto;
    background: #242a30;
    border: 1px solid #7b8a98;
    border-radius: 4px;
    padding: 8px 20px;
    margin: 0;
}

.gun-card-container dl {
    border: 0;
    padding: 0px 12px;
}

dt {
    grid-column-start: 1;
    width: 200px;
}

dd {
    grid-column-start: 2;
    margin-bottom: 0;
}

dt, dd {
    border-bottom: 1px solid #7b8a98;
    padding: 6px 0;
}

@media (min-width: 1200px) {
    dt, dd {
        padding: 12px 0;
    }
}

dt:nth-last-child(-n + 2),
dd:nth-last-child(-n + 2) {
    border-bottom: none;
}

.expandable_row {
    cursor: pointer;
}

.expand {
    background: #2e353d;
    border-radius: 4px;
    padding: 7px;
    width: 28px;
    text-align: center;
    display: inline-block;
}

.badge {
    border-radius: 16px;
    font-size: 14px;
    font-weight: normal;
    background: #16181b;
    border: 1px solid #9FAABF;
    border-radius: 30px;
    padding: 2px 12px;
    color: #fff;
    margin-left: 6px;
    margin-top: 12px;
}

.badge__open {
    background: rgba(187, 23, 12, 0.2);
    color: #d70e00;
}

.badge__closed {
    background: #1f392a;
    color: #12b76a;
}

.export {
    color: #fff;
    font-size: 14px;
}
</style>
