<template>
<div class="kpi-item">
    <slot name="kpi-title">
        <span class="kpi-title">
            {{ title }}
        </span>
    </slot>

    <!-- Dynamic KPI tooltip provided via tooltip management -->
    <tooltip-component 
        id="tooltip-container"
        :tooltip_key="kpiTooltipKeys[item.options.type]"
        :customer_id="$parent.customer.id"
    />

    <!-- Extra Info -->
    <div ref="extraInfo" class="extra-info-container">
        <slot name="extra-info">
        </slot>
    </div>

    <iws-alert v-if="showNotEnoughDataWarning"
        text="Not enough data to generate this KPI summary!"
        icon="fas fa-exclamation-circle"
        type="danger"
        style="margin-top: 10px"
    />
    <div v-else-if="!showChart" class="full-width" style="padding: 10% 0px; text-align: center;">
        <div class="spinner-border light-text-color" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
    <template v-else>
        <!-- Open Export button -->
        <i v-if="!editMode && !exportOverlayMode"
            id="export-container"
            v-tooltip:top="'Export'"
            class="fas fa-file-download clickable"
            @click="exportOverlayMode = true"
        />

        <!-- Analytics Info -->
        <div v-if="!_isNullOrEmpty(analyticsData)" class="d-flex flex-wrap" id="analytics-container" ref="analyticsInfo">
            <div v-for="analytic in analyticsData"
                class="d-flex align-items-center"
                :style="{
                    'flex-basis': `calc(100% / ${columnCount})`,
                    'max-width': `calc(100% / ${columnCount})`,
                }"
            >
                <span class="kpi-box" :style="{ 'background-color': analytic.backgroundColor }"></span>
                {{ analytic.label }}
            </div>
        </div>

        <!-- Chart Content -->
        <slot name="content">
        </slot>
    </template>

    <div v-if="_isIterable(errors)" ref="errorsInfo">
        <div v-for="error in errors" class="danger-text-color text-center">
            {{ error?.message || error }}
        </div>
    </div>

    <!--Edit Overlay (Slot: 'settings') -->
    <template v-if="editMode">
        <div class="overlay"></div>
        <div class="edit-container">
            <iws-select
                :options="kpiTypes"
                :value="item.options.type"
                label="KPI Type"
                display-name="label"
                value-name="value"
                :visible-options="6"
                @change="onTypeChange"
            />

            <iws-select v-if="item.options.type != 'kpi-progress'"
                :options="analyticTypes"
                :value="item.options.analyticsType"
                label="Analytics"
                :placeholder="!_isNullOrEmpty(item?.options?.analyticsType) ? item?.options?.analyticsType.join(', ') : '0 selected'"
                :multiselect="true"
                :maintain-on-select="true"
                form-spacing
                @change="onAnalyticsChange"
                full-placeholder
            />

            <iws-select v-if="showZeroValueAnalyticField"
                label="Treatment of Zero Values in Analytics"
                :options="zeroStageTypes"
                :value.sync="item.options.zeroStageType"
                display-name="label"
                value-name="value"
                form-spacing
                @change="$emit('analyticsChanges', $event)"
            />

            <iws-select v-if="item.options.type !== 'kpi-swapTime' && item.options.type !== 'kpi-metricsPerStage'"
                label="Time Range"
                :options="$parent.dayTypes"
                :value.sync="item.options.dayType"
                form-spacing
                @change="$emit('settingsChanges', $event)"
            />

            <slot name="settings">
            </slot>

            <div class="mt-2 float-right">
                <iws-button
                    text="Revert"
                    type="danger"
                    :click="revertConfig"
                />
            </div>
        </div>
    </template>
    <!--Export Overlay (Slot: 'export') -->
    <template v-else-if="exportOverlayMode">
        <div class="overlay"></div>
        <div class="export-container">
            <iws-select
                label="Export Type"
                :options="exportTypes"
                :value.sync="selectedExportType"
            />

            <slot name="export">
            </slot>

            <div class="mt-2 float-right">
                <iws-button
                    text="Cancel"
                    type="light"
                    @click="exportOverlayMode = false"
                />

                <iws-button class="ml-2"
                    text="Export"
                    type="primary"
                    :click="exportChart"
                    :disabled="!isChartExportable()"
                />
            </div>
        </div>
    </template>
</div>
</template>

<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, isIterable, toast } = GlobalFunctions;

export default {
    props: {
        editMode: Boolean,
        item: Object,
        initalOptions: Object,
        createExportData: Function,
        analyticsData: Array,
        errors: Array,
        title: String,
        showNotEnoughDataWarning: Boolean,
        showChart: Boolean
    },

    data: () => ({
        exportOverlayMode: false,
        selectedExportType: 'csv',

        kpiTypes: [
            { label: 'Total Stages Per Day', value: 'kpi-total' },
            { label: 'Cumulative Complete Stages', value: 'kpi-progress' },
            { label: 'Lateral Length Completed', value: 'kpi-lateral' },
            { label: 'Pump Time Summary', value: 'kpi-pumpingHours' },
            { label: 'Standby / Transition Times', value: 'kpi-swapTime' },
            { label: 'Metrics Per Stage', value: 'kpi-metricsPerStage' }
        ],
        analyticTypes: [ 
            'Average', 'Median'
        ],
        zeroStageTypes: [
            { label: 'Include days with 0 values', value: 'zeroStage-include'},
            { label: 'No leading days with 0 values', value: 'zeroStage-nonLeading'},
            { label: 'No days with 0 values', value: 'zeroStage-none'}
        ],
        exportTypes: [
            'csv', 'json'
        ],
        kpiTooltipKeys: {
            'kpi-total': "kpi-total-stages",
            'kpi-progress': "kpi-completion-progress",
            'kpi-lateral': "kpi-lateral-length-completed",
            'kpi-pumpingHours': "kpi-pumping-hours-per-day",
            'kpi-swapTime': 'kpi-swapTime',
            'kpi-metricsPerStage': 'kpi-metricsPerStage'
        }
    }),

    computed: {
        isDayNight: function() {
            return this.item.options.dayType === 'Day shift and night shift';
        },
        showZeroValueAnalyticField() {
            return !isNullOrEmpty(this.item?.options?.analyticsType) && !['kpi-swapTime', 'kpi-metricsPerStage', 'kpi-progress'].includes(this.item.options.type);
        },
        columnCount() {
            if (this.analyticsData.length <= 3)
                return this.analyticsData.length;
            if (this.analyticsData.length === 4)
                return 2;
            if (this.analyticsData.length === 8)
                return 4;
            return 3;
        }
    },

    methods: {
        _isFalsy: (val) => isFalsy(val),
        _isNullOrEmpty: (val) => isNullOrEmpty(val),
        _isIterable: (val) => isIterable(val),

        onTypeChange: function(newValue) {
            // Clear all non common options when switching types (setDefaults resets the new kpis needed values with setters/getters)
            this.item.options = {}
            
            this.$set(this.item.options, 'type', newValue);
            this.$set(this.item.options, 'analyticsType', []);
        },
        onAnalyticsChange: function(value) {
            // If not set yet, analyticsType will not exist in the object (since a selection is now made we default it to [])
            if (this.item.options.analyticsType == null)
                this.$set(this.item.options, 'analyticsType', []);

            const _index = this.item.options.analyticsType.findIndex(_value => _value == value);

            if (_index < 0) {
                this.item.options.analyticsType.push(value);
            } else {
                this.item.options.analyticsType.splice(_index, 1);
            }

            this.$emit('analyticsChanges');
        },
        revertConfig: function() {
            this.item.options = _.cloneDeep(this.initalOptions);

            toast({
                title: 'Your changes have been reverted.',
                variant: 'success'
            });

            this.$emit('revertChanges');
        },
        isChartExportable() {
            /* Determine if current configuration of chart is exportable.
               To be used for export overlay confirmation button. */
            if (this.showNotEnoughDataWarning)
                return false;
            if (this.item.options.type == 'kpi-pumpingHours' && this.isDayNight && (!this.pumpTimeData.day || !this.pumpTimeData.night))
                return false;
            return true;
        },
        exportCSV(fields, items, fileName) {
            let csv = [];

            // Compose header/field name row, add it to csv data
            csv.push([...fields]);

            // Add data rows after header row
            csv.push(...items);

            // Compile export data and format:
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob([csv.join('\r\n')]));
            link.setAttribute('download', fileName + '.csv');

            document.body.appendChild(link);
            link.click();
        },
        exportJSON(fields, items, fileName) {
            let exportObject = {};

            for (let i = 0; i < items.length; i++) {
                exportObject[i] = {};
                for (let f = 0; f < fields.length; f++) {
                    exportObject[i][fields[f]] = items[i][f];
                }
            }

            let data = "text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObject));

            // Format
            const link = document.createElement('a');
            link.href = 'data:' + data;
            link.setAttribute('download', fileName + '.json');

            document.body.appendChild(link);
            link.click();
        },
        exportChart() {
            // Call custom export
            const { fields, items, fileName } = this.createExportData();

            if (isFalsy(fileName))
                return toast({
                    title: 'Export failed',
                    body: `Invalid file name: ${filename}`,
                    variant: 'danger'
                });

            if (this.selectedExportType == 'csv') {
                this.exportCSV(fields, items, fileName);
            } else {
                this.exportJSON(fields, items, fileName);
            }

            this.exportOverlayMode = false;
        }
    }
}
</script>

<style>
    /* 
        The base component provides unscoped classes to be used by the inheriting kpi components
        To avoid affecting unintended files, we bury everything under .kpi-item
    */

    /* Compact this "form" more than normal to fit into the min kpi box */
    .kpi-item .edit-container .form-input-spacing,
    .kpi-item .export-container .form-input-spacing {
        margin-top: 7.5px !important;
    }
    .kpi-item .edit-container label,
    .kpi-item .export-container label {
        margin-bottom: 0px !important;
    }

    .kpi-item .kpi-box,
    .kpi-item .kpi-circle {
        display: inline-block;

        height: 10px;
        width: 10px;

        margin-right: 5px;
    }
    .kpi-item .kpi-circle {
        border-radius: 50%;
    }

    .kpi-item #tooltip-container h4 {
        margin-bottom: 0px !important;
    }
</style>
<style scoped>
    .kpi-item {
        position: relative;
        background-color: #242A30;
        border: 1px solid #7B8A98;
        border-radius: 4px;
        margin: 5px;
        padding: 12px;

        height: 100%;
    }

    .kpi-item .kpi-title {
        font-size: 1.5rem;
        margin-bottom: .5rem;
        font-family: inherit;
        font-weight: 500;
        line-height: 1.2;
    }
    .overlay {
        position: absolute;
        top: 0px;
        left: 0px;

        height: 100%;
        width: 100%;

        background-color: black;
        opacity: 35%;
    }
    .edit-container,
    .export-container {
        position: absolute;
        top: 1px;
        right: 15px;

        z-index: 10000;

        width: 80%;
        max-width: 300px;
        padding: 0px 8px 4px;

        background-color: rgba(36, 42, 48, 0.90);
        border: thin solid #ccc;
        border-radius: 4px;
    }
    .export-container {
        top: 15px;
    }

    #tooltip-container {
        display: inline-block;
    }

    #export-container {
        position: absolute;
        top: 15px;
        right: 15px;

        font-size: 20px;
        color: #999;
    }
    #export-container:hover {
        color: white !important;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        transition: all .5s ease;
    }

    #analytics-container {
        padding: 4px 0px;
    }

    .extra-info-container {
        padding: 4px 0px;
    }
    .extra-info {
        position: relative;
        background-color: #2E353D;
        border-radius: .25rem;
        padding: 8px 12px;
    }
</style>