<template>
    <div class="d-flex flex-column p-3" style="border-bottom: 2px solid #666666;">
        <a :href="jobPath(job)" style="text-decoration: none; color: inherit;">
            <div class="d-flex flex-row justify-content-between" style="font-size:large;">
                <div v-bind:id="'jobLocationDiv-' + job.id" class="font-weight-bold">{{ jobMainTitle(job) }}</div>
                <div class="font-weight-light">{{ job.companyName }}</div>
            </div>
            <div class="d-flex flex-row justify-content-between" style="font-size:small;">
                <div class="font-weight-bold">{{ jobSecondaryTitle(job) }}</div>
                <a v-if="isAdminOrIws === '1'" :href="'/jobs/' + job.jobNumber + '/edit'"
                   style="text-decoration: none; color: inherit;">
                    <span class="badge badge-primary">Edit</span>
                </a>
            </div>
            <div v-if="job.start" class="text-muted" style="font-size:small;">{{
                    convertDate(job.start, job.hourOffset)
                }} to {{ convertDate(job.end, job.hourOffset) }}<span v-if="job.end == null"
                                                                      class="badge badge-success">On-going</span></div>
            <div v-else-if="job.scheduledStart" class="text-muted" style="font-size:small;"><span v-if="job.end == null"
                                                                                                  class="badge badge-success">Projected</span>
                {{ convertDate(job.scheduledStart, job.hourOffset) }}
            </div>
            <div v-if="numberOfStagesAllWells(job) > 0" class="progress my-3" style="height: 30px;">
                <div
                    :class="['progress-bar', job.completedStages == numberOfStagesAllWells(job) ? 'bg-success' : 'bg-primary']"
                    role="progressbar" :style="{width: completedPercent(job) + '%'}"
                    :aria-valuenow="completedPercent(job)" aria-valuemin="0" aria-valuemax="100">
                    <div class="px-1 progress-bar-title-left"><b>{{ job.completedStages }}</b> of
                        <b>{{ numberOfStagesAllWells(job) }}</b> Stages Completed
                    </div>
                    <div class="px-1 progress-bar-title-right">{{ completedPercent(job) }}%</div>
                </div>
            </div>
            <div v-if="showWellInfo">
                <div v-if="job.wells.length > 0 && !job.jobNumber.includes('CM')">
                    <div v-for="well in job.wells" :key="well.id" class="row my-3 mx-2" style="font-size:small;">
                        <div class="col-2 text-truncate" style="font-size:large; padding-left:0px; padding-right:0px">
                            <b>{{ well.name }}</b>
                        </div>
                        <div class="col-sm-12 col-md-12 col-lg-10 col-xl-10"
                             style="padding-left:0px; padding-right:0px">
                            <div class="progress" style="height: 30px;">
                                <div :class="['progress-bar']" role="progressbar"
                                     :style="{width: completedPercentWell(well) + '%', 'background-color':well.color}"
                                     :aria-valuenow="completedPercentWell(well)" aria-valuemin="0" aria-valuemax="100">
                                    <div class="px-1 progress-bar-title-left"
                                         :style="{ 'font-size' : 'small', 'color': getTitleColorForBG(well.color)}">
                                        {{ getActivityString(well.activity) }}
                                    </div>
                                    <div class="px-1 progress-bar-title-right"
                                         :style="{ 'font-size' : 'small', 'color': getTitleColorForBG(well.color)}">
                                        Stage {{ well.currentStage || '--' }} / {{ well.numberOfStages || '--' }}
                                        ({{ completedPercentWell(well) }}%)
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="jobHasDayTech(job) || jobHasNightTech(job)">
                <div class="row text-center mx-3 mt-2" style="font-size:small;">
                    <div class="col-12 text-center font-weight-bold">IWS Field Support</div>
                </div>
                <div class="row text-center" style="font-size:small;">
                    <a v-if="jobHasDayTech(job)"
                       :href="'tel:' + job.techUserDaytime.phone_number"
                       target="_blank"
                       :class="{
                                        'col-6': jobHasDayTech(job) && jobHasNightTech(job),
                                        'col-12': !(jobHasDayTech(job) && jobHasNightTech(job)),
                                         'text-center': true}"
                       style="text-decoration: none; color: inherit;">
                        {{ job.techUserDaytime.first_name }}
                        {{ job.techUserDaytime.last_name }}<br>{{ job.techUserDaytime.phone_number }}
                    </a>
                    <a v-if="jobHasNightTech(job)"
                       :href="'tel:' + job.techUserNight.phone_number"
                       target="_blank"
                       :class="{
                                        'col-6': jobHasDayTech(job) && jobHasNightTech(job),
                                        'col-12': !(jobHasDayTech(job) && jobHasNightTech(job)),
                                         'text-center': true}"
                       style="text-decoration: none; color: inherit;">
                        {{ job.techUserNight.first_name }}
                        {{ job.techUserNight.last_name }}<br>{{ job.techUserNight.phone_number }}
                    </a>
                </div>
            </div>
        </a>
    </div>
</template>

<style scoped>
.progress {
    background-color: #AAAAAA;
    -webkit-box-shadow: none;
    box-shadow: none;
    position: relative;
}
.progress-bar-title-left {
    position: absolute;
    text-align: left;
    line-height: 30px; /* line-height should be equal to bar height */
    font-size: medium;
    overflow: hidden;
    color: #fff;
    right: 0;
    left: 0;
    top: 0;
}
.progress-bar-title-right {
    position: absolute;
    text-align: right;
    line-height: 30px; /* line-height should be equal to bar height */
    font-size: medium;
    overflow: hidden;
    color: #fff;
    right: 0;
    left: 0;
    top: 0;
}

</style>

<script>
import moment from "moment/moment";
import JobMixin from '../mixins/JobMixin.js';
import GlobalFunctions from "../GlobalFunctions.js";

export default {
    props: {
        job: {
            type: Object,
            required: true
        },
        showWellInfo: {
            type: Boolean,
            required: true
        },
        isAdminOrIws: {
            type: String,
            required: true
        }

    },
    mixins: [JobMixin],
    methods: {
        convertDate: function (date, hourOffset) {
            if (!date) return '';
            return moment(date, 'YYYY-MM-DD HH:mm')
                .add(hourOffset, 'hours')
                .format('MMMM Do YYYY');
        },
        jobHasDayTech: function (job) {
            return job.techUserDaytime && job.techUserDaytime.phone_number;
        },
        jobHasNightTech: function (job) {
            return job.techUserNight && job.techUserNight.phone_number;
        },
        numberOfStagesAllWells: function (job) {
            if (job.jobNumber.includes('CM')) {
                return 0;
            }
            return job.wells.reduce((a, b) => a + b.numberOfStages, 0);
        },
        completedPercent: function (job) {
            return (job.completedStages / this.numberOfStagesAllWells(job) * 100).toFixed(1);
        },
        completedPercentWell: function (well) {
            if (!well.currentStage) {
                return 0;
            } else {
                return (well.currentStage / well.numberOfStages * 100).toFixed(1);
            }
        },
        getTitleColorForBG: function (bgColor) {
            return GlobalFunctions.getTitleColorForBG(bgColor);
        },
        jobPath: function (job) {
            if (job.jobNumber.includes('CM')) {
                return '/cameras/viewer/' + job.jobNumber;
            }
            return '/dashboards/' + job.jobNumber
        },
        getActivityString: function(activityType) {
            if (activityType === 'frac') {
                return 'Frac';
            } else if (activityType === 'wireline') {
                return 'Wireline';
            } else {
                return 'Idle';
            }
        },
    }
}
</script>
