var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      style: { fill: _vm.color, stroke: _vm.color },
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "-50 0 1010 750",
      },
    },
    [
      _c("rect", {
        staticClass: "cls-3",
        attrs: { x: "15", y: "564", width: "980", height: "130", rx: "55" },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M473,15h64a30,30,0,0,1,30,30V563a0,0,0,0,1,0,0H443a0,0,0,0,1,0,0V45A30,30,0,0,1,473,15Z",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M526,705c0-291.29,166.83-527,373-527",
          transform: "translate(-455 -141)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M1395,705c0-286.87-167.83-527-374-527",
          transform: "translate(-455 -141)",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }