<template>
    <div v-if="show"
        @click.self="close"
        class="ticket-status-filter-modal dark-modal modal show"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Filter Tickets</h5>

                    <button type="button" class="close" @click="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div class="modal-body pb-5" v-if="options">
                    <ticket-status-filter 
                        :filter="filters.incidentNumber"
                        :options="options.incidentNumber"
                        filter-key="incidentNumber"
                        label="Incident Number"
                        display-name="label"
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <ticket-status-filter 
                        :filter="filters.title"
                        :options="options.title"
                        filter-key="title"
                        label="Ticket Title"
                        display-name="label"
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <ticket-status-filter 
                        :filter="filters.workOrder"
                        :options="options.workOrder"
                        filter-key="workOrder"
                        label="Work Order Number"
                        display-name="label"
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <ticket-status-filter 
                        :filter="filters.customerPadName"
                        :options="options.customerPadName"
                        filter-key="customerPadName"
                        label="Pad Name"
                        display-name="label"
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <ticket-status-filter 
                        :filter="filters.severity"
                        :options="options.severity"
                        display-name="label"
                        filter-key="severity"
                        label="Severity"
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <ticket-status-filter 
                        :filter="filters.priority"
                        :options="options.priority"
                        display-name="label"
                        filter-key="priority"
                        label="Priority"
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <ticket-status-filter 
                        :filter="filters.product"
                        :options="options.product"
                        display-name="label"
                        filter-key="product"
                        label="Product" 
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <div class="px-3 pb-3 mt-3 border border-secondary rounded">
                        <div class="row">
                            <div class="col-md">
                                <ticket-status-filter
                                    :filter="filters.start"
                                    filter-key="start"
                                    label="Start Date"
                                    @remove="startDate = null"
                                >
                                    <v-date-picker v-model="startDate" mode="date" is-dark>
                                        <template v-slot="{ inputValue, showPopover, hidePopover }">
                                            <input
                                                class="form-control ticket-status-filter__input"
                                                :value="inputValue"
                                                @focusin="showPopover"
                                                @focusout="hidePopover"
                                                placeholder="Select Start Date"
                                            />
                                        </template>
                                    </v-date-picker>
                                </ticket-status-filter>
                            </div>
                            <div class="col-md">
                                <ticket-status-filter
                                    :filter="filters.end"
                                    filter-key="end"
                                    label="End Date"
                                    @remove="endDate = null"
                                >
                                    <v-date-picker v-model="endDate" mode="date" is-dark>
                                        <template v-slot="{ inputValue, showPopover, hidePopover }">
                                            <input
                                                class="form-control ticket-status-filter__input"
                                                :value="inputValue"
                                                @focusin="showPopover"
                                                @focusout="hidePopover"
                                                placeholder="Select End Date"
                                            />
                                        </template>
                                    </v-date-picker>
                                </ticket-status-filter>
                                
                            </div>
                        </div>
                    </div>

                    <ticket-status-filter 
                        :filter="filters.currentOwner"
                        :options="options.currentOwner"
                        display-name="label"
                        filter-key="currentOwner"
                        label="Current Owner" 
                        @change="handleChange"
                        @remove="removeFilter"
                    />

                    <div class="row">
                        <div class="col-md">
                            <ticket-status-filter 
                                :filter="filters.createdBy"
                                :options="options.createdBy"
                                display-name="label"
                                filter-key="createdBy"
                                label="Created By" 
                                @change="handleChange"
                                @remove="removeFilter"
                            />
                        </div>
                        <div class="col-md">
                            <ticket-status-filter 
                                :filter="filters.reportedBy"
                                :options="options.reportedBy"
                                display-name="label"
                                filter-key="reportedBy"
                                label="Reported By" 
                                @change="handleChange"
                                @remove="removeFilter"
                            />
                        </div>
                    </div>
                </div>

                <div class="modal-body text-center p-4" v-else-if="error">
                    <h4>We've Encountered a Problem</h4>
                    <p>{{ error }}</p>
                </div>

                <div class="modal-body" v-else-if="loading">
                    Loading data for fields...
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline btn-outline-light" @click="close">
                        Close
                    </button>

                    <button v-if="error" type="button" class="btn primary-color btn-primary" data-toggle="modal" data-target="#supportModal">Contact Support</button>
                    <button v-else type="button" class="btn primary-color btn-primary" @click="submit">
                        Apply Filters
                    </button>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import TicketStatusFilter from './TicketStatusFilter.vue';

export default {
    props: {
        activeFilters: Object,
        options: Object,
        loading: Boolean,
        error: String,
    },

    components: {
        TicketStatusFilter,
    },

    data: () => ({
        filters: {
            incidentNumber: [],
            title: [],
            customerPadName: [],
            workOrder: [],
            severity: [],
            status: [],
            createdBy: [],
            reportedBy: [],
            currentOwner: [],
            resolutionType: [],
            product: [],
            priority: [],
            start: [],
            end: [],
        },
        startDate: null,
        endDate: null,
        openDate: null,
        closedDate: null,
        show: false
    }),
    methods: {
        handleChange(field, value) {
            const index = field.indexOf(value);

            if (index === -1) {
                field.push(value)
            } else {
                field.splice(index, 1)
            }
        },
        submit() {
            this.$emit('submit', this.filters);
            this.close();
        },
        open() {
            this.$emit('open');
            this.show = true;
        },
        close() {
            this.$emit('close');
            this.show = false;
        },
        removeFilter(key, index) {
            this.filters[key].splice(index, 1);
        }
    },
    watch: {
        show(newValue) {
            // enable/disable background scroll
            if (newValue) {
                document.body.style.height = '100vh';
                document.body.style.overflow = 'hidden';
            } else {
                document.body.style.height = null;
                document.body.style.overflow = null;
            }

            // Copy filter values from props, avoid shallow reference  
            Object.keys(this.activeFilters).forEach(filterKey => {
                this.filters[filterKey] = this.filters[filterKey] = [].concat(this.activeFilters[filterKey]);
            });

            if (this.filters.start.length > 0)
                this.startDate = moment(this.filters.start[0].label).toDate();

            if (this.filters.end.length > 0)
                this.endDate = moment(this.filters.end[0].label).toDate();
        },
        startDate(newValue) {
            this.filters.start = newValue ? [{
                id: moment(newValue).format('YYYY-MM-DD'),
                label: moment(newValue).format('M/D/YYYY'),
            }] : [];
        },
        endDate(newValue) {
            this.filters.end = newValue ? [{
                id: moment(newValue).format('YYYY-MM-DD'),
                label: moment(newValue).format('M/D/YYYY'),
            }] : [];
        },
    }
};
</script>

<style>
    .ticket-status-filter-modal.modal.dark-modal {
        overflow-y: auto;
        overflow-x: hidden;
        padding: 30px 20px 70px;
        margin-right: -20px;
        margin-left: -20px;
        width: calc(100% + 40px);
    }
    .ticket-status-filter-modal .modal-body {
        max-height: none !important;
        overflow-y: visible;
    }
</style>