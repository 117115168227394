var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("CustomDatePicker", {
    attrs: {
      type: _vm.dateTimeType,
      disabled: _vm.disabled,
      placeholder: _vm.placeholder,
      "value-type": _vm.dateTimeFormat,
      use12h: "",
      clearable: "",
    },
    on: { input: _vm.updateValue, close: _vm.onClose },
    model: {
      value: _vm.localValue,
      callback: function ($$v) {
        _vm.localValue = $$v
      },
      expression: "localValue",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }