var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "infoBox",
      staticClass: "d-flex border border-danger rounded px-2 mx-2 mb-2 mt-1",
      staticStyle: { height: "26px", width: "171px" },
    },
    [
      _c("div", { staticClass: "align-self-center" }, [
        _vm._v("\n        Latency :\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex align-items-center" },
        _vm._l(_vm.circleStatus, function (circle, index) {
          return _c("div", {
            key: index,
            staticClass: "rounded-circle mx-1 circle-css",
            class: circle.isFlashing ? "blink_me" : "",
            style: { ..._vm.cssVars, backgroundColor: circle.color },
            attrs: { id: "missed-tag-" + index },
          })
        }),
        0
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "latency-tags-dropdown",
            triggers: "hover",
            placement: "bottom",
            delay: { show: 0, hide: 500 },
            boundary: "viewport",
          },
        },
        [
          _c("div", { staticClass: "text-white h6 mb-0" }, [
            _vm._v("\n            Active wells "),
            _c("span", [
              _c("small", [
                _vm._v(
                  " ( Frac or Wireline tags will be tracked based on well status ) "
                ),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "pl-2 pb-2 text-white" },
            [
              _vm._l(_vm.activeWells, function (aWell, index) {
                return _c("div", { key: index }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(aWell.name) +
                      " - " +
                      _vm._s(aWell.activity) +
                      "\n            "
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.activeWells.length == 0
                ? _c("div", [_vm._v(" No wells are active")])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column mt-2" }, [
            _c(
              "div",
              [
                _vm.fracTags.length === 0
                  ? _c("div", { staticClass: "text-white pl-2" }, [
                      _c("small", [_vm._v("No active frac tags")]),
                    ])
                  : _c("checkbox-list", {
                      attrs: {
                        enableSelectAllOption: true,
                        enableSearch: true,
                        label: "Frac tags",
                        valueKey: "prioritizedTagName",
                        outputKey: "name",
                        height: "200",
                        options: _vm.fracTags,
                        selectedOptions: _vm.selectedFracTags,
                      },
                      on: {
                        "update:selectedOptions": function ($event) {
                          _vm.selectedFracTags = $event
                        },
                      },
                    }),
                _vm._v(" "),
                _vm.wirelineTags.length === 0
                  ? _c("div", { staticClass: "text-white pl-2" }, [
                      _c("small", [_vm._v(" No active wireline tags.")]),
                    ])
                  : _c("checkbox-list", {
                      attrs: {
                        enableSearch: true,
                        enableSelectAllOption: true,
                        label: "Wireline tags",
                        valueKey: "prioritizedTagName",
                        outputKey: "name",
                        height: "200",
                        options: _vm.wirelineTags,
                        selectedOptions: _vm.selectedWirelineTags,
                      },
                      on: {
                        "update:selectedOptions": function ($event) {
                          _vm.selectedWirelineTags = $event
                        },
                      },
                    }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-row-reverse mt-3 mb-2" }, [
            _c("div", [
              _vm.latencySaved
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-success green-button float-right ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onSaveLatencyTags($event)
                        },
                      },
                    },
                    [_vm._v("\n                    Saved!\n                ")]
                  )
                : !_vm.latencySaving
                ? _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-success green-button float-right ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.onSaveLatencyTags($event)
                        },
                      },
                    },
                    [_vm._v("\n                    Save\n                ")]
                  )
                : _c(
                    "button",
                    {
                      staticClass:
                        "btn btn-success green-button float-right ml-2",
                      attrs: { type: "button", disabled: "" },
                    },
                    [
                      _vm._v(
                        "\n                    Saving...\n                "
                      ),
                    ]
                  ),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary grey-button float-right ml-2",
                  attrs: { type: "button" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onClearLatencyTags($event)
                    },
                  },
                },
                [_vm._v("\n                    Clear\n                ")]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "missed-tag-0",
            triggers: "hover",
            placement: "bottom",
            delay: { show: 0, hide: 500 },
            boundary: "viewport",
          },
          on: { show: (bvEvent) => _vm.onPopoverShow(bvEvent, "level1") },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column mt-2 text-white" },
            [
              _c("h6", [_vm._v("15 Seconds Indicator")]),
              _vm._v(" "),
              _vm._l(_vm.channelsMissingData.level1, function (tags, index) {
                return _c("div", { key: index }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(tags.friendlyTagname) +
                      "\n            "
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.channelsMissingData.level1.length == 0
                ? _c("div", [_vm._v("\n                --\n            ")])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "missed-tag-1",
            triggers: "hover",
            placement: "bottom",
            delay: { show: 0, hide: 500 },
            boundary: "viewport",
          },
          on: { show: (bvEvent) => _vm.onPopoverShow(bvEvent, "level2") },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column mt-2 text-white" },
            [
              _c("h6", [_vm._v("30 Seconds Indicator")]),
              _vm._v(" "),
              _vm._l(_vm.channelsMissingData.level2, function (tags, index) {
                return _c("div", { key: index }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(tags.friendlyTagname) +
                      "\n            "
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.channelsMissingData.level2.length == 0
                ? _c("div", [_vm._v("\n                --\n            ")])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "missed-tag-2",
            triggers: "hover",
            placement: "bottom",
            delay: { show: 0, hide: 500 },
            boundary: "viewport",
          },
          on: { show: (bvEvent) => _vm.onPopoverShow(bvEvent, "level3") },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex flex-column mt-2 text-white" },
            [
              _c("h6", [_vm._v("60 Seconds Indicator")]),
              _vm._v(" "),
              _vm._l(_vm.channelsMissingData.level3, function (tags, index) {
                return _c("div", { key: index }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(tags.friendlyTagname) +
                      "\n            "
                  ),
                ])
              }),
              _vm._v(" "),
              _vm.channelsMissingData.level3.length == 0
                ? _c("div", [_vm._v("\n                --\n            ")])
                : _vm._e(),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "align-self-center" }, [
      _c("i", {
        staticClass: "fas fa-caret-down",
        attrs: { id: "latency-tags-dropdown" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }