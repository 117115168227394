var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { id: "Layer" } }, [
        _c("path", {
          attrs: { id: "Layer_2", d: "M0 0H11.4V11.4H0V0Z", fill: "#F25022" },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Layer_3",
            d: "M12.6001 0H24.0001V11.4H12.6001V0Z",
            fill: "#7FBA00",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Layer_4",
            d: "M0 12.6H11.4V24H0V12.6Z",
            fill: "#00A4EF",
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            id: "Layer_5",
            d: "M12.5996 12.6H23.9996V24H12.5996V12.6Z",
            fill: "#FFB900",
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }