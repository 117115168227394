<!--
    * Component Description
        A simple, re-usable slider wrapper that applies IWS styling and other small features
        See props for an explanation of all supported functions

    * Side Effects
        Will emit 2 signals
            update:value           <- triggers upon click of the slider
            change                 <- triggers upon release of the slider

    * Slots
        #min                       <- Target min label
        #max                       <- Target max label

    * Example Usage
        <iws-slider
            label="Zoom"
            :value.sync="value"
            :min="0"
            :max="100"
            :step="1"
        />
-->
<template>
    <div :id="id" class="full-width" :class="{ 'form-input-spacing': formSpacing !== false }">
        <label v-if="label" class="primary-text-color">
            {{ label }}
        </label>

        <input ref="sliderRef" class="form-range"
            type="range"
            :value="value"
            :min="min"
            :max="max"
            :step="step"
            @input="onChange"
            @pointerup="onRelease"
            :disabled="disabled"
        >

        <div v-if="!_isFalsy(min) || !_isFalsy(max)" class="position-relative" style="min-height: 25px">
            <span v-if="!_isFalsy(min)" class="min-label">
                <slot name="min">
                    {{ min }}
                </slot>
            </span>

            <span v-if="!_isFalsy(max)" class="max-label">
                <slot name="max">
                    {{ max }}
                </slot>
            </span>
        </div>

        <label v-if="hint" class="secondary-text-color" style="margin-top: 25px">
            {{ hint }}
        </label>

        <label v-if="inErrorState" class="danger-text-color">
            <template v-if="required !== false && _isFalsy(value)">
                Required
            </template>
            <template v-else-if="_isTruthy(min) && value < min">
                Must be at least {{ min }}
            </template>
            <template v-else-if="_isTruthy(max) && value > max">
                Must be at less than {{ max+1 }}
            </template>
        </label>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isTruthy } = GlobalFunctions;

export default {
    props: {
        id: {
            type: String
        },

        // Label for input, appears at the top-left
        label: {
			type: String | Number | Function
		},
        // Placeholder/hint that appears INSIDE input (only visible when value is null)
		placeholder: {
			type: String | Number | Function
		},
        // Placeholder/hint that appears BELOW input (is ALWAYS visible when provided)
        hint: {
			type: String | Number | Function
		},

        // Synced value of input field
        value: {
			type: Number
		},

        // min/max are INCLUSIVE (value >= min && value <= max)
        min: {
			type: Number
		},
        max: {
			type: Number
		},
        step: {
			type: Number,
            default: 1
		},

        disabled: {
            type: Boolean,
            default: false
        },
        // Provides a consistent margin between fields across forms
        formSpacing: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean | String,
            default: false
        }
    },

    data: () => ({
        inErrorState: false
    }),

    methods: {		
        _isFalsy(value) {
            return isFalsy(value);
        },
        _isTruthy(value) {
            return isTruthy(value);
        },

        onChange($event) {
            this.$emit('update:value', +$event.target.value);
        },
        onRelease() {
            if (this.disabled)
                this.$emit('disabled-click');
            this.$emit('change', +this.value);
        },

        validateInput() {
             // To support just the required keyword in the parent, anything other than just false is required
            this.inErrorState = this.required !== false && isFalsy(this.value);

            // When min and max values are provided, ensure they are valid
            if (isTruthy(this.value)) {
                if (isTruthy(this.min) && this.value < this.min)
                    this.inErrorState = true;
                if (isTruthy(this.max) && this.value > this.max)
                    this.inErrorState = true;
            }

            return !this.inErrorState;
        },
    }
};
</script>

<style scoped>
    label.primary-text-color {
        display: block;
    }
    input[type='range'] {
        width: 100%;
        background: var(--form-color);
        border: solid 1px var(--form-color);
        border-radius: 8px;
        height: 7px;
        outline: none;
        transition: background 450ms ease-in;
        -webkit-appearance: none;
        display: block;
    }

    .min-label {
        position: absolute;
        top: 7.5px;
        left: 0px;
        font-size: 12px;
    }
    .max-label {
        position: absolute;
        top: 7.5px;
        right: 0px;
        font-size: 12px;
    }
</style>