<template>
  <svg 
    :style="{fill: color, stroke: color}"
    version="1.1" 
    id="Layer_1" 
    xmlns="http://www.w3.org/2000/svg" 
    xmlns:xlink="http://www.w3.org/1999/xlink" 
    x="0px" 
    y="0px" 
    viewBox="0 0 210 210" 
    style="enable-background:new 0 0 210 210;" 
    xml:space="preserve"
    >
      <path d="M179.07,105L30.93,210V0L179.07,105z"/>
    </svg>
</template>

<style>
  .cls-2 {
    letter-spacing: -0.02em;
  }
  .cls-3 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 30px;
  }
</style>

<script>
export default {
  props: ["color"],
};
</script>