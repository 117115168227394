var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "px-2", attrs: { id: "TimeKeeperComponent" } },
    [
      _c("base-table-view", {
        attrs: {
          busy: _vm.busy,
          filters: _vm.filters,
          showFilters: _vm.filtersActive,
          canClearAllFilters: false,
          filter: _vm.searchFilter,
        },
        on: {
          filter: function ($event) {
            return _vm.openFilters()
          },
          "update:filter": function ($event) {
            _vm.searchFilter = $event
          },
        },
        scopedSlots: _vm._u([
          {
            key: "button-extensions",
            fn: function () {
              return [
                _c("iws-button", {
                  staticClass: "btn btn-outline-light ml-3",
                  attrs: {
                    type: "link",
                    nptOnlyFilter: _vm.nptOnlyFilter,
                    disabled: _vm.busy || _vm.nptReport,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.nptOnlyFilter()
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm.nptOnlyMode || _vm.nptReport
                        ? {
                            key: "text",
                            fn: function () {
                              return [
                                _c("i", {
                                  staticClass: "far fa-check-square mr-2",
                                }),
                                _vm._v("NPT Events Only"),
                              ]
                            },
                            proxy: true,
                          }
                        : {
                            key: "text",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "far fa-square mr-2" }),
                                _vm._v("NPT Events Only"),
                              ]
                            },
                            proxy: true,
                          },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "title",
            fn: function () {
              return [
                _vm.nptReport
                  ? _c("div", { staticClass: "mt-3" }, [_vm._v(" NPT Report")])
                  : _c("div", { staticClass: "mt-3" }, [
                      _vm._v(" Time Keeper"),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "right-option",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  [
                    _c("iws-button", {
                      attrs: { type: "link", disabled: _vm.busy },
                      on: {
                        click: function ($event) {
                          _vm.closePopover("priority-popover"),
                            (_vm.showTableConfig = true)
                        },
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "text",
                          fn: function () {
                            return [
                              _c("i", { staticClass: "fas fa-cog" }),
                              _vm._v(
                                " Table configuration\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                    }),
                    _vm._v(" "),
                    _c("b-table-export-component", {
                      staticClass: "clickable",
                      attrs: {
                        items: _vm.preformattedDataSet,
                        fields: _vm.exportFields,
                        fileName: _vm.exportFileName,
                        jsonExportsAll: false,
                        arrayFields: { customerVendors: ["friendly_name"] },
                        jsonFields: {
                          "data.timekeeper.code.text": "code",
                          "data.timekeeper.duration": "durationSeconds",
                          "data.endTime": "endTime",
                          wellName: "wellId",
                          "nptOutcome.friendly_name": "nptOutcome",
                          customerVendors: "nptResponsibleVendors",
                          "data.comment": "comment",
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "active-filters",
            fn: function () {
              return [
                _vm._l(_vm.stagesDisplay, function (display, well) {
                  return [
                    display?.text
                      ? _c(
                          "div",
                          {
                            key: `${well}-stages`,
                            staticClass: "custom-badge clickable",
                            on: {
                              click: function ($event) {
                                return _vm.clearFilter("stages", well)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(well) +
                                " - stages: " +
                                _vm._s(display.text) +
                                "\n                    "
                            ),
                            display.count > 0
                              ? _c("div", { staticClass: "filter-count" }, [
                                  _vm._v("+" + _vm._s(display.count)),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.busy
                              ? _c("img", {
                                  staticClass: "clickable",
                                  attrs: {
                                    src: "/images/icons/badge-close.svg",
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
                _vm._v(" "),
                _vm._l(_vm.dynamicFilters, function (filter, index) {
                  return [
                    filter.canFilter &&
                    (_vm.filters[filter.name].length > 0 ||
                      (filter.includeUncoded &&
                        _vm.includeUncoded[filter.name]))
                      ? _c(
                          "div",
                          {
                            key: index,
                            staticClass: "custom-badge clickable",
                            on: {
                              click: function ($event) {
                                return _vm.clearFilter(filter.name)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(filter.header) +
                                ": " +
                                _vm._s(
                                  _vm.filters[filter.name].length > 0
                                    ? _vm.getDisplayValue(
                                        index,
                                        _vm.filters[filter.name][0]
                                      )
                                    : "Uncoded"
                                ) +
                                "\n                    "
                            ),
                            _vm.filters[filter.name].length > 1 ||
                            _vm.includeUncoded[filter.name]
                              ? _c("div", { staticClass: "filter-count" }, [
                                  _vm._v(
                                    "\n                        +" +
                                      _vm._s(
                                        (_vm.filters[filter.name].length > 1
                                          ? _vm.filters[filter.name].length - 1
                                          : 0) +
                                          (_vm.includeUncoded[filter.name]
                                            ? 1
                                            : 0)
                                      ) +
                                      "\n                    "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.busy
                              ? _c("img", {
                                  staticClass: "clickable",
                                  attrs: {
                                    src: "/images/icons/badge-close.svg",
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ]
                }),
                _vm._v(" "),
                _vm.filters.startTime || _vm.filters.endTime
                  ? _c(
                      "div",
                      {
                        staticClass: "custom-badge clickable",
                        on: {
                          click: function ($event) {
                            return _vm.clearFilter("datetime")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(
                              _vm.datetimeString(
                                _vm.filters.startTime,
                                _vm.filters.endTime
                              )
                            ) +
                            "\n                "
                        ),
                        !_vm.busy
                          ? _c("img", {
                              staticClass: "clickable",
                              attrs: { src: "/images/icons/badge-close.svg" },
                            })
                          : _vm._e(),
                      ]
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "b-overlay",
                  {
                    attrs: {
                      show: _vm.busy,
                      variant: "light",
                      blur: "5px",
                      opacity: "0.80",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "overlay",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "text-center" },
                              [
                                _c("b-spinner", {
                                  staticClass: "align-middle",
                                  attrs: { large: "" },
                                }),
                                _vm._v(" "),
                                _c("p", { staticClass: "mt-2" }, [
                                  _vm._v("Updating Results..."),
                                ]),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  },
                  [
                    _vm._v(" "),
                    _c("iws-table", {
                      key: _vm.nptValueKey,
                      ref: "mainTable",
                      staticClass: "m-0",
                      attrs: {
                        busy: _vm.busy,
                        filter: _vm.searchFilter,
                        columns: _vm.displayFields,
                        items: _vm.filteredEventData,
                        maxPageSize:
                          parseInt(_vm.jsonLocalStorage.config?.rowsPerPage) !=
                          NaN
                            ? parseInt(_vm.jsonLocalStorage.config?.rowsPerPage)
                            : 5,
                        tableDimension: {
                          "max-height": "calc(100vh - 240px)",
                          "overflow-y": "auto",
                        },
                        sortAsc: _vm.sortAsc,
                        sortByCol: _vm.sortBy,
                        paginationPosition: "left",
                        id: "main-table",
                      },
                      on: {
                        "update:items": function ($event) {
                          return _vm.updateItems()
                        },
                        "update:sortByCol": _vm.updateSortColumn,
                        "update:sortAsc": function ($event) {
                          _vm.sortAsc = $event
                        },
                        "update:sort-asc": function ($event) {
                          _vm.sortAsc = $event
                        },
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header_priorityDescription",
                            fn: function ({ data }) {
                              return [
                                _c("div", { staticClass: "d-flex" }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(data.label) +
                                      "\n                            "
                                  ),
                                  _c("i", {
                                    staticClass:
                                      "fas fa-info-circle icon ml-1 clickable",
                                    attrs: { id: "priority-descriptions" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openPopover(
                                          "priority-popover"
                                        )
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell_wellColor",
                            fn: function ({ data }) {
                              return [
                                _c("div", {
                                  style: {
                                    height: "20px",
                                    width: "100%",
                                    "background-color": data.value,
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "cell_customerVendors",
                            fn: function ({ data }) {
                              return [
                                data.value?.length > 0
                                  ? _c(
                                      "ul",
                                      _vm._l(
                                        data.value,
                                        function (vendor, index) {
                                          return _c("li", { key: index }, [
                                            _vm._v(
                                              _vm._s(vendor?.friendly_name)
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                          _vm._l(_vm.editableColumns, function (col) {
                            return {
                              key: `cell_${col}`,
                              fn: function ({ data }) {
                                return [
                                  !_vm.isApprovedStage(data.item)
                                    ? _c(
                                        "b-form-input",
                                        {
                                          staticClass: "darkmode-form",
                                          on: {
                                            change: function ($event) {
                                              return _vm.updateNptValue(data)
                                            },
                                          },
                                          model: {
                                            value: data.value,
                                            callback: function ($$v) {
                                              _vm.$set(data, "value", $$v)
                                            },
                                            expression: "data.value",
                                          },
                                        },
                                        [_vm._v(_vm._s(data.value))]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            }
                          }),
                          {
                            key: "cell_comment",
                            fn: function ({ data }) {
                              return [
                                data.item.data.comment
                                  ? _c(
                                      "span",
                                      [
                                        _c("i", {
                                          staticClass: "fas fa-comment ml-2",
                                          attrs: {
                                            id:
                                              "popover-comment-" + data.item.id,
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "b-popover",
                                          {
                                            attrs: {
                                              "custom-class":
                                                "popover-approvals",
                                              target:
                                                "popover-comment-" +
                                                data.item.id,
                                              boundary: "viewport",
                                              triggers: "hover",
                                              placement: "bottom",
                                              delay: { show: 0, hide: 50 },
                                            },
                                          },
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c("b-col", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        color: "black",
                                                        "max-height": "200px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        { staticClass: "px-3" },
                                                        [
                                                          _c("p", [
                                                            _vm._v(
                                                              _vm._s(
                                                                data.item.data
                                                                  .comment
                                                              )
                                                            ),
                                                          ]),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    )
                                  : _c("span", [_vm._v("-")]),
                              ]
                            },
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _vm.filteredEventData.length > 0
                                  ? _c(
                                      "tr",
                                      _vm._l(
                                        _vm.sumValuesToShow,
                                        function (slot, index) {
                                          return _c("td", { key: index }, [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(slot) +
                                                "\n                            "
                                            ),
                                          ])
                                        }
                                      ),
                                      0
                                    )
                                  : _vm._e(),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        true
                      ),
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("time-keeper-filters-modal", {
        ref: "filterModal",
        attrs: {
          dateMinMax: _vm.dateMinMax,
          uniqueStages: _vm.stagesByWellDefaultBooleans,
          dynamicFilters: _vm.dynamicFilters,
          formatDatetime: _vm.datetimeString,
          stagesByWell: _vm.stagesByWell,
          getDisplayValue: _vm.getDisplayValue,
        },
      }),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          ref: "priority-popover",
          attrs: {
            "custom-class": "priority-popover",
            target: "priority-descriptions",
            placement: _vm.popoverPlacement,
            triggers: "click",
          },
          scopedSlots: _vm._u([
            {
              key: "title",
              fn: function () {
                return [
                  _c(
                    "span",
                    {
                      staticClass: "text-light d-flex justify-content-end",
                      on: {
                        click: function ($event) {
                          return _vm.closePopover("priority-popover")
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-times-circle" })]
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("b-table", {
            staticClass: "m-0",
            attrs: {
              fixed: "",
              small: "",
              dark: true,
              items: _vm.priorityDescriptions,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          id: "table-config",
          title: "Table Configuration",
          showModal: _vm.showTableConfig,
          primaryButtonText: "Apply",
          maxWidth: "400px",
          primaryButtonDisabled: false,
          secondaryButtonVisible: false,
        },
        on: {
          close: function ($event) {
            _vm.showTableConfig = false
          },
          "primary-action": function ($event) {
            return _vm.saveConfig()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticStyle: { "margin-bottom": "20px" } }, [
                  _vm.isFeatureFlagged("TIMEKEEPER_PRIORITY")
                    ? _c(
                        "div",
                        { staticClass: "config-container" },
                        [
                          _c("p", [_vm._v("Priority Handling")]),
                          _vm._v(" "),
                          _c("iws-switch", {
                            attrs: {
                              size: "medium",
                              value: _vm.copiedUserPriorityHandlingConfig,
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.copiedUserPriorityHandlingConfig = $event
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "config-container align-items-baseline" },
                    [
                      _c("label", { attrs: { for: "per-page" } }, [
                        _vm._v("Rows per page:"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.tableConfig.rowsPerPage,
                            expression: "tableConfig.rowsPerPage",
                          },
                        ],
                        attrs: { id: "per-page", min: "1", type: "number" },
                        domProps: { value: _vm.tableConfig.rowsPerPage },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.tableConfig,
                              "rowsPerPage",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "config-toggle-columns" }, [
                  _c("p", { staticClass: "config-header" }, [
                    _vm._v("Show / Hide Columns"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "config-container" },
                    [
                      _c("p", [_vm._v("Priority Description")]),
                      _vm._v(" "),
                      _c("iws-switch", {
                        attrs: {
                          size: "medium",
                          value: _vm.tableConfig.showDescriptions,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.tableConfig,
                              "showDescriptions",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "config-container" },
                    [
                      _c("p", [_vm._v("NPT Outcome")]),
                      _vm._v(" "),
                      _c("iws-switch", {
                        attrs: {
                          size: "medium",
                          value: _vm.tableConfig.showNptOutcomes,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.tableConfig,
                              "showNptOutcomes",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "config-container" },
                    [
                      _c("p", [_vm._v("NPT Responsible Vendor(s)")]),
                      _vm._v(" "),
                      _c("iws-switch", {
                        attrs: {
                          size: "medium",
                          value: _vm.tableConfig.showResponsibleVendor,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.tableConfig,
                              "showResponsibleVendor",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          title: "NPT Edit Confirmation",
          showModal: _vm.showNptModal,
          "max-width": "500px",
        },
        on: {
          "primary-action": function ($event) {
            return _vm.confirmNptEdit()
          },
          "secondary-action": function ($event) {
            return _vm.cancelNptEdit()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _vm._v(
                  "\n            Are you sure you want to change NPT value? This will trigger frac stage summary regeneration. Please confirm.\n        "
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }