var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("library-base", {
        attrs: {
          items: _vm.plugs,
          filters: _vm.filters,
          columns: _vm.columns.map((c) => c.key),
          sortByCol: _vm.sortByCol,
          sortAsc: _vm.sortAsc,
          title: "Plug Library",
          createBtnTxt: "Add New Plug",
          breadCrumbPath: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "Plug Library",
              link: "/plugs",
            },
          ],
        },
        on: {
          create: function ($event) {
            return _vm.editAction(_vm.emptyPlug)
          },
          openFilters: _vm.openFilters,
        },
        scopedSlots: _vm._u([
          {
            key: "breadcrumbs",
            fn: function () {
              return [
                _c("iws-breadcrumbs", {
                  staticClass: "breadcrumbs-margins",
                  attrs: {
                    path: [
                      {
                        title: "Dashboard",
                        link: "/home",
                      },
                      {
                        title: "Plug Library",
                        link: "/plugs",
                      },
                    ],
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "title",
            fn: function () {
              return [_vm._v("\n            Plug Library\n        ")]
            },
            proxy: true,
          },
          {
            key: "add-item-title",
            fn: function () {
              return [_vm._v("\n            Add New Plug\n        ")]
            },
            proxy: true,
          },
          {
            key: "no-item-body",
            fn: function () {
              return [
                _c("div", { staticClass: "title" }, [_vm._v("Add Plugs")]),
                _vm._v(" "),
                _c("div", { staticClass: "subtitle" }, [
                  _vm._v(
                    'Start by adding a Plug. Click on the "Add New Plug" button'
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "card",
            fn: function ({ filteredItems: filteredPlugs }) {
              return _vm._l(filteredPlugs, function (plug) {
                return _c(
                  "div",
                  { staticClass: "gun-card-container" },
                  [
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Description")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(plug.description))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Icon")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(plug.icon))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Outer Diameter (in)")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(plug.outer_diameter))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Inner Diameter (in)")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(plug.inner_diameter))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Make")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(plug.make))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Model")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(plug.model))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "row fixed-width" }, [
                      _c("label", [_vm._v("Customer")]),
                      _vm._v(" "),
                      _c("span", [_vm._v(_vm._s(plug.customerName))]),
                    ]),
                    _vm._v(" "),
                    _c("hr"),
                    _vm._v(" "),
                    _vm.enableRowActions
                      ? _c("library-crud-actions", {
                          attrs: {
                            id: plug.id,
                            "enable-actions": {
                              edit: true,
                              view: true,
                              delete: !plug.in_use,
                            },
                          },
                          on: {
                            edit: function ($event) {
                              return _vm.editAction(plug)
                            },
                            view: function ($event) {
                              return _vm.openAction(plug)
                            },
                            delete: function ($event) {
                              return _vm.deleteAction(plug)
                            },
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              })
            },
          },
          {
            key: "table",
            fn: function ({ filteredItems: filteredPlugs }) {
              return [
                _c("iws-table", {
                  attrs: {
                    items: filteredPlugs,
                    columns: [..._vm.columns, ""],
                    sortByCol: _vm.sortByCol,
                    sortAsc: _vm.sortAsc,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sortAsc": function ($event) {
                      _vm.sortAsc = $event
                    },
                    "update:sort-asc": function ($event) {
                      _vm.sortAsc = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function () {
                          return _vm._l(filteredPlugs, function (plug) {
                            return _c("tr", { key: plug.id }, [
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(plug.description) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(plug.icon) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(plug.outer_diameter) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(plug.inner_diameter) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(plug.make) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(plug.model) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.enableCustomerCol
                                ? _c("td", [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(plug.customerName) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _vm.enableRowActions
                                    ? _c("library-crud-actions", {
                                        attrs: {
                                          id: plug.id,
                                          "enable-actions": {
                                            edit: true,
                                            view: true,
                                            delete: !plug.in_use,
                                          },
                                        },
                                        on: {
                                          edit: function ($event) {
                                            return _vm.editAction(plug)
                                          },
                                          view: function ($event) {
                                            return _vm.openAction(plug)
                                          },
                                          delete: function ($event) {
                                            return _vm.deleteAction(plug)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ])
                          })
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("plug-library-filters-modal", {
        ref: "plugFiltersModal",
        attrs: {
          id: "plugFiltersModal",
          plugs: _vm.plugs,
          "enable-customer-col": _vm.enableCustomerCol,
        },
      }),
      _vm._v(" "),
      _c("plug-library-add-edit", {
        ref: "plugAddEdit",
        attrs: {
          id: "plugAddEdit",
          "enable-customer-col": _vm.enableCustomerCol,
        },
      }),
      _vm._v(" "),
      _c("plug-library-view", {
        ref: "plugView",
        attrs: { id: "plugView", "enable-customer-col": _vm.enableCustomerCol },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }