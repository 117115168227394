<template>
    <div class="row mb-2">
        <div class="col-6">
            {{descriptionDisplayed}}
        </div>
        <div class="col-6">
            <input class="form-control" type="text" v-if="(this.dataType == 'number' || this.dataType == 'string') && !this.select" v-model="newValue" @change="handleChange()">
            <span v-if="this.dataType == 'boolean'">Current setting:</span>
            <span v-if="this.dataType == 'boolean' && this.value" class="alert alert-success">ON</span>
            <span v-if="this.dataType == 'boolean' && !this.value" class="alert alert-danger">OFF</span>
            <button class="btn btn-primary" v-if="this.dataType == 'boolean' && !this.value" @click="on()"> Turn ON</button>
            <button class="btn btn-primary" v-if="this.dataType == 'boolean' && this.value" @click="off()"> Turn OFF</button>
            <select v-if="select" class="form-control" v-model="newValue" @change="handleChange()">
                <option v-for="option in options" :value="option">{{option}}</option>
            </select>
        </div>
    </div>

</template>

<script>
     export default {
        data() {
            return {
                dataType: '',
                newValue: this.value,
                select: false,
                descriptionDisplayed: '',
                options:[],
            }
        },
        props: ['description','value','topic'],
        mounted() {
            this.dataType = typeof this.value;
            this.checkDescription();
            
        },
        watch: {
            value: function(newVal,oldVal){
                this.newValue = newVal;
                this.dataType = typeof this.newValue;
            },
            description: function(newVal,oldVal){
                this.checkDescription();
            }
        },
        methods: {
            checkDescription: function(){
                var self = this;
                if(self.description.includes("[")){
                    // example description: "Manual bypass[none,135,246]" where options are in []
                    self.select = true;
                    var splitIndex = self.description.indexOf("[");
                    self.descriptionDisplayed = self.description.substring(0,splitIndex);
                    var options = self.description.substring(splitIndex+1);
                    options = options.substr(0,options.length-1);
                    console.log(options);
                    self.options = options.split(',');
                } else {
                    self.descriptionDisplayed = self.description;
                    self.select = false;
                }
                    
            },
            on: function(){
                var msg = {};
                msg.topic = this.topic;
                msg.payload = 'true';
                this.$emit('updateSetting',msg);
            },
            off: function(){
                var msg = {};
                msg.topic = this.topic;
                msg.payload = 'false';
                this.$emit('updateSetting',msg);
            },
            handleChange: function(){
                var msg = {};
                msg.topic = this.topic;
                var payload = this.newValue;
                msg.payload = payload;
                    
                this.$emit('updateSetting',msg);
            }
        }
     }

</script>