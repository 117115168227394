var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "multiWellTimelineChart",
      staticClass: "w-100",
      style:
        "font-size:0.8rem; background-color: #fff; height:" + _vm.height + "px",
    },
    [
      _c("notifications", {
        ref: "signalRNotification",
        attrs: {
          id: "chartNotifications",
          group: "dataFetch",
          position: "top center",
        },
      }),
      _vm._v(" "),
      _c("notifications", {
        ref: "chartNotifications",
        attrs: {
          id: "chartNotifications",
          group: "signalR",
          position: "top center",
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "grid" }, [
        _c(
          "div",
          {
            ref: "padControls",
            staticClass: "padControls m-1",
            attrs: { id: "padControls" },
          },
          [
            _c(
              "div",
              {
                ref: "filterHeaders",
                staticClass: "row px-2 pb-2 pt-2",
                staticStyle: { "margin-right": "0px" },
                style:
                  _vm.isLoadingPads || _vm.isSaving
                    ? "background-color:rgba(0,0,0,0.5);"
                    : "",
              },
              [
                _c("div", { staticClass: "col-8" }, [
                  _c("div", { staticClass: "row w-100" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Pad\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("multi-select", {
                              key: _vm.refreshTicker,
                              attrs: {
                                id: "selectedPads",
                                options: _vm.receivedPads,
                                value: _vm.selectedPads,
                                label: "label",
                                disabled: _vm.isLoadingPads || _vm.isLoading,
                                multiSelect: false,
                                maxHeight: 300,
                                placeholderText: "Pick One",
                                sortByKey: _vm.sortPadDropdownByKey,
                                isReverseSort: _vm.isReverseSort,
                              },
                              on: { selectedupdated: _vm.padsSelected },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  id: "checkbox-1",
                                  value: true,
                                  "unchecked-value": false,
                                  name: "checkbox-1",
                                },
                                model: {
                                  value: _vm.sortByJobStartDate,
                                  callback: function ($$v) {
                                    _vm.sortByJobStartDate = $$v
                                  },
                                  expression: "sortByJobStartDate",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                        Sort by Job Start Date\n                                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Channel(s)\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("multi-select", {
                              key: _vm.refreshTicker,
                              attrs: {
                                id: "selectedChannels",
                                options: _vm.receivedChannels,
                                value: _vm.selectedChannels,
                                label: "friendlyTagname",
                                selectAllDuplicates: true,
                                removeDuplicatesKey: "name",
                                uniqueId: "channel-select-" + _vm.item.i,
                                selected: _vm.selectedChannels,
                                disabled:
                                  _vm.selectedPads.length == 0 ||
                                  _vm.isLoadingChannels ||
                                  _vm.isLoading,
                                maxHeight: 300,
                              },
                              on: { selectedupdated: _vm.channelsSelected },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-4" }, [
                  _c("div", {
                    staticClass: "d-flex justify-content-end pr-3 pb-2",
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex row justify-content-left w-100 gx-2",
                    },
                    [
                      _c("div", [
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-secondary grey-button mt-2 mr-2",
                            attrs: { disabled: _vm.isLoadingPads },
                            on: {
                              click: function ($event) {
                                return _vm.clearConfiguration()
                              },
                            },
                          },
                          [_vm._v("Clear All Filters")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-secondary grey-button mt-2 mr-2",
                            attrs: { disabled: _vm.isLoadingPads },
                            on: {
                              click: function ($event) {
                                _vm.modalVisible = true
                              },
                            },
                          },
                          [_vm._v("Template Settings")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass:
                              "btn btn-secondary grey-button mt-2 mr-2",
                            attrs: { disabled: _vm.isLoadingPads },
                            on: {
                              click: function ($event) {
                                return _vm.validateAndFetchData()
                              },
                            },
                          },
                          [_vm._v("Load Chart")]
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticStyle: { color: "black" },
                          attrs: { disabled: _vm.isLoadingPads },
                          model: {
                            value: _vm.showLegend,
                            callback: function ($$v) {
                              _vm.showLegend = $$v
                            },
                            expression: "showLegend",
                          },
                        },
                        [
                          _vm._v(
                            "\n                            Show Legend\n                        "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "timeControls",
            staticClass:
              "timeControls ml-2 d-flex flex-column justify-content-between",
            staticStyle: { height: "100%", "border-left": "1px solid black" },
            style: { "max-height": _vm.getComponentHeight() + "px" },
            attrs: { id: "timeControls" },
          },
          [
            _c(
              "span",
              {
                staticClass: "w-100 text-center",
                staticStyle: { display: "block", "font-size": "1rem" },
              },
              [_vm._v("Channel Options")]
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "channelControls",
                staticClass: "scrollContainer h-100",
                attrs: { id: "channelControls" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "d-flex flex-column p-1",
                    staticStyle: { "font-size": "0.8rem" },
                  },
                  _vm._l(_vm.selectedChannels, function (channel, index) {
                    return _c("div", { key: index }, [
                      _vm.chartOptions.channelOptions[channel.id]
                        ? _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex w-100 align-items-center justify-content-left",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex w-100 align-items-center justify-content-between",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-start",
                                        staticStyle: { width: "60%" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "mt-1 text-left",
                                            staticStyle: {
                                              "word-wrap": "break-word",
                                            },
                                          },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.chartOptions
                                                      .channelOptions[
                                                      channel.id
                                                    ].show,
                                                  expression:
                                                    "chartOptions.channelOptions[channel.id].show",
                                                },
                                              ],
                                              staticClass: "check-box mr-1",
                                              attrs: {
                                                type: "checkbox",
                                                disabled:
                                                  _vm.isLoading ||
                                                  _vm.isLoadingPads,
                                              },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.chartOptions
                                                    .channelOptions[channel.id]
                                                    .show
                                                )
                                                  ? _vm._i(
                                                      _vm.chartOptions
                                                        .channelOptions[
                                                        channel.id
                                                      ].show,
                                                      null
                                                    ) > -1
                                                  : _vm.chartOptions
                                                      .channelOptions[
                                                      channel.id
                                                    ].show,
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.channelToggle(
                                                    channel.id
                                                  )
                                                },
                                                change: function ($event) {
                                                  var $$a =
                                                      _vm.chartOptions
                                                        .channelOptions[
                                                        channel.id
                                                      ].show,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.chartOptions
                                                            .channelOptions[
                                                            channel.id
                                                          ],
                                                          "show",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.chartOptions
                                                            .channelOptions[
                                                            channel.id
                                                          ],
                                                          "show",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.chartOptions
                                                        .channelOptions[
                                                        channel.id
                                                      ],
                                                      "show",
                                                      $$c
                                                    )
                                                  }
                                                },
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(
                                          _vm._s(channel.friendlyTagname) +
                                            "\n                                    "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-end align-items-center",
                                        staticStyle: { width: "40%" },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "p-0 mr-1 ml-2" },
                                          [
                                            _c("div", {
                                              staticClass: "color fake-button",
                                              style: {
                                                "background-color": _vm
                                                  .chartOptions.channelOptions[
                                                  channel.id
                                                ].color
                                                  ? _vm.chartOptions
                                                      .channelOptions[
                                                      channel.id
                                                    ].color
                                                  : "#FFFFFF",
                                              },
                                            }),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "show-clicker-finger",
                                            on: {
                                              click: function ($event) {
                                                return _vm.openChannelOptionsModal(
                                                  channel.id
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "fas fa-cog px-2",
                                            }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ])
                        : _vm._e(),
                    ])
                  }),
                  0
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  ref: "stageBandConfig",
                  staticClass: "mt-1",
                  attrs: { id: "stageBandConfig" },
                },
                [
                  _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-link text-center",
                        staticStyle: { color: "black" },
                        attrs: {
                          type: "button",
                          "data-toggle": "collapse",
                          "data-target": "#stageOptionsCollapse",
                        },
                        on: {
                          click: function ($event) {
                            _vm.showStageOptions = !_vm.showStageOptions
                          },
                        },
                      },
                      [_vm._v("Stage Options")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "flex-shrink-1 p-2",
                        attrs: { id: "collapseStageConfig" },
                      },
                      [
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: !_vm.showStageOptions,
                              expression: "!showStageOptions",
                            },
                          ],
                          staticClass: "fas fa-caret-up",
                        }),
                        _vm._v(" "),
                        _c("div", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.showStageOptions,
                              expression: "showStageOptions",
                            },
                          ],
                          staticClass: "fas fa-caret-down",
                        }),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "collapse",
                      attrs: { id: "stageOptionsCollapse" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "p-1 d-flex align-items-center" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.chartOptions.allStageBandsToggle,
                                expression: "chartOptions.allStageBandsToggle",
                              },
                            ],
                            staticClass: "check-box mr-1",
                            attrs: {
                              type: "checkbox",
                              disabled: _vm.isLoading || _vm.isLoadingPads,
                            },
                            domProps: {
                              checked: Array.isArray(
                                _vm.chartOptions.allStageBandsToggle
                              )
                                ? _vm._i(
                                    _vm.chartOptions.allStageBandsToggle,
                                    null
                                  ) > -1
                                : _vm.chartOptions.allStageBandsToggle,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleAllStageBands()
                              },
                              change: function ($event) {
                                var $$a = _vm.chartOptions.allStageBandsToggle,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.chartOptions,
                                        "allStageBandsToggle",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.chartOptions,
                                        "allStageBandsToggle",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.chartOptions,
                                    "allStageBandsToggle",
                                    $$c
                                  )
                                }
                              },
                            },
                          }),
                          _vm._v(
                            "\n                            Select All\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-items-center justify-content-start p-1 w-100",
                        },
                        [
                          _vm._v(
                            "\n                                Opacity:\n                                "
                          ),
                          _c("input", {
                            staticClass: "ml-1",
                            attrs: {
                              disabled:
                                _vm.isLoading ||
                                _vm.isLoadingPads ||
                                !this.chartOptions.allStageBandsToggle,
                              type: "range",
                              value: "90",
                              min: "0",
                              max: "255",
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateAllWellStageOpacities(
                                  $event.target.value
                                )
                              },
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.selectableWells, function (well, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "d-flex w-100" },
                          [
                            _c("div", { staticClass: "w-100" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-start align-items-center p-1",
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.chartOptions.stageBandOptions[
                                            well.id
                                          ].show,
                                        expression:
                                          "chartOptions.stageBandOptions[well.id].show",
                                      },
                                    ],
                                    staticClass: "check-box mr-1",
                                    attrs: {
                                      type: "checkbox",
                                      disabled:
                                        _vm.isLoading || _vm.isLoadingPads,
                                    },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.chartOptions.stageBandOptions[
                                          well.id
                                        ].show
                                      )
                                        ? _vm._i(
                                            _vm.chartOptions.stageBandOptions[
                                              well.id
                                            ].show,
                                            null
                                          ) > -1
                                        : _vm.chartOptions.stageBandOptions[
                                            well.id
                                          ].show,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.chartOptions.stageBandOptions[
                                          well.id
                                        ].show =
                                          !_vm.chartOptions.stageBandOptions[
                                            well.id
                                          ].show
                                      },
                                      change: [
                                        function ($event) {
                                          var $$a =
                                              _vm.chartOptions.stageBandOptions[
                                                well.id
                                              ].show,
                                            $$el = $event.target,
                                            $$c = $$el.checked ? true : false
                                          if (Array.isArray($$a)) {
                                            var $$v = null,
                                              $$i = _vm._i($$a, $$v)
                                            if ($$el.checked) {
                                              $$i < 0 &&
                                                _vm.$set(
                                                  _vm.chartOptions
                                                    .stageBandOptions[well.id],
                                                  "show",
                                                  $$a.concat([$$v])
                                                )
                                            } else {
                                              $$i > -1 &&
                                                _vm.$set(
                                                  _vm.chartOptions
                                                    .stageBandOptions[well.id],
                                                  "show",
                                                  $$a
                                                    .slice(0, $$i)
                                                    .concat($$a.slice($$i + 1))
                                                )
                                            }
                                          } else {
                                            _vm.$set(
                                              _vm.chartOptions.stageBandOptions[
                                                well.id
                                              ],
                                              "show",
                                              $$c
                                            )
                                          }
                                        },
                                        function ($event) {
                                          return _vm.updateWellStageOptions()
                                        },
                                      ],
                                    },
                                  }),
                                  _vm._v(
                                    _vm._s(well.wellName) +
                                      "\n                                "
                                  ),
                                  _c("div", { staticClass: "p-0 mr-1 ml-2" }, [
                                    _c("div", {
                                      staticClass: "color fake-button",
                                      style: {
                                        "background-color": well.color
                                          ? well.color
                                          : "#FFFFFF",
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex align-items-center justify-content-start p-1 w-100",
                                },
                                [
                                  _vm._v(
                                    "\n                                Opacity:\n                                "
                                  ),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.chartOptions.stageBandOptions[
                                            well.id
                                          ].opacity,
                                        expression:
                                          "chartOptions.stageBandOptions[well.id].opacity",
                                      },
                                    ],
                                    staticClass: "ml-1",
                                    attrs: {
                                      disabled:
                                        _vm.isLoading || _vm.isLoadingPads,
                                      type: "range",
                                      default: "",
                                      min: "0",
                                      max: "255",
                                    },
                                    domProps: {
                                      value:
                                        _vm.chartOptions.stageBandOptions[
                                          well.id
                                        ].opacity,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateWellStageOptions()
                                      },
                                      __r: function ($event) {
                                        return _vm.$set(
                                          _vm.chartOptions.stageBandOptions[
                                            well.id
                                          ],
                                          "opacity",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "timeConfiguration",
                      attrs: { id: "timeConfiguration" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "p-2",
                          staticStyle: { "border-top": "1px solid black" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between mb-1",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "w-60",
                                  attrs: { for: "aggregateInterval" },
                                },
                                [_vm._v("Aggregate Interval: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.aggregateInterval,
                                      expression: "aggregateInterval",
                                    },
                                  ],
                                  ref: "aggregateInterval",
                                  staticClass: "w-40",
                                  attrs: {
                                    id: "aggregateInterval",
                                    disabled: _vm.isLoadingPads,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.aggregateInterval = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                _vm._l(
                                  _vm.dashboardData.aggregateOptions,
                                  function (option, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: option.value },
                                      },
                                      [_vm._v(_vm._s(option.name))]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between mb-1",
                            },
                            [
                              _c(
                                "label",
                                {
                                  staticClass: "w-60",
                                  attrs: { for: "timeType" },
                                },
                                [_vm._v("Interval Type: ")]
                              ),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.timeType,
                                      expression: "timeType",
                                    },
                                  ],
                                  ref: "timeType",
                                  staticClass: "w-40",
                                  attrs: {
                                    id: "timeType",
                                    disabled: _vm.isLoadingPads,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.timeType = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                  },
                                },
                                [
                                  _c("option", { attrs: { value: "time" } }, [
                                    _vm._v("Time"),
                                  ]),
                                  _vm._v(" "),
                                  _c("option", { attrs: { value: "stage" } }, [
                                    _vm._v("Well Stages"),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "option",
                                    { attrs: { value: "current" } },
                                    [_vm._v("Current")]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.timeType === "stage"
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "w-100 d-flex justify-content-between mb-1",
                                  },
                                  [
                                    _c(
                                      "label",
                                      {
                                        staticClass: "w-60",
                                        attrs: {
                                          for: "stageIntervalWellSelect",
                                        },
                                      },
                                      [_vm._v("Well: ")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.stageIntervalWellSelect,
                                            expression:
                                              "stageIntervalWellSelect",
                                          },
                                        ],
                                        ref: "stageIntervalWellSelect",
                                        staticClass: "w-40",
                                        attrs: {
                                          id: "stageIntervalWellSelect",
                                          disabled: _vm.isLoadingPads,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.stageIntervalWellSelect =
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                            },
                                            function ($event) {
                                              return _vm.updateStageTimeSelects()
                                            },
                                          ],
                                        },
                                      },
                                      _vm._l(
                                        _vm.selectableWells,
                                        function (well, index) {
                                          return _c(
                                            "option",
                                            {
                                              key: index,
                                              domProps: { value: well },
                                            },
                                            [_vm._v(_vm._s(well.wellName))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.selectableStages.length > 0
                                  ? _c("div", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-between mb-1",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "w-60",
                                              attrs: { for: "startStage" },
                                            },
                                            [_vm._v("Start Stage: ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.startStage,
                                                  expression: "startStage",
                                                },
                                              ],
                                              ref: "startStage",
                                              staticClass: "w-40",
                                              attrs: {
                                                id: "startStage",
                                                disabled:
                                                  _vm.isLoadingPads ||
                                                  _vm.templateData
                                                    .loadLatestStage,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.startStage = $event
                                                      .target.multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  },
                                                  function ($event) {
                                                    return _vm.validateStageSelects(
                                                      "start"
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              _vm.selectableStageStarts,
                                              function (stage, index) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    domProps: { value: stage },
                                                  },
                                                  [_vm._v(_vm._s(stage.stage))]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex justify-content-between mb-1",
                                        },
                                        [
                                          _c(
                                            "label",
                                            {
                                              staticClass: "w-60",
                                              attrs: { for: "endStage" },
                                            },
                                            [_vm._v("End Stage: ")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "select",
                                            {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value: _vm.endStage,
                                                  expression: "endStage",
                                                },
                                              ],
                                              ref: "endStage",
                                              staticClass: "w-40",
                                              attrs: {
                                                id: "endStage",
                                                disabled:
                                                  _vm.isLoadingPads ||
                                                  _vm.templateData
                                                    .loadLatestStage,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$selectedVal =
                                                      Array.prototype.filter
                                                        .call(
                                                          $event.target.options,
                                                          function (o) {
                                                            return o.selected
                                                          }
                                                        )
                                                        .map(function (o) {
                                                          var val =
                                                            "_value" in o
                                                              ? o._value
                                                              : o.value
                                                          return val
                                                        })
                                                    _vm.endStage = $event.target
                                                      .multiple
                                                      ? $$selectedVal
                                                      : $$selectedVal[0]
                                                  },
                                                  function ($event) {
                                                    return _vm.validateStageSelects(
                                                      "end"
                                                    )
                                                  },
                                                ],
                                              },
                                            },
                                            _vm._l(
                                              _vm.selectableStageEnds,
                                              function (stage, index) {
                                                return _c(
                                                  "option",
                                                  {
                                                    key: index,
                                                    domProps: { value: stage },
                                                  },
                                                  [_vm._v(_vm._s(stage.stage))]
                                                )
                                              }
                                            ),
                                            0
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "label",
                                        {
                                          staticClass: "font-90",
                                          attrs: { for: "" },
                                        },
                                        [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.templateData
                                                    .loadLatestStage,
                                                expression:
                                                  "templateData.loadLatestStage",
                                              },
                                            ],
                                            staticClass: "mr-1",
                                            attrs: { type: "checkbox" },
                                            domProps: {
                                              checked: Array.isArray(
                                                _vm.templateData.loadLatestStage
                                              )
                                                ? _vm._i(
                                                    _vm.templateData
                                                      .loadLatestStage,
                                                    null
                                                  ) > -1
                                                : _vm.templateData
                                                    .loadLatestStage,
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  var $$a =
                                                      _vm.templateData
                                                        .loadLatestStage,
                                                    $$el = $event.target,
                                                    $$c = $$el.checked
                                                      ? true
                                                      : false
                                                  if (Array.isArray($$a)) {
                                                    var $$v = null,
                                                      $$i = _vm._i($$a, $$v)
                                                    if ($$el.checked) {
                                                      $$i < 0 &&
                                                        _vm.$set(
                                                          _vm.templateData,
                                                          "loadLatestStage",
                                                          $$a.concat([$$v])
                                                        )
                                                    } else {
                                                      $$i > -1 &&
                                                        _vm.$set(
                                                          _vm.templateData,
                                                          "loadLatestStage",
                                                          $$a
                                                            .slice(0, $$i)
                                                            .concat(
                                                              $$a.slice($$i + 1)
                                                            )
                                                        )
                                                    }
                                                  } else {
                                                    _vm.$set(
                                                      _vm.templateData,
                                                      "loadLatestStage",
                                                      $$c
                                                    )
                                                  }
                                                },
                                                function ($event) {
                                                  return _vm.selectLatestStages()
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(
                                            "\n                            Last Complete"
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.timeType === "time",
                                  expression: "timeType==='time'",
                                },
                              ],
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-content-between mb-1",
                                },
                                [
                                  _c("label", [_vm._v("Start:")]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.startTime,
                                        expression: "startTime",
                                      },
                                    ],
                                    staticClass: "w-100 align-self-end",
                                    staticStyle: { "font-size": "0.9rem" },
                                    attrs: {
                                      disabled: _vm.isLoadingPads,
                                      type: "datetime-local",
                                      min: _vm.localJobStartTime,
                                    },
                                    domProps: { value: _vm.startTime },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.startTime = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex flex-column justify-content-between mb-1",
                                },
                                [
                                  _c("label", [_vm._v("End:")]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.endTime,
                                        expression: "endTime",
                                      },
                                    ],
                                    staticClass: "w-100 align-self-end",
                                    staticStyle: { "font-size": "0.9rem" },
                                    attrs: {
                                      disabled: _vm.isLoadingPads,
                                      type: "datetime-local",
                                      min: _vm.startTime,
                                      max: _vm.localJobEndTime,
                                    },
                                    domProps: { value: _vm.endTime },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.endTime = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.timeType === "current",
                                  expression: "timeType==='current'",
                                },
                              ],
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "d-flex justify-content-between mb-1",
                                },
                                [
                                  _c("label", { staticClass: "w-60" }, [
                                    _vm._v("Get Past Hours: "),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.pastHours,
                                        expression: "pastHours",
                                      },
                                    ],
                                    staticClass: "w-40",
                                    staticStyle: { "font-size": "0.9rem" },
                                    attrs: {
                                      disabled: _vm.isLoadingPads,
                                      type: "number",
                                    },
                                    domProps: { value: _vm.pastHours },
                                    on: {
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.pastHours = $event.target.value
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "chartArea", style: _vm.chartAreaStyle }, [
          _vm.isLoadingPads
            ? _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-content-center align-items-center",
                },
                [
                  _c("div", {
                    staticClass: "spinner-border spinner-border-sm",
                    staticStyle: {
                      color: "black",
                      position: "absolute",
                      "z-index": "100",
                      top: "10%",
                    },
                    attrs: { role: "status" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-column" }, [
            _c(
              "div",
              {
                ref: "chartInfoHeader",
                staticClass: "chartHeader d-flex",
                staticStyle: {
                  "background-color": "#BEBEBE",
                  border: "2px solid #BEBEBE",
                  "border-bottom": "0",
                  "border-radius": "5px 5px 0 0",
                },
              },
              [
                _c("div", { staticClass: "d-flex justify-content-left w-50" }, [
                  _c(
                    "div",
                    { staticClass: "show-clicker-finger" },
                    [
                      _c("i", {
                        staticClass: "fas fa-info mx-2",
                        attrs: { id: "chartInfo" },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "chartInfo",
                            placement: "right",
                            triggers: "hover",
                          },
                        },
                        [
                          _c("div", { staticStyle: { color: "white" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "w-100 text-center",
                                staticStyle: { "text-decoration": "underline" },
                              },
                              [_vm._v("Controls")]
                            ),
                            _vm._v(" "),
                            _c("div", [_vm._v("Mouse Wheel: Zoom In / Out")]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "Left Click and Drag: Zoom to Defined Area"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v("Right Click and Drag: Pan View"),
                            ]),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "show-clicker-finger" }, [
                    _c("i", {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip:top",
                          value: "Return to Default Axis Settings",
                          expression: "'Return to Default Axis Settings'",
                          arg: "top",
                        },
                      ],
                      staticClass: "fa fa-undo px-2",
                      class: { "icon-disabled": _vm.isLoadingPads },
                      attrs: { disabled: _vm.isLoadingPads },
                      on: {
                        click: function ($event) {
                          return _vm.returnToDefaultsMethod()
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c(
                      "div",
                      {
                        staticClass: "show-clicker-finger",
                        on: {
                          click: function ($event) {
                            return _vm.openAxisOptionsModal()
                          },
                        },
                      },
                      [
                        _c("i", {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip:top",
                              value: "Y-axis Options",
                              expression: "'Y-axis Options'",
                              arg: "top",
                            },
                          ],
                          staticClass: "fas fa-cog px-2",
                          class: { "icon-disabled": _vm.isLoadingPads },
                          attrs: {
                            disabled:
                              _vm.isLoadingPads && !_vm.selectedChannels,
                          },
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("analytic-options-modal", {
                          attrs: {
                            modalVisible: _vm.chartAxisModalVisible,
                            modalData: _vm.axisOptionsModalData,
                            isSaving: _vm.isSaving,
                          },
                          on: {
                            onDismiss: function ($event) {
                              _vm.chartAxisModalVisible = false
                            },
                            onApplyOptionChanges: _vm.applyOptionChanges,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex flex-row-reverse w-50" }, [
                  _c(
                    "div",
                    {
                      staticClass: "show-clicker-finger",
                      on: {
                        click: function ($event) {
                          _vm.followingLiveUpdates = !_vm.followingLiveUpdates
                        },
                      },
                    },
                    [
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              _vm.followingLiveUpdates &&
                              _vm.timeType === "current",
                            expression:
                              "followingLiveUpdates && timeType === 'current'",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip:top",
                            value: "Tracking Live Data",
                            expression: "'Tracking Live Data'",
                            arg: "top",
                          },
                        ],
                        staticClass:
                          "fas fa-satellite-dish mx-2 show-clicker-finger",
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value:
                              !_vm.followingLiveUpdates ||
                              _vm.timeType !== "current",
                            expression:
                              "!followingLiveUpdates || timeType !== 'current'",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip:top",
                            value: "Not Tracking Live Data",
                            expression: "'Not Tracking Live Data'",
                            arg: "top",
                          },
                        ],
                        staticClass:
                          "fas fa-satellite-dish mx-2 show-clicker-finger",
                        class: {
                          "not-live-tracking": _vm.timeType == "current",
                          "icon-disabled": _vm.timeType != "current",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "tooltip",
                          rawName: "v-tooltip:top",
                          value: "Print Chart View",
                          expression: "'Print Chart View'",
                          arg: "top",
                        },
                      ],
                      class: { "show-clicker-finger": _vm.isLoadComplete },
                      attrs: {
                        disabled:
                          _vm.isLoading ||
                          _vm.isLoadingPads ||
                          !_vm.isLoadComplete,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$refs.wellComparisonLightning.printChartToImage()
                        },
                      },
                    },
                    [_c("i", { staticClass: "fas fa-print mx-2" })]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { ref: "exportDataIcon", attrs: { id: "exportDataIcon" } },
                    [
                      _c("i", {
                        staticClass: "fas fa-download mx-2 show-clicker-finger",
                      }),
                      _vm._v(" "),
                      _c(
                        "b-popover",
                        {
                          attrs: {
                            target: "exportDataIcon",
                            placement: "left",
                            triggers: "hover",
                          },
                        },
                        [
                          _c("div", { staticStyle: { color: "white" } }, [
                            _c(
                              "div",
                              {
                                staticClass: "w-100 text-center",
                                staticStyle: { "text-decoration": "underline" },
                              },
                              [_vm._v("Export Data")]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between mt-2",
                              },
                              [
                                _c("div", { staticClass: "mx-2" }, [
                                  _vm._v(
                                    "\n                                            File Type:\n                                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.exportFileType,
                                        expression: "exportFileType",
                                      },
                                    ],
                                    staticClass: "mx-2",
                                    attrs: { id: "exportFileType" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.exportFileType = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "json" } }, [
                                      _vm._v("JSON"),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      { attrs: { value: "csv", selected: "" } },
                                      [_vm._v("csv")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-between mt-2",
                              },
                              [
                                _c("div", { staticClass: "mx-2" }, [
                                  _vm._v(
                                    "\n                                            Data Range:\n                                        "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.exportRangeType,
                                        expression: "exportRangeType",
                                      },
                                    ],
                                    staticClass: "mx-2",
                                    attrs: { id: "exportRangeType" },
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.exportRangeType = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      { attrs: { value: "allLoaded" } },
                                      [_vm._v("All Loaded Data")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      {
                                        attrs: {
                                          value: "visibleInterval",
                                          selected: "",
                                        },
                                      },
                                      [_vm._v("Visisble Range")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-center mt-2",
                              },
                              [
                                _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success green-button",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        _vm.isLoading ||
                                        _vm.isLoadingPads ||
                                        !_vm.isLoadComplete,
                                    },
                                    on: {
                                      click: function ($event) {
                                        $event.preventDefault()
                                        return _vm.$refs.wellComparisonLightning.exportChartData(
                                          _vm.exportFileType,
                                          _vm.exportRangeType
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-flex justify-content-center",
                                      },
                                      [
                                        false
                                          ? _c("span", {
                                              staticClass:
                                                "spinner-border spinner-border-sm pr-2",
                                              attrs: {
                                                role: "status",
                                                "aria-hidden": "true",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "\n                                                    Export\n                                                "
                                          ),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.isLoading
              ? _c("div", { staticClass: "d-flex justify-content-center" }, [
                  _vm._m(0),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  border: "2px solid white",
                  "border-top": "0",
                  "border-radius": "0 0 5px 5px",
                },
              },
              [
                _c("well-comparison-lightning-chart", {
                  ref: "wellComparisonLightning",
                  attrs: {
                    points: _vm.points,
                    livePoints: _vm.liveChannelData,
                    followingLiveUpdates: _vm.followingLiveUpdates,
                    height: _vm.canvasHeight,
                    showLegend: _vm.showLegend,
                    chartOptions: _vm.chartOptions,
                    pastHoursInMS: _vm.pastHoursInMS,
                    clearChartData: _vm.clearChartData,
                    job: _vm.templateSelectedJobs,
                    wells: _vm.templateWells,
                    timeType: _vm.timeType,
                    isLoading: _vm.isLoading,
                    stageData: _vm.stageData,
                    lockToMinChannelsIds: _vm.lockToMinChannelIds,
                  },
                  on: { onError: _vm.lightningChartErrorHandler },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("stage-comparison-settings-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoadingPads,
            expression: "!isLoadingPads",
          },
        ],
        ref: "settingsModal",
        attrs: {
          modalVisible: _vm.modalVisible,
          chartType: "MWT",
          templateData: _vm.templateData,
          dashboardInfo: _vm.dashboardInfo,
          isAdmin: _vm.isAdmin,
          userid: _vm.userid,
          targetTemplateId: _vm.item.targetTemplateId
            ? _vm.item.targetTemplateId
            : null,
        },
        on: {
          onDismiss: function ($event) {
            _vm.modalVisible = false
          },
          onLoadTemplate: _vm.loadedTemplate,
          onCreatedTemplate: _vm.createdTemplate,
          onUpdatedTemplate: _vm.updatedTemplate,
          onNewTemplate: _vm.newTemplate,
          onCopyTemplate: _vm.copyChartTemplate,
          onDeleteTemplate: _vm.deleteChartTemplate,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        {},
        [
          _c("analytic-options-modal", {
            attrs: {
              modalVisible: _vm.chartOptionsModalVisible,
              modalData: _vm.optionsModalData,
            },
            on: {
              onDismiss: function ($event) {
                _vm.chartOptionsModalVisible = false
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm mt-2",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }