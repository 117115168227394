var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col" }),
    _vm._v(" "),
    _c("div", { staticClass: "col-2 mt-2" }, [
      _c(
        "div",
        { staticClass: "btn btn-primary btn-block", on: { click: _vm.back } },
        [_vm._v("Back")]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "w-100 mb-2" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "col-8 mb-5" },
      _vm._l(_vm.settings, function (setting) {
        return _c("setting-individual-component", {
          key: setting.index,
          attrs: {
            description: setting.description,
            topic: setting.topic,
            value: setting.value,
          },
          on: { updateSetting: _vm.updateSetting },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }