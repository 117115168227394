var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      showModal: _vm.showHistoryModal,
      "max-width": "750px",
      "max-height": "95vh",
      secondaryButtonText: "Return",
      primaryButtonVisible: false,
      id: "Time_Series_History",
    },
    on: {
      close: function ($event) {
        return _vm.resolve(null)
      },
      "secondary-action": function ($event) {
        return _vm.resolve(null)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(
              "\n        History (" +
                _vm._s(_vm.selectedConfig?.templateName) +
                ")\n    "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "content",
        fn: function () {
          return [
            _vm.showHistoryModal && !_vm._isNullOrEmpty(_vm.versionHistory)
              ? _c("iws-table", {
                  attrs: {
                    items: _vm.versionHistory,
                    columns: _vm.chartConfigHistoryColumns,
                    sortByCol: _vm.sortByCol,
                    sortAsc: _vm.sortAsc,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sortAsc": function ($event) {
                      _vm.sortAsc = $event
                    },
                    "update:sort-asc": function ($event) {
                      _vm.sortAsc = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.versionHistory, function (version) {
                        return {
                          key: `row_${version.id}`,
                          fn: function ({ data }) {
                            return [
                              _c("tr", [
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm._dateTimeDisplayUTC(
                                          data?.item?.created_at
                                        )
                                      ) +
                                      "\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("td", [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(data?.item?.owned_by) +
                                      "\n                    "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticStyle: {
                                      width: "110px",
                                      padding: "5px 12px",
                                    },
                                  },
                                  [
                                    _c("iws-button", {
                                      staticStyle: { width: "100px" },
                                      attrs: {
                                        type: "outline-primary",
                                        text: data?.item?.changeDetails
                                          ? "Hide Details"
                                          : "Compare",
                                        size: "sm",
                                        disabled:
                                          _vm.selectedConfig?.id ===
                                          data.item.id,
                                        click: () =>
                                          _vm.openConfigCompare(data.item),
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                              _vm._v(" "),
                              data?.item?.changeDetails
                                ? _c("tr", { staticClass: "full-width" }, [
                                    _c(
                                      "td",
                                      { attrs: { colspan: "3" } },
                                      [
                                        _vm._l(
                                          data?.item?.changeDetails.changes,
                                          function (changeData) {
                                            return [
                                              _c("iws-table", {
                                                attrs: {
                                                  items: changeData.changes,
                                                  columns: _vm.changeLogColumns,
                                                  sortByCol: "readableProperty",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "cell_compare",
                                                      fn: function ({ data }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              style: {
                                                                color:
                                                                  _vm.changeLogPropertyTextColor(
                                                                    data.item
                                                                      .readableProperty
                                                                  ),
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                                        " +
                                                                  _vm._s(
                                                                    data.item
                                                                      .readableProperty
                                                                  ) +
                                                                  "\n                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                              _vm._v(" "),
                                              _c("iws-button", {
                                                staticStyle: {
                                                  position: "relative",
                                                  top: "-35px",
                                                },
                                                attrs: {
                                                  text: "Restore",
                                                  type: "primary",
                                                  click: () =>
                                                    _vm.restoreVersion(
                                                      data?.item?.changeDetails
                                                        ?.compareVersion
                                                    ),
                                                  disabled: !_vm.hasPermission,
                                                },
                                              }),
                                            ]
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                            ]
                          },
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                })
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }