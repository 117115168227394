var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      title: `${_vm.isEdit ? "Edit" : "Add"} Stream`,
      "primary-button-text": `${_vm.isEdit ? "Edit" : "Add"} Stream`,
      showModal: _vm.showBaseModal,
      "max-width": "500px",
    },
    on: {
      close: _vm.cancelAction,
      "secondary-action": _vm.cancelAction,
      "primary-action": _vm.confirmAction,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("iws-input", {
              ref: "streamID",
              attrs: { value: _vm.data.name, label: "Stream ID", required: "" },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(_vm.data, "name", $event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { "margin-top": "30px" } },
              [
                _c("iws-input", {
                  ref: "url",
                  attrs: {
                    value: _vm.data.connectionURL,
                    label: "RTSP URL",
                    required: "",
                  },
                  on: {
                    "update:value": function ($event) {
                      return _vm.$set(_vm.data, "connectionURL", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "margin-top": "30px" } },
              [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("iws-input", {
                      ref: "onvifUsername",
                      attrs: {
                        value: _vm.data.username,
                        label: "Username",
                        required: "",
                        autocomplete: "username",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.data, "username", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("iws-input", {
                      ref: "onvifPassword",
                      attrs: {
                        value: _vm.data.password,
                        label: "Password",
                        type: "password",
                        required: "",
                        autocomplete: "new-password",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.data, "password", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }