export default {
    TOP_PADDING_KPI: 10,
    NO_VALUE_PLACEHOLDER: '-',
    DEFAULT_DATE_FORMAT: 'YYYY-MM-DD HH:mm:ss',
    SECONDARY_DATE_FORMAT: 'MMMM Do, YYYY',
    ANALYTICS_COLORS: [ '#dd6e42', '#f2cd5d', '#edd3c4', '#f9627d', '#011638', '#ff3c38', '#28afb0', '#b744b8' ],

    chartStyle: {
        primaryBarGraphColour: '#486de8',
        secondaryBarGraphColour: '#ffb0c8',

        primaryLineGraphColour: '#40bfa9',
        secondaryLineGraphColour: '#e07941',

        labelFontColor: '#CCCCCC',
        gridLinesColor: 'rgba(200, 200, 200, 0.2)',
    }
}