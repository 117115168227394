var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.selectedConfig !== null
    ? _c(
        "b-modal",
        {
          staticStyle: { "z-index": "1200" },
          attrs: {
            "hide-footer": "",
            "hide-header": "",
            "no-close-on-backdrop": "",
            size: "xl",
            id: _vm.selectedConfig.type + "modal",
            "content-class": "modal-content main-container div-border modal-bg",
            "body-class": "px-2 py-2",
            title: "Chart Configuration",
          },
          on: { hide: _vm.modalHide },
        },
        [
          _c("div", { attrs: { role: "document" } }, [
            !_vm.isLoading
              ? _c("div", [
                  _vm.selectedAxis
                    ? _c("div", [
                        _c("div", { staticClass: "modal-body py-0" }, [
                          _c(
                            "div",
                            { staticClass: "row justify-content-center my-3" },
                            [_vm._v("Edit Chart Axis")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("label")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedAxis.label,
                                      expression: "selectedAxis.label",
                                    },
                                  ],
                                  ref: "name",
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.selectedAxis.label },
                                  on: {
                                    change: function ($event) {
                                      _vm.axisChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedAxis,
                                        "label",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.nameErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Name is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("min")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.selectedAxis.min,
                                      expression: "selectedAxis.min",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  ref: "minimum",
                                  staticClass: "form-control",
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.selectedAxis.min },
                                  on: {
                                    change: function ($event) {
                                      _vm.axisChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedAxis,
                                        "min",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.minErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Min is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.minMaxErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [
                                          _vm._v(
                                            "Min must be a smaller value than Max"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("max")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.selectedAxis.max,
                                      expression: "selectedAxis.max",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  ref: "maximum",
                                  staticClass: "form-control",
                                  attrs: { type: "number" },
                                  domProps: { value: _vm.selectedAxis.max },
                                  on: {
                                    change: function ($event) {
                                      _vm.axisChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedAxis,
                                        "max",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.maxErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Max is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("ticks")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.selectedAxis.ticks,
                                      expression: "selectedAxis.ticks",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  ref: "ticks",
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    min: "2",
                                    disabled:
                                      _vm.selectedAxis.alignToPrimaryAxis,
                                  },
                                  domProps: { value: _vm.selectedAxis.ticks },
                                  on: {
                                    change: function ($event) {
                                      _vm.selectedAxis.ticks =
                                        _vm.validateNumberInputs(
                                          $event.target.value,
                                          2
                                        )
                                      _vm.axisChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedAxis,
                                        "ticks",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9 text-left" }, [
                                _c("span", [
                                  _c("small", [
                                    _vm._v(
                                      "( Default to 10 tick intervals, minimum: 2 )"
                                    ),
                                  ]),
                                ]),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("position"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedAxis.position,
                                        expression: "selectedAxis.position",
                                      },
                                    ],
                                    ref: "position",
                                    staticClass: "form-control",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.selectedAxis,
                                            "position",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          _vm.axisChanged = true
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          selected:
                                            _vm.selectedAxis.position == "left"
                                              ? "selected"
                                              : "",
                                        },
                                      },
                                      [_vm._v("left")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          selected:
                                            _vm.selectedAxis.position == "right"
                                              ? "selected"
                                              : "",
                                        },
                                      },
                                      [_vm._v("right")]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.positionErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Position is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-3 list-label text-right pl-0",
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.inputLabelLookup("isPrimaryAxis")
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedAxis.isPrimaryAxis,
                                      expression: "selectedAxis.isPrimaryAxis",
                                    },
                                  ],
                                  ref: "primaryAxis",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedAxis.isPrimaryAxis
                                    )
                                      ? _vm._i(
                                          _vm.selectedAxis.isPrimaryAxis,
                                          null
                                        ) > -1
                                      : _vm.selectedAxis.isPrimaryAxis,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedAxis.isPrimaryAxis,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "isPrimaryAxis",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "isPrimaryAxis",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedAxis,
                                            "isPrimaryAxis",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.changePrimaryAxis()
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.inputLabelLookup("alignToPrimaryAxis")
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.selectedAxis.alignToPrimaryAxis,
                                      expression:
                                        "selectedAxis.alignToPrimaryAxis",
                                    },
                                  ],
                                  ref: "useCustomGridlinesColor",
                                  staticClass: "check-box",
                                  attrs: {
                                    type: "checkbox",
                                    disabled: _vm.selectedAxis.isPrimaryAxis,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedAxis.alignToPrimaryAxis
                                    )
                                      ? _vm._i(
                                          _vm.selectedAxis.alignToPrimaryAxis,
                                          null
                                        ) > -1
                                      : _vm.selectedAxis.alignToPrimaryAxis,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedAxis.alignToPrimaryAxis,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "alignToPrimaryAxis",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "alignToPrimaryAxis",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedAxis,
                                            "alignToPrimaryAxis",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        _vm.axisChanged = true
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value:
                                        _vm.getPrimaryAxis == null &&
                                        _vm.selectedAxis.alignToPrimaryAxis,
                                      expression:
                                        "getPrimaryAxis==null && selectedAxis.alignToPrimaryAxis",
                                    },
                                  ],
                                  staticClass: "col-6 text-right",
                                  staticStyle: {
                                    color: "red",
                                    "font-size": "75%",
                                  },
                                },
                                [_vm._v("No primary axis is currently set")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("hideYAxis"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-2 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedAxis.hideYAxis,
                                      expression: "selectedAxis.hideYAxis",
                                    },
                                  ],
                                  ref: "hideYAxis",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedAxis.hideYAxis
                                    )
                                      ? _vm._i(
                                          _vm.selectedAxis.hideYAxis,
                                          null
                                        ) > -1
                                      : _vm.selectedAxis.hideYAxis,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.selectedAxis.hideYAxis,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "hideYAxis",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "hideYAxis",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedAxis,
                                            "hideYAxis",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        _vm.axisChanged = true
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-7" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("logarithmic"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedAxis.logarithmic,
                                      expression: "selectedAxis.logarithmic",
                                    },
                                  ],
                                  ref: "logarithmicYAxis",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedAxis.logarithmic
                                    )
                                      ? _vm._i(
                                          _vm.selectedAxis.logarithmic,
                                          null
                                        ) > -1
                                      : _vm.selectedAxis.logarithmic,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.selectedAxis.logarithmic,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "logarithmic",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "logarithmic",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedAxis,
                                            "logarithmic",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        _vm.axisChanged = true
                                      },
                                    ],
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v("Axis Color")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9 text-left" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "swatch p-0",
                                    on: {
                                      click: function ($event) {
                                        _vm.showAxisColorPicker =
                                          !_vm.showAxisColorPicker
                                      },
                                    },
                                  },
                                  [
                                    _c("div", {
                                      staticClass: "color",
                                      style: {
                                        "background-color":
                                          _vm.selectedAxis.color,
                                      },
                                    }),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.showAxisColorPicker
                                  ? _c(
                                      "div",
                                      { staticClass: "popover" },
                                      [
                                        _c("div", {
                                          staticClass: "cover",
                                          on: {
                                            click: function ($event) {
                                              _vm.showAxisColorPicker = false
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("sketch-picker", {
                                          ref: "defaultColor",
                                          attrs: {
                                            value: _vm.selectedAxis.color
                                              ? _vm.selectedAxis.color
                                              : "#FFFFFF",
                                            id: "color",
                                            disableAlpha: true,
                                            "preset-colors": _vm.presetColors,
                                          },
                                          on: {
                                            input: (color) => {
                                              _vm.selectedAxis.color = color.hex
                                              _vm.axisChanged = true
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.inputLabelLookup("displayGridlines")
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedAxis.displayGridlines,
                                      expression:
                                        "selectedAxis.displayGridlines",
                                    },
                                  ],
                                  ref: "displayGridlines",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedAxis.displayGridlines
                                    )
                                      ? _vm._i(
                                          _vm.selectedAxis.displayGridlines,
                                          null
                                        ) > -1
                                      : _vm.selectedAxis.displayGridlines,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedAxis.displayGridlines,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "displayGridlines",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedAxis,
                                                "displayGridlines",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedAxis,
                                            "displayGridlines",
                                            $$c
                                          )
                                        }
                                      },
                                      _vm.displayGridlinesChanged,
                                    ],
                                  },
                                }),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.selectedAxis.displayGridlines
                            ? _c(
                                "div",
                                { staticClass: "row align-items-center mb-2" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(
                                            _vm.inputLabelLookup(
                                              "customGridlinesColor"
                                            )
                                          ) +
                                          "\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "swatch p-0",
                                          on: {
                                            click: function ($event) {
                                              _vm.showCustomGridlinesColorPicker =
                                                !_vm.showCustomGridlinesColorPicker
                                            },
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "color",
                                            style: {
                                              "background-color":
                                                _vm.selectedAxis
                                                  .customGridlinesColor,
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.showCustomGridlinesColorPicker
                                        ? _c(
                                            "div",
                                            { staticClass: "popover" },
                                            [
                                              _c("div", {
                                                staticClass: "cover",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showCustomGridlinesColorPicker = false
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("sketch-picker", {
                                                ref: "customGridlinesColorPicker",
                                                attrs: {
                                                  value: _vm.selectedAxis
                                                    .customGridlinesColor
                                                    ? _vm.selectedAxis
                                                        .customGridlinesColor
                                                    : "#FFFFFF",
                                                  id: "customGridlinesColorPicker",
                                                  disableAlpha: true,
                                                  "preset-colors":
                                                    _vm.presetColors,
                                                },
                                                on: {
                                                  input: (color) => {
                                                    _vm.selectedAxis.customGridlinesColor =
                                                      color.hex
                                                    _vm.axisChanged = true
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "modal-footer d-flex flex-row",
                              staticStyle: { "border-top-width": "0px" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("yaxis")
                                    },
                                  },
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("config")
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _vm._v(" "),
                              _vm.hasPermission()
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success green-button",
                                      attrs: {
                                        type: "button",
                                        disabled:
                                          !_vm.configInputChanged() ||
                                          !_vm.hasPermission(),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveConfig("yaxis")
                                        },
                                      },
                                    },
                                    [_vm._v("Save Template")]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success green-button",
                                      attrs: { type: "button", disabled: "" },
                                    },
                                    [_vm._v("Save Template")]
                                  ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm.selectedChartItem
                    ? _c("div", [
                        _c("div", { staticClass: "modal-body py-0" }, [
                          _c(
                            "div",
                            { staticClass: "row justify-content-center my-3" },
                            [_vm._v("Edit Data Source")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("tagName"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-9" },
                                [
                                  _c("autocomplete", {
                                    ref: "tagName",
                                    staticClass:
                                      "form-control d-flex flex-grow-1",
                                    attrs: {
                                      search: _vm.search,
                                      placeholder: "Search for a tag name",
                                      "get-result-value": _vm.getResultValue,
                                      "default-value":
                                        _vm.selectedChartItem.tagName,
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.chartItemChanged =
                                          _vm.tagNameChanged = true
                                      },
                                      blur: function ($event) {
                                        _vm.selectedChartItem.tagName =
                                          $event.target.value
                                      },
                                      submit: _vm.onTagChanged,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "result",
                                          fn: function ({ result, props }) {
                                            return [
                                              _c(
                                                "a",
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action text-secondary p-1 text-left",
                                                    attrs: { href: "#" },
                                                  },
                                                  "a",
                                                  props,
                                                  false
                                                ),
                                                [_vm._v(_vm._s(result.name))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2029283812
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.tagErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Source Tag Name is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.isTagAlreadyUsed
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [
                                          _vm._v(
                                            "Source Tag Name is already in use"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.getWellboreWell !== false
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup("friendlyName")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-9" }, [
                                    _c("div", { staticClass: "row" }, [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "col-5 input-group pr-0",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "input-group-text mr-2",
                                            },
                                            [_vm._v("Prefix")]
                                          ),
                                          _vm._v(" "),
                                          _vm.isWellheadTag(
                                            _vm.selectedChartItem.tagName
                                          ) &&
                                          _vm.getWellheadPrefixForTag(
                                            _vm.selectedChartItem.tagName
                                          ) !== ""
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-auto align-self-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.getWellheadPrefixForTag(
                                                          _vm.selectedChartItem
                                                            .tagName
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.selectedChartItem
                                                        .prefixName,
                                                    expression:
                                                      "selectedChartItem.prefixName",
                                                  },
                                                ],
                                                ref: "friendlyNamePrefix",
                                                staticClass:
                                                  "form-control rounded col-auto",
                                                attrs: { type: "text" },
                                                domProps: {
                                                  value:
                                                    _vm.selectedChartItem
                                                      .prefixName,
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.chartItemChanged = true
                                                  },
                                                  input: function ($event) {
                                                    if ($event.target.composing)
                                                      return
                                                    _vm.$set(
                                                      _vm.selectedChartItem,
                                                      "prefixName",
                                                      $event.target.value
                                                    )
                                                  },
                                                },
                                              }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "col-7 input-group" },
                                        [
                                          _vm.isWellheadTag(
                                            _vm.selectedChartItem.tagName
                                          ) &&
                                          _vm.getWellheadSuffixTitleForTag(
                                            _vm.selectedChartItem.tagName
                                          ) !== ""
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col-auto align-self-center",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.getWellheadSuffixTitleForTag(
                                                          _vm.selectedChartItem
                                                            .tagName
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "input-group-text mr-2",
                                            },
                                            [_vm._v("Suffix")]
                                          ),
                                          _vm._v(" "),
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value:
                                                  _vm.selectedChartItem
                                                    .suffixName,
                                                expression:
                                                  "selectedChartItem.suffixName",
                                              },
                                            ],
                                            ref: "friendlyNameSuffix",
                                            staticClass:
                                              "form-control rounded col-auto",
                                            attrs: { type: "text" },
                                            domProps: {
                                              value:
                                                _vm.selectedChartItem
                                                  .suffixName,
                                            },
                                            on: {
                                              change: function ($event) {
                                                _vm.chartItemChanged = true
                                              },
                                              input: function ($event) {
                                                if ($event.target.composing)
                                                  return
                                                _vm.$set(
                                                  _vm.selectedChartItem,
                                                  "suffixName",
                                                  $event.target.value
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      ),
                                    ]),
                                  ]),
                                ]
                              )
                            : _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup("friendlyName")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-9" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.selectedChartItem.friendlyName,
                                          expression:
                                            "selectedChartItem.friendlyName",
                                        },
                                      ],
                                      ref: "friendlyName",
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.selectedChartItem.friendlyName,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.chartItemChanged = true
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.selectedChartItem,
                                            "friendlyName",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("unit")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedChartItem.unit,
                                      expression: "selectedChartItem.unit",
                                    },
                                  ],
                                  ref: "unit",
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.selectedChartItem.unit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.chartItemChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedChartItem,
                                        "unit",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.unitErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Unit is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("chartAxis"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-6" },
                                [
                                  _c("autocomplete", {
                                    ref: "chartyaxis",
                                    staticClass:
                                      "form-control d-flex flex-grow-1",
                                    attrs: {
                                      search: _vm.chartYAxisSearch,
                                      placeholder: "Search for an axis name",
                                      "get-result-value":
                                        _vm.getChartYAxisValue,
                                      "default-value": _vm.selectedChartItem
                                        .chartYAxis_key
                                        ? _vm.findAxisLabel(
                                            _vm.selectedChartItem.chartYAxis_key
                                          )
                                        : "",
                                    },
                                    on: {
                                      submit: _vm.checkChartYAxis,
                                      change: _vm.checkChartYAxis,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "result",
                                          fn: function ({ result, props }) {
                                            return [
                                              _c(
                                                "a",
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action text-secondary p-1 text-left",
                                                    attrs: { href: "#" },
                                                  },
                                                  "a",
                                                  props,
                                                  false
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(result.label) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      4153542533
                                    ),
                                  }),
                                  _vm._v(" "),
                                  _vm.chartYAxisErrorSeen
                                    ? _c(
                                        "div",
                                        {
                                          staticClass: "col-7 text-left",
                                          staticStyle: { "padding-left": "0" },
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "error-span" },
                                            [_vm._v("Chart Axis is required")]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass: "col-3 list-label text-left p-0",
                                },
                                [
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip:top",
                                          value: "Add Y axis",
                                          expression: "'Add Y axis'",
                                          arg: "top",
                                        },
                                      ],
                                      staticClass:
                                        "fake-button show-clicker-finger",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addAxis()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-plus-circle",
                                        staticStyle: { color: "white" },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip:top",
                                          value: "Edit Y axis",
                                          expression: "'Edit Y axis'",
                                          arg: "top",
                                        },
                                      ],
                                      staticClass:
                                        "fake-button show-clicker-finger",
                                      attrs: {
                                        disabled: _vm.chartYAxisMissing,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editAxis()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-pen",
                                        staticStyle: { color: "white" },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip:top",
                                          value: "Delete Y axis",
                                          expression: "'Delete Y axis'",
                                          arg: "top",
                                        },
                                      ],
                                      staticClass:
                                        "fake-button show-clicker-finger",
                                      attrs: {
                                        disabled: _vm.chartYAxisMissing,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteAxis()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-trash-alt",
                                        staticStyle: { color: "white" },
                                      }),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "row justify-content-center align-items-center mb-2",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "col-3 list-label text-right",
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.inputLabelLookup("decimalPrecision")
                                      )
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-2" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model.number",
                                        value:
                                          _vm.selectedChartItem
                                            .decimalPrecision,
                                        expression:
                                          "selectedChartItem.decimalPrecision",
                                        modifiers: { number: true },
                                      },
                                    ],
                                    ref: "decimals",
                                    staticClass: "form-control",
                                    attrs: {
                                      type: "number",
                                      id: "decimals",
                                      name: "decimals",
                                      step: "1",
                                      min: "0",
                                      max: "9",
                                    },
                                    domProps: {
                                      value:
                                        _vm.selectedChartItem.decimalPrecision,
                                    },
                                    on: {
                                      change: function ($event) {
                                        _vm.selectedChartItem.decimalPrecision =
                                          _vm.validateNumberInputs(
                                            $event.target.value,
                                            0,
                                            9
                                          )
                                        _vm.chartItemChanged = true
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.selectedChartItem,
                                          "decimalPrecision",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      blur: function ($event) {
                                        return _vm.$forceUpdate()
                                      },
                                    },
                                  }),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-7" }),
                              ]
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("lineWidth"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-2" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.selectedChartItem.lineWidth,
                                      expression: "selectedChartItem.lineWidth",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  ref: "lineWidth",
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "number",
                                    id: "points",
                                    name: "points",
                                    step: "1",
                                    min: "1",
                                    max: "5",
                                  },
                                  domProps: {
                                    value: _vm.selectedChartItem.lineWidth,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.selectedChartItem.lineWidth =
                                        _vm.validateNumberInputs(
                                          $event.target.value,
                                          1,
                                          5
                                        )
                                      _vm.chartItemChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedChartItem,
                                        "lineWidth",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-7" }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.getWellboreWell !== false
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup(
                                            "customLineColor"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-2 text-left" },
                                    [
                                      _c("input", {
                                        ref: "customLineColor",
                                        staticClass: "check-box",
                                        attrs: { type: "checkbox" },
                                        domProps: {
                                          checked: _vm.customLineColor,
                                        },
                                        on: {
                                          change: function ($event) {
                                            _vm.customLineColor =
                                              $event.target.checked
                                            _vm.selectedChartItem.customLineColor =
                                              $event.target.checked
                                            _vm.chartItemChanged = true
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          (_vm.getWellboreWell !== false &&
                            _vm.selectedChartItem.customLineColor) ||
                          _vm.getWellboreWell == false
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [_vm._v("Line Color")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-2 text-left" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "swatch",
                                          on: {
                                            click: function ($event) {
                                              _vm.showDefaultColorPicker =
                                                !_vm.showDefaultColorPicker
                                            },
                                          },
                                        },
                                        [
                                          _c("div", {
                                            staticClass: "color",
                                            style: {
                                              "background-color":
                                                _vm.selectedChartItem.color,
                                            },
                                          }),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _vm.showDefaultColorPicker
                                        ? _c(
                                            "div",
                                            { staticClass: "popover" },
                                            [
                                              _c("div", {
                                                staticClass: "cover",
                                                on: {
                                                  click: function ($event) {
                                                    _vm.showDefaultColorPicker = false
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("sketch-picker", {
                                                ref: "defaultColor",
                                                attrs: {
                                                  value:
                                                    _vm.selectedChartItem.color,
                                                  id: "color",
                                                  disableAlpha: true,
                                                  "preset-colors":
                                                    _vm.presetColors,
                                                },
                                                on: {
                                                  input: (color) => {
                                                    _vm.selectedChartItem.color =
                                                      color.hex
                                                    _vm.chartItemChanged = true
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-2 text-left" },
                                    [
                                      _vm.colorErrorSeen
                                        ? _c(
                                            "span",
                                            { staticClass: "error-span" },
                                            [_vm._v("Color is required")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-5" }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("bgColor"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-4 text-left" }, [
                                _c("input", {
                                  ref: "customBgColor",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: _vm.chartItemBgColorEnabled,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.chartItemBgColorEnabled = Boolean(
                                        $event.target.checked
                                      )
                                      _vm.selectedChartItem.bgColor =
                                        _vm.chartItemBgColorEnabled
                                          ? "#FFFFFF"
                                          : null
                                      _vm.chartItemChanged = true
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _vm.chartItemBgColorEnabled
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "ml-2 swatch",
                                        on: {
                                          click: function ($event) {
                                            _vm.showBGColorPicker =
                                              !_vm.showBGColorPicker
                                          },
                                        },
                                      },
                                      [
                                        _c("div", {
                                          staticClass: "color",
                                          style: {
                                            "background-color":
                                              _vm.selectedChartItem.bgColor,
                                          },
                                        }),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.showBGColorPicker
                                  ? _c(
                                      "div",
                                      { staticClass: "popover" },
                                      [
                                        _c("div", {
                                          staticClass: "cover",
                                          on: {
                                            click: function ($event) {
                                              _vm.showBGColorPicker = false
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("sketch-picker", {
                                          ref: "bgColor",
                                          attrs: {
                                            value:
                                              _vm.selectedChartItem.bgColor,
                                            id: "bgColor",
                                            disableAlpha: true,
                                            "preset-colors": _vm.presetColors,
                                          },
                                          on: {
                                            input: (color) => {
                                              _vm.selectedChartItem.bgColor =
                                                color.hex
                                              _vm.chartItemChanged = true
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-5" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.inputLabelLookup("showDataPoints")
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-2 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.selectedChartItem.showDataPoints,
                                      expression:
                                        "selectedChartItem.showDataPoints",
                                    },
                                  ],
                                  ref: "showDataPoints",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedChartItem.showDataPoints
                                    )
                                      ? _vm._i(
                                          _vm.selectedChartItem.showDataPoints,
                                          null
                                        ) > -1
                                      : _vm.selectedChartItem.showDataPoints,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedChartItem
                                              .showDataPoints,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedChartItem,
                                                "showDataPoints",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedChartItem,
                                                "showDataPoints",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedChartItem,
                                            "showDataPoints",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        _vm.selectedChartItem.showDataPoints =
                                          Boolean($event.target.checked)
                                        _vm.chartItemChanged = true
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-7" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.inputLabelLookup("selectedByDefault")
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-2 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.selectedChartItem.selectedByDefault,
                                      expression:
                                        "selectedChartItem.selectedByDefault",
                                    },
                                  ],
                                  ref: "selectedByDefault",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedChartItem.selectedByDefault
                                    )
                                      ? _vm._i(
                                          _vm.selectedChartItem
                                            .selectedByDefault,
                                          null
                                        ) > -1
                                      : _vm.selectedChartItem.selectedByDefault,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedChartItem
                                              .selectedByDefault,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedChartItem,
                                                "selectedByDefault",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedChartItem,
                                                "selectedByDefault",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedChartItem,
                                            "selectedByDefault",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        _vm.selectedChartItem.selectedByDefault =
                                          Boolean($event.target.checked)
                                        _vm.chartItemChanged = true
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-7" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("staticSource"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-2 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedChartItem.staticSource,
                                      expression:
                                        "selectedChartItem.staticSource",
                                    },
                                  ],
                                  ref: "staticSource",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedChartItem.staticSource
                                    )
                                      ? _vm._i(
                                          _vm.selectedChartItem.staticSource,
                                          null
                                        ) > -1
                                      : _vm.selectedChartItem.staticSource,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedChartItem.staticSource,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedChartItem,
                                                "staticSource",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedChartItem,
                                                "staticSource",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedChartItem,
                                            "staticSource",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        _vm.selectedChartItem.staticSource =
                                          Boolean($event.target.checked)
                                        _vm.chartItemChanged = true
                                      },
                                    ],
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-7" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("hr", { staticClass: "w-100" }),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "modal-footer d-flex flex-row",
                              staticStyle: { "border-top-width": "0px" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: {
                                    type: "button",
                                    disabled: _vm.quickEditModalAccess,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("chartitem")
                                    },
                                  },
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("config")
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _vm._v(" "),
                              _vm.hasPermission
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success green-button",
                                      attrs: {
                                        type: "button",
                                        disabled:
                                          !_vm.configInputChanged() ||
                                          !_vm.hasPermission(),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveConfig("chartitem")
                                        },
                                      },
                                    },
                                    [_vm._v("Save Template")]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success green-button",
                                      attrs: { type: "button", disabled: "" },
                                    },
                                    [_vm._v("Save Template")]
                                  ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm.selectedSection
                    ? _c("div", [
                        _c("div", { staticClass: "modal-body py-0" }, [
                          _c(
                            "div",
                            { staticClass: "row justify-content-center my-3" },
                            [_vm._v("Edit Section")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-left align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("name")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedSection.name,
                                      expression: "selectedSection.name",
                                    },
                                  ],
                                  ref: "sectionName",
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: { value: _vm.selectedSection.name },
                                  on: {
                                    change: function ($event) {
                                      _vm.sectionChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedSection,
                                        "name",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("input", {
                                  ref: "sectionID",
                                  attrs: { type: "hidden", value: "" },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.sectionErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Name is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c("hr", { staticClass: "w-100" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row mt-2 mb-1 px-3 align-items-center",
                            },
                            [
                              _vm._v(
                                "\n                            Data Sources\n                            "
                              ),
                              _c("i", {
                                staticClass:
                                  "fas fa-plus-circle ml-2 show-clicker-finger",
                                on: {
                                  click: function ($event) {
                                    return _vm.addChartItem()
                                  },
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.selectedSection.orderedChartItems.filter(
                            (chartItem) => chartItem
                          ).length > 0
                            ? _c("div", [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mx-3 align-items-center",
                                    staticStyle: {
                                      "overflow-y": "auto",
                                      "overflow-x": "hidden",
                                      "max-height": "300px",
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectedSection.orderedChartItems,
                                    function (chartItem, index) {
                                      return _c(
                                        "div",
                                        { key: index, staticClass: "row" },
                                        [
                                          _vm.isWellheadTag(chartItem.tagName)
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col ml-4 text-left font-weight-light",
                                                  staticStyle: {
                                                    display: "inline",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        _vm.getWellheadTagNameForChartItem(
                                                          chartItem
                                                        )
                                                      ) +
                                                      "\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "col ml-4 text-left font-weight-light",
                                                  staticStyle: {
                                                    display: "inline",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        " +
                                                      _vm._s(
                                                        chartItem.friendlyName
                                                      ) +
                                                      " ( " +
                                                      _vm._s(
                                                        chartItem.tagName
                                                      ) +
                                                      " )\n                                    "
                                                  ),
                                                ]
                                              ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass: "col-2",
                                              staticStyle: {
                                                display: "inline",
                                                float: "right",
                                                "inline-size": "fit-content",
                                              },
                                            },
                                            [
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-pen show-clicker-finger mr-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.editChartItem(
                                                      chartItem,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("i", {
                                                staticClass:
                                                  "fas fa-trash-alt show-clicker-finger mr-2",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.deleteChartItem(
                                                      chartItem,
                                                      index
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm.getChartItemWellboreWell(
                                            chartItem
                                          ) &&
                                          _vm.getChartItemWellboreWell(
                                            chartItem
                                          ).name
                                            ? _c("div", {
                                                staticClass: "col-1",
                                                style: {
                                                  width: "25px",
                                                  height: "25px",
                                                  "background-color":
                                                    chartItem.customLineColor
                                                      ? chartItem.color
                                                      : chartItem.wellColor
                                                      ? chartItem.wellColor
                                                      : _vm.getChartItemWellboreWell(
                                                          chartItem
                                                        ).color,
                                                  float: "right",
                                                  "inline-size": "fit-content",
                                                },
                                              })
                                            : _c("div", {
                                                staticClass: "col-1",
                                                style: {
                                                  width: "25px",
                                                  height: "25px",
                                                  "background-color":
                                                    chartItem.color,
                                                  float: "right",
                                                  "inline-size": "fit-content",
                                                },
                                              }),
                                          _vm._v(" "),
                                          _c("hr", {
                                            staticClass: "w-100 mx-4 my-1",
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _c(
                                "div",
                                { staticClass: "row justify-content-center" },
                                [_vm._v("No Data Sources Added")]
                              ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "modal-footer d-flex flex-row",
                              staticStyle: { "border-top-width": "0px" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("section")
                                    },
                                  },
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("config")
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-success green-button",
                                  attrs: {
                                    type: "button",
                                    disabled:
                                      !_vm.configInputChanged() ||
                                      !_vm.sectionChanged ||
                                      !_vm.hasPermission(),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.saveConfig("section")
                                    },
                                  },
                                },
                                [_vm._v("Save Template")]
                              ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm.selectedCustomXAxis
                    ? _c("div", [
                        _c("div", { staticClass: "modal-body py-0" }, [
                          _c(
                            "div",
                            { staticClass: "row justify-content-center my-3" },
                            [_vm._v("Edit Custom Axis")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("tagName"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-9" },
                                [
                                  _c("autocomplete", {
                                    ref: "tagName",
                                    staticClass:
                                      "form-control d-flex flex-grow-1",
                                    attrs: {
                                      search: _vm.search,
                                      placeholder: "Search for a tag name",
                                      "get-result-value": _vm.getResultValue,
                                      "default-value":
                                        _vm.selectedCustomXAxis.tagName,
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.updateCustomXAxisTagname(
                                          $event
                                        )
                                      },
                                      submit: _vm.onCustomAxisTagChange,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "result",
                                          fn: function ({ result, props }) {
                                            return [
                                              _c(
                                                "a",
                                                _vm._b(
                                                  {
                                                    staticClass:
                                                      "list-group-item list-group-item-action text-secondary p-1 text-left",
                                                    attrs: { href: "#" },
                                                  },
                                                  "a",
                                                  props,
                                                  false
                                                ),
                                                [_vm._v(_vm._s(result.name))]
                                              ),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      2029283812
                                    ),
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.customAxisTagError
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Source Tag Name is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup(" label")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedCustomXAxis.label,
                                      expression: "selectedCustomXAxis.label",
                                    },
                                  ],
                                  ref: "label",
                                  staticClass: "form-control",
                                  attrs: {
                                    type: "text",
                                    placeholder: "Enter a label",
                                  },
                                  domProps: {
                                    value: _vm.selectedCustomXAxis.label,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.customXAxisChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedCustomXAxis,
                                        "label",
                                        $event.target.value
                                      )
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.nameErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Label is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("unit")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  ref: "unit",
                                  staticClass: "form-control",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value: _vm.selectedCustomXAxis.unit,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.selectedCustomXAxis.unit =
                                        $event.target.value
                                      _vm.customXAxisChanged = true
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.unitErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Unit is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("min")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.selectedCustomXAxis.min,
                                      expression: "selectedCustomXAxis.min",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  ref: "minimum",
                                  staticClass: "form-control",
                                  attrs: { type: "number" },
                                  domProps: {
                                    value: _vm.selectedCustomXAxis.min,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.customXAxisChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedCustomXAxis,
                                        "min",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.minErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Min is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.minMaxErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [
                                          _vm._v(
                                            "Min must be a smaller value than Max"
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v(_vm._s(_vm.inputLabelLookup("max")))]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.number",
                                      value: _vm.selectedCustomXAxis.max,
                                      expression: "selectedCustomXAxis.max",
                                      modifiers: { number: true },
                                    },
                                  ],
                                  ref: "maximum",
                                  staticClass: "form-control",
                                  attrs: { type: "number" },
                                  domProps: {
                                    value: _vm.selectedCustomXAxis.max,
                                  },
                                  on: {
                                    change: function ($event) {
                                      _vm.customXAxisChanged = true
                                    },
                                    input: function ($event) {
                                      if ($event.target.composing) return
                                      _vm.$set(
                                        _vm.selectedCustomXAxis,
                                        "max",
                                        _vm._n($event.target.value)
                                      )
                                    },
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.maxErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Max is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("position"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9" }, [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.selectedCustomXAxis.position,
                                        expression:
                                          "selectedCustomXAxis.position",
                                      },
                                    ],
                                    ref: "position",
                                    staticClass: "form-control",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.selectedCustomXAxis,
                                            "position",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          _vm.customXAxisChanged = true
                                        },
                                      ],
                                    },
                                  },
                                  [
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          selected:
                                            _vm.selectedCustomXAxis.position ==
                                            "top"
                                              ? "selected"
                                              : "",
                                        },
                                      },
                                      [_vm._v("top")]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "option",
                                      {
                                        domProps: {
                                          selected:
                                            _vm.selectedCustomXAxis.position ==
                                            "bottom"
                                              ? "selected"
                                              : "",
                                        },
                                      },
                                      [_vm._v("bottom")]
                                    ),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-3 text-right" }),
                              _vm._v(" "),
                              _vm.positionErrorSeen
                                ? _c(
                                    "div",
                                    { staticClass: "col-9 text-left" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "error-span" },
                                        [_vm._v("Position is required")]
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.inputLabelLookup("logarithmic"))
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-9 text-left" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value:
                                        _vm.selectedCustomXAxis.logarithmic,
                                      expression:
                                        "selectedCustomXAxis.logarithmic",
                                    },
                                  ],
                                  ref: "logarithmicXAxis",
                                  staticClass: "check-box",
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.selectedCustomXAxis.logarithmic
                                    )
                                      ? _vm._i(
                                          _vm.selectedCustomXAxis.logarithmic,
                                          null
                                        ) > -1
                                      : _vm.selectedCustomXAxis.logarithmic,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a =
                                            _vm.selectedCustomXAxis.logarithmic,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.selectedCustomXAxis,
                                                "logarithmic",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.selectedCustomXAxis,
                                                "logarithmic",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.selectedCustomXAxis,
                                            "logarithmic",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        _vm.customXAxisChanged = true
                                      },
                                    ],
                                  },
                                }),
                              ]),
                            ]
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "div",
                            {
                              staticClass: "modal-footer d-flex flex-row",
                              staticStyle: { "border-top-width": "0px" },
                            },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("xaxis")
                                    },
                                  },
                                },
                                [_vm._v("Back")]
                              ),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-secondary grey-button",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.backPressed("config")
                                    },
                                  },
                                },
                                [_vm._v("Close")]
                              ),
                              _vm._v(" "),
                              _vm.hasPermission
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success green-button",
                                      attrs: {
                                        type: "button",
                                        disabled:
                                          !_vm.configInputChanged() ||
                                          !_vm.hasPermission(),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.saveConfig("xaxis")
                                        },
                                      },
                                    },
                                    [_vm._v("Save Template")]
                                  )
                                : _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-success green-button",
                                      attrs: { type: "button", disabled: "" },
                                    },
                                    [_vm._v("Save Template")]
                                  ),
                            ]
                          ),
                        ]),
                      ])
                    : _vm.selectedConfig
                    ? _c("div", [
                        _c("div", { staticClass: "modal-body py-0" }, [
                          _c(
                            "div",
                            { staticClass: "row justify-content-center my-3" },
                            [_vm._v("Chart Configuration")]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row justify-content-center align-items-center mb-2",
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "col-3 list-label text-right" },
                                [_vm._v("Selected Template")]
                              ),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-7" }, [
                                _c("div", { staticClass: "select-wrapper" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-content-between pl-3 p-1 align-items-center select form-control",
                                      class: { active: _vm.isActive },
                                      on: {
                                        click: function ($event) {
                                          _vm.isActive = !_vm.isActive
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "select-value" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectedConfig.templateName
                                            )
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "h-100 d-flex align-items-center",
                                        },
                                        [
                                          _c("img", {
                                            staticClass: "select-arrow",
                                            attrs: {
                                              src: "/images/dropdown.png",
                                            },
                                          }),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "select-dropdown",
                                      class: { active: _vm.isActive },
                                    },
                                    _vm._l(
                                      _vm.options,
                                      function (group, index) {
                                        return _c(
                                          "div",
                                          {
                                            key: index,
                                            staticClass: "select-group",
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "select-group-label",
                                              },
                                              [_vm._v(_vm._s(group.label))]
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              group.options,
                                              function (option, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: index,
                                                    staticClass:
                                                      "select-option",
                                                    staticStyle: {
                                                      cursor: "default",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.onTemplateDropdownChange(
                                                          option
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        option.templateName
                                                      ) +
                                                        "\n                                    "
                                                    ),
                                                    option.id ===
                                                    _vm.selectedConfig.id
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "selected-check ml-2",
                                                          },
                                                          [
                                                            _c("i", {
                                                              staticClass:
                                                                "fas fa-check",
                                                            }),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "col-2 pl-0" },
                                [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "fake-button white-text p-1",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addConfig()
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip:top",
                                            value: "New Configuration",
                                            expression: "'New Configuration'",
                                            arg: "top",
                                          },
                                        ],
                                        staticClass:
                                          "fas fa-plus-circle show-clicker-finger",
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass:
                                        "fake-button white-text p-1 ml-1",
                                      class: {
                                        "text-secondary":
                                          _vm.selectedConfig.hasValidationError,
                                        "show-clicker-finger":
                                          !_vm.selectedConfig
                                            .hasValidationError,
                                      },
                                      attrs: {
                                        disabled:
                                          _vm.selectedConfig.hasValidationError,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.copyConfig(false)
                                        },
                                      },
                                    },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-copy white-text",
                                        class: {
                                          "text-secondary":
                                            _vm.selectedConfig
                                              .hasValidationError,
                                        },
                                        attrs: { id: "copyConfigIcon" },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "b-tooltip",
                                        {
                                          attrs: {
                                            target: "copyConfigIcon",
                                            triggers: "hover",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(
                                                !_vm.selectedConfig
                                                  .hasValidationError
                                                  ? "Clone Configuration"
                                                  : "Legacy Configuration Cannot Clone"
                                              ) +
                                              "\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _vm.canDelete || _vm.isAdmin
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "fake-button white-text p-1 ml-1",
                                          attrs: {
                                            disabled:
                                              _vm.isGlobalConfigSelected,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteConfig()
                                            },
                                          },
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "fas fa-trash-alt show-clicker-finger",
                                            class: {
                                              "text-secondary":
                                                _vm.isGlobalConfigSelected,
                                            },
                                            attrs: { id: "configTrashIcon" },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "b-tooltip",
                                            {
                                              attrs: {
                                                target: "configTrashIcon",
                                                triggers: "hover",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(
                                                    !_vm.isGlobalConfigSelected
                                                      ? "Delete Configuration"
                                                      : "Cannot Delete Global Configuration"
                                                  ) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.isFeatureFlagged(
                                            "CHART_CONFIG_HISTORY"
                                          ),
                                          expression:
                                            "isFeatureFlagged('CHART_CONFIG_HISTORY')",
                                        },
                                      ],
                                      staticClass:
                                        "fake-button white-text ml-1",
                                    },
                                    [
                                      _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip:top",
                                            value:
                                              "Restore Chart Configuration",
                                            expression:
                                              "'Restore Chart Configuration'",
                                            arg: "top",
                                          },
                                        ],
                                        staticClass:
                                          "fas fa-history show-clicker-finger",
                                        attrs: {
                                          id: "history-popover-target-1",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.isFeatureFlagged("CHART_CONFIG_HISTORY")
                                    ? _c(
                                        "b-popover",
                                        {
                                          attrs: {
                                            "custom-class":
                                              "config-history-popover",
                                            target: "history-popover-target-1",
                                            triggers: "hover",
                                            placement: "bottom",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "title",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "history-popover-title",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "Version History"
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            1233004812
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _vm.changeLogData.length == 0
                                            ? _c(
                                                "div",
                                                [
                                                  _c(
                                                    "b-overlay",
                                                    {
                                                      attrs: {
                                                        show: _vm.isLoadingVersionHistory,
                                                        rounded: "sm",
                                                        "bg-color": "#f9f9fa",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "p-1" },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticStyle: {
                                                                "max-height":
                                                                  "600px",
                                                                "overflow-y":
                                                                  "auto",
                                                              },
                                                            },
                                                            [
                                                              _c("dark-table", {
                                                                attrs: {
                                                                  items:
                                                                    _vm.versionHistory,
                                                                  columns: [
                                                                    ..._vm.chartConfigHistoryColumns,
                                                                  ],
                                                                  sortByCol:
                                                                    _vm.sortByCol,
                                                                  sortAsc:
                                                                    _vm.sortAsc,
                                                                  busy: _vm.isLoadingVersionHistory,
                                                                },
                                                                on: {
                                                                  "update:sortByCol":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.sortByCol =
                                                                        $event
                                                                    },
                                                                  "update:sort-by-col":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.sortByCol =
                                                                        $event
                                                                    },
                                                                  "update:sortAsc":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.sortAsc =
                                                                        $event
                                                                    },
                                                                  "update:sort-asc":
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      _vm.sortAsc =
                                                                        $event
                                                                    },
                                                                },
                                                                scopedSlots:
                                                                  _vm._u(
                                                                    [
                                                                      {
                                                                        key: "body",
                                                                        fn: function () {
                                                                          return _vm._l(
                                                                            _vm.versionHistory,
                                                                            function (
                                                                              item,
                                                                              index
                                                                            ) {
                                                                              return _c(
                                                                                "tr",
                                                                                {
                                                                                  key: index,
                                                                                },
                                                                                [
                                                                                  _c(
                                                                                    "td",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.created_at
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.templateName
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.owned_by
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    [
                                                                                      _vm._v(
                                                                                        _vm._s(
                                                                                          item.user_role
                                                                                        )
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "p-1 text-center",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "show-clicker-finger grey-button btn-sm",
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "white",
                                                                                              "min-width":
                                                                                                "80px",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                !_vm.companyConfigs ||
                                                                                                _vm
                                                                                                  .companyConfigs
                                                                                                  .length ===
                                                                                                  0 ||
                                                                                                !_vm.showVersionHistoryButtons ||
                                                                                                _vm
                                                                                                  .selectedConfig
                                                                                                  .id ===
                                                                                                  item.id,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.compareVersion(
                                                                                                  item
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _vm._v(
                                                                                            "\n                                                                Compare\n                                                            "
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                  _vm._v(
                                                                                    " "
                                                                                  ),
                                                                                  _c(
                                                                                    "td",
                                                                                    {
                                                                                      staticClass:
                                                                                        "p-1 text-center",
                                                                                    },
                                                                                    [
                                                                                      _c(
                                                                                        "button",
                                                                                        {
                                                                                          staticClass:
                                                                                            "fake-button show-clicker-finger",
                                                                                          staticStyle:
                                                                                            {
                                                                                              color:
                                                                                                "white",
                                                                                            },
                                                                                          attrs:
                                                                                            {
                                                                                              disabled:
                                                                                                _vm
                                                                                                  .selectedConfig
                                                                                                  .id ===
                                                                                                item.id,
                                                                                            },
                                                                                          on: {
                                                                                            click:
                                                                                              function (
                                                                                                $event
                                                                                              ) {
                                                                                                return _vm.restoreVersion(
                                                                                                  item
                                                                                                )
                                                                                              },
                                                                                          },
                                                                                        },
                                                                                        [
                                                                                          _c(
                                                                                            "i",
                                                                                            {
                                                                                              staticClass:
                                                                                                "fas fa-undo-alt show-clicker-finger",
                                                                                              class:
                                                                                                {
                                                                                                  disabledIcon:
                                                                                                    _vm
                                                                                                      .selectedConfig
                                                                                                      .id ===
                                                                                                    item.id,
                                                                                                },
                                                                                            }
                                                                                          ),
                                                                                        ]
                                                                                      ),
                                                                                    ]
                                                                                  ),
                                                                                ]
                                                                              )
                                                                            }
                                                                          )
                                                                        },
                                                                        proxy: true,
                                                                      },
                                                                    ],
                                                                    null,
                                                                    false,
                                                                    1304785491
                                                                  ),
                                                              }),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "d-flex justify-content-between align-items-center mt-2",
                                                            },
                                                            [
                                                              _c("div", [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "text-left",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                Showing " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .versionHistory
                                                                            .length
                                                                        ) +
                                                                        " / " +
                                                                        _vm._s(
                                                                          _vm
                                                                            .versionHistoryAll
                                                                            .length
                                                                        ) +
                                                                        " results\n                                              "
                                                                    ),
                                                                  ]
                                                                ),
                                                              ]),
                                                              _vm._v(" "),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "d-flex",
                                                                },
                                                                [
                                                                  _vm.showVersionHistoryButtons
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-primary cobaltButton",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.isHistoryButtonDisabled,
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  _vm.showMore,
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                  Show More\n                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                  _vm._v(" "),
                                                                  _vm.showVersionHistoryButtons
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "ml-2",
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "button",
                                                                            {
                                                                              staticClass:
                                                                                "btn btn-primary cobaltButton",
                                                                              attrs:
                                                                                {
                                                                                  disabled:
                                                                                    _vm.isHistoryButtonDisabled,
                                                                                },
                                                                              on: {
                                                                                click:
                                                                                  _vm.showAll,
                                                                              },
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "\n                                                  Show All\n                                                "
                                                                              ),
                                                                            ]
                                                                          ),
                                                                        ]
                                                                      )
                                                                    : _vm._e(),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _c("div", [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "d-flex flex-column justify-content-center",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass: "w-100",
                                                        staticStyle: {
                                                          overflow: "scroll",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              "max-height":
                                                                "600px",
                                                              "overflow-y":
                                                                "auto",
                                                            },
                                                          },
                                                          _vm._l(
                                                            _vm.changeLogData,
                                                            function (
                                                              changeLog,
                                                              index
                                                            ) {
                                                              return _c(
                                                                "div",
                                                                {
                                                                  key: index,
                                                                  staticStyle: {
                                                                    padding:
                                                                      "10px",
                                                                  },
                                                                },
                                                                [
                                                                  _c("h6", [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm
                                                                          .propertyDisplayNameLookup[
                                                                          changeLog
                                                                            .property
                                                                        ].title
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    "dark-table",
                                                                    {
                                                                      attrs: {
                                                                        items:
                                                                          changeLog.changes,
                                                                        columns:
                                                                          [
                                                                            "Identifier",
                                                                            "Property",
                                                                            "Old Value",
                                                                            "Current Value",
                                                                            "Unique Identifier",
                                                                          ],
                                                                        fitContainerWidth: true,
                                                                      },
                                                                      scopedSlots:
                                                                        _vm._u(
                                                                          [
                                                                            {
                                                                              key: "body",
                                                                              fn: function () {
                                                                                return _vm._l(
                                                                                  changeLog.changes,
                                                                                  function (
                                                                                    item,
                                                                                    index
                                                                                  ) {
                                                                                    return _c(
                                                                                      "tr",
                                                                                      {
                                                                                        key: index,
                                                                                      },
                                                                                      [
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item.identityProperty
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          {
                                                                                            style:
                                                                                              {
                                                                                                color:
                                                                                                  _vm.changeLogPropertyTextColor(
                                                                                                    item.readableProperty
                                                                                                  ),
                                                                                              },
                                                                                          },
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item.readableProperty
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item.versionValue
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item.currentValue
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                        _vm._v(
                                                                                          " "
                                                                                        ),
                                                                                        _c(
                                                                                          "td",
                                                                                          [
                                                                                            _vm._v(
                                                                                              _vm._s(
                                                                                                item.uniqueKey
                                                                                              )
                                                                                            ),
                                                                                          ]
                                                                                        ),
                                                                                      ]
                                                                                    )
                                                                                  }
                                                                                )
                                                                              },
                                                                              proxy: true,
                                                                            },
                                                                          ],
                                                                          null,
                                                                          true
                                                                        ),
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          0
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "d-flex justify-content-end",
                                                      },
                                                      [
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-secondary grey-button m-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.changeLogData =
                                                                  []
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Return")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "button",
                                                          {
                                                            staticClass:
                                                              "btn btn-primary cobaltButton m-2",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.restoreVersion(
                                                                  _vm.versionConfigData
                                                                )
                                                                _vm.changeLogData =
                                                                  []
                                                              },
                                                            },
                                                          },
                                                          [_vm._v("Restore")]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                ),
                                              ]),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("hr", { staticClass: "w-100" }),
                          _vm._v(" "),
                          _vm.hasPermission
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup("templateName")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.selectedConfig.templateName,
                                          expression:
                                            "selectedConfig.templateName",
                                        },
                                      ],
                                      ref: "templateName",
                                      staticClass: "form-control",
                                      attrs: { type: "text" },
                                      domProps: {
                                        value: _vm.selectedConfig.templateName,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.selectedConfig,
                                            "templateName",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _vm.missingTemplateNameError
                                      ? _c(
                                          "span",
                                          { staticClass: "error-span" },
                                          [
                                            _vm._v(
                                              "A Template Name must be provided"
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "col-2 list-label text-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermission && !_vm.isWirelineChart
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center pb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup("header_style")
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.selectedConfig.header_style,
                                            expression:
                                              "selectedConfig.header_style",
                                          },
                                        ],
                                        ref: "headerStyle",
                                        staticClass: "form-control",
                                        attrs: {
                                          disabled:
                                            _vm.chartType == "wireline" ||
                                            _vm.selectedConfig.isDefault == 1,
                                        },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.selectedConfig,
                                                "header_style",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              _vm.configChanged = true
                                            },
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { value: "frac" },
                                            domProps: {
                                              selected:
                                                _vm.selectedConfig
                                                  .header_style === "frac",
                                            },
                                          },
                                          [_vm._v("Frac Status")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          {
                                            attrs: { value: "wireline" },
                                            domProps: {
                                              selected:
                                                _vm.selectedConfig
                                                  .header_style === "wireline",
                                            },
                                          },
                                          [_vm._v("Wireline Status")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          {
                                            attrs: { value: "default" },
                                            domProps: {
                                              selected:
                                                _vm.selectedConfig
                                                  .header_style === "default" ||
                                                _vm.selectedConfig
                                                  .header_style === null,
                                            },
                                          },
                                          [_vm._v("Default Status")]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "col-2 list-label text-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermission
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center pb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup(
                                            "controlsPosition"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.selectedConfig
                                                .controlsPosition,
                                            expression:
                                              "selectedConfig.controlsPosition",
                                          },
                                        ],
                                        ref: "chartControlsPosition",
                                        staticClass: "form-control",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.selectedConfig,
                                                "controlsPosition",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            function ($event) {
                                              _vm.configChanged = true
                                            },
                                          ],
                                        },
                                      },
                                      _vm._l(
                                        _vm.chartControlsPositions,
                                        function (position) {
                                          return _c(
                                            "option",
                                            {
                                              key: position,
                                              domProps: { value: position },
                                            },
                                            [_vm._v(_vm._s(position))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "col-2 list-label text-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermission
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center pb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup("controlsSize") +
                                            " (10-50%)"
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value:
                                            _vm.tempChartConfigData
                                              .controlsSize,
                                          expression:
                                            "tempChartConfigData.controlsSize",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      ref: "chartControlsSize",
                                      staticClass: "form-control",
                                      class: {
                                        "text-muted":
                                          _vm.selectedConfig.hideChart == 1,
                                      },
                                      attrs: {
                                        type: "number",
                                        step: "1",
                                        min: _vm.minControlsSize,
                                        max: _vm.maxControlsSize,
                                        disabled:
                                          _vm.selectedConfig.hideChart == 1,
                                      },
                                      domProps: {
                                        value:
                                          _vm.tempChartConfigData.controlsSize,
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.onControlsSizeChange(
                                            $event.target.value
                                          )
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.tempChartConfigData,
                                            "controlsSize",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "col-2 list-label text-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermission
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [_vm._v("Axis Type")]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-7 text-left" },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.xAxisType,
                                              expression: "xAxisType",
                                            },
                                          ],
                                          ref: "xAxisType",
                                          staticClass: "form-control",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.xAxisType = $event.target
                                                  .multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              },
                                              function ($event) {
                                                _vm.configChanged = true
                                              },
                                            ],
                                          },
                                        },
                                        [
                                          _c(
                                            "option",
                                            {
                                              domProps: {
                                                selected:
                                                  _vm.xAxisType == "Date"
                                                    ? "selected"
                                                    : "",
                                              },
                                            },
                                            [_vm._v("Date")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "option",
                                            {
                                              domProps: {
                                                selected:
                                                  _vm.xAxisType != "Date"
                                                    ? "selected"
                                                    : "",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.customAxisLabel())
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "col-2 text-left" },
                                    [
                                      _vm.xAxisType != "Date"
                                        ? _c(
                                            "button",
                                            {
                                              staticClass:
                                                "btn btn-secondary grey-button mr-auto",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.onChangeCustomXAxis()
                                                },
                                              },
                                            },
                                            [_vm._v("Customize Axis")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.customAxisErrorSeen
                                    ? _c(
                                        "div",
                                        { staticClass: "col-6 text-left" },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "error-span" },
                                            [
                                              _vm._v(
                                                "A custom axis must be created"
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermission
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup(
                                            "rightSideMsOffset"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("input", {
                                      ref: "rightSideHourOffset",
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        step: "any",
                                        min: "0",
                                        disabled: _vm.xAxisType !== "Date",
                                      },
                                      domProps: {
                                        value:
                                          !isNaN(
                                            _vm.selectedConfig.rightSideMsOffset
                                          ) &&
                                          _vm.selectedConfig
                                            .rightSideMsOffset >= 0
                                            ? _vm.selectedConfig
                                                .rightSideMsOffset /
                                              1000 /
                                              60 /
                                              60
                                            : 0.5 /* ms to hours  or default to half an hour */,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.configChanged = true
                                          _vm.selectedConfig.rightSideMsOffset =
                                            isNaN($event.target.value)
                                              ? 0
                                              : $event.target.value *
                                                1000 *
                                                60 *
                                                60
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "col-2 list-label text-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermission
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center mb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup(
                                            "resolutionZoomHours"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value:
                                            _vm.selectedConfig
                                              .resolutionZoomHours,
                                          expression:
                                            "selectedConfig.resolutionZoomHours",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      ref: "resolutionZoomHours",
                                      staticClass: "form-control",
                                      attrs: {
                                        type: "number",
                                        step: "any",
                                        min: "0",
                                        disabled: _vm.xAxisType !== "Date",
                                      },
                                      domProps: {
                                        value:
                                          _vm.selectedConfig
                                            .resolutionZoomHours,
                                      },
                                      on: {
                                        change: function ($event) {
                                          _vm.selectedConfig.resolutionZoomHours =
                                            _vm.validateNumberInputs(
                                              $event.target.value,
                                              0
                                            )
                                          _vm.configChanged = true
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.selectedConfig,
                                            "resolutionZoomHours",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "col-2 list-label text-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.hasPermission && !_vm.isWirelineChart
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "row justify-content-center align-items-center pb-2",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "col-3 list-label text-right",
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.inputLabelLookup(
                                            "defaultZoomAction"
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "col-7" }, [
                                    _c(
                                      "select",
                                      {
                                        ref: "defaultZoomAction",
                                        staticClass: "form-control",
                                        on: {
                                          change: function ($event) {
                                            _vm.configChanged = true
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          {
                                            attrs: { value: "dragToZoom" },
                                            domProps: {
                                              selected:
                                                _vm.selectedConfig
                                                  .defaultZoomAction ===
                                                "dragToZoom",
                                            },
                                          },
                                          [_vm._v("Drag to Zoom")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "option",
                                          {
                                            attrs: { value: "scrollToZoom" },
                                            domProps: {
                                              selected:
                                                _vm.selectedConfig
                                                  .defaultZoomAction ===
                                                "scrollToZoom",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "Drag to Pan, Scroll to Zoom"
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", {
                                    staticClass: "col-2 list-label text-right",
                                  }),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c("div", { staticClass: "mb-2" }, [
                            _vm.hasPermission
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row justify-content-center align-items-center",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-4 list-label text-right",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.inputLabelLookup(
                                                "pinRightSideMsOffset"
                                              )
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-2 text-left" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.selectedConfig
                                                  .pinRightSideMsOffset,
                                              expression:
                                                "selectedConfig.pinRightSideMsOffset",
                                            },
                                          ],
                                          ref: "pinRightSideMsOffset",
                                          staticClass: "check-box",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.selectedConfig
                                                .pinRightSideMsOffset
                                            )
                                              ? _vm._i(
                                                  _vm.selectedConfig
                                                    .pinRightSideMsOffset,
                                                  null
                                                ) > -1
                                              : _vm.selectedConfig
                                                  .pinRightSideMsOffset,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.selectedConfig
                                                      .pinRightSideMsOffset,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "pinRightSideMsOffset",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "pinRightSideMsOffset",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.selectedConfig,
                                                    "pinRightSideMsOffset",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                _vm.configChanged = true
                                              },
                                            ],
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-3 list-label text-right",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.inputLabelLookup("showLegend")
                                          )
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-3 text-left" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.selectedConfig.showLegend,
                                              expression:
                                                "selectedConfig.showLegend",
                                            },
                                          ],
                                          ref: "showLegend",
                                          staticClass: "check-box",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.selectedConfig.showLegend
                                            )
                                              ? _vm._i(
                                                  _vm.selectedConfig.showLegend,
                                                  null
                                                ) > -1
                                              : _vm.selectedConfig.showLegend,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.selectedConfig
                                                      .showLegend,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showLegend",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showLegend",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.selectedConfig,
                                                    "showLegend",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                _vm.configChanged = true
                                              },
                                            ],
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "col-4 list-label text-right",
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              _vm.inputLabelLookup("isVertical")
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      { staticClass: "col-2 text-left" },
                                      [
                                        _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.selectedConfig.isVertical,
                                              expression:
                                                "selectedConfig.isVertical",
                                            },
                                          ],
                                          ref: "isVertical",
                                          staticClass: "check-box",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.selectedConfig.isVertical
                                            )
                                              ? _vm._i(
                                                  _vm.selectedConfig.isVertical,
                                                  null
                                                ) > -1
                                              : _vm.selectedConfig.isVertical,
                                          },
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.selectedConfig
                                                      .isVertical,
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "isVertical",
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "isVertical",
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.selectedConfig,
                                                    "isVertical",
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                _vm.configChanged = true
                                              },
                                            ],
                                          },
                                        }),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      !_vm.isLegacyChart && !_vm.isWirelineChart
                                        ? _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-6 list-label text-right",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.inputLabelLookup(
                                                        "hideChart"
                                                      )
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-6 text-left",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.selectedConfig
                                                          .hideChart,
                                                      expression:
                                                        "selectedConfig.hideChart",
                                                    },
                                                  ],
                                                  ref: "hideChart",
                                                  staticClass: "check-box",
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.selectedConfig
                                                        .hideChart
                                                    )
                                                      ? _vm._i(
                                                          _vm.selectedConfig
                                                            .hideChart,
                                                          null
                                                        ) > -1
                                                      : _vm.selectedConfig
                                                          .hideChart,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$a =
                                                            _vm.selectedConfig
                                                              .hideChart,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.selectedConfig,
                                                                "hideChart",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.selectedConfig,
                                                                "hideChart",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "hideChart",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                      function ($event) {
                                                        _vm.configChanged = true
                                                        _vm.selectedConfig.hideChart =
                                                          $event.target.checked
                                                      },
                                                    ],
                                                  },
                                                }),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "col-6" }, [
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-8 list-label text-right",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.inputLabelLookup(
                                                    "isDateAxisHidden"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-4 text-left" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.selectedConfig
                                                      .isDateAxisHidden,
                                                  expression:
                                                    "selectedConfig.isDateAxisHidden",
                                                },
                                              ],
                                              ref: "isDateAxisHidden",
                                              staticClass: "check-box",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.selectedConfig
                                                    .isDateAxisHidden
                                                )
                                                  ? _vm._i(
                                                      _vm.selectedConfig
                                                        .isDateAxisHidden,
                                                      null
                                                    ) > -1
                                                  : _vm.selectedConfig
                                                      .isDateAxisHidden,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.selectedConfig
                                                          .isDateAxisHidden,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "isDateAxisHidden",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "isDateAxisHidden",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "isDateAxisHidden",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    _vm.configChanged = true
                                                    _vm.selectedConfig.isDateAxisHidden =
                                                      $event.target.checked
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-8 list-label text-right",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.xAxisType == "Date"
                                                    ? "Date Axis Gridlines"
                                                    : _vm.xAxisType +
                                                        " Axis Gridlines"
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-4 text-left" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.selectedConfig
                                                      .showVerticalGridlines,
                                                  expression:
                                                    "selectedConfig.showVerticalGridlines",
                                                },
                                              ],
                                              ref: "showVerticalGridlines",
                                              staticClass: "check-box",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.selectedConfig
                                                    .showVerticalGridlines
                                                )
                                                  ? _vm._i(
                                                      _vm.selectedConfig
                                                        .showVerticalGridlines,
                                                      null
                                                    ) > -1
                                                  : _vm.selectedConfig
                                                      .showVerticalGridlines,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.selectedConfig
                                                          .showVerticalGridlines,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "showVerticalGridlines",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "showVerticalGridlines",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showVerticalGridlines",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    _vm.configChanged = true
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.selectedConfig.showVerticalGridlines
                                        ? _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-8 list-label text-right",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                        " +
                                                    _vm._s(
                                                      _vm.xAxisType == "Date"
                                                        ? "Date Axis Gridline Color"
                                                        : _vm.xAxisType +
                                                            " Axis Gridline Color"
                                                    ) +
                                                    "\n                                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _vm.selectedConfig
                                              .showVerticalGridlines
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "list-label text-right",
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "col-4 text-left",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "swatch p-0",
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                _vm.showGridLineColorPicker =
                                                                  !_vm.showGridLineColorPicker
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c("div", {
                                                              staticClass:
                                                                "color",
                                                              style: {
                                                                "background-color":
                                                                  _vm
                                                                    .selectedConfig
                                                                    .verticalGridlinesColor,
                                                              },
                                                            }),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _vm.showGridLineColorPicker
                                                          ? _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "popover",
                                                              },
                                                              [
                                                                _c("div", {
                                                                  staticClass:
                                                                    "cover",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.showGridLineColorPicker = false
                                                                      },
                                                                  },
                                                                }),
                                                                _vm._v(" "),
                                                                _c(
                                                                  "sketch-picker",
                                                                  {
                                                                    ref: "verticalGridlinesColor",
                                                                    attrs: {
                                                                      value: _vm
                                                                        .selectedConfig
                                                                        .verticalGridlinesColor
                                                                        ? _vm
                                                                            .selectedConfig
                                                                            .verticalGridlinesColor
                                                                        : "#FFFFFFFF",
                                                                      id: "verticalGridlinesColor",
                                                                      disableAlpha: false,
                                                                      "preset-colors":
                                                                        _vm.presetColors,
                                                                    },
                                                                    on: {
                                                                      input: (
                                                                        color
                                                                      ) => {
                                                                        _vm.selectedConfig.verticalGridlinesColor =
                                                                          color.hex8
                                                                        _vm.configChanged = true
                                                                      },
                                                                    },
                                                                  }
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e(),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("div", { staticClass: "row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-8 list-label text-right",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.inputLabelLookup(
                                                    "showNPTLines"
                                                  )
                                                ) +
                                                "\n                                    "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "col-4 text-left" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.selectedConfig
                                                      .showNPTLines,
                                                  expression:
                                                    "selectedConfig.showNPTLines",
                                                },
                                              ],
                                              ref: "showNPTLines",
                                              staticClass: "check-box",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.selectedConfig
                                                    .showNPTLines
                                                )
                                                  ? _vm._i(
                                                      _vm.selectedConfig
                                                        .showNPTLines,
                                                      null
                                                    ) > -1
                                                  : _vm.selectedConfig
                                                      .showNPTLines,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.selectedConfig
                                                          .showNPTLines,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "showNPTLines",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "showNPTLines",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showNPTLines",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    _vm.configChanged = true
                                                    _vm.selectedConfig.showNPTLines =
                                                      $event.target.checked
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass: "col-6",
                                        staticStyle: { "align-self": "start" },
                                      },
                                      [
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-6 list-label text-right",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.inputLabelLookup(
                                                      "showStageLines"
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-6 text-left" },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.selectedConfig
                                                        .showStageLines,
                                                    expression:
                                                      "selectedConfig.showStageLines",
                                                  },
                                                ],
                                                ref: "showStageLines",
                                                staticClass: "check-box",
                                                attrs: { type: "checkbox" },
                                                domProps: {
                                                  checked: Array.isArray(
                                                    _vm.selectedConfig
                                                      .showStageLines
                                                  )
                                                    ? _vm._i(
                                                        _vm.selectedConfig
                                                          .showStageLines,
                                                        null
                                                      ) > -1
                                                    : _vm.selectedConfig
                                                        .showStageLines,
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$a =
                                                          _vm.selectedConfig
                                                            .showStageLines,
                                                        $$el = $event.target,
                                                        $$c = $$el.checked
                                                          ? true
                                                          : false
                                                      if (Array.isArray($$a)) {
                                                        var $$v = null,
                                                          $$i = _vm._i($$a, $$v)
                                                        if ($$el.checked) {
                                                          $$i < 0 &&
                                                            _vm.$set(
                                                              _vm.selectedConfig,
                                                              "showStageLines",
                                                              $$a.concat([$$v])
                                                            )
                                                        } else {
                                                          $$i > -1 &&
                                                            _vm.$set(
                                                              _vm.selectedConfig,
                                                              "showStageLines",
                                                              $$a
                                                                .slice(0, $$i)
                                                                .concat(
                                                                  $$a.slice(
                                                                    $$i + 1
                                                                  )
                                                                )
                                                            )
                                                        }
                                                      } else {
                                                        _vm.$set(
                                                          _vm.selectedConfig,
                                                          "showStageLines",
                                                          $$c
                                                        )
                                                      }
                                                    },
                                                    function ($event) {
                                                      _vm.configChanged = true
                                                      _vm.selectedConfig.showStageLines =
                                                        $event.target.checked
                                                    },
                                                  ],
                                                },
                                              }),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "row" }, [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "col-6 list-label text-right",
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        " +
                                                  _vm._s(
                                                    _vm.inputLabelLookup(
                                                      "stageLabelType"
                                                    )
                                                  ) +
                                                  "\n                                    "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "col-6 text-left" },
                                            [
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.selectedConfig
                                                          .stageLabelType,
                                                      expression:
                                                        "selectedConfig.stageLabelType",
                                                    },
                                                  ],
                                                  ref: "stageLabelType",
                                                  staticClass: "form-control",
                                                  staticStyle: {
                                                    width: "60%",
                                                    height: "90%",
                                                    "font-size": "0.9rem",
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$selectedVal =
                                                          Array.prototype.filter
                                                            .call(
                                                              $event.target
                                                                .options,
                                                              function (o) {
                                                                return o.selected
                                                              }
                                                            )
                                                            .map(function (o) {
                                                              var val =
                                                                "_value" in o
                                                                  ? o._value
                                                                  : o.value
                                                              return val
                                                            })
                                                        _vm.$set(
                                                          _vm.selectedConfig,
                                                          "stageLabelType",
                                                          $event.target.multiple
                                                            ? $$selectedVal
                                                            : $$selectedVal[0]
                                                        )
                                                      },
                                                      function ($event) {
                                                        _vm.configChanged = true
                                                      },
                                                    ],
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: { value: "label" },
                                                    },
                                                    [_vm._v("Label")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "option",
                                                    {
                                                      attrs: { value: "hover" },
                                                    },
                                                    [_vm._v("Hover")]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", {}, [
                                          _c("div", { staticClass: "row" }, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col-6 list-label text-right",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                                " +
                                                    _vm._s(
                                                      _vm.inputLabelLookup(
                                                        "hideCommentsTimeline"
                                                      )
                                                    ) +
                                                    "\n                                            "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "col-6 text-left",
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm.selectedConfig
                                                          .hideCommentsTimeline,
                                                      expression:
                                                        "selectedConfig.hideCommentsTimeline",
                                                    },
                                                  ],
                                                  ref: "hideCommentsTimeline",
                                                  staticClass: "check-box",
                                                  attrs: { type: "checkbox" },
                                                  domProps: {
                                                    checked: Array.isArray(
                                                      _vm.selectedConfig
                                                        .hideCommentsTimeline
                                                    )
                                                      ? _vm._i(
                                                          _vm.selectedConfig
                                                            .hideCommentsTimeline,
                                                          null
                                                        ) > -1
                                                      : _vm.selectedConfig
                                                          .hideCommentsTimeline,
                                                  },
                                                  on: {
                                                    change: [
                                                      function ($event) {
                                                        var $$a =
                                                            _vm.selectedConfig
                                                              .hideCommentsTimeline,
                                                          $$el = $event.target,
                                                          $$c = $$el.checked
                                                            ? true
                                                            : false
                                                        if (
                                                          Array.isArray($$a)
                                                        ) {
                                                          var $$v = null,
                                                            $$i = _vm._i(
                                                              $$a,
                                                              $$v
                                                            )
                                                          if ($$el.checked) {
                                                            $$i < 0 &&
                                                              _vm.$set(
                                                                _vm.selectedConfig,
                                                                "hideCommentsTimeline",
                                                                $$a.concat([
                                                                  $$v,
                                                                ])
                                                              )
                                                          } else {
                                                            $$i > -1 &&
                                                              _vm.$set(
                                                                _vm.selectedConfig,
                                                                "hideCommentsTimeline",
                                                                $$a
                                                                  .slice(0, $$i)
                                                                  .concat(
                                                                    $$a.slice(
                                                                      $$i + 1
                                                                    )
                                                                  )
                                                              )
                                                          }
                                                        } else {
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "hideCommentsTimeline",
                                                            $$c
                                                          )
                                                        }
                                                      },
                                                      function ($event) {
                                                        _vm.selectedConfig.hideCommentsTimeline =
                                                          $event.target.checked
                                                      },
                                                    ],
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.hasPermission &&
                            (_vm.isAdmin || _vm.isCompanyAdmin) &&
                            _vm.selectedConfig.customer_id
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "row justify-content-center align-items-center",
                                  },
                                  [
                                    _vm.showCustomerDefaultToggle
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-4 list-label text-right",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.customerDefaultToggleText
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.showCustomerDefaultToggle
                                      ? _c(
                                          "div",
                                          { staticClass: "col-2 text-left" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.selectedConfig
                                                      .isDefault,
                                                  expression:
                                                    "selectedConfig.isDefault",
                                                },
                                              ],
                                              ref: "customerDefault",
                                              staticClass: "check-box",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.selectedConfig.isDefault
                                                )
                                                  ? _vm._i(
                                                      _vm.selectedConfig
                                                        .isDefault,
                                                      null
                                                    ) > -1
                                                  : _vm.selectedConfig
                                                      .isDefault,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.selectedConfig
                                                          .isDefault,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "isDefault",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "isDefault",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "isDefault",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    return _vm.onCustomerDefaultToggled(
                                                      $event
                                                    )
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        )
                                      : _c("div", { staticClass: "col-6" }),
                                    _vm._v(" "),
                                    _vm.selectedConfig.owned_by === this.userId
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-3 list-label text-right",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.inputLabelLookup("shared")
                                              )
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.selectedConfig.owned_by === this.userId
                                      ? _c(
                                          "div",
                                          { staticClass: "col-3 text-left" },
                                          [
                                            _c("input", {
                                              directives: [
                                                {
                                                  name: "model",
                                                  rawName: "v-model",
                                                  value:
                                                    _vm.selectedConfig.shared,
                                                  expression:
                                                    "selectedConfig.shared",
                                                },
                                              ],
                                              ref: "shareWithCompany",
                                              staticClass: "check-box",
                                              attrs: { type: "checkbox" },
                                              domProps: {
                                                checked: Array.isArray(
                                                  _vm.selectedConfig.shared
                                                )
                                                  ? _vm._i(
                                                      _vm.selectedConfig.shared,
                                                      null
                                                    ) > -1
                                                  : _vm.selectedConfig.shared,
                                              },
                                              on: {
                                                change: [
                                                  function ($event) {
                                                    var $$a =
                                                        _vm.selectedConfig
                                                          .shared,
                                                      $$el = $event.target,
                                                      $$c = $$el.checked
                                                        ? true
                                                        : false
                                                    if (Array.isArray($$a)) {
                                                      var $$v = null,
                                                        $$i = _vm._i($$a, $$v)
                                                      if ($$el.checked) {
                                                        $$i < 0 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "shared",
                                                            $$a.concat([$$v])
                                                          )
                                                      } else {
                                                        $$i > -1 &&
                                                          _vm.$set(
                                                            _vm.selectedConfig,
                                                            "shared",
                                                            $$a
                                                              .slice(0, $$i)
                                                              .concat(
                                                                $$a.slice(
                                                                  $$i + 1
                                                                )
                                                              )
                                                          )
                                                      }
                                                    } else {
                                                      _vm.$set(
                                                        _vm.selectedConfig,
                                                        "shared",
                                                        $$c
                                                      )
                                                    }
                                                  },
                                                  function ($event) {
                                                    _vm.configChanged = true
                                                    _vm.selectedConfig.shared =
                                                      $event.target.checked
                                                  },
                                                ],
                                              },
                                            }),
                                          ]
                                        )
                                      : _c("div", { staticClass: "col-6" }),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c("hr", { staticClass: "w-100" }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "row mt-2 mb-1 px-3 align-items-center",
                            },
                            [
                              _vm._v(
                                "\n                        Sections\n                        "
                              ),
                              _vm.hasPermission
                                ? _c("i", {
                                    staticClass:
                                      "fas fa-plus-circle ml-2 show-clicker-finger",
                                    on: {
                                      click: function ($event) {
                                        return _vm.addSection()
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.tempChartConfigData.orderedSections.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass: "mx-3 align-items-center",
                                  staticStyle: {
                                    "overflow-y": "auto",
                                    "overflow-x": "hidden",
                                    "max-height": "300px",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    _vm._l(
                                      _vm.tempChartConfigData.orderedSections,
                                      function (section, index) {
                                        return _c(
                                          "div",
                                          { key: index, staticClass: "row" },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "col ml-4 text-left font-weight-light",
                                                staticStyle: {
                                                  display: "inline",
                                                },
                                              },
                                              [_vm._v(_vm._s(section.name))]
                                            ),
                                            _vm._v(" "),
                                            _vm.hasPermission
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "col-2",
                                                    staticStyle: {
                                                      display: "inline",
                                                      float: "right",
                                                      "inline-size":
                                                        "fit-content",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-pen show-clicker-finger mr-2",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.editSection(
                                                            section,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-trash-alt show-clicker-finger mr-2",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.deleteSection(
                                                            section,
                                                            index
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticClass: "col-2",
                                                    staticStyle: {
                                                      display: "inline",
                                                      float: "right",
                                                      "inline-size":
                                                        "fit-content",
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-pen show-clicker-finger mr-2",
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.copyConfig(
                                                            true
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                ),
                                            _vm._v(" "),
                                            _c("hr", {
                                              staticClass: "w-100 mx-4 my-1",
                                            }),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              )
                            : _c(
                                "div",
                                { staticClass: "mx-3 align-items-center" },
                                [_vm._v("No sections added")]
                              ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "modal-footer d-flex flex-row",
                            staticStyle: { "border-top-width": "0px" },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary grey-button mr-auto",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onResetSelectionsPressed()
                                  },
                                },
                              },
                              [_vm._v("Clear User Settings")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary grey-button",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.backPressed("config")
                                  },
                                },
                              },
                              [_vm._v("Close")]
                            ),
                            _vm._v(" "),
                            _vm.hasPermission()
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success green-button",
                                    attrs: {
                                      type: "button",
                                      disabled:
                                        !_vm.configInputChanged() ||
                                        !_vm.hasPermission() ||
                                        _vm.errorSeen(),
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveConfig()
                                      },
                                    },
                                  },
                                  [_vm._v("Save Template")]
                                )
                              : _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success green-button",
                                    attrs: { type: "button", disabled: "" },
                                  },
                                  [_vm._v("Save Template")]
                                ),
                            _vm._v(" "),
                            _c(
                              "b-button",
                              {
                                staticClass: "btn btn-success green-button",
                                attrs: {
                                  type: "button",
                                  id: "default-template-warning" + _vm._uid,
                                  disabled: _vm.isUseTemplateDisabled,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.onUseTemplateSelected()
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        Use Template\n                      "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm.showDefaultTemplateWarning
                              ? _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      target:
                                        "default-template-warning" + _vm._uid,
                                      placement: "bottom",
                                      show: _vm.showDefaultTemplateWarning,
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticStyle: { color: "white" } },
                                      [
                                        _vm._v(
                                          " Global templates cannot be selected"
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("div", [
                  _c("div", { staticClass: "modal-body py-0" }, [
                    _c("div", { staticClass: "mt-3" }, [
                      _vm._v(_vm._s(_vm.isLoading)),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "spinner-border mt-3 mb-3" }),
                  ]),
                ]),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }