var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      key: _vm.id,
      class: _vm.computedClass,
      attrs: { id: _vm.id },
      on: {
        click: _vm.onClick,
        pointerdown: _vm.onPress,
        pointerup: _vm.onRelease,
        pointerleave: _vm.onPointerLeave,
      },
    },
    [
      _vm._t("text", function () {
        return [
          !!_vm.prependIcon ? [_c("i", { class: _vm.prependIcon })] : _vm._e(),
          _vm._v(" "),
          !!_vm.text
            ? [
                _vm._v(
                  "\n            " +
                    _vm._s(
                      typeof _vm.text == "function" ? _vm.text() : _vm.text
                    ) +
                    "\n        "
                ),
              ]
            : _vm._e(),
          _vm._v(" "),
          !!_vm.icon || !!_vm.appendIcon
            ? [_c("i", { class: _vm.icon || _vm.appendIcon })]
            : _vm._e(),
        ]
      }),
      _vm._v(" "),
      _vm.allowLoadingState && _vm.loading
        ? _c(
            "div",
            {
              class: `spinner-border ${_vm.type}-text-color`,
              attrs: { role: "status" },
            },
            [
              _vm._t("loading", function () {
                return [
                  _c("span", { staticClass: "sr-only" }, [
                    _vm._v("Loading..."),
                  ]),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }