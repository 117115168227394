<template>
    <div class="dark-modal modal" id="ToolstringStageModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Configure Stage

                        <div>
                            This well has {{ max }} stages
                        </div>
                    </h5>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
        
                <div v-if="from !== undefined && to !== undefined" class="modal-body">
                    <div class="row justify-content-between">
                        <div class="col-6">
                            <iws-input ref="stageFromInput"
                                label="From *"
                                :value.sync="from"
                                required="true"
                                type="number"
                                :error-message="fromErrorMessage"

                                min="1"
                                :max="to || max"

                                @input="validateForm"
                                @blur="validateForm"
                            />
                        </div>

                        <div class="col-6">
                            <iws-input ref="stageToInput"
                                label="To *"
                                :value.sync="to"
                                required="true"
                                type="number"
                                :error-message="toErrorMessage"

                                :min="from || 1"
                                :max="max"

                                @input="validateForm"
                                @blur="validateForm"
                            />
                        </div>
                    </div>

                    <iws-alert v-if="!!conflictsWith && !!conflictsWith.length" type="warning" style="margin-top: 20px;">
                        <template #body>
                            <div class="title">
                                This action affects {{ conflictsWith.length }} toolstring{{ conflictsWith.length > 1 ? 's' : null }}
                            </div>
                            <div class="sub_title">
                                These settings will affect {{ conflictsWith.map(_toolstring => `'${_toolstring.name || _toolstring.id}'`).join(', ') }}.
                                Are you sure you want to apply?
                            </div>
                        </template>
                    </iws-alert>
                </div>
    
                <div class="modal-footer">
                    <iws-button id="cancel_button" type="outline-light" text="Cancel" />
    
                    <iws-button text="Apply" :click="saveAction" />
                </div>
            </div>
        </div>
    </div>
</template>
        
        
<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, toast } = GlobalFunctions;

export default {
    data: () => ({
        toolstring: null,
        from: undefined,
        to: undefined,
        max: null,
        toolstrings: [],

        resolve: null
    }),

    computed: {
        conflictsWith() {
            return this.toolstrings.filter(_toolstring => {
                // cannot conflict with itself
                if (this.toolstring.id == _toolstring.id)
                    return false;
                return (this.from >= _toolstring.stageNumber && this.from <= _toolstring.stageNumberClosing)
                    || (this.to >= _toolstring.stageNumber && this.to <= _toolstring.stageNumberClosing);
            })
        },

        fromErrorMessage() {
            if (isFalsy(this.from))
                return 'Please select stage';
            // When set, 'from' must be a positive number <= this.to (default to capping at max when no to is set)
            // 'From' CAN EQUAL to
            if (this.from < 1)
                return 'Must be greater than 0';
            if (this.from > (this.to || this.max))
                return `Must be less than ${(this.to || this.max)+1}`;
        },

        toErrorMessage() {
            if (isFalsy(this.to))
                return 'Please select stage';
            // When set, 'to' must be a positive number >= this.from
            // 'to' CAN EQUAL From
            if (this.to < (this.from || 1))
                return `Must be greater than ${this.from || 0}`;
            if (this.to > this.max)
                return `Must be less than ${this.max+1}`;
        }
    },

    methods: {
        validateForm(forceValidate=false) {
            this.$nextTick(_ => {
                for (const _ref in this.$refs)
                    (forceValidate || this.$refs[_ref].inErrorState) && this.$refs[_ref]?.validateInput();
            });
        },

        saveAction() {
            this.validateForm(true)

            if (isFalsy(this.from) || isFalsy(this.to)) {
                toast({
                    title: 'Both Stage Numbers are required',
                    variant: 'danger'
                });
            } else if (this.from > this.to) {
                toast({
                    title: 'Please correct range',
                    variant: 'danger'
                });
            } else if (this.from < 1) {
                toast({
                    title: 'Your range must consist of positive numbers',
                    variant: 'danger'
                });
            } else if (this.to > this.max) {
                toast({
                    title: 'Your end stage number must be less than '+(this.max+1),
                    variant: 'danger'
                });
            } else {
                this.resolve({
                    from: this.from,
                    to: this.to
                });
            }
        },

        open(toolstring, from, to, max, toolstrings) {
            var modalElem = $('#ToolstringStageModal');
            this.toolstring = toolstring;
            this.from = from;
            this.to = to;
            this.max = max;
            this.toolstrings = toolstrings;

            return new Promise((resolve, reject) => {
                modalElem.show();
                this.resolve = (val) => {
                    this.close();
                    resolve(val);
                }
            });
        },
        close() {
            $('#ToolstringStageModal').hide();

            this.from = undefined;
            this.to = undefined;
            this.max = null;
            this.toolstrings = null;
        }
    },

    mounted() {
        $('#ToolstringStageModal').find('#cancel_button, .close').on('click', () => {
            this.resolve(null);
            this.close();
        });
    }
};
</script>

<style scoped>
    .modal-dialog {
        max-width: 400px;
    }
    .modal-title>div {
        font-size: 14px !important;
        font-weight: 400 !important;
    }
</style>