var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c(
      "button",
      {
        staticClass: "btn btn-outline-dark btn-sm primary-border-color",
        attrs: { type: "button", disabled: _vm.isDisabledRemove },
        on: {
          click: function ($event) {
            $event.preventDefault()
            return (() => _vm.onAddRemovePressed(false)).apply(null, arguments)
          },
        },
      },
      [_c("b", { staticClass: "sub-txt" }, [_vm._v("−")])]
    ),
    _vm._v(" "),
    _c("div", { staticClass: "px-1" }),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "btn btn-outline-dark btn-sm primary-border-color",
        attrs: { type: "button" },
        on: { click: () => _vm.onAddRemovePressed(true) },
      },
      [_c("b", { staticClass: "sub-txt" }, [_vm._v("+")])]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }