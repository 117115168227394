var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: { "form-input-spacing": _vm.formSpacing !== false },
      attrs: { id: "swatch-container" },
    },
    [
      !_vm._isFalsy(_vm.label)
        ? _c("label", { staticClass: "primary-text-color" }, [
            _vm._v("\n        " + _vm._s(_vm.label) + "\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "swatch",
          staticClass: "swatch",
          class: { "is-invalid": _vm.inErrorState, disabled: _vm.disabled },
          on: {
            click: function ($event) {
              return _vm.setStatus(true)
            },
          },
        },
        [
          _c("div", {
            staticClass: "color",
            style: { "background-color": _vm.value },
          }),
        ]
      ),
      _vm._v(" "),
      !!_vm.colorPickerShown
        ? [
            _c("div", {
              staticClass: "cover",
              on: {
                click: function ($event) {
                  return _vm.setStatus(false)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "colourPicker",
                style: {
                  position: "fixed",
                  "z-index": 999,
                  top: `${_vm.dimensions.top}px`,
                  left: `${_vm.dimensions.left}px`,
                  width: `${_vm.dimensions.width}px`,
                },
              },
              [
                _c("sketch-picker", {
                  attrs: {
                    value: _vm.value || _vm.defaultValue,
                    disableAlpha: true,
                    "preset-colors": _vm.presetColours,
                  },
                  on: { input: _vm.setColour },
                }),
              ],
              1
            ),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.inErrorState
        ? _c("label", { staticClass: "danger-text-color" }, [
            _vm._v(
              "\n        " + _vm._s(_vm.errorMessage || "Required") + "\n    "
            ),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }