<template>
    
        <div class="alarmsWrapper">
            <div class="d-flex flex-column justify-content-between">
                <div class="alarmBanner" v-for="alarm in alarms">
                    <h2 class="alarmText">
                        <span class="badge badge-secondary">{{alarm.source}}</span>
                        <span class="badge badge-warning mr-2" v-for="msg in alarm.messages">{{msg}}</span>
                    </h2>
                </div>
            </div>
        </div>

</template>

<style>
    #alarmBackground { 
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 6;
    }
    .alarmBanner { 
        background-color: red;
        border-style: outset;
        border-color: white;
    }
    .alarmsWrapper {
        position: absolute;
        left: 50%; 
        top: 50%;
        width: 100%;
        height: auto; 
        transform: translate(-50%, -50%); 
        z-index:10;
    }
    .alarmText {
        color: white;
        text-align: center;
        line-height: 100px;
    }
</style>

<script>
    export default {
        data() {
            return {
                alarms: []
            }
        },
        mounted() {
          var self = this;
          this.$root.$on('mqttConnected',function(){
            //console.log("messagesToolbar component got a connect message!");
            self.mqttInit();
          });
            
        },
        methods:{
          mqttInit(){
            this.$root.$mqtt.subscribe('alarm/#')

            this.$root.$mqtt.on("message", function(topic, message) {
                var topicArray = topic.split("/");
                var self = this;
                if(topicArray.length > 0) {
                    switch (topicArray[0]) {
                        case "alarm":
                            var foundIndex = -1;
                            $.each(self.alarms,function(index,value){
                                if(self.alarms[index].topic == topic)
                                    foundIndex = index;
                            });
                            if(foundIndex == -1){
                                //not found
                                if(message != "") {
                                    try{
                                        var alarm = {};
                                        alarm.topic = topic;
                                        alarm.messages = JSON.parse(message);
                                        alarm.source = topic.replace("alarm/","Alarm from: ");
                                        self.alarms.push(alarm);
                                    } catch(e){
                                        console.log("unable to add alarms for "+topic);
                                    }
                                    
                                }
                            }else{
                                //found
                                if(message == "") {
                                    self.alarms.splice(foundIndex,1);
                                }else{
                                    try{
                                        self.alarms[foundIndex].messages = JSON.parse(message);
                                    } catch(e){
                                        console.log("unable to add alarms for "+topic);
                                    }
                                }
                            }
                            break;
                    }
                }
            }.bind(this));
          }
        }
    }
</script>