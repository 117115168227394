<script>
import moment from 'moment';
import GlobalFunctions from '../../GlobalFunctions';

export default {
    props: ['event', 'offset', 'wells', 'processName'],
    methods:{
        toLocalString(timestamp){
            let isoTimestamp = timestamp?.timezone ? timestamp.date+timestamp.timezone : timestamp.date
            let time = new Date(isoTimestamp);
            return moment(time).utcOffset(this.offset).format('HH:mm:ssA');
        },
        isUserList(){
            return this.event.data.users ? this.event.data.users.length > 0 : false
        },
        getLastUser(){
            return this.event.data.users[this.event.data.users.length -1]
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        convertPastTense(string) {
            // simple conversion for 3+ characters long word to its Past Tense form, exclude all Irregular verbs
            if (string.length > 2) {
                const lastChar = string.charAt(string.length - 1)
                const secondLastCharIsVowel = (/[aeiou]/gi).test(string.charAt(string.length - 2))
                const thirdLastCharIsVowel = (/[aeiou]/gi).test(string.charAt(string.length - 3))
                switch (lastChar) {
                    case 'e':
                        return string + 'd'
                    case 'y':
                        if (secondLastCharIsVowel)
                            return string + 'ed'
                        else
                            return string.substring(0, string.length - 1) + 'ied'
                    default:
                        if (secondLastCharIsVowel && !thirdLastCharIsVowel)
                            return string + lastChar + 'ed'
                        else
                            return string + 'ed'
                }
            } else
                return string
        },
        nameColor(color){
            return GlobalFunctions.getTitleColorForBG(color);
        },
        getProcessEventMessage(event) {
            const subCategory = event.subCategory === 'skipStep' ? 'step skipped' : this.capitalizeFirstLetter(event.subCategory)
            const issuedBy = event.data.issuedBy ? ` by ${event.data.issuedBy}` : ''
            let description = event.data.description ? event.data.description : `${this.processName} on ${this.wellName}`
            if (description.length > 100)
                description = description.substring(0, 99) + '...'
            let processEventMessage = `Process ${subCategory} ${issuedBy} : ${description}`
            if (event.data?.isMessageStep)
                processEventMessage = `Message acknowledged ${issuedBy}: '${description}'`
            return processEventMessage
        }
    },
    computed:{
        wellName(){
            return this.wells.find(well => well.index === this.event.wellNumber)?.name
        },
        wellColor(){
            return  this.wells.find(well => well.index === this.event.wellNumber)?.color
        }
    }
}
</script>

<template>
    <b-card no-body class="processCard pl-2 pr-2 rounded">
        <b-row >
            <b-col><span>{{toLocalString(event.timestamp)}}</span></b-col>
            <b-col cols="8">
                <b-card-text>
            <span  v-if="event.category==='handshake'">
                <b>DIGITAL HANDSHAKE®:</b>
                <span v-if="event.subCategory==='request'">{{event.data.requestReason}} on {{wellName}}</span>
                <span v-if="isUserList()">{{capitalizeFirstLetter(event.subCategory)}} by {{ getLastUser().user }} ({{ getLastUser().role}})</span>
                <span v-if="event.subCategory==='request' && event.data.userSelection!=='None'">, {{event.data.userSelection}} (#{{event.stageNumber}})</span>
            </span>
                    <span v-if="event.category==='valveCommand'">
                <span class="nameplate" :style="{background:wellColor, color:nameColor(wellColor)}">{{ wellName }}</span>
                <span>{{event.data.valveName}} {{convertPastTense(capitalizeFirstLetter(event.subCategory))}}</span>
                <span v-if="event.data.position"> into {{event.data.finalPosition ? 'final' : ''}} position {{event.data.position}}</span>
                <span v-if="event.data.issuedBy"> issued by {{event.data.issuedBy}}</span>
            </span>
                    <span v-if="event.category==='wellActivity'">
                <span class="nameplate" :style="{background:wellColor, color:nameColor(wellColor)}">{{ wellName }}</span> Status changed to: {{event.data.statusString}}, Stage {{event.stageNumber}}
            </span>
                    <span v-if="event.category==='interlock'">
                <span class="text-danger" style="font-weight:bold;">INTERLOCK {{(event.subCategory).toUpperCase()}} ON {{wellName.toUpperCase()}}</span> {{event.data.interlock_msg}}
            </span>
                    <span v-if="event.category==='processEvent'"> {{ getProcessEventMessage(event) }} </span>
                    <span  v-if="event.category==='inspectValves'">
                <b>MISMATCH VALVE
                    <span v-if="['resolved', 'ignored', 'forceQuit'].includes(event.subCategory)"> {{ event.subCategory === 'forceQuit' ? 'force quit' : capitalizeFirstLetter(event.subCategory) }}</span>
                    <span v-if="['ignored'].includes(event.subCategory.toLowerCase())"> {{event.data.issuedBy ? ' by ' + event.data.issuedBy : '' }} </span>:
                </b>
                <span> {{event.data.valveName}} </span> <span v-if="event.subCategory==='mismatched'"> <b>HPS</b> {{event.data.hpsPosition}} <b>VPS</b> {{event.data.vpsPosition}}</span>
            </span>
                    <span v-if="event.category==='emergencyBypass'">
                <span v-if="event.subCategory==='request'">Requested by <b>{{event.data.requestByWsmPin === true ? 'WSM PIN' : ''}}</b> {{event.data.requesterUser}} {{ event.data.requesterRole }}</span>
                <span v-if="event.subCategory==='bypassActuate'">
                    <span class="nameplate" :style="{background:wellColor, color:nameColor(wellColor)}">{{ wellName }}</span>
                    <span>{{event.data.valveName}} Actuated</span>
                    <span v-if="event.data.position"> into position {{event.data.position}}</span>
                    <span v-if="event.data.issuedBy"> issued by {{event.data.issuedBy}}</span>
                </span>
                <span v-if="event.subCategory==='completed'">
                    <span v-if="event.data.cancelledBy">Cancelled by {{event.data.cancelledBy}} {{event.data.cancelledByRole}}</span>
                    <span v-else>Completed as Timeout</span>
                </span>
            </span>
                </b-card-text>
                <div class="col-4 mt-0 pr-0 pl-0 pt-0 d-flex justify-content-center">
                </div>
            </b-col>
            <b-col>
                <div
                    v-if="['processEvent', 'emergencyBypass'].includes(event.category) &&
                ['completed', 'started', 'continued'].includes(event.subCategory)"
                    class='img-box'>
                    <i class="fa fa-check fa-2x ml-1"></i>
                </div>
                <div v-else-if="event.category==='processEvent' && event.subCategory !== 'skipStep'" class='img-box'>
                    <i class="fa fa-times fa-2x ml-1"></i>
                </div>
                <div v-else-if="event.category==='handshake'&&(event.subCategory==='completed')" class='img-box'>
                    <i class="far fa-handshake fa-2x"></i>
                </div>
                <b-card-text v-else-if="'duration' in event && event.category !== 'inspectValves'" style="{font-weight:bold;}"> Step Duration: {{event.duration}} </b-card-text>
            </b-col>
        </b-row>
    </b-card>
</template>

<style scoped>
.processCard{
    width:70%;
    background-color: #32383e;
}
.img-box {
    display: flex;
    align-items:center;
}

.nameplate{
    font-weight: bold;
    text-overflow: clip;
    border-radius: 2px;
    padding: 2px;
    white-space: nowrap;
}
</style>
