<template>
    <div class="row">
        <div class="col-12">

            <canvas v-bind:width="this.width" v-bind:height="this.height" style="border:1px solid #AAA;" v-init-canvas="{width: this.width, height: this.height}"></canvas>
        </div>
    </div>
</template>

<script>
     export default {
        data() {
            return {
                ctx:{},
                history:[],
                updateDataTimer:{}
            }
        },
        props: ['boreDia','diameter','width','height','timeSpan','direction'],
        directives: {
            initCanvas: function(canvasElement, binding, vnode){
                vnode.context.ctx = canvasElement.getContext("2d");
                vnode.context.ctx.clearRect(0,0,binding.width,binding.height);
                vnode.context.ctx.fillStyle = 'black';
                vnode.context.ctx.font = '20px Arial';
                vnode.context.refresh();
            }
        },
        methods: {
            drawLine: function(){
                this.ctx.beginPath();
                this.ctx.moveTo(10,10);
                this.ctx.lineTo(50,90);
                this.ctx.stroke();
            },
            refresh: function(){
                var self = this;
                self.ctx.clearRect(0,0,self.width,self.height);
                for(var i=0;i<self.history.length;i++){
                    if(self.history[i]>0){
                        self.ctx.beginPath();
                        if (self.directionIn) {
                            self.ctx.moveTo(self.width/2-self.history[i]*self.xPxPerInch/2,self.height - i);
                            self.ctx.lineTo(self.width/2+self.history[i]*self.xPxPerInch/2,self.height - i);
                        } else {
                            self.ctx.moveTo(self.width/2-self.history[i]*self.xPxPerInch/2,i);
                            self.ctx.lineTo(self.width/2+self.history[i]*self.xPxPerInch/2,i);
                        }
                        
                        self.ctx.stroke();
                    }
                    
                }
            },
            updateData: function(){
                this.history.push(parseFloat(this.diameter));
                if(this.history.length > this.height)
                    this.history.splice(0,1);
            },
            redraw: function(){
                this.refresh();
            },
            startUpdateData: function(){
                clearInterval(this.updateDataTimer);
                var dt = this.timeSpan * 1000 / this.height;
                this.updateDataTimer = setInterval(this.updateData,dt);
            },
            initHistory: function(){
                var self = this;
                self.history = [];
                for(var i=0;i<self.height;i++){
                    self.history.push(0.0);
                }
            }
        },
        computed: {
            xPxPerInch: function(){
                return this.width / this.boreDia;
            },
            directionIn: function(){
              return this.direction == "in";
            }
        },
        mounted() {
            this.initHistory();
            this.updateDataTimer = setInterval(this.updateData,1000);
            setInterval(this.redraw(),200);
            this.startUpdateData();
        },
        watch:{
            timeSpan: function(val){
                this.startUpdateData();
            },
            directionIn: function(val){
                this.initHistory();
            }
        }
     }
</script>