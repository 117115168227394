<script>
import _ from 'lodash';

export default {
    props: {
        processes: {
            type: Array,
            default: []
        },
        wells: {
            type: Array,
            default: []
        },
        shifts: {
            type: Array,
            default: []
        },
        disabled: {
            type: Boolean,
            required: true
        },
        search: {
            type: String,
            required: true
        },
        filters: {
            type: Object,
            required: true
        },
        sortBy: {
            type: String,
            required: false
        },
        sortDirection: {
            type: String,
            required: false
        }
    },
    data() {
        return {
            selectedFilters: _.cloneDeep(this.filters), // filters selected and saved
            newFilters: _.cloneDeep(this.filters), // active filter selection from the modal
            searchInput: this.search,
            show: false
        }
    },
    computed: {
        screenBadgeClass() {
            return {
                'badge': true,
                'badge-outline': true,
                'badge-disabled': this.disabled
            }
        },
        sortInfo() {
            let sortString = ''
            if (!this.sortBy) {
                return sortString;
            }
            sortString = 'Sorting By ' + this.sortBy
            if (!!this.sortDirection) {
                sortString += ' : ' + this.sortDirection
            }
            return sortString;
        }
    },
    methods: {
        closeFilterModal() {
            this.show = false;
            // copy over the old values so anything added/discarded is reversed
            this.newFilters = _.cloneDeep(this.selectedFilters);
        },
        saveFilters() {
            const filtersAreUnchanged = Object.keys(this.newFilters).every(key => _.isEqual(_.sortBy(this.selectedFilters[key]), _.sortBy(this.newFilters[key])));
            if (!filtersAreUnchanged) {
                this.$emit('update:filters', _.cloneDeep(this.newFilters));
                this.selectedFilters = _.cloneDeep(this.newFilters);
            }
            this.show = false;
        },
        onFilterChanged($event, category) {
            if (this.newFilters[category].includes($event)) {
                this.onModalBadgeClicked($event, category);
            } else {
                this.newFilters[category].push($event);
            }
        },
        onModalBadgeClicked($event, category) {
            const index = this.newFilters[category].indexOf($event);
            if (index !== -1) {
                this.newFilters[category].splice(index, 1);
            }
        },
        onScreenBadgeClicked($event, category) {
            if (this.disabled) {
                return;
            }
            const index = this.selectedFilters[category].indexOf($event);
            if (index < 0)
                return;
            this.selectedFilters[category].splice(index, 1);
            this.newFilters[category].splice(index, 1);
            this.$emit('update:filters', _.cloneDeep(this.selectedFilters))
        },
    },
    watch: {
        searchInput(newValue) {
            this.$emit('update:search', newValue);
        },
    }
}
</script>

<template>
    <div class="process-logs-filter-container">
            <div class="d-flex align-items-baseline">
                <div id="search-bar-container">
                        <b-input-group size="md">
                            <b-form-input
                                id="search-input"
                                class="search-box"
                                v-model="searchInput"
                                type="text"
                                placeholder="Type to Search"
                                debounce="1000"
                            ></b-form-input>
                        </b-input-group>
                </div>
                <button id="filters-button" :disabled="disabled" class="btn btn-outline-light"
                        @click="show = true">
                    <i class="fas fa-filter"/> Filters
                </button>
                <div v-if="!!sortBy" class="sort-info">
                    {{ sortInfo }}
                </div>
            </div>
        <div class="filter-container selected-filter-container">
                        <span :class="screenBadgeClass" :disabled="disabled"
                              v-for="(option, index) in selectedFilters.processes" :key="index">
                            {{ option }} <i class="fas fa-times pointer-hover" style="margin-right: 10px"
                                            @click="onScreenBadgeClicked(option, 'processes')"/>
                        </span>
            <span :class="screenBadgeClass" :disabled="disabled" v-for="(option, index) in selectedFilters.wells"
                  :key="index">
                                        {{ option }} <i class="fas fa-times pointer-hover" style="margin-right: 10px"
                                                        @click="onScreenBadgeClicked(option, 'wells')"/>
                                    </span>
            <span :class="screenBadgeClass" :disabled="disabled" v-for="(option, index) in selectedFilters.shifts"
                  :key="index">
                                        {{ option }} <i class="fas fa-times pointer-hover" style="margin-right: 10px"
                                                        @click="onScreenBadgeClicked(option, 'shifts')"/>
                                    </span>
        </div>
        <iws-modal
            id="table-config"
            title="Filters"
            :showModal="show"
            maxWidth="680px"
            @close="closeFilterModal"
            @primary-action="saveFilters"
            @secondary-action="closeFilterModal"
        >
            <template #content>
                <div class="filter-container modal-filter-container">
                    <iws-select
                        label="Process"
                        :value="newFilters.processes"
                        :multiselect="true"
                        :maintain-on-select="true"
                        display-name="label"
                        value-name="value"
                        :visible-options="10"
                        :options="processes"
                        placeholder="Select Process"
                        @change="onFilterChanged($event, 'processes')"
                    />
                    <div class="modal-selected-filter-container">
                        <span v-for="(option, index) in newFilters.processes" :key="index"
                              class="badge badge-outline">
                            {{ option }} <i class="fas fa-times pointer-hover" style="margin-right: 10px"
                                            @click="onModalBadgeClicked(option, 'processes')"/>
                        </span>
                    </div>
                    <iws-select
                        label="Well"
                        :value="newFilters.wells"
                        :multiselect="true"
                        :maintain-on-select="true"
                        display-name="name"
                        value-name="name"
                        :visible-options="10"
                        :options="wells"
                        placeholder="Select Well"
                        @change="onFilterChanged($event, 'wells')"
                    />
                    <div class="modal-selected-filter-container">
                        <span v-for="(option, index) in newFilters.wells" :key="index" class="badge badge-outline">
                            {{ option }} <i class="fas fa-times pointer-hover" style="margin-right: 10px"
                                            @click="onModalBadgeClicked(option, 'wells')"/>
                        </span>
                    </div>
                    <iws-select
                        label="Shift"
                        :value="newFilters.shifts"
                        :multiselect="true"
                        :maintain-on-select="true"
                        display-name="label"
                        value-name="value"
                        :visible-options="10"
                        :options="shifts"
                        placeholder="Select Shift"
                        @change="onFilterChanged($event, 'shifts')"
                    />
                    <div class="modal-selected-filter-container">
                        <span v-for="(option, index) in newFilters.shifts" :key="index" class="badge badge-outline">
                            {{ option }} <i class="fas fa-times pointer-hover" style="margin-right: 10px"
                                            @click="onModalBadgeClicked(option, 'shifts')"/>
                        </span>
                    </div>
                </div>
            </template>
        </iws-modal>
    </div>
</template>

<style scoped>

.modal-filter-container {
    /* The 140px padding to the bottom is to give extra space to the last multiselect, otherwise the user would need to
    scroll down the modal just to scroll down the multi-select. */
    padding: 0 20px 140px 20px;
}

.modal-selected-filter-container {
    margin: 10px 0 20px 0;
}

.selected-filter-container {
}

.badge-outline {
    margin-top: -10px;
}

.badge-disabled {
    opacity: 50%;
}

.sort-info {
    padding: 16px;
}

.search-box {
    background-color: #343a40;
    box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 4px;
    color: var(--primary-text-color);
    margin-right: 16px;
}

.search-box:focus {
    transition: border-color 0.25s ease-in-out;
}

.search-box:focus {
    background: #343A40;
    color: var(--primary-text-color);
    border-color: var(--primary-color) !important;
}

</style>
