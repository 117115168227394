var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass: "table table-striped table-sm small-font",
      class: { "table-dark": this.$root.darkMode },
    },
    [
      _c("thead", [
        _c(
          "tr",
          { staticClass: "ash-bg" },
          _vm._l(_vm.tableData, function (value, index) {
            return _c("th", { key: index }, [
              _vm._v("\n                " + _vm._s(index) + "\n            "),
            ])
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c("tbody", [
        _c(
          "tr",
          _vm._l(_vm.tableData, function (value, index) {
            return _c("td", { key: index }, [
              _vm._v("\n                " + _vm._s(value) + "\n            "),
            ])
          }),
          0
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }