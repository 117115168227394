<template>
<div>
    <div class="row">
        <div class="col">
            <input id="pinInput" name="pinInput" type="password" class="form-control form-control-sm text-center font-weight-bold" v-model="pin">
        </div>
    </div>
    <div class="row">
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3" v-on:click="pinPad($event,7)">7</button>
        </div>
        <div class="col-4  mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,8)">8</button>
        </div>
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,9)">9</button>
        </div>
    </div>
    <div class="row">
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,4)">4</button>
        </div>
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,5)">5</button>
        </div>
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,6)">6</button>
        </div>
        </div>
    <div class="row">
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,1)">1</button>
        </div>
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,2)">2</button>
        </div>
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3"  v-on:click="pinPad($event,3)">3</button>
        </div>
    </div>
    <div class="row">
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
                    
        </div>
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3" v-on:click="pinPad($event,0)">0</button>
        </div>
        <div class="col-4 mr-0 ml-0 pl-1 pr-1">
            <button class="btn btn-secondary btn-block mt-2 btn-lg py-3" v-on:click="pinPad($event,'Del')">Del</button>
        </div>
    </div>
</div>
</template>

<script>

    export default {
        data() {
            return {
                pin: "",
            }
        },
        methods: {
            pinPad: function(event,button){
                //console.log("Triggered the pinPad method with button: "+button);
                if(button == "Del"){
                    this.pin = this.pin.substr(0,this.pin.length-1);
                } else {
                    this.pin = this.pin + button;
                }
            },
            clearPin: function() {
                this.pin = '';
            }
        }
    }
</script>