var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.useNewKPi || (!!_vm.initalOptions && !!_vm.job?.id)
    ? _c(_vm.kpiComponentName, {
        tag: "component",
        style: {
          height: _vm.height
            ? `${_vm.useNewKPi ? _vm.height + 15 : _vm.height}px`
            : "auto",
        },
        attrs: {
          item: _vm.item,
          job: _vm.job,
          initalOptions: _vm.initalOptions,
          "job-number": _vm.jobNumber,
          customer: _vm.customer,
          "edit-mode": _vm.editMode,
          "dashboard-data": _vm.dashboardData,
          height: _vm.height,
        },
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }