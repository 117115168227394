var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "video-scribber-wrapper" }, [
    _c(
      "span",
      { staticClass: "control-container" },
      [
        _c("iws-button", {
          attrs: { type: "outline-light" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              !_vm.paused ? _vm.onPauseClicked() : _vm.onPlayClicked()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("b-icon", {
                    attrs: { icon: !_vm.paused ? "pause" : "play" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
        _vm._v(" "),
        _c("iws-button", {
          attrs: { type: "outline-light" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              !_vm.muted ? _vm.mutePlayer() : _vm.unMutePlayer()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [
                  _c("b-icon", {
                    attrs: { icon: !_vm.muted ? "volume-up" : "volume-mute" },
                  }),
                ]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "span",
      [
        !_vm.liveMode
          ? [
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.makeTimeReadable(_vm.sliderTime)) +
                    " / 24:00\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("VueSlider", {
                staticStyle: { width: "calc(100% - 110px) !important" },
                attrs: {
                  max: _vm.sliderMaxValue,
                  "tooltip-formatter": (val) => _vm.makeTimeReadable(val),
                  marks: _vm.scrubberTicks[_vm.vodSelected],
                },
                on: { change: _vm.onSliderValueChanged },
                model: {
                  value: _vm.sliderTime,
                  callback: function ($$v) {
                    _vm.sliderTime = _vm._n($$v)
                  },
                  expression: "sliderTime",
                },
              }),
            ]
          : !!_vm.camera.llhls
          ? [
              _c("span", [
                _vm._v(
                  "\n\t\t\t\t" +
                    _vm._s(_vm.makeLiveTimeReadable(_vm.sliderTime)) +
                    "\n\t\t\t"
                ),
              ]),
              _vm._v(" "),
              _c("VueSlider", {
                attrs: {
                  max: _vm.dvrDuration,
                  direction: "rtl",
                  "tooltip-formatter": (val) => _vm.makeLiveTimeReadable(val),
                },
                on: { change: _vm.onSliderValueChanged },
                model: {
                  value: _vm.sliderTime,
                  callback: function ($$v) {
                    _vm.sliderTime = _vm._n($$v)
                  },
                  expression: "sliderTime",
                },
              }),
            ]
          : _vm._e(),
      ],
      2
    ),
    _vm._v(" "),
    _c(
      "span",
      { staticClass: "control-container" },
      [
        _c("iws-button", {
          staticStyle: { "margin-left": "55px" },
          attrs: { type: "outline-light" },
          on: {
            click: function ($event) {
              $event.stopPropagation()
              return _vm.enterFullScreen.apply(null, arguments)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "text",
              fn: function () {
                return [_c("i", { staticClass: "fas fa-expand" })]
              },
              proxy: true,
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }