var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "col",
      style: _vm.wellStyle(),
      on: {
        click: function ($event) {
          return _vm.wellSelectedEvent()
        },
        touchend: function ($event) {
          return _vm.wellSelectedEvent()
        },
      },
    },
    [
      _vm.well.emergencyBypass
        ? _c("div", { staticClass: "row row-cols-1" }, [
            _c(
              "div",
              {
                staticClass: "text-center h4 mb-1 py-1",
                staticStyle: {
                  color: "#ffffff",
                  "background-color": "#ce5835",
                },
              },
              [_vm._v("EMERGENCY BYPASS")]
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.processActive
        ? _c("process-step-component", {
            attrs: {
              currentStep: _vm.currentStep,
              valveStates: _vm.well.valves,
              lastStep: _vm.lastStep,
              wellNumber: _vm.index,
              activity: _vm.well.activity,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center mb-0 pb-0" }, [
        _c("h5", { staticClass: "text-center mb-0" }, [
          _vm._v(_vm._s(_vm.well.wirelineTruck)),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-1 mt-0 pt-0" }, [
        _c(
          "div",
          {
            staticClass:
              "col mt-0 pr-0 pl-0 pt-0 d-flex justify-content-center",
          },
          [
            _vm.well.activity == "wireline"
              ? _c("wireline-activity", {
                  staticStyle: { "max-height": "40px" },
                  attrs: { spoolCol: _vm.spoolCol },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.well.activity == "frac"
              ? _c("frac-activity", { staticStyle: { "max-height": "40px" } })
              : _vm._e(),
            _vm._v(" "),
            _vm.well.activity == "maintenance"
              ? _c("maintenance-activity", {
                  staticStyle: { "max-height": "40px" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-center mt-0 pb-0" }, [
        _vm.well.activity != "none"
          ? _c(
              "h5",
              {
                staticClass: "text-center mt-0",
                staticStyle: { "text-transform": "uppercase" },
              },
              [_vm._v(_vm._s(_vm.well.activity))]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mb-1" }, [
        _c("div", { staticClass: "col text-center" }, [
          _c(
            "div",
            {
              ref: _vm.imgId,
              staticStyle: { position: "relative", height: "100%" },
              on: {
                click: function ($event) {
                  return _vm.action()
                },
                touchend: function ($event) {
                  return _vm.action()
                },
              },
            },
            [
              _c("img", {
                key: _vm.imgId,
                staticClass: "img-fluid",
                style: { "max-height": _vm.maxWellHeight + "px" },
                attrs: {
                  src: "../images/well_" + _vm.well.config_number + ".png",
                  id: _vm.imgId,
                },
              }),
              _vm._v(" "),
              _vm._l(_vm.well.valves, function (valve, index) {
                return _c("valve-component", {
                  key: index,
                  attrs: {
                    state: valve,
                    config: _vm.well.config_number,
                    imgId: _vm.imgId,
                    currentStep: _vm.currentStep,
                    index: index,
                  },
                })
              }),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "border",
              class: _vm.classCasingPressure,
              staticStyle: {
                position: "absolute",
                right: "2px",
                bottom: "2px",
                width: "30%",
                "z-index": "1",
              },
              on: {
                click: function ($event) {
                  return _vm.pressureAction("casing")
                },
                touchend: function ($event) {
                  return _vm.pressureAction("casing")
                },
              },
            },
            [
              _c("div", { staticStyle: { "font-size": "1.2em" } }, [
                _vm._v(_vm._s(_vm.casingPressureValue)),
              ]),
              _vm._v(" "),
              _c("div", { staticStyle: { "font-size": "0.8em" } }, [
                _vm._v("psi"),
              ]),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      !_vm.well.config_number
        ? _c("div", { staticClass: "row mb-1" }, [
            _vm.well.unlocked
              ? _c(
                  "div",
                  {
                    staticClass: "col m-1",
                    staticStyle: { "background-color": "#00ff00" },
                  },
                  [
                    _c("h5", { staticClass: "text-center mb-0" }, [
                      _vm._v("UNLOCKED"),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.well.unlocked
              ? _c(
                  "div",
                  {
                    staticClass: "col m-1",
                    staticStyle: { "background-color": "#ff0000" },
                  },
                  [
                    _c("h5", { staticClass: "text-center mb-0" }, [
                      _vm._v("LOCKED"),
                    ]),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "row row-cols-1" }, [
        _c(
          "div",
          {
            staticClass: "col",
            style: { "background-color": _vm.well.color, color: _vm.nameColor },
          },
          [
            _c("h5", { staticClass: "text-center mb-0 h1 py-2" }, [
              _vm._v(_vm._s(_vm.well.name)),
            ]),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "col text-center mb-1 h5",
            style: {
              height: _vm.statusHeight + "px",
              "background-color": _vm.well.color,
              color: _vm.nameColor,
            },
          },
          [
            _vm._v(
              "         \n        Stage " +
                _vm._s(_vm.well.currentStage) +
                "/" +
                _vm._s(_vm.well.numberOfStages) +
                " \n        "
            ),
            _c("br"),
            _vm._v(" "),
            _c("div", { staticClass: "col mb-4 h5" }, [
              _vm._v(_vm._s(_vm.well.statusString)),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "b-modal",
            { ref: "pressureModal" + _vm.index, attrs: { title: "Pressure" } },
            [
              _c(
                "div",
                {
                  staticClass: "btn btn-lg btn-primary btn-block py-3",
                  on: {
                    click: function ($event) {
                      return _vm.setPressureEnable("true")
                    },
                    touchend: function ($event) {
                      return _vm.setPressureEnable("true")
                    },
                  },
                },
                [_vm._v("Enable")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-lg btn-primary btn-block py-3",
                  on: {
                    click: function ($event) {
                      return _vm.setPressureEnable("false")
                    },
                    touchend: function ($event) {
                      return _vm.setPressureEnable("false")
                    },
                  },
                },
                [_vm._v("Disable")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-lg btn-primary btn-block py-3",
                  on: {
                    click: function ($event) {
                      return _vm.zeroPressure()
                    },
                    touchend: function ($event) {
                      return _vm.zeroPressure()
                    },
                  },
                },
                [_vm._v("Zero")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-lg btn-secondary btn-block py-3",
                  attrs: { slot: "modal-footer" },
                  on: {
                    click: function ($event) {
                      return _vm.hidePressureModal()
                    },
                    touchend: function ($event) {
                      return _vm.hidePressureModal()
                    },
                  },
                  slot: "modal-footer",
                },
                [_vm._v("Cancel")]
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }