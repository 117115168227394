<template>
    <b-modal 
        v-model="modalVisible"
        ref="error-modal" 
        @hidden="$emit('onDismissErrorsModal')"
        no-close-on-backdrop
        size="xl"
        header-class="border-bottom-0 justify-content-center  p-0"
        body-class="pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg">
        <template #modal-header>
            <div class="col w-100">
                <div class="modal-header">
                    <div class="font-large w-100 text-center"> Fetch Errors </div>
                </div>
            </div>
        </template>
        <template #default>
            <h4 v-for="(errorObject) in errors" :key="errorObject.error">
                {{ errorObject.error }}
                <h5 v-for="(detail) in errorObject.details" :key="detail">
                    {{ "- " + detail }}
                </h5>
            </h4>
        </template>
        <template #modal-footer>
            <div class="d-flex w-100 justify-content-center align-items-center px-2 pb-2">
                <button type="button" class="btn btn-secondary grey-button" @click="()=>{ $emit('onDismissErrorsModal') }" >Confirm</button>
            </div>
        </template>
    </b-modal>
</template>
<style scoped>
    .form-control:disabled {
        background-color: lightgray;
    }
</style>
<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';

export default {
    data() {
        return {
        };
    },
    props: {
        modalVisible: {
            type: Boolean,
            default: false
        },
        errors: {
            type: [Array, Object],
            required: false
        }
    },
    mounted() {
    },
    methods: {
    }
};
</script>
