var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      title: "Add NPT Outcome",
      "primary-button-text": "Save",
      showModal: _vm.showBaseModal,
      "max-width": "400px",
      "close-on-clickaway": false,
    },
    on: {
      close: _vm.cancelAction,
      "secondary-action": _vm.cancelAction,
      "primary-action": _vm.confirmAction,
    },
    scopedSlots: _vm._u(
      [
        !!_vm.data
          ? {
              key: "content",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("iws-input", {
                        ref: "nameInput",
                        attrs: {
                          value: _vm.data.item.name,
                          label: "Name *",
                          required: "",
                          validator: (value) =>
                            _vm.validator(value, _vm.data.index, "name"),
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.data.item, "name", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "30px" } },
                    [
                      _c("iws-input", {
                        ref: "friendlyNameInput",
                        attrs: {
                          value: _vm.data.item.friendly_name,
                          label: "Display Name *",
                          required: "",
                          validator: (value) =>
                            _vm.validator(
                              value,
                              _vm.data.index,
                              "friendly_name"
                            ),
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(
                              _vm.data.item,
                              "friendly_name",
                              $event
                            )
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }