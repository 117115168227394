<template>
    <div class="row" id="job-control-bar">
        <div v-if="(!isHome && !isClips) || !_isNullOrEmpty(cameras)" class="col-sm-12 col-md-7">
            <a v-if="!isHome && !isClips" :href="`/cameras/viewer/${workOrderId}`" style="display: inline-block; position: relative; top: -3px; width: 38px">
                <iws-button v-if="!isHome && !isClips" type="outline-light" prepend-icon="fas fa-chevron-left" />
            </a>

            <iws-select v-if="!_isNullOrEmpty(cameras)"
                class="ml-2"
                :placeholder="cameraPlaceholder"
                :options="cameras"
                :visible-options="10"
                :searchable="false"
                full-placeholder
            >
                <template #option="{ data, option }">
                    <div :style="`padding: ${!data?.index ? '5px' : '15px'} 0px 0px 7.5px; font-size: 20px; font-weight: 700;`">
                        {{ option?.displayName || option?.name || `Camera: ${data.index+1}` }}
                    </div>

                    <option v-for="(stream, streamIndex) in option?.streamConfigurations"
                        @click="onStreamChange(option, stream)"
                        class="pl-4"
                        :class="{ 
                            'selected': stream.cameraStreamConfigId == cameras[cameraIndex]?.primaryStream?.cameraStreamConfigId,
                            'no-border-option': !streamIndex
                        }"
                    >
                        &#x2022; {{ stream?.name || `Camera: ${streamIndex}` }}
                    </option>
                </template>
            </iws-select>
        </div>

        <div class="col-sm-12 col-md">
            <span>
                <a v-if="iwsUser" :href="`/cameras/settings/${workOrderId}/${device.deviceId}`">
                    <iws-button type="text-light" prepend-icon="fas fa-cog" text="Configure" />
                </a>

                <a v-if="!isClips" :href="`/cameras/clips/${workOrderId}`">
                    <iws-button type="text-light" prepend-icon="fas fa-photo-video" text="Manage Clips" />
                </a>
                <a v-else :href="`/cameras/viewer/${workOrderId}`">
                    <iws-button type="text-light" prepend-icon="fas fa-video" text="SiteVision" />
                </a>
            </span>
        </div>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty } = GlobalFunctions;

import JobMixin from '../../mixins/JobMixin.js';

export default {
    props: {
        user: {
            type: Object,
            required: true
        },

        device: {
            type: Object,
            required: true
        },

        cameras: {
            type: Array,
            required: false
        },

        workOrderId: {
            type: String,
            required: true
        },

        cameraIndex: {
            type: String | Number,
            required: false
        },

        // If already on clips page, instead of linking to clips we link back to multi cam viewer
        isClips: {
            type: Boolean,
            default: false
        },


        // When on multi cam page, don't link to multi cam page
        isHome: {
            type: Boolean,
            default: false
        }
    },

    mixins: [JobMixin],

    computed: {
        iwsUser() {
            return this.user?.customer?.name == 'IWS';
        },
        cameraPlaceholder() {
            return `${this.cameras[this.cameraIndex]?.displayName || this.cameras[this.cameraIndex]?.name || `Camera: ${this.cameraIndex+1}`} - ${this.cameras[this.cameraIndex]?.primaryStream?.name}`
        }
    },

    methods: {
        _isNullOrEmpty(value) {
            return isNullOrEmpty(value);
        },
        onStreamChange(camera, stream) {
            window.location.href = `/cameras/viewer/${this.workOrderId}/${camera.cameraConfigId}/${stream.cameraStreamConfigId}`; 
        }
    }
};
</script>

<style scoped>
    #job-control-bar {
        max-width: 100vw;
        margin-bottom: 20px;
        padding-right: 5px;
    }
    #job-control-bar .col-md>span {
        float: right;
        padding-top: 5px;
    }
    #job-control-bar .custom-search-select {
        display: inline-block;
        width: 100%;
        max-width: 350px;
    }
    .no-border-option {
        border-top: none !important;
    }
</style>