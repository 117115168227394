var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "table table-sm text-dark" }, [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.headers, function (header, index) {
            return _c(
              "th",
              {
                key: "header" + header.id,
                staticStyle: { "font-size": "small" },
                attrs: { scope: "col" },
              },
              [
                index == 0 && _vm.copyIndex != null
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectAll,
                          expression: "selectAll",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.selectAll)
                          ? _vm._i(_vm.selectAll, null) > -1
                          : _vm.selectAll,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.selectAll,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectAll = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectAll = $$c
                            }
                          },
                          _vm.updateSelectAll,
                        ],
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                header.selectable
                  ? _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: header.selected,
                          expression: "header.selected",
                        },
                      ],
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(header.selected)
                          ? _vm._i(header.selected, null) > -1
                          : header.selected,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = header.selected,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(header, "selected", $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  header,
                                  "selected",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(header, "selected", $$c)
                          }
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v("\n          " + _vm._s(header.val) + "\n          "),
                index == 0 &&
                _vm.copyIndex != null &&
                _vm.numberOfRowsSelected > 0
                  ? _c(
                      "span",
                      {
                        staticClass: "btn btn-sm btn-primary",
                        on: { click: _vm.copyToSelected },
                      },
                      [_vm._v("Copy to selected")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                index == 0 &&
                _vm.copyIndex != null &&
                _vm.numberOfRowsSelected == 0
                  ? _c("span", { staticClass: "badge badge-pill badge-info" }, [
                      _vm._v("Select destination rows"),
                    ])
                  : _vm._e(),
              ]
            )
          }),
          0
        ),
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.rows, function (row, rowIndex) {
          return _c(
            "tr",
            {
              key: "row" + row.id,
              class: { "bg-warning text-light": row.unsavedChanges },
            },
            _vm._l(row.values, function (value, valueIndex) {
              return _c(
                "td",
                { key: "val" + value.id, staticStyle: { padding: "0.2rem" } },
                [
                  valueIndex == 0 &&
                  (_vm.copyIndex == null || _vm.copyIndex == rowIndex) &&
                  !row.unsavedChanges
                    ? _c("span", {
                        staticClass: "fas fa-copy",
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.copy(rowIndex)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  valueIndex == 0 && row.unsavedChanges
                    ? _c("span", {
                        staticClass: "fas fa-save",
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.save(_vm.items[rowIndex], row)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.headers[valueIndex].val == "job_jobNumber" &&
                  _vm.type == "well"
                    ? _c("span", {
                        staticClass: "fas fa-search",
                        attrs: { role: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.openSelectJobModal(rowIndex)
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  valueIndex == 0 &&
                  _vm.copyIndex != rowIndex &&
                  _vm.copyIndex != null
                    ? _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: row.selected,
                            expression: "row.selected",
                          },
                        ],
                        attrs: { type: "checkbox" },
                        domProps: {
                          checked: Array.isArray(row.selected)
                            ? _vm._i(row.selected, null) > -1
                            : row.selected,
                        },
                        on: {
                          change: function ($event) {
                            var $$a = row.selected,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 &&
                                  _vm.$set(row, "selected", $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  _vm.$set(
                                    row,
                                    "selected",
                                    $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                  )
                              }
                            } else {
                              _vm.$set(row, "selected", $$c)
                            }
                          },
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  valueIndex == 0
                    ? _c(
                        "a",
                        {
                          attrs: { href: `/metadata/${_vm.type}/${value.id}` },
                        },
                        [_vm._v(_vm._s(value.value))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  valueIndex == 0 && row.saving
                    ? _c(
                        "span",
                        { staticClass: "badge badge-pill badge-warning" },
                        [_vm._v("saving")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  valueIndex != 0 && value.readOnly
                    ? _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(value.value) +
                            "\n          "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  valueIndex != 0 && !value.readOnly
                    ? _c("input", {
                        staticClass:
                          "form-control form-control-sm bg-light text-dark",
                        staticStyle: {
                          height: "calc(1.5em + 2px)",
                          "border-style": "none",
                        },
                        attrs: { type: "text", disabled: row.saving },
                        domProps: { value: value.value },
                        on: {
                          change: function ($event) {
                            return _vm.updateValue(
                              $event,
                              _vm.items[rowIndex],
                              value,
                              row
                            )
                          },
                        },
                      })
                    : _vm._e(),
                ]
              )
            }),
            0
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "selectJobModal",
          tabindex: "-1",
          "aria-labelledby": "selectJobModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content text-light" }, [
            _vm._m(0),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body" }, [
              _vm._v("\n          Select job\n          "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("Job")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedJobIndex,
                        expression: "selectedJobIndex",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedJobIndex = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.jobs, function (job, jobIndex) {
                    return _c(
                      "option",
                      { key: "job" + job.id, domProps: { value: jobIndex } },
                      [_vm._v(_vm._s(job.jobNumber))]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.setJobNumber },
                },
                [_vm._v("Okay")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "selectJobModalLabel" } },
        [_vm._v("Select Job")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }