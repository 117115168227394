<template>
    <b-modal 
        v-model="modalVisible"
        ref="chartOptionsModal" 
        @hidden="$emit('onDismiss')"
        no-close-on-backdrop
        size="lg"
        header-class="border-bottom-0 justify-content-center  p-0"
        body-class="pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg">
        <template v-if="configData.type=='channel'" #modal-header>
            <div class="col w-100">
                <div class="modal-header d-flex justify-content-center">
                    <select class="form-control w-50" style="text-align-last:center; text-align:center" v-model="configData.selectedChannelId">
                        <option v-for="channel in configData.selectedChannels" :key="channel.id" :value="channel.id">{{channel.prioritizedTagName? channel.prioritizedTagName: channel.friendlyTagname}}</option>
                    </select>
                </div>
            </div>
        </template>
        <template #default>
            <div v-if="configData.type=='axis'" class="col w-100">
                <div class="modal-header d-flex justify-content-center">
                    <select class="form-control w-50" style="text-align-last:center; text-align:center" v-model="configData.selectedChannelId">
                        <option v-for="channel in configData.selectedChannels" :key="channel.id" :value="channel.id">{{channel.prioritizedTagName? channel.prioritizedTagName: channel.friendlyTagname}}</option>
                    </select>
                </div>
            </div>
            <div>
                <form>
                    <div v-if="configData.type=='channel'" class="col-12 text-center align-items-center mb-2">Channel Options</div>
                    <div v-if="configData.type=='channel'"  class="row justify-content-center align-items-center">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-4 text-right">Line Color</div>
                                <div class="col-8">
                                    <div class="">
                                        <div class="swatch p-0" @click="showChannelColorPicker = !showChannelColorPicker">
                                            <div class="color" :style="{'background-color': channelOptions.color}" />
                                        </div>
                                        <div v-if="showChannelColorPicker" class="popover">
                                            <div class="cover" @click="showChannelColorPicker = false;"/>
                                            <sketch-picker
                                                ref="colorPicker"
                                                :value="channelOptions.color ? channelOptions.color : '#FFFFFF'"
                                                id="color"
                                                :disableAlpha="true"
                                                @input="color => {channelOptions.color = color.hex;}"
                                                />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-4 text-right">Line Size</div>
                                <div class="col-8">
                                    <input ref="lineSize" type="number" default="2" min="1" max="10" v-model="channelOptions.thickness" style="max-width:75%" @blur="restrictLineSize()">
                                    <div v-if="formErrors.lineSize" class="text-danger">
                                        Line size is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="configData.type=='channel'" class="row align-items-center mb-2">
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-4 text-right">Show</div>
                                <div class="col-8 ">
                                    <input ref="showChannel" type="checkbox" v-model="channelOptions.show">
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <div class="col-12 text-center align-items-center mb-2">Axis Options</div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-4 text-right" v-tooltip:top="'Axis position changes will only appear on data load'">Axis</div>
                                <div class="col-6 pr-1">
                                        <select id="axisLocation" ref="selectedAxis" name="selectedAxis" default="default" class="mw-100 w-100 text-overflow-ellipsis" v-model="selectedAxisId" @change="updateSelectedAxis()">
                                        <option v-for="(axisOption) in axisOptionsArray" :key="axisOption.id" :value="axisOption.id">{{axisOption.label}}</option>
                                    </select>
                                </div>
                                <div class="col-2 p-0">
                                    <i class="fas fa-plus-circle show-clicker-finger" v-tooltip:top="'Create New Axis'" @click="addAxis()"></i>
                                    <i class="fas fa-copy show-clicker-finger" v-tooltip:top="'Copy Axis'" @click="copyAxis()"></i>
                                    <div v-if="configData.type=='channel'">
                                        <i v-if="configData.axisOptions[selectedAxisId].axisCreationType == 'custom'" class="fas fa-trash-alt show-clicker-finger ml-2" v-tooltip:top="'Delete Axis'" @click="deleteAxis()"></i> 
                                        <i v-else class="fas fa-trash-alt show-clicker-finger not-selectable-icon" v-tooltip:top="'Delete Axis'"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                                <div class="row align-items-center">
                                <div class="col-4 text-right">Label</div>
                                <div class="col-8">
                                    <input ref="axisLabel" type="text" style="max-width:100%;" class="text-overflow-ellipsis" v-model="configData.axisOptions[selectedAxisId].label" placeholder="Axis Label">
                                    <div v-if="formErrors.axisLabel" class="text-danger">
                                        Axis label is required
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-4 text-right" v-tooltip:top="'Axis position changes will only appear on data load'">Position<sup>*</sup></div>
                                <div class="col-8">
                                        <select ref="axisPosition" id="axisLocation" name="axisLocation" default="default" style="max-width:75%;" v-model="configData.axisOptions[selectedAxisId].location">
                                        <option id="defaultAxisLocation" value="default" >Left</option>
                                        <option id="oppositeAxisLocation" value="opposite">Right</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                                <div class="row align-items-center">
                                <div class="col-4 text-right">Auto Fit</div>
                                <div class="col-8">
                                    <input ref="axisAutoFit" type="checkbox" :disabled="configData.axisOptions[selectedAxisId].lockToZero==true" v-model="configData.axisOptions[selectedAxisId].autoFit">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-4 text-right">Min</div>
                                <div class="col-8">
                                    <input ref="axisMin" type="number" :disabled="configData.axisOptions[selectedAxisId].autoFit==true || configData.axisOptions[selectedAxisId].lockToZero==true"  v-model="configData.axisOptions[selectedAxisId].axisMin" style="max-width:75%">
                                    <div v-if="formErrors.axisMin" class="text-danger">
                                        Axis minimum is required and must be less than axis maximum
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-4 text-right">Max</div>
                                <div class="col-8">
                                    <input ref="axisMax" type="number" :disabled="(configData.axisOptions[selectedAxisId].autoFit==true || configData.axisOptions[selectedAxisId].lockToZero==true)"  v-model="configData.axisOptions[selectedAxisId].axisMax" style="max-width:75%">
                                    <div v-if="formErrors.axisMax" class="text-danger">
                                        Axis maximum is required and must be greater than axis minimum
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-6">
                            <div class="row align-items-center">
                                <div class="col-4 text-right">Unit</div>
                                <div class="col-8">
                                   <select ref="axisUnit" v-model="unitSelect" class="w-100" @input="setAxisUnits($event.target.value)">
                                       <option v-for="(unit,index) in channelUnits" :selected="unit==unitSelect" :key="index" :value="unit" >{{unit}}</option>
                                   </select>
                                   <input ref="axisCustom" type="text" class="w-100 mt-1 text-overflow-ellipsis" v-if="unitSelect=='Custom'" :value="configData.axisOptions[selectedAxisId].unit" @blur="setAxisUnits($event.target.value)">

                                </div>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="row align-items-center">
                            <div class="col-4 text-right">Lock to Zero</div>
                            <div class="col-8">
                                <input ref="axisLockToZero" type="checkbox" v-model="configData.axisOptions[selectedAxisId].lockToZero">
                            </div>
                        </div>
                    </div>
                        <div class="col-6">
                            <div class="row align-items-center">
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>
        <template #modal-footer>
            <div class="d-flex w-100 align-items-center justify-content-between px-2 pb-2">
                <div class="mx-1 w-25">
                    <button type="button" class="btn w-50 btn-secondary grey-button" @click="exitModal()" >Close</button>
                </div>
                <div class="d-flex flex-row justify-content-between">
                    <button type="button" class="btn btn-primary mr-2" @click.prevent="applyChanges()" :disabled="isSaving">
                        Apply Changes
                    </button>
                    <button type="button" class="btn btn-success float-right green-button" @click.prevent="applyChanges(true)" :disabled="isSaving">
                        <div class="d-flex justify-content-center">
                                <span v-if="isSaving" class="spinner-border spinner-border-sm pr-2" role="status" aria-hidden="true"></span>
                            <div>
                                Save Changes to Template
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<style scoped>
    .form-control:disabled {
        background-color: lightgray;
    }
    .popover {
    position: absolute;
    top: 30px;
    z-index: 2;
    }
    .swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
    }
    .not-selectable-icon {
        opacity: 0.25;
    }
    .text-overflow-ellipsis {
        text-overflow: ellipsis;
    }
</style>
<script>
import moment from 'moment';
import GlobalFunctions from '../../GlobalFunctions.js';
import { Sketch } from 'vue-color';
import _ from 'lodash';
import {v4 as uuidv4} from 'uuid';

export default {
    //TODO : This can be made generic and should be. Naming can just be analysistemplatesettingsmodal
    //And the can leverage the type passed in via prop
    data() {
        return {
            dropDownValue: false,
            returnedDataset: null,
            templateOptions: [],
            rerenderFlipper: true,
            templateName: null,
            templateLoading: false,
            processedTemplateData: null,
            showChannelColorPicker: false,
            configData: {},
            isFormValid: false,
            formErrors: {
                lineSize: false,
                axisLabel: false,
                axisMin: false,
                axisMax: false
            },
            configChanged: false,
            showCustomUnit: false,
            axisUnit: null,
            unitSelect: null
        };
    },
    components: {
        'sketch-picker': Sketch
    },
    props: {
        isSaving: {
            type: Boolean,
            default: false
        },
        modalVisible: {
            type: Boolean,
            default: false
        },
        templateData: {
            type: Object,
            default: null
        },
        modalData: {
            type: Object
        },
        targetTemplateId: {
            type: Number,
            required: false
        }
    },
    mounted() {
        this.fetchAvailableTemplates();
        if(this.templateData?.name){
            this.templateName = this.templateData.name;
            this.configData.axisOptions[selectedAxisId].lockToZero = this.templateData.chartOptions.axisOptions[selectedAxisId].lockToZero;
            this.configData.axisOptions[selectedAxisId].axisMin = parseFloat(this.templateData.chartOptions.axisOptions[selectedAxisId].axisMin);
            this.configData.axisOptions[selectedAxisId].axisMax = parseFloat(this.templateData.chartOptions.axisOptions[selectedAxisId].axisMax);
        }
        if(this.targetTemplateId){
            this.onLoad(this.targetTemplateId);
        }
    },
    methods: {
        updateSelectedAxis() {
            const axis = this.configData.axisOptions[this.selectedAxisId];
            axis.channels.push(this.channelOptions.id);//should check to see if channel is already in the list or not, or remove array 
            this.channelOptions.axisId = axis.id;
            if (axis.axisCreationType == 'custom') { //record used customAxis id so the channel can be reassigned to this axis next load
                this.channelOptions.customAxisId = axis.id;
            } else {
                this.channelOptions.customAxisId = null;
            }
        },
        addAxis() {
            const newAxis = this.configData.axisTemplate;
            newAxis.axisCreationType = 'custom';
            newAxis.id = uuidv4();
            newAxis.label = '';
            this.channelOptions.axisId = newAxis.id;
            this.$set(this.configData.axisOptions, newAxis.id, newAxis); //force axisOptionsArray to recompute
            this.selectedAxisId = newAxis.id;
            this.updateSelectedAxis();
        },
        deleteAxis() {
            if (!confirm('If this axis is deleted, all channels assigned to it will revert to their default axes. Do you wish to proceed?')) {
                return;
            }
            const self = this;
            //iterate through all channels attatched to this axis and assign them to their fallback axes
            const deletingAxis = this.configData.axisOptions[this.selectedAxisId];
            deletingAxis.channels.forEach(channelId => {
                const defaultAxis = Object.values(this.configData.axisOptions).find(axis => 
                    axis.axisCreationType == 'default'
                    && axis?.channels.includes(channelId)
                );
                if (defaultAxis) {
                    this.selectedAxisId  = defaultAxis.id;
                } else {
                    const autoAxis = Object.values(this.configData.axisOptions).find(axis => 
                        axis.channelId == self.channelOptions.id && axis.axisCreationType == 'auto'
                    );
                    this.selectedAxisId  = autoAxis.id;
                }
            });
            //delete the currently selected axis, then either return to the default axis for this tag or its auto axis
            this.$delete(this.configData.axisOptions, deletingAxis.id); //force axisOptionsArray to recompute
            this.applyChanges();
        },
        copyAxis() {
            const copiedAxis = _.cloneDeep(this.configData.axisOptions[this.selectedAxisId]);
            copiedAxis.axisCreationType = 'custom';
            copiedAxis.id = uuidv4();
            copiedAxis.label = copiedAxis.label + ' (Copy)';

            this.channelOptions.axisId = copiedAxis.id;
            this.$set(this.configData.axisOptions, copiedAxis.id, copiedAxis); //force axisOptionsArray to recompute
            this.selectedAxisId = copiedAxis.id;
            this.updateSelectedAxis();
        },
        validateFormFields() {
            this.isFormValid = true;
            //validate fields of the form that require user input and may have invalid values
            if (this.configData.type=='channel') {
                if (this.$refs.lineSize.value == null || this.$refs.lineSize.value == '') {
                    this.isFormValid = false;
                    this.formErrors.lineSize = true;
                }
            }
            if (this.$refs.axisLabel.value == '' || this.$refs.axisLabel == null) {
                this.isFormValid = false;
                this.formErrors.axisLabel = true;
            }
            if (!this.$refs.axisAutoFit.checked) {
                if (this.$refs.axisMin.value === '' || this.$refs.axisMin.value === null 
                    || this.$refs.axisMin.value === undefined || +this.$refs.axisMin.value >= +this.$refs.axisMax.value) {
                    this.isFormValid = false;
                    this.formErrors.axisMin = true;
                }
                if (this.$refs.axisMin.value === '' || this.$refs.axisMin.value === null 
                    || this.$refs.axisMin.value === undefined || +this.$refs.axisMax.value <= +this.$refs.axisMin.value) {
                    this.isFormValid = false;
                    this.formErrors.axisMax = true;
                }
            }
            if (this.isFormValid) {
                for (const error in this.formErrors) {
                    this.formErrors[error] = false;
                }
            }
        },
        setAxisUnits(newUnit) {
            if (newUnit != 'Custom') {
                this.configData.axisOptions[this.selectedAxisId].unit = newUnit;
            }           
        },
        applyChanges(saveConfiguration=false) {
            this.validateFormFields();
            if (!this.isFormValid) {return;}

            const newOptions = {
                channelOptions: this.channelOptions,
                axisOptions: this.configData.axisOptions,
                saveConfiguration: saveConfiguration
            };
            this.configChanged = false;
            this.$emit('onApplyOptionChanges', newOptions);
        },
        exitModal() {
            let allowExit = true;
            if (this.configChanged) {
                allowExit = confirm('Discard unsaved changes and exit?');
                if (allowExit) {//revert changes from exiting without save or apply
                    this.configData = _.cloneDeep(this.modalData);
                }
            }
            if (allowExit) {
                this.$emit('onDismiss');
            }
        },
        saveOptions() {
            const newOptions = {
                channelOptions: this.channelOptions,
                axisOptions: this.configData.axisOptions
            };
            this.configChanged = false;
            this.$root.$emit('saveOptionChanges', newOptions);
        },
        onLoad(targetTemplateID) {
            //Bail out early if the default element is selected
            if(targetTemplateID == '') {
                this.dropDownValue = false;
                return;
            }

            this.dropDownValue = true;

            this.templateLoading = true;

            const url = '/analysis/getTemplate';
            const self = this;

            this.wellOptions = [];
            
            $.get(
                url,
                {
                    targetTemplateID: targetTemplateID
                },
                function(response) {
                    self.$emit('onLoadTemplate', response);
                    self.templateLoading = false;
                    self.processTemplateData();
                }
            );
        },
        fetchAvailableTemplates() {
            //Should fetch all of the available configs given their type and populate the list
            const url = '/analysis/getTemplates';
            const self = this;

            this.wellOptions = [];
            $.get(
                url,
                {
                    type: self.chartType
                },
                function(returnSet) {
                    self.templateOptions = returnSet;
                    self.rerenderFlipper = !self.rerenderFlipper;
                }
            );
        },
        createNewTemplate() {
            //This asks the parent to handle setting up a new template
            this.$emit('onNewTemplate');
        },
        processTemplateData() {
            this.processedTemplateData = [];
            this.templateData.data.jobs.forEach(targetJob => {
                let targetAssociatedWells = this.templateData.data.wells.filter(well => {
                    return well.job_id == targetJob.id;
                });
                targetAssociatedWells.forEach(targetWell => {
                    let targetAssociatedStages = this.templateData.data.stages.filter(stage => {
                        return stage.well_id == targetWell.id
                    });
                    targetAssociatedStages.forEach(targetStage => {
                        this.templateData.data.channels.forEach(targetChannel => {
                            this.processedTemplateData.push({
                                job: targetJob.name,
                                well: targetWell.wellName,
                                stage: targetStage.stage,
                                index: this.templateData.data.indexBy[0].name,
                                channel: targetChannel.friendlyTagname
                            });
                        });
                    });
                });
            });
        },
        restrictLineSize() {
            if (this.channelOptions.thickness > 10) {
                this.channelOptions.thickness = 10;
            } else if (this.channelOptions.thickness < 1) {
                this.channelOptions.thickness = 1;
            }
        }
    },
    computed: {
        axisOptionsArray() {
            return Object.values(this.configData.axisOptions).filter(axis =>
                axis != null && axis?.axisCreationType != null 
                && axis.axisCreationType == 'default'
                || (axis.axisCreationType == 'auto' && axis.channelId == this.configData.selectedChannelId)
                || axis.axisCreationType == 'custom');
        },
        selectedAxisId: {
            get: function() {
                return this.configData.channelOptions[this.configData.selectedChannelId].axisId;
            },
            set: function(outputVal) {
                this.configData.channelOptions[this.configData.selectedChannelId].axisId = outputVal;          
            }
        },
        channelOptions() {
            return this.configData.channelOptions[this.configData.selectedChannelId];
        },
        channelUnits() {
            //hacky, but this will force units to recompute and update when the selected channel changes in the modal
            const trackChannelChanges = this.configData.selectedChannelId;

            //unit options for this axis are derived from all channels attatched to this axis + a custom option
            const channelIds = Object.values(this.configData.channelOptions).filter(option => option.axisId == this.selectedAxisId).map(option => option.id);
            const channels = this.configData.selectedChannels.filter(channel => channelIds.includes(channel.id));
            return  ['Custom',...new Set(channels.map(channel => channel.unit))];
        }
    },
    watch: {
        templateData: {
            handler(newTemplateData, oldTemplateData) {
                this.processTemplateData();
            },
            deep: true
        },
        modalData: {
            handler(newVal, oldVal) {
                this.configData = _.cloneDeep(newVal);
                const unit = this.unitSelect = this.configData.axisOptions[this.selectedAxisId]?.unit;
                if (!unit || !this.channelUnits.includes(unit)) {
                    this.unitSelect = 'Custom';
                } else {
                    this.unitSelect = this.configData.axisOptions[this.selectedAxisId].unit;
                }
            }
        },
        configData: {
            deep: true,
            handler(newval, oldval) {
                //check if any values are zero on first component load before assigning configChanged as true:
                if (Object.keys(newval).length !== 0 && Object.keys(this.modalData).length !== 0 && Object.keys(oldval).length !== 0) {
                    if (!_.isEqual(this.modalData, newval)) {
                        this.configChanged = true;
                    } else {
                        this.configChanged = false;
                    }
                } else {
                    this.configChanged = false;
                }
            }
        }
    }
};
</script>
