<template>
<base-kpi-template ref="baseKpiTemplate"
    title="Completion Progress"
    :item="item"
    :inital-options="initalOptions"
    :edit-mode="editMode"
    :create-export-data="createExportData"
    :analytics-data="analyticsData"
    :errors="errors"
    :show-not-enough-data-warning="showNotEnoughDataWarning"
    :show-chart="showChart"

    @analyticsChanges="buildAnalytics()"
    @settingsChanges="buildData()"
    @revertChanges="buildData()"
>
    <template #settings>
        <iws-input
            label="Projected Stages Per Day"
            type="number"
            :value.sync="item.options.progressPerDay[job.jobNumber]"
            step="0.5"
            min="0"
            @change="setupCompletedStagesPerDay()"
            form-spacing
        />

        <iws-checkbox
            label="Full Job"
            :value.sync="item.options.isFullJob"
            form-spacing
            enable-click-label
            @change="onFullJobchange()"
        />
    </template>

    <template #extra-info>
        <div class="extra-info">
            Time Range: {{ getTimeRangeDescription }} <br>
            <template v-if="averageActualStagesPerDay > 0">
                Average stages completed per day: {{ averageActualStagesPerDay }}
            </template>
        </div>
    </template>

    <template v-if="showChart" #content>
        <simple-line-chart
            ref="kpi"
            :chart-data="kpi"
            :options="options"
        />
    </template>
</base-kpi-template>
</template>
    
<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty } = GlobalFunctions;

import DateFunctions from '../../../DateFunctions.js';
const { applyTimeOffset } = DateFunctions;

import constants from './constants.js';
const { DEFAULT_DATE_FORMAT, SECONDARY_DATE_FORMAT, chartStyle } = constants;

const moment = require('moment');

import BaseKpi from './BaseKpi.vue';
import BaseKpiTemplate from './BaseKpiTemplate.vue';

// Typically just one of the below
import SimpleLineChart from '../../../lineChart.js';

export default {
    extends: BaseKpi,
    components: { BaseKpiTemplate, SimpleLineChart },

    data() {
        return {
            ...this.BaseKpi({
                yAxisLabel: 'Stages Completed',
                datasets: [{
                    label: 'Projected Stages Completed',
                    fill: false,
                    data: [],
                    backgroundColor: chartStyle.primaryLineGraphColour,
                    borderColor: chartStyle.primaryLineGraphColour
                }, {
                    label: 'Actual Stages Completed',
                    fill: false,
                    data: [],
                    backgroundColor: chartStyle.primaryBarGraphColour,
                    borderColor: chartStyle.primaryBarGraphColour
                }],

                xTick_callback: value => moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D'),
                legend: {
                    display: true,
                    labels: {
                        fontColor: '#CCC',
                        boxWidth: 15,
                        filter: label => label.datasetIndex < 2 // Projected dataset labels are redundent
                    }
                },

                tooltip_callback: (tooltipItem, data) => {
                    if (tooltipItem.datasetIndex < 2) {
                        const delta = data?.datasets[1].data[tooltipItem.index] - data?.datasets[0].data[tooltipItem.index];

                        // Handle conflicting tooltips
                        if (delta == 0 && tooltipItem.datasetIndex == 0)
                            return '';

                        return `
                            Projected: ${data?.datasets[0].data[tooltipItem.index]}
                            <br>
                            Actual: ${data?.datasets[1].data[tooltipItem.index]}
                            <br>
                            Delta: ${delta > 0 ? '+' : ''}${delta}
                        `;
                    }

                    // Handle conflicting tooltips
                    if (data?.datasets[0]?.data[tooltipItem.index] == data?.datasets[2]?.data[tooltipItem.index])
                        return '';

                    return `
                        Projected: ${data?.datasets[2]?.data[tooltipItem.index] || 'N/A'}
                        <br>
                        Predicted: ${data?.datasets[3]?.data[tooltipItem.index] || 'N/A'}
                    `;
                }
            })
        }
    },

    computed: {
        // Generically named computed properties to handle changes specific to this KPI
        showNotEnoughDataWarning: function() {
            return this.allowShowChart && this.isNotEnoughDataMsgPerKPIType(this.kpi);
        },
        showChart: function() {
            return this.allowShowChart && !isNullOrEmpty(this.kpi?.datasets[0]?.data) && !isNullOrEmpty(this.kpi?.datasets[1]?.data);
        },


        
        averageActualStagesPerDay: function() {
            const numberDifference = [];
            const allFullDays = [ ...(this.kpi.datasets[1].data || []) ];

            if (isNullOrEmpty(allFullDays) || allFullDays.length <= 2)
                return this.item.options.progressPerDay[this.job.jobNumber] || 0;

            // Ignore the first/last days as they're expected to be incomplete working days
            for (let i = 1; i < allFullDays.length-1; i++) {
                const rateOfChange = allFullDays[i] - allFullDays[i - 1];

                // Ignore NaN and non working days
                if (!!rateOfChange && rateOfChange > 0)
                    numberDifference.push(rateOfChange);
            }

            return !isNullOrEmpty(numberDifference) ? this.getAverage(numberDifference).toFixed(2) : 0;
        },
        // Unless the job start time is actually before shift start, we will miss some stages by the time the job actually starts
        // Start time varies between midnight and shift start depending on setting
        firstStagesMissing: function() {
            // Shift start/length depends on graph settings
            let startTime, endTime;
            if (this.item.options.dayType === 'Midnight to midnight') {
                const shifts = this.getShiftStartTimes();
                startTime = shifts.dayShift;
                endTime = shifts.nightShift;
            } else {
                startTime = '00:00:00';
                endTime = '23:59:59';
            }

            const jobStart = applyTimeOffset(this.job.start, this.job.hourOffset, 'HH:mm:ss');
            const shiftLength = moment(endTime, 'HH:mm:ss') - moment(startTime, 'HH:mm:ss');
            const timeMissed = moment(jobStart, 'HH:mm:ss') - moment(startTime, 'HH:mm:ss');
            const missedStages = Math.ceil(this.item.options.progressPerDay[this.job.jobNumber] * (timeMissed / shiftLength));            

            // If timeMissed is negative, the shift started after the job started and we missed 0 stages
            if (timeMissed < 0)
                return 0;

            // Missed stages cannot exceed predicated progress in a single day
            if (missedStages > this.item.options.progressPerDay[this.job.jobNumber])
                return this.item.options.progressPerDay[this.job.jobNumber];

            // Determine how many stages they missed based on how far into day/shift we are so far
            return Math.max(missedStages, 0);
        }
    },

    methods: {
        // Genericly named function for BaseKPI.vue to call on mounted
        // This creates the KPI in a unique way to the current files needs
        setDefaults: function() {
            this.$set(this.item.options, 'zeroStageType', this.item.options.zeroStageType || 'zeroStage-include');
            this.$set(this.item.options, 'dayType', this.item.options.dayType || 'Shift start to shift start');

            // isFullJob is only now being stored in the db, default to true unless it has already been added
            if (!('isFullJob' in this.item.options))
                this.$set(this.item.options, 'isFullJob', true);

            // We are removing filers object and moving this property to the normal options object
            // if does not exist at all, default to an empty hashmap
            if (!('progressPerDay' in this.item.options))
                this.item.options.progressPerDay = {};

            // For backwards compatibilty, if progressPerDay[this.job.jobNumber] does not exist in options, fetch it from the kpisettings
            if (!(this.job.jobNumber in this.item.options.progressPerDay))
                return $.get(`/jobkpisettings/${this.job.id}`).then(kpiSettings => {
                    this.$set(this.item.options.progressPerDay, this.job.jobNumber, kpiSettings?.stagesCompletedProjection || 9.5);
                });
        },
        initKPI: function() {
            // Generic template but this can be whatever
            return this.fetchData().then(this.buildData);
        },
        fetchData: function() {
            return $.get(`/job-kpi-completed-activities/${this.jobNumber}`).then(response => this.data = response);
        },
        buildData: function() {
            this.allowShowChart = false;

            // adding job offset here
            this.dataLookup = this.createLookupByDate(this.data.map(activity => {
                return {
                    ...activity,
                    startTime: moment.utc(activity.startTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}),
                    endTime: moment.utc(activity.endTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}),
                    readableLocalEndTime: moment.utc(activity.endTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}).format(DEFAULT_DATE_FORMAT)
                };
            }));

            if (!isFalsy(this.dataLookup)) {
                if (this.item.options.dayType !== 'Midnight to midnight')
                    this.shiftDatesByStartTime(this.job.shiftStart);
                this.setupCompletedStagesPerDay();
            }

            this.setChartSmartStepSize(this.kpi?.datasets.map(_dataset => _dataset.data).flat(), 10, 1, 20);
            this.allowShowChart = true;

            this.$nextTick(() => {
                // this.createJobTimeAnnotationLines();
                this.buildAnalytics();
            });
        },
        buildAnalytics: function() {
            this.onAnalyticsTypeChange(this.item.options.analyticsType);

            this.buildAnalyticsData();

            this.$nextTick(() => {
                this.assignChartSize();
            });
        },
        createExportData: function() {
            const labels = _.cloneDeep(this.kpi.labels);
            const fields = [ ];

            // Scatter Lines have a different structure, restructure to fit the export function
            const items = this.processExportItems(this.kpi.datasets, labels).map(_list => {
                // Should be a list of exactly two
                if (isNullOrEmpty(_list) || _list.length !== 2)
                    return null;

                return [ ];
            }).filter(_list => !isNullOrEmpty(_list));

            const fileName = this.getExportFilename(this.item.options.type);

            return { 
                fields, 
                items, 
                fileName
            };
        },
        onFullJobchange: function() {
            this.allowShowChart = false;

            if (this.item.options.isFullJob) {
                this.calculatePredictedCompletionRate();
            } else {
                this.kpi.datasets = this.kpi.datasets.slice(0, 2);
                const untilTodayDataLength = this.kpi.datasets[0].data.length;
                this.kpi.labels = this.kpi.labels.slice(0, untilTodayDataLength);
            }
            
            this.$nextTick(() => {
                this.allowShowChart = true;

                this.$nextTick(() => {
                    // this.createJobTimeAnnotationLines();
                    this.buildAnalytics();
                });
            })
        },
        calculatePredictedCompletionRate: function() {
            if (!this.item.options.isFullJob)
                return;

            const progressPerDay = parseInt(this.item.options.progressPerDay[this.job.jobNumber]);
            const projectedArrayLength = this.kpi.datasets[0].data.length;
            const momentLastDate = moment(this.kpi.labels[(this.kpi.labels.length-1)], SECONDARY_DATE_FORMAT);
            const lastProjectedValue = this.kpi.datasets[0].data[this.kpi.datasets[0].data.length-1];
            const dataSet = _.cloneDeep(this.kpi.datasets[1].data);
            const lastActualValue  = dataSet[dataSet.length-1];
            const lastKnownValues = _.cloneDeep(dataSet);

            // Always assume last day is incomplete (we wouldn't be calculating predictions after job completed)
            lastKnownValues.pop();

            const futureLabels = [];
            let futureProjectedArray = [];
            let futureActualValuesArray = [];
            let futureActualValue = lastActualValue;
            let newProjectedValue = lastProjectedValue;

            if (newProjectedValue !== null && progressPerDay > 0)
                while (newProjectedValue < this.totalStages) { //calculation - future projected values
                    futureProjectedArray.push(newProjectedValue);
                    newProjectedValue = newProjectedValue + progressPerDay;
                }

            if (!isNullOrEmpty(futureProjectedArray) && futureProjectedArray[futureProjectedArray.length-1] < this.totalStages)
                futureProjectedArray.push(this.totalStages);

            // Future actual values should account for the stages we haven't had time to complete yet today
            let addOffset = 0;
            if (this.averageActualStagesPerDay > 0) {
                const flooredAverage = Math.floor(this.averageActualStagesPerDay);

                while (futureActualValue < this.totalStages) { //calculation - future predicted values
                    // On the last day, cap the line to the total stagess
                    const adjustedValue = futureActualValue+addOffset;
                    futureActualValuesArray.push(adjustedValue < this.totalStages ? adjustedValue : this.totalStages);

                    // Calculate offset only after the first index to connect the lines
                    if (addOffset === 0 && dataSet.length > 1)
                        addOffset = Math.max(flooredAverage - (dataSet[dataSet.length-1] - dataSet[dataSet.length-2]), 0);

                    futureActualValue = futureActualValue + flooredAverage;
                    lastKnownValues.push(futureActualValue);
                }
            }

            if (!isNullOrEmpty(futureActualValuesArray) && futureActualValuesArray[futureActualValuesArray.length-1] < this.totalStages)
                futureActualValuesArray.push(this.totalStages);

            let newDaysToAdd = futureActualValuesArray.length > futureProjectedArray.length
                ? futureActualValuesArray.length
                : futureProjectedArray.length;

            for (let i = 0; i < newDaysToAdd; i++)
                futureLabels.push(momentLastDate.add(1, 'day').format(SECONDARY_DATE_FORMAT).toString());

            this.futureProgressLabels = futureLabels;
            this.kpi.labels = [...this.kpi.labels, ...futureLabels];
            const currentFilledValues = new Array((projectedArrayLength||1)-1).fill(null);
            futureProjectedArray = [...currentFilledValues, ...futureProjectedArray];
            futureActualValuesArray =  [...currentFilledValues, ...futureActualValuesArray];

            if (!isFalsy(futureProjectedArray.find(_val => _val !== null)))
                this.kpi.datasets.push({
                    backgroundColor: chartStyle.primaryLineGraphColour,
                    borderColor: chartStyle.primaryLineGraphColour,
                    borderDash: [3,5],
                    data: futureProjectedArray,
                    label: 'Predicted Stages Completed',
                    fill: false
                });

            if (!isFalsy(futureActualValuesArray.find(_val => _val !== null)))
                this.kpi.datasets.push({
                    backgroundColor: chartStyle.primaryBarGraphColour,
                    borderColor: chartStyle.primaryBarGraphColour,
                    data: futureActualValuesArray,
                    borderDash: [3,5],
                    fill: false,
                    label: 'Predicted Completion Rate',
                    labelColor: '#CCCCCC'
                });

            this.$refs?.$data?._chart?.update();
        },
        setupCompletedStagesPerDay: function() {
            let totalStagesCompleted = 0;

            this.kpi.labels = Object.keys(this.dataLookup).sort();
            this.kpi.datasets = [{ label: chartStyle.yAxisLabel, backgroundColor: chartStyle.primaryBarGraphColour, data: [] }];

            for (const key of this.kpi.labels) {
                this.kpi.datasets[0].data.push(this.dataLookup[key].length);
                totalStagesCompleted += this.dataLookup[key].length;
            }

            if (!this.job?.start) { return; }
            
            let kpiDataDays = this.kpi.labels.map(n => moment(parseInt(n)).utc().format('YYYY-MM-DD').toString());

            // only back fill the graph if we havn't completed all the stages
            if (totalStagesCompleted < this.totalStages) {
                function getDates(startDate, stopDate) {
                    var dateArray = [];
                    var currentDate = moment(startDate);
                    var stopDate = moment(stopDate);
                    while (currentDate <= stopDate) {
                        dateArray.push( moment(currentDate) )
                        currentDate = moment(currentDate).add({days: 1});
                    }
                    return dateArray;
                }

                const endDate = isFalsy(this.job.end)
                    ? moment.utc().add({ hours: this.job.hourOffset }).startOf('day')
                    : moment.utc(this.job.end).add({ hours: this.job.hourOffset }).startOf('day');
                let startDate = moment.utc(this.job.start).add({ hours: this.job.hourOffset }).startOf('day');
                let dateRange = getDates(startDate, endDate); //returns days between job start and end or job start and present day

                //adds zeroes to datasets and labels to make sure kpi chart starts at job start and ends at job end or present day
                for(let i = 0; i < dateRange.length; i++) {
                    dateRange = dateRange.reverse();
                    if (moment(dateRange[i]).isBefore(moment(kpiDataDays[0]), 'days')) {
                        kpiDataDays.splice(0, 0, dateRange[i]);
                        for (let index = 0; index < this.kpi.datasets.length; index++) {
                            this.kpi.datasets[index].data.splice(0, 0, 0);
                        }
                    }

                    dateRange = dateRange.reverse();
                    if (moment(dateRange[i]).isAfter(moment(kpiDataDays[kpiDataDays.length-1]), 'days')) {
                        kpiDataDays.push(dateRange[i]);
                        for (let index = 0; index < this.kpi.datasets.length; index++) {
                            this.kpi.datasets[index].data.splice(kpiDataDays.length-1, 0, 0);
                        }
                    }
                }
            }

            this.kpi.labels = kpiDataDays.map(t => {
                const isDayShiftSelected = this.dayTypes[0] && this.item.options.dayType === this.dayTypes[0];
                const timestamp = moment.utc(t)
                
                if (isDayShiftSelected) {
                    const { hours, minutes } = this.getShiftStartTimeInHourAndMinutes()
                    timestamp.hour(hours).minute(minutes);
                }

                return timestamp.valueOf().toString();
            });

            this.analyticDatasets = _.cloneDeep(this.kpi.datasets);

            let completionProgressLabels =  _.cloneDeep(this.kpi?.labels);
            let completionProgressData = _.cloneDeep(this.kpi.datasets[0]?.data);
            this.completionProgressData = completionProgressData;

            const remainingToGenerate = 10 - this.kpi.labels.length;

            if (!isNullOrEmpty(this.kpi.labels) && !isNullOrEmpty(this.kpi.datasets)) {
                for (let i=0; i < remainingToGenerate; i++) {
                    const lastLabel = parseInt(this.kpi.labels[this.kpi.labels.length - 1]);
                    const appendedLabel = (lastLabel + parseInt(8.64e+7)).toString();
                    this.kpi.labels.push(appendedLabel);

                    for (let index = 0; index < this.kpi.datasets.length; index++)
                        this.kpi.datasets[index].data.push(0);
                }
            }

            // Preserve timestamps in default format for export operations
            this.kpi.defaultFormatLabels = this.kpi.labels.map(t => moment.utc(parseInt(t)).format(DEFAULT_DATE_FORMAT).toString());
            this.kpi.labels = this.kpi.labels.map(t => moment(parseInt(t)).utc().format(SECONDARY_DATE_FORMAT).toString());

            const completionProgressDefaultFormatLabels = completionProgressLabels.map(t => moment.utc(parseInt(t)).format(DEFAULT_DATE_FORMAT).toString())
            completionProgressLabels = completionProgressLabels.map(t => moment(parseInt(t)).utc().format(SECONDARY_DATE_FORMAT).toString());

            if (completionProgressData) {
                this.kpi = {
                    labels: completionProgressLabels, // for now share
                    defaultFormatLabels: completionProgressDefaultFormatLabels,
                    datasets: [{
                        label: 'Projected Stages Completed',
                        labelColor: '#CCCCCC',
                        fill: false,
                        // below line creates the projected data array by adding 0's until we hit the point in the array that we have completed stages
                        // completed stages are calculated as 9.5 * days since first stage completed.
                        data: completionProgressData.map((v, i) => {
                            const multiplier = this.item.options.progressPerDay[this.job.jobNumber];
                            const projectedValue = ((i+1)*multiplier) - this.firstStagesMissing;

                            // In case it happens, filter out negative values
                            if (projectedValue > 0)
                                // Cap the line to the total stages
                                return projectedValue < this.totalStages ? projectedValue : this.totalStages;
                            return 0;
                        }),
                        backgroundColor: chartStyle.primaryLineGraphColour,
                        borderColor: chartStyle.primaryLineGraphColour
                    }, {
                        label: 'Actual Stages Completed',
                        labelColor: '#CCCCCC',
                        fill: false,
                        backgroundColor: chartStyle.primaryBarGraphColour,
                        borderColor: chartStyle.primaryBarGraphColour,
                        data: completionProgressData.reduce((acc, current, index) => [...acc, current + (acc[index-1] || 0)], []) // creates a new array with a running cumulative sum
                    }]
                };
            }
            
            this.calculatePredictedCompletionRate(true);
        }
    }
}
</script>