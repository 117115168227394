<template>
    <div id="UserPermissionsCompany">
        <div class="h4">
            {{ customer.name }}
        </div>
        <div v-if="!isIwsAdmin" class="h6">
            Please contact your admin if you think this information is incorrect
        </div>

        <hr>

        <template v-if="jobs === null">
            Loading...
        </template>
        <div v-else-if="jobs.length === 0" class="h6">
            No Jobs
        </div>
        <template v-else>
            <template v-if="records === null">
                Loading...
            </template>
            <div v-else-if="records.length === 0" class="h6">
                No Restrictions. Click below to limit this users api access.
            </div>
            <template v-else>
                <div v-for="(record, index) in records" :key="record.id" :class="{ 'add-space': !!index }">
                    <div class="row" :key="customer.id+record.id">
                        <div class="col-4">
                            <iws-select
                                label="Work Order"
                                :value="record.workOrders ? record.workOrders : 'All Jobs'"
                                :options="jobs"
                                display-name="jobNumber"
                                value-name="jobNumber"
                                :multiselect="false"
                                :maintain-on-select="false"
                                @change="handleEvent(record, $event)"

                                :disabled="!isIwsAdmin"

                                class="mb-3"
                                :key="customer.id+record.id+'jobNumber'"
                            />

                            <div class="sub-title">
                                Select All Jobs to grant access to all jobs within {{ customer.name }}
                            </div>
                        </div>
                        <div class="col-2 text-center">
                            <label>API Only Access</label>
                            <span v-if="!JSON.parse(user.dashboard_permissions).includes('API')">
                                <input  v-model="record.api_access" type="checkbox"  class="checkbox style-2 pull-right" :disabled="true" v-tooltip:top="'API access is disabled for user'" />
                            </span>
                            <span v-else>
                                <input  v-model="record.api_access" type="checkbox"  class="checkbox style-2 pull-right"/>
                            </span>

                        </div>

                        <div class="col-3">
                            <label>Start</label>

                            <b-form-datepicker
                                v-model="record.start_date"
                                :reset-button="true"
                                @input="handleDateChange($event, record, 'start')"
                                :disabled="!isIwsAdmin"
                                class="mb-3"

                                :max="!!record.end_date ? new Date(record.end_date) : null"
                                :key="customer.id+record.id+'start_date'"
                            />

                            <div class="sub-title">
                                Access to Work Order from this day
                            </div>
                        </div>

                        <div class="col-3">
                            <label>End</label>

                            <b-form-datepicker
                                v-model="record.end_date"
                                :reset-button="true"
                                @input="handleDateChange($event, record, 'end')"
                                :disabled="!isIwsAdmin"
                                class="mb-3"

                                :min="!!record.start_date ? new Date(record.start_date) : null"
                                :key="customer.id+record.id+'end_date'"
                            />

                            <div class="sub-title">
                                Leave blank to never expire
                            </div>
                        </div>

                        <iws-button type="outline-danger" @click="deleteRecord(record, index)" :disabled="!isIwsAdmin">
                            <template #text>
                                <i class="fas fa-trash-alt"></i>
                            </template>
                        </iws-button>
                    </div>

                     <iws-button data-toggle="collapse" :href="`#moreDetails_${customer.id}_${record.id}`"
                        :text="moreDetailsText(record)"
                        :type="moreDetailsColour(record)"
                    />
                     <div class="collapse accordian-parent" :id="`moreDetails_${customer.id}_${record.id}`">
                        <div class="card">
                            <iws-table :columns="moreDetailsCols" :items="mapJobNumbers(record.workOrders)">
                                <template #cell_jobNumber="{ data }">
                                    {{ data.item.jobNumber }}
                                </template>
                                <template #cell_start="{ data }">
                                    {{ _dateTimeDisplay(data.item.start) || 'N/A' }}
                                </template>

                                <template #cell_end="{ data }">
                                    {{ _dateTimeDisplay(data.item.end) || 'N/A' }}
                                </template>

                                <template #cell_location="{ data }">
                                    {{ _truncateStr(data.item.location, 50) }}
                                </template>
                            </iws-table>
                        </div>
                    </div>
                </div>
            </template>

            <iws-button v-if="isIwsAdmin && records !== null" type="primary" @click="addRecord()">
                <template #text>
                    <i class="fas fa-plus"></i>
                    Add
                </template>
            </iws-button>
        </template>
    </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { getCSRFToken, isTruthy, isNullOrEmpty, toast, truncateStr } = GlobalFunctions;

import DateFunctions from '../DateFunctions.js';
const { dateDisplay, dateTimeDisplay, compareDates } = DateFunctions;

import eventBus from '../eventBus';

export default {
    props: {
        user: {
            type: Object,
            required: true
        },
        customer: {
            type: Object,
            required: true
        },
        isIwsAdmin:{
            type: [Boolean, Number],
            required: true
        },
    },

    data: () => ({
        jobs: null,
        jobMap: {},
        records: null,

        moreDetailsCols: [
            {
                key: 'jobNumber',
                label: 'Job Number'
            },  {
                key: 'start',
                label: 'Start'
            },  {
                key: 'end',
                label: 'End'
            },  {
                key: 'location',
                label: 'Pad'
            },
        ]
    }),

    computed: {
        conflictedJobs() {
            if (isNullOrEmpty(this.jobs))
                return [];
            if (isNullOrEmpty(this.records))
                return [];

            // Create a hashmap of all records that wouldn't actually allow their selected jobs because of date restrictions
            const map = {};
            this.records.forEach(record => {
                if(record.workOrders === 'All Jobs'){
                    const conflictingjobs = this.jobs.filter(job => {
                    //if the job.workOrders is 'All Jobs', no need to check for conflicts
                    if (job.jobNumber === 'All Jobs'){
                        return false;
                    }

                    // If the record has job restrictions and does not include this job, it fails
                    if (!isTruthy(record.workOrders) && !(record.workOrders == job.jobNumber)){
                        return false;
                    }

                    // If the record restricts start date, make sure the job start date is after the api access restrictions
                    if (isTruthy(record.start_date) && compareDates(record.start_date, job.start) < 0){
                        return true;
                    }

                    // If the record restricts end date, make sure the job end date is before the api access restrictions
                    // Jobs that have not ended (ongoing) are an exception to this restriction
                    if (isTruthy(record.end_date) && isTruthy(job.end) && compareDates(record.end_date, job.end) > 0){
                        return true;

                    }

                    // Passed conflicts
                    return false;
                    });

                    if (!isNullOrEmpty(conflictingjobs)){
                    map[record.id] = conflictingjobs;
                    }
                }else{
                    //get the job from jobs and check if it conflicts with record
                    const job = this.jobs.find(job => job.jobNumber === record.workOrders);
                    if (!isNullOrEmpty(job)){
                        if (isTruthy(record.start_date) && compareDates(record.start_date, job.start) < 0){
                            map[record.id] = [job];
                        } else if (isTruthy(record.end_date) && isTruthy(job.end) && compareDates(record.end_date, job.end) > 0){
                            map[record.id] = [job];
                        }
                    }
                }

            });

            return map;
        },
        filteredJobs(){
            //filter out jobs that are in records
            return this.jobs.filter(job => {
                return !this.records.some(record => {
                    return record.workOrders === job.jobNumber;
                });
            });
        }
    },

    methods: {
        _isNullOrEmpty(val) { return isNullOrEmpty(val); },
        _dateDisplay(val) { return dateDisplay(val); },
        _dateTimeDisplay(val) { return dateTimeDisplay(val); },
        _truncateStr(text, count) { return truncateStr(text, count); },

        markAsDirty(record) {
            this.$set(record, 'isDirty', true);
        },
        handleEvent(record, value) {
            //check if this.records has 'All Jobs' option
            if(record.id === null){
                let selectAllIndex = this.records.findIndex((record) => record.workOrders === 'All Jobs');
                if(selectAllIndex !== -1){
                    toast({
                        title: 'Grant all access',
                        body: 'You have selected to grant all access to this user. Please remove the "All Jobs" option to select individual jobs',
                        variant: 'danger'
                    });
                    //delete the newly added record from this.records
                    this.records.splice(this.records.length - 1, 1);
                    return;
                }
                if(value === 'All Jobs' && this.records.length > 1){
                    toast({
                        title: 'Grant all access',
                        body: 'You have selected to grant all access to this user. Please remove all other jobs to grant all access',
                        variant: 'danger'
                    });
                    //delete the newly added record from this.records
                    this.records.splice(this.records.length - 1, 1);
                    return;
                }
            }
            // Mark as dirty, aka has changes needing saved
            this.markAsDirty(record);
            let index = this.records.findIndex((record) => record.workOrders === value);
            if(index === -1){
                record.workOrders = value;
            }else{
                toast({
                    title: 'Job already selected',
                    body: 'This job is already selected',
                    variant: 'danger'
                });
            }
        },

        handleDateChange($event, record, dateOption) {
            this.markAsDirty(record);

            if (isTruthy($event)) {
                // When a date was selected, attach hours to the dates to support proper inclusive date options
                // Both dates are inclusive
                if (dateOption == 'start') {
                    record.start_date+=' 00:00:00.000';
                } else {
                    record.end_date+=' 23:59:59.000';
                }
            } else {
                // When resetting dates, Bootstrap datepicker returns an empty string, I would rather have it actually be null
                if (dateOption == 'start') {
                    record.start_date = null;
                } else {
                    record.end_date = null;
                }
            }
        },

        saveRecord(record) {

            return $.ajax({
                url: '/apiControl',
                method: !!record.id && record.id !== null ? 'PUT' : 'POST',
                data: {
                    '_token': getCSRFToken(),
                    id: !!record.id && record.id !== null ? record.id : null,
                    userId: this.user.id,
                    customerId: this.customer.id,
                    workOrders: record.workOrders,
                    startDate: record.start_date,
                    endDate: record.end_date,
                    api_access: record.api_access
                },
                dataType: 'json'
            }).then(_response => {
                this.fetchRecords();
                toast({
                    title: 'Record Saved',
                    body: 'The record has been saved',
                    variant: 'success'
                });
            }).fail(error => {
                toast({
                    title: 'Failed to save changes',
                    body: error.responseJSON.message,
                    variant: 'danger'
                });
            });
        },
        deleteRecord(record, index) {
            return GlobalFunctions.iwsConfirm({
                title: 'Are you sure?',
                body: 'Are you sure you delete to this record?',
                confirmColour: 'danger'
            }).then(_answer => {
                if (_answer) {
                    if (!!record.id && record.id !== null) {
                        // If the record has an id, delete it from the database
                        return $.ajax({
                            url: '/apiControl',
                            method: 'DELETE',
                            data: {
                                '_token': getCSRFToken(),
                                id: record.id
                            },
                            dataType: 'json'
                        }).then(_response => {
                            this.fetchRecords();
                            toast({
                                title: 'Record Deleted',
                                body: 'The record has been deleted',
                                variant: 'success'
                            });
                        }).fail(error => {
                            toast({
                                title: 'Failed to delete',
                                body: error.responseJSON.message,
                                variant: 'danger'
                            });
                        });
                    } else {
                        // If no id is set, it only exists in the frontend
                        this.records.splice(index, 1);
                    }
                }
            });
        },
        submitForm() {
            let selectJobIndex = this.records.findIndex((record) => record.workOrders === 'Select Job');
            if(selectJobIndex !== -1){
                this.records.splice(selectJobIndex, 1);
            }
            this.records.forEach(_record => {
                this.saveRecord(_record);
            });


        },

        addRecord() {
            this.records.push({
                // New records need unique ids (before new records would share conflicts as the id meant to act as a unique identifier)
                id: null,
                workOrders: 'Select Job',
                startDate: null,
                endDate: null,
                userId: this.user.id,
                customerId: this.customer.id,
                api_access: false
            });
        },

        fetchJobs() {
            this.jobs = null;

            return $.post(
                '/getJobsByCustomer',
                {
                    '_token': getCSRFToken(),
                    customerId: this.customer.id,
                    getWells: false
                },
                'json'
            ).then(_response => {
                this.jobs = _response;
                this.jobs.unshift({jobNumber: 'All Jobs', id: 'all'});
                //iterate over jobs and add a new field with value as jobNumber +  status
                this.jobMap = new Map(this.jobs.map(obj => [obj.jobNumber, obj]));
            }).fail(_ => {
                this.jobs = [];
                this.jobMap = {};
                toast({
                    title: 'Failed to find jobs for: '+this.user.name,
                    variant: 'danger'
                });
            });
        },
        fetchRecords() {
            this.records = null;

            return $.get(
                '/apiControl',
                {
                    '_token': getCSRFToken(),
                    customerId: this.customer.id,
                    userId: this.user.id
                },
                'json'
            ).then(_response => {
                this.records = (_response || []);
            }).fail(_ => {
                this.records = [];
                toast({
                    title: 'Failed to find records for: '+this.customer.name,
                    variant: 'danger'
                });
            });
        },

        mapJobNumbers(workOrders) {
           if(workOrders === 'All Jobs'){
                //filter jobs and remove 'All Jobs' option
                return this.jobs.filter(job => job.jobNumber !== 'All Jobs');
           }
            return this.jobs.filter(job => job.jobNumber === workOrders);

        },
        moreDetailsText(record) {
            return 'More Details';
        },
        moreDetailsColour(record) {
            const conflictedJobsCount = this.conflictedJobs[record.id]?.length || 0;
            const activeJobs = !isNullOrEmpty(record.workOrders) ? record.workOrders : this.jobs;

            // Change the colour of the more details button to draw more and more attention depending on how many of the selected jobs are actually accessible
            if (conflictedJobsCount == 0)
                return 'outline-primary';
            if (conflictedJobsCount < activeJobs.length)
                return 'outline-warning';
            return 'outline-danger';
        },
    },

    mounted() {
        this.fetchJobs();
        this.fetchRecords();
        eventBus.$on('submitPermissions', () => {
            if(this.records.length > 0)
                this.submitForm();
        });
    }
};
</script>

<style>
    #UserPermissionsCompany .dropdown-menu.show {
        background: #343A40;
        border: 1px solid #676E78 !important;
        color: white !important;
    }
    #UserPermissionsCompany select option {
        background: #343A40;
    }
    #UserPermissionsCompany .b-calendar-grid {
        background: #343A40;
    }
    #UserPermissionsCompany .b-calendar-grid-caption,
    #UserPermissionsCompany .b-calendar-grid-weekdays {
        color: white !important;
    }
</style>
<style scoped>
    #UserPermissionsCompany {
        border: 1px solid #676E78;
        border-radius: 5px;

        padding: 15px 20px
    }

    .row {
        position: relative;

        margin-top: 10px;
        padding-right: 60px
    }
    .row label {
        display: block;
    }
    .row .sub-title {
        font-size: 12px;
        font-weight: 400;
        color: grey;

        position: relative;
        top: -10px
    }

    .row input,
    .row select {
        width: 100%;
        height: 40px;
    }

    .row > button {
        position: absolute;
        right: 15px;
        top: 28px;
        height: 40px;
    }

    .btn.btn-primary {
        margin-top: 20px;
    }

    hr {
        background-color: #676E78;
    }

    label {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
    }

    .add-space {
        margin-top: 40px;
    }
    .show-warning {
        color: var(--danger-color) !important;
    }

    .accordian-parent .card {
        margin-top: 15px;
    }
    input[type="checkbox"] {
    -webkit-appearance: none !important;
    -moz-appearance: none    !important;
    appearance: none !important;
    display: inline-block !important;
    width: 30px !important;
    height: 30px !important;
    background-clip: content-box !important;
    border: 1px solid #D0D5DD !important;
    background-color: #343A40 !important;
    border-radius: 3px !important;
    transition: all 0.3s ease-in-out 0s !important;
    cursor: pointer     !important;
    margin-right: 10px   !important;
    margin-top:3px !important;
    padding: 0px !important;
    margin-left: 10px !important;
    vertical-align: middle
}
/* style the checked state */
 input[type="checkbox"]:checked {
    border: 1px solid #29a5ff !important;

}
 input[type="checkbox"]:checked::before {
    display: block;
    text-align: center;
    line-height: 28px;
    font-size: 30px;
    content: '✓' !important;
    color: #29a5ff !important;
}
</style>
