var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-0 d-flex flex-column pb-1" }, [
    _vm.wireline.placed
      ? _c(
          "div",
          {
            staticClass:
              "px-0 mx-0 col-12 border border-secondary rounded mb-2 mt-2",
            style: {
              "background-color": _vm.well.color,
              color: _vm.getTitleColorForBG(_vm.well.color),
            },
          },
          [
            _c("b", [
              _vm._v(
                "Wireline Perforating - " +
                  _vm._s(_vm.well.name) +
                  " " +
                  _vm._s(_vm.well.currentStage) +
                  "/" +
                  _vm._s(_vm.well.numberOfStages)
              ),
            ]),
          ]
        )
      : _c("div", { staticClass: "col-12 rounded mb-2 mt-2 bg-secondary" }, [
          _c("b", [_vm._v("Wireline not currently placed on well")]),
        ]),
    _vm._v(" "),
    _c("div", { staticClass: "mx-0 row justify-content-between" }, [
      _c(
        "div",
        {
          staticClass: "col-4 text-center border border-secondary rounded mb-1",
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0 mt-1",
              staticStyle: { "font-size": "0.7em", "line-height": "0.7" },
            },
            [_vm._v("Diameter (in)")]
          ),
          _vm._v(
            "\n            " + _vm._s(_vm.wireline.diameter) + "\n        "
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-4 text-center border border-secondary rounded mb-1",
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0 mt-1",
              staticStyle: { "font-size": "0.7em", "line-height": "0.7" },
            },
            [_vm._v("Depth (ft)")]
          ),
          _vm._v("\n            " + _vm._s(_vm.wireline.depth) + "\n        "),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "col-4 text-center border border-secondary rounded mb-1",
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0 mt-1",
              staticStyle: { "font-size": "0.7em", "line-height": "0.7" },
            },
            [_vm._v("Duration")]
          ),
          _vm._v(" "),
          _vm.wireline.placed
            ? _c("span", [_vm._v(_vm._s(_vm.wireline.duration))])
            : _vm._e(),
          _vm._v(" "),
          !_vm.wireline.placed ? _c("span", [_vm._v("n/a")]) : _vm._e(),
        ]
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "mx-0 row justify-content-between" }, [
      _c(
        "div",
        {
          staticClass:
            "col-sm-12 col-md-12 col-lg-4 text-center border border-secondary rounded mb-1",
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0 mt-1",
              staticStyle: { "font-size": "0.7em", "line-height": "0.7" },
            },
            [_vm._v("Tension (lbs)")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                ref: "tensionGauge",
                staticClass: "col flex-column pl-0 pr-0 pt-2",
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(this.wireline.tension.toString()) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-sm-12 col-md-12 col-lg-4 text-center border border-secondary rounded mb-1",
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0 mt-1",
              staticStyle: { "font-size": "0.7em", "line-height": "0.7" },
            },
            [_vm._v("Speed (ft/min)")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                ref: "speedGauge",
                staticClass: "col flex-column pl-0 pr-0 pt-2",
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(this.wireline.speed.toString()) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-sm-12 col-md-12 col-lg-4 text-center border border-secondary rounded mb-1",
        },
        [
          _c(
            "p",
            {
              staticClass: "mb-0 mt-1",
              staticStyle: { "font-size": "0.7em", "line-height": "0.7" },
            },
            [_vm._v("Pumpdown (psi)")]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              {
                ref: "pumpdownGauge",
                staticClass: "col flex-column pl-0 pr-0 pt-2",
              },
              [
                _vm._v(
                  "\n                    " +
                    _vm._s(this.pressurePumpdown.toString()) +
                    "\n                "
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }