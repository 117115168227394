<template>
    <div class="dark-modal modal" id="MetadataTopLevelPadsModal" ref="MetadataTopLevelPadsModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        Select Pads
                    </h5>
    
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
        
                <div v-if="system !== null" class="modal-body">
                    <div v-if="fields !== null" style="margin-bottom: 20px">
                        <iws-select
                            label="Field"
                            :value.sync="selectedField"
                            :options="fields"
                            :display-name="(field) => field.data.name"
                            value-name="id"
                            @change="getPads"
                        />
                    </div>

                    <div v-if="pads !== null" style="margin-bottom: 20px">
                        <iws-select
                            label="Pads"
                            :value="selectedPads"
                            :options="pads"
                            :display-name="(field) => field.data.name"
                            value-name="id"
                            @change="handlePadSelection"
                            :maintain-on-select="true"
                            :multiselect="true"
                        />
                    </div>

                    <iws-alert type="info">
                        <template #body>
                            <template v-if="selectedField === null">
                                Please select a field to see pads
                            </template>
                            <template v-else>
                                Please select the pad(s) for this field
                            </template>
                        </template>
                    </iws-alert>
                </div>
    
                <div class="modal-footer">
                    <button class="btn btn-outline-light" id="cancel_button">
                        Cancel
                    </button>
    
                    <iws-button text="Save" :click="onSaveClick" />
                </div>
            </div>
        </div>
    </div>
</template>
        
        
<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, toast, getCSRFToken } = GlobalFunctions;

export default {
    props: {
        fieldTypeId: String,
        padTypeId: String,
    },

    data: () => ({
        system: null,
        
        fields: null,
        pads: null,

        selectedField: null,
        selectedPads: []
    }),

    methods: {
        open(system) {
            const modalElem = $('#MetadataTopLevelPadsModal');
            this.system = system;
            
            return $.get(`metadataChildren/${this.fieldTypeId}/${system.data.customer.id}`).then(_fields => {
                for (const field of _fields)
                    field.data = JSON.parse(field.data);
                this.fields = _fields;

                if (!isFalsy(system?.data?.pads))
                    this.selectedPads = system.data.pads.split(',');

                return new Promise((resolve, reject) => {
                    modalElem.show();
                    this.resolve = (val) => {
                        this.system = null;
                        this.fields = null;
                        this.pads = null;
                        this.selectedField = null;
                        this.selectedPads = [];

                        modalElem.hide();
                        resolve(val);
                    }
                });
            });
        },
        handlePadSelection(selectedPad) {
            // If selectedPads is somehow in an invalid state, init to empty list
            if (this.selectedPads === null)
                this.selectedPads = [];

            // If not already selected, push to end of the list
            // If already selected, unselect
            const index = this.selectedPads.findIndex(_pad => _pad === selectedPad);
            if (isFalsy(index) || index < 0) {
                this.selectedPads.push(selectedPad);
            } else {
                this.selectedPads.splice(index, 1);
            }
        },

        getPads() {
            if (isFalsy(this.selectedField))
                return toast({
                    title: 'Must have a field selected',
                    variant: 'error'
                });
            
            this.pads = [];

            return $.get(`metadataChildren/${this.padTypeId}/${this.selectedField}`).then(_pads => {
                for (const pad of _pads)
                    pad.data = JSON.parse(pad.data);
                this.pads = _pads;
            });
        },

        onSaveClick() {
            if (isFalsy(this.system))
                return toast({
                    title: 'Must have a system selected',
                    variant: 'error'
                });

            this.system.data.pads = this.selectedPads.join(',');
            this.system.data._token = getCSRFToken();

            return $.post(`/metadata/system/${this.system.id}`, this.system.data, 'json').then(_res => {
                this.resolve(_res);
            }).catch(_error => {
                return toast({
                    title: 'Failed to save changes',
                    variant: 'error'
                });
            });
        }
    },

    mounted() {
        $('#MetadataTopLevelPadsModal')?.find('#cancel_button, .close').on('click', () => {
            this.resolve(null);
        });
    }
};
</script>
    
<style scoped>
    .modal-dialog {
        width: 400px;
    }
    .modal-body {
        min-height: 400px;
        position: relative;
    }

    .modal-body .alert {
        position: absolute;
        bottom: 0px;

        width: calc(100% - 2rem);
    }
</style>