<template>
	<div class="card main-container mb-2" style="width:100%;">
		<div class="card-body">
			<p class="card-title text-secondary">{{ dateUpdated }} by {{ user }} <span v-if="isEditable" class="link-txt-color pointer-cursor" @click="()=>onEditPress()">{{ enableTextArea ? 'Save' : 'Edit' }}</span></p>
			<p v-if="!enableTextArea" class="card-text dark-text">{{ description }}</p>
			<textarea v-else class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="description" ></textarea>
		</div>
	</div>
</template>
<style scoped>
	.main-container {
      background-color: transparent;
			border-radius: 0px
  }
	.dark-text { 
		color : #343a40;
	}
	.div-border {
		border-width: 1px;
		border-color: #000;
	}
	.green-button {
		background-color: #5cc55b;
		border-width: 0px;
		min-width: 150px;
	}
	.grey-button {
		background-color: #8e8e8e;
		border-width: 0px;
		min-width: 150px;
	}
	.pointer-cursor {
			cursor: pointer; 
	}
   
</style>

<script>
    export default {
				props : ['commentInfo', 'isEditable', 'onSavePressed'],
				mounted(){
					const {
						user,
						description,
						updated_at
					} = this.commentInfo;

					this.user = user;
					this.dateUpdated = updated_at;
					this.description = description;
				},
				data(){
					return {
						user : null,
						description : null,
						dateUpdated : null,
						enableTextArea : false
					}
				},
				methods: {
					onEditPress(){
						if(this.enableTextArea){
							const commentObject = {
								id : this.commentInfo.id,
								wellNumber : this.commentInfo.wellNumber,
								stageNumber : this.commentInfo.stageNumber,
								description : this.description
							}

							this.onSavePressed && this.onSavePressed(commentObject)
						}
						this.enableTextArea = !this.enableTextArea;
					},
				},
    }
</script>