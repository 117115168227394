var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "my-modal",
    attrs: {
      size: "xl",
      "hide-header-close": "",
      "header-class": "border-bottom-0 justify-content-center  pb-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg",
      "no-close-on-backdrop": "",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "font-large" }, [
              _vm._v(
                _vm._s(
                  _vm.modalTextOptions("New Project", _vm.dashboardInfo.name)
                )
              ),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "b-card",
              {
                staticClass: "inner-bg round border-bottom-0",
                attrs: { title: "Enter Project Name", "title-tag": "h5" },
              },
              [
                _vm.error
                  ? _c("div", { staticClass: "text-left pb-2" }, [
                      _c("span", { staticStyle: { color: "red" } }, [
                        _vm._v("Project name cannot be empty"),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.projectName,
                      expression: "projectName",
                    },
                  ],
                  staticClass: "form-control",
                  domProps: { value: _vm.projectName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.projectName = $event.target.value
                    },
                  },
                }),
              ]
            ),
            _vm._v(" "),
            _vm.iwsUser
              ? _c(
                  "div",
                  [
                    _c(
                      "b-card",
                      {
                        staticClass: "inner-bg round border-bottom-0",
                        attrs: {
                          title: "Select Company Name",
                          "title-tag": "h5",
                        },
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.company,
                                expression: "company",
                              },
                            ],
                            class: { "form-control": true },
                            attrs: { id: "company" },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.company = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                            },
                          },
                          _vm._l(_vm.customers, function (cust) {
                            return _c(
                              "option",
                              { key: cust.id, domProps: { value: cust.id } },
                              [_vm._v(_vm._s(cust.name))]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex w-100" }, [
              _c("div", { staticClass: "flex-grow-1" }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary grey-button",
                  attrs: { type: "button" },
                  on: { click: _vm.dismissModal },
                },
                [_vm._v(_vm._s(_vm.modalTextOptions("Back", "Cancel")))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "px-2" }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-success green-button",
                  attrs: { type: "button", disabled: _vm.isFetching },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.onCreateOrSavePressed.apply(null, arguments)
                    },
                  },
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "col-4" }, [
                      _vm.isFetching
                        ? _c("span", {
                            staticClass:
                              "spinner-border spinner-border-sm pr-2",
                            attrs: { role: "status", "aria-hidden": "true" },
                          })
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("div", [
                      _vm._v(
                        "\n                        " +
                          _vm._s(_vm.modalTextOptions("Create", "Save")) +
                          "\n                    "
                      ),
                    ]),
                  ]),
                ]
              ),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.show,
      callback: function ($$v) {
        _vm.show = $$v
      },
      expression: "show",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }