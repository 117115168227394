var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      title: "Add Camera",
      "primary-button-text": "Add Camera",
      showModal: _vm.showBaseModal,
      "max-width": "500px",
    },
    on: {
      close: _vm.cancelAction,
      "secondary-action": _vm.cancelAction,
      "primary-action": _vm.confirmAction,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("iws-input", {
              ref: "ipAddress",
              attrs: { value: _vm.data.ipAddress, label: "IP", required: "" },
              on: {
                "update:value": function ($event) {
                  return _vm.$set(_vm.data, "ipAddress", $event)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "row", staticStyle: { "margin-top": "30px" } },
              [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("iws-input", {
                      ref: "onvifUsername",
                      attrs: {
                        value: _vm.data.onvifUsername,
                        label: "Username",
                        required: "",
                        autocomplete: "username",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.data, "onvifUsername", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("iws-input", {
                      ref: "onvifPassword",
                      attrs: {
                        value: _vm.data.onvifPassword,
                        label: "Password",
                        type: "password",
                        required: "",
                        autocomplete: "new-password",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.data, "onvifPassword", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }