<template>
    <div class="mt-2">
        <div v-for="(requirement, key) in passwordRequirements" :key="key">
            <span :class="requirement.value? 'requirement valid' : 'requirement invalid'">
                <b-icon :icon="requirement.value ? 'check2-all' : 'x'"></b-icon>
                {{ requirement.description }}
            </span>
        </div>
    </div>
</template>
<script>

export default {
    props: ['password', 'currentPassword', 'firstName', 'lastName', 'email', 'passwordResetEmail'],
    computed: {
        passwordRequirements() {
            const dependencies = [this.password, this.currentPassword, this.firstName, this.lastName, this.email];
            const passReq = [
                {
                    description: 'Must not contain white spaces',
                    value: false,
                    key: 'whiteSpace'
                },
                {
                    description: 'Must have a minimum of 10 characters',
                    value: false,
                    key: 'length'
                },
                {
                    description: 'Cannot contain the user account name',
                    value: false,
                    key: 'accountName'
                },
                {
                    description: 'Must have at least one uppercase character',
                    value: false,
                    key: 'upperCase'
                },
                {
                    description: 'Must have at least one lowercase character',
                    value: false,
                    key: 'lowerCase'
                },
                {
                    description: 'Must have at least one number',
                    value: false,
                    key: 'number'
                },
                {
                    description: 'Must have at least one special character',
                    value: false,
                    key: 'specialCharacter'
                },
            ];
            if (!this.passwordResetEmail && (this.currentPassword || this.currentPassword === '')) {
                passReq.push({
                    description: 'Cannot be the same as the current password',
                    value: false,
                    key: 'current'
                })
            }
            if (!this.password) return passReq;
            passReq.forEach(req => {
                switch(req.key) {
                    case 'whiteSpace':
                        req.value = /\s/g.test(this.password) ? false : true;
                        break;
                    case 'length':
                        req.value = this.password.length >= 10 ? true : false;
                        break;
                    case 'current':
                        req.value = this.password !== this.currentPassword ? true : false;
                        break;
                    case 'accountName':
                        req.value = this.containsCurrentAccountName() ? false : true;
                        break;
                    case 'upperCase':
                        req.value = /[A-Z]/.test(this.password) ? true : false;
                        break;
                    case 'lowerCase':
                        req.value = /[a-z]/.test(this.password) ? true : false;
                        break;
                    case 'number':
                        req.value = /[0-9]/.test(this.password) ? true : false;
                        break;
                    case 'specialCharacter':
                        req.value = /[^a-zA-Z0-9]/.test(this.password) ? true : false;
                        break;
                }
            })

            return passReq;
        }
    },
    methods: {
        containsCurrentAccountName() {
            let filterStrings = []
            if (this.firstName) {
                const firstName = this.firstName.replace(/\s/g, "");
                filterStrings.push(firstName);
            }
            if (this.lastName) {
                const lastName = this.lastName.replace(/\s/g, "");
                filterStrings.push(lastName);
            }
            if (this.email) {
                const email = this.email.split('@')[0];
                filterStrings.push(email);
            }
            const regex = new RegExp(filterStrings.join( "|" ), "i");
            return regex.test(this.password);
        }
    },
    watch: {
        passwordRequirements: {
            deep: true,
            handler(newVal) {
                const requirementsMet = newVal.every(req => req.value);
                this.$emit('requirementsMet', requirementsMet);
            }
        }
    }
}
</script>
<style scoped>
    .requirement {
        font-size: small;
    }
    .requirement.valid {
        color: #5cb85c;
    }
    .requirement.invalid {
        color: #d9534f;
    }
</style>