<template>
	<div class="video-scribber-wrapper">
		<span class="control-container">
			<iws-button type="outline-light" @click.stop="!paused ? onPauseClicked() : onPlayClicked()">
				<template #text>
					<b-icon :icon="!paused ? 'pause' : 'play'"></b-icon>
				</template>
			</iws-button>

			<iws-button type="outline-light" @click.stop="!muted ? mutePlayer() : unMutePlayer()">
				<template #text>
					<b-icon :icon="!muted ? 'volume-up' : 'volume-mute'"></b-icon>
				</template>
			</iws-button>
		</span>

		<span>
			<template v-if="!liveMode">
				<span>
					{{ makeTimeReadable(sliderTime) }} / 24:00
				</span>
				<VueSlider style="width: calc(100% - 110px) !important"
					v-model.number="sliderTime"
					:max="sliderMaxValue"
					:tooltip-formatter="val => makeTimeReadable(val)"
					:marks="scrubberTicks[vodSelected]"
					@change="onSliderValueChanged"
				/>
			</template>
			<template v-else-if="!!camera.llhls">
				<span>
					{{ makeLiveTimeReadable(sliderTime) }}
				</span>

				<VueSlider
					v-model.number="sliderTime"
					:max="dvrDuration"
					direction="rtl"
					:tooltip-formatter="val => makeLiveTimeReadable(val)"
					@change="onSliderValueChanged"
				/>
			</template>
		</span>

		<span class="control-container">
			<iws-button type="outline-light" @click.stop="enterFullScreen" style="margin-left: 55px;">
				<template #text>
					<i class="fas fa-expand"></i>
				</template>
			</iws-button>
		</span>
	</div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css'

const SECONDS_IN_DAY = 86400;
const SECONDS_IN_HOUR = 3600;
const SECONDS_IN_MINUTE = 60;

export default {
	name: 'VideoScrubber',
	components: { VueSlider },

	props: [
		'workOrderId',
		'camera',
		'sliderTime',
		'paused',
		'muted',
		'sliderMaxValue',
		'scrubberTicks',
		'dvrDuration',
		'vodSelected',
	],

	data: () => ({
		timer: null
	}),
	computed: {
		liveMode() {
			return this.vodSelected === 'Live';
		}
	},

	methods: {
		debounce(func, timeout=500) {
			clearTimeout(this.timer);
			this.timer = setTimeout(func, timeout);
		},
		
		onSliderValueChanged() {
			// If you hold and drag the slider, it was originally scrubbing every change which was just causing a ridiculous amount of calls
			// Use debouce to only do scrubbing once the dragging is down for at least half a second
			return this.debounce(() => {
				this.$emit('update:sliderTime', this.sliderTime);
				this.$parent.handleScrubberTimeJump();
			});
		},
		enterFullScreen() {
			this.$parent.enterFullScreen();
		},

		makeTimeReadable(time) {
			const numHours = Math.floor(time / SECONDS_IN_HOUR);
			const numMinutes = Math.floor(Math.floor(time % SECONDS_IN_HOUR) / SECONDS_IN_MINUTE);
			return `${numHours.toString().padStart(2, '0')}:${numMinutes.toString().padStart(2, '0')}`;
		},
		makeLiveTimeReadable(time) {
			const strTime = this.makeTimeReadable(time);
			return strTime === '00:00' ? 'Live' : `-${strTime}`;
		},
		onPauseClicked() {
			this.$parent.pausePlayer();
		},
		onPlayClicked() {
			this.$parent.playPlayer();
		},
		mutePlayer() {
			this.$parent.mutePlayer();
		},
		unMutePlayer() {
			this.$parent.unMutePlayer();
		}
	}
};
</script>

<style scoped>
	.video-scribber-wrapper {
		position: absolute;
		bottom: 0px;
		padding: 5px;
		width: 100%;

		background-color: rgba(16, 24, 40, .30);
	}

	.control-container {
		display: inline-block;
		width: 100px;
	}

	.control-container>.btn {
		width: 45px;
		border: none !important;
	}

	.video-scribber-wrapper>span:not(.control-container) {
		display: inline-block;
		width: calc(100% - 210px);
		padding: 0px 5px;
	}

	.video-scribber-wrapper>span:not(.control-container)>span {
		display: inline-block;
		margin: 0px 5px;
	}

	.vue-slider {
		width: calc(100% - 60px) !important;
		display: inline-block !important;

		position: relative;
		top: 5px;
	}
</style>
