<!--
    * Component Description
    A re-usable confirmation modal

    * Side Effects
    The 'GlobalFunctions.iwsConfirm' function will return a promise resovled by returning 1 of 3 different options
        1. true:    the confirm button was pressed
        2. false:   the reject button was pressed
        3. null:    the close button or if persistent is false, outside the bounds of the modal was pressed to cancel (i.e. neither option was selected)

    * Example Usage
        Trigger programmatically via JS
            GlobalFunctions.iwsConfirm({
                title: 'Confirm Changes',
                body: 'Are you sure you want to save changes?'
            }).then(_answer => {
                // ...Do something
            })

        The open function takes an object detailing the behaviour/appearance of the confirm, with several defaults
            {
                title: null,                    // Reccommended that title is always used, body is optional for more details
                body: null,

                confirmText: 'Yes',
                confirmColour: 'primary',

                rejectText: 'No',
                rejectColour: 'secondary',

                persistent: false,              // When true, users will only be able to dismiss the modal by pressing the confirm, reject, or close buttons (not outside the bounds)
                width: '500px'
            }
-->

<template>
<div class="dark-modal modal" :id="`iws_global_confirm_modal_${id}`" :ref="`iws_global_confirm_modal_${id}`" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document" :style="`max-width: ${details.width}`">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    {{ details.title }}
                </h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div v-if="!!details.body" class="modal-body">
                <div class="modal-text" v-html="details.body">
                </div>
            </div>

            <div class="modal-footer">
                <button v-if="details.allowReject" :class="`btn btn-outline-${details.rejectColour}`" id="reject-button" data-dismiss="modal">
                    {{ details.rejectText }}
                </button>

                <button :class="`btn btn-${details.confirmColour}`" id="confirm-button" data-dismiss="modal" data-response="ok">
                    {{ details.confirmText }}
                </button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import eventBus from "../../eventBus";

const initDetails = () => {
    return {
        title: null,
        body: null,

        confirmText: 'Yes',
        confirmColour: 'primary',

        rejectText: 'No',
        rejectColour: 'light',
        allowReject: true,

        persistent: false,
        width: '500px'
    }
}

export default {
    props: {
        id: {
            type: String,
            required: true,
        }
    },
    data: () => ({
        details: initDetails()
    }),

    methods: {
        open(details) {
            // Combine modal default properties with provided, prioritizing the given details
            this.details = {
                ...initDetails(),
                ...details
            };

            var modalElem = $(`#iws_global_confirm_modal_${this.id}`);
            const ref = this.$refs[`iws_global_confirm_modal_${this.id}`];

            function initModal(modalElem, confirmCallback, rejectCallback, cancelCallback) {
                modalElem.show();
                
                modalElem.find('#confirm-button').on('click', function() {
                    modalElem.hide();
                    confirmCallback();
                });
                modalElem.find('#reject-button').on('click', function() {
                    modalElem.hide();
                    rejectCallback();
                });
                modalElem.find('.close').on('click', function() {
                    modalElem.hide();
                    cancelCallback();
                });
                if (details.persistent !== true)
                    modalElem.on('click', function(event) {
                        // when caller does not specify the modal to be persistent,
                        // users can click the background to cancel out
                        if (ref === event.target) {
                            modalElem.hide();
                            cancelCallback();
                        }
                    });
            }

            return new Promise( (resolve, reject) => {
                initModal(modalElem,
                    () => resolve(true),
                    () => resolve(false),
                    () => resolve(null)
                );
            });
        },

        globalOpen(details, uuid) {
            this.open(details).then(answer => {
                eventBus.$emit('iws-global-confirm-close', { answer, uuid });
            })
        }
    },

    mounted() {
        // GlobalFunctions.iwsConfirm triggers this
        eventBus.$on('iws-global-confirm-open', (details, uuid) => {
            this.globalOpen(details, uuid)
        });
    }
};
</script>

<style scoped>
    .modal-header {
        border-bottom: none !important;

        font-weight: 600;
        font-size: 18px;
        line-height: 28px;

        color: var(--primary-text-color);
    }
    .modal-body {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;

        color: var(--primary-text-color);

        padding: 0px 1rem !important;
    }
    .modal-footer {
        border-top: none !important;
    }

    #reject-button {
        width: 80px;
        max-width: 40%;
    }
    #confirm-button {
        width: 100px;
        max-width: 40%;
    }
</style>