var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "analysisDefaultsModal",
    attrs: {
      size: "xl",
      "no-close-on-backdrop": "",
      "header-class": "border-bottom-0 justify-content-center  p-0",
      "body-class": "pb-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg",
    },
    on: {
      hidden: function ($event) {
        return _vm.$emit("onDismiss")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "col w-100" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "font-large w-100 text-center" }, [
                  _vm._v("Edit Analysis Quick Selects"),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c(
                  "b-overlay",
                  {
                    attrs: {
                      show: _vm.isSaving || _vm.isLoading,
                      rounded: "sm",
                    },
                  },
                  [
                    _c(
                      "b-card",
                      { attrs: { "no-body": "" } },
                      [
                        _c(
                          "b-tabs",
                          { attrs: { pills: "", card: "", vertical: "" } },
                          [
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: "Stage Comparison",
                                  active:
                                    _vm.initialSelectedComponent.name ==
                                    "stage-comparison-chart",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setActiveTab($event)
                                  },
                                },
                              },
                              [
                                _c("b-card-text", [
                                  _c(
                                    "div",
                                    { staticClass: "w-100 d-flex flex-column" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 justify-content-between",
                                        },
                                        [
                                          _c("div", { staticClass: "w-50" }, [
                                            _c("label", [
                                              _vm._v("Default Pad:"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "pr-2 mw-100" },
                                              [
                                                _c("multi-select", {
                                                  key: _vm.refreshTicker,
                                                  attrs: {
                                                    id: "selectedPads",
                                                    options: _vm.selectablePads,
                                                    value:
                                                      _vm.stageComparisonOptions
                                                        .pads,
                                                    disabled:
                                                      _vm.selectablePads ==
                                                      null,
                                                    label: "fullPadName",
                                                    showSelectedOptions: true,
                                                    sortByKey:
                                                      _vm.sortPadDropdownByKeyStageComparison,
                                                    isReverseSort:
                                                      _vm.isReverseSortStageComparison,
                                                  },
                                                  on: {
                                                    selectedupdated:
                                                      _vm.multiPadsSelected,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "mt-1 mb-2",
                                                    attrs: {
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.sortByJobStartDateStageComparison,
                                                      callback: function ($$v) {
                                                        _vm.sortByJobStartDateStageComparison =
                                                          $$v
                                                      },
                                                      expression:
                                                        "sortByJobStartDateStageComparison",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Sort by Job Start Date\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("div", { staticClass: "w-50" }, [
                                            _c("label", [
                                              _vm._v("Default Wells:"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "pl-1 pr-1",
                                                staticStyle: {
                                                  "max-width": "100%",
                                                },
                                              },
                                              [
                                                _c("multi-select", {
                                                  attrs: {
                                                    id: "selectedWells",
                                                    options:
                                                      _vm.selectableWells || [],
                                                    value:
                                                      _vm.stageComparisonOptions
                                                        .wells || [],
                                                    hasSubLabels: true,
                                                    disabled: false,
                                                    label: "name",
                                                    showSelectedOptions: true,
                                                  },
                                                  on: {
                                                    selectedupdated:
                                                      _vm.multiWellsSelected,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 justify-content-between",
                                        },
                                        [
                                          _c("div", { staticClass: "w-50" }, [
                                            _c("label", [
                                              _vm._v("Index Data By:"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "select",
                                              {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value:
                                                      _vm.stageComparisonOptions
                                                        .index,
                                                    expression:
                                                      "stageComparisonOptions.index",
                                                  },
                                                ],
                                                staticClass:
                                                  "multiSelectStyle w-100",
                                                staticStyle: {
                                                  "text-overflow": "ellipsis",
                                                  "max-width": "100%",
                                                },
                                                on: {
                                                  change: [
                                                    function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.stageComparisonOptions,
                                                        "index",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                    function ($event) {
                                                      _vm.getChannelsForJobs(
                                                        _vm.stageComparisonOptions.pads.map(
                                                          (pad) => pad.jobNumber
                                                        ),
                                                        _vm
                                                          .stageComparisonOptions
                                                          .index
                                                      )
                                                    },
                                                  ],
                                                },
                                              },
                                              [
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      selected: "",
                                                      value: "Place Frac",
                                                    },
                                                  },
                                                  [_vm._v("Place Frac")]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "option",
                                                  {
                                                    attrs: {
                                                      value: "Place Wireline",
                                                    },
                                                  },
                                                  [_vm._v("Place Wireline")]
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "w-50 ml-2" },
                                            [
                                              _c("label", [
                                                _vm._v("Aggregate Interval:"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .stageComparisonOptions
                                                          .aggregateInterval,
                                                      expression:
                                                        "stageComparisonOptions.aggregateInterval",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "multiSelectStyle w-100",
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.stageComparisonOptions,
                                                        "aggregateInterval",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.analysisDashboardData
                                                    .aggregateOptions,
                                                  function (option, index) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: index,
                                                        domProps: {
                                                          value: option.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(option.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("checkbox-list", {
                                            staticClass: "w-100",
                                            attrs: {
                                              enableSelectAllOption: true,
                                              enableSearch: true,
                                              label:
                                                "Component Default Channels",
                                              valueKey: "prioritizedTagName",
                                              outputKey: "name",
                                              height: "400",
                                              options: _vm.allChannels,
                                              disabledOptions:
                                                _vm.getDisabledChannelOptions,
                                              selectedOptions:
                                                _vm.getSelectedChannelNames(),
                                              isDark: false,
                                            },
                                            on: {
                                              "update:selectedOptions":
                                                function ($event) {
                                                  return _vm.updateSelectedChannels(
                                                    $event
                                                  )
                                                },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c(
                                            "b-form-checkbox",
                                            {
                                              model: {
                                                value:
                                                  _vm.stageComparisonOptions
                                                    .loadLatestStage,
                                                callback: function ($$v) {
                                                  _vm.$set(
                                                    _vm.stageComparisonOptions,
                                                    "loadLatestStage",
                                                    $$v
                                                  )
                                                },
                                                expression:
                                                  "stageComparisonOptions.loadLatestStage",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                        Load Latest Complete Stage\n                                        "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tab",
                              {
                                attrs: {
                                  title: "Multi-well Comparison",
                                  active:
                                    _vm.initialSelectedComponent.name ==
                                    "well-comparison",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.setActiveTab($event)
                                  },
                                },
                              },
                              [
                                _c("b-card-text", [
                                  _c(
                                    "div",
                                    { staticClass: "w-100 d-flex flex-column" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "d-flex w-100 justify-content-between",
                                        },
                                        [
                                          _c("div", { staticClass: "w-50" }, [
                                            _c("label", [
                                              _vm._v("Default Pad:"),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "mw-100" },
                                              [
                                                _c("multi-select", {
                                                  key: _vm.refreshTicker,
                                                  attrs: {
                                                    id: "selectedPads",
                                                    options: _vm.selectablePads,
                                                    value:
                                                      _vm
                                                        .multiWellComparisonOptions
                                                        .pads,
                                                    multiSelect: false,
                                                    placeholderText: "Pick One",
                                                    disabled:
                                                      _vm.selectablePads ==
                                                      null,
                                                    label: "fullPadName",
                                                    showSelectedOptions: true,
                                                    sortByKey:
                                                      _vm.sortPadDropdownByKeyMultiWell,
                                                    isReverseSort:
                                                      _vm.isReverseSortMultiWell,
                                                  },
                                                  on: {
                                                    selectedupdated:
                                                      _vm.singlePadSelected,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(
                                                  "b-form-checkbox",
                                                  {
                                                    staticClass: "mt-1 mb-2",
                                                    attrs: {
                                                      value: true,
                                                      "unchecked-value": false,
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.sortByJobStartDateMultiWell,
                                                      callback: function ($$v) {
                                                        _vm.sortByJobStartDateMultiWell =
                                                          $$v
                                                      },
                                                      expression:
                                                        "sortByJobStartDateMultiWell",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                                Sort by Job Start Date\n                                            "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "w-50 ml-2" },
                                            [
                                              _c("label", [
                                                _vm._v("Aggregate Interval:"),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "select",
                                                {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model",
                                                      value:
                                                        _vm
                                                          .multiWellComparisonOptions
                                                          .aggregateInterval,
                                                      expression:
                                                        "multiWellComparisonOptions.aggregateInterval",
                                                    },
                                                  ],
                                                  staticClass:
                                                    "multiSelectStyle w-100",
                                                  on: {
                                                    change: function ($event) {
                                                      var $$selectedVal =
                                                        Array.prototype.filter
                                                          .call(
                                                            $event.target
                                                              .options,
                                                            function (o) {
                                                              return o.selected
                                                            }
                                                          )
                                                          .map(function (o) {
                                                            var val =
                                                              "_value" in o
                                                                ? o._value
                                                                : o.value
                                                            return val
                                                          })
                                                      _vm.$set(
                                                        _vm.multiWellComparisonOptions,
                                                        "aggregateInterval",
                                                        $event.target.multiple
                                                          ? $$selectedVal
                                                          : $$selectedVal[0]
                                                      )
                                                    },
                                                  },
                                                },
                                                _vm._l(
                                                  _vm.analysisDashboardData
                                                    .aggregateOptions,
                                                  function (option, index) {
                                                    return _c(
                                                      "option",
                                                      {
                                                        key: index,
                                                        domProps: {
                                                          value: option.value,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(option.name)
                                                        ),
                                                      ]
                                                    )
                                                  }
                                                ),
                                                0
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("checkbox-list", {
                                            staticClass: "w-100",
                                            attrs: {
                                              enableSelectAllOption: true,
                                              enableSearch: true,
                                              label:
                                                "Component Default Channels",
                                              valueKey: "prioritizedTagName",
                                              outputKey: "name",
                                              height: "400",
                                              options: _vm.allChannels,
                                              disabledOptions:
                                                _vm.getDisabledChannelOptions,
                                              selectedOptions:
                                                _vm.getSelectedChannelNames(),
                                              isDark: false,
                                            },
                                            on: {
                                              "update:selectedOptions":
                                                function ($event) {
                                                  return _vm.updateSelectedChannels(
                                                    $event
                                                  )
                                                },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        { staticClass: "mt-2" },
                                        [
                                          _c("b-form-group", {
                                            attrs: { label: "" },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function ({
                                                  ariaDescribedby,
                                                }) {
                                                  return [
                                                    _c(
                                                      "b-form-radio-group",
                                                      {
                                                        attrs: {
                                                          stacked: "",
                                                          id: "radio-group-2",
                                                          "aria-describedby":
                                                            ariaDescribedby,
                                                          name: "radio-sub-component",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm
                                                              .multiWellComparisonOptions
                                                              .timeType,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.multiWellComparisonOptions,
                                                              "timeType",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "multiWellComparisonOptions.timeType",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-radio",
                                                          {
                                                            attrs: {
                                                              value: "current",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                Current, load past\n                                                "
                                                            ),
                                                            _c("input", {
                                                              directives: [
                                                                {
                                                                  name: "model",
                                                                  rawName:
                                                                    "v-model.number",
                                                                  value:
                                                                    _vm
                                                                      .multiWellComparisonOptions
                                                                      .pastHours,
                                                                  expression:
                                                                    "multiWellComparisonOptions.pastHours",
                                                                  modifiers: {
                                                                    number: true,
                                                                  },
                                                                },
                                                              ],
                                                              staticStyle: {
                                                                "max-width":
                                                                  "50px",
                                                              },
                                                              attrs: {
                                                                type: "number",
                                                              },
                                                              domProps: {
                                                                value:
                                                                  _vm
                                                                    .multiWellComparisonOptions
                                                                    .pastHours,
                                                              },
                                                              on: {
                                                                input:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    if (
                                                                      $event
                                                                        .target
                                                                        .composing
                                                                    )
                                                                      return
                                                                    _vm.$set(
                                                                      _vm.multiWellComparisonOptions,
                                                                      "pastHours",
                                                                      _vm._n(
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    )
                                                                  },
                                                                blur: function (
                                                                  $event
                                                                ) {
                                                                  return _vm.$forceUpdate()
                                                                },
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                                                hours\n                                            "
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-form-radio",
                                                          {
                                                            attrs: {
                                                              value: "stage",
                                                            },
                                                          },
                                                          [
                                                            _vm._v(
                                                              "Last Completed Stage of \n                                                "
                                                            ),
                                                            _vm._v(" "),
                                                            _vm.selectedComponentType ==
                                                              "well-comparison" &&
                                                            _vm.selectableWells
                                                              .length > 0
                                                              ? _c(
                                                                  "select",
                                                                  {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model",
                                                                          value:
                                                                            _vm
                                                                              .multiWellComparisonOptions
                                                                              .well,
                                                                          expression:
                                                                            "multiWellComparisonOptions.well",
                                                                        },
                                                                      ],
                                                                    on: {
                                                                      change:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          var $$selectedVal =
                                                                            Array.prototype.filter
                                                                              .call(
                                                                                $event
                                                                                  .target
                                                                                  .options,
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  return o.selected
                                                                                }
                                                                              )
                                                                              .map(
                                                                                function (
                                                                                  o
                                                                                ) {
                                                                                  var val =
                                                                                    "_value" in
                                                                                    o
                                                                                      ? o._value
                                                                                      : o.value
                                                                                  return val
                                                                                }
                                                                              )
                                                                          _vm.$set(
                                                                            _vm.multiWellComparisonOptions,
                                                                            "well",
                                                                            $event
                                                                              .target
                                                                              .multiple
                                                                              ? $$selectedVal
                                                                              : $$selectedVal[0]
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  _vm._l(
                                                                    _vm
                                                                      .selectableWells[0]
                                                                      .valueArray,
                                                                    function (
                                                                      well,
                                                                      index
                                                                    ) {
                                                                      return _c(
                                                                        "option",
                                                                        {
                                                                          key: index,
                                                                          domProps:
                                                                            {
                                                                              value:
                                                                                well,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            _vm._s(
                                                                              well.nameLong
                                                                            )
                                                                          ),
                                                                        ]
                                                                      )
                                                                    }
                                                                  ),
                                                                  0
                                                                )
                                                              : _vm._e(),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.isFeatureFlagged("HEATMAPS")
                              ? _c(
                                  "b-tab",
                                  {
                                    attrs: {
                                      title: "Stage Summary Heat Map",
                                      active:
                                        _vm.initialSelectedComponent.name ==
                                        "stage-summary-heatmap",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.setActiveTab($event)
                                      },
                                    },
                                  },
                                  [
                                    _c("b-card-text", [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "w-100 d-flex flex-column",
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex w-100 justify-content-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-50" },
                                                [
                                                  _c("label", [
                                                    _vm._v("Default Pad:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "mw-100 pr-2",
                                                    },
                                                    [
                                                      _c("multi-select", {
                                                        key: _vm.refreshTicker,
                                                        attrs: {
                                                          id: "selectedPads",
                                                          options:
                                                            _vm.selectablePads,
                                                          value:
                                                            _vm.heatmapOptions
                                                              .pads,
                                                          multiSelect: false,
                                                          placeholderText:
                                                            "Pick One",
                                                          disabled:
                                                            _vm.selectablePads ==
                                                            null,
                                                          label: "fullPadName",
                                                          showSelectedOptions: true,
                                                          sortByKey:
                                                            _vm.sortPadDropdownByKeyHeatMap,
                                                          isReverseSort:
                                                            _vm.isReverseSortHeatMap,
                                                        },
                                                        on: {
                                                          selectedupdated:
                                                            _vm.singlePadSelected,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    [
                                                      _c(
                                                        "b-form-checkbox",
                                                        {
                                                          staticClass:
                                                            "mt-1 mb-2",
                                                          attrs: {
                                                            value: true,
                                                            "unchecked-value": false,
                                                          },
                                                          model: {
                                                            value:
                                                              _vm.sortByJobStartDateHeatMap,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.sortByJobStartDateHeatMap =
                                                                $$v
                                                            },
                                                            expression:
                                                              "sortByJobStartDateHeatMap",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                Sort by Job Start Date\n                                            "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "w-50 ml-2" },
                                                [
                                                  _c("label", [
                                                    _vm._v("Default Wells:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pl-1 pr-1",
                                                      staticStyle: {
                                                        "max-width": "100%",
                                                      },
                                                    },
                                                    [
                                                      _c("multi-select", {
                                                        attrs: {
                                                          id: "selectedWells",
                                                          options:
                                                            _vm.selectableWells,
                                                          value:
                                                            _vm.heatmapOptions
                                                              .wells,
                                                          hasSubLabels: true,
                                                          disabled: false,
                                                          label: "name",
                                                          showSelectedOptions: true,
                                                        },
                                                        on: {
                                                          selectedupdated:
                                                            _vm.multiWellsSelected,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex w-100 justify-content-between mt-2",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-50" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "Default Stage Summary Channel:"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "max-width": "100%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .heatmapOptions
                                                                  .summaryChannels,
                                                              expression:
                                                                "heatmapOptions.summaryChannels",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "multiSelectStyle w-100",
                                                          staticStyle: {
                                                            "text-overflow":
                                                              "ellipsis",
                                                            "max-width": "100%",
                                                          },
                                                          on: {
                                                            change: [
                                                              function (
                                                                $event
                                                              ) {
                                                                var $$selectedVal =
                                                                  Array.prototype.filter
                                                                    .call(
                                                                      $event
                                                                        .target
                                                                        .options,
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        return o.selected
                                                                      }
                                                                    )
                                                                    .map(
                                                                      function (
                                                                        o
                                                                      ) {
                                                                        var val =
                                                                          "_value" in
                                                                          o
                                                                            ? o._value
                                                                            : o.value
                                                                        return val
                                                                      }
                                                                    )
                                                                _vm.$set(
                                                                  _vm.heatmapOptions,
                                                                  "summaryChannels",
                                                                  $event.target
                                                                    .multiple
                                                                    ? $$selectedVal
                                                                    : $$selectedVal[0]
                                                                )
                                                              },
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.onChannelSelect()
                                                              },
                                                            ],
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.selectableStageSummaryChannels,
                                                          function (
                                                            summaryChannel
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: summaryChannel.tagName,
                                                                domProps: {
                                                                  value:
                                                                    summaryChannel,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    summaryChannel.displayName
                                                                  ) +
                                                                    "                                                  \n                                                "
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                { staticClass: "w-50 ml-2" },
                                                [
                                                  _c("label", [
                                                    _vm._v(
                                                      "Default Expression:"
                                                    ),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass: "pl-1 pr-1",
                                                      staticStyle: {
                                                        "max-width": "100%",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "select",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm
                                                                  .heatmapOptions
                                                                  .expression,
                                                              expression:
                                                                "heatmapOptions.expression",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "multiSelectStyle w-100",
                                                          staticStyle: {
                                                            "text-overflow":
                                                              "ellipsis",
                                                            "max-width": "100%",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$selectedVal =
                                                                Array.prototype.filter
                                                                  .call(
                                                                    $event
                                                                      .target
                                                                      .options,
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      return o.selected
                                                                    }
                                                                  )
                                                                  .map(
                                                                    function (
                                                                      o
                                                                    ) {
                                                                      var val =
                                                                        "_value" in
                                                                        o
                                                                          ? o._value
                                                                          : o.value
                                                                      return val
                                                                    }
                                                                  )
                                                              _vm.$set(
                                                                _vm.heatmapOptions,
                                                                "expression",
                                                                $event.target
                                                                  .multiple
                                                                  ? $$selectedVal
                                                                  : $$selectedVal[0]
                                                              )
                                                            },
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.selectableExpressions,
                                                          function (
                                                            expression,
                                                            index
                                                          ) {
                                                            return _c(
                                                              "option",
                                                              {
                                                                key: index,
                                                                domProps: {
                                                                  value:
                                                                    expression,
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.getExpressionDisplayName(
                                                                      expression
                                                                    )
                                                                  )
                                                                ),
                                                              ]
                                                            )
                                                          }
                                                        ),
                                                        0
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "d-flex w-100 justify-content-between",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "w-50" },
                                                [
                                                  _c("label", [
                                                    _vm._v("Default Category:"),
                                                  ]),
                                                  _vm._v(" "),
                                                  _c(
                                                    "select",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "model",
                                                          rawName: "v-model",
                                                          value:
                                                            _vm.heatmapOptions
                                                              .category,
                                                          expression:
                                                            "heatmapOptions.category",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "multiSelectStyle w-100",
                                                      staticStyle: {
                                                        "text-overflow":
                                                          "ellipsis",
                                                        "max-width": "100%",
                                                      },
                                                      on: {
                                                        change: function (
                                                          $event
                                                        ) {
                                                          var $$selectedVal =
                                                            Array.prototype.filter
                                                              .call(
                                                                $event.target
                                                                  .options,
                                                                function (o) {
                                                                  return o.selected
                                                                }
                                                              )
                                                              .map(function (
                                                                o
                                                              ) {
                                                                var val =
                                                                  "_value" in o
                                                                    ? o._value
                                                                    : o.value
                                                                return val
                                                              })
                                                          _vm.$set(
                                                            _vm.heatmapOptions,
                                                            "category",
                                                            $event.target
                                                              .multiple
                                                              ? $$selectedVal
                                                              : $$selectedVal[0]
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "option",
                                                        {
                                                          attrs: {
                                                            selected: "",
                                                          },
                                                          domProps: {
                                                            value: "stage_frac",
                                                          },
                                                        },
                                                        [_vm._v("Frac")]
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "option",
                                                        {
                                                          domProps: {
                                                            value:
                                                              "stage_wireline",
                                                          },
                                                        },
                                                        [_vm._v("Wireline")]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "mt-2" },
                                            [
                                              _c("b-form-group", {
                                                attrs: { label: "" },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "default",
                                                      fn: function ({
                                                        ariaDescribedby,
                                                      }) {
                                                        return [
                                                          _c(
                                                            "b-form-radio-group",
                                                            {
                                                              attrs: {
                                                                stacked: "",
                                                                id: "radio-group-2",
                                                                "aria-describedby":
                                                                  ariaDescribedby,
                                                                name: "radio-sub-component",
                                                              },
                                                              model: {
                                                                value:
                                                                  _vm
                                                                    .heatmapOptions
                                                                    .stageSelect,
                                                                callback:
                                                                  function (
                                                                    $$v
                                                                  ) {
                                                                    _vm.$set(
                                                                      _vm.heatmapOptions,
                                                                      "stageSelect",
                                                                      $$v
                                                                    )
                                                                  },
                                                                expression:
                                                                  "heatmapOptions.stageSelect",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "latestStages",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                                                Load last\n                                                "
                                                                  ),
                                                                  _c("input", {
                                                                    directives:
                                                                      [
                                                                        {
                                                                          name: "model",
                                                                          rawName:
                                                                            "v-model.number",
                                                                          value:
                                                                            _vm
                                                                              .heatmapOptions
                                                                              .latestStageAmount,
                                                                          expression:
                                                                            "heatmapOptions.latestStageAmount",
                                                                          modifiers:
                                                                            {
                                                                              number: true,
                                                                            },
                                                                        },
                                                                      ],
                                                                    staticStyle:
                                                                      {
                                                                        "max-width":
                                                                          "50px",
                                                                      },
                                                                    attrs: {
                                                                      type: "number",
                                                                    },
                                                                    domProps: {
                                                                      value:
                                                                        _vm
                                                                          .heatmapOptions
                                                                          .latestStageAmount,
                                                                    },
                                                                    on: {
                                                                      input:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          if (
                                                                            $event
                                                                              .target
                                                                              .composing
                                                                          )
                                                                            return
                                                                          _vm.$set(
                                                                            _vm.heatmapOptions,
                                                                            "latestStageAmount",
                                                                            _vm._n(
                                                                              $event
                                                                                .target
                                                                                .value
                                                                            )
                                                                          )
                                                                        },
                                                                      blur: function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.$forceUpdate()
                                                                      },
                                                                    },
                                                                  }),
                                                                  _vm._v(
                                                                    "\n                                                stages\n                                            "
                                                                  ),
                                                                ]
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "b-form-radio",
                                                                {
                                                                  attrs: {
                                                                    value:
                                                                      "all",
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "All Stages"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  4060642327
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex justify-content-between w-100" }, [
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-1",
                    on: {
                      click: function ($event) {
                        return _vm.resetSelections(_vm.selectedComponentType)
                      },
                    },
                  },
                  [_vm._v("Reset")]
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary mr-1",
                    on: {
                      click: function ($event) {
                        return _vm.$emit("onDismiss")
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success green-button",
                    on: {
                      click: function ($event) {
                        return _vm.saveDefaultTemplate()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "Save " +
                        _vm._s(
                          _vm.tabTitle
                            ? _vm.tabTitle
                            : _vm.initialSelectedComponent.friendlyName
                        ) +
                        " Defaults"
                    ),
                  ]
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v
      },
      expression: "modalVisible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }