<template>
  <div class="text-light p-1 pt-3 pb-3" 
    :class="[clicked ? 'bg-secondary' : 'bg-primary']"
    style="margin-right:3px;margin-bottom:3px;display:inline;text-align:center;cursor:pointer;" 
    :style="keyStyle()"
    @click="click()"
    unselectable="on"
    onselectstart="return false">
    <span v-if="shift">{{secondary}}</span>
    <span v-else>{{primary}}</span>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        clicked:false
      }
    },
    props:['primary','shift','secondary','width','ml'],
    methods:{
      click(){
        var self = this;
        var val = this.primary;
        if(this.shift)
          val = this.secondary;
        this.$emit('key',val);
        this.clicked = true;
        setTimeout(function(){
          self.clicked = false;
        },100)
      },
      keyStyle(){
        return {
          width:this.width+'px',
          'margin-left':this.width * parseFloat(this.ml) + 'px'
        }
      }
    }
  }
</script>