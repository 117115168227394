var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row",
      staticStyle: { "font-size": "small", "line-height": "0.5" },
    },
    [
      _c(
        "div",
        { staticClass: "col-12" },
        _vm._l(_vm.messages, function (message, index) {
          return _c("whiteboard-message-component", {
            key: index,
            attrs: { message: message, index: index },
          })
        }),
        1
      ),
      _vm._v(" "),
      _vm.sandWeights.length > 0
        ? _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("bar-chart", {
                attrs: {
                  height: 555,
                  "chart-data": _vm.sandChartData,
                  options: _vm.chartOptions(
                    "Proppant - Total: " +
                      _vm.formatNumber(_vm.weightsTotal(_vm.sandWeights), 0) +
                      " lbs (" +
                      _vm.sandWeightAllPercent +
                      "%)"
                  ),
                },
              }),
              _vm._v(" "),
              _c(
                "table",
                {
                  staticClass: "table table-striped table-hover",
                  class: { "table-dark": this.$root.darkMode },
                },
                [
                  _c(
                    "tbody",
                    _vm._l(_vm.sandWeights, function (sandWeight, index) {
                      return _c("tr", { key: index }, [
                        _c("td", { staticStyle: { width: "10%" } }, [
                          _vm._v(_vm._s(index + 1)),
                        ]),
                        _vm._v(" "),
                        _c("td", { staticClass: "text-left" }, [
                          _vm._v(
                            _vm._s(_vm.formatNumber(sandWeight, 0)) + " lbs"
                          ),
                        ]),
                      ])
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.edgeLevelAst != null && _vm.edgeLevelFreshOsmosis != null
        ? _c(
            "div",
            { staticClass: "col-6" },
            [
              _c("bar-chart", {
                attrs: {
                  height: 555,
                  "chart-data": _vm.waterChartData,
                  options: _vm.chartOptions("Water Storage"),
                },
              }),
              _vm._v(" "),
              _c(
                "table",
                {
                  staticClass: "table table-striped table-hover",
                  class: { "table-dark": this.$root.darkMode },
                },
                [
                  _c("tbody", [
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: { width: "50%" },
                        },
                        [_vm._v("Usable AST")]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(
                          _vm._s(_vm.formatNumber(_vm.edgeLevelAst, 0)) +
                            " bbls"
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("tr", [
                      _c(
                        "td",
                        {
                          staticClass: "text-right",
                          staticStyle: { width: "50%" },
                        },
                        [_vm._v("DI Water")]
                      ),
                      _vm._v(" "),
                      _c("td", { staticClass: "text-left" }, [
                        _vm._v(
                          _vm._s(
                            _vm.formatNumber(_vm.edgeLevelFreshOsmosis, 0)
                          ) + " bbls"
                        ),
                      ]),
                    ]),
                  ]),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }