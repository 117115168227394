var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.hasSubLabels
        ? _c("multiselect", {
            attrs: {
              options: _vm.getOptions,
              multiple: _vm.multiSelect,
              "close-on-select": false,
              "show-labels": false,
              "append-to-body": true,
              "limit-text": _vm.limitText,
              "max-height": _vm.maxHeight,
              disabled: _vm.disabled,
              allowEmpty: _vm.allowEmpty,
              placeholder: _vm.placeholderText,
              label: _vm.label,
              "track-by": _vm.trackByVal,
            },
            on: { input: _vm.onUpdate },
            scopedSlots: _vm._u(
              [
                {
                  key: "selection",
                  fn: function ({ values, search, isOpen }) {
                    return [
                      values.length && !isOpen
                        ? _c(
                            "span",
                            {
                              staticClass:
                                "multiselect__single d-inline-block text-truncate",
                            },
                            [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.selectedItemsTextDisplay) +
                                  "\n            "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  },
                },
                {
                  key: "tag",
                  fn: function (props) {
                    return [_c("span")]
                  },
                },
              ],
              null,
              false,
              647358129
            ),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          })
        : _c("multiselect", {
            attrs: {
              options: _vm.options,
              multiple: _vm.multiSelect,
              "close-on-select": false,
              "show-labels": false,
              "group-values": "valueArray",
              "group-label": "subCatLabel",
              "limit-text": _vm.limitText,
              "max-height": _vm.maxHeight,
              disabled: _vm.disabled,
              allowEmpty: _vm.allowEmpty,
              placeholder: _vm.placeholderText,
              label: _vm.label,
              "track-by": _vm.trackByVal,
            },
            on: { input: _vm.onUpdate },
            scopedSlots: _vm._u([
              {
                key: "selection",
                fn: function ({ values, search, isOpen }) {
                  return [
                    values.length && !isOpen
                      ? _c("span", { staticClass: "multiselect__single" }, [
                          _vm._v(_vm._s(_vm.selectedItemsTextDisplay)),
                        ])
                      : _vm._e(),
                  ]
                },
              },
              {
                key: "tag",
                fn: function (props) {
                  return [_c("span")]
                },
              },
            ]),
            model: {
              value: _vm.selected,
              callback: function ($$v) {
                _vm.selected = $$v
              },
              expression: "selected",
            },
          }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }