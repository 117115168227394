var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "MetadataTopLevelPadsModal",
      staticClass: "dark-modal modal",
      attrs: {
        id: "MetadataTopLevelPadsModal",
        tabindex: "-1",
        role: "dialog",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.system !== null
            ? _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm.fields !== null
                    ? _c(
                        "div",
                        { staticStyle: { "margin-bottom": "20px" } },
                        [
                          _c("iws-select", {
                            attrs: {
                              label: "Field",
                              value: _vm.selectedField,
                              options: _vm.fields,
                              "display-name": (field) => field.data.name,
                              "value-name": "id",
                            },
                            on: {
                              "update:value": function ($event) {
                                _vm.selectedField = $event
                              },
                              change: _vm.getPads,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.pads !== null
                    ? _c(
                        "div",
                        { staticStyle: { "margin-bottom": "20px" } },
                        [
                          _c("iws-select", {
                            attrs: {
                              label: "Pads",
                              value: _vm.selectedPads,
                              options: _vm.pads,
                              "display-name": (field) => field.data.name,
                              "value-name": "id",
                              "maintain-on-select": true,
                              multiselect: true,
                            },
                            on: { change: _vm.handlePadSelection },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("iws-alert", {
                    attrs: { type: "info" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _vm.selectedField === null
                                ? [
                                    _vm._v(
                                      "\n                            Please select a field to see pads\n                        "
                                    ),
                                  ]
                                : [
                                    _vm._v(
                                      "\n                            Please select the pad(s) for this field\n                        "
                                    ),
                                  ],
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      3915420160
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-light",
                  attrs: { id: "cancel_button" },
                },
                [_vm._v("\n                    Cancel\n                ")]
              ),
              _vm._v(" "),
              _c("iws-button", {
                attrs: { text: "Save", click: _vm.onSaveClick },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v("\n                    Select Pads\n                "),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }