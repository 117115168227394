var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.componentSpecificPermissions[_vm.item.type] != undefined &&
    !_vm.componentSpecificPermissions[_vm.item.type]
      ? _c(
          "div",
          {
            staticClass: "itemContainer",
            style: "height:" + _vm.height + "px;",
          },
          [
            _c("div", { attrs: { id: "overlay" } }, [
              _c(
                "div",
                { staticClass: "alert alert-warning m-4 absolute-center" },
                [
                  _c("div", { staticClass: "pb-2" }, [
                    _vm._v(
                      "Please contact your IWS Completions Advisor to enable " +
                        _vm._s(_vm.friendlyName)
                    ),
                  ]),
                ]
              ),
            ]),
          ]
        )
      : !_vm.componentExists
      ? _c(
          "div",
          {
            staticClass: "itemContainer",
            style: "height:" + _vm.height + "px;",
          },
          [_vm._m(0)]
        )
      : _c("div", [
          _vm.item.type == "well-group-component"
            ? _c(
                "div",
                [
                  _vm.dashboardData.wells && _vm.dashboardData.wells.length > 0
                    ? _c("well-group-component", {
                        attrs: {
                          height: _vm.parentHeight,
                          job: _vm.dashboardData.job,
                          wells: _vm.wells,
                          casingPressures:
                            _vm.dashboardData.pressure.casingPressures,
                          crownPressures:
                            _vm.dashboardData.pressure.crownPressures,
                          "is-multi-wireline":
                            _vm.dashboardData.isMultiWireline,
                          "wireline-systems": _vm.dashboardData.wirelineSystems,
                          "is-multi-frac": _vm.dashboardData.isMultiFrac,
                          "frac-systems": _vm.dashboardData.fracSystems,
                          jobEnd: _vm.dashboardData.jobEnd,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm.item.type == "event-table-component"
            ? _c(
                "div",
                [
                  _c("event-table-component", {
                    attrs: {
                      editMode: _vm.editMode,
                      item: _vm.item,
                      dashboardData: _vm.dashboardData,
                      height: _vm.height,
                      heightOffset: 35,
                      detailed: _vm.dashboardData.true,
                      events: _vm.dashboardData.events,
                      wells: _vm.dashboardData.wells,
                      eventActivities: _vm.dashboardData.eventActivities,
                      eventReasons: _vm.dashboardData.eventReasons,
                      contractors: _vm.dashboardData.contractors,
                      eventActivityEventReasons:
                        _vm.dashboardData.eventActivityEventReasons,
                      jobNumber: _vm.dashboardData.jobNumber,
                      jobHourOffset: _vm.dashboardData.job.hourOffset,
                      "is-standalone-wireline":
                        _vm.dashboardData.isStandaloneWireline,
                    },
                    on: {
                      createCommentButtonClicked:
                        _vm.dashboardData.handleCreateCommentButtonClicked,
                    },
                  }),
                ],
                1
              )
            : _vm.item.type == "summary-bar-component"
            ? _c(
                "div",
                [
                  _vm.isFeatureFlagged("NEW_SUMMARY_BAR") &&
                  _vm.dashboardData.summaryBarEndTime
                    ? _c("summary-bar-component", {
                        attrs: {
                          editMode: _vm.editMode,
                          endDate: _vm.dashboardData.summaryBarEndTime,
                          bounds: {
                            lower: _vm.dashboardData.activitiesStartTime,
                            upper: _vm.dashboardData.activitiesEndTime,
                          },
                          height: _vm.height,
                          jobStart: _vm.dashboardData.jobStart,
                          jobShiftStart: _vm.dashboardData.job.shiftStart,
                          jobNumber: _vm.dashboardData.jobNumber,
                          isContinuousFrac: _vm.dashboardData.isContinuousFrac,
                          isJobCompleted: _vm.dashboardData.jobEnd
                            ? true
                            : false,
                          isJobEndTime: _vm.dashboardData.jobEnd,
                          "is-multi-wireline":
                            _vm.dashboardData.isMultiWireline,
                          "is-multi-frac": _vm.dashboardData.isMultiFrac,
                          activityData: _vm.dashboardData.activitiesFetched,
                          latestActivityData:
                            _vm.dashboardData.latestActivityData,
                          "job-hour-offset": _vm.dashboardData.job.hourOffset,
                          wells: _vm.dashboardData.wells,
                          onXAxisChange: _vm.dashboardData.onSummaryXAxisChange,
                          isSimuFrac: _vm.dashboardData.isSimuFrac,
                          "wireline-systems": _vm.dashboardData.wirelineSystems,
                          "frac-systems": _vm.dashboardData.fracSystems,
                          summaryBarTimeFrame:
                            _vm.dashboardData.summaryBarTimeFrame,
                          latestActivityBySystem:
                            _vm.dashboardData.latestActivityBySystem,
                          item: _vm.item,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isFeatureFlagged("NEW_SUMMARY_BAR") &&
                  _vm.dashboardData.summaryBarEndTime
                    ? _c("summary-bar-component-old", {
                        attrs: {
                          endDate: _vm.dashboardData.summaryBarEndTime,
                          bounds: {
                            lower: _vm.dashboardData.activitiesStartTime,
                            upper: _vm.dashboardData.activitiesEndTime,
                          },
                          height: _vm.height,
                          jobNumber: _vm.dashboardData.jobNumber,
                          isContinuousFrac: _vm.dashboardData.isContinuousFrac,
                          isJobCompleted: _vm.dashboardData.jobEnd
                            ? true
                            : false,
                          isJobEndTime: _vm.dashboardData.jobEnd,
                          "is-multi-wireline":
                            _vm.dashboardData.isMultiWireline,
                          "is-multi-frac": _vm.dashboardData.isMultiFrac,
                          activityData: _vm.dashboardData.activitiesFetched,
                          latestActivityData:
                            _vm.dashboardData.latestActivityData,
                          latestActivityBySystem:
                            _vm.dashboardData.latestActivityBySystem,
                          "job-hour-offset": _vm.dashboardData.job.hourOffset,
                          wells: _vm.dashboardData.wells,
                          onXAxisChange: _vm.dashboardData.onSummaryXAxisChange,
                          isSimuFrac: _vm.dashboardData.isSimuFrac,
                          "wireline-systems": _vm.dashboardData.wirelineSystems,
                          "frac-systems": _vm.dashboardData.fracSystems,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm.item.type == "dashboard-frac-chart"
            ? _c(
                "div",
                [
                  _c("dashboard-time-series-chart", {
                    key: _vm.item.type + _vm.item.i,
                    ref: "dashboardFracComponent",
                    attrs: {
                      item: _vm.item,
                      height: _vm.height,
                      width: _vm.width,
                      layout: _vm.layout,
                      heightOffset: 50,
                      dashboardItem: _vm.item,
                      dashboardData: _vm.dashboardData,
                      "is-multi-frac": _vm.dashboardData.isMultiFrac,
                      "is-multi-wireline": _vm.dashboardData.isMultiWireline,
                      "is-continuous-frac": _vm.dashboardData.isContinuousFrac,
                      defaultChartColumnCount: _vm.defaultChartColumnCount,
                      onSyncedViewChanged:
                        _vm.dashboardData.onSyncedViewChanged,
                      signalRConnected: _vm.dashboardData.signalRConnected,
                      iwsUser: !!_vm.iwsUser,
                      modalRefKey: "chartConfigModal",
                    },
                    on: { onChartLoadComplete: _vm.updateActiveTags },
                  }),
                ],
                1
              )
            : _vm.item.type == "dashboard-comparison-chart"
            ? _c(
                "div",
                [
                  _c("dashboard-comparison-chart", {
                    key: _vm.item.type + _vm.item.i,
                    ref: "dashboardComparisonComponent",
                    attrs: {
                      chartType: _vm.analysisChartTypes.STAGE_COMPARE,
                      height: _vm.height,
                      heightOffset: 50,
                      dashboardData: _vm.dashboardData,
                      dashboardInfo: _vm.dashboardInfo,
                      userid: _vm.userid,
                      filterOptionsData: {},
                      item: _vm.item,
                      isAdmin: _vm.isAdmin,
                      userCompanyId: _vm.userCompanyId,
                      modalRefKey: "chartConfigModal",
                    },
                    on: { updateDashboardItem: _vm.updateDashboardItem },
                  }),
                ],
                1
              )
            : _vm.item.type == "well-comparison"
            ? _c(
                "div",
                [
                  _c("well-comparison-chart", {
                    key: _vm.item.type + _vm.item.i,
                    ref: "multiWellComparison",
                    attrs: {
                      chartType: _vm.analysisChartTypes.WELL_COMPARE,
                      sessionId: _vm.dashboardData.sessionId,
                      height: _vm.height,
                      heightOffset: 50,
                      dashboardData: _vm.dashboardData,
                      dashboardInfo: _vm.dashboardInfo,
                      userid: _vm.userid,
                      filterOptionsData: {},
                      item: _vm.item,
                      isAdmin: _vm.isAdmin,
                      userCompanyId: _vm.userCompanyId,
                      modalRefKey: "chartConfigModal",
                    },
                    on: { updateDashboardItem: _vm.updateDashboardItem },
                  }),
                ],
                1
              )
            : _vm.item.type == "latency-component"
            ? _c(
                "div",
                [
                  _c("latency-component", {
                    attrs: {
                      wells: _vm.wells,
                      utcDifference: _vm.utcDifference,
                      tags: _vm.latencyComponentTags,
                      items: _vm.items,
                      latestDataCollection: _vm.latestDataCollection,
                      latencySaving: _vm.latencySaving,
                      latencySaved: _vm.latencySaved,
                    },
                    on: { onSavePressed: () => this.$emit("onSavePressed") },
                  }),
                ],
                1
              )
            : _vm.item.type == "latency-component-new"
            ? _c(
                "div",
                [
                  _c("latency-component-new", {
                    attrs: {
                      nav: false,
                      utcDifference: _vm.utcDifference,
                      latestDataCollection: _vm.latestDataCollection,
                    },
                  }),
                ],
                1
              )
            : _vm.item.type == "stage-summary-heatmap"
            ? _c(
                "div",
                [
                  _c("stage-summary-heat-map", {
                    key: _vm.item.type + _vm.item.i,
                    ref: "stageSummaryHeatMap-" + _vm.item.i,
                    attrs: {
                      chartType: _vm.analysisChartTypes.STAGE_SUMMARY_HEATMAP,
                      sessionId: _vm.dashboardData.sessionId,
                      height: _vm.height,
                      heightOffset: 50,
                      dashboardData: _vm.dashboardData,
                      dashboardInfo: _vm.dashboardInfo,
                      userid: _vm.userid,
                      filterOptionsData: {},
                      item: _vm.item,
                      isAdmin: _vm.isAdmin,
                      userCompanyId: _vm.userCompanyId,
                      modalRefKey: "chartConfigModal",
                    },
                    on: { updateDashboardItem: _vm.updateDashboardItem },
                  }),
                ],
                1
              )
            : _vm.item.type == "kpi-progress"
            ? _c(
                "div",
                [
                  !!_vm.customer
                    ? _c("kpi-display-item", {
                        attrs: {
                          editMode: _vm.editMode,
                          jobNumber: _vm.dashboardData.jobNumber,
                          job: _vm.dashboardData.job,
                          customer: _vm.customer,
                          dashboardData: _vm.dashboardData,
                          height: _vm.height,
                          item: _vm.item,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm.item.type == "activity-alert-component"
            ? _c(
                "div",
                [
                  _c("activity-alert-component", {
                    attrs: {
                      onAlertPress: _vm.dashboardData.onAlertPress,
                      height: _vm.height + 10,
                      messagesArray: _vm.dashboardData.messagesArray,
                      alertHistory: _vm.dashboardData.eventAlertHistory,
                      clusterAlerts: _vm.dashboardData.clusterAlerts,
                    },
                  }),
                ],
                1
              )
            : _vm.item.type == "wireline-diameter-component"
            ? _c(
                "div",
                [
                  _c("wireline-diameter-component", {
                    attrs: {
                      dashboardData: _vm.dashboardData,
                      height: _vm.height,
                      item: _vm.item,
                      editMode: _vm.editMode,
                      latestSignalRDataCollection:
                        _vm.dashboardData.latestDataCollection,
                      allJobWells: _vm.dashboardData.wells,
                      jobHourOffset: _vm.dashboardData.job.hourOffset,
                      perforatingWellIndex: _vm.dashboardData.indexWireline(),
                      "wireline-systems": _vm.dashboardData.wirelineSystems,
                    },
                  }),
                ],
                1
              )
            : _vm.item.type == "wellbore-diagram-component"
            ? _c(
                "div",
                [
                  _vm.dashboardData.wells && _vm.dashboardData.wells.length > 0
                    ? _c("wellbore-diagram-component", {
                        attrs: {
                          height: _vm.height,
                          width: _vm.width,
                          layout: _vm.layout,
                          dashboardData: _vm.dashboardData,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm.item.type == "single-well-display-component"
            ? _c(
                "div",
                [
                  _c("single-well-display-component", {
                    attrs: {
                      editMode: _vm.editMode,
                      wells: _vm.wells,
                      "is-multi-wireline": _vm.dashboardData.isMultiWireline,
                      "wireline-systems": _vm.dashboardData.wirelineSystems,
                      pressure: _vm.dashboardData.pressure,
                      height: _vm.height,
                      dashboardData: _vm.dashboardData,
                      loading: !_vm.dashboardData.initActivityCallEnded,
                      item: _vm.item,
                    },
                  }),
                ],
                1
              )
            : _vm.item.type == "wireline-info-component"
            ? _c(
                "div",
                [
                  _c("wireline-info-component", {
                    attrs: {
                      height: _vm.height,
                      heightOffset: _vm.heightOffsetDefault,
                      wirelineWellIndex: _vm.dashboardData.currentWellIndex,
                      jobNumber: _vm.dashboardData.jobNumber,
                      wells: _vm.dashboardData.wells,
                      hourOffset: _vm.dashboardData.job.hourOffset,
                      logEntries: _vm.dashboardData.logEntries,
                      item: _vm.item,
                      dashboardId: _vm.dashboardData.selectedDashboard.id,
                    },
                  }),
                ],
                1
              )
            : _vm.item.type == "wireline-controls-component"
            ? _c(
                "div",
                [
                  _c("wireline-controls-component", {
                    attrs: {
                      item: _vm.item,
                      dashboardId: _vm.dashboardData._data.selectedDashboard.id,
                      loading: !_vm.dashboardData.initActivityCallEnded,
                      wirelineWellIndex: _vm.dashboardData.currentWellIndex,
                      jobNumber: _vm.dashboardData.jobNumber,
                      wells: _vm.dashboardData.wells,
                      hourOffset: _vm.dashboardData.job.hourOffset,
                      customerId: _vm.dashboardData.customer_id,
                      height: _vm.height,
                    },
                  }),
                ],
                1
              )
            : _vm.item.type == "activity-breakdown-pie-charts"
            ? _c(
                "div",
                [
                  _c("activity-breakdown-pie-charts", {
                    attrs: {
                      height: _vm.height,
                      width: _vm.width,
                      currentEvent: _vm.dashboardData.currentEvent,
                      activityData: _vm.dashboardData.activitiesFetched,
                      latestActivityData: _vm.dashboardData.latestActivityData,
                      jobNumber: _vm.dashboardData.jobNumber,
                      wells: _vm.dashboardData.wells,
                      job: _vm.dashboardData.job,
                      item: _vm.item,
                      dashboardId: _vm.dashboardData._data.selectedDashboard.id,
                      latestHandshakeEvent:
                        _vm.dashboardData._data.latestHandshakeEvent,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { attrs: { id: "overlay" } }, [
      _c("div", { staticClass: "alert alert-warning m-4 absolute-center" }, [
        _c("div", { staticClass: "pb-2" }, [
          _vm._v("This component does not exist. Please delete."),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }