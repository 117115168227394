<template>
    <b-modal ref="tag-config" 
    header-class="border-bottom-0 justify-content-center  pb-0"
    footer-class="border-top-0 pt-0"
    content-class="modal-bg" ok-only>
        <template #modal-header>
            <div class="font-large"> Tag Summary Configuration </div>
        </template>
        <template #default>
            <b-card class="inner-bg round-top round-bottom border-bottom-0">
                <div>
                    <label>
                        <input type="checkbox" name="showDefaultName" v-model="showDefaultColumn"> 
                        Show "Default Tag Names"
                    </label>
                </div>
            </b-card>
        </template>
    </b-modal>
</template>

<script>
export default {
    props: {
        jobNumber: {
            type: String,
            required: true
        }
    },
    emits: ['showDefault'],
    data() {
        return {
            showDefaultColumn: false,
            key: `showDefault-${this.jobNumber}`
        };
    },
    mounted() {
        const canShow = localStorage.getItem(this.key);
        this.showDefaultColumn = canShow ? (canShow === 'true') : false;
    },
    watch: {
        showDefaultColumn(newValue) {
            localStorage.setItem(this.key, newValue);
            this.$emit('showDefault', newValue);
        }
    },
    methods: {
        changeModalVisibility(status) {
            status ? this.$refs['tag-config'].show() : this.$refs['tag-config'].hide();
        }
    }
};
</script>