import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins
import ChartAnnotations from 'chartjs-plugin-annotation';

export default {
    extends: Line,
    mixins: [reactiveProp],
    props: {
      options: Object,
      customPlugins: Array
    },
    mounted () {
      this.addPlugin(ChartAnnotations);
      Chart.Tooltip.positioners.cursor = function(chartElements, coordinates) {
        return coordinates;
      };

      if(this.customPlugins)
      {
        for(let i = 0; i < this.customPlugins.length; i++)
        {
          this.addPlugin(this.customPlugins[i]);
        }
      }

    this.renderChart(this.chartData, this.options);
	},
	watch: { 
		chartData: function(newVal, oldVal) {
			this.renderChart(this.chartData, this.options);
    }
  }
}