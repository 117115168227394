<template>
    <div>
        <div class="table-responsive mb-0" :style="{paddingTop: editMode ? '30px' : '0px'}">
            <table class="table table-sm text-left fixed-header" v-bind:class="tableColor">
                <thead>
                    <tr>
                        <th style="width: 4%;">
                            <b-popover
                                target="event-filter"
                                placement="top"
                                boundary="window"
                                triggers="click blur">
                                <div class="row mb-1 mt-1">
                                    <div class="col-12">
                                        <div class="row mb-1 ml-1 mr-1">
                                            <div class="col-6 d-flex align-items-center" style="white-space:nowrap">
                                                <input
                                                    v-model="slideMinMax[0]"
                                                    style="border-style: solid;"
                                                    class="rounded w-100"
                                                    :min="slideData[0] + 'T00:00:00'"
                                                    :max="slideData[slideData.length-1] + 'T23:59:00'"
                                                    type="datetime-local"
                                                    step="1"
                                                    @change="(startDate, endDate)=>setDateFilter(slideMinMax[0], endDate)">
                                            </div>
                                            <div class="col-6 d-flex align-items-center" style="white-space:nowrap">
                                                <input
                                                    v-model="slideMinMax[1]"
                                                    style="border-style: solid;"
                                                    class="rounded w-100"
                                                    :min="slideData[0] + 'T00:00:00'"
                                                    :max="slideData[slideData.length-1] + 'T23:59:00'"
                                                    type="datetime-local"
                                                    step="1"
                                                    @change="(startDate, endDate)=>setDateFilter(startDate, slideMinMax[1])">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mb-2 mr-1 ml-1">
                                    <div class="col-4">
                                        <select class="form-control form-control-sm" v-model="filterWell">
                                            <option :value="null">--Filter By Well--</option>
                                            <option v-for="(well) in wells" :value="well.id" v-bind:key="well.id">{{well.name}}</option>
                                        </select>
                                        <button type="button" class="btn btn-primary btn-sm mt-2" :disabled="isFilterCleared" @click="clearTableFilters()">Clear Filters</button>
                                    </div>
                                    <div class="col-4">
                                        <select class="form-control form-control-sm" v-model="filterActivity">
                                            <option :value="null">--Filter By Code--</option>
                                            <option v-for="(item) in availableActivities" :value="item.activity_id" v-bind:key="item.activity_id">{{item.activity}}</option>
                                        </select>
                                    </div>
                                    <div class="col-4">
                                        <select class="form-control form-control-sm" v-model="filterRequester">
                                            <option :value="null">--Filter By User--</option>
                                            <option v-for="(item, index) in availableHandshakeRequesters" :key="index">{{item}}</option>
                                        </select>
                                        <button type="button" class="btn btn-danger mt-2 btn-sm float-right" @click.prevent="$root.$emit('bv::hide::popover')">Close</button>
                                    </div>
                                </div>
                            </b-popover>
                            <div id="event-filter">
                                <span v-tooltip:top="'Filter'">
                                    <i class="fas fa-filter clickable" :class="{ green: !isFilterCleared }"></i>
                                </span>
                            </div>
                        </th>
                        <th style="width: 17%;">Start</th>
                        <th style="width: 17%;">End</th>
                        <th v-if="editMode || shouldShowCodeColumn"
                            style="width: 15%;">
                            Code
                            <i v-show="editMode && !shouldShowCodeColumn"
                               class="fas fa-eye-slash pl-1"
                               style="cursor: pointer"
                               @click="toggleColumn('code')"
                               v-tooltip:top="'Show code column'"></i>
                            <i v-show="editMode && shouldShowCodeColumn"
                               class="fas fa-eye pl-1"
                               style="cursor: pointer"
                               @click="toggleColumn('code')"
                               v-tooltip:top="'Hide code column'"></i>
                        </th>
                        <th v-if="editMode || shouldShowReasonColumn"
                            style="width: 15%;">
                            Reason
                            <i v-show="editMode && !shouldShowReasonColumn"
                               class="fas fa-eye-slash pl-1"
                               style="cursor: pointer"
                               @click="toggleColumn('reason')"
                               v-tooltip:top="'Show reason column'"></i>
                            <i v-show="editMode && shouldShowReasonColumn"
                               class="fas fa-eye pl-1"
                               style="cursor: pointer"
                               @click="toggleColumn('reason')"
                               v-tooltip:top="'Hide reason column'"></i>
                        </th>
                        <th class="pl-3">Remarks</th>
                        <th >
                            <div v-if="isFeatureFlagged('DRAW_NPT')" class="d-inline float-right">
                                <span v-tooltip:top="'New Entry'" @click="handleNewCommentButtonClicked">
                                    <i class="fa fa-plus-square clickable"></i>
                                </span>
                            </div>
                            <div class="d-inline float-right mr-2">
                                <span v-tooltip:top="'Export'" @click="exportTable">
                                    <i class="fas fa-download clickable"></i>
                                </span>
                            </div>

                        </th>
                    </tr>
                </thead>
                <tbody class="scrollbar-color small" :style="{ 'height' : (height-heightOffset)+'px' }">
                     <div v-if="isLoading" class="h-100 row align-items-center text-center">
                        <div class="col">
                            <div class="spinner-border" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>
                    <TransitionGroup name="list">
                    <tr v-for="(event, index) in filteredEventsData" v-bind:key="index">
                        <template v-if="!editComments.find(comment => comment.comment_id == event.comment_id)">
                            <td v-bind:style="{width: '2%', 'backgroundColor': getWellColor(event.well_id)}"></td>
                            <td style="width: 19%;">
                                {{ event.start_time | shortTime }}
                            </td>
                            <td style="width: 19%;">
                                {{ event.end_time | shortTime }}
                            </td>
                            <td  v-if="editMode || shouldShowCodeColumn" style="width: 15%;" class="text-truncate">
                                {{ event.activity }}
                            </td>
                            <td v-if="editMode || shouldShowReasonColumn" style="width: 15%;">
                                {{ event.reason }}
                            </td>
                            <td>
                                {{ event.remarks }}
                            </td>
                            <td style="width: 5%;white-space: nowrap;" class="align-middle">
                                <div class="float-right">
                                    <i class="fas fa-pen clickable" v-if="event.type == 'comment'" @click="editComment(event, index)"></i>
                                    <i class="fas fa-trash-alt clickable" v-if="event.type == 'comment'" @click="deleteEditComment(event, index)"></i>
                                </div>
                            </td>
                        </template>
                        <template v-else-if="getNewComment(event)">
                            <td v-bind:style="{width: '2%','backgroundColor': getWellColor(getNewComment(event).well_id)}" @click="onWellSelectClicked(index)">
                                <div style="text-align: center">
                                    <i class="fas fa-caret-down" :style="{'color': getTitleColorForBG(getWellColor(getNewComment(event).well_id))}"></i>
                                </div>
                                <div class="dropdown">
                                    <div class="dropdown-content" :ref="'well_dropdown_' + index">
                                        <a @click="onWellSelected(getNewComment(event),null,index)"> --No Well Set--</a>
                                        <a v-for="(well) in wells" @click="onWellSelected(getNewComment(event),well.id,index)" style="display: flex; align-items: center">
                                            <div class="mr-2" style="display: inline-block;">{{well.name}}</div>
                                            <div :style="{width:'25px', height:'25px', border:'solid #000000', display:'inline-block', 'marginLeft':'auto', 'marginRight':'0px', 'backgroundColor': getWellColor(well.id)}"></div>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 19%;">
                                <div class="input-group-sm white-text">
                                    <input type="date" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getNewComment(event).start_d" v-on:change="(value)=>onStartDateChanged(getNewComment(event),index)" :ref="'start_date_' + index" :id="'popover-start' + _uid" />
                                    <input type="time" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getNewComment(event).start_t" v-on:change="(value)=>onStartTimeChanged(getNewComment(event),index)" :ref="'start_time_' + index" id="startTimePicker" />
                                </div>

                                <b-popover :show.sync="errorStartTimeInvalid" :target="'popover-start' + _uid" boundary="window" triggers="manual" placement="bottom" variant="danger">
                                    Start time is invalid.
                                </b-popover>

                                <b-popover :show.sync="errorStartTimeLater" :target="'popover-start' + _uid" boundary="window" triggers="manual" variant="danger" placement="bottom">
                                    Start time cannot be later than end time.
                                </b-popover>
                            </td>
                            <td style="width: 19%;">
                                <div class="input-group-sm white-text">
                                    <input type="date" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getNewComment(event).end_d" v-on:change="(value)=>onEndDateChanged(getNewComment(event),index)" :ref="'end_date_' + index" :id="'popover-end' + _uid" />
                                    <input type="time" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getNewComment(event).end_t" v-on:change="(value)=>onEndTimeChanged(getNewComment(event),index)" :ref="'end_time_' + index" id="endTimePicker" />
                                </div>
                                
                                <b-popover :show.sync="errorEndTimeInvalid" :target="'popover-end' + _uid" boundary="window" triggers="manual" variant="danger" placement="bottom">
                                    Invalid end time.
                                </b-popover>
                            </td>
                            <td style="width: 15%;" v-if="shouldShowCodeColumn">
                                <select class="form-control form-control-sm" style="width: 100%;" v-model="getNewComment(event).activity_id" @change="(value)=>onActivityChanged(event,index)" :ref="'activity_' + index">
                                    <option v-for="(item) in eventActivities" :value="item.id" v-bind:key="item.id">{{item.type}}</option>
                                </select>
                            </td>
                            <td style="width: 15%;" v-if="shouldShowReasonColumn">
                                <select class="form-control form-control-sm" style="width: 100%;" v-model="getNewComment(event).reason_id" @change="(value)=>onReasonChanged(event,index)" :ref="'reason_' + index">
                                    <option :value="null"> --Not Set--</option>
                                    <option v-for="(item) in visibleEventReasons(getNewComment(event))" :value="item.id" v-bind:key="item.id">{{item.type}}</option>
                                </select>
                            </td>
                            <td >
                                <textarea v-bind:value="getNewComment(event).remarks" style="width: 100%;" class="form-control form-control-sm" rows="3" @input="(value)=>onRemarkChanged(getNewComment(event),index)" :ref="'remark_' + index"></textarea>

                            </td>
                            <td style="width: 5%;white-space: nowrap;" class="align-middle">
                                <div class="float-right">
                                    <i class="fas fa-check clickable" @click="saveComment(getNewComment(event), index)"></i>
                                    <i class="fas fa-times clickable" @click="cancelEditComment(event,index)"></i>
                                </div>
                            </td>
                        </template>
                        <template v-else-if="getExistingComment(event)">
                            <td v-bind:style="{width: '2%','backgroundColor': getWellColor(getExistingComment(event).well_id)}" @click="onWellSelectClicked(index)">
                                <div style="text-align: center">
                                    <i class="fas fa-caret-down" :style="{'color': getTitleColorForBG(getWellColor(getExistingComment(event).well_id))}"></i>
                                </div>
                                <div class="dropdown">
                                    <div class="dropdown-content" :ref="'well_dropdown_' + index" tabindex="0">
                                        <a @click="onWellSelected(getExistingComment(event),null,index)"> --No Well Set--</a>
                                        <a v-for="(well, index) in wells" @click="onWellSelected(getExistingComment(event),well.id,index)" :key="index" style="display: flex; align-items: center">
                                            <div class="mr-2" style="display: inline-block;">{{well.name}}</div>
                                            <div :style="{width:'25px', height:'25px', border:'solid #000000', display:'inline-block', 'marginLeft':'auto', 'marginRight':'0px', 'backgroundColor': getWellColor(well.id)}"></div>
                                        </a>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 19%;">
                                <div class="input-group-sm white-text">
                                    <input type="date" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getExistingComment(event).start_d" v-on:change="(value)=>onStartDateChanged(getExistingComment(event),index)" :ref="'start_date_' + index" :id="'popover-edit-start' + _uid" />
                                    <input type="time" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getExistingComment(event).start_t" v-on:change="(value)=>onStartTimeChanged(getExistingComment(event),index)" :ref="'start_time_' + index" id="startTimePicker" />
                                </div>

                                <b-popover :show.sync="errorStartTimeInvalid" :target="'popover-edit-start' + _uid" boundary="window" triggers="manual" variant="danger" placement="bottom">
                                    Start time is invalid.
                                </b-popover>

                                <b-popover :show.sync="errorStartTimeLater" :target="'popover-edit-start' + _uid" boundary="window" triggers="manual" variant="danger" placement="bottom">
                                    Start time cannot be later than end time.
                                </b-popover>

                            </td>
                            <td style="width: 19%;">
                                <div class="input-group-sm white-text">
                                    <input type="date" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getExistingComment(event).end_d" v-on:change="(value)=>onEndDateChanged(getExistingComment(event),index)" :ref="'end_date_' + index" :id="'popover-edit-end' + _uid"/>
                                    <input type="time" step="1" class="form-control form-control-sm" style="max-width:150px;" v-model="getExistingComment(event).end_t" v-on:change="(value)=>onEndTimeChanged(getExistingComment(event),index)" :ref="'end_time_' + index" id="endTimePicker" />
                                </div>
                                
                                <b-popover :show.sync="errorEndTimeInvalid" :target="'popover-edit-end' + _uid" boundary="window" triggers="manual" variant="danger" placement="bottom">
                                    Invalid end time.
                                </b-popover>
                            </td>
                            <td style="width: 15%;" class="text-truncate" v-if="shouldShowCodeColumn">
                                <select class="form-control form-control-sm" v-model="getExistingComment(event).activity_id" @change="(value)=>onActivityChanged(event,index)" :ref="'activity_' + index">
                                    <option v-for="(item) in eventActivities" :value="item.id" v-bind:key="item.id">{{item.type}}</option>
                                </select>
                            </td>
                            <td style="width: 15%;" v-if="shouldShowReasonColumn">
                                <select class="form-control form-control-sm" v-model="getExistingComment(event).reason_id" @change="(value)=>onReasonChanged(event,index)" :ref="'reason_' + index">
                                    <option :value="null"> --Not Set--</option>
                                    <option v-for="(item) in visibleEventReasons(getExistingComment(event))" :value="item.id" v-bind:key="item.id">{{item.type}}</option>
                                </select>
                            </td>
                            <td>
                                <textarea v-bind:value="getExistingComment(event).remarks" style="width: 100%;" class="form-control form-control-sm" rows="3" @input="(value)=>onRemarkChanged(getExistingComment(event),index)" :ref="'remark_' + index"></textarea>
                            </td>
                            <td style="width: 5%;white-space: nowrap;" class="align-middle">
                                <div class="float-right">
                                    <i class="fas fa-check clickable" @click="saveComment(getExistingComment(event), index)"></i>
                                    <i class="fas fa-times clickable" @click="cancelEditComment(event,index)"></i>
                                </div>
                            </td>
                        </template>
                    </tr>
                    </TransitionGroup>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
import moment from 'moment';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import _ from 'lodash';

export default {
    components: {
		    VueSlider
	    },
    props: {
        height: {
            type: Number,
            default: 300
        },
        heightOffset: { 
            type: Number,
            default: 0
        },
        item: Object,
        dashboardData: Object,
        editMode: Boolean,
        events: Array,
        wells: Array,
        eventActivities: Array,
        eventReasons: Array,
        contractors: Array,
        eventActivityEventReasons: Array,
        jobNumber: String,
        jobHourOffset: Number
    },
    data() {
        return {
            isLoading: true,
            editComments: [],
            selectedActivityId: 1,
            selectedReasonId: null,
            selectedContractorId: null,
            selectedWellId: null,
            newComment: false,
            eventActivityEventReasonsLookup: {},
            eventsData: [],
            newEventsData: [],
            newCommentCounter: 0,
            isUpdateLocked: false,
            filterWell: null,
            filterActivity: null,
            filterRequester: null,
            filterAfterDate: null,
            filterBeforeDate: null,
            slideData: [],
            slideMinMax: [null, null],
            dateFilterActive: false,
            shouldShowCodeColumn: false,
            shouldShowReasonColumn: false,
            errorStartTimeInvalid: false,
            errorEndTimeInvalid: false,
            errorStartTimeLater: false
        };
    },
    watch: { 
        events: function(newVal, oldVal) {
            if(newVal.length > 0 && !this.isUpdateLocked) {
                this.eventsData = [...this.newEventsData, ...newVal];
                this.setSlideData();
                this.isLoading=false;

                if(this.slideData && this.slideData.length > 0 && !this.dateFilterActive) {
                    this.slideMinMax = [this.slideData[0] + 'T00:00:00', this.slideData[this.slideData.length - 1] + 'T23:59:00'] ;
                }
            }

            if(this.slideData && this.slideData.length > 0 && this.slideMinMax.length == 0) {
                this.slideMinMax = [this.slideData[0],this.slideData[this.slideData.length - 1]];
            }
        },
         eventsData: function() {
            this.isLoading=false;
        }
    },
    created() {
        const self = this;
        window.addEventListener('click', function(e) {
            const divs = document.querySelectorAll('.dropdown-content');
            for (let i = 0; i < divs.length; i++) {
                if(divs[i].classList.contains('justopened')) {
                    divs[i].classList.remove('justopened');
                }
                else if (divs[i].classList.contains('show')) {
                    divs[i].classList.remove('show');
                }
            }
        });
    },
    mounted() {
        if(!this.item?.options && this.item.options?.hiddenColumns === undefined) {
            this.item.options = {};
            this.item.options.hiddenColumns = ['code', 'reason'];
        }
        else{
            if (this.item.options.hiddenColumns) { //skip if undefined
                if(!this.item.options.hiddenColumns.includes('code')) {
                    this.shouldShowCodeColumn = true;
                }
                if(!this.item.options.hiddenColumns.includes('reason')) {
                    this.shouldShowReasonColumn = true;
                }
            }
        }

        if(this.eventsData?.length===0 && this.events?.length>0) {
            this.eventsData = this.events;
            this.setSlideData();

            this.slideMinMax = [this.slideData[0],this.slideData[this.slideData.length - 1]];
        }

        for(let i = 0; i < this.eventActivityEventReasons.length; i++)
        {
            const eventActivityId = this.eventActivityEventReasons[i].eventactivity_id;
            const eventReasonId = this.eventActivityEventReasons[i].eventreason_id;

            if(this.eventActivityEventReasonsLookup.hasOwnProperty(eventActivityId))
            {
                this.eventActivityEventReasonsLookup[eventActivityId].push(eventReasonId);
            } 
            else 
            {
                this.eventActivityEventReasonsLookup[eventActivityId] = [];
                this.eventActivityEventReasonsLookup[eventActivityId].push(eventReasonId);
            }
        }
    },
    computed: {
        isFilterCleared() {
            return this.filterWell == null && this.filterActivity == null && this.filterRequester == null && !this.dateFilterActive;
        },
        tableColor() {
            return {
                'table-dark': this.$root.darkMode
            };
        },
        filteredEventsData() {
            let filteredEventsData = [ ...this.eventsData ];

            //Check for each filter here and apply accordingly
            if(this.filterWell) {
                filteredEventsData = filteredEventsData.filter(event => (
                    event.well_id == this.filterWell || event.newComment_id != null
                ));
            }

            if(this.filterActivity) {
                filteredEventsData = filteredEventsData.filter(event => (
                    event.activity_id == this.filterActivity || event.newComment_id != null
                ));
            }

            if(this.filterRequester) {
                filteredEventsData = filteredEventsData.filter(event => (
                    event.requesterUser == this.filterRequester || event.newComment_id != null
                ));
            }

            if(this.dateFilterActive) {
                if(this.slideMinMax[0]){
                    //Always filter by the date selector
                    filteredEventsData = filteredEventsData.filter(event => (
                        moment(event.start_time).isAfter(
                            // account for if the original 'end date' marker crosses over the 'start date' marker
                            moment(this.slideMinMax[0] <= this.slideMinMax[1]) ? this.slideMinMax[0] : this.slideMinMax[1]
                      ) || event.newComment_id != null
                    ));
                }

                if(this.slideMinMax[1]){
                    filteredEventsData = filteredEventsData.filter(event => (
                        moment(event.start_time).isBefore(
                            // account for if the original 'end date' marker crosses over the 'start date' marker
                            moment((this.slideMinMax[1] >= this.slideMinMax[0] ? this.slideMinMax[1] : this.slideMinMax[0]) )
                        ) || event.newComment_id != null
                    ));
                }
            }

            return filteredEventsData;
        },
        availableHandshakeRequesters() {
            let returnArray = this.events.map(event => event.requesterUser);

            //Remove duplicates
            returnArray = [...new Set(returnArray)];
                
            //Remove null entry
            const nullIndex = returnArray.findIndex(requestor => requestor == null);
            if(nullIndex != -1) {
                returnArray.splice(nullIndex,1);
            }

            //Alphabetical order desc
            returnArray.sort(function(a,b) {
                const x = a.toLowerCase();
                const y = b.toLowerCase();
                if(x < y) { return -1; }
                if(x > y) { return 1; }
                return 0;
            });

            return returnArray;
        },
        availableActivities() {
            //Remove null entries
            const filtered = this.events.filter(event => (
                event.activity_id != null && event.activity != null
            ));

            //Remove duplicates
            let activityPart = filtered.map(event => event.activity);
            activityPart = [...new Set(activityPart)];
            let activity_idPart = filtered.map(event => event.activity_id);
            activity_idPart = [...new Set(activity_idPart)];

            //Merge into useful objects
            const lengthOfArray = activityPart.length;
            const returnArray = [];
            for(let i = 0; i < lengthOfArray; i++) {
                returnArray.push({
                    activity: activityPart[i],
                    activity_id: activity_idPart[i]
                });
            }

            //Alphabetical order desc
            returnArray.sort(function(a,b) {
                const x = a.activity.toLowerCase();
                const y = b.activity.toLowerCase();
                if(x < y) { return -1; }
                if(x > y) { return 1; }
                return 0;
            });

            return returnArray;
        }
    },
    methods: {
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        clearTableFilters() {
            //Set dates back to default values and clear well, activy, and user
            this.slideMinMax = [this.slideData[0] + 'T00:00:00', this.slideData[this.slideData.length - 1] + 'T23:59:00'];
            this.filterWell = null;
            this.filterActivity = null;
            this.filterRequester = null;
            this.dateFilterActive = false;
        },
        exportTable() {
            const csvData = [
                ['Start time', 'End Time', 'Duration', 'Activity', 'Well', 'Stage Number', 'Comments/Remarks'].join(', ')
            ];

            const wellsById = _.keyBy(this.wells, 'id');

            this.filteredEventsData.forEach(data => {
                const start = moment(data.start_time);
                const end = moment(data.end_time);
                const durationObject = moment.duration(start.diff(end)).abs();
                const inHours = durationObject.hours();
                const inMinutes = durationObject.minutes();
                const inSeconds = durationObject.seconds();

                

                const addLeadingZeros = (time) => {
                    if (time.toString().length == 1) {
                        time = '0' + time;
                    }
                    return time;
                };

                const newRow = [
                    data.start_time,
                    data.end_time,  
                    `${addLeadingZeros(inHours)}:${addLeadingZeros(inMinutes)}:${addLeadingZeros(inSeconds)}`, 
                    data.activity? data.activity: '',
                    wellsById[data.well_id]? wellsById[data.well_id].name : '',
                    data.stageNumber? data.stageNumber: '',
                    `"${data.remarks}"`
                ];
                csvData.push(newRow);
            });

            let fileName = 'event_table_export_' + this.slideMinMax[0] + '_to_' + this.slideMinMax[1];

            if(this.filterWell) {
                fileName += `_well: ${wellsById[this.filterWell].name}`;
            }

            if(this.filterActivity) {
                const activityObject = this.availableActivities.find((activity)=> activity.activity_id === this.filterActivity);
                fileName += `_activity: ${activityObject?.activity}`;
            }

            if(this.filterRequester) {
                fileName += `_requester: ${this.filterRequester}`;
            }

            const url = window.URL.createObjectURL(new Blob([csvData.join('\n')]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName + '.csv');
            document.body.appendChild(link);
            link.click();
        },
        toggleColumn(column) {
            // Update internal booleans (used for reactivity)
            if(column == 'code'){
                this.shouldShowCodeColumn = !this.shouldShowCodeColumn;
            }
            else if(column == 'reason'){
                this.shouldShowReasonColumn = !this.shouldShowReasonColumn;
            }

            // Update options.hiddenColumn array (used for storing the boolean on the item options)
            const eventTable = this.dashboardData.selectedDashboard.items.find(item => item.i == this.item.i);
            if(eventTable.options === undefined || eventTable.options.hiddenColumns === undefined || eventTable.options.length == 0){
                eventTable.options = { hiddenColumns: ['code', 'reason'] };
            }
            const columnId = eventTable.options.hiddenColumns.indexOf(column);
            if(columnId == -1){
                eventTable.options.hiddenColumns.push(column);
            }
            else{
                eventTable.options.hiddenColumns.splice(columnId, 1);
            }
        },
        setDateFilter(startDate, endDate) {
            if(startDate != this.slideData[0] || endDate != this.slideData[this.slideData.length -1]) {
                this.dateFilterActive = true;
            }else{
                this.dateFilterActive = false;
            }
        },
        onWellSelectClicked(index) {
            const dropdown = this.$refs['well_dropdown_' + index.toString()][0];
            if (dropdown.classList.contains('show')) {
                dropdown.classList.remove('show');
            }else{
                dropdown.classList.add('show');
                dropdown.classList.add('justopened');
            }
        },
        getNewComment(event) {
            return this.editComments.find(comment => event.newComment_id != null && comment.newComment_id == event.newComment_id);
        },
        getExistingComment(event) {
            return this.editComments.find(comment => comment.comment_id == event.comment_id);
        },
        getDates(startDate, stopDate) {
            const dateArray = [];
            let currentDate = moment(startDate);
            stopDate = moment(stopDate);
            while (currentDate <= stopDate) {
                dateArray.push( moment(currentDate).format('YYYY-MM-DD') );
                currentDate = moment(currentDate).add(1, 'days');
            }

            return dateArray;
        },
        setSlideData() {
            const reduceEvents = this.events.map(event => event.start_time);
            //Get the min date for events
            let min = reduceEvents.reduce(function (a,b) {
                return moment(a).isBefore(moment(b)) ? a : b;
            });
            min = min.substring(0,min.indexOf(' '));
                
            //Get the max date for events
            let max = reduceEvents.reduce(function (a,b) {
                return moment(a).isAfter(moment(b)) ? a : b;
            });
            max = max.substring(0,max.indexOf(' '));

            this.slideData = this.getDates(min, max);

            if(this.slideMinMax.length > 0 && moment(this.slideMinMax[1]).isAfter(moment(max))) {
                this.slideMinMax = [this.slideMinMax[0], max];
            }
        },
        visibleEventReasons(event) {
            return this.eventReasons;

            //TEMPORARILY DISABLING THIS HANDLING (Will be updated in the future)
            
            // return this.eventReasons.filter(r => 
            // {
            //     const reasons = this.eventActivityEventReasonsLookup[event.activity_id];

            //     if(reasons)
            //     {return reasons.includes(r.id);}

            //     return false;
            // });
        },
        getTimestampStringFormatted(timestamp) {
            return moment(timestamp).format('YYYY-MM-DD HH:mm:ss').replace(' ', 'T');
        },
        handleNewCommentButtonClicked(evt)
        {
            const startTime = moment().utc().add(this.jobHourOffset, 'h').format('YYYY-MM-DDTHH:mm:ss');
            const tIndex = startTime.indexOf('T');

            //Should get the current time and set start_time and end_time accordingly
            let newEvent = {
                activity: '',
                activity_id: '',
                comment_id: '',
                end_time: startTime,
                end_t: startTime.substring(tIndex + 1),
                end_d: startTime.substring(0,tIndex),
                reason: '',
                reason_id: '',
                remarks: '',
                start_time: startTime,
                start_t: startTime.substring(tIndex + 1),
                start_d: startTime.substring(0,tIndex),
                type: 'comment',
                well_id: '',
                newComment_id: this.newCommentCounter
            };
            this.newEventsData.unshift(newEvent);

            this.eventsData.unshift(newEvent);

            this.editComments.push({
                ...newEvent,
                start_time: this.getTimestampStringFormatted(this.newEventsData[0].start_time),
                end_time: this.getTimestampStringFormatted(this.newEventsData[0].end_time)
            });

            this.newCommentCounter = this.newCommentCounter + 1;
        },
        getTitleColorForBG: function(bgColor) {
            //Null well_id give a transparent background color
            //So set it to white so it doesn't look back.
            if(bgColor == 'transparent') {
                bgColor = '#000000';
            }
            return GlobalFunctions.getTitleColorForBG(bgColor);
        },
        getWellColor(wellId)
        {
            const well = this.wells.find(w => w.id == wellId);
            if(well)
            {return well.color;}

            return 'transparent';
        },
        getEventActivityIdFromString(targetString) {
            const target = this.eventActivities.find(act => act.type == targetString);
            return target ? target.id : null;
        },
        getEventReasonIdFromString(targetString) {
            const target = this.eventReasons.find(reas => reas.type == targetString);
            return target ? target.id : null;
        },
        editComment(event, index) {
            this.editComments.push({
                ...event,
                start_time: this.getTimestampStringFormatted(event.start_time),
                end_time: this.getTimestampStringFormatted(event.end_time)
            });

            const self = this;
        },
        onStartTimeChanged(event,index) {
            const targetRef = this.$refs['start_time_' + index.toString()][0];
            const tIndex = event.start_time.indexOf('T');
            event.start_time = event.start_time.substring(0,tIndex) + 'T' + targetRef.value;
        },
        onStartDateChanged(event,index) {
            const targetRef = this.$refs['start_date_' + index.toString()][0];
            const tIndex = event.start_time.indexOf('T');
            event.start_time = targetRef.value + 'T' + event.start_time.substring(tIndex + 1);
        },
        onEndTimeChanged(event,index) {
            const targetRef = this.$refs['end_time_' + index.toString()][0];
            const tIndex = event.start_time.indexOf('T');
            event.end_time = event.end_time.substring(0,tIndex) + 'T' + targetRef.value;
        },
        onEndDateChanged(event,index) {
            const targetRef = this.$refs['end_date_' + index.toString()][0];
            const tIndex = event.start_time.indexOf('T');
            event.end_time = targetRef.value + 'T' + event.end_time.substring(tIndex + 1);
        },
        onActivityChanged(event,index) {
            const targetRef = this.$refs['activity_' + index.toString()][0];
            const targetEditComment = this.editComments.find(comment => comment.comment_id == event.comment_id);
            targetEditComment.activity = this.eventActivities.find(act => act.id == targetRef.value).type;
        },
        onReasonChanged(event,index) {
            const targetRef = this.$refs['reason_' + index.toString()][0];
            const targetEditComment = this.editComments.find(comment => comment.comment_id == event.comment_id);
            targetEditComment.reason = this.eventReasons.find(reas => reas.id == targetRef.value).type;
        },
        onRemarkChanged(event,index) {
            const targetRef = this.$refs['remark_' + index.toString()][0];
            event.remarks = targetRef.value;
        },
        onWellSelected(event,well_id,index) {
            event.well_id = well_id;
        },
        saveComment(event,index) {
            if(event.newComment_id != null) {
                this.saveNewComment(event, index);
            }else{
                this.saveEditComment(event, index);
            }
        },
        saveNewComment(event, index) {
            const targetEvent = this.getNewComment(event);

            const startMoment = moment(event.start_time, 'YYYY-MM-DDTHH:mm:ss').add(-this.jobHourOffset, 'h');
            const endMoment = moment(event.end_time, 'YYYY-MM-DDTHH:mm:ss').add(-this.jobHourOffset, 'h');

            if(!startMoment.isValid()) {
                console.warn('startTime is invalid');
                this.errorStartTimeInvalid = true;
                return;
            }
            else{
                this.errorStartTimeInvalid = false;
            }

            if(!endMoment.isValid()) {
                console.warn('endTime is invalid');
                this.errorEndTimeInvalid = true;
                return;
            }
            else{
                this.errorEndTimeInvalid = false;
            }
            
            if(startMoment > endMoment) {
                console.warn('start time cannot be later than endTime');
                this.errorStartTimeLater = true;
                return;
            }
            else{
                this.errorStartTimeLater = false;
            }
            
            const startTimestamp = moment(startMoment).format('YYYY-MM-DD HH:mm:ss');
            const endTimestamp = moment(endMoment).format('YYYY-MM-DD HH:mm:ss');

            const eventActivity_id = event.activity_id;
            const eventReason_id = event.reason_id;
            const contractor_id = null;
            const well_id = event.well_id;

            this.isUpdateLocked = true;
            const self = this;
            
            $.post(
                '/chart-comments/' + this.jobNumber,
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    jobNumber: this.jobNumber,
                    text: event.remarks,
                    start_time: startTimestamp,
                    end_time: endTimestamp,
                    eventActivity_id,
                    eventReason_id,
                    contractor_id,
                    well_id
                },
                function (data) {
                    if(!data || data.error || data.length < 1){
                        alert("Could not save new comment: " + data.error);
                    }
                    else {
                        //Update to eventdata and editcomments immediately for snappier ux
                        const targetIndex = self.eventsData.findIndex(target => moment(target.start_time, 'YYYY-MM-DDTHH:mm:ss').add(-self.jobHourOffset, 'h').isBefore(startMoment));
                        const newCommentId = data[data.length-1].comment_id;

                        self.eventsData.splice(
                            targetIndex, 
                            0, 
                            {
                                ...event, 
                                comment_id: newCommentId,
                                newComment_id: null, 
                                start_time: moment(event.start_time).format('YYYY-MM-DD HH:mm:ss'), 
                                end_time: moment(event.end_time).format('YYYY-MM-DD HH:mm:ss')
                            }
                        );
                        let targetEventIndex = self.eventsData.findIndex(comment => comment.newComment_id == event.newComment_id);
                        self.eventsData.splice(targetEventIndex,1);
                        targetEventIndex = self.editComments.findIndex(comment => comment.newComment_id == event.newComment_id);
                        self.editComments.splice(targetEventIndex,1);
                        const targetNewCommentIndex = self.newEventsData.findIndex(comment => comment.newComment_id == event.newComment_id);
                        self.newEventsData.splice(targetNewCommentIndex,1);
                    }
                },
                'json'
            ).done(() => { })
                .fail((xhr, status, error) => {
                    alert("Could not save comment: " + error);
                })
                .always((msg) => { this.isUpdateLocked = false; });
        },
        saveEditComment(event, index) {
            const startMoment = moment(event.start_time, 'YYYY-MM-DDTHH:mm:ss').add(-this.jobHourOffset, 'h');
            const endMoment = moment(event.end_time, 'YYYY-MM-DDTHH:mm:ss').add(-this.jobHourOffset, 'h');

            if(!startMoment.isValid()) {
                console.warn('startTime is invalid');
                this.errorStartTimeInvalid = true;
                return;
            }
            else{
                this.errorStartTimeInvalid = false;
            }

            if(!endMoment.isValid()) {
                console.warn('endTime is invalid');
                this.errorEndTimeInvalid = true;
                return;
            }
            else{
                this.errorEndTimeInvalid = false;
            }

            if(startMoment > endMoment) {
                console.warn('start time cannot be later than endTime');
                this.errorStartTimeLater = true;
                return;
            }
            else{
                this.errorStartTimeLater = false;
            }
                
            const startTimestamp = moment(startMoment).format('YYYY-MM-DD HH:mm:ss');
            const endTimestamp = moment(endMoment).format('YYYY-MM-DD HH:mm:ss');

            const eventActivity_id = this.getEventActivityIdFromString(event.activity);
            const eventReason_id = this.getEventReasonIdFromString(event.reason);
            const commentText = event.remarks;

            const well_id = event.well_id;
            const jobNumber = this.jobNumber;
            const comment_id = event.comment_id;

            const self = this;
            this.isUpdateLocked = true;

            $.ajax({
                url: '/chart-comments/' + this.jobNumber + '/update',
                method: 'PUT',
                data:
                    {
                        '_token': GlobalFunctions.getCSRFToken(),
                        id: comment_id,
                        jobNumber: jobNumber,
                        text: commentText,
                        start_time: startTimestamp,
                        end_time: endTimestamp,
                        eventactivity_id: eventActivity_id,
                        eventreason_id: eventReason_id,
                        well_id: well_id
                    },
                dataType: 'json'
            })
                .done(() => {
                    //Update to eventdata and editcomments immediately for snappier ux
                    const targetEv = self.eventsData.findIndex(target => target.comment_id == event.comment_id);
                    Vue.set(
                        self.eventsData,
                        targetEv,
                        {
                            ...event, 
                            start_time: moment(event.start_time).format('YYYY-MM-DD HH:mm:ss'), 
                            end_time: moment(event.end_time).format('YYYY-MM-DD HH:mm:ss')
                        }
                    );
                    const targetEventIndex = self.editComments.findIndex(comment => comment.comment_id == event.comment_id);
                    self.editComments.splice(targetEventIndex,1);
                })
                .fail((xhr, status, error) => {
                    alert("Could not save comment: " + error);
                })
                .always((msg) => { this.isUpdateLocked = false; });
        },
        deleteEditComment(event,index) {
            if(confirm('Do you really want to delete this comment?')) {
                const self = this;

                this.isUpdateLocked = true;
                //Should delete the actual comment
                $.ajax({
                    url: '/chart-comments/' + this.jobNumber + '/delete',
                    method: 'PUT',
                    data:
                        {
                            '_token': GlobalFunctions.getCSRFToken(),
                            comment_id: event.comment_id
                        },
                    dataType: 'json'
                })
                    .done((data) => {
                        if(data){ // Laravel returns non-zero if the ->delete() worked.
                            const targetEventIndex = this.eventsData.findIndex(targetEvent => targetEvent.comment_id == event.comment_id);
                            this.eventsData.splice(targetEventIndex,1);
                        }
                        else {
                            alert("Deleting comment failed: Invalid comment ID or comment already deleted.");
                        }
                    })
                    .fail((msg) => {
                        alert("Deleting comment failed:" + msg);
                    })
                    .always((msg) => { this.isUpdateLocked = false; });
            }
        },
        cancelEditComment(event,index) {
            let targetEventIndex = null;
            if(event.newComment_id != null) {
                this.newEventsData.splice(index,1);
                this.eventsData.splice(index,1);
                targetEventIndex = this.editComments.findIndex(comment => comment.newComment_id == event.newComment_id);
            }else{
                targetEventIndex = this.editComments.findIndex(comment => comment.comment_id == event.comment_id);
            }
            this.editComments.splice(targetEventIndex,1);

            this.errorEndTimeInvalid = false;
            this.errorStartTimeInvalid = false;
            this.errorStartTimeLater = false;
        }
    },
    filters: {
        shortTime: function(string) {
            if(string)
            {return string.split('.')[0];}
            return '';
        }
    }
};
</script>

<style scoped>
.popover {
    max-width: 50vw !important;
    background-color: #373A3CBB;
    padding: 0px;
    border-style: solid;
    border-radius: 3.5px;
    border-color: #95989A;
    border-width: thin;
}

.fixed-header table {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.fixed-header thead {
    flex: 0 0 auto;
}

.fixed-header tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 300px;
}

.fixed-header tr {
    width: 100%;
    display: table;
    table-layout: fixed;
}

.form-control-hidetext {
    text-indent: 0px;
    text-overflow: '';
    font-size: 0;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 140px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
    color: black;
    background-color: #ddd;
}

.show {
    display:block;
}

.clickable {
  cursor: pointer;
  border: 0px;
  color: #999999;
}

.clickable:hover {
  color: #FFFFFF;
}

.list-enter-active, .list-leave-active {
  transition: opacity .4s;
}
.list-enter, .list-leave-to {
  opacity: 0;
}

.green {
    color: #01923F
}

</style>
