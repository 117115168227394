<template>
<base-kpi-template ref="baseKpiTemplate"
    :title="item?.options?.includePumpHoursPlotline ? 'Total Stages &amp; Pumping Hours/Day' : 'Total Stages/Day'"
    :item="item"
    :inital-options="initalOptions"
    :edit-mode="editMode"
    :create-export-data="createExportData"
    :analytics-data="analyticsData"
    :errors="errors"
    :show-not-enough-data-warning="showNotEnoughDataWarning"
    :show-chart="showChart"

    @analyticsChanges="buildAnalytics()"
    @settingsChanges="buildData()"
    @revertChanges="buildData()"
>
    <template #settings>
        <iws-checkbox
            label="Include Pump Hours"
            :value.sync="item.options.includePumpHoursPlotline"
            form-spacing
            enable-click-label
            @change="handleShowPumpHoursChange()"
        />

        <iws-checkbox
            label="Stack by Well"
            :value.sync="item.options.isStackedByWell"
            form-spacing
            enable-click-label
            @change="buildData()"
        />
    </template>

    <template #extra-info>
        <div class="extra-info">
            Time Range: {{ getTimeRangeDescription }}
        </div>
    </template>

    <template v-if="showChart" #content>
        <bar-chart v-if="!item?.options?.includePumpHoursPlotline"
            ref="kpi"
            :chart-data="kpi.completedStagesPerDay"
            :options="options.completedStagesPerDay">
        </bar-chart>
        <bar-chart v-else
            ref="kpi"
            :chart-data="kpi.pumpHoursAndStagesPerDay"
            :options="options.pumpHoursAndStagesPerDay">
        </bar-chart>
    </template>
</base-kpi-template>
</template>

<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty } = GlobalFunctions;

import constants from './constants.js';
const {
    TOP_PADDING_KPI,
    DEFAULT_DATE_FORMAT,
    SECONDARY_DATE_FORMAT,
    DAY_STACK_NAME,
    NIGHT_STACK_NAME,
    chartStyle
} = constants;

const moment = require('moment');

import BaseKpi from './BaseKpi.vue';
import BaseKpiTemplate from './BaseKpiTemplate.vue';
import BarChart from '../../../barChart.js';

export default {
    extends: BaseKpi,
    components: { BaseKpiTemplate, BarChart },

    data() {
        return {
            kpi: {
                completedStagesPerDay: {
                    labels: [],
                    datasets: []
                },
                pumpHoursAndStagesPerDay: {
                    labels: [],
                    datasets: []
                }
            },

            options:  {
                completedStagesPerDay: this.buildOptions({
                    yAxisLabel: 'Stages Completed',

                    xTick_callback: value => moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D'),
                    legend: this.item.options.dayType === 'Day shift and night shift' || this.item.options.isStackedByWell
                        ? {
                            display: true,
                            labels: {
                                fontColor: '#CCC',
                                boxWidth: 15,
                                generateLabels: chart => {
                                    const labels = Chart.defaults.global.legend.labels.generateLabels(chart);
                                    
                                    return labels.map(_label => {
                                        _label.text = _label.text.charAt(0).toUpperCase() + _label.text.slice(1);
                                        return _label;
                                    });
                                }
                            }
                        }
                        : null,

                    tooltip_callback: this.item.options.dayType === 'Day shift and night shift'
                        ? (tooltipItem, data) => `Stages Completed (Day): ${data?.datasets[0].data[tooltipItem.index]}<br>Stages Completed (Night): ${data?.datasets[1].data[tooltipItem.index]}`
                        : tooltipItem => `Stages Completed: ${tooltipItem?.value || tooltipItem}`
                }),

                pumpHoursAndStagesPerDay: {
                    cubicInterpolationMode: 'linear', // Set interpolation mode to linear
                    layout: { padding: { top: TOP_PADDING_KPI }},
                    annotation: { annotations: [] },
                    plugins: {
                        paddingBelowLegends: true,
                        datalabels: {
                            display: context => context?.dataset?.type !== 'line', // dont want datalabels on pump hours plotline,
                            color: chartStyle.labelFontColor,
                            font: { size: 16 },
                            formatter: null,
                            anchor: 'end',
                            align: 'top'
                        },
                        zoom: {
                            pan: {
                                enabled: false,
                                mode: 'x',
                                rangeMin: {
                                    x: null
                                },
                                rangeMax: {
                                    x: null
                                }
                            },
                            zoom: {
                                enabled: false,
                                drag: false
                            }
                        }
                    },
                    legend: {
                        onClick: event => event.stopPropagation(), // Disable hiding datasets on legend click
                        display: true,
                        labels: {
                            fontColor: '#CCC',
                            usePointStyle: true,
                            generateLabels: chart => {
                                const originalLabels = Chart.defaults.global.legend.labels.generateLabels(chart);
                                const modifiedLabels = _.cloneDeep(originalLabels);

                                for (let i = 0; i < originalLabels.length; i++) {
                                    const ogLabel = originalLabels[i].text;
                                    const { type, label } = chart.data.datasets[i];

                                    if (type === 'line') {
                                        if (label === 'day' || label === 'night')
                                            modifiedLabels[i].text = ogLabel === 'day' ? 'Pump Time (Day)' : 'Pump Time (Night)'; // Add a custom label for line datasets
                                    } else {
                                        if (label === 'day' || label === 'night')
                                            modifiedLabels[i].text = ogLabel === 'day' ? 'Completed Stages (Day)' : 'Completed Stages (Night)';
                                    }
                                }

                                return modifiedLabels;
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            id: 'x-axis',
                            scaleLabel: {
                                display: true
                            },
                            gridLines: {
                                color: 'transparent'
                            },
                            ticks: {
                                fontColor: chartStyle.labelFontColor,
                                callback: value => moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D')
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.labelFontColor,
                                fontSize: 14,
                                labelString: 'Stages Completed'
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: this.item.options.dayType === 'Day shift and night shift' ? 24 : 12,
                                stepSize: 2,
                                maxRotation: 0,
                                fontColor: chartStyle.labelFontColor
                            },
                            gridLines: {
                                color: chartStyle.gridLinesColor
                            }
                        }, {
                            id: 'right-axis',
                            position: 'right',
                            scaleLabel: {
                                display: true,
                                fontColor: chartStyle.labelFontColor,
                                fontSize: 14,
                                labelString: 'Pump Time (Hours)'
                            },
                            ticks: {
                                beginAtZero: true,
                                suggestedMax: this.item.options.dayType === 'Day shift and night shift' ? 24 : 12,
                                stepSize: 4,
                                maxRotation: 0,
                                fontColor: chartStyle.labelFontColor
                            },
                            gridLines: {
                                display: false
                            }
                        }]
                    },
                    tooltips: {
                        enabled: false,
                        mode: 'nearest',
                        intersect: true,
                        position: 'cursor',
                        animationDuration: 0,
                        backgroundColor: '#000',
                        callbacks: {
                            label: this.item.options.dayType === 'Day shift and night shift'
                            ? (tooltipItem, data) => {
                                if (tooltipItem.datasetIndex < 2) {
                                    // Handle conflicting tooltips
                                    if (data?.datasets[0].data[tooltipItem.index] == data?.datasets[1].data[tooltipItem.index] && tooltipItem.datasetIndex == 0)
                                        return '';

                                    return `
                                        Pumping Hours (Day): ${data?.datasets[0].data[tooltipItem.index]}
                                        <br>
                                        Pumping Hours (Night): ${data?.datasets[1].data[tooltipItem.index]}
                                    `;
                                }

                                return `
                                    Completed Stages (Day): ${data?.datasets[2].data[tooltipItem.index]}
                                    <br>
                                    Completed Stages (Night): ${data?.datasets[3].data[tooltipItem.index]}
                                `;
                            }
                            : tooltipItem => `${tooltipItem.datasetIndex == 0 ? 'Pump Time' : 'Stages Completed'}: ${tooltipItem?.value || tooltipItem}`,
                        },
                        custom: function(tooltipModel) {
                            // Tooltip Element
                            let tooltipEl = document.getElementById('chartjs-tooltip-summary-bar');

                            // Create element on first render
                            if (!tooltipEl) {
                                tooltipEl = document.createElement('div');
                                tooltipEl.id = 'chartjs-tooltip-summary-bar';
                                tooltipEl.innerHTML = '<table></table>';
                                document.body.appendChild(tooltipEl);
                            }

                            // Hide if no tooltip
                            if (tooltipModel.opacity === 0) {
                                tooltipEl.style.opacity = 0;
                                return;
                            }

                            // Set caret Position
                            tooltipEl.classList.remove('above', 'below', 'no-transform');
                            if (tooltipModel.yAlign) {
                                tooltipEl.classList.add(tooltipModel.yAlign);
                            } else {
                                tooltipEl.classList.add('no-transform');
                            }

                            function getBody(bodyItem) {
                                return bodyItem.lines;
                            }

                            // Set Text
                            if (tooltipModel.body) {
                                const titleLines = tooltipModel.title || [];
                                const bodyLines = tooltipModel.body.map(getBody);

                                let innerHtml = '<thead>';

                                titleLines.forEach(function(title) {
                                    const style = 'width : 10px; height: 10px; border-width : 1px;';
                                    innerHtml += '<tr><th><div class="d-flex pr-3"><div class="mx-2 mt-1" style="' + style + '"></div>' + title + '</div></th></tr>';
                                });
                                innerHtml += '</thead><tbody>';


                                bodyLines.forEach(function(body, i) {
                                    if(body && body.length>0) {
                                        innerHtml += '<tr><td><div class="d-flex pr-3" style="padding-left: 5px">' + body + ' </div></td></tr>';
                                    }
                                });
                                innerHtml += '</tbody>';

                                const tableRoot = tooltipEl.querySelector('table');
                                tableRoot.innerHTML = innerHtml;
                            }

                            // `this` will be the overall tooltip
                            const position = this._chart.canvas.getBoundingClientRect();

                            //mouse position
                            let offset = tooltipModel.caretX;

                            //when the tooltip tries to render at the right edge
                            //of the screen, give it more space to the left
                            const averageTooltipWidth = 150;
                            if (tooltipModel.caretX > this._chart.width - averageTooltipWidth)
                            {offset = this._chart.width - averageTooltipWidth;}

                            // Display, position, and set styles for font
                            tooltipEl.style.opacity = 1;
                            tooltipEl.style.position = 'fixed';
                            tooltipEl.style.left = position.left + offset + 'px';
                            tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                            tooltipEl.style.padding = 2 + 'px ' + 0 + 'px';
                            tooltipEl.style.pointerEvents = 'none';
                        }
                    },
                    elements: {
                        point: {
                            radius: 5,
                            hoverRadius: 8
                        }
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            },

            stageData: null,
            pumpData: null,
            dataLookup: {},
            dayNightDataLookup: {}
        }
    },

    computed: {
        // Generically named computed properties to handle changes specific to this KPI
        showNotEnoughDataWarning: function() {
            if (!this.item?.options?.includePumpHoursPlotline)
                return this.allowShowChart && this.isNotEnoughDataMsgPerKPIType(this.kpi.completedStagesPerDay);
            return this.allowShowChart && this.isNotEnoughDataMsgPerKPIType(this.kpi.pumpHoursAndStagesPerDay);
        },
        showChart: function() {
            if (!this.item?.options?.includePumpHoursPlotline)
                return this.allowShowChart && !isNullOrEmpty(this.kpi?.completedStagesPerDay?.datasets[0]?.data);
            return this.allowShowChart && !isNullOrEmpty(this.kpi?.pumpHoursAndStagesPerDay?.datasets[0]?.data);
        }
    },

    methods: {
        // Genericly named function for BaseKPI.vue to call on mounted
        // This creates the KPI in a unique way to the current files needs
        setDefaults: function() {
            this.$set(this.item.options, 'zeroStageType', this.item.options.zeroStageType || 'zeroStage-include');
            this.$set(this.item.options, 'dayType', this.item.options.dayType || 'Shift start to shift start');
            this.$set(this.item.options, 'includePumpHoursPlotline', this.item.options.includePumpHoursPlotline || false);
            this.$set(this.item.options, 'isStackedByWell', this.item.options.isStackedByWell || false);
        },
        initKPI: function() {
            this.allowShowChart = false;

            // Generic template but this can be whatever
            return this.fetchData().then(this.buildData);
        },
        fetchData: async function() {
            // Only make the neccessary api calls based on the api settings
            const promises = [ this.getJobCompletedStagesInfo() ];

            if (this.item?.options?.includePumpHoursPlotline)
                promises.push(this.getPumpTimeData());
                
            return Promise.all(promises);
        },
        buildData: function() {
            this.allowShowChart = false;

            // Default is 'normal' bar charts, iff isStackedByWell, set up the more complicated state
            if (this.item.options?.isStackedByWell)
                this.setupKPIChart('completedStagesPerDay');

            // adding job offset here
            this.dataLookup = this.createLookupByDate(this.stageData.map(activity => {
                return {
                    ...activity,
                    startTime: moment.utc(activity.startTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}),
                    endTime: moment.utc(activity.endTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}),
                    readableLocalEndTime: moment.utc(activity.endTime, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.job.hourOffset}).format(DEFAULT_DATE_FORMAT)
                };
            }));

            if (!isFalsy(this.dataLookup)) {
                if (this.item.options.dayType !== 'Midnight to midnight')
                    this.shiftDatesByStartTime(this.job.shiftStart);
                this.dayNightDataLookup = this.setupDayAndNightData(this.dataLookup);
                this.setupCompletedStagesPerDayHandler(this.kpi.completedStagesPerDay, false);
            
                // Iff includePumpHoursPlotline is enabled, set up the dual chart kpi / options
                if (this.item?.options?.includePumpHoursPlotline) {
                    this.setupKPIChart('pumpHoursAndStagesPerDay');
                    this.processCalculatedKPIResponse('pumpHoursAndStagesPerDay', this.pumpData)
                    this.setupCompletedStagesPerDayHandler(this.kpi.pumpHoursAndStagesPerDay, this.item?.options?.includePumpHoursPlotline);
                } else {
                    this.options.completedStagesPerDay.scales.yAxes[0].ticks.stepSize = this.setChartSmartStepSize(this.kpi?.completedStagesPerDay?.datasets.map(_dataset => _dataset.data).flat(), 1, 1, 10);
                }
            }

            this.allowShowChart = true;

            this.$nextTick(() => {
                // this.createJobTimeAnnotationLines();
                this.buildAnalytics();
            });
        },
        buildAnalytics: function() {
            this.onAnalyticsTypeChange(this.item.options.analyticsType);
            this.buildAnalyticsData();

            this.$nextTick(() => {
                this.assignChartSize();
            });
        },
        createExportData: function() {
            let fields;
            let items;
            let fileName;
            let isDayNight = this.item.options.dayType === 'Day shift and night shift';
            
            if (this.item?.options?.includePumpHoursPlotline) {
                const labels = this.kpi.pumpHoursAndStagesPerDay.defaultFormatLabels;
                const dataSets = this.kpi.pumpHoursAndStagesPerDay.datasets;
                
                if (this.item.options?.isStackedByWell) {
                    const dataSets_stages = dataSets.filter(dataset => !dataset.type);
                    const fieldsStacked = this.processStackedExportFields(dataSets_stages);
                    const pumpHourPlotlineFields = isDayNight ? ['Pump Time (Day)', 'Pump Time (Night)'] : ['Pump Time'];

                    fields = [...fieldsStacked, ...pumpHourPlotlineFields];
                    items = this.processExportItems(dataSets, labels, isDayNight);
                } else {
                    fields = isDayNight ? ['Date', 'Stages (Day)', 'Stages (Night)', 'Pump Time (Day)', 'Pump Time (Night)'] : ['Date', 'Stages', 'Pump Time'];
                    items = this.processExportItems(dataSets, labels, isDayNight);
                }

                fileName = this.getExportFilename(this.item.options.type + '-including-pump-hours');
            } else {
                const labels = this.kpi.completedStagesPerDay.defaultFormatLabels;
                const dataSets = this.kpi.completedStagesPerDay.datasets;

                if (this.item.options?.isStackedByWell) { // Stages need to be added as fields in case of stacked by well
                    fields = this.processStackedExportFields(dataSets);
                    items = this.processStackedExportItems(dataSets, labels);
                } else {
                    fields = isDayNight ? ['Date', 'Day', 'Night'] : ['Date', 'Stages'];
                    items = this.processExportItems(dataSets, labels, isDayNight);
                }

                fileName = this.getExportFilename(isDayNight ? this.item.options.type + '-dayNight' : this.item.options.type);
            }

            return { 
                fields, 
                items, 
                fileName
            };
        },

        async handleShowPumpHoursChange() {
            this.allowShowChart = false;

            // If includePumpHoursPlotline is being enabled and we have not yet fetched the data, do so
            if (this.item?.options?.includePumpHoursPlotline && this.pumpData === null)
                await this.getPumpTimeData();

            this.buildData();
        },
        processStackedExportItems(dataSets, labels) {
            let row;
            let items = [];
            for (let i = 0; i < labels.length; i++) {
                row = [labels[i]]; // Start each row with date label
                for (var k = 0; k < dataSets.length; k++)
                    row.push(dataSets[k].data[i]);
                items.push(row);
            }
            return items;
        },
        processStackedExportFields(dataSets) {
            let label, colon;
            let fields =  ['Date'];

            for (let i = 0; i < dataSets.length; i++) {
                label = dataSets[i].label;
                // "Remove ': ' after label
                colon = label.indexOf(':');
                if (colon != -1)
                    label = label.substring(0, colon);
                fields.push(label);
            }

            return fields;
        },
        async getJobCompletedStagesInfo() {
            return $.get(`/job-kpi-completed-activities/${this.jobNumber}`).then(response => this.stageData = response);
        },
        async getPumpTimeData() {
            return $.get(`/job-kpi-pump-times/${this.jobNumber}`).then(response => this.pumpData = this.pumpHoursToXDecimalPoints(response, 2));
        },
        mapStackedData(dataLookUpDay, wellsInfo, indexStart = 0, groupName = null, dataKpiType) {
            const dataByWellNumber =   _.groupBy(dataLookUpDay, 'wellNumber');
            for (const [index] of Object.entries(wellsInfo)) {
                const datasetIndex = Number(index) + indexStart;
                dataKpiType.datasets[datasetIndex].data.push(dataByWellNumber[index]? dataByWellNumber[index].length : 0);

                if(groupName) {
                    dataKpiType.datasets[datasetIndex].stack = groupName;
                }
            }
        },
        setupKPIChart(kpiType) {
            if(this.item.options?.isStackedByWell) {
                this.options[kpiType].scales.xAxes[0].stacked = true;
                this.options[kpiType].scales.yAxes[0].stacked = true;
                this.options[kpiType].scales.xAxes[0].ticks.fontColor = '#CCCCCC'
                this.options[kpiType].plugins.datalabels.display = function(ctx) {
                    if (!ctx.dataset.stack)
                        return ctx.datasetIndex === ctx.chart.$totalizer.utmost;

                    // grouped stack bar chart ( coded for only 2 groups / example. day and night )
                    let numberOfDataSets, headGroupEndIndex, tailGroupEndIdex, datasetIndex, barDatasets, lineDatasets, isCombinedPumpHoursAndStagesChart;
                    barDatasets = ctx.chart.config.data.datasets.filter(dataset => dataset.type === 'bar' || !dataset.type);
                    lineDatasets = ctx.chart.config.data.datasets.filter(dataset => dataset.type === 'line');

                    isCombinedPumpHoursAndStagesChart = barDatasets?.length > 0 && lineDatasets?.length > 0;
                    if (isCombinedPumpHoursAndStagesChart) {
                        //need to subtract the number of line chart datasets in combined chart
                        //to avoid inproper indexing of datalabels
                        let numberOfLineChartDatasets = lineDatasets.length;
                        numberOfDataSets = ctx.chart.config.data.datasets.length - numberOfLineChartDatasets;
                        datasetIndex = ctx.datasetIndex - numberOfLineChartDatasets;
                    } else {
                        numberOfDataSets = ctx.chart.config.data.datasets.length;
                        datasetIndex = ctx.datasetIndex;
                    }

                    headGroupEndIndex = (numberOfDataSets/2) - 1;
                    tailGroupEndIdex = numberOfDataSets - 1;

                    return datasetIndex === headGroupEndIndex || datasetIndex === tailGroupEndIdex;
                };
                this.options[kpiType].plugins.datalabels.formatter = (value, ctx) => {
                    const stack = ctx.dataset.stack ?? null;
                    const total = ctx.chart.$totalizer.totals[ctx.dataIndex + stack];
                    if (typeof total == 'number') {
                        return total > 0 ? +total.toFixed(2) : 0;
                    } else {
                        return '';
                    }
                };
            } else {
                this.options[kpiType].scales.xAxes[0].stacked = false;
                this.options[kpiType].scales.yAxes[0].stacked = false;
                if (!this.item?.options?.includePumpHoursPlotline) {
                    this.options[kpiType].plugins.datalabels.display = true;
                    this.options[kpiType].plugins.datalabels.formatter = (value, ctx) => {
                        if (!value) {
                            return '';
                        }
                    }
                }
            }
        },
        setupCompletedStagesPerDayHandler: function(dataKpiType, isCombinedPumpHoursAndStagesChart) {
            const isDayNight = this.item.options.dayType === 'Day shift and night shift';
            const wellsById = _.keyBy(this.dashboardData.wells, 'index');
            const numberOfWells = this.dashboardData.wells.length;
            let totalActualStagesCompleted = 0;

            dataKpiType.labels = Object.keys(this.dataLookup).sort();
            dataKpiType.datasets = [{ label: 'Stages Completed', backgroundColor: chartStyle.primaryBarGraphColour, data: [] }];

            if(isDayNight) {
                if(this.item.options?.isStackedByWell) {
                    dataKpiType.datasets = [];
                    const numberOfGroups = 2;
                    for (let dayIndex = 0; dayIndex < numberOfGroups; dayIndex++) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            if (isCombinedPumpHoursAndStagesChart) {
                                dataKpiType.datasets.push({ label: wellInfo.name + ' Stages Completed' + (dayIndex? ' (Night)' : ' (Day)'), backgroundColor: wellInfo.color, data: [] });
                            } else {
                                dataKpiType.datasets.push({ label: wellInfo.name + (dayIndex? ' night' : ' day') + ' Stages Completed', backgroundColor: wellInfo.color, data: [] });
                            }
                        }
                    }
                } else {
                    dataKpiType.datasets = [
                        { label: 'day', backgroundColor: chartStyle.primaryBarGraphColour, data: [] },
                        { label: 'night', backgroundColor: chartStyle.secondaryBarGraphColour, data: [] }
                    ];
                }
            } else if (this.item.options?.isStackedByWell) {
                dataKpiType.datasets = [];
                for (const [index, wellInfo] of Object.entries(wellsById)) {
                    dataKpiType.datasets.push({ label: wellInfo.name + ' Stages Completed', backgroundColor: wellInfo.color, data: [] });
                }
            }

            for (const key of Object.keys(this.dataLookup).sort()) {
                if (isDayNight) {
                    //group
                    if(this.item.options?.isStackedByWell) {
                        this.mapStackedData(this.dayNightDataLookup[key].day, wellsById, 0, DAY_STACK_NAME, dataKpiType);
                        this.mapStackedData(this.dayNightDataLookup[key].night, wellsById, numberOfWells, NIGHT_STACK_NAME, dataKpiType);
                        totalActualStagesCompleted += this.dayNightDataLookup[key].day.length + this.dayNightDataLookup[key].night.length;
                    } else {
                        dataKpiType.datasets[0].data.push(this.dayNightDataLookup[key].day.length);
                        dataKpiType.datasets[1].data.push(this.dayNightDataLookup[key].night.length);
                        totalActualStagesCompleted += this.dayNightDataLookup[key].day.length + this.dayNightDataLookup[key].night.length;
                    }
                } else {
                    if (this.item.options?.isStackedByWell) {
                        this.mapStackedData(this.dataLookup[key], wellsById, 0, null, dataKpiType);
                        totalActualStagesCompleted += this.dataLookup[key].length;
                    } else {
                        dataKpiType.datasets[0].data.push(this.dataLookup[key].length);
                        totalActualStagesCompleted += this.dataLookup[key].length;
                    }
                }
            }
        
            if(!this.job?.start) { return; }

            let kpiDataDays = dataKpiType.labels.map(n => {
                return moment(parseInt(n)).utc()
                    .format('YYYY-MM-DD')
                    .toString();
            });

            // only back fill the graph if we havn't completed all the stages
            if (totalActualStagesCompleted < this.totalStages) {
                function getDates(startDate, stopDate) {
                    var dateArray = [];
                    var currentDate = moment(startDate);
                    var stopDate = moment(stopDate);
                    while (currentDate <= stopDate) {
                        dateArray.push( moment(currentDate) )
                        currentDate = moment(currentDate).add({days: 1});
                    }
                    return dateArray;
                }

                const endDate = isFalsy(this.job.end)
                    ? moment.utc().add({ hours: this.job.hourOffset }).startOf('day')
                    : moment.utc(this.job.end).add({ hours: this.job.hourOffset }).startOf('day');
                const startDate = moment.utc(this.job.start).add({ hours: this.job.hourOffset }).startOf('day');
                let dateRange = getDates(startDate, endDate); //returns days between job start and end or job start and present day

                //adds zeroes to datasets and labels to make sure kpi chart starts at job start and ends at job end or present day
                for(let i = 0; i < dateRange.length; i++) {
                    dateRange = dateRange.reverse();
                    if (moment(dateRange[i]).isBefore(moment(kpiDataDays[0]), 'days')) {
                        kpiDataDays.splice(0, 0, dateRange[i]);
                        for (let index = 0; index < dataKpiType.datasets.length; index++) {
                            dataKpiType.datasets[index].data.splice(0, 0, 0);
                        }
                    }

                    dateRange = dateRange.reverse();
                    if (moment(dateRange[i]).isAfter(moment(kpiDataDays[kpiDataDays.length-1]), 'days')) {
                        kpiDataDays.push(dateRange[i]);
                        for (let index = 0; index < dataKpiType.datasets.length; index++) {
                            dataKpiType.datasets[index].data.splice(kpiDataDays.length-1, 0, 0);
                        }
                    }
                }
            }

            const selectedDayType = this.item.options.dayType ?? this.dayTypes[0];
            dataKpiType.labels = kpiDataDays.map(t => {
                const isDayShiftSelected = this.dayTypes[0] && selectedDayType === this.dayTypes[0];
                const isDayNightShiftSelected = this.dayTypes[2] && selectedDayType === this.dayTypes[2];
                const timestamp = moment.utc(t)
                if (isDayShiftSelected || isDayNightShiftSelected) {
                    const {hours, minutes} = this.getShiftStartTimeInHourAndMinutes()
                    timestamp
                        .hour(hours)
                        .minute(minutes)
                }
                return timestamp
                    .valueOf()
                    .toString();
            });

            //pumptime plotline dataset
            if (isCombinedPumpHoursAndStagesChart) {
                if (isDayNight) {
                    if (this.pumpTimeData.day && this.pumpTimeData.night) {
                        const labels = [...new Set([...Object.keys(this.pumpTimeData.day), ...Object.keys(this.pumpTimeData.night)])].sort();
                        let dayNightDatasets = [
                            {
                                label: 'day',
                                data: [],
                                tension: 0,
                                lineTension: 0,
                                type: 'line',
                                pointStyle: 'rectRot',
                                borderColor: chartStyle.primaryLineGraphColour,
                                pointBackgroundColor: chartStyle.primaryLineGraphColour,
                                pointRadius: 6,
                                pointLabel: null,
                                labels: {
                                    usePointStyle: true, // Use point style for legend symbol
                                },
                                fill: false,
                                yAxisID: 'right-axis',
                            },
                            {
                                label: 'night',
                                data: [],
                                tension: 0,
                                lineTension: 0,
                                type: 'line',
                                pointStyle: 'rectRot',
                                borderColor: chartStyle.secondaryLineGraphColour,
                                pointBackgroundColor: chartStyle.secondaryLineGraphColour,
                                pointRadius: 6,
                                pointLabel: null,
                                labels: {
                                    usePointStyle: true, // Use point style for legend symbol
                                },
                                fill: false,
                                yAxisID: 'right-axis',
                            }
                        ];

                        for(const key of labels) {
                            dayNightDatasets[0].data.push(this.pumpTimeData.day[key]?.duration ?? 0);
                            dayNightDatasets[1].data.push(this.pumpTimeData.night[key]?.duration ?? 0);
                        }
                        dataKpiType.datasets.unshift(...dayNightDatasets);
                    }
                } else {
                    const labels = Object.keys(this.pumpTimeData).sort();
                    dataKpiType.datasets.unshift({
                        label: 'Pump Time (Hours)',
                        data: [],
                        tension: 0,
                        lineTension: 0,
                        type: 'line',
                        pointStyle: 'rectRot',
                        borderColor: chartStyle.primaryLineGraphColour,
                        pointBorderColor: chartStyle.primaryLineBorderGraphColour,
                        pointBackgroundColor: chartStyle.primaryLineGraphColour,
                        pointRadius: 6,
                        pointLabel: null,
                        fill: false,
                        yAxisID: 'right-axis',
                    });
                    for(const key of labels) {
                        dataKpiType.datasets[0].data.push(this.pumpTimeData[key]?.duration ?? 0);
                    }
                }
            }
            this.analyticDatasets = _.cloneDeep(dataKpiType.datasets);
            
            if (dataKpiType.labels.length && dataKpiType.datasets.length) {
                const remainingToGenerate = 10 - dataKpiType.labels.length;
                for(let i = 0; i < remainingToGenerate; i++) {
                    const lastLabel = parseInt(dataKpiType.labels[dataKpiType.labels.length - 1]);
                    const appendedLabel = (lastLabel + parseInt(8.64e+7)).toString();
                    dataKpiType.labels.push(appendedLabel);
                    for (let index = 0; index < dataKpiType.datasets.length; index++) {
                        dataKpiType.datasets[index].data.push(0);
                    }
                }
            }

            // Preserve timestamps in default format for export operations
            dataKpiType.defaultFormatLabels = dataKpiType.labels.map(t => {
                return moment.utc(parseInt(t))
                    .format(DEFAULT_DATE_FORMAT)
                    .toString();
            });

            dataKpiType.labels = dataKpiType.labels.map(t => {
                return moment(parseInt(t)).utc()
                    .format(SECONDARY_DATE_FORMAT)
                    .toString();
            });
        },
        processCalculatedKPIResponse(kpiTypeKey, responseData) {
            if (responseData?.error) {
                console.log(responseData?.message);
                return;
            }

            const isDayNight = this.item.options.dayType === 'Day shift and night shift';
            const defaultDataSetObj = {
                label: kpiTypeKey == 'pumpHoursAndStagesPerDay' ? 'Pump Time (Hours)' : 'Stages Completed',
                labelColor: '#CCCCCC',
                fill: false,
                backgroundColor: chartStyle.primaryBarGraphColour,
                borderColor: chartStyle.primaryBarGraphColour,
                data: []
            };

            //if timeRange is dayNight, then split the lateral length data into seperate collections based on shift times
            if (isDayNight) {
                this.kpi[kpiTypeKey].datasets = [
                    { ...defaultDataSetObj, label: 'day'},
                    { ...defaultDataSetObj, label: 'night', backgroundColor: chartStyle.secondaryBarGraphColour }
                ];

                //shiftData start times only required for day-night calculations currently
                const shiftStartTimes = this.getShiftStartTimes();
                const days = kpiTypeKey === 'pumpHoursAndStagesPerDay' ? {} : [];
                const nights = kpiTypeKey === 'pumpHoursAndStagesPerDay' ? {} : [];
                const labels = new Set(); //collection of only unique values for the date axis labels

                Object.entries(responseData).forEach(record => {//record[0] => dateTime, record[1] => lateral length value
                    //add unique dates to the set of date labels
                    const date = moment(record[0]).format('YYYY-MM-DD');
                    labels.add(date);
                    //compare time of the record to which shift threshold it occurs on
                    if (moment(record[0]).format('HH:mm:ss') >= shiftStartTimes.nightShift
                        || moment(record[0]).format('HH:mm:ss') < shiftStartTimes.dayShift) {
                        kpiTypeKey === 'pumpHoursAndStagesPerDay' ?  nights[date] = record[1] : nights.push(record[1]);
                    } else {
                        kpiTypeKey === 'pumpHoursAndStagesPerDay' ?  days[date] = record[1] : days.push(record[1]);
                    }
                });

                //turn date labels into an array without duplicates dates, use $set to force chart re-render
                this.$set(this.kpi[kpiTypeKey], 'labels', Array.from(labels));

                //populate datasets with filtered data (rounded to 2 decimals but kept as a  Number type)
                if (kpiTypeKey === 'pumpHoursAndStagesPerDay') {
                    this.pumpTimeData =  {};
                    this.pumpTimeData.day = days;
                    this.pumpTimeData.night = nights;
                    this.kpi[kpiTypeKey].datasets[0].data = Object.values(days).map(value=>value.duration);
                    this.kpi[kpiTypeKey].datasets[1].data = Object.values(nights).map(value=>value.duration);
                } else {
                    this.kpi[kpiTypeKey].datasets[0].data = days.map(value=>value);
                    this.kpi[kpiTypeKey].datasets[1].data = nights.map(value=>value);
                }
            } else {
                this.kpi[kpiTypeKey] = {
                    datasets: [
                        { ...defaultDataSetObj }
                    ]
                };

                //Add values based on dates already calculated on the backend, use $set to ensure re-render
                //use unary (+) to enforce Number type of data
                this.$set(this.kpi[kpiTypeKey], 'labels', Object.keys(responseData));
                if(kpiTypeKey === 'pumpHoursAndStagesPerDay') {
                    this.pumpTimeData = responseData;
                    this.kpi[kpiTypeKey].datasets[0].data = Object.values(responseData).map(value=>value.duration);
                } else {
                    this.kpi[kpiTypeKey].datasets[0].data = Object.values(responseData).map(value=>value);
                }
            }

            this.appendZeroEntriesToEnd(this.kpi[kpiTypeKey]);
        },
        pumpHoursToXDecimalPoints(res, X) {
            return _.mapValues(res, (nestedObj) => {
                return _.mapValues(nestedObj, (value) => {
                    return parseFloat(GlobalFunctions.roundAccurately(value, X).toFixed(X));
                });
            });
        },
        setupDayAndNightData: function(lookup) {
            const dayStartHour = this.job.shiftStart;
            const dayNightDataLookup = {};

            Object.entries(lookup).forEach(date => {
                const key = date[0];
                const values = date[1];

                const dataObj = {
                    day: [],
                    night: []
                };

                values.forEach(stage => {
                    const stageEndhours = stage.endTime.hours();

                    if (dayStartHour > 12) { //12 = 12 noon
                        if ((stageEndhours >= dayStartHour && stageEndhours < 24) || (stageEndhours >= 0 && stageEndhours < (dayStartHour + 12))) {
                            dataObj.day.push(stage);
                        } else {
                            dataObj.night.push(stage);
                        }
                    } else {
                        if (stageEndhours >= dayStartHour && stageEndhours < (dayStartHour + 12)) {
                            dataObj.day.push(stage);
                        } else {
                            dataObj.night.push(stage);
                        }
                    }
                });

                dayNightDataLookup[key] = dataObj;
            });

            return dayNightDataLookup;
        }
    }
}
</script>