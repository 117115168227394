<template>
    <iws-modal
        id="comms-position-control"
        title="Settings"
        :show-modal="modalIsOpen"

        max-width="800px"
        @close="resolve(null)"
        @secondary-action="resolve(null)"
        @primary-action="resolve({ audioDevice: selectedAudioDevice})"

    >
        <template #content>
            <div>
                <iws-select
                    :options="audioDevices"
                    label="Audio"
                    @change="audioDeviceChanged"
                    :value="selectedAudioDevice ? selectedAudioDevice : audioOutputId"
                    display-name="name"
                    value-name="id"
                />
                <br/>
                <div class="audiDeviceDisclaimer">
                    <i class="fas fa-info-circle"></i>
                    <span class="mx-2">If you do not see a newly connected device,  please refresh your page to see the device.</span>
                </div>
            </div>
            <div class="mt-2" v-if="isAdmin">
                <h5>Radios</h5>
                <div class="mt-2">
                    <iws-table
                    :items="allSCRUnits"
                    :columns="['SCR-Unit','Room Id','Service Channel', '']"
                    >
                    <template #body>
                        <tr v-for="(scr,index) in allSCRUnits" :key="index">
                        <td>
                            {{ scr.roip_name }}
                        </td>
                        <td>
                            {{ scr.room_id }}
                        </td>
                        <td>

                            <iws-select
                                :value="selectedChannelForSCR(index,scr)"
                                :options="allChannels"
                                display-name="alias"
                                value-name="id"
                                @change="mappingChanged(scr, index,$event)"
                            />
                        </td>
                        <td>
                            <iws-button
                                type="primary"
                                text="Join"
                                :id="'mapButton-'+index"
                                class="d-none"
                                @click="mapSCRToService(scr, index)"
                            />
                        </td>
                    </tr>

                    </template>
                </iws-table>
                <div class="audiDeviceDisclaimer d-none" id="roip-map-warning">
                    <i class="fas fa-info-circle"></i>
                    <span class="mx-2" id="roip-map-warning-text"></span>

                </div>
                </div>

            </div>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { toast } = GlobalFunctions;

import eventBus from '../../eventBus';

export default {
    props:{
        jobNumber: {
            type: String,
            default: ''
        },
        isAdmin: {
            type: Boolean,
            default: false
        },
    },

    data() {
        return {


            resolve: null,
            modalIsOpen: false,
            selectedAudioDevice: null,
            audioDevices:[],
            audioOutputId: null,
            allSCRUnits: [],
            allChannels: [],
            selectedValuesOfChannels: [],
        }
    },
    async mounted(){
        if (this.audioOutputId) {
            this.selectedAudioDevice = this.audioOutputId;
        }
        let devices = null
        navigator.mediaDevices.getUserMedia({audio: true, video: false})
                .then(async (s) => {
          devices = await navigator.mediaDevices.enumerateDevices();
            const audioOutputDevices = devices.filter(device => device.kind === 'audiooutput');
            let deviceMap = {};
            audioOutputDevices.forEach(device => {
                if (device.deviceId !== "default") {
                    let label = device.label;
                    let count = 1;
                    while (deviceMap.hasOwnProperty(label)) {
                        label = `${device.label} (${count++})`;
                    }
                    deviceMap[label] = device.deviceId;
                }
            });
            deviceMap = Object.keys(deviceMap).map(key => {
                return {
                    id: deviceMap[key],
                    name: key
                }
            });
            this.audioDevices = deviceMap;
        })
        this.fetchSCRUnits();
        this.fetchAllChannels();
        this.fetchActiveChannels();
    },

    methods: {
        open(audioOutputId) {
            this.selectedAudioDevice = audioOutputId;
            this.audioOutputId = audioOutputId;``
            this.modalIsOpen = true;
            let self = this;
            this.checkForBrowserCompatibility();
            return new Promise((resolve, reject) => {
                this.resolve = (val) => {
                    self.modalIsOpen = false;
                    resolve(val);
                }
            });
        },
        audioDeviceChanged(device){
            this.selectedAudioDevice = device;
        },
        checkForBrowserCompatibility(){
            //browser compatibility check for speaker selection
            const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
            const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
            const firefoxVersion = navigator.userAgent.toLowerCase().split('firefox/')[1];
            if(isSafari){
                toast({
                    title: 'Browser not supported',
                    body: 'Please use Chrome, Edge or latest version of Firefox to use ROIP speaker selection effectively',
                    variant: 'danger'
                });
            }
            if(isFirefox && firefoxVersion <= 116){
                toast({
                    title: 'Browser not supported',
                    body: 'Please use Chrome, Edge or update Firefox to its latest version to use ROIP speaker selection effectively',
                    variant: 'danger'
                });
            }

        },
        async fetchSCRUnits(){

            if(this.jobNumber){
                await axios.get(`/comms/getAllRoipDeviceList?jobNumber=${this.jobNumber}`).then(response => {
                this.allSCRUnits = response.data;
            });
            }
        },
        async fetchAllChannels(){
            await axios.get(`/comms/getAllChannels?jobNumber=${this.jobNumber}`).then(response => {
                // add a default option for the select
                response.data.unshift({id: 'select-channel', alias: 'General'});
                this.allChannels = response.data;
            });
        },
        async fetchActiveChannels(){
            await axios.get(`/comms/getActiveChannels?jobNumber=${this.jobNumber}`).then(response => {
                this.activeChannels = response.data;
            });
        },
        mappingChanged(scr,index,event){
            //check if it has been reverted back to its original value
            let selectedChannel = this.activeChannels.find(channel => channel.roip_name == scr.roip_name);
            let selectedChannelId = event;
            if(selectedChannel){
                if(selectedChannel.id == selectedChannelId){
                    document.getElementById(`mapButton-${index}`).classList.add('d-none');
                    document.getElementById('roip-map-warning-text').innerText = '';
                    document.getElementById('roip-map-warning').classList.add('d-none');
                }else{
                    //check if the selected channel is already mapped to another scr
                    let mappedSCR = this.activeChannels.find(channel => channel.id == selectedChannelId);
                    if(mappedSCR){
                        // get the channel alias of the newly selected channel
                        let selectedChannelAlias = this.allChannels.find(channel => channel.id == selectedChannelId).alias;
                        let oldMapping = this.activeChannels.find(channel => channel.id == selectedChannelId);
                        let warningText = "Mapping "+selectedChannelAlias+" to "+scr.roip_name+" will invalidate the current service mapping for "+oldMapping.roip_name;
                        document.getElementById('roip-map-warning-text').innerText = warningText;
                        document.getElementById('roip-map-warning').classList.remove('d-none');
                        document.getElementById(`mapButton-${index}`).classList.remove('d-none');

                    }else{
                        if(selectedChannelId == 'select-channel'){
                            document.getElementById('roip-map-warning-text').innerText = '';
                            document.getElementById('roip-map-warning').classList.add('d-none');
                            document.getElementById(`mapButton-${index}`).classList.add('d-none');
                        }else{
                            document.getElementById('roip-map-warning-text').innerText = '';
                            document.getElementById('roip-map-warning').classList.add('d-none');
                            document.getElementById(`mapButton-${index}`).classList.remove('d-none');
                        }
                    }
                }
            }else{
                //new mapping
                let mappedSCR = this.activeChannels.find(channel => channel.id == selectedChannelId);
                if(mappedSCR){
                    // get the channel alias of the newly selected channel
                    let selectedChannelAlias = this.allChannels.find(channel => channel.id == selectedChannelId).alias;
                    let warningText = "Mapping "+selectedChannelAlias+" to "+scr.roip_name+" will invalidate the current service mapping for "+mappedSCR.roip_name;
                    document.getElementById('roip-map-warning-text').innerText = warningText;
                    document.getElementById('roip-map-warning').classList.remove('d-none');
                    document.getElementById(`mapButton-${index}`).classList.remove('d-none');
                }else{
                    // get the channel alias of the newly selected channel
                    document.getElementById('roip-map-warning-text').innerText = '';
                    document.getElementById('roip-map-warning').classList.add('d-none');
                    document.getElementById(`mapButton-${index}`).classList.remove('d-none');
                }
            }
            this.selectedValuesOfChannels[index] = selectedChannelId;
        },
        selectedChannelForSCR(index,scr){
            // find the selected scr.ropi_name from the active channels
            let selectedChannel = this.activeChannels.find(channel => channel.roip_name == scr.roip_name);
            return selectedChannel.id ? selectedChannel.id : 'select-channel';
        },
        mapSCRToService(scr, index, id){
            let selectedChannelId = this.selectedValuesOfChannels[index];

            let oldMapping = this.activeChannels.find(channel => channel.id == selectedChannelId);
            let selectedChannel = this.allChannels.find(channel => channel.id == selectedChannelId);
            let data = {
                roip_name: scr.roip_name,
                channel_id: selectedChannel.id,
                channel_alias: selectedChannel.alias,
                jobNumber: this.jobNumber,
                _token: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
            let self = this;
            axios.post('/comms/mapSCRToService', data).then(response => {
                if(response.data.success){
                    toast({
                        title: 'Success',
                        body: 'Mapped '+scr.roip_name+' to '+selectedChannel.alias+' successfully',
                        variant: 'success'
                    });
                    document.getElementById(`mapButton-${index}`).classList.add('d-none');
                    document.getElementById('roip-map-warning-text').innerText = '';
                    document.getElementById('roip-map-warning').classList.add('d-none');
                    self.fetchSCRUnits();
                    self.fetchAllChannels();
                    self.fetchActiveChannels();
                    //relod the whole component
                    self.modalIsOpen = false;


                    if(oldMapping){
                        // get the index of the old mapping
                        let oldMappingIndex = self.allSCRUnits.findIndex(scr => scr.roip_name == oldMapping.roip_name);
                       this.selectedValuesOfChannels[oldMappingIndex] = 'select-channel';
                    }
                    eventBus.$emit('refreshActiveChannels',scr.roip_name);
                }else{
                    toast({
                        title: 'Error',
                        body: 'Mapping failed',
                        variant: 'danger'
                    });
                }
            });
        }
    },

};
</script>

<style scoped>
    .spacing-botom {
        margin-bottom: 20px;
    }
    .position-card {
        border: 1px solid #6B7380;
        border-radius: 4px;
        padding: 15px 20px;
    }
    .position-card.selected {
        border-color: #29A5FF !important;
    }

    .position-header {
        font-size: 20px;
        margin-top: 10px
    }

    .position-card .circle {
        position: absolute;
        top: 10px;
        right: 10px;

        display: inline-block;
        border: 1px solid white;
        border-radius: 50%;
        height: 25px;
        width: 25px;
        padding: 5px;
    }
    .position-card .circle.active {
        border-color: #29A5FF !important;
    }
    .position-card .circle.active>div {
        border-radius: 50%;
        background-color: #29A5FF;
        height: 13px;
        width: 13px;
    }
    .audiDeviceDisclaimer{
        margin-top:12px;
        display: flex;
        padding: 16px;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--Warning-Warning-500, #F79009);
    }
    .audiDeviceDisclaimer i{
        font-size: 20px;
        color: var(--Warning-Warning-500, #F79009);
    }
</style>
