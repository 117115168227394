<template>
  <!-- Allocate chart inside div container -->
  <div class="h-100 w-100">
    <div :id="chartId" :ref="chartId" :style="'height:' + height + 'px;'" ></div>
  </div>
</template>

<script>
const lcjs = require('@arction/lcjs');

// Extract required parts from LightningChartJS.
const {
    lightningChart,
    SolidLine,
    SolidFill,
    emptyLine,
    ColorRGBA,
    AxisTickStrategies,
    AxisScrollStrategies,
    UIOrigins,
    DataPatterns,
    VisibleTicks,
    Themes,
    ColorHEX,
    UIElementBuilders,
    UIDraggingModes,
    UILayoutBuilders
} = lcjs;

//Needed for production deployments to license the use of lightning charts on deployment
const lightningChartLicence = process.env.MIX_LC_LICENSE || null;

import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';

const CHART_RIGHT_SIDE_OFFSET = 50;
const ORIGIN_LINE_COLOR = ColorRGBA(0, 255, 0, 200);
const MILLIS_PER_HR = 3600000;
const BORDER_HIGHLIGHT_COLOR = '#FFD700'; //gold
const DEFAULT_BORDER_COLOR = '#cacaca'; //grey

export default {
    name: 'LightningChart',
    props: {
        lockToMinChannelsIds: {
            type: Array
        },
        points: {
            type: [Array, Object],
        },
        livePoints: {
            type: [Object]
        },
        stageData: {
            type: [Object]
        },
        showLegend: {
            type: Boolean,
            default: false
        },
        height: {
            type: Number
        },
        chartOptions: {
            type: Object
        },
        job: {
            type: Object
        },
        wells: {
            type: Array
        },
        timeType: {
            type: [String]
        },
        pastHoursInMS: {
            type: [Number]
        },
        clearChartData: {
            type: Boolean,
            default: false
        },
        followingLiveUpdates: {
            type: Boolean
        },
        isLoading: {
            type: Boolean
        }
    },
    created() {
        this.legend = null;
        this.chart = null;
        this.depthSeries = null;
        this.tensionSeries = null;
        this.cclSeries = null;
        this.speedSeries = null;
        this.timeAxis = null;
        this.durationAxis = null;
        this.lineSeries2 = null;
        this.isFirstSeries = true;
        this.bottomOffset = 150;
        this.ticker = false;
    },
    data() {
    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
        return {
            chartId: null,
            isOriginCreated: false,
            strategyIncrement: 0,
            activeSeries: [],
            activeChannelAxis: {},
            activeIndexBy: null,
            fetchErrors: null,
            stageLineCustomTicks: [],
            dataRecord: {},
            stageLineDisplay: null,
            stageEvents: [],
            chartBands: [],
            overlapBands: [],
            chartBandAnnotations: [],
            zoomToStage: {
                zoomState: 'manual',
                zoomInStart: null,
                zoomInEnd: null,
                zoomOutStart: null,
                zoomOutEnd: null,
                anno: null
            }
        };
    },
    computed: {
        channelOptions: function() {
            return this.chartOptions.channelOptions;
        },
        stageBandOptions: function() {
            return this.chartOptions.stageBandOptions;
        },
        axisOptions: function() {
            return this.chartOptions.axisOptions;
        }
    },
    methods: {
        lockToZeroMethod(option,axis) {
            let yVal;
            if (this.lockToMinChannelsIds.includes(option.channelId)) {
                yVal = option.axisMin; //lock to min in case of certain tags like wireline speed
            } else {
                yVal = 0; //lock to zero default value
            }
            let previousScaleStart = 0;
            axis.onScaleChange((start, end) => {
                if (!axis.lockToZero) { //kill process if lock to zero is not set
                    return;
                }

                let newScaleStart = start;
                let newScaleEnd = end;
                let updateInterval = false;

                if(start !== yVal ) {
                    newScaleStart = yVal;
                    updateInterval = true;
                }
                // Only update the interval if there is a need
                if (updateInterval) {
                    axis.setInterval(newScaleStart, newScaleEnd, false, true);
                } else {
                    // update previous scale info
                    previousScaleStart = start;
                }
            });
        },
        updateAnnotationLabelPosition() {
            if (this.chartBandAnnotations.length > 0) {
                const annotations = this.chartBandAnnotations;
                const xAxisInterval = this.chart.getDefaultAxisX().getInterval();
                annotations.forEach(annotation => {
                    if (annotation.annotationXPos < xAxisInterval.start || annotation.annotationXPos > xAxisInterval.end) {
                        annotation.dispose();
                    }
                    if (annotation.isDisposed() && annotation.annotationXPos > xAxisInterval.start && annotation.annotationXPos < xAxisInterval.end) {
                        annotation.restore();
                    }
                    if (!annotation.isDisposed()) {
                        const yAxis = this.activeChannelAxis['default'];
                        if (yAxis.isDisposed()) {
                            yAxis.restore();
                        }
                        annotation.setPosition({x: annotation.annotationXPos, y: yAxis.getInterval().end});
                        yAxis.dispose();
                    }
                });
            }
        },
        populateStageBands() {
            //bail out early if there is no data
            if (this.stageData.stages == null || this.stageData?.stages?.length == 0) {
                return;
            }
            const self = this;
            //draw the stage bands on the chart and label them after load
            const shownStages = this.stageData.stages.filter(
                stage => stage.timestampUnix > self.stageData.queryStartUnix 
                && stage.timestampUnix < self.stageData.queryEndUnix);
            //prefer to use wellNumber as the stageBandkey, but can fall back to wellId if index number isn't available
            const wellKey = ('wellNumber' in shownStages[0]) ? 'wellNumber' : 'wellId';
            
            //Valid stage bands require a place frac to start it and a remove frac to end it.
            for (let index = shownStages.length - 1; index >= 0; index--) {
                const firstStage = shownStages[index];
                //if a stage band has been created already then move on to prevent duplicates
                if (this.chartBands.find(band => band.stageNumber == firstStage.stageNumber && band.wellKey == firstStage[wellKey])) {
                    continue;
                }
                const pairEventType = firstStage.data.requestReason == 'Place Frac'?  'Remove Frac' : 'Place Frac';
                const pairIndex = this.stageData.stages.findIndex(pairStage => 
                    pairStage.data.requestReason == pairEventType
                    && pairStage[wellKey] == firstStage[wellKey]
                    && pairStage.stageNumber == firstStage.stageNumber
                );
                const pairStage = self.stageData.stages[pairIndex];
                //case if the last event is place flag and timeType is current then
                //create a stage band up to the current time for stage in progress
                if (index == shownStages.length -1 && self.timeType == 'current' && firstStage.data.requestReason == 'Place Frac' && pairIndex == -1) {
                    self.createStageBand(firstStage.originDiff, moment.utc().valueOf()-this.job.originUnixMs, firstStage[wellKey], firstStage.stageNumber);                   
                    this.$root.$emit('setActiveStage', firstStage);
                }
                //draw other stages using start and end times of the stage
                else if (pairIndex > -1) {
                    const start = Math.min(firstStage.originDiff, pairStage.originDiff);
                    const end = Math.max(firstStage.originDiff, pairStage.originDiff);
                    self.createStageBand(start, end, firstStage[wellKey], firstStage.stageNumber);
                }
            }
            this.applyOptionChanges(); //update stage bands to current settings
        },
        createStageBand: function(startOriginDiff, endOriginDiff, wellKey, stageNumber) {
            const self = this;
            const well = this.wells.find(well => well.index == wellKey || well.id == wellKey);
            if (!well) {
                console.error('MultiWellTimeline Stage band creation error: unable to find the desired well');
                this.$emit('onError',{
                    message: `Unable to attribute stage data to well: ${wellKey} If the error persists, please contact your administrator.`,
                    wellKey: wellKey,
                    title: 'Stage Band Creation Error',
                    type: 'stageBand'
                });
                return;
            }
            const xAxis = this.chart.getDefaultAxisX();
            const newBand = xAxis.addBand();
            //record the original position of the band in case it is modified
            newBand.originalStartMS = startOriginDiff;
            newBand.originalEndMS = endOriginDiff;
            newBand.setValueStart(startOriginDiff);
            newBand.setValueEnd(endOriginDiff);
            //set up options for the wellBand
            newBand.setMouseInteractions(false);
            const color = GlobalFunctions.isValidHexColor(well?.color)? well.color : '#000';
            const opacity = well? self.stageBandOptions[well.id].opacity: 90;
            newBand.setFillStyle(new SolidFill({color: ColorHEX(color)}));
            newBand.setFillStyle((solidFill) => solidFill.setA(opacity));
            newBand.wellKey = wellKey;
            newBand.stageNumber = stageNumber;
            self.chartBands.push(newBand);

            //attach stage bands and labels to the default axis so their 
            //position is independant of whether a data axis is shown or hidden
            const yAxis = this.activeChannelAxis['default'];
            if (yAxis.isDisposed()) {
                yAxis.restore();
            }
            //give each stage band a label of it's well name
            const annotationXPos = (startOriginDiff + endOriginDiff) / 2;
            const annotation = self.chart.addUIElement(UIElementBuilders.TextBox, 
                {x: xAxis, y: yAxis})
                .setPosition({x: annotationXPos, y: yAxis?.getInterval()?.end})
                .setBackground(style => style 
                    .setFillStyle(new SolidFill({ color: ColorHEX('#fff') }))
                )
                .setOrigin(UIOrigins.CenterTop)
                .setText(well.wellName + ' - ' + stageNumber);
            
            annotation.setDraggingMode(UIDraggingModes.notDraggable);
            annotation.annotationXPos = annotationXPos;
            annotation.wellKey = wellKey;
            annotation.stageNumber = stageNumber;
            annotation.bandStart = startOriginDiff;
            annotation.bandEnd = endOriginDiff;
            annotation.chart = this.chart;
            annotation.chartContainer = this;

            annotation.onMouseDoubleClick((anno, event) => {
                //stop tracking live data so it doesn't try to adjust the viewing interval with signalR updates
                anno.chartContainer.$root.$emit('stopLiveUpdates');

                const xAxis = anno.chart.getDefaultAxisX();
                if (xAxis.getScrollStrategy() != undefined) { //chart view interval must be manually set to keep up with live data
                    xAxis.setScrollStrategy(undefined);
                }
                if (anno.chartContainer.zoomToStage.zoomState != 'in') {
                    //give the annotation a gold border to indicate it is in zoom in state
                    anno.setBackground(bg => bg.setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX(BORDER_HIGHLIGHT_COLOR)))));
                    anno.chartContainer.zoomToStage = {
                        anno: anno,
                        zoomState: 'in',
                        zoomInStart: anno.bandStart,
                        zoomInEnd: anno.bandEnd,
                        zoomOutStart: xAxis.getInterval().start, //preserves the original xAxis Interval to return to
                        zoomOutEnd: xAxis.getInterval().end
                    };
                    anno.chartContainer.setChartWindowInterval(anno.bandStart, anno.bandEnd);
                }
                else if (anno.chartContainer.zoomToStage.zoomState == 'in') {
                    //return border to default color
                    const zoomedAnno = anno.chartContainer.zoomToStage.anno;
                    zoomedAnno.setBackground(bg => bg.setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX(DEFAULT_BORDER_COLOR)))));
                    anno.chartContainer.zoomToStage.zoomState = 'out';         
                    anno.chartContainer.setChartWindowInterval(anno.chartContainer.zoomToStage.zoomOutStart, anno.chartContainer.zoomToStage.zoomOutEnd);
                    xAxis.setScrollStrategy(AxisScrollStrategies.expansion);
                }
            });
            yAxis.dispose();
            self.chartBandAnnotations.push(annotation);

            //search for intercepts with other stage bands to generate overlap bands
            const intercepts = self.chartBands.filter(band => 
                band.getValueStart() < endOriginDiff && startOriginDiff < band.getValueEnd()
                && !(band.wellKey == wellKey && band.stageNumber == stageNumber));
            if (intercepts.length == 1) { //case where the newly created band was found to intersect another
                //add newBand to the array also, then iteratate through the array comparing each element only with elements of a higher index than it
                intercepts.push(newBand);
                const newOverlap = xAxis.addBand();
                //Examine all unique combinations of 2 selected bands out of the entire array of band intercepts
                for (let baseIndex = 0; baseIndex < intercepts.length; baseIndex++) {
                    const firstStage = intercepts[baseIndex];
                    for (let compareIndex = baseIndex+1; compareIndex < intercepts.length; compareIndex++) {
                        //for each intercept, determine the latest 'start', and the earliest 'end' which is the span of the overlap
                        const secondStage = intercepts[compareIndex];

                        const overlapStart = Math.max(firstStage.getValueStart(), secondStage.getValueStart());
                        const overlapEnd = Math.min(firstStage.getValueEnd(), secondStage.getValueEnd());
                    
                        const wells = self.wells.filter(well => well.id == firstStage.wellKey || well.index == firstStage.wellKey
                            || well.id == secondStage.wellKey || well.index == secondStage.wellKey);
                        
                        const colorOptions = {stops: []};
                        wells.forEach(well => {
                            const wellKey = well.id? well.id : well.wellNumber;
                            const colorHex = GlobalFunctions.isValidHexColor(well?.color)? well.color : '#000';
                            const opacity = well? self.stageBandOptions[well.id].opacity: 90;
                            let color = ColorHEX(colorHex);
                            color = color.setA(opacity); //color is an immutable object, so a new value must be created 

                            const linearGradientSegment =  {
                                color: color,
                                offset: 1/intercepts.length, //percent of band to occupy,
                                well: wellKey
                            };
                            colorOptions.stops.push(linearGradientSegment);
                           
                            if (!newOverlap?.wellKeys) {
                                newOverlap.wellKeys = [];
                            }
                            newOverlap.wellKeys.push(wellKey);
                        });
                        newOverlap.setValueStart(overlapStart);
                        newOverlap.setValueEnd(overlapEnd);
                        newOverlap.setMouseInteractions(false);
                        newOverlap.setFillStyle(new lcjs.LinearGradientFill(colorOptions));
                        newOverlap.originBands = intercepts;
                        newOverlap.originalStartMS = overlapStart;
                        newOverlap.originalEndMS = overlapEnd;

                        self.overlapBands.push(newOverlap);

                        //Change the span/visibility of the original stage bands so that the overlay bands draw on the chart
                        //directly, and not over the existing bands, otherwise the opacity value will not be properly shown
                        //This is fine for how it is for now, but to handle 3+ overlaps trimming the original stages will likely need to be refactored
                        self.trimOverlappedStageBands(intercepts, overlapStart, overlapEnd);
                    }
                }
            } else if (intercepts.length > 1) {
                //To Do: Refactor stage overlap handling to be able to handle N amount of overlapped stages, 
                //for the cases of SF and CF jobs.
                console.error('Error: Intercept of more than 2 well stages is detected. Handling 3+ well stage intersections has not yet been implemented');
            }
        },
        trimOverlappedStageBands(stageBandArray, overlapStart, overlapEnd) {
            stageBandArray.forEach(stageBand => {
                if (stageBand.originalStartMS < overlapEnd) { //right side case -> band start needs to be moved to overlap end
                    stageBand.setValueStart(overlapEnd);
                } else if (stageBand.originalEndMS > overlapStart) {//left side case -> band end needs to be moved to overlap start
                    stageBand.setValueEnd(overlapStart);
                } 
                //stage band and overlap band exactly match, so hide original band
                else if (stageBand.originalStartMS == overlapStart && stageBand.originalEndMS == overlapEnd) {
                    stageBand.setValueEnd(stageBand.originalStartMS); //hide stage band by giving it no width
                }
            });
        },
        modifyStageBand(stage, startDiff=null, endDiff=null) {
            const stageBand = this.chartBands.find(band => 
                (band.wellKey == stage.wellId || band.wellKey == stage.wellNumber) && band.stageNumber == stage.stageNumber);
            const annotation = this.chartBandAnnotations.find(anno => 
                (anno.wellKey == stage.wellId || anno.wellKey == stage.wellNumber) && anno.stageNumber == stage.stageNumber);
            if (startDiff) {
                stageBand.setValueStart(startDiff);
            }
            if (endDiff) {
                stageBand.setValueEnd(endDiff);
            }
            const annotationXPos = (stageBand.getValueStart() + stageBand.getValueEnd()) / 2;
            const yAxis = this.activeChannelAxis['default'];
            if (yAxis.isDisposed()) {
                yAxis.restore();
            }
            const yPos = yAxis.getInterval()?.end;
            annotation.setPosition({x: annotationXPos, y: yPos});
            annotation.annotationXPos = annotationXPos;
            yAxis.dispose();
        },
        getStageLineColor: function(wellId=null) {
            let color = this.stageBandOptions.color ?? '#000'; //defaults to black
            if (this.stageBandOptions.useWellColor && wellId) {
                color = this.stageBandOptions.wellColors[wellId];
            }
            return color;
        },
        setChartWindowInterval(start, end, rightSideOffset=0,animateTransitions=true, preventScrollAfter=false) {
            this.chart.getDefaultAxisX().setInterval(start,end + rightSideOffset, animateTransitions, preventScrollAfter);
        },
        switchChannelToAxis(channelId,newAxisId) {
            const channel = this.chartOptions.channelOptions[channelId];
            const seriesIndex = this.activeSeries.findIndex(series => series.tagId == channel.id);
            //if the series has not been loaded into the chart yet, axis setup will occur during data load instead
            if (seriesIndex > -1) {            
                const channelData = this.dataRecord[channelId].data;
                //data from old series required to build the new one                     
                const oldSeries = this.activeSeries.find(series => series.tagId == channel.id);
                const seriesName = oldSeries.getName();
                const tagId = oldSeries.tagId;
                const tagName = oldSeries.tagName;
                let newAxis = this.activeChannelAxis[newAxisId];
                if (newAxis && newAxis.isDisposed()) {
                    newAxis.restore();
                }
                //check to ensure that the relevant axis to switch to is created. If not, create it
                if (!newAxis) {
                    newAxis = this.createChannelAxis(tagId, tagName, null, newAxisId);
                }
                const newSeries = this.createSeries(seriesName, tagName, tagId, newAxisId);
                this.setDataForSeries(channelData, newSeries);
                //delete the old series and remove it from the record
                oldSeries.dispose();
                this.activeSeries.splice(seriesIndex,1);
                //if the former axis of the series has no data applied to it, dispose the axis as well
                const oldAxis = this.activeChannelAxis[oldSeries.axisId];
                const axisSeriesIndex = oldAxis.series.findIndex(series => series.tagId == channelId);
                oldAxis.series.splice(axisSeriesIndex, 1);
                if (oldAxis.series.length === 0) {
                    oldAxis.dispose();
                    delete this.activeChannelAxis[oldSeries.axisId];
                }
            }
        },
        applyOptionChanges() {
            const self = this;
            const usedAxesIds = new Set();
            //apply changes to channel options
            Object.values(this.chartOptions.channelOptions).forEach(channel => {
                const series = this.activeSeries.find(series => series.tagId == channel.id);
                if (series) {
                    if (channel.show) {
                        usedAxesIds.add(channel.axisId);
                        if (series.isDisposed()) {
                            series.restore();
                        }
                    } else {
                        series.dispose();
                    }
                    if (!series.isDisposed()) {
                        const seriesStrokeStyle = new SolidLine({ 
                            fillStyle: new SolidFill({ color: ColorHEX(channel.color)}),
                            thickness: channel.thickness
                        });
                        series.setStrokeStyle(seriesStrokeStyle);
                    }
                    if (channel?.hasAxisChanged) {
                        self.switchChannelToAxis(channel.id, channel.axisId);
                    }
                }
            });
            //apply changes to stage bands and overlap bands if they exist
            Object.values(this.stageBandOptions).forEach(option => {
                const wellBands = self.chartBands.filter(band => band.wellKey == option.wellId || band.wellKey == option.wellNumber);
                const annotations = self.chartBandAnnotations.filter(anno => anno.wellKey == option.wellId || anno.wellKey == option.wellNumber);
                const overlapBands = self.overlapBands.filter(overlap => overlap.wellKeys.includes(option.wellId) || overlap.wellKeys.includes(option.wellNumber));
                if ((option?.show !== undefined && option?.show !== null) && !option?.show) {
                    wellBands.forEach(band => {
                        band.dispose();
                    });
                    annotations.forEach(anno => {
                        anno.dispose();
                    });
                    overlapBands.forEach(overlapBand => {
                        const bandFillStyle = overlapBand.getFillStyle();
                        bandFillStyle.stops.forEach(stop => {
                            //if a well that is part of an overlap band has been hidden, hide that overlap band
                            //and show the original band part of it that was not hidden
                            const c = stop.color;
                            const newColor = ColorRGBA(c.getR(), c.getG(), c.getB(), 0); //set alpha to 0 to hide overlap band
                            stop.color = newColor;
                            overlapBand.originBands.forEach(band => { 
                                //return original bands to proper viewing size (different handling is likely required for 3+ overlaps)
                                band.setValueStart(band.originalStartMS);
                                band.setValueEnd(band.originalEndMS);
                            });
                        });
                    });
                } else {
                    wellBands.forEach(band => {
                        if (band.isDisposed()) {
                            band.restore();
                        }
                        band.setFillStyle(solidFill => solidFill.setA(option.opacity));
                    });
                    annotations.forEach(anno => {
                        if (anno.isDisposed()) {
                            anno.restore();
                        }
                    });
                    overlapBands.forEach(overlapBand => {
                        //Overlap band should only be present if both all it's origin creation bands are shown
                        if (overlapBand.originBands.every(band => !band.isDisposed())) { 
                            //if both wells that compose an overlap band are shown, draw the overlap band               
                            const bandFillStyle = overlapBand.getFillStyle();
                            bandFillStyle.stops.forEach(stop => {
                                //match stop value by well Key to only affect part of the overlap band
                                if (stop.well == option.wellId || stop.well == option.wellNumber) {                                  
                                    stop.color = stop.color.setA(option.opacity);                                      
                                } else {
                                    //get options for other sides of the band
                                    const otherOption = Object.values(self.stageBandOptions).find(option => option.wellId == stop.well || option.wellNumber == stop.well);
                                    stop.color = stop.color.setA(otherOption.opacity);     
                                }
                            });
                            //reduce size of origin bands to outside the overlap range.
                            self.trimOverlappedStageBands(overlapBand.originBands, overlapBand.originalStartMS, overlapBand.originalEndMS);
                        }
                    });
                }
            });
            //apply changes to chart axes
            Object.values(this.axisOptions).forEach(option => {
                const axis = self.activeChannelAxis[option.id];
                if (axis) {
                    if (option.returnToDefaults) {
                        axis.setMouseInteractions(true);
                        axis.setInterval(option.axisMin, option.axisMax, true, false);
                    }
                    if (option.autoFit && !option.lockToZero) {
                        axis.setScrollStrategy(AxisScrollStrategies.fitting);
                        axis.fit();
                    } 
                    if (option.lockToZero) {
                        axis.setScrollStrategy(AxisScrollStrategies.expansion);
                        //applies lock to zero/lock to min functionality to axis
                        this.lockToZeroMethod(option, axis);
                        axis.setInterval(0, axis.getInterval().end);
                    }
                    if (!option.autoFit && !option.lockToZero) {
                        if (axis.getScrollStrategy() != undefined) { //chart view interval must be manually set to keep up with live data
                            axis.setScrollStrategy(undefined);
                        }
                        axis.setInterval(option.axisMin, option.axisMax, true, false);
                    }
                    axis.lockToZero = option.lockToZero;
                    let title = option.label;
                    if (option?.unit != '' && option?.unit != null) {
                        title += ` (${option.unit})`;
                    }
                    axis.setTitle(title);

                    //hide axes if they do not have visible data
                    if (usedAxesIds.has(axis.id)) {
                        if (axis.isDisposed()) {
                            axis.restore();
                        }
                    } else {
                        if (!axis.isDisposed()) {
                            axis.dispose();
                        }
                    }
                }
            });
        },
        clearChart() {//parent component should also call this to clean data before a new data set loads
            //If legend current exists destroy it
            if(this?.legend) {
                this.legend.dispose();
                this.legend = null;
            }
            //Destroy all of the current channels
            for(let i = 0; i < this.activeSeries.length; i++) {
                this.activeSeries[i].dispose();
                this.activeSeries = [];
            }
            //Destroy any existing axes
            if (Object.keys(this.activeChannelAxis).length > 0) {
                const axes = Object.values(this.activeChannelAxis);
                axes.forEach(axis => {
                    axis.dispose();
                }); 
                this.activeChannelAxis = {};
            }
            //Destroy the current origin line
            if(this.activeIndexBy != null) {
                this.activeIndexBy.dispose();
                this.activeIndexBy = null;
                this.isOriginCreated = false;
            }         
            //clear out stage bands
            if (this.chartBands.length > 0) {
                this.chartBands.forEach(band => {
                    band.dispose();
                });
                this.chartBands = [];
                this.chartBandAnnotations.forEach(anno => {
                    anno.dispose();
                });
                this.chartBandAnnotations = [];
            }
            if (this.stageEvents.length > 0) {
                this.stageEvents = [];
            }
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
        },
        createNewLightningChart() {
            this.createChart();
            setTimeout(()=>{ // used to fix height issues, giving the process to a different thread. ( need to run as the last process of the chart )
                this.chart.engine.layout();
            }, 0);
            this.dataRecord = {};
            this.$root.$emit('chartDataCleared');
            //required for quick add data load so other functions can access the chart reference
            this.$forceUpdate(); 
        },
        createSeries(seriesName, tagName, tagId, axisId) {
            let randomColor = this.channelOptions[tagId]?.color? this.channelOptions[tagId]?.color: GlobalFunctions.getRandomColor("dark");
            const seriesStrokeStyle = new SolidLine({ 
                fillStyle: new SolidFill({ color: ColorHEX(randomColor) }), 
                thickness: this.channelOptions[tagId]?.thickness || 2 
            });
            const yAxis = this.activeChannelAxis[axisId];
            if (yAxis.isDisposed()) {
                yAxis.restore();
            }
            const newSeries = this.chart.addLineSeries({ 
                dataPattern: DataPatterns.horizontalProgressive,
                yAxis: this.activeChannelAxis[axisId]
            }).setName(seriesName)
                .setStrokeStyle(seriesStrokeStyle);
            //Lightning Charts obfuscates variables after creation, this makes them easy to find
            newSeries.axisId = axisId;
            newSeries.yAxis = this.activeChannelAxis[axisId];
            newSeries.tagId = tagId;
            newSeries.tagName = tagName;
            this.activeSeries.push(newSeries);
            //Add to the on axis record of which series are attached to it
            this.activeChannelAxis[axisId].series.push(newSeries);

            return this.activeSeries[this.activeSeries.length -1];
        },
        createChannelAxis(tagId, tagName, friendlyName=null, axisId) {
            //Should check if the axis already exists here and bail out early if it does
            if(this.activeChannelAxis[axisId] != null) { return; }
            const axisOptions = this.chartOptions.axisOptions[axisId];
            let newAxis = null;
            newAxis = this.chart.addAxisY({
                type: 'linear',
                opposite: axisOptions.location != 'default'
            });
            newAxis.setTickStrategy(AxisTickStrategies.Numeric, (styler) =>
                styler
                    .setMajorTickStyle(tickStyle => tickStyle
                        .setGridStrokeStyle(emptyLine))
                    .setMinorTickStyle(tickStyle => tickStyle
                        .setGridStrokeStyle(emptyLine))
            );
            if (axisOptions.autoFit == false) {
                if (newAxis.getScrollStrategy() != undefined) { //chart view interval must be manually set to keep up with live data
                    newAxis.setScrollStrategy(undefined);
                }   
                newAxis.setInterval(axisOptions.axisMin, axisOptions.axisMax,true, false);
            }
            newAxis.lockToZero = axisOptions.lockToZero;
            if (axisOptions.lockToZero) {
                //applies lock to zero/lock to min functionality to axis
                newAxis.setScrollStrategy(AxisScrollStrategies.expansion);
                this.lockToZeroMethod(axisOptions, newAxis);
            }
            //keep annotation labels locked to the chart top and hidden if outside visible range
            newAxis.onScaleChange(() => {
                this.updateAnnotationLabelPosition();
            });

            let title = axisOptions.label;
            if (axisOptions?.unit != '' && axisOptions?.unit != null) {
                title += ` (${axisOptions.unit})`;
            }
            newAxis.setTitle(title);
            newAxis.id = axisId;
            newAxis.series = []; //array of each series that is attached to this axis

            this.activeChannelAxis[axisOptions.id] = newAxis;
            return newAxis;
        },
        createOriginLine(targetIndexBy) {
            //Add vertical line @ origin
            const xAxis = this.chart.getDefaultAxisX();
            this.activeIndexBy = xAxis.addConstantLine()
                .setName('Place Frac / Remove Frac on Well')
                .setValue(0)
                .setStrokeStyle(new SolidLine({
                    thickness: 2,
                    fillStyle: new SolidFill({ color: ColorHEX('000') })
                }))
                .setMouseInteractions(false);
        },
        createChart() {     
            // Create chartXY
            if(lightningChartLicence) {
                this.chart = lightningChart(
                    lightningChartLicence
                ).ChartXY({
                    container: `${this.chartId}`,
                    theme: Themes.light
                });
            }else{
                this.chart = lightningChart().ChartXY({
                    container: `${this.chartId}`,
                    theme: Themes.light
                });
            }
            

            this.chart.setPadding({
                right: CHART_RIGHT_SIDE_OFFSET
            });
            
            this.chart.setTitle('Multi-Well Timeline Variation Chart');

            // Enable AutoCursor auto-fill.
            this.chart.setAutoCursor(cursor => cursor
                .setResultTableAutoTextStyle(true)
                .disposeTickMarkerX()
                .setTickMarkerYAutoTextStyle(true)
            );
            //Add time label and job origin (adjusted to job local time) to x-axis
            const localTimeOriginDate = moment.utc(this.job.start, 'YYYY-MM-DD HH:mm:ss').add(this.job.hourOffset, 'h').format('YYYY-MM-DDTHH:mm:ss');
            // const originDate = new Date(this.job.start);
            const originDate = new Date(localTimeOriginDate);
            const xAxis = this.chart.getDefaultAxisX()
                .setTickStrategy(
                    AxisTickStrategies.DateTime,
                    (tickStrategy) => tickStrategy.setDateOrigin(originDate)
                        .setMajorTickStyle((tickStyle) => tickStyle.setTickLength(15))
                        .setMinorTickStyle((tickStyle) =>tickStyle.setTickLength(10))
                );
            xAxis.onScaleChange(() => {
                this.updateAnnotationLabelPosition();
            });

            this.chart.chartContainer = this;
            //when the user pans or zooms the chart, stop adjusting the chart window to track live data
            this.chart.onSeriesBackgroundMouseDrag(function(chart,event) {
                if (chart.chartContainer.timeType == 'current') {
                    chart.chartContainer.$root.$emit('stopLiveUpdates');
                }
            });
            this.chart.onSeriesBackgroundMouseWheel(function(chart, event) {
                if (chart.chartContainer.timeType == 'current') {
                    chart.chartContainer.$root.$emit('stopLiveUpdates');
                }
                if (chart.chartContainer.zoomToStage.zoomState !== 'manual') {
                    chart.chartContainer.zoomToStage.anno.setBackground(bg => bg.setStrokeStyle((lineStyle) => lineStyle.setFillStyle(fillStyle => fillStyle.setColor(ColorHEX(DEFAULT_BORDER_COLOR)))));
                    chart.chartContainer.zoomToStage = {
                        zoomState: 'manual',
                        zoomInStart: null,
                        zoomInEnd: null,
                        zoomOutStart: null,
                        zoomOutEnd: null,
                        anno: null
                    };
                    chart.getDefaultAxisX().setScrollStrategy(AxisScrollStrategies.expansion);
                }
            });
            
            xAxis.setTitle('Date');
            this.chart.engine.layout();

            if (this.showLegend) {
                this.legend = this.chart.addLegendBox();
                this.legend.add(this.chart);
            }
            
            //save a record of default y axis (to attach data independant UIElements to), then dispose of it to keep chart view clean
            this.activeChannelAxis['default'] = this.chart.getDefaultAxisY();
            this.chart.getDefaultAxisY().dispose();
        },
        printChartToImage() {
            this.chart.saveToFile(this.chart.getTitle() + ' - Screenshot-' + moment().format('YYYY-MM-DD HH:mm:ss'));
        },
        //To do: find a way to make this non blocking. either webworker or direct ADX call instead
        convertJsonToCSV(jsonData) {
            const self = this;
            const tags = Object.keys(jsonData);
            //get a list of used tagNames for column headers in tag priority order
            const tagNames = Object.values(jsonData).map((channel) => {
                if (channel?.friendlyName) {
                    return channel.friendlyName;
                } else if (channel?.tagName) {
                    return channel.tagName;
                } else {
                    return channel.tagId;
                }});
            const tagValueIndex = {};
            //tagValueIndex keeps track of which channel value is next to be added to a csv row if it's timestamp is valid
            tags.forEach((tag,index)=>{
                tagValueIndex[tag] = 0;
            });
            let returnCSV = '';
            const header = ['Local Date Time','Unix Time', ...tagNames];
            returnCSV += header.join(',') + '\r\n';

            let isCSVConversionFinished = false;
            do  {
                //get the next lowest timestamp for out of all the tags that hasn't been processed so far to start the next csv row
                let nextLowestTime = null; 
                Object.values(tagValueIndex).forEach((tag, index) => {
                    const tagId = tags[index];
                    const rawTimestamp = jsonData[tagId].data[tagValueIndex[tagId]]?.rawTimestamp ?? null;
                    if (rawTimestamp && (rawTimestamp < nextLowestTime || nextLowestTime == null)) {
                        nextLowestTime = jsonData[tagId].data[tagValueIndex[tagId]].rawTimestamp;
                    }
                });
                //process all tags at the current lowest timestamp. 
                //If a tag does not have a value for that time then give it a blank value for the csv
                const dateTime = moment.utc(nextLowestTime).add(this.job.hourOffset, 'h').format('YYYY-MM-DD HH:mm:ss');
                const tagColumns = [];
                tags.forEach((tag,index)=>{
                    const tagTimestamp = jsonData[tag].data[tagValueIndex[tag]]?.rawTimestamp;
                    //foreach tag, get it's 'y axis' value at the current timestamp time, or supply a blank string if the tag has no data there
                    if (tagTimestamp != null && tagTimestamp == nextLowestTime) {
                        tagColumns[index] = jsonData[tag].data[tagValueIndex[tag]].y;
                        tagValueIndex[tag]++;
                    } else {
                        tagColumns[index] = '';
                    }
                });
                //append the current data row data to the collection
                const row = [dateTime, nextLowestTime, ...tagColumns];
                returnCSV += row.join(',') + '\r\n';

                //To do: find a better day of checking if loop is finished
                //keep looping through data until all tag records have been processed
                for (const index in tags) {
                    const tagId = tags[index];
                    if (tagValueIndex[tagId] <= jsonData[tagId].data.length - 1) {
                        isCSVConversionFinished = false;
                        break;
                    } else {
                        isCSVConversionFinished = true;
                    }
                }      
            } while (!isCSVConversionFinished);
            return returnCSV;
        },
        exportChartData(fileType,rangeType) {
            let blob = null;
            const exportData = this.dataRecord;
            if (rangeType == 'visibleInterval') {
                const xAxisInterval = this.chart.getDefaultAxisX().getInterval();
                for (const tagId in this.dataRecord) {
                    const recordSet = this.dataRecord[tagId].data.filter(data => data.x >= xAxisInterval.start && data.x <= xAxisInterval.end);
                    exportData[tagId].data = recordSet;
                }
            }
            if (fileType == 'csv') {
                blob = new Blob([this.convertJsonToCSV(exportData)], {type: 'text/json'});
            } else {
                blob = new Blob([JSON.stringify(exportData)], {type: 'text/json'});
            }        
            //create a link element, initiate download with a click event, then remove the link
            const downloadLink = document.createElement('a');
            downloadLink.download = 'AnalysisChart-' + moment().format('YYYY-MM-DD-HH:mm:ss') + '.' + fileType;
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.dataset.downloadurl = ['text/json', downloadLink.download, downloadLink.href].join(':');

            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            downloadLink.dispatchEvent(event);
            downloadLink.remove();
        },
        setDataForSeries(data, series) {
            const chartSeries = series;

            if(chartSeries) {
                chartSeries.clear();
                chartSeries.add(data);
            }
        },
        addLegend() {
            if(this.showLegend) {
                if(this.legend) {
                    this.legend.dispose();
                }
                this.legend = this.chart.addLegendBox();
                //adds only the series names to the legend, and not constant lines or bands
                this.chart.getSeries().forEach(series => { 
                    this.legend.add(series,{disposeOnClick: false}); //prevent legend clicks for affecting series visibility 
                });
            }
        },
        addChartPointData(jobNumber, targetChannel) {
            if (!this.chart) { //if this is the first data to be added to the chart, then create the new chart
                this.createNewLightningChart();
            }
            const self = this;
            const friendlyName = targetChannel["friendlyName"];

            const tagId = targetChannel['tagId'];
            const tagName = targetChannel["tagName"];
            const seriesName = jobNumber + " - " + friendlyName;
            //look for if the axis for this channel has already been created by another tag
            let axis;
            if (!(targetChannel['axisId'] in this.activeChannelAxis)) {
                //Create the channel axis for this if needed
                axis = this.createChannelAxis(tagId, tagName, friendlyName, targetChannel['axisId']);
            } else {
                axis = this.activeChannelAxis[targetChannel['axisId']];
            }

            //looking to see if the series exists or not
            let targetSeries = this.activeSeries.find(series=>series?.getName() == seriesName);
            if (targetSeries != null && targetSeries.getName() == seriesName) {
                targetSeries.add(targetChannel["dataSet"]);
            } else {
                targetSeries = this.createSeries(seriesName, tagName, tagId, axis.id);
                //Display on chart
                this.setDataForSeries(targetChannel["dataSet"], targetSeries);
                
                //keep record of added points accessible
                if (this.dataRecord[tagId] == null) {
                    this.dataRecord[tagId] = {};
                    this.dataRecord[tagId].data = [];
                    this.dataRecord[tagId].friendlyName = friendlyName;
                    this.dataRecord[tagId].tagName = tagName;
                    this.dataRecord[tagId].tagId = tagId;
                }
                //if series is not shown then immediately hide it after creation
                if (!this.chartOptions.channelOptions[targetSeries.tagId].show) {
                    targetSeries.dispose();
                }
            }
            //add data to datarecord (same usage as spread operator, but avoids max stack size exceeded error)
            for (const x of targetChannel["dataSet"]) {
                this.dataRecord[tagId].data.push(x);
            }

            //Create the origin line for the job
            if (!this.isOriginCreated) {
                this.createOriginLine();
                this.isOriginCreated = true;
            }

            this.addLegend();

            if (this.legend) {
                const entries = this.legend.entries;
                for (let i = entries.length-1; i>1; i--) {
                    entries.pop();
                }
            }
            if (this.chartBands.length == 0) {
                this.populateStageBands();
            }
        },
        addLivePointData(timestamp, channelValues) {
            const self = this;
            const xAxis = this.chart.getDefaultAxisX();
            if (xAxis.getScrollStrategy() != undefined) { //chart view interval must be manually set to keep up with live data
                xAxis.setScrollStrategy(undefined);
            }                
            this.activeSeries.forEach(series => {
                if (channelValues[series.tagName]) {
                    const newData = {
                        rawTimestamp: timestamp,
                        x: timestamp - self.job.originUnixMs,
                        y: channelValues[series.tagName]
                    };
                    series.add([newData]);
                    this.dataRecord[series.tagId].data.push(newData);
                    if (this.followingLiveUpdates) {
                        const windowStart = timestamp-self.pastHoursInMS - self.job.originUnixMs;
                        const windowEnd = timestamp-self.job.originUnixMs;
                        const rightSideMsOffset = MILLIS_PER_HR / 2; //add a half hour offset
                        this.setChartWindowInterval(windowStart, windowEnd, rightSideMsOffset);
                    }
                }
            });
        }
    },
    watch: {
        height: {
            handler(newVal, oldVal) {
                if (this.chart) {
                    this.chart.engine.layout();
                }
            }
        },
        job: function(newVal, oldVal) {
            if (this.chart && newVal) {
                this.clearChart();//clear old chart and build a new one if a new job is selected to load
            }         
        },
        showLegend: function(newVal, oldVal) {
            if(newVal) {
                if (!this.legend) {
                    this.addLegend();
                } else {
                    this.legend.restore();
                }            
            }else{
                this.legend.dispose();
            }
        },
        stageData: function(newVal, oldVal) {
            if (this.chartBands.length == 0 && this.chart) {
                this.populateStageBands();
            }
        } 
    },
    beforeMount() {
        // Generate random ID to us as the containerId for the chart and the target div id
        this.chartId = Math.trunc(Math.random() * 1000000);
    },
    mounted() {
        //Hacks for getting the chart to load at the proper size. No idea whats going wrong here.
        window.resizeTo(window.screenX - 50, window.screenY);
        this.ticker = !this.ticker;
    },
    beforeDestroy() {
        // "dispose" should be called when the component is unmounted to free all the resources used by the chart
        this?.chart?.dispose();
    }
};
</script>

<style scoped>
  .fill {
    height: 100%;
  }
</style>
