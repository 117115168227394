<template>
<div id="CameraSettings">
    <div class="position-relative">
        <iws-breadcrumbs
            :path="[{
                title: 'Devices',
                link: `/cameras/settings`
            }]"
        />

        <div class="page-title">
            Device List
        </div>

        <template v-if="!_isNullOrEmpty(devices)">
            <div id="search" class="search-positioned">
                <iws-search :value.sync="searchKey" />
            </div>

            <iws-table ref="devicesTable"
                :columns="columns"
                
                :items="devices"

                :filter="searchKey"
                :sortByCol.sync="sortByCol"
                :maxPageSize="12"
            >    
                <template #cell_workOrderId="{ data }">
                    <a :href="`/dashboards/${data?.item?.workOrderId}`">
                        {{ data?.item?.workOrderId }}
                    </a>
                </template>
                <template #cell_heartbeatTime="{ data }">
                    <span>
                        {{ formatHeartbeatTime(data?.item?.heartbeatTime) }}
                    </span>
                </template>  
                <template #cell_status="{ data }">
                    <span v-if="data?.item?.status" class="circle" :class="{ 'green-gradient': data?.item?.status == 'Online', 'red-gradient': data?.item?.status != 'Online' }"></span>
                </template>

                <template #cell_actions="{ data }">
                    <a :href="`/cameras/viewer/${data?.item?.workOrderId}`">
                        <iws-button type="outline-primary" icon="far fa-eye" />
                    </a>
                    <a :href="`/cameras/settings/${data?.item?.workOrderId}/${data?.item?.deviceId}`" class="ml-2">
                        <iws-button type="outline-light" icon="fas fa-cog" />
                    </a>
                </template>
            </iws-table>
        </template>
        <div v-else class="no-items clickable">
            <h2 class="danger-text-color">
                No devices configured for this job
            </h2>
        </div>
    </div>
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, toast } = GlobalFunctions;

export default {
    props: [ 'user', 'devices' ],

    data: () => ({
        columns: [
            {
                key: 'deviceId',
                label: 'Device ID'
            }, {
                key: 'workOrderId',
                label: 'Work Order'
            }, {
                label: 'Customer',
                key: 'customer',
                formatter: (value) => value?.name || 'N/A',
                filter: (filter, item) => item?.customer?.name?.toLocaleLowerCase().includes(filter)
            }, {
                label: 'Location',
                key: 'location',
                formatter: (value, key, item) => item?.job?.location || 'N/A',
                filter: (filter, item) => item?.job?.location?.toLocaleLowerCase().includes(filter)
            }, {
                label: 'System',
                key: 'system',
                formatter: (value, key, item) => item?.job?.system || 'N/A',
                filter: (filter, item) => item?.job?.system?.toLocaleLowerCase().includes(filter)
            }, {
                label: 'Cameras',
                key: 'cameras',
                formatter: (value) => value?.length || 'N/A'
            }, {
                key: 'heartbeatTime',
                label: 'VM Last Heartbeat'
            }, {
                key: 'status',
                label: 'VM Status'
            }, {
                label: null,
                key: 'actions',
                showHeader: false,
                sortable: false
            }
        ],
        searchKey: null,
        sortByCol: 'customer',

        vmData: null,
        polling: {},
    }),

    methods: {
        _isNullOrEmpty: (value) => isNullOrEmpty(value),
        _isFalsy: (value) => isFalsy(value),
        formatHeartbeatTime(time) {
            // No data until it has been provisioned
            if (this.canProvision)
                return 'N/A';

            const zeroPadding = (value) => value < 10 ? "0" + value : value;

            const date = new Date(time);
            if (date.getTime() > 0)
                return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}, ${zeroPadding(date.getHours())}:${zeroPadding(date.getMinutes())}:${zeroPadding(date.getSeconds())}`;
            return 'Waiting for heartbeat...';
        },

        fetchVmData() {
            return axios.get(`/cameras/virtual-machines/all`).then(_result => {
                if (!isFalsy(_result?.data)) {
                    const map = {};
                    _result.data.forEach(vm => {
                        map[vm.deviceID] = vm;
                    });

                    for (let i = 0; i < this.devices.length; i++) {
                        if (this.devices[i].deviceId && this.devices[i].deviceId in map) {
                            this.$set(this.devices[i], 'status', map[this.devices[i].deviceId]?.status);
                            this.$set(this.devices[i], 'heartbeatTime', map[this.devices[i].deviceId]?.heartbeatTime);
                        }
                    }

                    if (this.$refs.devicesTable)
                        this.$refs.devicesTable.forceItemsUpdate();
                
                }
            }).catch(_error => {
                return toast({
                    title: 'Failed to fetch VM data changes',
                    body: _error.message,
                    variant: 'error'
                });
            });
        },
        pollVM() {
            this.fetchVmData();
            this.polling = setInterval(this.fetchVmData, 5000);
        },
        clearVMPoll() {
            clearInterval(this.polling);
        }
    },

    mounted() {
        this.pollVM();
    },
    destroyed() {
        this.clearVMPoll
    }
};
</script>

<style>
    #CameraSettings {
        padding: 20px 50px 50px;
    }
    #CameraSettings>.position-relative {
        max-width: 1400px;
        margin: auto;
    }
    #CameraSettings .page-title {
        margin-top: 10px;
        margin-bottom: 25px;
    }

    #CameraSettings .positioned-actions {
        position: absolute;
        top: 0px;
        right: 0px;
    }

    #CameraSettings #search {
        width: 350px;
        max-width: calc(100% - 120px);
        display: inline-block;
    }
    #CameraSettings .search-positioned {
        position: absolute;
        top: 10px;
        right: 0px;
    }

    #CameraSettings #add-new {
        float: right;
    }

    #CameraSettings .no-items {
        width: 100%;
        padding: 20px;
        text-align: center;
    }

    #CameraSettings .button-size {
        font-size: 1rem;
    }
    #CameraSettings .button-size:last-of-type {
        margin-left: 20px;
    }

    .form-container {
        margin-top: 50px;
    }
    .form-container>label {
        font-size: 1.5rem;
    }

    .form-container .row:not(:first-of-type) {
        margin-top: 25px;
    }

    .page-save-action {
        margin-top: 50px;
        text-align: center;
    }
    .page-save-action .btn {
        min-width: 200px;
    }
</style>