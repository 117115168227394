<template>

            <div>
                    <api-token-enabler
                        :api-token="userApiToken"
                        :user-id="userId"
                        :auth-user="authUser"
                        >
                    </api-token-enabler>
            </div>

</template>

<script>
     export default {
        data() {
            return {
                inputJobNumber:'',
                showKeyboard:false,
                activeInput:'',
                nodes:[],
                jobNumberInputDisabled: true,
                wells:[],
                wellsChanged:false,
                currentJobData:{},
                offlineJobCreate:false,
                componentRoot: null
            }
        },
        props:['jobNumber','role','csrfToken','jobs','currentJob', 'userApiToken', 'userId', 'authUser'],
        mounted(){
        },
     }
</script>
