var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "StageSummaryHeatMap",
      staticClass: "w-100",
      staticStyle: { "font-size": "0.8rem", "background-color": "#fff" },
      attrs: { id: "stageSummaryHeatMap" },
    },
    [
      _c("notifications", {
        ref: "dataFetchNotification",
        attrs: {
          id: "chartNotifications",
          group: "dataFetch-" + _vm.item.i,
          position: "top center",
        },
      }),
      _vm._v(" "),
      _c("notifications", {
        ref: "errorNotification",
        attrs: {
          id: "errorNotification",
          group: "error-" + _vm.item.i,
          position: "top center",
        },
      }),
      _vm._v(" "),
      _c("div", [
        _c(
          "div",
          { staticClass: "m-1 w-100", attrs: { id: "filtersContainer" } },
          [
            _vm.isLoadingPads
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center align-items-center",
                  },
                  [
                    _c("div", {
                      staticClass: "spinner-border spinner-border-sm",
                      staticStyle: {
                        color: "black",
                        position: "absolute",
                        "z-index": "100",
                        top: "10%",
                      },
                      attrs: { role: "status" },
                    }),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                ref: "heatmapFilters",
                staticClass: "row px-2 pb-2 pt-2",
                staticStyle: { "margin-right": "0px" },
                style:
                  _vm.isLoadingPads || _vm.isSaving
                    ? "background-color:rgba(0,0,0,0.5);"
                    : "",
                attrs: { id: "heatmapFilters" },
              },
              [
                _c("div", { staticClass: "col-12" }, [
                  _c("div", { staticClass: "row w-100" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Pad\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("multi-select", {
                              key: _vm.refreshTicker,
                              attrs: {
                                id: "selectedPads",
                                options: _vm.receivedPads,
                                value: _vm.selectedPads,
                                label: "label",
                                disabled: _vm.isLoadingPads || _vm.isLoading,
                                multiSelect: false,
                                maxHeight: 300,
                                placeholderText: "Pick One",
                                sortByKey: _vm.sortPadDropdownByKey,
                                isReverseSort: _vm.isReverseSort,
                              },
                              on: { selectedupdated: _vm.padsSelected },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c(
                              "b-form-checkbox",
                              {
                                staticClass: "mt-2",
                                attrs: {
                                  value: true,
                                  "unchecked-value": false,
                                },
                                model: {
                                  value: _vm.sortByJobStartDate,
                                  callback: function ($$v) {
                                    _vm.sortByJobStartDate = $$v
                                  },
                                  expression: "sortByJobStartDate",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                        Sort by Start Date\n                                    "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Well(s)\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          [
                            _c("multi-select", {
                              key: _vm.refreshTicker,
                              attrs: {
                                id: "selectedWells",
                                options: _vm.selectableWells,
                                value: _vm.selectedWells,
                                hasSubLabels: false,
                                disabled:
                                  _vm.selectedPads.length == 0 ||
                                  _vm.isLoadingPads,
                                label: "wellName",
                              },
                              on: { selectedupdated: _vm.wellsSelected },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Start Stage\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "controlBorder" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model.number",
                                  value: _vm.selectedStageStart,
                                  expression: "selectedStageStart",
                                  modifiers: { number: true },
                                },
                              ],
                              staticClass: "form-control",
                              attrs: {
                                id: "aggregateSelect",
                                disabled: _vm.isLoadingPads,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return _vm._n(val)
                                      })
                                    _vm.selectedStageStart = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.startStageSelected(
                                      $event.target.value
                                    )
                                  },
                                ],
                              },
                            },
                            _vm._l(
                              _vm.selectableStageNumbers,
                              function (option, index) {
                                return _c(
                                  "option",
                                  { key: index, domProps: { value: option } },
                                  [_vm._v(_vm._s(option))]
                                )
                              }
                            ),
                            0
                          ),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select End Stage\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "controlBorder" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value: _vm.selectedStageEnd,
                                    expression: "selectedStageEnd",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "aggregateSelect",
                                  disabled: _vm.isLoadingPads,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return _vm._n(val)
                                        })
                                      _vm.selectedStageEnd = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.endStageSelected(
                                        $event.target.value
                                      )
                                    },
                                  ],
                                },
                              },
                              _vm._l(
                                _vm.selectableStageNumbers,
                                function (option, index) {
                                  return _c(
                                    "option",
                                    { key: index, domProps: { value: option } },
                                    [_vm._v(_vm._s(option))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Category\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "controlBorder" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedCategory,
                                    expression: "selectedCategory",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { id: "aggregateSelect" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedCategory = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.categorySelected(
                                        $event.target.value
                                      )
                                    },
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  { attrs: { value: "stage_frac" } },
                                  [_vm._v("Frac")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { attrs: { value: "stage_wireline" } },
                                  [_vm._v("Wireline")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Channel\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "controlBorder" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedDisplayName,
                                    expression: "selectedDisplayName",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "channelSelect",
                                  disabled:
                                    _vm.isLoadingPads || _vm.isLoadingChannels,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedDisplayName = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(
                                _vm.selectableChannels,
                                function (option, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: option.displayName },
                                    },
                                    [_vm._v(_vm._s(option.displayName))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col" }, [
                      _c("div", [
                        _c("div", { staticClass: "pb-2 selectlabel" }, [
                          _vm._v(
                            "\n                                    Select Expression\n                                "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("div", { staticClass: "controlBorder" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedExpression,
                                    expression: "selectedExpression",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: {
                                  id: "aggregateSelect",
                                  disabled: !_vm.selectedExpression,
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedExpression = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.expressionSelected(
                                        $event.target.value
                                      )
                                    },
                                  ],
                                },
                              },
                              _vm._l(
                                _vm.selectableExpressions,
                                function (expression, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      domProps: { value: expression },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.getExpressionDisplayName(
                                            expression
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3 ml-4" }, [
                      _c("div", {
                        staticClass: "d-flex justify-content-end pr-3 pb-2",
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex row justify-content-left w-100 gx-2",
                        },
                        [
                          _c("div", [
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary grey-button mt-2 mr-2",
                                attrs: { disabled: _vm.isLoadingPads },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearSelections()
                                  },
                                },
                              },
                              [_vm._v("Clear All Filters")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary grey-button mt-2 mr-2",
                                attrs: { disabled: _vm.isLoadingPads },
                                on: {
                                  click: function ($event) {
                                    return _vm.openEditTemplate()
                                  },
                                },
                              },
                              [_vm._v("Template Settings")]
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-secondary grey-button mt-2 mr-2",
                                attrs: { disabled: _vm.isLoadingPads },
                                on: {
                                  click: function ($event) {
                                    return _vm.validateAndLoadData()
                                  },
                                },
                              },
                              [_vm._v("Load Chart")]
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mt-2" },
                        [
                          _c(
                            "b-form-checkbox",
                            {
                              staticStyle: { color: "black" },
                              attrs: { disabled: _vm.isLoadingPads },
                              model: {
                                value: _vm.showLegend,
                                callback: function ($$v) {
                                  _vm.showLegend = $$v
                                },
                                expression: "showLegend",
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    Show Legend\n                                "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _vm.isLoading
          ? _c("div", { staticClass: "d-flex justify-content-center" }, [
              _vm._m(0),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          { attrs: { id: "chart-area" } },
          [
            _c("heat-map-lightning-chart", {
              ref: "heatMapLightningChart",
              attrs: {
                job: _vm.templateSelectedJobs,
                height: _vm.canvasHeight,
                data: _vm.chartData,
                wellData: _vm.templateWells,
                stageMin: _vm.templateStartStage,
                stageMax: _vm.templateEndStage,
                propShowLegend: _vm.getLegendStatus(),
              },
              on: {
                showLegendChanged: _vm.saveLegendStatus,
                error: _vm.lightningChartErrorHandler,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("stage-comparison-settings-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoadingPads,
            expression: "!isLoadingPads",
          },
        ],
        ref: "settingsModal",
        attrs: {
          modalVisible: _vm.modalVisible,
          chartType: _vm.chartType.description,
          templateData: _vm.templateData,
          dashboardInfo: _vm.dashboardInfo,
          isAdmin: _vm.isAdmin,
          userid: _vm.userid,
          targetTemplateId: _vm.item.targetTemplateId
            ? _vm.item.targetTemplateId
            : null,
        },
        on: {
          onDismiss: function ($event) {
            _vm.modalVisible = false
          },
          onLoadTemplate: _vm.loadedTemplate,
          onCreatedTemplate: _vm.createdTemplate,
          onUpdatedTemplate: _vm.updatedTemplate,
          onNewTemplate: _vm.newTemplate,
          onCopyTemplate: _vm.copyChartTemplate,
          onDeleteTemplate: _vm.deleteChartTemplate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm mt-2",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }