import { render, staticRenderFns } from "./MetadataAddTagModal.vue?vue&type=template&id=968276a0&scoped=true&"
import script from "./MetadataAddTagModal.vue?vue&type=script&lang=js&"
export * from "./MetadataAddTagModal.vue?vue&type=script&lang=js&"
import style0 from "./MetadataAddTagModal.vue?vue&type=style&index=0&id=968276a0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "968276a0",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10162/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('968276a0')) {
      api.createRecord('968276a0', component.options)
    } else {
      api.reload('968276a0', component.options)
    }
    module.hot.accept("./MetadataAddTagModal.vue?vue&type=template&id=968276a0&scoped=true&", function () {
      api.rerender('968276a0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/Metadata/MetadataAddTagModal.vue"
export default component.exports