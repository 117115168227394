var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("iws-breadcrumbs", {
        staticClass: "mt-4 mb-1",
        attrs: {
          path: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "Contractors",
              link: "/contractors",
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between mb-4" }, [
        _c("div", { staticClass: "page-title" }, [
          _vm._v("\n            Contractors\n        "),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { position: "relative", top: "-20px" } }, [
          _c(
            "button",
            { staticClass: "btn btn-primary", on: { click: _vm.create } },
            [_vm._v("New Contractor")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "table",
            {
              staticClass: "table table-striped table-hover mt-2",
              class: { "table-dark": _vm.darkMode },
            },
            [
              _c("thead", [
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Type")]),
                _vm._v(" "),
                _vm.role == "admin"
                  ? _c("th", { attrs: { scope: "col" } }, [_vm._v("Customer")])
                  : _vm._e(),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.contractors, function (contractor) {
                  return _c("tr", { key: contractor.id }, [
                    _c("td", [_vm._v(_vm._s(contractor.name))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(contractor.type))]),
                    _vm._v(" "),
                    _vm.role == "admin"
                      ? _c("td", [_vm._v(_vm._s(contractor.customerName))])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("td", [
                      _vm.role == "admin" || _vm.role == "companyAdmin"
                        ? _c(
                            "span",
                            {
                              staticClass: "badge badge-primary",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.edit(contractor.id)
                                },
                              },
                            },
                            [_vm._v("Edit")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.role == "admin" || _vm.role == "companyAdmin"
                        ? _c(
                            "span",
                            {
                              staticClass: "badge badge-primary",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.open(contractor.id)
                                },
                              },
                            },
                            [_vm._v("View")]
                          )
                        : _vm._e(),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }