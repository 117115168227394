var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "container-fluid text-center" },
    [
      _c("p", [_vm._v(" ")]),
      _vm._v(" "),
      _c("div", { staticClass: "row no-gutters m-3" }, [
        _c("div", { staticClass: "col-6" }, [
          _c("div", { staticClass: "progress" }, [
            _c(
              "div",
              {
                staticClass: "progress-bar",
                class: _vm.progressClass,
                style: _vm.progressStyle,
                attrs: { role: "progressbar" },
              },
              [
                _c("span", { staticStyle: { "font-size": "large" } }, [
                  _vm._v(
                    "Last message (s): " + _vm._s(_vm.messageDelay / 1000)
                  ),
                ]),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6" },
          [
            _c("scatter-chart", {
              ref: "latencyLineChart",
              attrs: {
                height: 50,
                "chart-data": _vm.latencydatacollection,
                options: _vm.latencyoptions,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-4" },
          [
            _c("scatter-chart", {
              ref: "timeLineChart",
              attrs: {
                "chart-data": _vm.timedatacollection,
                options: _vm.timeoptions,
              },
            }),
            _vm._v(" "),
            _vm.realtimedata.wireline
              ? _c("div", { staticClass: "mx-0 row justify-content-between" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 text-center border border-secondary rounded mb-1",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 mt-1",
                          staticStyle: {
                            "font-size": "0.7em",
                            "line-height": "0.7",
                          },
                        },
                        [_vm._v("Depth (ft)")]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.currentWirelineValue(
                              _vm.realtimedata.wireline.wireline_1_depth_rt
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 text-center border border-secondary rounded mb-1",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 mt-1",
                          staticStyle: {
                            "font-size": "0.7em",
                            "line-height": "0.7",
                          },
                        },
                        [_vm._v("CCL")]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.currentWirelineValue(
                              _vm.realtimedata.wireline.wireline_1_ccl_tool
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 text-center border border-secondary rounded mb-1",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 mt-1",
                          staticStyle: {
                            "font-size": "0.7em",
                            "line-height": "0.7",
                          },
                        },
                        [_vm._v("Tension")]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.currentWirelineValue(
                              _vm.realtimedata.wireline.wireline_1_line_tension
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 text-center border border-secondary rounded mb-1",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 mt-1",
                          staticStyle: {
                            "font-size": "0.7em",
                            "line-height": "0.7",
                          },
                        },
                        [_vm._v("Speed")]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.currentWirelineValue(
                              _vm.realtimedata.wireline.wireline_1_line_speed
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-4 text-center border border-secondary rounded mb-1",
                    },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "mb-0 mt-1",
                          staticStyle: {
                            "font-size": "0.7em",
                            "line-height": "0.7",
                          },
                        },
                        [_vm._v("Pumpdown")]
                      ),
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.currentWirelineValue(
                              _vm.realtimedata.wireline.pumpdown_1_rate
                            )
                          ) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass:
                      "col-4 text-center border border-secondary rounded mb-1",
                  }),
                ])
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "col-8" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("scatter-chart", {
                  ref: "cclLineChart",
                  attrs: {
                    "chart-data": _vm.ccldatacollection,
                    options: _vm.ccloptions,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "col-6" },
              [
                _c("scatter-chart", {
                  ref: "tensionLineChart",
                  attrs: {
                    "chart-data": _vm.tensiondatacollection,
                    options: _vm.tensionoptions,
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                on: {
                  click: function ($event) {
                    _vm.windowLocked = !_vm.windowLocked
                  },
                },
              },
              [
                _vm._v(
                  "Toggle Window Locked (" + _vm._s(_vm.windowLocked) + ")"
                ),
              ]
            ),
            _vm._v(" "),
            _c("label", { staticClass: "ml-3" }, [
              _vm._v("Depth Window Size (ft)"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.windowSizeDepth,
                  expression: "windowSizeDepth",
                },
              ],
              staticStyle: { width: "4em", "text-align": "right" },
              attrs: { type: "number", min: "0" },
              domProps: { value: _vm.windowSizeDepth },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.windowSizeDepth = $event.target.value
                },
              },
            }),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("auth-fail-component", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hasAuthError,
            expression: "hasAuthError",
          },
        ],
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }