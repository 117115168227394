var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "form",
    { ref: "form", attrs: { autocomplete: "disabled" } },
    [_vm._t("body", null, { dataItem: _vm.localData, submit: _vm.submit })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }