<template>
	<div> 
			<!-- //header -->
		<div class="d-flex justify-content-center"> 
			<b>{{ title }}</b>
		</div>
			<!-- body -->
		<div class="inner-bg py-1 mt-2"> 
			<div class="font-small pt-2 d-flex flex-row" style="justify-content: space-between">
				<select-component :title="''" :initSelected="initSelected" :options="options" :onChange="(value)=>onDropdownValueChange(value)" :externalInputStyles="{ fontSize : '0.9rem' }"/>
				<div>
					<span class="btn btn-dark m-1 p-2"  style="cursor:pointer;" aria-label="Edit" v-if="this.onEditClicked != null && this.dropdownselected != 'None'" @click="()=>this.onEditClicked()">
						<i class="fas fa-pen" aria-hidden="true"></i>
					</span>
					<span class="btn btn-dark m-1 p-2" style="cursor:pointer;" aria-label="New" v-if="this.onNewClicked != null" @click="()=>this.onNewClicked()">
						<i class="fas fa-plus"></i>
					</span>
				</div>
			</div>
			<div v-for="(info, index) in descriptionValues" v-bind:key="index"  class="d-flex flex-row m-2 px-1 font-small">
				<div class="flex-1 sub-txt">
					{{ info.name }}
				</div>
				<div class="flex-grow-1"> </div>
				<div class="flex-1" style="text-align:right">
					{{ info.value }}
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props : ['title' , 'infoList', 'filterKeys', 'onChange', 'initialSelected','initialSelectedName', 'onEditClicked', 'onNewClicked'],
	mounted() {
		if(this.infoList){
			this.options = Object.keys(this.infoList);

			if(Array.isArray(this.options) && this.options.length <= 0)
			{
				this.options.push('None');
			}

			let selectedIndex = 0;

			if(this.initialSelected && this.options[this.initialSelected]){
				selectedIndex = this.initialSelected;
			}
			
			this.dropdownselected = this.initialSelectedName? this.initialSelectedName : this.options[selectedIndex];
			
			this.initSelected = this.dropdownselected
			this.setData(this.dropdownselected)		
		}
	},
	methods: {
		setData(dropDownSelected){
			const self = this;
			const selectionValue = Array.isArray(self.infoList[dropDownSelected]) && self.infoList[dropDownSelected].length > 0 ? self.infoList[dropDownSelected][0] : [];
			const descriptionArray = [];
			Object.keys(selectionValue).filter(function(key) {
				if(self.filterKeys.includes(key)){
					return false;
				} else {
					descriptionArray.push({ name : key.replace(/_/g, " ") , value : selectionValue[key]});
					return true;
				}
			});
			this.onChange && this.onChange(selectionValue)
			this.descriptionValues = descriptionArray;
		},
		onDropdownValueChange(value){
			this.dropdownselected = value;
			this.setData(value);
		}
	},
	data(){
		return {
			options : [],
			dropdownselected : null,
			descriptionValues : [],
			initSelected : null
		}
	}
}
</script>

<style>

</style>