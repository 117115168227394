var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "iws-input",
      class: { "form-input-spacing": _vm.formSpacing !== false },
      attrs: { id: _vm.id },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "primary-text-color" }, [
            _vm._v(
              "\r\n        " +
                _vm._s(
                  typeof _vm.label == "function" ? _vm.label() : _vm.label
                ) +
                "\r\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-group", class: { focused: _vm.isFocused } },
        [
          !!_vm.prependIcon
            ? _c(
                "div",
                {
                  staticClass: "input-group-prepend",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("prepependClick", $event)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "input-group-text",
                      attrs: { id: "addon-wrapping" },
                    },
                    [_c("span", { class: `fas ${_vm.prependIcon}` })]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.type !== "textarea"
            ? _c("input", {
                ref: "inputField",
                staticClass: "form-control",
                class: { "is-invalid": _vm.inErrorState },
                attrs: {
                  type:
                    _vm.type !== "password" || !_vm.showPassword
                      ? _vm.type
                      : "text",
                  placeholder:
                    !!_vm.placeholder && typeof _vm.placeholder == "function"
                      ? _vm.placeholder()
                      : _vm.placeholder,
                  disabled: _vm.disabled,
                  required: _vm.required !== false ? "required" : null,
                  min: _vm.min,
                  max: _vm.max,
                  step: _vm.step,
                  autocomplete: _vm.autocomplete,
                  readonly: _vm.readonly,
                },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.handleEvent("input", $event)
                  },
                  change: function ($event) {
                    return _vm.handleEvent("change", $event)
                  },
                  focus: function ($event) {
                    return _vm.handleEvent("focus", $event)
                  },
                  blur: function ($event) {
                    return _vm.handleEvent("blur", $event)
                  },
                  keyup: function ($event) {
                    return _vm.handleEvent("keyup", $event)
                  },
                },
              })
            : _c("textarea", {
                staticClass: "form-control",
                class: { "is-invalid": _vm.inErrorState },
                attrs: {
                  placeholder: _vm.placeholder,
                  disabled: _vm.disabled,
                  required: _vm.required !== false ? "required" : null,
                  rows: _vm.rows,
                  min: _vm.min,
                  max: _vm.max,
                  autocomplete: _vm.autocomplete,
                  readonly: _vm.readonly,
                },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.handleEvent("input", $event)
                  },
                  change: function ($event) {
                    return _vm.handleEvent("change", $event)
                  },
                  focus: function ($event) {
                    return _vm.handleEvent("focus", $event)
                  },
                  blur: function ($event) {
                    return _vm.handleEvent("blur", $event)
                  },
                  keyup: function ($event) {
                    return _vm.handleEvent("keyup", $event)
                  },
                },
              }),
          _vm._v(" "),
          !!_vm.appendIcon
            ? _c(
                "div",
                {
                  staticClass: "input-group-append",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("appendClick", $event)
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "input-group-text",
                      attrs: { id: "addon-wrapping" },
                    },
                    [
                      _vm._t("appendIcon", function () {
                        return [_c("span", { class: `fas ${_vm.appendIcon}` })]
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm.type == "password"
            ? _c(
                "div",
                {
                  staticClass: "input-group-append",
                  staticStyle: { width: "45px !important" },
                  on: {
                    click: function ($event) {
                      _vm.showPassword = !_vm.showPassword
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass: "input-group-text clickable",
                      attrs: { id: "addon-wrapping" },
                    },
                    [
                      _vm._t("appendIcon", function () {
                        return [
                          _c("span", {
                            class: `fas ${
                              _vm.showPassword ? "fa-eye" : "fa-eye-slash"
                            }`,
                            staticStyle: { width: "20px !important" },
                          }),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.hint
        ? _c("label", { staticClass: "secondary-text-color" }, [
            _vm._v(
              "\r\n        " +
                _vm._s(typeof _vm.hint == "function" ? _vm.hint() : _vm.hint) +
                "\r\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.inErrorState
        ? _c(
            "label",
            {
              staticClass: "danger-text-color",
              staticStyle: { display: "block" },
            },
            [
              _vm.required !== false && _vm._isFalsy(_vm.value)
                ? [_vm._v("\r\n            Required\r\n        ")]
                : _vm._isTruthy(_vm.errorMessage)
                ? [
                    _vm._v(
                      "\r\n            " +
                        _vm._s(
                          typeof _vm.errorMessage == "function"
                            ? _vm.errorMessage()
                            : _vm.errorMessage
                        ) +
                        "\r\n        "
                    ),
                  ]
                : _vm.type == "email"
                ? [
                    _vm._v(
                      "\r\n            Must be a valid e-mail address\r\n        "
                    ),
                  ]
                : _vm.type == "tel"
                ? [
                    _vm._v(
                      "\r\n            Must be a valid telephone\r\n        "
                    ),
                  ]
                : _vm._isTruthy(_vm.min) && _vm.value < _vm.min
                ? [
                    _vm._v(
                      "\r\n            Must be at least " +
                        _vm._s(_vm.min) +
                        "\r\n        "
                    ),
                  ]
                : _vm._isTruthy(_vm.max) && _vm.value > _vm.max
                ? [
                    _vm._v(
                      "\r\n            Must be at less than " +
                        _vm._s(_vm.max + 1) +
                        "\r\n        "
                    ),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }