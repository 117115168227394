var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.addNewWell.apply(null, arguments)
          },
        },
      },
      [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newWellTextInternal,
              expression: "newWellTextInternal",
            },
          ],
          attrs: { id: "new-wellname-long", placeholder: "H1" },
          domProps: { value: _vm.newWellTextInternal },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.newWellTextInternal = $event.target.value
            },
          },
        }),
        _vm._v(" "),
        _c("button", [_vm._v("Add")]),
      ]
    ),
    _vm._v(" "),
    _c(
      "ul",
      _vm._l(_vm.wellNamesLong, function (wellName, index) {
        return _c("wellName-item", {
          key: _vm.wellNamesLong.id,
          tag: "li",
          attrs: { title: wellName.title },
          on: {
            remove: function ($event) {
              return _vm.wellNamesLong.splice(index, 1)
            },
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }