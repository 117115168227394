var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: `plug_view_${_vm.id}`,
      staticClass: "dark-modal modal",
      attrs: { id: `plug_view_${_vm.id}`, role: "document" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              {
                staticClass: "modal-title",
                attrs: { id: "exampleModalCenterTitle" },
              },
              [_vm._v("\r\n                    View Plug\r\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: { click: _vm.hide },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "field-container" }, [
              _c("label", [
                _vm._v(
                  "\r\n                        Description\r\n                    "
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\r\n                        " +
                    _vm._s(_vm.plug.description) +
                    "\r\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Customer Name\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.customerName) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Icon\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.icon) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Length (in)\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.length) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Outer Diameter (in)\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.outer_diameter) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Inner Diameter (in)\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.inner_diameter) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Equipment Type\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.equipment_type) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Make\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.make) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\r\n                                Model\r\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\r\n                                " +
                        _vm._s(_vm.plug.model) +
                        "\r\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }