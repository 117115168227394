var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "col" },
    [
      _c("iws-breadcrumbs", {
        staticClass: "breadcrumbs-margins",
        attrs: { path: _vm.breadcrumbPath },
      }),
      _vm._v(" "),
      _vm.customers != [] && _vm.customers.length > 0 && _vm.parentId == ""
        ? _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("iws-select", {
                attrs: {
                  label: "Company",
                  value: _vm.value.customer_id,
                  options: _vm.customers,
                  "display-name": "name",
                  "value-name": "id",
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.value, "customer_id", $event)
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.numberOfRevisions > 1
        ? _c("iws-button", {
            attrs: { type: "outline-light" },
            on: { click: _vm.getHistory },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function () {
                    return [_c("span", { staticClass: "fas fa-history" })]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3152134211
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.history.length > 0
        ? _c(
            "span",
            { staticStyle: { padding: "0px 5px" } },
            [
              _c("iws-button", {
                attrs: {
                  type: "outline-light",
                  disabled: _vm.historyIndex === 0,
                },
                on: {
                  click: function ($event) {
                    return _vm.changeHistoryIndex(-1)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function () {
                        return [_c("span", { staticClass: "fas fa-backward" })]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  955169610
                ),
              }),
              _vm._v(" "),
              _c("iws-button", {
                attrs: {
                  type: "outline-light",
                  disabled: _vm.historyIndex === _vm.history.length - 1,
                },
                on: {
                  click: function ($event) {
                    return _vm.changeHistoryIndex(1)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "text",
                      fn: function () {
                        return [_c("span", { staticClass: "fas fa-forward" })]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  3219524442
                ),
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.type == "well" &&
      (!_vm.value.hasOwnProperty("name") || !_vm.value.hasOwnProperty("UWI"))
        ? _c("div", { staticClass: "badge badge-pill badge-warning" }, [
            _vm._v("\n    Enter name and UWI to save\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.viewingOldVersion
        ? _c("iws-button", {
            attrs: {
              text: "Restore this version",
              type: "primary",
              click: _vm.showRestoreOldVersionModal,
            },
          })
        : !(
            _vm.type == "well" &&
            (!_vm.value.hasOwnProperty("name") ||
              !_vm.value.hasOwnProperty("UWI"))
          )
        ? _c("iws-button", {
            attrs: { text: "Save", type: "primary", click: _vm.save },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.history.length > 0
        ? _c("iws-select", {
            staticStyle: {
              "max-width": "209px",
              display: "block",
              margin: "5px 0px",
            },
            attrs: {
              value: _vm.historyIndex,
              options: _vm.history,
              "display-name": "created_at",
              "value-name": "index",
            },
            on: {
              "update:value": function ($event) {
                _vm.historyIndex = $event
              },
              change: _vm.viewHistory,
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.errorMessage
        ? _c("div", { staticClass: "alert alert-warning m-2" }, [
            _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm._l(_vm.structure, function (item) {
        return _c("structure-node-component", {
          key: item.name + parseInt(Math.random() * 1000000),
          attrs: { structure: item, parents: [], data: _vm.value, id: _vm.id },
          on: { valueChange: _vm.onValueChange },
        })
      }),
      _vm._v(" "),
      !_vm.newItem
        ? _vm._l(_vm.childTypes, function (childType) {
            return _c(
              "div",
              { key: "childType" + childType.id, staticClass: "row-col mb-3" },
              [
                _c("div", { staticClass: "d-flex justify-content-between" }, [
                  _c("div", { staticClass: "h3" }, [
                    _vm._v(
                      "\n          " + _vm._s(childType.name) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    childType.name == "stage" &&
                    _vm.value.hasOwnProperty("planned") &&
                    _vm.value.planned.hasOwnProperty("stageCount") &&
                    _vm.value.planned.stageCount > 0 &&
                    childType.children.length == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-primary btn-sm m-1",
                            on: {
                              click: function ($event) {
                                return _vm.createStages(childType.id)
                              },
                            },
                          },
                          [
                            _vm.createStageMessage
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.createStageMessage) +
                                      "\n            "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              Create all stages\n            "
                                  ),
                                ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    childType.name == "perfInterval" &&
                    _vm.value.hasOwnProperty("planned") &&
                    _vm.value.planned.hasOwnProperty("clusters") &&
                    childType.children.length == 0
                      ? _c(
                          "div",
                          {
                            staticClass: "btn btn-primary btn-sm m-1",
                            on: {
                              click: function ($event) {
                                return _vm.createPerforationIntervals(
                                  childType.id
                                )
                              },
                            },
                          },
                          [
                            _vm.createIntervalsMessage
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.createIntervalsMessage) +
                                      "\n            "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    "\n              Create all perforation intervals\n            "
                                  ),
                                ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "a",
                      {
                        staticClass: "btn btn-primary btn-sm m-1",
                        attrs: {
                          href: `/metadata/${childType.name}/new?parentId=${_vm.id}`,
                        },
                      },
                      [_vm._v("\n            New\n          ")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("child-table-component", {
                  attrs: {
                    "prop-data": JSON.stringify(childType),
                    "csrf-token": _vm.csrfToken,
                  },
                }),
              ],
              1
            )
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "restoreOldModal",
            tabindex: "-1",
            "aria-labelledby": "restoreOldModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content text-light" }, [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.restoreOldVersion },
                  },
                  [_vm._v("Restore this version")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "restoreOldModalLabel" } },
        [_vm._v("Restore old version")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-body" }, [
      _c("div", { staticClass: "alert alert-danger" }, [
        _vm._v(
          "Restoring this older version will permanently delete newer versions"
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }