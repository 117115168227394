<template>
  <div>
    <div class="btn btn-primary m-2" @click="openModal('addExistingModal')">Add Existing</div>
    <table class="table table-sm text-dark">
      <thead>
        <tr>
          <th scope="col">Id</th>
          <th scope="col">Name</th>
          <th scope="col">Customer</th>
          <th scope="col">Pads</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(system,index) of systems" :key="'system'+system.id">
          <td>
            {{system.object_id}}
          </td>
          <td>
            {{system.data.name}}
          </td>
          <td>
            <span v-if="system.data.hasOwnProperty('customer')">{{system.data.customer.name}}</span>
            <div class="btn btn-primary btn-sm" @click="openSelectCustomer(index)">Edit</div>
          </td>
          <td>
            <span class="badge badge-pill badge-warning" v-if="!system.data.hasOwnProperty('customer')">Must specifiy customer</span>
            <template v-else>
              <span v-if="system.data.hasOwnProperty('pads')">{{system.data.pads}}</span>
              <div class="btn btn-primary btn-sm" @click="openSelectPads(index)">Edit</div>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal fade" id="addExistingModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-light" id="addExistingModalLabel">Add existing system</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-light">
            <div class="form-group">
              <label>
                Name
              </label>
              <input class="form-control" type="text" v-model="newName">
            </div>
            <div class="form-group">
              <label>
                ID
              </label>
              <input class="form-control" type="text" v-model="newId">
            </div>
            <div class="alert alert-warning" v-if="errorMessage">
              {{errorMessage}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="saveNew">Create</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="selectCustomerModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-light" id="selectCustomerLabel">Select customer for system</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-light">
            <div class="form-group">
              <label>
                Customer
              </label>
              <select class="form-control" v-model="selectedCustomerIndex">
                <option v-for="(cust, index) of customers" :key="'cust'+cust.id" :value="index">{{cust.name}}</option>
              </select>
            </div>
            <div class="alert alert-warning" v-if="errorMessage">
              {{errorMessage}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="updateCustomer">Okay</button>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="selectPadsModal" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title text-light" id="selectCustomerLabel">Select pads</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-light">
            <div class="form-group">
              <label>
                Field
              </label>
              <select class="form-control" v-model="selectedFieldIndex" @change="getPads">
                <option v-for="(field, index) of fields" :key="'field'+field.id" :value="index">{{field.data.name}}</option>
              </select>
            </div>
            <div v-if="pads.length>0" class="form-group">
              <label>
                Pads
              </label>
              <select multiple class="form-control" v-model="selectedPads" @change="getPads">
                <option v-for="pad of pads" :key="'pad'+pad.id" :value="pad.id">{{pad.data.name}}</option>
              </select>
            </div>
            <div v-else class="alert alert-info">No pads found for selected field</div>
            <div class="alert alert-warning" v-if="errorMessage">
              {{errorMessage}}
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="updatePads">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props:{
    csrfToken:String,
    fieldTypeId:String,
    padTypeId:String,
    propCustomers:String,
    propSystems:String,
    systemsTypeId:String
  },
  data() {
    return {
      currentSystemIndex:null,
      customers:[],
      errorMessage:null,
      fields:[],
      message:'working',
      newName:null,
      newId:null,
      pads:[],
      selectedCustomerIndex:null,
      selectedFieldIndex:null,
      selectedPads:[],
      systems:[]
    }
  },
  methods:{
    getPads:async function(){
      let self = this
      self.pads = await $.get(`metadataChildren/${self.padTypeId}/${self.fields[self.selectedFieldIndex].id}`)
      for(const pad of self.pads){
        pad.data = JSON.parse(pad.data)
      }
    },
    openModal(name){
      $('#'+name).modal('show')
    },
    openSelectCustomer(index){
      this.currentSystemIndex = index
      this.openModal('selectCustomerModal')
    },
    openSelectPads:async function(index){
      let self = this
      let system = self.systems[index]
      self.currentSystemIndex = index
      self.fields = await $.get(`metadataChildren/${self.fieldTypeId}/${system.data.customer.id}`)
      for(const field of self.fields){
        field.data = JSON.parse(field.data)
      }
      $('#selectPadsModal').modal('show')
    },
    saveNew(){
      let self = this
      if(self.newId && self.newName){
        $.post(
          `/metadata/system/${self.newId}`,
          {
            name:self.newName,
            _token: self.csrfToken,
            type_id:self.systemsTypeId
          },
          function(data){
            console.log(`save response: ${data}`)
            self.errorMessage = null
            $('#addExistingModal').modal('hide')
            location.reload()
          },
          'json'
        )
      }else{
        self.errorMessage = 'Must provide name and id'
      }
    },
    updateCustomer(){
      let self = this
      if(self.currentSystemIndex != null && self.selectedCustomerIndex != null){
        let system = self.systems[self.currentSystemIndex]
        system.data.customer = {
          id:self.customers[self.selectedCustomerIndex].id,
          name:self.customers[self.selectedCustomerIndex].name
        }
        system.data._token = self.csrfToken
        $.post(
          `/metadata/system/${system.id}`,
          system.data,
          function(data){
            console.log(`save response: ${data}`)
            $('#selectCustomerModal').modal('hide')
            delete system.data._token
            self.errorMessage = null
          },
          'json'
        )
      }else{
        self.errorMessage = 'Must select a customer'
      }
    },
    updatePads(){
      let self = this
      if(self.currentSystemIndex != null){
        let system = self.systems[self.currentSystemIndex]
        system.data.pads = self.selectedPads.join(',')
        self.$forceUpdate()
        system.data._token = self.csrfToken
        $.post(
          `/metadata/system/${system.id}`,
          system.data,
          function(data){
            console.log(`save response: ${data}`)
            $('#selectPadsModal').modal('hide')
            delete system.data._token
            self.errorMessage = null
          },
          'json'
        )
      }else{
        self.errorMessage = 'Must have a system selected'
      }
    }
  },
  mounted(){
    this.systems = JSON.parse(this.propSystems)
    this.customers = JSON.parse(this.propCustomers)
    for(const sys of this.systems){
      sys.data = JSON.parse(sys.data)
      sys.id = sys.object_id
    }
  }
}
</script>