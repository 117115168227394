<template>
    <div class="dark-modal modal" id="WellDuplicateModal" ref="WellDuplicateModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        <h5>
                            Duplicate to other Wells
                        </h5>

                        <div>
                            Select the wells you wish to save this configuration to.
                        </div>
                    </div>

                    <button type="button" class="close" @click="resolve(null)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
        
                <div v-if="!!sourceWell && !_isNullOrEmpty(wells)" class="modal-body">
                    <template v-if="!showConfirm">
                        <iws-alert type="info">
                            <template #body>
                                Copying from: {{ sourceWell.name }}
                            </template>
                        </iws-alert>

                        <iws-select
                            label="Wells"
                            :value="targetWells"
                            :options="wells"
                            display-name="name"

                            :placeholder="multiselectPlaceholder"

                            @change="handleWellSelection"
                            :maintain-on-select="true"
                            :multiselect="true"
                        />

                        <div id="update-alerts">
                            <iws-alert v-if="!_isNullOrEmpty(conflictWells)" type="danger">
                                <template #body>
                                    The following selected wells have configurations that will be overwritten in this action:
                                    <ul>
                                        <li v-for="well in conflictWells">
                                            {{ well.name }}
                                        </li>
                                    </ul>
                                </template>
                            </iws-alert>
                            <iws-alert v-else type="warning">
                                <template #body>
                                    This action may override any existing toolstring configurations for the selected well(s)
                                </template>
                            </iws-alert>
                        </div>
                    </template>
                    <template v-else>
                        You are going to make updates to:
                        <ul>
                            <li v-for="well in targetWells">
                                {{ well.name }}

                                <iws-badge v-if="!_isNullOrEmpty(well.toolstrings)" type="danger" text="Overwriting Data" />
                                <iws-badge v-else type="success" text="Empty Well" />
                            </li>
                        </ul>

                        <div id="update-alerts">
                            <iws-alert type="danger">
                                <template #body>
                                    This action will override any existing toolstring configurations for the selected well(s)
                                </template>
                            </iws-alert>
                        </div>
                    </template>
                </div>
                <div v-else class="modal-body">
                    No target wells found.
                </div>
    
                <div class="modal-footer">
                    <iws-button v-if="!showConfirm" type="outline-light" text="Cancel" @click="resolve(null)" />
                    <iws-button v-else type="outline-light" text="Back" :click="() => showConfirm = false" />
    
                    <iws-button :text="!showConfirm ? 'Save and Apply' : 'Confirm'" :click="onSaveClick" />
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isNullOrEmpty, isFalsy, toast } = GlobalFunctions;
    
export default {
    data: () => ({
        resolve: null,

        sourceWell: null,
        wells: [],
        targetWells: [],

        showConfirm: false
    }),

    computed: {
        multiselectPlaceholder() {
            if (!isNullOrEmpty(this.targetWells))
                return this.targetWells.map(_well => _well.name).join(', ');
            return 'Select Well(s)';
        },

        conflictWells() {
            // Conflict wells are target wells with existing data that will be overwritten
            if (!isNullOrEmpty(this.targetWells))
                return this.targetWells.filter(_well => !isNullOrEmpty(_well.toolstrings));
            return [];
        }
    },

    methods: {
        _isNullOrEmpty(value) {
            return isNullOrEmpty(value);
        },

        submitRequest() {
            return $.ajax({
                method: 'POST',
                url: '/well/duplicate',
                data: {
                    '_token': GlobalFunctions.getCSRFToken(),
                    'source': this.sourceWell.id,
                    'targets': this.targetWells.map(_target => _target.id).filter(_id => !isFalsy(_id))
                },
                dataType: 'json'
            }).then(res => {
                this.resolve(res);
            }).catch(error => {
                toast({
                    title: 'Failed to duplicate wells',
                    body: error?.responseJSON?.message,
                    variant: 'danger'
                });
            });
        },
        onSaveClick() {
            // At least one target well to duplicate to is required
            if (isNullOrEmpty(this.targetWells)) {
                toast({
                    title: 'Please select target wells',
                    variant: 'danger'
                });
                return;
            }

            // If the duplicate is going to overwrite existing data, we want to make it very clear.
            // When there is existing data, the first time we hit save, show a confirm overwrite page
            if (!isNullOrEmpty(this.conflictWells) && !this.showConfirm) {
                toast({
                    title: 'Please confirm changes',
                    variant: 'warning'
                });

                this.showConfirm = true;
                return;
            }

            return this.submitRequest();
        },

        handleWellSelection(selectedWell) {
            // If targetWells is somehow in an invalid state, init to empty list
            if (this.targetWells === null)
                this.targetWells = [];

            // If not already selected, push to end of the list
            // If already selected, unselect
            const index = this.targetWells.findIndex(_well => _well.id === selectedWell.id);
            if (index < 0) {
                this.targetWells.push(selectedWell);
            } else {
                this.targetWells.splice(index, 1);
            }
        },
        open(sourceWell, wells) {
            const modalElem = $('#WellDuplicateModal');
            this.sourceWell = sourceWell;
            this.wells = !isNullOrEmpty(wells) ? wells.filter(_well => _well.id !== sourceWell.id) : [];
            this.targetWells = [];
            this.showConfirm = false;

            return new Promise((resolve, reject) => {
                modalElem.show();
                this.resolve = (val) => {
                    modalElem.hide();
                    resolve(val);
                }
            });
        }
    }
};
</script>

<style scoped>
    .modal-dialog {
        max-width: 450px;
    }

    #update-alerts {
        margin-top: 25px;
    }
    #update-alerts ul {
        margin-bottom: 0px !important;
    }

    .badge {
        float: right;
        width: 110px;
    }
</style>