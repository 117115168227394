export default {
    data() {
        return {
        };
    },
    methods: {
        jobMainTitle: function(job) {
            if(job.frac_configs && job.frac_configs[0]) {
                let fracConfig = job.frac_configs[0];
                if(fracConfig.vendor_fleet) {
                    return fracConfig.vendor_fleet.name + ' - ' + job.location;
                }
            }

            return job.location;
        },
        jobSecondaryTitle: function(job) {
            return "Unit: " + job.system + " - Job #: " + job.jobNumber;
        }
    }
}