import { render, staticRenderFns } from "./PlugLibraryView.vue?vue&type=template&id=53dab212&scoped=true&"
import script from "./PlugLibraryView.vue?vue&type=script&lang=js&"
export * from "./PlugLibraryView.vue?vue&type=script&lang=js&"
import style0 from "./PlugLibraryView.vue?vue&type=style&index=0&id=53dab212&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "53dab212",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10162/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('53dab212')) {
      api.createRecord('53dab212', component.options)
    } else {
      api.reload('53dab212', component.options)
    }
    module.hot.accept("./PlugLibraryView.vue?vue&type=template&id=53dab212&scoped=true&", function () {
      api.rerender('53dab212', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/PlugLibraryView.vue"
export default component.exports