<template>
    <div>
        <!-- modal views start -->
        <b-modal :ref="'tooltipModal-' + _uid"
            content-class="modal-content main-container div-border modal-bg"
            :title="editingId? 'Update Tooltip' : 'Create Tooltip'"
            style="z-index: 1200"
            size="xl"
            hide-header
            hide-footer
            >

            <div class="row justify-content-center my-3">{{editingId? 'Update Tooltip' : 'Create Tooltip'}}</div>
            <div class="row justify-content-center align-items-center mb-2">
                <div class="col-2 text-right">Key</div>
                <div class="col-8" v-if="!editingId">
                    <select class="form-control" v-model="tooltipKeyId">
                    <option v-for="(tooltip_key, index) in available_keys" :value="tooltip_key.id" :key="index" >
                        {{ tooltip_key.friendly_name }} 
                    </option>
                    <option v-if="available_keys.length < 1" disabled>
                        All keys have been used
                    </option>
                    </select>
                    <div class="text-danger" v-if="errorBag.indexOf('key') == -1">{{errorBag['key']}}</div>
                </div>
                <div class="col-8" v-else>
                    <input type="text" :value="editingKey" class="w-100" disabled/>
                </div>
            </div>
            <div class="row justify-content-center align-items-start mb-2">
                <div class="col-2 text-right">Message</div>
                <div class="col-8">
                    <textarea v-model="tooltipMessage" class="w-100" rows="5"></textarea>
                    <div class="text-danger" v-if="errorBag.indexOf('message') == -1">{{errorBag['message']}}</div>
                </div>
            </div>

            <div class="modal-footer d-flex flex-row" style="border-top-width: 0px;">
                <button class="btn btn-secondary grey-button" @click="close" :disabled="isLoading">Close</button>
                <button class="btn btn-success green-button" @click="save" :disabled="isLoading">Save Tooltip</button>
            </div>
        </b-modal>
        <!-- modal views end -->

        <iws-breadcrumbs class="mt-4 mb-1"
            :path="[{
                title: 'Home',
                link: '/home'
            }, {
                title: 'Tooltip Management',
                link: '/tooltipManagement'
            }]"
        />
        <div class="d-flex justify-content-between mb-4">
            <div class="page-title">
                Tooltip Management
            </div>
            <div style="position: relative; top: -20px">
                <button class="btn btn-primary" @click="create()" :disabled="isLoading">New Tooltip</button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <table v-if="tooltips.data.length > 0" class="table table-striped table-hover mt-2" :class="{'table-dark': darkMode}">
                    <thead>
                    <th scope="col">Key</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    
                    <th scope="col">Message</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>

                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    </thead>
                    <tbody>
                    <tr v-for="tooltip in tooltips.data" :key="tooltip.id">
                        <td colspan="4">{{getTooltipKey(tooltip)}}</td>
                        <td colspan="6" class="messageText">{{tooltip.message}}</td>
                
                        <td > 
                            <button type="button" class="badge badge-primary" @click="edit(tooltip.id)" style="cursor:pointer;border:none;outline:none" 
                                :disabled="isLoading"
                                v-if="(role == 'admin' || 'iwsTech')">Edit</button>
                        </td>
                        <td> 
                            <button type="button" class="badge badge-danger" @click="remove(tooltip.id)" style="cursor:pointer;border:none;outline:none;" 
                                :disabled="isLoading"
                                v-if="role == 'admin' || 'iwsTech'">Delete</button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div v-else class="title m-b-md">No tooltips exist</div>
            </div>
        </div>
    </div>

</template>

<script>
import GlobalFunctions from '../GlobalFunctions';

export default {
    data() {
        return {
            column: '',
            order: 'asc',
            search: '',
            selectedKey: null,
            //TODO: uncomment when company level tooltips are implemented
            //selectedCustomer: null,
            editingId: null,

            //for editing/creating tooltips
            tooltipMessage: "",
            tooltipKeyId: null,
            errorBag: [],

            isLoading: false //request loading
        }
    },
    mounted(){
    },
    props: ['tooltips', 'tooltip_keys', 'available_keys', 'role', 'customers', 'isIws', 'authUser'],
    methods: {
        create: function () {
            this.editingId = null;
            this.tooltipKeyId = null;
            this.tooltipMessage = "";
            this.$refs['tooltipModal-' + this._uid].show();
        }, 
        edit: function (id) {
            this.editingId = id;
            let editing = this.tooltips.data.filter(k=>k.id == id)?.[0];
            if (!editing) return;
            this.tooltipKeyId = editing.tooltip_key.id;
            this.tooltipMessage = editing.message;
            this.$refs['tooltipModal-' + this._uid].show();
        },
        close: function()
        {
            this.errorBag = [];
            this.$refs['tooltipModal-' + this._uid].hide();
        },
        save: function()
        {
            this.errorBag = [];

            let isValid = true;

            if (this.tooltipKeyId == null)
            {
                Vue.set(this.errorBag, 'key', "No key selected");
                isValid = false;
            }
            if (this.tooltipMessage == "")
            {
                Vue.set(this.errorBag, 'message',  "No message provided");
                isValid = false;
            }

            //frontend checks failed
            if (!isValid) return;

            let self = this;
            
            //editing tooltip
            if (this.editingId)
            {
                this.isLoading = true;

                $.post(
                    '/tooltipManagement/' + self.editingId + '/update',
                    {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        message: self.tooltipMessage
                    },
                    function (result) {
                        if (result.error) {
                            console.error(result.message);
                            confirm(result.message);
                        }
                        else
                        {
                            self.$refs['tooltipModal-' + self._uid].hide();
                            window.location.reload();
                        }  
                        
                        self.isLoading = false;
                    },
                    'json'
                )
                .fail(function (jqXHR, textStatus, errorThrown) {
                    const errorMessage = 'Failed to update tooltip';
                    console.error(errorMessage, errorThrown);
                    if (jqXHR.status == 401) {
                        console.error('unauthorized');
                    }

                    self.isLoading = false;
                });
            }
            //creating tooltip
            else
            {
                this.isLoading = true;

                $.post(
                    '/tooltipManagement/create',
                    {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        key_id: self.tooltipKeyId,
                        message: self.tooltipMessage
                    },
                    function (result) {
                        if (result.error) {
                            console.error(result.message);
                            confirm(result.message);
                        } 
                        else
                        {
                            self.$refs['tooltipModal-' + self._uid].hide();
                            window.location.reload();
                        }

                        self.isLoading = false;
                    },
                    'json'
                )
                .fail(function (jqXHR, textStatus, errorThrown) {
                    const errorMessage = 'Failed to create tooltip';
                    console.error(errorMessage, errorThrown);
                    if (jqXHR.status == 401) {
                        console.error('unauthorized');
                    } 

                    self.isLoading = false;
                });
            }       
        },
        remove: function(id) {
            if(confirm("Do you really want to delete this tooltip?")) {
                const self= this;
                this.isLoading = true;
                $.ajax({
                    method: "get",
                    url: "/tooltipManagement/" + id + "/delete",
                    success: function()
                    {
                        self.isLoading = false;
                        window.location.reload();
                    }
                }).fail(function (jqXHR, textStatus, errorThrown) {
                    const errorMessage = 'Failed to delete tooltip';
                    console.error(errorMessage, errorThrown);
                    confirm(errorMessage);
                    self.isLoading = false;
                });
            } else {
                return false;
            }
        },
        getTooltipKey: function(tooltip)
        {
            return this.tooltip_keys.map(k=>k.key).includes(tooltip.tooltip_key.key)? this.tooltip_keys.filter(k=>k.key == tooltip.tooltip_key.key)?.[0]?.friendly_name : 'Key not found'
        }
    },
    computed: {
        darkMode: function () {
            return this.$root.darkMode;
        },
        editingKey: function() {
            return this.tooltips.data.filter(t=>t.id==this.editingId)?.[0]?.tooltip_key?.friendly_name
        }
    }
}
</script>

<style scoped>
th {
    cursor: pointer;
    width: 12%;
}
label {
    padding: 6px;
}
.popover .arrow {
    display: none;
}
.messageText{
    white-space: pre-wrap;
}
</style>
