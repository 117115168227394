<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-lg-10 col-12">
                <div class="row pt-2 pb-4">
                    <div class="col-flex page-title pr-2">Tag Summary</div>
                    <div class="flex-grow-1"></div>
                    <div><a class="col-3 h5 " :href="returnToDashboardURL">Return to Dashboards</a></div>
                    <span class="fas fa-cog fa-md pt-1"
                        role="button"
                        ref="tableConfig"
                        v-b-toggle.sidebar-right
                        v-tooltip:top="'Table Config'"
                        @click="onGearIconPress">
                    </span>
                </div>
                <div class="row">
                    <div class="col">
                        <tag-summary-table-slot
                            v-for="(tagGroup, index) in tagGroupsMapData"
                            :headerName="tagGroup.baseTagName"
                            :tpiComp="tagGroup.tpiComp"
                            v-bind:key="index"
                            :darkMode="darkMode"
                            :showDefaultColumn="showDefaultColumn"
                            :sortedTags="tagGroup.tags"
                            :latestTagValues="latestTagValues"
                            :topLevel="tagGroup.topLevel"
                        />
                        <div v-if="tagGroupsMapData.length === 0" class="text-white pl-2"> No tags information available</div>
                    </div>
                </div>
                <signalr-error-modal :modalVisible="signalRModalVisible"
                                        :resolutionPackage="resolutionPackage"
                                        @okayPressed="initializeSignalRConnection"
                                        @modalClose="signalRConnected = true; signalRModalVisible = false"/>
            </div>
        </div>

        <!-- component modals -->
        <tag-summary-config
            ref="tableConfig"
            :jobNumber="jobNumber"
            @showDefault="(canShow) => showDefaultColumn = canShow"
        />
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
import SignalRMixin from '../../mixins/SignalRMixin.js';
import _ from 'lodash';

const NO_VALUE_PLACEHOLDER = '--';
export default {
    props: {
        tags: {
            type: [Array, Object],
            required: true,
            default: ()=>[]
        },
        wells: {
            type: [Array, Object],
            required: true,
            default: ()=>[]
        },
        activeCompanyJobs: {
            type: [Array],
            required: true,
            default: ()=>[]
        },
        tagOrder: {
            type: [Array],
            required: true,
            default: ()=>[]
        },
        sessionId: {
            type: String,
            required: true
        },
        jobNumber: {
            type: String,
            required: true
        },
        systemName: {
            type: String,
            required: false
        },
        userId: {
            type: [String, Number],
            required: true
        },
        customerId: {
            type: [String, Number],
            require: true
        }
    },
    mixins: [SignalRMixin],
    data() {
        return {
            tagGroupsMapData: [],
            signalRModalVisible: false,
            signalRConnected: true, //start true so init doesn't count as reconnect
            resolutionPackage: null,
            sortedTags: [],
            showDefaultColumn: false,
            initialSortedTags: [],
            latestTagValues: {},
            sortCriteria: {
                column: 'rawTagNames',
                direction: 'asc'
            },
            key: `showDefault-${this.jobNumber}`
        };
    },
    methods: {
        tableTagNames(tags) {
            let tagNames = [];
            if (tags) {
                for (let i = 0; i < tags.length; i++) {
                    let tag = tags[i];
                    if (tag.name) {
                        tagNames.push(tag.name)
                    }
                }
            }
            return tagNames;
        },
        mapTagsToTPI() {
            this.tagOrder.forEach(TPIInfo => {
                //check is data.ports exists
                TPIInfo.data.ports.forEach(groupInfo => {
                    const tpi = TPIInfo._id !== 'ungrouped' ? TPIInfo._id : '';
                    const comPort = groupInfo.comPort ? `(${groupInfo.comPort})` : '';
                    //We don't show a table if COM port has no data sources (tags):
                    let tagNames = this.tableTagNames(groupInfo.tags);
                    let tableSlotObject;
                    if (tpi && comPort && tagNames.length == 0) {
                        return;
                    } else {
                        tableSlotObject = {
                            topLevel: `${groupInfo.type}`,
                            baseTagName: `${groupInfo.type}${groupInfo.spread? '_' + groupInfo.spread : ''}`,
                            tpiComp: `${tpi} ${comPort}`,
                            tags: groupInfo.tags,
                            TPI: TPIInfo._id
                        };
                    }
                    this.tagGroupsMapData.push(tableSlotObject);
                });
            });

            this.tagGroupsMapData.sort(function(a, b) {
                const nameA = a.baseTagName.toUpperCase();
                const nameB = b.baseTagName.toUpperCase();
                //The following code puts "NOT SET" tags on the bottom:
                if (a.topLevel.toUpperCase() == "NOT SET"){
                    return 1;
                }
                if (b.topLevel.toUpperCase() == "NOT SET"){
                    return -1;
                }
                //normal sorting alogithm:
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                } if (nameA == nameB) {
                    return 0;
                }
            });
        },
        signalrConnectedHandler() {
            this.signalRModalVisible = false;
            this.signalRConnected = true;
        },
        signalrConnectionFailedHandler(error, resolutionPackage) {
            this.resolutionPackage = resolutionPackage;
            this.signalRModalVisible = true;
            this.signalRConnected = false;
        },
        signalrMessageHandler(message) {
            if (message.type !== 'telemetry') return;

            // remove nesting from incoming wireline data
            const latestWirelineData = Object.fromEntries(
                Object.entries(message.wireline ?? {})
                    .map(([tagName, value]) => [tagName, value[0]])
            );

            // merge telementry data sources
            const telemetryData = {
                ...message.telemetry,
                ...latestWirelineData
            };

            // Use tagGroups tags as complete list in case any tags are missing from tag_summaries table
            this.tagGroupsFlattened.forEach((tag) => {
                if (telemetryData[tag.name] == null) return;

                if (tag.decimalPlaces != null) {
                    const decimals = tag.decimalPlaces;
                    const roundedValue = GlobalFunctions.roundAccurately(telemetryData[tag.name],decimals).toFixed(decimals)
                    this.$set(this.latestTagValues, tag.name, roundedValue);
                } else {
                    this.$set(this.latestTagValues, tag.name, telemetryData[tag.name] || '0.0' );
                }
            });
        },
        sortTable(column) {
            switch (column) {
            case 'rawTagNames':
                if (this.sortCriteria.column !== 'rawTagNames') {
                    this.sortCriteria.column = 'rawTagNames';
                    this.sortCriteria.direction = 'asc';
                } else {
                    this.sortCriteria.direction = this.sortCriteria.direction === 'asc'? 'desc':'asc';
                }
                this.sortedTags = this.sortedTags.sort(this.sortByRawTagName);
                break;
            case 'friendlyTagNames':
                if (this.sortCriteria.column !== 'friendlyTagNames') {
                    this.sortCriteria.column = 'friendlyTagNames';
                    this.sortCriteria.direction = 'asc';
                } else {
                    this.sortCriteria.direction = this.sortCriteria.direction === 'asc'? 'desc':'asc';
                }
                this.sortedTags = this.sortedTags.sort(this.sortByFriendlyTagName);
                break;
            }
        },
        sortByRawTagName(a,b) {
            const nameA = a.name.toLowerCase();
            const nameB = b.name.toLowerCase();
            if (this.sortCriteria.direction === 'asc') { //ascending order
                if (nameA > nameB) return 1;
                if (nameA < nameB) return -1;
                return 0;
            } else { //descending order
                if (nameA > nameB) return -1;
                if (nameA < nameB) return 1;
                return 0;
            }
        },
        sortByFriendlyTagName(a,b) {
            const friendlyNameA = a.friendlyTagname.toLowerCase();
            const friendlyNameB = b.friendlyTagname.toLowerCase();
            if (this.sortCriteria.direction === 'asc') { //ascending order
                if (friendlyNameA != '' && friendlyNameB == '') return -1;
                if (friendlyNameA == '' && friendlyNameB != '') return 1;
                if (friendlyNameA > friendlyNameB) return 1;
                if (friendlyNameA < friendlyNameB) return -1;
                return 0;
            } else { //descending order
                if (friendlyNameA != '' && friendlyNameB == '') return 1;
                if (friendlyNameA == '' && friendlyNameB != '') return -1;
                if (friendlyNameA > friendlyNameB) return -1;
                if (friendlyNameA < friendlyNameB) return 1;
                return 0;
            }
        },
        onGearIconPress() {
            if(this.$refs?.tableConfig) {
                this.$refs.tableConfig.changeModalVisibility(true);
            }
        },

    },
    computed: {
        tagGroupsFlattened: function () {
            return this.tagGroupsMapData.reduce((prev, current) => {
                return prev.concat(current.tags);
            }, [])
        },
        darkMode: function () {
            return this.$root.darkMode;
        },
        returnToDashboardURL: function() {
            //return to either last visited or default dashboard
            const previousURL = document.referrer;
            return(previousURL == null || previousURL.search('/dashboards') >= 0)?
                document.referrer : '/dashboards/' + this.jobNumber;
        },
        initSelected: function() {
            const result = this.activeCompanyJobs.find((target) => {
                return target["jobNumber"] == this.jobNumber;
            });
            return result;
        }
    },
    mounted() {
        const canShow = localStorage.getItem(this.key);
        this.showDefaultColumn = canShow ? (canShow === 'true') : false;
        this.initializeSignalRConnection(this.sessionId, this.signalrMessageHandler, this.signalrConnectedHandler, this.signalrConnectionFailedHandler);
        this.tags.forEach(tag => {//initialize latestTagValues with tags as key name and no values
            this.latestTagValues[tag] = NO_VALUE_PLACEHOLDER;
            //if friendly tag name is not set, use an empty string instead of fallback value
            if (tag.name === tag.friendlyTagname) {
                tag.friendlyTagname = '';
            }
        });
        this.sortedTags = this.tags.sort(this.sortByRawTagName);
        this.mapTagsToTPI();
    }
};
</script>

<style scoped>
    .pointer-hover
    {
        cursor: pointer;
    }
</style>
