<template>
    <div class="form-group">
        <form v-on:submit.prevent="addNewWell">
            <input v-model="newWellTextInternal"
                   id="new-wellname"
                   placeholder="H1">
            <button>Add</button>
        </form>
        <ul>
            <li is="wellName-item"
                v-for="(wellName, index) in wellNames"
                v-bind:key="wellNames.id"
                v-bind:title="wellName.title"
                v-on:remove="wellNames.splice(index, 1)"></li>
        </ul>
    </div>
</template>

<style>
    #addWellBackground { 
        background-color: rgba(0, 0, 0, 0.5);
        position: absolute;
        left: 0; 
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 6;
    }
    .addWellBanner { 
        background-color: red;
        border-style: outset;
        border-color: white;
    }
    .addWellsWrapper {
        position: absolute;
        left: 50%; 
        top: 50%;
        width: 100%;
        height: auto; 
        transform: translate(-50%, -50%); 
        z-index:10;
    }
    .addWellText {
        color: white;
        text-align: center;
        line-height: 100px;
    }
</style>

<script>
    Vue.component('wellName-item', {
        template: '\
            <li>\
            {{ title }}\
            <button v-on:click="$emit(\'remove\')">Remove</button>\
            </li>\
        ',
        props: ['title']
    })

    export default {
        data() {
            return {
                wellNames: [
                    {
                        id: 1,
                        title: 'H1',
                    },
                ],
                nextWellId: 2,
                newWellTextInternal: this.newWellText
            }
        },
        props: ['newWellText'],
        methods: {
            addNewWell: function () {
                this.wellNames.push({
                    id: this.nextWellId++,
                    title: this.newWellTextInternal
                }),
                //console.log(this.newWellTextInternal);
                this.$emit('update:wellNames', this.newWellTextInternal)
                this.newWellTextInternal = ''
            }
        },
    }
</script>
