var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.featureFlagEnabled
    ? _c(
        "span",
        [
          _c("iws-button", {
            attrs: { type: `outline-${_vm.statusColour}`, id: "commsWidget" },
            on: {
              click: function ($event) {
                _vm.modalIsOpen = true
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "text",
                  fn: function () {
                    return [_c("i", { class: `fas ${_vm.statusIcon}` })]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              306245995
            ),
          }),
          _vm._v(" "),
          _c("comms-modal", {
            attrs: {
              "modal-is-open": _vm.modalIsOpen,
              job: _vm.job,
              logs: _vm.logs,
              users: _vm.users,
              pushToTalkInProgress: _vm.pushToTalkInProgress,
              pushToTalkConnecting: _vm.pushToTalkConnecting,
              callIsMuted: _vm.callIsMuted,
              connected: _vm.connected,
              isMinimized: _vm.isMinimized,
              joinCall: _vm.joinCall,
              commsRole: _vm.userCommsPermission,
              activeSpeakerUserId: _vm.activeSpeakerUserId,
              activeChannels: _vm.activeChannels,
              user: _vm.user,
              storedActiveChannel:
                _vm.getActiveChannelForRoIPFromLocalStorage(),
              loading: _vm.loading,
              hasUserEndedTheCall: _vm.checkIfUserEndedTheCall(),
              commsModalPositionTop: _vm.commsModalPositionTop,
              commsModalPositionLeft: _vm.commsModalPositionLeft,
            },
            on: {
              close: _vm.onClose,
              endCallFromButton: _vm.endCallFromButton,
              pushToTalk: function ($event) {
                return _vm.pushToTalk($event)
              },
              toggleMute: _vm.toggleMute,
              openPosition: _vm.openPositionModal,
              toggleSize: _vm.toggleSize,
              fetchLogs: _vm.fetchLogs,
            },
          }),
          _vm._v(" "),
          _c("comms-position-modal", {
            ref: "CommsPositionModal",
            attrs: { jobNumber: _vm.jobNumber, isAdmin: _vm.isAdmin },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }