<template>
    <div>
        <b-popover target="export-popover" triggers="hover" placement="left" :delay="{show: 0, hide: 500}">
            <div class="btn-group-vertical">
                <button type="button" class="btn btn-secondary grey-button mb-2" @click="exportTable('csv')">
                    Export as CSV
                </button>
                <button type="button" class="btn btn-secondary grey-button mb-2" @click="exportTable('json')">
                    Export as JSON
                </button>
                <button v-if="haswvxmlExport" type="button" class="btn btn-secondary grey-button" @click="onWvxmlExport()">
                    Export as WVXML
                </button>
            </div>
        </b-popover>
        <iws-button type="link" id="export-popover">
            <template #text>
                <export-icon/> Export
            </template>
        </iws-button>
    </div>
</template>
<script>

import SignalRMixin from '../mixins/SignalRMixin.js';

import _ from 'lodash';
import GlobalFunctions from '../GlobalFunctions';

const NO_VALUE_PLACEHOLDER = null;
export default {
    props: {
        items: {
            type: [Array, Object]
        },
        fields: {
            type: [Array, Object],
            required: true
        },
        fileName: {
            type: String,
            required: false,
            default: 'Table_Export'
        },
        haswvxmlExport: {
            type: Boolean,
            required: false,
            default: false
        },
        jobNumber: {
            type: String,
            required: false
        },
        wellIndex: {
            type: Number,
            required: false
        },
        // used to override the name of the json fields
        jsonFields: {
            type: Object,
            required: false
        },
        jsonExportsAll: {
            type: Boolean,
            default: true,
            required: false
        },
        // array fields to select specific fields in an object eg. [{field1: value1}]
        arrayFields: {
            type: [Object, Array],
            required: false
        }
    },
    mixins: [SignalRMixin],
    data() {
        return {
            disabled: false
        };
    },
    methods: {
        exportTable(type) {
            const data = [];

            if(type == 'csv') {
                //Compose csv data for header row
                const csvHeaderNamesRow = [];
                this.fields.forEach((field)=>{
                    csvHeaderNamesRow.push(field['label']);
                });
                data.push([...csvHeaderNamesRow]);

                //Reduce fields down to keys
                const targetDataKeys = this.fields.map((field) => {
                    return field.key;
                });

                this.items.forEach((item) => {
                    //Should check the incoming fields to get keys
                    const dataRow = [];
                    targetDataKeys.forEach((key) => {
                        let data = _.get(item,key) !== undefined ? _.get(item,key) : NO_VALUE_PLACEHOLDER;
                        //Check if it's a string value and if it is wrap it in string characters to avoid newline issues
                        if ( typeof data === 'string' ) {
                            data = '"' + data + '"';
                        }
                        if (this.arrayFields?.hasOwnProperty(key)) {
                            data = data?.map(d => _.pick(d, this.arrayFields[key])).map(v => _.get(v, this.arrayFields[key])).join(',');
                            data = '"' + data + '"';
                        }
                        dataRow.push(data);
                    });
                    data.push(dataRow);
                });
            }

            //Compile export data
            const fileName = `${this.fileName}.${type}`;
            const url = type === 'csv' ? window.URL.createObjectURL(new Blob([data.join('\n')])) :
                `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(this.jsonExportsAll ? this.items : this.getJsonExport()))}`;
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        },
        async onWvxmlExport() {
            try {
                const targetedData = this.items.map(item => {
                    return ({
                        stage_number: item.stage_number ? item.stage_number : null,
                        stage_time_startElapsedFrac: item.stage_time_startElapsedFrac ? item.stage_time_startElapsedFrac : null,
                        stage_time_endElapsedFrac: item.stage_time_endElapsedFrac ? item.stage_time_endElapsedFrac : null,
                        stage_wellhead_openingPressure_wellbore: item.stage_wellhead_openingPressure_wellbore ? item.stage_wellhead_openingPressure_wellbore : null,
                        stage_wellhead_closingPressure_wellbore: item.stage_wellhead_closingPressure_wellbore ? item.stage_wellhead_closingPressure_wellbore : null,
                        stage_totalStageProppantActual_maxBoxes: item.stage_totalStageProppantActual_maxBoxes ? item.stage_totalStageProppantActual_maxBoxes : null,
                        stage_conc_maxSlurryPropBlenderSetPoint: item.stage_conc_maxSlurryPropBlenderSetPoint ? item.stage_conc_maxSlurryPropBlenderSetPoint : null,
                        stage_conc_maxSlurryPropBottomHole: item.stage_conc_maxSlurryPropBottomHole ? item.stage_conc_maxSlurryPropBottomHole : null,
                        stage_pressure_avgTreatmentZipper1: item.stage_pressure_avgTreatmentZipper1 ? item.stage_pressure_avgTreatmentZipper1 : null,
                        stage_pressure_maxTreatmentMain1: item.stage_pressure_maxTreatmentMain1 ? item.stage_pressure_maxTreatmentMain1 : null,
                        stage_rate_avgTreatmentSlurry: item.stage_rate_avgTreatmentSlurry ? item.stage_rate_avgTreatmentSlurry : null,
                        stage_rate_maxTreatmentSlurry: item.stage_rate_maxTreatmentSlurry ? item.stage_rate_maxTreatmentSlurry : null,
                        stage_frac_pumpCountAtStart: item.stage_frac_pumpCountAtStart ? item.stage_frac_pumpCountAtStart : null,
                        stage_frac_pumpCountAtEnd: item.stage_frac_pumpCountAtEnd ? item.stage_frac_pumpCountAtEnd : null,
                        stage_notes: item.stage_notes ? item.stage_notes : null,
                        stage_totalStageVolActual_maxClean: item.stage_totalStageVolActual_maxClean ? item.stage_totalStageVolActual_maxClean : null,
                        stage_gasTotal_maxNaturalGas: item.stage_gasTotal_maxNaturalGas ? item.stage_gasTotal_maxNaturalGas : null
                    })
                });

                const url =`/fss-op/${this.jobNumber}/exportData/${this.wellIndex}`;

                axios({
                url: url,
                method: 'POST',
                data: {
                    '_token': GlobalFunctions.getCSRFToken(),
                    data: targetedData
                },
                responseType: 'blob',
                validateStatus: (status) => {
                    if(status===200) {
                        return true;
                    } else {
                        return false;
                    }
                }
            })
            .catch(error => {
                console.log('Error producing FSS report', error)
            }).then((response) => {
                if(response?.headers?.error) {
                    alert(response?.headers?.error);
                    return;
                }
                if(response?.data) {
                    //get the file name from the response
                    const contentName = response.headers['content-disposition'];
                    const fileName = contentName.split('"')[1] ? contentName.split('"')[1] : contentName;
                    const blobUrl = window.URL.createObjectURL(new Blob([response.data], {type: '.wvxml'}));
                    const link = document.createElement('a');
                    link.href = blobUrl;
                    link.setAttribute('download', fileName);
                    document.body.appendChild(link);
                    link.click();
                }
            });
            }
            catch(e) {
                console.log('Error producing FSS report', e)
            }
        },
        getJsonExport() {
            const jsonData = [];
            const targetDataKeys = this.fields.map((field) => {
                return field.key;
            });

            this.items.forEach((item) => {
                const itemData = {};
                targetDataKeys.forEach((key) => {
                    const data = _.get(item,key) !== undefined ? _.get(item,key) : NO_VALUE_PLACEHOLDER;
                    const jsonKey = this.jsonFields?.hasOwnProperty(key) ? this.jsonFields[key] : key.split('.')[key.split('.').length-1];
                    itemData[jsonKey] = this.arrayFields?.hasOwnProperty(key) ? data?.map(d => _.pick(d, this.arrayFields[key])).map(v => _.get(v, this.arrayFields[key])) : data;
                });
                jsonData.push(itemData);
            });
            return jsonData;
        },
        getJsonKey(label) {
            const words = label.split(' ').map((word) => {
                return word[0].toUpperCase() + word.slice(1);
            }).join('');
            return words[0].toLowerCase()+words.slice(1);
        }
    },
    mounted() {

    }
};
</script>

<style scoped>
    .clickable {
        border-width: 0px;
        cursor: pointer;
        color: #eeeeee;
    }

    .clickable:hover {
        color: #59acff;
    }
</style>
