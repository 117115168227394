<!-- DEPRECIATED -->
<!-- iwsSelect.vue SHOULD BE USED INSTEAD -->
<template>
	<div class="d-flex flex-row">
		<div class="pr-2" v-if="title" :class="customTitleClass">
			{{ title }}
		</div> 
		<select v-if="!isCustomClassEnabled && initSelected!==null" @change="change" id="selectedID" class="custom-select link-txt-color" v-bind:style="externalInputStyles" :disabled="disabled">
			<option v-for="(item, index) in options" v-bind:key="index" 
				:value="propValue ? item[propValue] : item"
				:selected="isUpdated? 
					index==selectedIndex : 
					((typeof initIndex !== 'undefined')?  initIndex==index : (item==initSelected? true : false) )"
			> 
				{{ displayPropName != null ? item[displayPropName] : item }} 
			</option>
		</select>
		<select v-if="isCustomClassEnabled && initSelected!==null" @change="change" id="selectedID" :class="customClass" v-bind:style="externalInputStyles" :disabled="disabled">
			<option v-for="(item, index) in options" v-bind:key="index" 
				:value="propValue ? item[propValue] : item"
				:selected="isUpdated? 
					index==selectedIndex : 
					((typeof initIndex !== 'undefined')?  initIndex==index : (item==initSelected? true : false) )"
			> 
				{{ displayPropName != null ? item[displayPropName] : item }} 
			</option>
		</select>
	</div>
</template>

<script>
export default {
	mounted(){
		const self = this;
		self.initVal = self.initSelected;
	},
	props : [
		'isCustomClassEnabled',
		'customClass',
		'customTitleClass',
		'title', 
		'options', 
		'onChange', 
		'initSelected', 
		'externalInputStyles', 
		'useIndex', 
		'initIndex', 
		'disabled',
		'propValue',	  //If provided, options are items and this is the property that will supply the select value
		'displayPropName' //If displayPropName is provided this component will display the value of that given prop for it's label and return the full object on change
	],
	data (){
		return {
			initVal : null,
			isUpdated : false,
			selectedIndex : null
		}
	},
	methods : {
		change(event){
			this.selectedIndex = event.target.selectedIndex;

			if(this.useIndex){ //if the options are not unique use indexes to select the correct option after a change
				this.isUpdated = true
			}
			this.onChange(event.target.value, this.selectedIndex);
		},
        setSelectedIndex(index) {//if the selected option is changed by a parent component, no need to emit the update
            this.selectedIndex = index;
            if (this.useIndex) {
                this.isUpdated = true;
            }
        }
	}
}
</script>

<style>

</style>