var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("library-base", {
        attrs: {
          items: _vm.currentTab.tickets.map((ticket) => ticket.values),
          quickFilters: _vm.quickFilters,
          columns: _vm.columns.map((c) => c.key),
          sortByCol: _vm.sortByCol,
          sortAsc: _vm.sortAsc,
          enableCreate: false,
          title: "Support Tickets",
          breadCrumbPath: [
            {
              title: "Dashboard",
              link: "/home",
            },
            {
              title: "Support Tickets",
              link: "/tickets",
            },
          ],
        },
        on: {
          setFilters: _vm.applyFilters,
          openFilters: function ($event) {
            return _vm.$refs.ticketStatusFiltersModal.open()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "no-item-body",
            fn: function () {
              return [
                _vm.currentTab.error
                  ? _c("div", { staticClass: "py-4" }, [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("We've Encountered a Problem"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "subtitle" }, [
                        _vm._v(_vm._s(_vm.currentTab.error)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn primary-color btn-primary mt-4",
                          attrs: {
                            type: "button",
                            "data-toggle": "modal",
                            "data-target": "#supportModal",
                            href: "",
                          },
                        },
                        [_vm._v("Contact Support")]
                      ),
                    ])
                  : _vm.currentTab.loading || _vm.currentTab.loading === null
                  ? _c("div", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Loading Tickets..."),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "subtitle" }, [
                        _vm._v(
                          "Fetching the latest data on your support items"
                        ),
                      ]),
                    ])
                  : _c("div", [
                      _c("div", { staticClass: "title" }, [
                        _vm._v(
                          "You have no " +
                            _vm._s(_vm.showClosed ? "closed" : "active") +
                            " tickets at the moment."
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "subtitle" }, [
                        _vm._v("Any tickets created will be visible here."),
                      ]),
                    ]),
              ]
            },
            proxy: true,
          },
          {
            key: "tabs",
            fn: function () {
              return [
                _c(
                  "div",
                  { staticClass: "active-filters" },
                  _vm._l(_vm.activeFilters, function (filter) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(filter.label) +
                          ": " +
                          _vm._s(
                            filter.values.map((fil) => fil.label).join(", ")
                          ) +
                          "\n                    "
                      ),
                      _c("i", {
                        staticClass: "fas fa-times clickable",
                        on: {
                          click: function ($event) {
                            return _vm.removeFilter(filter.key)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
                _vm._v(" "),
                _c("iws-tabs", {
                  attrs: {
                    tabs: [
                      { title: "Open tickets" },
                      { title: "Closed tickets" },
                    ],
                    showBody: false,
                  },
                  on: {
                    "tab-change": function ($event) {
                      _vm.showClosed = $event === 1
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "additional-actions",
            fn: function ({ filteredItems: filteredTickets }) {
              return [
                _c(
                  "button",
                  {
                    staticClass: "btn export",
                    on: {
                      click: function ($event) {
                        return _vm.exportTickets(filteredTickets)
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "fa fa-download" }),
                    _vm._v(" Export\n            "),
                  ]
                ),
              ]
            },
          },
          {
            key: "table",
            fn: function ({ filteredItems: filteredTickets }) {
              return [
                _c("iws-table", {
                  attrs: {
                    items: _vm.currentTab.tickets.map(
                      (ticket) => ticket.values
                    ),
                    columns: ["", ..._vm.tableColumns],
                    sortByCol: _vm.sortByCol,
                    sortAsc: _vm.sortAsc,
                    busy: _vm.currentTab.loading,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sortAsc": function ($event) {
                      _vm.sortAsc = $event
                    },
                    "update:sort-asc": function ($event) {
                      _vm.sortAsc = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _vm._l(filteredTickets, function (ticket) {
                              return [
                                _c(
                                  "tr",
                                  {
                                    staticClass: "expandable_row",
                                    on: {
                                      click: function ($event) {
                                        return _vm.expandTicket(ticket.id)
                                      },
                                    },
                                  },
                                  [
                                    _c("td", [
                                      _c("span", { staticClass: "expand" }, [
                                        _vm.selectedTicketId === ticket.id
                                          ? _c("i", {
                                              staticClass:
                                                "fas fa-chevron-down",
                                            })
                                          : _c("i", {
                                              staticClass:
                                                "fas fa-chevron-right",
                                            }),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _vm._l(_vm.tableColumns, function (col) {
                                      return _c("td", [
                                        _vm._v(
                                          _vm._s(
                                            _vm.formatCell(ticket, col.key)
                                          )
                                        ),
                                      ])
                                    }),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.selectedTicketId === ticket.id
                                  ? _c("tr", [
                                      _c(
                                        "td",
                                        {
                                          staticClass: "additional-info",
                                          attrs: { colspan: "15" },
                                        },
                                        [
                                          _c(
                                            "dl",
                                            [
                                              _vm._l(
                                                _vm.detailColumns,
                                                function (col) {
                                                  return [
                                                    _c("dt", [
                                                      _vm._v(_vm._s(col.label)),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("dd", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.formatCell(
                                                            ticket,
                                                            col.key
                                                          )
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                        ]
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _vm._v(" "),
                _c("div", { staticClass: "text-center py-4" }, [
                  _vm.currentTab.next
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-outline-light",
                          attrs: { disabled: _vm.currentTab.loading },
                          on: { click: _vm.fetchTickets },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.currentTab.loading
                                ? "Loading..."
                                : "Load More"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
          {
            key: "card",
            fn: function ({ filteredItems: filteredTickets }) {
              return [
                _vm._l(filteredTickets, function (ticket) {
                  return _c("div", { staticClass: "gun-card-container" }, [
                    _c(
                      "dl",
                      [
                        _vm._l(
                          [..._vm.tableColumns, ..._vm.detailColumns],
                          function (col, index) {
                            return index <= 4 ||
                              _vm.selectedTicketId === ticket.id
                              ? [
                                  _c("dt", [_vm._v(_vm._s(col.label))]),
                                  _vm._v(" "),
                                  _c("dd", [
                                    _vm._v(
                                      _vm._s(_vm.formatCell(ticket, col.key))
                                    ),
                                  ]),
                                ]
                              : _vm._e()
                          }
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "pt-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-link",
                          on: {
                            click: function ($event) {
                              return _vm.expandTicket(ticket.id)
                            },
                          },
                        },
                        [
                          _vm.selectedTicketId === ticket.id
                            ? _c("span", [
                                _vm._v(
                                  "\n                            Show Less\n                            "
                                ),
                                _c("i", { staticClass: "fas fa-chevron-up" }),
                              ])
                            : _c("span", [
                                _vm._v(
                                  "\n                            Show More\n                            "
                                ),
                                _c("i", { staticClass: "fas fa-chevron-down" }),
                              ]),
                        ]
                      ),
                    ]),
                  ])
                }),
                _vm._v(" "),
                _c("div", { staticClass: "text-center py-4" }, [
                  _vm.currentTab.next
                    ? _c(
                        "button",
                        {
                          staticClass: "btn btn-secondary",
                          attrs: { disabled: _vm.currentTab.loading },
                          on: { click: _vm.fetchTickets },
                        },
                        [
                          _vm._v(
                            _vm._s(
                              _vm.currentTab.loading
                                ? "Loading..."
                                : "Load More"
                            )
                          ),
                        ]
                      )
                    : _vm._e(),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c("ticket-status-filters-modal", {
        ref: "ticketStatusFiltersModal",
        attrs: {
          "active-filters": _vm.filters,
          loading: _vm.pageMetadata.loading,
          error: _vm.pageMetadata.error,
          options: _vm.pageMetadata.options ?? null,
        },
        on: { submit: _vm.applyFilters },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }