var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "video-scrubber-wrapper",
      staticStyle: { position: "relative" },
    },
    [
      _c("canvas", {
        ref: "scrubber",
        attrs: {
          id: "scrubber-canvas",
          height: _vm.scrubberCanvasHeight,
          width: _vm.timelineCanvas.width,
        },
        on: {
          mousedown: _vm.onTimelineMouseDown,
          mousemove: _vm.onMouseMove,
          mouseleave: _vm.onTimelineMouseLeave,
          mouseup: _vm.onTimelineMouseUp,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { padding: "0px 5px 5px 5px" } },
        [
          _c(
            "b-row",
            {
              attrs: {
                id: "scrubber-control-row",
                "no-gutters": "",
                "align-v": "center",
              },
            },
            [
              !_vm.clippingMode
                ? [
                    _c("iws-button", {
                      staticClass: "mr-2",
                      attrs: {
                        type: "outline-light",
                        pill: "",
                        size: "sm",
                        click: _vm.onSkipBackButtonClicked,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "fas fa-undo mr-1" }),
                                _vm._v(
                                  "\n                        30s\n                    "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        4034693699
                      ),
                    }),
                    _vm._v(" "),
                    !_vm.paused
                      ? _c("iws-button", {
                          attrs: {
                            click: _vm.onPauseClicked,
                            icon: "fas fa-pause",
                            type: "outline-light",
                            size: "sm",
                            rounded: "",
                          },
                        })
                      : _c("iws-button", {
                          attrs: {
                            click: _vm.onPlayClicked,
                            icon: "fas fa-play",
                            type: "outline-light",
                            size: "sm",
                            rounded: "",
                          },
                        }),
                    _vm._v(" "),
                    _vm.vodSelected?.label == "Live" && !_vm.liveMode
                      ? _c("iws-button", {
                          staticClass: "ml-2",
                          attrs: {
                            text: "Live",
                            type: "outline-primary",
                            pill: "",
                            size: "sm",
                            click: _vm.onLiveButtonClicked,
                          },
                        })
                      : _vm._e(),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _c(
                "span",
                {
                  class: { "shift-slider-time": _vm.clippingMode },
                  attrs: { id: "current-datetime" },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getReadableDayTime(_vm.sliderTime)) +
                      "\n            "
                  ),
                ]
              ),
              _vm._v(" "),
              _vm.finishedManualClip
                ? _c("span", [
                    _vm._v("\n                Clip from: "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.getReadableDayTime(_vm.manualClip?.start))
                      ),
                    ]),
                    _vm._v(" to "),
                    _c("b", [
                      _vm._v(
                        _vm._s(_vm.getReadableDayTime(_vm.manualClip?.end))
                      ),
                    ]),
                  ])
                : _vm.clippingMode
                ? _c("i", [
                    _vm._v(
                      "\n                Drag mouse on timeline to select section to clip\n            "
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.clippingMode
                ? [
                    _c("iws-button", {
                      staticClass: "ml-2",
                      attrs: {
                        text: "Cancel Clip",
                        type: "outline-danger",
                        pill: "",
                        size: "sm",
                        click: _vm.onClippingModeCancelClicked,
                      },
                    }),
                    _vm._v(" "),
                    _c("iws-button", {
                      staticClass: "ml-2",
                      attrs: {
                        type: "primary",
                        pill: "",
                        size: "sm",
                        click: _vm.onClippingModeSaveClicked,
                        disabled:
                          !_vm.finishedManualClip ||
                          _vm.manualClip.start - _vm.manualClip.end == 0,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "far fa-save mr-1" }),
                                _vm._v(
                                  "\n                        Save Clip\n                    "
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        2230788404
                      ),
                    }),
                  ]
                : _vm.permissions?.edit
                ? _c("iws-button", {
                    attrs: {
                      type: "outline-light",
                      pill: "",
                      size: "sm",
                      click: _vm.onClippingModeClicked,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "fas fa-cut mr-1" }),
                            _vm._v(
                              "\n                    Clip\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("iws-select", {
                key: "rangeSelector",
                staticClass: "control-select",
                attrs: {
                  value: _vm.rangeSelected,
                  placeholder: `${
                    _vm.rangeOptions[_vm.rangeSelected]?.label || "Select Range"
                  }`,
                  options: _vm.rangeOptions,
                  "display-name": "label",
                  "value-name": "index",
                  visibleOptions: 7,
                },
                on: {
                  "update:value": function ($event) {
                    _vm.rangeSelected = $event
                  },
                  change: _vm.onRangeSelected,
                },
              }),
              _vm._v(" "),
              !_vm.clippingMode
                ? _c("iws-select", {
                    key: "vodSelector",
                    staticClass: "control-select",
                    attrs: {
                      value: _vm.vodSelected,
                      placeholder: "Live",
                      options: _vm.vodDropdownItems,
                      "display-name": "label",
                      visibleOptions: 7,
                    },
                    on: {
                      "update:value": function ($event) {
                        _vm.vodSelected = $event
                      },
                      change: _vm.onJump,
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          title: "Save Clip",
          showModal: _vm.showSaveClipModal,
          "max-width": "500px",
        },
        on: {
          close: function ($event) {
            return _vm.onClickCancelSaveClip()
          },
          "secondary-action": function ($event) {
            return _vm.onClickCancelSaveClip()
          },
          "primary-action": function ($event) {
            return _vm.onClickSaveClip()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", [
                  _vm._v(
                    "\n                Select the camera to clip from, then click save to generate the clip. Clipping may take several minutes. You can refresh the Clip Browser to view the clip.\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "b-row",
                  { staticClass: "pt-3" },
                  [
                    _c("b-col", [
                      _c("div", [
                        _vm._v(
                          "\n                        Clip Start\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.manualClip.start == null
                                ? ""
                                : _vm.getReadableDayTime(_vm.manualClip.start)
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("b-col", [
                      _c("div", [
                        _vm._v(
                          "\n                        Clip End\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              _vm.manualClip.end == null
                                ? ""
                                : _vm.getReadableDayTime(_vm.manualClip.end)
                            ) +
                            "\n                    "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("b-col", [
                      _c("div", [
                        _vm._v(
                          "\n                        Clip Length\n                    "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("b", [
                        _vm._v(
                          "\n                        " +
                            _vm._s(_vm.clipLength.mins) +
                            " mins, " +
                            _vm._s(_vm.clipLength.secs) +
                            " seconds\n                    "
                        ),
                      ]),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "30px" } },
                  [
                    _c("iws-select", {
                      ref: "clipCameraName",
                      attrs: {
                        label: "Camera",
                        value: _vm.manualClip.camera,
                        placeholder: "-- Please select a camera --",
                        options: _vm.cameraList,
                        "display-name": "displayName",
                        required: true,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.manualClip, "camera", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "30px" } },
                  [
                    _c("iws-select", {
                      ref: "clipStreamConfig",
                      attrs: {
                        label: "Stream",
                        value: _vm.manualClip.streamGlobalId,
                        placeholder: "-- Please select a stream --",
                        options: _vm.manualClip?.camera?.streamConfigurations
                          ? _vm.manualClip.camera.streamConfigurations
                          : [],
                        "display-name": "name",
                        "value-name": "globalId",
                        required: true,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.manualClip,
                            "streamGlobalId",
                            $event
                          )
                        },
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticStyle: { "margin-top": "30px" } },
                  [
                    _c("iws-input", {
                      ref: "clipCameraDescription",
                      attrs: {
                        label: "Clip Description",
                        placeholder: "Type description for clip...",
                        value: _vm.manualClip.description,
                        type: "textarea",
                        rows: 3,
                        required: "",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.manualClip, "description", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }