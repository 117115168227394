
<template>
<div>
    <library-base
        :items="plugs"
        :filters="filters"
        :columns="columns.map(c => c.key)"
        :sortByCol="sortByCol"
        :sortAsc="sortAsc"
        title="Plug Library"
        createBtnTxt="Add New Plug"
        :breadCrumbPath="[{
            title: 'Home',
            link: '/home'
        }, {
            title: 'Plug Library',
            link: '/plugs'
        }]"

        @create="editAction(emptyPlug)"
        @openFilters="openFilters"
    >
        <template #breadcrumbs>
            <iws-breadcrumbs class="breadcrumbs-margins"
                :path="[{
                    title: 'Dashboard',
                    link: '/home'
                }, {
                    title: 'Plug Library',
                    link: '/plugs'
                }]"
            />
        </template>

        <template #title>
            Plug Library
        </template>

        <template #add-item-title>
            Add New Plug
        </template>

        <template #no-item-body>
            <div class="title">Add Plugs</div>
            <div class="subtitle">Start by adding a Plug. Click on the "Add New Plug" button</div>
        </template>

        <template #card="{ filteredItems: filteredPlugs }">
            <div v-for="plug in filteredPlugs" class="gun-card-container">
                <div class="row fixed-width">
                    <label>Description</label>
                    <span>{{ plug.description }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Icon</label>
                    <span>{{ plug.icon }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Outer Diameter (in)</label>
                    <span>{{ plug.outer_diameter }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Inner Diameter (in)</label>
                    <span>{{ plug.inner_diameter }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Make</label>
                    <span>{{ plug.make }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Model</label>
                    <span>{{ plug.model }}</span>
                </div>

                <div class="row fixed-width">
                    <label>Customer</label>
                    <span>{{ plug.customerName }}</span>
                </div>

                <hr>

                <library-crud-actions v-if="enableRowActions"
                    :id="plug.id"
                    :enable-actions="{
                        edit: true,
                        view: true,
                        delete: !plug.in_use
                    }"

                    @edit="editAction(plug)"
                    @view="openAction(plug)"
                    @delete="deleteAction(plug)"
                />
            </div>
        </template>

        <template #table="{ filteredItems: filteredPlugs }">
            <iws-table
                :items="filteredPlugs"
                :columns="[...columns, '']"
                :sortByCol.sync="sortByCol"
                :sortAsc.sync="sortAsc"
            >
                <template #body>
                    <tr v-for="plug in filteredPlugs" :key="plug.id">
                        <td>
                            {{ plug.description}}
                        </td>
                        <td>
                            {{ plug.icon }}
                        </td>
                        <td>
                            {{ plug.outer_diameter }}
                        </td>
                        <td>
                            {{ plug.inner_diameter }}
                        </td>
                        <td>
                            {{ plug.make }}
                        </td>
                        <td>
                            {{ plug.model }}
                        </td>
                        <td v-if="enableCustomerCol">
                            {{ plug.customerName }}
                        </td>
                        <td>
                            <library-crud-actions v-if="enableRowActions"
                                :id="plug.id"
                                :enable-actions="{
                                    edit: true,
                                    view: true,
                                    delete: !plug.in_use
                                }"

                                @edit="editAction(plug)"
                                @view="openAction(plug)"
                                @delete="deleteAction(plug)"
                            />
                        </td>
                    </tr>
                </template>
            </iws-table>
        </template>
    </library-base>

    <plug-library-filters-modal id="plugFiltersModal" ref="plugFiltersModal"
        :plugs="plugs"
        :enable-customer-col="enableCustomerCol"
    />

    <plug-library-add-edit id="plugAddEdit" ref="plugAddEdit"
        :enable-customer-col="enableCustomerCol"
    />

    <plug-library-view id="plugView" ref="plugView"
        :enable-customer-col="enableCustomerCol"
    />
</div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';

import LibraryBase from './LibraryBase.vue';

import PlugLibraryFiltersModal from './PlugLibraryFiltersModal.vue';
import PlugLibraryAddEdit from './PlugLibraryAddEdit.vue';
import PlugLibraryView from './PlugLibraryView.vue';

import LibraryCrudActions from './LibraryCrudActions.vue';

export default {
    props: {
        plugs: [Array, Object],
        role: String,
        customer: Object
    },

    components: {
        LibraryBase,

        PlugLibraryFiltersModal,
        PlugLibraryAddEdit,
        PlugLibraryView,
        
        LibraryCrudActions
    },

    data: () => ({
        sortByCol: 'description',
        sortAsc: true,

        fields: [
            {
                key: 'description',
                label: 'Description',
                sortable: true
            },
            {
                key: 'icon',
                label: 'Icon',
                sortable: true
            },
            {
                key: 'outer_diameter',
                label: 'Outer Diameter',
                sortable: true
            },
            {
                key: 'inner_diameter',
                label: 'Inner Diameter',
                sortable: true
            },
            {
                key: 'make',
                label: 'Make',
                sortable: true
            },
            {
                key: 'model',
                label: 'Model',
                sortable: true
            },
        ],

        filters: {
            description: [],
            icon: [],
            outer_diameter: [],
            inner_diameter: [],
            make: [],
            model: [],
            customerName: []
        },

        emptyPlug: {
            description: null,
            icon: null,
            outer_diameter: null,
            inner_diameter: null,
            make: null,
            model: null,
            customerName: null
        }
    }),

    computed: {
        enableCustomerCol() {
            return this.role == 'admin' || this.customer.name == 'IWS';
        },
        enableRowActions() {
            return this.role == 'admin' || this.role =='companyAdmin' || this.customer.name == 'IWS';
        },

        columns() {
            const columns = [...this.fields];
            if (this.enableCustomerCol)
                columns.push({
                    key: 'customerName',
                    label: 'Customer',
                    sortable: true
                });
            return columns;
        }
    },

    methods: {
        openFilters() {
            this.$refs.plugFiltersModal.open(this.filters).then($event => {
                if (!!$event)
                    this.filters = $event;
            });
        },

        editAction(plug) {
            this.$refs.plugAddEdit.open(plug);
        },
        openAction(plug) {
            this.$refs.plugView.open(plug);
        },
        deleteAction(plug) {
            if (plug.in_use)
                return;
            
            GlobalFunctions.iwsConfirm({
                title: 'Delete Plug',
                body: `Are you sure you want to delete "${plug.description || 'this plug'}"? This action cannot be undone!`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer) {
                    //Should delete the actual comment
                    $.ajax({
                        url: '/plugs/' + plug.id + '/delete',
                        method: 'PUT',
                        data:
                        {
                            "_token": GlobalFunctions.getCSRFToken(),
                            id: plug.id
                        },
                        dataType: 'json'
                    }).done(() => {
                        window.location.reload();
                    }).fail(e => {
                        console.warn(e.responseText);
                    });
                }
            })
        }
    }
}
</script>

<style scoped>
    .fixed-width label {
        width: 130px;
    }
    .fixed-width span {
        width: calc(100% - 130px - 5px);
    }
</style>