<template>
    <div style="z-index: 1200; position: absolute; top: 0px; left: 0px;" class="modal fade" ref="supportModal" id="supportModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content main-container modal-bg text-center text-wrap py-2">
                <div class="ml-3 mr-2 text-white text-dark">
                    <img src="/images/inVision_support.png" alt="inVision support logo" class="support-logo">
                </div>
                <div class="mb-0 header text-white text-dark">
                    <p>Need Support? Report a Bug?</p>
                    <p>Feature Request or General Inquiry?</p>
                    <hr class="mt-1">
                </div>
                <div class="content text-white text-dark">
                    <p>Contact the IWS 24/7 Remote Operations Center:</p>
                    <a style="color: inherit;" href="tel:1-866-229-0999">1-866-229-0999</a><br>
                    <a style="color: inherit;" href="mailto:support@iwscorp.com">support@iwscorp.com</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

};
</script>

<style scoped>
    p, a {
        margin-bottom: 0;
        font-size: 1.3rem;
        font-family: 'Source Sans Pro', 'Nunito', sans-serif;
    }

    .header p {
        font-weight: 600;
    }

    .content p, a {
        font-weight: 200;
    }

    .main-container {
		border-radius: 20px
    }

    .support-logo {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    hr {
        width: 80%;
        border: 1.5px solid;
    }
</style>
