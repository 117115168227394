<template>
    <div ref="multiWellTimelineChart" class="w-100" :style="'font-size:0.8rem; background-color: #fff; height:' + height + 'px'">
        <notifications ref="signalRNotification" id="chartNotifications" group="dataFetch" position="top center"/>
        <notifications ref="chartNotifications" id="chartNotifications" group="signalR" position="top center"/>
        <div class="grid">
            <div id="padControls" ref="padControls" class="padControls m-1" style="">
                <!-- Area One -->
                <div ref="filterHeaders" class="row px-2 pb-2 pt-2" style="margin-right: 0px;" :style="isLoadingPads || isSaving ? 'background-color:rgba(0,0,0,0.5);' : ''">
                    <div class="col-8">
                        <div class="row w-100">
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Pad
                                    </div>
                                    <div>
                                        <!-- Control goes here -->
                                        <multi-select id="selectedPads"
                                            :options="receivedPads"
                                            :value="selectedPads"
                                            label='label'
                                            :disabled="isLoadingPads || isLoading"
                                            :multiSelect="false"
                                            :maxHeight="300"
                                            :placeholderText="'Pick One'"
                                            @selectedupdated="padsSelected"
                                            :sortByKey="sortPadDropdownByKey"
                                            :isReverseSort="isReverseSort"
                                            :key="refreshTicker"/>
                                    </div>
                                    <div>
                                        <b-form-checkbox
                                            id="checkbox-1"
                                            class="mt-2"
                                            v-model="sortByJobStartDate"
                                            :value="true"
                                            :unchecked-value="false"
                                            name="checkbox-1"
                                        >
                                            Sort by Job Start Date
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Channel(s)
                                    </div>
                                    <div>
                                        <!-- Control goes here -->
                                        <multi-select id="selectedChannels"
                                            :options="receivedChannels"
                                            :value="selectedChannels"
                                            :label="'friendlyTagname'"
                                            :selectAllDuplicates="true"
                                            :removeDuplicatesKey="'name'"
                                            :uniqueId="'channel-select-'+item.i"
                                            :selected="selectedChannels"
                                            :disabled="selectedPads.length == 0 || isLoadingChannels || isLoading"
                                            :maxHeight="300"
                                            @selectedupdated="channelsSelected"
                                            :key="refreshTicker"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="d-flex justify-content-end pr-3 pb-2">
                        </div>
                        <div class="d-flex row justify-content-left w-100 gx-2">
                            <div>
                                <button :disabled="isLoadingPads" class="btn btn-secondary grey-button mt-2 mr-2" @click="clearConfiguration()">Clear All Filters</button>
                                <button :disabled="isLoadingPads" class="btn btn-secondary grey-button mt-2 mr-2" @click="modalVisible=true">Template Settings</button>
                                <button :disabled="isLoadingPads" class="btn btn-secondary grey-button mt-2 mr-2" @click="validateAndFetchData()">Load Chart</button>
                            </div>
                        </div>
                        <div class="mt-2">
                            <b-form-checkbox :disabled="isLoadingPads" style="color:black;" v-model="showLegend">
                                Show Legend
                            </b-form-checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <!--Max Height of the sidebar controls needs to be set to the component height to properly create space for each control block-->
            <div ref="timeControls" id="timeControls" class="timeControls ml-2 d-flex flex-column justify-content-between" style="height:100%; border-left: 1px solid black;" :style="{'max-height':getComponentHeight() +'px'}">
                <!-- Area two -->
                <span class="w-100 text-center" style="display:block; font-size:1rem;">Channel Options</span>
                <div ref="channelControls" id="channelControls" class="scrollContainer h-100">
                    <div class="d-flex flex-column p-1" style="font-size:0.8rem;">
                        <div v-for="(channel,index) in selectedChannels" :key="index">
                            <div v-if="chartOptions.channelOptions[channel.id]">
                                <div class=" d-flex w-100 align-items-center justify-content-left">
                                    <div class="d-flex w-100  align-items-center justify-content-between">
                                        <div class="d-flex justify-content-start" style="width:60%;">
                                            <div class="mt-1 text-left" style="word-wrap: break-word">
                                                <input class="check-box mr-1" type="checkbox" :disabled="isLoading || isLoadingPads" @click="channelToggle(channel.id)" v-model="chartOptions.channelOptions[channel.id].show"/>
                                            </div>{{channel.friendlyTagname}}
                                        </div>
                                        <div class="d-flex justify-content-end align-items-center" style="width:40%;">
                                            <div class=" p-0 mr-1 ml-2">
                                                <div
                                                    class="color fake-button"
                                                    :style="{'background-color': chartOptions.channelOptions[channel.id].color ? chartOptions.channelOptions[channel.id].color : '#FFFFFF'}"
                                                >
                                                </div>
                                            </div>
                                            <div @click="openChannelOptionsModal(channel.id)" class="show-clicker-finger">
                                                <i class="fas fa-cog  px-2" ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <div ref="stageBandConfig" id="stageBandConfig" class="mt-1">
                        <div class="d-flex justify-content-center">
                            <button class="btn btn-link text-center" type="button" data-toggle="collapse" data-target="#stageOptionsCollapse" @click="showStageOptions=!showStageOptions" style="color:black">Stage Options</button>
                            <div class="flex-shrink-1 p-2" id="collapseStageConfig">
                                <div v-show="!showStageOptions" class="fas fa-caret-up"></div>
                                <div v-show="showStageOptions" class="fas fa-caret-down"></div>
                            </div>
                        </div>
                        <div id="stageOptionsCollapse" class="collapse">
                            <div class="p-1 d-flex align-items-center">
                                <input class="check-box mr-1 " type="checkbox" :disabled="isLoading || isLoadingPads" @click="toggleAllStageBands()"  v-model="chartOptions.allStageBandsToggle"/>
                                Select All
                            </div>
                            <div class="d-flex align-items-center justify-content-start p-1 w-100">
                                    Opacity:
                                    <input class="ml-1" :disabled="isLoading || isLoadingPads || !this.chartOptions.allStageBandsToggle" type="range" value="90" min="0" max="255" @change="updateAllWellStageOpacities($event.target.value)">
                            </div>
                            <div v-for="(well,index) in selectableWells" :key="index" class="d-flex w-100">
                                <div class="w-100">
                                    <div class="d-flex justify-content-start align-items-center p-1" style="">
                                    <input class="check-box mr-1" type="checkbox" :disabled="isLoading || isLoadingPads" @click="chartOptions.stageBandOptions[well.id].show = !chartOptions.stageBandOptions[well.id].show" @change="updateWellStageOptions()" v-model="chartOptions.stageBandOptions[well.id].show"/>{{well.wellName}}
                                    <div class=" p-0 mr-1 ml-2">
                                        <div
                                            class="color fake-button"
                                            :style="{'background-color': well.color ? well.color : '#FFFFFF'}"
                                        >
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center justify-content-start p-1 w-100">
                                    Opacity:
                                    <input class="ml-1" :disabled="isLoading || isLoadingPads" type="range" default min="0" max="255" v-model="chartOptions.stageBandOptions[well.id].opacity" @change="updateWellStageOptions()">
                                </div>
                                </div>
                            </div>
                        </div>
                    <div ref="timeConfiguration" id="timeConfiguration">
                        <div class="p-2" style="border-top: 1px solid black;">
                            <div class="d-flex justify-content-between mb-1">
                                <label class="w-60" for="aggregateInterval">Aggregate Interval: </label>
                                <select class="w-40" id="aggregateInterval" ref="aggregateInterval" :disabled="isLoadingPads" v-model="aggregateInterval">
                                    <option v-for="(option,index) in dashboardData.aggregateOptions" :key="index" :value="option.value">{{option.name}}</option>
                                </select>
                            </div>
                            <div class="d-flex justify-content-between mb-1">
                                <label class="w-60" for="timeType">Interval Type: </label>
                                <select class="w-40" id="timeType" ref="timeType" :disabled="isLoadingPads" v-model="timeType">
                                    <option value="time">Time</option>
                                    <option value="stage">Well Stages</option>
                                    <option value="current">Current</option>
                                </select>
                            </div>
                            <div v-if="timeType==='stage'">
                                <div class="w-100 d-flex justify-content-between mb-1">
                                    <label class="w-60" for="stageIntervalWellSelect">Well: </label>
                                    <select class="w-40" id="stageIntervalWellSelect" ref="stageIntervalWellSelect" @change="updateStageTimeSelects()" :disabled="isLoadingPads" v-model="stageIntervalWellSelect">
                                        <option v-for="(well, index) in selectableWells" :key="index" :value="well">{{well.wellName}}</option>
                                    </select>
                                </div>

                                <div v-if="selectableStages.length > 0"> <!--Wait for data to be loaded before trying to render stage controls-->
                                    <div class="w-100 d-flex justify-content-between mb-1">
                                        <label class="w-60" for="startStage">Start Stage: </label>
                                        <select class="w-40" id="startStage" ref="startStage" :disabled="isLoadingPads || templateData.loadLatestStage" v-model="startStage" @change="validateStageSelects('start')">
                                            <option v-for="(stage,index) in selectableStageStarts" :key="index" :value="stage" >{{stage.stage}}</option>
                                        </select>
                                    </div>

                                    <div class="w-100 d-flex justify-content-between mb-1">
                                        <label class="w-60" for="endStage">End Stage: </label>
                                        <select class="w-40" id="endStage" ref="endStage" :disabled="isLoadingPads || templateData.loadLatestStage" v-model="endStage" @change="validateStageSelects('end')">
                                            <option v-for="(stage,index) in selectableStageEnds" :key="index" :value="stage" >{{stage.stage}}</option>
                                        </select>
                                    </div>
                                    <label class="font-90" for="">
                                    <input class="mr-1" type="checkbox"
                                        v-model="templateData.loadLatestStage"
                                        @change="selectLatestStages()"
                                        >
                                Last Complete</label>

                                </div>
                            </div>
                            <div v-show="timeType==='time'">
                                <div class="d-flex flex-column justify-content-between mb-1">
                                    <label>Start:</label>
                                    <input :disabled="isLoadingPads" type="datetime-local" :min="localJobStartTime" v-model="startTime" class="w-100 align-self-end" style="font-size:0.9rem;">
                                </div>

                                <div class="d-flex flex-column justify-content-between  mb-1">
                                    <label>End:</label>
                                    <input :disabled="isLoadingPads" type="datetime-local" :min="startTime" :max="localJobEndTime" v-model="endTime" class="w-100 align-self-end" style="font-size:0.9rem;">
                                </div>

                            </div>
                            <div v-show="timeType==='current'">
                                <div class="d-flex justify-content-between mb-1">
                                    <label class="w-60">Get Past Hours: </label>
                                    <input class="w-40" :disabled="isLoadingPads" type="number" v-model="pastHours" style="font-size:0.9rem;">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
            <div :style="chartAreaStyle" class="chartArea" >
                <!-- Area three  -->
                <div v-if="isLoadingPads" class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border spinner-border-sm" role="status" style="color:black; position: absolute; z-index: 100; top: 10%;"></div>
                </div>
                <div class="d-flex flex-column">
                    <div ref="chartInfoHeader" class="chartHeader d-flex" style="background-color:#BEBEBE; border: 2px solid #BEBEBE; border-bottom: 0; border-radius:5px 5px 0 0;">
                        <div class="d-flex justify-content-left w-50">
                            <div class="show-clicker-finger">
                                <i id="chartInfo" class="fas fa-info mx-2" ></i>
                                <b-popover target="chartInfo" placement="right" triggers="hover" >
                                    <div style="color:white;">
                                        <div class="w-100 text-center" style="text-decoration:underline;">Controls</div>
                                        <div>Mouse Wheel: Zoom In / Out</div>
                                        <div>Left Click and Drag: Zoom to Defined Area</div>
                                        <div>Right Click and Drag: Pan View</div>
                                    </div>
                                </b-popover>
                            </div>
                            <div class="show-clicker-finger">
                                <i @click="returnToDefaultsMethod()" :disabled="isLoadingPads" class="fa fa-undo px-2" :class="{'icon-disabled': isLoadingPads}" v-tooltip:top="'Return to Default Axis Settings'" ></i>
                            </div>
                            <div>
                            <div @click="openAxisOptionsModal()" class="show-clicker-finger">
                                <i :disabled="(isLoadingPads && !selectedChannels)" class="fas fa-cog px-2" :class="{'icon-disabled': isLoadingPads}" v-tooltip:top="'Y-axis Options'" ></i>
                            </div>
                            <div>
                                <analytic-options-modal
                                    @onDismiss="chartAxisModalVisible=false"
                                    @onApplyOptionChanges="applyOptionChanges"
                                    :modalVisible="chartAxisModalVisible"
                                    :modalData="axisOptionsModalData"
                                    :isSaving="isSaving"
                                >
                                </analytic-options-modal>
                            </div>
                            </div>
                        </div>
                        <div class="d-flex flex-row-reverse w-50">
                            <div class="show-clicker-finger" @click="followingLiveUpdates=!followingLiveUpdates" ><!--To do:replace with a more robust function-->
                                <i class="fas fa-satellite-dish mx-2 show-clicker-finger" v-show="followingLiveUpdates && timeType === 'current'" v-tooltip:top="'Tracking Live Data'"></i>
                                <i class="fas fa-satellite-dish mx-2 show-clicker-finger"
                                    :class="{'not-live-tracking': timeType == 'current', 'icon-disabled': timeType != 'current'}"
                                    v-show="!followingLiveUpdates || timeType !== 'current'"
                                    v-tooltip:top="'Not Tracking Live Data'"></i>
                            </div>
                            <div :class="{'show-clicker-finger':isLoadComplete}" @click="$refs.wellComparisonLightning.printChartToImage()" v-tooltip:top="'Print Chart View'" :disabled="isLoading || isLoadingPads || !isLoadComplete">
                                <i class="fas fa-print mx-2"></i>
                            </div>
                            <div id="exportDataIcon" ref="exportDataIcon">
                                <i class="fas fa-download mx-2 show-clicker-finger"></i>
                                <b-popover target="exportDataIcon" placement="left" triggers="hover" >
                                    <div style="color:white;">
                                        <div class="w-100 text-center" style="text-decoration:underline;">Export Data</div>
                                        <div class="d-flex justify-content-between mt-2">
                                            <div class="mx-2">
                                                File Type:
                                            </div>
                                            <select id="exportFileType" class="mx-2" v-model="exportFileType">
                                                <option value="json" >JSON</option>
                                                <option value="csv" selected>csv</option>
                                            </select>
                                        </div>
                                        <div class="d-flex justify-content-between mt-2">
                                            <div class="mx-2">
                                                Data Range:
                                            </div>
                                            <select id="exportRangeType" class="mx-2" v-model="exportRangeType">
                                                <option value="allLoaded" >All Loaded Data</option>
                                                <option value="visibleInterval" selected>Visisble Range</option>
                                            </select>
                                        </div>
                                        <div class="d-flex justify-content-center mt-2">
                                            <button type="button" class="btn btn-success green-button" @click.prevent="$refs.wellComparisonLightning.exportChartData(exportFileType, exportRangeType)" :disabled="isLoading || isLoadingPads || !isLoadComplete">
                                                <div class="d-flex justify-content-center">
                                                        <span v-if="false" class="spinner-border spinner-border-sm pr-2" role="status" aria-hidden="true"></span>
                                                    <div>
                                                        Export
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </b-popover>
                            </div>

                        </div>
                    </div>
                     <div v-if="isLoading" class="d-flex justify-content-center">
                        <div class="spinner-border spinner-border-sm mt-2" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div style="border: 2px solid white; border-top: 0; border-radius:0 0 5px 5px;">
                        <well-comparison-lightning-chart ref="wellComparisonLightning"
                        :points="points"
                        :livePoints="liveChannelData"
                        :followingLiveUpdates="followingLiveUpdates"
                        :height="canvasHeight"
                        :showLegend="showLegend"
                        :chartOptions="chartOptions"
                        :pastHoursInMS="pastHoursInMS"
                        :clearChartData="clearChartData"
                        :job="templateSelectedJobs"
                        :wells="templateWells"
                        :timeType="timeType"
                        :isLoading="isLoading"
                        :stageData="stageData"
                        @onError="lightningChartErrorHandler"
                        :lockToMinChannelsIds="lockToMinChannelIds"
                        >
                        </well-comparison-lightning-chart>
                    </div>
                </div>
            </div>
        </div>
        <stage-comparison-settings-modal ref="settingsModal" v-show="!isLoadingPads" :modalVisible="modalVisible" :chartType="'MWT'" :templateData="templateData"
            :dashboardInfo="dashboardInfo"
            :isAdmin="isAdmin"
            :userid="userid"
            @onDismiss="modalVisible = false"
            @onLoadTemplate="loadedTemplate"
            @onCreatedTemplate="createdTemplate"
            @onUpdatedTemplate="updatedTemplate"
            @onNewTemplate="newTemplate"
            @onCopyTemplate="copyChartTemplate"
            @onDeleteTemplate="deleteChartTemplate"
            :targetTemplateId="item.targetTemplateId ? item.targetTemplateId : null">
        </stage-comparison-settings-modal>
        <div class="">
            <analytic-options-modal
            @onDismiss="chartOptionsModalVisible=false"
            :modalVisible="chartOptionsModalVisible"
            :modalData="optionsModalData"
            >
            </analytic-options-modal>
        </div>
    </div>
</template>

<style>
    .grid {
        display: grid;
        grid-template-columns: auto 260px;
        grid-auto-rows: auto;
        grid-template-areas:
            "padControls timeControls"
            "chartArea timeControls"
            "chartArea timeControls";
        height:100%;
        width:100%;

    }
    .scroll {
        overflow:scroll;
    }
    .scrollContainer {
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .scrollContainer::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .padControls {
        grid-area: padControls;
    }
    .timeControls {
        grid-area: timeControls;
    }
    .chartArea {
        grid-area: chartArea
    }
    select{
        font-family: FontAwesome, sans-serif;
    }
    .popover {
        max-width: 50vw !important;
        background-color: #373A3C;
        padding: 0px;
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
    }
    .white-text {
        color: white;
    }
    #chartjs-tooltip {
        opacity: 1;
        text-align: left;
        position: absolute;
        background: rgba(0, 0, 0, .7);
        color: white;
        border-radius: 3px;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        pointer-events: none;
    }

    .chartjs-tooltip-key {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    #timeline-wrapper {
        position: relative;
        height: 2rem;
        text-align: center;
        z-index: 1;
    }

    .not-live-tracking {
        opacity: 0.25;
    }

    .fake-button:hover {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }

    .fake-button:active {
        outline: none;
        border: none;
    }

    .fake-button:focus {
        outline: 0;
    }
    .w-60 {
        width: 60%!important;
    }
    .w-40 {
        width:40%!important;
    }

    @keyframes warning {
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }
    @-webkit-keyframes warning {
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }

    @keyframes critical {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }
    @-webkit-keyframes critical {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }

    @keyframes failed {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    }
    @-webkit-keyframes failed {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    }
    .show-clicker-finger {
        cursor: pointer;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .filter-dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .show {
        display:block;
    }
    .swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
    }
    .controlBorder {
    border-color: black;
    border-style: solid;
    border-width: 2px;
    }
    .multiselect{
        border-color:black;
        border-style:solid;
        border-width:2px;
    }
    .selectlabel{
        color:black;
    }
    .icon-disabled {
        opacity: 0.25;
        pointer-events: none;
    }
</style>

<script>
import moment from 'moment';
import _ from 'lodash';
import GlobalFunctions from '../GlobalFunctions.js';
import { Sketch } from 'vue-color';
import Notifications from 'vue-notification';
import SignalRMixin from '../mixins/SignalRMixin.js';
import ADXDownloadMixin from '../mixins/ADXDownloadMixin.js';
import AnalyticsMixin from '../mixins/AnalyticsMixin.js';
import {v4 as uuidv4} from 'uuid';

//To Do: remove some constants that are not required
const MILLIS_PER_HR = 3600000;
const MILLIS_PER_DAY = 86400000;
const MILLIS_PER_QUARTER_DAY =  86400000 / 4; //25% of a day in milliseconds, reduced number to prevent php memory overflow
const INITIAL_WINDOW_HRS = 9; //hours of data to load on startup
const MINIMUM_COMPONENT_HEIGHT_PIXELS = 415;
const MAX_WINDOW_HRS = 24; //maximum amount to show on screen at once
const MAX_DATA_POINTS = 2500; //maximum number of data points on the graph
const MAX_FILL_IN_PAGE_HRS = 3; //hours of data to retrieve at a time when panning or zooming out
const MIN_PAGE_SIZE_MILLIS = 5000; //minimum diff between start and end when getting more data
const MAX_SECTION_HEIGHT_PERCENTAGE = 1.2/4;
const HEADER_OFFSET = 125;
const BOTTOM_LABEL_INSET = 50;

export default {
    inject: ['dashboardData','quickAddData'],
    created() {
        this.chartTypes = GlobalFunctions.analysisChartTypes();
    },
    mixins: [SignalRMixin, ADXDownloadMixin, AnalyticsMixin],
    data() {
        return {
            canvasHeight: this.chartHeight,
            defaultChannels: [],
            sortByJobStartDate: false,
            isReverseSort: false,
            lockToMinTags: ['wireline_1_line_speed'],
            defaultTemplateData: null,
            showSignalRConnectError: false,
            timeSeriesResponse: {
                dataBuffer: {},
                channels: {}
            },
            isSignalRConnected: false,
            errorNotifications: {
                dataFetch: {
                    tags: {}
                }
            },
            clearChartData: false,
            job: null,
            jobNumber: null,
            systemName: null,
            startTime: null,
            endTime: null,
            minimumDate: null,
            maximumDate: null,
            startStage: null,
            endStage: null,
            pastHours: INITIAL_WINDOW_HRS, //default is 9 hours in the past
            refreshTicker: false,
            isLoading: false,
            isLoadComplete: false,
            isLoadingPads: true,
            isLoadingChannels: false,
            isSaving: false,

            checkboxLiveData: false,
            exportFileType: 'csv',
            exportRangeType: 'visibleInterval',
            showLegend: false,
            modalVisible: false,
            chartOptionsModalVisible: false,
            chartAxisModalVisible: false,
            optionsModalData: {},
            axisOptionsModalData: {},
            templateData: null,
            templateDataDefault: {
                id: null,
                name: null,
                type: 'MWT',
                isUserDefault: false,
                loadLatestStage: false,
                createdBy: null,
                data: {
                    jobs: [],
                    wells: [],
                    stages: [],
                    interval: {
                        timeType: 'current',
                        aggregateInterval: '',
                        startTime: null,
                        endTime: null,
                        startStage: null,
                        endStage: null,
                        stageIntervalWellSelect: 0,
                        pastHours: INITIAL_WINDOW_HRS
                    },
                    channels: [],
                    chartOptions: {}
                },
                lineConfigs: []
            },
            filterOptionsDataIn: {},

            receivedPads: [],
            receivedWells: [],
            receivedStages: [],
            receivedIndices: [],
            receivedChannels: [],
            selectableWells: [],
            selectableStages: [],
            selectableStageStarts: [],
            selectableStageEnds: [],
            stageIntervalWellSelect: {},
            selectedPads: [],
            selectedWells: [],
            selectedStages: [],
            selectedIndices: [],
            selectedChannels: [],
            defaultAxes: [],
            chartOptions: {
                channelOptions: {},
                stageBandOptions: {},
                axisOptions: {},
                allStageBandsToggle: true
            },
            chartOptionsDefault: {
                channelOptions: {
                    id: null,
                    axisId: null,
                    show: true,
                    color: '#000000',
                    thickness: 2,
                    customAxisId: null,
                },
                stageBandOptions: {
                    wellId: null,
                    show: true,
                    opacity: 90
                },
                axisOptions: {
                    id: null,
                    showAxis: true,
                    label: null,
                    location: 'default',
                    autoFit: true,
                    axisMin: 0,
                    axisMax: 1000,
                    lockToZero: true,
                    returnToDefaults: false,
                    axisCreationType: 'default', //options are 'default', 'auto', 'custom'
                    channelId: null,
                    channels: [], //all channels attached to this axis,
                    unit: '',
                },
                allStageBandsToggle: true,
                allWellStagesOpacity: 90
            },
            timeType: 'current',
            aggregateInterval: '',
            followingLiveUpdates: false,
            channelColorFocus: null,
            showStageLineColor: false,
            points: [],
            stageData: {},
            liveChannelData: {},
            activeStage: false,
            axiosInstance: null,
            showStageOptions: false
        };
    },
    props: {
        chartType: {
            type: Symbol,
            required: false
        },
        filterOptionsData: {
            type: Object,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            require: true
        },
        sessionId: {
            type: [String, Object]
        },
        dashboardInfo: {
            type: Object
        },
        isAdmin: {
            type: [Boolean, Number]
        },
        userid: {
            type: [String]
        },
        userCompanyId: {
            type: String,
            required: true
        }
    },
    components: {
        'sketch-picker': Sketch
    },
    methods: {
        defaultChannelsWhenNoTemplateSelected() {
            if (this.isNoTemplateSelected) {
                if (this.defaultChannels) {
                    this.channelsSelected(this.defaultChannels);
                }
            }
        },
        applyOptionChanges(options) {
            const currentChannelOptions = this.chartOptions.channelOptions[options.channelOptions.id];
            //axis changed, lightningChart series data must be destroyed and re-created on another axis
            if (currentChannelOptions.axisId !== options.channelOptions.axisId) {
                options.channelOptions.hasAxisChanged = true;
            }

            this.chartOptions.channelOptions[options.channelOptions.id] = options.channelOptions;
            this.chartOptions.axisOptions = options.axisOptions;
            this.$forceUpdate(); //variables changed are nested, so change isn't seen until the next update
            this.$refs.wellComparisonLightning.applyOptionChanges();
            if (options.saveConfiguration) {
                this.saveConfiguration();
            }
        },
        toggleAllStageBands() {
            this.chartOptions.allStageBandsToggle = !this.chartOptions.allStageBandsToggle;
            Object.values(this.chartOptions.stageBandOptions).forEach(stageOptions =>
                stageOptions.show = this.chartOptions.allStageBandsToggle
            );
            this.$forceUpdate();//required update to force the UI to render the proper toggle stage
            this.$refs.wellComparisonLightning.applyOptionChanges();
            this.templateData.data.chartOptions = this.chartOptions;
        },
        isAutoDataLoadValid() {
            //Gatekeeper function that determines if a chart can load data immediately during points of page load
            const interval = this.templateData?.data?.interval;
            //current time type
            if (interval.timeType == 'current') {
                return true;
            }

            if (interval.timeType == 'time' && interval.startTime && interval.endTime) {
                return true;
            }
            //not pinned to loading latest the stage, load some stages directly from saved template data
            if (!this.templateData?.loadLatestStage && interval.timeType == 'stage' && interval?.stageIntervalWellSelect
                && interval.startStage && interval.endStage && !this.isLoadComplete) {
                return true;
            //is loading latest stage, and stages have been returned so we are able to select the latest complete
            //and load data after the filters have loaded
            } else if (this.templateData?.loadLatestStage && interval.timeType == 'stage'
            && this.receivedStages.length > 0 && !this.isLoadComplete) {
                return true;
            }
            return false; //in other cases, chart will not auto load data
        },
        selectLatestStages: function() {
            if (this.stageIntervalWellSelect && Object.keys(this.stageIntervalWellSelect).length > 0
                && this.receivedStages.length > 0 && this.templateData.loadLatestStage) {
                const stageGroup = this.receivedStages.find(stageGroup => stageGroup.well_id == this.stageIntervalWellSelect.id);
                const lastCompleteStage = stageGroup.valueArray.findLast(stage => stage.requestReason ==  'Remove Frac');
                this.startStage = stageGroup.valueArray.find(stage => stage.requestReason == 'Place Frac' && stage.stage == lastCompleteStage.stage);
                this.endStage = lastCompleteStage;
            }
        },
        getComponentHeight() {
            const mwtContainer = this.$refs.multiWellTimelineChart;
            if (mwtContainer) {
                return mwtContainer.clientHeight;
            } else {
                return MINIMUM_COMPONENT_HEIGHT_PIXELS;
            }
        },
        convertUnixTimeToLocal(unixTimestamp) {
            const localTimeAdjust = this.templateSelectedJobs?.hourOffset * MILLIS_PER_HR;
            return unixTimestamp + localTimeAdjust;
        },
        updateWellStageOptions() {
            this.templateData.data.chartOptions = this.chartOptions;
            this.$forceUpdate(); //variables changed are nested, so change isn't seen until the next update
            this.$refs.wellComparisonLightning.applyOptionChanges();
        },
        updateAllWellStageOpacities(newValue) {
            Object.values(this.chartOptions.stageBandOptions).forEach(wellOptions => {
                wellOptions.opacity = newValue;
            });
            this.updateWellStageOptions();
        },
        channelToggle(channelId) {
            this.chartOptions.channelOptions[channelId].show = !this.chartOptions.channelOptions[channelId].show;
            this.$refs.wellComparisonLightning.applyOptionChanges();
        },
        openChannelOptionsModal(channelId) {
            const channel = this.selectedChannels.find(channel=>channel.id == channelId);
            const channelOptions = this.chartOptions.channelOptions[channelId];
            this.optionsModalData = {
                channel: channel,
                selectedChannels: this.selectedChannels,
                selectedChannelId: channelId,
                channelOptions: this.chartOptions.channelOptions,
                axisOptions: this.chartOptions.axisOptions,
                type: 'channel',
                axisTemplate: this.chartOptionsDefault.axisOptions
            };
            this.chartOptionsModalVisible=true;
        },
        openAxisOptionsModal() {
            this.axisOptionsModalData = {
                channel: this.selectedChannels[0],
                selectedChannels: this.selectedChannels,
                selectedChannelId: this.selectedChannels[0]?.id,
                channelOptions: this.chartOptions.channelOptions,
                axisOptions: this.chartOptions.axisOptions,
                type: 'axis',
                axisTemplate: this.chartOptionsDefault.axisOptions
            };
            this.chartAxisModalVisible=true;
        },
        clearConfiguration: function() {
            this.chartOptions.axisOptions = {};
            this.chartOptions.channelOptions = {};
            this.chartOptions.stageBandOptions = {};
            this.chartOptions.allStageBandsToggle = false;
            this.clearSelections();
            if (this.isSignalRConnected) {
                this.endSignalRConnection();
                this.isSignalRConnected = false;
            }
        },
        saveConfiguration: function() {
            const url = '/analysis/saveTemplate';
            const self = this;
            //purge chartOptions of unused tag data before save
            const channelOptions = self.chartOptions.channelOptions;
            for (const channelId in channelOptions) {
                if (channelOptions[channelId]?.id == null || !self.selectedChannels.some(channel => channel.id == channelOptions[channelId].id)) {
                    delete channelOptions[channelId];
                }
            }
            //save the time configuration to the template data
            self.templateData.data.chartOptions = self.chartOptions;
            const interval = self.templateData.data.interval;
            interval.timeType = self.timeType;

            interval.startTime = self.startTime;
            interval.endTime = self.endTime;

            interval.startStage = self.startStage;
            interval.endStage = self.endStage;
            interval.stageIntervalWellSelect = self.stageIntervalWellSelect;
            interval.pastHours = self.pastHours;
            //if there is no template yet for this chart then complete the first save through the template modal
            if (self.templateData.id == null) {
                self.modalVisible = true;
                return;
            }
            const packetData = {
                _token: GlobalFunctions.getCSRFToken(),
                id: self.templateData.id ? self.templateData.id : null,
                name: self.templateData.name,
                type: self.templateData.type,
                data: self.templateData.data,
                loadLatestStage: self.templateData.loadLatestStage
            };
            this.isSaving = true;
            axios.post(url, packetData)
                .then(function (response) {
                    self.isSaving = false;
                    self.returnedDataset = response;
                    self.$emit('updateDashboardItem', {
                        item: self.item,
                        targetTemplateId: response.data.id,
                        saveDashboard: true
                    });
                    self.templateName = response.data.name;
                    self.templateData.name = response.data.name;
                    self.$refs['settingsModal'].fetchAvailableTemplates();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onResize() {
            let divHeight = this.getComponentHeight();
            let filterHeadersId = this.$refs.filterHeaders;
            let chartInfoId = this.$refs.chartInfoHeader;

            if (filterHeadersId && chartInfoId) {
                let filterHeadersHeight = filterHeadersId.clientHeight;
                let chartInfoHeight = chartInfoId.clientHeight;
                this.canvasHeight = divHeight - filterHeadersHeight - chartInfoHeight;
            }
        },
        setChannelColor: function(channel,color) {
            const id = channel.id;
            if (this.chartOptions.channelOptions[channel.id] == null ) {
                this.chartOptions.channelOptions[id] = channel;
            }
            this.$set(this.chartOptions.channelOptions[channel.id],'color', color.hex);
        },
        unpackFilterData: function() {
            if(this.filterOptionsDataIn.selectablePads != null) {
                this.receivedPads = this.filterOptionsDataIn.selectablePads;
            }
            if(this.filterOptionsDataIn.selectableWells != null) {
                this.receivedWells = this.filterOptionsDataIn.selectableWells;
                this.selectableWells = this.receivedWells;
            }
            if(this.filterOptionsDataIn.selectableChannels != null) {
                this.receivedChannels = this.filterOptionsDataIn.selectableChannels;
            }
            if(this.filterOptionsDataIn.selectableStages != null) {
                this.receivedStages = this.filterOptionsDataIn.selectableStages;
            }
        },
        fetchFilterData: function() {
            //Should fetch filter information needed for this chart
            const url = '/analysis/getGhostChartFilterData/';
            const self = this;
            $.get(
                url,
                {
                    indexBy: ['Place Frac', 'Remove Frac'],
                    customerId: this.dashboardInfo.customer_id || this.userCompanyId,
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.receivedPads = result.selectablePads.sort((a,b)=>{
                            if (a.location < b.location) {return -1;}
                            if (a.location > b.location) {return 1;}
                            return 0;
                        });
                        self.receivedWells = result.selectableWells;
                        self.receivedStages = result.selectableStages;

                        self.setSelections(self.templateData.data);
                        if (self.templateData.loadLatestStage) {
                            self.selectLatestStages();
                        }
                    }
                    self.isLoadingPads = false;
                    //if a chart does not have a template (id), it does not have the ability to autoload data in any case
                    if (self.templateData.id && self.isAutoDataLoadValid()) {
                        self.validateAndFetchData(false);
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                //TODO: handle this
                }
            });
        },
        loadDataFromFilters: function() {
            const self = this;
            this.jobNumber = this.templateSelectedJobs?.name;
            //clear out any current chart data
            this.$refs.wellComparisonLightning.clearChart();
            this.activeStage = null;
            //get start and end values as utc unix timestamps
            let startUnix;
            let endUnix;
            if (this.timeType === 'time') {
                //convert start and end times to a unix timestamp, removing the job offset
                startUnix = moment.utc(this.startTime).add(-this.templateSelectedJobs?.hourOffset,'h').valueOf();
                endUnix = moment.utc(this.endTime).add(-this.templateSelectedJobs?.hourOffset,'h').valueOf();
            } else if (this.timeType == 'stage') {
                startUnix = moment.utc(this.startStage.stageStart,'YYYY-MM-DDTHH:mm:ss').valueOf();
                endUnix = moment.utc(this.endStage.stageEnd,'YYYY-MM-DDTHH:mm:ss').valueOf();
            } else if (this.timeType == 'current') {
                endUnix = moment.utc().valueOf();
                const hoursInMS = this.pastHours * MILLIS_PER_HR;
                startUnix =  endUnix - hoursInMS;
                this.followingLiveUpdates = true;
            }

            if (this.timeType === 'current') {
                if (this.isSignalRConnected) {
                    this.endSignalRConnection();
                }
                this.initializeSignalRConnection(this.sessionId, this.signalrMessageHandler, this.signalrConnectedHandler, this.signalrConnectionFailedHandler);
            }
            //dismiss current chart notifications
            this.$notify({
                group: 'dataFetch',
                clean: true
            });

            //get stage data for pad
            const url = '/analysis/getStagesforPad';
            const packetData = {
                _token: GlobalFunctions.getCSRFToken(),
                padsSelected: [this.templateSelectedJobs],
                startTime: this.jobStartUnix,
                endTime: this.jobEndUnix
            };
            const errors = [];
            axios.post(url, packetData)
                .then(function (response) {
                    self.stageData = {};
                    self.stageData.stages = response.data;
                    self.stageData.queryStartUnix = startUnix;
                    self.stageData.queryEndUnix = endUnix;
                })
                .catch(function (error) {
                    self.$notify({
                        group: 'dataFetch',
                        title: 'Stage Data Request Error',
                        text: 'An error ocurred fetching stage data. If the problem persists please contact your administrator.',
                        duration: -1
                    });
                });
            this.isLoading = true;
            this.isLoadComplete = false;
            this.clearChartData = true;
            //this function makes the call to the api to get data
            //a single call is made for multiple tags, but only a page
            //is retrieved at a time
            $.get(
                '/adxConfig/' + this.jobNumber,
                {},
                (result) => {
                    const adxAccessToken = result.adxAccessToken;
                    const adxURL = result.adxUrl;
                    const adxDBName = result.adxDBName;
                    self.downloadDataDirectFromADX(adxDBName, self.templateSelectedChannels.map(channel => channel.name), this.jobNumber, null, null, adxURL, adxAccessToken, startUnix, endUnix, self.aggregateInterval, self.directADXDownloadDataHandler);
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.warn('ADX config failure', errorThrown, jqXHR.responseText);
                self.$notify({
                    group: 'dataFetch',
                    title: 'Data Request Error',
                    text: 'ADX data request failed. If the problem persists please contact your administrator.',
                    duration: -1
                });
                self.isLoading = false;
                self.isLoadComplete = true;
            });
            return;
        },
        directADXDownloadDataHandler(result, tags, jobNumber, wellName, stageNumber, startDate, endDate, interval, startTimeOffset = 0) {
            const self = this;
            if(result.status != 200) {
                console.warn(result);
            }else if(result.data) {
                const resultData = result.data;

                const data = this.adxDataParser(tags, resultData);

                for(let tagNameIndex in tags) {
                    let tagName = tags[tagNameIndex];
                    const processedData = [];
                    data[tagName].forEach(dataPoint => {
                        if (dataPoint.dataVal != null) {
                            const rawTimestamp = moment.utc(dataPoint.dateTimestamp).valueOf();
                            const localTimeStringISO = moment.utc(dataPoint.dateTimestamp).add(this.templateSelectedJobs.hourOffset).toISOString();
                            const xVal = this.convertUnixTimeToLocal(rawTimestamp) - this.convertUnixTimeToLocal(this.jobStartUnix);
                            const yVal = dataPoint.dataVal;

                            processedData.push({
                                rawTimestamp: rawTimestamp,
                                localTimeString: localTimeStringISO,//timestamp,
                                x: xVal,
                                y: yVal
                            });
                        }
                    });

                    this.sortAndDisplayADXData(tagName, processedData);
                }
            }
            this.isLoading = false;
        },
        sortAndDisplayADXData(tagName, dataSet) {
            const channel = this.templateSelectedChannels.find(channel => channel.name == tagName);
            const chartOptions = this.chartOptions.channelOptions[channel.id];
            const axisOptions = this.chartOptions.axisOptions[chartOptions.axisId];
            if (dataSet.length != 0 && axisOptions) { //no data returned for tag, show error notification and abort data processing/adding
                dataSet.sort((a,b)=>a.rawTimestamp - b.rawTimestamp);
                const targetChannelData = {
                    dataSet: dataSet,
                    friendlyName: channel.friendlyTagname,
                    tagId: channel.tagId || channel.id,
                    tagName: channel.name,
                    axisId: axisOptions.id
                };
                this.$refs.wellComparisonLightning.addChartPointData(this.jobNumber, targetChannelData);
                //if all expected responses for all tags have not returned yet then the chart is still loading
            } else {
                this.$notify({
                    group: 'dataFetch',
                    title: 'Data Fetch Error',
                    text: `Data is missing or non-existant for channel ${tagName} over the selected time interval.`,
                    duration: -1
                });
            }
            this.isLoading = false;
            this.isLoadComplete = true;
        },
        validateAndFetchData: function(showErrorMessages=true) {
            if (this.validateFetchData(showErrorMessages)) {
                this.$forceUpdate(); //variables changed are nested, so change isn't seen until the next update
                this.loadDataFromFilters();
            }
        },
        returnToDefaultsMethod() {
            //return to defaults applies to all axes
            Object.values(this.chartOptions.axisOptions).forEach(option => {
                option.returnToDefaults = true
            })
            //update chart and reset:
            this.$forceUpdate();
            this.$refs.wellComparisonLightning.applyOptionChanges();
            Object.values(this.chartOptions.axisOptions).forEach(option => {
                option.returnToDefaults = false;
            })
        },
        validateFetchData: function(showErrorMessage) {
            const testData = this.templateData.data;
            let errorString = '';
            //Check at least one pad selected
            if(testData.jobs == null || testData.jobs.length == 0) {
                errorString = errorString + '\n' + 'Please select at least one pad.';
            }
            if (testData?.interval?.timeType == 'stage' && (testData?.interval?.startStage == null || testData?.interval?.endStage == null)) {
                errorString = errorString + "\n" + "Please select stage start and end."
            }
            //Check at least one channel selected
            if(testData.channels == null || testData.channels.length == 0) {
                errorString = errorString + "\n" + "Please select at least one channel.";
            }
            if(errorString != '') {
                errorString = "Please resolve the following issues before fetching data :" + errorString;
                if (showErrorMessage) {
                    alert(errorString);
                }
                return false;
            }else{
                return true;
            }
        },
        //Selection Handlers
        padsSelected: function(newValue) {
            if (newValue != null) {
                //if multiselect, use array. If single select, put object in array of one for similar handling
                this.selectedPads = Array.isArray(newValue)? newValue : [newValue];
                if (this.startTime == null) {
                    this.startTime =
                         moment.utc(this.selectedPads[0]?.start).add(this.selectedPads[0].hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss');
                    this.minimumDate = this.startTime;
                }
                if (this.endTime == null) {
                    this.endTime = this.selectedPads[0]?.end ?
                        moment.utc(this.selectedPads[0].end).add(this.selectedPads[0].hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss')
                        : moment.utc().add(this.selectedPads[0].hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss');
                }
            } else {
                this.clearConfiguration();
            }
        },
        wellsSelected: function(newValue) {
            this.selectedWells = newValue;
        },
        stagesSelected: function(newValue) {
            this.selectedStages = newValue;
        },
        channelsSelected: function(newValue) {
            this.selectedChannels = newValue;

            //add new channel/axis options, remove old channel/axis options
            const self = this;
            this.selectedChannels.forEach(channel => {
                let options = Object.values(self.chartOptions.channelOptions).find(obj => obj?.id != null && obj.id === channel?.id);
                if (!options) {
                    options = {
                        ..._.cloneDeep(this.chartOptionsDefault.channelOptions),
                        color: channel?.color? channel.color : GlobalFunctions.getRandomColor('dark'),
                        id: channel.id,
                        show: true,
                        location: 'default',
                        unit: channel?.unit? channel.unit : ''
                    };
                    self.chartOptions.channelOptions[channel.id] = options;
                }
                //create a generic auto axis for a tag to fall back to
                const autoAxis = Object.values(self.chartOptions.axisOptions).find(axis => axis.channelId == options.id && axis.axisCreationType == 'auto');
                if (!autoAxis) {
                    const newAxisId = uuidv4();
                    self.chartOptions.axisOptions[newAxisId] = {
                        ..._.cloneDeep(self.chartOptionsDefault.axisOptions),
                        id: newAxisId,
                        label: channel.prioritizedTagName? channel.prioritizedTagName: channel.friendlyTagname,
                        axisCreationType: 'auto',
                        channelId: channel.id
                    };
                    options.axisId = newAxisId;
                } else {
                    options.axisId = autoAxis.id;
                }
                if (channel?.defaultAxisId) {
                    //use default axis to create axis options
                    //check if default axis has already been created for this template
                    let defaultAxis = Object.values(self.chartOptions.axisOptions).find(axis => axis.id == channel.defaultAxisId);
                    if (!defaultAxis) {
                        if(defaultAxis = self.defaultAxes.find(axis => axis.id == channel.defaultAxisId)) {
                            self.chartOptions.axisOptions[defaultAxis.id] = {
                                id: defaultAxis.id,
                                label: defaultAxis.label,
                                axisMin: defaultAxis.min,
                                axisMax: defaultAxis.max,
                                location: defaultAxis.position == 'left'? 'default' : 'opposite',
                                autoFit: false,
                                axisCreationType: 'default',
                                channels: [channel.id] //channels that share this default axis
                            };
                        }
                    } else if (!defaultAxis.channels.includes(channel.id))  {
                        defaultAxis.channels.push(channel.id);
                    }
                    options.axisId = defaultAxis?.id ? defaultAxis.id : options.axisId;
                }
                if (options.customAxisId) {
                    const customAxis = Object.values(self.chartOptions.axisOptions).find(axis => axis.id == options.customAxisId);
                    if (customAxis) {
                        options.axisId = customAxis.id;
                        customAxis.channels.push(options.id);
                    }
                }
                //ensure axis has a unit for it
                const axis = Object.values(self.chartOptions.axisOptions).find(axis => axis.id == options.axisId);
                if (axis && (!axis?.unit || axis?.unit == '')) {
                    if (channel?.unit) {
                        axis.unit = channel.unit;
                    } else {
                        axis.unit = '';
                    }
                }
            });
            //remove auto/default axis options for channels that are no longer selected
            const channelIDs = new Set(this.selectedChannels.map(channel => {return channel.id;}));
            for (const axisId in this.chartOptions.axisOptions) {
                const axisOption = this.chartOptions.axisOptions[axisId];
                if (axisOption.axisCreationType == 'auto' && !channelIDs.has(axisOption.channelId)) {
                    delete this.chartOptions.axisOptions[axisId];
                }
                if (axisOption.axisCreationType == 'default' && !channelIDs.has(...axisOption.channels)) {
                    delete this.chartOptions.axisOptions[axisId];
                }
            }
        },
        //Selection Handlers ends here
        loadedTemplate: function(incomingTemplateData) {
            this.loadTemplate(incomingTemplateData);
            if (this.isAutoDataLoadValid()) {
                this.$nextTick(() => { this.validateAndFetchData(false); }); //suppress error messages on template load
            }
        },
        createdTemplate: function(incomingTemplateData) {
            this.$emit('updateDashboardItem', {
                item : this.item,
                targetTemplateId : incomingTemplateData.id,
                saveDashboard: true
            });
            //Takes the returned data and updated the local state so we now have id available for subsequent updates to the object
            this.loadTemplate(incomingTemplateData);
        },
        updatedTemplate: function(incomingTemplateData) {
            this.$emit('updateDashboardItem', {
                item : this.item,
                targetTemplateId : incomingTemplateData.id,
                saveDashboard: true
            });
        },
        loadTemplate: function(incomingTemplateData, saveDashboard=false) {
            this.templateData.id = incomingTemplateData.id;
            this.templateData.name = incomingTemplateData.name;
            this.templateData.type = incomingTemplateData.type;
            this.templateData.createdBy = incomingTemplateData.createdBy;
            this.templateData.isUserDefault = !!incomingTemplateData.is_user_default;
            this.templateData.data = JSON.parse(incomingTemplateData.data);

            if(!!incomingTemplateData.load_latest_stage && this.templateData.data.interval.stageIntervalWellSelect) {
                this.stageIntervalWellSelect = this.templateData.data.interval.stageIntervalWellSelect;
                this.templateData.loadLatestStage = true;
            }
            const interval = this.templateData.data.interval; //time parameters for channel data retrieval
            //population of template data for time interval controls is handled by the timeType watcher
            this.timeType = interval.timeType;
            const stageBandOptions = this.templateData.data.chartOptions.stageBandOptions; //optional config data for chart
            const channelOptions = this.templateData.data.chartOptions.channelOptions;
            const axisOptions = this.templateData.data.chartOptions.axisOptions;
            this.chartOptions.axisOptions = axisOptions;
            //db actions may store the empty object as an array, which can cause issues
            if (stageBandOptions && !Array.isArray(stageBandOptions) && Object.keys(stageBandOptions).length > 0) {
                this.chartOptions.stageBandOptions = stageBandOptions;
                this.chartOptions.allStageBandsToggle = this.templateData.data.chartOptions.allStageBandsToggle;
                this.chartOptions.allStageBandsToggle = this.templateData.data.chartOptions.allStageBandsToggle;
            }
            if (channelOptions && !Array.isArray(channelOptions) && Object.keys(channelOptions).length > 0) {
                this.chartOptions.channelOptions = channelOptions;
            } else if (this.templateData.data.channelOptions) {//handler for legacy key location for older dashboards
                this.chartOptions.channelOptions = this.templateData.data.channelOptions;
            }
            if (axisOptions && !Array.isArray(axisOptions) && Object.keys(axisOptions).length > 0) {
                this.chartOptions.axisOptions = axisOptions;
            }
            //set defaults for channel options if they do not exist
            Object.values(this.chartOptions.channelOptions).forEach(channel => {
                if (channel.show === null || channel.show === undefined) {
                    channel.show = true;
                }
                if (channel.location === null || channel.location === undefined) {
                    channel.location = 'default'; //axis will be on left side of chart
                }
            });
            this.pastHours = parseInt(interval.pastHours) ?? INITIAL_WINDOW_HRS;
            this.aggregateInterval = interval.aggregateInterval || '';

            //currently not using modal to change config setting, that may change in the future
            // self.$refs['settingsModal'].fetchAvailableTemplates();

            // Emit an event here to be captured by the analytics dashboard to set the appropriate component level template persistance option
            this.$emit('updateDashboardItem', {
                item: this.item,
                targetTemplateId: this.templateData.id,
                saveDashboard
            });
        },
        newTemplate: function() {
            this.templateData = _.cloneDeep(this.templateDataDefault);
            this.clearConfiguration();
        },
        copyChartTemplate: function() {
            this.templateData = _.cloneDeep(this.templateData);
            this.templateData.name = this.templateData.name + ' (Copy)';
        },
        deleteChartTemplate: function() {
            const self = this;
            //need to delete template from db, and then change the local template back to the default
            const url = '/analysis/deleteTemplate';
            const data = {
                targetTemplateID: this.templateData.id
            };
            axios.post(url, data)
                .then(function (response) {
                    self.templateData = _.cloneDeep(self.templateDataDefault);
                    self.clearConfiguration();
                    self.$refs.settingsModal.fetchAvailableTemplates();//refresh the template list
                    // Emit an event here to be captured by the analytics dashboard to set the appropriate component level template persistance option
                    self.$emit('updateDashboardItem', {
                        item: self.item,
                        targetTemplateId: null,
                        saveDashboard: true
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        clearSelections: function() {
            //Clear all the current setting data
            this.selectedPads = [];
            //selected Pad must be deleted in both template and local prop to fully clear it for save
            this.templateData.data.jobs = [];
            this.selectedWells = [];
            this.selectableWells = [];
            this.selectedStages = [];
            this.selectedChannels = [];
            this.refreshTicker = !this.refreshTicker;
        },
        setSelections: function(incomingTemplateData) {
            //set all of the active filter selections to the defined values
            this.selectedPads = incomingTemplateData.jobs;
            this.selectedWells = incomingTemplateData.wells;
            this.selectedStages = incomingTemplateData.stages;
            this.selectedChannels = incomingTemplateData.channels;
        },
        getDefaultChannels: function() {
            const url = '/analysis/getDefaultChannels';
            const self = this;

            $.get(
                url,
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.defaultChannels = result;
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        getChannelsForJobs: function(jobs) {
            const url = '/analysis/getChannelsForJobs';
            const self = this;
            this.isLoadingChannels = true;

            $.get(
                url,
                {
                    targetJobs: jobs,
                    getDefaultAxis: true
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        const channels = [...result.defaultChannels,...result.selectableChannels];
                        //remove channel duplicates, shows the last duplicate found
                        self.receivedChannels =
                            [...new Map(channels.map((channel) => [channel['name'], channel])).values()];
                        if (result?.defaultAxes) {
                            self.defaultAxes = [...result.defaultAxes];
                        }
                        //when switching jobs keep selected channels except for those that are not valid on the newly loaded job,
                        //but update channels to the new job's options
                        const currentSelectedChannels = [];
                        self.templateData.data.channels.forEach(channel => {
                            const channelData = self.receivedChannels.find(recChannel => channel.name == recChannel.name);
                            if (channelData) {
                                currentSelectedChannels.push(channelData);
                            }
                        });
                        self.templateData.data.channels = currentSelectedChannels;
                        self.channelsSelected(self.templateData.data.channels);
                    }
                    self.isLoadingChannels = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
                self.isLoadingChannels = false;
            });
        },
        validateStageSelects(typeChanged) {
            //de-select the option of loading latest stage data by default
            this.templateData.loadLatestStage = false;
            //Ensure that the stage start time is always lower than the stage end time and vice versa
            if (typeChanged === 'start' && this.endStage) {
                if (this.endStage.stage < this.startStage.stage) {
                    this.endStage = this.selectableStageEnds.find(stageEnd => stageEnd.stage >= this.startStage.stage);
                }
            }
            if (typeChanged === 'end' && this.startStage) {
                if (this.startStage.stage > this.endStage.stage) {
                    this.startStage = this.selectableStageStarts.slice().reverse().find(stageStart => stageStart.stage <= this.startStage.stage);
                }
            }
        },
        updateStageTimeSelects () {
            //get a list of valid stage starts and ends for dropdowns
            const validWellStages = this.selectableStages.filter(well => well.valueArray[0].wellNumber == this.stageIntervalWellSelect.index);
            const stagesArray = validWellStages[0]?.valueArray;
            if (stagesArray) {
                //Get the first place frac event and last remove frac event for each well stage for select drop downs
                this.selectableStageStarts = stagesArray.filter((stage, i, array) => stage.requestReason == 'Place Frac'
                    && stage.wellNumber == this.stageIntervalWellSelect.index
                    && array.findIndex(stage2 => (stage2.stage === stage.stage)) === i);
                this.selectableStageEnds = stagesArray.slice().reverse().filter((stage, i, array) => stage.requestReason == 'Remove Frac'
                    && stage.wellNumber == this.stageIntervalWellSelect.index
                    && array.findIndex(stage2 => (stage2.stage === stage.stage)) === i).reverse();
                //add job start + job end/current time as selectable stage options
                this.selectableStageStarts.unshift({
                    stage: 'Start',
                    job_id: this.selectedPads[0].id,
                    stageStart: this.jobStartTime
                });
                this.selectableStageEnds.push({
                    stage: this.selectedPads[0]?.end? 'End' : 'Latest',
                    job_id: this.selectedPads[0].id,
                    stageEnd: this.jobEndTime
                });
                //if load latest stage is selected, then grab the highest stage for that well
                if (this.templateData.loadLatestStage) {
                    this.selectLatestStages();
                } else {
                    //otherwise if there is already a saved value for selected start/end, populate it
                    const interval = this.templateData.data.interval;
                    if (!this.startStage || this.startStage.wellNumber !== stagesArray[0].wellNumber) {
                        if (interval.startStage && interval.startStage.wellNumber == this.stageIntervalWellSelect.index) {
                            this.startStage = interval.startStage;
                        } else {
                            this.startStage = this.selectableStageStarts[0];
                        }
                    }
                    if (!this.endStage || this.endStage.wellNumber !== stagesArray[0].wellNumber) {
                        if (interval.endStage && interval.endStage.wellNumber == this.stageIntervalWellSelect.index) {
                            this.endStage = interval.endStage;
                        } else {
                            this.endStage = this.endStage = this.selectableStageEnds[this.selectableStageEnds.length-1];
                        }
                    }
                }
            }
        },
        signalrMessageHandler(message) {
            if (this.isSignalRConnected) {
                //wait until historic data is loaded before processing incoming data
                if (message.telemetry && this.timeType == 'current' && !this.isLoading) {
                    this.$refs.wellComparisonLightning.addLivePointData(message.msTimestamp, message.telemetry);
                }
                if(message.category == 'handshake' && message.subCategory == 'completed') {
                    if (message.data.requestReason == 'Place Frac') {
                        //add new stage data to the record and raise current flag
                        this.activeStage = message;
                        this.stageData.stages.push(message); //add data to record

                        //create the new stage band
                        const eventUnixDiff = moment.utc(message.timestamp).valueOf() - this.jobStartUnix;
                        const currentTimeDiff = moment.utc().valueOf() - this.jobStartUnix;
                        this.$refs.wellComparisonLightning.createStageBand(eventUnixDiff, currentTimeDiff, message.wellId, this.activeStage.stageNumber);
                    } else if (message.data.requestReason == 'Remove Frac'
                            && message.wellNumber == this.activeStage.wellNumber && message.stageNumber == this.activeStage.stageNumber) {
                        this.activeStage = null;
                    }
                }
                if (this.activeStage) { //if there is an well in frac state, increase well-stage band size
                    const eventUnixDiff = moment.utc(message.timestamp).valueOf() - this.jobStartUnix;
                    this.$refs.wellComparisonLightning.modifyStageBand(this.activeStage, null, eventUnixDiff);
                }
            }
        },
        signalrConnectedHandler() {
            console.log('signalR connection success');
            this.isSignalRConnected = true;
            this.$notify({
                group: 'signalR',
                clean: true
            });
            this.showSignalRConnectError = false;
        },
        signalrConnectionFailedHandler(error, resolutionPackage) {
            console.log('signalR connection interrupted, attempting to reconnect.');
            //To Do: Put attempts to reconnect in a timeout function
            if (this.isSignalRConnected) {
                this.initializeSignalRConnection(this.sessionId, this.signalrMessageHandler, this.signalrConnectedHandler, this.signalrConnectionFailedHandler);

                if (this.timeType == 'current' && !this.showSignalRConnectError) {
                    this.$notify({
                        group: 'signalR',
                        title: 'SignalR Connection Ended',
                        text: 'If problems persist, re-load series data to establish a new connection.',
                        duration: -1
                    });
                    this.showSignalRConnectError = true;
                }
            }
        },
        lightningChartErrorHandler(error) {
            if (error.type === 'stageBand') {
                this.stageBandErrorHandler(error);
            }
        },
        stageBandErrorHandler(error) {
            this.$notify({
                group: 'dataFetch',
                title: error.title,
                text: error.message,
                ignoreDuplicates: true,
                duration: -1
            });
        },
        quickAddComponentHandler(templateDefaults) {
            //pass and select defaults to new component
            if (templateDefaults?.pads) {
                let templatePad = templateDefaults?.pads[0]? templateDefaults.pads[0].jobNumber: null; //Should be array of one since can only select one pad for MWT
                if (templatePad) {
                    this.templateData.data.jobs = [this.receivedPads.find(pad => pad.name == templatePad)];
                    this.padsSelected(this.templateData.data.jobs)
                }
            }
            if (templateDefaults?.channels) {
                this.templateData.data.channels = templateDefaults.channels;
                this.channelsSelected(this.templateData.data.channels); //build channel and axis options
            }
            if (this.templateData.data.jobs.length > 0 && templateDefaults?.well && templateDefaults.well?.nameLong) {
                const targetWells = this.receivedWells.find(wellGroup => wellGroup.subCatLabel == templateDefaults.pads);
                this.stageIntervalWellSelect = targetWells.valueArray.find(well => well.wellName == templateDefaults.well.nameLong);
            }
            if (this.stageIntervalWellSelect && Object.keys(this.stageIntervalWellSelect).length > 0) {
                this.templateData.loadLatestStage = true;
                this.selectLatestStages();
            }
            this.timeType = templateDefaults.timeType;
            this.templateData.data.interval.timeType = templateDefaults.timeType;

            if (this.timeType == 'stage') {
                this.templateData.loadLatestStage = true;
            }
            this.pastHours = templateDefaults.pastHours;
            this.aggregateInterval = templateDefaults.aggregateInterval;

            this.templateData.isUserDefault = true;
            this.templateData.data.chartOptions.allStageBandsToggle = true;
            this.setSelections(this.templateData.data);
            //load chart data on the next tick so that the props passed to the lightning chart have time to update
            this.$nextTick(() => {
                this.$refs.settingsModal.saveTemplate(); //save the default template values in the newly added chart
                this.validateAndFetchData();
            });
        }
    },
    computed: {
        chartAreaStyle() {
            return {
                height: this.canvasHeight,
                overflow: 'hidden'
            };
        },
        isNoTemplateSelected() {
            let templateInfo = this.templateData.data;
            return !this.templateData.name || (!templateInfo?.jobs && !templateInfo?.wells && !templateInfo?.stages && !templateInfo?.channels);
        },
        lockToMinChannelIds() {
            let lockToMinChannels;
            this.lockToMinTags.forEach((tag) => {
                lockToMinChannels = this.selectedChannels.map((element) => {
                    if (element.name == tag) {
                        return element.id;
                    }
                })
            })
            return lockToMinChannels;
        },
        templateSelectedJobs() {
            //shortcut to job data stored in the chart template.
            //This may share the same reference to selected pad, however since this is derived from the
            //stored template it can be a source of data when selectedPad is not assigned a value
            if (this?.templateData?.data?.jobs && this.templateData.data.jobs[0]) {
                return this.templateData.data.jobs[0];
            }
            return null;
        },
        templateWells() {
            if (this.templateData?.data?.wells && this.templateData.data.wells.length > 0) {
                return this.templateData.data.wells;
            } else {
                return [];
            }
        },
        templateSelectedChannels() {
            if (this?.templateData?.data?.channels && this.templateData.data.channels.length > 0) {
                return this.templateData.data.channels;
            } else {
                return [];
            }
        },
        chartHeight() {
            //TO DO //fix chart height at some point so it properly stretches to the bottom of the display item
            return (this.height - 150);
        },
        filtersPopulated() {
            return this.selectedPads.length > 0 && this.selectedChannels.length > 0;
        },
        timeFiltersPopulated() {
            let timeFilters = null;
            if (this.timeType == 'time') {
                timeFilters = (this.startTime != null) && (this.endTime != null);
            } else if (this.timeType == 'stage') {
                timeFilters = (this.startStage != null) && (this.endStage != null)
                    && this.startStage.job_id == this.selectedPads[0].id && this.endStage.job_id == this.selectedPads[0].id;
            } else if (this.timeType == 'current') {
                timeFilters = this.pastHours != null;
            }
            return timeFilters;
        },
        channelColorChange() {
            const color = this.chartOptions.channelOptions[this.channelColorFocus]?.color;
            return color ?? '#FFF';
        },
        jobStartTime() {
            return moment.utc(this.templateSelectedJobs?.start).format('YYYY-MM-DDTHH:mm:ss');
        },
        localJobStartTime() {
            if (!this.templateSelectedJobs || this.templateSelectedJobs?.start == null) {
                return '';
            }
            return moment.utc(this.templateSelectedJobs?.start).add(this.templateSelectedJobs?.hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss');
        },
        jobStartUnix() {
            return moment.utc(this.templateSelectedJobs?.start).valueOf();
        },
        jobEndTime() {
            return this.templateSelectedJobs?.end ? this.templateSelectedJobs?.end : moment.utc().format('YYYY-MM-DDTHH:mm:ss');
        },
        localJobEndTime() {
            if (!this.templateSelectedJobs || this.templateSelectedJobs.end == null) {
                return '';
            }
            return this.templateSelectedJobs?.end ?
                moment.utc(this.templateSelectedJobs.end).add(this.templateSelectedJobs?.hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss')
                : moment.utc().add(this.templateSelectedJobs?.hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss');
        },
        jobEndUnix() {
            return this.templateSelectedJobs?.end ?
                moment.utc(this.templateSelectedJobs?.end).valueOf() : moment.utc().valueOf();
        },
        pastHoursInMS() {
            return this.pastHours * MILLIS_PER_HR;
        }
    },
    watch: {
        receivedChannels: function(newVal, oldVal) {
            //If there is more selected channels then received channels for a given job, we filter them out.
            let selectedChannelNames = this.selectedChannels.map(channel => channel.name);
            let receivedChannelNames = this.receivedChannels.map(channel => channel.name);
            let validChannels = selectedChannelNames.filter(channel => receivedChannelNames.includes(channel));
            let newSelectedChannels = [];
            validChannels.forEach(validChannel => {
                let selectedChannels = this.selectedChannels.find(selectedChannel => selectedChannel.name == validChannel);
                newSelectedChannels.push(selectedChannels);
            })
            this.selectedChannels = newSelectedChannels;
        },
        selectedPads: {
            deep: true, //watch for changes in properties if true
            immediate: this?.templateSelectedJobs ? true: false, //set to true to fire on start up (such as if template data is saved)
            handler (jobs, oldJobs) { //remove unselectable wells
                if (!jobs || jobs.length == 0) {
                    return;
                }
                //if job changes clear stageband options and end live updates
                if (oldJobs.length != 0 && jobs[0]?.id != oldJobs[0]?.id) {
                    this.chartOptions.stageBandOptions = {};
                    if (this.isSignalRConnected) {
                        this.endSignalRConnection();
                        this.isSignalRConnected = false;
                    }
                    this.$refs.wellComparisonLightning.clearChart();
                }
                //Fetch selectable channels for currently selected jobs and verify
                //previously selected channels are still in that list
                if (jobs.length != 0) {
                    this.chartOptions.channelOptions = {};
                    this.chartOptions.axisOptions = {};
                    this.getChannelsForJobs(jobs.map(a => a.name));
                }

                //selectable wells based on selected pads
                const targetPadNames = jobs.map(a => a.name);
                const targetWells = this.receivedWells.filter((well) => {
                    if(targetPadNames.includes(well.subCatLabel)) {
                        return well;
                    }
                });
                this.selectableWells = targetWells[0].valueArray;
                //set up default well stage config options for each well
                const self = this;
                this.selectableWells.forEach(well => {
                    if (!self.chartOptions?.stageBandOptions.hasOwnProperty(well.id)) {
                        self.chartOptions.stageBandOptions[well.id] = {
                            ...self.chartOptionsDefault.stageBandOptions,
                            wellId: well.id,
                            wellNumber: well.index
                        };
                    }
                });

                this.selectableStages = this.receivedStages.filter(stage => {
                    if (stage.job_id == jobs[0].id) {
                        return stage;
                    }
                });

                //unselect wells that cannot be selected
                const selectedJobIDs = jobs.map((job) => job.id);
                this.selectedWells = this.selectedWells.filter((well)=> selectedJobIDs.includes(well.job_id));
                this.templateData.data.jobs = jobs;
                //update data required for signalR connection
                this.systemName = this.selectedPads[0].system;
            }
        },
        selectableWells: function(newVal, oldVal) {
            this.templateData.data.wells = newVal;
        },
        selectableStages: function(newVal, oldVal) {
            this.updateStageTimeSelects();
        },
        selectedStages: function (stages, oldVal) {
            this.templateData.data.stages = stages;
        },
        selectedChannels: function (channels, oldVal) {
            this.templateData.data.channels = channels;
        },
        aggregateInterval: function (newVal, oldVal) {
            this.templateData.data.interval.aggregateInterval = newVal;
        },
        timeType: function(timeType, oldVal) {
            const interval = this.templateData.data.interval;
            interval.timeType = timeType;
            if (timeType === 'time') {
                //should check for saved start and end times in interval here for first load
                const padInfo = this.templateSelectedJobs;
                this.startTime = interval?.startTime? interval.startTime : moment.utc(padInfo?.start).add(padInfo?.hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss');//moment(padInfo.start, 'YYYY-MM-DD HH:mm:ss' ).format('YYYY-MM-DDTHH:mm:ss');

                const validEnd = padInfo?.endTime?
                    moment.utc(padInfo.end).add(padInfo?.hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss')
                    : moment.utc().add(padInfo?.hourOffset,'h').format('YYYY-MM-DDTHH:mm:ss');
                this.endTime = interval?.endTime? interval.endTime : validEnd;
            } else if (timeType === 'stage') {
                if (interval.startStage && interval.endStage) {
                    this.startStage = interval.startStage;
                    this.endStage = interval.endStage;
                    this.stageIntervalWellSelect = interval.stageIntervalWellSelect;
                }
            } else if (timeType === 'current') {
                this.pastHours = interval.pastHours ?? INITIAL_WINDOW_HRS;
            }
        },
        //below watchers update the currently selected interval values, in case the
        //user switches between different types. Saving will lock in the current values for next load
        startTime: function(start, oldVal) {
            this.templateData.data.interval.startTime = start;
        },
        endTime: function(end, oldVal) {
            this.templateData.data.interval.endTime = end;
        },
        startStage: function(start, oldVal) {
            this.templateData.data.interval.startStage = start;
        },
        endStage: function(end, oldVal) {
            this.templateData.data.interval.endStage = end;
        },
        stageIntervalWellSelect: function (newVal, oldVal) {
            this.templateData.data.interval.stageIntervalWellSelect = newVal;
        },
        pastHours: function(newVal, oldVal) {
            this.templateData.data.interval.pastHours = newVal;
        },
        isLoadingPads: function(newVal, oldVal) {
            this.defaultChannelsWhenNoTemplateSelected();
            if (!newVal && this.defaultTemplateData) {
                this.quickAddComponentHandler(this.defaultTemplateData);
            }
            if (!newVal) {
                //Updates axis options when pads/data is loaded
                const axisOptions = this.templateData.data.chartOptions? this.templateData.data.chartOptions?.axisOptions: null;
                if (axisOptions) {
                    this.chartOptions.axisOptions = axisOptions;
                    this.$forceUpdate(); //variables changed are nested, so change isn't seen until the next update
                    this.$refs.wellComparisonLightning.applyOptionChanges();
                }
            }
        },
        quickAddData: {
            handler(newVal, oldVal) {
                if (this.item.i == this.quickAddData.targetItemId) {
                    this.defaultTemplateData = this.quickAddData.templateDefaults;
                    this.$emit('updateDashboardItem', {
                        item: this.item,
                        targetTemplateId: null,
                        saveDashboard: true
                    });
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.getDefaultChannels();
        this.lightningChart = this.$refs.wellComparisonLightning;
        this.templateData = _.cloneDeep(this.templateDataDefault); //ensure default values for chart template
        this.fetchFilterData();

        let resizeObject = new ResizeObserver(this.onResize);
        resizeObject.observe(this.$refs.multiWellTimelineChart);

        //default filter options
        this.filterOptionsDataIn = {
            selectableIndices: [
                {id: 1, name: 'Place Frac on Well', requestReason: 'Place Frac'},
            ],
            selectableChannels: [
                {id: 1, name: 'Treating Pressure (psi)', tagName: 'frac_1_pressure_treatmentMain1'},
                {id: 2, name: 'Clean Rate (bbl/min)', tagName: 'frac_1_rate_treatmentClean'},
                {id: 3, name: 'Slurry Rate (bbl/min)', tagName: 'frac_1_rate_treatmentSlurry'},
                {id: 4, name: 'Clean Volume Stage Total (bbl)', tagName: 'frac_1_totalStageVolActual_clean'},
                {id: 5, name: 'Proppant Loading (lb/gal)', tagName: 'frac_1_conc_slurryPropInline1'},
                {id: 6, name: 'Proppant Stage Total (lb)', tagName: 'frac_1_totalStageProppantActual_pumped'}
            ]
        };
        this.unpackFilterData();
        //set up cross domain axios Instance for direct ADX calls
        this.axiosInstance =  axios.create();
        delete this.axiosInstance.defaults.headers.common['X-CSRF-TOKEN'];

        //mount listeners for actions from child components
        this.$root.$on('chartDataCleared',() => { //clears flag that empties out chart data
            this.clearChartData = false;
        });
        this.$root.$on('stopLiveUpdates',() =>{
            this.followingLiveUpdates = false;
        });
        this.$root.$on('applyOptionChanges', (options) => {
            const currentChannelOptions = this.chartOptions.channelOptions[options.channelOptions.id];
            //axis changed, lightningChart series data must be destroyed and re-created on another axis
            if (currentChannelOptions?.axisId !== options.channelOptions.axisId) {
                options.channelOptions.hasAxisChanged = true;
            }
            this.chartOptions.channelOptions[options.channelOptions.id] = options.channelOptions;
            this.chartOptions.axisOptions = options.axisOptions;
            this.$forceUpdate(); //variables changed are nested, so change isn't seen until the next update
            this.$refs.wellComparisonLightning.applyOptionChanges();
            if (options.saveConfiguration) {
                this.saveConfiguration();
            }
        });
        this.$root.$on('setActiveStage',(activeStage) => {
            this.activeStage = activeStage;
        });
    }

};
</script>
