var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.config
    ? _c(
        "div",
        {
          ref: _vm.myRef,
          staticClass: "text-light",
          staticStyle: {
            position: "absolute",
            top: "0px",
            height: "100%",
            width: "100%",
          },
        },
        [
          _vm.position != null
            ? _c("img", {
                ref: "openImage",
                staticClass: "img-fluid",
                style: _vm.valveStyle,
                attrs: { src: _vm.imageOpen(_vm.position) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showWellLocks && _vm.unlocked != null
            ? _c("img", {
                ref: "lockImage",
                staticClass: "img-fluid",
                style: _vm.locksStyle,
                attrs: { id: "lockImage", src: _vm.imageLock(_vm.unlocked) },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showWellLocks && _vm.unlocked != null
            ? _c("img", {
                ref: "dotsImage",
                staticClass: "img-fluid",
                style: _vm.dotsStyle,
                attrs: { id: "dotsImage", src: _vm.imageLock(_vm.unlocked) },
              })
            : _vm._e(),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }