<template>
    <div>
        <div v-if="componentSpecificPermissions[item.type] != undefined && !componentSpecificPermissions[item.type]"
            :style="'height:' + height + 'px;'" class="itemContainer">
            <div id="overlay">
                <div class="alert alert-warning m-4 absolute-center">
                    <div class="pb-2">Please contact your IWS Completions Advisor to enable {{ friendlyName }}</div>
                </div>
            </div>
        </div>
        <div v-else-if="!componentExists"
             :style="'height:' + height + 'px;'" class="itemContainer">
            <div id="overlay">
                <div class="alert alert-warning m-4 absolute-center">
                    <div class="pb-2">This component does not exist. Please delete.</div>

                </div>
            </div>
        </div>
        <div v-else>
            <div v-if='item.type == "well-group-component"'>
                <well-group-component v-if="dashboardData.wells && dashboardData.wells.length > 0"
                    :height="parentHeight"
                    :job="dashboardData.job"
                    :wells="wells"
                    :casingPressures="dashboardData.pressure.casingPressures"
                    :crownPressures="dashboardData.pressure.crownPressures"                 
                    :is-multi-wireline="dashboardData.isMultiWireline"
                    :wireline-systems="dashboardData.wirelineSystems"
                    :is-multi-frac="dashboardData.isMultiFrac"
                    :frac-systems="dashboardData.fracSystems"
                    :jobEnd="dashboardData.jobEnd"></well-group-component>
            </div>
            <div v-else-if='item.type == "event-table-component"'>
                <event-table-component
                    :editMode="editMode"
                    :item="item"
                    :dashboardData="dashboardData"
                    :height="height"
                    :heightOffset="35"
                    :detailed="dashboardData.true" 
                    :events="dashboardData.events" 
                    :wells="dashboardData.wells"
                    :eventActivities="dashboardData.eventActivities"
                    :eventReasons="dashboardData.eventReasons"
                    :contractors="dashboardData.contractors"
                    :eventActivityEventReasons="dashboardData.eventActivityEventReasons"
                    :jobNumber="dashboardData.jobNumber"
                    :jobHourOffset="dashboardData.job.hourOffset"  
                    :is-standalone-wireline="dashboardData.isStandaloneWireline"
                    @createCommentButtonClicked="dashboardData.handleCreateCommentButtonClicked"/>
            </div>
            <div v-else-if='item.type == "summary-bar-component"'>
                <!-- latestActivityData needs to be replaced with signal-r -->
                <summary-bar-component
                    v-if="isFeatureFlagged('NEW_SUMMARY_BAR') && dashboardData.summaryBarEndTime"
                    :editMode="editMode"
                    :endDate="dashboardData.summaryBarEndTime"
                    :bounds="{
                        lower: dashboardData.activitiesStartTime,
                        upper: dashboardData.activitiesEndTime,
                    }"
                    :height="height"
                    :jobStart="dashboardData.jobStart"
                    :jobShiftStart="dashboardData.job.shiftStart"
                    :jobNumber="dashboardData.jobNumber"
                    :isContinuousFrac="dashboardData.isContinuousFrac"
                    :isJobCompleted="dashboardData.jobEnd? true : false"
                    :isJobEndTime="dashboardData.jobEnd"
                    :is-multi-wireline="dashboardData.isMultiWireline"
                    :is-multi-frac="dashboardData.isMultiFrac"
                    :activityData="dashboardData.activitiesFetched"
                    :latestActivityData="dashboardData.latestActivityData"
                    :job-hour-offset="dashboardData.job.hourOffset"
                    :wells="dashboardData.wells"
                    :onXAxisChange="dashboardData.onSummaryXAxisChange"
                    :isSimuFrac="dashboardData.isSimuFrac"
                    :wireline-systems="dashboardData.wirelineSystems"
                    :frac-systems="dashboardData.fracSystems"
                    :summaryBarTimeFrame="dashboardData.summaryBarTimeFrame"
                    :latestActivityBySystem="dashboardData.latestActivityBySystem"
                    :item="item"
                >
                </summary-bar-component>
                <summary-bar-component-old
                    v-if="!isFeatureFlagged('NEW_SUMMARY_BAR') && dashboardData.summaryBarEndTime"
                    :endDate="dashboardData.summaryBarEndTime"
                    :bounds="{
                        lower: dashboardData.activitiesStartTime,
                        upper: dashboardData.activitiesEndTime,
                    }"
                    :height="height"
                    :jobNumber="dashboardData.jobNumber"
                    :isContinuousFrac="dashboardData.isContinuousFrac"
                    :isJobCompleted="dashboardData.jobEnd? true : false"
                    :isJobEndTime="dashboardData.jobEnd"
                    :is-multi-wireline="dashboardData.isMultiWireline"
                    :is-multi-frac="dashboardData.isMultiFrac"
                    :activityData="dashboardData.activitiesFetched"
                    :latestActivityData="dashboardData.latestActivityData"
                    :latestActivityBySystem="dashboardData.latestActivityBySystem"
                    :job-hour-offset="dashboardData.job.hourOffset"
                    :wells="dashboardData.wells"
                    :onXAxisChange="dashboardData.onSummaryXAxisChange"
                    :isSimuFrac="dashboardData.isSimuFrac"
                    :wireline-systems="dashboardData.wirelineSystems"
                    :frac-systems="dashboardData.fracSystems"
                >
                </summary-bar-component-old>
            </div>
            <div v-else-if='item.type == "dashboard-frac-chart" '>
                <dashboard-time-series-chart 
                        :key="item.type + item.i"
                        :item="item"
                        :height="height"
                        :width="width"
                        :layout="layout"
                        :heightOffset="50"
                        :dashboardItem="item"
                        :dashboardData="dashboardData"
                        :is-multi-frac="dashboardData.isMultiFrac"
                        :is-multi-wireline="dashboardData.isMultiWireline"
                        :is-continuous-frac="dashboardData.isContinuousFrac"
                        :defaultChartColumnCount="defaultChartColumnCount"
                        :onSyncedViewChanged="dashboardData.onSyncedViewChanged"
                        @onChartLoadComplete="updateActiveTags"
                        :signalRConnected="dashboardData.signalRConnected"
                        :iwsUser="!!iwsUser"
                        ref="dashboardFracComponent"
                        modalRefKey="chartConfigModal">
                </dashboard-time-series-chart>
            </div>
            <div v-else-if='item.type == "dashboard-comparison-chart"'>
                <dashboard-comparison-chart 
                        :chartType="analysisChartTypes.STAGE_COMPARE"
                        :key="item.type + item.i"
                        :height="height"
                        :heightOffset="50"
                        :dashboardData="dashboardData"
                        :dashboardInfo="dashboardInfo"
                        :userid="userid"
                        :filterOptionsData="{}"
                        :item="item"
                        :isAdmin="isAdmin"
                        :userCompanyId="userCompanyId"
                        @updateDashboardItem="updateDashboardItem"
                        ref="dashboardComparisonComponent"
                        modalRefKey="chartConfigModal">
                </dashboard-comparison-chart>
            </div>
            <div v-else-if='item.type == "well-comparison"'>
                <well-comparison-chart
                        :chartType="analysisChartTypes.WELL_COMPARE"
                        :sessionId="dashboardData.sessionId"
                        :key="item.type + item.i"
                        :height="height"
                        :heightOffset="50"
                        :dashboardData="dashboardData"
                        :dashboardInfo="dashboardInfo"
                        :userid="userid"
                        :filterOptionsData="{}"
                        :item="item"
                        :isAdmin="isAdmin"
                        :userCompanyId="userCompanyId"
                        @updateDashboardItem="updateDashboardItem"
                        ref="multiWellComparison"
                        modalRefKey="chartConfigModal"
                >
                </well-comparison-chart>
            </div>
            <div v-else-if='item.type == "latency-component"'>
                <latency-component
                        :wells="wells"
                        :utcDifference="utcDifference"
                        :tags="latencyComponentTags"
                        :items="items"
                        :latestDataCollection="latestDataCollection"
                        :latencySaving="latencySaving"
                        :latencySaved="latencySaved"
                        @onSavePressed="()=> this.$emit('onSavePressed')"
                />
            </div>
            <div v-else-if='item.type == "latency-component-new"'>
                <latency-component-new
                        :nav="false"
                        :utcDifference="utcDifference"
                        :latestDataCollection="latestDataCollection"
                />
            </div>
            <div v-else-if='item.type == "stage-summary-heatmap"'>
                <stage-summary-heat-map
                        :chartType="analysisChartTypes.STAGE_SUMMARY_HEATMAP"
                        :sessionId="dashboardData.sessionId"
                        :key="item.type + item.i"
                        :height="height"
                        :heightOffset="50"
                        :dashboardData="dashboardData"
                        :dashboardInfo="dashboardInfo"
                        :userid="userid"
                        :filterOptionsData="{}"
                        :item="item"
                        :isAdmin="isAdmin"
                        :userCompanyId="userCompanyId"
                        @updateDashboardItem="updateDashboardItem"
                        :ref="'stageSummaryHeatMap-' + item.i"
                        modalRefKey="chartConfigModal"
                >
                </stage-summary-heat-map>
            </div>
            <div v-else-if='item.type == "kpi-progress"'>
                <kpi-display-item v-if="!!customer"
                    :editMode="editMode"
                    :jobNumber="dashboardData.jobNumber"
                    :job="dashboardData.job"
                    :customer="customer"
                    :dashboardData="dashboardData"
                    :height="height"
                    :item="item">
                </kpi-display-item>
            </div>
            <div v-else-if='item.type == "activity-alert-component"'>
                <activity-alert-component
                    :onAlertPress="dashboardData.onAlertPress"
                    :height="height + 10"
                    :messagesArray="dashboardData.messagesArray"
                    :alertHistory="dashboardData.eventAlertHistory"
                    :clusterAlerts="dashboardData.clusterAlerts"
                >
                </activity-alert-component>
            </div>
            <div v-else-if='item.type == "wireline-diameter-component"'>
                <wireline-diameter-component
                    :dashboardData="dashboardData"
                    :height="height"
                    :item="item"
                    :editMode="editMode"
                    :latestSignalRDataCollection="dashboardData.latestDataCollection"
                    :allJobWells="dashboardData.wells"
                    :jobHourOffset="dashboardData.job.hourOffset"
                    :perforatingWellIndex="dashboardData.indexWireline()"
                    :wireline-systems="dashboardData.wirelineSystems"
                    ></wireline-diameter-component>
            </div>
            <div v-else-if='item.type == "wellbore-diagram-component"'>
                <wellbore-diagram-component
                    v-if="dashboardData.wells && dashboardData.wells.length > 0"
                    :height="height"
                    :width="width"
                    :layout="layout"
                    :dashboardData="dashboardData">
                </wellbore-diagram-component>
            </div>
            <div v-else-if='item.type == "single-well-display-component"'>
                <single-well-display-component 
                    :editMode="editMode"
                    :wells="wells"
                    :is-multi-wireline="dashboardData.isMultiWireline"
                    :wireline-systems="dashboardData.wirelineSystems"
                    :pressure="dashboardData.pressure"
                    :height="height"
                    :dashboardData="dashboardData"
                    :loading="!dashboardData.initActivityCallEnded"                   
                    :item="item"
                />
            </div>
            <div v-else-if='item.type == "wireline-info-component"'>
                <wireline-info-component 
                    :height="height"
                    :heightOffset="heightOffsetDefault"
                    :wirelineWellIndex="dashboardData.currentWellIndex"
                    :jobNumber="dashboardData.jobNumber"
                    :wells="dashboardData.wells"
                    :hourOffset="dashboardData.job.hourOffset"
                    :logEntries="dashboardData.logEntries"
                    :item="item"
                    :dashboardId="dashboardData.selectedDashboard.id"
                />
            </div>
            <div v-else-if='item.type == "wireline-controls-component"'>
                <wireline-controls-component
                    :item="item"
                    :dashboardId="dashboardData._data.selectedDashboard.id"
                    :loading="!dashboardData.initActivityCallEnded"
                    :wirelineWellIndex="dashboardData.currentWellIndex"
                    :jobNumber="dashboardData.jobNumber"
                    :wells="dashboardData.wells"
                    :hourOffset="dashboardData.job.hourOffset"
                    :customerId="dashboardData.customer_id"
                    :height="height"
                />
            </div>
            <div v-else-if="item.type=='activity-breakdown-pie-charts'">
                <activity-breakdown-pie-charts
                    :height="height"
                    :width="width"
                    :currentEvent="dashboardData.currentEvent"
                    :activityData="dashboardData.activitiesFetched"
                    :latestActivityData="dashboardData.latestActivityData"
                    :jobNumber="dashboardData.jobNumber"
                    :wells="dashboardData.wells"
                    :job="dashboardData.job"
                    :item="item"
                    :dashboardId="dashboardData._data.selectedDashboard.id"
                    :latestHandshakeEvent="dashboardData._data.latestHandshakeEvent"
                >
                </activity-breakdown-pie-charts>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isNullOrEmpty } = GlobalFunctions;

const HEIGHT_OFFSET = 25;

export default {
    inject: ['dashboardData'],
    props: {
        item: {
            type: Object,
            required: true
        },
        componentExists: {
            type: Boolean,
            required: false,
            default: true
        },
        iwsUser: {
            type: Boolean,
            required: false,
            default: false
        },
        layout: {
            type: Object,
            required: true
        },
        editMode: {
            type: Boolean,
            required: true
        },
        dashboardData: {
            type: Object,
            required: false
        },
        dashboardInfo: {
            type: Object
        },
        userid: {
            type: [String]
        },
		defaultChartColumnCount: {
			type: Number,
			required: false
		},
        isAdmin: {
            type: [Number, Boolean]
        },
        customers: {
            type: Array,
            default: () => [],
            required: false
        },
        userCompanyId: {
            type: String,
            required: false
        },
        componentSpecificPermissions: {
            type: Object,
            required: false
        },
        friendlyName: {
            type: String,
            required: false
        },
        latencyComponentTags: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        latestDataCollection: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        items: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        utcDifference: {
            type: [Number],
            default: ()=> 0,
            required: true
        },
        latencySaving: {
            type: Boolean,
            default: false,
            required: true
        },
        latencySaved: {
            type: Boolean,
            defualt: false,
            required: true
        }
    },
    data() {
        return {
            height: 400,
            width: 600,
            parentHeight: 0,
            analysisChartTypes: GlobalFunctions.analysisChartTypes(),
            heightOffsetDefault: HEIGHT_OFFSET
        };
    },
    computed: {
        customer() {
            return this.dashboardData.customerObj || {};
        },
        wells() {
            const wells = [];
            if (this.dashboardData.wells.length) {
                for(let i=0;i<this.dashboardData.wells.length;i++){
                    this.dashboardData.wells[i].configuration.components.forEach(async comp => {
                    const svgName = comp.wellhead_component.name;
                    const svgString = await axios.get(`/svgs/${svgName}`, {
                        "Content-Type": "image/svg+xml"
                    })
                    .then((svg) => comp.wellhead_component.svg = svg.data);
                    });
                    wells.push(this.dashboardData.wells[i])
                }
            }
            return wells;
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.height = this.$el.parentNode.clientHeight - HEIGHT_OFFSET; // HACK: this is the only way I've figured how to get the grid items height on load
            this.parentHeight = this.$el.parentNode.clientHeight;
            this.width = this.$el.parentNode.clientWidth;
            new ResizeObserver(this.updateSizeResolution).observe(this.$el.parentNode);
            this.updateActiveTags();
        });
    },
    methods: {
        updateSizeResolution(roEntryArray,ro) {
            if (roEntryArray[0]) {
                this.height = roEntryArray[0].contentRect.height - HEIGHT_OFFSET;
                this.width = roEntryArray[0].contentRect.width;
            }
        },
        updateActiveTags() {
            let activeTags = [];

            const wellTags = () => {
                const regexWellCrownTagMatch = /wellhead\_\d+\_pressure\_crown/;
                const regexWellheadTagMatch = /wellhead\_\d+\_pressure\_wellbore/;
                activeTags = this.dashboardData.tags.filter((tag)=>{
                    return (tag.name.match(regexWellheadTagMatch) || tag.name.match(regexWellCrownTagMatch)); 
                }).map((tag)=>tag.name);
            }

            switch (this.item.type) {
            case 'well-group-component':
                wellTags();
                break;
            case 'dashboard-frac-chart':
                const chartConfig = this.$refs['dashboardFracComponent'].chartConfiguration;
                activeTags = [];
                chartConfig?.sections.forEach(section => {
                    section?.orderedChartItems.forEach(chartItem => {
                        activeTags.push(chartItem.tagName);
                    });
                });
                break;
            case 'dashboard-comparison-chart':
                //TODO
                break;
            case 'single-well-display-component':
                wellTags();
                break;
            }

            this.$emit('pushToActiveTags', activeTags);
        },							
        getParentComponentRef() {
            return this.$parent;
        },
        updateDashboardItem(eventData) {
            this.$emit('updateDashboardItem', eventData);
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
    }
};
</script>
<style scoped>
    .itemContainer {
        background-color: #404040; 
        border-style: solid;
        border-width: thin;
        border-radius: 4px;
        border-color: #CCCCCC;
        padding: 16px;
        margin: 10px;
    }

    .errorText{
        background-color: rgba(30,30,30,1);
        z-index: 500;
        position: absolute;
        top: 50%;
        left: 50%;
        font-weight: bold;
        color: white;
        transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        text-align: center;
        border: 1px solid #a00;
        padding: 20px;
    }

    .absolute-center {
        position: absolute;
        left: 50%; 
        top: 50%;
        transform: translate(-50%, -50%);
    }
</style>