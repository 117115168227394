<template>
    <CustomDatePicker
        v-model="localValue"
        @input="updateValue"
        :type="dateTimeType"
        :disabled="disabled"
        :placeholder="placeholder"
        :value-type="dateTimeFormat"
        @close="onClose"
        use12h
        clearable
    />
</template>

<style>
.mx-input-wrapper *, .mx-calendar, .mx-time, .mx-btn {
    background-color: #343a40;
    color: #f0f0f0;
}

.mx-input {
    height: calc(2.25rem + 2px);

}

.mx-input:hover {
    border-color: #cccccc;
}

.mx-time-column .mx-time-item.active, .mx-table-date .today  {
    color: var(--primary-text-color);
}

.mx-btn:hover {
    color:  #73879c;
}

.mx-datepicker {
    width: 100%;
}

.mx-calendar-content .cell.active {
    background-color: #73879c;
}

</style>
<script>
import CustomDatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

const DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const DATE_TIME_TYPE = 'datetime'

export default {
    components: {
        CustomDatePicker
    },
    props: {
        // Synced value of input field
        value: {
            type: String,
        },
        disabled: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'YYYY-MM-DD hh:mm:ss'
        }

    },
    methods: {
        updateValue(value) {
            this.$emit('update:value', value);
            this.$emit('input', value);
        },
        onClose($event) {
            this.$emit('close', $event);
        }
    },
    data() {
        return {
            localValue: this.value,
            dateTimeFormat: DATE_TIME_FORMAT,
            dateTimeType: DATE_TIME_TYPE
        }
    }
}
</script>
