var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "position-relative",
      class: { "form-input-spacing": _vm.formSpacing !== false },
      attrs: { id: _vm.id },
    },
    [
      _vm.prependLabel
        ? _c(
            "label",
            {
              staticClass: "form-check-label",
              class: { clickable: _vm.enableClickLabel },
              on: { click: _vm.clickLabel },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    typeof _vm.prependLabel == "function"
                      ? _vm.prependLabel()
                      : _vm.prependLabel
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("input", {
        ref: "checkboxRef",
        attrs: { type: "checkbox", disabled: _vm.disabled },
        domProps: { value: _vm.value, checked: _vm.value },
        on: { change: _vm.onClick },
      }),
      _vm._v(" "),
      _vm.label
        ? _c(
            "label",
            {
              staticClass: "form-check-label",
              class: { clickable: _vm.enableClickLabel },
              on: { click: _vm.clickLabel },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(
                    typeof _vm.label == "function" ? _vm.label() : _vm.label
                  ) +
                  "\n    "
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }