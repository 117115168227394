var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      id: "comms-position-control",
      title: "Settings",
      "show-modal": _vm.modalIsOpen,
      "max-width": "800px",
    },
    on: {
      close: function ($event) {
        return _vm.resolve(null)
      },
      "secondary-action": function ($event) {
        return _vm.resolve(null)
      },
      "primary-action": function ($event) {
        return _vm.resolve({ audioDevice: _vm.selectedAudioDevice })
      },
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c(
              "div",
              [
                _c("iws-select", {
                  attrs: {
                    options: _vm.audioDevices,
                    label: "Audio",
                    value: _vm.selectedAudioDevice
                      ? _vm.selectedAudioDevice
                      : _vm.audioOutputId,
                    "display-name": "name",
                    "value-name": "id",
                  },
                  on: { change: _vm.audioDeviceChanged },
                }),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", { staticClass: "audiDeviceDisclaimer" }, [
                  _c("i", { staticClass: "fas fa-info-circle" }),
                  _vm._v(" "),
                  _c("span", { staticClass: "mx-2" }, [
                    _vm._v(
                      "If you do not see a newly connected device,  please refresh your page to see the device."
                    ),
                  ]),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _vm.isAdmin
              ? _c("div", { staticClass: "mt-2" }, [
                  _c("h5", [_vm._v("Radios")]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "mt-2" },
                    [
                      _c("iws-table", {
                        attrs: {
                          items: _vm.allSCRUnits,
                          columns: [
                            "SCR-Unit",
                            "Room Id",
                            "Service Channel",
                            "",
                          ],
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return _vm._l(
                                  _vm.allSCRUnits,
                                  function (scr, index) {
                                    return _c("tr", { key: index }, [
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(scr.roip_name) +
                                            "\n                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("td", [
                                        _vm._v(
                                          "\n                        " +
                                            _vm._s(scr.room_id) +
                                            "\n                    "
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("iws-select", {
                                            attrs: {
                                              value: _vm.selectedChannelForSCR(
                                                index,
                                                scr
                                              ),
                                              options: _vm.allChannels,
                                              "display-name": "alias",
                                              "value-name": "id",
                                            },
                                            on: {
                                              change: function ($event) {
                                                return _vm.mappingChanged(
                                                  scr,
                                                  index,
                                                  $event
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "td",
                                        [
                                          _c("iws-button", {
                                            staticClass: "d-none",
                                            attrs: {
                                              type: "primary",
                                              text: "Join",
                                              id: "mapButton-" + index,
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.mapSCRToService(
                                                  scr,
                                                  index
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ])
                                  }
                                )
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          186101987
                        ),
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "audiDeviceDisclaimer d-none",
                          attrs: { id: "roip-map-warning" },
                        },
                        [
                          _c("i", { staticClass: "fas fa-info-circle" }),
                          _vm._v(" "),
                          _c("span", {
                            staticClass: "mx-2",
                            attrs: { id: "roip-map-warning-text" },
                          }),
                        ]
                      ),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }