var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "customSearchSelect",
      staticClass: "custom-search-select",
      class: {
        required: _vm.required !== false,
        "form-input-spacing": _vm.formSpacing !== false,
      },
      attrs: { id: _vm._genUuid() },
    },
    [
      _vm.label
        ? _c("label", { staticClass: "primary-text-color" }, [
            _vm._v("\r\n        " + _vm._s(_vm.label) + "\r\n    "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          ref: "searchBarInputContainer",
          class: { "full-placeholder": _vm.fullPlaceholder },
          staticStyle: { position: "relative" },
        },
        [
          _c("iws-input", {
            ref: "searchBarInput",
            attrs: {
              placeholder: _vm.placeholder,
              value: _vm.searchKey,
              disabled: _vm.disabled,
              searchable: _vm.searchable,
            },
            on: {
              "update:value": function ($event) {
                _vm.searchKey = $event
              },
              focus: function ($event) {
                $event.target.select()
                _vm.showOptions()
              },
              blur: _vm.hideOptions,
              keyup: _vm.handleKey,
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "select-chevron clickable",
              on: {
                click: function ($event) {
                  _vm.optionsAreVisible
                    ? _vm.$refs.searchBarInput.blur()
                    : _vm.$refs.searchBarInput.focus()
                },
              },
            },
            [
              _c("i", {
                staticClass: "fas passthrough fa-chevron-down",
                class: { "spin-icon": _vm.optionsAreVisible },
              }),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.optionsAreVisible,
              expression: "optionsAreVisible",
            },
          ],
          ref: "optionsContainer",
          staticClass: "container",
          style: {
            top: `${_vm.dimensions.top}px`,
            left: `${_vm.dimensions.left}px`,
            width: `${_vm.dimensions.width}px`,
          },
        },
        [
          _c(
            "div",
            {
              ref: "resultScroll",
              staticClass: "options-container",
              style: { "max-height": _vm.optionsHeight },
            },
            [
              _vm._isNullOrEmpty(_vm.options)
                ? [
                    _vm._t(
                      "option",
                      function () {
                        return [
                          _c("option", [
                            _vm._v(
                              "\r\n                        No Options\r\n                    "
                            ),
                          ]),
                        ]
                      },
                      {
                        filteredOptions: _vm.options,
                        selectOption: _vm.selectOption,
                        filterOption: _vm.filterOption,
                      }
                    ),
                  ]
                : _vm._isNullOrEmpty(_vm.filteredOptions)
                ? [
                    _vm._t(
                      "option",
                      function () {
                        return [
                          _c("option", [
                            _vm._v(
                              "\r\n                        No Results\r\n                    "
                            ),
                          ]),
                        ]
                      },
                      {
                        filteredOptions: _vm.filteredOptions,
                        selectOption: _vm.selectOption,
                        filterOption: _vm.filterOption,
                      }
                    ),
                  ]
                : [
                    _vm.optionsAreVisible &&
                    _vm.multiselect &&
                    _vm.filteredOptions.length > 1
                      ? _c(
                          "div",
                          { staticClass: "option-container" },
                          [
                            _vm._t("select_all", function () {
                              return [
                                _c(
                                  "option",
                                  {
                                    staticClass: "multi-select",
                                    class: { selected: _vm.allAreSelected() },
                                    on: {
                                      click: function ($event) {
                                        return _vm.selectAll()
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\r\n                            " +
                                        _vm._s(
                                          _vm.allAreSelected()
                                            ? "Unselect"
                                            : "Select"
                                        ) +
                                        " All\r\n                        "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c("input", {
                                  attrs: { type: "checkbox", id: "select_all" },
                                  domProps: { checked: _vm.allAreSelected() },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectAll()
                                    },
                                  },
                                }),
                              ]
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._l(_vm.filteredOptions, function (option, index) {
                      return _c(
                        "div",
                        { key: index, staticClass: "option-container" },
                        [
                          _vm._t(
                            "option",
                            function () {
                              return [
                                _c(
                                  "option",
                                  {
                                    key: index + "" + _vm.getValue(option),
                                    class: {
                                      selected: _vm.isSelected(option),
                                      "focus-class": _vm.focus === index,
                                      "multi-select": _vm.multiselect,
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.selectOption(
                                          _vm.getValue(option),
                                          _vm.getDisplay(option)
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._t(
                                      "option_text",
                                      function () {
                                        return [
                                          _vm._v(
                                            "\r\n                                " +
                                              _vm._s(_vm.getDisplay(option)) +
                                              "\r\n                            "
                                          ),
                                        ]
                                      },
                                      { data: _vm.getDataItem(option, index) }
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _vm.optionsAreVisible && _vm.multiselect
                                  ? [
                                      _c("input", {
                                        attrs: {
                                          type: "checkbox",
                                          id: `checkbox_${_vm.getValue(
                                            option
                                          )}`,
                                        },
                                        domProps: {
                                          checked: _vm.isSelected(option),
                                        },
                                        on: {
                                          click: function ($event) {
                                            _vm.selectOption(
                                              _vm.getValue(option),
                                              _vm.getDisplay(option)
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  : _vm._e(),
                              ]
                            },
                            {
                              data: _vm.getDataItem(option, index),
                              option: option,
                              filteredOptions: _vm.filteredOptions,
                              selectOption: _vm.selectOption,
                              filterOption: _vm.filterOption,
                            }
                          ),
                        ],
                        2
                      )
                    }),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _vm._t("dropdown-footer", null, {
            maintainOpenOptions: _vm.maintainOpenOptions,
          }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.hint
        ? _c("label", { staticClass: "secondary-text-color" }, [
            _vm._v(
              "\r\n        " +
                _vm._s(typeof _vm.hint == "function" ? _vm.hint() : _vm.hint) +
                "\r\n    "
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }