var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { staticClass: "processCard pl-2 pr-2 rounded", attrs: { "no-body": "" } },
    [
      _c(
        "b-row",
        [
          _c("b-col", [
            _c("span", [
              _vm._v(_vm._s(_vm.toLocalString(_vm.event.timestamp))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { cols: "8" } },
            [
              _c("b-card-text", [
                _vm.event.category === "handshake"
                  ? _c("span", [
                      _c("b", [_vm._v("DIGITAL HANDSHAKE®:")]),
                      _vm._v(" "),
                      _vm.event.subCategory === "request"
                        ? _c("span", [
                            _vm._v(
                              _vm._s(_vm.event.data.requestReason) +
                                " on " +
                                _vm._s(_vm.wellName)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isUserList()
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.capitalizeFirstLetter(_vm.event.subCategory)
                              ) +
                                " by " +
                                _vm._s(_vm.getLastUser().user) +
                                " (" +
                                _vm._s(_vm.getLastUser().role) +
                                ")"
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.event.subCategory === "request" &&
                      _vm.event.data.userSelection !== "None"
                        ? _c("span", [
                            _vm._v(
                              ", " +
                                _vm._s(_vm.event.data.userSelection) +
                                " (#" +
                                _vm._s(_vm.event.stageNumber) +
                                ")"
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.category === "valveCommand"
                  ? _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "nameplate",
                          style: {
                            background: _vm.wellColor,
                            color: _vm.nameColor(_vm.wellColor),
                          },
                        },
                        [_vm._v(_vm._s(_vm.wellName))]
                      ),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.event.data.valveName) +
                            " " +
                            _vm._s(
                              _vm.convertPastTense(
                                _vm.capitalizeFirstLetter(_vm.event.subCategory)
                              )
                            )
                        ),
                      ]),
                      _vm._v(" "),
                      _vm.event.data.position
                        ? _c("span", [
                            _vm._v(
                              " into " +
                                _vm._s(
                                  _vm.event.data.finalPosition ? "final" : ""
                                ) +
                                " position " +
                                _vm._s(_vm.event.data.position)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.event.data.issuedBy
                        ? _c("span", [
                            _vm._v(
                              " issued by " + _vm._s(_vm.event.data.issuedBy)
                            ),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.category === "wellActivity"
                  ? _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "nameplate",
                          style: {
                            background: _vm.wellColor,
                            color: _vm.nameColor(_vm.wellColor),
                          },
                        },
                        [_vm._v(_vm._s(_vm.wellName))]
                      ),
                      _vm._v(
                        " Status changed to: " +
                          _vm._s(_vm.event.data.statusString) +
                          ", Stage " +
                          _vm._s(_vm.event.stageNumber) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.category === "interlock"
                  ? _c("span", [
                      _c(
                        "span",
                        {
                          staticClass: "text-danger",
                          staticStyle: { "font-weight": "bold" },
                        },
                        [
                          _vm._v(
                            "INTERLOCK " +
                              _vm._s(_vm.event.subCategory.toUpperCase()) +
                              " ON " +
                              _vm._s(_vm.wellName.toUpperCase())
                          ),
                        ]
                      ),
                      _vm._v(
                        " " +
                          _vm._s(_vm.event.data.interlock_msg) +
                          "\n        "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.category === "processEvent"
                  ? _c("span", [
                      _vm._v(
                        " " +
                          _vm._s(_vm.getProcessEventMessage(_vm.event)) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.category === "inspectValves"
                  ? _c("span", [
                      _c("b", [
                        _vm._v("MISMATCH VALVE\n                "),
                        ["resolved", "ignored", "forceQuit"].includes(
                          _vm.event.subCategory
                        )
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.event.subCategory === "forceQuit"
                                      ? "force quit"
                                      : _vm.capitalizeFirstLetter(
                                          _vm.event.subCategory
                                        )
                                  )
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        ["ignored"].includes(
                          _vm.event.subCategory.toLowerCase()
                        )
                          ? _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.event.data.issuedBy
                                      ? " by " + _vm.event.data.issuedBy
                                      : ""
                                  ) +
                                  " "
                              ),
                            ])
                          : _vm._e(),
                        _vm._v(":\n            "),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(" " + _vm._s(_vm.event.data.valveName) + " "),
                      ]),
                      _vm._v(" "),
                      _vm.event.subCategory === "mismatched"
                        ? _c("span", [
                            _c("b", [_vm._v("HPS")]),
                            _vm._v(
                              " " + _vm._s(_vm.event.data.hpsPosition) + " "
                            ),
                            _c("b", [_vm._v("VPS")]),
                            _vm._v(" " + _vm._s(_vm.event.data.vpsPosition)),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.event.category === "emergencyBypass"
                  ? _c("span", [
                      _vm.event.subCategory === "request"
                        ? _c("span", [
                            _vm._v("Requested by "),
                            _c("b", [
                              _vm._v(
                                _vm._s(
                                  _vm.event.data.requestByWsmPin === true
                                    ? "WSM PIN"
                                    : ""
                                )
                              ),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(_vm.event.data.requesterUser) +
                                " " +
                                _vm._s(_vm.event.data.requesterRole)
                            ),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.event.subCategory === "bypassActuate"
                        ? _c("span", [
                            _c(
                              "span",
                              {
                                staticClass: "nameplate",
                                style: {
                                  background: _vm.wellColor,
                                  color: _vm.nameColor(_vm.wellColor),
                                },
                              },
                              [_vm._v(_vm._s(_vm.wellName))]
                            ),
                            _vm._v(" "),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.event.data.valveName) + " Actuated"
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.event.data.position
                              ? _c("span", [
                                  _vm._v(
                                    " into position " +
                                      _vm._s(_vm.event.data.position)
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.event.data.issuedBy
                              ? _c("span", [
                                  _vm._v(
                                    " issued by " +
                                      _vm._s(_vm.event.data.issuedBy)
                                  ),
                                ])
                              : _vm._e(),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.event.subCategory === "completed"
                        ? _c("span", [
                            _vm.event.data.cancelledBy
                              ? _c("span", [
                                  _vm._v(
                                    "Cancelled by " +
                                      _vm._s(_vm.event.data.cancelledBy) +
                                      " " +
                                      _vm._s(_vm.event.data.cancelledByRole)
                                  ),
                                ])
                              : _c("span", [_vm._v("Completed as Timeout")]),
                          ])
                        : _vm._e(),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", {
                staticClass:
                  "col-4 mt-0 pr-0 pl-0 pt-0 d-flex justify-content-center",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            [
              ["processEvent", "emergencyBypass"].includes(
                _vm.event.category
              ) &&
              ["completed", "started", "continued"].includes(
                _vm.event.subCategory
              )
                ? _c("div", { staticClass: "img-box" }, [
                    _c("i", { staticClass: "fa fa-check fa-2x ml-1" }),
                  ])
                : _vm.event.category === "processEvent" &&
                  _vm.event.subCategory !== "skipStep"
                ? _c("div", { staticClass: "img-box" }, [
                    _c("i", { staticClass: "fa fa-times fa-2x ml-1" }),
                  ])
                : _vm.event.category === "handshake" &&
                  _vm.event.subCategory === "completed"
                ? _c("div", { staticClass: "img-box" }, [
                    _c("i", { staticClass: "far fa-handshake fa-2x" }),
                  ])
                : "duration" in _vm.event &&
                  _vm.event.category !== "inspectValves"
                ? _c(
                    "b-card-text",
                    { staticStyle: { "{font-weight": "bold" } },
                    [
                      _vm._v(
                        " Step Duration: " + _vm._s(_vm.event.duration) + " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }