var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "supportModal",
      staticClass: "modal fade",
      staticStyle: {
        "z-index": "1200",
        position: "absolute",
        top: "0px",
        left: "0px",
      },
      attrs: { id: "supportModal", tabindex: "-1", role: "dialog" },
    },
    [_vm._m(0)]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "modal-dialog", attrs: { role: "document" } },
      [
        _c(
          "div",
          {
            staticClass:
              "modal-content main-container modal-bg text-center text-wrap py-2",
          },
          [
            _c("div", { staticClass: "ml-3 mr-2 text-white text-dark" }, [
              _c("img", {
                staticClass: "support-logo",
                attrs: {
                  src: "/images/inVision_support.png",
                  alt: "inVision support logo",
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mb-0 header text-white text-dark" }, [
              _c("p", [_vm._v("Need Support? Report a Bug?")]),
              _vm._v(" "),
              _c("p", [_vm._v("Feature Request or General Inquiry?")]),
              _vm._v(" "),
              _c("hr", { staticClass: "mt-1" }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "content text-white text-dark" }, [
              _c("p", [
                _vm._v("Contact the IWS 24/7 Remote Operations Center:"),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticStyle: { color: "inherit" },
                  attrs: { href: "tel:1-866-229-0999" },
                },
                [_vm._v("1-866-229-0999")]
              ),
              _c("br"),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticStyle: { color: "inherit" },
                  attrs: { href: "mailto:support@iwscorp.com" },
                },
                [_vm._v("support@iwscorp.com")]
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }