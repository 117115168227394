<template>
    <b-modal 
        v-model="isVisible"
        ref="error-modal" 
        @hidden="$emit('onDismissSignalRErrorModal')"
        hide-footer
        no-close-on-backdrop
        size="xl"
        header-class="border-bottom-0 justify-content-center  p-0"
        body-class="pb-0"
        footer-class="border-top-0 pt-0"
        content-class="border-3 modal-bg">
        <template #modal-header>
            <div class="col w-100">
                <div class="modal-header">
                    <div class="font-large w-100 text-center"> Your connection has timed out. </div>
                </div>
            </div>
        </template>
        <template #default>
            <div class="d-flex align-items-center pt-2 pb-4">
                <b-spinner variant="primary" label="Spinning"></b-spinner>
                <div class="px-2">Please wait - Attempting to reestablish connection</div>
            </div>
        </template>
    </b-modal>
</template>
<style scoped>
    .form-control:disabled {
        background-color: lightgray;
    }
 	* /deep/ .border-3 {
		border: 3px solid #dc3545 !important;
	}
</style>
<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';

export default {
    data() {
        return {
            isVisible: false,
            tabFocus: null
        };
    },
    props: {
        modalVisible: {
            type: Boolean,
            default: false
        },
        resolutionPackage: {
            type: [Array, Object, Function],
            default: null
        }
    },
    watch: {
        modalVisible: function(newValue) {
            if(this.tabFocus) {
                this.isVisible = newValue;
            }
        },
        tabFocus: function(newValue, oldValue) { 
            if(oldValue == false && newValue == true) {
                this.isVisible = this.modalVisible;
            }
        }
    },
    methods: {
        detectFocusOut() {
            let inView = false;

            const onWindowFocusChange = (e) => {
                if ({ focus: 1, pageshow: 1 }[e.type]) {
                    if (inView) return;
                    this.tabFocus = true;
                    inView = true;
                } else if (inView) {
                    this.tabFocus = !this.tabFocus;
                    inView = false;
                }
            };

            window.addEventListener('focus', onWindowFocusChange);
            window.addEventListener('blur', onWindowFocusChange);
            window.addEventListener('pageshow', onWindowFocusChange);
            window.addEventListener('pagehide', onWindowFocusChange);
        }
    },
    mounted () {
        this.detectFocusOut();
    },
};
</script>
