<template>
    <!-- TO DO : Explain how this component is used : What its inputs and outputs are for-->
    <!-- Link to vue-multiselect : https://github.com/shentao/vue-multiselect -->
    <div>
        <multiselect v-if="!hasSubLabels"
            v-model="selected"
            :options="getOptions"
            :multiple="multiSelect"
            :close-on-select="false"
            :show-labels="false"
            :append-to-body="true"
            :limit-text="limitText"
            :max-height="maxHeight"
            :disabled="disabled"
            :allowEmpty="allowEmpty"
            :placeholder="placeholderText"
            :label="label"
            :track-by="trackByVal"
            @input="onUpdate">
            <template slot="selection" slot-scope="{ values, search, isOpen }">
                <span class="multiselect__single d-inline-block text-truncate" v-if="values.length && !isOpen">
                    {{selectedItemsTextDisplay}}
                </span>
            </template>
            <template slot="tag" slot-scope="props"><span></span></template>
        </multiselect>
        <multiselect v-else
            v-model="selected"
            :options="options"
            :multiple="multiSelect"
            :close-on-select="false"
            :show-labels="false"
            group-values="valueArray"
            group-label="subCatLabel"
            :limit-text="limitText"
            :max-height="maxHeight"
            :disabled="disabled"
            :allowEmpty="allowEmpty"
            :placeholder="placeholderText"
            :label="label"
            :track-by="trackByVal"
            @input="onUpdate">
                <template slot="selection" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length && !isOpen">{{selectedItemsTextDisplay}}</span></template>
                <template slot="tag" slot-scope="props"><span></span></template>
        </multiselect>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
    components: { Multiselect },
    data() {
        return {
            selected: null,
            optionsArray: []
        };
    },
    mounted() {
        this.optionsArray = _.cloneDeep(this.options);
        if (this.value && this.value.length > 0) {
            if (this.selectByKey) {
                this.selected = [];
                this.value.forEach(value => {
                    this.selected.push(this.getSelectedOptionbyKeyValue(value));
                });
            }
            else
            {
                this.selected = this.value;
            }
        }
        else {
            this.selected = null;
        }
    },
    watch: {
        value: {
            handler: function (newVal, oldVal) { 
                if (!this.selectByKey && newVal ) {
                    this.selected = newVal;
                }
            }
        },
        sortByKey: { //change options array if sort key changes
            handler: function (newVal, oldVal) {
                this.sortOptions(this.sortByKey, this.optionsArray, this.isReverseSort);
            }
        },
        isReverseSort: {//change options array is order is reversed
            handler: function (newVal, oldVal) {
                this.sortOptions(this.sortByKey, this.optionsArray, this.isReverseSort);
            }
        },
        options: {
            handler: function(newval, oldval) {
                this.optionsArray = _.cloneDeep(this.options);
            }
        }
    },
    props: {
        trackByVal: {
            type: String,
            default: 'id'
        },
        value: {
            type: [Array, String, Object],
            default: ()=>[]
        },
        options: {
            type: [Array, String],
            default: ()=>[]
        },
        selectByKey: {
            type: [String, Number],
            default: null,
        },
        hasSubLabels: {
            type: Boolean,
            default: false
        },
        identifyingName: {
            type: String,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        multiSelect: {
            type: Boolean,
            default: true
        },
        allowEmpty: {
            type: Boolean,
            default: true
        },
        selectAllDuplicates: {
            type: Boolean,
            default: false
        },
        removeDuplicatesKey: {
            type: String,
            default: null
        },
        uniqueId: {
            type: String,
            default: null
        },
        label: {
            type: String,
            default: "name"
        },
        maxHeight: {
            type: Number,
            default: 600
        },
        placeholderText: {
            type: String,
            default: 'Pick Some'
        },
        showSelectedOptions: {
            type: [Boolean,Number],
            default: false
        },
        sortByKey: {
            type: String
        },
        isReverseSort: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        sortOptions(sortKey, arrayToSort, isReverseSort) {
            arrayToSort.sort(
                function(a, b) {
                    if (!isReverseSort) {
                        if (a[sortKey].toLowerCase() < b[sortKey].toLowerCase()) {
                            return -1;
                        }
                        if (a[sortKey].toLowerCase() > b[sortKey].toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    } else {
                        if (a[sortKey].toLowerCase() > b[sortKey].toLowerCase()) {
                            return -1;
                        }
                        if (a[sortKey].toLowerCase() < b[sortKey].toLowerCase()) {
                            return 1;
                        }
                        return 0;
                    }
                }
            );
        },
        getSelectedOptionbyKeyValue(value) {
            return this.optionsArray.find(option => option[this.selectByKey] === value);
        },
        limitText (count) {
            return `${count} element(s) selected`;
        },
        onUpdate (newValue) {
            if(this.selectAllDuplicates && this.removeDuplicatesKey) {
                newValue.forEach(value => {
                    const selectedValue = value[this.removeDuplicatesKey];
                    const selectedArray = this.optionsArray.filter((option)=> option[this.removeDuplicatesKey] == selectedValue);
                    const selectedValues = [...newValue, ...selectedArray].filter((value, index, self) =>
                        index === self.findIndex((t) => (
                            t[this.uniqueId] === value[this.uniqueId]
                        ))
                    );;
                    this.selected = selectedValues;
                    this.$emit('selectedupdated', this.selected);
                });
            } else {
                this.selected = newValue;
                this.$emit('selectedupdated', newValue);
            }
            this.$emit('selectedupdated', newValue);
        },
        numberOfItemsSelected(selectedItems) {
            if(this.selectAllDuplicates && this.removeDuplicatesKey) {
                const filteredSelectedItems = selectedItems.filter((value, index, self) => {
                    return index === self.findIndex((t) => (
                        t[this.removeDuplicatesKey] === value[this.removeDuplicatesKey]
                    ));
                });
                return filteredSelectedItems.length;
            } else {
                return selectedItems.length;
            }
        }
    },
    computed: {
        selectedStringify() {
            return JSON.stringify(this.selected);
        },
        getOptions() {
            if(this.selectAllDuplicates && this.removeDuplicatesKey) {
                return this.optionsArray.filter((value, index, self) => {
                    return index === self.findIndex((t) => (
                        t[this.removeDuplicatesKey] === value[this.removeDuplicatesKey]
                    ));
                });
            }
            return this.optionsArray;
        },
        selectedItemsTextDisplay() {
            if (this.showSelectedOptions) {
                return this.selected.map(option => option[this.label]).join(', ');
            } else {
                if (!this.hasSubLabels) {
                    return `${this.numberOfItemsSelected(this.value)} item(s) selected`;
                } else {
                    return `${this.value.length } item(s) selected`;
                } 
            }
        }
    }
};
</script>

<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
    .multiselect__single {
  white-space: nowrap;
  overflow: hidden; 
}

.multiselect {
  border-width: 0px;
}

.multiselect__content-wrapper {
  min-width: fit-content; 
}

 .multiselect__option {
  width: 100%; 
}

.dropdown-btn.disabled {
    border-color: #676E78 !important;
    color: #676E78 !important;
    background: #343A40 !important;
    pointer-events: all !important;
    cursor: not-allowed !important;
}
</style>
