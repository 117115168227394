<template>
    <div id="HandshakeModal" class="modal" tabindex="-1" role="dialog" data-keyboard="false" data-backdrop="static">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header d-flex flex-column p-1">
                    <div class="d-flex">
                        <img class="px-2" v-bind:src="image"/>
                        <h4 class="m-1" v-if="state == 'inProgress' || state=='rejected'"><span class="p-1" :style="{'background-color':wellColor, 'color':getTitleColorForBG(wellColor)}">{{statusMessage}}</span></h4>
                    </div>
                    <div class="alert alert-warning m-0" v-if="state=='rejected'" style="position:absolute;top:5px;right:5px;">
                      {{rejectMessage}}
                      <button type="button" class="close" data-dismiss="modal" aria-label="Close" >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                </div>
                <div class="modal-body p-1" :style="{'color':'#ffffff'}">
                    <div class="row m-1" v-if="jobStatus != 'inProgress'">
                      <div class="alert alert-danger m-0">
                        JOB HAS NOT BEEN STARTED
                      </div>
                    </div>
                    <div class="row ml-1 mr-1">
                        <h5 class="col">
                          <span class="p-1 text-center" :style="{'background-color':wsmColor,'display':'block','height':'100%'}">
                            <template v-if="wsmApproved">
                              COMPANY REP APPROVED
                            </template>
                            <template v-else>
                              COMPANY REP PENDING
                            </template>
                          </span>
                        </h5>
                        <h5 class="col">
                          <span class="p-1 text-center" :style="{'background-color':fracColor,'display':'block','height':'100%'}">
                            <template v-if="fracApproved">
                              FRAC APPROVED
                            </template>
                            <template v-else>
                              FRAC PENDING
                            </template>
                          </span>
                        </h5>
                    </div>
                    <div class="row ml-1 mr-1">
                        <h5 class="col">
                          <span class="p-1 text-center" :style="{'background-color':wirelineColor,'display':'block','height':'100%'}">
                            <template v-if="wirelineApproved">
                              WIRELINE APPROVED
                            </template>
                            <template v-else>
                              WIRELINE PENDING
                            </template>
                          </span>
                        </h5>
                        <h5 class="col">
                          <span class="p-1 text-center" :style="{'background-color':techColor,'display':'block','height':'100%'}">
                            <template v-if="techApproved">
                              TECH APPROVED
                            </template>
                            <template v-else>
                              TECH PENDING
                            </template>
                          </span>
                        </h5>
                    </div>
                    
                    <div class="row p-2 mt-0 mb-0">
                      
                        <div class="col text-center text-dark">
                          <template v-if="reason != 'placeMaintenance' && reason != 'manualSelectUnlock'">
                            <img class="img-fluid" :src="wellImage" style="height:180px;" :style="{'background-color':wellColor}"/>
                            <br>
                            <h5><span class="badge" :style="{'background-color':wellColor, 'color':getTitleColorForBG(wellColor)}">{{wellName}}</span></h5>
                          </template>
                          <template v-else>
                            <valve-select-component :well="wells[wellNumber]" :readOnly="true" 
                              v-if="wells[wellNumber].valves.length>1" :selectedValves="selectValveUnlock"></valve-select-component>
                          </template>
                        </div>
                        <div class="col">
                            <enter-pin-component ref="pinInput"></enter-pin-component>
                        </div>
                      
                    </div>
                    <div class="row d-flex p-2 justify-content-between align-items-baseline" :style="{'color':'#000000'}">
                      <input type="text" v-if="showRejectReason" class="form-control ml-1 mr-2" v-model="rejectReason" 
                          id="rejectReason" placeholder="Reject reason..." @click="input($event)">
                        <h5 class="ml-auto m-2">
                          Please enter your PIN 
                          <span v-if="reason=='placeFrac' || reason=='placeMaintenance' || reason == 'placeWireline'"> and select the well below</span>
                          <div class="alert alert-warning" v-if="showRejectReason && rejectReason==''">Must enter a reject reason</div>
                        </h5>
                        <button class="btn btn-secondary m-2 btn-lg" v-if="canMinimize" @click="minimize()">Hide</button>
                        <button class="btn btn-secondary m-2 btn-lg" @click="response('reject')">Reject</button>
                        <button class="btn btn-primary m-2 btn-lg" v-if="!(reason=='placeFrac' || reason=='placeMaintenance' || reason == 'placeWireline')"
                        @click="response('accept')">
                          Accept
                        </button>
                        
                    </div>
                    <keyboard-component v-on:key="keyPress" v-if="showKeyboard" class="fixed-bottom" style="z-index:1040;"></keyboard-component>
                </div>
                <div class="modal-footer d-flex justify-content-center" v-if="reason=='placeFrac' || reason=='placeMaintenance' || reason == 'placeWireline'">
                    <div class="d-flex justify-content-center">
                        <div class="col text-center" v-for="(well, index) in wells" :key="well.index" v-on:click="checkAccept(well, index)" style="cursor:pointer;">
                            <img class="img-fluid" :src="wellImage" style="height:80px;" :style="{'background-color':well.color}"/>
                            <br>
                            <h5><span class="badge" :style="{'background-color':well.color, 'color':getTitleColorForBG(well.color)}">{{well.name}}</span></h5>
                        </div>
                    </div>
                    <!-- <button class="btn btn-secondary mr-auto" v-if="canMinimize" @click="minimize()">Hide</button>
                    <button class="btn btn-secondary" @click="response('reject')">Reject</button>
                    <button class="btn btn-primary" @click="response('accept')">Accept</button> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import GlobalFunctions from '../GlobalFunctions.js'

    export default {
        data() {
            return {
                name: "my handshake",
                type: "handshake",
                reason: "",
                statusMessage: "",
                wellColor: "#000000",
                wellName: "",
                wellNumber: -1,
                state: "completed", //possible states: completed, inProgress, rejected
                wsmApproved: false,
                wirelineApproved: false,
                techApproved: false,
                fracApproved: false,
                headerColor:"#ff0000",
                wsmColor: "#ff0000",
                wirelineColor: "#ff0000",
                techColor: "#ff0000",
                fracColor: "#ff0000",
                image: '../images/activityNone.png',
                showRejectReason:false,
                rejectReason:'',
                showKeyboard:false, //boolean to show/hide keyboard
                activeInput:'', //string containing the id of the active input element
                closeTimeout:{},
                rejectMessage:'not set'
            }
        },
        props: {
            canMinimize: {
                default: false,
                type: Boolean
            },
            wells: {
                default: [],
                type: Array
            },
            selectValveUnlock:{
              default:[],
              type: Array
            }
        },
        computed:{
          wellImage(){
            if(this.$root.darkMode)
              return '../images/WellheadTemplate.png';
            else
              return '../images/WellheadTemplate_dark.png';
          },
          jobStatus(){
            return this.$root.jobStatus.status;
          }
        },
        mounted() {
            // console.log('Handshake Component mounted.')
          var self = this;
          this.$root.$on('mqttConnected',function(){
            //console.log("messagesToolbar component got a connect message!");
            self.mqttInit();
          }); 
            
        },
        methods: {
            checkAccept: function(well, index) {
                //console.log("Handshake: check accept running...");
                var prevColor = well.color;
                var newColor = "#FF0000";
                if(index == this.wellNumber) {
                    newColor = '#00FF00';
                    if(this.showRejectReason)
                      this.response('reject');
                    else
                      this.response('accept');
                }

                well.color = newColor;
                setTimeout(function(){
                    well.color = prevColor;
                }, 250);

            },
            response: function(type){
              var self = this;
                //console.log("Handshake response type: "+type);
                var pin = this.$refs.pinInput.pin;
                
                if(type=='accept'){
                    this.$refs.pinInput.clearPin();
                    $.get(
                        '../handshakes/action',
                        {
                            action: 'approve',
                            pin: pin
                        },
                        function(data){
                            if(data.error){
                              alert(data.message);
                            }else{
                              
                              try{
                                if(data.hasOwnProperty('mqttStatus'))
                                  self.$root.$mqtt.publish("handshake/status",data.mqttStatus,{qos:1,retain:true});
                              }catch{
                                //console.log("didn't find expected mqttStatus in message");
                              }
                              try{
                                if(data.hasOwnProperty('processSteps')){
                                  //console.log("received process steps: "+JSON.stringify(data.processSteps));
                                  if(data.processSteps.steps.length > 0){
                                    console.log(`will publish process/steps/${self.wellNumber}: ${JSON.stringify(data.processSteps)}`)
                                    self.$root.$mqtt.publish(`process/steps/${self.wellNumber}`,JSON.stringify(data.processSteps),{qos:1,retain:true});
                                    
                                  }
                                }
                              }catch{
                                // do nothing
                              }
                              if(data.handshake.status == 'completed'){
                                
                                if(self.reason == 'placeMaintenance' || self.reason == 'manualSelectUnlock' && self.selectValveUnlock.length>0){
                                  // going to make up and send out a process steps message
                                  console.log("manual valve select handshake completed...should send out process steps")
                                  var step = {
                                    step_number:0,
                                    autoUnlock:true
                                  }
                                  var valves = []
                                  for(var j=0;j<self.selectValveUnlock.length;j++){
                                    var valve = {
                                      name:self.selectValveUnlock[j].name,
                                      activity:self.selectValveUnlock[j].activity,
                                      index:self.selectValveUnlock[j].index,
                                      finalPosition:'none'
                                    }
                                    valves.push(valve)
                                  }
                                  step.valves = valves
                                  var payload = {
                                    steps:[step],
                                    nextActivity:self.reason
                                  }
                                  if(self.reason=='placeMaintenance')
                                    payload.nextActivity = 'maintenance'
                                  self.$root.$mqtt.publish(`process/steps/${self.wellNumber}`,JSON.stringify(payload),{qos:1,retain:true});
                                  console.log(`will publish process/steps/${self.wellNumber}: ${JSON.stringify(payload)}`)
                                }else{
                                  for(var i=0;i<data.mqttMessages.length;i++){
                                    try{
                                      //console.log("going to publish topic: "+data.mqttMessages[i].topic+", payload: "+data.mqttMessages[i].payload);
                                      self.$root.$mqtt.publish(data.mqttMessages[i].topic,data.mqttMessages[i].payload.toString(),{qos:1,retain:true});
                                    }catch{
                                      //console.log("couldn't publish well status messages");
                                    }
                                    
                                  }
                                }
                              }
                            }
                            
                        },
                        'json'

                    );
                }
                if(type=='reject'){
                  self.showRejectReason = true;
                  self.showKeyboard = false;
                  if(pin!='' && self.rejectReason != ''){
                    this.$refs.pinInput.clearPin();
                    $.get(
                        '../handshakes/action',
                        {
                            action: 'reject',
                            reason: self.rejectReason,
                            pin: pin
                        },
                        function(data){
                            if(data.error){
                              alert(data.message);
                            }else{
                              
                              try{
                                if(data.hasOwnProperty('mqttStatus'))
                                  self.$root.$mqtt.publish("handshake/status",data.mqttStatus,{qos:1,retain:true});
                              }catch{
                                //console.log("didn't find expected mqttStatus in message");
                              }

                              if(data.handshake.status == 'completed'){
                                for(var i=0;i<data.mqttMessages.length;i++){
                                  try{
                                    //console.log("going to publish topic: "+data.mqttMessages[i].topic+", payload: "+data.mqttMessages[i].payload);
                                    self.$root.$mqtt.publish(data.mqttMessages[i].topic,data.mqttMessages[i].payload.toString(),{qos:1,retain:true});
                                  }catch{
                                    //console.log("couldn't publish well status messages");
                                  }
                                  
                                }
                              }
                            }
                            
                        },
                        'json'

                    );
                  }
                  
                }
            },
            minimize: function() {
                $('#HandshakeModal').modal('hide');
            },
            getTitleColorForBG: function(bgColor) {
                return GlobalFunctions.getTitleColorForBG(bgColor);
            },
            // handle key press events
          keyPress(val){
            var self = this;
            switch(val){
              case 'Back':
                self[self.activeInput] = self[self.activeInput].substring(0,self[self.activeInput].length-1);
                break;
              case 'Enter':
                this.showKeyboard = false;
                var event = new Event('change');
                document.getElementById(self.activeInput).dispatchEvent(event);
                break;
              default:
                self[self.activeInput] = self[self.activeInput]+val;
                break;
            }  
          },
          // handle click of input element
          input(event){
            this.activeInput = event.currentTarget.id;
            this.showKeyboard = true;
          },
          mqttInit(){
            this.$root.$mqtt.subscribe('handshake/#')

            this.$root.$mqtt.on("message", function(topic, message) {
                //console.log("handshake: onMessage: " + topic + " " + message);
                var topicArray = topic.split("/");
                //console.log("topicArray: "+JSON.stringify(topicArray)+", type: "+this.type);
                if(topicArray.length > 0) {
                    switch (topicArray[0]) {
                        case this.type:
                            //console.log("component "+this.type+" has received a message. Topic: "+topic+", payload: "+message);
                            switch(topicArray[1]){
                                case "status":
                                    var temp = JSON.parse(message);
                                    var colorGreen = '#64de6e';
                                    var colorRed = '#ff0000';
                                    var colorOrange = '#ebb217';
                                    this.reason = temp.reason;
                                    this.wellColor = temp.wellColor;
                                    this.wellName = temp.wellName;
                                    this.wellNumber = temp.wellNumber;
                                    this.statusMessage = temp.statusMessage;
                                    this.state = temp.state;
                                    var bgColor = colorRed;
                                    switch(this.state){
                                        case "completed":
                                            bgColor = colorGreen; 
                                            $('#HandshakeModal').modal('hide');
                                            clearTimeout(this.closeTimeout);
                                            break;
                                        case "rejected":
                                            bgColor = colorOrange;
                                            this.rejectReason= '';
                                            this.showRejectReason=false;
                                            this.rejectMessage = "Rejected by: "+temp.rejectUser+", Reason: "+temp.rejectReason;
                                            this.closeTimeout = setTimeout(function(){$('#HandshakeModal').modal('hide');},5000);
                                            //$('#HandshakeModal').modal('hide');
                                            break;
                                        default: // must be in progress...
                                            $('#HandshakeModal').modal();
                                            clearTimeout(this.closeTimeout);
                                            break;
                                    }
                                    //console.log("bgColor: "+bgColor);
                                    this.headerColor = bgColor;
                                    //console.log("set header color to "+bgColor);
                                    bgColor = colorRed;
                                    if(temp.wsmApproval){
                                        bgColor = colorGreen;
                                        this.wsmApproved = true;
                                    }
                                    this.wsmColor = bgColor;
                                    bgColor = colorRed;
                                    if(temp.fracApproval){
                                        bgColor = colorGreen;
                                        this.fracApproved = true;
                                    }
                                    this.fracColor = bgColor;
                                    bgColor = colorRed;
                                    if(temp.wirelineApproval){
                                        bgColor = colorGreen;
                                        this.wirelineApproved = true;
                                    }
                                    this.wirelineColor = bgColor;
                                    bgColor = colorRed;
                                    if(temp.techApproval){
                                        bgColor = colorGreen;
                                        this.techApproved = true;
                                    }
                                    this.techColor = bgColor;

                                    if(this.reason == 'placeWireline' || this.reason == 'removeWireline')
                                        this.image = '../images/activityWireline.png';
                                    else if (this.reason == 'placeFrac' || this.reason == 'removeFrac')
                                        this.image = '../images/activityFrac.png';
                                    else if (this.reason == 'placeMaintenance')
                                        this.image = '../images/activityMaintenance.png';
                                    else
                                        this.image = '../images/activityNone.png';
                                    break;
                                default:
                                    break;
                            }
                            break;
                        default:
                            break;
                    }
                }
            }.bind(this));
          }
        }
    }
</script>