var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.nav
    ? _c("div", [
        _c("li", { ref: "latencyNavRef", staticClass: "nav-item dropdown" }, [
          _c("div", { staticClass: "latency-nav-icon" }, [
            _c("i", {
              staticClass: "fas fa-wifi icon",
              class: _vm.latencyNavFormat,
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dropdown-menu bg-light mt-2 px-2 pt-2 pb-1",
              class: _vm.isViewOnlyRole
                ? "dropdown-menu-right"
                : "dropdown-menu-left",
              attrs: { id: "latency-dropdown" },
            },
            [
              [
                _c(
                  "li",
                  {
                    staticClass:
                      "nav-item latency-nav-item text-nowrap border rounded p-1",
                    class: _vm.latestPayloadFormat,
                  },
                  [
                    _c("span", { staticClass: "px-2" }, [
                      _vm._v("Latest Payload"),
                    ]),
                    _vm._v(" "),
                    _vm.latestPayload < 60000
                      ? _c("span", { staticClass: "px-2" }, [
                          _vm._v(_vm._s(_vm.latestPayload) + " ms"),
                        ])
                      : _c("span", { staticClass: "px-2" }, [_vm._v(">1 min")]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "li",
                  {
                    staticClass: "nav-item latency-nav-item text-nowrap p-1",
                    class: _vm.latencyNavFormat,
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "px-2", class: _vm.latencyNavFormat },
                      [_vm._v("Average Latency")]
                    ),
                    _vm._v(" "),
                    _vm.overallAverageLatency < 60000
                      ? _c(
                          "span",
                          { staticClass: "px-2", class: _vm.latencyNavFormat },
                          [
                            _vm._v(
                              _vm._s(
                                (_vm.overallAverageLatency / 1000).toFixed(1)
                              ) + " s"
                            ),
                          ]
                        )
                      : _c(
                          "span",
                          { staticClass: "px-2", class: _vm.latencyNavFormat },
                          [_vm._v("-")]
                        ),
                  ]
                ),
              ],
            ],
            2
          ),
        ]),
      ])
    : _c(
        "div",
        { ref: "infoBox", staticClass: "border border-dark rounded p-2" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "border rounded p-2 mb-1",
                  class: _vm.latestPayloadFormat,
                },
                [
                  _c("div", { staticStyle: { float: "left" } }, [
                    _vm._v("Latest Payload"),
                  ]),
                  _vm._v(" "),
                  _vm.latestPayload < 60000
                    ? _c("div", { staticStyle: { float: "right" } }, [
                        _vm._v(_vm._s(_vm.latestPayload) + " ms"),
                      ])
                    : _c("div", { staticStyle: { float: "right" } }, [
                        _vm._v("> 1 min"),
                      ]),
                  _vm._v(" "),
                  _c("br"),
                ]
              ),
              _vm._v(" "),
              _vm._l(_vm.latencyTagDisplay, function (tag, index) {
                return _c("div", [
                  !!index ? _c("hr", { staticClass: "hr m-0" }) : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "px-2 py-1 mb-1", class: tag.format },
                    [
                      _c("div", { staticStyle: { float: "left" } }, [
                        _vm._v(_vm._s(tag.name)),
                      ]),
                      _vm._v(" "),
                      tag.averageLatency < 60000
                        ? _c("div", { staticStyle: { float: "right" } }, [
                            _vm._v(
                              _vm._s((tag.averageLatency / 1000).toFixed(1)) +
                                " s"
                            ),
                          ])
                        : _c("div", { staticStyle: { float: "right" } }, [
                            _vm._v("-"),
                          ]),
                      _vm._v(" "),
                      _c("br"),
                    ]
                  ),
                ])
              }),
            ],
            2
          ),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticStyle: { float: "left" } }, [
        _c("b", [_vm._v("Latency")]),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("hr", { staticClass: "hr my-2" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }