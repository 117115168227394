var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { class: { activeSpeaking: _vm.roipActivelySpeaking } }, [
    _vm.roipActivelySpeaking
      ? _c("div", { staticClass: "roipBanner" }, [
          _vm._v(
            "\n       " +
              _vm._s(_vm.roipPad) +
              " : " +
              _vm._s(
                _vm.roipActivelySpeakingUsers.join(", ") + " currently speaking"
              ) +
              "\n    "
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      [
        _c(
          "div",
          [
            _c("div", { attrs: { id: "transparent-popover-container" } }),
            _vm._v(" "),
            _vm.dashboards.length == 0
              ? _c(
                  "div",
                  [
                    _c("center", { staticClass: "mt-5 h1" }, [
                      _vm._v(
                        "\n                    No dashboards currently exist,\n                    "
                      ),
                      _c("b", [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link text-white",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                return _vm.createNewDashboard()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                            click here to create one.\n                        "
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ],
                  1
                )
              : _vm.noDefaultDashboardError
              ? _c(
                  "div",
                  { staticClass: "col-12 align-content-end px-4 py-2" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "flex-grow-1 p-1 rounded pl-3",
                        staticStyle: {
                          "font-weight": "bold",
                          "vertical-align": "bottom",
                          color: "red",
                          border: "1px solid red",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    A customer default dashboard has not been created yet, please contact an administrator for assistance.\n                "
                        ),
                      ]
                    ),
                  ]
                )
              : _vm.isDashboardLoading
              ? _c(
                  "div",
                  {
                    staticClass: "spinner-border m-5",
                    attrs: { role: "status" },
                  },
                  [
                    _c("span", { staticClass: "sr-only" }, [
                      _vm._v("Loading..."),
                    ]),
                  ]
                )
              : _c(
                  "div",
                  [
                    _c("div", { staticClass: "d-flex flex-row" }),
                    _vm._v(" "),
                    _c("notifications", {
                      attrs: { group: "notifications", position: "top center" },
                    }),
                    _vm._v(" "),
                    _vm.selectedDashboard.layout.length != 0
                      ? _c(
                          "grid-layout",
                          {
                            attrs: {
                              layout: _vm.selectedDashboard.layout,
                              "is-draggable": _vm.editMode,
                              "is-resizable": _vm.editMode,
                              responsive: _vm.isResponsive,
                              "col-num": 12,
                              "row-height": 30,
                              "is-mirrored": false,
                              "vertical-compact": true,
                              margin: [5, 5],
                              "use-css-transforms": true,
                            },
                            on: {
                              "update:layout": function ($event) {
                                return _vm.$set(
                                  _vm.selectedDashboard,
                                  "layout",
                                  $event
                                )
                              },
                              "layout-updated": _vm.layoutUpdatedEvent,
                            },
                          },
                          _vm._l(
                            _vm.selectedDashboard.layout,
                            function (item, index) {
                              return _c(
                                "div",
                                { key: item.i },
                                [
                                  _c(
                                    "grid-item",
                                    {
                                      attrs: {
                                        x: item.x,
                                        y: item.y,
                                        w: item.w,
                                        h: item.h,
                                        i: item.i,
                                        minW: _vm.getDimensionsForItem(item)
                                          ?.minW,
                                        minH: _vm.getDimensionsForItem(item)
                                          ?.minH,
                                        maxH: _vm.getDimensionsForItem(item)
                                          ?.maxH,
                                        maxW: _vm.getDimensionsForItem(item)
                                          ?.maxW,
                                        dragAllowFrom: ".item_title",
                                      },
                                      on: {
                                        resize: _vm.handleResizeEvent,
                                        resized: _vm.handleResizedEvent,
                                      },
                                    },
                                    [
                                      _vm.editMode
                                        ? _c(
                                            "div",
                                            { staticClass: "item_title" },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "pl-2",
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                },
                                                [
                                                  _c("b-form-select", {
                                                    staticClass: "select_form",
                                                    attrs: {
                                                      options:
                                                        _vm.availableDisplayItems,
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        _vm.handleComponentTypeChanged(
                                                          _vm.getDashboardItemFromLayoutItem(
                                                            item
                                                          ),
                                                          index
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.getDashboardItemFromLayoutItem(
                                                          item
                                                        ).type,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.getDashboardItemFromLayoutItem(
                                                            item
                                                          ),
                                                          "type",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "getDashboardItemFromLayoutItem(item).type",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "closer",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.removeDashboardItem(
                                                        item,
                                                        index
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("X")]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c("display-item-component", {
                                        ref: "grid-item-" + item.i,
                                        refInFor: true,
                                        attrs: {
                                          editMode: _vm.editMode,
                                          item: _vm.getDashboardItemFromLayoutItem(
                                            item
                                          ),
                                          componentExists:
                                            _vm.checkComponentExists(
                                              _vm.getDashboardItemFromLayoutItem(
                                                item
                                              )
                                            ),
                                          iwsUser: !!_vm.iwsUser,
                                          defaultChartColumnCount:
                                            _vm.defaultChartColumnCount,
                                          layout:
                                            _vm.selectedDashboard.layout[index],
                                          componentSpecificPermissions:
                                            _vm.componentSpecificPermissions,
                                          friendlyName:
                                            _vm.availableDisplayItems.find(
                                              (target) =>
                                                target.value ==
                                                _vm.getDashboardItemFromLayoutItem(
                                                  item
                                                ).type
                                            )?.friendlyName,
                                          wells: _vm.wells,
                                          utcDifference: _vm.utcDifference,
                                          latencyComponentTags:
                                            _vm.latencyComponentTags,
                                          items: _vm.selectedDashboard.items,
                                          latestDataCollection:
                                            _vm.latestDataCollection,
                                          latencySaving: _vm.latencySaving,
                                          latencySaved: _vm.latencySaved,
                                        },
                                        on: {
                                          pushToActiveTags:
                                            _vm.pushToActiveTags,
                                          onSavePressed:
                                            _vm.onLatencyComponentSave,
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                          0
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedDashboard.layout.length == 0 &&
                    !_vm.isDashboardLoading
                      ? _c("center", { staticClass: "mt-5 h1" }, [
                          _vm._v(
                            "\n                    Add components to the dashboard by clicking the "
                          ),
                          _c("div", { staticClass: "fas fa-pen fa-md px-1" }),
                          _vm._v(" to the right.\n                "),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
            _vm._v(" "),
            _c("critical-alert-modal-component", {
              attrs: { jobNumber: _vm.jobNumber, isLimited: true },
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "b-sidebar",
          {
            attrs: {
              id: "sidebar-right",
              "backdrop-variant": "dark",
              width: _vm.windowSize.width <= 768 ? "100vw" : "400px",
              backdrop: "",
              "no-close-on-backdrop": "",
              shadow: "",
              right: "",
              "no-header": "",
              "sidebar-class": "border-left border-primary",
            },
            on: {
              change: _vm.handleDashboardOptionsVisibilityChange,
              hidden: function ($event) {
                _vm.isSelectedDashboardOptionsVisible = false
              },
            },
            model: {
              value: _vm.isSelectedDashboardOptionsVisible,
              callback: function ($$v) {
                _vm.isSelectedDashboardOptionsVisible = $$v
              },
              expression: "isSelectedDashboardOptionsVisible",
            },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "d-flex flex-column p-2 justify-content-center mt-4",
              },
              [
                _c("div", { staticClass: "align-self-stretch" }, [
                  _c("div", [_c("h2", [_vm._v("Dashboard Options")])]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "p-1" },
                    [
                      _c(
                        "b-form",
                        {
                          on: {
                            submit: _vm.onDashboardOptionsSubmit,
                            reset: _vm.onDashboardOptionsReset,
                          },
                        },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                id: "t-group-1",
                                label: "Dashboard name:",
                                "label-for": "input-1",
                              },
                            },
                            [
                              _c("b-form-input", {
                                attrs: { id: "input-1", required: "" },
                                on: {
                                  input: function ($event) {
                                    _vm.dashboardOptionsForm.changed = true
                                  },
                                  focus: function ($event) {
                                    _vm.isDashboardNameTaken = false
                                  },
                                  blur: function ($event) {
                                    return _vm.checkIsDashboardNameTaken(
                                      _vm.dashboardOptionsForm.dashboardName
                                    )
                                  },
                                },
                                model: {
                                  value: _vm.dashboardOptionsForm.dashboardName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.dashboardOptionsForm,
                                      "dashboardName",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "dashboardOptionsForm.dashboardName",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "b-popover",
                            {
                              attrs: {
                                target: "input-1",
                                varient: "light",
                                show: _vm.isDashboardNameTaken,
                                triggers: "",
                                placement: "bottom",
                              },
                              on: {
                                "update:show": function ($event) {
                                  _vm.isDashboardNameTaken = $event
                                },
                              },
                            },
                            [
                              _c("div", { staticStyle: { color: "white" } }, [
                                _vm._v(
                                  "\n                                Name is already used or unavailable\n                            "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-4 mb-1",
                              attrs: {
                                value: "default",
                                "unchecked-value": "private",
                              },
                              model: {
                                value: _vm.dashboardOptionsForm.sharedSettings,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dashboardOptionsForm,
                                    "sharedSettings",
                                    $$v
                                  )
                                },
                                expression:
                                  "dashboardOptionsForm.sharedSettings",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Company Default\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-1 mb-1",
                              attrs: {
                                value: "true",
                                "unchecked-value": "false",
                              },
                              model: {
                                value: _vm.dashboardOptionsForm.syncCharts,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dashboardOptionsForm,
                                    "syncCharts",
                                    $$v
                                  )
                                },
                                expression: "dashboardOptionsForm.syncCharts",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Sync Charts\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.canChangeDashboardTruckConfigs
                            ? _c(
                                "b-form-checkbox",
                                {
                                  staticClass: "mt-1 mb-1",
                                  attrs: {
                                    value: "true",
                                    "unchecked-value": "false",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.syncTrucksToggle($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.dashboardOptionsForm.syncTrucks,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.dashboardOptionsForm,
                                        "syncTrucks",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "dashboardOptionsForm.syncTrucks",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                            Set Primary Wireline Truck to Use:\n                        "
                                  ),
                                ]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.dashboardOptionsForm.syncTrucks === "true" &&
                          _vm.canChangeDashboardTruckConfigs
                            ? _c("div", [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.dashboardOptionsForm.defaultTruck,
                                        expression:
                                          "dashboardOptionsForm.defaultTruck",
                                      },
                                    ],
                                    staticClass: "form-control w-50",
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDefaultTruckChange(
                                          $event.target.value
                                        )
                                      },
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.dashboardOptionsForm,
                                          "defaultTruck",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  _vm._l(
                                    _vm.wirelineSystems,
                                    function (truck, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          domProps: { value: truck.number },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    " +
                                              _vm._s(truck.name) +
                                              "\n                                "
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-form-checkbox",
                            {
                              staticClass: "mt-1 mb-1",
                              attrs: {
                                disabled: !_vm.isTooltipSyncingValid(),
                                value: "true",
                                "unchecked-value": "false",
                              },
                              model: {
                                value:
                                  _vm.dashboardOptionsForm.syncChartTooltips,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dashboardOptionsForm,
                                    "syncChartTooltips",
                                    $$v
                                  )
                                },
                                expression:
                                  "dashboardOptionsForm.syncChartTooltips",
                              },
                            },
                            [
                              _vm._v(
                                "\n                            Sync Chart Tooltips\n                        "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "b-form-group",
                            {
                              staticClass: "mt-1 mb-4",
                              attrs: { id: "input-group-4" },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-between",
                                },
                                [
                                  _c("div", { staticClass: "w-50" }, [
                                    _vm._v("Chart Tooltip Mode: "),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "b-form-select",
                                    {
                                      staticClass:
                                        "select_form w-50 text-center",
                                      staticStyle: { "padding-top": "2px" },
                                      on: {
                                        click: function ($event) {
                                          _vm.dashboardOptionsForm.changed = true
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.dashboardOptionsForm
                                            .chartTooltipMode,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dashboardOptionsForm,
                                            "chartTooltipMode",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dashboardOptionsForm.chartTooltipMode",
                                      },
                                    },
                                    [
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "nearest" } },
                                        [_vm._v("Show Nearest")]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "all" } },
                                        [_vm._v("Show All")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.isAdmin || _vm.isCompanyAdmin
                            ? _c(
                                "b-form-group",
                                {
                                  staticClass: "mt-4 mb-4",
                                  attrs: {
                                    id: "input-group-4",
                                    label: "Shared with: ",
                                  },
                                },
                                [
                                  _c(
                                    "b-form-select",
                                    {
                                      staticClass: "select_form",
                                      class: {
                                        "text-muted":
                                          _vm.dashboardOptionsForm
                                            .sharedSettings == "default",
                                      },
                                      attrs: {
                                        disabled:
                                          _vm.dashboardOptionsForm
                                            .sharedSettings == "default",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.dashboardOptionsForm.changed = true
                                        },
                                      },
                                      model: {
                                        value:
                                          _vm.dashboardOptionsForm
                                            .sharedSettings,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.dashboardOptionsForm,
                                            "sharedSettings",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "dashboardOptionsForm.sharedSettings",
                                      },
                                    },
                                    [
                                      _vm.isDashboardOwner || _vm.isAdmin
                                        ? _c(
                                            "b-form-select-option",
                                            { attrs: { value: "private" } },
                                            [_vm._v("No one")]
                                          )
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "default" } },
                                        [
                                          _vm._v(
                                            "All " +
                                              _vm._s(_vm.customer) +
                                              " users"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "shared-roles" } },
                                        [
                                          _vm._v(
                                            "Certain " +
                                              _vm._s(_vm.customer) +
                                              " roles"
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "b-form-select-option",
                                        { attrs: { value: "shared-users" } },
                                        [
                                          _vm._v(
                                            "Certain " +
                                              _vm._s(_vm.customer) +
                                              " users"
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.dashboardOptionsForm.sharedSettings ==
                          "shared-roles"
                            ? _c("div", [
                                _c(
                                  "table",
                                  {
                                    staticClass: "table table-dark text-center",
                                    attrs: { id: "#usertable" },
                                  },
                                  [
                                    _c(
                                      "thead",
                                      { staticClass: "thead-dark text-center" },
                                      [
                                        _c("th", [_vm._v("Role")]),
                                        _vm._v(" "),
                                        _c("th", [_vm._v("Permissions")]),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm._l(
                                      _vm.dashboardOptionsForm.allRoles,
                                      function (role) {
                                        return _c("tr", { key: role.id }, [
                                          _c(
                                            "td",
                                            {
                                              class: {
                                                "text-muted": role.isDisabled,
                                              },
                                            },
                                            [_vm._v(_vm._s(role.name))]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              _c(
                                                "b-form-select",
                                                {
                                                  staticClass:
                                                    "select_form ml-4 pl-2",
                                                  attrs: {
                                                    value: role.shared_settings,
                                                    disabled: role.isDisabled,
                                                  },
                                                  on: {
                                                    change: (value) =>
                                                      _vm.onRolePermissionChange(
                                                        value,
                                                        role
                                                      ),
                                                  },
                                                },
                                                [
                                                  _c(
                                                    "b-form-select-option",
                                                    {
                                                      attrs: { value: "none" },
                                                    },
                                                    [_vm._v("No access")]
                                                  ),
                                                  _vm._v(" "),
                                                  role.name !== "admin" &&
                                                  role.name !== "companyAdmin"
                                                    ? _c(
                                                        "b-form-select-option",
                                                        {
                                                          attrs: {
                                                            value: "readonly",
                                                          },
                                                        },
                                                        [_vm._v("Read-only")]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "b-form-select-option",
                                                    {
                                                      attrs: {
                                                        value: "readwrite",
                                                      },
                                                    },
                                                    [_vm._v("Read/write")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ])
                                      }
                                    ),
                                  ],
                                  2
                                ),
                              ])
                            : _vm.dashboardOptionsForm.sharedSettings ==
                              "shared-users"
                            ? _c("div", [
                                _c("div", { staticClass: "flex-parent pb-2" }, [
                                  _c("div", { staticClass: "pr-3" }, [
                                    _vm._v(
                                      "\n                                    Search:\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "pr-3" },
                                    [
                                      _c("autocomplete", {
                                        ref: "userAutocomplete",
                                        attrs: {
                                          search: _vm.searchShareableUsernames,
                                          "auto-select": true,
                                          "get-result-value":
                                            _vm.getUserResultValue,
                                          placeholder: "Search for a username",
                                        },
                                        on: {
                                          submit: _vm.searchUsernameSelected,
                                        },
                                        scopedSlots: _vm._u([
                                          {
                                            key: "default",
                                            fn: function ({
                                              rootProps,
                                              inputProps,
                                              inputListeners,
                                              noResults,
                                              focused,
                                              resultListProps,
                                              resultListListeners,
                                              results,
                                              resultProps,
                                            }) {
                                              return [
                                                _c(
                                                  "div",
                                                  _vm._b(
                                                    {},
                                                    "div",
                                                    rootProps,
                                                    false
                                                  ),
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "input-group",
                                                      },
                                                      [
                                                        _c(
                                                          "input",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                class: [
                                                                  "form-control",
                                                                  "autocomplete-input",
                                                                  {
                                                                    "autocomplete-input-no-results":
                                                                      noResults,
                                                                  },
                                                                  {
                                                                    "autocomplete-input-focused":
                                                                      focused,
                                                                  },
                                                                ],
                                                                attrs: {
                                                                  role: "combobox",
                                                                  autocomplete:
                                                                    "off",
                                                                  autocapitalize:
                                                                    "off",
                                                                  autocorrect:
                                                                    "off",
                                                                  spellcheck:
                                                                    "false",
                                                                  "aria-autocomplete":
                                                                    "list",
                                                                  "aria-haspopup":
                                                                    "listbox",
                                                                  "aria-owns":
                                                                    "autocomplete-result-list-1",
                                                                  "aria-expanded":
                                                                    "true",
                                                                  placeholder:
                                                                    "Search for a username",
                                                                },
                                                              },
                                                              "input",
                                                              inputProps,
                                                              false
                                                            ),
                                                            inputListeners
                                                          )
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "input-group-append",
                                                          },
                                                          [
                                                            _vm
                                                              .dashboardOptionsForm
                                                              .searchedUsername !==
                                                            ""
                                                              ? _c(
                                                                  "button",
                                                                  {
                                                                    staticClass:
                                                                      "btn btn-primary dropdown-toggle-split",
                                                                    staticStyle:
                                                                      {
                                                                        "min-width":
                                                                          "15%",
                                                                      },
                                                                    attrs: {
                                                                      type: "button",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.clearUserAutocomplete()
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                            x\n                                                        "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _c(
                                                                  "button",
                                                                  _vm._g(
                                                                    {
                                                                      staticClass:
                                                                        "btn btn-primary dropdown-toggle dropdown-toggle-split",
                                                                      staticStyle:
                                                                        {
                                                                          "min-width":
                                                                            "15%",
                                                                        },
                                                                      attrs: {
                                                                        type: "button",
                                                                        "data-toggle":
                                                                          "dropdown",
                                                                        "aria-expanded":
                                                                          "false",
                                                                        "aria-autocomplete":
                                                                          "list",
                                                                        "aria-haspopup":
                                                                          "listbox",
                                                                        "aria-owns":
                                                                          "autocomplete-result-list-1",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.searchShareableUsernames()
                                                                          },
                                                                      },
                                                                    },
                                                                    inputListeners
                                                                  )
                                                                ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "ul",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "ul",
                                                          resultListProps,
                                                          false
                                                        ),
                                                        resultListListeners
                                                      ),
                                                      _vm._l(
                                                        results,
                                                        function (
                                                          result,
                                                          index
                                                        ) {
                                                          return _c(
                                                            "a",
                                                            _vm._b(
                                                              {
                                                                key: resultProps[
                                                                  index
                                                                ].id,
                                                                staticClass:
                                                                  "list-group-item list-group-item-action text-secondary p-1 text-left",
                                                                attrs: {
                                                                  href: "#",
                                                                },
                                                              },
                                                              "a",
                                                              resultProps[
                                                                index
                                                              ],
                                                              false
                                                            ),
                                                            [
                                                              _c("li", [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    result.username
                                                                  )
                                                                ),
                                                              ]),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      0
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ]),
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "pr-3" },
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            size: "sm",
                                            disabled:
                                              !_vm.dashboardOptionsForm
                                                .validSearchedUser,
                                            variant:
                                              _vm.dashboardOptionsForm
                                                .validSearchedUser != null
                                                ? "success"
                                                : "secondary",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.addToOptionsFormUsers(
                                                _vm.dashboardOptionsForm
                                                  .validSearchedUser
                                              )
                                            },
                                          },
                                        },
                                        [_vm._v("Add")]
                                      ),
                                    ],
                                    1
                                  ),
                                ]),
                                _vm._v(" "),
                                _vm.dashboardOptionsForm.sharedUsers.length > 0
                                  ? _c("div", [
                                      _c(
                                        "table",
                                        {
                                          staticClass:
                                            "table table-dark text-center",
                                          attrs: { id: "#usertable" },
                                        },
                                        [
                                          _c(
                                            "thead",
                                            {
                                              staticClass:
                                                "thead-dark text-center",
                                            },
                                            [
                                              _c("th", [_vm._v("User")]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Permissions")]),
                                              _vm._v(" "),
                                              _c("th", [_vm._v("Remove")]),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            _vm.dashboardOptionsForm
                                              .sharedUsers,
                                            function (user, index) {
                                              return _c("tr", { key: index }, [
                                                _c(
                                                  "td",
                                                  {
                                                    staticClass:
                                                      "max-text-width text-truncate",
                                                    class: {
                                                      "text-muted":
                                                        user.isDisabled,
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(user.username)
                                                    ),
                                                  ]
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "td",
                                                  [
                                                    _c(
                                                      "b-form-select",
                                                      {
                                                        staticClass:
                                                          "select_form",
                                                        attrs: {
                                                          value: user.pivot
                                                            .is_writable
                                                            ? "readwrite"
                                                            : "readonly",
                                                          disabled:
                                                            user.isDisabled,
                                                        },
                                                        on: {
                                                          change: (value) => {
                                                            _vm.dashboardOptionsForm.changed = true
                                                            _vm.onUserPermissionChange(
                                                              value,
                                                              user
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "b-form-select-option",
                                                          {
                                                            attrs: {
                                                              value: "readonly",
                                                            },
                                                          },
                                                          [_vm._v("Read-only")]
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "b-form-select-option",
                                                          {
                                                            attrs: {
                                                              value:
                                                                "readwrite",
                                                            },
                                                          },
                                                          [_vm._v("Read/write")]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c("td", [
                                                  _c(
                                                    "span",
                                                    {
                                                      class: {
                                                        "user-remover":
                                                          !user.isDisabled,
                                                        "user-remover-disabled":
                                                          user.isDisabled,
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-user-times",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            !user.isDisabled &&
                                                              _vm.removeUserFromDashboardSharedOptions(
                                                                user
                                                              )
                                                          },
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                ]),
                                              ])
                                            }
                                          ),
                                        ],
                                        2
                                      ),
                                    ])
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex w-100 mt-2" },
                            [
                              _c(
                                "div",
                                { staticClass: "mr-auto text-left" },
                                [
                                  !_vm.newDashboardDraft &&
                                  (_vm.selectedDashboard.created_by ===
                                    _vm.userId ||
                                    _vm.isAdmin ||
                                    _vm.isCompanyAdmin)
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: { variant: "secondary" },
                                          on: {
                                            click: _vm.deleteSelectedDashboard,
                                          },
                                        },
                                        [_vm._v("Delete")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  directives: [
                                    {
                                      name: "b-toggle",
                                      rawName: "v-b-toggle.sidebar-right",
                                      modifiers: { "sidebar-right": true },
                                    },
                                  ],
                                  staticClass: "mx-1 text-right",
                                  attrs: { variant: "light" },
                                  on: {
                                    click: function ($event) {
                                      _vm.newDashboardDraft = false
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  staticClass: "text-right",
                                  attrs: {
                                    type: "submit",
                                    variant: "primary",
                                    disabled: _vm.isDashboardNameTaken,
                                  },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("auth-fail-component", {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.hasAuthError,
              expression: "hasAuthError",
            },
          ],
        }),
        _vm._v(" "),
        _c("chart-config-modal", {
          ref: "chartConfigModal",
          attrs: {
            tags: _vm.tags,
            chartConfiguration: _vm.chartConfiguration,
            jobNumber: _vm.jobNumber,
            isAdmin: _vm.isAdmin,
            isCompanyAdmin: _vm.isCompanyAdmin,
            userId: _vm.userId,
            iwsUser: _vm.iwsUser,
            "dashboard-id": _vm.selectedDashboard.id,
            customer: _vm.customer,
            defaultChartColumnCount: _vm.defaultChartColumnCount,
            wells: _vm.wells,
            jobHourOffset: _vm.jobHourOffset,
            userRoles: _vm.userRoles.map((o) => o.name),
            chartType: "frac",
          },
        }),
        _vm._v(" "),
        _vm.jobEnd == null
          ? _c("signalr-error-modal", {
              attrs: {
                modalVisible: _vm.signalRModalVisible,
                resolutionPackage: _vm.resolutionPackage,
              },
              on: {
                onDismissSignalRErrorModal: _vm.onSignalRReconnect,
                modalClose: function ($event) {
                  _vm.signalRConnected = true
                  _vm.signalRModalVisible = false
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.initActivityCallEnded &&
        _vm.selectedDashboard.items.filter(
          (item) => item.type == "wireline-controls-component"
        ).length > 0
          ? _c(
              "div",
              [
                _c("comment-component", {
                  ref: "commentModal",
                  attrs: { jobData: _vm.getUpdatedJobData() },
                }),
                _vm._v(" "),
                _c("plug-gun-status-component", {
                  ref: "missRunModal",
                  attrs: {
                    jobData: _vm.getUpdatedJobData(),
                    noResultMessage: "No shots fired",
                    isFilterPlugs: false,
                    title: "Shots fired:",
                  },
                }),
                _vm._v(" "),
                _c("plug-gun-status-component", {
                  ref: "conditionModal",
                  attrs: {
                    jobData: _vm.getUpdatedJobData(),
                    noResultMessage: "No plug set",
                    isFilterPlugs: true,
                    title: "Plug set:",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }