<template>
    <div class="pb-5">
        <iws-breadcrumbs class="mt-4 mb-1"
            :path="[{
                title: 'Home',
                link: '/home'
            }, {
                title: 'Copy Remote Data',
                link: '/copy-data'
            }]"
        />
        <div class="page-title mb-4">
            Sync Remote Data
        </div>
        <b>**** Warning this is a developer tool and may overwrite your data, please use with caution. ****</b>
        <p class="mb-0">This tool is primarily for copying over data not related to a specific job into your local environment.</p>
        <p class="mb-0">The customer list dropdown is temporarily retrieved from the database specified in your .env Mongo string. Customers aren't copied down until requested.</p>
        <div class="mt-5">
            <h4>Company Data</h4>
            <div class="form-group has-validation">
                <label class="form-label" for="company">Select company you want data synced for:</label>
                <select :class="{'form-control': true, 'is-invalid': errors['company']}" id="company" v-model="company" :disabled="busy" placeholder="Select a company" required>
                    <option value="" disabled selected>Select company</option>
                    <option v-for="customer in customers" :value="customer.id" :key="customer.id">{{customer.name}}</option>
                </select>
                <div class="text-danger" v-if="errors['company']">
                    <small>{{ errors['company'] ? errors['company'][0] : undefined }}</small>
                </div>
            </div>
            <div class="pt-3">
                <div class="form-group">
                    <p>Select tables to be synced:</p>
                    <div v-for="option in companyOptions" v-bind:key="option.table" class="mb-4">
                        <b-form-checkbox v-model="option.copy" :disabled="busy">
                            {{ option.table }}
                        </b-form-checkbox>
                        <b-form-checkbox class="ml-4" v-model="option.value.includeUsers" :disabled="busy">
                            Include users
                        </b-form-checkbox>
                        <b-form-checkbox class="ml-4" v-model="option.value.includeDeleted" :disabled="busy">
                            Include deleted entries
                        </b-form-checkbox>
                    </div>
                    <div class="text-danger" v-if="errors['tables'] && syncCompanyData">
                        <small>{{ errors['tables'] ? errors['tables'][0] : undefined }}</small>
                    </div>
                </div>
                <div class="d-flex justify-content-end mt-5">
                    <button class="btn btn-primary" :disabled="busy" @click="copyData('/customer-data', companyOptions, true)">
                        <b-spinner small v-if="busy" class="mr-1"></b-spinner> Sync Company Data
                    </button>
                </div>
            </div>
        </div>
        <section class="mt-5">
            <h4>General Data</h4>
            <div class="pt-3">
                <p>Select tables to be synced:</p>
                <div v-for="option in generalOptions" v-bind:key="option.table" class="mb-4">
                    <b-form-checkbox v-model="option.copy" :disabled="busy">
                        {{ option.table }}
                    </b-form-checkbox>
                    <b-form-checkbox class="ml-4" v-model="option.value.includeUsers" :disabled="busy">
                        Include users
                    </b-form-checkbox>
                    <b-form-checkbox class="ml-4" v-model="option.value.includeDeleted" :disabled="busy">
                        Include deleted entries
                    </b-form-checkbox>
                </div>
                <div class="text-danger" v-if="errors['tables'] && !syncCompanyData">
                    <small>{{ errors['tables'] ? errors['tables'][0] : undefined }}</small>
                </div>
                <div class="d-flex justify-content-end mt-5">
                    <button class="btn btn-primary" :disabled="busy" @click="copyData('/general-data', generalOptions)">
                        <b-spinner small v-if="busy" class="mr-1"></b-spinner> Sync General Data
                    </button>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    props: {
        customers: {
            type: [Array, Object],
            required: true,
            default: () => []
        }
    },
    data() {
        return {
            errors: {},
            busy: false,
            company: '',
            syncCompanyData: false,
            companyOptions: [
                {table: 'Dashboard Data', copy: false, value: {name: 'dashboards', includeDeleted: false, includeUsers: false}},
                {table: 'Chart Configuration', copy: false, value: {name: 'chart_configuration', includeDeleted: false, includeUsers: false}}
            ],
            generalOptions: [
                {table: 'Metadata', copy: false, value: {name: 'metadata', includeDeleted: false}}
            ]
        };
    },
    methods: {
        copyData(url, options, forCompany = false) {
            const tables = [];
            options.forEach((option) => {
                if (option.copy) {
                    tables.push(option.value);
                }
            });
            const data = { tables };
            if (forCompany) {
                data['company'] = this.company;
            }
            this.syncCompanyData = forCompany;
            this.makeApiCall(url, data);
        },
        makeApiCall(url, data) {
            this.busy = true;
            this.errors = {};
            const self = this;
            $.post(
                url,
                {
                    ...data,
                    '_token': $('meta[name="csrf-token"]').attr('content')
                },
                (response) => {
                    if (response.error) {
                        self.errors = response.errors;
                    }
                    alert(JSON.stringify(response.message));
                },
                'json'
            ).fail((jqXHR, textStatus, errorThrown) => {
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                }
                alert(`Failed to sync over data: ${errorThrown}`);
            }).always(() => {
                self.busy = false;
            });
        }
    }
};
</script>

<style scoped>
    select {
        max-width: max(50%, 500px);
    }
</style>