var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "32",
        height: "32",
        viewBox: "0 0 32 32",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("g", { attrs: { "clip-path": "url(#clip0_26_10886)" } }, [
        _c("path", {
          attrs: {
            d: "M26 24V28H6V24H4V28L4.0076 27.9951C4.00665 28.2576 4.05745 28.5177 4.15709 28.7606C4.25673 29.0034 4.40325 29.2243 4.58829 29.4105C4.77333 29.5967 4.99325 29.7446 5.23548 29.8457C5.47771 29.9469 5.7375 29.9993 6 30H26C26.5304 30 27.0391 29.7893 27.4142 29.4142C27.7893 29.0391 28 28.5304 28 28V24H26Z",
            fill: _vm.color,
          },
        }),
        _vm._v(" "),
        _c("path", {
          attrs: {
            d: "M6 12L7.411 13.405L15 5.825V24H17V5.825L24.591 13.405L26 12L16 2L6 12Z",
            fill: _vm.color,
          },
        }),
      ]),
      _vm._v(" "),
      _c("defs", [
        _c("clipPath", { attrs: { id: "clip0_26_10886" } }, [
          _c("rect", { attrs: { width: "32", height: "32", fill: _vm.color } }),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }