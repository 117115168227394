<template>
  <div class="col">
    remote control
    <div class="form-group">
      <label>Job</label>
      <select class="form-control" v-model="jobId">
        <option v-for="job of jobs" :key="'job'+job.id" :value="job.id">{{job.jobNumber}} - {{job.location}}</option>
      </select> 
    </div>

    <div v-if="jobId" class="card bg-light m-2">
      <div class="card-header">
        Request remote control token
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Actions (csv)</label>
          <input class="form-control" type="text" v-model="actions">
        </div>
        <div v-if="actions.length>0" class="btn btn-primary" @click="requestToken">Request</div>
      </div>
    </div>
    <div v-if="jobId" class="card bg-light m-2">
      <div class="card-header">
        Remote command
      </div>
      <div class="card-body">
        <div class="form-group">
          <label>Action</label>
          <input class="form-control" type="text" v-model="action">
        </div>
        <div v-if="action.length>0" class="btn btn-primary" @click="sendCommand">Send command</div>
      </div>
    </div>
    <div class="alert alert-secondary m-2" v-if="response">
      {{response}}
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        action:'',
        actions:'',
        jobId:null,
        message: 'working',
        response:null
      }
    },
    props:["csrfToken","jobs"],
    methods:{
      requestToken(){
        let self = this
        self.response = null
        $.get(
          `/remoteControlRequestToken/${self.jobId}?actions=${self.actions}`,
          function(data){
            self.response = JSON.stringify(data)
          },
          'json'
        )
      },
      sendCommand(){
        let self = this
        self.response = null
        $.post(
          `/remoteControlCommand`,
          {
            '_token':self.csrfToken,
            jobId:self.jobId,
            action:self.action
          },
          function(data){
            self.response = JSON.stringify(data)
          },
          'json'
        )
      }
    }
  }
</script>
