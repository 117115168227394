<template>
    <div class="modal vertical-align-center" style="z-index: 1200" tabindex="-1" role="dialog" id="critical-alert-modal">
        <div class="modal-dialog modal-xl " role="document">
            <div class="modal-content main-container div-border px-2" :style="containerStyle">
                <div class="modal-body py-0">
                    <div class="text-center mt-3 mb-3" :style="titleStyle">
                        <h3>{{ alerts.length == 1 ? 'CRITICAL ALERT' : 'CRITICAL ALERTS ' + (index + 1) + " of " + alerts.length  }}</h3>
                    </div>
                    <hr :style="hrStyle"/>
                    <div class="row">
                        <div v-if="alerts.length > 1" class="col-1">
                            <i class="fas fa-chevron-left" @click="modIndex(-1)" :style="chevronStyle"></i>
                        </div>
                        <div :class="{ 'col-10': alerts.length > 1, 'col-12': alerts.length == 1 }">
                            <h1 class="text-center" style="color:black;">
                                <!-- TODO : CURRENTLY HARDCODED - If we end up adding other critical alerts this string should be included 
                                in the packet and pulled from the alert object -->
                                FRAC HIT ALERT - PLEASE FLUSH AND GO TO NEXT INTERVAL
                            </h1>
                            <h4 class="text-center" style="color:black;">
                                Tag Name : {{ alerts.length > 0 ? alerts[index].tagName : ''}}
                            </h4>
                        </div>
                        <div v-if="alerts.length > 1" class="col-1">
                            <i class="fas fa-chevron-right" @click="modIndex(1)" :style="chevronStyle"></i>
                        </div>
                    </div>
                    <hr :style="hrStyle"/>
                </div>
                <div class="modal-footer d-flex flex-row" style="border-top-width:0px">
                    <button @click="hideModal()" type="button" class="btn btn-secondary grey-button flex-end" style="color:black;">
                        Hide
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        props:[
            'jobNumber',
            'isLimited'
        ],
        data() {
            return {
                index: 0,
                alerts: [],
                flashTick: false,
                flasher: null,
                flasherInterval: 1000,
                hiddenAlerts: [],
                key: null,
                poller: null,
                pollerInterval: 10000,
                chevronStyle: {
                    color: "white"
                },
                containerStyle: {
                    backgroundColor: "red"
                },
                titleStyle: {
                    color: "white"
                },
                hrStyle: {
                    borderColor: "white", /* Chrome and Safari */
                    backgroundColor: "white", /* Firefox and Opera */
                    color: "white" /* IE7+ */
                }
            }
        },
        methods: {
            cullHiddenAlerts(incomingAlerts){
                let alertsToRemove = [];
                for(let y = 0; y < incomingAlerts.length; y++){
                    const targetAlert = incomingAlerts[y];
                    for(let x = 0; x < this.hiddenAlerts.length; x++){
                        if(this.hiddenAlerts[x].id == targetAlert.id){
                            alertsToRemove.push(targetAlert);
                        }
                    }
                }
                alertsToRemove.forEach( targetAlert => {
                    incomingAlerts.splice(incomingAlerts.indexOf(targetAlert),1);  
                });
                return incomingAlerts;
            },
            async fetchCriticalAlerts () {
                const url = '/getCriticalAlertsForJob';
                const self = this;
                const data = await $.get(
                    url,
                    {
                        jobNumber: self.jobNumber,
                        //To do: Limit can be removed once the issue with too many alert records
                        //causing page lag is resolved.
                        limit: self.isLimited ? 10 : null
                    },
                    'json'
                );

                if(data?.latestAlerts){
                    const notHiddenAlerts = self.cullHiddenAlerts(data.latestAlerts);
                    self.alerts = notHiddenAlerts;

                    if(self.alerts.length > 0){
                        self.showModal();
                    }else if($('#critical-alert-modal').hasClass('show')){
                        self.hideModal();
                    }
                }
            },
            flashModal(){
                if(this.flashTick){
                    this.containerStyle = { backgroundColor : "white"};
                    this.titleStyle = { color: "black" };
                    this.hrStyle = { 
                        borderColor: "black", /* Chrome and Safari */
                        backgroundColor: "black", /* Firefox and Opera */
                        color: "black" /* IE7+ */
                    };
                    this.chevronStyle = { color: "black"};

                    this.flashTick = false;
                }else{
                    this.containerStyle = { backgroundColor : "red"};
                    this.titleStyle = { color: "white" };
                    this.hrStyle = { 
                        borderColor: "white", /* Chrome and Safari */
                        backgroundColor: "white", /* Firefox and Opera */
                        color: "white" /* IE7+ */
                    };
                    this.chevronStyle = { color: "white"};

                    this.flashTick = true;
                }
            },
            hideModal(){
                clearInterval(this.flasher);
                this.flasher = null;

                const target = $('#critical-alert-modal');
                target.modal('hide');

                this.alerts = this.alerts.filter(function (targetAlert) {
                    return targetAlert != null;
                });

                this.storeIgnoredAlerts(this.alerts);
                this.hiddenAlerts = this.hiddenAlerts.concat(this.alerts);
                
                this.alerts = [];
                this.index = 0;
            },
            modIndex(modValue){
                const targetValue = this.index + modValue;
                if(targetValue >= 0 && targetValue < this.alerts.length){
                    this.index = targetValue;
                }
            },
            showModal(){
                const target = $('#critical-alert-modal');
                target.modal('show');
                
                if(this.flasher == null){
                    this.flasher = setInterval(this.flashModal, this.flasherInterval);
                }
            },
            storeIgnoredAlerts(targetAlerts){
                const currentAlerts = JSON.parse(localStorage.getItem(this.key));
                let newStorage = targetAlerts.concat(currentAlerts);
                newStorage = newStorage.filter(function (targetAlert) {
                    return targetAlert != null;
                });

                localStorage.setItem(this.key, JSON.stringify(newStorage));
            },
            fetchIgnoredAlerts(){
                const dataIn = JSON.parse(localStorage.getItem(this.key));
                return dataIn;
            }
        },
        mounted() {
            this.key = "ignored-crit-alerts-" + this.jobNumber;

            //Alert ignoring functionality
            const alertsToIgnore = this.fetchIgnoredAlerts();
            this.hiddenAlerts = alertsToIgnore ? alertsToIgnore : [];

            this.poller = null;
            this.poller = setInterval(this.fetchCriticalAlerts, this.pollerInterval);
        }
    }
</script>

<style scoped>
</style>
