<template>
    <base-kpi-template ref="baseKpiTemplate"
        title="Lateral Length/Day"
        :item="item"
        :inital-options="initalOptions"
        :edit-mode="editMode"
        :create-export-data="createExportData"
        :analytics-data="analyticsData"
        :errors="errors"
        :show-not-enough-data-warning="showNotEnoughDataWarning"
        :show-chart="showChart"
    
        @analyticsChanges="buildAnalytics()"
        @settingsChanges="initKPI()"
        @revertChanges="initKPI()"
    >
        <template #extra-info>
            <div class="extra-info">
                Time Range: {{ getTimeRangeDescription }}
            </div>
        </template>
    
        <template v-if="showChart" #content>
            <bar-chart
                ref="kpi"
                :chart-data="kpi"
                :options="options"
            />
        </template>
    </base-kpi-template>
    </template>
    
    <script>
    import GlobalFunctions from '../../../GlobalFunctions.js';
    const { isNullOrEmpty } = GlobalFunctions;
    
    import constants from './constants.js';
    const { DEFAULT_DATE_FORMAT, SECONDARY_DATE_FORMAT, chartStyle } = constants;
    
    const moment = require('moment');
    
    import BaseKpi from './BaseKpi.vue';
    import BaseKpiTemplate from './BaseKpiTemplate.vue';
    
    // Typically just one of the below
    import BarChart from '../../../barChart.js';
    
    export default {
        extends: BaseKpi,
        components: { BaseKpiTemplate, BarChart },
    
        data() {
            return {
                defaultDataSetObj: {
                    label: 'Lateral Length (ft)',
                    labelColor: '#CCCCCC',
                    fill: false,
                    backgroundColor: chartStyle.primaryBarGraphColour,
                    borderColor: chartStyle.primaryBarGraphColour,
                    data: []
                },

                ...this.BaseKpi({
                    yAxisLabel: 'Lateral Length (ft)',

                    xTick_callback: value => moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D'),
                    tooltip_callback: tooltipItem => `Lateral Length (ft): ${tooltipItem?.value || tooltipItem}`,
                    legend: this.item.options.dayType === 'Day shift and night shift'
                        ? {
                            display: true,
                            labels: {
                                fontColor: '#CCC',
                                boxWidth: 15,
                                generateLabels: chart => {
                                    const labels = Chart.defaults.global.legend.labels.generateLabels(chart);
                                    
                                    return labels.map(_label => {
                                        _label.text = _label.text.charAt(0).toUpperCase() + _label.text.slice(1);
                                        return _label;
                                    });
                                }
                            }
                        }
                        : null,
                })
            }
        },
    
        computed: {
            // Generically named computed properties to handle changes specific to this KPI
            showNotEnoughDataWarning: function() {
                return this.allowShowChart && this.isNotEnoughDataMsgPerKPIType(this.kpi);
            },
            showChart: function() {
                return this.allowShowChart && !isNullOrEmpty(this.kpi?.datasets[0]?.data);
            }
        },
    
        methods: {
            // Genericly named function for BaseKPI.vue to call on mounted
            // This creates the KPI in a unique way to the current files needs
            setDefaults: function() {
                this.$set(this.item.options, 'zeroStageType', this.item.options.zeroStageType || 'zeroStage-include');
                this.$set(this.item.options, 'dayType', this.item.options.dayType || 'Shift start to shift start');
            },
            initKPI: function() {
                this.allowShowChart = false;

                return this.fetchData().then(this.buildData);
            },
            fetchData: function() {
                return $.get(`/job-kpi-lateral-length/${this.jobNumber}`, { timeRangeType: this.item.options.dayType }).then(response => this.data = response);
            },
            buildData: function() {
                //package async call into a function so new data can be acquired during watcher handler on selectedDayType
                this.processCalculatedKPIResponse();
                this.buildLabels();
                
                this.setChartSmartStepSize(this.kpi?.datasets.map(_dataset => _dataset.data).flat(), 100, 10, 1000);

                this.allowShowChart = true;

                this.$nextTick(() => {
                    // this.createJobTimeAnnotationLines();
                    this.buildAnalytics();
                })
            },
            buildAnalytics: function() {
                this.onAnalyticsTypeChange(this.item.options.analyticsType);
                this.buildAnalyticsData();

                this.$nextTick(() => {
                    this.assignChartSize();
                });
            },
            createExportData: function() {
                const isDayNight = this.item.options.dayType === 'Day shift and night shift';

                const fields = isDayNight ? ['Date', 'Day Length', 'Night Length'] : ['Date', 'Length'];
                const items = this.processExportItems(this.kpi.datasets, this.kpi.defaultFormatLabels, isDayNight);
                const fileName = this.getExportFilename(this.item.options.type);
    
                return { 
                    fields, 
                    items, 
                    fileName
                };
            },

            processCalculatedKPIResponse: function() {
                if (this.data?.error) {
                    console.log(this.data?.message);
                    return;
                }

                //if timeRange is dayNight, then split the lateral length data into seperate collections based on shift times
                if(this.item.options.dayType === 'Day shift and night shift') {
                    this.kpi.datasets = [
                        { ...this.defaultDataSetObj, data: [], label: 'day' },
                        { ...this.defaultDataSetObj, data: [], label: 'night', backgroundColor: chartStyle.secondaryBarGraphColour }
                    ];

                    //shiftData start times only required for day-night calculations currently
                    const shiftStartTimes = this.getShiftStartTimes();
                    const days = [];
                    const nights = [];
                    const labels = new Set(); //collection of only unique values for the date axis labels

                    Object.entries(this.data).forEach(record => {//record[0] => dateTime, record[1] => lateral length value
                        //add unique dates to the set of date labels
                        labels.add(moment(record[0]).format('YYYY-MM-DD'));

                        const time = moment(record[0]).format('HH:mm:ss');

                        //compare time of the record to which shift threshold it occurs on
                        if (time >= shiftStartTimes.nightShift || time < shiftStartTimes.dayShift) {
                            nights.push(record[1]);
                        } else {
                            days.push(record[1]);
                        }
                    });

                    //turn date labels into an array without duplicates dates, use $set to force chart re-render
                    this.$set(this.kpi, 'labels', Array.from(labels));

                    //populate datasets with filtered data (rounded to 2 decimals but kept as a  Number type)
                    this.kpi.datasets[0].data = days.map(value=>value);
                    this.kpi.datasets[1].data = nights.map(value=>value);
                } else {
                    this.kpi.datasets = [ { ...this.defaultDataSetObj, data: [] } ];

                    //Add values based on dates already calculated on the backend, use $set to ensure re-render
                    //use unary (+) to enforce Number type of data
                    this.$set(this.kpi, 'labels', Object.keys(this.data));

                    this.kpi.datasets[0].data = Object.values(this.data).map(value=>value);
                }

                this.analyticDatasets = _.cloneDeep(this.kpi.datasets);
                this.appendZeroEntriesToEnd(this.kpi);
            },
            buildLabels: function() {
                const kpiDays = []
                const defaultFormatLabels = []
                const { hours, minutes } = this.getShiftStartTimeInHourAndMinutes();
                const isDayNight = this.item.options.dayType === 'Day shift and night shift';

                this.kpi.labels.forEach((ts) => {
                    kpiDays.push(moment(ts).format(SECONDARY_DATE_FORMAT));

                    if (isDayNight) {
                        defaultFormatLabels.push(moment(ts).hour(hours).minute(minutes).format(DEFAULT_DATE_FORMAT));
                    } else {
                        defaultFormatLabels.push(ts);
                    }
                });

                this.kpi.labels = kpiDays;
                this.kpi.defaultFormatLabels = defaultFormatLabels;
            }
        }
    }
    </script>