import { render, staticRenderFns } from "./PerforationReportComponent.vue?vue&type=template&id=49892caa&scoped=true&"
import script from "./PerforationReportComponent.vue?vue&type=script&lang=js&"
export * from "./PerforationReportComponent.vue?vue&type=script&lang=js&"
import style0 from "./PerforationReportComponent.vue?vue&type=style&index=0&id=49892caa&scoped=true&lang=css&"
import style1 from "./PerforationReportComponent.vue?vue&type=style&index=1&id=49892caa&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49892caa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10162/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49892caa')) {
      api.createRecord('49892caa', component.options)
    } else {
      api.reload('49892caa', component.options)
    }
    module.hot.accept("./PerforationReportComponent.vue?vue&type=template&id=49892caa&scoped=true&", function () {
      api.rerender('49892caa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/PerforationReportComponent.vue"
export default component.exports