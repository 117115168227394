<template>
    <div class="dark-modal modal" id="ToolstringDuplicateModal" ref="ToolstringDuplicateModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        <h5>
                            Duplicate Toolstring
                        </h5>

                        <div>
                            Are you sure you want to duplicate this stage?
                        </div>
                    </div>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
        
                <div v-if="toolstring" class="modal-body">
                    <div style="margin-bottom: 15px">
                        <iws-input ref="nameInput"
                            label="Name"
                            :value.sync="toolstring.name"
                            required="true"
                            placeholder="Give this toolstring a name"
                            error-message="Please provide a name"
                        />

                        <div v-if="!_isNullOrEmpty(wells)" style="margin-top: 20px">
                            <iws-select
                                label="Well"
                                :value.sync="selectedWell"
                                :options="wells"
                                display-name="name"
                                value-name="index"
                            />
                        </div>
                    </div>

                    <iws-alert type="info">
                        <template #body>
                            Please make sure to configure and customize once duplicated
                        </template>
                    </iws-alert>
                </div>
    
                <div class="modal-footer">
                    <iws-button id="cancel_button" type="outline-light" text="Cancel" />
    
                    <iws-button text="Duplicate" :click="onSaveClick" />
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isNullOrEmpty, toast } = GlobalFunctions;
    
export default {
    props: {
        jobNumber: {
            type: String | Number,
            required: true
        },
        wells: {
            type: Array
        }
    },

    data: () => ({
        toolstring: null,
        resolve: null,

        selectedWell: null
    }),

    methods: {
        _isNullOrEmpty(value) {
            return isNullOrEmpty(value);
        },

        onSaveClick() {
            return $.ajax({
                method: 'POST',
                url: '/toolstring',
                data: {
                    '_token': GlobalFunctions.getCSRFToken(),
                    ...this.toolstring,
                    wellNumber: this.selectedWell
                },
                dataType: 'json'
            }).then(_ => {
                this.resolve(_);
            }).catch(_ => {
                toast({
                    title: 'Failed to duplicate',
                    body: _?.responseJSON?.message,
                    variant: 'danger'
                });
            });
        },

        open(currentWellNumber, toolstring) {
            const modalElem = $('#ToolstringDuplicateModal');
            this.selectedWell = currentWellNumber;
            this.toolstring = toolstring;

            return new Promise((resolve, reject) => {
                modalElem.show();
                this.resolve = (val) => {
                    modalElem.hide();
                    resolve(val);
                }
            });
        }
    },

    mounted() {
        $('#ToolstringDuplicateModal')?.find('#cancel_button, .close').on('click', () => {
            this.resolve(null);
        });
    }
};
</script>

<style scoped>
    .modal-dialog {
        max-width: 400px;
    }
</style>