<template>
  <!-- Allocate chart inside div container -->
  <div class="h-100 w-100">
    <div class="w-100 d-flex" style="position: relative: top: 5px; left: 5px;">
        <div class="mr-2">
            <label for="dataFilterButton">
                <button id="dataFilterButton" v-tooltip:top="'Set heatmap filter options'" class="fake-button" @click="$bvModal.show('limitsModal')">
                    <i class="fas fa-cog fa-lg pl-2" ></i>
                </button>Data Filter
            </label>
        </div>
        <div>
        <button class="fake-button show-clicker-finger" :disabled="!data || data.length == 0" @click="printChartToImage()" v-tooltip:top="'Print Chart View'">
                <i class="fas fa-print mx-2"></i>
        </button>
        <button id="exportChartButton" v-tooltip:top="'Export Chart Data'" class="fake-button show-clicker-finger" :disbled="!data || data.length == 0" >
            <i class="fas fa-download"></i>
            <b-popover target="exportChartButton" placement="left" triggers="hover" >
                <div style="color:white;">
                    <div class="w-100 text-center" style="text-decoration:underline;">Export Data</div>
                    <div class="d-flex justify-content-between mt-2">
                        <div class="mx-2">
                            File Type: 
                        </div>
                        <select id="exportFileType" class="mx-2" v-model="exportFileType">
                            <option value="json" >JSON</option>
                            <option value="csv" selected>CSV</option>
                        </select>
                    </div>
                    <div class="d-flex justify-content-center mt-2">
                        <button type="button" class="btn btn-success green-button" @click="exportChartData(exportFileType)" :disabled="!data || data.length == 0">
                            <div class="d-flex justify-content-center">
                                    <span v-if="false" class="spinner-border spinner-border-sm pr-2" role="status" aria-hidden="true"></span>
                                <div>
                                    Export
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </b-popover>
        </button>
        </div>
    </div>
    <b-modal id="limitsModal"
        header-class="border-bottom-0 justify-content-center p-0"
        body-class="pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg"
        @ok="applyLimitChanges()"
        > 
        <template #modal-header>
            <div class="d-flex justify-content-center">Data Limits</div>
        </template>
    
        <div class="d-flex flex-column justify-content-center">
            <div class="w-100">
                <label for="ignoreZeroes"><input :disabled="!data || data.length == 0" type="checkbox" id="ignoreZeroes" class="mr-2" v-model="ignoreZeroValues">Ignore Zero Values</label>
            </div>
            <div class="d-flex flex-column">
                <label for="setDataLimits"><input id="setDataLimits" :disabled="!data || data.length == 0" class="mr-2" type="checkbox" v-model="filterExtremeValues">Set Data Limits</label>
                <div class="d-flex justify-content-center">
                    <div class="w-50 text-center">
                        <label for="lowDataThreshold">Lower Limit
                            <input id="lowDataThreshold" class="ml-2 form-control" type="number" v-model.number="lowDataThreshold" :disabled="!filterExtremeValues">
                        </label>
                    </div>
                    <div class="w-50 text-center">
                        <label for="highDataThreshold">Upper Limit
                            <input id="highDataThreshold" class="ml-2 form-control" type="number" v-model.number="highDataThreshold" :disabled="!filterExtremeValues">
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </b-modal>
    <div :id="chartId" :ref="chartId" :style="'height:' + height + 'px;'" ></div>
  </div>
</template>

<script>
const lcjs = require('@arction/lcjs');

// Extract required parts from LightningChartJS.
const {
    lightningChart,
    SolidLine,
    SolidFill,
    emptyFill,
    emptyLine,
    ColorRGBA,
    PalettedFill,
    AxisTickStrategies,
    AxisScrollStrategies,
    UIOrigins,
    DataPatterns,
    VisibleTicks,
    Themes,
    ColorHEX,
    UIElementBuilders,
    UIDraggingModes,
    UILayoutBuilders,
    LUT
} = lcjs;

//Needed for production deployments to license the use of lightning charts on deployment
const lightningChartLicence = process.env.MIX_LC_LICENSE || null;

import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';
import _ from 'lodash';

const CHART_RIGHT_SIDE_OFFSET = 50;
const FREQUENCY_CHART_PADDING_RIGHT = 40;
const BAR_CHART_LABEL_PADDING = {left: 20};
const HEATMAP_PADDING = {bottom: 50};
const LEGEND_CHART_PADDING = {bottom: 40};
const FREQUENCY_CHART_PADDING = {left: 25};
const LUT_POSITION = { x: 0, y: 0 };
const HIGH_STAGE_COUNT_BREAKPOINT = 24; //if trying to show a heatmap of stages > than this value, ticks must be hidden on the x axis
const HIGH_STAGE_COUNT_BREAKPOINT2 = 49; //if trying to show a heatmap of stages > than this value, ticks must be hidden on the x axis
const EMPTY_MATRIX_CELL_VALUE = -9999;
const MILLIS_PER_HR = 3600000;
const BORDER_HIGHLIGHT_COLOR = '#FFD700'; //gold
const DEFAULT_BORDER_COLOR = '#cacaca'; //grey
const GRADIENT_DIVISIONS = 12;
const RECT_GAP = 0.5;
const FREQUENCY_CHART_X_LABEL_POS = 0.4;
const RECT_THICKNESS = 1;
const DEFAULT_COLOR_HIGH = '00ff00';
const DEFAULT_COLOR_LOW = 'ff0000';
const DEFAULT_AXIS_TICK_COLOR = '#888888';
const DEFAULT_FONT_SIZE = 17;
const DEFAULT_FONT_SIZE_SMALL = 14;
const DATA_LABEL_FONT_SIZE = 15;
const FREQUENCY_DATA_LABEL_OFFSET = 4;

export default {
    name: 'LightningChart',
    props: {
        job: {
            type: [Array, Object]
        },
        data: {
            type: [Array, Object]
        },
        wellData: {
            type: [Array, Object]
        },
        height: {
            type: Number
        },
        stageMin: {
            type: Number
        },
        stageMax: {
            type: Number
        },
        propShowLegend: {
            default: true,
            type: Boolean
        }

    },
    created() {
        this.ticker = false;
    },
    data() {
    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
        return {
            chartId: null,
            lcDashboard: null,
            frequencyChart: null,
            legendChart: null,
            valueLookupTable: null,
            legendBarLookupTable: null,
            heatmapSteps: [],
            legendBarSteps: [],
            dataMatrix: [],
            colorLow: DEFAULT_COLOR_LOW,
            colorHigh: DEFAULT_COLOR_HIGH,
            heatMapConfig: {
                wellAxisStep: 1,
                wellAxisStart: 0,
                wellAxisEnd: 1,
                stageAxisStep: 1,
                stageAxisStart: 1,
                stageAxisEnd: 1
            },
            dataMinValue: null,
            dataMaxValue: null,
            ignoreZeroValues: false,
            filterExtremeValues: false,
            lowDataThreshold: null,
            highDataThreshold: null,
            showLegend: null,
            exportFileType: 'csv'
        };
    },
    computed: {
    },
    methods: {
        // Create chartXY
        createNewLightningChart() {
            this.createChart();
            this.$nextTick(() => {
                this.resizeLayout();
            });
            
            this.dataRecord = {};
            this.$root.$emit('chartDataCleared');
        },
        resizeLayout() {
            if (this.lcDashboard && this.chart && this.frequencyChart) {
                // used to fix height issues, giving the process to a different thread. 
                //( need to run as the last process of the chart )
                setTimeout(()=>{
                    this.lcDashboard.setHeight(this.height);
                    this.lcDashboard.engine.layout();
                    this.chart.engine.layout();
                    this.frequencyChart.engine.layout();
                    if (this.legendChart) {
                        this.legendChart.engine.layout();
                    }       
                }, 0);
            }
        },
        clearChart() {
            if (this.chart) {
                this.chart.dispose();
                this.chart = null;
            }
            if (this.frequencyChart) {
                this.frequencyChart.dispose();
                this.frequencyChart = null;
            }
            if (this.legendChart) {
                this.legendChart.dispose();
                this.legendChart = null;
            }
            if (this.lcDashboard) {
                this.lcDashboard.dispose();
                this.lcDashboard = null;
            }
        },
        createHeatmapChart() {
            this.chart = this.lcDashboard.createChartXY({
                theme: Themes.light,
                rowIndex: 0,
                columnSpan: this.showLegend ? 1 : 2,
                columnIndex: 0
            });
            this.chart.setPadding({
                right: CHART_RIGHT_SIDE_OFFSET
            });
            const titleString = this.job?.location ? this.job.location + ' Heat Map' : 'Heat Map';
            this.chart.setTitle(titleString);

            // Enable AutoCursor auto-fill.
            this.chart.setAutoCursor(cursor => cursor
                .setResultTableAutoTextStyle(true)
                //remove automatic axis tick markers when the mouse cursor table is visible
                .disposeTickMarkerX()
                .disposeTickMarkerY()
            );
            this.chart.setMouseInteractions(false);//disable pan and zoom on the heatmap
            //set up heatmap x axis
            const axisX = this.chart.getDefaultAxisX();
            axisX.setTitle('Stages');
            axisX.setTickStrategy(AxisTickStrategies.Empty);
            // Disable Mouse interactions for the X Axis
            axisX.setMouseInteractions(false);
            
            let showFewerTicks = false;
            let showTickInterval = 1;
            if (this.stageMax - this.stageMin > HIGH_STAGE_COUNT_BREAKPOINT2) {
                showFewerTicks = true;
                showTickInterval = 5;
            }
            else if (this.stageMax - this.stageMin > HIGH_STAGE_COUNT_BREAKPOINT) {
                showFewerTicks = true;
                showTickInterval = 2;
            }
            axisX.setMouseInteractions(false);
            axisX.setInterval(this.stageMin - RECT_GAP, this.stageMax + RECT_GAP, false, true);
            for (let i = this.stageMin - RECT_GAP; i <= this.stageMax + RECT_GAP; i += RECT_GAP) {
                //if there are too many x axis ticks to show due to label overlaps, only create every n tick marker
                if (showFewerTicks === false || i % showTickInterval === 0) {
                    const newTick = axisX.addCustomTick(UIElementBuilders.AxisTick);
                    newTick.setValue(i);
                    newTick.setGridStrokeLength(0);
                    newTick.setTextFormatter((value) => value.toFixed(0));
                    newTick.setMarker((marker) => marker
                        .setTextFont(fontSettings => fontSettings.setSize(DEFAULT_FONT_SIZE))
                    );
                    //remove tick marks for minor ticks on x axis
                    if (!Number.isInteger(i)) {
                        newTick.setTickLength(0);
                        newTick.setTextFormatter(() => '');
                    }
                }
            }
            //set up heatmap y axis
            const axisY = this.chart.getDefaultAxisY();
            axisY.setTitle('Wells');
            // Hide default ticks
            axisY.setTickStrategy(AxisTickStrategies.Empty);
            // Disable Mouse interactions for the Y Axis
            axisY.setMouseInteractions(false);
            
            this.wellData.forEach((well,index) => {
                axisY.setInterval(0,index + 1, false, false); //index +1 offsets the first well from being on the bottom of the chart y axis
                const newTick = axisY.addCustomTick(UIElementBuilders.AxisTick);
                newTick.setValue(index + RECT_GAP);
                newTick.setGridStrokeLength(0);
                newTick.setTextFormatter(_ => (`${well.wellName}`));
                newTick.setMarker((marker) => marker
                    .setTextFillStyle(new SolidFill({ color: ColorHEX('#000') }))
                    .setTextFont(fontSettings => fontSettings.setSize(DEFAULT_FONT_SIZE))
                );
                return newTick;
            });

            this.chart.engine.layout();
        },
        createLegendChart() {
            this.legendChart = this.lcDashboard.createChartXY({
                theme: Themes.lightNew,
                rowIndex: 0,
                rowSpan: 2,
                columnIndex: 1
            })
                .setTitle('Legend')
                .setMouseInteractions(false);
            this.legendChart.disableAnimations();

            this.legendChart.setAutoCursorMode(false);
            this.legendChart.setPadding(LEGEND_CHART_PADDING);

            const axisX = this.legendChart.getDefaultAxisX()
            // Disable the scrolling animation for the X Axis 
            //so the static values can't be scrolled out of view
                .setAnimationScroll(false)
                .setMouseInteractions(false)
                .setTickStrategy(AxisTickStrategies.Empty);

            const axisY = this.legendChart.getDefaultAxisY()
                // Hide default ticks
                .setTickStrategy(AxisTickStrategies.Empty)
                // Disable Mouse interactions for the Y Axis
                .setMouseInteractions(false);
            //create custom ticks for the frequency chart y axis
            const rectangleBars = [];
            this.legendBarSteps.forEach((step,index) => {
                axisY.addCustomTick(UIElementBuilders.AxisTick)
                    .setValue(index)
                    .setGridStrokeLength(0)
                    .setTextFormatter( _ => step.value.toFixed(0))
                    .setMarker((marker) => marker
                        .setTextFillStyle(new SolidFill({color: ColorHEX(DEFAULT_AXIS_TICK_COLOR)}))
                        .setTextFont(fontSettings => fontSettings.setSize(DEFAULT_FONT_SIZE))
                    );
                //rectancle object to hold bar configuration for graph
                const newRect = {
                    x: 0,
                    y: index,
                    width: 1,
                    height: RECT_THICKNESS
                };
                rectangleBars.push(newRect);
                const rectSeries = this.legendChart.addRectangleSeries();
                const rect = rectSeries.add(newRect);
                rect.setFillStyle(new SolidFill().setColor(step.color));
            });
        },
        createFrequencyChart() {
            this.frequencyChart = this.lcDashboard.createChartXY({
                theme: Themes.lightNew,
                rowIndex: 1,
                columnSpan: this.showLegend ? 1 : 2,
                columnIndex: 0
            })
                .setTitle('Frequency')
                .setMouseInteractions(false);
            this.frequencyChart.disableAnimations();
            this.frequencyChart.setPadding(FREQUENCY_CHART_PADDING);
            //turn auto cursor off for frequency chart
            this.frequencyChart.setAutoCursorMode(false);

            const axisX = this.frequencyChart.getDefaultAxisX()
            // Disable the scrolling animation for the X Axis 
            //so the static values can't be scrolled out of view
                .setAnimationScroll(false)
                .setMouseInteractions(false)
                .setTickStrategy(AxisTickStrategies.Empty);

            const axisY = this.frequencyChart.getDefaultAxisY()
                // Hide default ticks
                .setTickStrategy(AxisTickStrategies.Empty)
                // Disable Mouse interactions for the Y Axis
                .setMouseInteractions(false);
            //create custom ticks for the frequency chart y axis
            const rectangleBars = [];
            this.legendBarSteps.forEach((step,index) => {
                axisX.addCustomTick(UIElementBuilders.AxisTick)
                    .setValue(index)
                    .setGridStrokeLength(0)
                    .setTextFormatter( _ => step.value.toFixed(0))
                    .setMarker((marker) => marker
                        .setTextFillStyle(new SolidFill({color: ColorHEX(DEFAULT_AXIS_TICK_COLOR)}))
                        .setTextFont(fontSettings => fontSettings.setSize(DEFAULT_FONT_SIZE_SMALL))
                    );
                //rectancle object to hold bar configuration for graph
                const newRect = {
                    x: index,
                    y: 0,
                    width: RECT_THICKNESS,
                    height: step.frequency || 0
                };
                rectangleBars.push(newRect);
                const rectSeries = this.frequencyChart.addRectangleSeries({
                    xAxis: axisX,
                    yAxis: axisY
                });
                const rect = rectSeries.add(newRect);
                rect.setFillStyle(new SolidFill().setColor(step.color));
                //add frequency count as a label to the end of the bar
                const barLabel = this.frequencyChart.addUIElement(
                    UIElementBuilders.TextBox,
                    {x: this.frequencyChart.getDefaultAxisX(), y: this.frequencyChart.getDefaultAxisY()})
                    .setOrigin(UIOrigins.Center)
                    //setPosition formula is ugly, but necessary to consistently align labels with frequency rectangles
                    .setPosition({
                        x: index + FREQUENCY_CHART_X_LABEL_POS, 
                        y: ((step.frequency / this.maxFrequency) * this.maxFrequency + (this.maxFrequency / FREQUENCY_DATA_LABEL_OFFSET)) || 0
                    })
                    .setText(step.frequency > 0 ? step.frequency.toFixed(0) : '')
                    .setPadding(BAR_CHART_LABEL_PADDING)
                    .setTextFont(fontSettings => fontSettings.setSize(DATA_LABEL_FONT_SIZE))
                    .setBackground((background) => background
                        .setFillStyle(emptyFill)
                        .setStrokeStyle(emptyLine)
                    )
                    .setMouseInteractions(false);
            });
        },
        createChart() {  
            if (!this.colorLow) {
                this.colorLow = DEFAULT_COLOR_LOW;
            }
            if (!this.colorHigh) {
                this.colorHigh = DEFAULT_COLOR_HIGH;
            }
            if(lightningChartLicence) {
                this.lcDashboard = lightningChart(
                    lightningChartLicence
                ).Dashboard({
                    container: `${this.chartId}`,
                    theme: Themes.light,
                    numberOfColumns: 2,
                    numberOfRows: 2
                })
                    .setColumnWidth(0,8) //(a,b) => a = column index, b = relative width
                    .setColumnWidth(1,1)
                    .setRowHeight(0,3)
                    .setRowHeight(1,1);
                this.lcDashboard.setSplitterStyle(emptyLine); //removes dashboard divider between lc dashboard columns
                this.createHeatmapChart();
            }else{
                this.lcDashboard = lightningChart().Dashboard({
                    container: `${this.chartId}`,
                    theme: Themes.light,
                    numberOfColumns: 2,
                    numberOfRows: 2
                })
                    .setColumnWidth(0,8) //(a,b) => a = column index, b = relative width
                    .setRowHeight(0,3)
                    .setRowHeight(1,1);
                this.lcDashboard.setSplitterStyle(emptyLine); //removes dashboard divider between lc dashboard columns
                this.createHeatmapChart();
                //**frequency and legend charts are created at the end of new data processing**
                this.lcDashboard.setBackgroundFillStyle(new SolidFill({color: ColorHEX('#fff')}));
            }
        },
        printChartToImage() {
            this.chart.saveToFile(this.chart.getTitle() + ' - Screenshot-' + moment().format('YYYY-MM-DD HH:mm:ss'));
        },
        processStageSummaryData(data) {
            //reset data values to infinite values for numerical comparison
            this.dataMaxValue = -Infinity;
            this.dataMinValue = Infinity;

            const stageSummary = [];
            Object.values(data).forEach(well => {
                const stageSummaryData = Object.values(well);
                let filteredSummaryData = stageSummaryData.filter(o => o.value !== null); //remove null values

                if (this.ignoreZeroValues) {
                    filteredSummaryData = filteredSummaryData.filter(o => o.value !== 0);
                }

                if (this.filterExtremeValues) {
                    filteredSummaryData = filteredSummaryData.filter(o => o.value >= this.lowDataThreshold && o.value <= this.highDataThreshold);
                }
                stageSummary.push(...filteredSummaryData);
                
                const dataMax = Math.max(...filteredSummaryData.map(o => o.value));
                const dataMin = Math.min(...filteredSummaryData.map(o => o.value));

                if (dataMax >= this.dataMaxValue) {
                    this.dataMaxValue = dataMax;
                }
                if (dataMin <= this.dataMinValue) {
                    this.dataMinValue = dataMin;
                }
            });
            return stageSummary;
        },
        applyLimitChanges() {
            this.makeHeatmapFromData(this.data);
        },
        makeHeatmapFromData(data) {
            if (!data || data.length == 0) {
                const errorMessage = 'No summary tag data found for the selected pad or an invalid selection';
                const error = {
                    errorType: 'No Data',
                    message: errorMessage,
                    title: 'Stage Summary Error'
                };
                this.$emit('error', error);
                this.clearChart();
                return;
            }
            //if a previous heatmap already exists, dispose of it before creating a new one
            if (this.chart || this.frequencyChart) {
                this.clearChart();
            }
            this.createNewLightningChart();

            const stageSummary = this.processStageSummaryData(data);

            //if data min and max values were not found in processing there is an error in the data
            if (this.dataMinValue == Infinity || this.dataMaxValue == -Infinity) {
                const errorMessage = 'No values exist in the summary data for the selected channel';
                const error = {
                    errorType: 'dataFetch',
                    message: errorMessage,
                    title: 'No Data For Tag'
                };
                this.$emit('error', error);
                return;
            }

            const range = this.dataMaxValue - this.dataMinValue != 0 ? this.dataMaxValue - this.dataMinValue : 500;
            const gradientDivisions = range < GRADIENT_DIVISIONS ? parseInt(range) : GRADIENT_DIVISIONS;

            this.legendBarSteps = []; //set of color value steps used to populate the heatmap legend bar

            const self = this;
            let maxStepValue = null;
            let minStepValue = null;
            //basic way to mix the high and low colors, for color breakpoints
            //To Do: revise this and add customizations options so users can set 
            //their own colors and step boundaries.
            
            //(gradientDivisions - 1) => omit the step that would only contain the max value         
            for (let i = 0; i <= (gradientDivisions - 1); i++) {
                const mixValue = i / gradientDivisions;
                const colorStep = {};
                const newValue = this.dataMinValue + (range / gradientDivisions) * i;
                
                //edge case handling - min and max values are the same:
                //If min and max are the same value, the smallest data step needs to be reduced to lower
                //than the min value, otherwise all cells for that well will show as no data because of
                //the way that the heat maps bins the values. Normal cases are not affected by this handling

                //**Also** the lc grid series  seems to sometimes introduce
                //a rounding issue, where the cell that contains the lowest value may instead
                //appear as if no data was returned for it even though it had a valid value. 
                //This should also fix that problem.
                if (i === 0) {
                    //lower the minimum step value to be just below the minimum data value
                    newValue = Math.floor(this.dataMinValue) - 1; 
                    minStepValue = newValue;                
                }
                const newColor = GlobalFunctions.blendColors(self.colorHigh, self.colorLow, mixValue);
                colorStep.color = ColorHEX(newColor);
                colorStep.value = newValue;
                colorStep.frequency = 0;
                this.legendBarSteps.push(colorStep);

                maxStepValue = newValue;//keep a record of the largest used step value
            }
            // color-value steps that are used to create the heatmap
            // this is different from the legend bar steps in that it contains an additional
            // step at the first index to handle missing values.
            this.heatmapSteps = [
                { //add default step for missing values
                    color: ColorHEX('#fff'),
                    value: EMPTY_MATRIX_CELL_VALUE,
                    frequency: 0
                },
                ...this.legendBarSteps
            ];
            this.valueLookupTable = new LUT({
                interpolate: false,
                steps: this.heatmapSteps
            });

            this.legendBarLookupTable = new LUT({
                interpolate: false,
                steps: this.legendBarSteps
            });
            //create a data matrix for the heatmap, organize it by well number then stage number
            const newMatrix = [];
            this.maxFrequency = 0;
            this.wellData.forEach(well => {
                if (newMatrix[well.index] == undefined) {
                    newMatrix[well.index] = [];
                }
                //populate a matrix cell for each well and stage record
                for (let stageNumber = this.stageMin; stageNumber <= this.stageMax; stageNumber++) {
                    const record = stageSummary.find(stage => stage.wellNumber == well.index && stage.stageNumber == stageNumber);
                    let step;
                    if (record) {
                        let value = record?.value ? record.value : 0;
                        //edge case handling - min and max values are the same:
                        //if the record value matches the min value, ensure that it it binned into the lowest step
                        if (value == this.dataMinValue == this.dataMaxValue) {
                            step = this.heatmapSteps.reduce(function(prev,curr) { 
                                return prev.value !== EMPTY_MATRIX_CELL_VALUE && prev.value < curr.value ? prev : curr;
                            });
                        } else {
                            step = this.heatmapSteps.findLast(step => step.value <= value || step.value == minStepValue);
                        }
                        step.frequency++;
                        
                        //values are converted to strings, as 0 is automatically defined as an empty cell, but '0' is not
                        value = value.toString();
                        newMatrix[well.index].push(value);
                    } else { //record does not exist, so set to empty cell value and count it
                        step = this.heatmapSteps.find(step => step.value == EMPTY_MATRIX_CELL_VALUE);
                        step.frequency++;
                        newMatrix[well.index].push(EMPTY_MATRIX_CELL_VALUE);
                    }

                    if (step.frequency > this.maxFrequency) {
                        this.maxFrequency = step.frequency;
                    }
                }
            });

            this.dataMatrix = newMatrix.filter(Boolean); //remove any empty indexes from array

            const heatmapOptions = {
                columns: (this.stageMax - this.stageMin) + 1, //add extra column to display full stage range
                rows: this.wellData.length,
                start: {
                    x: this.stageMin - RECT_GAP, //add left side spacing for heat map start
                    y: 0
                },
                end: {
                    x: this.stageMax + RECT_GAP,//add right side spacing for heat map end
                    y: this.wellData.length
                },
                step: {
                    x: this.heatMapConfig.stageAxisStep,
                    y: this.heatMapConfig.wellAxisStep
                },
                dataOrder: 'rows'
            };
            const axisX = this.chart.getDefaultAxisX();

            const lut = this.valueLookupTable; //copy to a valid scope to use with the heatmap
            const heatmapSeries = this.chart.addHeatmapGridSeries(heatmapOptions)
                .setPixelInterpolationMode('disabled') //cells do not interpolate colors between them
                .invalidateIntensityValues(this.dataMatrix) //add matrix data to the heatmap
                //configure auto cursor text display
                .setCursorResultTableFormatter((builder, series, dataPoint) => builder
                    .addRow('Well Number:', '', (dataPoint.y - RECT_GAP).toString()) //adjust y value to Well Number
                    .addRow('Stage Number:', '', dataPoint.x.toString())
                    .addRow('Value:', '', dataPoint.intensity.toString())
                )
                //attach color value lookup table to the heatmap
                .setFillStyle(new PalettedFill({
                    lookUpProperty: 'value',
                    lut
                }));

            if (this.showLegend) {
                this.createLegendChart();
            }

            this.createFrequencyChart();
            this.$nextTick(() => {
                this.resizeLayout();
            });
        },
        convertDataJsonToCSV(jsonFile) {
            let headerRow = '';
            let resultString = '';

            if (this.wellData.length > 0) {
                headerRow += 'WellName,WellIndex,StageNumber,Timestamp,Tagname, Value\n';
            }
            this.wellData.forEach(well => {   
                const stages = this.data.find(dataGroup => 
                    Object.values(dataGroup).length > 0 && Object.values(dataGroup)[0].wellNumber == well.index);
                Object.values(stages).forEach(stage => {
                    resultString += `${well.wellName},${well.index},${stage.stageNumber},${stage.timestamp},${stage.tagName},${stage.value}\n`;
                });
            });
            return headerRow + resultString;
        },
        exportChartData(fileType='csv') {
            let blob = null;
            if (fileType == 'csv') {
                blob = new Blob([this.convertDataJsonToCSV()], {type: 'text/json'});
            } else {
                const jsonResult = {};
                this.wellData.forEach(well => {
                    jsonResult[well.wellName] = this.data.find(dataGroup => 
                        Object.values(dataGroup).length > 0 && Object.values(dataGroup)[0].wellNumber == well.index);
                });
                blob = new Blob([JSON.stringify(jsonResult)], {type: 'text/json'});
            }        
            //create a link element, initiate download with a click event, then remove the link
            const downloadLink = document.createElement('a');
            downloadLink.download = 'AnalysisHeatmap-' + moment().format('YYYY-MM-DD-HH:mm:ss') + '.' + fileType;
            downloadLink.href = window.URL.createObjectURL(blob);
            downloadLink.dataset.downloadurl = ['text/json', downloadLink.download, downloadLink.href].join(':');

            const event = new MouseEvent('click', {
                view: window,
                bubbles: true,
                cancelable: true
            });
            downloadLink.dispatchEvent(event);
            downloadLink.remove();
        }
    },
    watch: {
        height: {
            handler(newVal, oldVal) {
                this.resizeLayout();
            }
        },
        data: {
            handler(newVal, oldVal) {
                //the extreme values need to be determined by the new dataset before filtering can properly occur
                this.filterExtremeValues = false; 
                this.makeHeatmapFromData(newVal);
                //set thresholds to the extreme range of the new dataset
                this.highDataThreshold = this.dataMaxValue;
                this.lowDataThreshold = this.dataMinValue;
            }       
        },
        showLegend: {
            handler(newVal, oldVal) {
                if (newVal !== this.propShowLegend) {
                    this.$emit('showLegendChanged',newVal);
                }
                if (this.chart) {
                //to show/hide the legendbar chart, the chart dashboard needs to be recreated
                //as some options must be set during chart creation
                    this.clearChart();
                    this.$nextTick(() => {
                        this.createChart();
                        this.makeHeatmapFromData(this.data);
                    });
                }
            }
        },
        propShowLegend: {
            handler(newVal, oldVal) {
                this.showLegend = newVal;
            }
        }
    },
    beforeMount() {
        // Generate random ID to us as the containerId for the chart and the target div id
        this.chartId = Math.trunc(Math.random() * 1000000);
    },
    mounted() {
    },
    beforeDestroy() {
        // "dispose" should be called when the component is unmounted to free all the resources used by the chart
        this.chart.dispose();
    }
};
</script>

<style scoped>
    .fill {
        height: 100%;
    }
    .show-clicker-finger {
        cursor: pointer;
    }
</style>

