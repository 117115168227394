<template>
    <div id="TableBase">
        <slot name="breadcrumbs">
            <iws-breadcrumbs style="margin: 25px 0px 5px"
                :path="breadCrumbPath"
            />
        </slot>

        <div class="page-title">
            <slot name="title">
                {{ title }}
            </slot>
        </div>

        <div class="w-100 d-flex justify-content-between align-items-baseline flex-wrap" id="control-row">
            <div class="d-flex align-items-baseline">
                <div id="search-bar-container">
                    <iws-search :value.sync="searchFilter" :disabled="busy" @input="filtering()"/>
                </div>

                <button id="filters-button" :disabled="busy" class="btn btn-outline-light" @click="$emit('filter')">
                    <i class="fas fa-filter" /> Filters
                </button>
                <slot name="button-extensions">
                </slot>
            </div>
            <div id="right-option" class="d-flex align-items-baseline">
                <slot name="right-option"></slot>
            </div>
        </div>

        <div v-if="filtersAreActive" class="w-100 user-select-none" id="filter-row">
            <div id="filters-badges-container">
                <slot name="active-filters">
                    <template v-for="list in filters">
                        <div v-for="(_value, index) in list" class="badge badge-outline m-0">
                            {{ _value }} <i v-if="!busy" class="fas fa-times clickable" @click="removeFromFilter(list, index)"/>
                        </div>
                    </template>
                </slot>
            </div>

            <button v-if="canClearAllFilters" id="clear-filters-button" class="btn btn-outline primary-form" @click="clearFilters">
                <i class="fas fa-times" /> Clear Filters
            </button>
        </div>

        <slot name="content">
            <iws-table
                :busy="busy"
                :items="items"
                :filter="filter"
                :sortAsc="sortAsc"
                :columns="columns" 
                :sortByCol="sortByCol"
            />
        </slot>
    </div>
</template>

<script>
import iwsTableVue from './Common/iwsTable.vue';
import GlobalFunctions from '../GlobalFunctions.js';
const { isNullOrEmpty } = GlobalFunctions;

export default {
    props: {
        ...iwsTableVue.props,
        title: {
            type: String,
            default: 'Page Title'
        },
        filters: {
            type: [Array, Object],
            default: () => {
                return [];
            }
        },
        quickFilters: {
            type: [Array, Object],
            required: false,
            default: () => {
                return [];
            },
        },
        items: [Array, Object],
        filter: null,
        canClearAllFilters: {
            type: Boolean,
            default: true
        },
        busy: {
            type: Boolean,
            default: false
        },
        // used to override the filtersActive state
        showFilters: {
            type: Boolean,
            default: false
        },
        breadCrumbPath: {
            type: [Array, Object],
            required: false,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            searchFilter: null
        }
    },
    computed: {
        filtersAreActive() {
            return (!!Object.keys(this.filters).find(key => !isNullOrEmpty(this.filters[key])) || this.showFilters);
        }
    },
    methods: {
        filtering() {
            this.searchFilter = this.searchFilter ? this.searchFilter.toLowerCase() : null;
            this.$emit('update:filter', this.searchFilter);
        },
        clearFilters() {
            for (const _key in this.filters)
                this.filters[_key] = [];
            this.$emit('update:filters', this.filters);
        },
        removeFromFilter(list, index) {
            if (!isNullOrEmpty(list) && index >= 0 && index < list.length)
                list.splice(index, 1);
            this.$emit('update:filters', this.filters);
        }
    }
}
</script>

<style>
    /* 
        Since both this component and FilterModal display the same filter badges, we have these css classes in an unscoped style
        But we want to be very specific about what we target to avoid conflicting with other components
    */
    .filter-container .badge-outline,
    #TableBase .badge-outline {
        color: var(--primary-text-color) !important;

        box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
        border: #9FAABF 1px solid !important;
        border-radius: 12px;

        margin: 10px 5px 5px 0px;
        padding: 4px 8px;
    }
    .filter-container .badge-outline i,
    #TableBase .badge-outline i {
        margin-left: 5px;
    }
    #TableBase .custom-badge {
        gap: 6px;
        height:26px;
        display: flex;
        background: #242A30;
        border: 1px solid #626770;
        border-radius: 30px;
        align-items: center;
        padding: 4px 12px;
    }
    #TableBase .filter-count {
        background: #4E5F6D;
        border-radius: 50px;
        height: 16px;
        font-size: 12px;
        text-align: center;
        font-weight: 400;
        min-width: 24px;
        padding: 0 4px;
    }
</style>
<style scoped>
    @media screen and (max-width: 600px) {
        #right-option {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
    #control-row {
        margin: 16px 0px 12px;
    }
    #search-bar-container {
        width: 300px;
        max-width: calc(100vw - 30px - 108px);
    }
    #control-row>div, #control-row>button {
        display: inline-block;
    }
    #filters-button {
        margin: -5px 0px 0px 10px;
    }
    #filters-badges-container {
        gap: 8px;
        flex-wrap: wrap;
        margin-bottom: 20px;
        display: flex !important;
        width: 100% !important;
    }
    #new-gun-button, #clear-filters-button {
        width: 155px;
        float: right;
    }
    #filter-row {
        position: relative;
    }
    #clear-filters-button {
        position: absolute;
        top: 0px;
        right: 0px;
    }
    .fas {
        margin-right: 5px;
    }

    hr {
        background: #7B8A98;
    }
</style>
