<template>
	<b-modal 
        ref="my-modal" 
        size="xl"
        hide-header-close
        header-class="border-bottom-0 justify-content-center pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg"
    >
        <template #modal-header>
            <div class="font-large">Chart Export</div>
        </template>
        <template #default>
            <b-card
                title="Active tags"
                class="inner-bg round-top border-bottom-0"
                title-tag="h5"
            >
                <div class="d-flex mt-2">
                <div class="d-flex justify-content-center w-100">
                    <checkbox-list    
                        class="w-50 mr-1"       
                        :enableSelectAllOption="true"
                        :enableSearch="true"
                        label="Frac tags"
                        :valueKey="'priorityName'"
                        :outputKey="'name'"
                        height="200"
                        :options="fracTags"
                        v-bind:selectedOptions="selectedFracTags"
                        v-on:update:selectedOptions="selectedFracTags = $event"
                        >
                    </checkbox-list>
                    <checkbox-list     
                        class="w-50 ml-1"             
                        :enableSearch="true"
                        :enableSelectAllOption="true"
                        label="Wireline tags"
                        :valueKey="'priorityName'"
                        :outputKey="'name'"
                        height="200"
                        :options="wirelineTags"
                        v-bind:selectedOptions="selectedWirelineTags"
                        v-on:update:selectedOptions="selectedWirelineTags = $event"
                        >
                    </checkbox-list>
                </div>
            </div>
            </b-card>
            <b-card
                :title="'Export between' "
                class="inner-bg round-bottom border-top-0 border-bottom-0"
                body-class="pt-0"
                title-tag="h5"
            >
                <div class="px-2 mb-2">
                    <div class="row">
                        <select v-model="timeType" class="mx-1  form-control-sm">
                            <option value="time">Times:</option>
                            <option value="stage">Stages:</option>
                        </select>
                            <select v-if="timeType==='stage'" v-model="stageSelect.stageType" class="form-control-sm mx-1">
                                <option value="frac" selected>Frac</option>
                                <option value="wireline">Wireline</option>
                                <option value="all">All</option>
                            </select>
                            <select v-if="timeType==='stage'" v-model="stageSelect.wellIndex" class="mx-1 form-control-sm">
                                <option v-for="well in wells" :value="well.index" :key="well.index" >
                                    {{ well.name }} 
                                </option>
                            </select>
                    </div>
                </div>
                <div v-if="timeType==='time'" class="px-2">
                    <div class="row">
                        <div class="col-2">
                            Start Date
                        </div>
                        <div class="col-4 ">
                            <div class="col-12">
                                <div class=" d-flex flex-shrink-1 pl-1 justify-content-end">
                                    <input type="datetime-local" :value="displayMinDateLimited" 
                                    :ref="'minDateInput' + _uid"
                                    @change="updateSelectedDate('startTime', $event)"
                                    step="1" placeholder="yyyy-mm-dd hh:mm:ss"
                                        class="form-control-sm">
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            End Date
                        </div>
                        <div class="col-4">
                            <div class="col-12">
                                <div class="d-flex flex-shrink-1 pl-1 justify-content-end">
                                    <input type="datetime-local" 
                                    :ref="'maxDateInput' + _uid"
                                    :value="displayMaxDateLimited" 
                                    @change="updateSelectedDate('endTime', $event)" 
                                    step="1" placeholder="yyyy-mm-dd hh:mm:ss"
                                        class="form-control-sm">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                 <div v-else-if="timeType==='stage'" class="px-2">
                    <div class="row">
                        <div class="col-2">
                            Start Stage
                        </div>
                        <div class="col-4 ">
                            <div class="col-12">
                                <div class=" d-flex flex-shrink-1 pl-1 justify-content-end">
                                    <select class="form-control-sm" v-model="stageSelect.startStageNumber" @change="validateExportTime(selectedStageStartTime, selectedStageEndTime)">
                                        <option v-for="(stage, index) in selectedWellStages" :disabled="stage.startTime == null" :key="index" :value="stage.stageNumber">{{`${stage.stageNumber} - ${stage.startTime || getPlaceholderTime(stage, 'start')}`}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-2">
                            End Stage
                        </div>
                        <div class="col-4">
                            <div class="col-12">
                                <div class="d-flex flex-shrink-1 pl-1 justify-content-end">
                                    <select class="form-control-sm" v-model="stageSelect.endStageNumber" @change="validateExportTime(selectedStageStartTime, selectedStageEndTime)">
                                        <option v-for="(stage, index) in selectedWellStages" :disabled="stage.endTime == null" :key="index" :value="stage.stageNumber">{{`${stage.stageNumber} - ${stage.endTime || getPlaceholderTime(stage, 'end')}`}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
            <b-card
                title="Export settings"
                class="mb-2 inner-bg round-bottom border-top-0"
                body-class="pt-0"
                title-tag="h5"
            >
                <div class="px-2">
                    <div class="row">
                        <div class="col-3">
                            File type
                        </div>
                        <div class="col-3">
                            <div class="col-10">
                                <div class="flex-shrink-1 justify-content-center">
                                    <select class="form-control form-control-sm" v-model="type">
                                        <option :value="'json'">JSON</option>
                                        <option :value="'csv'">CSV</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-3">
                            Job time
                        </div>
                        <div class="col-3">
                            <div class="col-10">
                                <div class="flex-shrink-1 justify-content-center">
                                    <select class="form-control form-control-sm" v-model="timezone">
                                        <option :value="'local-job'">Local job time</option>
                                        <option :value="'utc'">UTC</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-3">
                            Time format
                        </div>
                        <div class="col-3">
                            <div class="col-10">
                                <div class="flex-shrink-1 justify-content-center">
                                    <select class="form-control form-control-sm" v-model="timeFormat">
                                        <option :value="'unix'">Unix</option>
                                        <option :value="'db-datetime'">YYYY-MM-DD HH:mm:ss</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </b-card>
            <div class="row">
                <div class="col-2">
                    <div class="pr-0 mb-2" style="max-width:200px;" id="advancedSettings" @click="showAdvancedSettings=!showAdvancedSettings">
                        Advanced Options <i class="fas mx-1" :class="{'fa-caret-up': showAdvancedSettings, 'fa-caret-down': !showAdvancedSettings}" >
                        </i>
                    </div>
                </div>
            </div>
            <div>
                <transition name="slide">
                    <div v-show="showAdvancedSettings">
                        <b-card class="inner-bg">
                            <div class="px-2">
                                <div class="row">
                                    <div class="col-3">
                                        Aggregate Interval
                                    </div>
                                    <div class="col-3">
                                        <div class="col-10">
                                            <div class="flex-shrink-1 justify-content-center">
                                                <select class="form-control form-control-sm" v-model="aggregateInterval">
                                                    <option v-for="(interval, key) in aggregateValues" v-bind:key="key" :value="interval">{{key}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-3">
                                        Paging Window
                                    </div>
                                    <div class="col-3 d-flex">
                                        <div class="col-5">
                                            <div class="flex-shrink-1 justify-content-center">
                                                <input type="number" min="1" max="100" v-model="pagingWindow.value" class="form-control form-control-sm">
                                            </div>
                                        </div>
                                        <div class="col-7">
                                            <div class="flex-shrink-1 justify-content-center">
                                                <select class="form-control form-control-sm" v-model="pagingWindow.interval">
                                                    <option v-for="(interval, key) in pagingValues" v-bind:key="key" :value="key">{{interval}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                    </div>
                </transition>
            </div>
        </template>
        <template #modal-footer>
            <div class="d-flex w-100">
                <div class="flex-grow-1"></div>
                <button type="button" class="btn btn-secondary grey-button" @click="()=>changeModalVisibility(false)" >Close</button>
                <div class="px-2"></div>
                <div id="exportButtonContainer">
                    <button type="button" id="exportButton" class="btn btn-success green-button" @click.prevent="onExportPressed" :disabled="isFetching || isExportIntervalValid || noTagsSelected">
                        <div class="d-flex">
                            <div class="col-4">
                                <span v-if="isFetching" class="spinner-border spinner-border-sm pr-2" role="status" aria-hidden="true"></span>
                            </div>
                            <div>
                                Export
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <b-popover target="exportButton" placement="top" :show.sync="isExportIntervalValid">
                <span style="color:white;">Start time must be less than end time</span>
            </b-popover>
            <b-popover v-if="noTagsSelected" target="exportButtonContainer" placement="top" triggers="hover">
                    <span style="color:white;">At least one tag must be selected for export</span>
                </b-popover>
        </template>
    </b-modal>
</template>

<script>
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import GlobalFunctions from '../GlobalFunctions';
import moment from 'moment';

export default {
    mounted() {
        this.tags = this.tagsArray? this.tagsArray.map((item)=>{
            return item.name;
        }) : [];
        this.selectedTags = this.tagsArray.filter(tag=>tag.isActive).map(tag=>tag.name); 
    },
    props: ['minDate', 'maxDate', 'tagsArray', 'jobNumber', 'jobHourOffset', 'jobStart', 'jobStartUTC', 'rightSideMsOffset','stageTimeData', 'wells', 'isFetching'],
    methods: {	
        changeModalVisibility(status) {
            this.userSetInterval = false;
            const self = this;
            self.isModalVisible = status;
            if (!self.isModalVisible) {
                this.$emit('closeModal');
            }
            self.isModalVisible ? this.$refs['my-modal'].show(): this.$refs['my-modal'].hide();
        },

        async onExportPressed() {
            this.$emit('dataFetchStart');
            const self = this;
            const currentTimeStamp = moment().utc().valueOf();
            const timezoneString = this.timezone == 'local-job'? 'local_timezone' : 'utc_timezone';
            const exportFileName = `chart_export_${this.jobNumber}_${timezoneString}_${currentTimeStamp}.${this.type}`;
            const url = `/timeSeries/file-export/${this.jobNumber}`;

            //get start and end timestamps set in the utc timezone
            let startTime, endTime;
            if (this.timeType == 'time') {
                startTime = moment.utc(this.displayMinDate).add({'hours': -1 * this.jobHourOffset}).valueOf();
                endTime = moment.utc(this.displayMaxDate).add({'hours': -1 * this.jobHourOffset}).valueOf();
            } else if (this.timeType == 'stage') {
                startTime = moment.utc(this.selectedStageStartTime).add({'hours': -1 * this.jobHourOffset}).valueOf();
                endTime = moment.utc(this.selectedStageEndTime).add({'hours': -1 * this.jobHourOffset}).valueOf();
            }

            const selectedTagNames = this.selectedTags.map(tag => tag.name); // array of tag names, without priority names

            const data = {
                '_token': GlobalFunctions.getCSRFToken(),
                tags: selectedTagNames,
                isLocalTime: this.timezone == 'local-job'? true : false,
                isUnixFormat: this.timeFormat == 'unix'? true : false,
                fileType: this.type,
                startTime: startTime,
                endTime: endTime,
                aggregateInterval: this.aggregateInterval,
                pagingWindow: this.pagingWindow.interval * this.pagingWindow.value,
            };


            axios({
                url: url,
                method: 'GET',
                params: {
                    ...data
                },
                responseType: 'blob',
                validateStatus: (status) => {
                    if(status===200) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }).catch(error => {
                self.$emit('dataFetchComplete');
                setTimeout(() => { 
                    alert('There was an error with the export, try decreasing the time frame or number of tags.');
                }, 1000);
            }).then((response) => {
                if(response?.headers?.error) {
                    alert(response?.headers?.error);
                    self.$emit('dataFetchComplete');
                    return;
                }
                if(response?.data) {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', exportFileName);
                    document.body.appendChild(link);
                    link.click();
                }
                self.$emit('dataFetchComplete');
            });
        },
        updateSelectedDate(valueChanged, event) {
            if (valueChanged == 'startTime') {
                this.displayMinDateLimited = event.target.value;
            }
            else if (valueChanged == 'endTime') {
                this.displayMaxDateLimited = event.target.value; 
            }
            this.userSetInterval=true;
            this.validateExportTime(this.displayMinDateLimited, this.displayMaxDateLimited);
        },
        validateExportTime(startTime, endTime) {
            if (!startTime || !endTime) {
                this.isExportIntervalValid = false;
            }

            if (startTime >= endTime) {
                this.isExportIntervalValid = true;
            } else {
                this.isExportIntervalValid = false;
            }
        },
        getPlaceholderTime: function(stage, type) {
            const values = this.stageTimeData[this.stageSelect.stageType].wellStageActivityData[this.stageSelect.wellIndex];
            if(values) {
                if (type == 'end' && stage.stageNumber == Math.max(...Object.values(values).map(o => o.stageNumber))) {
                    return this.getCurrentTime();
                } else {
                    return 'Unavailable';
                }  
            }   
        },
        getCurrentTime: function() {
            return moment().utc().add({hours: this.jobHourOffset}).format('YYYY-MM-DD HH:mm:ss');
        }
    },
    computed: {
        displayMinDateLimited: { //guards user from trying to export data from before the job start
            get: function() {
                return moment(this.displayMinDate, 'YYYY-MM-DD HH:mm:ss' ).format('YYYY-MM-DDTHH:mm:ss');
            },
            set: function(outputVal) {
                const newValue = moment(outputVal, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                if (Date.parse(newValue) < Date.parse(this.jobStartUTC) || newValue == null) {
                    this.displayMinDate = this.jobStart; 
                    this.$refs['minDateInput' + this._uid].value = moment(this.displayMinDate, 'YYYY-MM-DD HH:mm:ss' ).format('YYYY-MM-DDTHH:mm:ss');   
                    alert('Please select a date after the job start ' + this.jobStart.format('YYYY-MM-DD hh:mm:ss A'));              
                } else {
                    this.displayMinDate = newValue;
                }
            }
        },
        displayMaxDateLimited: { //guards user from trying to export data that doesn't exist yet
            get: function() {
                return moment(this.displayMaxDate, 'YYYY-MM-DD HH:mm:ss' ).format('YYYY-MM-DDTHH:mm:ss');
            },
            set: function(outputVal) {
                const newValue = moment(outputVal, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD HH:mm:ss');
                if (Date.parse(newValue) > Date.parse(this.maxLimit) || newValue == null) {
                    this.displayMaxDate = this.maxLimit; 
                    this.$refs['maxDateInput' + this._uid].value = moment(this.displayMaxDate, 'YYYY-MM-DD HH:mm:ss' ).format('YYYY-MM-DDTHH:mm:ss');  
                    alert('Please select a date before the max limit ' + moment(this.maxLimit).format('YYYY-MM-DD hh:mm:ss A'));     
                } else {
                    this.displayMaxDate = newValue;
                }
            }
        },
        fracTags() {
            return this.tagsArray.filter((tag)=>(tag.name.startsWith('frac_') || tag.name.startsWith('wellhead_')  || tag.name.startsWith('fracStage')  || tag.name.startsWith('offset_')));
        },
        wirelineTags() {
            return this.tagsArray.filter((tag)=>(tag.name.startsWith('wireline_') || tag.name.startsWith('pumpdown_')));
        },
        selectedFracTags: {
            get: function () {
                return this.selectedTags.filter((tag)=>{
                    if(tag.name) {
                        return tag.name.startsWith('frac_') || tag.name.startsWith('wellhead_')  || tag.name.startsWith('fracStage') || tag.name.startsWith('offset_');
                    }
                });
            },
            set: function (newFracTags) {
                this.selectedTags = [...this.selectedWirelineTags, ...newFracTags];
            }
        },
        selectedWirelineTags: {
            get: function () {
                return this.selectedTags.filter((tag)=>{
                    if(tag.name) {
                        return tag.name.startsWith('wireline_') || tag.name.startsWith('pumpdown_');
                    }
                });
            },
            set: function (newWirelineTags) {
                this.selectedTags = [...this.selectedFracTags, ...newWirelineTags];
            }
        },
        selectedStageStartTime() {
            if (this.stageTimeData && this.stageTimeData[this.stageSelect.stageType]) {
                const values = this.stageTimeData[this.stageSelect.stageType].wellStageActivityData[this.stageSelect.wellIndex];
                if(values) {
                    const startStage =  Object.values(values)
                        .find(stage => stage.stageNumber == this.stageSelect.startStageNumber);
                    return moment(startStage.startTime, ['YYYY-MM-DD HH:mm:ss','YYYY-MM-DDTHH:mm:ss']).format('YYYY-MM-DDTHH:mm:ss');             
                }
            }

            return '';
        },
        selectedStageEndTime() {
            if (this.stageTimeData && this.stageTimeData[this.stageSelect.stageType]) {
                const values = this.stageTimeData[this.stageSelect.stageType].wellStageActivityData[this.stageSelect.wellIndex];
                if(values) {
                    const endStage =  Object.values(values)
                        .find(stage => stage.stageNumber == this.stageSelect.endStageNumber);
                    if (endStage.endTime) {
                        return moment(endStage.endTime, ['YYYY-MM-DD HH:mm:ss','YYYY-MM-DDTHH:mm:ss']).format('YYYY-MM-DDTHH:mm:ss');    
                    } else if (!endStage?.endTime && endStage.stageNumber == Math.max(...Object.values(values).map(o => o.stageNumber))) {
                        return this.getCurrentTime();
                    } else {
                        return '';
                    }               
                }    
            }

            return '';
        },
        selectedWellStages() {
            if (this.stageTimeData && this.stageTimeData[this.stageSelect.stageType]) {
                const values = this.stageTimeData[this.stageSelect.stageType].wellStageActivityData[this.stageSelect.wellIndex];
                if(values) {
                    return Object.values(values);
                }
            }

            return [];         
        },
        noTagsSelected() {
            return this.selectedTags.length == 0;
        }
    },
    watch: {
        tagsArray: function (newVal) {
            this.tags = newVal.map((item)=>{
                return item.name;
            });
        },
        minDate: function (newVal) {
            //only update display while modal is not showing
            if (!this.isModalVisible)
            {
                if (newVal && !this.userSetInterval) {
                    this.displayMinDate = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
                }
            }   
        },
        maxDate: function (newVal) {
            //only update display while modal is not showing
            if (!this.isModalVisible)
            {
                if (newVal && !this.userSetInterval) {
                    this.displayMaxDate = moment(newVal).format('YYYY-MM-DD HH:mm:ss');
                }
                this.maxLimit = moment(newVal).add({ms: this.rightSideMsOffset}).format('YYYY-MM-DD HH:mm:ss');
            }   
        },
        selectedWellStages: function(newVal, oldVal) {
            //on first load or after a well/stage type change set selection to the latest stage for that well 
            if (newVal) {
                const lastStage = newVal.slice(-1)[0];
                if(lastStage) {
                    this.$set(this.stageSelect,'startStageNumber',lastStage.stageNumber);
                    this.$set(this.stageSelect,'endStageNumber',lastStage.stageNumber);
                }
            }
        }
    },
    data() {
        return {
            isExportIntervalValid: false,
            timeType: 'time',
            stageSelect: {
                startTime: null,
                endTime: null,
                startStageNumber: null,
                endStageNumber: null,
                stageType: 'frac',
                wellIndex: 0
            },
            selectedTags: [],
            show: false,
            type: 'csv',
            aggregateInterval: 'PT1S',
            pagingWindow: {//amount of time interval, interval time in seconds
                interval: 86400,
                value: 1
            },
            timezone: 'local-job',
            timeFormat: 'db-datetime', 
            tags: [],
            maxLimit: null,
            userSetInterval: false,
            displayMinDate: null,
            displayMaxDate: null,
            showAdvancedSettings: false,
            aggregateValues: {
                Second: 'PT1S',
                Minute: 'PT1M',
                Hour: 'PT1H',
                Day: 'PT1D'
            },
            pagingValues: {//seconds to time interval for modal select
                60: 'Minute(s)',
                3600: 'Hour(s)',
                86400: 'Day(s)'
            },
            isModalVisible: false
        };
    }
};
</script>

<style>
	.main-container {
        border-dark-radius: 20px
	}
    .round-top {
        border-radius: 5px 5px  0   0;
    }
    .text-ellipsis {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .round-bottom {
        border-radius:  0   0 5px 5px ;
    }
	.div-border-dark {
		border-dark-width: 1px !important;
		border-dark-color: #000 !important;
	}
    .slide-enter-active {
    transition-duration: 0.3s;
    transition-timing-function: ease-in; 
    }

    .slide-leave-active {
    transition-duration: 0.3s;
    transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

    }
    .slide-enter-to, .slide-leave {
    max-height: 100px;
    overflow: hidden;
    }

    .slide-enter, .slide-leave-to {
    overflow: hidden;
    max-height: 0;
    }
</style>
