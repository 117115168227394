<template>
    <div class="row" style="font-size:small; line-height: 0.5;">
        <div class="col-12">
            <whiteboard-message-component v-for="(message, index) in messages" :message="message" :index="index" :key="index"></whiteboard-message-component>
        </div>
        <div class="col-6" v-if="sandWeights.length > 0">
            <bar-chart
                :height="555"
                :chart-data="sandChartData"
                :options="chartOptions('Proppant - Total: ' + formatNumber(weightsTotal(sandWeights), 0) + ' lbs (' + sandWeightAllPercent + '%)')"
                >

            </bar-chart>
            <table class="table table-striped table-hover" :class="{'table-dark': this.$root.darkMode}">
                <tbody>
                    <tr v-for="(sandWeight, index) in sandWeights" v-bind:key="index">
                        <td style="width: 10%;">{{index + 1}}</td>
                        <td class="text-left">{{formatNumber(sandWeight, 0)}} lbs</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="col-6" v-if="edgeLevelAst != null && edgeLevelFreshOsmosis != null">
            <bar-chart 
                :height="555"
                :chart-data="waterChartData"
                :options="chartOptions('Water Storage')"
                >
            </bar-chart>
            <table class="table table-striped table-hover" :class="{'table-dark': this.$root.darkMode}">
                <tbody>
                    <tr>
                        <td class="text-right" style="width: 50%;">Usable AST</td>
                        <td class="text-left">{{formatNumber(edgeLevelAst, 0)}} bbls</td>
                    </tr>
                    <tr>
                        <td class="text-right" style="width: 50%;">DI Water</td>
                        <td class="text-left">{{formatNumber(edgeLevelFreshOsmosis, 0)}} bbls</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>    
    import BarChart from '../barChart.js';
    import moment from 'moment';
    export default {
        components: {
            BarChart
        },
        data() {
            return {
                detailedHandshakeTable: false
            }
        },
        computed: {
            sandChartData: function() {
                let labels = [];
                for(let i = 1; i <= this.sandWeights.length; i++) {
                    labels.push(i);
                }

                const tankSize = 330000;
                let weights = [];
                for(let i = 0; i < this.sandWeights.length; i++) {
                    weights.push(this.formatNumber(100 * (this.sandWeights[i] / tankSize),0));
                }

                return {
                    labels: labels,
                    datasets: [
                        {
                            backgroundColor: ["#c2b280", "#c2b280","#c2b280","#c2b280","#c2b280"],
                            data: weights
                        }
                    ]
                }

            },
            sandWeightAllPercent: function() {
                const tankSize = 330000;
                let weightsTotal = 0;
                for(let i = 0; i < this.sandWeights.length; i++) {
                    weightsTotal += this.sandWeights[i];
                }

                return this.formatNumber(100 * (weightsTotal / (tankSize * this.sandWeights.length)), 0);
            },
            waterChartData: function() {
                return {
                    labels: ["Usable AST", "DI Water"],
                    datasets: [
                        {
                            backgroundColor: ["#0077be", "#0077be"],
                            data: [this.formatNumber(this.edgeLevelPercAst,0), this.formatNumber(this.edgeLevelPercFreshOsmosis,0)]
                        }
                    ]
                }
            }
        },
        methods: {
            willShow: function(weights) {
                return weights.length > 0;
            },
            weightsTotal: function(weights) {
                return weights && weights.length > 0 ?  weights.reduce((acc, value) => acc + value) : 0;
            },
            waterWeightsTotal: function(weights) {
                //There are 26 tanks on location, each tank has 100 bbls that is unusable. 
                //The calculation should be as follows: 
                //Total Usable Water Storage = (26 x (avg(tank1-8))-(26 x 100)
                return weights && weights.length > 0 ?  (26 * (weights.reduce((acc, value) => acc + value)/weights.length)) - (26 * 100) : 0;
            },
            formatNumber: function(value, precision) {
                return value.toLocaleString(undefined,{'minimumFractionDigits':precision,'maximumFractionDigits':precision})
            },
            utcToJobLocalTime(timestamp, formatString)
            {
                if(timestamp)
                {
                    return moment(timestamp).utc().add({hours: this.job.hourOffset}).format(formatString);
                }
                    
                return "";
            },
            chartOptions: function(title) {
                return {
                    animation: false,
                    title: {
                        display: true,
                        text: title,
                        fontSize: 14,
                        fontColor: 'white',
                        fontStyle: '',
                        padding: 20
                    },
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                fontColor: "white"
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                min: 0,
                                max: 100,
                                fontColor: "white",
                                callback: function(value, index, values) {
                                    return value + '%';
                                }
                            }
                        }]
                    }
                }
            }
        },
        props:[
            'completedStages',
            'messages',
            'handshakes',
            'wells', 
            'sandWeights', 
            'pumpdownWeights', 
            'waterStorageWeights', 
            'impoundmentWeights', 
            'gasBustersWeights', 
            'magnumASTWeights', 
            'edgeLevelAst', 
            'edgeLevelFreshOsmosis',
            'edgeLevelPercAst', 
            'edgeLevelPercFreshOsmosis', 
            'edgeLevelWasteOsmosis', 
            'edgeLevelPumpdownWeights',
            'job'
            ]
    }

</script>

<style scoped>
.fixed-header table {
    display: flex;
    flex-flow: column;
    width: 100%;
}

.fixed-header thead {
    flex: 0 0 auto;
}

.fixed-header tbody {
    flex: 1 1 auto;
    display: block;
    overflow-y: auto;
    overflow-x: hidden;
    height: 300px;
}

.fixed-header tr {
    width: 100%;
    display: table;
    table-layout: fixed;
}
</style>