var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "iws-switch",
      class: { "form-input-spacing": _vm.formSpacing !== false },
      attrs: { id: _vm.id },
    },
    [
      _c(
        "label",
        {
          staticClass: "switch",
          class: `${_vm.size} ${_vm.checked ? "active" : ""} ${
            _vm.disabled ? "disabled" : ""
          }`,
          on: {
            click: function ($event) {
              _vm.disabled ? _vm.$emit("disabledClick") : null
            },
          },
        },
        [
          _c("input", {
            attrs: {
              disabled: _vm.disabled,
              type: "checkbox",
              id: "switchComponent",
            },
            domProps: { checked: _vm.checked },
            on: { click: _vm.statusChanged },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "slider round" }),
          _vm._v(" "),
          !!_vm.labels && _vm.labels.length == 2
            ? _c(
                "div",
                {
                  class: { "pull-left": _vm.checked },
                  attrs: { id: "switch-label" },
                },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.value ? _vm.labels[1] : _vm.labels[0]) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.hint
        ? _c("label", { staticClass: "secondary-text-color" }, [
            _vm._v("\n        " + _vm._s(_vm.hint) + "\n    "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }