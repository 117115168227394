<template>
    <div v-bind:style="{ 'height' : height? height+'px': 'auto'}">
        <div id="overlay" class="flex-column" v-if="loading">
            <div class="overlay-center" style="padding-top:100%">Loading...</div>
        </div>
        <div class="flex-column text-center w-100" :ref="'buttons-container' + _uid">
            <div class="awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center">
                <label>Selected Truck:
                    <select class="w-100 h-100 form-control" v-model="selectedTruck" @change="getCurrentWirelineInfo(true)">
                        <option v-for="(wirelineSystem, index) in wirelineSystems" :key="index" :value="wirelineSystem">{{wirelineSystem.name}}</option>
                    </select>
                </label>
            </div>
            <div class="awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center" @click="onViewReportPress"
                style="line-height:0.7;width:100%;height:58px;">
                <span>View Report</span>
            </div>
            <div v-on:click="onCommentPress" class=" mt-1 awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center"
                style="line-height:0.7;width:100%;height:58px;">
                <span>Comment</span>
            </div>
             <div  v-on:click="onToolStringPress" class="mt-1 awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center"
                style="line-height:0.7;width:100%;height:58px;">
                <span>Tool String</span>
            </div>

            <div class="pt-3 ">
                <div v-if="currentWell && currentWell.currentStage" class="awesome btn-text"
                        :class="!gunEnabled ? 'awesome-disabled disable-btn-bg' : 'awesome-shot'"
                        @click="onShotFiredPress" style="height:88px;width:100%;margin-bottom:5px">
                    <p class="h5 mt-4">SHOT FIRED</p>

                </div>
                <div v-if="currentWell && currentWell.currentStage" class="awesome btn-text"
                        :class="!plugEnabled ? 'awesome-disabled disable-btn-bg' : 'awesome-plug green-btn-bg'"
                        @click="onPlugSetPress" style="height:88px;width:100%;">
                    <p class="h5 mt-4">SET PLUG</p>
                </div>

                <div class="pt-3">
                    <div v-on:click="onMissRunPress" class="mt-1 awesome awesome-standard ash-bg btn-text"
                                style="height:42px;font-size:0.8em;width:100%">
                            Confirm<br>
                            Shots Fired
                    </div>
                    <div v-on:click="onConditionPress" class="pt-2 mt-1 awesome awesome-standard ash-bg btn-text" style="height:42px;font-size:1em;width:100%">
                            Condition Run
                    </div>
                    <div v-if="!isDisableMissRun" class="pt-2 mt-1 awesome btn-text awesome-disabled disable-btn-bg" style="height:42px;font-size:1em;width:100%;cursor: default">
                            Miss Run
                    </div>
                </div>
            </div>
        </div>
        <div v-show="logEntries.length>0" class="p-0 pt-2" :style="'overflow-y:auto;' + 'height:' + logsHeight +'px'">
            <log-panel :logEntries="logEntries"/>
        </div>
    </div>
</template>

<style scoped>
    #overlay {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(30,30,30,0.7);
        z-index: 2;
    }
    .overlay-center {
        display: flex !important;
        justify-content: center;
        align-self: center;
    }
    .awesome{
        /*background: #222 url(/images/alert-overlay.png) repeat-x;*/
        display: inline-block;
        padding: 5px 10px 6px;
				color: #fff;
        text-decoration: none;
        font-weight: lighter;
        line-height: 1.2;
        -moz-border-radius: 5px;
        -webkit-border-radius: 5px;
        position: relative;
        cursor: pointer;
        border-color: #5c666e;
    }
    .awesome-plug{
        background-color: #50a369;
        -moz-box-shadow: 0 1px 3px #999;
        -webkit-box-shadow: 0 1px 3px #999;
        text-shadow: 0 -1px 1px #222;
        border-bottom: 1px solid #222;
    }
    .awesome-shot {
        background-color: #a35050;
        -moz-box-shadow: 0 1px 3px #999;
        -webkit-box-shadow: 0 1px 3px #999;
        text-shadow: 0 -1px 1px #222;
        border-bottom: 1px solid #222;
    }
		.pointer-cursor {
			cursor: pointer;
		}
    .awesome-disabled {
        background-color: #5f6962;
        -moz-box-shadow: 0 0px 0px #999;
        -webkit-box-shadow: 0 0px 0px #999;
        text-shadow: 0 -1px 0px #222;
        border-bottom: 0px solid #222;
		}
    .awesome-standard {
        background-color:  #464c52;
        -moz-box-shadow: 0 1px 2px #777;
        -webkit-box-shadow: 0 1px 2px #777;
        text-shadow: 0 -1px 1px #777;
        border-bottom: 1px solid #777;
    }
</style>

<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';
import JSONLocalStorageMixin from "../mixins/JSONLocalStorageMixin";
const ResizeSensor = require('css-element-queries/src/ResizeSensor');

export default {
    inject: ['dashboardData'],
    props: [
        'wirelineWellIndex',
        'jobNumber',
        'customerId',
        'wells',
        'hourOffset',
        'loading',
        'height',
        'item',
        'dashboardId'
    ],
    mixins: [JSONLocalStorageMixin],
    data() {
        return {
            currentStageNumber: 1,
            isDisableMissRun: true,
            buttonsHeight: 0,
            wirelineWell: null,
            truckPlugSet: {},
            gunEnabled: false,
            plugEnabled: false,
            selectedTruck: null,
            savedTruckObject: null,
            componentId: this.item.i,
            selectedTruckDashboardDetails: null,
            isRequestPending: false,
        };
    },
    mounted() {
        if (this.item?.selectedTruckId && this.wirelineSystems) {
            this.selectedTruck = this.wirelineSystems.find(truck => truck.number == this.item?.selectedTruckId);
            this.plugEnabled = true;
            this.gunEnabled = false;
        }

        if (this.item?.plugSet) {
            let plugset = this.item?.plugSet;
            let plugKeys = Object.keys(plugset);
            plugKeys.forEach((wirelineTruck) => {
                if (wirelineTruck === this.selectedTruck.name && Boolean(plugset[wirelineTruck])) {
                    // set plug disables and gun enables if the plug has already been set for the wireline truck
                    this.plugEnabled = false;
                    this.gunEnabled = true;
                }
            });
        }

        if(this?.$refs?.['buttons-container' + this._uid]) {
            const sectionElement = this.$refs['buttons-container' + this._uid];
            const self = this;
            new ResizeSensor(sectionElement, function() {
                self.buttonsHeight = sectionElement.clientHeight;
            });
        }
    },
    computed: {
        wirelineSystemsData() {
            return this.dashboardData._data.wirelineSystemsData;
        },
        wirelineSystems() {
            return this.dashboardData._props.wirelineSystems || [];
        },
        currentWell() {
            return this.wells[this.wirelineWellIndex] ?? 0; //if no valid well, default to first well
        },
        wireline() {
            return this.dashboardData?.wireline ?? null;
        },
        logsHeight() {
            return this.height-this.buttonsHeight;
        },
        logEntries() {
            if (this.wirelineWell) {
                const validEntries = this.dashboardData.logEntries.filter(entry =>
                    entry.wellNumber == this.wirelineWell.index && entry.stageNumber == this.wirelineWell.currentStage);
                return this.sortLogs(validEntries);
            }
            return [];
        }
    },
    watch: {
        wells: {
            deep: true,
            handler(newVal, oldval) {
                this.getCurrentWirelineInfo();
            }
        },
        logEntries: {
            deep: true,
            handler(newVal,oldVal) {
                const plugEntry = newVal.find(entry => entry.type == 'plug');
                if (!this.isRequestPending) {
                    if (plugEntry) {
                        if (!this.truckPlugSet[this.selectedTruck.name]) {
                            // save wireline truck that has plug set
                            this.savePlugSet(true);
                        }
                        this.plugEnabled = false;
                        this.gunEnabled = true;
                    } else {
                        if (this.truckPlugSet && this.truckPlugSet[this.selectedTruck?.name]) {
                            // reset wireline truck plug when at a new stage
                            this.savePlugSet(false);
                        }
                        this.plugEnabled = true;
                        this.gunEnabled = false;
                    }
                }
            }
        },
        wirelineWell: {
            handler(newVal, oldVal) {
                if (newVal == null) {
                    this.plugEnabled = false;
                    this.gunEnabled = false;
                }
            }
        }
    },
    methods: {
        sortLogs(logEntries) {
            //sorts in reverse time order of the latest stage
            if (this.currentWell && this.currentWell?.currentStage) {
                const currentStageNumber = this.currentWell.currentStage;
                const latestStageLogs = logEntries.filter((logEntry) => logEntry.stageNumber === currentStageNumber);
                return latestStageLogs.sort((a, b) => {
                    return b.time.localeCompare(a.time); // reverse chronological order
                });
            }
            return [];
        },
        savePlugSet(boolean) {
            // saves into items prop which wireline truck has a plug set
            this.truckPlugSet[this.selectedTruck.name] = boolean;
            this.$set(this.item, 'plugSet', this.truckPlugSet);
            this.dashboardData.saveDashboardItems(this.dashboardData.selectedDashboard);
        },
        getCurrentWirelineInfo(saveDashboardItems = false) {
            this.wirelineWell = this.wells.find(well =>
                well?.activityData?.service_id == this.selectedTruck?.number
                && well?.activity == 'wireline');

            if (saveDashboardItems && (this.dashboardData.isIwsUser || this.dashboardData.isAdmin || this.dashboardData.isCompanyAdmin) && this.item) {
                if (this.item.selectedTruckId && this.selectedTruck?.number) {
                    this.item.selectedTruckId = this.selectedTruck.number;
                    this.dashboardData.saveDashboardItems(this.dashboardData.selectedDashboard);
                }
            }
        },
        onViewReportPress() {
            if(this.wirelineWellIndex !== -1) {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/' + this.wirelineWellIndex + `/1/${this.dashboardData.currentStage}` ;
            } else {
                window.location.href = '/wireline-op/' + this.jobNumber + '/history/0';
            }
        },
        onCommentPress() {
            this.$root.$emit('toggleWirelineCommentsModal');
        },
        onToolStringPress() {
            this.$root.$emit('toogleToolstringJobConfigModal');
        },
        onMissRunPress() {
            this.$root.$emit('toggleMissRunModal');
        },
        onConditionPress() {
            this.$root.$emit('toggleconditionModal');
        },
        onPlugSetPress() {
            const self = this;
            const datetime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
            const data = this.wirelineSystemsData.find(system => system.number == this.selectedTruck.number);
            if (self.plugEnabled) {
                if(data.depth === undefined) {
                    alert('wireline depth is missing.');
                    return;
                }
                self.plugEnabled = false;
                self.gunEnabled = true;
                const url = '/wireline-op/' + this.jobNumber;
                this.isRequestPending = true;
                $.ajax({
                    url: url,
                    method: "POST",
                    data: {
                        _token: GlobalFunctions.getCSRFToken(),
                        type: "plug",
                        wellIndex: self.wirelineWell.index,
                        jobNumber: self.jobNumber,
                        stageNumber: self.wirelineWell.currentStage,
                        depth: data.depth,
                        depthShift: self.dashboardData.depthShift ?? 0, //default to 0 depthShift if undefined
                        interval: null,

                        datetime: datetime
                    },
                    dataType: 'json'
                }).done(function(data) {
                    if(data.error)
                    {
                        alert(JSON.stringify(data.message));
                        self.plugEnabled = true;
                        self.gunEnabled = false;
                    }
                    self.isRequestPending = false;
                    self.$root.$emit('forceUpdateWirelineHistory');
                });
            }
        },
        onShotFiredPress() {
            const self = this;
            const datetime = moment(Date.now()).format("YYYY-MM-DD HH:mm:ss");
            const data = this.wirelineSystemsData.find(system => system.number == this.selectedTruck.number);

            if(data.depth === undefined) {
                alert('wireline depth is missing.');
                return;
            }
            if (this.gunEnabled) {
                this.gunEnabled = false;

                const url = '/wireline-op/' + this.jobNumber;
                this.isRequestPending = true;
                $.ajax({
                    url: url,
                    method: "POST",
                    data: {
                        _token: GlobalFunctions.getCSRFToken(),
                        type: "shot",
                        wellIndex: self.wirelineWell.index,
                        jobNumber: self.jobNumber,
                        stageNumber: self.wirelineWell.currentStage,
                        depth: data.depth,
                        interval: null,
                        datetime: datetime
                    },
                    dataType: 'json'
                }).done(function(data) {
                    if(data.error)
                    {
                        alert(JSON.stringify(data.message));

                    }
                    self.gunEnabled = true;
                    self.isRequestPending = false;
                    self.$root.$emit('forceUpdateWirelineHistory');
                });
            }
        },
    }
};
</script>
