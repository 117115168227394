<template>
    <!-- NAV BAR LATENCY COMPONENT-->
    <div v-if="nav">
        <li class="nav-item dropdown" ref="latencyNavRef">
            <div class="latency-nav-icon">
                <i class="fas fa-wifi icon"
                :class="latencyNavFormat">
            </i>
            </div>

            <div class="dropdown-menu  bg-light mt-2 px-2 pt-2 pb-1" id="latency-dropdown" :class="isViewOnlyRole ? 'dropdown-menu-right' : 'dropdown-menu-left'">
                <template>
                    <li class="nav-item latency-nav-item text-nowrap border rounded p-1" :class="latestPayloadFormat">
                        <span class="px-2">Latest Payload</span>
                        <span class="px-2" v-if="latestPayload < 60000">{{ latestPayload }} ms</span>
                        <span class="px-2" v-else>>1 min</span>
                    </li>
                    <li class="nav-item latency-nav-item text-nowrap p-1" :class="latencyNavFormat">
                        <span class="px-2" :class="latencyNavFormat">Average Latency</span>
                        <span class="px-2" :class="latencyNavFormat" v-if="overallAverageLatency < 60000">{{(overallAverageLatency/1000).toFixed(1)}} s</span>
                        <span class="px-2" :class="latencyNavFormat" v-else>-</span>
                    </li>
                </template>
            </div>
        </li>
    </div>

    <!-- DASHBOARD LATENCY COMPONENT-->
    <div v-else class="border border-dark rounded p-2" ref="infoBox">
        <div>
            <div style="float:left">
                <b>Latency</b>
            </div>
            <!-- BUTTON FOR LATENCY CONFIG WINDOW, TBD
            <div style="float: right">
                <iws-button
                    prepend-icon="fas fa-cog"
                    type="outline-light"
                    class="primary-text-color"
                    @click=""
                    style="border: none !important; padding-top: 2px; padding-bottom: 2px;"
                />
            </div> -->
            <br/>
            <hr class="hr my-2"/>
        </div>
        <div>
            <div class="border rounded p-2 mb-1" :class="latestPayloadFormat">
                <div style="float:left">Latest Payload</div>
                <div style="float: right" v-if="latestPayload < 60000">{{latestPayload}} ms</div>
                <div style="float: right" v-else>> 1 min</div>
                <br/>
            </div>
            <div v-for="(tag, index) in latencyTagDisplay">
                <hr class="hr m-0" v-if="!!index"/>
                <div class="px-2 py-1 mb-1" :class="tag.format">
                    <div style="float:left">{{tag.name}}</div>
                    <div style="float: right" v-if="tag.averageLatency < 60000">{{(tag.averageLatency/1000).toFixed(1)}} s</div>
                    <div style="float: right" v-else>-</div>
                    <br/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy } = GlobalFunctions;

export default {
    props: {
        latestDataCollection: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        utcDifference: {
            type: [Number],
            default: ()=> 0,
            required: true
        },
        nav: {
            type: [Boolean],
            default: ()=> true,
            required: true
        },
        userRoles: {
            type: [Array],
            required: false
        }
    },

    data() {
        return {
            latencyTagDisplay: [],
            latencyTagAverages: [],
            latencyTags: [],
            tagServiceTypes: ['Frac', 'Wireline'],
            latestPayload: null,
            overallAverageLatency: null,
        };
    },

    watch: {
        'latestDataCollection': {
            handler: function (newValue, oldValue) {

                // update latency tags for this data packet
                let tempLatencyTags = []
                for(let i=0; i< newValue.length; i++){
                    let latencyTagName = this.parseTagName(newValue[i].tagName);
                    let latencyTagShortName = this.parseTagShortName(newValue[i].tagName);
                    let tagServiceType = this.parseTagServiceType(newValue[i].tagName);
                    if (this.tagServiceTypes.includes(tagServiceType)){
                        let latencyTagDelay = this.calculateTagLatency(newValue[i]);
                        const tagObject = tempLatencyTags.find((latencyTag) => latencyTag.name == latencyTagName);
                        if(isFalsy(tagObject)){
                            let newLatencyTag = {
                                name: latencyTagName,
                                shortName: latencyTagShortName,
                                latency: latencyTagDelay,
                            };
                            tempLatencyTags.push(newLatencyTag);
                        } else {
                            tagObject.latency = latencyTagDelay;
                        }
                    }
                }

                //update latest payload
                let tempLatestPayload = null;
                if(tempLatencyTags.length > 0){
                    for(let i=0; i < tempLatencyTags.length; i++){
                        if(isFalsy(tempLatestPayload) || tempLatencyTags[i].latency < tempLatestPayload){
                            tempLatestPayload = tempLatencyTags[i].latency;
                        }
                    }
                }
                this.latestPayload = Math.abs(tempLatestPayload);

                //update average latency data collection
                this.latencyTags = tempLatencyTags;
                for(let i=0; i< this.latencyTags.length; i++){
                    const tagAverageObject = this.latencyTagAverages.find((latencyTagAverage) => latencyTagAverage.name == this.latencyTags[i].name);
                    if(isFalsy(tagAverageObject)){
                        let newAverageLatencyTag = {
                            name: this.latencyTags[i].name,
                            shortName: this.latencyTags[i].shortName,
                            format: null,
                            averageLatency: null,
                            latencyValues: [this.latencyTags[i].latency],
                            latencyCount: 1,
                        };
                        this.latencyTagAverages.push(newAverageLatencyTag);
                    } else {
                        tagAverageObject.latencyValues.push(this.latencyTags[i].latency);
                        tagAverageObject.latencyCount += 1;
                    }
                }
            },
            deep: true
        },
    },

    computed: {
        latestPayloadFormat(){
            if(!isFalsy(this.latestPayload)){
                let payloadFormat = '';
                if(this.latestPayload < 2000){
                    payloadFormat = 'text-success border-success underlay-success';
                } else if (this.latestPayload < 4000){
                    payloadFormat = 'text-warning border-warning underlay-warning';
                } else {
                    payloadFormat = 'text-danger border-danger underlay-danger';
                }
                return payloadFormat;
            }
        },
        latencyNavFormat(){
            let iconFormat = '';
                if(isFalsy(this.overallAverageLatency)){
                    iconFormat = '';
                } else if(this.overallAverageLatency < 2000){
                    iconFormat = 'latency-nav-success';
                } else if (this.overallAverageLatency < 4000){
                    iconFormat = 'latency-nav-warning';
                } else {
                    iconFormat = 'latency-nav-danger';
                }
                return iconFormat;
        },
        isViewOnlyRole(){
            return this.userRoles[0].name === 'viewOnly';
        }
    },

    mounted() {
        setInterval(() => {
            this.updateTagAverages();
        }, 3000);
    },

    methods: {
        parseTagName(rawTagName){
            let parsedTags = rawTagName.split("_");
            let parsedTagName = parsedTags?.[0].charAt(0).toUpperCase() + parsedTags?.[0].slice(1) + " " + parsedTags?.[1];
            return parsedTagName;
        },
        parseTagShortName(rawTagName){
            let parsedTags = rawTagName.split("_");
            let parsedTagName = parsedTags?.[0].charAt(0).toUpperCase() + parsedTags?.[1];
            return parsedTagName;
        },
        parseTagServiceType(rawTagName){
            let parsedTags = rawTagName.split("_");
            const tagServiceType = parsedTags?.[0].charAt(0).toUpperCase() + parsedTags?.[0].slice(1);
            return tagServiceType;
        },
        calculateTagLatency(newValue){
            let currentMoment = moment.utc();
            currentMoment = moment.utc(currentMoment.valueOf() + this.utcDifference);
            const tagMoment = moment.utc(newValue.dateTimestamp);
            let tagDelay = Math.abs(currentMoment.diff(tagMoment));
            return tagDelay;
        },
        calculateTagAverageLatency(tag){
            let averageLatency = tag.latencyValues.reduce((b, a) => b + a, 0)/tag.latencyCount;
            return averageLatency;
        },
        updateTagAverages(){
            let latencyTotal = 0;
            for(let i=0; i< this.latencyTagAverages.length; i++){
                this.latencyTagAverages[i].averageLatency = this.calculateTagAverageLatency(this.latencyTagAverages[i]);
                this.latencyTagAverages[i].format = this.calculateTagFormat(this.latencyTagAverages[i].averageLatency);
                latencyTotal += this.latencyTagAverages[i].averageLatency;
            }
            this.overallAverageLatency = latencyTotal / this.latencyTagAverages.length;
            this.latencyTagAverages.sort((a, b) => a.name.localeCompare(b.name));
            this.latencyTagDisplay = this.latencyTagAverages;
            this.latencyTagAverages = [];
        },
        calculateTagFormat(delay){
            let tagFormat = '';
            if(delay < 2000){
                tagFormat = 'text-success';
            } else if (delay < 4000){
                tagFormat = 'text-warning';
            } else {
                tagFormat = 'text-danger';
            }
            return tagFormat;
        }
    }
};
</script>

<style>
    .underlay-success{
        background: #1e3230;
    }
    .underlay-warning{
        background: #352b29;
    }
    .underlay-danger{
        background: #352a2b;
    }
    .latency-nav-success {
        color: #28a644;
    }
    .latency-nav-warning {
        color: #e9b10a;
    }
    .latency-nav-danger {
        color: #dc3545;
    }
    .latency-nav-icon {
        color: #fff;
    }
    .latency-nav-item {
        display:block;
        color: #fff;
    }
    .dropdown:hover .dropdown-menu {
        display: block;
    }

</style>
