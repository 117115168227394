<template> <!--Needs a lint fix-->
  <div 
		class="modal" 
		id="commentModal" 
		tabindex="-1"
		role="dialog"
	>
		<div class="modal-dialog modal-lg" role="document">
			<div class="modal-content main-container div-border px-2 modal-bg">
				<div class="modal-header"  style="border-bottom-width:0px">
					<job-info-header ref="header_ref" :jobData="jobData" :onChangeValues="getComments"/>
				</div>
				<div class="modal-body py-0">
					<div v-if="!isCommentsVisible" class="form-group">
						<label for="commentInput" class="primary-txt">Please enter the comment below <br/><span class="text-danger">{{ error }}</span><span class="text-success">{{ success }}</span>  </label>
						<textarea class="form-control div-border" id="textarea" rows="6"></textarea>
					</div>
					<div v-else class="scrollmenu pt-2 px-2 pb-0 div-border">
							<comment-card v-for="(comment, index) in commentData" v-bind:key="index" :commentInfo="comment" :isEditable="(username===comment.user)" :onSavePressed="(commentObject)=>onCommentPost(commentObject)"/>
							<div v-if="loading" class="pb-2 txt-dark" >Loading...</div>
							<div v-else-if="commentData.length===0" class="pb-2 txt-dark">No comments</div>
					</div>
				</div>
				<div class="modal-footer" style="border-top-width:0px">
					<div v-if="(!showingAllComments)" v-on:click="()=>{
						if(isCommentsVisible==false){
							onViewAllCommentsPressed()	
						} else {
							onGetJobCommentsPressed();
						}
					}" class="text-primary pointer-cursor link-txt-color">
						<u>{{ isCommentsVisible? 'Show all comments for Job' : 'View All Comments'}}</u>
					</div>
					<div class="d-flex flex-grow-1"></div>
					<div v-if="!isCommentsVisible">
						<button type="button" class="btn btn-secondary grey-button" data-dismiss="modal">Cancel</button>
						<button type="button" class="btn btn-success green-button" v-on:click="()=>onCommentPost()">Save</button>
					</div>
					<div v-else >
						<div v-on:click="()=>{
							if(showingAllComments){
								onViewAllCommentsPressed();
							} else {
								isVisibleComments(false)
							}
						}">
							<button type="button" class="btn btn-success green-button">Back</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<style scoped>
	.main-container {
			border-radius: 20px
  }
	.pointer-cursor {
		cursor: pointer; 
	}
	.div-border {
		border-width: 1px !important;
		border-color: #000 !important;
	}
	.txt-dark {
		color : black;
	}
	.scrollmenu {
		overflow : scroll;
		max-height: 350px;
		background-color: white;
	}	
	.green-button {
		background-color: #5cc55b;
		border-width: 0px;
		min-width: 150px;
	}
	
	.grey-button {
		background-color: #8e8e8e;
		border-width: 0px;
		min-width: 150px;
	}

   
</style>

<script>
		import moment from 'moment';
		import GlobalFunctions from '../GlobalFunctions.js'
    export default {
				props : ['jobData'],
        data() {
            return {
								isModalVisible : false,
								isCommentsVisible : false,
								wellNumber : 1,
								jobNumber : null,
								stageNumber : 2,
								description : '',
								commentData : [],
								loading : false,
								showingAllComments : false,
								error : '',
								success : ''
            }
				},
				created() {
					this.username = document.querySelector("meta[name='user-name']").getAttribute('content');
				},
				mounted(){
					const self = this;
					this.jobNumber = this.jobData.jobNumber;
					$("#commentModal").on('hide.bs.modal', function(){
						self.error = '';
						self.success = '';
					});
				},
				methods : {							
					changeModalVisibility : function (status) {
						const self = this;
						self.isModalVisible = status;
						self.isModalVisible ? $("#commentModal").modal('show') : $("#commentModal").modal('hide');
					},
					isVisibleComments : function(status){
						const self = this;
						self.isCommentsVisible = status;
					},
					onViewAllCommentsPressed(){
						this.commentData = []
						this.isVisibleComments(true);
						this.getComments();
						this.showingAllComments = false; 
					},
					onGetJobCommentsPressed(){
						this.commentData = []
						this.getAllComments();
						this.showingAllComments = true; 
					},
					onCommentPost: function(commentObject) {
							const self = this;

              let {
								selectedWell,
								selectedStage
							} = this.$refs.header_ref.$data;

							self.description = $('#textarea').val();
							let description = commentObject? commentObject.description : self.description;
							let method = 'POST';
							let url = '/comments';

							if(commentObject){
								selectedStage = commentObject.stageNumber;
								selectedWell = commentObject.wellNumber;
								url = `/comment/${commentObject.id}`
								method = "PUT"
							}
              console.log("method", method)


							if(!!description && description.trim().length > 0){
								self.error = '';
								self.success = 'Comment added successfully'
								this.clearTextArea()
								
								const datetime = moment(Date.now()).format(" HH:mm:ss, YYYY-MM-DD");
								const data = {
									"_token": GlobalFunctions.getCSRFToken(),
									wellNumber: selectedWell,
									jobNumber: self.jobNumber,
									stageNumber: selectedStage,
									description : description
								};
								$.ajax({
										method: method,
										url: url,
										data,
										dataType: 'json',
										datetime: datetime
								}).done((res)=>{
									// console.log("TCL: res", res)
								})
							} else {
								self.success = '';
								self.error = 'Comment cannot be empty';
							}
							
					},
					clearTextArea(){
						$('#textarea').val('');
					},
					getComments(){
						const self = this;
						self.loading = true
						const url =  '/comments/'+this.jobNumber;
						const {
								selectedWell,
								selectedStage
						} = this.$refs.header_ref.$data;

						$.get(
							url,
							{ 
								wellNumber : selectedWell,
								stage :selectedStage
							},
							function(data,status,xr){		
								self.commentData = data
								self.loading = false
							}
						);
					},
					getAllComments(){
						const self = this;
						self.loading = true
						const url =  '/allComments/'+this.jobNumber;
						$.get(
							url,
							{},
							function(data,status,xr){		
								self.commentData = data
								self.loading = false
							}
						);
					}
				}
    }
</script>