var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "dot", attrs: { id: _vm.generatedId() } },
    [
      _c(
        "b-button",
        {
          class: [
            (_vm.flagsArray.length || _vm.nptArray.length) > 0
              ? "dot-lg"
              : "dot-md",
            { "dot-read": this.read },
            "dot-clickable",
            "text-center",
          ],
          style: [_vm.getMarkerSymbolStyle(), _vm.dotColor],
          attrs: { id: _vm.popoverId() },
        },
        [_vm._v(_vm._s(_vm.getMarkerSymbol()))]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          ref: "popover",
          staticClass: "popover-body-custom",
          attrs: {
            target: _vm.popoverId(),
            triggers: "click blur",
            placement: "righttop",
          },
          on: { show: _vm.onShow, shown: _vm.onShown, hidden: _vm.onHidden },
        },
        [
          [
            _vm.hideListHeader === false
              ? _c(
                  "div",
                  {
                    staticClass: "m-1 px-1",
                    staticStyle: { color: "#FFFFFF" },
                  },
                  [_c("b", [_vm._v(_vm._s(_vm.timeString()) + " ")])]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.nptArray && _vm.nptArray.length > 0
              ? _c("div", { staticClass: "px-2" }, [
                  _c("b", { staticStyle: { color: "#FFFFFF" } }, [
                    _vm._v("NPT Events"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "px-2 mt-3",
                      staticStyle: {
                        "max-height": "280px",
                        "overflow-y": "auto",
                      },
                    },
                    _vm._l(_vm.nptArray, function (npt, index) {
                      return _c("div", { key: index }, [
                        _c(
                          "div",
                          {
                            staticClass: "comment-container m-0 mb-2 p-2",
                            style: {
                              "border-color":
                                npt.subCategory == "end" ? "red" : "green",
                            },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                                NPT\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.getNptPair(npt)
                              ? _c("div", [
                                  _c("div", [
                                    _vm._v(
                                      "\n                                    Start Time: " +
                                        _vm._s(
                                          _vm.convertTime(
                                            npt.subCategory == "start"
                                              ? npt.data.startTime
                                              : _vm.getNptPair(npt).data
                                                  .startTime
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                                    End Time: " +
                                        _vm._s(
                                          _vm.convertTime(
                                            npt.subCategory == "end"
                                              ? npt.data.endTime
                                              : _vm.getNptPair(npt).data.endTime
                                          )
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    "\n                                Time: " +
                                      _vm._s(
                                        _vm.convertTime(
                                          npt.subCategory == "start"
                                            ? npt.data.startTime
                                            : npt.data.endTime
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                                Well Name: " +
                                  _vm._s(_vm.getWellName(npt)) +
                                  " (" +
                                  _vm._s(npt.wellNumber) +
                                  ")\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                                Stage Number: " +
                                  _vm._s(npt.stageNumber) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                                Description: " +
                                  _vm._s(npt.data.timekeeper.description) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            npt.data.nptOutcomeId
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                NPT Outcome: " +
                                      _vm._s(
                                        _vm.getNPTOutcome(npt.data.nptOutcomeId)
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            npt.data.customerVendorIds.length > 0
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                NPT Responsible Vendors: " +
                                      _vm._s(
                                        _vm.getResponsibleVendors(
                                          npt.data.customerVendorIds
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            npt.data.comment
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                Comment: " +
                                      _vm._s(npt.data.comment) +
                                      "\n                            "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    "\n                                Comment: "
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { color: "red" } },
                                    [_vm._v("Not Set")]
                                  ),
                                ]),
                            _vm._v(" "),
                            _vm.getNptPair(npt)
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                Duration: " +
                                      _vm._s(
                                        _vm.getDuration(
                                          npt,
                                          _vm.getNptPair(npt)
                                        )
                                      ) +
                                      "\n                            "
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            npt.data.nonFracNPT
                              ? _c("div", [
                                  _vm._v(
                                    "\n                                NPT Type: Non Frac\n                            "
                                  ),
                                ])
                              : _c("div", [
                                  _vm._v(
                                    "\n                                NPT Type: Frac\n                            "
                                  ),
                                ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "d-flex pt-2" }, [
                              npt.showAddEvent
                                ? _c("div", { staticClass: "flex-grow-1" }, [
                                    _c(
                                      "button",
                                      {
                                        staticClass: "btn btn-success",
                                        staticStyle: { "font-size": "small" },
                                        attrs: {
                                          disabled:
                                            _vm.isFlagDeleting ||
                                            !_vm.hasEditPermission,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.addEndNPT(npt)
                                          },
                                        },
                                      },
                                      [_vm._v(_vm._s("Add End NPT"))]
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", { staticClass: "d-flex" }, [
                                _c("div", { staticClass: "pr-1" }, [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-primary",
                                      staticStyle: { "font-size": "small" },
                                      attrs: {
                                        disabled:
                                          _vm.isFlagDeleting ||
                                          !_vm.hasEditPermission,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editNPT(npt)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s("Edit"))]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn btn-danger",
                                      staticStyle: { "font-size": "small" },
                                      attrs: {
                                        disabled:
                                          _vm.isFlagDeleting ||
                                          !_vm.hasEditPermission,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteNPT(npt)
                                        },
                                      },
                                    },
                                    [_vm._v(_vm._s("Delete"))]
                                  ),
                                ]),
                              ]),
                            ]),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.alertArray && _vm.alertArray.length > 0
              ? _c("div", [
                  _c("b", { staticStyle: { color: "#FFFFFF" } }, [
                    _vm._v("Threshold Alerts"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "px-2 mt-3",
                      staticStyle: {
                        "max-height": "200px",
                        "overflow-y": "auto",
                      },
                    },
                    _vm._l(_vm.alertArray, function (alert) {
                      return _c("div", { key: alert.id }, [
                        _c(
                          "div",
                          {
                            staticClass: "comment-container m-0 mb-2 p-2",
                            style: { "border-color": +alert.color },
                          },
                          [
                            _c("div", [
                              _vm._v(
                                "\n                                Tag: " +
                                  _vm._s(alert.tagName) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                                Sensor Value: " +
                                  _vm._s(alert.alertValue) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _vm._v(
                                "\n                                Threshold Limit: " +
                                  _vm._s(alert.triggerLevel) +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "badge badge-danger",
                                style: _vm.getAlertColors(
                                  alert.alertLevel,
                                  alert.color
                                ),
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(
                                      _vm.getAlertLevelMessage(alert.alertLevel)
                                    ) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ])
                    }),
                    0
                  ),
                  _vm._v(" "),
                  _c("div", {
                    staticClass: "my-4",
                    staticStyle: {
                      width: "100%",
                      height: "1px",
                      "background-color": "grey",
                    },
                  }),
                  _vm._v(" "),
                  _vm.isFeatureFlagged("DRAW_NPT")
                    ? _c("div", { staticClass: "d-flex justify-content-end" }, [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary mb-1 mt-1",
                            staticStyle: { "font-size": "small" },
                            on: { click: _vm.toggleCommentInputs },
                          },
                          [_vm._v(_vm._s(_vm.commentDisplayText))]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.flagsArray && _vm.flagsArray.length > 0
              ? _c(
                  "div",
                  [
                    _c("b", { staticStyle: { color: "#FFFFFF" } }, [
                      _vm._v("Wellbore Flags"),
                    ]),
                    _vm._v(" "),
                    _vm._l(_vm.flagsArray, function (flag) {
                      return _c("div", { key: flag.id }, [
                        _c(
                          "div",
                          {
                            staticClass: "px-2 mt-3",
                            staticStyle: {
                              "max-height": "200px",
                              "overflow-y": "auto",
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "comment-container m-0 mb-2 p-2",
                                style: "border-color:" + _vm.getFlagColor(flag),
                              },
                              [
                                _c("div", [
                                  _vm._v(
                                    "\n                                Category: " +
                                      _vm._s(flag.category) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                                Sub-Category: " +
                                      _vm._s(flag.subCategory) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                                Description: " +
                                      _vm._s(flag.data.description) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                                Well Name: " +
                                      _vm._s(_vm.getWellName(flag)) +
                                      " (" +
                                      _vm._s(flag.wellNumber) +
                                      ")\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                                Stage Number: " +
                                      _vm._s(flag.stageNumber) +
                                      " \n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _vm._v(
                                    "\n                                Timestamp: " +
                                      _vm._s(_vm.convertTime(flag.timestamp)) +
                                      "\n                            "
                                  ),
                                ]),
                                _vm._v(" "),
                                flag.subCategory == "placeFlag"
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                Target Depth (ft): " +
                                          _vm._s(
                                            _vm.formatTargetDepthValue(flag)
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                flag.subCategory == "completed"
                                  ? _c("div", [
                                      _vm._v(
                                        "\n                                Duration (min): " +
                                          _vm._s(
                                            _vm.formatFlagDurationMinutes(flag)
                                          ) +
                                          "\n                            "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-2 px-2 mt-3" }, [
                          _c("div", { staticClass: "col-6" }, [
                            flag.subCategory == "placeFlag"
                              ? _c(
                                  "button",
                                  {
                                    staticClass:
                                      "btn btn-danger float-left mb-2 pb-2",
                                    staticStyle: { "font-size": "small" },
                                    attrs: { disabled: _vm.isFlagDeleting },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteFlag(flag)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.isFlagDeleting
                                            ? "Deleting..."
                                            : "Delete Flag"
                                        )
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                        ]),
                      ])
                    }),
                  ],
                  2
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.isNoComments
              ? _c(
                  "div",
                  {
                    staticClass: "scroll-container p-0 m-0",
                    attrs: {
                      id: String(_vm.popoverId() + "-msg-scroll-container"),
                    },
                  },
                  _vm._l(_vm.commentsArray, function (comment, index) {
                    return _c("div", { key: comment.id }, [
                      _c(
                        "div",
                        { staticClass: "comment-container m-0 my-2 p-2" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row m-0 p-1 justify-content-between",
                            },
                            [
                              _c("div", { staticClass: "d-flex" }, [
                                comment.well_id
                                  ? _c("div", { staticClass: "small" }, [
                                      comment.well_id
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "badge badge-secondary",
                                              style: {
                                                backgroundColor:
                                                  _vm.getWellColor(comment),
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                         \n                                    "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      _vm._v(
                                        "\n                                    " +
                                          _vm._s(_vm.getWellName(comment)) +
                                          "\n                                "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "d-flex" },
                                [
                                  !_vm.readOnly
                                    ? [
                                        _c("i", {
                                          staticClass: "fas fa-pen mr-2",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editComment(comment)
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("i", {
                                          staticClass: "fas fa-trash-alt",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteComment(
                                                comment,
                                                index
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "m-1" }, [
                            _vm._v(
                              "\n                            " +
                                _vm._s(comment.text) +
                                "\n                        "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "m-0 row justify-content-between p-1",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "small col-8 text-left m-0 p-0",
                                },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(_vm.createdAtString(comment)) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "small text-right m-0 p-0" },
                                [
                                  _vm._v(
                                    "\n                                " +
                                      _vm._s(
                                        comment.user
                                          ? comment.user.first_name ||
                                              comment.user.username
                                          : "NOT SET"
                                      ) +
                                      "\n                            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.getActivityType(comment) ||
                          _vm.getReasonType(comment)
                            ? _c("div", { staticClass: "m-0 row mb-1" }, [
                                _vm.getActivityType(comment)
                                  ? _c(
                                      "div",
                                      { staticClass: "small col-6 pl-1" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "badge badge-success",
                                            attrs: {
                                              id: "tooltip-activity" + index,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getActivityType(comment)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              triggers: "hover",
                                              target:
                                                "tooltip-activity" + index,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getActivityType(comment)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.getReasonType(comment)
                                  ? _c(
                                      "div",
                                      { staticClass: "small col-6 pr-2" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "badge badge-primary float-right",
                                            attrs: {
                                              id: "tooltip-reason" + index,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getReasonType(comment)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              triggers: "hover",
                                              target: "tooltip-reason" + index,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getReasonType(comment)
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.getContractorName(comment)
                            ? _c("div", { staticClass: "m-0 row mb-1" }, [
                                _c(
                                  "div",
                                  { staticClass: "small col-6 pl-1" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "badge badge-info",
                                        attrs: {
                                          id: "tooltip-contractor" + index,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.getContractorName(comment)
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "b-tooltip",
                                      {
                                        attrs: {
                                          triggers: "hover",
                                          target: "tooltip-contractor" + index,
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                    " +
                                            _vm._s(
                                              _vm.getContractorName(comment)
                                            ) +
                                            "\n                                "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ])
                            : _vm._e(),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.isFeatureFlagged("DRAW_NPT")
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-content-end" },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary mb-1 mt-1",
                                  staticStyle: { "font-size": "small" },
                                  on: { click: _vm.toggleCommentInputs },
                                },
                                [_vm._v(_vm._s(_vm.commentDisplayText))]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ])
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.readOnly && !_vm.hideCommentInputs
              ? [
                  _vm.editingComment
                    ? _c("div", { staticClass: "input-group mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "list-label my-auto text-white ml-3" },
                          [
                            _vm._v(
                              "\n                        Edit Comment\n                    "
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3 mt-2" }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.commentText,
                          expression: "commentText",
                        },
                      ],
                      staticClass: "form-control",
                      staticStyle: { resize: "none" },
                      attrs: {
                        rows: "5",
                        "aria-label": "With textarea",
                        placeholder: "Type your comment here...",
                      },
                      domProps: { value: _vm.commentText },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.commentText = $event.target.value
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3 text-white" }, [
                    _c("div", { staticClass: "col-12 m-0 p-0" }, [
                      _c("div", { staticClass: "list-label" }, [
                        _vm._v(
                          "\n                            Well:\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedWellId,
                              expression: "selectedWellId",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedWellId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { selected: "" },
                              domProps: { value: null },
                            },
                            [_vm._v(" --No Well--")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.wells, function (item) {
                            return _c(
                              "option",
                              { key: item.id, domProps: { value: item.id } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3 text-white" }, [
                    _c("div", { staticClass: "col-12 m-0 p-0" }, [
                      _c("div", { staticClass: "list-label text-white" }, [
                        _vm._v(
                          "\n                            Code 1:\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedActivityId,
                              expression: "selectedActivityId",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          on: {
                            change: [
                              function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.selectedActivityId = $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              },
                              _vm.onActivityChanged,
                            ],
                          },
                        },
                        _vm._l(_vm.eventActivities, function (item) {
                          return _c(
                            "option",
                            { key: item.id, domProps: { value: item.id } },
                            [_vm._v(_vm._s(item.type))]
                          )
                        }),
                        0
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3 text-white" }, [
                    _c("div", { staticClass: "col-12 m-0 p-0" }, [
                      _c("div", { staticClass: "list-label" }, [
                        _vm._v(
                          "\n                            Code 2:\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedReasonId,
                              expression: "selectedReasonId",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedReasonId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { selected: "" },
                              domProps: { value: null },
                            },
                            [_vm._v(" --Not Set--")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.visibleEventReasons, function (item) {
                            return _c(
                              "option",
                              { key: item.id, domProps: { value: item.id } },
                              [_vm._v(_vm._s(item.type))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-group mb-3 text-white" }, [
                    _c("div", { staticClass: "col-12 m-0 p-0" }, [
                      _c("div", { staticClass: "list-label" }, [
                        _vm._v(
                          "\n                            Contractor:\n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.selectedContractorId,
                              expression: "selectedContractorId",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedContractorId = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            {
                              attrs: { selected: "" },
                              domProps: { value: null },
                            },
                            [_vm._v(" --No Contractor Set--")]
                          ),
                          _vm._v(" "),
                          _vm._l(_vm.contractors, function (item) {
                            return _c(
                              "option",
                              { key: item.id, domProps: { value: item.id } },
                              [_vm._v(_vm._s(item.name))]
                            )
                          }),
                        ],
                        2
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "modal-footer d-flex flex-row pt-3 pb-2 pr-2",
                      staticStyle: { "border-top-width": "0px" },
                    },
                    [
                      this.editingComment
                        ? _c(
                            "button",
                            {
                              staticClass: "btn btn-secondary btn-sm",
                              on: {
                                click: function ($event) {
                                  return _vm.cancelEditing()
                                },
                              },
                            },
                            [_vm._v("Cancel")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary btn-sm ml-2",
                          attrs: { disabled: _vm.isFlagDeleting },
                          on: {
                            click: function ($event) {
                              return _vm.handleClick()
                            },
                          },
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(this.editingComment ? "Save" : "Comment")
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
          ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }