var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        ref: "controlsContainer",
        staticClass: "row no-gutters",
        attrs: { id: "controlsContainer" },
      },
      [
        _c(
          "div",
          { staticClass: "col-11 d-flex flex-row align-items-center" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-light btn-sm w-25 mx-2",
                attrs: { id: "chartButton" + _vm.item.i },
              },
              [_vm._v(_vm._s(_vm.chartButtonLabel))]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-light btn-sm w-25 mx-2",
                attrs: { id: "activitiesButton" + _vm.item.i },
              },
              [_vm._v(_vm._s(_vm.activityButtonLabel))]
            ),
            _vm._v(" "),
            _c("time-interval-select", {
              staticClass: "mr-3 ml-1",
              staticStyle: { "white-space": "nowrap" },
              attrs: {
                isCustomTimeOptions: true,
                job: _vm.job,
                item: _vm.item,
                resetToDefaults: _vm.resetToDefaults,
              },
              on: {
                selectedTimeMinChange: function ($event) {
                  _vm.selectedTimeMin = $event.target.value
                  _vm.changeResetFilters()
                },
                selectedTimeMaxChange: function ($event) {
                  _vm.selectedTimeMax = $event.target.value
                  _vm.changeResetFilters()
                },
                selectedDurationTypeChange: function ($event) {
                  _vm.selectedIntervalType = $event.target
                    ? $event.target.value
                    : $event
                  _vm.changeResetFilters()
                },
                selectedIntervalTypeChange: function ($event) {
                  _vm.selectedIntervalTimeType = $event.target
                    ? $event.target.value
                    : $event
                  _vm.changeResetFilters()
                },
                selectedDayMinChange: function ($event) {
                  _vm.selectedDayMin = $event.target.value
                  _vm.changeResetFilters()
                },
                selectedDayMaxChange: function ($event) {
                  _vm.selectedDayMax = $event.target.value
                  _vm.changeResetFilters()
                },
                onCalculatedMaxMinIntervalDateTime: function ($event) {
                  _vm.firstAndLastDateOfInterval = $event
                },
                resetToDefaultsChange: function ($event) {
                  _vm.resetToDefaults = $event
                },
              },
            }),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-success btn-sm w-25 whiteText mx-2",
                staticStyle: { width: "50%" },
                on: {
                  click: function ($event) {
                    return _vm.saveConfig()
                  },
                },
              },
              [
                _vm.isSaving
                  ? _c("span", {
                      staticClass: "spinner-border spinner-border-sm pr-2",
                      attrs: { role: "status", "aria-hidden": "true" },
                    })
                  : _vm._e(),
                _vm._v("\n                Save Filters\n            "),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary btn-sm w-25 whiteText mx-2",
                attrs: { disabled: _vm.disableResetFilters },
                on: {
                  click: function ($event) {
                    return _vm.loadConfig(true)
                  },
                },
              },
              [_vm._v("Reset Filters")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "b-popover",
              {
                attrs: {
                  target: "wellsButton" + _vm.item.i,
                  triggers: "click blur",
                  placement: "bottom",
                },
              },
              [
                _c("checkbox-list", {
                  staticClass: "w-100 mr-1",
                  attrs: {
                    enableSelectAllOption: true,
                    enableSearch: false,
                    label: "Selected Wells",
                    valueKey: "nameLong",
                    outputKey: "id",
                    height: "300",
                    options: _vm.wells,
                    selectedOptions: _vm.selectedWells,
                  },
                  on: {
                    "update:selectedOptions": function ($event) {
                      _vm.selectedWells = $event
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "activitiesButton" + _vm.item.i,
                  triggers: "click blur",
                  placement: "bottom",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column whiteText mx-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between" },
                      [
                        _c("div", { staticClass: "mr-2" }, [
                          _vm._v(" Select Activities: "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedActivityTypes,
                                expression: "selectedActivityTypes",
                              },
                            ],
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedActivityTypes = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                _vm.updateActivityDataBasedOnTypeChange,
                              ],
                            },
                          },
                          _vm._l(
                            Object.keys(_vm.availableActivities),
                            function (option, index) {
                              return _c(
                                "option",
                                {
                                  key: index,
                                  domProps: {
                                    value: _vm.availableActivities[option],
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.availableActivities[option])
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "chartButton" + _vm.item.i,
                  triggers: "click blur",
                  placement: "bottom",
                },
              },
              [
                _c("checkbox-list", {
                  staticClass: "w-100 mr-1",
                  attrs: {
                    enableSelectAllOption: true,
                    enableSearch: false,
                    label: "Select Charts",
                    height: "125",
                    options: _vm.availableCharts,
                    selectedOptions: _vm.selectedCharts,
                  },
                  on: {
                    "update:selectedOptions": function ($event) {
                      _vm.selectedCharts = $event
                      _vm.changeResetFilters()
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass:
              "col-1 d-flex justify-content-start align-items-center ml-0",
          },
          [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Export Current View",
                    expression: "'Export Current View'",
                    arg: "top",
                  },
                ],
                staticClass: "fake-button",
                staticStyle: {
                  backgroundColor: "white",
                  "border-radius": "5px",
                },
                attrs: { id: "exportDataIcon" + _vm.item.i },
              },
              [_c("i", { staticClass: "fas fa-download" })]
            ),
            _vm._v(" "),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "exportDataIcon" + _vm.item.i,
                  placement: "bottom",
                  triggers: "hover",
                },
              },
              [
                _c("div", { staticStyle: { color: "white" } }, [
                  _c(
                    "div",
                    {
                      staticClass: "w-100 text-center",
                      staticStyle: { "text-decoration": "underline" },
                    },
                    [_vm._v("Export Data")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-between mt-2" },
                    [
                      _c("div", { staticClass: "mx-2" }, [
                        _vm._v(
                          "\n                            File Type: \n                        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.exportFileType,
                              expression: "exportFileType",
                            },
                          ],
                          staticClass: "mx-2",
                          attrs: { id: "exportFileType" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.exportFileType = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "json" } }, [
                            _vm._v("JSON"),
                          ]),
                          _vm._v(" "),
                          _c(
                            "option",
                            { attrs: { value: "csv", selected: "" } },
                            [_vm._v("CSV")]
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center mt-2" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success green-button",
                          attrs: {
                            type: "button",
                            disabled:
                              !_vm.rawActivityData ||
                              (_vm.rawActivityData &&
                                !_vm.rawActivityData.dataForAllWells),
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.exportDataDownload()
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              false
                                ? _c("span", {
                                    staticClass:
                                      "spinner-border spinner-border-sm pr-2",
                                    attrs: {
                                      role: "status",
                                      "aria-hidden": "true",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                                    Export\n                                "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]),
              ]
            ),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "d-flex flex-row w-100",
        staticStyle: { "overflow-x": "auto", "overflow-y": "hidden" },
      },
      [
        _vm._l(_vm.wells, function (well, index) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showPieChartMultiWell,
                  expression: "showPieChartMultiWell",
                },
              ],
              key: index,
              style: {
                height: `${_vm.height - _vm.chartHeightOffset}px`,
                width: _vm.chartWidthPieChartMultiWell,
              },
              attrs: { id: "pieChartContainer" },
            },
            [
              _c("pie-chart", {
                ref: _vm.getPieChartRefNamesForMultiWell[well.index],
                refInFor: true,
                style: { position: "relative", width: "100%", height: "100%" },
                attrs: {
                  "chart-data": _vm.pieChartDataMultiWell[well.index],
                  options: _vm.pieChartOptionsMultiWell[well.index],
                  id: "pie-chart",
                  customPlugins: _vm.chartPlugins,
                },
              }),
            ],
            1
          )
        }),
        _vm._v(" "),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showPieChart,
                expression: "showPieChart",
              },
            ],
            style: {
              height: `${_vm.height - _vm.chartHeightOffset}px`,
              width: _vm.chartWidthPieChart,
            },
            attrs: { id: "pieChartContainer" },
          },
          [
            _c("pie-chart", {
              ref: "pieChart",
              style: { position: "relative", width: "100%", height: "100%" },
              attrs: {
                "chart-data": _vm.pieChartData,
                options: _vm.pieChartOptions,
                id: "pie-chart",
                customPlugins: _vm.chartPlugins,
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }