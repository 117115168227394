var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "ToolstringConstructModal",
      staticClass: "dark-modal modal",
      attrs: { id: "ToolstringConstructModal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.toolstring
            ? _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _vm.loading
                    ? _c("div", { staticClass: "pb-3 px-2" }, [
                        _vm._v(
                          "\n                    loading....\n                "
                        ),
                      ])
                    : [
                        _c(
                          "div",
                          { staticClass: "row justify-content-between" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "field-container col-xs",
                                attrs: { id: "toolstringName" },
                              },
                              [
                                _c("iws-input", {
                                  ref: "nameInput",
                                  attrs: {
                                    label: "Toolstring Name",
                                    value: _vm.toolstring.name,
                                    required: "true",
                                    placeholder: "Give this toolstring a name",
                                    "error-message": "Please provide a name",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        _vm.toolstring,
                                        "name",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-xs" },
                              [
                                _vm._m(1),
                                _vm._v(" "),
                                _c("iws-switch", {
                                  attrs: {
                                    onPress: _vm.onTaperedToggled,
                                    value: _vm.isTapered,
                                    labels: ["Off", "On"],
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("iws-table", {
                          staticStyle: { "margin-bottom": "20px" },
                          attrs: { columns: _vm.toolstringConfigColumns },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header_component",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            Component\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header_topShot",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            CCL to Top Shot\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header_description",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            Description\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header_spf",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            SPF\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header_phasing",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            Phasing\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header_chargeType",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            Charge Type\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "header_null",
                                fn: function () {
                                  return undefined
                                },
                                proxy: true,
                              },
                              {
                                key: "body",
                                fn: function () {
                                  return _vm._l(
                                    _vm.toolstringData,
                                    function (toolDetail, index) {
                                      return _c(
                                        "tr",
                                        { key: `${index}_${toolDetail.id}` },
                                        [
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(toolDetail.name) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _c(
                                              "div",
                                              { attrs: { id: "topShotInput" } },
                                              [
                                                _c("iws-input", {
                                                  ref: "customInput",
                                                  refInFor: true,
                                                  attrs: {
                                                    type: "number",
                                                    required: "true",
                                                    value: toolDetail.distance,
                                                    "append-icon": "slot",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        toolDetail,
                                                        "distance",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "appendIcon",
                                                        fn: function () {
                                                          return [
                                                            _vm._v(
                                                              "\n                                                Ft\n                                            "
                                                            ),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c(
                                            "td",
                                            [
                                              _c("iws-select", {
                                                ref: "customInput",
                                                refInFor: true,
                                                attrs: {
                                                  value:
                                                    toolDetail.selectedIndex,
                                                  options:
                                                    toolDetail.name === "Plug"
                                                      ? _vm.plugsDropDownOptions
                                                      : _vm.gunsDropDownOptions,
                                                  "value-name": "index",
                                                  required: "true",
                                                },
                                                on: {
                                                  "update:value": function (
                                                    $event
                                                  ) {
                                                    return _vm.$set(
                                                      toolDetail,
                                                      "selectedIndex",
                                                      $event
                                                    )
                                                  },
                                                  change: function ($event) {
                                                    _vm.onDescriptionChange(
                                                      toolDetail,
                                                      (toolDetail.name ===
                                                      "Plug"
                                                        ? _vm.plugsDropDownOptions
                                                        : _vm.gunsDropDownOptions)[
                                                        $event
                                                      ],
                                                      index,
                                                      $event
                                                    )
                                                  },
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(toolDetail.spf) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(toolDetail.phasing) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(toolDetail.chargeType) +
                                                "\n                                "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            toolDetail.name !== "Plug" &&
                                            _vm.toolstringData.length > 2
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "clickable table-row-action text-danger",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.removeGun(
                                                          toolDetail
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fas fa-trash-alt",
                                                    }),
                                                    _vm._v(
                                                      " Delete\n                                    "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ]
                                      )
                                    }
                                  )
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4100532644
                          ),
                        }),
                      ],
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "div",
                {
                  staticStyle: {
                    position: "absolute",
                    left: "16px",
                    display: "inline-block",
                  },
                },
                [
                  _c("iws-button", {
                    attrs: { type: "outline-light" },
                    on: { click: _vm.pushGun },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c("i", {
                              staticClass: "fas fa-plus-circle",
                              staticStyle: { "margin-right": "5px" },
                            }),
                            _vm._v("Add Gun\n                        "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-light",
                  attrs: { id: "cancel_button" },
                },
                [_vm._v("\n                    Cancel\n                ")]
              ),
              _vm._v(" "),
              _c("iws-button", {
                attrs: {
                  text: `${_vm.isEdit ? "Save" : "Add"} Toolstring`,
                  click: _vm.onSaveClick,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [
        _vm._v(
          "\n                    Toolstring Construction\n                "
        ),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticStyle: { "margin-right": "10px" } }, [
      _c("span", { attrs: { id: "tapered-title" } }, [
        _vm._v(
          "\n                                    Tapered Tool-String\n                                "
        ),
      ]),
      _vm._v(" "),
      _c("span", { attrs: { id: "tapered-sub-title" } }, [
        _vm._v(
          "\n                                    Apply same configuration to all guns\n                                "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }