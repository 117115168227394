var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { padding: "20px" } },
    [
      _c("iws-modal", {
        attrs: {
          "show-modal": _vm.modalShow,
          "max-width": "calc(550px + 3em)",
          "max-height": "100%",
        },
        on: {
          close: function ($event) {
            _vm.modalShow = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "title",
            fn: function () {
              return [
                _vm._v(
                  "\n            Preview  " +
                    _vm._s(_vm.currentClip.cameraName) +
                    " - " +
                    _vm._s(_vm.currentClip.description) +
                    "\n        "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "content",
            fn: function () {
              return [
                _c(
                  "video",
                  {
                    key: _vm.currentClip.url,
                    attrs: { controls: "", autoplay: "" },
                  },
                  [
                    _c("source", {
                      attrs: { src: _vm.currentClip.url, type: "video/mp4" },
                    }),
                  ]
                ),
                _vm._v(" "),
                _vm.currentClip.type == "Manual"
                  ? _c(
                      "div",
                      {
                        staticClass: "badge badge-pill my-2 badge-clip",
                        staticStyle: { "font-size": "16px" },
                      },
                      [_vm._v("\n                Clip\n            ")]
                    )
                  : _c(
                      "div",
                      {
                        staticClass: "badge badge-pill my-2 badge-alert",
                        staticStyle: { "font-size": "16px" },
                      },
                      [_vm._v("\n                Redzone\n            ")]
                    ),
                _vm._v(" "),
                _c("div", { staticStyle: { "font-size": "16px" } }, [
                  _c("b", [_vm._v("Camera")]),
                  _vm._v(": " + _vm._s(_vm.currentClip.cameraName) + " "),
                  _c("br"),
                  _vm._v(" "),
                  _c("b", [_vm._v("Date")]),
                  _vm._v(": " + _vm._s(_vm.getDate(_vm.currentClip)) + " "),
                  _c("br"),
                  _vm._v(" "),
                  _c("b", [_vm._v("Duration")]),
                  _vm._v(
                    ": " +
                      _vm._s(_vm.getDuration(_vm.currentClip)) +
                      "\n            "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "span",
                  { staticStyle: { position: "absolute", left: "1rem" } },
                  [
                    _c("iws-button", {
                      attrs: {
                        text: "Delete",
                        type: "danger",
                        "prepend-icon": "fas fa-trash-alt",
                        click: () => _vm.deleteClip(_vm.currentClip),
                      },
                    }),
                    _vm._v(" "),
                    _c("iws-button", {
                      staticClass: "ml-3",
                      attrs: {
                        text: `${
                          _vm.currentClip.type == _vm.falseAlarmCode
                            ? "Unflag"
                            : "Flag"
                        } False Positive`,
                        type:
                          _vm.currentClip.type == _vm.falseAlarmCode
                            ? "warning"
                            : "success",
                        "prepend-icon": "far fa-flag",
                        click: () => _vm.flagClip(_vm.currentClip),
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("iws-button", {
                  attrs: { text: "Close", type: "outline-light" },
                  on: {
                    click: function ($event) {
                      _vm.modalShow = false
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("camera-navigation", {
        staticStyle: { width: "calc(100vw - 25px)" },
        attrs: {
          user: _vm.user,
          device: _vm.device,
          "work-order-id": _vm.workOrderId,
          isClips: "",
          "is-home": "",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "full-width mb-4" },
        [
          _c("iws-select", {
            staticStyle: { width: "300px", display: "inline-block" },
            attrs: {
              options: [
                { title: "Group by : Midnight to Midnight", value: "midnight" },
                { title: "Group by : Shift to Shift", value: "shift" },
              ],
              value: _vm.groupBy,
              "display-name": "title",
              "value-name": "value",
              searchable: false,
            },
            on: {
              "update:value": function ($event) {
                _vm.groupBy = $event
              },
              change: function ($event) {
                return _vm.mapClips()
              },
            },
          }),
          _vm._v(" "),
          _c("iws-search", {
            staticClass: "ml-3",
            staticStyle: { display: "inline-block" },
            attrs: { value: _vm.searchInput },
            on: {
              "update:value": function ($event) {
                _vm.searchInput = $event
              },
              input: function ($event) {
                return _vm.mapClips()
              },
            },
          }),
          _vm._v(" "),
          _c("iws-checkbox", {
            staticClass: "ml-3",
            staticStyle: { display: "inline-block" },
            attrs: {
              label: "Show False Positives",
              value: _vm.showFalsePositives,
              "enable-click-label": "",
            },
            on: {
              "update:value": function ($event) {
                _vm.showFalsePositives = $event
              },
              change: function ($event) {
                return _vm.mapClips()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.mappedShifts == null
        ? _c(
            "div",
            { staticClass: "center-container" },
            [
              _c("b-spinner", {
                staticClass: "align-middle",
                attrs: { large: "" },
              }),
            ],
            1
          )
        : Object.keys(_vm.mappedShifts || {})?.length == 0
        ? _c("h1", { staticClass: "center-container danger-text-color" }, [
            _vm._v("\n        No Clips Recorded Yet\n    "),
          ])
        : _vm._l(Object.keys(_vm.mappedShifts), function (shift) {
            return _c(
              "div",
              {
                key: `${shift}_${_vm.mappedShifts[shift]?.length}`,
                staticClass: "shift",
              },
              [
                _c("div", { staticClass: "shift-title" }, [
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.groupBy == "shift" ? `${shift} Shift` : shift
                      ) +
                      " (" +
                      _vm._s(_vm.mappedShifts[shift]?.length || 0) +
                      ")\n        "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "clipSet" },
                  _vm._l(_vm.mappedShifts[shift], function (clip) {
                    return _c(
                      "div",
                      {
                        staticClass:
                          "card clip-card clickable position-relative",
                        on: {
                          click: function ($event) {
                            return _vm.openModal(clip)
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "clipImage",
                          attrs: {
                            src: clip.thumbnailURL,
                            alt: "Card image cap",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("h6", { staticClass: "card-title" }, [
                              _c("b", [
                                _vm._v(
                                  _vm._s(clip.cameraName) +
                                    " - " +
                                    _vm._s(clip.description)
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "clip-details" }, [
                              _c("b", [_vm._v("Taken at")]),
                              _vm._v(
                                ": " +
                                  _vm._s(_vm.getDate(clip, false)) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("p", { staticClass: "clip-details" }, [
                              _c("b", [_vm._v("Duration")]),
                              _vm._v(
                                ": " +
                                  _vm._s(_vm.getDuration(clip)) +
                                  "\n                    "
                              ),
                            ]),
                            _vm._v(" "),
                            clip.type == "Manual"
                              ? _c("iws-badge", {
                                  staticClass: "position-absolute bottom-left",
                                  attrs: { type: "success", text: "Clip" },
                                })
                              : _c("iws-badge", {
                                  staticClass: "position-absolute bottom-left",
                                  attrs: { type: "danger", text: "Redzone" },
                                }),
                            _vm._v(" "),
                            clip.type == _vm.falseAlarmCode
                              ? _c("iws-badge", {
                                  staticClass: "position-absolute bottom-right",
                                  attrs: {
                                    type: "success",
                                    text: "False Positive",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]
            )
          }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }