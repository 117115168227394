var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "my-modal",
    attrs: {
      size: "xl",
      "hide-header-close": "",
      "header-class": "border-bottom-0 justify-content-center pb-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "font-large" }, [_vm._v("Chart Export")]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "b-card",
              {
                staticClass: "inner-bg round-top border-bottom-0",
                attrs: { title: "Active tags", "title-tag": "h5" },
              },
              [
                _c("div", { staticClass: "d-flex mt-2" }, [
                  _c(
                    "div",
                    { staticClass: "d-flex justify-content-center w-100" },
                    [
                      _c("checkbox-list", {
                        staticClass: "w-50 mr-1",
                        attrs: {
                          enableSelectAllOption: true,
                          enableSearch: true,
                          label: "Frac tags",
                          valueKey: "priorityName",
                          outputKey: "name",
                          height: "200",
                          options: _vm.fracTags,
                          selectedOptions: _vm.selectedFracTags,
                        },
                        on: {
                          "update:selectedOptions": function ($event) {
                            _vm.selectedFracTags = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("checkbox-list", {
                        staticClass: "w-50 ml-1",
                        attrs: {
                          enableSearch: true,
                          enableSelectAllOption: true,
                          label: "Wireline tags",
                          valueKey: "priorityName",
                          outputKey: "name",
                          height: "200",
                          options: _vm.wirelineTags,
                          selectedOptions: _vm.selectedWirelineTags,
                        },
                        on: {
                          "update:selectedOptions": function ($event) {
                            _vm.selectedWirelineTags = $event
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-card",
              {
                staticClass:
                  "inner-bg round-bottom border-top-0 border-bottom-0",
                attrs: {
                  title: "Export between",
                  "body-class": "pt-0",
                  "title-tag": "h5",
                },
              },
              [
                _c("div", { staticClass: "px-2 mb-2" }, [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.timeType,
                            expression: "timeType",
                          },
                        ],
                        staticClass: "mx-1 form-control-sm",
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.timeType = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "time" } }, [
                          _vm._v("Times:"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "stage" } }, [
                          _vm._v("Stages:"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.timeType === "stage"
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.stageSelect.stageType,
                                expression: "stageSelect.stageType",
                              },
                            ],
                            staticClass: "form-control-sm mx-1",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.stageSelect,
                                  "stageType",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          [
                            _c(
                              "option",
                              { attrs: { value: "frac", selected: "" } },
                              [_vm._v("Frac")]
                            ),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "wireline" } }, [
                              _vm._v("Wireline"),
                            ]),
                            _vm._v(" "),
                            _c("option", { attrs: { value: "all" } }, [
                              _vm._v("All"),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.timeType === "stage"
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.stageSelect.wellIndex,
                                expression: "stageSelect.wellIndex",
                              },
                            ],
                            staticClass: "mx-1 form-control-sm",
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.stageSelect,
                                  "wellIndex",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(_vm.wells, function (well) {
                            return _c(
                              "option",
                              {
                                key: well.index,
                                domProps: { value: well.index },
                              },
                              [
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(well.name) +
                                    " \n                                "
                                ),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _vm.timeType === "time"
                  ? _c("div", { staticClass: "px-2" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v(
                            "\n                            Start Date\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-shrink-1 pl-1 justify-content-end",
                              },
                              [
                                _c("input", {
                                  ref: "minDateInput" + _vm._uid,
                                  staticClass: "form-control-sm",
                                  attrs: {
                                    type: "datetime-local",
                                    step: "1",
                                    placeholder: "yyyy-mm-dd hh:mm:ss",
                                  },
                                  domProps: {
                                    value: _vm.displayMinDateLimited,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateSelectedDate(
                                        "startTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v(
                            "\n                            End Date\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-shrink-1 pl-1 justify-content-end",
                              },
                              [
                                _c("input", {
                                  ref: "maxDateInput" + _vm._uid,
                                  staticClass: "form-control-sm",
                                  attrs: {
                                    type: "datetime-local",
                                    step: "1",
                                    placeholder: "yyyy-mm-dd hh:mm:ss",
                                  },
                                  domProps: {
                                    value: _vm.displayMaxDateLimited,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateSelectedDate(
                                        "endTime",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm.timeType === "stage"
                  ? _c("div", { staticClass: "px-2" }, [
                      _c("div", { staticClass: "row" }, [
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v(
                            "\n                            Start Stage\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-shrink-1 pl-1 justify-content-end",
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.stageSelect.startStageNumber,
                                        expression:
                                          "stageSelect.startStageNumber",
                                      },
                                    ],
                                    staticClass: "form-control-sm",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.stageSelect,
                                            "startStageNumber",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.validateExportTime(
                                            _vm.selectedStageStartTime,
                                            _vm.selectedStageEndTime
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectedWellStages,
                                    function (stage, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          attrs: {
                                            disabled: stage.startTime == null,
                                          },
                                          domProps: {
                                            value: stage.stageNumber,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              `${stage.stageNumber} - ${
                                                stage.startTime ||
                                                _vm.getPlaceholderTime(
                                                  stage,
                                                  "start"
                                                )
                                              }`
                                            )
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-2" }, [
                          _vm._v(
                            "\n                            End Stage\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "col-4" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex flex-shrink-1 pl-1 justify-content-end",
                              },
                              [
                                _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.stageSelect.endStageNumber,
                                        expression:
                                          "stageSelect.endStageNumber",
                                      },
                                    ],
                                    staticClass: "form-control-sm",
                                    on: {
                                      change: [
                                        function ($event) {
                                          var $$selectedVal =
                                            Array.prototype.filter
                                              .call(
                                                $event.target.options,
                                                function (o) {
                                                  return o.selected
                                                }
                                              )
                                              .map(function (o) {
                                                var val =
                                                  "_value" in o
                                                    ? o._value
                                                    : o.value
                                                return val
                                              })
                                          _vm.$set(
                                            _vm.stageSelect,
                                            "endStageNumber",
                                            $event.target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          )
                                        },
                                        function ($event) {
                                          return _vm.validateExportTime(
                                            _vm.selectedStageStartTime,
                                            _vm.selectedStageEndTime
                                          )
                                        },
                                      ],
                                    },
                                  },
                                  _vm._l(
                                    _vm.selectedWellStages,
                                    function (stage, index) {
                                      return _c(
                                        "option",
                                        {
                                          key: index,
                                          attrs: {
                                            disabled: stage.endTime == null,
                                          },
                                          domProps: {
                                            value: stage.stageNumber,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              `${stage.stageNumber} - ${
                                                stage.endTime ||
                                                _vm.getPlaceholderTime(
                                                  stage,
                                                  "end"
                                                )
                                              }`
                                            )
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                    ])
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "b-card",
              {
                staticClass: "mb-2 inner-bg round-bottom border-top-0",
                attrs: {
                  title: "Export settings",
                  "body-class": "pt-0",
                  "title-tag": "h5",
                },
              },
              [
                _c("div", { staticClass: "px-2" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col-3" }, [
                      _vm._v(
                        "\n                            File type\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c("div", { staticClass: "col-10" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-shrink-1 justify-content-center",
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.type,
                                    expression: "type",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.type = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { domProps: { value: "json" } }, [
                                  _vm._v("JSON"),
                                ]),
                                _vm._v(" "),
                                _c("option", { domProps: { value: "csv" } }, [
                                  _vm._v("CSV"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _vm._v(
                        "\n                            Job time\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c("div", { staticClass: "col-10" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-shrink-1 justify-content-center",
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.timezone,
                                    expression: "timezone",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.timezone = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  { domProps: { value: "local-job" } },
                                  [_vm._v("Local job time")]
                                ),
                                _vm._v(" "),
                                _c("option", { domProps: { value: "utc" } }, [
                                  _vm._v("UTC"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "row pt-2" }, [
                    _c("div", { staticClass: "col-3" }, [
                      _vm._v(
                        "\n                            Time format\n                        "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-3" }, [
                      _c("div", { staticClass: "col-10" }, [
                        _c(
                          "div",
                          {
                            staticClass: "flex-shrink-1 justify-content-center",
                          },
                          [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.timeFormat,
                                    expression: "timeFormat",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.timeFormat = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { domProps: { value: "unix" } }, [
                                  _vm._v("Unix"),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  { domProps: { value: "db-datetime" } },
                                  [_vm._v("YYYY-MM-DD HH:mm:ss")]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]),
                  ]),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-2" }, [
                _c(
                  "div",
                  {
                    staticClass: "pr-0 mb-2",
                    staticStyle: { "max-width": "200px" },
                    attrs: { id: "advancedSettings" },
                    on: {
                      click: function ($event) {
                        _vm.showAdvancedSettings = !_vm.showAdvancedSettings
                      },
                    },
                  },
                  [
                    _vm._v("\n                        Advanced Options "),
                    _c("i", {
                      staticClass: "fas mx-1",
                      class: {
                        "fa-caret-up": _vm.showAdvancedSettings,
                        "fa-caret-down": !_vm.showAdvancedSettings,
                      },
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("transition", { attrs: { name: "slide" } }, [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showAdvancedSettings,
                          expression: "showAdvancedSettings",
                        },
                      ],
                    },
                    [
                      _c("b-card", { staticClass: "inner-bg" }, [
                        _c("div", { staticClass: "px-2" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "col-3" }, [
                              _vm._v(
                                "\n                                        Aggregate Interval\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-3" }, [
                              _c("div", { staticClass: "col-10" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-shrink-1 justify-content-center",
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.aggregateInterval,
                                            expression: "aggregateInterval",
                                          },
                                        ],
                                        staticClass:
                                          "form-control form-control-sm",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.aggregateInterval = $event
                                              .target.multiple
                                              ? $$selectedVal
                                              : $$selectedVal[0]
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.aggregateValues,
                                        function (interval, key) {
                                          return _c(
                                            "option",
                                            {
                                              key: key,
                                              domProps: { value: interval },
                                            },
                                            [_vm._v(_vm._s(key))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-3" }, [
                              _vm._v(
                                "\n                                        Paging Window\n                                    "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-3 d-flex" }, [
                              _c("div", { staticClass: "col-5" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-shrink-1 justify-content-center",
                                  },
                                  [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value: _vm.pagingWindow.value,
                                          expression: "pagingWindow.value",
                                        },
                                      ],
                                      staticClass:
                                        "form-control form-control-sm",
                                      attrs: {
                                        type: "number",
                                        min: "1",
                                        max: "100",
                                      },
                                      domProps: {
                                        value: _vm.pagingWindow.value,
                                      },
                                      on: {
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.pagingWindow,
                                            "value",
                                            $event.target.value
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-7" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "flex-shrink-1 justify-content-center",
                                  },
                                  [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: _vm.pagingWindow.interval,
                                            expression: "pagingWindow.interval",
                                          },
                                        ],
                                        staticClass:
                                          "form-control form-control-sm",
                                        on: {
                                          change: function ($event) {
                                            var $$selectedVal =
                                              Array.prototype.filter
                                                .call(
                                                  $event.target.options,
                                                  function (o) {
                                                    return o.selected
                                                  }
                                                )
                                                .map(function (o) {
                                                  var val =
                                                    "_value" in o
                                                      ? o._value
                                                      : o.value
                                                  return val
                                                })
                                            _vm.$set(
                                              _vm.pagingWindow,
                                              "interval",
                                              $event.target.multiple
                                                ? $$selectedVal
                                                : $$selectedVal[0]
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.pagingValues,
                                        function (interval, key) {
                                          return _c(
                                            "option",
                                            {
                                              key: key,
                                              domProps: { value: key },
                                            },
                                            [_vm._v(_vm._s(interval))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ]
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex w-100" }, [
              _c("div", { staticClass: "flex-grow-1" }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary grey-button",
                  attrs: { type: "button" },
                  on: { click: () => _vm.changeModalVisibility(false) },
                },
                [_vm._v("Close")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "px-2" }),
              _vm._v(" "),
              _c("div", { attrs: { id: "exportButtonContainer" } }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success green-button",
                    attrs: {
                      type: "button",
                      id: "exportButton",
                      disabled:
                        _vm.isFetching ||
                        _vm.isExportIntervalValid ||
                        _vm.noTagsSelected,
                    },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onExportPressed.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "d-flex" }, [
                      _c("div", { staticClass: "col-4" }, [
                        _vm.isFetching
                          ? _c("span", {
                              staticClass:
                                "spinner-border spinner-border-sm pr-2",
                              attrs: { role: "status", "aria-hidden": "true" },
                            })
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("div", [
                        _vm._v(
                          "\n                                Export\n                            "
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "b-popover",
              {
                attrs: {
                  target: "exportButton",
                  placement: "top",
                  show: _vm.isExportIntervalValid,
                },
                on: {
                  "update:show": function ($event) {
                    _vm.isExportIntervalValid = $event
                  },
                },
              },
              [
                _c("span", { staticStyle: { color: "white" } }, [
                  _vm._v("Start time must be less than end time"),
                ]),
              ]
            ),
            _vm._v(" "),
            _vm.noTagsSelected
              ? _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "exportButtonContainer",
                      placement: "top",
                      triggers: "hover",
                    },
                  },
                  [
                    _c("span", { staticStyle: { color: "white" } }, [
                      _vm._v("At least one tag must be selected for export"),
                    ]),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }