var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex flex-grow-1 h-100", attrs: { id: "wellCol" } },
    [
      _vm.well
        ? _c(
            "div",
            {
              class: "row justify-content-center" + _vm.stretch ? "w-100" : "",
            },
            [
              _c(
                "div",
                {
                  staticClass: "text-center rounded p-1 m-0 py-2",
                  class: _vm.colSize,
                  style: "height:" + _vm.headerHeight,
                  attrs: { id: "header" },
                },
                [
                  _vm.well.activity == "wireline"
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _vm.isMultiWireline
                            ? _c("h6", { staticClass: "text-center mt-0" }, [
                                _vm._v(_vm._s(_vm.wirelineTruckName)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("wireline-activity", {
                            style: _vm.wellActivityIconSize,
                            attrs: { spoolColor: _vm.wirelineTruckColor },
                          }),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticClass: "text-center mt-0",
                              staticStyle: { "text-transform": "uppercase" },
                            },
                            [_vm._v(_vm._s(_vm.well.activity))]
                          ),
                        ],
                        1
                      )
                    : _vm.well.activity == "frac" &&
                      _vm.well.cfChangeoverState == "to"
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _vm.isMultiFrac
                            ? _c("h6", { staticClass: "text-center mt-0" }, [
                                _vm._v(_vm._s(_vm.fracTruckName)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("continuous-frac-to-activity", {
                            style: _vm.wellActivityIconSize,
                            attrs: { color: "#FFFFFF" },
                          }),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticClass: "text-center mt-0 pt-1",
                              staticStyle: { "text-transform": "uppercase" },
                            },
                            [_vm._v("Cont. Frac")]
                          ),
                        ],
                        1
                      )
                    : _vm.well.activity == "frac"
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _vm.isMultiFrac
                            ? _c("h6", { staticClass: "text-center mt-0" }, [
                                _vm._v(_vm._s(_vm.fracTruckName)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c("frac-activity", {
                            style: _vm.wellActivityIconSize,
                            attrs: { color: "#FFFFFF" },
                          }),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticClass: "text-center mt-0",
                              staticStyle: { "text-transform": "uppercase" },
                            },
                            [_vm._v(_vm._s(_vm.well.activity))]
                          ),
                        ],
                        1
                      )
                    : _vm.well.activity == "maintenance"
                    ? _c(
                        "div",
                        { staticClass: "d-flex flex-column" },
                        [
                          _c("maintenance-activity", {
                            style: _vm.wellActivityIconSize,
                            attrs: { color: "#FFFFFF" },
                          }),
                          _vm._v(" "),
                          _c(
                            "h6",
                            {
                              staticClass: "text-center mt-0",
                              staticStyle: { "text-transform": "uppercase" },
                            },
                            [_vm._v(_vm._s(_vm.well.activity))]
                          ),
                        ],
                        1
                      )
                    : _c("img", {
                        staticClass: "m-0 pb-2",
                        class: { "black-tint": !this.$root.darkMode },
                        style: _vm.wellActivityCheckSize,
                        attrs: { src: _vm.image },
                      }),
                  _vm._v(" "),
                  _vm.showWellLockToggle
                    ? _c("lock-button", {
                        attrs: {
                          activeTooltipText: "Hide Valve Locks",
                          inactiveTooltipText: "Show Valve Locks",
                          localStorageKey: "ShowWellLocks",
                          onValueChanged: (value) =>
                            (_vm.showWellLocksLocal = value),
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "text-center p-0 m-0",
                  class: _vm.colSize,
                  style: _vm.wellImageSize,
                },
                [
                  _vm.well.configuration.components.length
                    ? _c(
                        "div",
                        { staticClass: "h-100" },
                        [
                          _c("well", {
                            ref: "well",
                            attrs: {
                              well: _vm.well,
                              showWellLocks:
                                _vm.showWellLocks || _vm.showWellLocksLocal,
                              canvasHeight: _vm.wellImageSize["max-height"],
                              useDynamicOrigin: false,
                              maxStretch: 2,
                            },
                          }),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        {
                          ref: _vm.imgId,
                          staticClass:
                            "png-well-component d-flex justify-content-center pl-2",
                        },
                        [
                          _vm.well.configurationId
                            ? _c("img", {
                                key: _vm.imgId,
                                ref: "wellConfigImage",
                                staticClass: "img-fluid",
                                staticStyle: { "max-height": "inherit" },
                                attrs: {
                                  src:
                                    "/get-wellhead-image?config_number=" +
                                    _vm.well.configurationId,
                                  id: _vm.imgId,
                                },
                              })
                            : _c("img", {
                                style: { "background-color": _vm.color },
                                attrs: { src: _vm.wellImage },
                              }),
                          _vm._v(" "),
                          _vm._l(_vm.well.valves, function (valve, index) {
                            return _c("valve-component", {
                              key: index,
                              attrs: {
                                position: valve?.position?.value,
                                unlocked: valve?.unlocked?.value,
                                config: _vm.well.configurationId,
                                imgId: _vm.imgId,
                                index: index,
                                showWellLocks:
                                  _vm.showWellLocks || _vm.showWellLocksLocal,
                              },
                            })
                          }),
                        ],
                        2
                      ),
                  _vm._v(" "),
                  _vm.pressureCrown !== false
                    ? _c(
                        "div",
                        {
                          staticStyle: {
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                          },
                        },
                        [
                          _c("div", { staticClass: "border rounded p-1" }, [
                            _c("div", [_vm._v(_vm._s(_vm.pressureCrown))]),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticStyle: { "font-size": "0.7em" } },
                              [_vm._v(_vm._s(_vm.pressureUnit))]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "0px",
                        right: "0px",
                      },
                    },
                    [
                      _c("div", { staticClass: "border rounded p-1 mb-1" }, [
                        _c("div", [_vm._v(_vm._s(_vm.pressure))]),
                        _vm._v(" "),
                        _c("div", { staticStyle: { "font-size": "0.7em" } }, [
                          _vm._v(_vm._s(_vm.pressureUnit)),
                        ]),
                      ]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex flex-column text-center rounded pr-1 pl-1 m-0 pb-2 text-dark font-weight-bold",
                  class: {
                    border: !_vm.well.configurationId,
                    "border-secondary": !_vm.well.configurationId,
                    "bg-light": !_vm.well.configurationId,
                    ..._vm.colSize,
                  },
                  style: {
                    "background-color": _vm.well.configurationId
                      ? _vm.color
                      : undefined,
                    overflow: "hidden",
                    "white-space": "nowrap",
                    "max-height": _vm.getMaxHeight,
                  },
                  attrs: { id: _vm.displayIdName + _vm.index },
                },
                [
                  _c(
                    "h5",
                    {
                      staticClass: "m-0 pb-2",
                      style: {
                        color: _vm.well.configurationId
                          ? _vm.getTitleColorForBG(_vm.color)
                          : undefined,
                      },
                    },
                    [_vm._v(_vm._s(_vm.name))]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "well-label text-wrap",
                      style: {
                        color: _vm.well.configurationId
                          ? _vm.getTitleColorForBG(_vm.color)
                          : undefined,
                      },
                    },
                    [
                      _vm._v(
                        _vm._s(_vm.status) +
                          ": " +
                          _vm._s(_vm.currentStage) +
                          "/" +
                          _vm._s(_vm.numberOfStages)
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.stageStart
                    ? _c(
                        "div",
                        {
                          staticClass: "well-label text-wrap",
                          style: {
                            color: _vm.well.configurationId
                              ? _vm.getTitleColorForBG(_vm.color)
                              : undefined,
                          },
                        },
                        [_vm._v("Duration: " + _vm._s(_vm.duration))]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }