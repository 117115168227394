<template>
<iws-modal
    :showModal="showHistoryModal"
    max-width="750px"
    max-height="95vh"

    secondaryButtonText="Return"
    :primaryButtonVisible="false"

    @close="resolve(null)"
    @secondary-action="resolve(null)"

    id="Time_Series_History"
>
    <template #title>
        History ({{ selectedConfig?.templateName }})
    </template>

    <template #content>
        <iws-table v-if="showHistoryModal && !_isNullOrEmpty(versionHistory)"
            :items="versionHistory"
            :columns="chartConfigHistoryColumns"
            :sortByCol.sync="sortByCol"
            :sortAsc.sync="sortAsc"
        >
            <template v-for="version in versionHistory" v-slot:[`row_${version.id}`]="{ data }">
                <tr>
                    <td>
                        {{ _dateTimeDisplayUTC(data?.item?.created_at) }}
                    </td>
                    
                    <td>
                        {{ data?.item?.owned_by }}
                    </td>

                    <td style="width: 110px; padding: 5px 12px">
                        <iws-button style="width: 100px;"
                            type="outline-primary"
                            :text="data?.item?.changeDetails ? 'Hide Details' : 'Compare'"
                            size="sm"
                            :disabled="selectedConfig?.id === data.item.id"
                            :click="() => openConfigCompare(data.item)"
                        />
                    </td>
                </tr>

                <tr v-if="data?.item?.changeDetails" class="full-width">
                    <td colspan="3">
                        <template v-for="changeData in data?.item?.changeDetails.changes">
                            <iws-table
                                :items="changeData.changes"
                                :columns="changeLogColumns"
                                sortByCol="readableProperty"
                            >
                                <template #cell_compare="{ data }">
                                    <span :style="{ 'color': changeLogPropertyTextColor(data.item.readableProperty) }">
                                        {{ data.item.readableProperty }}
                                    </span>
                                </template>
                            </iws-table>

                            <iws-button
                                text="Restore"
                                type="primary"
                                style="position: relative; top: -35px;"
                                :click="() => restoreVersion(data?.item?.changeDetails?.compareVersion)"
                                :disabled="!hasPermission"
                            />
                        </template>
                    </td>
                </tr>
            </template>
        </iws-table>
    </template>
</iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { toast, iwsConfirm, isFalsy, isNullOrEmpty, getCSRFToken } = GlobalFunctions;

import DateFunctions from '../../DateFunctions.js';
const { dateTimeDisplay } = DateFunctions;

export default {
    props: {
        jobNumber: String,
        dashboardItem: Object
    },

    data: () => ({
        showHistoryModal: false,
        versionHistory: null,
        selectedConfig: null,
        hasPermission: false,
        resolve: null,

        chartConfigHistoryColumns: [{
            key: 'created_at',
            label: 'Date / Time'
        }, {
            key: 'owned_by',
            label: 'User'
        }, {
            key: 'compare',
            label: '',
            sortable: false
        }],
        changeLogColumns: [{
            key: 'readableProperty',
            label: 'Property'
        }, {
            key: 'versionValue',
            label: 'Old Value',
            sortable: false
        }, {
            key: 'currentValue',
            label: 'Current Value',
            sortable: false
        }],
        sortByCol: 'created_at',
        sortAsc: false
    }),

    methods: {
        _isFalsy: (val) => isFalsy(val),
        _isNullOrEmpty: (val) => isNullOrEmpty(val),
        _dateTimeDisplayUTC: (val) => dateTimeDisplay(new Date(val+' UTC')),

        open(versionHistory, selectedConfig, hasPermission) {
            if (!isNullOrEmpty(versionHistory)) {
                this.versionHistory = versionHistory;
                this.selectedConfig = selectedConfig;
                this.hasPermission = hasPermission;
                this.showHistoryModal = true;
                
                return new Promise( (resolve, reject) => {
                    this.resolve = (value) => {
                        this.showHistoryModal = false;
                        this.versionHistory = null;
                        this.hasPermission = null;
                        this.selectedConfig = null;

                        resolve(value);
                    };

                    this.showBaseModal = true;
                });
            } else {
                toast({
                    title: 'Nothing to show',
                    variant: 'danger'
                });
            }
        },

        async restoreVersion(config) {
            if (isFalsy(config))
                return toast({
                    title: 'Cannot find restore config!',
                    variant: 'danger'
                });

            if (config.isDefault == 0 && this.selectedConfig.isDefault == 1) {
                var message = 'This action will remove selected template as default and restore previous default configuration. Would you like to proceed?';
            } else if (config.isDefault == 1 && this.selectedConfig.isDefault == 0) {
                var message = 'This action will replace current default with old default config that is being restored. Would you like to proceed?';
            } else {
                var message = 'Do you want the chart configuration to be restored?';
            }

            const _answer = await iwsConfirm({
                title: 'Restoring chart configuration',
                body: message,
                width: '420px'
            });

            if (!_answer)
                return toast({
                    title: 'Canceled restoring config',
                    variant: 'warning'
                });

            return $.post('/user/config/restore', {
                _token: getCSRFToken(),
                jobNumber: this.jobNumber,
                id: config.id,
                version_id: config.version_id,
                selectedConfigId: this.selectedConfig.id,
                dashboardId: this.dashboardItem.id || null,
                dashboardItemIndex: this.dashboardItem?.i || null
            }).then(result => {
                if (result.error) {
                    console.warn(result.message);
                } else {
                    location.reload();
                }
            });
        },
        configCompare(compareConfig) {
            const changeLog = [];
            const pushChange = (identityProperty, prop, uniqueKey, currentValue, versionValue) => {
                let setup = changeLog.find(o => o?.property == 'chartSetup');

                if (isFalsy(setup)) {
                    // If setup has not been initialized, push one and assign setup to the pushed object
                    changeLog.push({ property: 'chartSetup', changes: [] });
                    setup = changeLog[changeLog?.length-1];
                }

                // Push to setup
                setup.changes.push({ identityProperty, prop, uniqueKey, currentValue, versionValue });
            }
            const customAxisLabel = () => {
                //need to check both here so that updates to the selected axis re-compute this variable output
                if (this.selectedConfig.chartXAxis)
                    return `Custom: ${this.selectedConfig.chartXAxis?.label ?? 'No Label'} Gridlines`;
                return 'Custom: No Axis Created Gridlines';
            };
            const inputLabelLookup = (key) => {//converts a property name stored in the db to what is displayed as the label in the model
                const specialLabels = {//labels that differ from their db column or JSON key names in a non-predictable way
                    //chart setup
                    'controlsPosition': 'Control Panel Location', 
                    'controlsSize': 'Control Panel Size',
                    'header_style': 'Header Style',
                    'rightSideMsOffset': 'End Time Offset (hours)',
                    'resolutionZoomHours': 'Default Resolution Zoom (hours)',
                    'pinRightSideMsOffset': 'Pin to Latest Data',
                    'isVertical': 'VerticalChart',
                    'isDateAxisHidden': 'Hide Date Axis', 
                    'showVerticalGridlines': customAxisLabel(),
                    'isDefault': 'Customer Default', 
                    //chart axes
                    'min': 'Minimum',
                    'max': 'Maximum',
                    'ticks': '# of Tick Intervals',
                    'isPrimaryAxis': 'PrimaryAxis',
                    'logarithmic': 'Logarithmic Scale',
                    'hideYAxis': 'Hide Axis',
                    //chart Item
                    'bgColor': 'Custom Background Color',
                    //misc
                    'label': 'Name',
                    'tagName': 'Source Tag Name'
                };
                if (Object.keys(specialLabels).includes(key)) {
                    return specialLabels[key];
                } else {
                    return _.startCase(key);//converts camelCase key name to Title Case
                }
            };
            const modifyChangeLogPropertyValue = (property, value) => {
                //converts a special value from what it is stored as in the db to what it is displayed as in the modal
                switch(property) {
                    case 'rightSideMsOffset':
                        return value / 60 / 60 / 1000 // milliseconds to hours
                    case 'hideChart':
                    case 'isDateAxisHidden':
                    case 'isDefault':
                    case 'isVertical':
                    case 'pinRightSideMsOffset':
                    case 'shared':
                    case 'showLegend':
                    case 'showNPTLines':
                    case 'showstageLines':
                    case 'showVerticalGridlines':
                    case 'hasValidationError':
                    case 'hideCommentsTimeline':
                        return value === 1 || value === true ? 'True' : 'False';
                    default:
                        return null;
                }
            };

            //get the nested JSON config data from each template
            const currentConfigData = _.cloneDeep(this.selectedConfig);
            const compareConfigData = _.cloneDeep(compareConfig);
            const currentSections = !isFalsy(currentConfigData?.data) ? JSON.parse(currentConfigData?.data)?.orderedSections : null;
            const versionSections = !isFalsy(compareConfigData?.data) ? JSON.parse(compareConfigData?.data)?.orderedSections : null; 
            
            //keys that will have their values modified from what is stored in the db for display in the change Log
            const customModifyValueKeys = [
                'rightSideMsOffset','hideChart','isDateAxisHidden','isDefault','isVertical','pinRightSideMsOffset', 'shared',
                'showLegend','showNPTLines','showstageLines','showVerticalGridlines','hasValidationError','hideCommentsTimeline'
            ];
            //keys to omit from changelog analysis
            const changeLogOmitKeys = [
                'id', 'created_at', 'updated_at', 'deleted_at', 'owned_by', 'user_role', 'index','oldTagname','changeDetails', 'data', 'chartYAxes', 'chartXAxis'
            ];
            const propertyDisplayNameLookup = {
                chartSetup: 'templateName',
                chartYAxes: 'label',
                chartXAxis: 'label', 
                orderedSections: 'name' ,
                chartItemChanges: 'tagName' 
            };

            // Loop through the data / orderSections objects to search for changes made in nested objects
            currentSections.forEach(section => {
                const matchedSection = versionSections.find(_item => _item.key == section.key);

                if (!isFalsy(matchedSection)) {
                    section.orderedChartItems.forEach(chartItem => {
                        const matchedChartItem = matchedSection.orderedChartItems.find(_chartItem => _chartItem.key == chartItem.key) || matchedSection.orderedChartItems.find(_chartItem => _chartItem.tagName == chartItem.tagName);

                        if (!isFalsy(matchedChartItem)) {
                            Object.keys(chartItem).forEach(_key => {
                                if (_key !== 'key' && typeof chartItem[_key] != 'object' && chartItem[_key] !== matchedChartItem[_key])
                                    pushChange(`${chartItem.friendlyName} change`, `Chart Item: ${_key}`, _key, chartItem[_key], matchedChartItem[_key]);
                            });
                        } else {
                            const chartName = chartItem.friendlyName || chartItem.tagName;
                            pushChange(`${section.name} Chart Item`, 'New Chart Item', chartName, chartName, null);
                        }
                    });
                } else {
                    pushChange('Section', 'New Section', section.name, section.name, null);
                }
            });

            // Search the version data for nested objects that no longer exist (were deleted)
            versionSections.forEach(section => {
                const matchedSection = currentSections.find(_item => _item.key == section.key);

                if (!isFalsy(matchedSection)) {
                    section.orderedChartItems.forEach(chartItem => {
                        const matchedChartItem = matchedSection.orderedChartItems.find(_chartItem => _chartItem.key == chartItem.key) || matchedSection.orderedChartItems.find(_chartItem => _chartItem.tagName == chartItem.tagName);

                        if (isFalsy(matchedChartItem)) {
                            const chartName = chartItem.friendlyName || chartItem.tagName;
                            pushChange(`${section.name} Chart Item`, 'Deleted Chart Item', chartName, null, chartName);
                        }
                    });
                } else {
                    pushChange('Section', 'Deleted Section', section.name, null, section.name);
                }
            });

            //get a unique set of all keys from the templates, minus any keys to be omitted
            const data = _.uniq([
                ...Object.keys(compareConfigData, ...Object.keys(currentConfigData))
            ]).filter(key => !changeLogOmitKeys.includes(key));

            //iterate through the column values of the templates looking for changes
            for (const key of data)
                if (!_.isEqual(this.selectedConfig[key], compareConfigData[key]))
                    pushChange(
                        this.selectedConfig[propertyDisplayNameLookup['chartSetup']] || null,
                        key,
                        null,
                        this.selectedConfig[key] || null,
                        compareConfigData[key] || null
                    );

            // Convert prop keys to human readable values
            changeLog.forEach((table, tableIndex) => {
                table.changes.forEach((change, changeIndex) => {
                    changeLog[tableIndex].changes[changeIndex].readableProperty = inputLabelLookup(change.prop);
                    
                    // Before and after values to more straight forward representations
                    if (customModifyValueKeys.includes(change.prop)) {
                        changeLog[tableIndex].changes[changeIndex].versionValue = modifyChangeLogPropertyValue(change.prop, change.versionValue);
                        changeLog[tableIndex].changes[changeIndex].currentValue = modifyChangeLogPropertyValue(change.prop, change.currentValue);
                    }
                });
            });

            if (isNullOrEmpty(changeLog)) {
                toast({
                    title: 'No changes found to compare!',
                    variant: 'warning'
                });
            } else {
                this.$set(compareConfig, 'changeDetails', {
                    currentVersion: currentConfigData,
                    compareVersion: compareConfigData,
                    changes: changeLog
                });
            }
        },
        openConfigCompare(compareConfig) {
            // If does not exist, add the changelog
            // If it does, we clear it to hide the table for this config version
            if (!compareConfig.changeDetails) {
                this.configCompare(compareConfig);
            } else {
                compareConfig.changeDetails = null;
            }
        },

        changeLogPropertyTextColor(property) {
            if (property === 'Delete')
                return 'red';
            if (property === 'Create')
                return 'green'
            return 'white';
        }
    }
}
</script>

<style>
    #Time_Series_History .content {
        padding: 0px 1.5em 1.5em 1.5em !important;
    }
    #Time_Series_History table {
        margin-top: 1.5em !important;
    }
</style>