var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "accordion" } },
    _vm._l(_vm.cards, function (card, index) {
      return _c("div", { staticClass: "card" }, [
        _c(
          "div",
          {
            ref: `accordian_${index}`,
            refInFor: true,
            staticClass: "card-header clickable",
            class: { "active-header": _vm.isActive(index) },
            attrs: {
              id: _vm.isEnabled(card) ? `heading_${index}` : null,
              "data-toggle": "collapse",
              "data-target": _vm.isEnabled(card) ? `#collapse_${index}` : null,
              "aria-expanded": "true",
              "aria-controls": _vm.isEnabled(card) ? `collapse_${index}` : null,
            },
            on: {
              click: function ($event) {
                return _vm.handleClick(card, index)
              },
            },
          },
          [
            _c("h5", { staticClass: "mb-0" }, [
              _vm._v(
                "\n                " +
                  _vm._s(card?.title || card) +
                  "\n            "
              ),
            ]),
            _vm._v(" "),
            _c("i", { staticClass: "fas fa-chevron-down" }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "collapse",
            attrs: {
              id: `collapse_${index}`,
              "aria-labelledby": `heading_${index}`,
              "data-parent":
                _vm.isEnabled(card) && !_vm.allowMultiTabs
                  ? `#accordion`
                  : null,
            },
          },
          [
            _c(
              "div",
              { staticClass: "card-body" },
              [
                _vm._t(`card-body_${index}`, function () {
                  return [
                    _vm._v(
                      "\n                    Card " +
                        _vm._s(index) +
                        "\n                "
                    ),
                  ]
                }),
              ],
              2
            ),
          ]
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }