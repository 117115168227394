<template>
  <div v-show="tooltipExists">
    <b-popover :target="'tooltip-' + _uid" 
        custom-class="white-popover"
        triggers="hover" 
        placement="right" 
        :boundary-padding="0" 
        :delay="{show: 0, hide: 0}" 
        boundary="viewport">
        <div class="tooltipText">{{ tooltipText }}</div>
    </b-popover>
    <h4 class="ml-2" :id="'tooltip-' + _uid">
        <i class="fas fa-info-circle"></i>
    </h4>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        tooltipText: "",
        tooltipExists: false
      }
    },
    props:['tooltip_key', 'customer_id'],
    mounted() {
      this.getTooltip();
    },
    methods:{
      getTooltip: function()
      {
        const url = '/tooltip/' + this.tooltip_key;
        const self = this;
  
        $.get(
            url,
            {customerId: this.customer_id},
            async function(data) {
              if (data.tooltipExists)
              {
                self.tooltipText = data.message;
                self.tooltipExists = true;
              } 
              else
              {
                self.tooltipExists = false;
              }  
            },
            'json'
        ).fail(function( jqXHR, textStatus, errorThrown ) {
            if(jqXHR.status == 401) {
                console.warn('unauthorized');
            }
            self.tooltipText = '[Error retrieving tooltip]';
        });
      },
    }
  }
</script>
<style scoped>
  .white-popover {
    background-color: white;
    font-weight: bold;
  }
  .white-popover > .popover-body {
      white-space: pre-wrap !important;
  }

  .tooltipText {
    white-space: pre-wrap;
  }
</style>