var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("iws-breadcrumbs", {
        staticClass: "mt-4 mb-1",
        attrs: {
          path: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "Copy Remote Job",
              link: "/distinctJobs",
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "page-title mb-4" }, [
        _vm._v("\n        Select Job to Sync\n    "),
      ]),
      _vm._v(" "),
      _c("b", [
        _vm._v(
          "**** Warning this is a developer tool and may overwrite your data, please use with caution. ****"
        ),
      ]),
      _vm._v(" "),
      _c("br"),
      _vm._v(
        "\n    This is a list of Job Numbers that were found using the Mongo/Cosmos connection string in the .env\n    "
      ),
      _c("br"),
      _vm._v(
        "\n    If you don't see a Job Number you expect, use the startTime URL parameter with UTC timestamp (milliseconds)\n\n    "
      ),
      _c(
        "ul",
        _vm._l(_vm.jobNumbers, function (jobNumber) {
          return _c("li", [
            _c(
              "a",
              { attrs: { href: `/createNewFromRemoteJob/${jobNumber}` } },
              [
                _vm._v(
                  "\n                " + _vm._s(jobNumber) + "\n            "
                ),
              ]
            ),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }