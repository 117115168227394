var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "badge", class: `badge-${_vm.type}` },
    [
      _vm._t("text", function () {
        return [_vm._v("\n        " + _vm._s(_vm.text) + "\n    ")]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }