<template>
    <div class="container-fluid text-center ">
        <p>&nbsp;</p>
        <div class="row no-gutters m-3">
            <div class="col-6">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" :style="progressStyle" :class="progressClass">
                        <span style="font-size:large;">Last message (s): {{messageDelay/1000}}</span>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <scatter-chart ref="latencyLineChart" :height="50" :chart-data="latencydatacollection" :options="latencyoptions"></scatter-chart>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <scatter-chart ref="timeLineChart" :chart-data="timedatacollection" :options="timeoptions"></scatter-chart>
                <div v-if="realtimedata.wireline" class="mx-0 row justify-content-between">
                    <div class="col-4 text-center border border-secondary rounded mb-1">
                        <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Depth (ft)</p>
                        {{currentWirelineValue(realtimedata.wireline.wireline_1_depth_rt)}}
                    </div>
                    <div class="col-4 text-center border border-secondary rounded mb-1">
                        <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">CCL</p>
                        {{currentWirelineValue(realtimedata.wireline.wireline_1_ccl_tool)}}
                    </div>
                    <div class="col-4 text-center border border-secondary rounded mb-1">
                        <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Tension</p>
                        {{currentWirelineValue(realtimedata.wireline.wireline_1_line_tension)}}
                    </div>
                    <div class="col-4 text-center border border-secondary rounded mb-1">
                        <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Speed</p>
                        {{currentWirelineValue(realtimedata.wireline.wireline_1_line_speed)}}
                    </div>
                    <div class="col-4 text-center border border-secondary rounded mb-1">
                        <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Pumpdown</p>
                        {{currentWirelineValue(realtimedata.wireline.pumpdown_1_rate)}}
                    </div>
                    <div class="col-4 text-center border border-secondary rounded mb-1">
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="row">
                    <div class="col-6">
                        <scatter-chart ref="cclLineChart" :chart-data="ccldatacollection" :options="ccloptions"></scatter-chart>
                    </div>
                    <div class="col-6">
                        <scatter-chart ref="tensionLineChart" :chart-data="tensiondatacollection" :options="tensionoptions"></scatter-chart>
                    </div>
                </div>
                <div>
                    <!-- <button v-on:click="depthDirection = depthDirection * -1">Simulate Depth Direction Changed</button> -->
                    <button v-on:click="windowLocked = !windowLocked">Toggle Window Locked ({{windowLocked}})</button>
                    <label class="ml-3">Depth Window Size (ft)</label>
                    <input type="number" min="0" v-model="windowSizeDepth" style="width: 4em;text-align: right;">
                </div>
            </div>
        </div>
        <auth-fail-component v-show="hasAuthError"></auth-fail-component>
    </div>
</template>

<style>
    .slide-fade-enter-active, .slide-fade-leave-active {
        transition: all 1s ease;
    }
    .slide-fade-enter, .slide-fade-leave-to {
        height: 0px;
        overflow-y: hidden;
        opacity: 0;
    }
    .progress {
        height: 30px;
        position: relative;
    }

    .progress span {
        position: absolute;
        display: block;
        width: 100%;
        color: black;
    }
</style>

<script>
import ScatterChart from '../scatterLineChart.js';
import moment from 'moment';
import * as signalR from '@microsoft/signalr';
import GlobalFunctions from '../GlobalFunctions.js';
import SignalRMixin from '../mixins/SignalRMixin.js';

const LATENCY_CHART_WINDOW = 60*1000;
const TIME_CHART_MAX_POINTS = 1000;
const MSG_DELAY_ERROR_THRESHOLD = 4000;
const MSG_DELAY_WARN_THRESHOLD = 3000;
const MSG_DELAY_SUCCESS_THRESHOLD = 2000;

export default {
    data() {
        return {
            previousDepth: null,
            depthDirection: 0, //tracks the direction the depth is going, -1 is negative, +1 is positive
            windowLocked: true, //if window is locked then graph will draw/follow at the top or bottom of the graph depending on depth direction
            windowSizeDepth: 150, //if windowLocked then this is the difference between max and min depth
            rawCCLData: {}, //raw data is a dictionary to enforce only unique values as new data comes in
            rawOldCCLData: [], //raw old data is an array because it is copied out of the already unique raw data
            rawTensionData: {},
            rawOldTensionData: [],
            historicalCCL: [],
            historicalDepth: [],
            historicalTension: [],
            historicalSpeed: [],
            historicalPumpdown: [],
            latencydatacollection: {
                datasets: [this.createDataset('Update Message Delay', '#786950')]
            },
            latencyoptions: {
                responsive: true,
                animation: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    yAxes: [{
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            callback: GlobalFunctions.commarize,
                            fontColor: 'white',
                            autoSkipPadding: 10,
                            maxRotation: 0
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Delay (ms)'
                        }
                    }],
                    xAxes: [{
                        type: 'linear',
                        ticks: {
                            callback: this.formatScaleWithJobLocalTime,
                            autoSkipPadding: 5,
                            maxRotation: 0,
                            fontColor: 'white'
                        }
                    }]
                },
                legend: {
                    display: false
                }
            },
            ccldatacollection: {
                datasets: [this.createDataset('CCL', '#CCCCCC'), this.createDataset('CCL (OLD)', '#666666')]
            },
            ccloptions: {
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'xy',
                            onPan: this.onPanZoom
                        },
                        zoom: {
                            enabled: true,
                            drag: false,
                            mode: 'x',
                            onZoom: this.onPanZoom
                        }
                    }
                },
                responsive: true,
                animation: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    yAxes: [{
                        id: 'depth-axis',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            callback: this.formatDepthScaleLabel,
                            fontColor: 'white',
                            reverse: true,
                            stepSize: 2
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        },
                        scaleLabel: {
                            display: true,
                            labelString: 'Depth (ft)'
                        }
                    }],
                    xAxes: [{
                        id: 'hover-axis',
                        type: 'linear',
                        ticks: {
                            min: -5,
                            max: 5,
                            fontColor: 'white'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    }]
                },
                tooltips: {
                    mode: 'index',
                    position: 'cursor',
                    intersect: false
                },
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        fontColor: 'white',
                        filter: function(item, chart) {
                            return !item.text.includes('(OLD)');
                        }
                    }
                }
            },
            tensiondatacollection: {
                datasets: [this.createDataset('Tension', '#FF6633'), this.createDataset('Tension (OLD)', '#666666')]
            },
            tensionoptions: {
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'xy',
                            onPan: this.onPanZoom
                        },
                        zoom: {
                            enabled: true,
                            drag: false,
                            mode: 'x',
                            onZoom: this.onPanZoom
                        }
                    }
                },
                responsive: true,
                animation: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    yAxes: [{
                        id: 'depth-axis',
                        type: 'linear',
                        ticks: {
                            callback: this.formatDepthScaleLabel,
                            fontColor: 'white',
                            reverse: true,
                            stepSize: 2
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    }],
                    xAxes: [{
                        id: 'hover-axis',
                        type: 'linear',
                        ticks: {
                            min: 0,
                            max: 2500,
                            callback: GlobalFunctions.commarize,
                            fontColor: 'white'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        fontColor: 'white',
                        filter: function(item, chart) {
                            return !item.text.includes('(OLD)');
                        }
                    }
                }
            },
            timedatacollection: {
                datasets: [
                    this.createDataset('Depth', '#FFF700', 'depth-axis'),
                    this.createDataset('CCL', '#CCCCCC', 'ccl-axis'),
                    this.createDataset('Tension', '#FF6633', 'tension-axis'),
                    this.createDataset('Speed', '#FF8080', 'speed-axis'),
                    this.createDataset('Pumpdown', '#3287F5', 'pumpdown-axis')
                ]
            },
            timeoptions: {
                responsive: true,
                animation: false,
                elements: {
                    point: {
                        radius: 0
                    }
                },
                scales: {
                    yAxes: [{
                        id: 'depth-axis',
                        type: 'linear',
                        ticks: {
                            min: -100,
                            max: 25000,
                            callback: GlobalFunctions.commarize,
                            fontColor: '#FFF700'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    },
                    {
                        id: 'ccl-axis',
                        type: 'linear',
                        ticks: {
                            min: -5,
                            max: 5,
                            fontColor: '#CCCCCC'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    },
                    {
                        id: 'tension-axis',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            min: 0,
                            max: 2500,
                            callback: GlobalFunctions.commarize,
                            fontColor: '#FF6633'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    },
                    {
                        id: 'speed-axis',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            min: -1500,
                            max: 1500,
                            callback: GlobalFunctions.commarize,
                            fontColor: '#FF8080'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    },
                    {
                        id: 'pumpdown-axis',
                        type: 'linear',
                        position: 'right',
                        ticks: {
                            min: 0,
                            max: 10000,
                            callback: GlobalFunctions.commarize,
                            fontColor: '#3287F5'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    }],
                    xAxes: [{
                        type: 'linear',
                        ticks: {
                            callback: this.formatScaleWithJobLocalTime,
                            autoSkipPadding: 5,
                            maxRotation: 0,
                            fontColor: 'white'
                        },
                        gridLines: {
                            display: true,
                            color: '#444444'
                        }
                    }]
                },

                tooltips: {
                    mode: 'index',
                    enabled: true,
                    position: 'cursor',
                    intersect: false,
                    callbacks: {
                        label: this.formatTooltipWithJobLocalTime
                    }
                },
                legend: {
                    display: true,
                    position: 'top',
                    labels: {
                        fontColor: 'white'
                    }
                }
            },
            hasAuthError: false
        };
    },
    components: {
        ScatterChart
    },
    mixins: [SignalRMixin],
    computed: {
        progressClass() {
            let c = 'bg-danger';
            if (this.messageDelay < MSG_DELAY_WARN_THRESHOLD) {
                c = 'bg-warning';
            }
            if (this.messageDelay < MSG_DELAY_SUCCESS_THRESHOLD) {
                c = 'bg-success';
            }

            return c;
        },
        progressStyle() {
            let percent = this.messageDelay / MSG_DELAY_ERROR_THRESHOLD * 100;
            if(percent>100) {
                percent = 100;
            }
            return {
                'width': percent+'%'
            };
        }
    },
    props: [
        'jobNumber',
        'jobHourOffset',
        'systemName',
        'chartcomments',
        'cclTensionSpeedDepth',
        'sessionId'
    ],
    mounted() {
        const allDepths = this.cclTensionSpeedDepth['wireline_1_depth_rt'];
        const allCCL = this.cclTensionSpeedDepth['wireline_1_ccl_tool'];
        const allTension = this.cclTensionSpeedDepth['wireline_1_line_tension'];
        const allSpeed = this.cclTensionSpeedDepth['wireline_1_line_speed'];
        const allPumpdown = this.cclTensionSpeedDepth['pumpdown_1_rate'];
        this.populateHistoricalData(allDepths, allCCL, allTension, allSpeed, allPumpdown);


        this.initializeSignalRConnection(this.sessionId, this.newMessageHandler);
        setInterval(this.updateMessageDelay,100);
    },
    methods: {
        onPanZoom: function(chartEvent) {
            this.windowLocked = false;

            const minDepth = chartEvent.chart.scales['depth-axis'].min;
            const maxDepth = chartEvent.chart.scales['depth-axis'].max;

            const tensionLineChart = this.$refs['tensionLineChart'].$data._chart;
            const cclLineChart = this.$refs['cclLineChart'].$data._chart;

            if(chartEvent.chart == cclLineChart) {
                tensionLineChart.options.scales.yAxes[0].ticks.min = minDepth;
                tensionLineChart.options.scales.yAxes[0].ticks.max = maxDepth;
                tensionLineChart.update();
            }
            else if(chartEvent.chart == tensionLineChart) {
                cclLineChart.options.scales.yAxes[0].ticks.min = minDepth;
                cclLineChart.options.scales.yAxes[0].ticks.max = maxDepth;
                cclLineChart.update();
            }
        },
        createDataset: function(label, color, yAxisID) {
            return {
                data: [],
                label: label,
                borderColor: color,
                yAxisID: yAxisID,
                showLine: true,
                lineTension: 0.1,
                borderWidth: 1,
                cubicInterpolationMode: 'monotone',
                fill: false,
                hidden: false
            };
        },
        dictionaryToArray: function(dictionary) {
            //use cclChart min/max to filter out data outside the drawable range (memory optimization)
            const cclChart = this.$refs['cclLineChart'].$data._chart;
            const min = cclChart.options.scales.yAxes[0].ticks.min;
            const max = cclChart.options.scales.yAxes[0].ticks.max;

            const yCompare = function (a, b) {
                if (a.y < b.y) {
                    return -1;
                }
                if (a.y > b.y) {
                    return 1;
                }
                return 0;
            };
            const retArray = [];
            for(const x in dictionary) {
                const obj = {x: x, y: dictionary[x]};
                if(obj.y >= min && obj.y <= max) {
                    retArray.splice(this.locationOf(obj, retArray, yCompare) + 1, 0, obj);
                }
            }

            return retArray;
        },
        locationOf: function(element, array, comparer, start, end) {
            //enables more efficient way to sort the incoming time series data
            //by inserting into sorted array instead of sorting array after inserting
            if (array.length === 0) {
                return -1;
            }

            start = start || 0;
            end = end || array.length;
            const pivot = (start + end) >> 1;  // should be faster than dividing by 2

            const c = comparer(element, array[pivot]);
            if (end - start <= 1) {
                return c == -1 ? pivot - 1 : pivot;
            }

            switch (c) {
            case -1: return this.locationOf(element, array, comparer, start, pivot);
            case 0: return pivot;
            case 1: return this.locationOf(element, array, comparer, pivot, end);
            };
        },
        formatScaleWithJobLocalTime: function(value) {
            const datetimeValue = moment(value).utc();
            datetimeValue.add({hours: this.jobHourOffset});
            return datetimeValue.format('h:mm:ss a');
        },
        formatTooltipWithJobLocalTime: function(tooltipItem) {
            const datetimeValue = moment(parseInt(tooltipItem.label)).utc();
            datetimeValue.add({hours: this.jobHourOffset});
            return tooltipItem.value + ' at ' + datetimeValue.format('h:mm:ss a');
        },
        formatDepthScaleLabel: function(value, index, values) {
            const numMajorLabels = 10;
            const numValuesPerMajorLabel = Math.floor(values.length / numMajorLabels);
            if(index % numValuesPerMajorLabel == 0) {
                return GlobalFunctions.commarize(value);
            }
            return ' ';
        },
        currentWirelineValue(values) {
            if(values && values.length) {
                return values[values.length - 1];
            }

            return '--';
        },
        populateHistoricalData: function(allDepths, allCCL, allTension, allSpeed, allPumpdown) {
            //takes the data provided and keeps the most recent set of data with a consistent depth direction
            //fills in the following variables
            // this.rawCCLData
            // this.rawTensionData
            // this.historicalCCL
            // this.historicalDepth
            // this.historicalTension
            // this.historicalSpeed
            // this.historicalPumpdown

            let _previousDepth = null;
            let _depthDirection = 0;

            for(const index in allDepths) {
                const depthEntry = allDepths[index];
                const previousDepthDirection = _depthDirection;
                if(_previousDepth != null) {
                    if(_previousDepth > depthEntry.dataVal) {
                        _depthDirection = -1;
                    }
                    else {
                        _depthDirection = 1;
                    }
                }

                if(depthEntry.dataVal > 0) {
                    //only care about depth direction changes if depth is greater than 0
                    if(previousDepthDirection != _depthDirection && _previousDepth != null) {
                        // console.log('**** THE DEPTH DIRECTION CHANGED!!! ****');
                        //clear out main series
                        this.rawCCLData = {};
                        this.rawTensionData = {};

                        this.historicalCCL = [];
                        this.historicalDepth = [];
                        this.historicalTension = [];
                        this.historicalSpeed = [];
                        this.historicalPumpdown = [];
                    }
                }

                _previousDepth = depthEntry.dataVal;

                const cclEntry = allCCL.find(function(element) { return element.dateTimestamp == depthEntry.dateTimestamp; });
                if(cclEntry) {
                    this.historicalCCL.push({x: moment.utc(cclEntry.dateTimestamp), y: cclEntry.dataVal});
                    this.rawCCLData[cclEntry.dataVal] = depthEntry.dataVal;
                }

                const tensionEntry = allTension.find(function(element) { return element.dateTimestamp == depthEntry.dateTimestamp; });
                if(tensionEntry) {
                    this.historicalTension.push({x: moment.utc(tensionEntry.dateTimestamp), y: tensionEntry.dataVal});
                    this.rawTensionData[tensionEntry.dataVal] = depthEntry.dataVal;
                }

                const speedEntry = allSpeed.find(function(element) { return element.dateTimestamp == depthEntry.dateTimestamp; });
                if(speedEntry) {
                    this.historicalSpeed.push({x: moment.utc(speedEntry.dateTimestamp), y: speedEntry.dataVal});
                }

                const pumpdownEntry = allPumpdown.find(function(element) { return element.dateTimestamp == depthEntry.dateTimestamp; });
                if(pumpdownEntry) {
                    this.historicalPumpdown.push({x: moment.utc(pumpdownEntry.dateTimestamp), y: pumpdownEntry.dataVal});
                }

                this.historicalDepth.push({x: moment.utc(depthEntry.dateTimestamp), y: depthEntry.dataVal});
            }
        },
        //// IOT methods
        newMessageHandler(message) {
            const depthArray = message?.wireline?.wireline_1_depth_rt;
            let cclArray = message?.wireline?.wireline_1_ccl_tool;
            let tensionArray = message?.wireline?.wireline_1_line_tension;
            let speedArray = message?.wireline?.wireline_1_line_speed;
            let pumpdownArray = message?.wireline?.pumpdown_1_rate;

            if(cclArray == undefined) {
                cclArray = [];
            }
            if(tensionArray == undefined) {
                tensionArray = [];
            }
            if(speedArray == undefined) {
                speedArray = [];
            }
            if(pumpdownArray == undefined) {
                pumpdownArray = [];
            }

            //only update local data if we got a depth array
            if(depthArray && depthArray.length > 0) {
                this.realtimedata = message;
                this.lastMessage = Date.now();

                //calculate delay on message
                const timestamp = new Date();
                this.realtimedata.wireline.dT = (timestamp - message.msTimestamp) / 1000;
                //we need to know the direction that depth is moving because when it changes
                //we want to grey out the previous data (move the data to a different series with grey color)
                //TODO: specify a threshold for change

                const firstDepthReading = depthArray[0];
                const lastDepthReading = depthArray[depthArray.length - 1];
                const previousDepthDirection = this.depthDirection;
                if(this.previousDepth == null) {
                    //first time, check first and last reading to see if there is a direction of movement
                    if(firstDepthReading > lastDepthReading) {
                        this.depthDirection = -1;
                    }
                    else {
                        this.depthDirection = 1;
                    }
                }
                else {
                    //check previous reading and last reading to see if there is a direction of movement
                    if(this.previousDepth > lastDepthReading) {
                        this.depthDirection = -1;
                    }
                    else {
                        this.depthDirection = 1;
                    }
                }

                if(previousDepthDirection != this.depthDirection && this.previousDepth != null) {
                    // console.log('**** THE DEPTH DIRECTION CHANGED!!! ****');
                    //move the data from the main series to the old series (clear out old series first)
                    this.rawOldCCLData = [];
                    this.rawOldTensionData = [];
                    this.rawOldCCLData.push(...this.dictionaryToArray(this.rawCCLData));
                    this.rawOldTensionData.push(...this.dictionaryToArray(this.rawTensionData));
                    //clear out main series
                    this.rawCCLData = {};
                    this.rawTensionData = {};
                }

                //remember last depth to compare with next readings
                this.previousDepth = lastDepthReading;

                // console.log(depthArray, cclArray, tensionArray);

                //Update the CCL data
                const cclChart = this.$refs['cclLineChart'].$data._chart;
                for (let i = 0; i < cclArray.length; i++) {
                    //assert cclArray.length == depthArray.length
                    this.rawCCLData[cclArray[i]] = depthArray[i];
                }

                //Update the tension data
                const tensionChart = this.$refs['tensionLineChart'].$data._chart;
                for (let i = 0; i < tensionArray.length; i++) {
                    //assert tensionArray.length == depthArray.length
                    this.rawTensionData[tensionArray[i]] = depthArray[i];
                }

                if(this.windowLocked) {
                    //Set the min/max for ccl and tension charts
                    //if the depth direction is increasing then the current reading should be pinned to the top
                    //and vice versa
                    const windowSizeDepthInt = parseInt(this.windowSizeDepth);
                    if(this.depthDirection > 0) {
                        cclChart.options.scales.yAxes[0].ticks.max = lastDepthReading;
                        cclChart.options.scales.yAxes[0].ticks.min = lastDepthReading - windowSizeDepthInt;

                        tensionChart.options.scales.yAxes[0].ticks.max = lastDepthReading;
                        tensionChart.options.scales.yAxes[0].ticks.min = lastDepthReading - windowSizeDepthInt;
                    }
                    else {
                        cclChart.options.scales.yAxes[0].ticks.max = lastDepthReading + windowSizeDepthInt;
                        cclChart.options.scales.yAxes[0].ticks.min = lastDepthReading;

                        tensionChart.options.scales.yAxes[0].ticks.max = lastDepthReading + windowSizeDepthInt;
                        tensionChart.options.scales.yAxes[0].ticks.min = lastDepthReading;
                    }
                }

                //Update the time line data
                //remove the earliest items to make room for new ones, this is a naive memory optimization tactic
                const timeChart = this.$refs['timeLineChart'].$data._chart;
                for (let i = 0; i < depthArray.length; i++) {
                    this.historicalDepth.push({x: message.msTimestamp, y: depthArray[i]});
                    if(this.historicalDepth.length > TIME_CHART_MAX_POINTS) {
                        this.historicalDepth.shift();
                    }
                }

                for (let i = 0; i < cclArray.length; i++) {
                    this.historicalCCL.push({x: message.msTimestamp, y: cclArray[i]});
                    if(this.historicalCCL.length > TIME_CHART_MAX_POINTS) {
                        this.historicalCCL.shift();
                    }
                }

                for (let i = 0; i < tensionArray.length; i++) {
                    this.historicalTension.push({x: message.msTimestamp, y: tensionArray[i]});
                    if(this.historicalTension.length > TIME_CHART_MAX_POINTS) {
                        this.historicalTension.shift();
                    }
                }

                for (let i = 0; i < speedArray.length; i++) {
                    this.historicalSpeed.push({x: message.msTimestamp, y: speedArray[i]});
                    if(this.historicalSpeed.length > TIME_CHART_MAX_POINTS) {
                        this.historicalSpeed.shift();
                    }
                }

                for (let i = 0; i < pumpdownArray.length; i++) {
                    this.historicalPumpdown.push({x: message.msTimestamp, y: pumpdownArray[i]});
                    if(this.historicalPumpdown.length > TIME_CHART_MAX_POINTS) {
                        this.historicalPumpdown.shift();
                    }
                }

                //Update the latency line chart
                const latencyChart = this.$refs['latencyLineChart'].$data._chart;
                const now = moment.utc().valueOf();
                this.latencydatacollection.datasets[0].data.push({x: now, y: this.messageDelay});


                //Set the min/max for latency chart
                if(!this.firstMessage) {
                    this.firstMessage = now;
                    latencyChart.options.scales.xAxes[0].ticks.min = now;
                }
                else if(now - latencyChart.options.scales.xAxes[0].ticks.min > LATENCY_CHART_WINDOW) {
                    latencyChart.options.scales.xAxes[0].ticks.min = now - LATENCY_CHART_WINDOW;
                }

                latencyChart.options.scales.xAxes[0].ticks.max = now;

                //Set the min/max for time chart
                if(this.historicalDepth.length > 0) {
                    const earliestKnownDepth = this.historicalDepth[0];
                    timeChart.options.scales.xAxes[0].ticks.min = earliestKnownDepth.x.valueOf();
                }
                timeChart.options.scales.xAxes[0].ticks.max = now;

                //filter out old values to prevent memory blowing up
                const filterFunction = function(element) { return element.x >= now - LATENCY_CHART_WINDOW; };
                this.latencydatacollection.datasets[0].data = this.latencydatacollection.datasets[0].data.filter(filterFunction);

                //trigger render function on each chart
                this.ccldatacollection.datasets[0].data = this.dictionaryToArray(this.rawCCLData);
                this.ccldatacollection.datasets[1].data = this.rawOldCCLData;
                cclChart.update();

                this.tensiondatacollection.datasets[0].data = this.dictionaryToArray(this.rawTensionData);
                this.tensiondatacollection.datasets[1].data = this.rawOldTensionData;
                tensionChart.update();

                this.timedatacollection.datasets[0].data = this.historicalDepth;
                this.timedatacollection.datasets[1].data = this.historicalCCL;
                this.timedatacollection.datasets[2].data = this.historicalTension;
                this.timedatacollection.datasets[3].data = this.historicalSpeed;
                this.timedatacollection.datasets[4].data = this.historicalPumpdown;
                cclChart.update();
                timeChart.update();
                latencyChart.update();
            }
        },
        updateMessageDelay() {
            this.messageDelay = Date.now() - this.lastMessage;
            // console.log("updateMessageDelay", this.messageDelay)
        }
    }
};
</script>
