var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "AddTagModal",
      staticClass: "dark-modal modal",
      attrs: { id: "AddTagModal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.resolve(null)
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c(
              "div",
              [
                _c("iws-select", {
                  attrs: {
                    label: "Top Level Tag",
                    value: _vm.topLevelTagId,
                    options: _vm.topLevelTags,
                    "display-name": "name",
                    "value-name": "id",
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.topLevelTagId = $event
                    },
                    change: function ($event) {
                      return _vm.getStandardTags()
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("iws-select", {
                  attrs: {
                    label: "Standard Tag",
                    value: _vm.standardTagId,
                    options: _vm.standardTags,
                    "display-name": "name",
                    "value-name": "id",
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.standardTagId = $event
                    },
                    change: function ($event) {
                      return _vm.updateCompleteTagName()
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("iws-checkbox", {
                  attrs: {
                    value: _vm.spreadEnabled,
                    label: "Spread Number (* or integer)",
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.spreadEnabled = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("iws-input", {
                  attrs: {
                    value: _vm.spreadNumber,
                    disabled: !_vm.spreadEnabled,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.spreadNumber = $event
                    },
                    input: _vm.updateCompleteTagName,
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("iws-input", {
                  attrs: {
                    value: _vm.completeTagName,
                    label: "Complete tag name",
                    required: "true",
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.completeTagName = $event
                    },
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c("iws-button", {
                attrs: { type: "outline-light", text: "Cancel" },
                on: {
                  click: function ($event) {
                    return _vm.resolve(null)
                  },
                },
              }),
              _vm._v(" "),
              _c("iws-button", {
                attrs: { text: "Add Tag", click: _vm.saveTag },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title" }, [
      _c("h5", [
        _vm._v("\n                        Add Tag\n                    "),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                        You can easily add and edit tags here. Once you are done, click on 'Add Tag'.\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }