var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("notifications", {
        ref: "dashboardNotification",
        attrs: {
          id: "dashboardNotification",
          group: "dashboardNotification",
          position: "top center",
        },
      }),
      _vm._v(" "),
      _c("analytics-create-modal", {
        attrs: {
          iwsUser: _vm.iwsUser,
          customers: _vm.customers,
          show: _vm.createModalShow,
          dashboardInfo: _vm.dashboardInfo,
        },
        on: {
          onCreateOrSavePressed: _vm.onCreateOrSavePressed,
          dismissModal: _vm.dismissModal,
        },
      }),
      _vm._v(" "),
      _vm.isFeatureFlagged("ANALYSIS_QUICK_ADD")
        ? _c(
            "div",
            {
              staticClass: "left-sidebar",
              staticStyle: { width: "60px", "background-color": "#bdbdbd" },
              attrs: { id: "left-sidebar" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "p-1 m-1 d-flex align-items-center",
                  class: {
                    "justify-content-center": !_vm.sidebarIsOpen,
                    "justify-content-between": _vm.sidebarIsOpen,
                  },
                  staticStyle: { "border-bottom": "4px solid #f1f1f1" },
                },
                [
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.sidebarIsOpen,
                          expression: "sidebarIsOpen",
                        },
                      ],
                      staticClass: "horizontal-label p-1",
                    },
                    [_vm._v("Quick Select")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "m-0 p-1 sidebar-trigger",
                      staticStyle: { "align-self": "center" },
                    },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.sidebarToggle()
                            },
                          },
                        },
                        [
                          _c("i", {
                            staticClass: "pt-1 font-32",
                            class: {
                              fa: true,
                              "fa-arrow-circle-left": _vm.sidebarIsOpen,
                              "fa-arrow-circle-right": !_vm.sidebarIsOpen,
                            },
                          }),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.sidebarIsOpen,
                      expression: "sidebarIsOpen",
                    },
                  ],
                },
                _vm._l(_vm.getSidebarItems(), function (displayItem, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "pb-2 sidebar-item" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between p-1" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "pl-1",
                              on: {
                                click: function ($event) {
                                  return _vm.createDashboardItem(
                                    displayItem,
                                    true
                                  )
                                },
                              },
                            },
                            [_vm._v(_vm._s(displayItem.friendlyName))]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "d-flex float-right" }, [
                            _c(
                              "div",
                              {
                                staticClass: "pointer-cursor",
                                on: {
                                  click: function ($event) {
                                    return _vm.createDashboardItem(
                                      displayItem,
                                      true
                                    )
                                  },
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-plus-circle icon",
                                }),
                              ]
                            ),
                            _vm._v(" "),
                            _c("div", { staticClass: "pl-1" }),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "pointer-cursor",
                                on: {
                                  click: function ($event) {
                                    return _vm.setDefaults(displayItem)
                                  },
                                },
                              },
                              [_c("i", { staticClass: "fas fa-cog icon" })]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                }),
                0
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !_vm.sidebarIsOpen,
                      expression: "!sidebarIsOpen",
                    },
                  ],
                  staticClass: "vertical-label",
                },
                [_vm._v("Quick Select")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "d-flex w-100 justify-content-between px-2 sidebar-footer",
                },
                [
                  _vm.sidebarIsOpen
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-content-start" },
                        [
                          false
                            ? _c(
                                "button",
                                {
                                  staticClass: "btn btn-primary",
                                  staticStyle: { width: "111px" },
                                  attrs: { disabled: !_vm.isQuickAddUnsaved },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.saveCurrentDashboard(
                                        true,
                                        true
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                    Save Layout\n                    "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                ]
              ),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "ml-auto d-flex justify-content-end px-3 pt-3" },
        [
          _vm.showUserDefaultsModal
            ? _c(
                "div",
                [
                  _c("user-analysis-defaults-modal", {
                    attrs: {
                      modalVisible: _vm.showUserDefaultsModal,
                      initialSelectedComponent: _vm.selectedDisplayItem,
                      userId: _vm.userid,
                      customerId: _vm.dashboardInfo.customer_id,
                      analysisDashboard: this,
                      userComponentDefaults: _vm.userComponentDefaults,
                      availableDisplayItems: _vm.availableDisplayItems,
                    },
                    on: {
                      onDismiss: function ($event) {
                        _vm.showUserDefaultsModal = false
                      },
                      update: _vm.updateUserDefaultTemplates,
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.editMode
            ? _c(
                "div",
                {
                  class: { activeNewComponent: _vm.sidebarIsOpen },
                  staticStyle: { "padding-left": "60px" },
                },
                [
                  _c(
                    "b-dropdown",
                    {
                      staticClass: "newComponentDropdown",
                      attrs: {
                        id: "dropdown-1",
                        text: "Add Component",
                        size: "sm",
                        variant: "primary",
                      },
                    },
                    _vm._l(_vm.availableDisplayItems, function (displayItem) {
                      return _c(
                        "b-dropdown-item",
                        {
                          key: displayItem.id,
                          on: {
                            click: function ($event) {
                              return _vm.createDashboardItem(displayItem)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(displayItem.friendlyName) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.dashboardInfo && _vm.dashboardInfo.name
            ? _c(
                "div",
                { staticClass: "absolute-center pt-2 font-weight-bold" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.dashboardInfo.name) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex flex-grow-1" }),
          _vm._v(" "),
          !_vm.editMode
            ? _c("span", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Edit Layout",
                    expression: "'Edit Layout'",
                    arg: "top",
                  },
                ],
                staticClass: "fas fa-pen fa-md px-1",
                staticStyle: { margin: "auto" },
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    _vm.editMode = true
                  },
                },
              })
            : _vm._e(),
          _vm._v(" "),
          !_vm.editMode
            ? _c("span", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Project Options",
                    expression: "'Project Options'",
                    arg: "top",
                  },
                ],
                staticClass: "fas fa-cog fa-md px-1 mr-2",
                staticStyle: { margin: "auto" },
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    _vm.createModalShow = true
                  },
                },
              })
            : _c("div", [
                _c("div", { staticClass: "d-flex flex-row" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary bar_button mr-2 ml-3",
                      attrs: {
                        role: "button",
                        "data-toggle": "tooltip",
                        "data-placement": "top",
                        title: "Save the current layout",
                      },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveCurrentDashboard()
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Save Layout\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary bar_button mr-2",
                      attrs: { role: "button" },
                      on: {
                        click: function ($event) {
                          _vm.editMode = false
                          _vm.setupDashboard()
                        },
                      },
                    },
                    [_vm._v("\n                    Discard\n                ")]
                  ),
                ]),
              ]),
        ]
      ),
      _vm._v(" "),
      _vm.selectedDashboard.layout.length != 0
        ? _c(
            "grid-layout",
            {
              class: {
                "sidebar-spacer": _vm.isFeatureFlagged("ANALYSIS_QUICK_ADD"),
              },
              attrs: {
                layout: _vm.selectedDashboard.layout,
                "is-draggable": _vm.editMode,
                "is-resizable": _vm.editMode,
                responsive: true,
                "col-num": 12,
                "row-height": 30,
                "is-mirrored": false,
                "vertical-compact": true,
                margin: [5, 5],
                "use-css-transforms": true,
              },
              on: {
                "update:layout": function ($event) {
                  return _vm.$set(_vm.selectedDashboard, "layout", $event)
                },
                "layout-updated": _vm.layoutUpdatedEvent,
              },
            },
            _vm._l(_vm.selectedDashboard.layout, function (item, index) {
              return _c(
                "div",
                { key: item.i },
                [
                  _c(
                    "grid-item",
                    {
                      attrs: {
                        x: item.x,
                        y: item.y,
                        w: item.w,
                        h: item.h,
                        i: item.i,
                        minW: _vm.getDimensionsForItem(item, "minW"),
                        minH: _vm.getDimensionsForItem(item, "minH"),
                        maxH: _vm.getDimensionsForItem(item, "maxH"),
                        maxW: _vm.getDimensionsForItem(item, "maxW"),
                        dragAllowFrom: ".item_title",
                      },
                      on: {
                        resize: _vm.handleResizeEvent,
                        resized: _vm.handleResizedEvent,
                      },
                    },
                    [
                      _vm.editMode
                        ? _c("div", { staticClass: "item_title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "pl-2",
                                staticStyle: { display: "inline-block" },
                              },
                              [
                                _c("b-form-select", {
                                  staticClass: "select_form",
                                  attrs: { options: _vm.availableDisplayItems },
                                  on: {
                                    change: function ($event) {
                                      return _vm.handleComponentTypeChanged(
                                        _vm.selectedDashboard.items[index],
                                        index
                                      )
                                    },
                                  },
                                  model: {
                                    value:
                                      _vm.selectedDashboard.items[index].type,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.selectedDashboard.items[index],
                                        "type",
                                        $$v
                                      )
                                    },
                                    expression:
                                      "selectedDashboard.items[index].type",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "closer",
                                on: {
                                  click: function ($event) {
                                    return _vm.removeDashboardItem(item, index)
                                  },
                                },
                              },
                              [_vm._v("X")]
                            ),
                          ])
                        : _c("div", { staticClass: "item_title" }, [
                            _c(
                              "div",
                              {
                                staticClass: "pl-2",
                                staticStyle: {
                                  display: "inline-block",
                                  color: "white",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.availableDisplayItems.find(
                                        (target) =>
                                          target.name ==
                                          _vm.selectedDashboard.items[index]
                                            .type
                                      ).friendlyName
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            ),
                          ]),
                      _vm._v(" "),
                      _c("display-item-component", {
                        ref: "grid-item-" + index,
                        refInFor: true,
                        attrs: {
                          editMode: _vm.editMode,
                          item: _vm.selectedDashboard.items[index],
                          defaultChartColumnCount: _vm.defaultChartColumnCount,
                          layout: _vm.selectedDashboard.layout[index],
                          isAdmin: _vm.isAdmin,
                          dashboardInfo: _vm.dashboardInfo,
                          userid: _vm.userid,
                          customers: _vm.customers,
                          userCompanyId: _vm.userCompanyId,
                          componentSpecificPermissions:
                            _vm.componentSpecificPermissions,
                          friendlyName:
                            _vm.selectedComponentFriendlyName(index),
                        },
                        on: { updateDashboardItem: _vm.updateDashboardItem },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c("center", { staticClass: "mt-5 h1" }, [
            _vm._v("Add components to the project by clicking the "),
            _c("div", { staticClass: "fas fa-pen fa-md px-1" }),
            _vm._v(" to the right."),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }