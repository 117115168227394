<template>
    <iws-modal
        title="Add Camera" 
        primary-button-text="Add Camera"

        :showModal="showBaseModal"
        max-width="500px"

        @close="cancelAction"
        @secondary-action="cancelAction"
        @primary-action="confirmAction"
    >
        <template #content>
            <iws-input
                :value.sync="data.ipAddress"
                label="IP"
                required
                ref="ipAddress"
            />

            <div class="row" style="margin-top: 30px">
                <div class="col-6">
                    <iws-input
                        :value.sync="data.onvifUsername"
                        label="Username"
                        required
                        ref="onvifUsername"
                        autocomplete="username"
                    />
                </div>

                <div class="col-6">
                    <iws-input
                        :value.sync="data.onvifPassword"
                        label="Password"
                        type="password"
                        required
                        ref="onvifPassword"
                        autocomplete="new-password"
                    />
                </div>
            </div>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { flushToasts } = GlobalFunctions;

export default {
    data() {
        return {
            showBaseModal: false,
            resolve: null,
            data: {
                ipAddress: null,
                onvifUsername: null,
                onvifPassword: null
            }
        };
    },
    
    methods: {
        cancelAction() {
            this.resolve(null)
        },
        confirmAction() {
            flushToasts();

            // Always run both validators to visually show if both are invalid
            const testResults = [
                this?.$refs?.ipAddress?.validateInput(),
                this?.$refs?.onvifUsername?.validateInput(),
                this?.$refs?.onvifPassword?.validateInput()
            ];

            if (testResults[0] && testResults[1] && testResults[2])
                this.resolve({ ...this.data });
        },

        open() {
            this.data.ipAddress = null;
            this.data.onvifUsername = null;
            this.data.onvifPassword = null;

            return new Promise( (resolve, reject) => {
                this.resolve = (value) => {
                    this.showBaseModal = false;
                    this.data.ipAddress = null;
                    this.data.onvifUsername = null;
                    this.data.onvifPassword = null;
                    resolve(value);
                };

                this.showBaseModal = true;
            });
        }
    }
};
</script>