var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    _vm._l(_vm.publishedAlerts, function (alert, index) {
      return _c("div", [
        alert.display
          ? _c(
              "div",
              {
                staticClass: "alert alert-dismissible text-center alert-banner",
                class: [
                  _vm.isHighSeverity(alert)
                    ? "alert-danger"
                    : _vm.isMediumSeverity(alert)
                    ? "alert-warning"
                    : "alert-success",
                ],
                attrs: { role: "alert" },
              },
              [
                _c("i", {
                  class: [
                    _vm.isHighSeverity(alert)
                      ? "fas fa-exclamation-triangle"
                      : _vm.isMediumSeverity(alert)
                      ? "fas fa-exclamation-circle"
                      : "fas fa-info-circle",
                  ],
                  staticStyle: { "font-size": "15px" },
                }),
                _vm._v(" "),
                _c("span", { staticStyle: { "font-size": "15px" } }, [
                  _vm._v(_vm._s(alert.message)),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "close alert-banner",
                    attrs: {
                      type: "button",
                      "data-dismiss": "alert",
                      "aria-label": "Close",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.closeAlert(alert, index)
                      },
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }