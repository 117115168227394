<template>
  <div
    class="modal"
    :id="isFilterPlugs ? 'conditionModal' : 'missRunModal'"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg primary-txt" role="document">
      <div class="modal-content main-container div-border px-2 modal-bg">
        <div class="modal-header" style="border-bottom-width: 0px">
          <job-info-header
            :jobData="jobData"
            :onChangeValues="getShotsHistory"
          />
        </div>
        <div class="modal-body py-0">
          <label>{{ title }}</label>
          <div class="scrollmenu scrolldiv pt-2 pr-2 pb-0 div-border modal-bg">
            <div
              v-if="loading || displayData.length === 0"
              style="background-color: white"
              class="d-flex  p-2"
            >
              <div v-if="loading" class="dark-text">Loading...</div>
              <div v-else-if="displayData.length === 0" class="dark-text">
                {{ noResultMessage }}
              </div>
            </div>
            <div
              v-for="(shot, index) in displayData"
              class="mb-2"
              v-bind:key="index"
            >
              <shot-plug-status-row
                :shot="shot"
                :shotValue="true"
                :isPlug="isFilterPlugs"
                :hourOffset="hourOffset"
              />
            </div>
          </div>
        </div>
        <div
          class="modal-footer justify-content-end"
          style="border-top-width: 0px"
        >
          <button
            type="button"
            class="btn btn-secondary green-button"
            data-dismiss="modal"
          >
            Done
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
    props: ['jobData', 'isFilterPlugs', 'noResultMessage', 'title'],
    mounted() {
		const modalID = this.isFilterPlugs ? 'conditionModal' : 'missRunModal';
		const self = this;
		$(`#${modalID}`).on('show.bs.modal', function (e) {
			self.getShotsHistory();
		})
    },
    data() {
        return {
            isModalVisible: false,
            isCommentsVisible: false,
            jobNumber: this.jobData.jobNumber,
            displayData: [],
            loading: false,
            hourOffset: this.jobData.hourOffset
        };
    },
    methods: {
        changeModalVisibility: function (status) {
            const self = this;
            const modalID = this.isFilterPlugs ? 'conditionModal' : 'missRunModal';
            self.isModalVisible = status;
            self.isModalVisible
                ? $(`#${modalID}`).modal('show')
                : $(`#${modalID}`).modal('hide');
        },
        isVisibleComments: function (status) {
            const self = this;
            self.isCommentsVisible = status;
        },
        getShotsHistory(wellNumber, stageNumber) {
            const self = this;
            self.loading = true;
            self.displayData = [];

            let well = this.jobData.wellIndex;
            let stage = this.jobData.stage;

            if (wellNumber !== undefined && stageNumber !== undefined) {
                well = wellNumber;
                stage = stageNumber;
            }

            const url = '/wireline-history';
            $.get(
                url,
                {
                    jobNumber: self.jobNumber,
                    wellIndex: well,
                    stage: stage
                },
                function (data, status, xr) {
                    let filteredData = data;

                    if (self.isFilterPlugs) {
                        filteredData = data.filter((item) => {
                            return item.type == 'plug';
                        });
                    }
                    self.loading = false;
                    self.displayData = filteredData;
                }
            );
        }
    }
};
</script>

<style>
.green-button {
  background-color: #5cc55b;
  border-width: 0px;
  min-width: 150px;
}
.grey-button {
  background-color: #8e8e8e;
  border-width: 0px;
  min-width: 150px;
}
.main-container {
  border-radius: 20px;
}
.scrollmenu {
  overflow: auto;
  max-height: 350px;
}
.pointer-cursor {
  cursor: pointer;
}
.dark-text {
  color: #343a40;
}
.div-border {
  border-width: 1px !important;
  border-color: #000 !important;
}
</style>