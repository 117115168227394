<template>
    <div class="alert alert-secondary text-left">
        {{message.header}}: {{message.body}}
        <img src="/images/edit.png" style="height:20px;position:absolute;right:5px;bottom:5px;" data-toggle="modal" :data-target="'#whiteboardModal'+index" @click="openDialog()">
        <div class="modal" tabindex="-1" role="dialog" :id="'whiteboardModal'+index" data-backdrop="static">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Edit message</h5>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    <input type="text" class="form-control" v-model="newBody" @change="saveChanges()">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
                    <button class="btn btn-primary" @click="saveChanges()">Save</button>
                </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                newBody:"",
                csrf:document.querySelector('meta[name="csrf-token"]').getAttribute('content')
            }
        },
        props: ['message','index'],
        methods:{
            saveChanges(){
                var self = this;
                var id = "#whiteboardModal"+this.index;
                $(id).modal('hide');
                $('body').removeClass('modal-open');
                $('.modal-backdrop').remove();
                $.ajax({
                    method: "PUT",
                    url: '/messages/'+self.message.id,
                    data: {
                        "_token":self.csrf,
                        header: self.message.header,
                        body: self.newBody
                    },
                    dataType: 'json'
                }).done(function(data){
                    //console.log(JSON.stringify(data));
                    self.$parent.$emit('refresh-whiteboard');
                });
            },
            openDialog: function(){
                this.newBody = this.message.body;
            }
        },
        mounted(){
            //console.log("whiteboard message has been mounted");
        }
    }
</script>