var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("First Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "field-output" }, [
          _vm._v(
            "\n                    " +
              _vm._s(_vm.user.first_name) +
              "\n                "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Last Name")]),
        _vm._v(" "),
        _c("div", { staticClass: "field-output" }, [
          _vm._v(
            "\n                    " +
              _vm._s(_vm.user.last_name) +
              "\n                "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Email")]),
        _vm._v(" "),
        _c("div", { staticClass: "field-output" }, [
          _vm._v(
            "\n                    " +
              _vm._s(_vm.user.email) +
              "\n                "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Username")]),
        _vm._v(" "),
        _c("div", { staticClass: "field-output" }, [
          _vm._v(
            "\n                    " +
              _vm._s(_vm.user.username) +
              "\n                "
          ),
        ]),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "row mt-4" }, [
      _c("div", { staticClass: "col-md-6" }, [
        _c("label", [_vm._v("Phone")]),
        _vm._v(" "),
        _c("div", { staticClass: "field-output" }, [
          _vm._v(
            "\n                    " +
              _vm._s(_vm.user.phone_number ? _vm.user.phone_number : " ") +
              "\n                "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "col-md-6" },
        [
          _c("label", [_vm._v("Tooltip Font Size")]),
          _vm._v(" "),
          _c("iws-select", {
            staticStyle: { padding: "5px" },
            attrs: {
              value: _vm.tooltipFontSize,
              options: _vm.tooltipOptions,
              "display-name": "label",
              "value-name": "value",
            },
            on: {
              "update:value": function ($event) {
                _vm.tooltipFontSize = $event
              },
              change: _vm.tooltipChanged,
            },
          }),
          _vm._v(" "),
          _c("br"),
          _vm._v(" "),
          _vm._m(0),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row mt-4" },
      [
        _c("div", { staticClass: "col-md-6 d-flex" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: { click: () => this.$bvModal.show("password-change-modal") },
            },
            [_vm._v("Change Password")]
          ),
        ]),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: "password-change-modal",
              size: "xl",
              "hide-header-close": "",
              "header-class": "border-bottom-0 justify-content-center pb-0",
              "footer-class": "border-top-0 pt-0",
              "content-class": "modal-bg",
              "ok-disabled": true,
              "hide-footer": "",
            },
          },
          [
            _c("change-password-component", {
              attrs: {
                passwordStrengthRequired: _vm.passwordStrengthRequired,
                requestedByUser: true,
                user: _vm.user,
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("Medium:"),
        _c("span", { staticStyle: { "font-size": "12px" } }, [
          _vm._v(" Sample Text"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Large:"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(" Sample Text"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Extra Large:"),
        _c("span", { staticStyle: { "font-size": "20px" } }, [
          _vm._v(" Sample Text"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }