var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12" }, [
      _c("canvas", {
        directives: [
          {
            name: "init-canvas",
            rawName: "v-init-canvas",
            value: { width: this.width, height: this.height },
            expression: "{width: this.width, height: this.height}",
          },
        ],
        staticStyle: { border: "1px solid #AAA" },
        attrs: { width: this.width, height: this.height },
      }),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }