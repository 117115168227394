<template>
	<div class="primary-txt justify-content-center" style="height:100%">
		<div class="pb-2 d-flex justify-content-center">
			<b>Tool-String Measurements</b>
		</div>
		<div class="inner-bg div-border p-2 font-small" style="height:95%">
			<div class="d-flex flex-row py-2">
				<div class="d-flex justify-content-center align-items-center">
					<span>Number of Guns</span><span class="px-1">{{ currentGunCount }}</span>
				</div>
				<div class="flex-grow-1"></div>
				<div class="px-3">
					<add-remove-buttons :minValue="1" :currentValue="currentGunCount" :onChange="(value)=>onGunNumberChange(value, true)"> </add-remove-buttons>
				</div>
			</div>
			<div>
				<div style="flex-row">
					<div v-for="(toolDetail, index) in toolDetailSet" v-bind:key="index" class="input-group flex-nowrap my-2">
						<div class="d-flex align-items-center">
							<div>{{ toolDetail.name}}</div>
						</div>
						<div class="flex-grow-1"></div>
						<input type="number" :value="toolDetail.value" @change="(event)=>onValueChanged(index, event)" style="width:70px;text-align:right">
						<div class="input-group-append">
							<span class="input-group-text" id="basic-addon2">ft</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props : ['gunPlugInfo', 'onChange'],
	mounted() {
		this.currentGunCount = this.gunPlugInfo.gunCount;
		this.currentToolSetData = this.gunPlugInfo.data; //data on the database
		this.onGunNumberChange(this.currentGunCount);
		this.valuesChanged = false;
	},
	data(){
		return {
			options : [...Array(11).keys()].slice(1), // max guns 10
			toolDetailSet : [],
			currentToolSetData : [],
			defaultGunValue : 0,
			currentGunCount : 5,
			valuesChanged : false
		}
	},
	watch: { 
		gunPlugInfo: function(newVal, oldVal) { // watch it
			this.currentGunCount = newVal.gunCount;
			this.currentToolSetData = newVal.data;
			this.onGunNumberChange(this.currentGunCount);
		},
	},
	methods: {
		onGunNumberChange(value, isUpdated){
			this.currentGunCount = value;
			this.toolDetailSet = [];
			let i;
			let gunNumber=1;

			if(isUpdated){
				this.onChange();
			}


			const sortByIndex = (a, b) => {
				const gunIndexA = a.gunOrPlugIndex;
				const gunIndexB = b.gunOrPlugIndex;

				let comparison = 0;
				if (gunIndexA > gunIndexB) {
					comparison = 1;
				} else if (gunIndexA < gunIndexB) {
					comparison = -1;
				}
				return comparison;
			}

			this.currentToolSetData && this.currentToolSetData.length>0 && this.currentToolSetData.sort(sortByIndex);

			
			for( i = 0; i < value; i++){

				this.toolDetailSet.push({ index : gunNumber, name : `Gun ${gunNumber}`, value : this.currentToolSetData[i]? this.currentToolSetData[i].distance : this.defaultGunValue })
				gunNumber++;
			}

			
			this.toolDetailSet.length>0 &&  this.toolDetailSet.reverse();
			

			this.toolDetailSet.push({ index : gunNumber, name : `Plug`, value : this.currentToolSetData[i]? this.currentToolSetData[i].distance : this.defaultGunValue })
		},
		onValueChanged(arrayIndex, event){
			const updatedValue = event.target.value;
			this.onChange();
			if(this.toolDetailSet[arrayIndex]){
				this.toolDetailSet[arrayIndex] = { ...this.toolDetailSet[arrayIndex], ...{ value : updatedValue }}
			}
		},
		getToolStringDetails(){
			return this.toolDetailSet;
		}
	},

}
</script>

<style>
	.inputStyle {
		width : auto;
	}
</style>