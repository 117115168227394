var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      title: "Stream Quality " + `(${this.streamData.name})`,
      "primary-button-text": "Save",
      showModal: _vm.showBaseModal,
      "max-width": "500px",
    },
    on: {
      close: _vm.cancelAction,
      "secondary-action": _vm.cancelAction,
      "primary-action": _vm.confirmAction,
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _c("iws-tabs", {
              attrs: {
                tabs: [{ title: "Field Quality" }, { title: "Cloud Quality" }],
              },
              scopedSlots: _vm._u([
                {
                  key: "tab_0",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("iws-select", {
                            ref: "localQualityPreset",
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Quality Preset",
                              options: _vm.qualityPresetOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.localQualityPreset,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "localQualityPreset",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row d-flex justify-content-between" },
                        [
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Resolution",
                              placeholder: _vm.streamData.localResolution
                                ? ""
                                : "Use source",
                              options: _vm.resolutionOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.localResolution,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "localResolution",
                                  $event
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "FPS",
                              options: _vm.fpsOptions,
                              value: _vm.streamData.localFPS,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "localFPS",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row d-flex justify-content-between" },
                        [
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Bitrate (kbps)",
                              options: _vm.bitrateOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.localBitrate,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "localBitrate",
                                  $event
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Key Frame Interval (secs)",
                              options: [1, 2, 3, 4],
                              value: _vm.streamData.localKeyFrameIntervalFactor,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "localKeyFrameIntervalFactor",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Encoder Preset",
                              options: _vm.encoderPresetOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.localPreset,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "localPreset",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "tab_1",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("iws-select", {
                            ref: "cloudQualityPreset",
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Quality Preset",
                              options: _vm.qualityPresetOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.cloudQualityPreset,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "cloudQualityPreset",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row d-flex justify-content-between" },
                        [
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Resolution",
                              options: _vm.resolutionOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.cloudResolution,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "cloudResolution",
                                  $event
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "FPS",
                              options: _vm.fpsOptions,
                              value: _vm.streamData.cloudFPS,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "cloudFPS",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row d-flex justify-content-between" },
                        [
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Bitrate (kbps)",
                              options: _vm.bitrateOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.cloudBitrate,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "cloudBitrate",
                                  $event
                                )
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Key Frame Interval (secs)",
                              options: [1, 2, 3, 4],
                              value: _vm.streamData.cloudKeyFrameIntervalFactor,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "cloudKeyFrameIntervalFactor",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row" },
                        [
                          _c("iws-select", {
                            staticClass: "mb-2 stream-field",
                            attrs: {
                              label: "Encoder Preset",
                              options: _vm.encoderPresetOptions,
                              "display-name": "text",
                              "value-name": "value",
                              value: _vm.streamData.cloudPreset,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(
                                  _vm.streamData,
                                  "cloudPreset",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ]),
            }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }