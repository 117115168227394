<template>
    <div class="container col-9">
        <div class="h5">Edit Profile</div>
        <div class="form-group">
            <label for="email">Email</label>
            <input id="email" name="email" type="email" class="form-control" style="font-weight: bold;" :value="loggedInUser.email" readonly>
        </div>
        <div class="form-group" v-if="!(loggedInUser.email === loggedInUser.username)">
            <label for="username">Username</label>
            <input id="username" name="username" type="username" class="form-control" style="font-weight: bold;" :value="loggedInUser.username" readonly>
        </div>
        <div class="form-group" v-if="loggedInUser.email === ''">
            <label for="email">Email</label>
            <input id="email" name="email" type="email" class="form-control" v-model="loggedInUser.email" required>
        </div>
        <div class="form-group">
                <label for="text">First Name</label>
                <input id="first_name" name="first_name" type="text" class="form-control" v-model="loggedInUser.first_name">
        </div>
        <div class="form-group">
                <label for="text">Last Name</label>
                <input id="last_name" name="last_name" type="text" class="form-control" v-model="loggedInUser.last_name">
        </div>
        <div class="form-group">
                <label for="phone_number">Phone</label>
                <input id="phone_number" name="phone_number" type="tel" class="form-control" v-model="loggedInUser.phone_number">
        </div>
        <hr>
        <div class="form-group">
            <label for="password">Password <i>*Leave blank if you do not wish to update*</i></label>
            <input id="password" name="password" type="password" class="form-control" v-model="newPassword">
        </div>
        <div class="form-group">
            <label for="password_confirmation">Confirm Password</label>
            <input id="password_confirmation" name="password_confirmation" type="password" class="form-control" v-model="confirmPassword">
        </div>
        <div class="form-group">
            <label for="dashboard_permissions">Tooltip Font Size</label>
            <select id="toolTipFontSize" name="toolTipFontSize" class="form-control" @change="tooltipChanged" v-model="tooltipFontSize">
                <option value="medium">Medium </option>
                <option value="large">Large  </option>
                <option value="x-large">Extra Large </option>
            </select>
            <br/>
            <ul>
                <li>Medium:<span style="font-size: 12px;"> Sample Text</span> </li>
                <li>Large:<span style="font-size: 16px;"> Sample Text</span></li>
                <li>Extra Large:<span style="font-size: 20px;"> Sample Text</span></li>
            </ul>
        </div>
        <div class="form-group">
            <a href="/account-settings" class="btn btn-secondary" >Back</a>
            <button name="submit" type="button" class="btn btn-primary" @click="submitUserProfile">Submit</button>
        </div>

    </div>
</template>
<script>
import GlobalFunctions from '../GlobalFunctions.js';
const {  toast } = GlobalFunctions;
export default {
    props: ['user'],
    data() {
        return {
            loggedInUser: this.user,
            newPassword: '',
            confirmPassword: '',
            tooltipFontSize: 'medium'
        }
    },
    mounted() {
        let tooltipFontSize = localStorage.getItem('tooltipFontSize' + this.loggedInUser.id);
        if (tooltipFontSize) {
            this.tooltipFontSize = tooltipFontSize;
        }else{
            localStorage.setItem('tooltipFontSize' + this.loggedInUser.id, 'medium');
        }
    },
    methods: {
        tooltipChanged() {
            localStorage.setItem('tooltipFontSize' + this.loggedInUser.id, this.tooltipFontSize);
        },
        submitUserProfile(){
            if((this.newPassword !== this.confirmPassword) && (this.newPassword !== '' || this.confirmPassword !== '') ){
                alert('Password and Confirm Password do not match');
                return;
            }

            let data = {
                _token: $('meta[name="csrf-token"]').attr('content'),
                email: this.loggedInUser.email,
                first_name: this.loggedInUser.first_name,
                last_name: this.loggedInUser.last_name,
                username: this.loggedInUser.username,
                phone_number: this.loggedInUser.phone_number,
                password: this.newPassword,
                password_confirmation: this.confirmPassword
            };
            $.post('/userProfile/'+this.loggedInUser.id, data)
                .then(response => {
                    if(response.status === 200){
                       location.href = '/account-settings';
                    }else{
                        toast({
                            title: response.message,
                            variant: 'danger'
                        });

                    }
                })
                .catch(error => {
                    toast({
                                title: error,
                                variant: 'danger'
                            });

                });


        }
    }

}
</script>
