<template>
    <b-modal 
        v-model="modalVisible"
        size="xl"
        ref="analysisDefaultsModal" 
        @hidden="$emit('onDismiss')"
        no-close-on-backdrop
        header-class="border-bottom-0 justify-content-center  p-0"
        body-class="pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg">
        <template #modal-header>
            <div class="col w-100">
                <div class="modal-header">
                    <div class="font-large w-100 text-center">Edit Analysis Quick Selects</div>
                </div>
            </div>
        </template>
        <template #default>
            <div>
                <b-overlay :show="isSaving || isLoading" rounded="sm">
                    <b-card no-body>
                        <b-tabs pills card vertical>
                        <b-tab title="Stage Comparison" :active="initialSelectedComponent.name == 'stage-comparison-chart'" @click="setActiveTab($event)">
                            <b-card-text>
                                <div class="w-100 d-flex flex-column">
                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="w-50">
                                            <label>Default Pad:</label>
                                            <div class="pr-2 mw-100">
                                                <multi-select id="selectedPads"
                                                    :options="selectablePads"
                                                    :value="stageComparisonOptions.pads"
                                                    :disabled="selectablePads == null"
                                                    :label="'fullPadName'"
                                                    :showSelectedOptions="true"
                                                    @selectedupdated="multiPadsSelected"
                                                    :key="refreshTicker"
                                                    :sortByKey="sortPadDropdownByKeyStageComparison"
                                                    :isReverseSort="isReverseSortStageComparison"
                                                />
                                            </div>
                                            <div>
                                                <b-form-checkbox
                                                    class="mt-1 mb-2"
                                                    v-model="sortByJobStartDateStageComparison"
                                                    :value="true"
                                                    :unchecked-value="false"
                                                >
                                                    Sort by Job Start Date
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="w-50">
                                            <label>Default Wells:</label>
                                            <div style="max-width:100%;" class="pl-1 pr-1">
                                                <multi-select id="selectedWells"
                                                    :options="selectableWells || []"
                                                    :value="stageComparisonOptions.wells || []"
                                                    :hasSubLabels="true"
                                                    :disabled="false"
                                                    :label="'name'"
                                                    :showSelectedOptions="true"
                                                    @selectedupdated="multiWellsSelected"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="w-50">
                                            <label>Index Data By:</label>
                                            <select  class="multiSelectStyle w-100" style="text-overflow:ellipsis; max-width:100%;" v-model="stageComparisonOptions.index"
                                                @change="getChannelsForJobs(stageComparisonOptions.pads.map(pad => pad.jobNumber),stageComparisonOptions.index);"
                                            >
                                                <option selected value="Place Frac">Place Frac</option>
                                                <option value="Place Wireline">Place Wireline</option>
                                            </select>
                                        </div>
                                        <div class="w-50 ml-2">
                                            <label>Aggregate Interval:</label>
                                            <select class="multiSelectStyle w-100" v-model="stageComparisonOptions.aggregateInterval">
                                                <option v-for="(option,index) in analysisDashboardData.aggregateOptions" :value="option.value" :key="index">{{option.name}}</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div class="mt-2">
                                        <checkbox-list
                                            class="w-100 "       
                                            :enableSelectAllOption="true"
                                            :enableSearch="true"
                                            label="Component Default Channels"
                                            :valueKey="'prioritizedTagName'"
                                            :outputKey="'name'"
                                            height="400"
                                            :options="allChannels"
                                            :disabledOptions="getDisabledChannelOptions"
                                            v-bind:selectedOptions="getSelectedChannelNames()"
                                            v-on:update:selectedOptions="updateSelectedChannels($event)"
                                            :isDark="false"
                                        >
                                        </checkbox-list>
                                    </div>
                                    <div class="mt-2">
                                        <b-form-checkbox
                                            v-model="stageComparisonOptions.loadLatestStage"
                                            >
                                            Load Latest Complete Stage
                                            </b-form-checkbox>
                                    </div>
                                </div>
                            </b-card-text></b-tab>
                        <b-tab title="Multi-well Comparison" :active="initialSelectedComponent.name == 'well-comparison'" @click="setActiveTab($event)">
                            <b-card-text>
                                <div class="w-100 d-flex flex-column">
                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="w-50">
                                            <label>Default Pad:</label>
                                            <div class="mw-100">
                                                <multi-select id="selectedPads"
                                                    :options="selectablePads"
                                                    :value="multiWellComparisonOptions.pads"
                                                    :multiSelect="false"
                                                    :placeholderText="'Pick One'"
                                                    :disabled="selectablePads == null"
                                                    :label="'fullPadName'"
                                                    :showSelectedOptions="true"
                                                    @selectedupdated="singlePadSelected"
                                                    :key="refreshTicker"
                                                    :sortByKey="sortPadDropdownByKeyMultiWell"
                                                    :isReverseSort="isReverseSortMultiWell"
                                                />
                                            </div>
                                            <div>
                                                <b-form-checkbox
                                                    class="mt-1 mb-2"
                                                    v-model="sortByJobStartDateMultiWell"
                                                    :value="true"
                                                    :unchecked-value="false"
                                                >
                                                    Sort by Job Start Date
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="w-50 ml-2">
                                            <label>Aggregate Interval:</label>
                                            <select class="multiSelectStyle w-100" v-model="multiWellComparisonOptions.aggregateInterval">
                                                <option v-for="(option,index) in analysisDashboardData.aggregateOptions" :value="option.value" :key="index">{{option.name}}</option>
                                            </select>
                                        </div>

                                    </div>
                                    <div class="mt-2">
                                        <checkbox-list
                                            class="w-100 "       
                                            :enableSelectAllOption="true"
                                            :enableSearch="true"
                                            label="Component Default Channels"
                                            :valueKey="'prioritizedTagName'"
                                            :outputKey="'name'"
                                            height="400"
                                            :options="allChannels"
                                            :disabledOptions="getDisabledChannelOptions"
                                            v-bind:selectedOptions="getSelectedChannelNames()"
                                            v-on:update:selectedOptions="updateSelectedChannels($event)"
                                            :isDark="false"

                                        >
                                        </checkbox-list>
                                    </div>
                                    <div class="mt-2">
                                        <b-form-group label="" v-slot="{ ariaDescribedby }">
                                            <b-form-radio-group
                                                stacked
                                                id="radio-group-2"
                                                v-model="multiWellComparisonOptions.timeType"
                                                :aria-describedby="ariaDescribedby"
                                                name="radio-sub-component"
                                            >
                                                <b-form-radio value="current">
                                                    Current, load past
                                                    <input type="number" v-model.number="multiWellComparisonOptions.pastHours" style="max-width:50px;">
                                                    hours
                                                </b-form-radio>
                                                <b-form-radio value="stage">Last Completed Stage of 
                                                    <!--Load well data in html only when it's available to prevent errors-->
                                                    <select v-if="selectedComponentType == 'well-comparison' && selectableWells.length > 0" v-model="multiWellComparisonOptions.well">
                                                        <option v-for="(well, index) in selectableWells[0].valueArray" :key="index" :value="well">{{well.nameLong}}</option> 
                                                    </select>
                                                </b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </div>
                                </div>
                            </b-card-text></b-tab>
                        <b-tab v-if="isFeatureFlagged('HEATMAPS')" title="Stage Summary Heat Map" :active="initialSelectedComponent.name == 'stage-summary-heatmap'" @click="setActiveTab($event)">
                            <b-card-text>
                                <div class="w-100 d-flex flex-column">
                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="w-50">
                                            <label>Default Pad:</label>
                                            <div class="mw-100 pr-2">
                                                <multi-select id="selectedPads"
                                                    :options="selectablePads"
                                                    :value="heatmapOptions.pads"
                                                    :multiSelect="false"
                                                    :placeholderText="'Pick One'"
                                                    :disabled="selectablePads == null"
                                                    :label="'fullPadName'"
                                                    :showSelectedOptions="true"
                                                    @selectedupdated="singlePadSelected"
                                                    :key="refreshTicker"
                                                    :sortByKey="sortPadDropdownByKeyHeatMap"
                                                    :isReverseSort="isReverseSortHeatMap"
                                                />
                                            </div>
                                            <div>
                                                <b-form-checkbox
                                                    class="mt-1 mb-2"
                                                    v-model="sortByJobStartDateHeatMap"
                                                    :value="true"
                                                    :unchecked-value="false"
                                                >
                                                    Sort by Job Start Date
                                                </b-form-checkbox>
                                            </div>
                                        </div>
                                        <div class="w-50 ml-2">
                                            <label>Default Wells:</label>
                                            <div style="max-width:100%;" class="pl-1 pr-1">
                                                <multi-select id="selectedWells"
                                                    :options="selectableWells"
                                                    :value="heatmapOptions.wells"
                                                    :hasSubLabels="true"
                                                    :disabled="false"
                                                    :label="'name'"
                                                    :showSelectedOptions="true"
                                                    @selectedupdated="multiWellsSelected"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between mt-2">
                                        <div class="w-50">
                                            <label>Default Stage Summary Channel:</label>
                                            <div style="max-width:100%;" class="">
                                                <select class="multiSelectStyle w-100" style="text-overflow:ellipsis; max-width:100%;" v-model="heatmapOptions.summaryChannels" @change="onChannelSelect()">
                                                    <option v-for="summaryChannel in selectableStageSummaryChannels" 
                                                        :key="summaryChannel.tagName"
                                                        :value="summaryChannel"
                                                        >{{summaryChannel.displayName}}                                                  
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="w-50 ml-2">
                                            <label>Default Expression:</label>
                                            <div style="max-width:100%;" class="pl-1 pr-1">
                                                <select  class="multiSelectStyle w-100" style="text-overflow:ellipsis; max-width:100%;" v-model="heatmapOptions.expression">
                                                    <option v-for="(expression,index) in selectableExpressions" :value="expression" :key="index">{{getExpressionDisplayName(expression)}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex w-100 justify-content-between">
                                        <div class="w-50">
                                            <label>Default Category:</label>
                                            <select  class="multiSelectStyle w-100" style="text-overflow:ellipsis; max-width:100%;" v-model="heatmapOptions.category">
                                                <option selected :value="'stage_frac'">Frac</option>
                                                <option :value="'stage_wireline'">Wireline</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <b-form-group label="" v-slot="{ ariaDescribedby }">
                                            <b-form-radio-group
                                                stacked
                                                id="radio-group-2"
                                                v-model="heatmapOptions.stageSelect"
                                                :aria-describedby="ariaDescribedby"
                                                name="radio-sub-component"
                                            >
                                                <b-form-radio value="latestStages">
                                                    Load last
                                                    <input type="number" v-model.number="heatmapOptions.latestStageAmount" style="max-width:50px;">
                                                    stages
                                                </b-form-radio>
                                                <b-form-radio value="all">All Stages</b-form-radio>
                                            </b-form-radio-group>
                                        </b-form-group>
                                    </div>
                                </div>
                            </b-card-text></b-tab>
                        </b-tabs>
                    </b-card>
                </b-overlay>
            </div>
        </template>
        <template #modal-footer>
            <div class="d-flex justify-content-between w-100">
                <div>
                    <button class="btn btn-secondary mr-1" @click="resetSelections(selectedComponentType)">Reset</button>
                </div>
                <div>
                    <button class="btn btn-secondary mr-1" @click="$emit('onDismiss')">Close</button>
                     <button class="btn btn-success green-button" @click="saveDefaultTemplate()">Save {{tabTitle ? tabTitle : initialSelectedComponent.friendlyName}} Defaults</button>
                </div>
            </div>
        </template>
    </b-modal>
</template>
<style scoped>
    .form-control:disabled {
        background-color: lightgray;
    }
    .icon-disabled {
        opacity: 0.25;
        pointer-events: none; 
    }
    .multiSelectStyle {
    border-color: black;
    border-style: solid;
    border-width: 2px;
    height: 43px;
    }
</style>
<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions';
import _ from 'lodash';
import AnalyticsMixin from '../mixins/AnalyticsMixin.js';
import { defaults } from 'chart.js';

export default {
    data() {
        return {
            isReverseSort: false,
            stageSummaryExpressions: {avg: 'Average', max: 'Maximum', min: 'Minimum'},
            sortByJobStartDate: false,
            refreshTicker: false,
            isReverseSortHeatMap: false,
            sortByJobStartDateHeatMap: false,
            isReverseSortStageComparison: false,
            sortByJobStartDateStageComparison: false,
            isReverseSortMultiWell: false,
            sortByJobStartDateMultiWell: false,
            allChannels: [],
            isSaving: false,
            isLoading: true,
            firstLoad: true,
            userSelectedComponentDefaults: {},
            templateLoading: false,
            selectablePads: [],
            selectableWells: [],
            multiWellComparisonOptions: {},
            stageComparisonOptions: {},
            heatmapOptions: {},
            tabTitle: '',
            selectedComponentType: 'dashboard-comparison-chart',
            channelOptions: [],
            stageSummaryChannels: {}
        };
    },
    mixins: [AnalyticsMixin],
    props: {
        initialSelectedComponent: {
            type: Object,
        },
        modalVisible: {
            type: Boolean,
            default: false
        },
        customerId: {
            type: String
        },
        analysisDashboard: {
            type: Object
        },
        userComponentDefaults: {
            type: Object
        },
        availableDisplayItems: {
            type: Array,
            required: true
        },
        dashboardInfo: {
            type: Object
        },
        userid: {
            type: [String],
        }
    },
    mounted() {
        this.setInitialSelectedValues();
        this.getJobsAndWellsForCustomer();
        this.copyUserDefaults();
    },
    computed: {
        selectableExpressions() {
            if (this.heatmapOptions?.summaryChannels?.expressions) {
                return this.heatmapOptions.summaryChannels.expressions;
            } else {
                return ['max', 'avg'];
            }
        },
        selectableJobNumbers() {
            return this.selectablePads.map(pad => pad.jobNumber);
        },
        getDisabledChannelOptions() {
            let allChannelNames = this.allChannels.map(channel => channel.name);
            if (this.channelOptions.length == 0) {
                this.channelOptions = this.allChannels;
            }
            let selectableChannelNames = this.channelOptions.map(channel => channel.name);
            let disabledChannelOptions = allChannelNames.filter(channel => !selectableChannelNames.includes(channel));
            return disabledChannelOptions;
        },
        sortPadDropdownByKeyHeatMap() {
            if (this.sortByJobStartDateHeatMap) {
                this.isReverseSortHeatMap = true;
                return 'start';
            } else {
                this.isReverseSortHeatMap = false;
                return 'location'
            }
        },
        sortPadDropdownByKeyStageComparison() {
            if (this.sortByJobStartDateStageComparison) {
                this.isReverseSortStageComparison = true;
                return 'start';
            } else {
                this.isReverseSortStageComparison = false;
                return 'location'
            }
        },
        sortPadDropdownByKeyMultiWell() {
            if (this.sortByJobStartDateMultiWell) {
                this.isReverseSortMultiWell = true;
                return 'start';
            } else {
                this.isReverseSortMultiWell = false;
                return 'location'
            }
        },
        analysisDashboardData() {
            return this.analysisDashboard._data;
        },
        selectableStageSummaryChannels() {
            // heatmapOptions.expression
            //will remove expression identifiers (Max, Min, etc.) from display name
            const displayNameRegex = /\b(?=\w)(?!(?:Maximum|Max|Average|Avg|Ave|Minimum|Min)\b).*?(?=\s+(?:Maximum|Max|Average|Avg|Ave|Minimum|Min)\b|\s*$)/;
            
            //will sort the stage summary tag name out into relevant groups of data
            const channelRegex = /(?<stage>^[a-z]+)_(?<type>[a-zA-Z]*)_(?<expression>[a-z]+)(?<tagName>[A-Za-z0-9\_]+)/;
            const channels = {};

            if (this.stageSummaryChannels && this.stageSummaryChannels[this.heatmapOptions.category]) {
                this.stageSummaryChannels[this.heatmapOptions.category].forEach(channel => {
                    //break channel tag name into it's groups of data
                    const matches = channel.tagName.match(channelRegex);
                    let groups;
                    //get generic display name of the tag, without any expression identifiers
                    const matchedDisplayName = channel.displayName.match(displayNameRegex)[0] || null;

                    if (matches) {
                        groups = matches.groups;
                        const expression = groups.expression;
                        const key = groups.type + groups.tagName;
                        if (channels[key] && channels[key].expressions.indexOf(expression) == -1) {
                            channels[key].expressions.push(expression);
                            channels[key].tagNames[expression] = channel.tagName;
                        } else {
                            channels[key] = {
                                tagNames: {[expression]: channel.tagName},
                                expressions: [expression],
                                displayName: matchedDisplayName ? matchedDisplayName : channel.displayName
                            };
                        }
                    }
                });
            }
            return channels || [];
        }
    },
    methods: {
        selectAllWellsWhenPadSelected(options) {
            let wellsForJobs = [];
            if (options.pads.length > 0) {
                this.selectableWells.forEach(wellInfo => {
                    wellsForJobs.push(wellInfo.valueArray);
                })
                options.wells = wellsForJobs.flat();
            }
        },
        onChannelSelect() {
            let defaultExpression = 'max';
            this.heatmapOptions.expression = this.heatmapOptions.summaryChannels?.expressions? this.heatmapOptions.summaryChannels.expressions[0]: defaultExpression;
        },
        getExpressionDisplayName(expression) {
            if (this.stageSummaryExpressions[expression]) {
                return this.stageSummaryExpressions[expression];
            } else {
                return expression;
            }
        },    
        setInitialSelectedValues() {
            switch(this.initialSelectedComponent.name) {
            case 'stage-summary-heatmap':
                this.tabTitle = 'Stage Summary Heat Map';
                this.selectedComponentType = 'stage-summary-heatmap';
                break;
            case 'dashboard-comparison-chart':
                this.tabTitle = 'Stage Comparison';
                this.selectedComponentType = 'dashboard-comparison-chart';
                break;
            case 'well-comparison':
                this.tabTitle = 'Multi-well Comparison';
                this.selectedComponentType = 'well-comparison';
                break;
            }
        },
        sortObjArray(array, key) {
            return array.sort((a,b) => {
                if (a[key].toLowerCase() < b[key].toLowerCase()) {return -1;}
                if (a[key].toLowerCase() > b[key].toLowerCase()) {return 1;}
                return 0;
            });
        },
        getSelectedChannelNames() {
            return this.localDefaultsCopy[this.selectedComponentType]?.channels?.map(channel => channel.name) || [];
        },
        updateSelectedChannels(event) {
            const selectedChannelNames = event.map(channel => channel.name);
            this.localDefaultsCopy[this.selectedComponentType].channels = this.channelOptions.filter(channels => selectedChannelNames.includes(channels.name)) || [];
        },
        updateSelectedSummaryChannels(event) {
            this.localDefaultsCopy[this.selectedComponentType].summaryChannels = this.selectableStageSummaryChannels.filter(channels => event.includes(channels.tagName)) || [];
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        multiPadsSelected(pads) {
            this.refreshTicker = true;
            switch(this.selectedComponentType) {
            case 'dashboard-comparison-chart':
                this.stageComparisonOptions.pads = pads;
                this.getSelectableWells();
                this.getChannelsForAllPads()
                this.getChannelsForChartType(this.selectedComponentType);
                break;
            default:
                return null;
            }
        },
        singlePadSelected(pad) {
            this.refreshTicker = true;
            let padArrayWellComparison = []
            let padArrayHeatMap = [];
            switch(this.selectedComponentType) {
            case 'well-comparison':
                padArrayWellComparison.push(pad);
                this.multiWellComparisonOptions.pads = pad? padArrayWellComparison: null;
                break;
            case 'stage-summary-heatmap': 
                padArrayHeatMap.push(pad);
                this.heatmapOptions.wells = [];
                this.heatmapOptions.pads = pad? padArrayHeatMap: null;
                break;
            default:
                return null;
            }
            this.getSelectableWells();
            this.getChannelsForAllPads()
            this.getChannelsForChartType(this.selectedComponentType);
        },
        multiWellsSelected(wells) {
            switch(this.selectedComponentType) {
            case 'dashboard-comparison-chart':
                this.stageComparisonOptions.wells = wells || [];
                break;
            case 'stage-summary-heatmap':
                this.heatmapOptions.wells = wells || [];
                break;
            default:
                return null;
            }
        },
        getSelectableWells() {
            if (!this.selectablePads) {return null;}
            let selectedJobNumbers, validJobs, returnableWells;
            switch(this.selectedComponentType) { //add cases as required
            case 'dashboard-comparison-chart':
                if (!this.stageComparisonOptions.pads || this.stageComparisonOptions.pads.length == 0) {
                    this.stageComparisonOptions.wells = [];
                    return;
                }
                selectedJobNumbers = this.stageComparisonOptions.pads? this.stageComparisonOptions.pads.map(pad => pad.jobNumber): null;
                validJobs = selectedJobNumbers? this.selectablePads.filter(pad => selectedJobNumbers.includes(pad.jobNumber)): null;
                returnableWells = [];
                if (validJobs && validJobs.length > 0) {
                    validJobs.forEach(job => {
                        if (job.wells) {
                            const wellOption = {
                                subCatLabel: job.jobNumber,
                                valueArray: job?.wells || []
                            };
                            returnableWells.push(wellOption);
                        } 
                    });
                }
                this.selectableWells = returnableWells;

                this.selectAllWellsWhenPadSelected(this.stageComparisonOptions);
                break;
            case 'well-comparison':
                if (!this.multiWellComparisonOptions.pads || this.multiWellComparisonOptions.pads.length == 0) {
                    this.multiWellComparisonOptions.well = {};
                    return;
                }
                selectedJobNumbers = this.multiWellComparisonOptions.pads? this.multiWellComparisonOptions.pads.map(pad => pad.jobNumber): null;
                validJobs = selectedJobNumbers? this.selectablePads.filter(pad => selectedJobNumbers.includes(pad.jobNumber)): null;
                returnableWells = [];
                if (validJobs && validJobs.length > 0) {
                    validJobs.forEach(job => {
                        if (job.wells) {
                            const wellOption = {
                                subCatLabel: job.jobNumber,
                                valueArray: job?.wells || []
                            };
                            returnableWells.push(wellOption);
                        } 
                    });
                }
                this.selectableWells = returnableWells;
                //if no well is selected, choose the first option by default
                if (!this.multiWellComparisonOptions.well) {
                    this.multiWellComparisonOptions.well = this.selectableWells[0].valueArray[0];
                }
                break;
            case 'stage-summary-heatmap':
                if (!this.heatmapOptions.pads || this.heatmapOptions.pads.length == 0) {
                    this.heatmapOptions.wells = [];
                    return;
                }
                selectedJobNumbers = this.heatmapOptions.pads? this.heatmapOptions.pads.map(pad => pad.jobNumber): null;
                validJobs = selectedJobNumbers? this.selectablePads.filter(pad => selectedJobNumbers.includes(pad.jobNumber)): null;
                returnableWells = [];
                if (validJobs && validJobs.length > 0) {
                    validJobs.forEach(job => {
                        if (job.wells) {
                            const wellOption = {
                                subCatLabel: job.jobNumber,
                                valueArray: job?.wells || []
                            };
                            returnableWells.push(wellOption);
                        } 
                    });
                }
                this.selectableWells = returnableWells
                this.selectAllWellsWhenPadSelected(this.heatmapOptions);
                break;
            default:
                return null;
            }
        },
        saveDefaultTemplate() {
            const url = '/analysis/updateUserDefaultAnalysisTemplate';
            const self = this;
            this.isSaving = true;
            $.post(
                url,
                {
                    '_token': $('meta[name="csrf-token"]').attr('content'),
                    updatedTemplate: JSON.stringify(this.localDefaultsCopy[this.selectedComponentType]),
                    templateType: this.selectedComponentType
                    
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        //emit changed template to the parent component
                        self.$emit('update', self.localDefaultsCopy);
                    }
                    self.isSaving = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                self.isSaving = false;
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                }
            });
        },
        getChannelsForChartType(type) {
            switch(type) {
            case 'dashboard-comparison-chart':
                if (this.stageComparisonOptions?.pads && this.stageComparisonOptions.pads.length > 0) {
                    //selected options are multiselect objects so pull out job numbers
                    this.getChannelsForJobs(this.stageComparisonOptions.pads.map(pad => pad.jobNumber),this.stageComparisonOptions.index);
                } 
                break;
            case 'well-comparison':
                if (this.multiWellComparisonOptions?.pads && this.multiWellComparisonOptions.pads.length > 0) {
                    this.getChannelsForJobs([this.multiWellComparisonOptions?.pads[0]?.jobNumber]);
                } 
                break;
            case 'stage-summary-heatmap':
                this.getStageSummaryChannelsForCustomer();
                break;
            default:
                break;
            };
        },
        getChannelsForJobs: function(jobs, channelType='all') {
            const targetJobs = Array.isArray(jobs) ? jobs : [jobs];
            if (targetJobs.length == 0) {
                return;
            }
            const url = '/analysis/getChannelsForJobs';
            const self = this;

            $.get(
                url,
                {
                    targetJobs
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.getChannelsForAllPads()
                        let defaultChannels;
                        if (channelType == 'Place Frac') {
                            defaultChannels = result.defaultChannels.filter(channel => channel.type == 'frac');
                        } else if (channelType == 'Place Wireline') {
                            defaultChannels = result.defaultChannels.filter(channel => channel.type == 'wireline');
                        } else {
                            defaultChannels = result.defaultChannels;
                        }
                        self.sortObjArray(result.selectableChannels, 'prioritizedTagName'); //sort tags, then merge with default tags
                        const channels = defaultChannels.concat(result.selectableChannels);
                        //fancy one liner I found that reduces an array to a unique set based on property key
                        //https://yagisanatode.com/2021/07/03/get-a-unique-list-of-objects-in-an-array-of-object-in-javascript/
                        //Note that if there are duplicates in the array the duplicate with the higher index is what will be displayed
                        self.channelOptions = 
                            [...new Map(channels.map((channel) => [channel['name'], channel])).values()];
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                }
            });
        },
        getChannelsForAllPads: function() {
            const targetJobs = this.selectablePads.map(pad => pad.jobNumber)
            if (targetJobs.length == 0) {
                return;
            }
            const url = '/analysis/getChannelsForJobs';
            const self = this;

            $.get(
                url,
                {
                    targetJobs
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        let defaultChannels = result.defaultChannels;
                        
                        self.sortObjArray(result.selectableChannels, 'prioritizedTagName'); //sort tags, then merge with default tags
                        const channels = defaultChannels.concat(result.selectableChannels);

                        self.allChannels = 
                            [...new Map(channels.map((channel) => [channel['name'], channel])).values()];
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                }
            });
        },
        getStageSummaryChannelsForCustomer: function() {
            const url = '/analysis/getStageSummaryTagsForCustomer';
            const self = this;
            this.isLoadingChannels = true;
            $.get(
                url,
                {
                    customerId: this.customerId,
                    category: 'stage_all'//get all stag summary tags at once
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.stageSummaryChannels = result;
                    }
                    self.isLoadingChannels = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
                self.isLoadingChannels = false;
            });
        },
        setActiveTab(event) {
            this.tabTitle = event.target.textContent;
            this.selectedComponentType = this.availableDisplayItems.find(item => item.text == this.tabTitle).name;
            //set valid channel options for the newly selected tab
            if (this.localDefaultsCopy[this.selectedComponentType]?.pads) {
                this.getChannelsForAllPads();
                this.getChannelsForChartType(this.selectedComponentType);
                this.getSelectableWells();
            } else {
                this.channelOptions = [];
                this.selectableWells = [];
            }
        },
        copyUserDefaults() {
            this.localDefaultsCopy = _.cloneDeep(this.userComponentDefaults); //make a local copy

            //assign template data to variables used to v-model inputs or associate new template with empty local object
            if (this.localDefaultsCopy['well-comparison']) {
                this.multiWellComparisonOptions = this.localDefaultsCopy['well-comparison'];
            } else {
                this.localDefaultsCopy['well-comparison'] = this.multiWellComparisonOptions;
            }

            if (this.localDefaultsCopy['dashboard-comparison-chart']) {
                this.stageComparisonOptions = this.localDefaultsCopy['dashboard-comparison-chart'];
            } else {
                this.localDefaultsCopy['dashboard-comparison-chart']= this.stageComparisonOptions;
            }

            if (this.localDefaultsCopy['stage-summary-heatmap']) {
                this.heatmapOptions = this.localDefaultsCopy['stage-summary-heatmap'];
            } else {
                this.localDefaultsCopy['stage-summary-heatmap']= this.heatmapOptions;
            }
        },
        getJobsAndWellsForCustomer: function() {
            const url = '/getJobsByCustomer';
            const self = this;
            this.isLoading = true;
            $.post(
                url,
                {
                    '_token': $('meta[name="csrf-token"]').attr('content'),
                    customerId: this.customerId|| this.userCompanyId,
                    getWells: true
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } 
                    else {
                        self.selectablePads = result.map((item) => {
                            item.fullPadName = item.location + ' - ' + item.jobNumber;
                            return item;
                        }).sort((a,b)=>{
                            if (a.fullPadName < b.fullPadName) {return -1;}
                            if (a.fullPadName > b.fullPadName) {return 1;}
                            return 0;
                        });

                        //on page load, user template data may be received before the selectable option data is present.
                        //In order to ensure that saved user default data is properly displayed on first modal load,
                        //wells and channel options are determined once the pad is known and default selections are refreshed.
                        if (self.firstLoad) {
                            //filter out already selected pads carried over from other companies (only affects IWS users)
                            self.$set(self.stageComparisonOptions, 'pads',
                                _.cloneDeep(self.stageComparisonOptions.pads.filter(pad => pad.customer_id == self.customerId)));
                            self.getSelectableWells();
                            self.$set(self.stageComparisonOptions, 'wells',_.cloneDeep(self.stageComparisonOptions.wells));
                            self.getChannelsForChartType(self.selectedComponentType);
                            self.$set(self.stageComparisonOptions, 'channels',_.cloneDeep(self.stageComparisonOptions.channels));
                            self.firstLoad = false;
                        }
                    }
                    self.isLoading = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                }
            });
        },
        resetSelections(type) {
            switch(type) {
            case 'dashboard-comparison-chart':
                const newStageCompare = {
                    loadLatestStage: true,
                    channels: [],
                    pads: [],
                    wells: [],
                    aggregateInterval: 'PT1S',
                    index: 'Place Frac'

                };
                this.$set(this.localDefaultsCopy, 'dashboard-comparison-chart', newStageCompare);
                this.stageComparisonOptions = this.localDefaultsCopy[this.selectedComponentType];
                break;
            case 'well-comparison': 
                const newMultiWell = {
                    pads: [],
                    timeType: 'stage',
                    pastHours: 9,
                    channels: [],
                    aggregateInterval: 'PT1S'
                };
                this.$set(this.localDefaultsCopy, 'well-comparison', newMultiWell);
                this.multiWellComparisonOptions = this.localDefaultsCopy[this.selectedComponentType];
                break;
            case 'stage-summary-heatmap': 
                const newHeatMap = {
                    pads: [],
                    category: "stage_wireline",
                    summaryChannels: [],
                    wells: []
                };
                this.$set(this.localDefaultsCopy, 'stage-summary-heatmap', newHeatMap);
                this.heatmapOptions = this.localDefaultsCopy[this.selectedComponentType];
                this.channelOptions = [];
                break;
            };
        },
    },
    watch: {
        selectablePads: {
            handler(newVal, oldVal) {
                this.getChannelsForAllPads();
            }
        }
    }
};
</script>
</style>

