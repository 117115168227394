var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-2" },
    _vm._l(_vm.passwordRequirements, function (requirement, key) {
      return _c("div", { key: key }, [
        _c(
          "span",
          {
            class: requirement.value
              ? "requirement valid"
              : "requirement invalid",
          },
          [
            _c("b-icon", {
              attrs: { icon: requirement.value ? "check2-all" : "x" },
            }),
            _vm._v(
              "\n            " + _vm._s(requirement.description) + "\n        "
            ),
          ],
          1
        ),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }