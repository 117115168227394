var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: `library_add_edit_${_vm.id}`,
      staticClass: "dark-modal modal",
      class: { "extended-dialog": _vm.addMoreSelection === true },
      attrs: { id: `library_add_edit_${_vm.id}`, role: "document" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [_vm._t("title")], 2),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _vm.showBody
            ? _c("div", { staticClass: "modal-body" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    {
                      class: {
                        "col-12 col-lg-6": _vm.addMoreSelection,
                        "col-12": !_vm.addMoreSelection,
                      },
                    },
                    [
                      _vm._t("leftBody", null, { testForm: _vm.testForm }),
                      _vm._v(" "),
                      _c("div", { attrs: { id: "add-details-container" } }, [
                        _c("p", [
                          _vm._v(
                            "\n                                Would you like to add in more details *\n                            "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", { attrs: { id: "add-details-control" } }, [
                          _c(
                            "span",
                            {
                              staticClass: "clickable",
                              class: { active: _vm.addMoreSelection === true },
                              on: {
                                click: function ($event) {
                                  _vm.addMoreSelection = true
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    Yes, add more details "
                              ),
                              _c("span", { staticClass: "circle" }, [
                                _vm.addMoreSelection === true
                                  ? _c("i", { staticClass: "fas fa-check" })
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "span",
                            {
                              staticClass: "clickable",
                              class: { active: _vm.addMoreSelection === false },
                              on: {
                                click: function ($event) {
                                  _vm.addMoreSelection = false
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n                                    No, leave it as it is "
                              ),
                              _c("span", { staticClass: "circle" }, [
                                _vm.addMoreSelection === false
                                  ? _c("i", { staticClass: "fas fa-check" })
                                  : _vm._e(),
                              ]),
                            ]
                          ),
                        ]),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.addMoreSelection === true
                    ? _c(
                        "div",
                        { staticClass: "col-12 col-lg-6" },
                        [_vm._t("rightBody", null, { testForm: _vm.testForm })],
                        2
                      )
                    : _vm._e(),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c(
                "button",
                {
                  staticClass: "btn btn-outline btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("\n                    Cancel\n                ")]
              ),
              _vm._v(" "),
              _c("iws-button", {
                attrs: {
                  text: _vm.isEditMode(_vm.item) ? "Save Changes" : "Create",
                  type: "primary",
                  click: _vm.onSaveClick,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }