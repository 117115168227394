var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-kpi-template", {
    ref: "baseKpiTemplate",
    attrs: {
      title: "Completion Progress",
      item: _vm.item,
      "inital-options": _vm.initalOptions,
      "edit-mode": _vm.editMode,
      "create-export-data": _vm.createExportData,
      "analytics-data": _vm.analyticsData,
      errors: _vm.errors,
      "show-not-enough-data-warning": _vm.showNotEnoughDataWarning,
      "show-chart": _vm.showChart,
    },
    on: {
      analyticsChanges: function ($event) {
        return _vm.buildAnalytics()
      },
      settingsChanges: function ($event) {
        return _vm.buildData()
      },
      revertChanges: function ($event) {
        return _vm.buildData()
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "settings",
          fn: function () {
            return [
              _c("iws-input", {
                attrs: {
                  label: "Projected Stages Per Day",
                  type: "number",
                  value: _vm.item.options.progressPerDay[_vm.job.jobNumber],
                  step: "0.5",
                  min: "0",
                  "form-spacing": "",
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.item.options.progressPerDay,
                      _vm.job.jobNumber,
                      $event
                    )
                  },
                  change: function ($event) {
                    return _vm.setupCompletedStagesPerDay()
                  },
                },
              }),
              _vm._v(" "),
              _c("iws-checkbox", {
                attrs: {
                  label: "Full Job",
                  value: _vm.item.options.isFullJob,
                  "form-spacing": "",
                  "enable-click-label": "",
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(_vm.item.options, "isFullJob", $event)
                  },
                  change: function ($event) {
                    return _vm.onFullJobchange()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
        {
          key: "extra-info",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "extra-info" },
                [
                  _vm._v(
                    "\n            Time Range: " +
                      _vm._s(_vm.getTimeRangeDescription) +
                      " "
                  ),
                  _c("br"),
                  _vm._v(" "),
                  _vm.averageActualStagesPerDay > 0
                    ? [
                        _vm._v(
                          "\n                Average stages completed per day: " +
                            _vm._s(_vm.averageActualStagesPerDay) +
                            "\n            "
                        ),
                      ]
                    : _vm._e(),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
        _vm.showChart
          ? {
              key: "content",
              fn: function () {
                return [
                  _c("simple-line-chart", {
                    ref: "kpi",
                    attrs: { "chart-data": _vm.kpi, options: _vm.options },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }