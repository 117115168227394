<template>
	<div class="d-flex flex-column flex-fill">
		<div class="d-flex justify-content-center mb-2"> 
			<b>{{ title }}</b>
		</div>
		<div>
			<div class="d-flex p-2 font-small inner-bg flex-fill">
				1)Select the number of guns you are running <br/><br/>
				2) Record the distance between th CCL and the top of each gun and plug <br/><br/>
				3) Choose a Gun to run under Gun Specifications <br/><br/>
				4) Choose a Plug to run under Plug Specifications <br/><br/>
				• in the event the gun or plug isn't listed, you can add a new one by selecting "Other..." 
			</div>
		</div>
		<div class="flex-1 inner-bg"></div>
	</div>
</template>
<script>
export default {
	props : ['title']
}
</script>

<style>

</style>