var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._v(
      "\n    Push to talk " +
        _vm._s(_vm.version) +
        ", communicationUserId: " +
        _vm._s(_vm.communicationUserId) +
        "\n    "
    ),
    _c("br"),
    _vm._v("\n    Status: " + _vm._s(_vm.status) + "\n    "),
    _vm.apiKey
      ? _c("div", [_vm._v(" --- field device mode --- ")])
      : _c("div", [_vm._v(" --- dashboard user mode --- ")]),
    _vm._v(" "),
    _vm.call && !_vm.callEnded
      ? _c("div", { staticClass: "row-col" }, [
          _c(
            "div",
            { staticClass: "btn btn-danger", on: { click: _vm.endCall } },
            [_vm._v("\n            End call\n        ")]
          ),
          _vm._v(" "),
          !_vm.apiKey
            ? _c(
                "div",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    pointerdown: function ($event) {
                      return _vm.pushToTalk("start")
                    },
                    pointerup: function ($event) {
                      return _vm.pushToTalk("end")
                    },
                  },
                },
                [_vm._v("\n            Push to talk\n        ")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "btn btn-warning" }, [
                _vm._v(
                  "\n            Please wait...Connecting... waiting for okay to talk...\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.pushToTalkInProgress
            ? _c("div", { staticClass: "btn btn-success" }, [
                _vm._v("\n            Push to talk in progress...\n        "),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }