<template>
    <div class="col-12 col-md-6 col-lg-4 col-xl px-1 py-1">            
        <div class="card-deck">
            <div class="card" >
                <div :style="{'background-color':backgroundColor, 'color':color}" class="card-header center-block">
                    <h5 class="text-center">{{name}}</h5>
                    <div class="text-center"><img :style="{'background-color':backgroundColor, 'color':color}" v-bind:src="image"></div>
                </div>
                <div class="card-body bg-light">
                    
                    <div  class="card-group">
                        <div :style="{'margin': 0}" class="card " >
                            <div class="p-1 card-body ">
                                <h6 class="card-title font-weight-bold">Start</h6>
                            </div>    
                        </div>
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <h6 class="card-text"><small>{{start}}</small></h6>
                            </div>    
                        </div>  
                    </div>   
                    <div class="card-group">
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <h6 class="card-title font-weight-bold">Stage</h6>
                            </div>    
                        </div>
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <p class="card-text"><small>{{currentStage}}/{{numStages}}</small></p>
                            </div>    
                        </div>  
                    </div> 
                    <div class="card-group">
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <h6 class="card-title font-weight-bold">Duration</h6>
                            </div>    
                        </div>
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <p class="card-text"><small>{{duration}}</small></p>
                            </div>    
                        </div>  
                    </div>
                    <div class="card-group">
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <h6 class="card-title font-weight-bold">Status</h6>
                            </div>    
                        </div>
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <p class="card-text"><small>{{status}}</small></p>
                            </div>    
                        </div>  
                    </div>
                    <div :style="{'margin': 0 }" class="card text-center">
                        <div class="card-body  bg-light">
                            <h6 class="card-title font-weight-bold">psi</h6>
                        </div>    
                    </div>
                    <div class="card-group">
                        <div :style="{'margin': 0 }" class="card">
                            <div  class="p-1 card-body">
                                <h6 class="card-title font-weight-bold">Well</h6>
                            </div>    
                        </div>
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <p class="card-text"><small>{{pressureWell}}</small></p>
                            </div>    
                        </div>  
                    </div> 
                    <div class="card-group" v-if="activity=='wireline'">
                        <div  :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <h6 class="card-title font-weight-bold">Pumpdown</h6>
                            </div>    
                        </div>
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <p class="card-text"><small>{{pressurePumpdown}}</small></p>
                            </div>    
                        </div>  
                    </div>
                    <div class="card-group" v-if="activity=='frac'">
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <h6 class="card-title font-weight-bold">Zipper</h6>
                            </div>    
                        </div>
                        <div :style="{'margin': 0 }" class="card">
                            <div class="p-1 card-body">
                                <p class="card-text"><small>{{pressureFrac}}</small></p>
                            </div>    
                        </div>  
                    </div>
                    <div :style="{'margin': 0 }" class="card" v-if="activity=='wireline'">
                        <div class="card-body text-center  bg-light">
                            <h6 class="card-title font-weight-bold">Wireline</h6>
                        </div>  
                        <div class="card-group" >
                            <div :style="{'margin': 0 }" class="card">
                                <div class="p-1 card-body">
                                    <h6 class="card-title font-weight-bold">Diameter (in)</h6>
                                </div>    
                            </div>
                            <div :style="{'margin': 0 }" class="card">
                                <div class="p-1 card-body">
                                    <p class="card-text"><small>{{wirelineDiameter}}</small></p>
                                </div>    
                            </div>  
                        </div>
                        <div class="card-group" >
                            <div :style="{'margin': 0 }" class="card">
                                <div class="p-1 card-body">
                                    <h6 class="card-title font-weight-bold">Depth (ft)</h6>
                                </div>    
                            </div>
                            <div :style="{'margin': 0 }" class="card">
                                <div  class="p-1 card-body">
                                    <p class="card-text"><small>{{wirelineDepth}}</small></p>
                                </div>    
                            </div>  
                        </div>
                        <div class="card-group" >
                            <div :style="{'margin': 0 }" class="card">
                                <div class="p-1 card-body">
                                    <h6 class="card-title font-weight-bold">Speed (ft/min)</h6>
                                </div>    
                            </div>
                            <div :style="{'margin': 0 }" class="card">
                                <div  class="p-1 card-body">
                                    <p class="card-text"><small>{{wirelineSpeed}}</small></p>
                                </div>    
                            </div>  
                        </div>
                        <div class="card-group" >
                            <div :style="{'margin': 0 }" class="card">
                                <div class="p-1 card-body">
                                    <h6 class="card-title font-weight-bold">Tension (lbs)</h6>
                                </div>    
                            </div>
                            <div :style="{'margin': 0 }" class="card">
                                <div  class="p-1 card-body">
                                    <p class="card-text"><small>{{wirelineSpeed}}</small></p>
                                </div>    
                            </div>  
                        </div>              
                    </div>        
                </div>
            </div>
        </div>
    </div>                

</template>

<script>
    export default {
        data() {
            return {
                "id":"something",
                "duration":'-',
            }
        },
        props: ['name','backgroundColor','color','activity','start','currentStage','numberOfStages','status','pressureWell','pressurePumpdown',
        'pressureFrac','wirelineDiameter','wirelineDepth','wirelineSpeed','wirelineTension','stageDuration'],
        mounted() {
            setInterval(this.calcDuration,1000);
        },
        methods: {
            calcDuration: function(){
                //console.log("running calc duration");
                var diff = (new Date() - this.startTimestamp) / 1000;
                if(this.status == 'Completed' || this.status == 'Stage complete')
                    diff = this.stageDuration * 60;
                var hours = Math.floor(diff/3600);
                diff = diff - hours * 3600; 
                var minutes = Math.floor(diff/60);
                diff = diff - minutes * 60;
                if(minutes < 10)
                    minutes = "0"+minutes;
                var seconds = Math.floor(diff/1);
                if(seconds < 10)
                    seconds = "0"+seconds;
                this.duration = hours +":"+minutes+":"+seconds;
            }
        },
        computed: {
            image: function(){
                //console.log("Will compute the image now...");
                var i = 'images/check_transparent.png';
                var a = this.activity;
                switch(a){
                    case "frac":
                        i = '../images/frac.png';
                        break;
                    case "wireline":
                        i = "../images/wireline.png";
                        break;
                    case "maintenance":
                        i = "../images/maintenance.png";
                        break;
                    default:
                        i = "../images/check_transparent.png";
                        break;
                }
                    
                
                return i;
            },
            startTimestamp: function(){
                return new Date(this.start);
            }
        }
    }
</script>
