<template>
    <div>
        <iws-breadcrumbs class="mt-4 mb-1"
            :path="[{
                title: 'Home',
                link: '/home'
            }, {
                title: 'User Management',
                link: '/userManagement'
            }]"
        />

        <div class="d-flex justify-content-between mb-4">
            <div class="page-title">
                User Management
            </div>
            <div v-if="role === 'admin'" style="position: relative; top: -20px">
                <button class="btn btn-primary" @click="create()">New User</button>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-12 form-inline">
                <div class="form-group mr-3 ml-3"><input type="text" v-model="search" class="form-control search-bar" placeholder="Filter by Name or Email" /></div>
                <div class="form-group mr-3">
                    <select id="role" class="form-control" name="role" v-model="selectedRole">
                        <option selected :value="null">Filter by Role</option>
                        <option v-for="role in sortedRoles" :key="role.id">{{role.name}}</option>
                    </select>
                </div>
                <div  v-if="role == 'admin'" class="form-group mr-3" >
                    <select id="customer" class="form-control" name="customer" v-model="selectedCustomer">
                        <option selected :value="null">Filter by Company</option>
                        <option v-for="customer in sortedCustomers" :key="customer.id">{{customer.name}}</option>
                    </select>
                </div>
                <div class="form-group mr-3">
                    <select id="deleted" class="form-control" name="deleted" v-model="deleted">
                        <option value="0">Current</option>
                        <option value="1">Deleted</option>
                    </select>
                </div>
                <div class="form-group mr-3">
                    <select id="order" class="form-control" name="order" v-model="order">
                        <option value="asc">Asc</option>
                        <option value="desc">Desc</option>
                    </select>
                </div>
                <div class="mr-3 d-flex align-items-center">
                    <span v-tooltip:top="'Clear All Filters'">
                        <button @click="clearFilters()" class="btn btn-outline-light" :disabled="column == ''  && order == 'asc' && deleted == '' && search == '' && selectedRole == null && selectedCustomer== null " >
                            Clear
                        </button>
                    </span>
                </div>
                <div class="mr-3">
                    <button class="btn btn-primary" @click="filter()" id="filter_button"><b-icon icon="filter"/>Filter</button>
                </div>
                <div class="d-flex justify-content-end">
                    <div class="mr-3 d-flex align-items-center">
                        <span v-tooltip:top="'Export Filtered Table'">
                            <i class="fas fa-download fa-lg clickable" @click="exportTable"></i>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col">
                <table v-if="usersList.length > 0" class="table table-striped table-hover mt-2" :class="{'table-dark': darkMode}">
                    <thead>
                    <th scope="col" @click="sort('first_name')"><span class="fas fa-sort"></span>Name</th>
                    <th scope="col" @click="sort('username')"><span class="fas fa-sort"></span>Username</th>
                    <th scope="col" @click="sort('email')"><span class="fas fa-sort"></span>Email</th>
                    <th scope="col" @click="sort('phone_number')"><span class="fas fa-sort"></span>Phone</th>
                    <th scope="col" @click="sort('customer_id')"><span class="fas fa-sort"></span>Company</th>
                    <th scope="col" >Role</th>
                    <th v-if="isIws" scope="col">Permission</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                    </thead>
                    <tbody>
                    <tr v-for="user in usersList" :key="user.id">
                        <td>{{user.first_name}} {{user.last_name}}</td>
                        <td>{{user.username}}</td>
                        <td>{{user.email}}</td>
                        <td>{{user.phone_number}}</td>
                        <td>{{user.customer ? user.customer.name : 'None'}}</td>
                        <td v-if="user.roles.length != 0">
                            <div v-for="userRole in user.roles" :key="userRole.id">
                                {{userRole.name}}
                            </div>
                        </td>
                        <td v-if="user.roles.length == 0"></td>
                        <td v-if="isIws" style="text-transform: capitalize;">
                            <span v-if="showLegacyPermissionText(user)" style="color:red;">Legacy - </span>
                            {{ permissionList(user.dashboard_permissions) }}
                        </td>

                        <td>
                            <span v-if="isIws"
                            class="badge badge-primary" @click="edit(user.id)" style="cursor:pointer;">Edit</span>
                            <span v-else>
                                <span class="badge badge-primary" @click="view(user.id)" style="cursor:pointer;">View</span>
                            </span>
                        </td>
                        <td v-if="user.deleted_at === null">
                            <span class="badge badge-danger" @click="remove(user.id)" style="cursor:pointer;"
                            v-if="isIws && user.id != authUser.id">Delete</span>
                        </td>

                        <td v-if="user.deleted_at !== null">
                            <span class="badge badge-danger" @click="restore(user.id)" style="cursor:pointer;"
                                v-if="isIws">Restore</span>
                        </td>

                        <td v-if="isIws">
                            <span :id="'user-session-popover-target-' + user.id"
                                class="badge badge-info" style="cursor:pointer;">Sessions</span>
                            <b-popover custom-class="config-history-popover" :target="'user-session-popover-target-' + user.id" triggers="hover focus" placement="bottom">
                                <template #title>
                                    <div class="history-popover-title">
                                        User Sessions for {{ user.username }}
                                    </div>
                                </template>
                                <b-overlay rounded="sm" bg-color="#f9f9fa">
                                    <div class="p-3">
                                        <div style="max-height: 600px; max-width: 800px; overflow-y: auto;">
                                            <iws-table
                                                :columns="['last_activity', 'ip_address', 'user_agent']"
                                                :items="user.sessions"
                                            >
                                                <template #header_last_activity>
                                                    Last Activity
                                                </template>
                                                <template #header_ip_address>
                                                    IP Address
                                                </template>
                                                <template #header_user_agent>
                                                    User Agent
                                                </template>

                                                <template #cell_last_activity="{ data }">
                                                    {{new Date(data.value * 1000)}}
                                                </template>
                                            </iws-table>
                                        </div>

                                        <hr v-if="user.sessions.length > 0">

                                        <div v-if="user.sessions.length > 0" class="d-flex justify-content-end">
                                            <button id="clear-all-button" class="btn btn-outline-light" @click="clearAllUserSessions(user)">
                                                <i class="fas fa-undo" /> Clear All
                                            </button>
                                        </div>
                                    </div>
                                </b-overlay>
                            </b-popover>
                        </td>
                    </tr>
                    </tbody>
                </table>
            <div v-if="usersList.length <= 0" class="title m-b-md">No users match these filters.</div>
            </div>
        </div>

        <form id="logout-form" action="logout" method="POST" style="display: none;">
            @csrf
        </form>

    </div>

</template>

<script>
import GlobalFunctions from '../GlobalFunctions';

export default {
    data() {
        return {
            usersList: this.users.data,
            column: '',
            order: 'asc',
            deleted: '0',
            search: '',
            selectedRole: null,
            selectedCustomer: null
        }
    },
    mounted(){
        const splitURL = window.location.pathname.split('/');
        if (splitURL.length > 5){
            this.search = splitURL[3] !== 'null.0' ? splitURL[3].split('%20').join(' ') : "";
            this.selectedRole = splitURL[4] !== 'null.0' ? splitURL[4] : null;
            this.selectedCustomer = splitURL[5] !== 'null.0' ? splitURL[5] : null;
            this.deleted = splitURL[6];
            this.order = splitURL[7];
        }

        document.addEventListener("keyup", function(event) {
            if(event.keyCode === 13) {
                document.getElementById("filter_button").click();
            }
        })
    },
    props: ['users', 'role', 'roles', 'customers', 'isIws', 'authUser'],
    methods: {
        clearFilters(){
            window.location.pathname = "/userManagement";
        },

        isLegacyDashboardPermissions: function(dashboardPermissions) {
            return !Array.isArray(dashboardPermissions) && !this.isJsonString(dashboardPermissions);
        },
        showLegacyPermissionText: function(user) {
            return (this.isIws && user && this.isLegacyDashboardPermissions(user?.dashboard_permissions));
        },
        isJsonString: function(str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        },
        permissionList: function(dashboardPermissions) {
            //Adapts user permission list for both new/old dashboard
            //permission handling:
            let permissionList;
            if (Array.isArray(dashboardPermissions)) {
                if (dashboardPermissions.length == 0) {
                    permissionList = 'none'
                } else {
                    permissionList = dashboardPermissions.join(", ");
                }
                return permissionList;
            }
            if (this.isJsonString(dashboardPermissions)) {
                dashboardPermissions = JSON.parse(dashboardPermissions);
                if (dashboardPermissions.length == 0) {
                    permissionList = 'none'
                } else {
                    permissionList = dashboardPermissions.join(", ");
                }
                return permissionList;
            }
            else {
                return dashboardPermissions;
            }
        },
        canEditOrDeleteUser: function(user) {
            if (user.roles[0]) {
                let userRole = user.roles[0].name; //role of user in user list
                return (this.role == 'iwsTech' && userRole != 'admin') || this.role == 'admin' || this.role == 'companyAdmin';
            } else {
                return true;
            }
        },
        clearAllUserSessions: function(user) {
            if(confirm("Do you really want to clear all sessions for this user?")) {
                const self= this;
                $.ajax({
                    method: "get",
                    url: "/userManagement/clearSessions",
                    data:{id:user.id}
                }).done(function() {
                    if(user.id == self.authUser?.id) {
                        //if the user being cleared is the current user, we need to log them out or the active session will persist
                        document.getElementById('logout-form').submit();
                    }
                    else {
                        user.sessions = [];
                    }
                });
            } else {
                return false;
            }
        },
        create: function () {
            window.open("/register", "_self");
        },
        edit: function (id) {
            window.open("/userManagement/" + id + "/edit", "_self");
        },
        view: function (id) {
            window.open("/userManagement/" + id + "/view", "_self");
        },
        remove: function(id) {
            if(confirm("Do you really want to delete this user?")) {
                const self= this;
                $.ajax({
                    method: "post",
                    url: "/userManagement/delete",
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data: { id: id }
                });
                const userIndex = self.usersList.map(function(user) {return user.id;}).indexOf(id);
                self.usersList.splice(userIndex, 1);
            } else {
                return false;
            }
        },
        restore: function(id) {
            if(confirm("Do you really want to restore this user?")) {
                const self= this;
                $.ajax({
                    method: "post",
                    url: "/userManagement/restore",
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    data:{id:id}
                });
                const userIndex = self.usersList.map(function(user) {return user.id;}).indexOf(id);
                self.usersList.splice(userIndex, 1);
            } else {
                return false;
            }
        },
        sort: function (columnName) {
            const pathName = window.location.pathname
            let sortOrder = 'asc';
            if (pathName.includes(columnName)){
                if (pathName.includes('asc')){
                    sortOrder = 'desc';
                }
            }
            this.column = columnName;
            window.open("/userManagement/sort/" + this.column +"/"+ sortOrder, "_self");
        },
        filter: function() {
            this.search = this.search.trim()
            window.open("/userManagement/filter/" +(!this.search ? 'null.0' : this.search )+"/"+ (!this.selectedRole? 'null.0': this.selectedRole )+"/"+ (!this.selectedCustomer? 'null.0' : this.selectedCustomer) +"/"+ ( !this.deleted ? '0' : this.deleted )+"/"+ (!this.order? 'asc': this.order), "_self");
        },

        exportTable:function(){

            $.ajax({
                method: 'GET',
                url: '/exportUsers/' + (!this.search ? 'null.0' : this.search )+"/"+ (!this.selectedRole? 'null.0': this.selectedRole )+"/"+ (!this.selectedCustomer? 'null.0' : this.selectedCustomer) +"/"+ ( !this.deleted ? '0' : this.deleted )+"/"+ (!this.order? 'asc': this.order),
                success: function (data) {
                    const blob = new Blob([data], { type: 'text/csv' });
                    const link = document.createElement('a');  // Create a link element
                    link.download = 'users.csv'; // Set the download attribute and create a URL for the Blob
                    link.href = window.URL.createObjectURL(blob);
                    document.body.appendChild(link);// Append the link to the document
                    link.click();// Trigger a click on the link to start the download
                    document.body.removeChild(link);  // Remove the link from the document
                },
                error: function (error) {
                    console.error('Error exporting data:', error);
                }
            })
        }

    },
    computed: {
        sortedRoles: function() {
            return (this.roles.sort((a,b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();

                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }));
        },
        sortedCustomers: function() {
            return (this.customers.sort((a,b) => {
                var textA = a.name.toUpperCase();
                var textB = b.name.toUpperCase();

                return (textA < textB) ? -1 : (textA > textB) ? 1 : 0;
            }));
        },
        darkMode: function () {
            return this.$root.darkMode;
        }
    }
}
</script>

<style scoped>
th {
    cursor: pointer;
    width: 12%;
}

label {
    padding: 6px;
}

hr {
    background: #7B8A98;
}

.search-bar {
    width: 350px;
}
</style>
