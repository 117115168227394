<template>
     <div class="collapse bg-secondary w-50" style="z-index:11;" id="warningsCollapsible">
        <div class="py-1 px-1" v-for="typedWarnings, path in allWarnings">
            <span class="badge badge-secondary">From: {{path}}</span>
            <div class="alert alert-warning alert-dismissible fade show m-1 p-1" role="alert" v-for="warning in typedWarnings" :key="warning.id">
                <strong>{{warning}}</strong>
            </div>
        </div>
        <div class="navbar navbar-light bg-light">
            <button class="btn btn-warning" type="button">
                hidden
            </button>
        </div>
    </div>
</template>

<style>
    #warningsCollapsible { 
        position: absolute;
        left: 0; 
        bottom: 0;
        z-index: 6;
    }
</style>

<script>
    export default {
        props:  {
            allWarnings: {
                default: {},
                type: Object
            }
        }
    }
</script>