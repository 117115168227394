var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      id: "comms-control",
      classObj: {
        pushToTalkInProgress: _vm.pushToTalkInProgress,
        minimized: _vm.minimized,
        callIsMuted: _vm.callIsMuted,
      },
      "show-modal": _vm.modalIsOpen,
      "primary-button-visible": false,
      "secondary-button-visible": false,
      "show-backdrop": false,
      "enable-click-outside": true,
      "close-on-clickaway": false,
      "can-click-outside-components": true,
      "max-width": !_vm.minimized ? "420px" : "188px",
      "max-height": _vm.getModalHeight,
      top: _vm.commsModalPositionTop,
      left: _vm.commsModalPositionLeft,
    },
    on: {
      close: function ($event) {
        return _vm.$emit("close", $event)
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [
              !_vm.minimized
                ? _c(
                    "div",
                    {
                      staticClass: "modal-title",
                      staticStyle: { "font-size": "19px", display: "flex" },
                      on: { mousedown: _vm.dragStart },
                    },
                    [
                      _vm._v(
                        "\n            BETA - " +
                          _vm._s(
                            _vm.job?.location || _vm.job?.jobNumber || "Call"
                          ) +
                          "\n            "
                      ),
                      _vm.connected && _vm.activeSpeakerUserId.length > 0
                        ? _c("div", { staticClass: "equaliser-container" }, [
                            _c("ol", { staticClass: "equaliser-column" }, [
                              _c("li", { staticClass: "colour-bar" }),
                            ]),
                            _vm._v(" "),
                            _c("ol", { staticClass: "equaliser-column" }, [
                              _c("li", { staticClass: "colour-bar" }),
                            ]),
                            _vm._v(" "),
                            _c("ol", { staticClass: "equaliser-column" }, [
                              _c("li", { staticClass: "colour-bar" }),
                            ]),
                            _vm._v(" "),
                            _c("ol", { staticClass: "equaliser-column" }, [
                              _c("li", { staticClass: "colour-bar" }),
                            ]),
                            _vm._v(" "),
                            _c("ol", { staticClass: "equaliser-column" }, [
                              _c("li", { staticClass: "colour-bar" }),
                            ]),
                          ])
                        : _vm._e(),
                    ]
                  )
                : !_vm.connected
                ? _c("iws-button", {
                    attrs: {
                      type: "primary btn-sm",
                      click: _vm.joinCall,
                      disabled: _vm.activeChannels.length === 0,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "fas fa-phone" }),
                            _vm._v(" Join\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  })
                : _c("iws-button", {
                    attrs: {
                      type: _vm.pushToTalkConnecting
                        ? "warning btn-sm"
                        : "success btn-sm",
                      "data-toggle": "tooltip",
                      disabled: _vm.commsRole !== "talk",
                      "data-placement": "top",
                      title:
                        _vm.commsRole !== "talk"
                          ? "You lack talk permissions"
                          : "Hold Push to Talk or Ctrl + Space to talk",
                    },
                    on: {
                      press: function ($event) {
                        return _vm.$emit("pushToTalk", "start")
                      },
                      release: function ($event) {
                        return _vm.$emit("pushToTalk", "end")
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _vm.pushToTalkInProgress
                              ? _c("span", [
                                  _c("i", { staticClass: "fas fa-microphone" }),
                                  _vm._v(" Talking"),
                                ])
                              : _c("span", [
                                  _c(
                                    "svg",
                                    {
                                      attrs: {
                                        width: "24",
                                        height: "24",
                                        viewBox: "0 0 24 24",
                                        fill: "none",
                                        xmlns: "http://www.w3.org/2000/svg",
                                      },
                                    },
                                    [
                                      _c("path", {
                                        attrs: {
                                          "fill-rule": "evenodd",
                                          "clip-rule": "evenodd",
                                          d: "M12 15V15C10.343 15 9 13.657 9 12V6C9 4.343 10.343 3 12 3V3C13.657 3 15 4.343 15 6V12C15 13.657 13.657 15 12 15Z",
                                          stroke: "white",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d: "M18 10V12C18 15.314 15.314 18 12 18V18C8.686 18 6 15.314 6 12V10",
                                          stroke: "white",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d: "M12 18V21",
                                          stroke: "white",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("path", {
                                        attrs: {
                                          d: "M7.82031 21H16.1803",
                                          stroke: "white",
                                          "stroke-width": "1.5",
                                          "stroke-linecap": "round",
                                          "stroke-linejoin": "round",
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" Talk\n\n               "),
                                ]),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
              _vm._v(" "),
              _c("i", {
                class: `fas expand fa-${
                  _vm.minimized ? "expand" : "compress"
                }-alt clickable`,
                on: { click: _vm.toggleSize },
              }),
              _vm._v(" "),
              !_vm.minimized
                ? _c("i", {
                    staticClass: "fas fa-sliders-h clickable",
                    on: { click: _vm.togglePosition },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("i", {
                staticClass: "fas fa-arrows-alt modal-title",
                class: _vm.minimized ? "minimized-class" : "maximized-class",
                on: { mousedown: _vm.dragStart },
              }),
            ]
          },
          proxy: true,
        },
        !_vm.minimized
          ? {
              key: "content",
              fn: function () {
                return [
                  _vm.connected
                    ? [
                        _c("div", { staticClass: "row" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c(
                              "span",
                              { staticStyle: { "font-size": "15px" } },
                              [
                                _vm._v(
                                  "Active Channel:   " +
                                    _vm._s(_vm.getActiveChannelText) +
                                    " "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticStyle: { "font-size": "13px" } },
                              [
                                _c("i", { staticClass: "fas fa-info-circle" }),
                                _vm._v(
                                  " If you wish to change channels, please disconnect the call first\n                "
                                ),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "row mt-2",
                            attrs: { id: "comms-control-container" },
                          },
                          [
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("iws-button", {
                                  attrs: { type: "outline-danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("endCallFromButton")
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _c("i", {
                                              staticClass: "fas fa-phone-slash",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    787408976
                                  ),
                                }),
                                _vm._v(" "),
                                _c("iws-button", {
                                  attrs: {
                                    type: "outline-light",
                                    disabled: _vm._isNullOrEmpty(_vm.logs),
                                    "data-toggle": "collapse",
                                    "data-target": "#collapseLog",
                                    "aria-expanded": "true",
                                    "aria-controls": "collapseLog",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("fetchLogs")
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _c("i", {
                                              staticClass: "fas fa-list-ul",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2344591186
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass: "col-4",
                                attrs: {
                                  "data-toggle": "tooltip",
                                  "data-placement": "top",
                                  title:
                                    _vm.commsRole !== "talk"
                                      ? "You lack talk permissions"
                                      : "Hold Push to Talk or Ctrl + Space to talk",
                                },
                              },
                              [
                                _c("iws-button", {
                                  staticStyle: {
                                    width: "100%",
                                    padding: "5px",
                                  },
                                  attrs: {
                                    type: _vm.getPTTButtonType,
                                    disabled: _vm.commsRole !== "talk",
                                  },
                                  on: {
                                    press: function ($event) {
                                      return _vm.$emit("pushToTalk", "start")
                                    },
                                    release: function ($event) {
                                      return _vm.$emit("pushToTalk", "end")
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _vm.pushToTalkInProgress
                                              ? _c("span", [
                                                  _c("i", {
                                                    staticClass:
                                                      "fas fa-microphone",
                                                  }),
                                                  _vm._v(" Talking "),
                                                ])
                                              : _c("span", [
                                                  _c(
                                                    "svg",
                                                    {
                                                      attrs: {
                                                        width: "24",
                                                        height: "24",
                                                        viewBox: "0 0 24 24",
                                                        fill: "none",
                                                        xmlns:
                                                          "http://www.w3.org/2000/svg",
                                                      },
                                                    },
                                                    [
                                                      _c("path", {
                                                        attrs: {
                                                          "fill-rule":
                                                            "evenodd",
                                                          "clip-rule":
                                                            "evenodd",
                                                          d: "M12 15V15C10.343 15 9 13.657 9 12V6C9 4.343 10.343 3 12 3V3C13.657 3 15 4.343 15 6V12C15 13.657 13.657 15 12 15Z",
                                                          stroke: "white",
                                                          "stroke-width": "1.5",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M18 10V12C18 15.314 15.314 18 12 18V18C8.686 18 6 15.314 6 12V10",
                                                          stroke: "white",
                                                          "stroke-width": "1.5",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M12 18V21",
                                                          stroke: "white",
                                                          "stroke-width": "1.5",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c("path", {
                                                        attrs: {
                                                          d: "M7.82031 21H16.1803",
                                                          stroke: "white",
                                                          "stroke-width": "1.5",
                                                          "stroke-linecap":
                                                            "round",
                                                          "stroke-linejoin":
                                                            "round",
                                                        },
                                                      }),
                                                    ]
                                                  ),
                                                  _vm._v(
                                                    " Push to Talk\n                            "
                                                  ),
                                                ]),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2474540462
                                  ),
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col" },
                              [
                                _c("iws-button", {
                                  attrs: {
                                    type: "outline-light",
                                    disabled: _vm._isNullOrEmpty(_vm.users),
                                    "data-toggle": "collapse",
                                    "data-target": "#collapseUsers",
                                    "aria-expanded": "true",
                                    "aria-controls": "collapseUsers",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.showUserAvatarList =
                                        !_vm.showUserAvatarList
                                    },
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _c("i", {
                                              staticClass: "fas fa-users",
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    4003751206
                                  ),
                                }),
                                _vm._v(" "),
                                _c("iws-button", {
                                  attrs: {
                                    type: _vm.callIsMuted
                                      ? "outline-warning"
                                      : "outline-light",
                                  },
                                  on: { click: _vm.toggleMute },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "text",
                                        fn: function () {
                                          return [
                                            _c("i", {
                                              class: `fas fa-volume-${
                                                _vm.callIsMuted ? "mute" : "up"
                                              }`,
                                            }),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1199288391
                                  ),
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        !_vm._isFalsy(_vm.callMutedStampfromNow)
                          ? _c(
                              "div",
                              { staticClass: "timestamp-details warning" },
                              [
                                _vm._v(
                                  "\n                Muted call " +
                                    _vm._s(_vm.callMutedStampfromNow) +
                                    " ago\n            "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        !_vm._isNullOrEmpty(_vm.users) && _vm.showUserAvatarList
                          ? _c("div", { staticClass: "mt-2" }, [
                              _c(
                                "ul",
                                { staticClass: "avatar-list" },
                                [
                                  _vm._l(
                                    _vm.activeUserList,
                                    function (user, index) {
                                      return _c(
                                        "li",
                                        {
                                          key: index,
                                          staticClass: "avatar",
                                          class:
                                            _vm.activeSpeakerUserId.includes(
                                              user.id
                                            )
                                              ? "active-speaker"
                                              : "",
                                          attrs: {
                                            title: `${user.first_name} ${user.last_name}`,
                                          },
                                        },
                                        [
                                          user.role === "Presenter"
                                            ? _c("span", [
                                                _c(
                                                  "svg",
                                                  {
                                                    attrs: {
                                                      xmlns:
                                                        "http://www.w3.org/2000/svg",
                                                      height: "16",
                                                      width: "12",
                                                      viewBox: "0 0 384 512",
                                                    },
                                                  },
                                                  [
                                                    _c("path", {
                                                      attrs: {
                                                        opacity: "1",
                                                        fill: "#D0D5DD",
                                                        d: "M112 24c0-13.3-10.7-24-24-24S64 10.7 64 24V96H48C21.5 96 0 117.5 0 144V300.1c0 12.7 5.1 24.9 14.1 33.9l3.9 3.9c9 9 14.1 21.2 14.1 33.9V464c0 26.5 21.5 48 48 48H304c26.5 0 48-21.5 48-48V371.9c0-12.7 5.1-24.9 14.1-33.9l3.9-3.9c9-9 14.1-21.2 14.1-33.9V144c0-26.5-21.5-48-48-48H320c0-17.7-14.3-32-32-32s-32 14.3-32 32H224c0-17.7-14.3-32-32-32s-32 14.3-32 32H112V24zm0 136H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16zm0 64H272c8.8 0 16 7.2 16 16s-7.2 16-16 16H112c-8.8 0-16-7.2-16-16s7.2-16 16-16z",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                              ])
                                            : _c("span", [
                                                _vm._v(
                                                  _vm._s(user.first_name[0]) +
                                                    _vm._s(user.last_name[0])
                                                ),
                                              ]),
                                        ]
                                      )
                                    }
                                  ),
                                  _vm._v(" "),
                                  _vm.users.length > 9
                                    ? _c(
                                        "li",
                                        { staticClass: "more-avatars" },
                                        [
                                          _vm._v(
                                            "+" + _vm._s(_vm.users.length - 9)
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                2
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : _c("div", [
                        _c("div", { staticClass: "row" }, [
                          _vm.activeChannels.length === 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center w-100 justify-content-center",
                                },
                                [
                                  _c("span", { staticClass: "text-danger" }, [
                                    _vm._v(
                                      "Please contact RoC to map a channel to the job"
                                    ),
                                  ]),
                                ]
                              )
                            : _c("div", { staticClass: "w-100" }, [
                                _c("div", { staticClass: "col-12" }, [
                                  _c(
                                    "select",
                                    {
                                      staticClass: "form-control",
                                      staticStyle: { color: "#35495e" },
                                      attrs: { id: "activeChannelList" },
                                      on: { change: _vm.channelChanged },
                                    },
                                    _vm._l(
                                      _vm.activeChannels,
                                      function (channel) {
                                        return _c(
                                          "option",
                                          {
                                            key: channel.roip_name,
                                            domProps: {
                                              value: channel.roip_name,
                                              selected:
                                                _vm.storedActiveChannel ===
                                                channel.roip_name,
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                channel.alias
                                                  ? channel.alias
                                                  : "No Service Mapped"
                                              ) +
                                                " - " +
                                                _vm._s(channel.roip_name)
                                            ),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]),
                              ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mt-2" }, [
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c("iws-button", {
                                attrs: {
                                  type: "outline-light",
                                  disabled: _vm._isNullOrEmpty(_vm.logs),
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseLog",
                                  "aria-expanded": "true",
                                  "aria-controls": "collapseLog",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("fetchLogs")
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function () {
                                        return [
                                          _c("i", {
                                            staticClass: "fas fa-list-ul",
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  2344591186
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-8" },
                            [
                              _c("iws-button", {
                                staticStyle: { width: "100%" },
                                attrs: {
                                  type: "primary",
                                  click:
                                    _vm.activeChannels.length === 0
                                      ? null
                                      : _vm.joinCall,
                                  disabled: _vm.activeChannels.length === 0,
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function () {
                                        return [
                                          _vm.loading &&
                                          !_vm.hasUserEndedTheCall
                                            ? _c("span", [
                                                _vm._v(
                                                  "\n                                Connecting ...\n                            "
                                                ),
                                              ])
                                            : _c("span", [
                                                _c("i", {
                                                  staticClass: "fas fa-phone",
                                                }),
                                                _vm._v(
                                                  " Join Call\n                            "
                                                ),
                                              ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  1456815211
                                ),
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-2" },
                            [
                              _c("iws-button", {
                                staticStyle: { float: "right" },
                                attrs: {
                                  type: "outline-light",
                                  disabled: _vm._isNullOrEmpty(_vm.users),
                                  "data-toggle": "collapse",
                                  "data-target": "#collapseUsers",
                                  "aria-expanded": "true",
                                  "aria-controls": "collapseUsers",
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function () {
                                        return [
                                          _c("i", {
                                            staticClass: "fas fa-users",
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  4003751206
                                ),
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                  _vm._v(" "),
                  !_vm._isNullOrEmpty(_vm.logs)
                    ? _c("div", { attrs: { id: "accordionLog" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "collapse",
                            attrs: {
                              id: "collapseLog",
                              "data-parent": "#accordionLog",
                            },
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "margin-bottom": "0px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Logs\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              _vm._l(_vm.logs, function (log) {
                                return _c("div", { staticClass: "log" }, [
                                  log.subCategory === "join"
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "small",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._timeDisplay(log.timestamp)
                                              ) +
                                                ": " +
                                                _vm._s(log.data.userName) +
                                                " joined"
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  log.subCategory === "disconnect"
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "small",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._timeDisplay(log.timestamp)
                                              ) +
                                                ": " +
                                                _vm._s(log.data.userName) +
                                                " left"
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  log.subCategory === "talkEnd"
                                    ? _c("span", [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "font-size": "small",
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm._timeDisplay(log.timestamp)
                                              ) +
                                                ": " +
                                                _vm._s(log.data.userName) +
                                                " spoke (" +
                                                _vm._s(
                                                  Math.round(
                                                    log.data.timeSpoken
                                                  )
                                                ) +
                                                " s)\n                                "
                                            ),
                                            log.data.isROIPOnline === false
                                              ? _c("span", [
                                                  _vm._v(
                                                    " unable to actuate radio"
                                                  ),
                                                ])
                                              : _vm._e(),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ])
                              }),
                              0
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm._isNullOrEmpty(_vm.users)
                    ? _c("div", { attrs: { id: "accordionUsers" } }, [
                        _c(
                          "div",
                          {
                            staticClass: "collapse",
                            attrs: {
                              id: "collapseUsers",
                              "data-parent": "#accordionUsers",
                            },
                          },
                          [
                            _c(
                              "h3",
                              {
                                staticStyle: {
                                  "margin-top": "10px",
                                  "margin-bottom": "0px",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                    Users\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "ol",
                              {
                                staticStyle: {
                                  "margin-bottom": "0px",
                                  "padding-left": "15px",
                                },
                              },
                              _vm._l(_vm.users, function (user, index) {
                                return _c(
                                  "li",
                                  { key: index, staticClass: "log" },
                                  [
                                    _c("span", { staticClass: "row" }, [
                                      _c("span", { staticClass: "col-6" }, [
                                        _vm.activeSpeakerUserId.includes(
                                          user.id
                                        )
                                          ? _c(
                                              "span",
                                              { staticClass: "user-speaking" },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(user.first_name) +
                                                    " " +
                                                    _vm._s(user.last_name) +
                                                    "\n                                "
                                                ),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                "\n                                    " +
                                                  _vm._s(user.first_name) +
                                                  " " +
                                                  _vm._s(user.last_name) +
                                                  "\n                                "
                                              ),
                                            ]),
                                      ]),
                                      _vm._v(" "),
                                      _vm.activeSpeakerUserId.includes(user.id)
                                        ? _c(
                                            "span",
                                            { staticClass: "col-6 text-right" },
                                            [
                                              _c(
                                                "svg",
                                                {
                                                  attrs: {
                                                    xmlns:
                                                      "http://www.w3.org/2000/svg",
                                                    width: "20",
                                                    height: "20",
                                                    viewBox: "0 0 20 20",
                                                    fill: "none",
                                                  },
                                                },
                                                [
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M4.89648 7.18746L8.21232 4.3783C8.75398 3.91913 9.58398 4.30413 9.58398 5.01413V14.985C9.58398 15.695 8.75315 16.08 8.21232 15.6208L4.89648 12.8116",
                                                      stroke: "#32D583",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M4.89648 12.8125H2.91732C2.45732 12.8125 2.08398 12.4392 2.08398 11.9792V8.02083C2.08398 7.56083 2.45732 7.1875 2.91732 7.1875H4.89648",
                                                      stroke: "#32D583",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M15.0007 6.66675V13.3334",
                                                      stroke: "#32D583",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M12.5007 8.33325V11.6666",
                                                      stroke: "#32D583",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                    },
                                                  }),
                                                  _vm._v(" "),
                                                  _c("path", {
                                                    attrs: {
                                                      d: "M17.5007 4.16675V15.8334",
                                                      stroke: "#32D583",
                                                      "stroke-width": "1.5",
                                                      "stroke-linecap": "round",
                                                      "stroke-linejoin":
                                                        "round",
                                                    },
                                                  }),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]),
                                  ]
                                )
                              }),
                              0
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }