var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      title: "Discover Cameras",
      showModal: _vm.showBaseModal,
      "max-width": "700px",
      "primary-button-disabled": _vm._isFalsy(_vm.selectedCamera),
      "primary-button-click": _vm.submitForm,
    },
    on: { close: _vm.cancelAction, "secondary-action": _vm.cancelAction },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _vm.discoveredCameras === null
              ? _c(
                  "div",
                  {
                    staticClass: "full-width",
                    attrs: { id: "loading-container" },
                  },
                  [
                    _c("b-spinner", {
                      staticClass: "align-middle",
                      attrs: { large: "" },
                    }),
                  ],
                  1
                )
              : !_vm.discoveredCameras?.length
              ? [
                  _c("h5", [
                    _vm._v("\n                No New Cameras\n            "),
                  ]),
                  _vm._v(" "),
                  _c("iws-button", {
                    staticClass: "mt-2",
                    attrs: {
                      text: "Re-run Network Scans",
                      type: "outline-primary",
                      "append-icon": "fab fa-searchengin",
                      click: _vm.fetchCameras,
                    },
                  }),
                ]
              : _vm._isFalsy(_vm.selectedCamera)
              ? [
                  _c("iws-button", {
                    staticClass: "mb-2",
                    staticStyle: { float: "right" },
                    attrs: {
                      text: "Re-run Network Scans",
                      type: "outline-primary",
                      "append-icon": "fab fa-searchengin",
                      click: _vm.fetchCameras,
                    },
                  }),
                  _vm._v(" "),
                  _c("iws-table", {
                    attrs: {
                      columns: _vm.columns,
                      items: _vm.discoveredCameras,
                      "sort-by-col": _vm.sortByCol,
                    },
                    on: {
                      "update:sortByCol": function ($event) {
                        _vm.sortByCol = $event
                      },
                      "update:sort-by-col": function ($event) {
                        _vm.sortByCol = $event
                      },
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell_actions",
                        fn: function ({ data }) {
                          return [
                            _c("iws-button", {
                              attrs: {
                                type: "outline-light",
                                icon: "fas fa-plus",
                              },
                              on: {
                                click: function ($event) {
                                  _vm.selectedCamera = data?.item
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                ]
              : _c("iws-form", {
                  ref: "selectedCamForm",
                  attrs: { data: _vm.selectedCamera },
                  scopedSlots: _vm._u([
                    {
                      key: "body",
                      fn: function ({ dataItem }) {
                        return [
                          _c("iws-input", {
                            attrs: {
                              label: "Name",
                              value: dataItem?.name,
                              disabled: "",
                              "form-spacing": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-input", {
                            attrs: {
                              label: "Network Hostname",
                              value: dataItem?.model,
                              disabled: "",
                              "form-spacing": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-input", {
                            attrs: {
                              label: "IP Address",
                              value: dataItem?.hostName,
                              disabled: "",
                              "form-spacing": "",
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-input", {
                            ref: "displayName",
                            attrs: {
                              label: "Display Name",
                              value: dataItem.displayName,
                              required: "",
                              "form-spacing": "",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(dataItem, "displayName", $event)
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("div", { staticClass: "row" }, [
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c("iws-input", {
                                  ref: "onvifUsername",
                                  attrs: {
                                    label: "Onvif Username",
                                    value: dataItem.onvifUsername,
                                    required: "",
                                    "form-spacing": "",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        dataItem,
                                        "onvifUsername",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "col-6" },
                              [
                                _c("iws-input", {
                                  ref: "onvifPassword",
                                  attrs: {
                                    label: "Onvif Password",
                                    value: dataItem.onvifPassword,
                                    type: "password",
                                    required: "",
                                    "form-spacing": "",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(
                                        dataItem,
                                        "onvifPassword",
                                        $event
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      },
                    },
                  ]),
                }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }