var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-grow-1", style: "height:" + _vm.height + "px;" },
    [
      _vm.messagesArray == null
        ? _c(
            "div",
            {
              staticClass: "rounded pl-1 alert-font",
              style:
                "color:" +
                _vm.latestAlert.alarmType +
                ";border:1px solid " +
                _vm.latestAlert.alarmType,
            },
            [
              _vm._v(
                "\n        Alert Status: " +
                  _vm._s(
                    _vm.latestAlert ? _vm.latestAlert.message : "<<No Alerts>>"
                  ) +
                  "\n    "
              ),
            ]
          )
        : _c(
            "div",
            {
              staticClass:
                "rounded pl-1 alert-font d-flex flex-1 h-100 align-items-center justify-content-between",
              style: "border:1px solid " + _vm.getSelectedColor,
            },
            [
              _c("transition", { attrs: { name: "fade", mode: "out-in" } }, [
                _c(
                  "div",
                  {
                    key: `${_vm.selected}-alert`,
                    staticClass: "truncate",
                    style: "color:" + _vm.getSelectedColor,
                  },
                  [
                    _vm._v(
                      "\n                " +
                        _vm._s(_vm.getSelectedText) +
                        "\n            "
                    ),
                  ]
                ),
              ]),
              _vm._v(" "),
              _vm.showDropdown
                ? _c("div", { staticClass: "dropdown custom-dropdown" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "btn btn-link dropdown-toggle btn-sm h-auto pb-1",
                        style: "color:" + _vm.getSelectedColor + "!important; ",
                        attrs: {
                          href: "#",
                          role: "button",
                          id: "dropdownMenuLink",
                          "data-toggle": "dropdown",
                          "aria-haspopup": "true",
                          "aria-expanded": "false",
                        },
                      },
                      [
                        _vm._v(
                          "\n                Show all alerts\n            "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "dropdown-menu custom-dropdown-menu dropdown-menu-right scrollable-menu bg-light",
                        attrs: { "aria-labelledby": "dropdownMenuLink" },
                      },
                      _vm._l(_vm.options, function (option, index) {
                        return _c(
                          "a",
                          {
                            key: index,
                            staticClass:
                              "dropdown-item cursor-point dropdown-focus-dark",
                            style: _vm.alertColor(option),
                            on: { click: () => _vm.onClick(option) },
                          },
                          [_vm._v(_vm._s(option.text))]
                        )
                      }),
                      0
                    ),
                  ])
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }