<template>
  <div class="px-2 py-1">
    <div class="border border-secondary rounded p-2">
      <div v-if="allowEdit" class="btn btn-sm btn-primary float-right m-1" @click="deleteWell">X</div>
      <br>
      <div class="form-group">
        <label>Short name</label>
        <input type="text" :value="wellInfo.name" class="form-control text-secondary" id="name" @change="updateWell" :disabled="isReadOnly()">
      </div>
      <div class="form-group">
        <label>Long name</label>
        <input type="text" :value="wellInfo.nameLong" class="form-control" id="nameLong" @change="updateWell" :disabled="isReadOnly()">
      </div>
      <div class="form-group">
        <label>Number of stages</label>
        <input type="number" :value="wellInfo.numberOfStages" class="form-control text-secondary" id="numberOfStages" @change="updateWell" :disabled="isReadOnly()">
      </div>
      <div class="form-group">
        <label>Cluster spacing</label>
        <input type="number" :value="wellInfo.clusterSpacing" class="form-control text-secondary" id="clusterSpacing" @change="updateWell" min="0" oninput="validity.valid||(value='')">
      </div>
      <div class="form-group">
        <label>Cluster spacing positive variance</label>
        <input type="number" :value="wellInfo.clusterSpacingPositive" class="form-control text-secondary" id="clusterSpacingPositive" @change="updateWell" min="0" oninput="validity.valid||(value='')">
      </div>
      <div class="form-group">
        <label>Cluster spacing negative variance</label>
        <input type="number" :value="wellInfo.clusterSpacingNegative" class="form-control text-secondary" id="clusterSpacingNegative" @change="updateWell" min="-9999" max="0" onfocusout="validity.valid||(value='0')">
      </div>
      <div class="form-group">
        <label>Stage spacing</label>
        <input type="number" :value="wellInfo.stageSpacing" class="form-control text-secondary" id="stageSpacing" @change="updateWell" min="0" oninput="validity.valid||(value='')">
      </div>
      <div class="form-group">
        <label>Stage spacing positive variance</label>
        <input type="number" :value="wellInfo.stageSpacingPositive" class="form-control text-secondary" id="stageSpacingPositive" @change="updateWell" min="0" oninput="validity.valid||(value='')">
      </div>
      <div class="form-group">
        <label>Stage spacing negative variance</label>
        <input type="number" :value="wellInfo.stageSpacingNegative" class="form-control text-secondary" id="stageSpacingNegative" @change="updateWell" min="-9999" max="0" onfocusout="validity.valid||(value='0')">
      </div>
      <div class="form-group">
        <label>Color</label>
        <input type="color" :value="wellInfo.color" id="color" @change="updateWell" class="form-control" :disabled="isReadOnly()" >
      </div>
      <div class="form-group">
          <label>Configuration</label>
          <select id="configuration_id" @change="updateWell" class="form-control" :disabled="isReadOnly()">
              <option :value="null" :selected="wellInfo.configuration_id==null">Not configured</option>
              <option v-for="(wellConfig, index) in wellConfigs" :value="wellConfig.id" :key="index" :selected="wellConfig.id==wellInfo.configuration_id">{{wellConfig.config_number}} - {{wellConfig.description}}</option>
          </select>
      </div>
      <div v-if="wellInfo.configuration_id" class="col-12 text-center p-0 m-0" style="max-height:216px;min-height:216px;">
        <img :src="'/get-wellhead-image?config_number=' + getWellConfig(wellInfo.configuration_id).config_number" style="max-height:inherit;" ref="wellImageRef">
      </div>
      <hr v-if="allowEdit && wellCount > 1" class="bg-secondary">
      <div class="d-flex justify-content-around" v-if="allowEdit">
        <div v-if="index > 0" class="btn btn-primary" @click="changeOrder('moveUp')"> &lt; </div>
        <div v-if="index < wellCount-1" class="btn btn-primary" @click="changeOrder('moveDown')"> &gt; </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions';
export default{
    data() {
        return {
            message: 'The well setup component'
        };
    },
    props: ['wellInfo','index','allowEdit','wellCount','wellConfigs', 'isReadOnly'],
    methods: {
        updateWell(event) {
            const parameter = {};
            parameter.name = event.target.id;
            parameter.value = event.target.value;
            parameter.index = this.index;
            if((parameter.name === 'clusterSpacingNegative' || parameter.name === 'stageSpacingNegative') && parseInt(parameter.value) > 0) {
                parameter.value = '0';
            }
            this.$emit('well',parameter);
        },
        deleteWell() {
            this.$emit('delete',this.index);
        },
        changeOrder(command) {
            this.$emit('order',{index: this.index,command: command});
        },
        getWellConfig(configuration_id) {
            if(this.wellConfigs) {
                return this.wellConfigs.find(config => {
                    return config.id == configuration_id;
                });
            }
        }
    }
};
</script>

<style scoped>
.form-control:disabled {
  background-color: #BABDBF;
}
</style>

