var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "WellDuplicateModal",
      staticClass: "dark-modal modal",
      attrs: { id: "WellDuplicateModal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _vm._m(0),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.resolve(null)
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          !!_vm.sourceWell && !_vm._isNullOrEmpty(_vm.wells)
            ? _c(
                "div",
                { staticClass: "modal-body" },
                [
                  !_vm.showConfirm
                    ? [
                        _c("iws-alert", {
                          attrs: { type: "info" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "body",
                                fn: function () {
                                  return [
                                    _vm._v(
                                      "\n                            Copying from: " +
                                        _vm._s(_vm.sourceWell.name) +
                                        "\n                        "
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            1092175023
                          ),
                        }),
                        _vm._v(" "),
                        _c("iws-select", {
                          attrs: {
                            label: "Wells",
                            value: _vm.targetWells,
                            options: _vm.wells,
                            "display-name": "name",
                            placeholder: _vm.multiselectPlaceholder,
                            "maintain-on-select": true,
                            multiselect: true,
                          },
                          on: { change: _vm.handleWellSelection },
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { id: "update-alerts" } },
                          [
                            !_vm._isNullOrEmpty(_vm.conflictWells)
                              ? _c("iws-alert", {
                                  attrs: { type: "danger" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                                The following selected wells have configurations that will be overwritten in this action:\n                                "
                                            ),
                                            _c(
                                              "ul",
                                              _vm._l(
                                                _vm.conflictWells,
                                                function (well) {
                                                  return _c("li", [
                                                    _vm._v(
                                                      "\n                                        " +
                                                        _vm._s(well.name) +
                                                        "\n                                    "
                                                    ),
                                                  ])
                                                }
                                              ),
                                              0
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    2321236905
                                  ),
                                })
                              : _c("iws-alert", {
                                  attrs: { type: "warning" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                                This action may override any existing toolstring configurations for the selected well(s)\n                            "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1382636628
                                  ),
                                }),
                          ],
                          1
                        ),
                      ]
                    : [
                        _vm._v(
                          "\n                    You are going to make updates to:\n                    "
                        ),
                        _c(
                          "ul",
                          _vm._l(_vm.targetWells, function (well) {
                            return _c(
                              "li",
                              [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(well.name) +
                                    "\n\n                            "
                                ),
                                !_vm._isNullOrEmpty(well.toolstrings)
                                  ? _c("iws-badge", {
                                      attrs: {
                                        type: "danger",
                                        text: "Overwriting Data",
                                      },
                                    })
                                  : _c("iws-badge", {
                                      attrs: {
                                        type: "success",
                                        text: "Empty Well",
                                      },
                                    }),
                              ],
                              1
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { attrs: { id: "update-alerts" } },
                          [
                            _c("iws-alert", {
                              attrs: { type: "danger" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "body",
                                    fn: function () {
                                      return [
                                        _vm._v(
                                          "\n                                This action will override any existing toolstring configurations for the selected well(s)\n                            "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1523190239
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                ],
                2
              )
            : _c("div", { staticClass: "modal-body" }, [
                _vm._v(
                  "\n                No target wells found.\n            "
                ),
              ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              !_vm.showConfirm
                ? _c("iws-button", {
                    attrs: { type: "outline-light", text: "Cancel" },
                    on: {
                      click: function ($event) {
                        return _vm.resolve(null)
                      },
                    },
                  })
                : _c("iws-button", {
                    attrs: {
                      type: "outline-light",
                      text: "Back",
                      click: () => (_vm.showConfirm = false),
                    },
                  }),
              _vm._v(" "),
              _c("iws-button", {
                attrs: {
                  text: !_vm.showConfirm ? "Save and Apply" : "Confirm",
                  click: _vm.onSaveClick,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-title" }, [
      _c("h5", [
        _vm._v(
          "\n                        Duplicate to other Wells\n                    "
        ),
      ]),
      _vm._v(" "),
      _c("div", [
        _vm._v(
          "\n                        Select the wells you wish to save this configuration to.\n                    "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }