var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "kpi-item" },
    [
      _vm._t("kpi-title", function () {
        return [
          _c("span", { staticClass: "kpi-title" }, [
            _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
          ]),
        ]
      }),
      _vm._v(" "),
      _c("tooltip-component", {
        attrs: {
          id: "tooltip-container",
          tooltip_key: _vm.kpiTooltipKeys[_vm.item.options.type],
          customer_id: _vm.$parent.customer.id,
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        { ref: "extraInfo", staticClass: "extra-info-container" },
        [_vm._t("extra-info")],
        2
      ),
      _vm._v(" "),
      _vm.showNotEnoughDataWarning
        ? _c("iws-alert", {
            staticStyle: { "margin-top": "10px" },
            attrs: {
              text: "Not enough data to generate this KPI summary!",
              icon: "fas fa-exclamation-circle",
              type: "danger",
            },
          })
        : !_vm.showChart
        ? _c(
            "div",
            {
              staticClass: "full-width",
              staticStyle: { padding: "10% 0px", "text-align": "center" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "spinner-border light-text-color",
                  attrs: { role: "status" },
                },
                [_c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")])]
              ),
            ]
          )
        : [
            !_vm.editMode && !_vm.exportOverlayMode
              ? _c("i", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip:top",
                      value: "Export",
                      expression: "'Export'",
                      arg: "top",
                    },
                  ],
                  staticClass: "fas fa-file-download clickable",
                  attrs: { id: "export-container" },
                  on: {
                    click: function ($event) {
                      _vm.exportOverlayMode = true
                    },
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            !_vm._isNullOrEmpty(_vm.analyticsData)
              ? _c(
                  "div",
                  {
                    ref: "analyticsInfo",
                    staticClass: "d-flex flex-wrap",
                    attrs: { id: "analytics-container" },
                  },
                  _vm._l(_vm.analyticsData, function (analytic) {
                    return _c(
                      "div",
                      {
                        staticClass: "d-flex align-items-center",
                        style: {
                          "flex-basis": `calc(100% / ${_vm.columnCount})`,
                          "max-width": `calc(100% / ${_vm.columnCount})`,
                        },
                      },
                      [
                        _c("span", {
                          staticClass: "kpi-box",
                          style: {
                            "background-color": analytic.backgroundColor,
                          },
                        }),
                        _vm._v(
                          "\n                " +
                            _vm._s(analytic.label) +
                            "\n            "
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm._v(" "),
            _vm._t("content"),
          ],
      _vm._v(" "),
      _vm._isIterable(_vm.errors)
        ? _c(
            "div",
            { ref: "errorsInfo" },
            _vm._l(_vm.errors, function (error) {
              return _c(
                "div",
                { staticClass: "danger-text-color text-center" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(error?.message || error) +
                      "\n        "
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.editMode
        ? [
            _c("div", { staticClass: "overlay" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "edit-container" },
              [
                _c("iws-select", {
                  attrs: {
                    options: _vm.kpiTypes,
                    value: _vm.item.options.type,
                    label: "KPI Type",
                    "display-name": "label",
                    "value-name": "value",
                    "visible-options": 6,
                  },
                  on: { change: _vm.onTypeChange },
                }),
                _vm._v(" "),
                _vm.item.options.type != "kpi-progress"
                  ? _c("iws-select", {
                      attrs: {
                        options: _vm.analyticTypes,
                        value: _vm.item.options.analyticsType,
                        label: "Analytics",
                        placeholder: !_vm._isNullOrEmpty(
                          _vm.item?.options?.analyticsType
                        )
                          ? _vm.item?.options?.analyticsType.join(", ")
                          : "0 selected",
                        multiselect: true,
                        "maintain-on-select": true,
                        "form-spacing": "",
                        "full-placeholder": "",
                      },
                      on: { change: _vm.onAnalyticsChange },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.showZeroValueAnalyticField
                  ? _c("iws-select", {
                      attrs: {
                        label: "Treatment of Zero Values in Analytics",
                        options: _vm.zeroStageTypes,
                        value: _vm.item.options.zeroStageType,
                        "display-name": "label",
                        "value-name": "value",
                        "form-spacing": "",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.item.options,
                            "zeroStageType",
                            $event
                          )
                        },
                        change: function ($event) {
                          return _vm.$emit("analyticsChanges", $event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.item.options.type !== "kpi-swapTime" &&
                _vm.item.options.type !== "kpi-metricsPerStage"
                  ? _c("iws-select", {
                      attrs: {
                        label: "Time Range",
                        options: _vm.$parent.dayTypes,
                        value: _vm.item.options.dayType,
                        "form-spacing": "",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.item.options, "dayType", $event)
                        },
                        change: function ($event) {
                          return _vm.$emit("settingsChanges", $event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm._t("settings"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-2 float-right" },
                  [
                    _c("iws-button", {
                      attrs: {
                        text: "Revert",
                        type: "danger",
                        click: _vm.revertConfig,
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        : _vm.exportOverlayMode
        ? [
            _c("div", { staticClass: "overlay" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "export-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Export Type",
                    options: _vm.exportTypes,
                    value: _vm.selectedExportType,
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.selectedExportType = $event
                    },
                  },
                }),
                _vm._v(" "),
                _vm._t("export"),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mt-2 float-right" },
                  [
                    _c("iws-button", {
                      attrs: { text: "Cancel", type: "light" },
                      on: {
                        click: function ($event) {
                          _vm.exportOverlayMode = false
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("iws-button", {
                      staticClass: "ml-2",
                      attrs: {
                        text: "Export",
                        type: "primary",
                        click: _vm.exportChart,
                        disabled: !_vm.isChartExportable(),
                      },
                    }),
                  ],
                  1
                ),
              ],
              2
            ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }