<template>
    <div ref="dropdown-div" class="w-100">
        <div
        ref="drop-btn"
        :class="{'disabled': disabled}" data-toggle="dropdown"
        class="dropdown-btn rounded-lg d-flex justify-content-between pl-3 p-1 align-items-center user-select-none">
            <p class="m-0" :style="textStyle">{{ displayText }}</p>
            <span class="h-100 d-flex align-items-center text-sm mr-2">
                <i class="fas fa-chevron-down"></i>
            </span>
        </div>
        <div class="dropdown-menu w-100 p-0" style="background-color: rgba(0,0,0,0)">
            <checkbox-list
                :label="label"
                :height="height"
                :isDark="isDark"
                :options="options"
                :valueKey="valueKey"
                :maxHeight="maxHeight"
                :outputKey="outputKey"
                :enableSearch="enableSearch"
                :enableSelectAllOption="enableSelectAllOption"
                v-bind:selectedOptions="selectedOptions"
                @update:selectedOptions="$emit('updateSelectedOptions', $event)"
                style="border:none !important">
            </checkbox-list>
        </div>
    </div>
</template>

<style scoped>
.dropdown-btn {
    width: 100%;
    height: 38px;
    border: none;
    cursor: pointer;
    color: #35495E;
    background-color: white;
}
.dropdown-btn:focus {
    outline: none;
    border: 1px solid #29a5ff;
    box-shadow: 0 0 0 0.2rem rgba(41, 165, 255, 0.25);
}
.dropdown-btn:focus{
    border: 1px solid #29a5ff;
}

.dropdown-btn p {
    width: 95%;
    overflow: hidden;
    white-space: nowrap;
    display: inline-block;
    text-overflow: ellipsis;
}

.disabled {
    pointer-events: none;
    background-color: #D3D3D3;
}
</style>

<script>
import CheckboxList from './CheckboxList.vue';

export default {
    props: {
        ...CheckboxList.props,
        maxHeight: {
            type: Number,
            default: 150,
            required: false
        },
        disabled: {
            type: Boolean,
            default: false,
            required: false
        },
        placeholder: {
            type: String,
            required: false,
            default: 'Select Item'
        },
        textSize: {
            type: Number,
            required: false,
            default: 1
        },
        placeholderTextSize: {
            type: Number,
            required: false,
            default: 1
        },
        isDark: {
            type: Boolean,
            required: false,
            default: true
        }
    },
    mounted() {
        const self = this;
        $(window).on('show.bs.dropdown', function(e) {
            const btnDiv = self.$refs.hasOwnProperty('drop-btn') ? self.$refs['drop-btn'] : null;
            if (btnDiv) {
                const rect = btnDiv.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const height = self.maxHeight ? self.maxHeight : self.height;
                const isAtBottom = (rect.bottom + height) >= viewportHeight;
                const dropdownDiv =  self.$refs.hasOwnProperty('dropdown-div') ? self.$refs['dropdown-div'] : null;
                if (dropdownDiv) {
                    if (isAtBottom) {
                        dropdownDiv.classList.remove('dropdown');
                        dropdownDiv.classList.add('dropup');
                    } else {
                        dropdownDiv.classList.remove('dropup');
                        dropdownDiv.classList.add('dropdown');
                    }
                }
            }
        });

        $(window).on('hide.bs.dropdown', function(e) {
            if (self.$refs['dropdown-div']?.contains(e?.clickEvent?.target)) {
                e.preventDefault();
            }
        });
    },
    computed: {
        isEmpty() {
            return this.selectedOptions.length === 0;
        },
        displayText() {
            if (this.isEmpty) {
                return this.placeholder;
            } else {
                const text = [];
                this.selectedOptions?.forEach(option => {
                    if (typeof option === 'object') {
                        text.push(option[this.valueKey]);
                    } else {
                        const opt = this.options.find(op => op[this.outputKey] === option);
                        if (opt) {
                            text.push(opt[this.valueKey]);
                        }
                    }
                });
                return text.join(', ');
            }
        },
        textStyle() {
            if (this.isEmpty) {
                return {
                    color: '#adadad',
                    'font-size': `${this.placeholderTextSize}rem`
                };
            } else {
                return {
                    color: '#35495E',
                    'font-size': `${this.textSize}rem`
                };
            }
        }
    }
};
</script>
