var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "SearchInput" } },
    [
      _c("iws-input", {
        ref: "inputField",
        class: {
          "leading-icon": !!_vm.prependIcon,
          active: !_vm._isFalsy(_vm.value),
        },
        attrs: {
          label: _vm.label,
          placeholder: _vm.placeholder,
          hint: _vm.hint,
          value: _vm.value,
          "prepend-icon": !!_vm.prependIcon ? `fas ${_vm.prependIcon}` : null,
          "append-icon":
            _vm.clearable && !_vm._isFalsy(_vm.value) ? "fas fa-times" : null,
          disabled: _vm.disabled,
          required: _vm.required,
        },
        on: {
          input: _vm.handleInput,
          focus: function ($event) {
            return _vm.$emit("focus", $event)
          },
          blur: function ($event) {
            return _vm.$emit("blur", $event)
          },
          keyup: function ($event) {
            return _vm.$emit("keyup", $event)
          },
          prepependClick: function ($event) {
            return _vm.handleInput({ target: _vm.value })
          },
          appendClick: _vm.clearInput,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }