import { render, staticRenderFns } from "./TagSummaryTableConfigModal.vue?vue&type=template&id=25a594d4&"
import script from "./TagSummaryTableConfigModal.vue?vue&type=script&lang=js&"
export * from "./TagSummaryTableConfigModal.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10162/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('25a594d4')) {
      api.createRecord('25a594d4', component.options)
    } else {
      api.reload('25a594d4', component.options)
    }
    module.hot.accept("./TagSummaryTableConfigModal.vue?vue&type=template&id=25a594d4&", function () {
      api.rerender('25a594d4', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/TagSummaryTableConfigModal.vue"
export default component.exports