<template>
    <div>
        <div id="header-bar">
            <iws-button type="light" @click="addEditTag(activity == 'npt' ? null : {}, null)">
                <template #text>
                    <i class="fas fa-plus-circle"></i> Add Tag
                </template>
            </iws-button>

            <div id="tag-count">
                {{ data.tags.length }} Tag{{ data.tags.length > 1 ? 's' : null }}
            </div>
        </div>

        <li v-if="_isNullOrEmpty(data.tags)">
            No {{ activity }} tags. Hit add tag to start addings new tags
        </li>
        <template v-else>
            <draggable v-if="activity == 'npt'" v-model="data.tags" handle=".fas" @change="dropEvent">
                <div v-for="(tag, index) in data.tags" class="draggable-item" :key="`${tag}_${index}`">
                    <i v-if="_isNullOrEmpty(Object.keys(backups))" class="fas fa-bars" style="margin-right: 5px"></i>
                    
                    <span style="display: inline-block; width: calc(100% - 120px)">
                        <span :class="{ 'limit-width': hasBackupSet(index, 'npt') }">
                            <iws-input :ref="buildBackupKey(index, 'npt')"
                                :value.sync="tag"
                                placeholder="Provide a tag name"
                                @pre-input="addBackup(index, 'npt')"
                                @input="backupChanges($event, index, 'npt')"
                                required
                            />
                        </span>
                        
                        <span v-if="hasBackupSet(index, 'npt')" class="action-buttons-container">
                            <iws-button type="outline-primary" @click="confirmChanges(index, 'npt')" :disabled="tag === null || tag ===''">
                                <template #text> <i class="fas fa-check"></i> </template>
                            </iws-button>

                            <iws-button type="outline-danger" @click="discardChanges(index, 'npt')">
                                <template #text> <i class="fas fa-times"></i> </template>
                            </iws-button>
                        </span>
                    </span>

                    <library-crud-actions
                        :id="`npt_actions_${index}`"
                        class="pt-2 text-center"
                        :view-actions="{
                            edit: false,
                            view: false,
                            delete: true
                        }"

                        @delete="deleteAction(index)"
                    />
                </div>
            </draggable>
            <template v-else-if="activity == 'frac'">
                <iws-table :columns="['', 'displayName', 'tagName', 'abbreviation', 'stageStart', 'stageEnd', '' ]" :isRowDraggable="true">
                    <template #header_displayName>
                        Display Name
                    </template>
                    <template #header_tagName>
                        Tag Name
                    </template>
                    <template #header_abbreviation>
                        Abbreviation
                    </template>
                    <template #header_stageStart>
                        Include in stage start
                    </template>
                    <template #header_stageEnd>
                        Include in stage end
                    </template>
            
                    <template #body>
                        <draggable v-model="data.tags" tag="tbody">
                            <tr v-for="(tag, index) in data.tags" :key="index">
                                <td>
                                    <i class="fas fa-bars" style="margin-right: 5px"></i>
                                </td>
                                <td>
                                    <span :class="{ 'limit-width': hasBackupSet(index, 'displayName') }">
                                        <p id="display-name">{{ tag.displayName }}</p>
                                    </span>
                                </td>
                                <td>
                                    <span :class="{ 'limit-width': hasBackupSet(index, 'tagName') }">
                                        <iws-input
                                            :value.sync="tag.tagName"
                                            placeholder="Provide a tag name"
                                            @pre-input="addBackup(index, 'tagName')"
                                            required
                                        />
                                    </span>
                                    
                                    <span v-if="hasBackupSet(index, 'tagName')" class="action-buttons-container">
                                        <iws-button type="outline-primary" @click="confirmChanges(index, 'tagName')" :disabled="tag.tagName === null || tag.tagName ===''">
                                            <template #text> <i class="fas fa-check"></i> </template>
                                        </iws-button>
    
                                        <iws-button type="outline-danger" @click="discardChanges(index, 'tagName')">
                                            <template #text> <i class="fas fa-times"></i> </template>
                                        </iws-button>
                                    </span>
                                </td>
                                <td>
                                    <span :class="{ 'limit-width': hasBackupSet(index, 'abbreviation') }">
                                        <p id="display-name">{{ tag?.abbreviation ? tag?.abbreviation: 'Not Set' }}</p>
                                    </span>
                                </td>
                                <td>
                                    <iws-checkbox :value.sync="tag.startReport" />
                                </td>
                                <td>
                                    <iws-checkbox :value.sync="tag.endReport" />
                                </td>
                                <td class="p-1 text-center">
                                    <library-crud-actions
                                        :id="`npt_actions_${index}`"
                                        :view-actions="{
                                            edit: false,
                                            view: false,
                                            delete: true
                                        }"
    
                                        @delete="deleteAction(index)"
                                    />
                                </td>
                            </tr>
                        </draggable>
                    </template>
                </iws-table>

                <div class="card-body text-dark">
                    <iws-button type="primary" @click="addOutput">
                        <template #text>
                            <i class="fas fa-plus-circle"></i> Add Outputs
                        </template>
                    </iws-button>

                    <div v-for="(output, index) of data.outputs" :key="activity+'_'+index" class="p-2 mb-2">
                        <hr v-if="index">

                        <div class="form-group pt-3">
                            <iws-input :value.sync="output.url" label="Type (teams, custom, ...)" @change="updateOutput($event, index, 'type')" />
                        </div>
                        <div class="form-group mb-4">
                            <iws-input :value.sync="output.type" label="Url" @change="updateOutput($event, index, 'url')" />
                        </div>

                        <iws-button type="danger" @click="deleteOutput(index)">
                            <template #text>
                                <i class="fas fa-trash-alt"></i> Delete
                            </template>
                        </iws-button>
                    </div>
                </div>
            </template>
            <template v-else-if="activity == 'wireline'">
                <iws-table :columns="['', 'displayName', 'tagName', 'abbreviation', 'stageStart', 'stageEnd', '' ]" :isRowDraggable="true">
                    <template #header_displayName>
                        Display Name
                    </template>
                    <template #header_tagName>
                        Tag Name
                    </template>
                    <template #header_abbreviation>
                        Abbreviation
                    </template>
                    <template #header_stageStart>
                        Include in stage start
                    </template>
                    <template #header_stageEnd>
                        Include in stage end
                    </template>
            
                    <template #body>
                        <draggable v-model="data.tags" tag="tbody">
                            <tr v-for="(tag, index) in data.tags" :key="index">
                                <td>
                                    <i class="fas fa-bars" style="margin-right: 5px"></i>
                                </td>
                                <td>
                                    <span :class="{ 'limit-width': hasBackupSet(index, 'displayName') }">
                                        <p id="display-name">{{ tag.displayName }}</p>
                                    </span>
                                </td>
                                <td>
                                    <span :class="{ 'limit-width': hasBackupSet(index, 'tagName') }">
                                        <iws-input
                                            :value.sync="tag.tagName"
                                            placeholder="Provide a tag name"
                                            @pre-input="addBackup(index, 'tagName')"
                                            required
                                        />
                                    </span>
                                    
                                    <span v-if="hasBackupSet(index, 'tagName')" class="action-buttons-container">
                                        <iws-button type="outline-primary" @click="confirmChanges(index, 'tagName')" :disabled="tag.tagName === null || tag.tagName ===''">
                                            <template #text> <i class="fas fa-check"></i> </template>
                                        </iws-button>

                                        <iws-button type="outline-danger" @click="discardChanges(index, 'tagName')">
                                            <template #text> <i class="fas fa-times"></i> </template>
                                        </iws-button>
                                    </span>
                                </td>
                                <td>
                                    <span :class="{ 'limit-width': hasBackupSet(index, 'abbreviation') }">
                                        <p id="display-name">{{ tag?.abbreviation ? tag?.abbreviation: 'Not Set' }}</p>
                                    </span>
                                </td>
                                <td>
                                    <iws-checkbox  :value.sync="tag.startReport" />
                                </td>
                                <td>
                                    <iws-checkbox :value.sync="tag.endReport" />
                                </td>
                                <td class="p-1 text-center">
                                    <library-crud-actions
                                        :id="`npt_actions_${index}`"
                                        :view-actions="{
                                            edit: false,
                                            view: false,
                                            delete: true
                                        }"

                                        @delete="deleteAction(index)"
                                    />
                                </td>
                            </tr>
                        </draggable>
                    </template>
                </iws-table>

                <div class="card-body text-dark">
                    <iws-button type="primary" @click="addOutput">
                        <template #text>
                            <i class="fas fa-plus-circle"></i> Add Outputs
                        </template>
                    </iws-button>

                    <div v-for="(output, index) of data.outputs" :key="activity+'_'+index" class="p-2 mb-2">
                        <hr v-if="index">

                        <div class="form-group pt-3">
                            <iws-input :value.sync="output.url" label="Type (teams, custom, ...)" @change="updateOutput($event, index, 'type')" />
                        </div>
                        <div class="form-group mb-4">
                            <iws-input :value.sync="output.type" label="Url" @change="updateOutput($event, index, 'url')" />
                        </div>

                        <iws-button type="danger" @click="deleteOutput(index)">
                            <template #text>
                                <i class="fas fa-trash-alt"></i> Delete
                            </template>
                        </iws-button>
                    </div>
                </div>
            </template>
        </template>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty, isFalsy, toast } = GlobalFunctions;

import LibraryCrudActions from '../LibraryCrudActions.vue';

export default {
    props: {
        activity: {
            type: String,
            required: true
        },

        data: {
            type: Object,
            required: true
        },

        refs: {
            type: Object,
            required: true
        }
    },

    components: { LibraryCrudActions },

    data: () => ({
        backups: {},
        changes: {}
    }),

    methods: {
        _isNullOrEmpty(value) {
            return isNullOrEmpty(value);
        },

        addEditTag(tag, index) {
            if (this.data.tags === null)
                return;
                
            return this.refs.MetadataAddTagModal.open(tag).then(updatedTag => {
                // Cancelled modal
                if (isFalsy(updatedTag))
                    return;

                if (isFalsy(index)) {
                    this.data.tags.push(updatedTag);
                } else {
                    this.data.tags.splice(index, 1, updatedTag);
                }
            })
        },

        removeTag(index) {
            if (isNullOrEmpty(this.data.tags) || !index in this.data.tags)
                return toast({
                    title: `Index out of bounds ${index} > ${this.data.tags.length}`,
                    variant: 'danger'
                });

            this.data.tags.splice(index, 1);
        },
        deleteAction(index) {
            if (isNullOrEmpty(this.data.tags) || isFalsy(index) || index < 0)
                return toast({
                    title: 'Invalid dataset',
                    variant: 'danger'
                });

            return GlobalFunctions.iwsConfirm({
                title: 'Delete tag',
                body: `Are you sure you want to delete this tag?<br>This action cannot be undone!`,
                confirmColour: 'danger',
                width: '320px'
            }).then(_answer => {
                if (_answer === true)
                    this.removeTag(index);
            });
        },

        dropEvent() {
            this.data.tags = this.data.tags.filter(Boolean);
        },

        buildBackupKey: (index, property) => `${index}_${property}`,
        hasBackupSet(index, property) {
            return this.buildBackupKey(index, property) in this.backups;
        },
        addBackup(index, property) {
            if (!this.hasBackupSet(index, property)) {
                if (this.activity == 'npt') {
                    this.$set(this.backups, this.buildBackupKey(index, property), this.data.tags[index]);
                } else {
                    this.$set(this.backups, this.buildBackupKey(index, property), this.data.tags[index][property]);
                }

                this.$parent.$parent.$forceUpdate();
            }
        },
        confirmChanges(index, property) {
            // Changes are already stored in the data object, confirm just clears backup and enables writing to db
            if (this.activity == 'npt')
                this.data.tags[index] = this.changes[this.buildBackupKey(index, property)];

            this.$delete(this.backups, this.buildBackupKey(index, property));
            this.$parent.$parent.$forceUpdate();
        },
        discardChanges(index, property) {
            // Revert to backup and clear backup from cache
            const key = this.buildBackupKey(index, property);

            if (this.activity == 'npt') {
                this.data.tags[index] = this.backups[key];
                this.$refs[key][0].$forceUpdate();
            } else {
                this.data.tags[index][property] = this.backups[key];
            }

            this.$delete(this.backups, key);
            this.$parent.$parent.$forceUpdate();
        },

        fullDiscard() {
            this.backups = {};
            this.changes = {};
        },

        backupChanges($event, index, property) {
            this.$set(this.changes, this.buildBackupKey(index, property), $event.target.value);
        },


        addOutput() {
            this.data.outputs.push({ type: '', url: '' });
        },
        deleteOutput(index) {
            return GlobalFunctions.iwsConfirm({
                title: 'Delete Output',
                body: `Are you sure you want to delete this?<br>This action cannot be undone!`,
                confirmColour: 'danger',
                width: '320px'
            }).then(_answer => {
                if (_answer === true)
                    this.data.outputs.splice(index, 1);
            });
        }
    }
};
</script>

<style scoped>
    #header-bar {
        margin-bottom: 20px;
    }
    #tag-count {
        font-size: 18px;
        font-weight: 600;
        color: #C9C9C9;
        
        float: right;
        padding-top: 6px;
    }

    .draggable-item {
        padding: 7.5px 0px;
    }
    .draggable-item .fas {
        cursor: grab;
    }
    .draggable-item span {
        font-size: 14px;
    }
    .draggable-item + .draggable-item {
        border-top: #C9C9C9 1px solid;
    }
    .draggable-item:hover {
        background: #2E353D !important;
    }
    
    .card-body {
        background: #242A30;
        border: 1px solid #7B8A98;
        border-radius: 5px;
    }
    hr {
        border-color: #7B8A98;
    }

    span.limit-width {
        display: inline-block;
        width: calc(100% - 38px - 38px - 8px);
    }
    span.action-buttons-container {
        position: relative;
        top: -3px;
    }
    span.action-buttons-container button {
        width: 38px;
        padding: .375rem 0px;;
    }
    .fa-bars {
        cursor: grab;
    }
    #display-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 0px;
    }
</style>