var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { ref: "keyboardRef", staticClass: "bg-light" }, [
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("key-component", {
          attrs: {
            primary: "`",
            secondary: "~",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "1",
            secondary: "!",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "2",
            secondary: "@",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "3",
            secondary: "#",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "4",
            secondary: "$",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "5",
            secondary: "%",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "6",
            secondary: "^",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "7",
            secondary: "&",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "8",
            secondary: "*",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "9",
            secondary: "(",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "0",
            secondary: ")",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "-",
            secondary: "_",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "=",
            secondary: "+",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("key-component", {
          attrs: {
            primary: "q",
            secondary: "Q",
            shift: _vm.shift,
            width: _vm.keyWidth,
            ml: "0.33",
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "w",
            secondary: "W",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "e",
            secondary: "E",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "r",
            secondary: "R",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "t",
            secondary: "T",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "y",
            secondary: "Y",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "u",
            secondary: "U",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "i",
            secondary: "I",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "o",
            secondary: "O",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "p",
            secondary: "P",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "[",
            secondary: "{",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "]",
            secondary: "}",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "\\",
            secondary: "|",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("key-component", {
          attrs: {
            primary: "a",
            secondary: "A",
            shift: _vm.shift,
            width: _vm.keyWidth,
            ml: "0.66",
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "s",
            secondary: "S",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "d",
            secondary: "D",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "f",
            secondary: "F",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "g",
            secondary: "G",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "h",
            secondary: "H",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "j",
            secondary: "J",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "k",
            secondary: "K",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "l",
            secondary: "L",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: ";",
            secondary: ":",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "'",
            secondary: '"',
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: { primary: "Enter", width: _vm.keyWidth, ml: "0.5" },
          on: { key: _vm.keyPress },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("key-component", {
          attrs: {
            primary: "Shift",
            secondary: "Shift",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "z",
            secondary: "Z",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "x",
            secondary: "X",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "c",
            secondary: "C",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "v",
            secondary: "V",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "b",
            secondary: "B",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "n",
            secondary: "N",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "m",
            secondary: "M",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: ",",
            secondary: "<",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: ".",
            secondary: ">",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "/",
            secondary: "?",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: {
            primary: "Shift",
            secondary: "Shift",
            shift: _vm.shift,
            width: _vm.keyWidth,
          },
          on: { key: _vm.keyPress },
        }),
        _vm._v(" "),
        _c("key-component", {
          attrs: { primary: "Back", width: _vm.keyWidth },
          on: { key: _vm.keyPress },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      [
        _c("key-component", {
          attrs: { primary: "Space", width: _vm.keyWidth * 10, ml: "0.12" },
          on: { key: _vm.keyPress },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }