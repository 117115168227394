<!--
    * Component Description
        IWS custom stylization of the bootstrap component "badge"
        https://getbootstrap.com/docs/4.0/components/badge/
        See props for an explanation of all supported functions

    * Slots
        #text   <- Target badge body

    * Example Usage
        <iws-badge type="info" text="Info" />

        <iws-badge type="info">
            <template #text>
                Info
            </template>
        </iws-badge>
-->
<template>
    <span class="badge" :class="`badge-${type}`">
        <slot name="text">
            {{ text }}
        </slot> 
    </span>
</template>

<script>
export default {
    props: {
        // Badge type / styling
        // Examples: primary, secondary, success, danger, warning, info, light, dark
        type: {
            type: String,
            default: 'primary'
        },

        // Content / text to appear in the badeg
        // Can be subbed for slot for more complicated uses
        text: {
            type: String
        }
    }
}
</script>

<style scoped>
    .badge {
        border-radius: 10px;
        padding: 5px;
    }

    .badge.badge-info {
        border-color: var(--primary-color);
        color: var(--primary-color);
        background-color: #F5FAFF;
    }
    .badge.badge-warning {
        border-color: #7D5E00;
        color: #7D5E00;
        background-color: #FFF3CD;
    }
    .badge.badge-danger {
        border-color: #EA868F;
        color: #EA868F;
        background-color: #842029;
    }
</style>