var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-kpi-template", {
    ref: "baseKpiTemplate",
    attrs: {
      title: `${_vm.swapTypeLabel?.upper} Times`,
      item: _vm.item,
      "inital-options": _vm.initalOptions,
      "edit-mode": _vm.editMode,
      "create-export-data": _vm.createExportData,
      "analytics-data": _vm.analyticsData,
      errors: _vm.errors,
      "show-not-enough-data-warning": _vm.showNotEnoughDataWarning,
      "show-chart": _vm.showChart,
    },
    on: {
      analyticsChanges: function ($event) {
        return _vm.buildAnalytics()
      },
      settingsChanges: function ($event) {
        return _vm.buildData()
      },
      revertChanges: function ($event) {
        return _vm.buildData()
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "settings",
          fn: function () {
            return [
              _c("iws-select", {
                attrs: {
                  options: _vm.swapTypes,
                  value: _vm.item.options.selectedSwapType,
                  label: "Swap Type",
                  "display-name": "label",
                  "value-name": "value",
                  "form-spacing": "",
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.item.options,
                      "selectedSwapType",
                      $event
                    )
                  },
                  change: function ($event) {
                    return _vm.buildData()
                  },
                },
              }),
              _vm._v(" "),
              _vm.requireWirelineCheck
                ? _c("iws-select", {
                    attrs: {
                      options: _vm.dashboardData.wirelineSystems,
                      value: _vm.item.options.wirelineSwapFilter,
                      label: "Wireline",
                      "display-name": "name",
                      "value-name": "number",
                      "form-spacing": "",
                    },
                    on: {
                      "update:value": function ($event) {
                        return _vm.$set(
                          _vm.item.options,
                          "wirelineSwapFilter",
                          $event
                        )
                      },
                      change: function ($event) {
                        return _vm.buildData()
                      },
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "swap-range-container" }, [
                _c(
                  "span",
                  { staticClass: "swap-range-main" },
                  [
                    !_vm.customMinSwap
                      ? _c("iws-select", {
                          attrs: {
                            label: "Swap Time Minimum",
                            value: _vm.item.options.minSwapTimeFilter,
                            options: _vm.minSwapFilterOptions,
                            "display-name": "label",
                            "value-name": "value",
                            "form-spacing": "",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.item.options,
                                "minSwapTimeFilter",
                                $event
                              )
                            },
                            change: function ($event) {
                              return _vm.buildData()
                            },
                          },
                        })
                      : _c("iws-input", {
                          attrs: {
                            label: "Swap Time Minimum",
                            value: _vm.secondsToMinutes(
                              _vm.item.options.minSwapTimeFilter
                            ),
                            min: "0",
                            placeholder: "In Minutes",
                            type: "number",
                            "form-spacing": "",
                          },
                          on: {
                            input: function ($event) {
                              _vm.item.options.minSwapTimeFilter =
                                _vm.minutesToSeconds($event?.target?.value)
                            },
                            blur: function ($event) {
                              return _vm.buildData()
                            },
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "swap-range-actions" },
                  [
                    _vm.item.options.minSwapTimeFilter !== null
                      ? _c("iws-button", {
                          staticClass: "clear-filter full-width",
                          attrs: {
                            text: "X",
                            type: "dark",
                            disabled:
                              _vm.item.options.minSwapTimeFilter === null,
                          },
                          on: {
                            click: function ($event) {
                              _vm.item.options.minSwapTimeFilter = null
                              _vm.buildData()
                            },
                          },
                        })
                      : !_vm.customMinSwap
                      ? _c("iws-button", {
                          staticClass: "switch-filter-type full-width",
                          attrs: { text: "Custom", type: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.customMinSwap = true
                            },
                          },
                        })
                      : _c("iws-button", {
                          staticClass: "switch-filter-type full-width",
                          attrs: { text: "Preset", type: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.customMinSwap = false
                            },
                          },
                        }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "swap-range-container" }, [
                _c(
                  "span",
                  { staticClass: "swap-range-main" },
                  [
                    !_vm.customMaxSwap
                      ? _c("iws-select", {
                          attrs: {
                            label: "Swap Time Maximum",
                            value: _vm.item.options.maxSwapTimeFilter,
                            options: _vm.maxSwapFilterOptions,
                            "display-name": "label",
                            "value-name": "value",
                            "form-spacing": "",
                            "visible-options": 6,
                            flipDirection: true,
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.item.options,
                                "maxSwapTimeFilter",
                                $event
                              )
                            },
                            change: function ($event) {
                              return _vm.buildData()
                            },
                          },
                        })
                      : _c("iws-input", {
                          attrs: {
                            label: "Swap Time Maximum",
                            value: _vm.secondsToMinutes(
                              _vm.item.options.maxSwapTimeFilter
                            ),
                            min: "0",
                            placeholder: "In Minutes",
                            type: "number",
                            "form-spacing": "",
                          },
                          on: {
                            input: function ($event) {
                              _vm.item.options.maxSwapTimeFilter =
                                _vm.minutesToSeconds($event?.target?.value)
                            },
                            blur: function ($event) {
                              return _vm.buildData()
                            },
                          },
                        }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "span",
                  { staticClass: "swap-range-actions" },
                  [
                    _vm.item.options.maxSwapTimeFilter !== null
                      ? _c("iws-button", {
                          staticClass: "clear-filter full-width",
                          attrs: {
                            text: "Clear",
                            type: "dark",
                            disabled:
                              _vm.item.options.maxSwapTimeFilter === null,
                          },
                          on: {
                            click: function ($event) {
                              _vm.item.options.maxSwapTimeFilter = null
                              _vm.buildData()
                            },
                          },
                        })
                      : !_vm.customMaxSwap
                      ? _c("iws-button", {
                          staticClass: "switch-filter-type full-width",
                          attrs: { text: "Custom", type: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.customMaxSwap = true
                            },
                          },
                        })
                      : _c("iws-button", {
                          staticClass: "switch-filter-type full-width",
                          attrs: { text: "Preset", type: "dark" },
                          on: {
                            click: function ($event) {
                              _vm.customMaxSwap = false
                            },
                          },
                        }),
                  ],
                  1
                ),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "extra-info",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "extra-info" },
                [
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.selectedSwapType?.label) +
                      "\n\n            "
                  ),
                  _vm.requireWirelineCheck &&
                  !_vm._isFalsy(_vm.selectedWireline)
                    ? [
                        _vm._v(
                          "\n                (" +
                            _vm._s(_vm.selectedWireline?.name) +
                            ")\n            "
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.filterInfoDetails != null ||
                  !_vm._isNullOrEmpty(_vm.filteredItems)
                    ? _c(
                        "span",
                        { staticStyle: { float: "right" } },
                        [
                          _c(
                            "div",
                            { attrs: { id: `popover-target-${_vm._uuid}` } },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(_vm.filterInfoDetails) +
                                  "\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          !_vm._isNullOrEmpty(_vm.filteredItems)
                            ? _c(
                                "b-popover",
                                {
                                  attrs: {
                                    target: `popover-target-${_vm._uuid}`,
                                    triggers: "hover",
                                    placement: "top",
                                    "boundary-padding": 0,
                                    "custom-class": "filtered-popover",
                                    variant: "dark",
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "title",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\n                        Filtered " +
                                                _vm._s(
                                                  _vm.swapTypeLabel.upper
                                                ) +
                                                " Times\n                    "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    false,
                                    1884567504
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.filteredItems,
                                    function (filteredItem, index) {
                                      return _c(
                                        "div",
                                        { class: { "pt-2": index > 0 } },
                                        [
                                          _c("div", [
                                            _c("b", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.swapTypeLabel.upper
                                                ) +
                                                  " Number: " +
                                                  _vm._s(filteredItem.index)
                                              ),
                                            ]),
                                          ]),
                                          _vm._v(
                                            "\n                        " +
                                              _vm._s(
                                                _vm.readableSeconds(
                                                  filteredItem.swapTime
                                                )
                                              ) +
                                              "\n                        "
                                          ),
                                          _c("div", {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                _vm.formatStartEndTime(
                                                  filteredItem.meta.from,
                                                  filteredItem.meta.to
                                                )
                                              ),
                                            },
                                          }),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ]
          },
          proxy: true,
        },
        _vm.showChart
          ? {
              key: "content",
              fn: function () {
                return [
                  _c("scatter-line-chart", {
                    ref: "kpi",
                    attrs: { "chart-data": _vm.kpi, options: _vm.options },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }