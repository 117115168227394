<template>
    <div >
       
        <button  class="btn btn-sm bg-light"  v-on:click="refresh()"><img class="img-circle" v-bind:src="'../images/refresh.png'"></img></button>
        <button  class="btn btn-sm bg-light"  v-on:click="refresh()"><img class="img-circle" v-bind:src="'../images/settings.png'"></img></button>
	   <line-chart  :height="220" :chart-data="datacollection" :options="options"></line-chart>
    </div>
  
</template>	


<script>
    import LineChart from '../chart.js'
    import moment from 'moment'

    //the number of points that are seen on the chart at any given time
    const MAX_CHART_POINTS = 4000;///7.04.47

    //the number of data points to keep in memory (history buffer)
    const MAX_DATA_POINTS = 10000;

    //contains configuration data for line chart (pressureWell lines are added after these)
    const staticChartItems = [

        {index:0, id:'pressureZipper', name:'Zipper', color:'#FAAC58', yAxisID: 'frac-1-axis'},
        {index:1, id:'frac_PresBlendSlurry', name:'BlenderDischarge', color:'#DF0101', yAxisID: 'frac-1-axis'},
        {index:2, id:'frac_PresDHCalc', name:'CalcBtmHole', color:'#B34D4D', yAxisID: 'frac-1-axis'},
        {index:3, id:'frac_PresWellAnn', name:'BacksidePressure', color:'#80B300', yAxisID: 'frac-1-axis'},
        {index:4, id:'frac_PropDHConcDefault', name:'DHConc', color:'#809900', yAxisID: 'frac-2-axis'},
        {index:5, id:'frac_PropWellConcInline', name:'Inline', color:'#00FFFF', yAxisID: 'frac-2-axis'},
        {index:6, id:'frac_PropWellConcAugers', name:'Augers', color:'#045FB4', yAxisID: 'frac-2-axis'},
        {index:7, id:'frac_RateBlendCleanDefault', name:'BlenderCleanRate', color:'#0000FF', yAxisID: 'frac-3-axis'},
        {index:8, id:'frac_RateBlendSlurryMeter', name:'BlenderDischargeRate', color:'#B34D4D', yAxisID: 'frac-3-axis'},
        {index:9, id:'frac_VolStageBlendSlurry', name:'StageSluryVolume', color:'#DF0101', yAxisID: 'frac-4-axis'},
        {index:10, id:'frac_VolJobBlendSlurryDefault', name:'JobSlurryVolume', color:'#FAAC58', yAxisID: 'frac-4-axis'}
    ]
        
    export default{
        components: {
            LineChart
        },
        data(){
            return{
            	"Zipper":0,
                "BlenderDischarge":0,
                "PresBlendSlurry":0,
                "Calcbtmhole":0,
                "PresWellAnn":0,
                "VolJobWellCleanDefault":0,
                "PropWellConcInline":0,
                "VolJobWellPropMeter":0,
                "PropDHConcDefault":0,
                "VolJobWellPropMeter":0,
                "Time":0,
                "VolJobBlendSlurryCalc":0,
                "padName":'-',
                "jobStart":'-',
                "totalStages":'0',
                "completedStages":"-/-",
                "customer":'-',
                "jobHourOffset":0,
                jobs:[],
                jobAccessError: false,
                rawdata: [],
                rawlabels: [],
                filtereddata: [],
                filteredlabels: [],
                datacollection: {}
            }
        },
                
        props: ['datalist','options','slider','chartrefresh'],
        mounted(){
        	//this.updateGraph(this.datalist);
        	setInterval(this.updateGraph,2000);
            if (this.chartrefresh){
                alert(this.chartrefresh);
            }
            
        },
        methods:{
        	filterChartData() {
              
                if(this.rawlabels.length < MAX_CHART_POINTS) {
                    //filtered data is a copy of rawdata until MAX_DATA_POINTS is reached
                    this.filteredlabels = this.rawlabels;

                    let self=this;
                    this.rawdata.forEach(function(element, index) {
                        self.filtereddata[index].data = element.data;
                    });
                }
                else {
                    //sending all the data points for the chart will lead to serious performance problems
                    //filtering is required, in this case we simply take out data points after the max resolution is reached
                    //a better approach is to properly average between points to keep peaks and other features
                    
                    var modValue = Math.ceil(this.rawlabels.length / MAX_CHART_POINTS);

                    this.filteredlabels = this.rawlabels.filter(function(element, index) {

                        return index % modValue == 0;
                    })
                    
                    let self=this;
                    this.rawdata.forEach(function(element, index) {
                        self.filtereddata[index].data = element.data.filter(function(element, index) {
                            return index % modValue == 0;
                        })
                    });

                }
            },
			updateGraph(){
                
                let self=this;
                var gotNewLabel = false;
                this.datalist.forEach(function(element){
                    let chartItem = staticChartItems.find(function(item){
                        return item.id == element.TagName;

                    })

                    if(chartItem){
                        if(!gotNewLabel){
                            
                            if(self.rawlabels.length >= self.MAX_DATA_POINTS){

                                self.rawlabels.splice(0, self.rawlabels.length - self.MAX_DATA_POINTS + 1);
                            }

                            self.rawlabels.push(self.convertDateTimeWithOffset(element.DateTimeStamp));
                            gotNewLabel = true;
                        }
                        
                        if(!(chartItem.index in self.rawdata)){
                            self.rawdata[chartItem.index]={
                                data:[],
                                label:chartItem.name,
                                yAxisID:chartItem.yAxisID,
                                borderColor:chartItem.color,
                                fill:false

                            };

                            self.filtereddata[chartItem.index]={
                                data:[],
                                label: chartItem.name,
                                yAxisID:chartItem.yAxisID,
                                borderColor:chartItem.color,
                                fill: false
                            };
                            //console.log(self.filtereddata[chartItem.index]);
                        }

                        if(self.rawdata[chartItem.index].data.length >= self.MAX_DATA_POINTS){
                            self.rawdata[chartItem.index].data.splice(0,self.rawdata[chartItem.index].data.length - self.MAX_DATA_POINTS + 1);
                        }
                         
                        self.rawdata[chartItem.index].data.push({x: self.convertDateTimeWithOffset(element.DateTimeStamp), y:element.DataVal});
                    }


                });
        
                this.filterChartData();
                let fdata=[];
                let flabel=[];
                this.filtereddata.forEach(function(element){
                    if (typeof element !='undefined'){
                        
                        fdata.push(element);
                    }
                });  
          
                console.log(this.slider);
                 
                flabel = this.filteredlabels;
                this.datacollection = {
                    labels: flabel,
                    datasets: fdata
                }
            },
            refresh(){
                let self = this;
                self.rawdata= [];
                self.rawlabels= [];
                self.filtereddata= [];
                self.filteredlabels= [];
                self.datacollection= {};            
            },
            
            convertDateTimeWithOffset(datetimeStr) {
                var date = moment(datetimeStr);
                date.add({hours: this.jobHourOffset});
                return date;
            }

        }
    }   
       
        
</script>