<template>
    <div>
        <div class="row justify-content-between">
            <div class="h3 pr-0 mb-0">Project Management</div>
            <div><button class="btn btn-primary" @click="create()">Create New Project</button></div>
        </div>
        <div class="d-flex row w-100 pl-1 pt-3">
            <ul class="navbar-nav d-flex flex-row flex-list">
                <li class="nav-item text-nowrap"
                    v-for="(tabName, index) in filterTabs" :key="index" 
                    v-bind:class='{ 
                        dashboard_navbar_selected: darkMode && selectedTabName == tabName , 
                        dashboard_navbar_item: darkMode && selectedTabName !== tabName, 
                        dashboard_navbar_selected_light: !darkMode && selectedTabName == tabName , 
                        dashboard_navbar_item_light: !darkMode && selectedTabName !== tabName 
                    }'
                >
                    <a class="nav-link" href="#" :class="{'text-white': darkMode, 'text-dark': !darkMode }"
                        v-on:click="setFilter(tabName)"
                    > 
                        {{ tabName }}
                    </a>
                </li>
            </ul>
        </div>
        <div class="pt-2" v-if="!loading">
            <table v-if="displayList[selectedTabName].length > 0" class="table table-striped table-hover mt-2" :class="{'table-dark': darkMode}">
                <thead>
                    <th scope="col" @click="order('name')" style="cursor:pointer;"><span class="fas fa-sort"></span>Name</th>
                    <th v-if="iwsUser" @click="order('customer')" scope="col" style="cursor:pointer;"><span class="fas fa-sort"></span>Customer</th>
                    <th scope="col" ><span class="fas"></span>Pinned</th>
                    <th scope="col">&nbsp;</th>
                    <th scope="col">&nbsp;</th>
                </thead>
                <tbody>
                    <tr v-for="(activeListItem, index) in displayList[selectedTabName]" :key="index">
                        <td class="d-flex align-items-center">
                            {{activeListItem.name}}
                            <div class="ml-2 badge badge-secondary" v-if="activeListItem.isDefault">
                                Default
                            </div>
                        </td>
                        <td v-if="iwsUser">{{activeListItem.customer.name}}</td>
                        <td @click="onPinnedClick(activeListItem)" style="cursor:pointer;"> 
                            <i v-if="activeListItem.isPinned" class="fas fa-star"></i>
                            <i v-else class="far fa-star"></i> 
                        </td>
                        <td ></td>
                        <td>
                            <div class="d-flex justify-content-end align-items-center h-100 w-100">
                                <span class="badge badge-secondary" @click="makeDefault(activeListItem.id)" style="cursor:pointer;"
                                    v-if="!activeListItem.isDefault">
                                    Make Default
                                </span>
                                <span class="badge badge-primary ml-1" @click="view(activeListItem.id)" style="cursor:pointer;">View</span>
                                <span class="badge badge-danger ml-1" @click="remove(activeListItem.id)" style="cursor:pointer;">Delete</span>
                            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
            <div v-else>
                No results
            </div>
            <div class="pt-4">
                <small>*Projects will be archived after 30 days of inactivity</small>
            </div>
            <div class="d-flex row justify-content-center">
                <b-pagination
                    @change="onPageClicked"
                    v-model="currentPage"
                    :total-rows="availableDashboardList.total"
                    :per-page="availableDashboardList.per_page"
                >
                </b-pagination>
            </div>
        </div>
        <div v-else class="d-flex align-items-center justify-content-center">
            <b-spinner variant="primary" label="Spinning"></b-spinner>
        </div>
    </div>
</template>

<style scoped>
    /* darktheme */
    .dashboard_navbar_selected{
        cursor: auto;
        color: #fff;
        transform-origin:  50% 50%;
    }

    .dashboard_navbar_selected:after{
        display:block;
        content: '';
        border-bottom: solid 3px #0275d8; 
        transform: scaleX(1);  
        transition: transform 250ms ease-in-out;
        transform-origin:  0% 50%;
        color: #fff;
    }
    .dashboard_navbar_item {
        transition: all 250ms ease-in-out;
    }

    .dashboard_navbar_item:after {
        display:block;
        content: '';
        color: #fff;
        border-bottom: solid 3px #fff;
        transform: scaleX(0);  
        transition: all 250ms ease-in-out;
    }
    .dashboard_navbar_item:hover:after {
        transform: scaleX(1);  
    }
    .flex-list li {
        border-right: 2px solid #444;
        padding-right: 0.5em;
        padding-left: 0.5em;
    }
    .flex-list li:last-child {
        border-right: 0;
    }
    /* end darktheme */
    /* light theme */
    .dashboard_navbar_selected_light{
        cursor: auto;
        color: #343a40;
        transform-origin:  50% 50%;
    }

    .dashboard_navbar_selected_light:after{
        display:block;
        content: '';
        border-bottom: solid 3px #0275d8; 
        transform: scaleX(1);  
        transition: transform 250ms ease-in-out;
        transform-origin:  0% 50%;
        color: #343a40;
    }
    .dashboard_navbar_item_light {
        transition: all 250ms ease-in-out;
    }

    .dashboard_navbar_item_light:after {
        display:block;
        content: '';
        color: #343a40;
        border-bottom: solid 3px #343a40;
        transform: scaleX(0);  
        transition: all 250ms ease-in-out;
    }
     .dashboard_navbar_item_light:hover:after {
        transform: scaleX(1);  
    }
     /* end theme */
</style>

<script>
import moment from 'moment';
import GlobalFunctions from '../../GlobalFunctions';

const TAB_NAME_INDEX = {
    recent: 'Recent', 
    pinned: 'Pinned',
    archived: 'Archived'
};

const TAB_NAMES = [TAB_NAME_INDEX.recent, TAB_NAME_INDEX.pinned, TAB_NAME_INDEX.archived];
const RECENT_DAY_START = moment.utc().add({ days: -30 }).valueOf();

export default {
    props: {
        availableDashboardList: {
            type: [Object],
            required: true
        },
        iwsUser: {
            type: [Number, Boolean],
            required: true
        }
    },    
    created () {
        this.filterTabs = TAB_NAMES;
    },
    data() {
        return {
            currentPage: this.availableDashboardList.current_page,
            selectedTabName: TAB_NAMES[0],
            fetchedDashBoards: [],
            loading: false
        };
    },
    mounted() {
        const selectedTag = TAB_NAME_INDEX[window.location.pathname.split('/')[2]];
        this.selectedTabName = selectedTag;
        this.fetchedDashBoards = this.availableDashboardList.data.map((dashboard) => {
            return {
                id: dashboard.id,
                name: dashboard.name,
                customer_id: dashboard.customer_id,
                customer: dashboard.customer,
                isPinned: dashboard.is_pinned,
                isDefault: dashboard.is_default,
                isArchived: false,
                updatedAt: dashboard.updated_at
            };
        });
    },
    computed: {
        darkMode: function () {
            return this.$root.darkMode;
        },
        displayList: function () {
            const displayList = {};
            TAB_NAMES.forEach(tabName => {
                if(tabName === TAB_NAME_INDEX.recent) {
                    displayList[tabName] = this.fetchedDashBoards.filter((dashboardInfo)=> moment.utc(dashboardInfo.updatedAt) >= RECENT_DAY_START);
                } else if (tabName === TAB_NAME_INDEX.pinned) {
                    displayList[tabName] = this.fetchedDashBoards.filter((dashboardInfo)=> dashboardInfo.isPinned);
                } else if(tabName === TAB_NAME_INDEX.archived) {
                    displayList[tabName] = this.fetchedDashBoards.filter((dashboardInfo)=> moment.utc(dashboardInfo.updatedAt) < RECENT_DAY_START);
                }
            });
            return displayList;
        }
    },
    methods: {
        onPageClicked: function(pageNumber) {
            this.loading = true;
            window.location = `${this.availableDashboardList.path}?page=${pageNumber}`;
        },
        onPinnedClick: async function(activeListItem) {
            activeListItem.isPinned = !activeListItem.isPinned;
            const data = {
                '_token': GlobalFunctions.getCSRFToken(),
                'is_pinned': activeListItem.isPinned? 1 : 0
            };

            const url = '/analytics/' + activeListItem.id;
            const res = await $.ajax({
                method: 'PUT',
                url: url,
                data,
                dataType: 'json'
            });
        },							
        setFilter: function(tabName) {
            this.loading = true;
            const filterURL = tabName.toLowerCase();
            window.location = `/analytics-list/${filterURL}`;
            this.selectedTabName = tabName;
        },
        order(columnName) {
            const pathName = window.location.pathname;
            let sortOrder = 'asc';
            if (pathName.includes(columnName)) {
                if (pathName.includes('asc')) {
                    sortOrder = 'desc';
                }
            }
            const filterURL = this.selectedTabName.toLowerCase();
            window.location = `/analytics-list/${filterURL}/${columnName}/${sortOrder}`;
        },
        create() {
            window.location = '/analytics/0';
        },
        remove: async function(id) {
            if (confirm('Are you sure you want to delete?')) {
                const data = {
                    '_token': GlobalFunctions.getCSRFToken()
                };
    
                const url = '/analytics/' + id;
                
                const res = await $.ajax({
                    method: 'DELETE',
                    url: url,
                    data,
                    dataType: 'json'
                });
                location.reload();        
            }
        },
        view(id) {
            window.location = '/analytics/' + id;
        },
        makeDefault: async function(id) {
            if (confirm('Are you sure you want to mark as default? Any previous default will be cleared.')) {
                const data = {
                    '_token': GlobalFunctions.getCSRFToken(),
                };
    
                const url = '/analytics/' + id + '/mark-default';
                
                const res = await $.ajax({
                    method: 'PUT',
                    url: url,
                    data,
                    dataType: 'json'
                });
                location.reload();        
            }
        },

    }
};
</script>
