<!--
    * Component Description
        Re-usable component for search bars
        See props for an explanation of all supported functions

    * Side Effects
    Will emit 2 signals
        update:value    <- syncs with the 'value' prop to comunicate upwards what the user is searching for
        input           <- emits on text input

    * Example Usage
    <iws-search :value.sync="searchKey" />
-->

<template>
<div id="SearchInput">
    <iws-input
        :label="label"
        :placeholder="placeholder"
        :hint="hint"
        :value="value"
        :class="{
            'leading-icon': !!prependIcon,
            'active': !_isFalsy(value)
        }"

        :prepend-icon="!!prependIcon ? `fas ${prependIcon}` : null"
        :append-icon="clearable && !_isFalsy(value) ? 'fas fa-times' : null"

        :disabled="disabled"

        @input="handleInput"
        @focus="$emit('focus', $event)"
        @blur="$emit('blur', $event)"
        @keyup="$emit('keyup', $event)"
        @prepependClick="handleInput({ 'target': value })"
        @appendClick="clearInput"

        :required="required"
        ref="inputField"
    />
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy } = GlobalFunctions;

const SEARCH_INPUT_DELAY_MS = 500;

export default {
    props: {
        // Label for input, appears at the top-left
        label: {
			type: String | Number | Function
		},
        // Placeholder/hint that appears INSIDE input (only visible when value is null)
		placeholder: {
			type: String | Number | Function,
            default: 'Search'
		},
        // Placeholder/hint that appears BELOW input (is ALWAYS visible when provided)
        hint: {
			type: String | Number | Function
		},

        // Synced value of input field
        value: {
			type: String | Number
		},

        disabled: {
            type: Boolean | String,
            default: false
        },
        required: {
            type: Boolean | String,
            default: false
        },

        // Making this false stops the X button from showing at the right most end of the search input (when active)
        clearable: {
            type: Boolean,
            default: true
        },

        // Can be used to modify (or remove when null) the prepend icon
        prependIcon: {
			type: String,
			default: 'fa-search'
		}
    },
    data: () => ({
        inputTimeoutId: null,
    }),
    methods: {							
        _isFalsy(val) {
            return isFalsy(val);
        },
        
        handleInput($event, inputDelay=SEARCH_INPUT_DELAY_MS) {
            clearTimeout(this.inputTimeoutId)
            this.inputTimeoutId = setTimeout(() => {
                this.$emit('update:value', $event.target.value);
			    this.$emit('input', $event);
            },inputDelay);
		},

        clearInput() {
            this.handleInput({ target: { value: null } },0);
        },

        focusInput() {
            this.$refs.inputField.focus();
        },
        blurInput() {
            this.$refs.inputField.blur();
        }
    }
};
</script>

<style>
    #SearchInput input {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }

    #SearchInput input, #SearchInput #addon-wrapping {
        background-color: #343A40 !important;
        color: var(--form-color) !important;
    }
    #SearchInput input:disabled {
        cursor: not-allowed;
    }
    #SearchInput .leading-icon input {
        border-left: none !important;
        padding-left: 0px !important;
    }
    #SearchInput .leading-icon.active input {
        border-right: none !important;
    }

    #SearchInput.active input:not(.is-invalid), 
    #SearchInput.active .input-group-text {
        border-color: var(--primary-color);
    }
    #SearchInput.active .fas {
        color: var(--primary-color) !important;
    }

    #SearchInput .fas.fa-times {
        position: absolute;
        top: 12px;
        right: 10px;
        z-index: 5;

        color: var(--primary-text-color) !important;
    }

    #SearchInput .form-control.is-invalid {
        background-image: none !important;
    }

    #SearchInput .input-group-append {
        cursor: pointer
    }
</style>