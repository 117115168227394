var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        class: `${_vm.borderStyle} rounded d-flex justify-content-center align-items-center grey-background`,
      },
      [
        _c(
          "div",
          {
            staticClass: "h-100 mr-1",
            class: {
              calendarBorderClicked: _vm.showingCalendar,
              "rounded-left": _vm.showingCalendar,
              calendarBorderPassive: !_vm.showingCalendar,
            },
          },
          [
            _c(
              "div",
              {
                staticClass: "d-flex mx-1 py-2",
                on: { click: _vm.toggleCalendar },
              },
              [
                _c("img", {
                  staticClass: "mr-1",
                  class: `${_vm.showingCalendar ? "light-blue-svg" : ""}`,
                  attrs: {
                    src: "/images/icons/calendar-clock.svg",
                    placeholder: "DD/MM/YYYY HH:MM:SS",
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  class: `${
                    _vm.showingCalendar ? "light-blue-svg upside-down" : ""
                  }`,
                  attrs: { src: "/images/icons/chevron-down-small.svg" },
                }),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          [
            _c("custom-date-time", {
              attrs: {
                jobHourOffset: _vm.utcHourOffset,
                parentDateString: _vm.selectedDateTimeString,
                min: _vm.min,
                max: _vm.max,
              },
              on: {
                manualDateEntered: _vm.handleManualDateSelection,
                clearInput: _vm.clearValue,
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.showingCalendar,
            expression: "showingCalendar",
          },
          {
            name: "on-clickaway",
            rawName: "v-on-clickaway",
            value: _vm.hideCalendar,
            expression: "hideCalendar",
          },
        ],
        staticClass: "position-absolute calendar-modal",
      },
      [
        _c(
          "div",
          {
            staticClass:
              "modal-container d-flex flex-column w-full grey-border grey-background pb-3",
            staticStyle: {
              "max-width": "700px",
              "z-index": "2",
              position: "relative",
            },
          },
          [
            !_vm.basic
              ? _c("div", { staticClass: "header p-3 d-none d-sm-block" }, [
                  _c("div", { staticClass: "d-flex justify-content-between" }, [
                    _c("div", [_vm._v("Select Date and Time")]),
                    _vm._v(" "),
                    _c("img", {
                      staticClass: "close-button clickable ml-2",
                      attrs: { src: "/images/icons/close.svg" },
                      on: { click: _vm.close },
                    }),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "content d-flex responsive-direction" }, [
              _c(
                "div",
                [
                  _c("v-date-picker", {
                    ref: "calendar",
                    staticStyle: {
                      "background-color": "transparent",
                      border: "none",
                    },
                    attrs: {
                      "is-dark": "",
                      attributes: _vm.calendarAttributes,
                      mode: "date",
                      timezone: "UTC",
                      "max-date": _vm.parsedMaxDate,
                      "min-date": _vm.parsedMinDate,
                    },
                    on: { dayclick: (day) => _vm.dayClicked(day) },
                    model: {
                      value: _vm.selectedDate,
                      callback: function ($$v) {
                        _vm.selectedDate = $$v
                      },
                      expression: "selectedDate",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.basic
                ? _c("div", { staticClass: "d-flex flex-column flex-grow-1" }, [
                    _c("div", [
                      _c("div", { staticClass: "mb-1" }, [
                        _vm._v("Select time"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex" },
                        [
                          _c("dropdown", {
                            ref: "hoursDropdown",
                            staticClass: "mr-2",
                            attrs: {
                              padAmount: 2,
                              defaultValue: _vm.DEFAULT_HOURS,
                              value: _vm.hours,
                              values: Array.from(
                                { length: 12 },
                                (_, i) => i + 1
                              ),
                            },
                            on: {
                              valueSelected: (value) => (_vm.hours = value),
                            },
                          }),
                          _vm._v(" "),
                          _c("dropdown", {
                            ref: "minutesDropdown",
                            staticClass: "mr-2",
                            attrs: {
                              padAmount: 2,
                              defaultValue: _vm.DEFAULT_MINUTES,
                              value: _vm.minutes,
                              values: [...Array(60).keys()],
                            },
                            on: {
                              valueSelected: (value) => (_vm.minutes = value),
                            },
                          }),
                          _vm._v(" "),
                          _c("dropdown", {
                            ref: "secondsDropdown",
                            staticClass: "mr-2",
                            attrs: {
                              padAmount: 2,
                              defaultValue: _vm.DEFAULT_SECONDS,
                              value: _vm.seconds,
                              values: [...Array(60).keys()],
                            },
                            on: {
                              valueSelected: (value) => (_vm.seconds = value),
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex rounded dropdown-box grey-border clickable",
                            },
                            [
                              _c(
                                "div",
                                {
                                  class: `d-flex align-items-center justify-content-center h-100 w-50 rounded-left ${
                                    !_vm.isPM ? "toggle-selected" : "text-muted"
                                  }`,
                                  on: {
                                    click: function ($event) {
                                      _vm.isPM = false
                                    },
                                  },
                                },
                                [_c("div", [_vm._v("AM")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  class: `d-flex align-items-center justify-content-center h-100 w-50 rounded-right ${
                                    _vm.isPM ? "toggle-selected" : "text-muted"
                                  }`,
                                  on: {
                                    click: function ($event) {
                                      _vm.isPM = true
                                    },
                                  },
                                },
                                [_c("div", [_vm._v("PM")])]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]),
                    _vm._v(" "),
                    _vm.showDefaultTimeNotification
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-items-center mt-2 grey-text",
                          },
                          [
                            _c("img", {
                              staticClass: "p-1",
                              attrs: {
                                src: "/images/icons/info-circle-faded.svg",
                              },
                            }),
                            _vm._v(
                              "Default Time is 12:00:00 AM\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex justify-content-end ml-auto mt-auto column-footer",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "secondary-button mr-2 rounded py-2 user-select-none clickable grey-border",
                            on: { click: _vm.clearValue },
                          },
                          [
                            _vm._v(
                              "\n                            Clear\n                        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass:
                              "primary-button rounded py-2 user-select-none clickable",
                            attrs: { disabled: this.selectedDate == null },
                            on: { click: _vm.updateValue },
                          },
                          [
                            _vm._v(
                              "\n                            Apply\n                        "
                            ),
                          ]
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }