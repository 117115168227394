var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("library-add-edit-base", {
    ref: "libraryAddEditBase",
    attrs: {
      id: "libraryAddEditBase",
      item: _vm.plug,
      updateAction: _vm.savePlug,
      createAction: _vm.createPlug,
    },
    scopedSlots: _vm._u([
      {
        key: "title",
        fn: function () {
          return [
            _vm._v(
              "\r\n        " +
                _vm._s(
                  _vm.isEditMode(_vm.plug) ? "Edit Plug" : "Add New Plug"
                ) +
                "\r\n    "
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "leftBody",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "field-container" },
              [
                _c("iws-input", {
                  ref: "descriptionInput",
                  attrs: {
                    label: "Description *",
                    value: _vm.plug.description,
                    required: "true",
                    "error-message": "Please provide a description",
                  },
                  on: {
                    "update:value": function ($event) {
                      return _vm.$set(_vm.plug, "description", $event)
                    },
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "lengthInput",
                      attrs: {
                        label: "Length (in) *",
                        value: _vm.plug.length,
                        required: "true",
                        "error-message": "Please provide a length",
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.plug, "length", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm.enableCustomerCol &&
              !!_vm.customers &&
              _vm.customers.length > 1
                ? _c("div", { staticClass: "col-12 col-sm-6" }, [
                    _c(
                      "div",
                      { staticClass: "field-container" },
                      [
                        _c("iws-select", {
                          ref: "customerName",
                          attrs: {
                            label: "Customer Name *",
                            value: _vm.plug.customer_id,
                            options: _vm.customers,
                            "value-name": "id",
                            "display-name": "name",
                            required: "required",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(_vm.plug, "customer_id", $event)
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "rightBody",
        fn: function () {
          return [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "iconField",
                      attrs: { label: "Icon", value: _vm.plug.icon },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.plug, "icon", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "outer_diameter",
                      attrs: {
                        label: "Outer Diameter (in)",
                        value: _vm.plug.outer_diameter,
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.plug, "outer_diameter", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "customerNameField",
                      attrs: {
                        label: "Inner Diameter (in)",
                        value: _vm.plug.inner_diameter,
                        type: "number",
                        min: "0",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.plug, "inner_diameter", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "equipment_type",
                      attrs: {
                        label: "Equipment Type",
                        value: _vm.plug.equipment_type,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.plug, "equipment_type", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "makeField",
                      attrs: { label: "Make", value: _vm.plug.make },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.plug, "make", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-12 col-sm-6" }, [
                _c(
                  "div",
                  { staticClass: "field-container" },
                  [
                    _c("iws-input", {
                      ref: "modelField",
                      attrs: { label: "Model", value: _vm.plug.model },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.plug, "model", $event)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }