var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !!_vm.path && !!_vm.path.length
    ? _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
        _c(
          "ol",
          { staticClass: "breadcrumb" },
          _vm._l(_vm.path, function (page, index) {
            return _c(
              "li",
              {
                key: `${index}_${page.name}`,
                staticClass: "breadcrumb-item",
                class: { active: _vm.isActiveItem(index) },
              },
              [
                page.link
                  ? _c(
                      "a",
                      {
                        class: {
                          "secondary-text-color": !_vm.isActiveItem(index),
                          "primary-text-color": _vm.isActiveItem(index),
                        },
                        attrs: { href: page.link },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(page.title) +
                            "\n            "
                        ),
                      ]
                    )
                  : _c(
                      "span",
                      {
                        class: {
                          "secondary-text-color": !_vm.isActiveItem(index),
                          "primary-text-color": _vm.isActiveItem(index),
                        },
                      },
                      [
                        _vm._v(
                          "\n                " +
                            _vm._s(page.title) +
                            "\n            "
                        ),
                      ]
                    ),
              ]
            )
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }