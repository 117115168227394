<template>
    <div>
        Push to talk {{ version }}, communicationUserId: {{ communicationUserId }}
        <br>
        Status: {{ status }}
        <div v-if="apiKey"> --- field device mode --- </div>
        <div v-else> --- dashboard user mode --- </div>
        <div class="row-col" v-if="call && !callEnded">
            <div class="btn btn-danger" @click="endCall">
                End call
            </div>

            <div v-if="!apiKey" class="btn btn-primary" @pointerdown="pushToTalk('start')" @pointerup="pushToTalk('end')">
                Push to talk
            </div>
            <div v-if="loading" class="btn btn-warning">
                Please wait...Connecting... waiting for okay to talk...
            </div>
            <div v-if="pushToTalkInProgress" class="btn btn-success">
                Push to talk in progress...
            </div>
        </div>
    </div>
</template>

<script>
import { CallClient, CallAgent } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty } = GlobalFunctions;

export default {
    props: ['user', 'jobId', 'apiKey'],

    data() {
        return {
            call: null,
            callAgent: null,
            callClient: null,
            callEnded: false,
            communicationUserId: null,
            loading: false,
            message: 'working',
            pushToTalkInProgress: false,
            pushToTalkTimer: null,
            roomId: null,
            status: 'No status to report',
            pushToTalkTimeout: 10000,
            token: '',
            tokenCredential: null,
            version: 'v1.0'
        }
    },

    methods: {
        async init() {
            let self = this
            self.status = 'initializing...'
            try {
                await self.getToken()
                if (!self.apiKey)
                    await self.requestRoomAccess()
                if (self.roomId) {
                    self.status = 'creating call agent'
                    self.callClient = new CallClient()
                    self.tokenCredential = new AzureCommunicationTokenCredential(self.token)
                    self.callAgent = await self.callClient.createCallAgent(self.tokenCredential)
                    self.status = 'callAgent created'
                    self.call = self.callAgent.join({ roomId: self.roomId })

                }
            } catch (err) {
                console.error(`init error: ${err.toString()}`)
            }
        },

        endCall() {
            this.call.hangUp({ forEveryone: false })
            this.callEnded = true
        },

        async getToken() {
            this.status = 'getting token...';
            
            try {
                let resp;
                if (this.apiKey) {
                    resp = await axios.get(`/comms/getRoomToken?apiKey=${this.apiKey}`);
                    this.roomId = resp?.data?.roomId;
                } else {
                    resp = await axios.get('/comms/getUserToken');
                }

                console.log('resp', resp);

                this.token = resp?.data?.token;
                this.communicationUserId = resp?.data?.user?.communicationUserId;

                if (!isFalsy(this.token) && !isFalsy(this.communicationUserId)) {
                    this.status = 'Fetched token successfully';
                } else {
                    this.status = 'Failed to fetch token';
                }
            } catch (err) {
                console.error(`getToken error: ${err.toString()}`);
                this.status = 'Failed to fetch token: ' + err.toString();
            }
        },

        async pushToTalk(action) {
            let self = this
            let proceed = false
            let resp
            switch (action) {
                case 'start':
                    proceed = true
                    self.loading = true
                    break
                case 'end':
                    if (self.pushToTalkTimer)
                        clearTimeout(self.pushToTalkTimer)
                    if (!self.call.isMuted)
                        self.call.mute()
                    proceed = true
                    break
                default:
                    break
            }
            if (proceed) {
                try {
                    resp = await axios.get(
                        `/comms/pushToTalk?action=${action}&jobId=${self.jobId}`
                    )
                    console.log(resp.data)
                    if (action == 'start') {
                        self.pushToTalkInProgress = true
                        await self.call.unmute()
                        self.pushToTalkTimeout = resp.data.timeout
                        self.pushToTalkTimer = setTimeout(
                            () => {
                                console.log(`pushToTalkTimeout reached`)
                                self.pushToTalk('end')
                            },
                            self.pushToTalkTimeout
                        )
                    } else {
                        self.pushToTalkInProgress = false
                    }
                    self.loading = false
                } catch (err) {
                    console.error(`pushToTalk error: ${err.toString()}`)
                    console.log(resp.data)
                }
            }
            console.log(`call muted: ${self.call.isMuted}`)
        },

        async requestRoomAccess() {
            let self = this
            self.status = 'requesting room access...'
            try {
                let resp = await axios.get(`/comms/requestRoomAccess?jobId=${self.jobId}`)
                if (resp.data && resp.data.roomId) {
                    self.status = `room access granted roomId: ${resp.data.roomId}`
                    self.roomId = resp.data.roomId
                } else {
                    self.status = 'room access request denied'
                }
            } catch (err) {
                console.error(`room request error: ${err.toString()}`)
            }
        }
    },

    mounted() {
        this.init()
    },

    watch: {
        call: async function (val) {
            if (val) {
                console.log(`call value changed and not null`)
                this.status = 'call in progress'
                if (this.apiKey) {
                    console.log(`field mode, will not mute mic`)
                } else {
                    await new Promise(resolve => setTimeout(resolve, 3000)) // yup, not ideal...
                    await val.mute()
                    console.log(`call mute status: ${val.isMuted}`)
                }
            }
        }
    }
}
</script>