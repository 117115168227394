var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "chartOptionsModal",
    attrs: {
      "no-close-on-backdrop": "",
      size: "lg",
      "header-class": "border-bottom-0 justify-content-center  p-0",
      "body-class": "pb-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg",
    },
    on: {
      hidden: function ($event) {
        return _vm.$emit("onDismiss")
      },
    },
    scopedSlots: _vm._u(
      [
        _vm.configData.type == "channel"
          ? {
              key: "modal-header",
              fn: function () {
                return [
                  _c("div", { staticClass: "col w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-header d-flex justify-content-center",
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.configData.selectedChannelId,
                                expression: "configData.selectedChannelId",
                              },
                            ],
                            staticClass: "form-control w-50",
                            staticStyle: {
                              "text-align-last": "center",
                              "text-align": "center",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.configData,
                                  "selectedChannelId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(
                            _vm.configData.selectedChannels,
                            function (channel) {
                              return _c(
                                "option",
                                {
                                  key: channel.id,
                                  domProps: { value: channel.id },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      channel.prioritizedTagName
                                        ? channel.prioritizedTagName
                                        : channel.friendlyTagname
                                    )
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            }
          : null,
        {
          key: "default",
          fn: function () {
            return [
              _vm.configData.type == "axis"
                ? _c("div", { staticClass: "col w-100" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "modal-header d-flex justify-content-center",
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.configData.selectedChannelId,
                                expression: "configData.selectedChannelId",
                              },
                            ],
                            staticClass: "form-control w-50",
                            staticStyle: {
                              "text-align-last": "center",
                              "text-align": "center",
                            },
                            on: {
                              change: function ($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function (o) {
                                    return o.selected
                                  })
                                  .map(function (o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.configData,
                                  "selectedChannelId",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              },
                            },
                          },
                          _vm._l(
                            _vm.configData.selectedChannels,
                            function (channel) {
                              return _c(
                                "option",
                                {
                                  key: channel.id,
                                  domProps: { value: channel.id },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      channel.prioritizedTagName
                                        ? channel.prioritizedTagName
                                        : channel.friendlyTagname
                                    )
                                  ),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", [
                _c("form", [
                  _vm.configData.type == "channel"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col-12 text-center align-items-center mb-2",
                        },
                        [_vm._v("Channel Options")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.configData.type == "channel"
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "row justify-content-center align-items-center",
                        },
                        [
                          _c("div", { staticClass: "col-6" }, [
                            _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-4 text-right" }, [
                                _vm._v("Line Color"),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "col-8" }, [
                                _c("div", {}, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "swatch p-0",
                                      on: {
                                        click: function ($event) {
                                          _vm.showChannelColorPicker =
                                            !_vm.showChannelColorPicker
                                        },
                                      },
                                    },
                                    [
                                      _c("div", {
                                        staticClass: "color",
                                        style: {
                                          "background-color":
                                            _vm.channelOptions.color,
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _vm.showChannelColorPicker
                                    ? _c(
                                        "div",
                                        { staticClass: "popover" },
                                        [
                                          _c("div", {
                                            staticClass: "cover",
                                            on: {
                                              click: function ($event) {
                                                _vm.showChannelColorPicker = false
                                              },
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("sketch-picker", {
                                            ref: "colorPicker",
                                            attrs: {
                                              value: _vm.channelOptions.color
                                                ? _vm.channelOptions.color
                                                : "#FFFFFF",
                                              id: "color",
                                              disableAlpha: true,
                                            },
                                            on: {
                                              input: (color) => {
                                                _vm.channelOptions.color =
                                                  color.hex
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]),
                              ]),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "row align-items-center" },
                              [
                                _c("div", { staticClass: "col-4 text-right" }, [
                                  _vm._v("Line Size"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.channelOptions.thickness,
                                        expression: "channelOptions.thickness",
                                      },
                                    ],
                                    ref: "lineSize",
                                    staticStyle: { "max-width": "75%" },
                                    attrs: {
                                      type: "number",
                                      default: "2",
                                      min: "1",
                                      max: "10",
                                    },
                                    domProps: {
                                      value: _vm.channelOptions.thickness,
                                    },
                                    on: {
                                      blur: function ($event) {
                                        return _vm.restrictLineSize()
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.channelOptions,
                                          "thickness",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _vm.formErrors.lineSize
                                    ? _c(
                                        "div",
                                        { staticClass: "text-danger" },
                                        [
                                          _vm._v(
                                            "\n                                    Line size is required\n                                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.configData.type == "channel"
                    ? _c(
                        "div",
                        { staticClass: "row align-items-center mb-2" },
                        [
                          _c("div", { staticClass: "col-6" }, [
                            _c(
                              "div",
                              { staticClass: "row align-items-center" },
                              [
                                _c("div", { staticClass: "col-4 text-right" }, [
                                  _vm._v("Show"),
                                ]),
                                _vm._v(" "),
                                _c("div", { staticClass: "col-8" }, [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.channelOptions.show,
                                        expression: "channelOptions.show",
                                      },
                                    ],
                                    ref: "showChannel",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(
                                        _vm.channelOptions.show
                                      )
                                        ? _vm._i(
                                            _vm.channelOptions.show,
                                            null
                                          ) > -1
                                        : _vm.channelOptions.show,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = _vm.channelOptions.show,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.channelOptions,
                                                "show",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.channelOptions,
                                                "show",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.channelOptions,
                                            "show",
                                            $$c
                                          )
                                        }
                                      },
                                    },
                                  }),
                                ]),
                              ]
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("hr"),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "col-12 text-center align-items-center mb-2",
                    },
                    [_vm._v("Axis Options")]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-content-center align-items-center mb-2",
                    },
                    [
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value:
                                    "Axis position changes will only appear on data load",
                                  expression:
                                    "'Axis position changes will only appear on data load'",
                                  arg: "top",
                                },
                              ],
                              staticClass: "col-4 text-right",
                            },
                            [_vm._v("Axis")]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-6 pr-1" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedAxisId,
                                    expression: "selectedAxisId",
                                  },
                                ],
                                ref: "selectedAxis",
                                staticClass:
                                  "mw-100 w-100 text-overflow-ellipsis",
                                attrs: {
                                  id: "axisLocation",
                                  name: "selectedAxis",
                                  default: "default",
                                },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedAxisId = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    function ($event) {
                                      return _vm.updateSelectedAxis()
                                    },
                                  ],
                                },
                              },
                              _vm._l(
                                _vm.axisOptionsArray,
                                function (axisOption) {
                                  return _c(
                                    "option",
                                    {
                                      key: axisOption.id,
                                      domProps: { value: axisOption.id },
                                    },
                                    [_vm._v(_vm._s(axisOption.label))]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-2 p-0" }, [
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value: "Create New Axis",
                                  expression: "'Create New Axis'",
                                  arg: "top",
                                },
                              ],
                              staticClass:
                                "fas fa-plus-circle show-clicker-finger",
                              on: {
                                click: function ($event) {
                                  return _vm.addAxis()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("i", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value: "Copy Axis",
                                  expression: "'Copy Axis'",
                                  arg: "top",
                                },
                              ],
                              staticClass: "fas fa-copy show-clicker-finger",
                              on: {
                                click: function ($event) {
                                  return _vm.copyAxis()
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.configData.type == "channel"
                              ? _c("div", [
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .axisCreationType == "custom"
                                    ? _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip:top",
                                            value: "Delete Axis",
                                            expression: "'Delete Axis'",
                                            arg: "top",
                                          },
                                        ],
                                        staticClass:
                                          "fas fa-trash-alt show-clicker-finger ml-2",
                                        on: {
                                          click: function ($event) {
                                            return _vm.deleteAxis()
                                          },
                                        },
                                      })
                                    : _c("i", {
                                        directives: [
                                          {
                                            name: "tooltip",
                                            rawName: "v-tooltip:top",
                                            value: "Delete Axis",
                                            expression: "'Delete Axis'",
                                            arg: "top",
                                          },
                                        ],
                                        staticClass:
                                          "fas fa-trash-alt show-clicker-finger not-selectable-icon",
                                      }),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-4 text-right" }, [
                            _vm._v("Label"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ].label,
                                  expression:
                                    "configData.axisOptions[selectedAxisId].label",
                                },
                              ],
                              ref: "axisLabel",
                              staticClass: "text-overflow-ellipsis",
                              staticStyle: { "max-width": "100%" },
                              attrs: {
                                type: "text",
                                placeholder: "Axis Label",
                              },
                              domProps: {
                                value:
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .label,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ],
                                    "label",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.formErrors.axisLabel
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                                    Axis label is required\n                                "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-content-center align-items-center mb-2",
                    },
                    [
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value:
                                    "Axis position changes will only appear on data load",
                                  expression:
                                    "'Axis position changes will only appear on data load'",
                                  arg: "top",
                                },
                              ],
                              staticClass: "col-4 text-right",
                            },
                            [_vm._v("Position"), _c("sup", [_vm._v("*")])]
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-8" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ].location,
                                    expression:
                                      "configData.axisOptions[selectedAxisId].location",
                                  },
                                ],
                                ref: "axisPosition",
                                staticStyle: { "max-width": "75%" },
                                attrs: {
                                  id: "axisLocation",
                                  name: "axisLocation",
                                  default: "default",
                                },
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.$set(
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ],
                                      "location",
                                      $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      id: "defaultAxisLocation",
                                      value: "default",
                                    },
                                  },
                                  [_vm._v("Left")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      id: "oppositeAxisLocation",
                                      value: "opposite",
                                    },
                                  },
                                  [_vm._v("Right")]
                                ),
                              ]
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-4 text-right" }, [
                            _vm._v("Auto Fit"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ].autoFit,
                                  expression:
                                    "configData.axisOptions[selectedAxisId].autoFit",
                                },
                              ],
                              ref: "axisAutoFit",
                              attrs: {
                                type: "checkbox",
                                disabled:
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .lockToZero == true,
                              },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .autoFit
                                )
                                  ? _vm._i(
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ].autoFit,
                                      null
                                    ) > -1
                                  : _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ].autoFit,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ].autoFit,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.configData.axisOptions[
                                            _vm.selectedAxisId
                                          ],
                                          "autoFit",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.configData.axisOptions[
                                            _vm.selectedAxisId
                                          ],
                                          "autoFit",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ],
                                      "autoFit",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-content-center align-items-center mb-2",
                    },
                    [
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-4 text-right" }, [
                            _vm._v("Min"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ].axisMin,
                                  expression:
                                    "configData.axisOptions[selectedAxisId].axisMin",
                                },
                              ],
                              ref: "axisMin",
                              staticStyle: { "max-width": "75%" },
                              attrs: {
                                type: "number",
                                disabled:
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .autoFit == true ||
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .lockToZero == true,
                              },
                              domProps: {
                                value:
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .axisMin,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ],
                                    "axisMin",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.formErrors.axisMin
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                                    Axis minimum is required and must be less than axis maximum\n                                "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-4 text-right" }, [
                            _vm._v("Max"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ].axisMax,
                                  expression:
                                    "configData.axisOptions[selectedAxisId].axisMax",
                                },
                              ],
                              ref: "axisMax",
                              staticStyle: { "max-width": "75%" },
                              attrs: {
                                type: "number",
                                disabled:
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .autoFit == true ||
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .lockToZero == true,
                              },
                              domProps: {
                                value:
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .axisMax,
                              },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ],
                                    "axisMax",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                            _vm._v(" "),
                            _vm.formErrors.axisMax
                              ? _c("div", { staticClass: "text-danger" }, [
                                  _vm._v(
                                    "\n                                    Axis maximum is required and must be greater than axis minimum\n                                "
                                  ),
                                ])
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-content-center align-items-center mb-2",
                    },
                    [
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-4 text-right" }, [
                            _vm._v("Unit"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-8" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.unitSelect,
                                    expression: "unitSelect",
                                  },
                                ],
                                ref: "axisUnit",
                                staticClass: "w-100",
                                on: {
                                  input: function ($event) {
                                    return _vm.setAxisUnits($event.target.value)
                                  },
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.unitSelect = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              _vm._l(_vm.channelUnits, function (unit, index) {
                                return _c(
                                  "option",
                                  {
                                    key: index,
                                    domProps: {
                                      selected: unit == _vm.unitSelect,
                                      value: unit,
                                    },
                                  },
                                  [_vm._v(_vm._s(unit))]
                                )
                              }),
                              0
                            ),
                            _vm._v(" "),
                            _vm.unitSelect == "Custom"
                              ? _c("input", {
                                  ref: "axisCustom",
                                  staticClass:
                                    "w-100 mt-1 text-overflow-ellipsis",
                                  attrs: { type: "text" },
                                  domProps: {
                                    value:
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ].unit,
                                  },
                                  on: {
                                    blur: function ($event) {
                                      return _vm.setAxisUnits(
                                        $event.target.value
                                      )
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }, [
                          _c("div", { staticClass: "col-4 text-right" }, [
                            _vm._v("Lock to Zero"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-8" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value:
                                    _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ].lockToZero,
                                  expression:
                                    "configData.axisOptions[selectedAxisId].lockToZero",
                                },
                              ],
                              ref: "axisLockToZero",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.configData.axisOptions[_vm.selectedAxisId]
                                    .lockToZero
                                )
                                  ? _vm._i(
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ].lockToZero,
                                      null
                                    ) > -1
                                  : _vm.configData.axisOptions[
                                      _vm.selectedAxisId
                                    ].lockToZero,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a =
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ].lockToZero,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.configData.axisOptions[
                                            _vm.selectedAxisId
                                          ],
                                          "lockToZero",
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.configData.axisOptions[
                                            _vm.selectedAxisId
                                          ],
                                          "lockToZero",
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(
                                      _vm.configData.axisOptions[
                                        _vm.selectedAxisId
                                      ],
                                      "lockToZero",
                                      $$c
                                    )
                                  }
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-6" }, [
                        _c("div", { staticClass: "row align-items-center" }),
                      ]),
                    ]
                  ),
                ]),
              ]),
            ]
          },
          proxy: true,
        },
        {
          key: "modal-footer",
          fn: function () {
            return [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex w-100 align-items-center justify-content-between px-2 pb-2",
                },
                [
                  _c("div", { staticClass: "mx-1 w-25" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn w-50 btn-secondary grey-button",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            return _vm.exitModal()
                          },
                        },
                      },
                      [_vm._v("Close")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "d-flex flex-row justify-content-between" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary mr-2",
                          attrs: { type: "button", disabled: _vm.isSaving },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.applyChanges()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    Apply Changes\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass:
                            "btn btn-success float-right green-button",
                          attrs: { type: "button", disabled: _vm.isSaving },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.applyChanges(true)
                            },
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-center" },
                            [
                              _vm.isSaving
                                ? _c("span", {
                                    staticClass:
                                      "spinner-border spinner-border-sm pr-2",
                                    attrs: {
                                      role: "status",
                                      "aria-hidden": "true",
                                    },
                                  })
                                : _vm._e(),
                              _vm._v(" "),
                              _c("div", [
                                _vm._v(
                                  "\n                            Save Changes to Template\n                        "
                                ),
                              ]),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v
      },
      expression: "modalVisible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }