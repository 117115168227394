var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "container-fluid",
      attrs: { id: "ToolstringConfigComponent" },
    },
    [
      _c("iws-breadcrumbs", {
        staticClass: "breadcrumbs-margins",
        attrs: {
          path: [
            {
              title: "Dashboard",
              link: "/home",
            },
            {
              title: _vm.jobNumber,
              link: `/wireline-op/${_vm.jobNumber}`,
            },
            {
              title: `Wireline ${_vm.wirelineSystemNumber}`,
              link: `/toolstring_config/${_vm.jobNumber}/${_vm.wirelineSystemNumber}`,
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "h5 page-title" }, [
        _vm._v(
          "\r\n        Job Number " +
            _vm._s(_vm.jobNumber) +
            " - Wireline " +
            _vm._s(_vm.wirelineSystemNumber) +
            "\r\n    "
        ),
      ]),
      _vm._v(" "),
      _c("iws-button", {
        attrs: {
          id: "save-all-wells",
          text: "Save All",
          disabled: !_vm.containsDirtyWell(),
          click: _vm.saveAllDirtyWells,
        },
      }),
      _vm._v(" "),
      _vm._isNullOrEmpty(_vm.wells)
        ? _c("div", [_vm._v("\r\n        No Wells\r\n    ")])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        [
          _vm._l(_vm.wells, function (well, index) {
            return [
              _c(
                "div",
                {
                  staticClass: "accordian-parent",
                  attrs: { id: `accordion_${well.id}` },
                },
                [
                  _c("div", { staticClass: "card" }, [
                    _c(
                      "div",
                      {
                        staticClass: "card-header clickable",
                        attrs: {
                          id: !well.isDirty ? `heading_${well.id}` : null,
                          "data-toggle": "collapse",
                          "data-target": !well.isDirty
                            ? `#collapse${well.id}`
                            : null,
                          "aria-expanded": "true",
                          "aria-controls": !well.isDirty
                            ? `collapse${well.id}`
                            : null,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.toggleWellData(well)
                          },
                        },
                      },
                      [
                        _c("h5", { staticClass: "mb-0" }, [
                          _vm._v(
                            "\r\n                            " +
                              _vm._s(well.name) +
                              "\r\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("i", {
                          class: _vm.isWellOpen(well)
                            ? "fas fa-chevron-down"
                            : "fas fa-chevron-right",
                        }),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "collapse",
                        class: { show: index === 0 },
                        attrs: {
                          id: `collapse${well.id}`,
                          "aria-labelledby": `heading_${well.id}`,
                          "data-parent": !well.isDirty
                            ? `#accordion_${well.id}`
                            : null,
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body" },
                          [
                            _c("div", { staticClass: "title_row" }, [
                              _c("h3", [
                                _vm._v(
                                  "\r\n                                    Toolstring Configuration - " +
                                    _vm._s(well.name) +
                                    "\r\n                                "
                                ),
                              ]),
                              _vm._v(" "),
                              !!_vm.openWellData[well.id]
                                ? _c(
                                    "div",
                                    { attrs: { id: "well_actions" } },
                                    [
                                      _c("iws-button", {
                                        attrs: {
                                          text: "Cancel",
                                          type: "outline-light",
                                          disabled: !well.isDirty,
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.discardWellChanges(well)
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("iws-button", {
                                        attrs: {
                                          text: "Duplicate to other wells",
                                          type: "outline-light",
                                          disabled: _vm._isNullOrEmpty(
                                            well.toolstrings
                                          ),
                                          click: () => _vm.duplicateWell(well),
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("iws-button", {
                                        attrs: {
                                          text: "Save",
                                          type: "primary",
                                          disabled:
                                            !well.isDirty ||
                                            !_vm.wellIsValid(well),
                                          click: () => _vm.saveWell(well),
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                            _vm._v(" "),
                            _vm._isNullOrEmpty(well.toolstrings)
                              ? _c("div", { attrs: { id: "no-toolstrings" } }, [
                                  _vm._v(
                                    "\r\n                                No Toolstrings\r\n                            "
                                  ),
                                ])
                              : _c("iws-table", {
                                  attrs: { columns: _vm.toolStringColumns },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "header_null1",
                                        fn: function () {
                                          return undefined
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "header_stage",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\r\n                                    Stage\r\n                                "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "header_name",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\r\n                                    Toolstring Name\r\n                                "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "header_gun_type",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\r\n                                    Gun Type\r\n                                "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "header_phasing",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\r\n                                    Phasing\r\n                                "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "header_num_guns",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\r\n                                    No. of Guns\r\n                                "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "header_plug_type",
                                        fn: function () {
                                          return [
                                            _vm._v(
                                              "\r\n                                    Plug Type\r\n                                "
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                      {
                                        key: "body",
                                        fn: function () {
                                          return [
                                            _vm._l(
                                              well.toolstrings,
                                              function (
                                                toolstring,
                                                toolstring_index
                                              ) {
                                                return [
                                                  _c(
                                                    "tr",
                                                    {
                                                      key: toolstring.id,
                                                      class: {
                                                        "hide-bottom-border":
                                                          _vm.toolstringDataIsOpen(
                                                            well,
                                                            toolstring_index
                                                          ),
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "td",
                                                        [
                                                          _c("iws-button", {
                                                            attrs: {
                                                              id: "toolstring-data-toggle",
                                                              type: "outline-light",
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.toggleToolstringData(
                                                                  well,
                                                                  toolstring_index
                                                                )
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "text",
                                                                  fn: function () {
                                                                    return [
                                                                      _c("i", {
                                                                        class:
                                                                          _vm.toolstringDataIsOpen(
                                                                            well,
                                                                            toolstring_index
                                                                          )
                                                                            ? "fas fa-chevron-down"
                                                                            : "fas fa-chevron-right",
                                                                      }),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "td",
                                                        {
                                                          staticClass:
                                                            "stage-number",
                                                        },
                                                        [
                                                          _c("iws-button", {
                                                            attrs: {
                                                              type: `outline-${
                                                                !!toolstring.hasError
                                                                  ? "danger"
                                                                  : "light"
                                                              }`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.openStageNumber(
                                                                  well,
                                                                  toolstring_index
                                                                )
                                                              },
                                                            },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key: "text",
                                                                  fn: function () {
                                                                    return [
                                                                      toolstring.stageNumber ===
                                                                      null
                                                                        ? [
                                                                            _vm._v(
                                                                              "\r\n                                                            N/A\r\n                                                        "
                                                                            ),
                                                                          ]
                                                                        : toolstring.stageNumber ===
                                                                          toolstring.stageNumberClosing
                                                                        ? [
                                                                            _vm._v(
                                                                              "\r\n                                                            " +
                                                                                _vm._s(
                                                                                  toolstring.stageNumber
                                                                                ) +
                                                                                "\r\n                                                        "
                                                                            ),
                                                                          ]
                                                                        : [
                                                                            _vm._v(
                                                                              "\r\n                                                            " +
                                                                                _vm._s(
                                                                                  toolstring.stageNumber
                                                                                ) +
                                                                                " - " +
                                                                                _vm._s(
                                                                                  toolstring.stageNumberClosing
                                                                                ) +
                                                                                "\r\n                                                        "
                                                                            ),
                                                                          ],
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c("i", {
                                                                        staticClass:
                                                                          "fas fa-chevron-down",
                                                                      }),
                                                                    ]
                                                                  },
                                                                  proxy: true,
                                                                },
                                                              ],
                                                              null,
                                                              true
                                                            ),
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          "\r\n                                                " +
                                                            _vm._s(
                                                              toolstring.name
                                                            ) +
                                                            "\r\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          "\r\n                                                " +
                                                            _vm._s(
                                                              _vm.convertToolstringList(
                                                                toolstring.gunType,
                                                                " Gun Types"
                                                              )
                                                            ) +
                                                            "\r\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          "\r\n                                                " +
                                                            _vm._s(
                                                              _vm.convertToolstringList(
                                                                toolstring.phasing,
                                                                " Phasing Options"
                                                              )
                                                            ) +
                                                            "\r\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          "\r\n                                                " +
                                                            _vm._s(
                                                              toolstring.gunCount
                                                            ) +
                                                            "\r\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _vm._v(
                                                          "\r\n                                                " +
                                                            _vm._s(
                                                              _vm.convertToolstringList(
                                                                toolstring.plugType,
                                                                " Plug Types"
                                                              )
                                                            ) +
                                                            "\r\n                                            "
                                                        ),
                                                      ]),
                                                      _vm._v(" "),
                                                      _c("td", [
                                                        _c("div", [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "table-row-action text-primary mr-2",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openAddEditToolstring(
                                                                      well,
                                                                      toolstring
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-edit",
                                                              }),
                                                              _vm._v(
                                                                " Edit\r\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "table-row-action text-primary mr-2",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.duplicateToolstring(
                                                                      well,
                                                                      toolstring
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-copy",
                                                              }),
                                                              _vm._v(
                                                                " Duplicate\r\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "table-row-action text-danger",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.deleteToolstring(
                                                                      toolstring
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c("i", {
                                                                staticClass:
                                                                  "fas fa-trash-alt",
                                                              }),
                                                              _vm._v(
                                                                " Delete\r\n                                                    "
                                                              ),
                                                            ]
                                                          ),
                                                        ]),
                                                      ]),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.toolstringDataIsOpen(
                                                    well,
                                                    toolstring_index
                                                  )
                                                    ? _c("tr", [
                                                        _c(
                                                          "td",
                                                          {
                                                            attrs: {
                                                              colspan: "8",
                                                              valign: "top",
                                                            },
                                                          },
                                                          [
                                                            _c("iws-table", {
                                                              attrs: {
                                                                columns:
                                                                  _vm.toolstringConfigColumns,
                                                              },
                                                              scopedSlots:
                                                                _vm._u(
                                                                  [
                                                                    {
                                                                      key: "header_component",
                                                                      fn: function () {
                                                                        return [
                                                                          _vm._v(
                                                                            "\r\n                                                        Component\r\n                                                    "
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                    {
                                                                      key: "header_topShot",
                                                                      fn: function () {
                                                                        return [
                                                                          _vm._v(
                                                                            "\r\n                                                        CCL to Top Shot\r\n                                                    "
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                    {
                                                                      key: "header_description",
                                                                      fn: function () {
                                                                        return [
                                                                          _vm._v(
                                                                            "\r\n                                                        Description\r\n                                                    "
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                    {
                                                                      key: "header_spf",
                                                                      fn: function () {
                                                                        return [
                                                                          _vm._v(
                                                                            "\r\n                                                        SPF\r\n                                                    "
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                    {
                                                                      key: "header_phasing",
                                                                      fn: function () {
                                                                        return [
                                                                          _vm._v(
                                                                            "\r\n                                                        Phasing\r\n                                                    "
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                    {
                                                                      key: "header_chargeType",
                                                                      fn: function () {
                                                                        return [
                                                                          _vm._v(
                                                                            "\r\n                                                        Charge Type\r\n                                                    "
                                                                          ),
                                                                        ]
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                    {
                                                                      key: "header_null",
                                                                      fn: function () {
                                                                        return undefined
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                    {
                                                                      key: "body",
                                                                      fn: function () {
                                                                        return _vm._l(
                                                                          toolstring.toolstringDetails,
                                                                          function (
                                                                            toolDetail,
                                                                            index
                                                                          ) {
                                                                            return _c(
                                                                              "tr",
                                                                              {
                                                                                key: toolDetail.id,
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\r\n                                                                " +
                                                                                        _vm._s(
                                                                                          toolDetail.name
                                                                                        ) +
                                                                                        "\r\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\r\n                                                                " +
                                                                                        _vm._s(
                                                                                          toolDetail.distance
                                                                                        ) +
                                                                                        "\r\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\r\n                                                                " +
                                                                                        _vm._s(
                                                                                          toolDetail.description
                                                                                        ) +
                                                                                        "\r\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\r\n                                                                " +
                                                                                        _vm._s(
                                                                                          toolDetail.spf
                                                                                        ) +
                                                                                        "\r\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\r\n                                                                " +
                                                                                        _vm._s(
                                                                                          toolDetail.phasing
                                                                                        ) +
                                                                                        "\r\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "td",
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\r\n                                                                " +
                                                                                        _vm._s(
                                                                                          toolDetail.chargeType
                                                                                        ) +
                                                                                        "\r\n                                                            "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            )
                                                                          }
                                                                        )
                                                                      },
                                                                      proxy: true,
                                                                    },
                                                                  ],
                                                                  null,
                                                                  true
                                                                ),
                                                            }),
                                                          ],
                                                          1
                                                        ),
                                                      ])
                                                    : _vm._e(),
                                                ]
                                              }
                                            ),
                                          ]
                                        },
                                        proxy: true,
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                }),
                            _vm._v(" "),
                            _c("div", { attrs: { id: "error-body" } }, [
                              _vm.containsErrorToolstring(well)
                                ? _c(
                                    "div",
                                    [
                                      _c("iws-alert", {
                                        attrs: { type: "danger" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "body",
                                              fn: function () {
                                                return [
                                                  _vm._l(
                                                    well.toolstrings,
                                                    function (toolstring) {
                                                      return [
                                                        toolstring.hasError &&
                                                        toolstring.hasError !==
                                                          true
                                                          ? _c("div", [
                                                              _vm._v(
                                                                "\r\n                                                    " +
                                                                  _vm._s(
                                                                    toolstring.hasError
                                                                  ) +
                                                                  "\r\n                                                "
                                                              ),
                                                            ])
                                                          : _vm._e(),
                                                      ]
                                                    }
                                                  ),
                                                ]
                                              },
                                              proxy: true,
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "button",
                                {
                                  staticClass: "btn btn-outline-light",
                                  on: {
                                    click: function ($event) {
                                      return _vm.newToolstring(well)
                                    },
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "fas fa-plus-circle mr-1",
                                  }),
                                  _vm._v(
                                    "Add Toolstring\r\n                                "
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
            ]
          }),
          _vm._v(" "),
          _c("well-duplicate-modal", { ref: "wellDuplicateModal" }),
          _vm._v(" "),
          _c("toolstring-construction-modal", {
            ref: "toolstringConstructionModal",
            attrs: {
              "job-number": _vm.jobNumber,
              "well-number": _vm.wirelineSystemNumber,
            },
          }),
          _vm._v(" "),
          _c("toolstring-duplicate-modal", {
            ref: "toolstringDuplicateModal",
            attrs: { "job-number": _vm.jobNumber, wells: _vm.wells },
          }),
          _vm._v(" "),
          _c("toolstring-stage-modal", { ref: "toolstringStageModal" }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }