<template>
  <svg
    :style="{fill: spoolColor, stroke: spoolColor}"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1519.99 645"
  >
    <path
      d="M1380,197V787H540V197h840m30-30H510V817h900V167Z"
      transform="translate(-200.01 -167)"
    />
    <line class="cls-1" x1="311.99" y1="15" x2="12.99" y2="24" />
    <line class="cls-1" x1="15" y1="9" x2="15.99" y2="632" />
    <line class="cls-1" x1="15.99" y1="617" x2="310.99" y2="635" />
    <line class="cls-1" x1="1207.99" y1="15" x2="1509" y2="24" />
    <line class="cls-1" x1="1504.99" y1="9" x2="1504" y2="632" />
    <line class="cls-1" x1="1504" y1="616" x2="1206.99" y2="635" />
    <rect x="744.99" y="63" width="30" height="230" />
    <path
      d="M970,235V455H950V235h20m10-10H940V465h40V225Z"
      transform="translate(-200.01 -167)"
    />
    <path
      d="M960,520a50,50,0,1,1-50,50,50.06,50.06,0,0,1,50-50m0-50a100,100,0,1,0,100,100A100,100,0,0,0,960,470Z"
      transform="translate(-200.01 -167)"
    />
    <rect
      x="1139.87"
      y="567.71"
      width="30"
      height="230"
      transform="translate(-213.81 1174.52) rotate(-60)"
    />
    <path
      d="M1064.61,619.05l190.52,110-10,17.32-190.52-110,10-17.32M1061,605.39,1041,640l207.84,120,20-34.64L1061,605.39Z"
      transform="translate(-200.01 -167)"
    />
    <rect
      x="648.92"
      y="667.8"
      width="230"
      height="30"
      transform="translate(-439.06 306.44) rotate(-30)"
    />
    <path
      d="M854.18,619.14l10,17.32-190.52,110-10-17.32,190.52-110m3.66-13.66L650,725.48l20,34.64,207.84-120-20-34.64Z"
      transform="translate(-200.01 -167)"
    />
    <rect
      x="1052.97"
      y="492.07"
      width="30"
      height="30"
      transform="translate(-310.46 434.91) rotate(-30)"
    />
    <path
      d="M1071.63,493.41l10,17.32-17.32,10-10-17.32,17.32-10m3.66-13.66-34.64,20,20,34.64,34.64-20-20-34.64Z"
      transform="translate(-200.01 -167)"
    />
    <rect x="744.99" y="513" width="30" height="30" />
    <path
      d="M970,685v20H950V685h20m10-10H940v40h40V675Z"
      transform="translate(-200.01 -167)"
    />
    <rect
      x="835.97"
      y="492.07"
      width="30"
      height="30"
      transform="translate(-213.66 823.49) rotate(-60)"
    />
    <path
      d="M847.31,493.41l17.32,10-10,17.32-17.32-10,10-17.32m-3.66-13.66-20,34.64,34.64,20,20-34.64-34.64-20Z"
      transform="translate(-200.01 -167)"
    />
    <circle class="cls-2" cx="759.99" cy="326" r="15" />
    <circle class="cls-2" cx="759.99" cy="478" r="15" />
    <circle class="cls-2" cx="835.99" cy="409" r="15" />
    <circle class="cls-2" cx="685.99" cy="409" r="15" />
    <circle class="cls-2" cx="820.99" cy="355" r="15" />
    <circle class="cls-2" cx="812.99" cy="456" r="15" />
    <circle class="cls-2" cx="704.99" cy="355" r="15" />
    <circle class="cls-2" cx="704.99" cy="456" r="15" />
  </svg>
</template>


<style>
  .cls-1 {
      fill: none;
      stroke-miterlimit: 10;
      stroke-width: 30px;
  }
  .cls-2 {
      fill: #fff;
  }
</style>

<script>
export default {
  props: ["spoolColor"],
};
</script>
