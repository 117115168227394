var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card main-container mb-2", staticStyle: { width: "100%" } },
    [
      _c("div", { staticClass: "card-body" }, [
        _c("p", { staticClass: "card-title text-secondary" }, [
          _vm._v(_vm._s(_vm.dateUpdated) + " by " + _vm._s(_vm.user) + " "),
          _vm.isEditable
            ? _c(
                "span",
                {
                  staticClass: "link-txt-color pointer-cursor",
                  on: { click: () => _vm.onEditPress() },
                },
                [_vm._v(_vm._s(_vm.enableTextArea ? "Save" : "Edit"))]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        !_vm.enableTextArea
          ? _c("p", { staticClass: "card-text dark-text" }, [
              _vm._v(_vm._s(_vm.description)),
            ])
          : _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.description,
                  expression: "description",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "exampleFormControlTextarea1", rows: "3" },
              domProps: { value: _vm.description },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.description = $event.target.value
                },
              },
            }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }