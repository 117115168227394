var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "btn btn-primary m-2",
        on: {
          click: function ($event) {
            return _vm.openModal("addExistingModal")
          },
        },
      },
      [_vm._v("Add Existing")]
    ),
    _vm._v(" "),
    _c("table", { staticClass: "table table-sm text-dark" }, [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.systems, function (system, index) {
          return _c("tr", { key: "system" + system.id }, [
            _c("td", [
              _vm._v("\n          " + _vm._s(system.object_id) + "\n        "),
            ]),
            _vm._v(" "),
            _c("td", [
              _vm._v("\n          " + _vm._s(system.data.name) + "\n        "),
            ]),
            _vm._v(" "),
            _c("td", [
              system.data.hasOwnProperty("customer")
                ? _c("span", [_vm._v(_vm._s(system.data.customer.name))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "btn btn-primary btn-sm",
                  on: {
                    click: function ($event) {
                      return _vm.openSelectCustomer(index)
                    },
                  },
                },
                [_vm._v("Edit")]
              ),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                !system.data.hasOwnProperty("customer")
                  ? _c(
                      "span",
                      { staticClass: "badge badge-pill badge-warning" },
                      [_vm._v("Must specifiy customer")]
                    )
                  : [
                      system.data.hasOwnProperty("pads")
                        ? _c("span", [_vm._v(_vm._s(system.data.pads))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "btn btn-primary btn-sm",
                          on: {
                            click: function ($event) {
                              return _vm.openSelectPads(index)
                            },
                          },
                        },
                        [_vm._v("Edit")]
                      ),
                    ],
              ],
              2
            ),
          ])
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "addExistingModal",
          "data-backdrop": "static",
          "data-keyboard": "false",
          tabindex: "-1",
          "aria-labelledby": "staticBackdropLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(1),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body text-light" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("\n              Name\n            ")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newName,
                      expression: "newName",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.newName },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newName = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("\n              ID\n            ")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newId,
                      expression: "newId",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.newId },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newId = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _vm.errorMessage
                ? _c("div", { staticClass: "alert alert-warning" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errorMessage) +
                        "\n          "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.saveNew },
                },
                [_vm._v("Create")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "selectCustomerModal",
          "data-backdrop": "static",
          "data-keyboard": "false",
          tabindex: "-1",
          "aria-labelledby": "staticBackdropLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(2),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body text-light" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("\n              Customer\n            ")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCustomerIndex,
                        expression: "selectedCustomerIndex",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedCustomerIndex = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.customers, function (cust, index) {
                    return _c(
                      "option",
                      { key: "cust" + cust.id, domProps: { value: index } },
                      [_vm._v(_vm._s(cust.name))]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.errorMessage
                ? _c("div", { staticClass: "alert alert-warning" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errorMessage) +
                        "\n          "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.updateCustomer },
                },
                [_vm._v("Okay")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "selectPadsModal",
          "data-backdrop": "static",
          "data-keyboard": "false",
          tabindex: "-1",
          "aria-labelledby": "staticBackdropLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c("div", { staticClass: "modal-dialog" }, [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(3),
            _vm._v(" "),
            _c("div", { staticClass: "modal-body text-light" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("label", [_vm._v("\n              Field\n            ")]),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedFieldIndex,
                        expression: "selectedFieldIndex",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.selectedFieldIndex = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.getPads,
                      ],
                    },
                  },
                  _vm._l(_vm.fields, function (field, index) {
                    return _c(
                      "option",
                      { key: "field" + field.id, domProps: { value: index } },
                      [_vm._v(_vm._s(field.data.name))]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _vm.pads.length > 0
                ? _c("div", { staticClass: "form-group" }, [
                    _c("label", [_vm._v("\n              Pads\n            ")]),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedPads,
                            expression: "selectedPads",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: { multiple: "" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedPads = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.getPads,
                          ],
                        },
                      },
                      _vm._l(_vm.pads, function (pad) {
                        return _c(
                          "option",
                          { key: "pad" + pad.id, domProps: { value: pad.id } },
                          [_vm._v(_vm._s(pad.data.name))]
                        )
                      }),
                      0
                    ),
                  ])
                : _c("div", { staticClass: "alert alert-info" }, [
                    _vm._v("No pads found for selected field"),
                  ]),
              _vm._v(" "),
              _vm.errorMessage
                ? _c("div", { staticClass: "alert alert-warning" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.errorMessage) +
                        "\n          "
                    ),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-secondary",
                  attrs: { type: "button", "data-dismiss": "modal" },
                },
                [_vm._v("Cancel")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { type: "button" },
                  on: { click: _vm.updatePads },
                },
                [_vm._v("Okay")]
              ),
            ]),
          ]),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Id")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Name")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Customer")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Pads")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title text-light",
          attrs: { id: "addExistingModalLabel" },
        },
        [_vm._v("Add existing system")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title text-light",
          attrs: { id: "selectCustomerLabel" },
        },
        [_vm._v("Select customer for system")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title text-light",
          attrs: { id: "selectCustomerLabel" },
        },
        [_vm._v("Select pads")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }