<template>
<div id="view-control-container">
    <div id="camera-positioning">
        <div id="pan-tilt-control" :class="{ 'disabled': !tiltEnabled }">
            <div class="slice" id="up">
                <div class="slice-contents clickable" @click="ptzCommand('up')">
                    <i class="fas fa-chevron-up clickable"  />
                </div>
            </div>

            <div class="slice" id="left">
                <div class="slice-contents clickable" @click="ptzCommand('left')">
                    <i class="fas fa-chevron-up clickable"  />
                </div>
            </div>

            <div class="slice" id="right">
                <div class="slice-contents clickable" @click="ptzCommand('right')">
                    <i class="fas fa-chevron-up clickable"  />
                </div>
            </div>

            <div class="slice" id="down">
                <div class="slice-contents clickable" @click="ptzCommand('down')">
                    <i class="fas fa-chevron-up clickable"  />
                </div>
            </div>

            <span id="home" class="clickable" @click="goHome()">
                <i class="fas fa-home" />
            </span>
        </div>

        <iws-slider class="mt-2 full-width"
            :value.sync="cameraDetails.speedValue"
            label="Pan / Tilt Speed"
            :max="cameraDetails.speedMax"
            :min="cameraDetails.speedMin"
            :step="(cameraDetails.speedMax - cameraDetails.speedMin) / 20"
            :disabled="!tiltEnabled"
            @disabled-click="onDisabledClick('Please contact your admin', !!cameraDetails?.tiltEnabled ? 'Tilt is not enabled on this device' : 'You do not have access to use pan / tilt')"
            @change="postTiltSpeed"
        >
            <template #min>
                Min
            </template>
            <template #max>
                Max
            </template>
        </iws-slider>

        <iws-slider class="mt-4 full-width"
            :value.sync="zoomValue"
            label="Zoom"
            :max="cameraDetails.zoomMax"
            :min="cameraDetails.zoomMin"
            :step="(cameraDetails.zoomMax - cameraDetails.zoomMin) / 20"
            :disabled="!zoomEnabled"
            @disabled-click="onDisabledClick('Please contact your admin', !!cameraDetails?.zoomEnabled ? 'Zoom is not enabled on this device' : 'You do not have access to use zoom')"
            @change="postZoom"
        >
            <template #min>
                Min
            </template>
            <template #max>
                Max
            </template>
        </iws-slider>

        <iws-slider class="mt-4 full-width"
            :value.sync="cameraDetails.zoomSpeedValue"
            label="Zoom Speed"
            :max="cameraDetails.zoomSpeedMax"
            :min="cameraDetails.zoomSpeedMin"
            :step="(cameraDetails.zoomSpeedMax - cameraDetails.zoomSpeedMin) / 20"
            :disabled="!zoomEnabled"
            @disabled-click="onDisabledClick('Please contact your admin', !!cameraDetails?.zoomEnabled ? 'Zoom is not enabled on this device' : 'You do not have access to use zoom speed')"
            @change="postZoomSpeed"
        >
            <template #min>
                Min
            </template>
            <template #max>
                Max
            </template>
        </iws-slider>
    </div>

    <template v-if="presets !== null">
        <hr>

        <div style="font-size: 18px;" class="mb-2">
            Presets
        </div>

        <iws-select ref="presetSelect"
            :value="activePreset"
            :placeholder="presetPlaceholder"
            :options="presets"
            display-name="name"
            @change="gotoPreset"
            :disabled="_isNullOrEmpty(presets) || isFixed"
        >
            <template #option_text="{ data }">
                <template v-if="!_isNullOrEmpty(data?.option?.redzones)">&#128997;</template>
                {{ data?.option?.name }}
            </template>
        </iws-select>

        <div v-if="permissions?.edit" class="mt-1">
            <iws-button v-if="!presetIsActive" type="text-primary" text="Save as Preset" @click="addNewPreset = true" />

            <iws-button v-else type="text-danger" text="Delete" :click="() => deletePreset(activePreset)" />
        </div>
    </template>

    <template v-if="permissions?.admin">
        <hr>

        <div style="font-size: 18px;" class="mb-2">
            Redzone
        </div>

        <template v-if="!drawingMode">
            <iws-button
                text="Add Redzone"
                type="primary"
                :disabled="!camera?.primaryStream?.isAIEnabled"
                @click="onAddRedzoneClicked"
                @disabledClick="_toast({ title: 'Not Enabled', body: 'AI Redzone is not enabled on this stream', variant: 'danger' })"
            />

            <iws-button
                :text="`Manage (${activePreset?.redzones?.length || 0})`"
                type="light"
                @click="manageRedzoneModal = true"
                :disabled="!redzoneIsActive" 
                class="float-right"
            />
        </template>
        <template v-else>
            <iws-button
                text="Save Redzone"
                type="primary"
                @click="nameRedzoneModal = true"
                :disabled="!camera?.primaryStream?.isAIEnabled"
                @disabledClick="_toast({ title: 'Not Enabled', body: 'AI Redzone is not enabled on this stream', variant: 'danger' })"
            />

            <iws-button
                text="Cancel"
                type="light"
                @click="cancelRedzone"
                class="float-right"
            />
        </template>
    </template>

    <template>
        <hr>

        <div style="font-size: 18px;" class="mb-1">
            Patrol Mode
            <iws-button v-if="permissions?.admin" 
                type="text-primary" 
                text="Edit" 
                :disabled="isFixed"
                @click="showPatrolModal=true"
                @disabledClick="_toast({ title: 'Not Enabled', body: 'Stream position is fixed', variant: 'danger' })"
            />
        </div>

        <div style="font-size: 16px;" class="mb-2">
            Wait Time: {{patrolWaitTime}} seconds
        </div>

        <iws-switch
            :labels="['Off', 'On']" 
            :value="patrolMode"
            :key="patrolSwitch"
            class="mb-2"
            :onPress="setPatrolMode"
            :disabled="isFixed"
            @disabledClick="_toast({ title: 'Not Enabled', body: 'Stream position is fixed', variant: 'danger' })"
        />
    </template>

    <iws-modal 
        :showModal="showPatrolModal"
        title="Edit Patrol Mode"
        @close="cancelEditPatrolMode"
        @secondary-action="cancelEditPatrolMode"
        @primary-action="editPatrolMode"
        max-width="550px">

        <template #content>

            <div class="mb-4">
                Update wait time and patrol order, then click on "Save" to apply the changes.
            </div>

            <div style="font-size: 16px;" class="mb-2">
                Wait Time (seconds):
            </div>
            <iws-input :value.sync="newWaitTime" placeholder="seconds"/>

            <div class="mb-2 mt-4" style="font-size: 16px;">
                Patrol Order:
            </div>
            <draggable v-model="patrolList" group="patrol-list" handle=".draggable">
                <div v-for="(item, index) in patrolList" :key="item.name" class="patrol-item border border-secondary rounded m-2 p-2">
                    <div class="draggable">
                        <span>{{index+1}}. {{item.name}}</span>
                        <iws-button v-if="permissions?.admin" type="text-primary" text="Remove" @click="RemoveFromPatrolOrder(item)" class="patrol-button"/>
                    </div>
                </div>
            </draggable>

            <div class="mb-2 mt-4" style="font-size: 16px;">
                Presets Not In Patrol:
            </div>
            <div v-for="item in presetsNotInPatrol" class="border border-secondary rounded m-2 p-2">
                <span>{{item.name}}</span>
                <iws-button v-if="permissions?.admin" type="text-primary" text="Add" @click="addToPatrolOrder(item)" class="patrol-button"/>
            </div>

        </template>
    </iws-modal>

    <iws-modal 
        title="Save As Preset"
        :show-modal="addNewPreset"
        @close="addNewPreset = false"
        @secondary-action="addNewPreset = false"
        @primary-action="addPreset(newPresetName)"
        max-width="500px"
    >
        <template #content>
            <iws-input label="Preset Name" :value.sync="newPresetName" class="new-preset-name" />

            <div class="mt-3">
                This will create a new preset with the name you enter and will be available to access on the preset selection dropdown
            </div>
        </template>
    </iws-modal>

    <iws-modal 
        title="Manage Redzones"
        :show-modal="manageRedzoneModal"
        @close="manageRedzoneModal = false"
        @secondary-action="manageRedzoneModal = false"
        secondary-button-text="Close"
        :primary-button-visible="false"
        max-width="500px"
    >
        <template #content>
            <template v-if="!_isNullOrEmpty(activePreset?.redzones)">
                <div v-for="(redzone, index) in activePreset?.redzones" :class="{ 'mt-2': !!index }" class="manage-redzone-row">
                    <iws-input :value.sync="redzone.name" required />

                    <span>
                        <iws-button
                            icon="fas fa-save"
                            type="primary"
                            :click="() => renameRedzone(redzone)"
                        />

                        <iws-button
                            icon="fas fa-trash-alt"
                            type="outline-danger"
                            :click="() => deleteRedzone(redzone)"
                            class="ml-1"
                        />
                    </span>
                </div>

                <div class="mt-3">
                    Rename or delete the redzones assigned to this cameras preset
                </div>
            </template>
            <h5 v-else>
                No Redzones for this preset
            </h5>
        </template>
    </iws-modal>

    <iws-modal 
        :showModal="nameRedzoneModal"
        title="Name Redzone"
        @close="nameRedzoneModal = false"
        @secondary-action="nameRedzoneModal = false"
        :primary-button-click="saveRedzone"
        max-width="500px"
    >
        <template #content>
            <iws-input label="Redzone Name" :value.sync="newRedzoneName" class="new-preset-name" required />

            <div class="mt-3">
                This will create a new redzone for the current preset ('{{ activePreset?.name || 'Redzone' }}') with the name you enter.
            </div>
        </template>
    </iws-modal>
</div>
</template>

<script>
import { isNull } from 'lodash';
import GlobalFunctions from '../../GlobalFunctions.js';
import draggable from 'vuedraggable';
const { toast, iwsConfirm, flushToasts, isNullOrEmpty, isIterable, isFalsy } = GlobalFunctions;

export default {
    props: [
        'workOrderId',
        'device',
        'permissions',
        'camera',
        'cameraDetails',
        'drawingMode',
        'control',
        'vodSelected',
        'vodDropdownItems'
    ],

    data() {
        return {
            zoomValue: this.cameraDetails.zoomValue,
            addNewPreset: false,
            newPresetName: null,
            presets: null,
            patrolMode: false,
            patrolSwitch: 0,
            showPatrolModal: false,
            patrolWaitTime: null,
            newWaitTime: null,
            patrolList: [],
            presetsNotInPatrol: [],
            manageRedzoneModal: false,
            nameRedzoneModal: false,
            newRedzoneName: null
        }
    },

    components: {
        draggable,
    },

    computed: {
        isLive() {
            return this.vodSelected == 'Live' && this.$parent.secondsBehindLive <= 1;
        },

        zoomEnabled() {
            return this.isLive && this.control && !!this.cameraDetails?.zoomEnabled;
        },
        tiltEnabled() {
            return this.isLive && this.control && !!this.cameraDetails?.tiltEnabled;
        },

        isFixed() {
            return !(this.tiltEnabled || this.zoomEnabled);
        },

        presetIsActive() {
            return !isNullOrEmpty(this.presets) && !isFalsy(this.cameraDetails?.activePreset);
        },
        activePreset() {
            if (this.presetIsActive)
                return this.presets.find(_preset => _preset.token == this.cameraDetails.activePreset)
            return null;
        },
        presetPlaceholder() {
            if (isNullOrEmpty(this.presets))
                return 'Save important preset(s) below'
            if (!isFalsy(this.activePreset))
                return this.activePreset?.name || 'Select Preset'
            return 'Select Preset';
        },

        redzoneIsActive() {
            return this.presetIsActive && !isNullOrEmpty(this.activePreset?.redzones);
        }
    },

    methods: {
        _isNullOrEmpty(value) {
            return isNullOrEmpty(value);
        },
        _isIterable(value) {
            return isIterable(value);
        },
        _toast(value) {
            return toast(value);
        },

        onDisabledClick(title, body) {
            flushToasts();
            return toast({
                title,
                body,
                variant: 'danger'
            });
        },

        async postZoom() {
            if (!await this.confirmDisablingRedzone()) {
                this.zoomValue = this.cameraDetails.zoomValue;    // Reset the display back to the redzone preset
                return toast({ title: 'Cancelled action', variant: 'info' });
            }

            const { zoomValue } = this;
            this.clearActivePreset();
            //Turn off patrol mode on zoom
            if(this.patrolMode){
                this.togglePatrolMode();
            }
            return axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/zoom`, { zoomValue });
        },

        postTiltSpeed() {
            const { speedValue } = this.cameraDetails;
            return axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/speed`, { speedValue });
        },
        postZoomSpeed() {
            const { zoomSpeedValue } = this.cameraDetails;
            return axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/zoomSpeed`, { zoomSpeedValue });
        },

        onVodChange(vod) {
            this.$parent.onVodSelectionChanged(vod)
        },

        async ptzCommand(command) {
            if (!this.isLive)
                return this.onDisabledClick('Not Available', 'Tilt is only available when viewing livestream');
            if (!this.tiltEnabled)
                return this.onDisabledClick('Not Available', 'Tilt is not available on this device');
            if (!this.control)
                return this.onDisabledClick('Please contact your admin', 'You do not have access to use tilt');
            if (!await this.confirmDisablingRedzone())
                return toast({ title: 'Cancelled action', variant: 'info' });

            // Certain criteria must be met to use either grouping of commands
            if (command == 'zoomIn' || command == 'zoomOut') {
                // Zoom is no longer run through this function
                return;
            } else if (command == 'up' || command == 'down' || command == 'left' || command == 'right') {
                if (!this.tiltEnabled)
                    return;
            }

            try {
                this.clearActivePreset();
                //Turn off patrol mode on ptz command
                if(this.patrolMode){
                    this.togglePatrolMode();
                }
                return axios.post(`/cameras/${this.workOrderId}/ptz`, {
                    deviceId: this.device.deviceId,
                    cameraId: this.camera.cameraConfigId,
                    command
                });
            } catch (err) {
                toast({
                    title: 'Error issuing command',
                    body: err.toString(),
                    variant: 'danger'
                });
            }
        },
        onKeyPress(e) {            
            // Disable key listeners if modals are open
            // most modals include text inputs and these specials keys may be needed for input
            if (this.showPatrolModal || this.addNewPreset)
                return;
            
            if (e.key == 'ArrowUp') {
                this.ptzCommand('up');
            } else if (e.key == 'ArrowDown') {
                this.ptzCommand('down');
            } else if (e.key == 'ArrowLeft') {
                this.ptzCommand('left');
            } else if (e.key == 'ArrowRight') {
                this.ptzCommand('right');
            } else if (e.key == '=' || e.key == '+') {
                // Abort if the feature is not available
                if (!this.zoomEnabled)
                    return;

                this.zoomValue += (this.cameraDetails.zoomMax - this.cameraDetails.zoomMin) / 5;
                this.zoomValue = Math.min(this.zoomValue, this.cameraDetails.zoomMax)
                this.postZoom();
            } else if (e.key == '-' || e.key == '_') {
                // Abort if the feature is not available
                if (!this.zoomEnabled)
                    return;

                this.zoomValue -= (this.cameraDetails.zoomMax - this.cameraDetails.zoomMin) / 5;
                this.zoomValue = Math.max(this.zoomValue, this.cameraDetails.zoomMin)
                this.postZoom();
            }
        },

        async goHome() {
            if (!this.isLive)
                return this.onDisabledClick('Not Available', 'Home is only available when viewing livestream');
            if (!this.tiltEnabled)
                return this.onDisabledClick('Please contact your admin', 'You do not have access to use home');
            if (!await this.confirmDisablingRedzone())
                return toast({ title: 'Cancelled action', variant: 'info' });

            return axios.post(`/cameras/devices/${this.device.deviceId}/${this.camera.cameraConfigId}/home`).then(_res => {
                this.clearActivePreset();
                //Turn off patrol mode on home cammand
                if(this.patrolMode){
                    this.togglePatrolMode();
                }
            });
        },

        clearActivePreset() {
            this.$emit('clearCanvas');
            this.cameraDetails.activePreset = null;
        },
        fetchPresets() {
            if (this.zoomEnabled || this.tiltEnabled)
                return axios.get(`/cameras/${this.device.deviceId}/presets/${this.camera.cameraConfigId}`).then(_res => {
                    if  (_res?.data?.error) {
                        toast({
                            title: 'Error fetching presets',
                            body: _res?.data?.message,
                            variant: 'danger'
                        });
                    }
                    this.presets = _res?.data?.map(_preset => {
                                        return {
                                            ..._preset,
                                            redzones: this.cameraDetails?.cameraRedZones?.filter(_redzone => _redzone.presetToken == _preset.token)
                                        }
                                    }) || [];
                    
                    this.updatePatrolList();
                    return this.presets;
                });
        },
        addPreset(name) {
            if (isFalsy(name))
                return toast({
                    title: 'Preset name is required',
                    variant: 'danger'
                });

            // Check for duplicates
            let duplicateName = false;
            if(this.presets){
                for(let i=0; i<this.presets.length;i++){
                    if(name==this.presets[i].name){
                        duplicateName = true;
                    }
                }
            }
            if (duplicateName)
                return toast({
                    title: 'Preset name already in use, please input a different preset name',
                    variant: 'danger'
                });
            
            return axios.post(`/cameras/${this.device.deviceId}/presets/${this.camera.cameraConfigId}?name=${name}&patrolOrder=${this.patrolList.length+1}`).then(_res => {
                this.newPresetName = null;
                this.addNewPreset = false;
                return this.fetchPresets().then(_ => {
                    // Make sure the backend actually registers the new preset as the active one
                    // Ideally this wouldn't be needed
                    return this.gotoPreset(this.presets[this.presets?.length-1]);
                });
            });
        },
        deletePreset(preset) {
            return iwsConfirm({
                title: 'Are you sure?',
                body: `Are you sure you want to delete preset: '${preset?.name}'?`,
                confirmColour: 'danger',
                width: '400px'
            }).then(async _answer => {
                if (_answer === true) {
                    // If the preset was tied to the current redzone, redzone also needs to be deleted and clearly communicated to the user
                    const matchedRedzones = this.cameraDetails?.cameraRedZones?.filter(_redzone => _redzone?.presetToken == preset.token);
                    if (!isNullOrEmpty(matchedRedzones))
                        await Promise.all(
                            matchedRedzones.map(_redzone => axios.delete(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/red-zones/${_redzone.id}`))
                        ).then(() => {
                            return iwsConfirm({
                                title: 'Redzone deleted',
                                body: 'This preset was associated with one or more redzones.<br>The preset and the redzone(s) were both deleted and it is recommended you reset the redzone with a new preset',
                                allowReject: false,
                                confirmText: 'Ok'
                            });
                        });

                    return axios.delete(`/cameras/${this.device.deviceId}/presets/${this.camera.cameraConfigId}?token=${preset.token}`).then(_res => {
                        this.$emit('update:cameraDetails', {
                            ..._res.data?.cameraConfiguration,
                            activePreset: null
                        });
                        return this.fetchPresets();
                    });
                }
            })
        },
        async gotoPreset(preset) {
            if (!await this.confirmDisablingRedzone()) {
                this.$refs.presetSelect.matchDisplay();     // Reset the display back to the redzone preset
                return toast({ title: 'Cancelled action', variant: 'info' });
            }
            //Turn off patrol mode before loading preset
            if(this.patrolMode){
                this.togglePatrolMode();
            }
            this.$emit('clearCanvas');
            return axios.post(`/cameras/${this.device.deviceId}/presets/${this.camera.cameraConfigId}/gotopreset`, { token: preset.token }).then(_res => {
                if (!isFalsy(_res.data?.cameraConfiguration)) {
                    this.zoomValue = _res.data?.cameraConfiguration.zoomValue;
                    this.$emit('update:cameraDetails', _res.data?.cameraConfiguration);
                    this.$emit('drawCanvas');
                }
            });
        },
        fetchPatrolModeSettings(){
            return axios.get(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}`).then(_res => {
                    if  (_res?.data?.error) {
                        toast({
                            title: 'Error fetching patrol wait time',
                            body: _res?.data?.message,
                            variant: 'danger'
                        });
                    }
                    this.patrolWaitTime = this.newWaitTime = _res?.data.patrolWaitTime;
                    this.patrolMode = _res?.data.isPatrolling;
                    this.patrolSwitch += 1;
                    //Clear preset if patrolling on page load
                    if(this.patrolMode){
                        this.clearActivePreset();
                    }
                });
        },
        setPatrolMode(){
            this.clearActivePreset();
            this.togglePatrolMode();
        },
        togglePatrolMode(){
            this.patrolMode = !this.patrolMode;
            this.patrolSwitch += 1;
            axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/patrol?isPatrolling=${this.patrolMode}`);
        },
        cancelEditPatrolMode(){
            this.newWaitTime = this.patrolWaitTime;
            this.showPatrolModal = false;
        },
        editPatrolMode(){
            //Turn off patrol mode before posting changes
            if(this.patrolMode){
                this.setPatrolMode();
            }
            this.updatePatrolWaitTime();
            this.updatePatrolSettings();
            this.showPatrolModal = false;
        },
        updatePatrolWaitTime(){
            if(Number.isInteger(parseFloat(this.newWaitTime)) && parseFloat(this.newWaitTime) > 0){
                this.patrolWaitTime = this.newWaitTime;
                return axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/patrolTime?patrolWaitTime=${this.newWaitTime}`).then(_res => {
                    if  (_res?.data?.error) {
                        toast({
                            title: 'Error updating patrol wait time',
                            body: _res?.data?.message,
                            variant: 'danger'
                        });
                    }
                });
            } else if (this.newWaitTime){
                this.newWaitTime = this.patrolWaitTime;
                return toast({title: 'Invalid wait time, please enter a positive integer', variant: 'danger'});
            } 
        },
        updatePatrolSettings(){
            this.patrolList.forEach((preset,index) => {
                axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/updatePatrolSettings?name=${preset.name}&patrolOrder=${index+1}&includeInPatrol=true&token=${preset.token}`);
            })
            this.presetsNotInPatrol.forEach((preset) => {
                axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/updatePatrolSettings?name=${preset.name}&patrolOrder=-1&includeInPatrol=false&token=${preset.token}`);
            })
            this.fetchPresets();
        },
        updatePatrolList(){
            this.patrolList=[];
            this.presetsNotInPatrol=[];
            if(this.presets){
                for(var i=0;i<this.presets.length;i++){
                    if(this.presets[i].includeInPatrol){
                        this.patrolList.push(this.presets[i]);
                    } else {
                        this.presetsNotInPatrol.push(this.presets[i]);
                    }
                }
                this.patrolList.sort(function(a,b) {
                    return a.patrolOrder - b.patrolOrder
                });
            }
        },
        addToPatrolOrder(item){
            this.presets.forEach((preset,index) => {
                if(item.name==preset.name){
                    this.presets[index].includeInPatrol = true;
                };
            });
            this.updatePatrolList();
        },
        RemoveFromPatrolOrder(item){
            this.presets.forEach((preset,index) => {
                if(item.name==preset.name){
                    this.presets[index].includeInPatrol = false;
                }
            });
            this.updatePatrolList();
        },

        saveRedzone() {
            return this.$parent.saveRedzone(this.newRedzoneName).then(_ => {
                this.nameRedzoneModal = false;
                this.newRedzoneName = null;
                toast({
                    title: 'Redzone added',
                    body: 'New redzone will appear momentarily',
                    variant: 'success'
                });

                return this.fetchPresets();
            })
        },
        renameRedzone(redzone) {
            return axios.post(`/cameras/devices/${this.device.deviceId}/cameras/${this.camera.cameraConfigId}/red-zones`, {
                cameraId: this.camera.cameraConfigId, 
                presetToken: this.cameraDetails.activePreset, 
                redZonePoints: redzone.redZonePoints,
                redZoneGlobalId: redzone.globalId,
                name: redzone.name
            }).then(() => {
                toast({
                    title: 'Changes Saved',
                    variant: 'success'
                });
            });
        },
        deleteRedzone(redzone) {
            return iwsConfirm({
                title: 'Are you sure?',
                body: `Are you sure you want to delete '${redzone?.name}'? This can\'t be undone!`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer === true)
                    return this.$parent.deleteRedzone(redzone).then(_ => {
                        toast({
                            title: 'Redzone deleted',
                            body: 'Redzone will disappear momentarily',
                            variant: 'success'
                        });

                        return this.fetchPresets();
                    });
            });
        },
        confirmDisablingRedzone() {
            // If not active, no need to confirm
            if (!this.redzoneIsActive)
                return true;
            return iwsConfirm({
                title: 'Disable redzone?',
                body: 'Modifying the position of the camera will disable the current redzone(s) which may impact redzone reporting & ' +
                        'real-time alerts.<br>Please note that after moving the camera you can re-enable the redzone using the Preset menu. '+
                        'Do you want to continue?',
                width: '470px'
            })
        },
        gotoRedzone() {
            if (!isNull(this.cameraDetails.cameraRedZones)) {
                const { presetToken } = this.cameraDetails.cameraRedZones[0];
                const preset = this.presets.find(_preset => _preset.token == presetToken);

                if (!isFalsy(preset)) {
                    this.gotoPreset(preset);
                } else {
                    return toast({
                        title: 'Failed to go to redzone',
                        body: 'Could not find preset for given redzone',
                        variant: 'danger'
                    });
                }
            } else {
                return toast({
                    title: 'Failed to go to redzone',
                    body: 'No existing redzone on camera',
                    variant: 'danger'
                });
            }
        },
        async onAddRedzoneClicked() {
            this.$emit('update:drawingMode', true);
            return toast({
                title: 'Drawing Redzone',
                body: 'Begin clicking on the camera viewer to draw your redzone (at least 3 points are required)',
                variant: 'info'
            });
        },
        cancelRedzone() {
            this.$emit('clearCanvas');
            this.$emit('drawCanvas');
            this.$parent.drawnPoints = [];
            this.$emit('update:drawingMode', false);
        }
    },

    mounted() {
        this.fetchPresets();
        this.fetchPatrolModeSettings();
        window.addEventListener('keydown', this.onKeyPress);
    },
    beforeDestroy() {
        window.removeEventListener('keydown', this.onKeyPress);
    }
}
</script>

<style scoped>
    #view-control-container {
        width: 325px !important;
        padding: 10px 10px;
        margin-left: 10px;
        display: inline-block;

        position: absolute;
        top: 0px;
        
        border-radius: 10px;
        height: calc(100% - 0px) !important;
        background-color: rgba(16, 24, 40, .30);

        height: 100%;
        overflow-y: auto;
    }

    hr {
        margin-top: 2rem;
        margin-bottom: 2rem;
        background-color: var(--form-color);
        opacity: 0.25;
    }

    #camera-positioning {
        background: #101828;
        border: 1px solid #1D2939;
        border-radius: 5px;

        padding: 10px;
        width: 100%;
    }

    #pan-tilt-control {
        background-color: #1D2939;   
        border-radius: 50%;
        height: 110px;
        width: 110px;
        margin: auto;
        position: relative;
    }
    #pan-tilt-control.disabled i {
        color: grey !important;
    }

    .slice {
        overflow: hidden;
        position: absolute;
        width: 50%;
        height: 50%;
        left: 50%;
        transform-origin: 0% 100%; 
    }
    .slice-contents {
        position: absolute;
        left: -100%;
        width: 200%; height: 200%;
        border-radius: 50%;
    }
    .slice .slice-contents:hover,
    #pan-tilt-control #home:hover { 
        background: #475467;
        color: white;
    }

    #pan-tilt-control #up {
        transform: rotate(-45deg);
    }
    #pan-tilt-control #right {
        transform: rotate(45deg);
    }
    #pan-tilt-control #down {
        transform: rotate(135deg);
    }
    #pan-tilt-control #left {
        transform: rotate(225deg);
    }

    #pan-tilt-control .slice i {
        position: absolute;
        top: 15px;
        left: 80px;
        transform: rotate(45deg);
    }

    #pan-tilt-control #home {
        position: absolute;
        left: calc(50% - 17px);
        top: calc(50% - 18px);

        background-color: white;   
        color: black;
        border-radius: 50%;
        height: 36px;
        width: 36px;
        padding: 6px 9px;
    }
    
    .patrol-item {
        background: #343A40;
    }
    .patrol-item span {
        font-size: 14px;
    }
    .patrol-item:hover {
        background: #2E353D !important;
    }
    .draggable{
        cursor: grab;
    }
    .patrol-button{
        float: right;
    }

    .manage-redzone-row {
        height: 38px;
    }
    .manage-redzone-row>div {
        display: inline-block;
        width: calc(100% - 100px);
        margin-right: 8px;
    }
    .manage-redzone-row>span {
        position: relative;
        top: -3px;
    }
</style>