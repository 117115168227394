<template>
    <div id="modalBackground">
        <div id="modalContainer">
            <div class="modal-dialog text-secondary">
                <div class="modal-content bg-dark">
                    <div class="modal-header bg-dark">
                        <h4>Authentication Error</h4>
                    </div>
                    <div class="modal-body bg-dark">
                        Please log in to continue
                    </div>
                    <div class="modal-footer bg-dark">
                        <button type="button" class="btn btn-primary btn-block" v-on:click="logout" data-dismiss="modal">Login</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            logout : function() {
                window.location.href = '/login'; 
            }
        }
    }
</script>

<style>
#modalBackground { 
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    left: 0; 
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 199;
}

#modalContainer{
    position: absolute;
    z-index: 200;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}
</style>