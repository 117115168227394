var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      "primary-button-text": "Save",
      showModal: _vm.showBaseModal,
      "max-width": "1200px",
      "max-height": "95vh",
      primaryButtonClick: _vm.modalDetails.primaryAction,
      "primary-button-disabled":
        !_vm.hasPermission || !_vm.modalDetails.primaryEnabled,
      "close-on-clickaway": false,
      id: "TimeSeriesChart",
    },
    on: {
      close: _vm.cancelAction,
      "secondary-action": _vm.modalDetails.secondaryAction,
    },
    scopedSlots: _vm._u(
      [
        {
          key: "title",
          fn: function () {
            return [
              _vm._v(
                "\r\n        " +
                  _vm._s(_vm.modalDetails.title) +
                  " " +
                  _vm._s(
                    !_vm._isFalsy(_vm.selectedConfig?.templateName)
                      ? `(${_vm.selectedConfig?.templateName})`
                      : ""
                  ) +
                  "\r\n    "
              ),
            ]
          },
          proxy: true,
        },
        !!_vm.data
          ? {
              key: "content",
              fn: function () {
                return [
                  !_vm._isNullOrEmpty(_vm.measurementUnits)
                    ? _c(
                        "div",
                        {
                          staticClass: "row",
                          attrs: { id: "measurementUnitsModal" },
                        },
                        _vm._l(_vm.measurementUnits, function (unit) {
                          return _c(
                            "div",
                            { staticClass: "col-xs-12 col-sm-6 col-lg-4" },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "measurement-unit-container clickable",
                                  class: {
                                    "selected-measurement":
                                      !_vm._isFalsy(
                                        _vm.selectedMeasurement
                                          ?.measurement_unit_id
                                      ) &&
                                      _vm.selectedMeasurement
                                        ?.measurement_unit_id ==
                                        unit.measurement_unit_id,
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.selectedMeasurement = unit
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "measurement-title" },
                                    [
                                      _vm._v(
                                        "\r\n                        " +
                                          _vm._s(unit?.measurement_unit_name) +
                                          "\r\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "measurement-body" },
                                    [
                                      _c("b", [_vm._v("Abbreviation")]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(unit?.abbreviation || "N/A") +
                                          "\r\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "measurement-body" },
                                    [
                                      _c("b", [_vm._v("Decimal Places")]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(
                                            unit?.decimal_places || "N/A"
                                          ) +
                                          "\r\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "measurement-body" },
                                    [
                                      _c("b", [_vm._v("Color")]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(unit?.color || "N/A") +
                                          "\r\n                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    { staticClass: "measurement-body" },
                                    [
                                      _c("b", [_vm._v("defaultaxis")]),
                                      _vm._v(
                                        ": " +
                                          _vm._s(
                                            unit?.defaultaxis?.label || "N/A"
                                          ) +
                                          "\r\n                    "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    : !_vm._isFalsy(_vm.selectedAxis)
                    ? _c("iws-form", {
                        ref: "custom_form",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  !_vm._isFalsy(_vm.selectedAxis)
                                    ? [
                                        _c("iws-input", {
                                          attrs: {
                                            value: _vm.selectedAxis.label,
                                            label: "Name",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "label",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-input", {
                                          attrs: {
                                            value: _vm.selectedAxis.min,
                                            label: "Minimum",
                                            type: "number",
                                            max: _vm.selectedAxis.max,
                                            required: "",
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "min",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-input", {
                                          attrs: {
                                            value: _vm.selectedAxis.max,
                                            label: "Maximum",
                                            type: "number",
                                            min: _vm.selectedAxis.min,
                                            required: "",
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "max",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-input", {
                                          attrs: {
                                            value: _vm.selectedAxis.ticks,
                                            label: "# of Tick Intervals",
                                            type: "number",
                                            min: 2,
                                            required: "",
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "ticks",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm._v(
                                            "\r\n                        (Default to 10 tick intervals, minimum: 2)\r\n                    "
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("iws-select", {
                                          attrs: {
                                            label: "Position",
                                            value: _vm.selectedAxis.position,
                                            options: [
                                              { label: "Left", value: "left" },
                                              {
                                                label: "Right",
                                                value: "right",
                                              },
                                            ],
                                            "display-name": "label",
                                            "value-name": "value",
                                            required: "",
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "position",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          attrs: {
                                            label: "Primary Axis",
                                            value:
                                              _vm.selectedAxis.isPrimaryAxis,
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "isPrimaryAxis",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          attrs: {
                                            label: "Align To Primary Axis",
                                            value:
                                              _vm.selectedAxis
                                                .alignToPrimaryAxis,
                                            disabled:
                                              _vm.selectedAxis.isPrimaryAxis ||
                                              !_vm.hasPermission,
                                            "form-spacing": "",
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "alignToPrimaryAxis",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          attrs: {
                                            label: "Hide Axis",
                                            value: _vm.selectedAxis.hideYAxis,
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "hideYAxis",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          attrs: {
                                            label: "Logarithmic Scale",
                                            value: _vm.selectedAxis.logarithmic,
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "logarithmic",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-colour-picker", {
                                          attrs: {
                                            label: "Axis Color",
                                            value: _vm.selectedAxis.color,
                                            "form-spacing": "",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "color",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          staticClass: "display-inline-block",
                                          attrs: {
                                            label: "Display Gridlines",
                                            value:
                                              _vm.selectedAxis.displayGridlines,
                                            disabled: !_vm.hasPermission,
                                            "form-spacing": "",
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedAxis,
                                                "displayGridlines",
                                                $event
                                              )
                                            },
                                            change: function ($event) {
                                              _vm.selectedAxis.customGridlinesColor =
                                                _vm.selectedAxis
                                                  .displayGridlines
                                                  ? _vm.selectedAxis
                                                      .customGridlinesColor ||
                                                    "#FFFFFF"
                                                  : null
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.selectedAxis.displayGridlines
                                          ? _c("iws-colour-picker", {
                                              staticClass:
                                                "display-inline-block ml-2",
                                              staticStyle: { top: "5px" },
                                              attrs: {
                                                value:
                                                  _vm.selectedAxis
                                                    .customGridlinesColor,
                                                required: "",
                                                disabled: !_vm.hasPermission,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.selectedAxis,
                                                    "customGridlinesColor",
                                                    $event
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          686886390
                        ),
                      })
                    : !_vm._isFalsy(_vm.selectedChartItem)
                    ? _c("iws-form", {
                        ref: "custom_form",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  !_vm._isFalsy(_vm.selectedChartItem)
                                    ? [
                                        _c("iws-select", {
                                          ref: "sourceTagName",
                                          attrs: {
                                            label: "Source Tag Name",
                                            value:
                                              _vm.selectedChartItem.tagName,
                                            options: _vm.filteredTags,
                                            "display-name": "name",
                                            "value-name": "name",
                                            disabled: !_vm.hasPermission,
                                            required: "",
                                          },
                                          on: { change: _vm.onTagChanged },
                                        }),
                                        _vm._v(" "),
                                        _vm.getWell(_vm.selectedChartItem)
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "row justify-content-center align-items-center",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _vm.isWellheadTag(
                                                      _vm.selectedChartItem
                                                        .tagName
                                                    ) &&
                                                    !_vm._isFalsy(
                                                      _vm.getWellheadPrefixForTag(
                                                        _vm.selectedChartItem
                                                          .tagName
                                                      )
                                                    )
                                                      ? _c(
                                                          "div",
                                                          [
                                                            _c("iws-input", {
                                                              attrs: {
                                                                value:
                                                                  _vm.getWellheadPrefixForTag(
                                                                    _vm
                                                                      .selectedChartItem
                                                                      .tagName
                                                                  ),
                                                                label: "Prefix",
                                                                "form-spacing":
                                                                  "",
                                                                disabled: "",
                                                              },
                                                            }),
                                                          ],
                                                          1
                                                        )
                                                      : _c("iws-input", {
                                                          attrs: {
                                                            value:
                                                              _vm
                                                                .selectedChartItem
                                                                .prefixName,
                                                            label: "Prefix",
                                                            placeholder:
                                                              "Provide a prefix name",
                                                            "form-spacing": "",
                                                            disabled:
                                                              !_vm.hasPermission,
                                                          },
                                                          on: {
                                                            "update:value":
                                                              function (
                                                                $event
                                                              ) {
                                                                return _vm.$set(
                                                                  _vm.selectedChartItem,
                                                                  "prefixName",
                                                                  $event
                                                                )
                                                              },
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col-3" },
                                                  [
                                                    _c("iws-input", {
                                                      attrs: {
                                                        value:
                                                          _vm.getWellheadSuffixTitleForTag(
                                                            _vm
                                                              .selectedChartItem
                                                              .tagName
                                                          ),
                                                        label: "Suffix Title",
                                                        "form-spacing": "",
                                                        disabled: "",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "div",
                                                  { staticClass: "col" },
                                                  [
                                                    _c("iws-input", {
                                                      attrs: {
                                                        value:
                                                          _vm.selectedChartItem
                                                            .suffixName,
                                                        label: "Suffix",
                                                        placeholder:
                                                          "Provide a suffix name",
                                                        "form-spacing": "",
                                                        disabled:
                                                          !_vm.hasPermission,
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.selectedChartItem,
                                                              "suffixName",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _c("iws-input", {
                                              attrs: {
                                                value:
                                                  _vm.selectedChartItem
                                                    .friendlyName,
                                                label: "Friendly Name",
                                                placeholder:
                                                  "Provide a friendly name",
                                                "form-spacing": "",
                                                required: "",
                                                disabled: !_vm.hasPermission,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.selectedChartItem,
                                                    "friendlyName",
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                        _vm._v(" "),
                                        _c("iws-input", {
                                          attrs: {
                                            value: _vm.selectedChartItem.unit,
                                            label: "Unit",
                                            "form-spacing": "",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "unit",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-input", {
                                          attrs: {
                                            value:
                                              _vm.selectedChartItem
                                                .timeoutThreshold,
                                            label:
                                              "Tag Timeout Threshold (in seconds)",
                                            hint: "Enter 0 to disable timeout",
                                            type: "number",
                                            min: 0,
                                            "form-spacing": "",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "timeoutThreshold",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-select", {
                                          ref: "chartAxisSelect",
                                          staticClass: "display-inline-block",
                                          staticStyle: {
                                            width: "calc(100% - 115px)",
                                          },
                                          attrs: {
                                            label: "Chart Axis",
                                            value:
                                              _vm.selectedChartItem
                                                .chartYAxis_key,
                                            options:
                                              _vm.selectedConfig.data
                                                .chartYAxes,
                                            "display-name": "label",
                                            "value-name": "key",
                                            "form-spacing": "",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "chartYAxis_key",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              id: "axis-action-container",
                                            },
                                          },
                                          [
                                            _c("iws-button", {
                                              staticClass: "mr-1",
                                              attrs: {
                                                type: "outline-primary",
                                                icon: "fas fa-plus",
                                                size: "small",
                                                disabled: !_vm.hasPermission,
                                                rounded: "",
                                              },
                                              on: {
                                                click: ($event) => {
                                                  $event.preventDefault()
                                                  _vm.addAxis()
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("iws-button", {
                                              attrs: {
                                                icon: `fas ${
                                                  !_vm.hasPermission
                                                    ? "fa-eye"
                                                    : "fa-pencil-alt"
                                                }`,
                                                type: "text-primary",
                                                disabled: _vm._isFalsy(
                                                  _vm.selectedChartItem
                                                    ?.chartYAxis_key
                                                ),
                                              },
                                              on: {
                                                click: ($event) => {
                                                  $event.preventDefault()
                                                  _vm.editAxis(
                                                    _vm.findFullSelectedAxis()
                                                  )
                                                },
                                              },
                                            }),
                                            _vm._v(" "),
                                            _c("iws-button", {
                                              attrs: {
                                                icon: "fas fa-trash-alt",
                                                type: "text-danger",
                                                disabled:
                                                  _vm._isFalsy(
                                                    _vm.selectedChartItem
                                                      ?.chartYAxis_key
                                                  ) || !_vm.hasPermission,
                                              },
                                              on: {
                                                click: ($event) => {
                                                  $event.preventDefault()
                                                  _vm.deleteYAxis()
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("iws-input", {
                                          attrs: {
                                            value:
                                              _vm.selectedChartItem
                                                .decimalPrecision,
                                            label: "Decimal Precision",
                                            type: "number",
                                            min: 0,
                                            max: 9,
                                            "form-spacing": "",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "decimalPrecision",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-input", {
                                          attrs: {
                                            value:
                                              _vm.selectedChartItem.lineWidth,
                                            label: "Line Width",
                                            type: "number",
                                            min: 0,
                                            max: 5,
                                            "form-spacing": "",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "lineWidth",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-colour-picker", {
                                          attrs: {
                                            label: "Line Color",
                                            value: _vm.selectedChartItem.color,
                                            "form-spacing": "",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "color",
                                                $event
                                              )
                                            },
                                            change: function ($event) {
                                              _vm.selectedChartItem.customLineColor = true
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          staticClass: "display-inline-block",
                                          attrs: {
                                            label: "Custom background color",
                                            value: _vm.chartItemBgColorEnabled,
                                            disabled: !_vm.hasPermission,
                                            "form-spacing": "",
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              _vm.chartItemBgColorEnabled =
                                                $event
                                            },
                                            change: function ($event) {
                                              _vm.selectedChartItem.bgColor =
                                                _vm.chartItemBgColorEnabled
                                                  ? _vm.selectedChartItem
                                                      .bgColor || "#FFFFFF"
                                                  : null
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _vm.chartItemBgColorEnabled
                                          ? _c("iws-colour-picker", {
                                              staticClass:
                                                "display-inline-block ml-2",
                                              staticStyle: { top: "5px" },
                                              attrs: {
                                                value:
                                                  _vm.selectedChartItem.bgColor,
                                                required: "",
                                                disabled: !_vm.hasPermission,
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.selectedChartItem,
                                                    "bgColor",
                                                    $event
                                                  )
                                                },
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          attrs: {
                                            label: "Show Data Points",
                                            value:
                                              _vm.selectedChartItem
                                                .showDataPoints,
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "showDataPoints",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          attrs: {
                                            label: "Selected By Default",
                                            value:
                                              _vm.selectedChartItem
                                                .selectedByDefault,
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "selectedByDefault",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("iws-checkbox", {
                                          attrs: {
                                            label: "Static Tag Data Source",
                                            value:
                                              _vm.selectedChartItem
                                                .staticSource,
                                            "form-spacing": "",
                                            disabled: !_vm.hasPermission,
                                            "enable-click-label": "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedChartItem,
                                                "staticSource",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3044150817
                        ),
                      })
                    : !_vm._isFalsy(_vm.selectedSection)
                    ? _c("iws-form", {
                        ref: "custom_form",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  !_vm._isFalsy(_vm.selectedSection)
                                    ? [
                                        _c("iws-input", {
                                          attrs: {
                                            value: _vm.selectedSection.name,
                                            label: "Name",
                                            required: "",
                                            disabled: !_vm.hasPermission,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.selectedSection,
                                                "name",
                                                $event
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c("div", { staticClass: "mt-4" }, [
                                          _c(
                                            "b",
                                            { staticClass: "container-label" },
                                            [_vm._v("Data Sources")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "float-buttons-right",
                                            },
                                            [
                                              _c("iws-button", {
                                                attrs: {
                                                  type: "outline-light",
                                                  "pre-icon": "fas fa-plus",
                                                  icon: "fas fa-plus",
                                                  click: _vm.addChartItem,
                                                  disabled: !_vm.hasPermission,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        !_vm._isNullOrEmpty(
                                          _vm.selectedSection?.orderedChartItems
                                        )
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "ordered-chart-items-container",
                                                attrs: {
                                                  id: "sections-container",
                                                },
                                              },
                                              _vm._l(
                                                _vm.selectedSection
                                                  .orderedChartItems,
                                                function (chartItem, index) {
                                                  return _c(
                                                    "div",
                                                    {
                                                      key: `${chartItem.friendlyName}_${index}`,
                                                      staticClass:
                                                        "chartItem-row",
                                                      class: {
                                                        "border-top": !!index,
                                                      },
                                                    },
                                                    [
                                                      _c("span", {
                                                        staticClass: "box",
                                                        style: {
                                                          "background-color":
                                                            _vm.getChartLineColour(
                                                              chartItem
                                                            ),
                                                        },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "list-label chartItem-label",
                                                        },
                                                        [
                                                          _vm.isWellheadTag(
                                                            chartItem.tagName
                                                          )
                                                            ? [
                                                                _vm._v(
                                                                  "    \r\n                                    " +
                                                                    _vm._s(
                                                                      _vm.getWellheadTagNameForChartItem(
                                                                        chartItem
                                                                      )
                                                                    ) +
                                                                    "\r\n                                "
                                                                ),
                                                              ]
                                                            : [
                                                                _vm._v(
                                                                  "\r\n                                    " +
                                                                    _vm._s(
                                                                      chartItem.friendlyName
                                                                    ) +
                                                                    " (" +
                                                                    _vm._s(
                                                                      chartItem.tagName
                                                                    ) +
                                                                    ")\r\n                                "
                                                                ),
                                                              ],
                                                        ],
                                                        2
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "float-buttons-right",
                                                        },
                                                        [
                                                          _c("iws-button", {
                                                            attrs: {
                                                              type: "text-primary",
                                                              icon: `fas ${
                                                                !_vm.hasPermission
                                                                  ? "fa-eye"
                                                                  : "fa-pencil-alt"
                                                              }`,
                                                            },
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.editChartItem(
                                                                  chartItem,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          }),
                                                          _vm._v(" "),
                                                          _c("iws-button", {
                                                            attrs: {
                                                              type: "text-danger",
                                                              icon: "fas fa-trash-alt",
                                                              click: (
                                                                $event
                                                              ) => {
                                                                $event.preventDefault()
                                                                _vm.deleteChartItem(
                                                                  chartItem,
                                                                  index
                                                                )
                                                              },
                                                              disabled:
                                                                !_vm.hasPermission,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            )
                                          : _c(
                                              "div",
                                              { attrs: { id: "no-items" } },
                                              [
                                                _vm._v(
                                                  "\r\n                        No Data Sources\r\n                    "
                                                ),
                                              ]
                                            ),
                                      ]
                                    : _vm._e(),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          2253074997
                        ),
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm._isFalsy(_vm.selectedAxis) &&
                            _vm._isFalsy(_vm.selectedChartItem) &&
                            _vm._isFalsy(_vm.selectedSection),
                          expression:
                            "_isFalsy(selectedAxis) && _isFalsy(selectedChartItem) && _isFalsy(selectedSection)",
                        },
                      ],
                    },
                    [
                      _c("div", { attrs: { id: "step-control" } }, [
                        _c(
                          "span",
                          [
                            _vm.currentStep > 0
                              ? _c("iws-button", {
                                  attrs: {
                                    type: "outline-light",
                                    icon: "fas fa-chevron-left",
                                    disabled: _vm.currentStep <= 0,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.jumpToStep(_vm.currentStep - 1)
                                    },
                                  },
                                })
                              : _c("iws-button", {
                                  attrs: {
                                    type: "outline-light",
                                    "pre-icon": "fas fa-plus",
                                    text: "New Template",
                                    icon: "fas fa-plus",
                                    click: _vm.handleAddConfig,
                                    disabled: !_vm.hasPermission,
                                  },
                                }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("span", [
                          _c(
                            "div",
                            {
                              staticClass: "btn-group margin-auto",
                              attrs: {
                                role: "group",
                                "aria-label": "Template Steps",
                              },
                            },
                            _vm._l(_vm.steps, function (step, index) {
                              return _c("iws-button", {
                                key: `step_button_${index}`,
                                class: {
                                  "seen-step": index <= _vm.maxSeenStep,
                                },
                                attrs: {
                                  type:
                                    _vm.currentStep == index
                                      ? "primary"
                                      : "outline-light",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.jumpToStep(index)
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "text",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "circle" },
                                            [
                                              _vm.currentStep === index
                                                ? [
                                                    _vm._v(
                                                      "\r\n                                        •\r\n                                    "
                                                    ),
                                                  ]
                                                : index <= _vm.maxSeenStep
                                                ? [
                                                    _vm._v(
                                                      "\r\n                                        ✓\r\n                                    "
                                                    ),
                                                  ]
                                                : [
                                                    _vm._v(
                                                      "\r\n                                        " +
                                                        _vm._s(index + 1) +
                                                        "\r\n                                    "
                                                    ),
                                                  ],
                                            ],
                                            2
                                          ),
                                          _vm._v(
                                            "\r\n                                " +
                                              _vm._s(step.title) +
                                              "\r\n                            "
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            }),
                            1
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "span",
                          [
                            _c("iws-button", {
                              staticClass: "float-right",
                              attrs: {
                                type: "primary",
                                icon: "fas fa-chevron-right",
                                disabled:
                                  !_vm.configSelected ||
                                  _vm.currentStep >= _vm.steps.length - 1,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.jumpToStep(_vm.currentStep + 1)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "row", attrs: { id: "step-container" } },
                        [
                          _c("div", { staticClass: "col" }, [
                            _c(
                              "div",
                              {
                                staticClass: "box-container position-relative",
                              },
                              [
                                _vm._isFalsy(_vm.selectedConfig) ||
                                _vm.currentStep === 0
                                  ? [
                                      _c("div", [
                                        _c(
                                          "h3",
                                          {
                                            staticClass:
                                              "display-inline-block no-margin",
                                          },
                                          [
                                            _vm._v(
                                              "\r\n                                    Select Template\r\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "span",
                                          {
                                            staticClass: "float-right",
                                            staticStyle: {
                                              "max-width": "250px",
                                            },
                                          },
                                          [
                                            _c("iws-search", {
                                              attrs: {
                                                value: _vm.tabSearch,
                                                placeholder: "Search Templates",
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  _vm.tabSearch = $event
                                                },
                                                input:
                                                  _vm.setTemplateDisplayArray,
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("hr"),
                                      _vm._v(" "),
                                      !_vm._isNullOrEmpty(_vm.templateOptions)
                                        ? _c("iws-tabs", {
                                            ref: "templatesTab",
                                            attrs: {
                                              tabs: _vm.templateOptions.map(
                                                (_template) => {
                                                  return {
                                                    title: `${_template.label} (${_template.options.length})`,
                                                  }
                                                }
                                              ),
                                              value: _vm.selectedConfigType,
                                            },
                                            on: {
                                              "update:value": function (
                                                $event
                                              ) {
                                                _vm.selectedConfigType = $event
                                              },
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                _vm._l(
                                                  _vm.templateOptions,
                                                  function (tab, index) {
                                                    return {
                                                      key: `tab_${index}`,
                                                      fn: function () {
                                                        return [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "row",
                                                            },
                                                            _vm._l(
                                                              tab.options,
                                                              function (
                                                                template
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-xs-12 col-sm-6 col-lg-4 template-card",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "clickable",
                                                                        class: {
                                                                          "active-card":
                                                                            _vm
                                                                              .selectedConfig
                                                                              ?.id ==
                                                                            template.id,
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              tab.key !==
                                                                              "global_templates"
                                                                                ? _vm.onSelectedTemplateChange(
                                                                                    template.id
                                                                                  )
                                                                                : null
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "b",
                                                                          [
                                                                            tab.key !==
                                                                            "global_templates"
                                                                              ? _c(
                                                                                  "iws-checkbox",
                                                                                  {
                                                                                    staticStyle:
                                                                                      {
                                                                                        position:
                                                                                          "absolute !important",
                                                                                        top: "10px",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        value:
                                                                                          _vm
                                                                                            .selectedConfig
                                                                                            ?.id ==
                                                                                          template.id,
                                                                                        disabled:
                                                                                          _vm
                                                                                            .selectedConfig
                                                                                            ?.id ==
                                                                                          template.id,
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e(),
                                                                            _vm._v(
                                                                              " "
                                                                            ),
                                                                            _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "display-inline-block",
                                                                                staticStyle:
                                                                                  {
                                                                                    width:
                                                                                      "calc(100% - 35px)",
                                                                                    "margin-left":
                                                                                      "35px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "span",
                                                                                  {
                                                                                    staticClass:
                                                                                      "text-ellipsis-2",
                                                                                  },
                                                                                  [
                                                                                    _vm._v(
                                                                                      "\r\n                                                            " +
                                                                                        _vm._s(
                                                                                          template.templateName
                                                                                        ) +
                                                                                        "\r\n                                                        "
                                                                                    ),
                                                                                  ]
                                                                                ),
                                                                              ]
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        tab.key ==
                                                                        "all_templates"
                                                                          ? _c(
                                                                              "div",
                                                                              {
                                                                                staticStyle:
                                                                                  {
                                                                                    position:
                                                                                      "relative",
                                                                                    top: "-15px",
                                                                                  },
                                                                              },
                                                                              [
                                                                                _vm._v(
                                                                                  "\r\n                                                    Owner: " +
                                                                                    _vm._s(
                                                                                      template.username
                                                                                    ) +
                                                                                    "\r\n                                                "
                                                                                ),
                                                                              ]
                                                                            )
                                                                          : _vm._e(),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        _c(
                                                                          "iws-button",
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                type: "outline-light",
                                                                                text: "Duplicate",
                                                                                click:
                                                                                  () =>
                                                                                    _vm.copyConfig(
                                                                                      template
                                                                                    ),
                                                                              },
                                                                          }
                                                                        ),
                                                                        _vm._v(
                                                                          " "
                                                                        ),
                                                                        tab.key !==
                                                                        "global_templates"
                                                                          ? _c(
                                                                              "span",
                                                                              {
                                                                                staticClass:
                                                                                  "float-buttons-right",
                                                                              },
                                                                              [
                                                                                _c(
                                                                                  "iws-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "outline-danger",
                                                                                        icon: "fas fa-trash-alt",
                                                                                        disabled:
                                                                                          !_vm._isFalsy(
                                                                                            template.disableMessage
                                                                                          ),
                                                                                        click:
                                                                                          () =>
                                                                                            _vm.deleteConfig(
                                                                                              template
                                                                                            ),
                                                                                      },
                                                                                    on: {
                                                                                      "disabled-click":
                                                                                        function (
                                                                                          $event
                                                                                        ) {
                                                                                          return _vm._toast(
                                                                                            template.disableMessage
                                                                                          )
                                                                                        },
                                                                                    },
                                                                                  }
                                                                                ),
                                                                                _vm._v(
                                                                                  " "
                                                                                ),
                                                                                _c(
                                                                                  "iws-button",
                                                                                  {
                                                                                    attrs:
                                                                                      {
                                                                                        type: "outline-light",
                                                                                        icon: "fas fa-history",
                                                                                        click:
                                                                                          () =>
                                                                                            _vm.openHistoryByTemplate(
                                                                                              template
                                                                                            ),
                                                                                      },
                                                                                  }
                                                                                ),
                                                                              ],
                                                                              1
                                                                            )
                                                                          : _vm._e(),
                                                                      ],
                                                                      1
                                                                    ),
                                                                  ]
                                                                )
                                                              }
                                                            ),
                                                            0
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    }
                                                  }
                                                ),
                                              ],
                                              null,
                                              true
                                            ),
                                          })
                                        : _c(
                                            "h3",
                                            { attrs: { id: "no-items" } },
                                            [
                                              _vm._v(
                                                "\r\n                                No templates to select from,"
                                              ),
                                              _c("br"),
                                              _vm._v(
                                                "click 'New Template' below to begin creating your first template.\r\n                            "
                                              ),
                                            ]
                                          ),
                                    ]
                                  : _vm.currentStep === 1
                                  ? _c("iws-form", {
                                      ref: "custom_form",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "body",
                                            fn: function () {
                                              return [
                                                _c("iws-input", {
                                                  attrs: {
                                                    value:
                                                      _vm.selectedConfig
                                                        .templateName,
                                                    label: "Template Name",
                                                    placeholder:
                                                      "Provide a template name",
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "templateName",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.selectedConfig?.owned_by ===
                                                  _vm.userId &&
                                                !_vm.selectedConfig?.isDefault
                                                  ? _c("iws-checkbox", {
                                                      attrs: {
                                                        label:
                                                          "Share this template",
                                                        value:
                                                          _vm.selectedConfig
                                                            .shared,
                                                        "form-spacing": "",
                                                        disabled:
                                                          !_vm.hasPermission,
                                                        "enable-click-label":
                                                          "",
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.selectedConfig,
                                                              "shared",
                                                              $event
                                                            )
                                                          },
                                                        change: function (
                                                          $event
                                                        ) {
                                                          _vm.templateOptions =
                                                            _vm.buildTemplateDisplayArray()
                                                        },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                !_vm.selectedConfig
                                                  ?.isDefault &&
                                                _vm.customerDefaultToggleText &&
                                                _vm.selectedConfig
                                                  ?.header_style !== "default"
                                                  ? _c("iws-checkbox", {
                                                      attrs: {
                                                        label:
                                                          _vm.customerDefaultToggleText,
                                                        value:
                                                          _vm.selectedConfig
                                                            .isDefault,
                                                        "form-spacing": "",
                                                        disabled:
                                                          !_vm.hasPermission,
                                                        "enable-click-label":
                                                          "",
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.selectedConfig,
                                                              "isDefault",
                                                              $event
                                                            )
                                                          },
                                                        change:
                                                          _vm.onCustomerDefaultToggled,
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("iws-select", {
                                                  attrs: {
                                                    label: "Header Style",
                                                    value:
                                                      _vm.selectedConfig
                                                        .header_style,
                                                    options: [
                                                      {
                                                        label: "Frac Status",
                                                        value: "frac",
                                                      },
                                                      {
                                                        label:
                                                          "Wireline Status",
                                                        value: "wireline",
                                                      },
                                                      {
                                                        label: "Default Status",
                                                        value: "default",
                                                      },
                                                    ],
                                                    "display-name": "label",
                                                    "value-name": "value",
                                                    "form-spacing": "",
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission ||
                                                      _vm.chartType ==
                                                        "wireline" ||
                                                      _vm.selectedConfig
                                                        .isDefault == 1,
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "header_style",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-select", {
                                                  attrs: {
                                                    label:
                                                      "Control Panel Location",
                                                    value:
                                                      _vm.selectedConfig
                                                        .controlsPosition,
                                                    options: [
                                                      {
                                                        label: "Left",
                                                        value: "left",
                                                      },
                                                      {
                                                        label: "Right",
                                                        value: "right",
                                                      },
                                                      {
                                                        label: "Top",
                                                        value: "top",
                                                      },
                                                      {
                                                        label: "Bottom",
                                                        value: "bottom",
                                                      },
                                                    ],
                                                    "display-name": "label",
                                                    "value-name": "value",
                                                    "form-spacing": "",
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "controlsPosition",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-input", {
                                                  attrs: {
                                                    value:
                                                      _vm.selectedConfig
                                                        .controlsSize,
                                                    label:
                                                      "Control Panel Size (10-50%)",
                                                    type: "number",
                                                    min: 10,
                                                    max: 50,
                                                    "form-spacing": "",
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission ||
                                                      _vm.selectedConfig
                                                        .hideChart == 1,
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "controlsSize",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-select", {
                                                  attrs: {
                                                    label: "Axis Type",
                                                    value: _vm.xAxisType,
                                                    options: [
                                                      {
                                                        label: "Date",
                                                        value: "Date",
                                                      },
                                                      {
                                                        label:
                                                          _vm.customAxisLabel(),
                                                        value:
                                                          _vm.customAxisLabel(),
                                                      },
                                                    ],
                                                    "display-name": "label",
                                                    "value-name": "value",
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      _vm.xAxisType = $event
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-input", {
                                                  attrs: {
                                                    value: _vm.msToHours(
                                                      _vm.selectedConfig
                                                        .rightSideMsOffset,
                                                      0.5
                                                    ) /* ms to hours or default to half an hour */,
                                                    label:
                                                      "End Time Offset (hours)",
                                                    type: "number",
                                                    min: 0,
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission ||
                                                      _vm.xAxisType !== "Date",
                                                    "form-spacing": "",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      _vm.selectedConfig.rightSideMsOffset =
                                                        _vm.hoursToMs(
                                                          $event.target.value
                                                        )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-input", {
                                                  attrs: {
                                                    value:
                                                      _vm.selectedConfig
                                                        .resolutionZoomHours,
                                                    label:
                                                      "Default Resolution Zoom (hours)",
                                                    type: "number",
                                                    min: 0,
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission ||
                                                      _vm.xAxisType !== "Date",
                                                    "form-spacing": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "resolutionZoomHours",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-select", {
                                                  attrs: {
                                                    label:
                                                      "Default Resolution Action",
                                                    value:
                                                      _vm.selectedConfig
                                                        .defaultZoomAction,
                                                    options: [
                                                      {
                                                        label: "Drag to Zoom",
                                                        value: "dragToZoom",
                                                      },
                                                      {
                                                        label:
                                                          "Drag to Pan, Scroll to Zoom",
                                                        value: "scrollToZoom",
                                                      },
                                                    ],
                                                    "display-name": "label",
                                                    "value-name": "value",
                                                    "form-spacing": "",
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "defaultZoomAction",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        3070467564
                                      ),
                                    })
                                  : _vm.currentStep === 2
                                  ? _c("iws-form", {
                                      ref: "custom_form",
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "body",
                                            fn: function () {
                                              return [
                                                _c("iws-select", {
                                                  staticStyle: {
                                                    width: "150px",
                                                  },
                                                  attrs: {
                                                    label: "Stage Label Style",
                                                    value:
                                                      _vm.selectedConfig
                                                        .stageLabelType,
                                                    options: [
                                                      {
                                                        label: "Label",
                                                        value: "label",
                                                      },
                                                      {
                                                        label: "Hover",
                                                        value: "hover",
                                                      },
                                                    ],
                                                    "display-name": "label",
                                                    "value-name": "value",
                                                    required: "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "stageLabelType",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label: "Pin to Latest Data",
                                                    value:
                                                      _vm.selectedConfig
                                                        .pinRightSideMsOffset,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "pinRightSideMsOffset",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label: "Vertical Chart",
                                                    value:
                                                      _vm.selectedConfig
                                                        .isVertical,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "isVertical",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label: "Hide Date Axis",
                                                    value:
                                                      _vm.selectedConfig
                                                        .isDateAxisHidden,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "isDateAxisHidden",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  staticClass:
                                                    "display-inline-block",
                                                  attrs: {
                                                    label: `${_vm.xAxisType} Axis Gridline Color`,
                                                    value:
                                                      _vm.selectedConfig
                                                        .showVerticalGridlines,
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "form-spacing": "",
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showVerticalGridlines",
                                                        $event
                                                      )
                                                    },
                                                    change: function ($event) {
                                                      _vm.selectedConfig.verticalGridlinesColor =
                                                        _vm.selectedConfig
                                                          .showVerticalGridlines
                                                          ? _vm.selectedConfig
                                                              .verticalGridlinesColor ||
                                                            "#FFFFFF"
                                                          : null
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _vm.selectedConfig
                                                  .showVerticalGridlines
                                                  ? _c("iws-colour-picker", {
                                                      staticClass:
                                                        "display-inline-block ml-2",
                                                      staticStyle: {
                                                        top: "5px",
                                                      },
                                                      attrs: {
                                                        value:
                                                          _vm.selectedConfig
                                                            .verticalGridlinesColor,
                                                        required: "",
                                                        disabled:
                                                          !_vm.hasPermission,
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.selectedConfig,
                                                              "verticalGridlinesColor",
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    })
                                                  : _vm._e(),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label: "Show NPT Lines",
                                                    value:
                                                      _vm.selectedConfig
                                                        .showNPTLines,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showNPTLines",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label: "Show Legend",
                                                    value:
                                                      _vm.selectedConfig
                                                        .showLegend,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showLegend",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label: "Hide Chart",
                                                    value:
                                                      _vm.selectedConfig
                                                        .hideChart,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "hideChart",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label: "Show Stage Lines",
                                                    value:
                                                      _vm.selectedConfig
                                                        .showStageLines,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "showStageLines",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                                _vm._v(" "),
                                                _c("iws-checkbox", {
                                                  attrs: {
                                                    label:
                                                      "Hide Comments Timeline",
                                                    value:
                                                      _vm.selectedConfig
                                                        .hideCommentsTimeline,
                                                    "form-spacing": "",
                                                    disabled:
                                                      !_vm.hasPermission,
                                                    "enable-click-label": "",
                                                  },
                                                  on: {
                                                    "update:value": function (
                                                      $event
                                                    ) {
                                                      return _vm.$set(
                                                        _vm.selectedConfig,
                                                        "hideCommentsTimeline",
                                                        $event
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        false,
                                        2600350497
                                      ),
                                    })
                                  : _vm.currentStep === 3
                                  ? [
                                      _c(
                                        "div",
                                        [
                                          _c(
                                            "b",
                                            { staticClass: "container-label" },
                                            [_vm._v("Sections")]
                                          ),
                                          _vm._v(" "),
                                          _c("iws-button", {
                                            staticClass: "float-buttons-right",
                                            attrs: {
                                              type: "outline-light",
                                              click: _vm.addSection,
                                              disabled: !_vm.hasPermission,
                                              icon: "fas fa-plus",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      !_vm._isNullOrEmpty(
                                        _vm.selectedConfig?.data
                                          ?.orderedSections
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sections-list-container",
                                              attrs: {
                                                id: "sections-container",
                                              },
                                            },
                                            _vm._l(
                                              _vm.selectedConfig.data
                                                .orderedSections,
                                              function (section, index) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: `${section.name}_${index}`,
                                                    class: {
                                                      "border-top": !!index,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "list-label",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\r\n                                        " +
                                                            _vm._s(
                                                              section.name ||
                                                                "N/A"
                                                            ) +
                                                            "\r\n                                    "
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "float-buttons-right",
                                                      },
                                                      [
                                                        _c("iws-button", {
                                                          attrs: {
                                                            type: "text-primary",
                                                            icon: `fas ${
                                                              !_vm.hasPermission
                                                                ? "fa-eye"
                                                                : "fa-pencil-alt"
                                                            }`,
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.editSection(
                                                                section,
                                                                index
                                                              )
                                                            },
                                                          },
                                                        }),
                                                        _vm._v(" "),
                                                        _c("iws-button", {
                                                          attrs: {
                                                            type: "text-danger",
                                                            icon: "fas fa-trash-alt",
                                                            click: () =>
                                                              _vm.deleteSection(
                                                                index
                                                              ),
                                                            disabled:
                                                              !_vm.hasPermission,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        : _c(
                                            "div",
                                            { attrs: { id: "no-items" } },
                                            [
                                              _vm._v(
                                                "\r\n                                No Sections\r\n                            "
                                              ),
                                            ]
                                          ),
                                    ]
                                  : [
                                      _vm._v(
                                        "\r\n                            Invalid step, please navigate back above.\r\n                        "
                                      ),
                                    ],
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.currentStep > 0,
                                  expression: "currentStep > 0",
                                },
                              ],
                              staticClass: "col-4",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "box-container position-relative",
                                },
                                [
                                  _c("iws-accordian", {
                                    ref: "quickSummaryAccordion",
                                    attrs: {
                                      cards: _vm.accordionStepAccess,
                                      value: _vm.currentStep,
                                      "allow-multi-tabs": true,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "card-body_0",
                                          fn: function () {
                                            return [
                                              _vm.configSelected
                                                ? [
                                                    _vm._v(
                                                      "\r\n                                    " +
                                                        _vm._s(
                                                          _vm.selectedConfig
                                                            .templateName
                                                        ) +
                                                        "\r\n                                "
                                                    ),
                                                  ]
                                                : _c("div", [
                                                    _vm._v(
                                                      "\r\n                                    Please select a config to move on\r\n                                "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "card-body_1",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Header Style"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("camelToEnglish")(
                                                        _vm.selectedConfig
                                                          .header_style || "N/A"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [_vm._v("Is Shared")]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.selectedConfig.shared
                                                        ? "Yes"
                                                        : "No"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _vm.selectedConfig.isDefault
                                                ? _c("div", [
                                                    _c("b", [
                                                      _vm._v("Is Default"),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c("span", [_vm._v("Yes")]),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Control Panel Location"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("camelToEnglish")(
                                                        _vm.selectedConfig
                                                          .controlsPosition ||
                                                          "N/A"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Control Panel Size"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !_vm._isFalsy(
                                                        _vm.selectedConfig
                                                          .controlsSize
                                                      )
                                                        ? `${_vm.selectedConfig.controlsSize}%`
                                                        : "N/A"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [_vm._v("Axis Type")]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.xAxisType || "N/A"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("End Time Offset"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !_vm._isFalsy(
                                                        _vm.selectedConfig
                                                          .rightSideMsOffset
                                                      )
                                                        ? `${_vm.msToHours(
                                                            _vm.selectedConfig
                                                              .rightSideMsOffset,
                                                            0.5
                                                          )} hours`
                                                        : "N/A"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Resolution Zoom"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !_vm._isFalsy(
                                                        _vm.selectedConfig
                                                          .resolutionZoomHours
                                                      )
                                                        ? `${_vm.selectedConfig.resolutionZoomHours} hours`
                                                        : "N/A"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Resolution Action"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("camelToEnglish")(
                                                        _vm.selectedConfig
                                                          .defaultZoomAction ||
                                                          "N/A"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "card-body_2",
                                          fn: function () {
                                            return [
                                              _c("div", [
                                                _c("b", [_vm._v("Label Type")]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("camelToEnglish")(
                                                        _vm.selectedConfig
                                                          .stageLabelType ||
                                                          "N/A"
                                                      )
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Pin to Latest"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .pinRightSideMsOffset
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Vertical Chart"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .isVertical
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Hide Date Axis"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .isDateAxisHidden
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Date Axis Gridlines"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .showVerticalGridlines
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Show NPT Lines"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .showNPTLines
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Show Legend"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .showLegend
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [_vm._v("Hide Chart")]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .hideChart
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v("Show Stage Lines"),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .showStageLines
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                              _vm._v(" "),
                                              _c("div", [
                                                _c("b", [
                                                  _vm._v(
                                                    "Hide Comments Timeline"
                                                  ),
                                                ]),
                                                _vm._v(" "),
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      !!_vm.selectedConfig
                                                        .hideCommentsTimeline
                                                        ? "On"
                                                        : "Off"
                                                    )
                                                  ),
                                                ]),
                                              ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                        {
                                          key: "card-body_3",
                                          fn: function () {
                                            return [
                                              !_vm._isNullOrEmpty(
                                                _vm.selectedConfig?.data
                                                  ?.orderedSections
                                              )
                                                ? _c(
                                                    "span",
                                                    _vm._l(
                                                      _vm.selectedConfig.data
                                                        .orderedSections,
                                                      function (section) {
                                                        return _c("div", [
                                                          _vm._v(
                                                            "\r\n                                        " +
                                                              _vm._s(
                                                                section.name
                                                              ) +
                                                              "\r\n\r\n                                        "
                                                          ),
                                                          !_vm._isNullOrEmpty(
                                                            section?.orderedChartItems
                                                          )
                                                            ? _c(
                                                                "ol",
                                                                {
                                                                  staticClass:
                                                                    "pl-4",
                                                                },
                                                                _vm._l(
                                                                  section.orderedChartItems,
                                                                  function (
                                                                    chartItem
                                                                  ) {
                                                                    return _c(
                                                                      "li",
                                                                      [
                                                                        _vm._v(
                                                                          "\r\n                                                " +
                                                                            _vm._s(
                                                                              chartItem.tagName
                                                                            ) +
                                                                            "\r\n                                            "
                                                                        ),
                                                                      ]
                                                                    )
                                                                  }
                                                                ),
                                                                0
                                                              )
                                                            : _vm._e(),
                                                        ])
                                                      }
                                                    ),
                                                    0
                                                  )
                                                : _c("span", [
                                                    _vm._v(
                                                      "\r\n                                    0 sections\r\n                                "
                                                    ),
                                                  ]),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      false,
                                      651158825
                                    ),
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            }
          : {
              key: "content",
              fn: function () {
                return [
                  _c("div", { attrs: { id: "loading" } }, [
                    _c(
                      "div",
                      {
                        staticClass: "spinner-border light-text-color",
                        attrs: { role: "status" },
                      },
                      [
                        _c("span", { staticClass: "sr-only" }, [
                          _vm._v("Loading..."),
                        ]),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "div",
                { staticClass: "full-width" },
                [
                  _c("iws-button", {
                    attrs: {
                      text: "Use Template",
                      type: "outline-light",
                      disabled:
                        _vm.selectedConfig == null ||
                        _vm.selectedConfig.id ==
                          _vm.data?.chartConfiguration?.id,
                      click: () =>
                        _vm.onUseTemplateSelected(_vm.selectedConfig),
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "float-buttons-right" },
                    [
                      _c("iws-button", {
                        attrs: { text: "Cancel", type: "outline-light" },
                        on: { click: _vm.modalDetails.secondaryAction },
                      }),
                      _vm._v(" "),
                      _c("iws-button", {
                        staticClass: "ml-2",
                        attrs: {
                          text: "Save",
                          type: "primary",
                          disabled:
                            !_vm.hasPermission ||
                            !_vm.modalDetails.primaryEnabled,
                        },
                        on: { click: _vm.modalDetails.primaryAction },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          },
          proxy: true,
        },
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }