var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.canManageDashboards
        ? _c("iws-modal", {
            attrs: {
              showModal: _vm.showManagementModal,
              title: `Dashboard Management for ${_vm.dashboardCustomer?.name}`,
              secondaryButtonVisible: false,
              primaryButtonVisible: false,
              "max-width": "80vw",
            },
            on: {
              close: function ($event) {
                _vm.showManagementModal = false
              },
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c("dashboard-management-component", {
                        attrs: {
                          job: _vm.job,
                          availableDisplayItems: _vm.availableDisplayItems,
                          selectedDashboard: _vm.selectedDashboard,
                          initDashboardManagementPage:
                            _vm.initDashboardManagementPage,
                          setSelectedDashboard: _vm.setSelectedDashboard,
                          reorderDashboards: _vm.reorderDashboards,
                          orderedDashboardIds:
                            _vm.orderedDashboards.ordered_dashboard_ids,
                          fleetDashboardIds:
                            _vm.fleetDashboard.ordered_dashboard_ids,
                        },
                        on: {
                          close: function ($event) {
                            _vm.showManagementModal = false
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2500082779
            ),
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm._isNullOrEmpty(_vm.dashboards) || _vm.isDashboardsPage
        ? _c(
            "nav",
            {
              staticClass: "navbar navbar-expand-md shadow-sm navbar-light",
              attrs: { id: "DashboardNavbar" },
            },
            [
              _c(
                "ul",
                {
                  ref: "dashboardContainer",
                  staticClass: "navbar-nav mr-auto flex-list full-width",
                  class: { "limit-width": _vm.isDashboardsPage },
                },
                [
                  _vm._l(_vm.dashboardsForMenu, function (dashboard, index) {
                    return _c(
                      "li",
                      {
                        key: dashboard.id,
                        ref: `dashboard_name_${index}`,
                        refInFor: true,
                        staticClass: "nav-item text-nowrap",
                        class: {
                          dashboard_navbar_selected:
                            _vm.selectedDashboard.id == dashboard.id,
                          dashboard_navbar_item:
                            _vm.selectedDashboard.id != dashboard.id,
                        },
                      },
                      [
                        _c(
                          "a",
                          {
                            staticClass: "nav-link",
                            class: {
                              "font-weight-bold": dashboard.is_customer_default,
                            },
                            attrs: { href: "#" },
                            on: {
                              click: function ($event) {
                                return _vm.setSelectedDashboard(
                                  dashboard.id,
                                  false,
                                  true
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(dashboard.name) +
                                "\n                "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    "li",
                    { ref: "moreRef", staticClass: "nav-item dropdown" },
                    [
                      (!_vm.isMoreDropdownEmpty && !_vm.isDashboardsPage) ||
                      _vm.isDashboardsPage
                        ? _c(
                            "a",
                            {
                              staticClass: "nav-link dropdown-toggle",
                              class: {
                                "selected-dropdown":
                                  _vm.selectedDashboardInDropdown,
                              },
                              attrs: {
                                href: "#",
                                id: "navbarDropdown",
                                role: "button",
                                "data-toggle": "dropdown",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    " +
                                  _vm._s(
                                    _vm._isNullOrEmpty(_vm.dashboardsForMenu)
                                      ? "Dashboards"
                                      : "More"
                                  ) +
                                  "\n                "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass:
                            "dropdown-menu dropdown-menu-left bg-light",
                          attrs: { id: "more-dropdown" },
                        },
                        [
                          _vm.canManageDashboards
                            ? [
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "nav-item dashboard_navbar_item text-nowrap",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link text-white",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.createNewDashboard()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                New Dashboard...\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm.dashboardExists
                                  ? _c(
                                      "li",
                                      {
                                        staticClass:
                                          "nav-item dashboard_navbar_item text-nowrap",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            staticClass: "nav-link text-white",
                                            attrs: { type: "button" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.createNewDashboard(
                                                  true
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                Copy This Dashboard...\n                            "
                                            ),
                                          ]
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "li",
                                  {
                                    staticClass:
                                      "nav-item dashboard_navbar_item text-nowrap",
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        staticClass: "nav-link text-white",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            _vm.showManagementModal = true
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                               Dashboard Management...\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                !_vm.isMoreDropdownEmpty
                                  ? _c("div", {
                                      staticClass: "dropdown-divider",
                                    })
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm._isNullOrEmpty(
                            _vm.dashboardsForDropDown?.default
                          )
                            ? _c(
                                "div",
                                { staticClass: "dropdown-section" },
                                [
                                  _c("b", [
                                    _vm._v(
                                      "\n                            Default\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.dashboardsForDropDown?.default,
                                    function (dashboard) {
                                      return _c(
                                        "li",
                                        {
                                          key: dashboard.id,
                                          staticClass: "nav-item text-nowrap",
                                          class: {
                                            dashboard_navbar_selected:
                                              _vm.selectedDashboard.id ==
                                              dashboard.id,
                                            dashboard_navbar_item:
                                              _vm.selectedDashboard.id !=
                                              dashboard.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              key: dashboard.id,
                                              staticClass:
                                                "nav-link text-white",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setSelectedDashboard(
                                                    dashboard.id,
                                                    false,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(dashboard.name) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          !_vm._isNullOrEmpty(
                            _vm.dashboardsForDropDown?.nonDefault
                          )
                            ? _c(
                                "div",
                                { staticClass: "dropdown-section" },
                                [
                                  _c("b", [
                                    _vm._v(
                                      "\n                            Non Default\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _vm._l(
                                    _vm.dashboardsForDropDown?.nonDefault,
                                    function (dashboard) {
                                      return _c(
                                        "li",
                                        {
                                          key: dashboard.id,
                                          staticClass: "nav-item text-nowrap",
                                          class: {
                                            dashboard_navbar_selected:
                                              _vm.selectedDashboard.id ==
                                              dashboard.id,
                                            dashboard_navbar_item:
                                              _vm.selectedDashboard.id !=
                                              dashboard.id,
                                          },
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              key: dashboard.id,
                                              staticClass:
                                                "nav-link text-white",
                                              attrs: { type: "button" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.setSelectedDashboard(
                                                    dashboard.id,
                                                    false,
                                                    true
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                                " +
                                                  _vm._s(dashboard.name) +
                                                  "\n                            "
                                              ),
                                            ]
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                        ],
                        2
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  !_vm._isNullOrEmpty(_vm.dashboardsForDropDown?.shared)
                    ? _c(
                        "li",
                        { ref: "sharedRef", staticClass: "nav-item dropdown" },
                        [
                          _c(
                            "a",
                            {
                              staticClass: "nav-link dropdown-toggle",
                              attrs: {
                                href: "#",
                                id: "navbarDropdown",
                                role: "button",
                                "data-toggle": "dropdown",
                              },
                            },
                            [
                              _vm._v(
                                "\n                    Shared with you\n                "
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "dropdown-menu bg-light drop-down-width",
                            },
                            _vm._l(
                              _vm.dashboardsForDropDown?.shared,
                              function (dashboard) {
                                return _c(
                                  "li",
                                  {
                                    key: dashboard.id,
                                    staticClass:
                                      "nav-item text-nowrap dashboard_navbar_selected",
                                    class: {
                                      dashboard_navbar_selected:
                                        _vm.selectedDashboard.id ==
                                        dashboard.id,
                                      dashboard_navbar_item:
                                        _vm.selectedDashboard.id !=
                                        dashboard.id,
                                    },
                                  },
                                  [
                                    _c(
                                      "a",
                                      {
                                        key: dashboard.id,
                                        staticClass: "nav-link text-white",
                                        attrs: { type: "button" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.setSelectedDashboard(
                                              dashboard.id,
                                              false,
                                              true
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(dashboard.name) +
                                            "\n                        "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
              _vm._v(" "),
              !_vm.job.end && _vm.isDashboardsPage
                ? _c(
                    "div",
                    { staticClass: "px-2", attrs: { id: "latency-nav" } },
                    [
                      _c("latency-component-new", {
                        attrs: {
                          nav: true,
                          utcDifference: _vm.utcDifference,
                          latestDataCollection: _vm.latestDataCollection,
                          "user-roles": _vm.userRoles,
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canManageDashboards && _vm.dashboardExists
                ? _c(
                    "span",
                    {
                      ref: "actionsContainer",
                      class: _vm.dashboardActions,
                      attrs: { id: "dashboard-actions" },
                    },
                    [
                      _c(
                        "span",
                        [
                          !_vm.editMode
                            ? [
                                _c("iws-button", {
                                  key: "editLayout",
                                  staticClass: "primary-text-color",
                                  staticStyle: {
                                    border: "none !important",
                                    "padding-top": "2px",
                                    "padding-bottom": "2px",
                                  },
                                  attrs: {
                                    "prepend-icon": "fas fa-pencil-alt",
                                    text: "Edit Layout",
                                    type: "outline-light",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitEditMode(true)
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("iws-button", {
                                  staticClass: "primary-text-color",
                                  staticStyle: {
                                    border: "none !important",
                                    "padding-top": "2px",
                                    "padding-bottom": "2px",
                                  },
                                  attrs: {
                                    "prepend-icon": "fas fa-cog",
                                    text: "Manage Dashboard",
                                    type: "outline-light",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitToggleManage(true)
                                    },
                                  },
                                }),
                              ]
                            : [
                                _c("iws-select", {
                                  staticStyle: {
                                    width: "150px",
                                    display: "inline-block",
                                  },
                                  attrs: {
                                    placeholder: "Add Component",
                                    options: _vm.availableDisplayItems,
                                    "display-name": "friendlyName",
                                    clearOnSelect: true,
                                    "full-placeholder": "",
                                  },
                                  on: { change: _vm.emitSelectComponent },
                                }),
                                _vm._v(" "),
                                _c("iws-button", {
                                  staticStyle: { "margin-left": "5px" },
                                  attrs: { type: "primary", text: "Save" },
                                  on: { click: _vm.emitSave },
                                }),
                                _vm._v(" "),
                                _c("iws-button", {
                                  staticStyle: {
                                    "margin-left": "5px",
                                    "margin-right": "10px",
                                  },
                                  attrs: { type: "danger", text: "Discard" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.emitEditMode(false)
                                    },
                                  },
                                }),
                              ],
                        ],
                        2
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }