var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LibraryFiltersBaseModal", {
    ref: "libraryFilters",
    attrs: {
      id: _vm.id,
      items: _vm.guns,
      filters: _vm.filters,
      title: "Filter Guns",
    },
    on: {
      clearFilters: function ($event) {
        _vm.filters = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function ({
          findUniqueValues,
          pushToFilter,
          removeFromFilter,
          handleEvent,
        }) {
          return [
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Name",
                    value: _vm.filters.name,
                    options: findUniqueValues("name"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.name, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.name, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times clickable",
                        staticStyle: { "margin-right": "5px" },
                        on: {
                          click: function ($event) {
                            return removeFromFilter(_vm.filters.name, index)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Description",
                    value: _vm.filters.description,
                    options: findUniqueValues("description"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.description, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.description, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times",
                        staticStyle: { "margin-right": "5px" },
                        on: {
                          click: function ($event) {
                            return removeFromFilter(
                              _vm.filters.description,
                              index
                            )
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "filter-container container-border" }, [
              _c("label", [_vm._v("Gun Size")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("iws-select", {
                      ref: "gunRangeMin",
                      attrs: {
                        label: "Select Min Gun Size",
                        value: _vm.sizeMin,
                        options: findUniqueValues("size"),
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.sizeMin = $event
                        },
                        change: _vm.checkForFullRange,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "col-6" },
                  [
                    _c("iws-select", {
                      ref: "gunRangeMax",
                      attrs: {
                        label: "Select Max Gun Size",
                        value: _vm.sizeMax,
                        options: findUniqueValues("size"),
                      },
                      on: {
                        "update:value": function ($event) {
                          _vm.sizeMax = $event
                        },
                        change: function ($event) {
                          return _vm.checkForFullRange(pushToFilter)
                        },
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                _vm._l(_vm.filters.size, function (option, index) {
                  return _c("span", { staticClass: "badge badge-outline" }, [
                    _vm._v("\r\n                    " + _vm._s(option) + " "),
                    _c("i", {
                      staticClass: "fas fa-times",
                      staticStyle: { "margin-right": "5px" },
                      on: {
                        click: function ($event) {
                          return removeFromFilter(_vm.filters.size, index)
                        },
                      },
                    }),
                  ])
                }),
                0
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Carrier",
                    value: _vm.filters.carrier_make,
                    options: findUniqueValues("carrier_make"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.carrier_make, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.carrier_make, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times",
                        staticStyle: { "margin-right": "5px" },
                        on: {
                          click: function ($event) {
                            return removeFromFilter(
                              _vm.filters.carrier_make,
                              index
                            )
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Charge",
                    value: _vm.filters.charge_make,
                    options: findUniqueValues("charge_make"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.charge_make, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.charge_make, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times",
                        staticStyle: { "margin-right": "5px" },
                        on: {
                          click: function ($event) {
                            return removeFromFilter(
                              _vm.filters.charge_make,
                              index
                            )
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.enableCustomerCol
              ? _c(
                  "div",
                  { staticClass: "filter-container" },
                  [
                    _c("iws-select", {
                      attrs: {
                        label: "Customer",
                        value: _vm.filters.customerName,
                        options: findUniqueValues("customerName"),
                        "maintain-on-select": true,
                        multiselect: true,
                      },
                      on: {
                        change: function ($event) {
                          return handleEvent(_vm.filters.customerName, $event)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      _vm._l(
                        _vm.filters.customerName,
                        function (option, index) {
                          return _c(
                            "span",
                            { staticClass: "badge badge-outline" },
                            [
                              _vm._v(
                                "\r\n                    " +
                                  _vm._s(option) +
                                  " "
                              ),
                              _c("i", {
                                staticClass: "fas fa-times",
                                staticStyle: { "margin-right": "5px" },
                                on: {
                                  click: function ($event) {
                                    return removeFromFilter(
                                      _vm.filters.customerName,
                                      index
                                    )
                                  },
                                },
                              }),
                            ]
                          )
                        }
                      ),
                      0
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }