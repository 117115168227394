var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "pb-2 accordion" }, [
    _c("div", { staticClass: "card text-white bg-light" }, [
      _c(
        "div",
        { staticClass: "card-header", attrs: { id: "header" + _vm._uid } },
        [
          _c(
            "h5",
            {
              staticClass: "mb-0",
              attrs: {
                "data-toggle": "collapse",
                "data-target": "#collapseTarget" + _vm._uid,
                "aria-expanded": _vm.toggleAriaExpanded,
                "aria-controls": "collapseTarget" + _vm._uid,
              },
            },
            [
              _c("button", { staticClass: "btn link-txt-color" }, [
                _vm._v("\n                    " + _vm._s(_vm.headerName) + " "),
                _c("span", { attrs: { id: "tpi-com" } }, [
                  _vm._v(_vm._s(_vm.tpiComp)),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          class: _vm.toggleClass,
          attrs: {
            id: "collapseTarget" + _vm._uid,
            "aria-labelledby": "header" + _vm._uid,
          },
        },
        [
          _c("div", { staticClass: "card-body" }, [
            _c(
              "table",
              {
                staticClass: "table table-striped table-hover mt-2",
                class: { "table-dark": _vm.darkMode },
              },
              [
                _c("thead", [
                  _vm._m(0),
                  _vm._v(" "),
                  _c("th", { staticClass: "col-3", attrs: { scope: "col" } }, [
                    _c("span", {
                      staticClass: "mr-1 pointer-hover",
                      class: _vm.rawTagSortIcon,
                      on: {
                        click: function ($event) {
                          return _vm.sortTable("rawTagNames")
                        },
                      },
                    }),
                    _vm._v("Raw Tag Names"),
                  ]),
                  _vm._v(" "),
                  _vm.showDefaultColumn
                    ? _c(
                        "th",
                        { staticClass: "col-3", attrs: { scope: "col" } },
                        [
                          _c("span", {
                            staticClass: "mr-1 pointer-hover",
                            on: {
                              click: function ($event) {
                                return _vm.sortTable("friendlyTagNames")
                              },
                            },
                          }),
                          _vm._v("Default Tag Names"),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("th", { staticClass: "col-2", attrs: { scope: "col" } }, [
                    _vm._v("Latest Tag Value"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "tbody",
                  _vm._l(_vm.sortedTags, function (tag, index) {
                    return _c("tr", { key: index }, [
                      _c("td", [_vm._v(" " + _vm._s(tag.orderNumber) + " ")]),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(tag.name))]),
                      _vm._v(" "),
                      _vm.showDefaultColumn
                        ? _c("td", [_vm._v(_vm._s(tag.friendlyTagname))])
                        : _vm._e(),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(tag.displayName) + " ")]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(
                          _vm._s(
                            _vm.latestTagValues[tag.name] == null
                              ? "--"
                              : _vm.latestTagValues[tag.name]
                          )
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "col-1", attrs: { scope: "col" } }, [
      _c("span", { staticClass: "mr-1 pointer-hover" }),
      _vm._v("Order"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { staticClass: "col-3", attrs: { scope: "col" } }, [
      _c("span", { staticClass: "mr-1 pointer-hover" }),
      _vm._v("Display Names"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }