var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: _vm.id } },
    [
      _c("iws-modal", {
        ref: `library_filters_${this.id}`,
        attrs: {
          title: _vm.title,
          showModal: _vm.showBaseModal,
          id: "LibraryFilters",
          "max-width": _vm.maxWidth,
          "max-height": _vm.maxHeight,
          closeOnClickaway: _vm.closeOnClickaway,
          primaryButtonText: _vm.primaryButtonText,
          secondaryButtonText: _vm.secondaryButtonText,
          primaryButtonDisabled: _vm.primaryButtonDisabled,
          secondaryButtonDisabled: _vm.secondaryButtonDisabled,
        },
        on: {
          close: function ($event) {
            _vm.showBaseModal = false
          },
          "secondary-action": _vm.clearFilters,
        },
        scopedSlots: _vm._u(
          [
            {
              key: "content",
              fn: function () {
                return [
                  _vm._t("body", null, {
                    handleEvent: _vm.handleEvent,
                    pushToFilter: _vm.pushToFilter,
                    removeFromFilter: _vm.removeFromFilter,
                    findUniqueValues: _vm.findUniqueValues,
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }