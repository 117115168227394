var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _c("iws-breadcrumbs", {
        staticClass: "mt-4 mb-1",
        attrs: {
          path: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "Create Job",
              link: "/jobs/create",
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "page-title mb-4" }, [
        _vm._v("\n        Create Job\n    "),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm.action == "create"
            ? _c("label", [
                _vm._v("Job Number (must start with A-Z, upper or lower case)"),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                _vm._v(" "),
                _vm.action != "create"
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("The Job Number cannot be changed!"),
                    ])
                  : _vm._e(),
              ])
            : _c("label", [
                _vm._v("Job Number "),
                _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
                _vm._v(" "),
                _vm.action != "create"
                  ? _c("span", { staticStyle: { color: "red" } }, [
                      _vm._v("The Job Number cannot be changed!"),
                    ])
                  : _vm._e(),
              ]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.jobNumber,
                expression: "jobNumber",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid": Object.keys(_vm.errors).indexOf("jobNumber") > -1,
            },
            attrs: {
              type: "text",
              disabled: _vm.action != "create" ? true : false,
            },
            domProps: { value: _vm.jobNumber },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.jobNumber = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("jobNumber") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["jobNumber"]
                      ? _vm.errors["jobNumber"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.location,
                expression: "location",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid": Object.keys(_vm.errors).indexOf("location") > -1,
            },
            attrs: { type: "text", disabled: _vm.isReadOnly() },
            domProps: { value: _vm.location },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.location = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("location") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["location"]
                      ? _vm.errors["location"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm._m(1),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.system,
                expression: "system",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid": Object.keys(_vm.errors).indexOf("system") > -1,
            },
            attrs: { type: "text", disabled: _vm.isReadOnly() },
            domProps: { value: _vm.system },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.system = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("system") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["system"] ? _vm.errors["system"][0] : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm._m(2),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.latitude,
                expression: "latitude",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid": Object.keys(_vm.errors).indexOf("latitude") > -1,
            },
            attrs: { type: "text", disabled: _vm.isReadOnly() },
            domProps: { value: _vm.latitude },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.latitude = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("latitude") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["latitude"]
                      ? _vm.errors["latitude"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm._m(3),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.longitude,
                expression: "longitude",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid": Object.keys(_vm.errors).indexOf("longitude") > -1,
            },
            attrs: { type: "text", disabled: _vm.isReadOnly() },
            domProps: { value: _vm.longitude },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.longitude = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("longitude") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["longitude"]
                      ? _vm.errors["longitude"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _c("label", [_vm._v("Projected start")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.scheduledStart,
                expression: "scheduledStart",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid":
                Object.keys(_vm.errors).indexOf("scheduledStart") > -1,
            },
            attrs: {
              type: "datetime-local",
              step: "1",
              placeholder: "yyyy-mm-dd hh:mm:ss",
              disabled: _vm.isReadOnly(),
            },
            domProps: { value: _vm.scheduledStart },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.scheduledStart = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("scheduledStart") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["scheduledStart"]
                      ? _vm.errors["scheduledStart"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _c("label", [_vm._v("Projected end")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.scheduledEnd,
                expression: "scheduledEnd",
              },
            ],
            staticClass: "form-control",
            attrs: {
              type: "datetime-local",
              step: "1",
              placeholder: "yyyy-mm-dd hh:mm:ss",
              disabled: _vm.isReadOnly(),
            },
            domProps: { value: _vm.scheduledEnd },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.scheduledEnd = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("scheduledEnd") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["scheduledEnd"]
                      ? _vm.errors["scheduledEnd"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _c("label", [_vm._v("Actual start")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.start,
                expression: "start",
              },
            ],
            staticClass: "form-control text-secondary",
            attrs: {
              type: "datetime-local",
              step: "1",
              placeholder: "yyyy-mm-dd hh:mm:ss",
              disabled: _vm.isReadOnly(),
            },
            domProps: { value: _vm.start },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.start = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("start") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["start"] ? _vm.errors["start"][0] : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _c("label", [_vm._v("Actual end")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.end,
                expression: "end",
              },
            ],
            staticClass: "form-control text-secondary",
            attrs: {
              type: "datetime-local",
              step: "1",
              placeholder: "yyyy-mm-dd hh:mm:ss",
              disabled: _vm.isReadOnly(),
            },
            domProps: { value: _vm.end },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.end = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("end") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(_vm.errors["end"] ? _vm.errors["end"][0] : undefined)
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm._m(4),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.shiftStart,
                expression: "shiftStart",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid": Object.keys(_vm.errors).indexOf("shiftStart") > -1,
            },
            attrs: { type: "number", disabled: _vm.isReadOnly() },
            domProps: { value: _vm.shiftStart },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.shiftStart = $event.target.value
                },
                _vm.handleShiftInput,
              ],
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("shiftStart") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["shiftStart"]
                      ? _vm.errors["shiftStart"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm._m(5),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.hourOffset,
                expression: "hourOffset",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid": Object.keys(_vm.errors).indexOf("hourOffset") > -1,
            },
            attrs: { type: "number", disabled: _vm.isReadOnly() },
            domProps: { value: _vm.hourOffset },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.hourOffset = $event.target.value
                },
                _vm.handleOffsetInput,
              ],
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("hourOffset") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["hourOffset"]
                      ? _vm.errors["hourOffset"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-6 form-group" }, [
          _vm._m(6),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.stagesCompletedProjection,
                expression: "stagesCompletedProjection",
              },
            ],
            class: {
              "form-control": true,
              "is-invalid":
                Object.keys(_vm.errors).indexOf("stagesCompletedProjection") >
                -1,
            },
            attrs: { type: "number", disabled: _vm.isReadOnly() },
            domProps: { value: _vm.stagesCompletedProjection },
            on: {
              input: [
                function ($event) {
                  if ($event.target.composing) return
                  _vm.stagesCompletedProjection = $event.target.value
                },
                _vm.handleProjectedCompletedStagesInput,
              ],
            },
          }),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("stagesCompletedProjection") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["stagesCompletedProjection"]
                      ? _vm.errors["stagesCompletedProjection"][0]
                      : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _vm._m(7),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.company,
                  expression: "company",
                },
              ],
              class: {
                "form-control": true,
                "is-invalid": Object.keys(_vm.errors).indexOf("company") > -1,
              },
              attrs: { id: "company", disabled: _vm.isReadOnly() },
              on: {
                input: _vm.handleCompanyInput,
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.company = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            _vm._l(_vm.customers, function (cust) {
              return _c(
                "option",
                { key: cust.id, domProps: { value: cust.id } },
                [_vm._v(_vm._s(cust.name))]
              )
            }),
            0
          ),
          _vm._v(" "),
          Object.keys(_vm.errors).indexOf("company") > -1
            ? _c("div", { staticClass: "invalid-feedback" }, [
                _vm._v(
                  _vm._s(
                    _vm.errors["company"] ? _vm.errors["company"][0] : undefined
                  )
                ),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _c("label", [_vm._v("Technician (daytime)")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.techUserDaytime,
                  expression: "techUserDaytime",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "techUserDaytime" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.techUserDaytime = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("None")]),
              _vm._v(" "),
              _vm._l(_vm.techUsers, function (user) {
                return _c(
                  "option",
                  { key: user.id, domProps: { value: user.id } },
                  [_vm._v(_vm._s(user.username))]
                )
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "col-12 col-lg form-group" }, [
          _c("label", [_vm._v("Technician (night)")]),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.techUserNight,
                  expression: "techUserNight",
                },
              ],
              staticClass: "form-control",
              attrs: { id: "techUserNight" },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.techUserNight = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c("option", { attrs: { value: "" } }, [_vm._v("None")]),
              _vm._v(" "),
              _vm._l(_vm.techUsers, function (user) {
                return _c(
                  "option",
                  { key: user.id, domProps: { value: user.id } },
                  [_vm._v(_vm._s(user.username))]
                )
              }),
            ],
            2
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("hr", { staticClass: "bg-secondary" }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between pb-1" }, [
        _c("h3", [_vm._v("Job site contractors")]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "btn btn-primary", on: { click: _vm.addContractor } },
          [_vm._v("Add Contractor")]
        ),
      ]),
      _vm._v(" "),
      _vm.assignedContractors.length == 0
        ? _c("div", [_vm._v("\n        No contractors\n    ")])
        : _c(
            "ul",
            { staticClass: "list-group pt-1" },
            _vm._l(
              _vm.assignedContractors,
              function (assignedContractor, index) {
                return _c(
                  "li",
                  {
                    key: index,
                    staticClass: "list-group-item d-flex align-items-center",
                  },
                  [
                    _c("div", { staticClass: "d-flex flex-grow-1" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: assignedContractor.id,
                              expression: "assignedContractor.id",
                            },
                          ],
                          staticClass: "form-control mr-1",
                          attrs: { id: "contractorType" },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                assignedContractor,
                                "id",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "option",
                            { attrs: { value: "-1", disabled: "" } },
                            [_vm._v("Select one...")]
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.contractorsForSelectedCustomer,
                            function (contractor, jndex) {
                              return _c(
                                "option",
                                {
                                  key: jndex,
                                  domProps: { value: contractor.id },
                                },
                                [
                                  _vm._v(
                                    _vm._s(contractor.name) +
                                      ", " +
                                      _vm._s(contractor.type)
                                  ),
                                ]
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: assignedContractor.isDefault,
                          expression: "assignedContractor.isDefault",
                        },
                      ],
                      staticClass: "mx-2 my-1",
                      staticStyle: { width: "30px", height: "30px" },
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(assignedContractor.isDefault)
                          ? _vm._i(assignedContractor.isDefault, null) > -1
                          : assignedContractor.isDefault,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = assignedContractor.isDefault,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  assignedContractor,
                                  "isDefault",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  assignedContractor,
                                  "isDefault",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(assignedContractor, "isDefault", $$c)
                          }
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("label", { staticClass: "mr-5 my-1 text-secondary" }, [
                      _vm._v("\n            Default"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary ml-auto",
                        on: {
                          click: function ($event) {
                            return _vm.deleteContractor(index)
                          },
                        },
                      },
                      [_vm._v("X")]
                    ),
                  ]
                )
              }
            ),
            0
          ),
      _vm._v(" "),
      _c("hr", { staticClass: "bg-secondary" }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between pb-1" }, [
        _c("h3", [_vm._v("Site status messages")]),
        _vm._v(" "),
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            attrs: { disabled: _vm.isReadOnly() },
            on: { click: _vm.addMessage },
          },
          [_vm._v("Add Message")]
        ),
      ]),
      _vm._v(" "),
      _vm.messages.length == 0
        ? _c("div", [_vm._v("\n        No messages\n    ")])
        : _c(
            "ul",
            { staticClass: "list-group pt-1" },
            _vm._l(_vm.messages, function (message, index) {
              return _c(
                "li",
                {
                  key: index,
                  staticClass:
                    "list-group-item d-flex justify-content-between align-items-center",
                },
                [
                  _c("div", { staticClass: "d-flex flex-grow-1" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: message.header,
                          expression: "message.header",
                        },
                      ],
                      staticClass: "form-control mr-1",
                      attrs: { type: "text", placeholder: "Title" },
                      domProps: { value: message.header },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(message, "header", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: message.body,
                          expression: "message.body",
                        },
                      ],
                      staticClass: "form-control mr-1",
                      attrs: { type: "text", placeholder: "Message" },
                      domProps: { value: message.body },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(message, "body", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary",
                      on: {
                        click: function ($event) {
                          return _vm.deleteMessage(index)
                        },
                      },
                    },
                    [_vm._v("X")]
                  ),
                ]
              )
            }),
            0
          ),
      _vm._v(" "),
      _c("div", [
        _c("hr", { staticClass: "bg-secondary" }),
        _vm._v(" "),
        _c("div", { staticClass: "d-flex justify-content-between pb-1" }, [
          _c("h3", [_vm._v("Wells")]),
          _vm._v(" "),
          _vm.wellCountEditable
            ? _c(
                "button",
                { staticClass: "btn btn-primary", on: { click: _vm.addWell } },
                [_vm._v("Add Well")]
              )
            : _c("div", [_vm._v("Wells can no longer be added or removed")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "row" },
          _vm._l(_vm.wells, function (well, index) {
            return _c("well-setup-cloud-component", {
              key: index,
              staticClass: "col-6 col-lg-3",
              attrs: {
                wellInfo: well,
                index: index,
                allowEdit: _vm.wellCountEditable,
                wellCount: _vm.wells.length,
                wellConfigs: _vm.wellConfigs,
                isReadOnly: _vm.isReadOnly,
              },
              on: {
                well: _vm.updateWellInfo,
                delete: _vm.deleteWell,
                order: _vm.changeWellOrder,
              },
            })
          }),
          1
        ),
      ]),
      _vm._v(" "),
      _vm.action == "create"
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary float-right my-5",
              attrs: { disabled: _vm.busy },
              on: { click: _vm.newJob },
            },
            [
              _vm.busy
                ? _c("b-spinner", { staticClass: "mr-1", attrs: { small: "" } })
                : _vm._e(),
              _vm._v(" Create new job\n    "),
            ],
            1
          )
        : _c(
            "button",
            {
              staticClass: "btn btn-primary float-right my-5",
              attrs: { disabled: _vm.busy },
              on: { click: _vm.updateJob },
            },
            [
              _vm.busy
                ? _c("b-spinner", { staticClass: "mr-1", attrs: { small: "" } })
                : _vm._e(),
              _vm._v(" Save job\n    "),
            ],
            1
          ),
      _vm._v(" "),
      _vm.showLatestRemoteDataButton
        ? _c(
            "button",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip:top",
                  value: `Warning: This feature only works on remote copied jobs`,
                  expression:
                    "`Warning: This feature only works on remote copied jobs`",
                  arg: "top",
                },
              ],
              staticClass:
                "btn btn-outline-primary float-right my-5 mr-2 ml-2 pr-2 pl-2",
              attrs: { disabled: _vm.busy },
              on: { click: _vm.getLatestData },
            },
            [
              _vm.busy
                ? _c("b-spinner", { staticClass: "mr-1", attrs: { small: "" } })
                : _vm._e(),
              _vm._v(" Get Latest Data For Job\n    "),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.jobUpdatedSuccessfully
        ? _c("label", { staticClass: "float-right p-5" }, [
            _c("span", { staticStyle: { color: "#00ff00" } }, [
              _vm._v(
                "Job was saved successfully, redirecting in " +
                  _vm._s(_vm.redirectCounter) +
                  " seconds."
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.jobCreatedSuccessfully
        ? _c("label", { staticClass: "float-right p-5" }, [
            _c("span", { staticStyle: { color: "#00ff00" } }, [
              _vm._v(
                "Job was created successfully, redirecting in " +
                  _vm._s(_vm.redirectCounter) +
                  " seconds."
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Pad location/name "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("System name (example: FS02) "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Latitude "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Longitude "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Shift start (example: 5.5 is 5:30am and 5:30pm) "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Hour offset to UTC (example: -6 for Alberta in summer) "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Projected completed stages (per day)"),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", [
      _vm._v("Company "),
      _c("span", { staticStyle: { color: "red" } }, [_vm._v("*")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }