<template>
    <b-modal 
        v-model="isModalVisible"
        ref="resolve-modal" 
        @hidden="$emit('onDismiss')"
        size="xl"
        hide-header-close
        header-class="border-bottom-0 justify-content-center  p-0"
        body-class="pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg"
    >
        <template #modal-header>
            <div class="col w-100">
                <div class="modal-header">
                    <div class="font-large w-100 text-center"> {{ modalTitle }}</div>
                </div>
            </div>
        </template>
        <template #default>
            <div class="px-2">
                <div class="d-flex align-items-center py-2">
                    <div class="px-3 "> Resolution </div>
                    <select class="form-control" v-model="dropDownValue" :disabled="isDisabledHeader">
                        <option
                            v-for="(option, index) in dropDownOptions"
                            :disabled="option.disabled"
                            :key="index"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </select>
                </div>
                <div v-if="dropDownValue" class="d-flex py-4">
                    <div class="col">
                        <b-card
                            :title="modalType==='missed-plug'? 'Plug' : 'Shot'"
                            class="inner-bg"
                            title-tag="h5"
                        >
                             <div class="d-flex py-2  justify-content-between">
                                <div class="col pt-1 px-0">
                                    Top Depth (ftKB) 
                                </div> 
                                <div class="col-8 px-2">
                                    <div class="input-group">
                                        <input
                                            :disabled="modalType === 'additional-shot'"
                                            v-model="topDepth"
                                            class="form-control"
                                            type="number"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text">ft</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between">
                                <div class="col pt-1 px-0">
                                    Shot Fired/Plug Set Date Time
                                </div>
                                <div class="col-8 px-2 input-group" >
                                    <input
                                        :disabled="modalType === 'additional-shot'"
                                        v-model="shotDateTime"
                                        type="datetime-local"
                                        step="1"
                                        class="form-control"
                                        placeholder="yyyy-mm-dd hh:mm:ss"
                                    >
                                </div>
                            </div>
                            <div v-if="modalType === 'missed-plug'" class="d-flex py-2  justify-content-between">
                                <div class="col pt-1 px-0">
                                    Plug condition
                                </div>
                                <div class="col-8 px-2 input-group" >
                                    <select class="form-control" v-model="plugCondition">
                                        <option
                                            v-for="(option, index) in [{ value: 1, text: 'New'}, { value: 0, text: 'Used'}]"
                                            :key="index"
                                            :value="option.value"
                                        >
                                            {{ option.text }}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between">
                                <div class="col pt-1 px-0">
                                    Well
                                </div>
                                <div class="col-8 px-2 input group">
                                    <input
                                        class="form-control"
                                        type="text"
                                        :value="wellName"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between">
                                <div class="col pt-1 px-0">
                                    Interval
                                </div>
                                <div class="col-8 input-group px-2">
                                    <input
                                        class="form-control"
                                        type="text"
                                        :value="stageNumber"
                                        disabled
                                    />
                                </div>
                            </div>
                        </b-card>
                    </div>
                    <div class="col px-0">
                        <b-card
                            title="Toolstring"
                            v-if="modalType==='missed-plug'"
                            class="inner-bg"
                            title-tag="h5"
                            body-class="mr-0"
                        >
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Plug Type
                                </div> 
                                <div class="col-8 px-2">
                                    <div class="input-group">
                                        <select class="form-control" v-model="plugType" disabled>
                                            <option
                                                v-for="(option, index) in dropdownTypeOptions"
                                                :key="index"
                                                :value="option.value"
                                            >
                                                {{ option.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Planned Depth
                                </div> 
                                <div class="col-8 px-2">
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            v-model="plannedDepth"
                                            type="number"
                                            disabled
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text">ft</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </b-card>
                        <b-card
                            title="Toolstring"
                            v-if="modalType!=='missed-plug'"
                            class="inner-bg"
                            title-tag="h5"
                            body-class="mr-0"
                        >
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Gun Type
                                </div> 
                                <div class="col-8 px-2">
                                    <div class="input-group">
                                        <select class="form-control" v-model="gunType" :disabled="modalType !== 'additional-shot'">
                                            <option
                                                v-for="(option, index) in dropdownTypeOptions"
                                                :key="index"
                                                :value="option.value"
                                            >
                                                {{ option.text }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Distance
                                </div> 
                                <div class="col-8 px-2">
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            v-model="distance"
                                            type="number"
                                            :disabled="modalType !== 'additional-shot'"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text">ft</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Planned Depth
                                </div> 
                                <div class="col-8 px-2">
                                    <div class="input-group">
                                        <input
                                            class="form-control"
                                            v-model="plannedDepth"
                                            type="number"
                                            :disabled="modalType !== 'additional-shot'"
                                        />
                                        <div class="input-group-append">
                                            <span class="input-group-text">ft</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Gun Number
                                </div> 
                                <div class="col-8 input-group px-2">
                                    <input
                                        class="form-control"
                                        type="text"
                                        :value="gunNumber"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    SPF
                                </div> 
                                <div class="col-8 input-group px-2">
                                    <input
                                        class="form-control"
                                        type="text"
                                        :value="selectedGun.shot_density"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Phasing Shift
                                </div> 
                                <div class="col-8 input-group px-2">
                                    <input
                                        class="form-control"
                                        type="text"
                                        :value="selectedGun.phasing"
                                        disabled
                                    />
                                </div>
                            </div>
                            <div class="d-flex py-2  justify-content-between"> 
                                <div class="col pt-1 px-0">
                                    Charge Type
                                </div> 
                                <div class="col-8 input-group px-2">
                                    <input
                                        class="form-control"
                                        type="text"
                                        :value="selectedGun.charge_make"
                                        disabled
                                    />
                                </div>
                            </div>
                        </b-card>
                    </div>
                </div>
                <div v-else class="px-3 py-2">
                    * Declining the alert will remove the alert and will not modify any toolstring or shot information
                </div>
            </div>
        </template>
        <template #modal-footer>
            <div class="d-flex w-100  justify-content-center align-items-center px-2 pb-2">
                <div class="flex-grow-1"></div>
                <button type="button" class="btn btn-secondary grey-button" @click="()=>{ $emit('onDismiss') }" >Cancel</button>
                <div class="px-2"></div>
                <button type="button" :class="`btn ${dropDownValue? 'btn-success' : 'btn-danger'}`" @click.prevent="onSubmitPressed">
                    <div>
                        {{ dropDownValue? 'Resolve Alert': 'Decline Alert'}}
                    </div>
                </button>
            </div>
        </template>
    </b-modal>
</template>
<style scoped>
    .form-control:disabled {
        background-color: lightgray;
    }
</style>
<script>

import moment from 'moment';

export default {
    data() {
        return {
            errors: [],
            topDepth: null,
            distance: null,
            shotDateTime: null,
            dropDownValue: true,
            plannedDepth: null,
            gunType: null,
            plugType: null,
            plugCondition: null,
            toolstringGun: null
        };
    },
    props: [
        'isVisible',
        'guns',
        'plugs',
        'modalType',
        'gunNumber',
        'toolstring',
        'stageToolstring',
        'isDisabledHeader',
        'shot',
        'wellIndex',
        'stageNumber',
        'hourOffset',
        'wellName'
    ],
    watch: {
        toolstring: function (newVal) { 
            if(newVal) {
                this.plannedDepth = newVal.toolstring_details.plannedDepth;
                this.toolstringGun = newVal.toolstring_details.gun;
                this.distance = newVal.toolstring_details.distance;
                this.gunType = newVal.toolstring_details.gun.id;
                this.plugType = newVal.plug?.id;
            } else {
                this.gunType = this.guns[0].id;
            }
        },
        shot: function (newVal) { 
            if (newVal) {
                this.topDepth = newVal.topDepth;
                this.shotDateTime = moment(newVal.created_at, 'YYYY-MM-DD HH:mm:ss').add({ hours: this.hourOffset }).format('YYYY-MM-DDTHH:mm:ss');
            }
        }
    },
    computed: {
        modalTitle() {
            switch (this.modalType) {
            case 'additional-shot':
                return 'Resolve Additional Shot';
            case 'missed-shot':
                return 'Resolve Missed Shot';
            case 'missed-plug':
                return 'Resolve Missed Plug';
            default:
                return null;
            }
        },
        dropDownOptions() {
            if(this.modalType === 'additional-shot') {
                return [{value: true, text: 'Submit missing toolstring' }, {value: false , text: 'Decline additional shot alert' }];
            } else if(this.modalType === 'missed-shot') {
                return [{value: true, text: 'Submit missing shot' }, {value: false , text: 'Decline missing shot alert' }];
            } else {
                return [{value: true, text: 'Submit missing plug' }, {value: false , text: 'Decline missing plug alert' }];
            }
        },
        selectedGun() {
            return this.guns.find((gun) => gun.id === this.gunType);
        },
        selectedPlug() {
            return this.plugs.find((plug) => plug.id === this.plugType);
        },
        isModalVisible: {
            get() {
                return this.isVisible;
            },
            set() {} 
        },
        dropdownTypeOptions() {
            const guns = this.guns.map((gun)=> { return {...gun, text: gun.name, value: gun.id}; });
            if(this.modalType === 'missed-plug') {
                const plugs = this.plugs.map((plug)=> { return {...plug, text: plug.description, value: plug.id}; });
                return plugs;
            }
            return guns;
        }
    },
    mounted() {},
    methods: {
        onSubmitPressed() {
            this.errors = [];

            let data = null;
            if(this.dropDownValue == false) { //decline alert
                let alertCategory = 'shots';
                let declinedGunNumber = this.gunNumber;
                const declinedWellIndex = this.wellIndex;
                const declinedStageNumber = this.stageNumber;

                if(this.modalType === 'missed-plug') {
                    alertCategory = 'plugs';
                    declinedGunNumber = null;
                }


                this.$emit('onDeclineSubmit', {
                    alertCategory,
                    gunNumber: declinedGunNumber,
                    wellNumber: declinedWellIndex,
                    stageNumber: declinedStageNumber
                });

                return;
            }



            if(this.modalType === 'additional-shot') {
                if(this.gunType !==0 && !this.gunType) {
                    this.errors.push('Gun type cannot be empty');
                }

                if(this.distance !==0 && !this.distance) {
                    this.errors.push('Distance cannot be empty');
                }

                if(this.plannedDepth !==0 && !this.plannedDepth) {
                    this.errors.push('Planned depth cannot be empty');
                }

                const toolstringInfo = {
                    wellIndex: this.wellIndex,
                    stageNumber: this.stageNumber,
                    gunNumber: this.gunNumber,
                    toolstringId: this.stageToolstring.id,
                    distance: this.distance,
                    gunId: this.gunType,
                    plannedDepth: this.plannedDepth
                };
                data = toolstringInfo;
            } else if(this.modalType === 'missed-shot') {
                if(this.topDepth !==0 && !this.topDepth) {
                    this.errors.push('Top Depth cannot be empty');
                }

                if(this.shotDateTime !==0 && !this.shotDateTime) {
                    this.errors.push('Shot fired/Plug set date time cannot be empty');
                }

                const utcTime = moment.utc(this.shotDateTime, 'YYYY-MM-DDTHH:mm:ss').subtract({ hours: this.hourOffset }).valueOf();
                const shotInfo = {
                    dateTime: utcTime,
                    type: 'shot',
                    wellIndex: this.wellIndex,
                    stageNumber: this.stageNumber,
                    topDepth: this.topDepth,
                    gunNumber: this.gunNumber
                };
                data = shotInfo;
            } else {
                if(this.topDepth !==0 && !this.topDepth) {
                    this.errors.push('Depth cannot be empty');
                }

                if(this.shotDateTime !==0 && !this.shotDateTime) {
                    this.errors.push('Shot fired/Plug set date time cannot be empty');
                }

                if(this.plugCondition !==0 && !this.plugCondition) {
                    this.errors.push('Plug condition cannot be empty');
                }

                const utcTime = moment.utc(this.shotDateTime, 'YYYY-MM-DDTHH:mm:ss').subtract({ hours: this.hourOffset }).valueOf();
                const plugInfo = {
                    dateTime: utcTime,
                    type: 'plug',
                    wellIndex: this.wellIndex,
                    stageNumber: this.stageNumber,
                    plugCondition: this.plugCondition,
                    topDepth: this.topDepth
                };
                data = plugInfo;
            }

            if(this.errors.length === 0) {
                this.$emit('onSubmit', this.modalType, data);
            } else {
                alert(this.errors.join('\n'));
            }
        }
    }
};
</script>