<template>
<div class="dark-modal modal"
    :id="`plug_view_${id}`"
    :ref="`plug_view_${id}`"
    role="document"
>
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalCenterTitle">
                    View Plug
                </h5>

                <button type="button" class="close" @click="hide">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="field-container">
                    <label>
                        Description
                    </label>
                    <div>
                        {{  plug.description }}
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Customer Name
                            </label>
                            <div>
                                {{  plug.customerName }}
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Icon
                            </label>
                            <div>
                                {{  plug.icon }}
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Length (in)
                            </label>
                            <div>
                                {{  plug.length }}
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Outer Diameter (in)
                            </label>
                            <div>
                                {{  plug.outer_diameter }}
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Inner Diameter (in)
                            </label>
                            <div>
                                {{  plug.inner_diameter }}
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Equipment Type
                            </label>
                            <div>
                                {{  plug.equipment_type }}
                            </div>
                        </div>
                    </div>
                </div>

                <hr>

                <div class="row">
                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Make
                            </label>
                            <div>
                                {{  plug.make }}
                            </div>
                        </div>
                    </div>

                    <div class="col-6">
                        <div class="field-container">
                            <label>
                                Model
                            </label>
                            <div>
                                {{  plug.model }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    props: {
        id: {
            type: String,
            required: true
        },
        enableCustomerCol: Boolean
    },

    data: () => ({
        plug: {}
    }),

    methods: {
        open(plug) {
            this.plug = { ...plug };

            const modalElem = $(`#plug_view_${this.id}`);
            const ref = this.$refs[`plug_view_${this.id}`];

            modalElem.show();

            modalElem.on('click', function(event) {
                // users can click the background to cancel out
                if (ref === event.target)
                    modalElem.hide();
            });
        },
        hide() {
            $(`#plug_view_${this.id}`).hide();
        }
    }
};
</script>

<style scoped>
    .modal-dialog {
        width: 600px !important;
        max-width: 90vw !important;
    }
    
    .dark-modal .modal-body {
        overflow-y: auto;
        /* Limit the height to leave some space on top after accounting for the header and footer */
        max-height: calc(90vh - var(--modal-header-height));
    }

    .field-container {
        margin-bottom: 15px;
    }
    .field-container label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 100%;

        color: #C9C9C9;

        margin-bottom: 0px !important;
    }
    .field-container>div {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px; 
        color: var(--primary-text-color);
    }
    
    hr {
        background-color: #7B8A98;
    }
</style>