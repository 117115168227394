<!--
    * Component Description
    Modal for adding or editing plugs. Not meant for reuse
-->

<template>
<library-add-edit-base id="libraryAddEditBase" ref="libraryAddEditBase"
    :item="plug"

    :updateAction="savePlug"
    :createAction="createPlug"
>
    <template #title>
        {{ isEditMode(plug) ? 'Edit Plug' : 'Add New Plug'}}
    </template>

    <template #leftBody>
        <div class="field-container">
            <iws-input ref="descriptionInput"
                label="Description *"
                :value.sync="plug.description"
                required="true"
                error-message="Please provide a description"
            />
        </div>
        
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="lengthInput"
                        label="Length (in) *"
                        :value.sync="plug.length"
                        required="true"
                        error-message="Please provide a length"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div v-if="enableCustomerCol && !!customers && customers.length > 1" class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-select
                        label="Customer Name *"
                        ref="customerName"
                        :value.sync="plug.customer_id"
                        :options="customers"
                        value-name="id"
                        display-name="name"
                        required="required"
                    />
                </div>
            </div>
        </div>
    </template>

    <template #rightBody>
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="iconField"
                        label="Icon"
                        :value.sync="plug.icon"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="outer_diameter"
                        label="Outer Diameter (in)"
                        :value.sync="plug.outer_diameter"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="customerNameField"
                        label="Inner Diameter (in)"
                        :value.sync="plug.inner_diameter"
                        type="number" min="0"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="equipment_type"
                        label="Equipment Type"
                        :value.sync="plug.equipment_type"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="makeField"
                        label="Make"
                        :value.sync="plug.make"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-6">
                <div class="field-container">
                    <iws-input ref="modelField"
                        label="Model"
                        :value.sync="plug.model"
                    />
                </div>
            </div>
        </div>
    </template>
</library-add-edit-base>
</template>
    
<script>
import GlobalFunctions from '../GlobalFunctions.js';

import LibraryAddEditBase from './LibraryAddEditBase.vue';

export default {
    extends: LibraryAddEditBase,

    props: {
        id: {
            type: String,
            required: true
        },
        enableCustomerCol: Boolean
    },

    components: { LibraryAddEditBase },

    data: () => ({
        plug: {}
    }),

    methods: {	
        createPlug() {
            return $.ajax({
                url: `/plugs`,
                method: 'POST',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    '_method': 'POST',
                    'originPage': `${location.origin}/plugs`,
                    ...this.plug,
                    id: 0
                },
                dataType: 'json'
            }).done((msg) => {
                if (msg.error) {
                    console.warn('Failed to save chart header state:' + JSON.stringify(msg));
                    if (msg.status == 401)
                        console.warn('unauthorized');
                } else {
                    location.reload();
                }
            });
        },
        savePlug() {
            return $.ajax({
                url: `/plugs/${this.plug.id}`,
                method: 'POST',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    '_method': 'PUT',
                    'originPage': `${location.origin}/plugs`,
                    ...this.plug
                },
                dataType: 'json'
            }).done((msg) => {
                if (msg.error) {
                    console.warn('Failed to save chart header state:' + JSON.stringify(msg));
                    if (msg.status == 401)
                        console.warn('unauthorized');
                } else {
                    location.reload();
                }
            });
        },

        open(plug) {
            this.fetchCustomers().done(response => {
                const _customers = response || [];

                // For new plugs, if there is only 1 customer (i.e. a customer is viewing this), just default to that single option
                if (!this.isEditMode(plug) && _customers.length == 1) {
                    this.plug.customer_id = _customers[0].id;
                    this.plug.customerName = _customers[0].name;
                }

                this.customers = _customers;
            });

            this.plug = { ...plug };

            return this.$refs.libraryAddEditBase.open();
        }
    }
};
</script>