<template>
  <div>
    <table class="table table-sm text-dark">
      <thead>
        <tr>
          <th scope="col" style="font-size:small;" v-for="(header,index) of headers" :key="'header'+header.id">
            <input type="checkbox" v-if="index == 0 && copyIndex!=null" v-model="selectAll" @change="updateSelectAll">
            <input type="checkbox" v-if="header.selectable" v-model="header.selected">
            {{header.val}}
            <span class="btn btn-sm btn-primary" v-if="index==0 && copyIndex != null && numberOfRowsSelected>0" @click="copyToSelected">Copy to selected</span>
            <span class="badge badge-pill badge-info" v-if="index==0 && copyIndex != null && numberOfRowsSelected==0">Select destination rows</span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row,rowIndex) of rows" :key="'row'+row.id" :class="{'bg-warning text-light':row.unsavedChanges}">
          <td v-for="(value,valueIndex) of row.values" :key="'val'+value.id" style="padding:0.2rem;">
            <span class="fas fa-copy" v-if="valueIndex==0 && (copyIndex == null || copyIndex==rowIndex) && !row.unsavedChanges" role='button' @click="copy(rowIndex)"></span>
            <span class="fas fa-save" v-if="valueIndex==0 && row.unsavedChanges" role='button' @click="save(items[rowIndex],row)"></span>
            <span class="fas fa-search" v-if="headers[valueIndex].val=='job_jobNumber' && type=='well'" role='button' @click="openSelectJobModal(rowIndex)"></span>
            <input v-if="valueIndex==0 && copyIndex != rowIndex && copyIndex != null" type="checkbox" v-model="row.selected">
            <a v-if="valueIndex==0" :href="`/metadata/${type}/${value.id}`">{{value.value}}</a>
            <span class="badge badge-pill badge-warning" v-if="valueIndex==0 && row.saving">saving</span>
            <span v-if="valueIndex != 0 && value.readOnly">
              {{value.value}}
            </span>
            <input type="text" v-if="valueIndex != 0 && !value.readOnly" class="form-control form-control-sm bg-light text-dark" :value="value.value" 
              @change="updateValue($event,items[rowIndex],value,row)" :disabled="row.saving" style="height:calc(1.5em + 2px);border-style:none;">
          </td>
        </tr>
      </tbody>
    </table>
    <div class="modal fade" id="selectJobModal" tabindex="-1" aria-labelledby="selectJobModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content text-light">
          <div class="modal-header">
            <h5 class="modal-title" id="selectJobModalLabel">Select Job</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            Select job
            <div class="form-group">
              <label>Job</label>
              <select class="form-control" v-model="selectedJobIndex">
                <option v-for="(job, jobIndex) of jobs" :key="'job'+job.id" :value="jobIndex">{{job.jobNumber}}</option>
              </select>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="setJobNumber">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {v4 as uuidv4} from "uuid"
  export default {
    data() {
      return {
        copyIndex:null,
        customerId:null,
        headers:[],
        id:null,
        items:[],
        jobs:[],
        jobSelectRow:null,
        rows:[],
        saving:false,
        selectAll:false,
        selectedJobIndex:null,
        structure:[],
        type:null,
        test:uuidv4(),
        valueLocations:[]
      }
    },
    props:{
      csrfToken:String,
      propData:String
    },
    mounted(){
      console.log(`mounted`)
    },
    methods:{
      buildHeadersAndRows(){
        this.headers = []
        let self = this
        let headers = []
        self.traverseStructure(self.structure,self,false,"[]")
        console.log(`done and valueLocations is now: ${JSON.stringify(self.valueLocations)}`)
        self.updateRows()
      },
      traverseStructure(structArray,self,forceTraverse,breadcrumb){
        
        for(const struct of structArray){
          //console.log(`looking at struct ${JSON.stringify(struct)} with breadcrumb: ${JSON.stringify(myBreadcrumb)}`)
          let myBreadcrumb = JSON.parse(breadcrumb)
          if(struct.hasOwnProperty('dataType')){
            //reached a leaf node
            myBreadcrumb.push(struct.name)
            //console.log(`leaf node with final breadcrumb of ${JSON.stringify(myBreadcrumb)}`)
            let header = {
              id:uuidv4(),
              val:myBreadcrumb.join('_'),
              selected:false,
              selectable:true
            }
            //console.log(`found a leaf node: ${JSON.stringify(struct)}, should add header: ${JSON.stringify(header)}`)
            if(struct.name == 'name' || struct.name == 'number'){
              //console.log(`should be first header`)
              //this should be the first header
              header.selectable = false
              self.headers.splice(0,0,header)
              //console.log(`self.headers: ${JSON.stringify(self.headers)}`)
              self.valueLocations.splice(0,0,{
                location:myBreadcrumb,
                readOnly:true
              })
            }else{
              //console.log(`not necessarily the first header`)
              self.headers.push(header)
              self.valueLocations.push({
                location:myBreadcrumb,
                readOnly:false
              })
            }
          }else{
            //not at a leaf node yet
            if(struct.name.toLowerCase().includes('planned') || forceTraverse || struct.name.toLowerCase().includes('job')){
              myBreadcrumb.push(struct.name)
              myBreadcrumb = JSON.stringify(myBreadcrumb)
              //console.log(`struct ${JSON.stringify(struct)} is a design branch and should be traversed with breadcrumb: ${myBreadcrumb}`)
              self.traverseStructure(struct.children,self,true,myBreadcrumb)
            }
          }
        }
      },
      copy(index){
        for(const row of this.rows){
          row.selected = false
        }
        if(this.copyIndex == null){
          console.log(`should copy row index ${index}`)
          this.copyIndex = index
        }else{
          this.copyIndex = null
        }
      },
      copyToSelected: async function(){
        let self = this
        let i = 0
        let sourceData = this.items[this.copyIndex].data
        let destData
        for(const row of this.rows){
          if(row.selected){
            destData = self.items[i].data
            for(const header of self.headers){
              if(header.selectable && header.selected){
                //this is something that should be copied over
                sourceData = this.items[this.copyIndex].data
                destData = self.items[i].data
                let loc = header.val.split('_')
                let j = 0
                for(const l of loc){
                  if(j+1 == loc.length){
                    destData[l] = sourceData[l]
                  }else{
                    destData = destData[l]
                    sourceData = sourceData[l]
                  }
                  j++
                }
              }
            }
            console.log(`should save item: ${JSON.stringify(this.items[i])}`)
            await this.save(this.items[i],row)
          }
          i++
        }
        self.updateRows()
        self.copyIndex = null
        self.selectAll = false
      },
      getTypeStructure: async function(){
        let self = this
        console.log(`should get structure for type ${this.type}`)
        $.get(`/metadataStructure/${this.type}`,function(data){
          console.log(`got data: ${JSON.stringify(data)}`)
          self.structure = data
          self.buildHeadersAndRows()
        }),
        'json'
      },
      getJobList(){
        let self = this
        if(this.customerId!=null){
          $.get(
            `/getJobsInfo?customer_id=${this.customerId}&active=false&completed=false&projected=true`,
            function(data){
              self.jobs = data.jobs
            },
            'json'
          )
        }
      },
      openSelectJobModal(index){
        $('#selectJobModal').modal('show')
        this.jobSelectRow = index
        this.getJobList()
      },
      save: async function(item,row){
        let self = this
        let id = row.id
        let dataToSave = item.data
        row.saving = true
        console.log(`should save type: ${this.type}, id: ${id} and data: ${JSON.stringify(dataToSave)}`)
        dataToSave['_token'] = this.csrfToken
        let resp = await $.post(
          `/metadata/${this.type}/${id}`,
          dataToSave
        )
        console.log(`save response: ${JSON.stringify(resp)}`)
        row.saving = false
        row.unsavedChanges = false
      },
      setJobNumber(){
        let self = this
        let job = self.jobs[self.selectedJobIndex]
        console.log(`should set row ${this.jobSelectRow} jobNumber to id of ${job.id} and jobNumber ${job.jobNumber}`)
        $('#selectJobModal').modal('hide')
        self.items[self.jobSelectRow].data.job = {
          id:job.id,
          jobNumber:job.jobNumber
        }
        self.rows[self.jobSelectRow].unsavedChanges = true
        let i = 0
        for(const header of self.headers){
          if(header.val == 'job_id')
            self.rows[self.jobSelectRow].values[i].value = job.id
          if(header.val == 'job_jobNumber')
            self.rows[self.jobSelectRow].values[i].value = job.jobNumber
          i++
        }
        self.selectedJobIndex = null
      },
      updateRows(){
        console.log(`executing update of rows`)
        let self = this
        self.rows = []
        for(const item of self.items){ //iterate through all items
          let row = {
            id:item.id,
            saving:false,
            selected:false,
            unsavedChanges:false,
            values:[]
          }
          for(const valueLocation of self.valueLocations){ //iterate through the location of all values
            let i = 0
            let d = item.data
            for(const loc of valueLocation.location){ //iterate through location hierarchy for a property
              if(!d.hasOwnProperty(loc)){
                //should create this object/property
                if(i+1 == valueLocation.location.length){
                  d[loc] = "0"
                }else{
                  d[loc] = {}
                }
              }
              if(i+1 == valueLocation.location.length){
                let v = {
                  id:uuidv4(),
                  location:valueLocation.location,
                  value:d[loc],
                  readOnly:false
                }
                if(loc == 'number' || loc == 'name'){
                  v.readOnly = true
                  v.id = item.id
                }
                row.values.push(v)
              }
              d = d[loc]
              i++
            }
          }
          self.rows.push(row)
        }
      },
      updateSelectAll(){
        console.log(`should update select all with value ${this.selectAll}`)
        let i = 0
        for(const row of this.rows){
          if(i++ != this.copyIndex)
            row.selected = this.selectAll
        }
      },
      updateValue:async function(event,item,value,row){
        value.value = event.target.value
        console.log(`changed value: ${event.target.value}, should update item ${JSON.stringify(item)} with value info ${JSON.stringify(value)}`)
        let ref = item.data
        for(let i=0;i<value.location.length;i++){
          if(i+1==value.location.length){
            //one away from the leaf node!
            ref[value.location[i]] = event.target.value
          }else{
            ref = ref[value.location[i]]
          }
        }
        console.log(`updated data: ${JSON.stringify(item.data)}`)
        row.unsavedChanges = true
        //this.save(item,row)
      }
    },
    watch:{
      propData:function(val){
        let t = JSON.parse(val)
        this.items = t.children
        if(this.items.length>0)
          this.customerId = this.items[0].customer_id
        this.rows = []
        this.type = t.name
        this.getTypeStructure()
        this.id = t.id
      }
    },
    computed:{
      numberOfRowsSelected(){
        let num = 0
        for(const row of this.rows){
          if(row.selected)
            num++
        }
        return num
      }
    }
  }
</script>