var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: {
            show: _vm.isBusy.busy,
            variant: "light",
            blur: "5px",
            opacity: "0.90",
          },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center" },
                    [
                      _c("b-spinner", {
                        staticClass: "align-middle",
                        attrs: { large: "" },
                      }),
                      _vm._v(" "),
                      _c("p", { staticClass: "mt-2" }, [
                        _vm._v(
                          _vm._s(_vm.isBusy.state) +
                            ", this may take a while..."
                        ),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c("div", { staticClass: "page-title mt-2 mb-4 px-5 pt-0" }, [
            _vm._v(
              "\n            " +
                _vm._s(
                  _vm.type == "frac"
                    ? "Frac Stage Summary"
                    : "Wireline Stage Summary"
                ) +
                "\n        "
            ),
          ]),
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "div",
            [
              _c(
                "b-popover",
                {
                  attrs: {
                    target: "approve-all",
                    triggers: "hover",
                    placement: "right",
                    delay: { show: 0, hide: 500 },
                  },
                },
                [
                  _c("div", { staticClass: "btn-group-vertical" }, [
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-success green-button mb-2",
                        attrs: { disabled: _vm.approvalsDisabled },
                        on: {
                          click: function ($event) {
                            return _vm.changeReportStatus(true, true)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Approve all\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-danger",
                        attrs: { disabled: _vm.approvalsDisabled },
                        on: {
                          click: function ($event) {
                            return _vm.changeReportStatus(true, false)
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Unapprove all\n                    "
                        ),
                      ]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "px-5 pt-0" }, [
                _c(
                  "div",
                  { staticClass: "pb-3 d-flex justify-content-between" },
                  [
                    _vm.wells.length > 0
                      ? _c("div", { staticClass: "col-4" }, [
                          _c("div", { staticClass: "row" }, [
                            _c("div", { staticClass: "cols-2 pr-2" }, [
                              _vm._v(
                                "\n                                " +
                                  _vm._s("Well:") +
                                  "\n                            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "col-9" }, [
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedWell,
                                      expression: "selectedWell",
                                    },
                                  ],
                                  staticClass: "custom-select link-txt-color",
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selectedWell = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      _vm.onWellUpdated,
                                    ],
                                  },
                                },
                                _vm._l(_vm.wells, function (well, index) {
                                  return _c(
                                    "option",
                                    {
                                      key: index,
                                      attrs: { target: well.index },
                                      domProps: { value: well },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(well.nameLong) +
                                          " (" +
                                          _vm._s(well.name) +
                                          ")"
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "col-4 pl-5" }, [
                      _c("div", { staticClass: "row justify-content-end" }, [
                        _c(
                          "div",
                          {
                            staticClass: "col-2 px-0 text-right",
                            staticStyle: { "min-width": "130px" },
                          },
                          [
                            _c("b-table-export-component", {
                              attrs: {
                                jobNumber: _vm.jobNumber,
                                wellIndex: _vm.selectedWell.index,
                                haswvxmlExport: this.type === "frac",
                                items: _vm.exportData,
                                fields: _vm.exportFields,
                                fileName: _vm.exportFileName,
                              },
                            }),
                            _vm._v(" "),
                            _c("input", {
                              ref: "importInput",
                              staticStyle: { display: "none" },
                              attrs: { type: "file", accept: ".csv" },
                              on: {
                                change: function ($event) {
                                  return _vm.handleImport($event)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "col-2 px-0 text-right",
                            staticStyle: { "min-width": "130px" },
                          },
                          [
                            _c("iws-button", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value: "Import CSV",
                                  expression: "'Import CSV'",
                                  arg: "top",
                                },
                              ],
                              staticClass: "ml-3",
                              attrs: { type: "link" },
                              on: {
                                click: function ($event) {
                                  return _vm.$refs.importInput.click()
                                },
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "text",
                                  fn: function () {
                                    return [
                                      _c("import-icon"),
                                      _vm._v(
                                        " Import\n                                    "
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ]),
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _vm.mapDataToTable.length
                      ? _c("b-table", {
                          ref: "stageTable",
                          attrs: {
                            "no-border-collapse": "",
                            striped: "",
                            hover: "",
                            dark: "",
                            small: "",
                            responsive: "",
                            "sticky-header": "80vh",
                            items: _vm.mapDataToTable,
                            fields: _vm.mapTableTitles,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "head(stage_number)",
                                fn: function (data) {
                                  return [
                                    _vm.canMassApprove &&
                                    _vm.isFeatureFlagged("STAGE_SUMMARY_IMPORT")
                                      ? _c(
                                          "span",
                                          {
                                            staticClass: "pl-4 float-left",
                                            attrs: { id: "approve-all" },
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "fas fa-paper-plane clickable",
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "p",
                                      { staticClass: "float-right m-0" },
                                      [_vm._v(_vm._s(data.label))]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(stage_number)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "container pl-0" },
                                      [
                                        _c("div", [
                                          !_vm.sendReportDisabled(
                                            _vm.selectedWell.index,
                                            data.item.stage_number,
                                            _vm.START_REPORT
                                          )
                                            ? _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "mr-1 btn btn-success green-button report-button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.sendReportToFunctionApp(
                                                          _vm.selectedWell
                                                            .index,
                                                          data.item
                                                            .stage_number,
                                                          _vm.START_REPORT,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Send Stage Start\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip:top",
                                                        value:
                                                          "Click to resend",
                                                        expression:
                                                          "'Click to resend'",
                                                        arg: "top",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "mr-1 btn btn-secondary gray-button report-button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.enableSendReport(
                                                          _vm.selectedWell
                                                            .index,
                                                          data.item
                                                            .stage_number,
                                                          _vm.START_REPORT
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    !data?.item?.start_date ||
                                                    data?.item?.start_date ==
                                                      "-"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Send Stage Start"
                                                          ),
                                                        ])
                                                      : _c("p", [
                                                          _vm._v(
                                                            "Stage Start Sent"
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                              ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          !_vm.sendReportDisabled(
                                            _vm.selectedWell.index,
                                            data.item.stage_number,
                                            _vm.END_REPORT
                                          )
                                            ? _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    staticClass:
                                                      "mr-1 btn btn-success green-button report-button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.sendReportToFunctionApp(
                                                          _vm.selectedWell
                                                            .index,
                                                          data.item
                                                            .stage_number,
                                                          _vm.END_REPORT,
                                                          true
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                                            Send Stage End\n                                        "
                                                    ),
                                                  ]
                                                ),
                                              ])
                                            : _c("div", [
                                                _c(
                                                  "button",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip:top",
                                                        value:
                                                          "Click to resend",
                                                        expression:
                                                          "'Click to resend'",
                                                        arg: "top",
                                                      },
                                                    ],
                                                    staticClass:
                                                      "mr-1 btn btn-secondary gray-button report-button",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.enableSendReport(
                                                          _vm.selectedWell
                                                            .index,
                                                          data.item
                                                            .stage_number,
                                                          _vm.END_REPORT
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    !data?.item?.end_date ||
                                                    data?.item?.end_date == "-"
                                                      ? _c("p", [
                                                          _vm._v(
                                                            "Send Stage End"
                                                          ),
                                                        ])
                                                      : _c("p", [
                                                          _vm._v(
                                                            "Stage End Sent"
                                                          ),
                                                        ]),
                                                  ]
                                                ),
                                              ]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm.isApproved(
                                            _vm.selectedWell.index,
                                            data.item.stage_number
                                          ) == "invalid"
                                            ? _c(
                                                "div",
                                                { staticClass: "d-flex" },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-success green-button report-button",
                                                      attrs: {
                                                        disabled:
                                                          _vm.approvalsDisabled,
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeReportStatus(
                                                            false,
                                                            true,
                                                            data
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                            Approve Stage\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm.isApproved(
                                                _vm.selectedWell.index,
                                                data.item.stage_number
                                              ) == "valid"
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-secondary gray-button report-button",
                                                  attrs: { disabled: "" },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                        Report Sent\n                                    "
                                                  ),
                                                ]
                                              )
                                            : _c("div", [_vm._v("Error")]),
                                        ]),
                                        _vm._v(" "),
                                        _c("div", [
                                          _vm.isStageAtypical(
                                            _vm.selectedWell.index,
                                            data.item.stage_number
                                          ) &&
                                          _vm.isApproved(
                                            _vm.selectedWell.index,
                                            data.item.stage_number
                                          ) == "invalid"
                                            ? _c("i", {
                                                staticClass:
                                                  "fas fa-exclamation-triangle ml-2",
                                                staticStyle: {
                                                  color: "orange",
                                                },
                                                attrs: {
                                                  id: `popover-stage-${data.item.stage_number}`,
                                                },
                                                on: {
                                                  mouseover: function ($event) {
                                                    return _vm.setPopoverAlertData(
                                                      _vm.selectedWell.index,
                                                      data.item.stage_number
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "fas fa-info-circle ml-2",
                                                attrs: {
                                                  id:
                                                    "popover-stage-" +
                                                    data.item.stage_number,
                                                },
                                                on: {
                                                  mouseover: function ($event) {
                                                    return _vm.setInfoHoveredData(
                                                      _vm.selectedWell.index,
                                                      data.item.stage_number,
                                                      data
                                                    )
                                                  },
                                                },
                                              }),
                                        ]),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "justify-content-between",
                                          },
                                          [
                                            !_vm.approvalsDisabled
                                              ? _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip:top",
                                                        value: "Copy Row Data",
                                                        expression:
                                                          "'Copy Row Data'",
                                                        arg: "top",
                                                      },
                                                    ],
                                                    staticClass: "col-4",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.copyRow(data)
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass:
                                                        "fa fa-copy clickable",
                                                    }),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    directives: [
                                                      {
                                                        name: "tooltip",
                                                        rawName:
                                                          "v-tooltip:top",
                                                        value: "Copy Row Data",
                                                        expression:
                                                          "'Copy Row Data'",
                                                        arg: "top",
                                                      },
                                                    ],
                                                    staticClass: "col-4",
                                                  },
                                                  [
                                                    _c("i", {
                                                      staticClass: "fa fa-copy",
                                                      staticStyle: {
                                                        color: "grey",
                                                      },
                                                    }),
                                                  ]
                                                ),
                                          ]
                                        ),
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(data.value) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(start_date)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticStyle: { "min-width": "130px" } },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(data.value) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(end_date)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      { staticStyle: { "min-width": "130px" } },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(data.value) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell()",
                                fn: function (data) {
                                  return [
                                    _vm.isApproved(
                                      _vm.selectedWell.index,
                                      data.item.stage_number
                                    ) == "invalid"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "b-row",
                                              [
                                                _c(
                                                  "b-col",
                                                  [
                                                    data.field.entryType ==
                                                    "NUMERIC"
                                                      ? _c("b-form-input", {
                                                          ref:
                                                            "editField-" +
                                                            data.field.key +
                                                            "-" +
                                                            data.item
                                                              .stage_number,
                                                          staticClass:
                                                            "darkmode-form",
                                                          staticStyle: {
                                                            width: "100px",
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              _vm.approvalsDisabled,
                                                            type: "number",
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.saveValue(
                                                                data
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: data.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                data,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "data.value",
                                                          },
                                                        })
                                                      : data.field.entryType ==
                                                        "TIME"
                                                      ? _c("div", [
                                                          data.value != null
                                                            ? _c("input", {
                                                                directives: [
                                                                  {
                                                                    name: "model",
                                                                    rawName:
                                                                      "v-model",
                                                                    value:
                                                                      data.value,
                                                                    expression:
                                                                      "data.value",
                                                                  },
                                                                ],
                                                                ref:
                                                                  "editField-" +
                                                                  data.field
                                                                    .key +
                                                                  "-" +
                                                                  data.item
                                                                    .stage_number,
                                                                staticClass:
                                                                  "darkmode-form",
                                                                attrs: {
                                                                  disabled:
                                                                    _vm.approvalsDisabled,
                                                                  type: "datetime-local",
                                                                  step: "1",
                                                                },
                                                                domProps: {
                                                                  value:
                                                                    data.value,
                                                                },
                                                                on: {
                                                                  change:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.saveValue(
                                                                        data
                                                                      )
                                                                    },
                                                                  input:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      if (
                                                                        $event
                                                                          .target
                                                                          .composing
                                                                      )
                                                                        return
                                                                      _vm.$set(
                                                                        data,
                                                                        "value",
                                                                        $event
                                                                          .target
                                                                          .value
                                                                      )
                                                                    },
                                                                },
                                                              })
                                                            : _c("div", [
                                                                _vm._v("-"),
                                                              ]),
                                                        ])
                                                      : _c("b-form-input", {
                                                          ref:
                                                            "editField-" +
                                                            data.field.key +
                                                            "-" +
                                                            data.item
                                                              .stage_number,
                                                          staticClass:
                                                            "darkmode-form",
                                                          staticStyle: {
                                                            width: "120px",
                                                          },
                                                          attrs: {
                                                            disabled:
                                                              _vm.approvalsDisabled,
                                                            type: "text",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.openModal(
                                                                "editField-" +
                                                                  data.field
                                                                    .key +
                                                                  "-" +
                                                                  data.item
                                                                    .stage_number,
                                                                data
                                                              )
                                                            },
                                                            change: function (
                                                              $event
                                                            ) {
                                                              return _vm.saveValue(
                                                                data
                                                              )
                                                            },
                                                          },
                                                          model: {
                                                            value: data.value,
                                                            callback: function (
                                                              $$v
                                                            ) {
                                                              _vm.$set(
                                                                data,
                                                                "value",
                                                                $$v
                                                              )
                                                            },
                                                            expression:
                                                              "data.value",
                                                          },
                                                        }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : data.field.entryType == "TEXT"
                                      ? _c(
                                          "div",
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "table-text-display",
                                                attrs: {
                                                  id:
                                                    data.field.key +
                                                    "-" +
                                                    data.item.stage_number,
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openModal(
                                                      "editField-" +
                                                        data.field.key +
                                                        "-" +
                                                        data.item.stage_number,
                                                      data,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    data.value != ""
                                                      ? data.value
                                                      : "-"
                                                  )
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            data.value != ""
                                              ? _c(
                                                  "b-tooltip",
                                                  {
                                                    attrs: {
                                                      target:
                                                        data.field.key +
                                                        "-" +
                                                        data.item.stage_number,
                                                      placement: "auto",
                                                      trigger: "hover",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          color: "white",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            data.value != ""
                                                              ? data.value
                                                              : "-"
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : data.field.entryType == "TIME"
                                      ? _c(
                                          "div",
                                          { staticStyle: { width: "150px" } },
                                          [
                                            _vm._v(
                                              "\n                                " +
                                                _vm._s(
                                                  _vm.formatDate(data.value)
                                                ) +
                                                "\n                            "
                                            ),
                                          ]
                                        )
                                      : _c("span", [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                data.value != ""
                                                  ? data.value
                                                  : "-"
                                              )
                                          ),
                                        ]),
                                  ]
                                },
                              },
                              {
                                key: "cell(vendor)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "table-text-display",
                                        staticStyle: { width: "120px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.vendorName) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                              {
                                key: "cell(uwi)",
                                fn: function (data) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "table-text-display",
                                        staticStyle: { width: "150px" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(_vm.selectedWell.well_UWI) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            3863279973
                          ),
                        })
                      : _c("div", { staticClass: "alert alert-warning" }, [
                          _vm._v(" Not enough data to show "),
                        ]),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _vm._l(_vm.mapDataToTable, function (targetRow, index) {
                return _c(
                  "div",
                  { key: index },
                  [
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          "custom-class": "popover-approvals",
                          target: "popover-stage-" + targetRow.stage_number,
                          boundary: "viewport",
                          triggers: "hover",
                          placement: "bottom",
                          delay: { show: 0, hide: 50 },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "mt-2 mb-2 font-weight-bold" },
                          [
                            _vm._v(
                              "Stage # " + _vm._s(targetRow.stage_number) + " :"
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.isStageAtypical(
                          _vm.selectedWell.index,
                          targetRow.stage_number
                        ) &&
                        _vm.isApproved(
                          _vm.selectedWell.index,
                          targetRow.stage_number
                        ) == "invalid"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            color: "black",
                                            "max-height": "200px",
                                          },
                                        },
                                        [
                                          _c("b-row", { staticClass: "px-3" }, [
                                            _c("p", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.popoverAlertData.message
                                                )
                                              ),
                                            ]),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-row",
                                  {
                                    staticClass: "px-1",
                                    attrs: { "align-h": "between" },
                                  },
                                  [
                                    ["admin", "iwsTech", "companyAdmin"].some(
                                      (item) =>
                                        _vm.userRoleNamesArray.includes(item)
                                    )
                                      ? _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "my-2 w-100",
                                                attrs: {
                                                  variant: "secondary",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.sendReportToFunctionApp(
                                                      _vm.selectedWell.index,
                                                      targetRow.stage_number
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    Regenerate Report\n                                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    ["admin", "iwsTech"].some((item) =>
                                      _vm.userRoleNamesArray.includes(item)
                                    ) && _vm.category == "frac"
                                      ? _c(
                                          "b-col",
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "my-2 w-100",
                                                attrs: {
                                                  variant: "secondary",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.runbackDataProcessor(
                                                      _vm.selectedWell.index,
                                                      targetRow.stage_number
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    Runback Data Processor\n                                "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "b-row",
                                  [
                                    _c("b-col", [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "scroll-div",
                                          staticStyle: {
                                            color: "black",
                                            "max-height": "200px",
                                          },
                                        },
                                        _vm._l(
                                          _vm.hoveredStageData,
                                          function (targetData, index) {
                                            return _c(
                                              "div",
                                              { key: index },
                                              [
                                                _c(
                                                  "b-row",
                                                  [
                                                    _c(
                                                      "b-col",
                                                      {
                                                        staticStyle: {
                                                          "font-size": ".65rem",
                                                        },
                                                        attrs: { cols: "3" },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            targetData["state"]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                    _vm._v(" "),
                                                    _c("b-col", [
                                                      _vm._v(
                                                        _vm._s(
                                                          targetData["user"]
                                                        )
                                                      ),
                                                    ]),
                                                    _vm._v(" "),
                                                    _c(
                                                      "b-col",
                                                      { attrs: { cols: "6" } },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            targetData[
                                                              "timestamp"
                                                            ]
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c("div", { staticClass: "text-right" }, [
                                  _vm.isApproved(
                                    _vm.selectedWell.index,
                                    targetRow.stage_number
                                  ) == "valid"
                                    ? _c(
                                        "button",
                                        {
                                          staticClass:
                                            "btn btn-danger mb-2 mt-3",
                                          on: {
                                            click: function ($event) {
                                              return _vm.changeReportStatus(
                                                false,
                                                false,
                                                _vm.targetPopoverStageData
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                Unapprove\n                            "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal fade",
                  attrs: {
                    id: "editTextModal",
                    tabindex: "-1",
                    role: "dialog",
                    "aria-labelledby": "editTextModalTitle",
                    "aria-hidden": "true",
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "modal-dialog modal-bg",
                      attrs: { role: "document" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "modal-content",
                          staticStyle: {
                            "background-color": "#393f4a!important",
                          },
                        },
                        [
                          _c("div", { staticClass: "modal-header" }, [
                            _vm.editModalData
                              ? _c(
                                  "h5",
                                  {
                                    staticClass: "modal-title",
                                    attrs: { id: "editTextModalTitle" },
                                  },
                                  [
                                    _vm._v(
                                      "Stage # " +
                                        _vm._s(
                                          _vm.editModalData.item.stage_number
                                        ) +
                                        " - " +
                                        _vm._s(_vm.editModalData.field.label)
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "modal-body" },
                            [
                              _c("b-form-textarea", {
                                staticClass: "darkmode-form",
                                attrs: {
                                  disabled: _vm.modalEditsDisabled,
                                  id: "textarea",
                                  lazy: "",
                                  rows: "6",
                                },
                                model: {
                                  value: _vm.editModalText,
                                  callback: function ($$v) {
                                    _vm.editModalText = $$v
                                  },
                                  expression: "editModalText",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "modal-footer" }, [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-secondary",
                                attrs: {
                                  type: "button",
                                  "data-dismiss": "modal",
                                },
                              },
                              [_vm._v("Close")]
                            ),
                            _vm._v(" "),
                            !_vm.modalEditsDisabled
                              ? _c(
                                  "button",
                                  {
                                    staticClass: "btn btn-success green-button",
                                    attrs: { type: "button" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.saveEditText()
                                      },
                                    },
                                  },
                                  [_vm._v("Save changes")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            2
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }