<!--
    * Component Description
        A simple, re-usable checkbox wrapper that applies IWS styling and other small features
        See props for an explanation of all supported functions

    * Side Effects
    Will emit 2 signals
        update:value           <- triggers upon click of the checkbox
        change                 <- triggers upon click of the checkbox

    * Example Usage
        <iws-checkbox
            label="Is Active"
            :value.sync="isActive"
        />
-->
<template>
    <div :id="id" class="position-relative" :class="{ 'form-input-spacing': formSpacing !== false }">
        <label v-if="prependLabel"
            class="form-check-label"
            :class="{ 'clickable': enableClickLabel }"
            @click="clickLabel"
        >
            {{ typeof prependLabel == 'function' ? prependLabel() : prependLabel }}
        </label>
        
        <input ref="checkboxRef"
            type="checkbox"
            :value="value"
            :checked="value"
            @change="onClick"
            :disabled="disabled"
        >

        <label v-if="label"
            class="form-check-label"
            :class="{ 'clickable': enableClickLabel }"
            @click="clickLabel"
        >
            {{ typeof label == 'function' ? label() : label }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String
        },

        // Text for checkbox (appears to the right of actual checkbox)
        label: {
            type: String | Number | Function
        },
        // prepend Text for checkbox (appears to the left of actual checkbox)
        prependLabel: {
            type: String | Number | Function
        },

        // Synced value of input field
        // When a Number, should only be 0 (false) or 1 (true)
        value: {
			type: Boolean | Number
		},

        disabled: {
            type: Boolean,
            default: false
        },
        // Provides a consistent margin between fields across forms
        formSpacing: {
            type: Boolean,
            default: false
        },

        // Allows the user to click the label (not just the check box) to interact with the input field
        enableClickLabel: {
            type: Boolean,
            default: false
        }
    },

    methods: {							
        onClick() {
            let _value;

            if (this.value === 0) {
                _value = 1
            } else if (this.value === 1) {
                _value = 0
            } else {
                _value = !this.value;
            }

            this.$emit('update:value', _value);
            this.$emit('change', _value);
        },
        
        clickLabel() {
            // Can enable clicking the label to flip value
            if (this.enableClickLabel !== false && !this.disabled)
                this.$refs.checkboxRef.click()
        }
    }
};
</script>

<style scoped>
    input[type='checkbox'], input[type='checkbox']:checked {
        transition: all 0.1s ease-in-out;
    }
    input[type='checkbox']:hover, input[type='checkbox']:checked:hover {
        background-color: #242A30;
    }
    input[type='checkbox']:checked {
        background-color: #2E353D;
    }
    input[type='checkbox']:checked:after {
        position: absolute;
        top: 0px;
        left: 0px;

        content: '\2713';
        color: #007bff;

        display: inline-block;

        width: 25px;
        height: 25px;

        font-size: 20px;
        text-align: center;
        vertical-align: middle;
        line-height: 25px;
    }
    input[type='checkbox'] {
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        width: 25px;
        height: 25px;
        appearance:none;
        border-radius: 20%;
        border: 1px solid #007bff;
        box-shadow: none;
        font-size: 1.5em;
    }
</style>