var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-100 w-100" },
    [
      _c(
        "div",
        {
          staticClass: "w-100 d-flex",
          staticStyle: { position: "relative: top: 5px", left: "5px" },
        },
        [
          _c("div", { staticClass: "mr-2" }, [
            _c("label", { attrs: { for: "dataFilterButton" } }, [
              _c(
                "button",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip:top",
                      value: "Set heatmap filter options",
                      expression: "'Set heatmap filter options'",
                      arg: "top",
                    },
                  ],
                  staticClass: "fake-button",
                  attrs: { id: "dataFilterButton" },
                  on: {
                    click: function ($event) {
                      return _vm.$bvModal.show("limitsModal")
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-cog fa-lg pl-2" })]
              ),
              _vm._v("Data Filter\n          "),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Print Chart View",
                    expression: "'Print Chart View'",
                    arg: "top",
                  },
                ],
                staticClass: "fake-button show-clicker-finger",
                attrs: { disabled: !_vm.data || _vm.data.length == 0 },
                on: {
                  click: function ($event) {
                    return _vm.printChartToImage()
                  },
                },
              },
              [_c("i", { staticClass: "fas fa-print mx-2" })]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Export Chart Data",
                    expression: "'Export Chart Data'",
                    arg: "top",
                  },
                ],
                staticClass: "fake-button show-clicker-finger",
                attrs: {
                  id: "exportChartButton",
                  disbled: !_vm.data || _vm.data.length == 0,
                },
              },
              [
                _c("i", { staticClass: "fas fa-download" }),
                _vm._v(" "),
                _c(
                  "b-popover",
                  {
                    attrs: {
                      target: "exportChartButton",
                      placement: "left",
                      triggers: "hover",
                    },
                  },
                  [
                    _c("div", { staticStyle: { color: "white" } }, [
                      _c(
                        "div",
                        {
                          staticClass: "w-100 text-center",
                          staticStyle: { "text-decoration": "underline" },
                        },
                        [_vm._v("Export Data")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between mt-2" },
                        [
                          _c("div", { staticClass: "mx-2" }, [
                            _vm._v(
                              "\n                          File Type: \n                      "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.exportFileType,
                                  expression: "exportFileType",
                                },
                              ],
                              staticClass: "mx-2",
                              attrs: { id: "exportFileType" },
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.exportFileType = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "json" } }, [
                                _vm._v("JSON"),
                              ]),
                              _vm._v(" "),
                              _c(
                                "option",
                                { attrs: { value: "csv", selected: "" } },
                                [_vm._v("CSV")]
                              ),
                            ]
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-center mt-2" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "btn btn-success green-button",
                              attrs: {
                                type: "button",
                                disabled: !_vm.data || _vm.data.length == 0,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.exportChartData(_vm.exportFileType)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "d-flex justify-content-center",
                                },
                                [
                                  false
                                    ? _c("span", {
                                        staticClass:
                                          "spinner-border spinner-border-sm pr-2",
                                        attrs: {
                                          role: "status",
                                          "aria-hidden": "true",
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("div", [
                                    _vm._v(
                                      "\n                                  Export\n                              "
                                    ),
                                  ]),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            id: "limitsModal",
            "header-class": "border-bottom-0 justify-content-center p-0",
            "body-class": "pb-0",
            "footer-class": "border-top-0 pt-0",
            "content-class": "modal-bg",
          },
          on: {
            ok: function ($event) {
              return _vm.applyLimitChanges()
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function () {
                return [
                  _c("div", { staticClass: "d-flex justify-content-center" }, [
                    _vm._v("Data Limits"),
                  ]),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column justify-content-center" },
            [
              _c("div", { staticClass: "w-100" }, [
                _c("label", { attrs: { for: "ignoreZeroes" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.ignoreZeroValues,
                        expression: "ignoreZeroValues",
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: {
                      disabled: !_vm.data || _vm.data.length == 0,
                      type: "checkbox",
                      id: "ignoreZeroes",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.ignoreZeroValues)
                        ? _vm._i(_vm.ignoreZeroValues, null) > -1
                        : _vm.ignoreZeroValues,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.ignoreZeroValues,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.ignoreZeroValues = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.ignoreZeroValues = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.ignoreZeroValues = $$c
                        }
                      },
                    },
                  }),
                  _vm._v("Ignore Zero Values"),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "d-flex flex-column" }, [
                _c("label", { attrs: { for: "setDataLimits" } }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filterExtremeValues,
                        expression: "filterExtremeValues",
                      },
                    ],
                    staticClass: "mr-2",
                    attrs: {
                      id: "setDataLimits",
                      disabled: !_vm.data || _vm.data.length == 0,
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.filterExtremeValues)
                        ? _vm._i(_vm.filterExtremeValues, null) > -1
                        : _vm.filterExtremeValues,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.filterExtremeValues,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.filterExtremeValues = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.filterExtremeValues = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.filterExtremeValues = $$c
                        }
                      },
                    },
                  }),
                  _vm._v("Set Data Limits"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "d-flex justify-content-center" }, [
                  _c("div", { staticClass: "w-50 text-center" }, [
                    _c("label", { attrs: { for: "lowDataThreshold" } }, [
                      _vm._v("Lower Limit\n                          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.lowDataThreshold,
                            expression: "lowDataThreshold",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "ml-2 form-control",
                        attrs: {
                          id: "lowDataThreshold",
                          type: "number",
                          disabled: !_vm.filterExtremeValues,
                        },
                        domProps: { value: _vm.lowDataThreshold },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.lowDataThreshold = _vm._n($event.target.value)
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "w-50 text-center" }, [
                    _c("label", { attrs: { for: "highDataThreshold" } }, [
                      _vm._v("Upper Limit\n                          "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.highDataThreshold,
                            expression: "highDataThreshold",
                            modifiers: { number: true },
                          },
                        ],
                        staticClass: "ml-2 form-control",
                        attrs: {
                          id: "highDataThreshold",
                          type: "number",
                          disabled: !_vm.filterExtremeValues,
                        },
                        domProps: { value: _vm.highDataThreshold },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.highDataThreshold = _vm._n($event.target.value)
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ]
      ),
      _vm._v(" "),
      _c("div", {
        ref: _vm.chartId,
        style: "height:" + _vm.height + "px;",
        attrs: { id: _vm.chartId },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }