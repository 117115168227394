var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      staticStyle: { "enable-background": "new 0 0 210 210" },
      style: { fill: _vm.color, stroke: _vm.color },
      attrs: {
        version: "1.1",
        id: "Layer_1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
        x: "0px",
        y: "0px",
        viewBox: "0 0 210 210",
        "xml:space": "preserve",
      },
    },
    [_c("path", { attrs: { d: "M179.07,105L30.93,210V0L179.07,105z" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }