var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-kpi-template", {
    ref: "baseKpiTemplate",
    attrs: {
      title: "Lateral Length/Day",
      item: _vm.item,
      "inital-options": _vm.initalOptions,
      "edit-mode": _vm.editMode,
      "create-export-data": _vm.createExportData,
      "analytics-data": _vm.analyticsData,
      errors: _vm.errors,
      "show-not-enough-data-warning": _vm.showNotEnoughDataWarning,
      "show-chart": _vm.showChart,
    },
    on: {
      analyticsChanges: function ($event) {
        return _vm.buildAnalytics()
      },
      settingsChanges: function ($event) {
        return _vm.initKPI()
      },
      revertChanges: function ($event) {
        return _vm.initKPI()
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "extra-info",
          fn: function () {
            return [
              _c("div", { staticClass: "extra-info" }, [
                _vm._v(
                  "\n            Time Range: " +
                    _vm._s(_vm.getTimeRangeDescription) +
                    "\n        "
                ),
              ]),
            ]
          },
          proxy: true,
        },
        _vm.showChart
          ? {
              key: "content",
              fn: function () {
                return [
                  _c("bar-chart", {
                    ref: "kpi",
                    attrs: { "chart-data": _vm.kpi, options: _vm.options },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }