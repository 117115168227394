export default function (apiIncident = null, columnOrder = []) {
  return new Ticket(apiIncident, columnOrder);
}

/**
 * Ticket class: class to make it easier to use JSON ticket data from
 * the `/tickets/get` endpoint with existing Vue library components
 */
class Ticket {
  constructor(apiIncident, columnOrder) {
    this.columns = this.initColumns(apiIncident, columnOrder);
    this.values = this.initValues();
  }

  // Create simplified key->value representation of ticket 
  initValues() {
    const values = {};
    this.columns.forEach(col => {
      values[col.key] = col.value;
    })

    return values;
  }

  /**
   * Create columns with metadata for sorting, filtering, hide/display, etc.
   * Can be called with null input to get metadata but no values
   */
  initColumns(apiIncident, columnOrder) {
    const columns = [
      {
        key: 'id',
        display: 'none',
        value: apiIncident?.id,
      },
      {
        key: 'closedDate',
        filterKey: 'closed_after',
        value: apiIncident?.closedDate,
        label: 'Closed Date',
        sortable: true,
        display: 'table',
        format: 'date',
      },
      {
        key: 'contact',
        filterKey: 'contact',
        filterValue: apiIncident?.contact?.id,
        value: apiIncident?.contact?.label,
        label: 'Contact',
        sortable: true,
        display: 'table',
      },
      {
        key: 'createdBy',
        filterKey: 'created_by',
        filterValue: apiIncident?.createdBy?.id,
        value: apiIncident?.createdBy?.label,
        label: 'Created By',
        sortable: true,
        display: 'table-lg',
      },
      {
        key: 'currentOwner',
        filterKey: 'current_owner',
        filterValue: apiIncident?.currentOwner?.id,
        value: apiIncident?.currentOwner?.label,
        label: 'Current Owner',
        sortable: true,
        display: 'table',
      },
      {
        key: 'customerPadName',
        filterKey: 'customer_pad_name',
        filterValue: apiIncident?.customerPadName,
        value: apiIncident?.customerPadName,
        label: 'Customer Pad Name',
        sortable: true,
        display: 'table',
      },
      {
        key: 'department',
        filterKey: 'department',
        filterValue: apiIncident?.department?.id,
        value: apiIncident?.department?.label,
        label: 'Department',
        sortable: true,
        display: 'table',
      },
      {
        key: 'incidentNumber',
        filterKey: 'incident_number',
        filterValue: apiIncident?.incidentNumber,
        value: apiIncident?.incidentNumber,
        label: 'Incident #',
        sortable: true,
        display: 'table',
      },
      {
        key: 'openDate',
        filterKey: 'openDate',
        filterValue: apiIncident?.openDate,
        value: apiIncident?.openDate,
        label: 'Open Date',
        sortable: true,
        display: 'table',
        format: 'date',
      },
      {
        key: 'status',
        filterValue: apiIncident?.status?.id,
        value: apiIncident?.status?.label,
        label: 'Status',
        sortable: true,
        display: 'table',
      },
      {
        key: 'severity',
        filterKey: 'severity',
        filterValue: apiIncident?.severity?.id,
        value: apiIncident?.severity?.label,
        label: 'Severity',
        sortable: true,
        display: 'table',
      },
      {
        key: 'reportedBy',
        filterKey: 'reported_by',
        filterValue: apiIncident?.reported_by?.id,
        value: apiIncident?.reportedBy?.label,
        label: 'Reported By',
        sortable: true,
        display: 'table-lg',
      },
      {
        key: 'resolutionType',
        filterKey: 'resolution_type',
        filterValue: apiIncident?.resolutionType?.id,
        value: apiIncident?.resolutionType?.label,
        label: 'Resolution Type',
        sortable: true,
        display: 'table-lg',
      },
      {
        key: 'resolutionSteps',
        filterKey: 'resolution_steps',
        filterValue: apiIncident?.resolutionSteps,
        value: apiIncident?.resolutionSteps,
        label: 'Resolution Steps',
        sortable: false,
        display: 'detail',
      },
      {
        key: 'title',
        filterKey: 'title',
        filterValue: apiIncident?.title,
        value: apiIncident?.title,
        label: 'Ticket Title',
        sortable: true,
        display: 'table',
      },
      {
        key: 'assetForTicket',
        value: apiIncident?.assetForTicket?.label,
        label: 'Asset For Ticket',
        display: 'detail',
      },
      {
        key: 'assetCategory',
        value: apiIncident?.assetCategory?.label,
        label: 'Customer Asset Category',
        display: 'detail',
      },
      {
        key: 'technicianSteps',
        value: apiIncident?.technicianSteps,
        label: 'Technician Steps Taken',
        display: 'detail',
      },
      {
        key: 'supportSteps',
        value: apiIncident?.supportSteps,
        label: 'Support Steps Taken',
        display: 'detail',
      },
      {
        key: 'supportedBy',
        value: apiIncident?.supportedBy?.label,
        label: 'Supported By',
        display: 'detail',
      },
      {
        key: 'callout',
        value: apiIncident?.callout,
        label: 'Was this a Callout?',
        display: 'detail',
        format: 'boolean',
      },
      {
        key: 'description',
        filterKey: 'description',
        filterValue: apiIncident?.description,
        value: apiIncident?.description,
        label: 'Description',
        display: 'detail',
      },
      {
        key: 'occurrence',
        filterKey: 'occurrence',
        filterValue: apiIncident?.occurence?.id,
        value: apiIncident?.occurence?.label,
        label: 'Occurence',
        display: 'detail',
      },
      {
        key: 'priority',
        filterKey: 'priority',
        filterValue: apiIncident?.priority?.id,
        value: apiIncident?.priority?.label,
        label: 'Priority',
        display: 'detail',
      },
      {
        key: 'product',
        filterKey: 'product',
        filterValue: apiIncident?.product?.id,
        value: apiIncident?.product?.label,
        label: 'Product',
        display: 'detail',
      },
      {
        key: 'productCategory1',
        value: apiIncident?.productCategory1?.label,
        label: 'Product Category',
        display: 'detail',
      },
      {
        key: 'productCategory2',
        value: apiIncident?.productCategory2?.label,
        label: 'Product Category 2',
        display: 'detail',
      },
      {
        key: 'productCategory3',
        value: apiIncident?.productCategory3?.label,
        label: 'Product Category 3',
        display: 'detail',
      },
      {
        key: 'start',
        filterKey: 'start',
        label: 'Start',
        display: 'none',
      },
      {
        key: 'end',
        filterKey: 'end',
        label: 'End',
        display: 'none',
      },
      {
        key: 'workOrder',
        filterKey: 'work_order',
        filterValue: apiIncident?.workOrder?.id,
        value: apiIncident?.workOrder?.label,
        label: 'Work Order',
        display: 'table',
        sortable: true
      }
    ];

    // items displayed in order, items with index -1 displayed last
    return columns.sort((colA, colB) => {
      const order = columnOrder.toReversed();
      const a = order.indexOf(colA.key);
      const b = order.indexOf(colB.key);

      return b - a;
    });
  }

  /** 
   * Easy method for fetching data for specific column
   */
  column(key) {
    return this.columns.find(col => col.key === key);
  }
}
