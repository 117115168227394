var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex-shrink" },
    [
      _c("select-job-component", {
        attrs: {
          companyJobs: _vm.activeCompanyJobs,
          jobNumber: _vm.jobNumber,
          newUi: _vm.newUi,
          iwsUser: _vm.iwsUser,
          jobSystem: _vm.jobSystem,
        },
        on: { jobSelected: (job, index) => _vm.onJobChanged(job, index) },
      }),
      _vm._v(" "),
      _c("h4", { staticStyle: { display: "inline" } }, [
        _vm.percentCompleted > 0
          ? _c("span", { staticClass: "badge badge-secondary" }, [
              _vm._v(
                "\n            Stages Completed: " +
                  _vm._s(_vm.totalCompletedStages) +
                  "/" +
                  _vm._s(_vm.numberOfStagesAllWells) +
                  " (" +
                  _vm._s(_vm.percentCompleted) +
                  "%)\n        "
              ),
            ])
          : _vm._e(),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }