<template>
    <table class="table table-striped table-sm small-font" :class="{ 'table-dark' : this.$root.darkMode } ">
        <thead>
            <tr class="ash-bg">
                <th v-for="(value, index) in tableData" v-bind:key="index">
                    {{index}}
                </th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td v-for="(value, index) in tableData" v-bind:key="index">
                    {{value}}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    props : ['tableData'],
    created(){
        this.data = this.tableData;
    },
    data(){
        return {
            data : [],
        }
    }

}
</script>

<style>
 .small-font {
     font-size : small;
 }
</style>