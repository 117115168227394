var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LibraryFiltersBaseModal", {
    ref: "libraryFilters",
    attrs: {
      title: "Filter",
      "max-width": "680px",
      filters: _vm.filters,
      id: "timekeeper-filter",
    },
    on: {
      clearFilters: function ($event) {
        return _vm.clearFilters($event)
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function ({ removeFromFilter, handleEvent }) {
          return [
            _c(
              "div",
              { staticClass: "filter-container container-border" },
              [
                _c("label", [_vm._v("Stage")]),
                _vm._v(" "),
                _vm.stagesByWell
                  ? _vm._l(_vm.stagesByWell, function (stages, well, index) {
                      return _c("accordion", {
                        key: `well-${index}`,
                        class: {
                          "mb-20":
                            index !== Object.keys(_vm.stagesByWell).length - 1,
                        },
                        attrs: { title: well },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "d-flex flex-wrap mb-20",
                                      staticStyle: { gap: "12px" },
                                    },
                                    [
                                      _vm.filters?.stages?.hasOwnProperty(well)
                                        ? _vm._l(
                                            stages,
                                            function (stage, index) {
                                              return _c(
                                                "div",
                                                {
                                                  key: `stage-${index}`,
                                                  staticClass: "chip",
                                                  class: {
                                                    "selected-success":
                                                      _vm.filters.stages[well][
                                                        stage
                                                      ],
                                                  },
                                                  staticStyle: {
                                                    width: "37px",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      _vm.filters.stages[well][
                                                        stage
                                                      ] =
                                                        !_vm.filters.stages[
                                                          well
                                                        ][stage]
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    " +
                                                      _vm._s(stage) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              )
                                            }
                                          )
                                        : _vm._e(),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex align-items-center justify-content-between w-100",
                                    },
                                    [
                                      _c("div", { staticClass: "d-flex" }, [
                                        _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "margin-right": "12px",
                                              "font-size": "12px",
                                              "font-weight": "400",
                                            },
                                          },
                                          [_vm._v("Quick select:")]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "d-flex",
                                            staticStyle: { gap: "16px" },
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "chip quick-select-chip",
                                                class: {
                                                  "selected-success":
                                                    _vm.selectionState(
                                                      well,
                                                      true
                                                    ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleStagesSelection(
                                                      well,
                                                      true
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm.selectionState(well, true)
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: "/images/icons/checkmark.svg",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "w-100" },
                                                    [_vm._v("All")]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "chip quick-select-chip",
                                                class: {
                                                  "selected-warning":
                                                    _vm.selectionState(
                                                      well,
                                                      false
                                                    ),
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.toggleStagesSelection(
                                                      well,
                                                      false
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c("div", [
                                                  _vm.selectionState(
                                                    well,
                                                    false
                                                  )
                                                    ? _c("img", {
                                                        attrs: {
                                                          src: "/images/icons/checkmark.svg",
                                                        },
                                                      })
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    { staticClass: "w-100" },
                                                    [_vm._v("None")]
                                                  ),
                                                ]),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _vm.fromToWell?.hasOwnProperty(well)
                                        ? _c(
                                            "div",
                                            { staticClass: "d-flex" },
                                            [
                                              _c("iws-select", {
                                                staticStyle: { width: "120px" },
                                                attrs: {
                                                  options: [...stages].sort(
                                                    (a, b) => {
                                                      return a - b
                                                    }
                                                  ),
                                                  value:
                                                    _vm.fromToWell[well][
                                                      "from"
                                                    ],
                                                  placeholder: "From",
                                                },
                                                on: {
                                                  "update:value": [
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.fromToWell[well],
                                                        "from",
                                                        $event
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.handleQuickSelection(
                                                        well,
                                                        stages
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                              _vm._v(" "),
                                              _c("iws-select", {
                                                staticStyle: {
                                                  "margin-left": "20px",
                                                  width: "120px",
                                                },
                                                attrs: {
                                                  options:
                                                    _vm.fromToWell[well]
                                                      .toOptions,
                                                  value:
                                                    _vm.fromToWell[well]["to"],
                                                  disabled:
                                                    _vm.fromToWell[well]
                                                      .toDisabled,
                                                  placeholder: "To",
                                                },
                                                on: {
                                                  "update:value": [
                                                    function ($event) {
                                                      return _vm.$set(
                                                        _vm.fromToWell[well],
                                                        "to",
                                                        $event
                                                      )
                                                    },
                                                    function ($event) {
                                                      return _vm.handleQuickSelection(
                                                        well,
                                                        stages
                                                      )
                                                    },
                                                  ],
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      })
                    })
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _vm._l(_vm.dynamicFilters, function (filter, dIndex) {
              return [
                filter.canFilter
                  ? _c(
                      "div",
                      {
                        key: `${dIndex}-filter`,
                        staticClass: "filter-container",
                      },
                      [
                        _c("iws-select", {
                          attrs: {
                            label: filter.header,
                            "value-name": "id",
                            multiselect: true,
                            "display-name": "value",
                            options: filter.options,
                            "maintain-on-select": true,
                            "dropdown-footer-height": 40,
                            value: _vm.filters[filter.name],
                            placeholder: `Select ${filter.placeholder}`,
                          },
                          on: {
                            change: function ($event) {
                              return handleEvent(
                                _vm.filters[filter.name],
                                $event
                              )
                            },
                          },
                          scopedSlots: _vm._u(
                            [
                              filter.includeUncoded
                                ? {
                                    key: "dropdown-footer",
                                    fn: function ({ maintainOpenOptions }) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "included-uncoded px-3 py-2",
                                            on: {
                                              click: function ($event) {
                                                return maintainOpenOptions()
                                              },
                                            },
                                          },
                                          [
                                            _c("p", [
                                              _vm._v("Include Uncoded"),
                                            ]),
                                            _vm._v(" "),
                                            _c("iws-switch", {
                                              attrs: {
                                                size: "medium",
                                                onPress: maintainOpenOptions,
                                                value:
                                                  _vm.includeUncoded[
                                                    filter.name
                                                  ],
                                              },
                                              on: {
                                                "update:value": function (
                                                  $event
                                                ) {
                                                  return _vm.$set(
                                                    _vm.includeUncoded,
                                                    filter.name,
                                                    $event
                                                  )
                                                },
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          _vm._l(
                            _vm.filters[filter.name],
                            function (option, index) {
                              return _c(
                                "span",
                                {
                                  key: index,
                                  staticClass: "badge badge-outline",
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(
                                        _vm.getDisplayValue(dIndex, option)
                                      ) +
                                      " "
                                  ),
                                  _c("i", {
                                    staticClass: "fas fa-times pointer-hover",
                                    staticStyle: { "margin-right": "5px" },
                                    on: {
                                      click: function ($event) {
                                        return removeFromFilter(
                                          _vm.filters[filter.name],
                                          index
                                        )
                                      },
                                    },
                                  }),
                                ]
                              )
                            }
                          ),
                          0
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
            _vm._v(" "),
            _c("div", { staticClass: "filter-container container-border" }, [
              _c("label", [_vm._v("Date / Time Range")]),
              _vm._v(" "),
              _c("div", { staticClass: "row" }, [
                _c("div", { staticClass: "col-6" }, [
                  _c("label", { attrs: { for: "start-time" } }, [
                    _vm._v("Start time"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.startTime,
                        expression: "filters.startTime",
                      },
                    ],
                    staticClass: "m-0",
                    attrs: {
                      step: "1",
                      id: "start-time",
                      min: _vm.minDate,
                      max: _vm.maxDate,
                      type: "datetime-local",
                    },
                    domProps: { value: _vm.filters.startTime },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.filters, "startTime", $event.target.value)
                      },
                    },
                  }),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-6" }, [
                  _c("label", { attrs: { for: "end-time" } }, [
                    _vm._v("End time"),
                  ]),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.filters.endTime,
                        expression: "filters.endTime",
                      },
                    ],
                    staticClass: "m-0",
                    attrs: {
                      step: "1",
                      id: "end-time",
                      max: _vm.maxDate,
                      min: _vm.minDate,
                      type: "datetime-local",
                    },
                    domProps: { value: _vm.filters.endTime },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.filters, "endTime", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm.filters.startTime || _vm.filters.endTime
                  ? _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.formatDatetime(
                              _vm.filters.startTime,
                              _vm.filters.endTime
                            )
                          ) +
                          "\n                    "
                      ),
                      _c("i", {
                        staticClass: "fas fa-times",
                        staticStyle: { "margin-right": "5px pointer-hover" },
                        on: { click: _vm.clearDatetime },
                      }),
                    ])
                  : _vm._e(),
              ]),
            ]),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }