<template>
    <iws-modal
        title="Add NPT Outcome" 
        primary-button-text="Save"

        :showModal="showBaseModal"
        max-width="400px"

        @close="cancelAction"
        @secondary-action="cancelAction"
        @primary-action="confirmAction"
        :close-on-clickaway="false"
    >
        <template v-if="!!data" #content>
            <div>
                <iws-input
                    :value.sync="data.item.name"
                    label="Name *"
                    required
                    :validator="(value) => validator(value, data.index, 'name')"
                    ref="nameInput"
                />
            </div>

            <div style="margin-top: 30px">
                <iws-input
                    :value.sync="data.item.friendly_name"
                    label="Display Name *"
                    required
                    :validator="(value) => validator(value, data.index, 'friendly_name')"
                    ref="friendlyNameInput"
                />
            </div>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { flushToasts } = GlobalFunctions;

export default {
    data() {
        return {
            showBaseModal: false,
            resolve: null,
            data: null,
            validator: null
        };
    },
    
    methods: {
        cancelAction() {
            this.resolve(null)
        },
        confirmAction() {
            flushToasts();

            // Always run both validators to visually show if both are invalid
            const testResults = [
                this?.$refs?.friendlyNameInput?.validateInput(),
                this?.$refs?.nameInput?.validateInput()
            ];

            if (testResults[0] && testResults[1])
                this.resolve(this.data.item);
        },

        open(data, validator) {
            this.data = _.cloneDeep(data);
            this.validator = validator;

            return new Promise( (resolve, reject) => {
                this.resolve = (value) => {
                    this.showBaseModal = false;
                    this.data = null;
                    this.validator = null;
                    resolve(value);
                };

                this.showBaseModal = true;
            });
        }
    }
};
</script>