<template>
  <div class="col">
    <iws-breadcrumbs class="breadcrumbs-margins"
        :path="breadcrumbPath"
    />

    <div class="form-group" v-if="customers != [] && customers.length > 0 && parentId == ''">
      <iws-select
        label="Company"
        :value.sync="value.customer_id"
        :options="customers"
        display-name="name"
        value-name="id"
      />
    </div>
    
    <iws-button v-if="numberOfRevisions > 1" type="outline-light" @click="getHistory">
      <template #text>
        <span class="fas fa-history"></span>
      </template>
    </iws-button>

    <span v-if="history.length > 0" style="padding: 0px 5px">
      <iws-button type="outline-light" @click="changeHistoryIndex(-1)" :disabled="historyIndex === 0">
        <template #text>
          <span class="fas fa-backward"></span>
        </template>
      </iws-button>

      <iws-button type="outline-light" @click="changeHistoryIndex(1)" :disabled="historyIndex === history.length-1">
        <template #text>
          <span class="fas fa-forward"></span>
        </template>
      </iws-button>
    </span>

    <div class="badge badge-pill badge-warning" v-if="type=='well' && ( !value.hasOwnProperty('name') || !value.hasOwnProperty('UWI') )">
      Enter name and UWI to save
    </div>

    <iws-button v-if="viewingOldVersion"
      text="Restore this version"
      type="primary"
      :click="showRestoreOldVersionModal"
    />
    <iws-button v-else-if="!(type == 'well' && ( !value.hasOwnProperty('name') || !value.hasOwnProperty('UWI') ))"
      text="Save"
      type="primary"
      :click="save"
    />
    
    <iws-select v-if="history.length > 0"
      :value.sync="historyIndex"
      :options="history"
      display-name="created_at"
      value-name="index"
      @change="viewHistory"
      style="max-width: 209px; display: block; margin: 5px 0px"
    />

    <div class="alert alert-warning m-2" v-if="errorMessage">
      {{ errorMessage }}
    </div>

    <structure-node-component v-for="item of structure"
      :key="item.name+parseInt(Math.random()*1000000)"
      :structure="item"
      :parents=[]
      @valueChange="onValueChange"
      :data="value"
      :id="id"
    ></structure-node-component>

    <template v-if="!newItem">
      <div v-for="childType of childTypes" :key="'childType'+childType.id" class="row-col mb-3">
        <div class="d-flex justify-content-between">
          <div class="h3">
            {{ childType.name }}
          </div>

          <div>
            <div v-if="childType.name=='stage' && value.hasOwnProperty('planned') && value.planned.hasOwnProperty('stageCount') && value.planned.stageCount>0 && childType.children.length==0"
              class="btn btn-primary btn-sm m-1" 
              @click="createStages(childType.id)"
            >
              <span v-if="createStageMessage">
                {{ createStageMessage }}
              </span>
              <span v-else>
                Create all stages
              </span>
            </div>

            <div v-if="childType.name=='perfInterval' && value.hasOwnProperty('planned') && value.planned.hasOwnProperty('clusters') && childType.children.length==0"
              class="btn btn-primary btn-sm m-1" 
              @click="createPerforationIntervals(childType.id)"
            >
              <span v-if="createIntervalsMessage">
                {{ createIntervalsMessage }}
              </span>
              <span v-else>
                Create all perforation intervals
              </span>
            </div>

            <a class="btn btn-primary btn-sm m-1" :href="`/metadata/${childType.name}/new?parentId=${id}`">
              New
            </a> 
          </div>
        </div>

        <child-table-component :prop-data="JSON.stringify(childType)" :csrf-token="csrfToken"></child-table-component>
      </div>
    </template>

    <div class="modal fade" id="restoreOldModal" tabindex="-1" aria-labelledby="restoreOldModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content text-light">
          <div class="modal-header">
            <h5 class="modal-title" id="restoreOldModalLabel">Restore old version</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="alert alert-danger">Restoring this older version will permanently delete newer versions</div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="restoreOldVersion">Restore this version</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty } = GlobalFunctions;

import ChildTableComponent from '../ChildTableComponent.vue'
import StructureNodeComponent from '../StructureNodeComponent.vue'
import {v4 as uuidv4} from "uuid"
export default {
  props : {
    propid: String,
    propcustomers:String,
    metadata:String,
    type:String,
    "csrfToken":String,
    typeId:String,
    parentTypeId:String,
    propChildTypes:String,
    parentId:String,
    syncIdFromParent:Boolean,
    numberOfRevisions:Number
	},

  components: { StructureNodeComponent },

  data() {
    return {
      breadcrumb:[],
      childTypes:[],
      createIntervalsMessage:null,
      createStageMessage:null,
      customers:[],
      errorMessage:null,
      gettingHistory:false,
      history:[],
      historyIndex:null,
      saving:false,
      structure:[],
      typesWithTableDisplay:[
        'stage',
        'perfInterval'
      ],
      value:{
        customer_id:null,
        type_id:null
      },
      id:null
    }
  },

  computed: {
    breadcrumbPath() {
      const home = {
        title: 'Operator List',
        link: `/metadata`
      };

      // If empty or one contains home, avoid manipulating
      if (isNullOrEmpty(this.breadcrumb) || this.breadcrumb.length == 1)
        return [ home ];

      return [
        home,
        ...[...this.breadcrumb].splice(1, this.breadcrumb.length).map(_breadcrumb => {
          return {
            title: `${_breadcrumb.type}: ${_breadcrumb.name}`,
            link: `/metadata/${_breadcrumb.type}/${_breadcrumb.id}`
          }
        })
      ];
    },

    newItem() {
      return this.id == 'new';
    },
    viewingOldVersion() {
      return this.historyIndex && this.historyIndex > 0;
    }
  },

  methods: {
    changeHistoryIndex(offset) {
      if (this.historyIndex+offset in this.history) {
        this.historyIndex += offset;
        this.viewHistory();
      }
    },

    async createStages(typeId) {
      let numberOfStages = parseInt(this.value.planned.stageCount);
      this.createStageMessage = `creating stage 0 of ${numberOfStages}`;
      let url = '/metadata/stage/new';
      let data = {
        _token:this.csrfToken,
        type_id:typeId,
        parent_id:this.id,
        syncIdFromParent:true,
        number:0
      };

      for (let i=0; i < numberOfStages; i++) {
        data.number = i + 1;
        this.createStageMessage = `creating stage ${i+1} of ${numberOfStages}`;
        await $.post(url, data);
      }

      this.createStageMessage = null;
      location.reload();
    },
    async createPerforationIntervals(typeId){
      let numberOfIntervals = parseInt(this.value.planned.clusters);
      this.createIntervalsMessage = `creating interval 0 of ${numberOfIntervals}`;
      let url = '/metadata/perfInterval/new';
      let data = {
        _token:this.csrfToken,
        type_id:typeId,
        parent_id:this.id,
        syncIdFromParent:true,
        number:0
      };

      for(let i=0; i < numberOfIntervals; i++) {
        data.number = i + 1;
        this.createIntervalsMessage = `creating interval ${i+1} of ${numberOfIntervals}`;
        await $.post(url, data);
      }

      this.createIntervalsMessage = null;
      location.reload();
    },
    getBreadcrumb() {
      let self = this
      if (self.id == 'new') {
        return $.get(
          `/metadataBreadcrumb/${self.parentId}`,
          function(data){
            data.splice(0,1);
            data.push({
              type: self.type,
              name:'new'
            });

            self.breadcrumb = data;
          },
          'json'
        );
      } else {
        return $.get(
          `/metadataBreadcrumb/${self.id}`,
          function(data){
            data.splice(0,0,{id:uuidv4()})
            self.breadcrumb = data
          },
          'json'
        );
      }
    },
    async getChildren(childType){
      let self = this
      childType.children = []
      let numbers = [] //if data includes number, keep track of numbers for ordering
      //console.log(`should get children of type ${childType.id} for item of id: ${self.id}`)
      $.get(`/metadataChildren/${childType.id}/${self.id}`,
      function(data){
        for (const child of data){
          child.data = JSON.parse(child.data)
          let foundNames = []
          let foundNumber = null
          for(const[key,value] of Object.entries(child.data)){
            if(key.toLowerCase().includes('number'))
              foundNumber = parseFloat(value)
            if(key=='name')
              foundNames.push(value)
            if(key=='number')
              foundNames.push(value)
          }
          //childType.children.push(child)
          if(foundNumber){
            let index = null
            for(let i=0;i<numbers.length;i++){
              if(index == null && foundNumber<numbers[i])
                index = i
            }
            if(index === null){
              childType.children.push(child)
              numbers.push(foundNumber)
            }else{
              childType.children.splice(index,0,child)
              numbers.splice(index,0,foundNumber)
            }
          }else{
            childType.children.push(child)
          }
          if(foundNames.length>0)
            child.calculatedName = foundNames.join(', ')
          else
            child.calculatedName = JSON.stringify(child.data)
        }
      },
      'json')
    },
    getHistory(){
      let self = this
      self.gettingHistory = true
      $.get(`/metadataHistory/${this.id}`,
        function(data){
          console.log('data', data);
          self.history = data
          self.historyIndex = 0
          console.log('got history')
          self.gettingHistory = false
        },
        'json')
    },
    getTypeStructure: async function(){
      let self = this
      console.log(`should get structure for type ${this.type}`)
      $.get(`/metadataStructure/${this.type}`,function(data){
        //console.log(`got data: ${JSON.stringify(data)}`)
        self.structure = data
      }),
      'json'
    },
    onValueChange(value){
      let self = this
      let newValue = self.value
      console.log(`metadatacomponent got child value change: ${JSON.stringify(value)}`)
      let depth = value.breadcrumb.length
      let breadcrumb = value.breadcrumb
      if(value.value === '' || value.value === '-'){
        console.log(`should clear/delete this value`)
        value.value = null
      }
      for(let i=0;i<depth;i++){ //make sure structure is correct/complete
        let name = breadcrumb[i]
        console.log(`depth: ${depth}, i: ${i}, name: ${name}`)
        if(!newValue.hasOwnProperty(name))
          newValue[name] = {}
        newValue = newValue[name]
      }
      switch(depth){
        case 5:
          self.$set(self.value[breadcrumb[0]][breadcrumb[1]][breadcrumb[2]][breadcrumb[3]],breadcrumb[4],value.value)
          break
        case 4:
          self.$set(self.value[breadcrumb[0]][breadcrumb[1]][breadcrumb[2]],breadcrumb[3],value.value)
          break
        case 3:
          self.$set(self.value[breadcrumb[0]][breadcrumb[1]],breadcrumb[2],value.value)
          break
        case 2:
          self.$set(self.value[breadcrumb[0]],breadcrumb[1],value.value)
          break
        default:
          //depth 1
          self.$set(self.value,breadcrumb[0],value.value)
          console.log(`should set value property ${breadcrumb[0]} to a value of ${value.value}`)
          break
      }
      self.$forceUpdate()
    },
    restoreOldVersion: async function(){
      $('#restoreOldModal').modal('hide')
      let resp = await $.get(`/metadataRestoreOldVersion/${this.history[this.historyIndex].id}`)
      location.reload()
    },
    save: async function(){
      let self = this
      let newInstance = self.id == 'new'
      self.saving = true
      if(this.parentId != '')
        this.value['parent_id'] = this.parentId
      console.log(`should save type: ${this.type}, id: ${this.id} and data: ${JSON.stringify(this.value)}`)
      this.value['_token'] = this.csrfToken
      this.value['syncIdFromParent'] = this.syncIdFromParent
      $.post(
        `/metadata/${this.type}/${this.id}`,
        this.value,
        function(data){
          if(data.error){
            self.errorMessage = data.message
          }else{
            self.id = data.id
            self.errorMessage = null
            if(newInstance)
              window.location.href = `/metadata/${data.type}/${data.id}`

          }
          console.log(`save response: ${JSON.stringify(data)}`)
          self.saving = false
        },
        'json'
      )
    },
    showRestoreOldVersionModal(){
      console.log(`should show modal for restore of old version`)
      $('#restoreOldModal').modal('show')
    },
    viewHistory(){
      if(this.historyIndex!=null){
        let data = JSON.stringify(this.history[this.historyIndex].data)
        console.log(`historyIndex: ${this.historyIndex}, value: ${data}`)
        this.value = JSON.parse(data)
      }
    }
  },

  mounted(){
    console.log(`metadata component mounted`)
    
    if(this.metadata=='null')
      this.value = {}
    else
      this.value = JSON.parse(this.metadata)
    this.id = this.propid
    this.getBreadcrumb()
    this.customers = JSON.parse(this.propcustomers)
    if(this.customers.length>0){
      this.customers.push({id:null,name:"Not customer specific"})
    }
    this.getTypeStructure()
    if(this.parentTypeId != ''){
      console.log(`should request list of possible parents`)
    }else{
      console.log(`this entity does not have a parent`)
    }
    this.value.type_id = this.typeId
    let childTypes = JSON.parse(this.propChildTypes)
    for(const child of childTypes){
      child.children = []
      this.childTypes.push(child)
      if(!this.newItem)
        this.getChildren(child)
    }
  }
}
</script>
