<template>
  <div id="GenericForm">
    <div class="h3">
      {{ title }}
    </div>

    <div v-if="!!fields && !!fields.length" class="row formOuterBox">
      <div
        v-for="(field, index) in fields"
        :key="index"
        class="form-group pt-3"
        :class="{ 'col-6': index < 4, 'col-12': index >= 4 }"
      >
        <iws-checkbox v-if="field.id === fieldIdForSingleSignOn || (permissions === 'admin' && field.id === fieldIdForAPICheckbox)"
          :label="field.labelText"
          :value.sync="field.value"
          enable-click-label
        />
        <!-- <div v-if="(permissions === 'admin' || permissions === 'companyAdmin' || permissions === 'iwsTech') && field.id === fieldIdForSingleSignOn" class="checkbox">
          <label :for="field.id">
              <span>{{field.labelText}}</span>
          </label>
          <input v-if="field.elementType === 'checkbox'" v-bind="getElementAttributes(field)" v-model="field.value" type="checkbox" :id="field.id" class="checkbox style-2 pull-right"/>
        </div>
        <div v-else-if="permissions === 'admin' && field.id === fieldIdForAPICheckbox" class="checkbox">
          <label :for="field.id">
              <span>{{field.labelText}}</span>
          </label>
          <input v-if="field.elementType === 'checkbox'" v-bind="getElementAttributes(field)" v-model="field.value" type="checkbox" :id="field.id" class="checkbox style-2 pull-right"/>
        </div> -->
        <div v-else>
          <!-- <label v-if="labelsHaveHTML" :for="field.id" v-html="field.labelText"></label>
          <label v-else :for="field.id">{{ field.labelText }}</label> -->
          <div v-if="!field.isComponent">
            <div
              v-if="field.elementType === 'password'"
              :id="field.id"
              class="input-group"
              :class="{'d-none':passwordDisabled}"
            >
              <iws-input
                :type="field.elementType"
                :label="field.labelText"
                :placeholder="field.placeholder"
                :value.sync="formValues[field.id]"
                @input="inputData($event)"
                :required="field.required"
                :disabled="passwordDisabled"
              />
            </div>

            <iws-input v-if="field.elementType === 'input'"
                :type="field.elementType"
                :label="field.labelText"
                :placeholder="field.placeholder"
                :value.sync="formValues[field.id]"
                @input="inputData($event)"
                :required="field.required"
                :disabled="field.disabled"
            />
            
            <iws-select v-if="field.elementType === 'select'"
                :options="field.options"
                :value.sync="formValues[field.id]"
                :label="field.labelText"
                :id="field.id"
                display-name="label"
                value-name="value"
                :disabled="field.propData?.disableField"
                @change="dropDownChanged(formValues[field.id], field.id)"
            />

            <div class="text-danger" v-show="isFieldError(field.id)">
              {{ errors[field.id] }}
            </div>
          </div>
          <div v-else>
            <div v-if="field.id == 'dashboard_permissions'">
              <iws-select
                :options="field.propData.options"
                :value="field.value"
                label="Permissions"
                :placeholder="field.value?.length ? `${field.value?.length} selected` : 'Select Permissions'"
                :id="field.id"
                :display-name="field.propData.selectByKey"
                :value-name="field.propData.label"
                :disabled="field.propData.disabled || field.propData.disableField"
                :multiselect="true"
                :maintain-on-select="true"
                @change="proccessMultiSelectSelection(field.value, $event, field)"
              />

              <div class="text-danger" v-show="isFieldError(field.id)">
                {{ errors[field.id] }}
              </div>
              <div v-if="field.propData.disabled  || field.propData?.disableField" class="secondary-text-color" style="font-size: 14px">
                Reach out to 24/7 support to adjust any user permissions via the "Support" option under your user options in the navbar
              </div>
            </div>
            <div v-else-if="field.id == 'apiPermissions'">
              <iws-select
                :options="field.propData.options"
                :value="field.value"
                label="Further Company API Permissions"
                :placeholder="field.value?.length ? `${field.value?.length} selected` : 'Select Further Company API Permissions'"
                :id="field.id"
                :display-name="'name'"
                :value-name="field.propData.selectByKey"
                :disabled="field.propData.disabled || field.propData.disableField"
                :multiselect="true"
                :maintain-on-select="true"
                @change="proccessMultiSelectSelection(field.value, $event, field)"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="form-group float-right col-12" style="text-align:end;">
        <span
            class="savingIndicator"
            v-if="formSubmitted && title === 'Add User'"
            style="color: #00ff00"
            >Saved successfully. Redirecting...</span
        >
        <iws-button text="Submit" :click="submitForm" />
    </div>
    </div>
    <!-- API Permission has been moved to DashboardAndAPIPermission.vue -->
  </div>
</template>

<script>
import GlobalFunctions from "../GlobalFunctions.js";
const { isNullOrEmpty, isFalsy } = GlobalFunctions;


export default {
  props: {
    submitURL: {
      type: String,
      required: true,
    },
    submitMethod: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    fields: {
      type: Array,
      required: true,
    },
    labelsHaveHTML: {
      type: Boolean,
      default: false,
    },
    components: {
      type: Array,
    },
    user: {
      type: Object,
    },
    companies: {
      type: Array,
      required: true,
    },
    usersInfo: {
      type: Array,
    },
    fieldIdForAPICheckbox: {
      type: String,
    },
    fieldIdForSingleSignOn: {
      type: String,
    },
    permissions: {
      type: String,
    },
    companyClearTick: {
      type: Boolean,
      default: false,
    },
    selectedCompany: {
      type: String,
    },
    isIwsAdmin: {
      type: [Boolean, Number],
      required: true,
    },
  },
  data() {
    return {
      formValues: {},
      errors: {},
      errorsPresent: false,
      refreshTicker1: 1,
      refreshTicker2: 1,
      formSubmitted: false,
      passwordDisabled: false,
    };
  },
  computed: {
    isErrors() {
      return Object.values(this.errors)?.length > 0;
    },
  },
  methods: {
    allowMoreControl() {
      // Only enable when apiPermissions is actvie
      if (isNullOrEmpty(this.formValues.apiPermissions)) return false;

      // When it's a new user, look for the value of the "enable api" checkbox
      if (this.title === "Add User")
        return this.fields.find((_field) => _field.id == this.fieldIdForAPICheckbox)
          ?.value;

      return !!this.user.api_token;
    },
    showPasswordToggle(id) {
      let idString = "#" + id;
      if ($(idString + " input").attr("type") == "text") {
        $(idString + " input").attr("type", "password");
        $(idString + " i").addClass("fa-eye-slash");
        $(idString + " i").removeClass("fa-eye");
      } else if ($(idString + " input").attr("type") == "password") {
        $(idString + " input").attr("type", "text");
        $(idString + " i").removeClass("fa-eye-slash");
        $(idString + " i").addClass("fa-eye");
      }
    },
    inputData($event) {
      this.$emit("inputChange", $event);
    },
    fieldBuilder(
      elementType,
      id,
      required,
      labelText,
      value = "",
      cssClass = "",
      name = "",
      placeholder = "",
      inputType = "",
      options = "",
      isComponent = false,
      propData = null,
    ) {
      //fieldBuilder is intended to be called by itself or a parent component to streamline to process of field creation
      //from external data so that all required attributes are present and to ease population of the fields prop

      //type is a special parameter for when elementType is 'input' and sets the type of input present

      //options are a special parameter for when elementType is 'select' and is designed to be an object
      //that holds key value pairs representing the  displayed text of the option and it's value

      //if a form is using the multi-select component, or perhaps in the future a custom component passed in as a prop,
      //the isComponent parameter should be set to true, and any prop values required by that component should be
      //put into an object for the propData parameter so they can be added to the component with v-bind

      //if the backend is expecting a particular name for a field value to pull the data out of the request, that name
      //should be specified with the name attribute, otherwise the request values will be keyed by id instead

      const newField = {
        elementType: elementType,
        id: id,
        required: required,
        labelText: labelText,
        inputType: inputType,
        value: value,
        cssClass: cssClass,
        name: name,
        placeholder: placeholder,
        options: options,
        isComponent: isComponent,
        propData: propData,
      };

      if (elementType == "select") {
        newField.options = Object.keys(options).map(_key => {
          return { label: _key, value: options[_key] }
        });
      }
      return newField;
    },
    getElementAttributes(field) {
      const attributes = {
        id: field.id,
        required: field.required,
        value: field.value,
        class: field.cssClass,
        name: field.name,
        placeholder: field.placeholder,
      };
      return attributes;
    },
    clearMultiselect(fieldId, updatedValue = null) {
      let targetField = this.fields.find((element) => fieldId == element.id);
      if (updatedValue) {
        targetField.value = updatedValue;
        targetField.propData.value = updatedValue;
      } else {
        if (targetField != undefined) {
          targetField.value = [];
          targetField.propData.value = [];
        }
      }
      if (fieldId == "dashboard_permissions") {
        this.refreshTicker1 = -this.refreshTicker1;
      }
      if (fieldId == "apiPermissions") {
        this.refreshTicker2 = -this.refreshTicker2;
      }
    },
    processMultiselectChange(newValues, fieldId) {
      if (fieldId == 'dashboard_permissions') {
        // For dashboard_permissions, users can only be assigned one of cameraAdmin, cameraOperator, cameraViewer
        const camsRoles = ['cameraAdmin', 'cameraOperator', 'cameraViewer'];
        const addedCam = newValues.find(value => camsRoles.includes(value.name) && !this.formValues?.dashboard_permissions?.find((_value) => _value == value.name));
        // If one of the 3 roles was added, only allow the newly selected (Not already in formValues) and all none cams permissions to pass
        if (!isFalsy(addedCam)) {
          newValues = newValues.filter(value => !camsRoles.includes(value?.name) || value?.name == addedCam?.name);

          // Remove all instances of cams roles from formValues (the new one will get re added below)
          this.formValues.dashboard_permissions = this.formValues.dashboard_permissions.filter(value => !camsRoles.includes(value))
        }
        const scrRoles = ['SCR Talk', 'SCR Listen', 'SCR None'];
        const addedScr = newValues.find(value => scrRoles.includes(value.name) && !this.formValues?.dashboard_permissions?.find((_value) => _value == value.name));
        if (!isFalsy(addedScr)) {
          newValues = newValues.filter(value => !scrRoles.includes(value?.name) || value?.name == addedScr?.name);
          this.formValues.dashboard_permissions = this.formValues.dashboard_permissions.filter(value => !scrRoles.includes(value))
        }

      }

      const multiSelectField = this.fields.find((field) => field.id == fieldId);
      if (multiSelectField.propData?.selectByKey) {
        this.formValues[fieldId] = newValues.map(
          (value) => value[multiSelectField.propData.selectByKey]
        );
        for (let i = 0; i < this.fields.length; i++) {
          if (fieldId === this.fields[i].id) {
            this.fields[i].value = newValues.map(
              (value) => value[multiSelectField.propData.selectByKey]
            );
          }
        }
      } else {
        this.formValues[fieldId] = newValues;
        if (this.formValues[fieldId] === this.fields[i].value) {
          this.fields[i].value = newValues;
        }
      }
    },
    proccessMultiSelectSelection(list, value, field) {
      if (list !== null) {
        const _index = list.indexOf(value);

        if (_index < 0) {
          list.push(value);
        } else {
          list.splice(_index, 1);
        }

        this.processMultiselectChange(list.map(_item => field.propData.options.find(_field => _field[field.propData.selectByKey] == _item)), field.id);
      }
    },
    submitForm() {
      const self = this;
      const isValid = this.validateInputs();
      if (isValid) {
        const payLoad = {};
        Object.keys(this.formValues).forEach((fieldId) => {
          const field = self.fields.find((field) => field.id == fieldId);
          const requestKey = field?.name ? field.name : fieldId;
          if (field.elementType == "checkbox") {
            payLoad[requestKey] = field.value || 0;
          } else {
            payLoad[requestKey] = self.formValues[fieldId] || '';
          }
        });

        return axios({
          method: this.submitMethod,
          url: this.submitURL,
          data: payLoad,
        })
          .then((response) => {
            // In case the user is new and the id wasn't previously set, set it for the UserPermissionsCompany to reference in their save
            if (!isFalsy(response?.data?.data?.id)) this.user.id = response.data.data.id;

            if (this.$refs?.UserPermissionsCompany) {
              return Promise.all(
                this.$refs?.UserPermissionsCompany?.map((_ref) => _ref.submitForm())
              )
                .then((responses) => {
                  self.formSubmitted = false;
                  self.$emit("responseComplete", response);
                })
                .catch(function (error) {
                  self.formSubmitted = false;
                  self.$emit("responseError", error);
                });
            } else {
              self.formSubmitted = false;
              self.$emit("responseComplete", response);
            }
          })
          .catch(function (error) {
            self.formSubmitted = false;
            self.$emit("responseError", error);
          });
      }
    },
    validateInputs() {
      const self = this;
      this.errors = {};
      const ssoField = this.fields.find(field => field.id === this.fieldIdForSingleSignOn);
      this.fields.forEach((field) => {
        const value = self.formValues[field.id];
        let errorString = "";

        if (field.required === true && (value == null || value == "" || value == [])) {
          errorString += "This field is required. ";
        }
        if (field.elementType === "input" || field.elementType === "password") {
          if (
            (field.inputType === "email" || field.inputType == "emailOnCreate") &&
            !self.validateEmail(self.formValues[field.id])
          ) {
            errorString += "Must be a valid email address. ";
          }
          if (field.inputType === "number" && !isFinite(self.formValues[field.id])) {
            errorString += "Must be a valid number. ";
          }
          if (
            field.inputType === "pin" &&
            self.validatePIN(self.formValues[field.id]) &&
            !isNaN(self.formValues[field.id])
          ) {
            errorString += "Must be at least 4 characters. ";
          }
          if (field.inputType === "pin" && !isFalsy(self.formValues[field.id]) && isNaN(self.formValues[field.id])) {
            errorString += "Must be a valid number only. ";
          }
          //check if single sign on is checked and if Not then and only then check for password and confirm password errors
          if (!ssoField?.value) {
            if (
              field.inputType === "password" &&
              (!this.formValues["password"] || this.formValues["password"].length < 8)
            ) {
              errorString += "Must be at least 8 characters. ";
            }
            if (
              field.inputType === "password" &&
              (this.formValues["password"] && this.formValues["password"].length >= 8) && 
              this.formValues["confirmPassword"] != this.formValues["password"]
            ) {
              errorString += "Passwords do not match. ";
            }
            if (
              field.inputType === "confirmPassword" &&
              this.formValues["confirmPassword"] && this.formValues["confirmPassword"] != this.formValues["password"]
            ) {
              errorString += "Passwords do not match. ";
            }
          }

          if (
            field.inputType === "pin" &&
            !self.uniquePIN() &&
            this.formValues["pin"]?.length > 0
          ) {
            errorString += "PIN already in use. ";
          }
          if ((field.inputType === "email" || field.inputType == "emailOnCreate") && !self.uniqueEmail(field.inputType)) {
            errorString += "Email already in use. ";
          }
        } else if (field.elementType === "select") {
          if (!field.options.find(_value => _value.value == value)) {
            errorString += "Invalid input.";
          }
        }
        if (errorString.length > 0) {
          self.errors[field.id] = errorString;
        }
      });
      if (!this.isErrors) {
        return true;
      } else {
        return false;
      }
    },
    validatePIN(pinValue) {
      return !isFalsy(pinValue?.length) && pinValue.length < 4;
    },
    validatePassword(password) {
      return this.formValues["confirmPassword"] != password;
    },
    validateEmail(testEmail) {
      //RFC2822 Email validation regex string
      const regex = /[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+\/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?/;
      return regex.test(testEmail);
    },
    isFieldError(fieldId) {
      return Object.values(this.errors)?.length > 0 && this.errors[fieldId];
    },
    uniquePIN() {
      let pinMatches = this.usersInfo.filter(
        (user) => user.pin == this.formValues["pin"]
      );
      return Object.keys(pinMatches).length == 0;
    },
    uniqueEmail(inputType) {
      const isEditing = inputType === 'email';
      const hasNewEmail = this.formValues["email"] !== this.user.email;

      if (!isEditing || (isEditing && hasNewEmail)) {
        let emailMatches = this.usersInfo.filter(
          (user) => user.email == this.formValues["email"]
          );

        return Object.keys(emailMatches).length == 0;
      }
      return true; // True would mean the email is unique
    },

    lookupCustomer(customerId) {
      return this.companies.find((company) => company.id === customerId);
    },
    dropDownChanged(value, id) {
      this.inputData({
        target: {
          id: id,
          value: value
        }
      });
    },
  },
  watch: {
    fields: {
      handler(newval, oldval) {
        //onload when props are added populate data for each form field into a record
        newval.forEach((field) => {
          this.$set(this.formValues, field.id, field.value || this.formValues[field.id])
        });
        let ssoField = this.fields.find(
          (element) => this.fieldIdForSingleSignOn == element.id
        );
        let passwordField = this.fields.find((element) => "password" == element.id);
        let confirmPasswordField = this.fields.find(
          (element) => "confirmPassword" == element.id
        );
        if (ssoField && passwordField && confirmPasswordField) {
          if (ssoField.value) {
            passwordField.value = "";
            confirmPasswordField.value = "";
            this.passwordDisabled = true;
            //find a label with the text having "Password and Confirm Password" in it and hide it
            document
              .querySelectorAll("label")
                .forEach((element) =>
                    element.textContent.includes("Password")
                    ? (element.style.display = "none")
                    : null
                );

          } else {
            this.passwordDisabled = false;
            //find a label with the text having "Password and Confirm Password" in it and show it
            document
              .querySelectorAll("label")
                .forEach((element) =>
                    element.textContent.includes("Password")
                    ? (element.style.display = "block")
                    : null
                );
          }
        }
      },
      immediate: true,
      deep: true,
    },
    companyClearTick: {
      handler(newval, oldval) {
        //filters api multiselect values for create/edit user page to not include selected company when user changes selections
        let apiPermissionsField = this.fields.find(
          (element) => "apiPermissions" == element.id
        );
        if (apiPermissionsField && this.selectedCompany) {
          if (apiPermissionsField.value.includes(this.selectedCompany)) {
            apiPermissionsField.value = apiPermissionsField.value.filter(
              (companyId) => companyId !== this.selectedCompany
            );
            this.clearMultiselect("apiPermissions", apiPermissionsField.value);
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.h3 {
  margin: 10px 0px 25px;
}

.customer-api-permissions {
  padding: 10px 0px;
}
.formOuterBox {
  display: flex;
  width: 100%;;
  padding: 0px 0px;
  flex-direction: row;
  align-items: flex-start;
  border-radius: 4px;
  border: 1px solid #676e78;
}
</style>
