var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex",
      style: { height: _vm.height ? _vm.height + "px" : "auto" },
    },
    [
      _vm._l(_vm.orderedWells, function (well) {
        return _c(
          "div",
          {
            key: well.index,
            staticClass: "col p-0",
            style: { "max-width": _vm.getMaxWidth },
          },
          [
            _c("well-small-component", {
              key: well.index,
              staticStyle: {
                width: "100%",
                display: "flex",
                "justify-content": "center",
              },
              attrs: {
                well: well,
                name: well.name,
                color: well.color,
                currentStage: well.currentStage,
                numberOfStages: well.numberOfStages,
                activity: well.activity,
                pressureWell: _vm.casingPressures[well.index],
                status: well.status,
                index: well.index,
                casingPressures: _vm.casingPressures,
                crownPressures: _vm.crownPressures,
                stageStart: well.start,
                duration: well.duration,
                jobEnd: _vm.jobEnd,
                job: _vm.job,
                "is-multi-wireline": _vm.isMultiWireline,
                "wireline-systems": _vm.wirelineSystems,
                "is-multi-frac": _vm.isMultiFrac,
                "frac-systems": _vm.fracSystems,
                showWellLocks: _vm.showWellLocks,
              },
              on: {
                "wireline-duration": _vm.getWirelineDuration,
                resize: _vm.checkDisplayHeight,
              },
            }),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("lock-button", {
        attrs: {
          activeTooltipText: "Hide Valve Locks",
          inactiveTooltipText: "Show Valve Locks",
          localStorageKey: "ShowWellLocks",
          onValueChanged: (value) => (_vm.showWellLocks = value),
        },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }