var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "px-2 py-1" }, [
    _c("div", { staticClass: "border border-secondary rounded p-2" }, [
      _vm.allowEdit
        ? _c(
            "div",
            {
              staticClass: "btn btn-sm btn-primary float-right m-1",
              on: { click: _vm.deleteWell },
            },
            [_vm._v("X")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Short name")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: { type: "text", id: "name", disabled: _vm.isReadOnly() },
          domProps: { value: _vm.wellInfo.name },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Long name")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "text", id: "nameLong", disabled: _vm.isReadOnly() },
          domProps: { value: _vm.wellInfo.nameLong },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Number of stages")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: {
            type: "number",
            id: "numberOfStages",
            disabled: _vm.isReadOnly(),
          },
          domProps: { value: _vm.wellInfo.numberOfStages },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Cluster spacing")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: {
            type: "number",
            id: "clusterSpacing",
            min: "0",
            oninput: "validity.valid||(value='')",
          },
          domProps: { value: _vm.wellInfo.clusterSpacing },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Cluster spacing positive variance")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: {
            type: "number",
            id: "clusterSpacingPositive",
            min: "0",
            oninput: "validity.valid||(value='')",
          },
          domProps: { value: _vm.wellInfo.clusterSpacingPositive },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Cluster spacing negative variance")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: {
            type: "number",
            id: "clusterSpacingNegative",
            min: "-9999",
            max: "0",
            onfocusout: "validity.valid||(value='0')",
          },
          domProps: { value: _vm.wellInfo.clusterSpacingNegative },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Stage spacing")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: {
            type: "number",
            id: "stageSpacing",
            min: "0",
            oninput: "validity.valid||(value='')",
          },
          domProps: { value: _vm.wellInfo.stageSpacing },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Stage spacing positive variance")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: {
            type: "number",
            id: "stageSpacingPositive",
            min: "0",
            oninput: "validity.valid||(value='')",
          },
          domProps: { value: _vm.wellInfo.stageSpacingPositive },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Stage spacing negative variance")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control text-secondary",
          attrs: {
            type: "number",
            id: "stageSpacingNegative",
            min: "-9999",
            max: "0",
            onfocusout: "validity.valid||(value='0')",
          },
          domProps: { value: _vm.wellInfo.stageSpacingNegative },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Color")]),
        _vm._v(" "),
        _c("input", {
          staticClass: "form-control",
          attrs: { type: "color", id: "color", disabled: _vm.isReadOnly() },
          domProps: { value: _vm.wellInfo.color },
          on: { change: _vm.updateWell },
        }),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "form-group" }, [
        _c("label", [_vm._v("Configuration")]),
        _vm._v(" "),
        _c(
          "select",
          {
            staticClass: "form-control",
            attrs: { id: "configuration_id", disabled: _vm.isReadOnly() },
            on: { change: _vm.updateWell },
          },
          [
            _c(
              "option",
              {
                domProps: {
                  value: null,
                  selected: _vm.wellInfo.configuration_id == null,
                },
              },
              [_vm._v("Not configured")]
            ),
            _vm._v(" "),
            _vm._l(_vm.wellConfigs, function (wellConfig, index) {
              return _c(
                "option",
                {
                  key: index,
                  domProps: {
                    value: wellConfig.id,
                    selected: wellConfig.id == _vm.wellInfo.configuration_id,
                  },
                },
                [
                  _vm._v(
                    _vm._s(wellConfig.config_number) +
                      " - " +
                      _vm._s(wellConfig.description)
                  ),
                ]
              )
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _vm.wellInfo.configuration_id
        ? _c(
            "div",
            {
              staticClass: "col-12 text-center p-0 m-0",
              staticStyle: { "max-height": "216px", "min-height": "216px" },
            },
            [
              _c("img", {
                ref: "wellImageRef",
                staticStyle: { "max-height": "inherit" },
                attrs: {
                  src:
                    "/get-wellhead-image?config_number=" +
                    _vm.getWellConfig(_vm.wellInfo.configuration_id)
                      .config_number,
                },
              }),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.allowEdit && _vm.wellCount > 1
        ? _c("hr", { staticClass: "bg-secondary" })
        : _vm._e(),
      _vm._v(" "),
      _vm.allowEdit
        ? _c("div", { staticClass: "d-flex justify-content-around" }, [
            _vm.index > 0
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.changeOrder("moveUp")
                      },
                    },
                  },
                  [_vm._v(" < ")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.index < _vm.wellCount - 1
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.changeOrder("moveDown")
                      },
                    },
                  },
                  [_vm._v(" > ")]
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }