<template> 
    <div class="row">
        <div class="col">
        </div>
        <div class="col-2 mt-2">
          <div class="btn btn-primary btn-block" @click="back">Back</div>
        </div>
        <div class="w-100 mb-2"></div>
        <div class="col-8 mb-5">
            <setting-individual-component v-for="setting in settings" v-bind:description="setting.description" v-bind:key="setting.index"
            v-bind:topic="setting.topic" v-bind:value="setting.value" @updateSetting="updateSetting"></setting-individual-component>
        </div>
    </div>

</template>

<script>
    export default {
        data() {
            return {
                settings:[],
            }
        },
        props: ['nodeType','nodeIndex','role','username'],
        mounted() {
          var self = this;
          this.$root.$on('mqttConnected',function(){
            console.log("messagesToolbar component got a connect message!");
            self.mqttInit();
          });
            

        },
        methods: {
            back(){
              window.history.back();
            },
            updateSetting: function(event){
                this.$root.$mqtt.publish(event.topic,event.payload+','+this.username,1,true);
            },
            mqttInit(){
              var subscribe = '';
              if(this.role == 'admin'){
                  subscribe = this.nodeType+'/'+this.nodeIndex+'/setting/#';
                  this.$root.$mqtt.subscribe(subscribe);
              } else if (this.role == 'wsm'){
                  subscribe = this.nodeType+'/'+this.nodeIndex+'/setting/1/#';
                  this.$root.$mqtt.subscribe(subscribe);
                  subscribe = this.nodeType+'/'+this.nodeIndex+'/setting/2/#';
                  this.$root.$mqtt.subscribe(subscribe);
              } else if (this.role == 'wireline' && this.nodeType == 'spool'){
                  subscribe = this.nodeType+'/'+this.nodeIndex+'/setting/1/#';
                  this.$root.$mqtt.subscribe(subscribe);
              }
              this.$root.$mqtt.publish(this.nodeType+'/'+this.nodeIndex+'/settingRequest',this.role,1);
              

              this.$root.$mqtt.on("message", function(topic, message) {
                  //console.log("topic:"+topic+", message:"+message);
                  var topicArray = topic.split("/");
                  
                  self = this;
                  if(topicArray[0]==self.nodeType && topicArray[1]==self.nodeIndex && topicArray[2]=='setting'){
                      var found = false;
                      var insertIndex = 0;
                      var msg = JSON.parse(message);
                      $.each(self.settings,function(index,value){
                          if(self.settings[index].description<msg.description)
                              insertIndex = index + 1;
                          if(value.name == msg.name){
                              found = true;
                              self.settings[index].value =msg.value;
                          }
                      });
                      if(!found){
                          //console.log("topic:"+topic+", message:"+message);
                          //console.log("Setting with name:"+msg.name);
                          var setting = {};
                          setting.topic = self.nodeType+'/'+self.nodeIndex+'/set/'+topicArray[3]+'/'+msg.name;
                          setting.value = msg.value;
                          setting.description = msg.description;
                          setting.name = msg.name;
                          self.settings.splice(insertIndex,0,setting);
                      }
                  }
                  
              }.bind(this));
            }
        }
    }
</script>