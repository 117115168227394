<template>
<LibraryFiltersBaseModal :id="id" ref="libraryFilters"
    :items="plugs"
    :filters="filters"
    title="Filter Plugs"
    @clearFilters="filters = $event"
>
    <template #body="{ findUniqueValues, pushToFilter, removeFromFilter, handleEvent }">
        <div class="filter-container">
            <iws-select
                label="Description"
                :value="filters.description"
                :options="findUniqueValues('description')"
                @change="handleEvent(filters.description, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.description" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" @click="removeFromFilter(filters.description, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Icon"
                :value="filters.icon"
                :options="findUniqueValues('icon')"
                @change="handleEvent(filters.icon, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.icon" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" @click="removeFromFilter(filters.icon, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Outer Diameter (in)"
                :value="filters.outer_diameter"
                :options="findUniqueValues('outer_diameter')"
                @change="handleEvent(filters.outer_diameter, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.outer_diameter" outer_diameter="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" @click="removeFromFilter(filters.outer_diameter, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Inner Diameter (in)"
                :value="filters.inner_diameter"
                :options="findUniqueValues('inner_diameter')"
                @change="handleEvent(filters.inner_diameter, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.inner_diameter" inner_diameter="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" @click="removeFromFilter(filters.inner_diameter, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Make"
                :value="filters.make"
                :options="findUniqueValues('make')"
                @change="handleEvent(filters.make, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.make" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" @click="removeFromFilter(filters.make, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Model"
                :value="filters.model"
                :options="findUniqueValues('model')"
                @change="handleEvent(filters.model, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.model" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" @click="removeFromFilter(filters.model, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Customer"
                :value="filters.customerName"
                :options="findUniqueValues('customerName')"
                @change="handleEvent(filters.customerName, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.customerName" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" @click="removeFromFilter(filters.customerName, index)"></i>
                </span>
            </div>
        </div>
    </template>
</LibraryFiltersBaseModal>
</template>
    
<script>
import LibraryFiltersBaseModal from './LibraryFiltersBaseModal.vue';

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        plugs: [Array, Object],
        enableCustomerCol: Boolean
    },

    components: {
        LibraryFiltersBaseModal
    },

    data: () => ({
        filters: {
            description: [],
            icon: [],
            outer_diameter: [],
            inner_diameter: [],
            make: [],
            model: [],
            customerName: []
        }
    }),

    methods: {
        open(filters) {
            this.filters = JSON.parse(JSON.stringify(filters));

            return this.$refs.libraryFilters.open();
        }
    }
}
</script>