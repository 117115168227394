import { Pie, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
import zoom from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotations from 'chartjs-plugin-annotation';

export default {
    name: 'PieChart',
    extends: Pie,
    mixins: [reactiveProp],
    props: ['options', 'customPlugins'],
    mounted() {
        this.addPlugin(zoom);
        this.addPlugin(ChartDataLabels);
        this.addPlugin(ChartAnnotations);
        if(this.customPlugins) {
          for(let i = 0; i < this.customPlugins.length; i++) {
            this.addPlugin(this.customPlugins[i]);
          }
        }

        Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
            display: false
        });
        this.renderChart(this.chartData, this.options);
    },
    watch: {
        chartData: function (newVal, oldVal) {
            this.renderChart(this.chartData, this.options);
        }
    },
    methods: {
    }
};