<template>
<div class="dark-modal modal" id="ToolstringConstructModal" ref="ToolstringConstructModal" tabindex="-1" role="dialog">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">
                    Toolstring Construction
                </h5>

                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div v-if="toolstring" class="modal-body">
                <div v-if="loading" class="pb-3 px-2">
                    loading....
                </div>
                <template v-else>
                    <div class="row justify-content-between">
                        <div class="field-container col-xs" id="toolstringName">
                            <iws-input ref="nameInput"
                                label="Toolstring Name"
                                :value.sync="toolstring.name"
                                required="true"
                                placeholder="Give this toolstring a name"
                                error-message="Please provide a name"
                            />
                        </div>

                        <div class="col-xs">
                            <span style="margin-right: 10px">
                                <span id="tapered-title">
                                    Tapered Tool-String
                                </span>
                                <span id="tapered-sub-title">
                                    Apply same configuration to all guns
                                </span>
                            </span>
                            <iws-switch :onPress="onTaperedToggled" :value="isTapered" :labels="['Off', 'On']" />
                        </div>
                    </div>

                    <iws-table :columns="toolstringConfigColumns" style="margin-bottom: 20px">
                        <template #header_component>
                            Component
                        </template>
                        <template #header_topShot>
                            CCL to Top Shot
                        </template>
                        <template #header_description>
                            Description
                        </template>
                        <template #header_spf>
                            SPF
                        </template>
                        <template #header_phasing>
                            Phasing
                        </template>
                        <template #header_chargeType>
                            Charge Type
                        </template>
                        <template #header_null>

                        </template>

                        <template #body>
                            <tr v-for="(toolDetail, index) in toolstringData" :key="`${index}_${toolDetail.id}`">
                                <td>
                                    {{ toolDetail.name }}
                                </td>
                                <td>
                                    <div id="topShotInput">
                                        <iws-input ref="customInput"
                                            type="number"
                                            required="true"
                                            :value.sync="toolDetail.distance"
                                            append-icon="slot"
                                        >
                                            <template #appendIcon>
                                                Ft
                                            </template>
                                        </iws-input>
                                    </div>
                                </td>
                                <td>
                                    <iws-select ref="customInput"
                                        :value.sync="toolDetail.selectedIndex"
                                        :options="toolDetail.name==='Plug'? plugsDropDownOptions : gunsDropDownOptions"
                                        value-name="index"
                                        required="true"
                                        @change="onDescriptionChange(
                                            toolDetail,
                                            (toolDetail.name==='Plug'? plugsDropDownOptions : gunsDropDownOptions)[$event],
                                            index,
                                            $event
                                        )"
                                    />
                                </td>
                                <td>
                                    {{ toolDetail.spf }}
                                </td>
                                <td>
                                    {{ toolDetail.phasing }}
                                </td>
                                <td>
                                    {{ toolDetail.chargeType }}
                                </td>
                                <td>
                                    <span v-if="toolDetail.name !== 'Plug' && toolstringData.length > 2"
                                        class="clickable table-row-action text-danger"
                                        @click="removeGun(toolDetail)"
                                    >
                                        <i class="fas fa-trash-alt"></i> Delete
                                    </span>
                                </td>
                            </tr>
                        </template>
                    </iws-table>
                </template>
            </div>

            <div class="modal-footer">
                <div style="position: absolute; left: 16px; display: inline-block;">
                    <iws-button type="outline-light" @click="pushGun">
                        <template #text>
                            <i class="fas fa-plus-circle" style="margin-right: 5px" />Add Gun
                        </template>
                    </iws-button>
                </div>

                <button class="btn btn-outline-light" id="cancel_button">
                    Cancel
                </button>

                <iws-button
                    :text="`${ isEdit ? 'Save' : 'Add' } Toolstring`"
                    :click="onSaveClick"
                />
            </div>
        </div>
    </div>
</div>
</template>


<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isTruthy, isNullOrEmpty, toast } = GlobalFunctions;

export default {
    props: {
        jobNumber: {
            type: String | Number,
            required: true
        },
        wellNumber: {
            type: String | Number,
            required: true
        }
    },

    data: () => ({
        toolstring: null,

        toolstringData: [],

        gunsData: [],
        gunsDropDownOptions: [],
        plugsData: [],
        plugsDropDownOptions: [],

        isTapered: false,

        loading: true,
        resolve: null,
        toolstringConfigColumns: [
            {
                key: 'component',
                label: 'Component',
                sortable: false
            },
            {
                key: 'topShot',
                label: 'CCL to Top Shot',
                sortable: false
            },
            {
                key: 'description',
                label: 'Description',
                sortable: false
            },
            {
                key: 'spf',
                label: 'SPF',
                sortable: false
            },
            {
                key: 'phasing',
                label: 'Phasing',
                sortable: false
            },
            {
                key: 'chargeType',
                label: 'Charge Type',
                sortable: false
            },
            {
                key: 'null',
                label: 'null',
                sortable: false
            }
        ]
    }),

    computed: {
        isEdit() {
            return isTruthy(this.toolstring?.id) && this.toolstring?.id > 0;
        }
    },

    methods: {
        changeAllGunRows(description, plannedDepth, gunID, selectedIndex, spf, phasing, chargeType) {
            if (!isNullOrEmpty(this.toolstringData))
                this.toolstringData = this.toolstringData.map(toolstring => {
                    if (toolstring.name === 'Plug')
                        return toolstring;
                    return {
                        ...toolstring,
                        description,
                        plannedDepth,
                        gunID,
                        selectedIndex,
                        spf,
                        phasing,
                        chargeType
                    };
                });
        },
        onDescriptionChange(toolDetail, description, index, selectedIndex) {
            let newSelectedPG={}, plugID=null, gunID=null;

            if (toolDetail.name === 'Plug') {
                newSelectedPG = this.plugsData[selectedIndex];
                plugID = newSelectedPG.id;
            } else {
                newSelectedPG = this.gunsData[selectedIndex];
                gunID = newSelectedPG.id;

                if (this.isTapered)
                    this.changeAllGunRows(description, 0, gunID, selectedIndex, newSelectedPG.shot_density, newSelectedPG.phasing, newSelectedPG.carrier_type);
            }

            this.$set(this.toolstringData, index, {
                ...toolDetail,
                description,
                spf: newSelectedPG.shot_density,
                phasing: newSelectedPG.phasing,
                chargeType: newSelectedPG.carrier_type,
                plannedDepth: 0,
                gunID,
                plugID,
                selectedIndex
            });
        },


        pushGun() {
            let allToolstringGuns = this.toolstringData.filter(toolstring => toolstring.gunID !== null);
            const currentPlug = this.toolstringData.find(toolstring => toolstring.plugID !== null);

            allToolstringGuns.unshift({
                ...allToolstringGuns[0],
                id: null,
                name: `Gun ${allToolstringGuns.length+1}`,
                distance: null,
                selectedIndex: null,
                gunOrPlugIndex: allToolstringGuns.length+1
            });

            this.toolstringData = [
                ...allToolstringGuns,
                { ...currentPlug, gunOrPlugIndex: allToolstringGuns.length+1 }
            ];
        },
        popGun() {
            let allToolstringGuns = this.toolstringData.filter(toolstring => toolstring.gunID !== null);
            const currentPlug = this.toolstringData.find(toolstring => toolstring.plugID !== null);

            allToolstringGuns.shift();
            newToolstringData = allToolstringGuns;
            newToolstringData.push({
                ...currentPlug,
                gunOrPlugIndex: newToolstringData.length+1
            });

            this.toolstringData = newToolstringData;
        },
        removeGunAt(index) {
            // Find all guns, they are displayed in reverse order, so to remove at index reverse them into chronological order
            let allToolstringGuns = [...this.toolstringData].filter(toolstring => toolstring.gunID !== null).reverse();
            const currentPlug = this.toolstringData.find(toolstring => toolstring.plugID !== null);
            allToolstringGuns.splice(index, 1);

            // Reshift numbering of all remaining guns
            for (let i = 0; i < allToolstringGuns.length; i++) {
                allToolstringGuns[i].name = `Gun ${i+1}`
                allToolstringGuns[i].gunOrPlugIndex = i+1
            }

            this.toolstringData = [
                ...allToolstringGuns.reverse(),
                { ...currentPlug, gunOrPlugIndex: allToolstringGuns.length+1 }
            ];
        },

        removeGun(gun) {
            return GlobalFunctions.iwsConfirm({
                title: 'Delete gun',
                body: `Are you sure you want to delete "${gun.name}"? This action cannot be undone!`,
                confirmColour: 'danger',
                width: '400px'
            }).then(_answer => {
                if (_answer)
                    this.removeGunAt(gun.gunOrPlugIndex-1);
            });
        },

        onTaperedToggled() {
            this.isTapered = !this.isTapered;
        },

        validateForm() {
            // Validate all inputs to show all invalid inputs, but fail on any failed inputs
            return [ this.$refs.nameInput, ...this.$refs.customInput ].filter(_ref => !_ref.validateInput()).length === 0;
        },
        async onSaveClick() {
            if (this.validateForm()) {
                const toolstringGuns = [];
                let toolstringPlug = null;
                this.toolstringData.map((toolstring)=> {
                    const toolstringObject = {
                        index: toolstring.gunOrPlugIndex,
                        value: toolstring.distance ? toolstring.distance : 0,
                        gunID: toolstring.gunID,
                        plugID: toolstring.plugID,
                        plannedDepth: toolstring.plannedDepth ? toolstring.plannedDepth : 0
                    };

                    if(toolstring.gunID !== null) {
                        toolstringGuns.push(toolstringObject);
                    } else if (toolstring.plugID !== null) {
                        toolstringPlug = toolstringObject;
                    }

                    return toolstringObject;
                });

                toolstringGuns.sort((a, b)=>{return a.index-b.index;});

                const toolstringDetails = toolstringGuns.map((toolstringGun, index) => {
                    return { ...toolstringGun, index: index+1 };
                });

                toolstringDetails.push({ ...toolstringPlug, index: toolstringGuns.length+1 });

                return $.ajax({
                    method: 'POST',
                    url: '/toolstring',
                    data: {
                        '_token': GlobalFunctions.getCSRFToken(),
                        jobNumber: this.jobNumber,
                        id: this.toolstring.id,
                        name: this.toolstring.name,
                        stageNumber: null, // We do not save stage number in this modal, that has a dedicated modal
                        wellNumber: this.toolstring.wellNumber,
                        activityType: 'wireline',
                        toolstringDetails: toolstringDetails
                    },
                    dataType: 'json'
                }).then(_ => {
                    this.resolve(_)
                }).catch(error => {
                    // If the request failed validation, display the errors so the user can correct them
                    const _errors = Object.values(error?.responseJSON?.errors).flat();

                    if (!isNullOrEmpty(_errors))
                        _errors.forEach(_error => {
                            toast({
                                title: _error,
                                variant: 'danger'
                            });
                        });
                });
            } else {
                toast({
                    title: 'Please fill all fields',
                    variant: 'danger'
                });
            }
        },

        getMapToolstringData(toolstringDetails = [], guns, plugs) {
            if (isNullOrEmpty(toolstringDetails) && !isNullOrEmpty(guns) && !isNullOrEmpty(plugs)) {
                // No toolstring set for this job
                return [{
                    gunOrPlugIndex: 1,
                    name: 'Gun 1',
                    distance: 0,
                    description: guns[0]?.name,
                    spf: guns[0]?.shot_density,
                    phasing: guns[0]?.phasing,
                    chargeType: guns[0]?.carrier_type,
                    plannedDepth: 0,
                    gunID: guns[0]?.id,
                    plugID: null,
                    plannedDepth: 0,
                    selectedIndex: 0
                }, {
                    gunOrPlugIndex: 2,
                    name: 'Plug' ,
                    distance: 0,
                    description: plugs[0]?.description,
                    plannedDepth: 0,
                    plugID: plugs[0]?.id,
                    gunID: null,
                    plannedDepth: 0,
                    selectedIndex: 0
                }];
            }

            return toolstringDetails.map(toolstring => {
                if (toolstring.gunID !== null) { //gun
                    return {
                        ...toolstring,
                        selectedIndex: guns.findIndex(gun => gun.id === toolstring.gunID)
                    };
                } else { //plug
                    return {
                        ...toolstring,
                        selectedIndex: plugs.findIndex(plug => plug.id === toolstring.plugID)
                    };
                }
            });
        },
        downloadData(toolstringData) {
            const networkCalls = async () => await Promise.all([
                $.get('/guns/all'),
                $.get('/plugs/all')
            ]);

            return networkCalls().then(async data => {
                this.gunsData = data[0];
                this.plugsData = data[1];
                this.gunsDropDownOptions = this.gunsData.map(gun => gun.name);
                this.plugsDropDownOptions = this.plugsData.map(plug => plug.description);
                this.toolstringData = this.getMapToolstringData(toolstringData, this.gunsData, this.plugsData);
                this.loading = false;
            });
        },

        open(toolstring) {
            this.loading = true;

            const modalElem = $('#ToolstringConstructModal');
            this.toolstring = toolstring;
            this.downloadData(toolstring.toolstringDetails);

            return new Promise((resolve, reject) => {
                modalElem.show();
                this.resolve = (val) => {
                    modalElem.hide();
                    resolve(val);
                };
            });
        }
    },

    mounted() {
        $('#ToolstringConstructModal')?.find('#cancel_button, .close').on('click', () => {
            this.resolve(null);
        });
    }
};
</script>

<style scoped>
    .modal-dialog {
        width: 96vw;
        max-width: 96vw;
    }

    .modal-body {
        padding: 0px 1rem !important;
    }
    .modal-body>.row.justify-content-between {
        padding: 1rem 16px;
    }

    #topShotInput {
        width: 110px;
    }

    #toolstringName {
        width: 300px;
        margin-bottom: 30px;
    }

    #tapered-title {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        color: white;
        display: block;
    }
    #tapered-sub-title {
        font-style: normal;
        font-weight: #667085;
        font-size: 16px;
        line-height: 24px;

        color: #667085;
        display: inline-block;
    }

    .switch {
        top: -20px;
    }
</style>
