<template>
    <div>
        <iws-breadcrumbs class="mt-4 mb-1"
            :path="[{
                title: 'Home',
                link: '/home'
            }, {
                title: 'Contractors',
                link: '/contractors'
            }]"
        />
        <div class="d-flex justify-content-between mb-4">
            <div class="page-title">
                Contractors
            </div>
            <div style="position: relative; top: -20px">
                <button class="btn btn-primary" @click="create">New Contractor</button>
            </div>
        </div>

        <div class="row">
            <div class="col">
                <table class="table table-striped table-hover mt-2" :class="{'table-dark': darkMode}">
                    <thead>
                    <th scope="col">Name</th>
                    <th scope="col">Type</th>
                    <th scope="col" v-if="role == 'admin'">Customer</th>
                    <th scope="col">&nbsp;</th>
                    </thead>
                    <tbody>
                    <tr v-for="contractor in contractors" v-bind:key="contractor.id">
                        <td>{{contractor.name}}</td>
                        <td>{{contractor.type}}</td>
                        <td v-if="role == 'admin'">{{contractor.customerName}}</td>
                        <td> <span class="badge badge-primary" @click="edit(contractor.id)" style="cursor:pointer;"
                                   v-if="role == 'admin' || role == 'companyAdmin'">Edit</span>
                            <span class="badge badge-primary" @click="open(contractor.id)" style="cursor:pointer;"
                                  v-if="role == 'admin' || role == 'companyAdmin'">View</span></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['contractors', 'role'],
    methods: {
        open: function (id) {
            window.open('/contractors/' + id, '_self');
        },
        edit: function (id) {
            window.open('/contractors/' + id + '/edit', '_self');
        },
        create: function () {
            window.open('/contractors/0/edit', '_self');
        }
    },
    computed: {
        darkMode: function () {
            return this.$root.darkMode;
        }
    }
};
</script>
