<template>
    <div class="d-flex flex-row monospace">
        <div class="d-flex flex-row mr-1">
            <input type="number" ref="yearInput" v-model="yearInput" placeholder="yyyy" class="maxInputWidth4Digits" :min="minYear" :max="maxYear" v-on:keyup="shiftFocusWithArrows($event,0)" @input="manualDateEntered=true">/
            <input type="number" ref="monthInput" v-model="monthInput" placeholder="mm" class="maxInputWidth2Digits" min="1" max="12" v-on:keyup="shiftFocusWithArrows($event,1)" @input="manualDateEntered=true">/
            <input type="number" ref="dayInput" v-model="dayInput" placeholder="dd" class="maxInputWidth2Digits" min="1" max="31" v-on:keyup="shiftFocusWithArrows($event,2)" @input="manualDateEntered=true">
        </div>
        <div class="d-flex flex-row ml-1">
            <input type="number" ref="hourInput" v-model="hourInput" placeholder="hh" class="maxInputWidth2Digits" min="1" max="12" v-on:keyup="shiftFocusWithArrows($event,3)" @input="manualDateEntered=true">:
            <input type="number" ref="minuteInput" v-model="minuteInput" placeholder="mm" class="maxInputWidth2Digits" min="0" max="60" v-on:keyup="shiftFocusWithArrows($event,4)" @input="manualDateEntered=true">:
            <input type="number" ref="secondInput" v-model="secondInput" placeholder="ss" class="maxInputWidth2Digits" min="0" max="60" v-on:keyup="shiftFocusWithArrows($event,5)" @input="manualDateEntered=true">
            <select class="ml-1 clickable" ref="meridiemInput" v-model="meridiemInput"  v-on:keyup="shiftFocusWithArrows($event, 6)" @input="manualDateEntered=true">
                <option v-for="(value,key) in meridiemOptions" :key="key" :value="value">{{key}}</option>
            </select>
        </div>
        <div :style="{visibility: isDateTimeSet ? 'visible' : 'hidden'}"><img src="/images/icons/close.svg" class="close-button clickable px-1 mx-1" @click="clearInput"/></div>
    </div>
</template>

<script>
import moment from 'moment';

const DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export default {
    props: {
        jobHourOffset: {
            type: Number,
            required: true
        },
        parentDateString: {
            type:String
        },
        min: {
            type:[String, Number]
        },
        max: {
            type:[String, Number]
        }
    },
    data() {
        return {
            dateTime: {
                year:null,
                month:null,
                day:null,
                hour:null,
                minute:null,
                second:null,
                meridiem:'am',
                utcOffset:0
            },
            meridiemOptions: {
                AM:'am',
                PM:'pm'
            },
            isDateTimeSet: false,
            manualDateEntered: false,
            focusPositionRefs: ['yearInput', 'monthInput', 'dayInput', 'hourInput', 'minuteInput', 'secondInput', 'meridiemInput']
        }
    },
    mounted() {
        this.dateTime.utcOffset = this.jobHourOffset;
    },
    methods: {
        shiftFocusWithArrows(event, focusIndex) {
            if (event?.key === 'ArrowLeft') {
                if (focusIndex > 0) {
                    const newFocusRef = this.focusPositionRefs[focusIndex - 1];
                    if (this.$refs[newFocusRef]) {
                        this.$refs[newFocusRef].focus();
                    }
                }
            } else if (event?.key === 'ArrowRight') {
                if (focusIndex < this.focusPositionRefs.length - 1) {
                    const newFocusRef = this.focusPositionRefs[focusIndex + 1];
                    if (this.$refs[newFocusRef]) {
                        this.$refs[newFocusRef].focus();
                    }
                }
            }

        },
        getMonthDays(monthNumber, yearNumber) {
            switch(monthNumber) {
                case 1:
                    return 31
                case 2:
                    const leapYear = yearNumber % 4 === 0;
                    if (leapYear) {
                        return 29;
                    } else {
                        return 28;
                    }
                case 3:
                    return 31
                case 4:
                    return 30
                case 5:
                    return 31
                case 6:
                    return 30
                case 7:
                    return 31;
                case 8:
                    return 31
                case 9:
                    return 30;
                case 10:
                    return 31;
                case 11:
                    return 30;
                case 12:
                    return 31;
                default:
                    return null;
            }
        },
        validateNumberInput(value,min,max) {
            //value comes in a string from the input
            const numValue = Number(value);
            if ((typeof numValue) == 'number') {
                if (numValue < min) {
                    return min;
                }
                if (numValue > max) {
                    return max;
                }
                return numValue;         
            }
            return null;

        },
        validateDaysInputByMonth(value) {
            if (this.monthInput && this.yearInput) {
                if (Number(this.monthInput) < this.maxMonth) {
                    const maxMonthDays = this.getMonthDays(Number(this.monthInput), Number(this.yearInput));
                    return value < maxMonthDays ? value : maxMonthDays;
                } else {
                    if (value < this.maxDay) {
                        return value;
                    }
                    return this.maxDay;
                }
            } 
            return null;
        },
        clearInput() {
            this.dateTime.year = null;
            this.dateTime.month = null;
            this.dateTime.day = null;
            this.dateTime.hour = null;
            this.dateTime.minute = null;
            this.dateTime.second = null;
            this.dateTime.meridiem = 'am';
            this.isDateTimeSet = false;
            this.$emit('clearInput');

        },
        checkEveryKeyHasData(testObject) {
            //check that every input is entered, so it doesn't have a null value
            const {meridiem, utcOffset, ...newTest} = testObject; //copy all vars except meridiem and utc offset
            return Object.values(newTest).every(value => (value !== null && value !== NaN));
        },
        wrapHoursTo24(hours,meridiem) {
            if (meridiem == 'am' && hours == 12) {
                return 0
            } else if (meridiem == 'pm' && hours < 12) {
                return hours + 12;
            }
            return hours;
        },
        wrapHoursTo12(hours) {
            if (hours < 12) {
                this.meridiemInput = 'am';
                if (hours == 0) {
                    hours = 12;
                }
            } else if (hours >= 12) {
                if (hours > 12) {
                    hours -= 12;
                }
                this.meridiemInput = 'pm';
            }
            return hours;
        },
        enforceDigitPadding(stringNumber, minimumDigitLength) {
            if (stringNumber.length < minimumDigitLength) {
                const diff = minimumDigitLength - stringNumber.length;
                return '0'.repeat(diff) + stringNumber;
            }
        },
        makeDateString() {
            let date = moment();
            date.year(Number(this.yearInput));
            date.month(Number(this.monthInput) - 1); //moment inputs for months range from 0-11
            date.date(Number(this.dayInput));
            date.hour(this.wrapHoursTo24(Number(this.hourInput),this.meridiemInput));
            date.minute(Number(this.minuteInput));
            date.second(Number(this.secondInput));
            return date.format(DATE_FORMAT);
        },
    },
    computed: {
        dayInput: {
            get() {
                if (this.dateTime?.day) {
                    return this.dateTime.day;
                } else {
                    return null;
                }
            },
            set(newValue) {
                if (newValue === null) {
                    this.dateTime.day = null;
                } else {
                    const validatedValue = this.validateDaysInputByMonth(newValue);
                    const paddedValue = validatedValue.toString().padStart(2, '0');
                    this.dateTime.day = paddedValue;
                    this.$refs.dayInput.value = paddedValue;
                }
            }
        },
        monthInput: {
            get() {
                if (this?.dateTime?.month) {
                    return this.dateTime.month;
                } else {
                    return null;
                }
            },
            set(newValue) {
                if (newValue === null) {
                    this.dateTime.day = null;
                } else {
                    const monthMaximum = Number(this.yearInput) < this.maxYear ? 12 : this.maxMonth;
                    const validatedValue = this.validateNumberInput(newValue,1,monthMaximum);
                    const paddedValue = validatedValue.toString().padStart(2, '0');
                    this.dateTime.month = paddedValue;
                    this.$refs.monthInput.value = paddedValue;
                }
            }
        },
        yearInput: {
            get() {
                if (this?.dateTime?.year) {
                    return this.dateTime.year;
                } else {
                    return null;
                }
            },
            set(newValue) {
                if (newValue === null) {
                    this.dateTime.day = null;
                } else {
                    const validatedValue = this.validateNumberInput(newValue,this.minYear,this.maxYear);
                    const paddedValue = validatedValue.toString().padStart(4, '0');
                    this.dateTime.year = paddedValue;
                    this.$refs.yearInput.value = paddedValue;
                }
            }
        },
        hourInput: {
            get() {
                if (this.dateTime?.hour) {
                    return this.dateTime.hour;
                } else {
                    return null;
                }
            },
            set(newValue) {
                if (newValue === null) {
                    this.dateTime.day = null;
                } else {
                    const validatedValue = this.validateNumberInput(newValue,1,12);
                    const paddedValue = validatedValue.toString().padStart(2, '0');
                    this.dateTime.hour = paddedValue;
                    this.$refs.hourInput.value = paddedValue;
                }
            }
        },
        minuteInput: {
            get() {
                if (this.dateTime?.minute) {
                    return this.dateTime.minute;
                } else {
                    return null;
                }
            },
            set(newValue) {
                if (newValue === null) {
                    this.dateTime.day = null;
                } else {
                    const validatedValue = this.validateNumberInput(newValue,0,59);
                    const paddedValue = validatedValue.toString().padStart(2, '0');
                    this.dateTime.minute = paddedValue;
                    this.$refs.minuteInput.value = paddedValue;
                }
            }
        },
        secondInput: {
            get() {
                if (this.dateTime?.second) {
                    return this.dateTime.second;
                } else {
                    return null;
                }
            },
            set(newValue) {
                if (newValue === null) {
                    this.dateTime.day = null;
                } else {
                    const validatedValue = this.validateNumberInput(newValue,0,59);
                    const paddedValue = validatedValue.toString().padStart(2, '0');
                    this.dateTime.second = paddedValue;
                    this.$refs.secondInput.value = paddedValue;
                }
            }
        },
        meridiemInput: {
            get() {
                return this.dateTime.meridiem;
            },
            set(newValue) {
                this.dateTime.meridiem = newValue;
            }
        },
        minYear() {
            if (this.min) {
                return new Date(this.min).getFullYear();
            }
            return 1;
        },
        maxYear() {
            if (this.max) {
                return new Date(this.max).getFullYear();
            }
            return 9999;
        },
        maxMonth() {
            if (this.max) {
                return new Date(this.max).getMonth() + 1;
            }
            return 12;
        },
        maxDay() {
            if (this.max) {
                return new Date(this.max).getDate();
            }
            return getMonthDays(Number(this.monthInput),Number(this.yearInput))
        }
    },
    watch: {
        dateTime: {
            deep:true,
            handler(newValue,oldValue) {
                const valid = this.checkEveryKeyHasData(newValue);
                this.isDateTimeSet = valid;
                if (valid && this.manualDateEntered) {
                    const newDate = this.makeDateString();
                    const dateObj = {
                        dateString: newDate,
                        dateFormat: DATE_FORMAT
                    }
                    this.$emit('manualDateEntered', dateObj);
                }
            }
        },
        monthInput(newValue, oldValue) {//if month changes, days may need to be re-evaluated due to different month lengths
            if (this.dayInput) {
                this.dayInput = this.validateDaysInputByMonth(this.dayInput);
            }
        },
        parentDateString(newValue, oldValue) {
            this.manualDateEntered = false;
            const newDate = moment.utc(newValue, DATE_FORMAT);
            if (newValue) {
                this.yearInput = newDate.year();
                this.monthInput = newDate.month() + 1;
                this.dayInput = newDate.date();
                this.hourInput = this.wrapHoursTo12(newDate.hour());
                this.minuteInput = newDate.minute();
                this.secondInput = newDate.second();
            }
        }
    }
};
</script>

<style scoped>
/*Browser Based CS Rules */

/* Hide scroll arrows on number inputs*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  text-align: center;
}
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
  text-align: center;
}
/*size attribute sets width of num input in chrome derivatives, but not firefox */
@-moz-document url-prefix() {
    .maxInputWidth4Digits {
        max-width: 2rem;
    }
    .maxInputWidth2Digits {
        max-width: 1rem;
    }
}

/*Change Placeholder text color */
::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #667085;
  opacity: 1; /* Firefox, set opacity to match other browsers*/
}
::-ms-input-placeholder { /* Microsoft Edge */
  color: #667085;
}

/*Center text and placeholder text */
::placeholder { /*Covers most browsers*/
   text-align: center; 
}
/* or, for legacy browsers */
::-webkit-input-placeholder {
   text-align: center;
}
:-moz-placeholder { /* Firefox 18- */
   text-align: center;  
}
::-moz-placeholder {  /* Firefox 19+ */
   text-align: center;  
}
:-ms-input-placeholder {  
   text-align: center; 
}

/*Other CSS Rules */
input[type=number] {
    background: transparent;
    border: none;
}
input {
    color:white;
    padding:0px;
    font-family:'Menlo','Cascadia Code','Courier New', Courier, monospace; /*Use monospace fonts so each input has a consistent size */
}
select {
    border: none;
    background-color: transparent;
    color:white;
    font-family:'Menlo','Cascadia Code','Courier New', Courier, monospace; /*Use monospace fonts so each input has a consistent size */
}
.monospace {
    font-family:'Menlo','Cascadia Code','Courier New', Courier, monospace; /*Use monospace fonts so each input has a consistent size */
}
.close-button {
    height:0.8rem;
    filter:brightness(0.75);
}
</style>