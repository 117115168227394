var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "table",
    {
      staticClass: "table table-striped mt-2 scroll-view",
      class: { "table-dark": _vm.darkMode },
    },
    _vm._l(_vm.logEntries, function (log, index) {
      return _c("tr", { key: index }, [
        _c("td", { staticClass: "col-6 p-1 m-1 text-left text-nowrap" }, [
          _vm._v(_vm._s(log.text)),
        ]),
        _vm._v(" "),
        _c("td", { staticClass: "col-6 p-1 m-1 text-right text-nowrap" }, [
          _vm._v(_vm._s(log.time)),
        ]),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }