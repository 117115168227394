<!--
    * Component Description
    A datepicker component that follows the design requirements

    * Dependencies
    - DropdownComponent.vue is used for the time value dropdowns
    - vue-clickaway package

    * Side Effects
    Emits a "change" event with the new datetime value

    * Example Usage
    (from SummaryBarComponent.vue)
    <calendar prompt="Select start date and time"
            :dateValue="tempFilterStartTime"
            @change="value => updateTempStartFilter(value)"/>
-->

<template>
<div>
    <div :class="`${borderStyle} rounded d-flex justify-content-center align-items-center grey-background`" >
        <div class="h-100 mr-1" :class="{'calendarBorderClicked': showingCalendar, 'rounded-left': showingCalendar, 'calendarBorderPassive': !showingCalendar}">
            <div @click="toggleCalendar" class="d-flex mx-1 py-2">
                <img src="/images/icons/calendar-clock.svg" :placeholder="'DD/MM/YYYY HH:MM:SS'" class="mr-1" :class="`${showingCalendar? 'light-blue-svg' : ''}`">
                <img src="/images/icons/chevron-down-small.svg" :class="`${showingCalendar? 'light-blue-svg upside-down' : ''}`"/>
            </div>
        </div>
        <div>
            <custom-date-time 
                :jobHourOffset="utcHourOffset"
                :parentDateString="selectedDateTimeString"
                @manualDateEntered="handleManualDateSelection"
                @clearInput="clearValue"
                :min="min"
                :max="max"
            ></custom-date-time>
        </div> 
    </div>
    <div v-show="showingCalendar" class="position-absolute calendar-modal" v-on-clickaway="hideCalendar">
        <div class="modal-container d-flex flex-column w-full grey-border grey-background pb-3"
            style="max-width: 700px; z-index: 2; position: relative">
            <div v-if="!basic" class="header p-3 d-none d-sm-block">
                <div class="d-flex justify-content-between">
                    <div>Select Date and Time</div>
                    <img src="/images/icons/close.svg" class="close-button clickable ml-2" @click="close"/>
                </div>
            </div>
            
            <div class="content d-flex responsive-direction">
                <div>
                    <v-date-picker 
                        style="background-color:transparent; border:none;"
                        is-dark
                        :attributes="calendarAttributes"
                        v-model="selectedDate"
                        mode="date"
                        @dayclick="day => dayClicked(day)"
                        timezone="UTC"
                        ref="calendar"
                        :max-date="parsedMaxDate"
                        :min-date="parsedMinDate"
                    />
                </div>
                <div v-if="!basic" class="d-flex flex-column flex-grow-1">
                    <div>
                        <div class="mb-1">Select time</div>
                        <div class="d-flex">
                            <dropdown :padAmount="2"
                                      :defaultValue="DEFAULT_HOURS"
                                      :value="hours"
                                      :values="Array.from({length: 12}, (_, i) => i + 1)"
                                      @valueSelected="value => hours = value"
                                      ref="hoursDropdown"
                                      class="mr-2"
                            />
                            <dropdown :padAmount="2"
                                      :defaultValue="DEFAULT_MINUTES"
                                      :value="minutes"
                                      :values="[...Array(60).keys()]"
                                      @valueSelected="value => minutes = value"
                                      ref="minutesDropdown"
                                      class="mr-2"
                            />
                            <dropdown :padAmount="2"
                                      :defaultValue="DEFAULT_SECONDS"
                                      :value="seconds"
                                      :values="[...Array(60).keys()]"
                                      @valueSelected="value => seconds = value"
                                      ref="secondsDropdown"
                                      class="mr-2"
                            />

                            <div class="d-flex rounded dropdown-box grey-border clickable">
                                <div :class="`d-flex align-items-center justify-content-center h-100 w-50 rounded-left ${!isPM? 'toggle-selected' : 'text-muted'}`" @click="isPM = false">
                                    <div>AM</div>
                                </div>
                                <div :class="`d-flex align-items-center justify-content-center h-100 w-50 rounded-right ${isPM? 'toggle-selected' : 'text-muted'}`" @click="isPM = true">
                                    <div>PM</div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div v-if="showDefaultTimeNotification" class="d-flex align-items-center mt-2 grey-text">
                        <img class="p-1" src="/images/icons/info-circle-faded.svg">Default Time is 12:00:00 AM
                    </div>
                    <div class="d-flex justify-content-end ml-auto mt-auto column-footer">
                        <div class="secondary-button mr-2 rounded py-2 user-select-none clickable grey-border"
                            @click="clearValue">
                            Clear
                        </div>
                        <div class="primary-button rounded py-2 user-select-none clickable"    
                            @click="updateValue" :disabled="this.selectedDate == null">
                            Apply
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    

</div>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(52, 64, 84, 0.8);
  transition: opacity 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
    width: 100%;
    border-radius: 12px;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    transition: all 0.3s ease;
}

 @media only screen and (max-width: 600px) {
   .modal-container {
    min-width: 100%;
    width: 100%;
    overflow: auto;
   }
 }
 @media only screen and (max-width:599px) {
    .responsive-direction {
        flex-direction: column;
    } 
 }
  @media only screen and (min-width:600px) {
    .responsive-direction {
        flex-direction: row;
    } 
 }
 @media only screen and (max-width:599px) {
    .column-footer {
        margin-top: 0.75rem !important;
        border-top: 1px solid #667085;
        width:100%;
        padding-top:0.75rem;
    }
 }

 .header {
    font-size: 1.2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid #7B8A98;
}

.content {
    padding: .5em 1em;
}

.primary-button{
    color:white;
    background-color: #004DBF;
    width: 4.8rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
}

.primary-button[disabled="disabled"] {
    color:#748CB0;
    background-color: #42546D33;
    cursor: default;
}

.secondary-button {
    color:white;
    width: 4.8rem;
    height: 2.4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
}

.close-button {
    height:0.9em;
}

:deep(.vc-day-content) {
    font-weight: 300;
}

:deep(.vc-highlight) {
    background-color: #004DBF!important;
}

:deep(.vc-title), :deep(.vc-nav-title) {
    background-color: #41464D;
    border-bottom: 1px solid #676E78;
}
:deep(.vc-title):focus, :deep(.vc-nav-title):focus {
    background-color: #41464D;
    border-bottom: 1px solid #676E78;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
}
:deep(.vc-title)
{
    padding: 0rem 0.7rem;
}

:deep(.vc-nav-title):hover {
    opacity: 0.75;
}

:deep(.vc-nav-popover-container) {
    background-color: #343A40;
    border: 1px solid #676E78;
}

:deep(.vc-nav-container), :deep(.vc-nav-title) {
    color: #F4F4F4;
}

:deep(.vc-nav-item.is-active)
{
    background-color: #004DBF;   
}

:deep(.vc-nav-item):focus {
    border-color: transparent;
}

:deep(.vc-nav-arrow):focus {
    border: transparent;
}

:deep(.vc-nav-item):hover:not(.is-active) , :deep(.vc-nav-arrow):hover, :deep(.vc-arrow):hover {
    color: #F4F4F4;
    background-color: #343A40;
    box-shadow: none;
    opacity:0.75;
}
:deep(.vc-nav-title):hover
{
    color: #F4F4F4;
    background-color: #41464D;
    box-shadow: none; 
}

:deep(.vc-weekday) {
    color: #F4F4F4!important;
}

:deep(.is-today) > span {
    font-weight: bold;
}
:deep(.is-today) > span:focus {
    background-color: inherit!important;
}

:deep(.vc-day-content):focus:not(.is-today > span) {
    background-color: inherit!important;
    font-weight: 300;
}

:deep(.vc-day-content.is-disabled):hover {
    background-color: transparent!important;
}

.toggle-selected {
    background-color: #004DBF;
    color: white;
}

.dropdown-box {
    width: 5rem; 
    height: 2.4rem;
}

.red-border{
    border: 1px solid #F04438;
}
.grey-border {
    border: 1px solid #676E78;
}
.light-blue-border {
    border: 1px solid #29A5FF;
}
.text-muted {
    color: #676E78;
}

:deep(.selected-day):not(.is-today > span) {
    font-weight: 300;
}

.grey-background {
    background-color: #343A40;
}

.light-blue-svg {
    filter: brightness(0) saturate(100%) invert(51%) sepia(46%) saturate(1735%) hue-rotate(182deg) brightness(100%) contrast(106%);
}

.upside-down {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}

.calendar-modal {
    margin-top:5px;
    padding-bottom: 10px;
}
.calendarBorderClicked {
    border: 1px solid #29A5FF;
}
.calendarBorderPassive {
    border: 1px solid transparent;
    border-right: 1px solid #676E78;
    
}
.grey-text {
    color:#667085;
}

</style>

<script>
import moment from 'moment';
import { directive as onClickaway } from 'vue-clickaway';
import CustomDateTime from './CustomDateTime.vue';

const MANUAL_INPUT_DATE_FORMAT = 'YYYY/MM/DD HH:mm:ss';

export default {
    directives: {
        onClickaway: onClickaway
    },
    props: {
        prompt: {
            type: String,
            default: "Select Date and Time"
        },
        dateValue: { //value from the parent
            type: String
        },
        showErrorState: {
            type: Boolean,
            default: false
        },
        min: {
            type: [String,Number]
        },
        max: {
            type: [String,Number]
        },
        utcHourOffset: {
            type: Number,
            default: 0
        },
        basic: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            showingCalendar: false,
            debouncedHide: false,
            selectedDate: null,
            hours: null,
            minutes: null,
            seconds: null,
            isPM: false,
            parsedMaxDate: null,
            parsedMinDate: null,
            datePickerString:null,
            inputDateString:null,
            maxISODate:null,
            minISODate:null,

            //constants
            DEFAULT_HOURS: 12,
            DEFAULT_MINUTES: 0,
            DEFAULT_SECONDS: 0
        };
    },
    mounted() {
        if (this.dateValue)
        {
            let date = moment.utc(this.dateValue);
            this.selectedDate = date.toDate();

            //calculate hours
            this.hours = date.hours();
            this.isPM = false;
            if (this.hours == 0) 
                this.hours = 12;
            else if (this.hours == 12)
                this.isPM = true;
            else if (this.hours > 12)
            {
                this.hours -= 12;
                this.isPM = true;
            }
            
            this.minutes = date.minutes();
            this.seconds = date.seconds();
            this.newValue = this.dateValue;
            this.datePickerString = date.format(MANUAL_INPUT_DATE_FORMAT);
        }  
    },  
    computed: {
        calendarAttributes: function() {
            return [
                {
                    dot: {
                        style: {
                            backgroundColor: '#ffffff',
                        } 
                    },
                    //extra styling done with is-today class
                    dates: moment.utc().toDate()
                },
                {
                    //highlight is hidden by styling
                    highlight: {
                        style: {
                            'color': '#004DBF'
                        }
                    },
                    dates: this.selectedDate
                },
            ];
        },
        borderStyle: function()
        {
            if (this.showErrorState)
                return 'red-border';
            else
                return 'grey-border';
        },
        showDefaultTimeNotification: function() {
            if ((this.hours == this.DEFAULT_HOURS && this.minutes == this.DEFAULT_MINUTES && this.seconds == this.DEFAULT_SECONDS) 
                || (this.hours == null && this.minutes == null && this.seconds == null)) {
                    return true;
            }
            return false;
        },
        dateDisplayText: function() {
            if (this.datePickerString)
                return this.datePickerString;
            return this.prompt;
        },
        selectedDateTimeString() {
            if (this.datePickerString) {
                return this.datePickerString;
            }
            return '';
        }
    },
    methods: {
        clearValue: function(){
            this.hours = null;
            this.minutes = null;
            this.seconds = null;
            this.isPM = false;
            this.selectedDate = null;
            this.$emit('change', null);
            this.close();
            this.datePickerString = null;
        },
        close: function(){
            this.showingCalendar = false;
        },
        pad2: function(number){
            return (number < 10 && number >= 0? '0' : '') + number;
        },
        dayClicked: function(day)
        {
            let dayIsSelected = day.el.classList.contains('selected-day');

            Array.from(document.querySelectorAll('.selected-day')).forEach(function(element) { 
                element.classList.remove('selected-day');
            });

            if (!dayIsSelected)
                day.el.classList.add('selected-day');
            
            if (this.basic) {
                this.updateValue();
            }
        },
        updateValue: function()
        {
            //shouldn't have been able to press the apply button
            if (!this.selectedDate)
                return;

            let newValue = moment.utc(this.selectedDate);

            //calculate hours
            let hours = this.hours? this.hours: this.DEFAULT_HOURS;
            if (hours == 12 && !this.isPM) 
                hours = 0;
            else if (hours != 12 && this.isPM)
                hours += 12;

            newValue.set('hours', hours);
            newValue.set('minutes', this.minutes != null? this.minutes : this.DEFAULT_MINUTES);
            newValue.set('seconds', this.seconds != null? this.seconds : this.DEFAULT_SECONDS);
            newValue.set('milliseconds', 0); //input doesn't support milliseconds
            this.datePickerString = newValue.format(MANUAL_INPUT_DATE_FORMAT);
            this.$emit('change', newValue.format('YYYY-MM-DDTHH:mm:ss'));
            this.close();
        },
        toggleCalendar: function()
        {
            if (!this.showingCalendar)
                this.debouncedHide = false; //reset debounce

            this.showingCalendar = !this.showingCalendar
        },  
        hideCalendar: function()
        {   
            //prevent calendar open button click from hiding calendar initially
            if (this.debouncedHide) 
                this.showingCalendar = false;
            else
                this.debouncedHide = true; //allow calendar to close on next click
        },
        handleManualDateSelection: function(newDateObj) {
            const dateString = newDateObj.dateString;
            const dateFormat = newDateObj.dateFormat;
            let newValue = moment.utc(dateString,dateFormat);
            let hours = newValue.hours()//this.hours? this.hours: this.DEFAULT_HOURS;
            this.hours = newValue.hours();
            this.minutes = newValue.minutes();
            this.seconds = newValue.seconds();
            if (this.hours >= 12) {
                this.isPM = true;
                if (hours > 12) {
                    this.hours -= 12;
                }
            } else  {
                this.isPM = false;
                if (this.hours == 0) {
                    this.hours = 12;
                }
            }

            this.datePickerString = newValue.format(MANUAL_INPUT_DATE_FORMAT);
            this.selectedDate = newValue.toDate();
            this.$emit('change', newValue.format('YYYY-MM-DDTHH:mm:ss'));
            let test = this.$refs['calendar'].focusDate(newValue.toDate(),{});
            this.close();
        }
    },
    watch: {
        max: function(newValue, oldValue) {
            this.parsedMaxDate = newValue? moment.utc(newValue).toDate() : null;
            this.maxISODate = newValue? moment.utc(newValue).toISOString() : null;
        },
        min: function(newValue, oldValue) {
            this.parsedMinDate = newValue? moment.utc(newValue).toDate() : null;
            this.minISODate = newValue? moment.utc(newValue).toISOString() : null;
        }
    }
};
</script>