<!--
    * Component Description
        A simple, re-usable colour picker wrapper that applies IWS styling and other small features

    * Side Effects
        Will emit 5 signals
            update:value    <- syncs with the 'value' prop to comunicate upwards what the last selection made
            input           <- triggers upon input within the colour picker field
            change          <- triggers upon change within the colour picker field
            focus           <- triggers upon focus within the colour picker field
            blur            <- triggers upon losing focus within the colour picker field

    * Example Usage
        <iws-colour-picker
            label="Axis Color"
            :value.sync="color"
        />
-->
<template>
    <div id="swatch-container" :class="{ 'form-input-spacing': formSpacing !== false }">
        <label v-if="!_isFalsy(label)" class="primary-text-color">
            {{ label }}
        </label>
        
        <div class="swatch" :class="{ 'is-invalid': inErrorState, 'disabled': disabled }" @click="setStatus(true)" ref="swatch">
            <div class="color" :style="{ 'background-color': value }" />
        </div>

        <template v-if="!!colorPickerShown">
            <div class="cover" @click="setStatus(false)" />

            <div ref="colourPicker"
                :style="{
                    'position': 'fixed',
                    'z-index': 999,
                    'top': `${dimensions.top}px`,
                    'left': `${dimensions.left}px`,
                    'width': `${dimensions.width}px`
                }"
            >
                <sketch-picker
                    :value="value || defaultValue"
                    :disableAlpha="true"
                    :preset-colors="presetColours"
                    @input="setColour"
                />
            </div>
        </template>

        <label v-if="inErrorState" class="danger-text-color">
            {{ errorMessage || 'Required' }}
        </label>
    </div>
</template>

<script>
import { Sketch } from 'vue-color';

import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy } = GlobalFunctions;

import eventBus from '../../eventBus';

export default {
    props: {
        value: {
			type: String | Number
		},

        defaultValue: {
            type: String,
            default: '#FFFFFF'
        },

        label: {
            type: String | Number
        },

        required: {
            type: Boolean | String,
            default: false
        },
        errorMessage: {
            type: String
        },

        // Provides a consistent margin between fields across forms
        formSpacing: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },

        presetColours: {
            type: Array,
            default: () => ['#D9251C', '#FFF500', '#281670', '#EA891B', '#01923F', '#7F2454', '#DE5F24', '#F5B900', '#69B92E', '#055D70', '#5A2064', '#A92740', '#000000', '#666666', '#AAAAAA', '#FFFFFF']
        }
    },

    components: {
        'sketch-picker': Sketch
    },

    data: () => ({
        colorPickerShown: false,
        dimensions: {
            top: 0,
            left: 0,
            width: 0
        },
        inErrorState: false
    }),

    methods: {
        _isFalsy(value) { return isFalsy(value); },

        setStatus(value) {
            // Don't allow colour picker to open when disabled
            if (this.disabled) {
                this.colorPickerShown = false;
                return;
            }

            this.colorPickerShown = value;

            if (value) {
                this.$emit('focus');

                // Apply position on next tick once it has rendered the bounding boxes
                this.$nextTick(() => {
                    this.setDimensions();
                });

                // We need to adjust positions when any scroll happens
                addEventListener("scroll", this.setDimensions);
                addEventListener("resize", this.setDimensions);
                eventBus.$on('modal-scroll', this.setDimensions);
            } else {
                this.$emit('blur');

                removeEventListener('scroll', this.setDimensions);
                removeEventListener('resize', this.setDimensions);
                eventBus.$off('modal-scroll', this.setDimensions);
            }

            if (this.inErrorState)
                this.validateInput();
        },
        setDimensions() {
            // Apply a fixed position to the options container so it can ignore the bounds of sub scroll containers or avoid overflowing the screen
            // Minor offsets have to do with the 1px border width and avoiding any overlap
            if (!isFalsy(this.$refs.swatch) && !isFalsy(this.$refs.colourPicker)) {
                const clientRect = this.$refs.swatch.getBoundingClientRect();
                const requiredHeight = this.$refs.colourPicker.getBoundingClientRect().height;
                const distanceToBottom = window.innerHeight-clientRect.bottom; 
                
                this.dimensions.left = clientRect.left;
                this.dimensions.width = clientRect.width;
                
                // If the expected height + some space exceeds the screen bounds, flip directions
                if (distanceToBottom < requiredHeight+10) {
                    this.dimensions.top = clientRect.top-requiredHeight-2;
                } else {
                    this.dimensions.top = clientRect.bottom+1;
                }
            }
        },

        setColour(colour) {
            this.$emit('update:value', colour.hex);
            this.$emit('input', colour.hex);
            this.$emit('change', colour.hex);
        },


        validateInput() {
            this.inErrorState = this.required !== false && isFalsy(this.value);
            return !this.inErrorState;
        },
    }
};
</script>

<style scoped>
    #swatch-container {
        position: relative;
    }

    #swatch-container label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        display: block;
    }

    .swatch {
        padding: 2px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 0 1px rgba(0,0,0,.1);
        display: inline-block;
    }
    .swatch.is-invalid {
        border: 2px solid var(--danger-color);
    }
    .color {
        width: 36px;
        height: 14px;
        border-radius: 2px;
    }

    .cover {
        position: fixed;
        top: 0px;
        right: 0px;
        bottom: 0px;
        left: 0px;
    }

    .swatch.disabled {
        background: #343A40;
    }
</style>