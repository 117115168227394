var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "position-relative",
      staticStyle: {
        height: "calc(100vh - 103px)",
        width: "100vw",
        padding: "10px",
      },
    },
    [
      _c("camera-navigation", {
        attrs: {
          user: _vm.user,
          device: _vm.device,
          cameras: _vm.device?.cameras,
          "work-order-id": _vm.workOrderId,
          "camera-index": _vm.index,
        },
      }),
      _vm._v(" "),
      _c("VideoPlayer", {
        attrs: {
          user: _vm.user,
          workOrderId: _vm.workOrderId,
          device: _vm.device,
          permissions: _vm.permissions,
          camera: _vm.device.cameras[_vm.index],
          index: _vm.index,
          control: _vm.control,
          "video-only": false,
          job: _vm.job,
          vods: _vm.vods,
          alerts: _vm.alerts,
          clips: _vm.clips,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }