var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "LibraryBase" } },
    [
      _c("base-table-view", {
        attrs: {
          title: _vm.title,
          items: _vm.items,
          columns: _vm.columns,
          filters: _vm.filters,
          filter: _vm.filterKey,
          breadCrumbPath: _vm.breadCrumbPath,
        },
        on: {
          "update:filter": function ($event) {
            _vm.filterKey = $event
          },
          filter: function ($event) {
            return _vm.$emit("openFilters")
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: "right-option",
              fn: function () {
                return [
                  _vm.enableCreate
                    ? [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-primary primary-color",
                            on: {
                              click: function ($event) {
                                return _vm.$emit("create")
                              },
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v(
                              " " +
                                _vm._s(_vm.createBtnTxt) +
                                "\n                "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._t("additional-actions", null, {
                          filteredItems: _vm.filteredItems,
                        }),
                      ]
                    : _vm.quickFilters
                    ? [
                        _c(
                          "div",
                          { staticClass: "quick-filters-container" },
                          [
                            _vm._v(
                              "\n                    Quick filters\n                    "
                            ),
                            _vm._l(_vm.quickFilters, function (quickFilter) {
                              return _c(
                                "button",
                                {
                                  key: quickFilter.name,
                                  staticClass: "quick-filter",
                                  on: {
                                    click: function ($event) {
                                      return _vm.setFilters(quickFilter.filters)
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                        " +
                                      _vm._s(quickFilter.name) +
                                      "\n                    "
                                  ),
                                ]
                              )
                            }),
                            _vm._v(" "),
                            _vm._t("additional-actions", null, {
                              filteredItems: _vm.filteredItems,
                            }),
                          ],
                          2
                        ),
                      ]
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "content",
              fn: function () {
                return [
                  _c("div", [_vm._t("tabs")], 2),
                  _vm._v(" "),
                  _vm._isNullOrEmpty(_vm.items)
                    ? _c(
                        "div",
                        { staticClass: "no-results-container" },
                        [
                          _vm.enableCreate
                            ? _c("i", { staticClass: "fas fa-plus" })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm._t("no-item-body"),
                        ],
                        2
                      )
                    : _vm._isNullOrEmpty(_vm.filteredItems)
                    ? _c("div", { staticClass: "no-results-container" }, [
                        _c("i", { staticClass: "fas fa-search" }),
                        _vm._v(" "),
                        _c("div", { staticClass: "title" }, [
                          _vm._v("No results found"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "subtitle" }, [
                          _vm._v(
                            "Your filters did not match any records. Please try again."
                          ),
                        ]),
                      ])
                    : _vm.isMobile && _vm.hasMobileView
                    ? [
                        _vm._t("card", null, {
                          filteredItems: _vm.filteredItems,
                        }),
                      ]
                    : [
                        _vm._t("table", null, {
                          filteredItems: _vm.filteredItems,
                        }),
                      ],
                ]
              },
              proxy: true,
            },
          ],
          null,
          true
        ),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }