<template>
    <b-modal 
        ref="my-modal" 
        size="lg"
        hide-header-close
        header-class="border-bottom-0 justify-content-center  pt-4"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg px-4"
    >
        <template #modal-header>
            <div class="font-large w-100 text-center">Spacing Alerts</div>
        </template>
        <template #default>
            <div class="row">
                <div class="col-3 d-flex justify-content-end align-items-center">
                    Template type
                </div>
                <div class="col-9"> 
                    <select class="form-control" v-model="templateType" @change="onDropdownChange">
                        <option
                            v-for="(option, index) in templateOptions"
                            :disabled="option.disabled"
                            :key="index"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row py-4" v-if="!isCompanyConfig">
                <div class="col-3 d-flex justify-content-end align-items-center">
                    Well
                </div>
                <div class="col-9"> 
                    <select class="form-control" v-model="selectedWell" @change="onDropdownChange">
                        <option
                            v-for="(option, index) in wellOptions"
                            :key="index"
                            :value="option.value"
                        >
                            {{ option.text }}
                        </option>
                    </select>
                </div>
            </div>
            <div>
                <table class="table table-borderless">
                    <thead>
                        <tr>
                            <th scope="col" class="col-3"></th>
                            <th scope="col" class="col-3 text-center text-white">-ve</th>
                            <th scope="col" class="col-3 text-center text-white">Spacing</th>
                            <th scope="col" class="col-3 text-center text-white">+ve</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th class="text-right text-white">Cluster</th>
                            <td>
                                <input
                                    class="form-control"
                                    min="-9999" max="0"
                                    onfocusout="validity.valid||(value='0')"
                                    type="number"
                                    v-model="clusterMin"
                                    :disabled="isViewOnly"
                                />
                            </td>
                            <td>
                                <input
                                    min="0" 
                                    onfocusout="validity.valid||(value='0')"
                                    class="form-control"
                                    type="number"
                                    v-model="clusterSpacing"
                                    :disabled="isViewOnly"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    min="0" 
                                    onfocusout="validity.valid||(value='0')"
                                    type="number"
                                    v-model="clusterMax"
                                    :disabled="isViewOnly"
                                />
                            </td>
                        </tr>
                        <tr> 
                            <td></td>
                            <td colspan="3" class="text-white range" align="center">
                                Range: {{+clusterSpacing + getNumber(clusterMin)}} to {{+clusterSpacing + getNumber(clusterMax)}}
                            </td>
                        </tr>
                        <tr>
                            <th class="text-right text-white">Stage</th>
                            <td>
                                <input
                                    class="form-control"
                                    min="-9999" max="0"
                                    onfocusout="validity.valid||(value='0')"
                                    type="number"
                                    v-model="stageMin"
                                    :disabled="isViewOnly"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    min="0" 
                                    onfocusout="validity.valid||(value='0')"
                                    type="number"
                                    v-model="stageSpacing"
                                    :disabled="isViewOnly"
                                />
                            </td>
                            <td>
                                <input
                                    class="form-control"
                                    min="0" 
                                    onfocusout="validity.valid||(value='0')"
                                    type="number"
                                    v-model="stageMax"
                                    :disabled="isViewOnly"
                                />
                            </td>
                        </tr>
                        <tr> 
                            <td></td>
                            <td colspan="3" class="text-white range" align="center">
                                Range: {{+stageSpacing + getNumber(stageMin)}} to {{+stageSpacing + getNumber(stageMax)}}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </template>
        <template #modal-footer>
            <div class="modal-footer d-flex flex-row" style="border-top-width:0px">
                <div class="d-flex flex-grow-1"></div>
                <button type="button" class="btn btn-secondary grey-button" data-dismiss="modal" @click="()=>changeModalVisibility(false)">Cancel</button>
                <div v-if="!isCompanyConfig">
                    <button type="button" class="btn btn-success green-button" :disabled="isViewOnly" @click.prevent="onSavePressed(true)">Save All Wells</button>
                    <button type="button" class="btn btn-success green-button" :disabled="isViewOnly" @click.prevent="onSavePressed()">Save This Well</button>
                </div>
                <button v-else type="button" class="btn btn-success green-button" :disabled="isViewOnly" @click.prevent="onSavePressed()">Save</button>
            </div>
        </template>
    </b-modal>
</template>
<style scoped>
    input:disabled {
        background-color: lightgray;
        cursor: default;
    }
    .select_form {
        display: inline;
        vertical-align: top;
        text-align:center;
        cursor: pointer;
        color: #5baaff;
        font-weight: bold;
    }
    .range {
        font-size: smaller;
    }
</style>
<script>

import _ from 'lodash';

const INIT_TEMPLATE_OPTIONS = [
    { value: 'personal-config', text: 'Personal' },
    { value: 'job-config', text: 'Job specific' },
    { value: 'company-config', text: 'Company specific'}
];

export default {
    data() {
        return {
            templateType: 'personal-config',
            selectedWell: null,
            templateOptions: [],
            wellOptions: [],
            clusterSpacing: null,
            stageSpacing: null,
            clusterMax: null,
            clusterMin: null,
            stageMax: null,
            stageMin: null
        };
    },
    props: [
        'wells',
        'currentWell',
        'clusterInfo',
        'isCompanyAdmin',
        'jobNumber',
        'isAdmin',
        'iwsUser',
        'currentWellData'
    ],
    mounted() {
        this.wellOptions = this.wells.map((item)=>{
            return {
                value: item.id,
                text: item.name
            };
        });

        this.templateOptions = INIT_TEMPLATE_OPTIONS;
        
        this.templateType = this.clusterInfo[0].type;
        this.selectedWell = this.currentWell;
        this.onDropdownChange();
    },
    computed: {
        isViewOnly() {
            return !this.isAdmin && !this.isCompanyAdmin && !this.iwsUser && this.templateType !== 'personal-config';
        },
        isCompanyConfig() {
            return this.templateType == INIT_TEMPLATE_OPTIONS[2].value;
        }
    },
    methods: {
        getNumber(number) {
            const parsedInt = parseInt(number);
            return Number.isNaN(parsedInt) ? 0 : parsedInt;
        },
        changeModalVisibility(status) {
            const self = this;
            self.isModalVisible = status;
            self.isModalVisible ? this.$refs['my-modal'].show(): this.$refs['my-modal'].hide();
        },
        onDropdownChange() {
            const clusterByType = _.groupBy(this.clusterInfo, 'type');
            for (const [key, value] of Object.entries(clusterByType)) {
                clusterByType[key] = _.groupBy(value, 'well_id');
            }
            let groupKey = 'null'; //company config, no well so the key is null
            if(this.templateType !== 'company-config') {
                groupKey = this.selectedWell;
            }

            if (this.clusterInfo && clusterByType.hasOwnProperty(this.templateType) && clusterByType[this.templateType]?.hasOwnProperty(groupKey)) {
                const { 
                    clusterPositiveVariance, 
                    clusterNegativeVariance, 
                    stagePositiveVariance, 
                    stageNegativeVariance, 
                    clusterSpacing, 
                    stageSpacing 
                } = JSON.parse(clusterByType[this.templateType][groupKey][0].cluster_info);
                
                this.clusterSpacing = clusterSpacing;
                this.stageSpacing = stageSpacing;
                this.clusterMax = clusterPositiveVariance;
                this.clusterMin = clusterNegativeVariance;
                this.stageMax = stagePositiveVariance;
                this.stageMin = stageNegativeVariance;
            } else {
                this.clusterSpacing = null;
                this.stageSpacing = null;
                this.clusterMax = null;
                this.clusterMin = null;
                this.stageMax = null;
                this.stageMin = null;
            }
        },
        onSavePressed(isSaveAll) {
            const valueClusterSpacing = parseInt(this.clusterSpacing);
            const valueClusterStageSpacing = parseInt(this.stageSpacing);
            const valueClusterPositiveVariance = parseInt(this.clusterMax);
            const valueClusterNegativeVariance = parseInt(this.clusterMin);
            const valueClusterStagePositiveVariance = parseInt(this.stageMax);
            const valueClusterStageNegativeVariance = parseInt(this.stageMin);
            const well = isSaveAll ? this.wells : this.wells.find(well => well.id === this.selectedWell);
            const data = {
                well: well,
                type: this.templateType,
                jobNumber: this.jobNumber,
                clusterInfo: {
                    'clusterSpacing': valueClusterSpacing >= 0? valueClusterSpacing : 0,
                    'stageSpacing': valueClusterStageSpacing >= 0? valueClusterStageSpacing : 0,
                    'clusterPositiveVariance': valueClusterPositiveVariance >= 0? valueClusterPositiveVariance : 0,
                    'clusterNegativeVariance': valueClusterNegativeVariance <= 0? valueClusterNegativeVariance : 0,
                    'stagePositiveVariance': valueClusterStagePositiveVariance >= 0? valueClusterStagePositiveVariance : 0,
                    'stageNegativeVariance': valueClusterStageNegativeVariance <= 0? valueClusterStageNegativeVariance : 0
                }
            };
            const url = isSaveAll ? '/cluster-config/all' : '/cluster-config';
            $.post(
                url,
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    ...data
                },
                function (result) {
                    location.reload();
                },
                'json'
            );
            this.changeModalVisibility(false);
        }
    }
};

</script>