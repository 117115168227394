<template>
  <!-- Allocate chart inside div container -->
  <div class="h-100 w-100">
    <div :id="chartId" :ref="chartId" :style="'height:' + height + 'px;'" ></div>
    <errors-modal :modalVisible="errorsModalVisible" :errors="fetchErrors" @onDismissErrorsModal="errorsModalVisible = false"  />
  </div>
</template>

<script>
const lcjs = require('@arction/lcjs');

// Extract required parts from LightningChartJS.
const {
    lightningChart,
    SolidLine,
    SolidFill,
    emptyLine,
    ColorRGBA,
    AxisTickStrategies,
    AxisScrollStrategies,
    UIOrigins,
    DataPatterns,
    VisibleTicks,
    Themes,
    ColorHEX
} = lcjs;

//Needed for production deployments to license the use of lightning charts on deployment
const lightningChartLicence = process.env.MIX_LC_LICENSE || null;

import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';

const CHART_RIGHT_SIDE_OFFSET = 50;
const ORIGIN_LINE_COLOR = ColorRGBA(0, 255, 0, 200);

export default {
    name: 'LightningChart',
    props: {
        lockToMinChannelsIds: {
            type: Array
        },
        showLegend: {
            type: Boolean,
            default: true
        },
        height: {
            type: Number
        },
        selectedIndex: {
            type: Object,
            required: true
        },
        chartOptions: {
            type: Object
        },
    },
    created() {
        this.legend = null;
        this.chart = null;
        this.depthSeries = null;
        this.tensionSeries = null;
        this.cclSeries = null;
        this.speedSeries = null;
        this.timeAxis = null;
        this.durationAxis = null;
        this.lineSeries2 = null;
        this.isFirstSeries = true;
        this.bottomOffset = 150;
        this.ticker = false;
    },
    data() {
    // Add the chart to the data in a way that Vue will not attach it's observers to it.
    // If the chart variable would be added in the return object, Vue would attach the observers and 
    // every time LightningChart JS made a change to any of it's internal variables, vue would try to observe the change and update.
    // Observing would slow down the chart a lot.
        return {
            chartId: null,
            strategyIncrement: 0,
            activeSeries: [],
            activeChannelAxis: {},
            activeIndexBy: null,
            errorsModalVisible: false,
            fetchErrors: null,
        };
    },
    computed: {
        channelOptions: function() {
            return this.chartOptions.channelOptions;
        },
        axisOptions: function() {
            return this.chartOptions.axisOptions;
        }
    },
    methods: {
        lockToZeroMethod(option,axis) {
            let yVal;
            if (this.lockToMinChannelsIds.includes(option.channelId)) {
                yVal = option.axisMin; //lock to min in case of certain tags like wireline speed
            } else {
                yVal = 0; //lock to zero default value
            }
            let previousScaleStart = 0;

            axis.onScaleChange((start, end) => {
                if (!axis.lockToZero) {//kill process early if not set
                    return; 
                }
                let newScaleStart = start;
                let newScaleEnd = end;
                let updateInterval = false;

                if(start !== yVal ) {
                    newScaleStart = yVal;
                    updateInterval = true;
                }

                // Only update the interval if there is a need
                if (updateInterval) {
                    axis.setInterval(newScaleStart, newScaleEnd, false, true);
                } else {
                    // update previous scale info
                    previousScaleStart = start;
                }
            });
        },
        applyOptionChanges() {
            const self = this;
            const usedAxesIds = new Set();
           
            //apply changes to chart axes
            Object.values(this.axisOptions).forEach(option => {
                const axis = self.activeChannelAxis[option?.tagName];
                if (axis) {
                    if (option.returnToDefaults) {
                        axis.setMouseInteractions(true);
                        axis.setInterval(option.axisMin, option.axisMax, true, false);
                    }
                    if (option.autoFit && !option.lockToZero) {
                        axis.setScrollStrategy(AxisScrollStrategies.fitting);
                        axis.fit();
                    } 
                    if (option.lockToZero) {
                        axis.setScrollStrategy(AxisScrollStrategies.expansion);
                        //applies lock to zero/lock to min functionality to axis
                        this.lockToZeroMethod(option, axis);
                        axis.setInterval(0, axis.getInterval().end);
                    }
                    if (!option.autoFit && !option.lockToZero) {
                        if (axis.getScrollStrategy() != undefined) { //chart view interval must be manually set to keep up with live data
                            axis.setScrollStrategy(undefined);
                        }
                        axis.setInterval(option.axisMin, option.axisMax, true, false);
                    }
                    axis.lockToZero = option.lockToZero;

                    let title = option.label;
                    if (option?.unit != '' && option?.unit != null) {
                        title += ` (${option.unit})`;
                    }
                    axis.setTitle(title);
                    // this.lockToZeroMethod(option, axis);
                }
            });
        },
        createSeries(seriesName, tagName) {
            const randomColor = GlobalFunctions.getRandomColor("dark");
            const depthStrokeStyle = new SolidLine({ fillStyle: new SolidFill({ color: ColorHEX(randomColor) }), thickness: 2 });

            const depthSeries = this.chart.addLineSeries({ 
                dataPattern: DataPatterns.horizontalProgressive,
                yAxis: this.activeChannelAxis[tagName]
            }).setName(seriesName)
                .setStrokeStyle(depthStrokeStyle);
            
            depthSeries.tagName = tagName;
            this.activeSeries.push(depthSeries);

            return this.activeSeries[this.activeSeries.length -1];
        },
        createChannelAxis(tagName, friendlyName, axisId=null) {
            //Should check if the axis already exists here and bail out early if it does
            if(this.activeChannelAxis[tagName] != null){ return; }
            const axisOptions = this.chartOptions.axisOptions[axisId] || null;
            let newAxis = null;
            if(this.isFirstSeries) {
                this.chart.getDefaultAxisY().dispose();
                this.isFirstSeries = false;
            }
            newAxis = this.chart.addAxisY({
                type: 'linear',
                opposite: axisOptions?.location == 'opposite'
            });
            newAxis.setTickStrategy(AxisTickStrategies.Numeric, (styler) =>
                styler
                    .setMajorTickStyle(tickStyle => tickStyle
                        .setGridStrokeStyle(emptyLine))
                    .setMinorTickStyle(tickStyle => tickStyle
                        .setGridStrokeStyle(emptyLine))
            );
            newAxis.setTitle(friendlyName);
            if (axisId) {
                const axisOptions = this.chartOptions.axisOptions[axisId];
                if (axisOptions.lockToZero) {
                    //applies lock to zero/lock to min functionality to axis
                    newAxis.setScrollStrategy(AxisScrollStrategies.expansion);
                    this.lockToZeroMethod(axisOptions, newAxis);                
                }
                newAxis.lockToZero = axisOptions.lockToZero;
            }

            this.activeChannelAxis[tagName] = newAxis;
        },
        createOriginLine(targetIndexBy) {
            //Add vertical line @ origin
            const xAxis = this.chart.getDefaultAxisX();
            this.activeIndexBy = xAxis.addConstantLine()
                .setName(targetIndexBy?.name)
                .setValue(0)
                .setStrokeStyle(new SolidLine({
                    thickness: 2,
                    fillStyle: new SolidFill({ color: ORIGIN_LINE_COLOR })
                }))
                .setMouseInteractions(false);
        },
        createChart() {
            // Create chartXY
            if(lightningChartLicence){
                this.chart = lightningChart(
                    lightningChartLicence
                ).ChartXY({
                    container: `${this.chartId}`,
                    theme: Themes.light
                });
            }else{
                this.chart = lightningChart().ChartXY({
                    container: `${this.chartId}`,
                    theme: Themes.light
                });
            }
            

            this.chart.setPadding({
                right: CHART_RIGHT_SIDE_OFFSET
            });
            
            this.chart.setTitle('Stage Comparison');

            // Enable AutoCursor auto-fill.
            this.chart.setAutoCursor(cursor => cursor
                .setResultTableAutoTextStyle(true)
                .disposeTickMarkerX()
                .setTickMarkerYAutoTextStyle(true)
            );

            //Add time label to x-axis
            const xAxis = this.chart.getDefaultAxisX();
            xAxis.setTitle('Seconds (since index point)');
            this.chart.engine.layout();

            this.legend = this.chart.addLegendBox();          
            this.legend.add(this.chart);
        },
        setDataForSeries(data, series) {
            const chartSeries = series;

            if(chartSeries) {
                chartSeries.clear();
                chartSeries.add(data);
            }
        },
        clearChartData() {
            this.activeSeries.forEach(series => {
                series.dispose();
            });
            this.activeSeries = [];

            if (this.activeChannelAxis && Object.values(this.activeChannelAxis).length > 0) {
                this.chart.forEachAxisY((axis, i, arr) => {
                    axis.dispose();
                });
                this.activeChannelAxis = {};
            }
        },
        addDataToChart(jobNumber,wellName, stageNumber, targetChannel) {
            //If legened current exists destroy it
            if(this.legend) {
                this.legend.dispose();
            }

            //Destroy the current origin line
            if(this.activeIndexBy != null) {
                this.activeIndexBy.dispose();
            }
            const friendlyName = targetChannel['friendlyName'];
            
            let errors = [];
            if(targetChannel["errors"]) {
                let errorTitle = jobNumber + " - " + wellName + " - " + friendlyName + " ( Stage :" + stageNumber + ")";
                const errorObject = {
                    "error": errorTitle,
                    "details": targetChannel["errors"]
                };
                errors.push(errorObject);
            }else{
                const tagName = targetChannel["tagName"];
                const seriesName = jobNumber + " - " + wellName + " - " + friendlyName + " ( Stage :" + stageNumber + ")";

                let axisId = Object.values(this.channelOptions).find(channel => channel.tagName === tagName)?.axisId
                //Create the channel axis for this if needed
                this.createChannelAxis(tagName, friendlyName, axisId);
                const targetSeries = this.createSeries(seriesName, tagName);
                //Display on chart
                this.setDataForSeries(targetChannel["dataSet"], targetSeries);
            }
            if(errors.length > 0) {
                this.fetchErrors = errors;
                this.errorsModalVisible = true;
            }

            //Recreate the origin line
            this.createOriginLine(this.selectedIndex);

            this.addLegend();
        },
        addLegend() {
            if(this.showLegend) {
                if(this.legend) {
                    this.legend.dispose();
                }
                this.legend = this.chart.addLegendBox();
                this.legend.add(this.chart);
            }
        }
    },
    watch: {
        height: {
            handler(newVal, oldVal) {
                this.chart.engine.layout();
            }
        },
        showLegend: function(newVal, oldVal){
            if(newVal){
                this.addLegend();
            }else{
                this.legend.dispose();
                this.legend = null;
            }
        }
    },
    beforeMount() {
        // Generate random ID to us as the containerId for the chart and the target div id
        this.chartId = Math.trunc(Math.random() * 1000000);
    },
    mounted() {
        // Chart can only be created when the component has mounted the DOM because 
        // the chart needs the element with specified containerId to exist in the DOM
        this.createChart();

        setTimeout(()=>{ // used to fix height issues, giving the process to a different thread. ( need to run as the last process of the chart )
            this.chart.engine.layout();
        }, 0);

        //Hacks for getting the chart to load at the proper size. No idea whats going wrong here.
        window.resizeTo(window.screenX - 50, window.screenY);
        this.ticker = !this.ticker;
    },
    beforeDestroy() {
        // "dispose" should be called when the component is unmounted to free all the resources used by the chart
        this.chart.dispose();
    }
};
</script>

<style scoped>
  .fill {
    height: 100%;
  }
</style>
