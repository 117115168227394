<template>
<div class="position-relative" style="height: calc(100vh - 103px); width: 100vw; padding: 10px">
    <camera-navigation :user="user" :device="device" :cameras="device?.cameras" :work-order-id="workOrderId" :camera-index="index" />

    <VideoPlayer
        :user="user"
        :workOrderId="workOrderId"
        :device="device"
        :permissions="permissions"
        :camera="device.cameras[index]"
        :index="index"
        :control="control"
        :video-only="false"
        :job="job"
        :vods="vods"
        :alerts="alerts"
        :clips="clips"
    />
</div>
</template>

<script>
import VideoPlayer from './VideoPlayer.vue';
import VideoScrubberV2 from './VideoScrubberV2.vue';

export default {
    props: [ 'user', 'workOrderId', 'device', 'permissions', 'index', 'control', 'job', 'vods', 'alerts', 'clips'],

    components: { VideoPlayer, VideoScrubberV2 },
    
    created() {
        this.device?.cameras?.forEach(camera => {
            camera.llhls = camera.url;
        })
    }
}
</script>