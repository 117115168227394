var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container col-9" }, [
    _c("div", { staticClass: "h5" }, [_vm._v("Edit Profile")]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
      _vm._v(" "),
      _c("input", {
        staticClass: "form-control",
        staticStyle: { "font-weight": "bold" },
        attrs: { id: "email", name: "email", type: "email", readonly: "" },
        domProps: { value: _vm.loggedInUser.email },
      }),
    ]),
    _vm._v(" "),
    !(_vm.loggedInUser.email === _vm.loggedInUser.username)
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "username" } }, [_vm._v("Username")]),
          _vm._v(" "),
          _c("input", {
            staticClass: "form-control",
            staticStyle: { "font-weight": "bold" },
            attrs: {
              id: "username",
              name: "username",
              type: "username",
              readonly: "",
            },
            domProps: { value: _vm.loggedInUser.username },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.loggedInUser.email === ""
      ? _c("div", { staticClass: "form-group" }, [
          _c("label", { attrs: { for: "email" } }, [_vm._v("Email")]),
          _vm._v(" "),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.loggedInUser.email,
                expression: "loggedInUser.email",
              },
            ],
            staticClass: "form-control",
            attrs: { id: "email", name: "email", type: "email", required: "" },
            domProps: { value: _vm.loggedInUser.email },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.loggedInUser, "email", $event.target.value)
              },
            },
          }),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "text" } }, [_vm._v("First Name")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loggedInUser.first_name,
            expression: "loggedInUser.first_name",
          },
        ],
        staticClass: "form-control",
        attrs: { id: "first_name", name: "first_name", type: "text" },
        domProps: { value: _vm.loggedInUser.first_name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.loggedInUser, "first_name", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "text" } }, [_vm._v("Last Name")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loggedInUser.last_name,
            expression: "loggedInUser.last_name",
          },
        ],
        staticClass: "form-control",
        attrs: { id: "last_name", name: "last_name", type: "text" },
        domProps: { value: _vm.loggedInUser.last_name },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.loggedInUser, "last_name", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "phone_number" } }, [_vm._v("Phone")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.loggedInUser.phone_number,
            expression: "loggedInUser.phone_number",
          },
        ],
        staticClass: "form-control",
        attrs: { id: "phone_number", name: "phone_number", type: "tel" },
        domProps: { value: _vm.loggedInUser.phone_number },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.loggedInUser, "phone_number", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("hr"),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newPassword,
            expression: "newPassword",
          },
        ],
        staticClass: "form-control",
        attrs: { id: "password", name: "password", type: "password" },
        domProps: { value: _vm.newPassword },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.newPassword = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "password_confirmation" } }, [
        _vm._v("Confirm Password"),
      ]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.confirmPassword,
            expression: "confirmPassword",
          },
        ],
        staticClass: "form-control",
        attrs: {
          id: "password_confirmation",
          name: "password_confirmation",
          type: "password",
        },
        domProps: { value: _vm.confirmPassword },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.confirmPassword = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", { attrs: { for: "dashboard_permissions" } }, [
        _vm._v("Tooltip Font Size"),
      ]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.tooltipFontSize,
              expression: "tooltipFontSize",
            },
          ],
          staticClass: "form-control",
          attrs: { id: "toolTipFontSize", name: "toolTipFontSize" },
          on: {
            change: [
              function ($event) {
                var $$selectedVal = Array.prototype.filter
                  .call($event.target.options, function (o) {
                    return o.selected
                  })
                  .map(function (o) {
                    var val = "_value" in o ? o._value : o.value
                    return val
                  })
                _vm.tooltipFontSize = $event.target.multiple
                  ? $$selectedVal
                  : $$selectedVal[0]
              },
              _vm.tooltipChanged,
            ],
          },
        },
        [
          _c("option", { attrs: { value: "medium" } }, [_vm._v("Medium ")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "large" } }, [_vm._v("Large  ")]),
          _vm._v(" "),
          _c("option", { attrs: { value: "x-large" } }, [
            _vm._v("Extra Large "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("br"),
      _vm._v(" "),
      _vm._m(1),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "form-group" }, [
      _c(
        "a",
        {
          staticClass: "btn btn-secondary",
          attrs: { href: "/account-settings" },
        },
        [_vm._v("Back")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "btn btn-primary",
          attrs: { name: "submit", type: "button" },
          on: { click: _vm.submitUserProfile },
        },
        [_vm._v("Submit")]
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "password" } }, [
      _vm._v("Password "),
      _c("i", [_vm._v("*Leave blank if you do not wish to update*")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("ul", [
      _c("li", [
        _vm._v("Medium:"),
        _c("span", { staticStyle: { "font-size": "12px" } }, [
          _vm._v(" Sample Text"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Large:"),
        _c("span", { staticStyle: { "font-size": "16px" } }, [
          _vm._v(" Sample Text"),
        ]),
      ]),
      _vm._v(" "),
      _c("li", [
        _vm._v("Extra Large:"),
        _c("span", { staticStyle: { "font-size": "20px" } }, [
          _vm._v(" Sample Text"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }