var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("iws-breadcrumbs", {
        staticClass: "mt-4 mb-1",
        attrs: {
          path: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "User Management",
              link: "/userManagement",
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-content-between mb-4" }, [
        _c("div", { staticClass: "page-title" }, [
          _vm._v("\n            User Management\n        "),
        ]),
        _vm._v(" "),
        _vm.role === "admin"
          ? _c("div", { staticStyle: { position: "relative", top: "-20px" } }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  on: {
                    click: function ($event) {
                      return _vm.create()
                    },
                  },
                },
                [_vm._v("New User")]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-12 form-inline" }, [
          _c("div", { staticClass: "form-group mr-3 ml-3" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.search,
                  expression: "search",
                },
              ],
              staticClass: "form-control search-bar",
              attrs: { type: "text", placeholder: "Filter by Name or Email" },
              domProps: { value: _vm.search },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.search = $event.target.value
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mr-3" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedRole,
                    expression: "selectedRole",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "role", name: "role" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.selectedRole = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c(
                  "option",
                  { attrs: { selected: "" }, domProps: { value: null } },
                  [_vm._v("Filter by Role")]
                ),
                _vm._v(" "),
                _vm._l(_vm.sortedRoles, function (role) {
                  return _c("option", { key: role.id }, [
                    _vm._v(_vm._s(role.name)),
                  ])
                }),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _vm.role == "admin"
            ? _c("div", { staticClass: "form-group mr-3" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCustomer,
                        expression: "selectedCustomer",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: { id: "customer", name: "customer" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedCustomer = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c(
                      "option",
                      { attrs: { selected: "" }, domProps: { value: null } },
                      [_vm._v("Filter by Company")]
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.sortedCustomers, function (customer) {
                      return _c("option", { key: customer.id }, [
                        _vm._v(_vm._s(customer.name)),
                      ])
                    }),
                  ],
                  2
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mr-3" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.deleted,
                    expression: "deleted",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "deleted", name: "deleted" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.deleted = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "0" } }, [_vm._v("Current")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "1" } }, [_vm._v("Deleted")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "form-group mr-3" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.order,
                    expression: "order",
                  },
                ],
                staticClass: "form-control",
                attrs: { id: "order", name: "order" },
                on: {
                  change: function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.order = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                },
              },
              [
                _c("option", { attrs: { value: "asc" } }, [_vm._v("Asc")]),
                _vm._v(" "),
                _c("option", { attrs: { value: "desc" } }, [_vm._v("Desc")]),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mr-3 d-flex align-items-center" }, [
            _c(
              "span",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Clear All Filters",
                    expression: "'Clear All Filters'",
                    arg: "top",
                  },
                ],
              },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-light",
                    attrs: {
                      disabled:
                        _vm.column == "" &&
                        _vm.order == "asc" &&
                        _vm.deleted == "" &&
                        _vm.search == "" &&
                        _vm.selectedRole == null &&
                        _vm.selectedCustomer == null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.clearFilters()
                      },
                    },
                  },
                  [
                    _vm._v(
                      "\n                        Clear\n                    "
                    ),
                  ]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "mr-3" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { id: "filter_button" },
                on: {
                  click: function ($event) {
                    return _vm.filter()
                  },
                },
              },
              [_c("b-icon", { attrs: { icon: "filter" } }), _vm._v("Filter")],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-end" }, [
            _c("div", { staticClass: "mr-3 d-flex align-items-center" }, [
              _c(
                "span",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip:top",
                      value: "Export Filtered Table",
                      expression: "'Export Filtered Table'",
                      arg: "top",
                    },
                  ],
                },
                [
                  _c("i", {
                    staticClass: "fas fa-download fa-lg clickable",
                    on: { click: _vm.exportTable },
                  }),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col" }, [
          _vm.usersList.length > 0
            ? _c(
                "table",
                {
                  staticClass: "table table-striped table-hover mt-2",
                  class: { "table-dark": _vm.darkMode },
                },
                [
                  _c("thead", [
                    _c(
                      "th",
                      {
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.sort("first_name")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fas fa-sort" }),
                        _vm._v("Name"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.sort("username")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fas fa-sort" }),
                        _vm._v("Username"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.sort("email")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fas fa-sort" }),
                        _vm._v("Email"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.sort("phone_number")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fas fa-sort" }),
                        _vm._v("Phone"),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.sort("customer_id")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fas fa-sort" }),
                        _vm._v("Company"),
                      ]
                    ),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v("Role")]),
                    _vm._v(" "),
                    _vm.isIws
                      ? _c("th", { attrs: { scope: "col" } }, [
                          _vm._v("Permission"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(_vm.usersList, function (user) {
                      return _c("tr", { key: user.id }, [
                        _c("td", [
                          _vm._v(
                            _vm._s(user.first_name) +
                              " " +
                              _vm._s(user.last_name)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(user.username))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(user.email))]),
                        _vm._v(" "),
                        _c("td", [_vm._v(_vm._s(user.phone_number))]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(user.customer ? user.customer.name : "None")
                          ),
                        ]),
                        _vm._v(" "),
                        user.roles.length != 0
                          ? _c(
                              "td",
                              _vm._l(user.roles, function (userRole) {
                                return _c("div", { key: userRole.id }, [
                                  _vm._v(
                                    "\n                            " +
                                      _vm._s(userRole.name) +
                                      "\n                        "
                                  ),
                                ])
                              }),
                              0
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        user.roles.length == 0 ? _c("td") : _vm._e(),
                        _vm._v(" "),
                        _vm.isIws
                          ? _c(
                              "td",
                              {
                                staticStyle: { "text-transform": "capitalize" },
                              },
                              [
                                _vm.showLegacyPermissionText(user)
                                  ? _c(
                                      "span",
                                      { staticStyle: { color: "red" } },
                                      [_vm._v("Legacy - ")]
                                    )
                                  : _vm._e(),
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(
                                      _vm.permissionList(
                                        user.dashboard_permissions
                                      )
                                    ) +
                                    "\n                    "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("td", [
                          _vm.isIws
                            ? _c(
                                "span",
                                {
                                  staticClass: "badge badge-primary",
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(user.id)
                                    },
                                  },
                                },
                                [_vm._v("Edit")]
                              )
                            : _c("span", [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge badge-primary",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.view(user.id)
                                      },
                                    },
                                  },
                                  [_vm._v("View")]
                                ),
                              ]),
                        ]),
                        _vm._v(" "),
                        user.deleted_at === null
                          ? _c("td", [
                              _vm.isIws && user.id != _vm.authUser.id
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-danger",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.remove(user.id)
                                        },
                                      },
                                    },
                                    [_vm._v("Delete")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        user.deleted_at !== null
                          ? _c("td", [
                              _vm.isIws
                                ? _c(
                                    "span",
                                    {
                                      staticClass: "badge badge-danger",
                                      staticStyle: { cursor: "pointer" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.restore(user.id)
                                        },
                                      },
                                    },
                                    [_vm._v("Restore")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.isIws
                          ? _c(
                              "td",
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge badge-info",
                                    staticStyle: { cursor: "pointer" },
                                    attrs: {
                                      id:
                                        "user-session-popover-target-" +
                                        user.id,
                                    },
                                  },
                                  [_vm._v("Sessions")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-popover",
                                  {
                                    attrs: {
                                      "custom-class": "config-history-popover",
                                      target:
                                        "user-session-popover-target-" +
                                        user.id,
                                      triggers: "hover focus",
                                      placement: "bottom",
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "title",
                                          fn: function () {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "history-popover-title",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                    User Sessions for " +
                                                      _vm._s(user.username) +
                                                      "\n                                "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                  },
                                  [
                                    _vm._v(" "),
                                    _c(
                                      "b-overlay",
                                      {
                                        attrs: {
                                          rounded: "sm",
                                          "bg-color": "#f9f9fa",
                                        },
                                      },
                                      [
                                        _c("div", { staticClass: "p-3" }, [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "max-height": "600px",
                                                "max-width": "800px",
                                                "overflow-y": "auto",
                                              },
                                            },
                                            [
                                              _c("iws-table", {
                                                attrs: {
                                                  columns: [
                                                    "last_activity",
                                                    "ip_address",
                                                    "user_agent",
                                                  ],
                                                  items: user.sessions,
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "header_last_activity",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            "\n                                                Last Activity\n                                            "
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: "header_ip_address",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            "\n                                                IP Address\n                                            "
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: "header_user_agent",
                                                      fn: function () {
                                                        return [
                                                          _vm._v(
                                                            "\n                                                User Agent\n                                            "
                                                          ),
                                                        ]
                                                      },
                                                      proxy: true,
                                                    },
                                                    {
                                                      key: "cell_last_activity",
                                                      fn: function ({ data }) {
                                                        return [
                                                          _vm._v(
                                                            "\n                                                " +
                                                              _vm._s(
                                                                new Date(
                                                                  data.value *
                                                                    1000
                                                                )
                                                              ) +
                                                              "\n                                            "
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  true
                                                ),
                                              }),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          user.sessions.length > 0
                                            ? _c("hr")
                                            : _vm._e(),
                                          _vm._v(" "),
                                          user.sessions.length > 0
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "d-flex justify-content-end",
                                                },
                                                [
                                                  _c(
                                                    "button",
                                                    {
                                                      staticClass:
                                                        "btn btn-outline-light",
                                                      attrs: {
                                                        id: "clear-all-button",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.clearAllUserSessions(
                                                            user
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-undo",
                                                      }),
                                                      _vm._v(
                                                        " Clear All\n                                        "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    }),
                    0
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.usersList.length <= 0
            ? _c("div", { staticClass: "title m-b-md" }, [
                _vm._v("No users match these filters."),
              ])
            : _vm._e(),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "form",
        {
          staticStyle: { display: "none" },
          attrs: { id: "logout-form", action: "logout", method: "POST" },
        },
        [_vm._v("\n        @csrf\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }