var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "UserPermissionsCompany" } },
    [
      _c("div", { staticClass: "h4" }, [
        _vm._v("\n        " + _vm._s(_vm.customer.name) + "\n    "),
      ]),
      _vm._v(" "),
      !_vm.isIwsAdmin
        ? _c("div", { staticClass: "h6" }, [
            _vm._v(
              "\n        Please contact your admin if you think this information is incorrect\n    "
            ),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("hr"),
      _vm._v(" "),
      _vm.jobs === null
        ? [_vm._v("\n        Loading...\n    ")]
        : _vm.jobs.length === 0
        ? _c("div", { staticClass: "h6" }, [_vm._v("\n        No Jobs\n    ")])
        : [
            _vm.records === null
              ? [_vm._v("\n            Loading...\n        ")]
              : _vm.records.length === 0
              ? _c("div", { staticClass: "h6" }, [
                  _vm._v(
                    "\n            No Restrictions. Click below to limit this users api access.\n        "
                  ),
                ])
              : _vm._l(_vm.records, function (record, index) {
                  return _c(
                    "div",
                    { key: record.id, class: { "add-space": !!index } },
                    [
                      _c(
                        "div",
                        {
                          key: _vm.customer.id + record.id,
                          staticClass: "row",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "col-4" },
                            [
                              _c("iws-select", {
                                key: _vm.customer.id + record.id + "jobNumber",
                                staticClass: "mb-3",
                                attrs: {
                                  label: "Work Order",
                                  value: record.workOrders
                                    ? record.workOrders
                                    : "All Jobs",
                                  options: _vm.jobs,
                                  "display-name": "jobNumber",
                                  "value-name": "jobNumber",
                                  multiselect: false,
                                  "maintain-on-select": false,
                                  disabled: !_vm.isIwsAdmin,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.handleEvent(record, $event)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v(
                                  "\n                            Select All Jobs to grant access to all jobs within " +
                                    _vm._s(_vm.customer.name) +
                                    "\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-2 text-center" }, [
                            _c("label", [_vm._v("API Only Access")]),
                            _vm._v(" "),
                            !JSON.parse(
                              _vm.user.dashboard_permissions
                            ).includes("API")
                              ? _c("span", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: record.api_access,
                                        expression: "record.api_access",
                                      },
                                      {
                                        name: "tooltip",
                                        rawName: "v-tooltip:top",
                                        value:
                                          "API access is disabled for user",
                                        expression:
                                          "'API access is disabled for user'",
                                        arg: "top",
                                      },
                                    ],
                                    staticClass: "checkbox style-2 pull-right",
                                    attrs: { type: "checkbox", disabled: true },
                                    domProps: {
                                      checked: Array.isArray(record.api_access)
                                        ? _vm._i(record.api_access, null) > -1
                                        : record.api_access,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = record.api_access,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                record,
                                                "api_access",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                record,
                                                "api_access",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(record, "api_access", $$c)
                                        }
                                      },
                                    },
                                  }),
                                ])
                              : _c("span", [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: record.api_access,
                                        expression: "record.api_access",
                                      },
                                    ],
                                    staticClass: "checkbox style-2 pull-right",
                                    attrs: { type: "checkbox" },
                                    domProps: {
                                      checked: Array.isArray(record.api_access)
                                        ? _vm._i(record.api_access, null) > -1
                                        : record.api_access,
                                    },
                                    on: {
                                      change: function ($event) {
                                        var $$a = record.api_access,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                record,
                                                "api_access",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                record,
                                                "api_access",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(record, "api_access", $$c)
                                        }
                                      },
                                    },
                                  }),
                                ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("label", [_vm._v("Start")]),
                              _vm._v(" "),
                              _c("b-form-datepicker", {
                                key: _vm.customer.id + record.id + "start_date",
                                staticClass: "mb-3",
                                attrs: {
                                  "reset-button": true,
                                  disabled: !_vm.isIwsAdmin,
                                  max: !!record.end_date
                                    ? new Date(record.end_date)
                                    : null,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.handleDateChange(
                                      $event,
                                      record,
                                      "start"
                                    )
                                  },
                                },
                                model: {
                                  value: record.start_date,
                                  callback: function ($$v) {
                                    _vm.$set(record, "start_date", $$v)
                                  },
                                  expression: "record.start_date",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v(
                                  "\n                            Access to Work Order from this day\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "col-3" },
                            [
                              _c("label", [_vm._v("End")]),
                              _vm._v(" "),
                              _c("b-form-datepicker", {
                                key: _vm.customer.id + record.id + "end_date",
                                staticClass: "mb-3",
                                attrs: {
                                  "reset-button": true,
                                  disabled: !_vm.isIwsAdmin,
                                  min: !!record.start_date
                                    ? new Date(record.start_date)
                                    : null,
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.handleDateChange(
                                      $event,
                                      record,
                                      "end"
                                    )
                                  },
                                },
                                model: {
                                  value: record.end_date,
                                  callback: function ($$v) {
                                    _vm.$set(record, "end_date", $$v)
                                  },
                                  expression: "record.end_date",
                                },
                              }),
                              _vm._v(" "),
                              _c("div", { staticClass: "sub-title" }, [
                                _vm._v(
                                  "\n                            Leave blank to never expire\n                        "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("iws-button", {
                            attrs: {
                              type: "outline-danger",
                              disabled: !_vm.isIwsAdmin,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRecord(record, index)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "text",
                                  fn: function () {
                                    return [
                                      _c("i", {
                                        staticClass: "fas fa-trash-alt",
                                      }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("iws-button", {
                        attrs: {
                          "data-toggle": "collapse",
                          href: `#moreDetails_${_vm.customer.id}_${record.id}`,
                          text: _vm.moreDetailsText(record),
                          type: _vm.moreDetailsColour(record),
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "collapse accordian-parent",
                          attrs: {
                            id: `moreDetails_${_vm.customer.id}_${record.id}`,
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "card" },
                            [
                              _c("iws-table", {
                                attrs: {
                                  columns: _vm.moreDetailsCols,
                                  items: _vm.mapJobNumbers(record.workOrders),
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "cell_jobNumber",
                                      fn: function ({ data }) {
                                        return [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(data.item.jobNumber) +
                                              "\n                            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell_start",
                                      fn: function ({ data }) {
                                        return [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm._dateTimeDisplay(
                                                  data.item.start
                                                ) || "N/A"
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell_end",
                                      fn: function ({ data }) {
                                        return [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm._dateTimeDisplay(
                                                  data.item.end
                                                ) || "N/A"
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "cell_location",
                                      fn: function ({ data }) {
                                        return [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(
                                                _vm._truncateStr(
                                                  data.item.location,
                                                  50
                                                )
                                              ) +
                                              "\n                            "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    1
                  )
                }),
            _vm._v(" "),
            _vm.isIwsAdmin && _vm.records !== null
              ? _c("iws-button", {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.addRecord()
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "text",
                        fn: function () {
                          return [
                            _c("i", { staticClass: "fas fa-plus" }),
                            _vm._v("\n                Add\n            "),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    2088447451
                  ),
                })
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }