<!--<template>
    <div>
        <div class="row">
            <div class="col-12 border border-secondary rounded mb-2 mt-2" :style="{'background-color':well.color,
            color:getTitleColorForBG(well.color)}"  v-if="wireline.placed">
                <b>Wireline Perforating - {{well.name}} {{well.currentStage}}/{{well.numberOfStages}}</b>
            </div>
            <div class="col-12 rounded mb-2 mt-2 bg-secondary" v-if="!wireline.placed">
                <b>Wireline not currently placed on well</b>
            </div>
        </div>
         <div class="row justify-content-between">
            <div class="col-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Diameter (in)</p>
                {{wireline.diameter}}
            </div>
            <div class="col-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Depth (ft)</p>
                {{wireline.depth}}
            </div>
            <div class="col-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Duration</p>
                <span v-if="wireline.placed">{{wireline.duration}}</span>
                <span v-if="!wireline.placed">n/a</span>
            </div>
            
        </div>
        <div class="row justify-content-between">
            <div class="card-group border-secondary p-1 rounded mb-2 mt-2">
                <div class="card text-center border-secondary p-1 rounded bg-light " style="max-width: 18rem;">
                    <div class="card-header border-secondary p-1">
                        <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Tension (lbs)</p>
                    </div>
                    <div class="card-body p-0 m-0 ">
                         <p ><radial-gauge-component :value="wireline.tension.toString()" :options="gaugeTensionOptions"></radial-gauge-component></p>
                    </div>
                </div>
                <div class="card text-center border-secondary p-1 rounded bg-light " style="max-width: 18rem;">
                    <div class="card-header border-secondary p-1">
                       <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Speed (ft/min)</p>
                    </div>
                    <div class="card-body p-0 m-0">
                        <p ><radial-gauge-component :value="wireline.speed.toString()" :options="gaugeSpeedOptions"></radial-gauge-component></p>
                    </div>
                </div>
                <div class="card text-center border-secondary p-1 rounded bg-light " style="max-width: 18rem;">
                    <div class="card-header border-secondary p-1">
                       <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Pumpdown (psi)</p>
                    </div>
                    <div class="card-body p-0 m-0">
                        <p><radial-gauge-component :value="pressurePumpdown.toString()" :options="gaugePressureOptions"></radial-gauge-component></p>
                    </div>
                </div>
            </div>
        </div>    
    </div>    


</template>-->


<template>
    <div class="px-0 d-flex flex-column pb-1">
        <div v-if="wireline.placed" class="px-0 mx-0 col-12 border border-secondary rounded mb-2 mt-2" :style="{'background-color':well.color,
        color:getTitleColorForBG(well.color)}">
            <b>Wireline Perforating - {{well.name}} {{well.currentStage}}/{{well.numberOfStages}}</b>
        </div>
        <div v-else class="col-12 rounded mb-2 mt-2 bg-secondary">
            <b>Wireline not currently placed on well</b>
        </div>
        <div class="mx-0 row justify-content-between">
            <div class="col-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Diameter (in)</p>
                {{wireline.diameter}}
            </div>
            <div class="col-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Depth (ft)</p>
                {{wireline.depth}}
            </div>
            <div class="col-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Duration</p>
                <span v-if="wireline.placed">{{wireline.duration}}</span>
                <span v-if="!wireline.placed">n/a</span>
            </div>
            
        </div>
        <div class="mx-0 row justify-content-between">
            <div class="col-sm-12 col-md-12  col-lg-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Tension (lbs)</p>
                <div class="row">
                    <div class="col flex-column pl-0 pr-0 pt-2" ref="tensionGauge">
                        {{ this.wireline.tension.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Speed (ft/min)</p>
                <div class="row">
                    <div class="col flex-column pl-0 pr-0 pt-2" ref="speedGauge">
                        {{ this.wireline.speed.toString() }}
                    </div>
                </div>
            </div>
            <div class="col-sm-12 col-md-12 col-lg-4 text-center border border-secondary rounded mb-1">
                <p class="mb-0 mt-1" style="font-size:0.7em;line-height:0.7;">Pumpdown (psi)</p>
                <div class="row">
                    <div class="col flex-column pl-0 pr-0 pt-2" ref="pumpdownGauge">
                        {{ this.pressurePumpdown.toString() }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template> 

<script>
    export default {
        data() {

            const tickColors = this.$root.darkMode? 'white' : '#212529';
            const commonProps = {
                colorMajorTicks: tickColors,
                colorMinorTicks: tickColors,
                colorUnits: tickColors,
                colorNumbers: tickColors,
                width : 130,
                height : 130
            };

            return {
                message:"this is the wireline component",
                gaugeVal:13,
                gaugeVal2:14,
                gaugeWidth:150,
                gaugeSpeedOptions:{
                    borders: false,
                    units: "ft/min",
                    minValue: -1000,
                    maxValue: 1000,
                    majorTicks:['-1000','-500','0','500','1000'],
                    highlights:[],
                    borderShadowWidth: 0,
                    colorPlate:"transparent",
                    ...commonProps
                },
                gaugeTensionOptions:{
                    borders: false,
                    units: "lbs",
                    minValue: 0,
                    maxValue: 2000,
                    majorTicks:['0','500','1000','1500','2000'],
                    highlights:[],
                    borderShadowWidth: 0,
                    colorPlate:"transparent",
                    ...commonProps
                },
                gaugePressureOptions:{
                    borders: false,
                    units: "lbs",
                    minValue: 0,
                    maxValue: 10000,
                    majorTicks:['0','2500','5000','7500','10000'],
                    highlights:[],
                    borderShadowWidth: 0,
                    colorPlate:"transparent",
                    ...commonProps
                }

            }
        },
        props:['well','pressurePumpdown','wireline'],
        mounted(){
            console.log("the wireline component is mounted");
            window.addEventListener('resize',this.updateSizes);
            this.$nextTick(function(){
              this.updateSizes();
            });
        },
        methods:{
            updateSizes(){
              //console.log("should update sizes...");
              //console.log("speed gauge width: "+this.$refs.speedGauge.clientWidth);
              this.gaugeWidth = this.$refs.speedGauge.clientWidth;
            },
            getColorLuminosity: function(hexCode) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
                var rgb = result && result[1] && result[2] && result[3] ? [
                    parseInt(result[1], 16),
                    parseInt(result[2], 16),
                    parseInt(result[3], 16)
                ] : null;

                if(!rgb) {
                    //can't determine luminosity
                    return null;
                }

                var lrgb = [];
                rgb.forEach(function(c) {
                    c = c / 255.0;
                    if (c <= 0.03928) {
                        c = c / 12.92;
                    } else {
                        c = Math.pow((c + 0.055) / 1.055, 2.4);
                    }
                    lrgb.push(c);
                });
                
                //returns luminosity in first index, raw rgb in second index
                return [0.2126 * lrgb[0] + 0.7152 * lrgb[1] + 0.0722 * lrgb[2], rgb];
            },
            getTitleColorForBG: function(hexCode) {
                var lum = this.getColorLuminosity(hexCode);
                return !lum || (lum[0] > 0.179) ? '#000000' : '#ffffff';
            }
        }
    }
</script>
