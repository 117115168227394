<!--
    * Component Description
        A simple, re-usable accordian wrapper that applies IWS styling and other small features
        See props for an explanation of all supported functions

    * Side Effects
    Will emit 3 signal
        update:value     <- syncs with the 'value' prop to comunicate upwards what the last selection made was
        change           <- triggers upon click of an accordian card

    * Slots
        card-body_<index>   <- Target each accordian body

    * Example Usage
        <iws-accordian :cards="[ { title: `<UserFriendlyTitle>` }, { title: `<UserFriendlyTitle>`, enabled: false } ]">
            <template #card-body_0>

            </template>

            <template #card-body_1>

            </template>
        </iws-accordian>
-->
<template>
    <div id="accordion">
        <div class="card" v-for="(card, index) in cards">
            <div :ref="`accordian_${index}`"
                :id="isEnabled(card) ? `heading_${index}` : null"
                class="card-header clickable"
                :class="{ 'active-header': isActive(index) }"
                data-toggle="collapse"
                :data-target="isEnabled(card) ? `#collapse_${index}` : null"
                aria-expanded="true"
                :aria-controls="isEnabled(card) ? `collapse_${index}` : null"
                @click="handleClick(card, index)"
            >
                <h5 class="mb-0">
                    {{ card?.title || card }}
                </h5>

                <i class="fas fa-chevron-down"></i>
            </div>

            <div
                :id="`collapse_${index}`"
                class="collapse"
                :aria-labelledby="`heading_${index}`"
                :data-parent="isEnabled(card) && !allowMultiTabs ? `#accordion` : null"
            >
                <div class="card-body">
                    <slot :name="`card-body_${index}`">
                        Card {{ index }}
                    </slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, toast } = GlobalFunctions;

export default {
    props: {
        // List of objects describing the accordian cards to display
        // Example: [ { title: `<UserFriendlyTitle>` }, { title: `<UserFriendlyTitle>`, enabled: false } ]
        cards: {
            type: Array,
            required: true
        },
        // Synced value of input field
        value: {
			type: String | Number,
            default: 0
		},
        // Allows for viewing multiple cards at a time
        allowMultiTabs: {
            type: Boolean,
            default: false
        }
    },

    data: () => ({
        // We support having multiple cards open at once so for simplicity, the open index property is always a list
        openIndex: []
    }),

    methods: {
        handleClick(card, index) {
            if (!this.isEnabled(card))
                return toast({
                    title: 'This tab is currently disabled',
                    variant: 'warning'
                });

            const matchedIndex = this.openIndex.findIndex(_index => _index === index);
                
            // When selecting the active card, it's actually unselect
            if (matchedIndex < 0) {
                this.openIndex.push(index)
            } else {
                this.openIndex.splice(matchedIndex, 1);
            }

            if (this.allowMultiTabs) {
                // When allowing multi tabs to be open, just send the list of indices as is
                this.$emit('update:value', this.openIndex);
                this.$emit('change', this.openIndex);
            } else {
                // If single, reduce the list to the single index (if set)
                this.$emit('update:value', !isNullOrEmpty(this.openIndex) ? this.openIndex[0] : null);
                this.$emit('change', !isNullOrEmpty(this.openIndex) ? this.openIndex[0] : null);
            }
        },
        openByIndex(index, state='toggle') {
            if (!isFalsy(index) && index in this.cards) {
                $(`#collapse_${index}`).collapse(state);
                this.handleClick(this.cards[index], index);
            }
        },
        isActive(index) {
            return this.openIndex.includes(index);
        },
        isEnabled(card) {
            // Only disable when enabled is specifically false.
            // null, undefined, etc. count as true as it's a optional property
            return card.enabled !== false;
        }
    },

    mounted() {
        if (this.value !== null && !isNullOrEmpty(this.$refs[`accordian_${this.value}`]))
            this.$refs[`accordian_${this.value}`][0]?.click();
    }
};
</script>

<style scoped>
    .card {
        background: #242A30 !important;
        color: #FFFFFF !important;
        
        margin-top: 15px;
        border: 1px solid white;
    }

    .card-body {
        background: #2E353D !important;

        border-top: 1px solid white;

    }

    .card-body .title_row {
        width: 100%;
        position: relative;
        padding: 20px 1.25rem;
    }
    .card-body .title_row h3 {
        margin-bottom: 0px !important;
    }

    .card-header i {
        position: absolute;
        top: 18px;
        right: 30px;

        transition: all 0.5s;
    }

    .active-header i {
        rotate: 180deg;
    }
</style>