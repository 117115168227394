<template>
    <div class="filter-container ticket-status-filter-container">
        <!-- If no slot present, use search/select field -->
        <iws-select
            v-if="!$slots.default"
            :label="label" 
            :value="filter"
            :display-name="displayName"
            :value-name="valueName"
            :options="options"
            :placeholder="placeholder ?? `Select ${label}`"
            :maintainOnSelect="maintainOnSelect"
            :multiselect="multiselect"
            @change="handleChange(filter, $event)"
        />

        <slot v-else></slot>

        <div>
            <span v-for="(option, index) in filter" class="badge badge-outline">
                {{ option.label }}
                <i class="fas fa-times clickable" @click="remove(filterKey, index)"></i>
            </span>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            label: String,
            placeholder: String,
            filter: Array,
            filterKey: String,
            options: Array,
            maintainOnSelect: {
                type: Boolean,
                default: true,
            },
            multiselect: {
                type: Boolean,
                default: true,
            },
            displayName: {
                type: [ String, null ],
                default: null,
            },
            valueName: {
                type: [ String, null ],
                default: null,
            },
        },
        methods: {
            remove(filterKey, index) {
                this.$emit('remove', filterKey, index);
            },
            handleChange(filter, event) {
                this.$emit('change', filter, event);
            }
        },
    }
</script>

<style scoped>
.ticket-status-filter-container :deep(.form-control),
.ticket-status-filter-container :deep(.form-control) {
    background: #343A40 !important;
    color: inherit;
    border-color: #fff;
    width: 100%;
    padding: 6px 12px;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    outline: none;
}
</style>