<template>
    <div>
        <template v-if="createUser">
            <iws-breadcrumbs
                class="mt-4 mb-1"
                :path="[{
                    title: 'Dashboard',
                    link: '/home',
                }, {
                    title: 'User Access Management',
                    link: '/userManagement',
                }, {
                    title: 'Create User',
                    link: '/register',
                }]"
            />
            <div class="page-title mb-4">
                Create User
            </div>
        </template>

        <iws-form ref="form" :data="user">
            <template #body="{ dataItem, submit }">
                <div class="row">
                    <div class="col-6">
                        <iws-input
                            ref="validateField"
                            label="First Name"
                            placeholder="Provide the users first name"
                            :value.sync="dataItem.first_name"
                            required
                            error-message="Please provide a name"
                            :readonly="!checkForIWSUser"
                        />
                    </div>

                    <div class="col-6">
                        <iws-input
                            ref="validateField1"
                            label="Last Name"
                            placeholder="Provide the users last name"
                            :value.sync="dataItem.last_name"
                            required
                            error-message="Please provide a name"
                            :readonly="!checkForIWSUser"
                        />
                    </div>

                    <div class="col-6 mt-4">
                        <iws-input
                            ref="validateField2"
                            label="Email"
                            placeholder="Provide a valid email for the user to sign in with"
                            :value.sync="dataItem.email"
                            type="email"
                            :validator="validateEmail"
                            :error-message="'E-mail is invalid or already in use'"
                            required
                            :readonly="!checkForIWSUser"
                        />
                    </div>

                    <div class="col-6 mt-4">
                        <iws-input
                            ref="validateField3"
                            label="Phone"
                            placeholder="Provide the users mobile telephone (optional)"
                            :value.sync="dataItem.phone_number"
                            type="tel"
                            :readonly="!checkForIWSUser"
                        />
                    </div>

                    <div v-if="createUser" class="col mt-4">
                        <iws-input
                            ref="validateField4"
                            label="PIN"
                            placeholder="Provide a 4 digit pin"
                            :value.sync="dataItem.pin"
                            type="password"
                            :validator="validatePIN"
                            :error-message="dataItem.pin?.length < 4 ? 'Must be at least 4 characters' : 'Pin is already in use'"
                            :readonly="readonly"
                        />
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <iws-select class="mt-4"
                            ref="validateField5"
                            label="Company"
                            :value.sync="dataItem.customer_id"
                            :options="companies"
                            display-name="name"
                            value-name="id"
                            required
                            :disabled="!checkForIWSUser"
                        />
                    </div>

                    <div class="col-6">
                        <iws-select class="mt-4"
                            ref="validateField6"
                            label="Role"
                            :value.sync="dataItem.roleId"
                            :options="filteredRoles"
                            display-name="name"
                            value-name="id"
                            required
                             :disabled="!checkForIWSUser"
                        />
                    </div>
                </div>

                <iws-select class="mt-4"
                    ref="validateField7"
                    label="Permissions"
                    :value="dataItem.permissions"
                    :options="permissionOptions"
                    :placeholder="!_isNullOrEmpty(dataItem?.permissions) ? `${dataItem?.permissions?.join(', ')}` : 'Search for permissions'"
                    :disabled="!checkForIWSUser"
                    :multiselect="true"
                    :maintain-on-select="true"
                    :visible-options="8"
                    @change="onPermissionSelect(dataItem, $event)"
                    @select-all="onPermissionSelectAll(dataItem)"
                />

                <iws-checkbox v-if="(createUser || dataItem.pin === null || dataItem.pin === '') && checkForIWSUser"
                    ref="validateField8"
                    class="mt-4"
                    label="Login via Microsoft"
                    :value.sync="dataItem.single_sign_on"
                    :disabled="!userIsAdmin()"
                    :enableClickLabel="true"
                />

                <template v-if="createUser">
                    <iws-input class="mt-4" style="display: block"
                        ref="validateField9"
                        label="Password"
                        :value.sync="dataItem.password"
                        type="password"
                        required
                        :validator="() => dataItem.password?.length >= 10 && (_isFalsy(dataItem.confirmPassword) || dataItem.password == dataItem.confirmPassword)"
                        :error-message="dataItem.password?.length < 10 ? 'Must be at least 10 characters' : 'Password and Confirm Password must match'"
                        :readonly="readonly"
                    />

                    <iws-input class="mt-4" style="display: block"
                        ref="validateField10"
                        label="Confirm Password"
                        :value.sync="dataItem.confirmPassword"
                        type="password"
                        required
                        :validator="() => dataItem.confirmPassword?.length >= 10 && dataItem.password == dataItem.confirmPassword"
                        :error-message="dataItem.confirmPassword?.length < 10 ? 'Must be at least 10 characters' : 'Password and Confirm Password must match'"
                        :readonly="readonly"
                    />
                    <password-strength-component
                        v-if="passwordStrengthRequired"
                        :password="dataItem.password?? ''"
                        :firstName="dataItem.first_name"
                        :lastName="dataItem.last_name"
                        :email="dataItem.email"
                        @requirementsMet="(value) => passRequirementsMet = value"
                    >
                    </password-strength-component>
                </template>
                <div v-if="errorOcurred" class="text-danger">
                    Failed to create new user. Please check password requirements
                </div>
                <iws-button class="mt-4 mb-4"
                    style="padding: .375rem 1.5rem"
                    text="Submit"
                    type="primary"
                    v-if="checkForIWSUser"
                    :click="($event) => { $event.preventDefault(); submit(onSubmit, Object.values($refs)) }"
                    :disabled="passwordStrengthRequired && createUser && !passRequirementsMet"
                />
            </template>
        </iws-form>
    </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, isFeatureFlagged } = GlobalFunctions;

export default {
    props: {
        createUser: {
            type: [Number],
        },
        user: {
            type: [Object],
        },
        companies: {
            type: [Array, String],
            required: true,
        },
        apiPermissions: {
            type: [Array, String, Object],
        },
        roles: {
            type: [Array],
            required: true,
        },
        role: {
            type: String,
            required: true,
        },
        usersInfo: {
            type: [Object, Array, String],
        },
        userRole: {
            type: [Object, Array],
        },
        isIwsUser: {
            type: [Boolean, Number],
            required: true,
        }
    },

    data: () => ({
        readonly: true,
        permissionOptions: [
            'analysis',
            'cameraAdmin',
            'cameraOperator',
            'cameraViewer',
            'Frac Stage Summary',
            'NPT Editor',
            'NPT Report',
            'operations',
            'Process Logs',
            'SCR Talk',
            'SCR Listen',
            'SCR None',
            'Tag Summary',
            'Time Keeper',
            'Wireline Plug and Perf',
            'Wireline Stage Summary',
            'API'
        ],
        passRequirementsMet: false,
        errors: {},
        errorOcurred: false,
        passwordStrengthRequired: false
    }),

    computed: {
        filteredRoles() {
            // Filter any empty roles
            const roles = this.roles.filter(role => role);

            if (!this.createUser && this.user?.customer?.name != "IWS")
                return roles.filter(role => role?.name !== "admin" && role?.name !== "iwsTech");
            return roles;
        },
        checkForIWSUser(){
            return this.isIwsUser === 1 || this.isIwsUser === true
        }
    },

    methods: {
        _isFalsy(val) {
            return isFalsy(val);
        },
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },

        userIsAdmin() {
            return this.role == "admin";
        },
        isUnique(value, property) {
            return !this.usersInfo.find(_user => _user[property] == value && this.user?.id !== _user.id);
        },
        validatePIN(pin) {
            // Is optional so when empty it passes
            if (isFalsy(pin))
                return true;
            // If set, must be at least 4 characters
            if (pin?.length < 4)
                return false;
            return this.isUnique(pin, 'pin');
        },
        validateEmail(email) {
            return this.isUnique(email, 'email');
        },

        onPermissionSelect(user, selectedItem) {
            const index = user?.permissions.indexOf(selectedItem);

            if (index < 0) {
                // Adding to list
                // For dashboard_permissions, users can only be assigned one of 'cameraAdmin', 'cameraOperator', 'cameraViewer'
                const camsRoles = ['cameraAdmin', 'cameraOperator', 'cameraViewer'];
                if (camsRoles.includes(selectedItem))
                    user.permissions = user?.permissions.filter(value => !camsRoles.includes(value));

                // For dashboard_permissions, users can only be assigned one of 'SCR Talk', 'SCR Listen', 'SCR None'
                const scrRoles = ['SCR Talk', 'SCR Listen', 'SCR None'];
                if (scrRoles.includes(selectedItem))
                    user.permissions = user?.permissions.filter(value => !scrRoles.includes(value));

                user?.permissions.push(selectedItem);
            } else {
                // Removing from list
                user?.permissions.splice(index, 1);
            }
        },
        onPermissionSelectAll(dataItem) {
            const filteredRoles = ['cameraAdmin', 'cameraOperator', 'cameraViewer', 'SCR Talk', 'SCR Listen', 'SCR None'];
            dataItem.permissions = dataItem?.permissions.filter(value => !filteredRoles.includes(value));

            dataItem?.permissions.push('cameraAdmin');
            dataItem?.permissions.push('SCR Talk');
        },

        onSubmit(user) {
            let payload = {
                _token: GlobalFunctions.getCSRFToken(),
                first_name: user.first_name,
                last_name: user.last_name,
                email: user.email,
                phone_number: user.phone_number || '',
                company: user.customer_id,
                role: user.roleId,
                dashboard_permissions: user.permissions,
                single_sign_on: user.single_sign_on || 0,
            };

            if (this.createUser) {
                payload = {
                    ...payload,
                    password: user.password,
                    confirmPassword: user.confirmPassword,
                    pin: user.pin
                }
            }

            $.ajax({
                url: this.createUser ? '/register' : `/userManagement/${user.id}`,
                method: 'PUT',
                data: payload,
                dataType: 'json'
            }).done((response) => {
                // In case the user is new and the id wasn't previously set, set it for the UserPermissionsCompany to reference in their save
                    if (!isFalsy(response?.data?.data?.id))
                        this.user.id = response.data.data.id;

                    if (!isNullOrEmpty(this.$refs?.UserPermissionsCompany))
                        return Promise.all(this.$refs?.UserPermissionsCompany?.map(_ref => _ref.submitForm())).then(this.onResponseComplete);
                    this.onResponseComplete(response);
            }).fail((msg) => {
                if (msg.responseJSON.message || msg.responseJSON.errors) {
                    this.errorOcurred = true;
                    setTimeout(() => {
                        this.errorOcurred = false;
                    }, 3000);
                }
            });
        },
        onResponseComplete(response) {
            if (response?.redirect) {
                window.location = response.redirect;
            }
        }
    },
    created() {
        this.passwordStrengthRequired = isFeatureFlagged('PASSWORDSTRENGTH');
    },
    mounted() {
        setTimeout(() => {
            this.readonly = false;
        }, 1000);

    }
};
</script>
