var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "position-relative", attrs: { id: "MetadataTopLevel" } },
    [
      _c("iws-breadcrumbs", {
        staticClass: "breadcrumbs-margins",
        attrs: {
          path: [
            {
              title: "Operator List",
              link: `/metadata`,
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "h5 page-title" }, [
        _vm._v("\r\n        Operator List\r\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { id: "search" } },
        [
          _c("iws-search", {
            attrs: { value: _vm.searchKey },
            on: {
              "update:value": function ($event) {
                _vm.searchKey = $event
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("iws-tabs", {
        attrs: { tabs: _vm.tabs },
        on: { "tab-change": _vm.handleTabChange },
        scopedSlots: _vm._u([
          {
            key: "tab_0",
            fn: function () {
              return [
                _c("iws-table", {
                  attrs: {
                    columns: [
                      {
                        label: "System Number",
                        key: "system",
                      },
                      {
                        label: "actions",
                        showHeader: false,
                        sortable: false,
                      },
                    ],
                    items: _vm.jobs,
                    filter: _vm.searchKey,
                    sortByCol: _vm.sortByCol,
                    maxPageSize: 12,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell_actions",
                      fn: function ({ data }) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: { href: `/metadata/job/${data.item.id}` },
                            },
                            [
                              _vm._v("\r\n                        Select "),
                              _c("i", {
                                staticClass: "fas fa-chevron-right ml-1",
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "tab_1",
            fn: function () {
              return [
                _c("iws-table", {
                  attrs: {
                    columns: [
                      {
                        label: "ID",
                        key: "id",
                      },
                      {
                        label: "Name",
                        key: "name",
                      },
                      {
                        label: "Customer",
                        key: "customer",
                      },
                      {
                        label: "Pads",
                        key: "pads",
                        thStyle: "width: 150px",
                      },
                    ],
                    items: _vm.systems,
                    filter: _vm.filterSystem,
                    sortByCol: _vm.sortByCol,
                    maxPageSize: 12,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      _vm._l(_vm.systems, function (system) {
                        return {
                          key: `row_${system.id}`,
                          fn: function () {
                            return [
                              _c("td", [
                                _vm._v(
                                  "\r\n                        " +
                                    _vm._s(system.id) +
                                    "\r\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\r\n                        " +
                                    _vm._s(system.data.name) +
                                    "\r\n                    "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  _c("iws-select", {
                                    staticStyle: {
                                      display: "inline-block",
                                      width: "calc(100% - 51px)",
                                      "max-width": "175px",
                                      margin: "0px",
                                    },
                                    attrs: {
                                      value: system.data.customer.id,
                                      options: _vm.customers,
                                      "display-name": "name",
                                      "value-name": "id",
                                    },
                                    on: {
                                      "update:value": function ($event) {
                                        return _vm.$set(
                                          system.data.customer,
                                          "id",
                                          $event
                                        )
                                      },
                                      change: function ($event) {
                                        system.data.customer.isDirty = true
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  system.data.customer.isDirty
                                    ? _c("iws-button", {
                                        staticStyle: {
                                          position: "relative",
                                          top: "-3px",
                                        },
                                        attrs: {
                                          text: "Save",
                                          type: "outline-primary",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.updateCustomer(system)
                                          },
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                [
                                  !_vm.hasCustomer(system)
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "badge badge-pill badge-warning",
                                        },
                                        [
                                          _vm._v(
                                            "\r\n                            Must specifiy customer\r\n                        "
                                          ),
                                        ]
                                      )
                                    : [
                                        _c("iws-button", {
                                          attrs: {
                                            text: _vm.makePadsButtonText(
                                              system
                                            ),
                                            type: "outline-light",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openSelectPads(system)
                                            },
                                          },
                                        }),
                                      ],
                                ],
                                2
                              ),
                            ]
                          },
                          proxy: true,
                        }
                      }),
                    ],
                    null,
                    true
                  ),
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "tab_2",
            fn: function () {
              return [
                _c("iws-table", {
                  attrs: {
                    columns: [
                      {
                        label: "Operator",
                        key: "name",
                      },
                      {
                        label: "actions",
                        showHeader: false,
                        sortable: false,
                      },
                    ],
                    items: _vm.customers,
                    filter: _vm.searchKey,
                    sortByCol: _vm.sortByCol,
                    maxPageSize: 12,
                  },
                  on: {
                    "update:sortByCol": function ($event) {
                      _vm.sortByCol = $event
                    },
                    "update:sort-by-col": function ($event) {
                      _vm.sortByCol = $event
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell_actions",
                      fn: function ({ data }) {
                        return [
                          _c(
                            "a",
                            {
                              attrs: {
                                href: `/metadata/operator/${data.item.id}`,
                              },
                            },
                            [
                              _vm._v("\r\n                        Select "),
                              _c("i", {
                                staticClass: "fas fa-chevron-right ml-1",
                              }),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("metadata-top-level-pads-modal", {
        ref: "MetadataTopLevelPadsModal",
        attrs: { fieldTypeId: _vm.fieldtypeid, padTypeId: _vm.padtypeid },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }