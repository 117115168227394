<template>
  <div v-on:click="openChat" class="omnichannel-chat-button" v-if="showWidget">
    <svg class="support-icon" xmlns="http://www.w3.org/2000/svg" width="20.4" height="24" viewBox="0 0 20.4 24">
      <path id="contact_support_FILL0_wght400_GRAD0_opsz48" d="M131.85,200l-.3-3.3h-1.2a10.35,10.35,0,1,1,4-19.935,9.589,9.589,0,0,1,3.165,2.145,10,10,0,0,1,2.115,3.255,10.739,10.739,0,0,1,.765,4.1,13.915,13.915,0,0,1-.585,3.96,15.593,15.593,0,0,1-1.7,3.78,16.993,16.993,0,0,1-2.7,3.33A18.269,18.269,0,0,1,131.85,200Zm1.5-3.24a15.117,15.117,0,0,0,3.855-4.875,12.53,12.53,0,0,0,1.395-5.625,8.33,8.33,0,0,0-2.325-6.09,7.872,7.872,0,0,0-5.865-2.37,8.55,8.55,0,1,0-.06,17.1h3Zm-2.94-3.45a1.127,1.127,0,1,0-.81-.33A1.1,1.1,0,0,0,130.41,193.31Zm-.81-4.08h1.5a2.7,2.7,0,0,1,.255-1.245,5.985,5.985,0,0,1,1.065-1.305,5.841,5.841,0,0,0,1.14-1.485,3.268,3.268,0,0,0,.33-1.455,2.93,2.93,0,0,0-.915-2.22,3.4,3.4,0,0,0-2.445-.87,3.946,3.946,0,0,0-3.72,2.46l1.38.57a2.455,2.455,0,0,1,.915-1.155,2.419,2.419,0,0,1,1.335-.375,2.1,2.1,0,0,1,1.41.435,1.443,1.443,0,0,1,.51,1.155,2.338,2.338,0,0,1-.33,1.155,7.825,7.825,0,0,1-1.17,1.455,5.344,5.344,0,0,0-1.035,1.35A3.974,3.974,0,0,0,129.6,189.23ZM130.2,187.28Z" transform="translate(-120 -176)" fill="#fff"/>
    </svg>
  </div>
</template>
<script>
  // Omnichannel Chat Widget
  // See wiki article for customization info & known issues:
  // https://dev.azure.com/iwssoftware/IWS%20Development/_wiki/wikis/IWS%20Wiki/502/Omnichannel-Chat
  
  window.customizeOmnichannelChat = function () {
    // Configurations go here (see wiki)
    return {}
  }

  export default {
    data() {
      return {}
    },
    props : {
      user: {
        type: Object,
        required: true,
      },
      src: {
        type: String,
      },
      appId: {
        type: String,
      },
      orgId: {
        type: String,
      },
      orgUrl: {
        type: String,
      }
    },
    computed: {
      showWidget() {
        // Do not show chat icon if env configs not available
        return this.src && this.appId && this.orgId && this.orgUrl;
      },
    },
    methods: {
      openChat() {
        // Should load script first time
        if (!(window.hasOwnProperty('Microsoft') && Microsoft.hasOwnProperty('Omnichannel'))) {
          this.loadScript();
          return;
        }

        this.setChatCookie();
        const userFullName = `${this.user.first_name} ${this.user.last_name ?? ''}`;
        
        // setContextProvider method reference
        // https://learn.microsoft.com/en-us/dynamics365/customer-service/developer/reference/methods/setcontextprovider
        Microsoft.Omnichannel.LiveChatWidget.SDK.setContextProvider(function contextProvider() {
            return {
                Name: { 
                  value: userFullName, 
                  isDisplayable: true
                }
            }
        });

        // startChat method reference:
        // https://learn.microsoft.com/en-us/dynamics365/customer-service/developer/reference/methods/startchat
        Microsoft.Omnichannel.LiveChatWidget.SDK.startChat();
      },
      loadScript() {
        // Create and load the omnichannel vendor script
        const script = document.createElement('script');
        script.setAttribute('v2', true)
        script.setAttribute('data-app-id', this.appId)
        script.setAttribute('data-org-id', this.orgId)
        script.setAttribute('data-org-url', this.orgUrl)
        script.setAttribute('data-lcw-version', 'prod')
        script.setAttribute('data-hide-chat-button', 'true')
        script.setAttribute('data-color-override', '#363A3D')
        script.setAttribute('data-customization-callback', 'customizeOmnichannelChat')
        script.id = 'Microsoft_Omnichannel_LCWidget'
        script.src = this.src;

        // Add it to the document
        document.head.appendChild(script)

        // open chat when client is ready
        window.addEventListener('lcw:ready', () => {
          this.openChat();
        })
      },
      setChatCookie() {
        // date as integer value (minutes)
        const time = (new Date()).getTime() / (1000 * 60);
        window.setCookie('omnichannelactivechat', time);
      },
      getChatCookie() {
        return window.getCookie('omnichannelactivechat')
      },
      checkActiveSession() {
        // Checks cookie to see if a recent active chat session exists
        const now = (new Date()).getTime() / (1000 * 60);
        const chatCookie = this.getChatCookie();

        if (!chatCookie || chatCookie === '') return;

        const sinceLastChat = now - parseInt(chatCookie);

        if (sinceLastChat < 30) this.openChat();
      }
    },
    mounted() {
      this.checkActiveSession();
      
      // List of chat events: https://learn.microsoft.com/en-us/dynamics365/customer-service/developer/reference/events/lcw-ready
      // We don't want to automatically load chat unless user has an active chat session
      // Listen for any chat close/minimize event and remove active chat cookie
      const hideChatEvents = ['lcw:onMinimize', 'lcw:onClose', 'lcw:closeChat'];
      hideChatEvents.forEach(chatEvent => {
        window.addEventListener(chatEvent, () => {
          window.deleteCookie('omnichannelactivechat');
        });
      });
    }
  }
</script>
<style>
  .omnichannel-chat-button {
    position: fixed;
    z-index: 5; /* 1 less than alerts */
    bottom: 20px;
    right: 60px;
    height: 50px;
    width: 50px;
    border-radius: 25px;
    background: #197efb;
    border: 2px solid #72b1ff;
    color: #ffffff;
    text-align: center;
  }

  .omnichannel-chat-button .support-icon {
    height: 100%;
    width: 45%;
  }

  #Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window {
    height: calc(100vh - 87px);
    width: calc(100vw - 40px);
    max-height: 600px;
    max-width: 360px;

    /* Need to override html style attributes added by script */
    bottom: 0px !important;
    right: 20px !important;
    left: auto !important;
    top: auto !important;
  }

  @media (min-width: 1920px) {
    #Microsoft_Omnichannel_LCWidget_Chat_Iframe_Window {
      max-width: 450px;
      max-height: 700px;
    }
  }
</style>