<template>
	<div>
		<span id="interlockButton"
			class="fas fa-lock fa-md px-1"
			role="button"
			:style="interlockButtonStyle"
			@click="toggleValue()"
		>
		</span>
		<b-tooltip triggers="hover" target="interlockButton">
			{{isActive ? activeTooltipText : inactiveTooltipText}}
		</b-tooltip>
	</div>
</template>

<script>
export default {
    data() {
        return {
            isActive: false
        };
    },
    props: ['isActiveProp', 'onValueChanged', 'activeTooltipText', 'inactiveTooltipText', 'localStorageKey'],
    computed: {
        interlockButtonStyle() {
            const style = {
                'top': '0px',
                'left': '0',
                'margin': 'auto',
                'position': 'absolute',
                'color': this.isActive ? 'white' : 'grey',
                '--lineWidth': this.isActive? '0px' : '2px'
            };
            return style;
        }
    },
    mounted() {
        this.isActive = localStorage.getItem('localStorageKey') == 'true';
        this.onValueChanged(this.isActive);
    },
    methods: {
        toggleValue: function() {
            this.isActive = !this.isActive;
            localStorage.setItem('localStorageKey', this.isActive);
            this.onValueChanged(this.isActive);
        }
    }
};
</script>

<style>
.fas.fa-lock:after {
	content: "";
	position: absolute;
	width: var(--lineWidth);
	height: 141.421356%;
	top: -20.710678%;
	display: block;
	background: grey;
	left: 50%;
	transform: translate(-50%, 0) rotate(45deg);
}
</style>