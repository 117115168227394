var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "filter-container ticket-status-filter-container" },
    [
      !_vm.$slots.default
        ? _c("iws-select", {
            attrs: {
              label: _vm.label,
              value: _vm.filter,
              "display-name": _vm.displayName,
              "value-name": _vm.valueName,
              options: _vm.options,
              placeholder: _vm.placeholder ?? `Select ${_vm.label}`,
              maintainOnSelect: _vm.maintainOnSelect,
              multiselect: _vm.multiselect,
            },
            on: {
              change: function ($event) {
                return _vm.handleChange(_vm.filter, $event)
              },
            },
          })
        : _vm._t("default"),
      _vm._v(" "),
      _c(
        "div",
        _vm._l(_vm.filter, function (option, index) {
          return _c("span", { staticClass: "badge badge-outline" }, [
            _vm._v("\n            " + _vm._s(option.label) + "\n            "),
            _c("i", {
              staticClass: "fas fa-times clickable",
              on: {
                click: function ($event) {
                  return _vm.remove(_vm.filterKey, index)
                },
              },
            }),
          ])
        }),
        0
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }