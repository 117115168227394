<template>
    <div ref="containerHeight" :style="'background-color:white; height:' + height + 'px' " class="d-flex flex-column">
        <div>
            <div ref="filtersHeader" class="row px-2 pb-2 pt-2" :style="isLoadingPads ? 'background-color:rgba(0,0,0,0.5);' : ''">
                <div class="col-10">
                    <div class="row w-100">
                        <div class="col">
                            <div>
                                <div class="pb-2 selectlabel">
                                    Select Pad(s)
                                </div>
                                <div>
                                    <!-- Control goes here -->
                                    <multi-select id="selectedPads"
                                        :options="receivedPads"
                                        :value="selectedPads"
                                        label='label'
                                        :disabled="isLoadingPads"
                                        :maxHeight="dropdownHeight"
                                        @selectedupdated="padsSelected"
                                        :sortByKey="sortPadDropdownByKey"
                                        :isReverseSort="isReverseSort"
                                        :key="refreshTicker"/>
                                </div>
                                <div>
                                    <b-form-checkbox
                                        class="mt-2"
                                        v-model="sortByJobStartDate"
                                        :value="true"
                                        :unchecked-value="false"
                                    >
                                        Sort by Job Start Date
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <div class="pb-2 selectlabel">
                                    Select Well(s)
                                </div>
                                <div>
                                    <!-- Wells data -->
                                    <multi-select id="selectedWells"
                                                  :options="selectableWells"
                                                  :value="selectedWells"
                                                  :hasSubLabels="true"
                                                  :disabled="selectedPads.length == 0"
                                                  :label="'wellName'"
                                                  :maxHeight="dropdownHeight"
                                                  @selectedupdated="wellsSelected"
                                                  :key="refreshTicker"/>

                                    <b-button @click="selectAllWells()"
                                              size="sm"
                                              class="my-1"
                                              :disabled="selectableWells.length == 0 || selectedPads.length == 0"
                                              variant='secondary'>All</b-button>
                                    <b-button @click="selectedWells = []"
                                              size="sm"
                                              class="my-1 ml-1"
                                              :disabled="selectedPads.length == 0 || selectedWells.length == 0"
                                              variant='secondary'>Clear</b-button>                                    
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <div class="pb-2 selectlabel">
                                    Index Data By
                                </div>
                                <div>
                                    <!-- Index data -->
                                    <multi-select id="selectedIndices"
                                                  :options="receivedIndices"
                                                  :value="selectedIndices"
                                                  :selected="selectedIndices"
                                                  :disabled="selectedPads.length == 0"
                                                  :multiSelect="false"
                                                  :allowEmpty="false"
                                                  @selectedupdated="indicesSelected"
                                                  :key="refreshTicker"/>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <div class="pb-2 selectlabel">
                                    Select Stage(s)
                                </div>
                                <div>
                                    <!-- Stages data -->
                                    <multi-select id="selectedStages"
                                                  :options="stageOptions"
                                                  :multiSelect="true"
                                                  :value="selectedStages"
                                                  :maxHeight="dropdownHeight"
                                                  :hasSubLabels="true"
                                                  :disabled="selectedWells.length == 0 || selectedIndices.length == 0 || templateData.loadLatestStage == 1"
                                                  @selectedupdated="stagesSelected"
                                                  :key="refreshTicker"/>

                                    <b-button @click="selectAllStages()"
                                              size="sm"
                                              class="my-1"
                                              :disabled="stageOptions.length == 0 || selectedWells.length == 0"
                                              variant='secondary'>All</b-button>
                                    <b-button @click="selectedStages = []"
                                              size="sm"
                                              class="my-1 ml-1"
                                              :disabled="stageOptions.length == 0 || selectedWells.length == 0"
                                              variant='secondary'>Clear</b-button>                                    

                                </div>
                                <label class="font-90" for="">
                                    <input class="mr-1" type="checkbox" 
                                        v-model="templateData.loadLatestStage"
                                        @change="selectLatestStages()"
                                        :disabled="selectedWells.length == 0 || selectedIndices.length == 0"
                                        >
                                Last Complete</label>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <div class="pb-2 selectlabel">
                                    Select Channel(s)
                                </div>
                                <div>
                                    <!-- Channel data -->
                                    <multi-select id="selectedChannels"
                                                  :options="receivedChannels"
                                                  :value="selectedChannels"
                                                  :label="'prioritizedTagName'"
                                                  :selectAllDuplicates="true"
                                                  :removeDuplicatesKey="'name'"
                                                  :maxHeight="dropdownHeight"
                                                  :uniqueId="'id'"
                                                  :trackByVal="'name'"
                                                  :disabled="selectedPads.length == 0 || isLoadingChannels"
                                                  @selectedupdated="channelsSelected"
                                                  :key="refreshTicker"/>
                                    <b-button @click="selectedChannels = [...receivedChannels]"
                                              size="sm"
                                              class="my-1"
                                              :disabled="isLoadingChannels || selectedPads.length == 0 || receivedChannels.length == 0"
                                              variant='secondary'>All</b-button>
                                    <b-button @click="selectedChannels = []"
                                              size="sm"
                                              class="my-1 ml-1"
                                              :disabled="isLoadingChannels || selectedPads.length == 0 || receivedChannels.length == 0 || selectedChannels.length == 0"
                                              variant='secondary'>Clear</b-button>
                                </div>
                            </div>
                        </div>
                        <div class="col">
                            <div>
                                <div class="pb-2 selectlabel">
                                    Aggregate Interval
                                </div>
                                <div class="controlBorder">  
                                    <select class="form-control" id="aggregateSelect" v-model="aggregateInterval" :disabled="isLoadingPads || isLoading">
                                        <option v-for="(option,index) in dashboardData.aggregateOptions" :key="index" :value="option.value">{{option.name}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-2">
                    <div class="d-flex justify-content-end pr-3 pb-2">
                        <!-- TODO: Temporarily disabled as this feature is not yet implemented. 
                        <div class="row" style="display: contents;">
                            <div class="pr-2">
                                <input class="check-box" type="checkbox" v-model="checkboxLiveData"/>
                            </div>
                            <div>
                                Display Live Data (NI)
                            </div>
                        </div> -->
                    </div>
                    <div class="d-flex row justify-content-left w-100">
                        <div class="d-flex flex-column mt-3">
                            <button :disabled="isLoadingPads" class="btn btn-secondary grey-button btnSizeOverride mr-2" @click="clearSelections()">Clear All Filters</button>
                        </div>
                        <div class="d-flex flex-column mt-3">
                            <button :disabled="isLoadingPads" class="btn btn-secondary grey-button btnSizeOverride mr-2" data-backdrop="static" data-keyboard="false" @click="modalVisible = true">Template Settings</button>
                        </div>
                        
                        <div class="d-flex flex-column mt-3">
                            <button :disabled="isLoadingPads" class="btn btn-secondary grey-button btnSizeOverride mr-2" @click="validateAndFetchData">Load Chart</button>
                        </div>
                        <!-- TODO: Temporarily disabled as this feature; is not yet implemented.
                        <div class="d-flex flex-column">
                            <button class="btn btn-light">Share (NI)</button>
                        </div> 
                        TODO END -->
                    </div>
                    <div class="mt-2">
                        <b-form-checkbox :disabled="isLoadingPads" style="color:black;" v-model="showLegend">
                            Show Legend
                        </b-form-checkbox>
                    </div>
                </div>
            </div>
        </div>
        <div v-if="isLoadingPads">
            <div class="spinner-border spinner-border-sm" role="status" style="color:black; position: absolute; z-index: 100; top: 5%; left: 50%; margin-left: -5px;">
            </div>
        </div>
        <div class="d-flex flex-column flex-grow-1">
            <div v-if="isLoading">
                <div class="spinner-border spinner-border-sm" role="status" style="color:black; position: absolute; z-index: 100; top: 47%; left: 50%; margin-left: -5px;">
                    <span class="sr-only mb-5">Loading...</span>
                </div>
            </div>
            <div ref="settingsHeader" class="d-flex justify-content-left w-50">
                <div class="show-clicker-finger">
                    <i @click="returnToDefaultsMethod()" :disabled="isLoadingPads" class="fa fa-undo px-2" :class="{'icon-disabled': isLoadingPads}" v-tooltip:top="'Return to Default Axis Settings'" ></i>
                </div>
                <div>
                <div @click="openAxisOptionsModal()" class="show-clicker-finger">
                    <i :disabled="(isLoadingPads && !selectedChannels)" class="fas fa-cog px-2" :class="{'icon-disabled': isLoadingPads}" v-tooltip:top="'Y-axis Options'" ></i>
                </div>
            </div>
            <div>
                <analytic-options-modal
                @onDismiss="chartAxisModalVisible=false"
                @onApplyOptionChanges="applyOptionChanges"
                :modalVisible="chartAxisModalVisible"
                :modalData="axisOptionsModalData"
                :isSaving="isSaving"
                >    
                </analytic-options-modal>
            </div>
            </div>
            <div :style="chartStyle">
                <lightning-chart ref="lightningChart" :height="canvasHeight" :showLegend="showLegend" :selectedIndex="selectedIndex" :chartOptions="chartOptions"  :lockToMinChannelsIds="lockToMinChannelIds"/>
            </div>
        </div>
        <stage-comparison-settings-modal 
            v-show="!isLoadingPads"
            ref="settingsModal"
            :modalVisible="modalVisible" 
            :chartType="'SCD'" 
            :templateData="templateData" 
            :isAdmin="isAdmin"
            :userid="userid"
            @onDismiss="modalVisible = false" 
            @onLoadTemplate="loadedTemplate" 
            @onCreatedTemplate="createdTemplate"
            @onUpdatedTemplate="updatedTemplate"
            @onNewTemplate="newTemplate"
            @onDeleteTemplate="deleteChartTemplate"
            :dashboardInfo="dashboardInfo"
            :targetTemplateId="item.targetTemplateId ? item.targetTemplateId : null">
        </stage-comparison-settings-modal>
    </div>
</template>

<style>
    .btnSizeOverride {
        min-width: 125px !important;
        font-size: 0.85rem;
        padding: 4px 2px !important;
        margin: 3px 1px;
    }
    select{
        font-family: FontAwesome, sans-serif;
    }
    .popover {
        max-width: 50vw !important;
        background-color: #373A3C;
        padding: 0px;
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
    }
    .white-text {
        color: white;
    }
    #chartjs-tooltip {
        opacity: 1;
        text-align: left;
        position: absolute;
        background: rgba(0, 0, 0, .7);
        color: white;
        border-radius: 3px;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        pointer-events: none;
    }

    .chartjs-tooltip-key {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    #timeline-wrapper {
        position: relative;
        height: 2rem;
        text-align: center;
        z-index: 1;
    }

    .not-live-tracking {
        opacity: 0.25;
    }

    @keyframes warning {  
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }
    @-webkit-keyframes warning {  
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }

    @keyframes critical {  
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }
    @-webkit-keyframes critical {  
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }

    @keyframes failed {  
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    } 
    @-webkit-keyframes failed {  
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    } 
    .show-clicker-finger {
        cursor: pointer;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .filter-dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .show {
        display:block;
    }
    .font-90 {
        font-size: 90%!important;
    }
    .w-60 {
        width: 60%!important;
    }
    .w-40 {
        width:40%!important;
    }
    .controlBorder {
        border-color: black;
        border-style: solid;
        border-width: 2px;
    }
</style>

<script>
import moment from 'moment';
import _ from 'lodash';
import GlobalFunctions from '../../GlobalFunctions.js';
import ADXDownloadMixin from '../../mixins/ADXDownloadMixin.js';
import AnalyticsMixin from '../../mixins/AnalyticsMixin.js';
import {v4 as uuidv4} from 'uuid';

const MILLIS_PER_HR = 3600000;
const INITIAL_WINDOW_HRS = 3; //hours of data to load on startup
const MAX_WINDOW_HRS = 24; //maximum amount to show on screen at once
const MAX_DATA_POINTS = 2500; //maximum number of data points on the graph
const MAX_FILL_IN_PAGE_HRS = 3; //hours of data to retrieve at a time when panning or zooming out
const MIN_PAGE_SIZE_MILLIS = 5000; //minimum diff between start and end when getting more data
const MAX_SECTION_HEIGHT_PERCENTAGE = 1.2/4;
const HEADER_OFFSET = 125;
const BOTTOM_LABEL_INSET = 75;
const DEFAULT_AGGREGATE_VALUE = 'PT1S';

export default {
    inject: ['dashboardData','quickAddData'],
    created() {
        this.chartTypes = GlobalFunctions.analysisChartTypes();
    },
    mixins: [ADXDownloadMixin, AnalyticsMixin],
    data() {
        return {
            canvasHeight: this.chartHeight,
            defaultChannels: [],
            sortByJobStartDate: false,
            isReverseSort: false,
            isSaving: false,
            chartOptionsDefault: {
                channelOptions: {
                    id: null,
                    axisId: null,
                    show: true,
                    color: '#000000',
                    thickness: 2,
                    customAxisId: null,
                },
                stageBandOptions: {
                    wellId: null,
                    show: true,
                    opacity: 90
                },
                axisOptions: {
                    id: null,
                    showAxis: true,
                    label: null,
                    location: 'default',
                    autoFit: true,
                    axisMin: 0,
                    axisMax: 1000,
                    lockToZero: true,
                    returnToDefaults: false,
                    axisCreationType: 'default', //options are 'default', 'auto', 'custom'
                    channelId: null,
                    channels: [], //all channels attached to this axis,
                    unit: '',
                },
                allStageBandsToggle: true,
                allWellStagesOpacity: 90
            },
            lockToMinTags: ['wireline_1_line_speed'],
            chartAxisModalVisible: false,
            axisOptionsModalData: {},
            chartOptions: {
                channelOptions: {},
                axisOptions: {}
            },
            userDefaultTemplateData: null,
            refreshTicker: false,
            isLoading: false,
            isLoadingPads: true,
            isLoadingChannels: false,

            checkboxLiveData: false,
            modalVisible: false,

            templateData: {
                id: null,
                name: null,
                type: 'SCD',
                isUserDefault: false,
                loadLatestStage: false,
                data: {
                    jobs: [],
                    wells: [],
                    stages: [],
                    indexBy: null,
                    channels: [],
                    showLegend: true
                },
                lineConfigs: []
            },
            templateDataDefault: {
                id: null,
                name: 'New Template',
                type: 'SCD',
                isUserDefault: false,
                loadLatestStage: false,
                data: {
                    jobs: [],
                    wells: [],
                    stages: [],
                    indexBy: null,
                    channels: [],
                    showLegend: true
                },
                lineConfigs: []
            },

            filterOptionsDataIn: {},
            filterHeaderOffset: HEADER_OFFSET,
            settingsHeaderOffset: BOTTOM_LABEL_INSET,

            receivedPads: [],
            receivedWells: [],
            receivedStages: [],
            receivedIndices: [],
            receivedChannels: [],

            selectableWells: [],
            selectableStages: [],
            stageOptions: [], //a subset of selectable stages for stages that match the selected index
            wellStageIntervals: {},

            selectedPads: [],
            selectedWells: [],
            selectedStages: [],
            selectedIndices: [],
            selectedChannels: [],

            aggregateInterval: 'PT1S'
        };
    },
    props: {
        chartType: {
            type: Symbol,
            required: false
        },
        filterOptionsData: {
            type: Object,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            require: true
        },
        dashboardInfo: {
            type: Object
        },
        isAdmin: {
            type: [Boolean, Number]
        },
        userid: {
            type: [String]
        },
        userCompanyId: {
            type: String,
            required: true
        }
    },
    methods: {
        deleteChartTemplate: function() {
            const self = this;
            //need to delete template from db, and then change the local template back to the default
            const url = '/analysis/deleteTemplate';
            const data = {
                targetTemplateID: this.templateData.id
            };
            axios.post(url, data)
                .then(function (response) {
                    self.templateData = _.cloneDeep(self.templateDataDefault);
                    self.$refs.settingsModal.fetchAvailableTemplates();//refresh the template list
                    // Emit an event here to be captured by the analytics dashboard to set the appropriate component level template persistance option
                    self.$emit('updateDashboardItem', {
                        item: self.item, 
                        targetTemplateId: null
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        selectLatestStagesWhenNoTemplate() {
            if (this.isNoTemplateSelected && !this.isQuickAddComponent) {
                this.templateData.loadLatestStage = true;
                this.selectLatestStages();
            }
        },
        defaultSelectionsWhenNoTemplateSelected() {
            if (this.isNoTemplateSelected) {
                //default channel selection
                if (this.defaultChannels) {
                    this.channelsSelected(this.defaultChannels); //build channel and axis options
                }
                //default index selection
                let fracIndex = this.receivedIndices.filter(index => index.requestReason === "Place Frac");
                if (fracIndex[0]) {
                    this.indicesSelected(fracIndex[0]);
                } 
            }
        },
        applyOptionChanges(options) {
            const currentChannelOptions = this.chartOptions.channelOptions[options.channelOptions.id];
            //axis changed, lightningChart series data must be destroyed and re-created on another axis
            if (currentChannelOptions.axisId !== options.channelOptions.axisId) {
                options.channelOptions.hasAxisChanged = true;
            }

            this.chartOptions.channelOptions[options.channelOptions.id] = options.channelOptions;
            this.chartOptions.axisOptions = options.axisOptions;
            this.$forceUpdate(); //variables changed are nested, so change isn't seen until the next update
            this.$refs.lightningChart.applyOptionChanges();
            if (options.saveConfiguration) {
                this.saveConfiguration();
            }
        },
        returnToDefaultsMethod() {
            //return to defaults applies to all axes
            Object.values(this.chartOptions.axisOptions).forEach(option => {
                option.returnToDefaults = true
            })
            //update chart and reset:
            this.$forceUpdate(); 
            this.$refs.lightningChart.applyOptionChanges();
            Object.values(this.chartOptions.axisOptions).forEach(option => {
                option.returnToDefaults = false;
            })
        },
        saveConfiguration: function() {
            const url = '/analysis/saveTemplate';
            const self = this;

            //if there is no template yet for this chart then complete the first save through the template modal
            if (self.templateData.id == null || self.templateData.name == null) {
                self.modalVisible = true;
                return;
            }
            //save the time configuration to the template data
            self.templateData.data.chartOptions = self.chartOptions;
            const packetData = {
                _token: GlobalFunctions.getCSRFToken(),
                id: self.templateData.id,
                name: self.templateData.name,
                type: self.templateData.type,
                data: self.templateData.data,
                loadLatestStage: self.templateData?.loadLatestStage
            };
            self.isSaving = true;
            axios.post(url, packetData)
                .then(function (response) {
                    self.isSaving = false;
                    self.returnedDataset = response;
                    self.templateName = response.data.name;
                    self.templateData.name = response.data.name;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onResize() {
            // observers for when the div container for each comparison is resized
            // when the div container is resized, dynamically calculates the canvas chart height
            let filtersId = this.$refs.filtersHeader;
            let settingsId = this.$refs.settingsHeader;

            if (filtersId) {
                this.filterHeaderOffset = filtersId.clientHeight;
            }

            if (settingsId) {
                this.settingsHeaderOffset = settingsId.clientHeight;
            }

            this.canvasHeight = this.height - this.filterHeaderOffset - this.settingsHeaderOffset;
        },
        openAxisOptionsModal() {
            if (this.selectedChannels.length > 0) {           
                this.axisOptionsModalData = {
                    channel: this.selectedChannels[0],
                    selectedChannels: this.selectedChannels,
                    selectedChannelId: this.selectedChannels[0].id,
                    channelOptions: this.chartOptions.channelOptions,
                    axisOptions: this.chartOptions.axisOptions,
                    type: 'axis',
                    axisTemplate: this.chartOptionsDefault.axisOptions
                };
                this.chartAxisModalVisible=true;
            }
        },
        selectLatestStages: function() {       
            const self = this;
            if (this.templateData.loadLatestStage && this.selectedPads.length > 0 && this.selectedWells.length > 0 && self.selectedIndices[0]) {
                this.selectedStages = [];
                this.selectedPads.forEach(pad => {
                    self.selectedWells.forEach(well => {
                        if (well.job_id == pad.id) {
                            const latestStage =  self.getLastCompletedStageInterval(pad.name, well.index, self.selectedIndexRequestReason);
                            const selectedStage = self.selectableStages.find(stage => stage.stage = latestStage.stageNumber);
                            self.selectedStages.push(selectedStage);
                        }
                    });
                });
            }
        },
        //New stuff starts here
        selectAllWells: function() {
            this.selectedWells = [];
            this.selectableWells.forEach(well => {
                this.selectedWells.push(...well.valueArray);
            });
        },
        selectAllStages: function() {
            this.selectedStages = [];
            this.selectableStages.forEach(stage => {
                this.selectedStages.push(...stage.valueArray);
            });
        },
        updateStageOptions: function() {
            this.stageOptions = [];
            const self = this;
            if (this.selectedIndices && this.selectedIndices[0]) {
                this.selectableStages.forEach(stageGroup => {
                    const valueArray = stageGroup.valueArray.filter(
                        stage => stage.requestReason == this.selectedIndexRequestReason);
                    self.stageOptions.push({
                        subCatLabel: stageGroup.subCatLabel,
                        valueArray
                    });
                });
            } 
        },
        unpackFilterData: function() {
            if(this.filterOptionsDataIn.selectablePads != null) {
                this.receivedPads = this.filterOptionsDataIn.selectablePads;
            }
            if(this.filterOptionsDataIn.selectableWells != null) {
                this.receivedWells = this.filterOptionsDataIn.selectableWells;
                this.selectableWells = this.receivedWells;
            }
            if(this.filterOptionsDataIn.selectableStages != null) {
                this.receivedStages = this.filterOptionsDataIn.selectableStages;
            }
            if(this.filterOptionsDataIn.selectableIndices != null) {
                this.receivedIndices = this.filterOptionsDataIn.selectableIndices;
            }
            if(this.filterOptionsDataIn.selectableChannels != null) {
                this.receivedChannels = this.filterOptionsDataIn.selectableChannels;
            }
        },
        fetchFilterData: function() {
            //Should fetch filter information needed for this chart
            const url = '/analysis/getGhostChartFilterData/';
            const self = this;
            $.get(
                url,
                {
                    customerId: this.dashboardInfo.customer_id || this.userCompanyId,
                    indexBy: ['Place Frac', 'Place Wireline']
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                         self.receivedPads = result.selectablePads.sort((a,b)=>{
                            if (a.location < b.location) {return -1;}
                            if (a.location > b.location) {return 1;}
                            return 0;
                        });
                        self.receivedWells = result.selectableWells;
                        self.receivedStages = result.selectableStages;
                        self.wellStageIntervals = result.wellStageIntervals;
                        if (self.templateData?.data) {
                            self.setSelections(self.templateData.data);
                        }
                        self.$nextTick(() => {
                            if (self.isAutoDataLoadValid()) {
                                self.validateAndFetchData(false);
                            }    
                        }
                        );
                    }
                    self.isLoadingPads = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                //TODO: handle this
                }
            });
        },
        loadDataFromFilters: function() {
            //Should fetch and store the appropriate data here
            const self = this;

            const data = this.templateData.data;
            this.$refs.lightningChart.clearChartData();
            this.isLoading = true;

            //direct adx download
            data.jobs.forEach(pad => {
                $.get('/adxConfig/' + pad.name,
                    {},
                    (result) => {
                        const adxAccessToken = result.adxAccessToken;
                        const adxURL = result.adxUrl;
                        const adxDBName = result.adxDBName;

                        const wells = data.wells.filter(well => well.job_id === pad.id);
                        wells.forEach(well => {
                            let wellStages;
                            //find and load the latest stages for each well
                            if (self.templateData.loadLatestStage) {
                                const latestStage =  self.getLastCompletedStageInterval(pad.name, well.index, self.selectedIndexRequestReason);
                                wellStages = [{
                                    wellStageInterval: latestStage,
                                    stage: latestStage.stageNumber
                                }];
                            } else { //load manually selected stages
                                wellStages = data.stages.filter(stage => stage.well_id === well.id);
                            }
                            wellStages.forEach(stage => {
                                const stageIntervals = stage.wellStageInterval;
                                const fromDate = moment.utc(stageIntervals.startTime).valueOf();
                                const toDate = moment.utc(stageIntervals.endTime).valueOf();
                                self.downloadDataDirectFromADX(adxDBName, data.channels.map(channel => channel.name), pad.name,well.wellName, stage.stage, adxURL, adxAccessToken, fromDate, toDate, self.aggregateInterval, self.directADXDownloadDataHandler);
                            });
                        });
                    },
                    'json'
                ).fail(function( jqXHR, textStatus, errorThrown ) {
                    console.warn('ADX config failure', errorThrown, jqXHR.responseText);
                }); 
            });
        },
        directADXDownloadDataHandler(result, tags, jobNumber, wellName, stageNumber, startDate, endDate, interval, startTimeOffset = 0) {
            const self = this;
            if(result.status != 200) {
                console.warn(result);
            }else if(result.data) {

                const resultData = result.data;

                const data = this.adxDataParser(tags, resultData);
                for(let tagNameIndex in tags) {
                    let tagName = tags[tagNameIndex];
                    const processedData = [];
                    data[tagName].forEach(dataPoint => {
                        if (dataPoint.dataVal != null) {
                            const pointVal = {
                                'x': (dataPoint.dateTimestamp - startDate) / 1000, //convert to seconds, relative to index point
                                'y': dataPoint.dataVal
                            };
                            processedData.push(pointVal);
                        }
                    });
                    const targetChannel = {
                        'tagName': tagName,
                        'friendlyName': this.templateData.data.channels.find(channel => channel.name === tagName).prioritizedTagName,
                        'dataSet': processedData,
                        'errors': processedData.length === 0 ? ['No data is available for this tag on this well within the time frame'] : null
                    };
                    this.$refs.lightningChart.addDataToChart(jobNumber,wellName,stageNumber,targetChannel);
                }
            }
            this.isLoading = false;
        },
        getStageInterval(jobNumber,wellNumber,stageNumber, indexBy) {
            const activityType = indexBy == 'Place Frac' ? 'frac' : 'wireline';
            return this.wellStageIntervals[jobNumber][activityType].wellStageActivityData[wellNumber][stageNumber];
        },
        getLastCompletedStageInterval(jobNumber, wellNumber, indexBy) {
            const activityType = indexBy == 'Place Frac' ? 'frac' : 'wireline';
            return Object.values(this.wellStageIntervals[jobNumber][activityType].wellStageActivityData[wellNumber]).findLast(stage => 
                stage.endTime != null &&
                stage.startTime != null);
        },
        validateAndFetchData(showErrorMessages=true) {
            if (this.validateFilterSelections(showErrorMessages)) {
                this.loadDataFromFilters();
            }
        },
        validateFilterSelections: function(showErrorMessage=true) {
            //The template data and the filter option selections share the same data references,
            //however validating the template allows data to be fetched on page load before the filter
            //options are populated, provided that the template has all the valid data.
            const testData = this.templateData.data;
            let errorString = '';
            
            //Check at least one pad selected
            if(testData.jobs == null || testData.jobs.length == 0) {
                errorString = errorString + "\n" + "Please select at least one pad.";
            }
            
            //Check at least one well selected
            if(testData.wells == null || testData.wells.length == 0) {
                errorString = errorString + "\n" + "Please select at least one well per pad.";
            }
            
            //Check at least one well for every pad selected
            if(testData.jobs != null && testData.jobs.length != 0) {
                testData.jobs.forEach((pad) => {
                    //Should take the padNumber and check that at least one well exists for it
                    let target = null;
                    if(testData.wells != null && testData.wells.length != 0) {
                        target = testData.wells.filter(obj => {
                            return obj.job_id == pad.id;
                        })
                    }
                    if(target == null) {
                        errorString = errorString + "\n" + "Please select a well for pad " + pad.JobNumber;
                    }
                });
            }

            //Check at least one stage selected
            if(testData.stages == null || testData.stages.length == 0) {
                errorString = errorString + "\n" + "Please select at least one stage per well.";
            }
            //Check at least one stage for every well selected
            if(testData.wells != null && testData.wells.length != 0) {
                testData.wells.forEach((well) => {
                    //Should take the padNumber and check that at least one well exists for it
                    let target = null;
                    if(testData.stages != null && testData.stages.length != 0) {
                        target = testData.stages.filter(obj => {
                            return obj.well_id == well.id;
                        });
                    }
                    if(target == null) {
                        errorString = errorString + "\n" + "Please select a stage for well " + pad.JobNumber;
                    }
                });
            }
            
            //Check on indice is selected
            if(testData.indexBy == null || testData.indexBy.length == 0) {
                errorString = errorString + "\n" + "Please select an Index By point.";
            }

            //Check at least one channel selected
            if(testData.channels == null || testData.channels.length == 0) {
                errorString = errorString + "\n" + "Please select at least one channel.";
            }
            if(errorString != '') {
                errorString = "Please resolve the following issues before fetching data :" + errorString;
                if (showErrorMessage) {
                    alert(errorString);
                }            
                return false;
            }else{
                //data fetch is allowed
                return true;
            }
        },
        //Selection Handlers
        padsSelected: function(newValue) {
            this.selectedPads = newValue;
            this.selectLatestStagesWhenNoTemplate();
        },
        wellsSelected: function(newValue) {
            this.selectedWells = newValue;
            this.selectLatestStagesWhenNoTemplate();
        },
        stagesSelected: function(newValue) {
            this.selectedStages = newValue;
        },
        indicesSelected: function(newValue) {
            this.selectedIndices = [newValue];
            if (this.isNoTemplateSelected) {
                if (this.selectedIndices[0]) {
                    if (this.selectedIndices[0].requestReason === 'Place Frac') {
                        let fracDefaultChannels = this.defaultChannels.filter(channel => channel.type === 'frac');
                        this.channelsSelected(fracDefaultChannels); 
                    } else if (this.selectedIndices[0].requestReason === 'Place Wireline') {
                        let wirelineDefaultChannels = this.defaultChannels.filter(channel => channel.type === 'wireline');
                        this.channelsSelected(wirelineDefaultChannels); 
                    }
                }
            }
            this.selectLatestStagesWhenNoTemplate();
        },
        channelsSelected: function(newValue) {
            this.selectedChannels = newValue;
            //add new channel/axis options, remove old channel/axis options
            const self = this;
            this.selectedChannels.forEach(channel => {
                let options = self.chartOptions.channelOptions ? 
                    Object.values(self.chartOptions.channelOptions).find(obj => obj?.id != null && obj.id === channel?.id) : null;
                if (!options) {
                    options = {
                        ..._.cloneDeep(this.chartOptionsDefault.channelOptions),
                        color: channel?.color? channel.color : GlobalFunctions.getRandomColor('dark'),
                        id: channel.id,
                        show: true,
                        location: 'default',
                        unit: channel?.unit? channel.unit : '',
                        friendlyTagname: channel.friendlyTagname,
                        tagName: channel.name
                    };
                    if (channel?.id) {
                        self.chartOptions.channelOptions[channel.id] = options;
                    }
                }

                //create a generic auto axis for a tag to fall back to
                const autoAxis = Object.values(self.chartOptions.axisOptions).find(axis => axis.channelId == options.id && axis.axisCreationType == 'auto');
                if (!autoAxis) {
                    const newAxisId = uuidv4();
                    self.chartOptions.axisOptions[newAxisId] = {
                        ..._.cloneDeep(self.chartOptionsDefault.axisOptions),
                        id: newAxisId,
                        label: channel.prioritizedTagName? channel.prioritizedTagName: channel.friendlyTagname,
                        axisCreationType: 'auto',
                        channelId: channel.id,
                        tagName: channel.name
                    };
                    options.axisId = newAxisId;
                } else {
                    options.axisId = autoAxis.id;
                }
                //ensure axis has a unit for it
                const axis = Object.values(self.chartOptions.axisOptions).find(axis => axis.id == options.axisId);
                if (axis && (!axis?.unit || axis?.unit == '')) {
                    if (channel?.unit) {
                        axis.unit = channel.unit;
                    } else {
                        axis.unit = '';
                    }
                }
            });
            // remove auto/default axis options for channels that are no longer selected
            const channelIDs = new Set(this.selectedChannels.map(channel => {return channel.id;}));
            for (const axisId in this.chartOptions.axisOptions) {
                const axisOption = this.chartOptions.axisOptions[axisId];
                if (axisOption.axisCreationType == 'auto' && !channelIDs.has(axisOption.channelId)) {
                    delete this.chartOptions.axisOptions[axisId];
                }
                if (axisOption.axisCreationType == 'default' && !channelIDs.has(...axisOption.channels)) {
                    delete this.chartOptions.axisOptions[axisId];
                }
            }
        },
        //Selection Handlers ends here

        loadedTemplate: function(incomingTemplateData) {
            this.loadTemplate(incomingTemplateData);

            if (this.templateData.data.chartOptions) {
                this.chartOptions = this.templateData.data.chartOptions;
            }
            if (this.templateData.data.channels.length > 0) {
                this.channelsSelected(this.templateData.data.channels);//populate channel and axis options on load
            }   
            this.$nextTick(() => {
                if(this.isAutoDataLoadValid()) {
                    this.validateAndFetchData(false);//suppress error alert for incomplete/empty templates on page load
                }
            });
        },
        updatedTemplate: function(incomingTemplateData) {
            this.$emit('updateDashboardItem', {
                item : this.item, 
                targetTemplateId : incomingTemplateData.id,
                saveDashboard: true
            });
        },
        createdTemplate: function(incomingTemplateData) {
            //Takes the returned data and updated the local state so we now have id available for subsequent updates to the object
            this.loadTemplate(incomingTemplateData, true);
        },
        loadTemplate: function(incomingTemplateData, saveDashboard=false) {
            this.templateData.id = incomingTemplateData.id;
            this.templateData.name = incomingTemplateData.name;
            this.templateData.type = incomingTemplateData.type;
            this.templateData.isUserDefault = !!incomingTemplateData.is_user_default;
            this.templateData.loadLatestStage = !!incomingTemplateData.load_latest_stage;
            this.templateData.createdBy = incomingTemplateData.createdBy;
            this.templateData.data = JSON.parse(incomingTemplateData.data);

            // Emit an event here to be captured by the analytics dashboard to set the appropriate component level template persistance option
            this.$emit('updateDashboardItem', {
                item : this.item, 
                targetTemplateId : this.templateData.id,
                saveDashboard
            });
        },
        newTemplate: function() {
            //Cleared the current selections / sets it up as a new template
            this.templateData = _.cloneDeep(this.templateDataDefault);
            this.clearSelections();
        },
        clearSelections: function() {
            //Should clear all the current setting data
            this.selectedPads = [];
            this.selectedWells = [];
            this.selectedStages = [];
            this.selectedIndices = [];
            this.selectedChannels = [];
            this.aggregateInterval = DEFAULT_AGGREGATE_VALUE;
            this.refreshTicker = !this.refreshTicker;
        },
        setSelections: function(incomingTemplateData) {
            //Should set all of the active selection to the defined values
            this.selectedPads = incomingTemplateData.jobs || [];
            this.selectedWells = incomingTemplateData.wells || [];
            this.selectedStages = incomingTemplateData.stages || [];
            this.selectedIndices = incomingTemplateData.indexBy || [];
            this.selectedChannels = incomingTemplateData.channels || [];
            this.aggregateInterval = incomingTemplateData.aggregateInterval || DEFAULT_AGGREGATE_VALUE;
        },
        getDefaultChannels: function() {
            const url = '/analysis/getDefaultChannels';
            const self = this;
            $.get(
                url,
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.defaultChannels = result;
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        getChannelsForJobs: function(jobs) {
            const url = '/analysis/getChannelsForJobs';
            const self = this;

            this.isLoadingChannels = true;

            $.get(
                url,
                {
                    targetJobs : jobs
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.channelsSelected(self.selectedChannels);
                        const channels = [...result.defaultChannels,...result.selectableChannels];
                        //remove channel duplicates, shows the last duplicate found
                        self.receivedChannels = 
                            [...new Map(channels.map((channel) => [channel['name'], channel])).values()];
                    }
                    self.isLoadingChannels = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
                self.isLoadingChannels = false;
            });
        },
        quickAddComponentHandler(templateDefaults) {
            let selectedJobNumbers = null;
            let selectedWellIds = null;
            if (templateDefaults?.pads) {
                selectedJobNumbers = templateDefaults.pads.map(pad => pad.jobNumber);
                this.selectedPads = this.receivedPads.filter(pads => selectedJobNumbers.includes(pads.name));
                this.templateData.data.jobs = this.receivedPads.filter(pads => selectedJobNumbers.includes(pads.name));
                this.padsSelected(this.templateData.data.jobs)
            }
            
            if (templateDefaults?.wells && templateDefaults.wells.length > 0) {
                const validWells = this.receivedWells.filter(wellGroup => selectedJobNumbers.includes(wellGroup.subCatLabel))
                    .map(wellGroup => wellGroup.valueArray).flat();
                selectedWellIds = templateDefaults.wells.map(well => well.id);
                this.selectedWells = validWells.filter(well => selectedWellIds.includes(well.id));
            }

            this.selectedIndices = [this.receivedIndices.find(index => index.requestReason == templateDefaults.index)];
            if (!!templateDefaults.loadLatestStage && selectedWellIds && this.selectedIndices[0]) { //cast to bool in case of strings 'true', 'false'
                this.templateData.loadLatestStage = true;
                const validStages = this.receivedStages.filter(stageGroup => selectedWellIds.includes(stageGroup.well_id));
                const selectedStages = [];
                validStages.forEach(stageGroup => {
                    const maxCompletedStage = this.getLastCompletedStageInterval(stageGroup.jobNumber, stageGroup.wellNumber, this.selectedIndexRequestReason);
                    const lastStage = stageGroup.valueArray.findLast(
                        stage => stage.stage == maxCompletedStage.stageNumber && 
                        stage.requestReason == this.selectedIndexRequestReason);
                    selectedStages.push(lastStage);
                });
                this.selectedStages = selectedStages;
            }
            if (templateDefaults.channels) {
                this.templateData.data.channels = templateDefaults.channels;
                this.channelsSelected(this.templateData.data.channels); //build channel and axis options
            }
            this.aggregateInterval = templateDefaults.aggregateInterval;
            this.templateData.isUserDefault = true;
            //load chart data on the next tick so that the props passed to the lightning chart have time to update
            this.$nextTick(() => {
                if(this.isAutoDataLoadValid()) {
                    this.validateAndFetchData(false);//suppress error alert for incomplete/empty templates on page load
                }
                this.$refs.settingsModal.saveTemplate(); //save the default template values in the newly added chart
            });   
        },
        isAutoDataLoadValid() {
            //Gatekeeper function that determines if a chart can load data immediately during points of page load
            //charts that don't track to the latest stage and have stages are capable of loading data
            if (!this.templateData?.loadLatestStage && this.templateData?.data?.stages?.length > 0) {
                return true;
            } else if (this.templateData?.loadLatestStage && !this.isLoadingPads) {
                //charts that do track latest data are allowed to load if the filters have already retrieved their data
                return true;
            } else {
                return false;
            }
        }
    },
    computed: {
        isQuickAddComponent() {
            return Object.keys(this.quickAddData).length !== 0;
        },
        isNoTemplateSelected() {
            let templateInfo = this.templateData.data
            return !this.templateData.name || (!this.isQuickAddComponent && !templateInfo?.jobs && !templateInfo?.wells && !templateInfo?.indexBy && !templateInfo?.stages && !templateInfo?.channels)
        },
        lockToMinChannelIds() {
            let lockToMinChannels;
            this.lockToMinTags.forEach((tag) => {
                lockToMinChannels = this.selectedChannels.map((element) => {
                    if (element.name == tag) {
                        return element.id;
                    }
                })
            })
            return lockToMinChannels;
        },
        chartHeight() {
            return (this.height - HEADER_OFFSET - BOTTOM_LABEL_INSET);
        },
        chartStyle() {
            return {
                height: this.canvasHeight,
                overflow: 'hidden'
            };
        },
        dropdownHeight() {
            // returns the height to set the dropdown component to
            const extraPadding = 80;
            return this.chartHeight + extraPadding;
        },
        selectedIndex() {
            return this.templateData.data.indexBy && this.templateData.data.indexBy[0] ? this.templateData.data.indexBy[0] : {};
        },
        selectedIndexRequestReason() {
            return this.templateData.data.indexBy && this.templateData.data.indexBy[0] ? this.templateData.data.indexBy[0]?.requestReason : null;
        },
        showLegend: {
            get() {
                return this.templateData.data?.showLegend;
            },
            set(newValue) {
                this.templateData.data.showLegend = newValue;
            }
        }
    },
    watch: {
        receivedChannels: function(newVal, oldVal) {
            //If there is more selected channels then received channels for a given job, we filter them out.
            let selectedChannelNames = this.selectedChannels.map(channel => channel.name);
            let receivedChannelNames = this.receivedChannels.map(channel => channel.name);
            let validChannels = selectedChannelNames.filter(channel => receivedChannelNames.includes(channel));
            let newSelectedChannels = [];
            validChannels.forEach(validChannel => {
                let selectedChannels = this.selectedChannels.find(selectedChannel => selectedChannel.name == validChannel);
                newSelectedChannels.push(selectedChannels);
            })
            this.selectedChannels = newSelectedChannels;
        },
        selectedPads: function (jobs, oldJobs) { //remove unselectable wells
            //Should fetch the channels just for the currently selected jobs
            if(jobs.length != 0) {
                this.getChannelsForJobs(jobs.map(a => a.name));
            }

            //selectable wells based on selected pads
            const targetPads = jobs.map(a => a.name);
            const targetWells = this.receivedWells.filter((well) => {
                if(targetPads.includes(well.subCatLabel)) {
                    return well;
                }
            });
            this.selectableWells = targetWells;

            //unselect wells that cannot be selected
            const selectedJobIDs = jobs.map((job) => job.id);
            this.selectedWells = this.selectedWells.filter((well)=> selectedJobIDs.includes(well?.job_id));

            let wellsForJobs = [];
            if (this.selectedPads.length > 0) {
                this.selectableWells.forEach(wellInfo => {
                    wellsForJobs.push(wellInfo.valueArray);
                })
                this.selectedWells = wellsForJobs.flat();
            }
            this.templateData.data.jobs = jobs;
        },
        selectedWells: function (wells, oldVal) {
            //selectable stages based on selected wells
            const targetWells = wells.map(a => a.wellName);
            const targetStages = this.receivedStages.filter((stage) => {
                if(targetWells.includes(stage.wellName)) {
                    return stage;
                }
            });
            this.selectableStages = targetStages;

            //unselect stages that cannot be selected
            const selectedWellIDs = wells.map((well) => well.id );
            this.selectedStages = this.selectedStages.filter((stage)=> selectedWellIDs.includes(stage?.well_id));
            if (this.templateData.loadLatestStage) {
                this.selectLatestStages();
            }
            this.templateData.data.wells = wells;
        },
        selectedStages: function (stages, oldVal) {      
            const self = this;
            //get the start and end times for each selected stage
            if (this.wellStageIntervals && Object.values(this.wellStageIntervals).length > 0 &&
                this.selectedIndices && this.selectedIndices[0]) {
                stages.forEach(stage => {
                    stage.wellStageInterval = this.getStageInterval(stage.jobNumber, stage.wellNumber, stage.stage, self.selectedIndexRequestReason);
                });
            }
            this.templateData.data.stages = stages;
        },
        selectedIndices: function (indices, oldVal) {
            this.templateData.data.indexBy = indices;
            this.updateStageOptions();       
        },
        selectableStages: function(newVal, oldVal) {
            this.updateStageOptions();
        },
        selectedChannels: function (channels, oldVal) {
            this.templateData.data.channels = channels;
        },
        aggregateInterval: function(newVal, oldVal) {
            this.templateData.data.aggregateInterval = newVal;
        },
        isLoadingPads: function(newVal, oldVal) {
            this.defaultSelectionsWhenNoTemplateSelected();
            if (!newVal && this.userDefaultTemplateData) {
                this.quickAddComponentHandler(this.userDefaultTemplateData);
            }
            if (!newVal) {
                //Updates axis options when pads/data is loaded
                // const axisOptions = this.templateData.data.chartOptions.axisOptions;
                const axisOptions = this.templateData.data.chartOptions? this.templateData.data.chartOptions?.axisOptions: null;
                if (axisOptions) {
                    this.chartOptions.axisOptions = axisOptions;
                    this.$forceUpdate(); //variables changed are nested, so change isn't seen until the next update
                    this.$refs.lightningChart.applyOptionChanges();
                }
            }
        },
        quickAddData: {
            handler(newVal, oldVal) {
                if (this.item.i == this.quickAddData.targetItemId) {
                    this.userDefaultTemplateData = this.quickAddData.templateDefaults;
                    this.$emit('updateDashboardItem', {
                        item: this.item, 
                        targetTemplateId: null,
                        saveDashboard: true
                    });
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.getDefaultChannels();
        this.templateData.type = this.chartType;

        this.fetchFilterData();

        let resizeObject = new ResizeObserver(this.onResize);
        resizeObject.observe(this.$refs.containerHeight);

        //this.filterOptionsDataIn = this.filterOptionsData;
        //Temp code for testing below : expected final code above 
        // though may need to change how we are fetching it due to how customer dashboard items are added

        this.filterOptionsDataIn = {
            selectableIndices: [
                {id: 1, name: 'Place Frac on Well', requestReason: 'Place Frac'},
                {id: 2, name: 'Place Wireline on Well', requestReason: 'Place Wireline'}
            ],
            selectableChannels: [
                {id: 1, name: 'Treating Pressure (psi)', tagName: 'frac_3_pressure_treatmentMain1'},
                {id: 2, name: 'Clean Rate (bbl/min)', tagName: 'frac_3_rate_treatmentClean'},
                {id: 3, name: 'Slurry Rate (bbl/min)', tagName: 'frac_3_rate_treatmentSlurry'},
                {id: 4, name: 'Clean Volume Stage Total (bbl)', tagName: 'frac_3_totalStageVolActual_clean'},
                {id: 5, name: 'Proppant Loading (lb/gal)', tagName: 'frac_3_conc_slurryPropInline1'},
                {id: 6, name: 'Proppant Stage Total (lb)', tagName: 'frac_3_totalStageProppantActual_pumped'}
            ]
        };
        this.unpackFilterData();
    }
        
};
</script>

<style>
    .multiselect{
        border-color:black; 
        border-style:solid; 
        border-width:2px;
    }
    .selectlabel{
        color:black;
    }
</style>
