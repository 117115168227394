<template>
<div class="d-flex flex-column" style="width: 100%; height: 100%;">
    <div :id="'availability' + dashboardItem.i" style="width: 100%; height: 100%;"></div>
    <div class="curtain" :style="enabledStyle"></div>
</div>
</template>


<style>
    .tsi-availabilityChart.tsi-dark, .tsi-availabilityChart.tsi-dark .tsi-timePickerContainer, .tsi-availabilityChart.tsi-dark .tsi-timePickerContainer .tsi-timePickerChart {
        background-color: transparent;
    }

    .tsi-availabilityChart .tsi-zoomButtonContainer .tsi-zoomButtonIn, .tsi-availabilityChart .tsi-zoomButtonContainer .tsi-zoomButtonOut {
        filter: invert(1);
    }
    .curtain {
        position: relative;
        top: -160px;
        width: 100%; 
        height: 160px;
        z-index: 1;
    }
    .centerContent {
        display: flex;
        justify-content: center;
    }

</style>

<script>
import TsiClient from 'tsiclient';
import 'tsiclient/tsiclient.min.css';
import moment from 'moment';
const IWS_BLUE_COLOR = '#0275d8';
const DISABLED_COLOR = '#AAAAAA';
const MILLIS_PER_HR = 3600000;

export default {
    props: {
        dashboardItem: Object,
        minDate: Number,
        maxDate: Number,
        rightSideMsOffset: Number,
        ghostPlotXMax: Number,
        jobStart: Number,
        jobEnd: Number,
        onBrushMoved: Function,
        onBrushMoving: Function,
        onIsCompactChanged: Function,
        enabled: Boolean,
        brushMinMax: Object,
        jobHourOffset: Number,
        propTSIOptions: Object,
        defaultResolutionHours: Number,
        defaultZoomWindowHours: [Number,String]
    },
    data() {
        return {
            availabilityComponent: null,
            isCompact: true,
            controlColor: null,
            tsiOptions: {
                isCompact: null,
                resetZoomWindowHours: null
            },
            timeDisplayTimeoutId: null,
            documentHidden: false,
            isAvailabilityComponentSetUp: false
        };
    },
    watch: {
        'minDate': {
            handler: function(newValue, oldValue) {
                // console.log('minDate watch', newValue, oldValue)
                if (this.isAvailabilityComponentSetUp) {
                    this.availabilityComponent.setBrush(this.minDate, this.maxDate)
                }
            }
        },
        'maxDate': {
            handler: function(newValue, oldValue) {
                // console.log('maxDate watch', newValue, oldValue)
                if (this.isAvailabilityComponentSetUp) {
                    this.availabilityComponent.setBrush(this.minDate, this.maxDate)
                }
            }
        },
        'enabled': {
            handler: function(newValue, oldValue) {
                this.controlColor = newValue ? IWS_BLUE_COLOR : DISABLED_COLOR
                if (this.isAvailabilityComponentSetUp) {
                    this.renderAvailabilityComponent()
                }
            }
        },
        'brushMinMax': {
            handler: function(newValue, oldValue) {
                if (this.isAvailabilityComponentSetUp) {
                    if(newValue && newValue.minDate && newValue.maxDate) {
                        this.availabilityComponent.setBrush(newValue.minDate, newValue.maxDate)
                    }
                }
            }
        },
        hasJobData: {
            handler: function(newVal, oldVal) {
                if (newVal && !this.isAvailabilityComponentSetUp) {
                    this.setupAvailability();
                }
            }
        },
        defaultZoomWindowHours: {
            handler: function(newVal, oldVal) {
                this.tsiOptions.resetZoomWindowHours = newVal;
            }
        },
        tsiOptions: {
            deep: true,
            handler: function(newVal, oldVal) {
                this.$emit('onTSIOptionsChange', newVal);
            }
        }
    },
    created() {
	    document.addEventListener('visibilitychange', this.visibilityChange, false);
    },
    mounted()
    {
        //set chart tsi variables and set the first load live range to default hours if a value is not saved
        this.tsiOptions = Object.assign(
            this.tsiOptions, 
            this.propTSIOptions
        );

        //render the availability chart once per minute so the range.to value increases with current time
        this.controlColor = this.enabled ? IWS_BLUE_COLOR : DISABLED_COLOR
        if (this.hasJobData && !this.isAvailabilityComponentSetUp) {
            this.setupAvailability();
        }
    },
    computed: {
        enabledStyle() {
            if(this.enabled) {
                return {
                    'display': 'none'
                };
            }

            return {};
        },
        hasJobData() {
            return !!this.jobStart && (!!this.jobHourOffset || this.jobHourOffset === 0);
        }
    },
    methods: {
        setupAvailability() {
            let tsiClient = new TsiClient();
            tsiClient.utils.createTimezoneAbbreviation = () => {
                return ' Local ';
            };
            this.availabilityComponent = new tsiClient.ux.AvailabilityChart(document.getElementById('availability' + this.dashboardItem.i));
            this.renderAvailabilityComponent(true)
            this.isAvailabilityComponentSetUp = true;
            //set window range amount to default ranges
            this.availabilityComponent.setBrush(this.maxDate - this.hoursToMilliSeconds(this.defaultZoomWindowHours), this.maxDate);
            this.setResolutionSlider(this.maxDate - this.hoursToMilliSeconds(this.defaultResolutionHours),this.maxDate);
            setInterval(this.renderAvailabilityComponent, 1000 * 60);
        },
        renderAvailabilityComponent(isFirstRender) {
            if(!this.documentHidden && this.availabilityComponent && this.minDate && this.maxDate) {
                const newXMax = !this.jobEnd ? Math.max(this.ghostPlotXMax, Date.now()) : this.jobEnd;
                    this.availabilityComponent.render(
                        [{availabilityCount: {'': {}}}], 
                        {
                            legend: 'hidden', 
                            theme: 'dark', 
                            color: this.controlColor,
                            brushMoveEndAction: (from, to, timezone) => {
                                this.onBrushMoved && this.onBrushMoved(from, to, timezone);
                            }, 
                            brushMoveAction: (from, to) => {
                                this.onBrushMoving && this.onBrushMoving(from, to);
                                this.updateDateTimeDisplay('chartScrubber',from, to);
                            },
                            offset: (this.jobHourOffset)*60, //minutes
                            isCompact: this.isCompact, 
                            is24HourTime: false,
                            persistDateTimeButtonRange: false,
                            preserveAvailabilityState: true
                        }, 
                        {
                            range: 
                            {
                                from: new Date(this.jobStart).toISOString(), 
                                to: new Date(newXMax + this.rightSideMsOffset).toISOString()
                            }, 
                            intervalSize: '1h'
                        }
                    );
                if(isFirstRender) {
                    const timePickerTextContainers = $('.tsi-dateTimeContainer');
                    for (let i = 0; i < timePickerTextContainers.length; i++) {
                        //remove unwanted child elements of the container
                        timePickerTextContainers[i].innerHTML = '';
                    }
                    
                    const availabilityChartElement = document.getElementById('availability' + this.dashboardItem.i);
                    
                    //create a new element to show the scrubber date times during movement, but hide it after creation
                    const dateTimeDisplay = document.createElement('div');
                    dateTimeDisplay.setAttribute('id',`dateTimeDisplay${this.dashboardItem.i}`);
                    dateTimeDisplay.classList.add('centerContent');
                    dateTimeDisplay.style.visibility = 'hidden';
                    dateTimeDisplay.style.float = 'right';
                    dateTimeDisplay.style.fontSize = '1em';
                    availabilityChartElement.appendChild(dateTimeDisplay);

                    const button = document.createElement('toggleCompactButton' + this.dashboardItem.i);
                    button.id = 'toggleCompactButton' + this.dashboardItem.i;
                    const caret = document.createElement('i');
                    caret.classList.add('fas');
                    caret.classList.add('fa-caret-down');
                    caret.style = 'cursor: pointer;';
                    button.appendChild(caret);
                    button.addEventListener('click', () => {this.toggleCompact(caret);}, false);
                    button.style = 'position:absolute;right:20px;top:30px;';

                    availabilityChartElement.appendChild(button);

                    //Resize observer will moniter for changes in the width of the resolution selection bar
                    //this is a workaround to update the saved resolution values when they change, as watchers 
                    //dont seem to work effectively with the availabilityComponent object.
                    const selectionRectangle = availabilityChartElement
                        .getElementsByClassName('tsi-sparklineContainer')[0].getElementsByClassName('selection')[0];
                    new ResizeObserver(this.updateSelectedResolution).observe(selectionRectangle);
                }
            }
        },
        toggleCompact(caret) {
            this.isCompact = !this.isCompact;
            this.tsiOptions.isCompact = this.isCompact;
            if(this.isCompact) {
                caret.classList.remove('fa-caret-up');
                caret.classList.add('fa-caret-down');
            }
            else {
                caret.classList.remove('fa-caret-down');
                caret.classList.add('fa-caret-up');
            }

            this.onIsCompactChanged && this.onIsCompactChanged(this.isCompact);
            if (this.isAvailabilityComponentSetUp) {
                this.renderAvailabilityComponent();
            }
        },
        setBrushMinMax(minDate, maxDate) {
            if (this.isAvailabilityComponentSetUp) {
                this.availabilityComponent.setBrush(minDate, maxDate);
            }
        },
        updateSelectedResolution() {
            this.updateDateTimeDisplay('resolutionSlider',this.availabilityComponent.zoomedFromMillis, this.availabilityComponent.zoomedToMillis);
        },
        setResolutionSlider(fromMs, toMs) {
            if (this.isAvailabilityComponentSetUp) {
                this.$nextTick(() => {
                    this.availabilityComponent.zoomedFromMillis = fromMs;
                    this.availabilityComponent.zoomedToMillis = toMs;
                });
            }
        },
        hoursToMilliSeconds(hours) {
            return hours * 3.6e6;
        },
        milliSecondsToHours(ms) {
            return ms / 3.6e6;
        },
        updateDateTimeDisplay(source,minDate, maxDate) {
            //set the time display to show
            const dateTimeDisplay = document.getElementById(`dateTimeDisplay${this.dashboardItem.i}`);
            if(dateTimeDisplay){
                const from = moment(minDate).utcOffset(this.jobHourOffset).format('YYYY-MM-DD HH:mm:ss A');
                const to = moment(maxDate).utcOffset(this.jobHourOffset).format('YYYY-MM-DD HH:mm:ss A');
                dateTimeDisplay.style.visibility = 'visible';
                //update display values
                if (dateTimeDisplay && source == 'chartScrubber') {
                    dateTimeDisplay.innerHTML = `Selection : &nbsp;<div style="color:${IWS_BLUE_COLOR};">${from}</div> &nbsp; to &nbsp; <div style="color:${IWS_BLUE_COLOR};">${to}</div>`;
                }
                if (dateTimeDisplay && source == 'resolutionSlider') {
                    dateTimeDisplay.innerHTML = `Resolution : &nbsp;<div style="color:grey;">${from}</div> &nbsp; to &nbsp; <div style="color:grey;">${to}</div>`;
                }
                //after a short period of time hide the time display again
                if (this.timeDisplayTimeoutId) {
                    clearTimeout(this.timeDisplayTimeoutId);
                }
            }
        },
        visibilityChange(e) {
            this.documentHidden = document.hidden;
        },
        resetScrubberBrush() {
            let currentTimeMilliSeconds = Date.now();
            this.setResolutionSlider(currentTimeMilliSeconds - this.hoursToMilliSeconds(this.defaultResolutionHours), 
                currentTimeMilliSeconds);
        }
    }
};
</script>
