var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "my-modal",
    attrs: {
      size: "xl",
      "hide-header-close": "",
      "header-class": "border-bottom-0 justify-content-center  p-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "col w-100" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "font-large w-100 text-center" }, [
                  _vm._v(_vm._s(_vm.modalTitle)),
                ]),
              ]),
              _vm._v(" "),
              _vm.type === "shot"
                ? _c("div", { staticClass: "pt-4 pb-2 row w-100" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", { staticClass: "py-1" }, [
                        _vm._v("Well: " + _vm._s(_vm.wellName)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "py-1" }, [
                        _vm._v("Interval: " + _vm._s(_vm.stageNumber)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "py-1" }, [
                        _vm._v("Cluster number: " + _vm._s(_vm.gunNumber)),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "py-1" }, [
                        _vm._v(
                          "Cluster depth (ft): " +
                            _vm._s(_vm.wirelineDepth || "--")
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.gunNumber === 1
                      ? _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Spacing (ft): " +
                                _vm._s(_vm.actualSpacing || "--")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Minimum alert (ft): " +
                                _vm._s(_vm.minSpaceAllowed || "--")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Maximum alert (ft): " +
                                _vm._s(_vm.maxSpaceAllowed || "--")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Previous stage top depth(ft): " +
                                _vm._s(_vm.lastShotDepth || "--")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Variance from acceptable(ft): " +
                                _vm._s(_vm.calculateSpaceMismatch() || "--")
                            ),
                          ]),
                        ])
                      : _vm.gunNumber > 1
                      ? _c("div", { staticClass: "col" }, [
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Spacing (ft): " + _vm._s(_vm.actualSpacing)
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Minimum alert (ft): " +
                                _vm._s(_vm.minSpaceAllowed || "--")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Maximum alert (ft): " +
                                _vm._s(_vm.maxSpaceAllowed || "--")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Previous cluster top depth(ft): " +
                                _vm._s(_vm.lastShotDepth || "--")
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "py-1" }, [
                            _vm._v(
                              "Variance from acceptable(ft): " +
                                _vm._s(_vm.calculateSpaceMismatch() || "--")
                            ),
                          ]),
                        ])
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "label",
              { staticClass: "primary-txt", attrs: { for: "commentInput" } },
              [_vm._v("Please enter the comment below ")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.commentValue,
                  expression: "commentValue",
                },
              ],
              staticClass: "form-control div-border pre-formatted",
              attrs: { id: "textarea", rows: "6" },
              domProps: { value: _vm.commentValue },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.commentValue = $event.target.value
                },
              },
            }),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex w-100 pl-3 justify-content-center align-items-center",
              },
              [
                _vm.isAlert
                  ? _c(
                      "div",
                      { staticClass: "flex-shrink-1 justify-content-center" },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "row",
                            staticStyle: {
                              "justify-content": "center",
                              "align-items": "center",
                            },
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.isShotSheetReferenced,
                                  expression: "isShotSheetReferenced",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(
                                  _vm.isShotSheetReferenced
                                )
                                  ? _vm._i(_vm.isShotSheetReferenced, null) > -1
                                  : _vm.isShotSheetReferenced,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.isShotSheetReferenced,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.isShotSheetReferenced = $$a.concat(
                                          [$$v]
                                        ))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.isShotSheetReferenced = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.isShotSheetReferenced = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "px-2" }, [
                              _vm._v("Shot sheet referenced"),
                            ]),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "flex-grow-1" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary grey-button",
                    attrs: { type: "button" },
                    on: { click: () => _vm.changeModalVisibility(false) },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "px-2" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-success green-button",
                    attrs: { type: "button", disabled: _vm.isFetching },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.onSavePressed.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.isAlert ? "Resolve" : "Save comment") +
                          "\n                "
                      ),
                    ]),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }