var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "info-container" + _vm.componentId,
      staticClass: "flex-column text-center w-100",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center",
        },
        [
          _c("label", [
            _vm._v("Selected Truck:\n            "),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.selectedTruck,
                    expression: "selectedTruck",
                  },
                ],
                staticClass:
                  "w-100 h-100 form-control align-items-center justify-content-center",
                staticStyle: { "text-align-last": "center" },
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.selectedTruck = $event.target.multiple
                        ? $$selectedVal
                        : $$selectedVal[0]
                    },
                    function ($event) {
                      return _vm.getCurrentWirelineInfo(true)
                    },
                  ],
                },
              },
              _vm._l(_vm.wirelineSystems, function (wirelineSystem, index) {
                return _c(
                  "option",
                  { key: index, domProps: { value: wirelineSystem } },
                  [_vm._v(_vm._s(wirelineSystem.name))]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "awesome awesome-standard ash-bg btn-text d-flex align-items-center justify-content-center",
          staticStyle: { "line-height": "0.7", width: "100%", height: "58px" },
          on: { click: _vm.onViewReportPress },
        },
        [_c("span", [_vm._v("View Report")])]
      ),
      _vm._v(" "),
      _vm.displayLogEntries.length > 0
        ? _c(
            "div",
            {
              staticClass: "d-flex p-0 pt-2 overflow-auto",
              style: { height: _vm.logsHeight + "px" },
            },
            [_c("log-panel", { attrs: { logEntries: _vm.displayLogEntries } })],
            1
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }