<!--
    * Component Description
    Simple function for disabled crud actions within tables as coloured text links
-->

<template>
    <div style="float: right;">
        <span v-if="viewActions.edit !== false"
            class="table-row-action"
            :class="{ 'text-primary': enableActions.edit }"
            @click="$emit('edit')"
            :id="`edit_${id}`"
            :disabled="enableActions.edit" 
        >
            <i class="fas fa-pen"></i> Edit
        </span>
        <b-tooltip triggers="hover" :target="`edit_${id}`">
            Cannot edit, item is in use
        </b-tooltip>

        <span v-if="viewActions.view !== false"
            class="table-row-action"
            :class="{ 'text-primary': enableActions.view }"
            @click="$emit('view')"
            :id="`view_${id}`"
            :disabled="enableActions.view" 
        >
            <i class="far fa-eye"></i> View
        </span>
        <b-tooltip triggers="hover" :target="`view_${id}`">
            Cannot view, item is in use
        </b-tooltip>

        <span v-if="viewActions.delete !== false"
            class="table-row-action"
            :class="{ 'text-danger': enableActions.delete }"
            @click="$emit('delete')"
            :id="`delete_${id}`"
            :disabled="enableActions.delete" 
        >
            <i class="fas fa-trash-alt"></i> Delete
        </span>
        <b-tooltip triggers="hover" :target="`delete_${id}`">
            Cannot delete, item is in use
        </b-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        id: String | Number,

        // Control which actions are shown
        viewActions: {
            type: Object,
            default: () => {
                return {
                    edit: true,
                    view: true,
                    delete: true
                }
            }
        },

        // Control when each action is enable/disabled
        enableActions: {
            type: Object,
            default: () => {
                return {
                    edit: true,
                    view: true,
                    delete: true
                }
            }
        }
    }
};
</script>
