var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "my-modal",
    attrs: {
      size: "lg",
      "hide-header-close": "",
      "header-class": "border-bottom-0 justify-content-center  pt-4",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg px-4",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "font-large w-100 text-center" }, [
              _vm._v("Spacing Alerts"),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass:
                    "col-3 d-flex justify-content-end align-items-center",
                },
                [_vm._v("\n                Template type\n            ")]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "col-9" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.templateType,
                        expression: "templateType",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.templateType = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        _vm.onDropdownChange,
                      ],
                    },
                  },
                  _vm._l(_vm.templateOptions, function (option, index) {
                    return _c(
                      "option",
                      {
                        key: index,
                        attrs: { disabled: option.disabled },
                        domProps: { value: option.value },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option.text) +
                            "\n                    "
                        ),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]),
            _vm._v(" "),
            !_vm.isCompanyConfig
              ? _c("div", { staticClass: "row py-4" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "col-3 d-flex justify-content-end align-items-center",
                    },
                    [_vm._v("\n                Well\n            ")]
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "col-9" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedWell,
                            expression: "selectedWell",
                          },
                        ],
                        staticClass: "form-control",
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedWell = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            _vm.onDropdownChange,
                          ],
                        },
                      },
                      _vm._l(_vm.wellOptions, function (option, index) {
                        return _c(
                          "option",
                          { key: index, domProps: { value: option.value } },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(option.text) +
                                "\n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c("table", { staticClass: "table table-borderless" }, [
                _c("thead", [
                  _c("tr", [
                    _c("th", { staticClass: "col-3", attrs: { scope: "col" } }),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "col-3 text-center text-white",
                        attrs: { scope: "col" },
                      },
                      [_vm._v("-ve")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "col-3 text-center text-white",
                        attrs: { scope: "col" },
                      },
                      [_vm._v("Spacing")]
                    ),
                    _vm._v(" "),
                    _c(
                      "th",
                      {
                        staticClass: "col-3 text-center text-white",
                        attrs: { scope: "col" },
                      },
                      [_vm._v("+ve")]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("tbody", [
                  _c("tr", [
                    _c("th", { staticClass: "text-right text-white" }, [
                      _vm._v("Cluster"),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.clusterMin,
                            expression: "clusterMin",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          min: "-9999",
                          max: "0",
                          onfocusout: "validity.valid||(value='0')",
                          type: "number",
                          disabled: _vm.isViewOnly,
                        },
                        domProps: { value: _vm.clusterMin },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.clusterMin = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.clusterSpacing,
                            expression: "clusterSpacing",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          min: "0",
                          onfocusout: "validity.valid||(value='0')",
                          type: "number",
                          disabled: _vm.isViewOnly,
                        },
                        domProps: { value: _vm.clusterSpacing },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.clusterSpacing = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.clusterMax,
                            expression: "clusterMax",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          min: "0",
                          onfocusout: "validity.valid||(value='0')",
                          type: "number",
                          disabled: _vm.isViewOnly,
                        },
                        domProps: { value: _vm.clusterMax },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.clusterMax = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td"),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-white range",
                        attrs: { colspan: "3", align: "center" },
                      },
                      [
                        _vm._v(
                          "\n                            Range: " +
                            _vm._s(
                              +_vm.clusterSpacing +
                                _vm.getNumber(_vm.clusterMin)
                            ) +
                            " to " +
                            _vm._s(
                              +_vm.clusterSpacing +
                                _vm.getNumber(_vm.clusterMax)
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("th", { staticClass: "text-right text-white" }, [
                      _vm._v("Stage"),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.stageMin,
                            expression: "stageMin",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          min: "-9999",
                          max: "0",
                          onfocusout: "validity.valid||(value='0')",
                          type: "number",
                          disabled: _vm.isViewOnly,
                        },
                        domProps: { value: _vm.stageMin },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.stageMin = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.stageSpacing,
                            expression: "stageSpacing",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          min: "0",
                          onfocusout: "validity.valid||(value='0')",
                          type: "number",
                          disabled: _vm.isViewOnly,
                        },
                        domProps: { value: _vm.stageSpacing },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.stageSpacing = $event.target.value
                          },
                        },
                      }),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.stageMax,
                            expression: "stageMax",
                          },
                        ],
                        staticClass: "form-control",
                        attrs: {
                          min: "0",
                          onfocusout: "validity.valid||(value='0')",
                          type: "number",
                          disabled: _vm.isViewOnly,
                        },
                        domProps: { value: _vm.stageMax },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.stageMax = $event.target.value
                          },
                        },
                      }),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("tr", [
                    _c("td"),
                    _vm._v(" "),
                    _c(
                      "td",
                      {
                        staticClass: "text-white range",
                        attrs: { colspan: "3", align: "center" },
                      },
                      [
                        _vm._v(
                          "\n                            Range: " +
                            _vm._s(
                              +_vm.stageSpacing + _vm.getNumber(_vm.stageMin)
                            ) +
                            " to " +
                            _vm._s(
                              +_vm.stageSpacing + _vm.getNumber(_vm.stageMax)
                            ) +
                            "\n                        "
                        ),
                      ]
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "modal-footer d-flex flex-row",
                staticStyle: { "border-top-width": "0px" },
              },
              [
                _c("div", { staticClass: "d-flex flex-grow-1" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary grey-button",
                    attrs: { type: "button", "data-dismiss": "modal" },
                    on: { click: () => _vm.changeModalVisibility(false) },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                !_vm.isCompanyConfig
                  ? _c("div", [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success green-button",
                          attrs: { type: "button", disabled: _vm.isViewOnly },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onSavePressed(true)
                            },
                          },
                        },
                        [_vm._v("Save All Wells")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-success green-button",
                          attrs: { type: "button", disabled: _vm.isViewOnly },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.onSavePressed()
                            },
                          },
                        },
                        [_vm._v("Save This Well")]
                      ),
                    ])
                  : _c(
                      "button",
                      {
                        staticClass: "btn btn-success green-button",
                        attrs: { type: "button", disabled: _vm.isViewOnly },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.onSavePressed()
                          },
                        },
                      },
                      [_vm._v("Save")]
                    ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }