<template>
    <div class="pb-5">
        <iws-breadcrumbs class="mt-4 mb-1"
            :path="[{
                title: 'Home',
                link: '/home'
            }, {
                title: 'Create Job',
                link: '/jobs/create'
            }]"
        />
        <div class="page-title mb-4">
            Create Job
        </div>

        <div class="row">
            <div class="col-12 col-lg form-group">
                <label v-if="action=='create'">Job Number (must start with A-Z, upper or lower case)<span style="color:red;">*</span> <span v-if="action!='create'" style="color:red;">The Job Number cannot be changed!</span></label>
                <label v-else>Job Number <span style="color:red;">*</span> <span v-if="action!='create'" style="color:red;">The Job Number cannot be changed!</span></label>
                <input type="text" v-model="jobNumber" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('jobNumber') > -1}" :disabled="action!='create'? true : false">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('jobNumber') > -1" >{{ errors['jobNumber'] ? errors['jobNumber'][0] : undefined }}</div>
            </div>
            <div class="col-12 col-lg form-group">
                <label>Pad location/name <span style="color:red;">*</span></label>
                <input type="text" v-model="location" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('location') > -1}" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('location') > -1" >{{ errors['location'] ? errors['location'][0] : undefined }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg form-group">
                <label>System name (example: FS02) <span style="color:red;">*</span></label>
                <input type="text" v-model="system" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('system') > -1}" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('system') > -1" >{{ errors['system'] ? errors['system'][0] : undefined }}</div>
            </div>
            <!-- <div class="col-12 col-lg form-group">
                <label>Database (example: HMI001)</label>
                <input type="text" v-model="database" class="form-control">
            </div> -->
        </div>
        <div class="row">
            <div class="col-12 col-lg form-group">
                <label>Latitude <span style="color:red;">*</span></label>
                <input type="text" v-model="latitude" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('latitude') > -1}" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('latitude') > -1" >{{ errors['latitude'] ? errors['latitude'][0] : undefined }}</div>
            </div>
            <div class="col-12 col-lg form-group">
                <label>Longitude <span style="color:red;">*</span></label>
                <input type="text" v-model="longitude" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('longitude') > -1}" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('longitude') > -1" >{{ errors['longitude'] ? errors['longitude'][0] : undefined }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg form-group">
                <label>Projected start</label>
                <input type="datetime-local" v-model="scheduledStart" step="1" placeholder="yyyy-mm-dd hh:mm:ss" :disabled="isReadOnly()"
                    :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('scheduledStart') > -1}">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('scheduledStart') > -1" >{{ errors['scheduledStart'] ? errors['scheduledStart'][0] : undefined }}</div>
            </div>
            <div class="col-12 col-lg form-group">
                <label>Projected end</label>
                <input type="datetime-local" v-model="scheduledEnd" step="1" placeholder="yyyy-mm-dd hh:mm:ss" class="form-control" :disabled="isReadOnly()"> 
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('scheduledEnd') > -1" >{{ errors['scheduledEnd'] ? errors['scheduledEnd'][0] : undefined }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg form-group">
                <label>Actual start</label>
                <input type="datetime-local" v-model="start" step="1" placeholder="yyyy-mm-dd hh:mm:ss" class="form-control text-secondary" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('start') > -1" >{{ errors['start'] ? errors['start'][0] : undefined }}</div>
            </div>
            <div class="col-12 col-lg form-group">
                <label>Actual end</label>
                <input type="datetime-local" v-model="end" step="1" placeholder="yyyy-mm-dd hh:mm:ss" class="form-control text-secondary" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('end') > -1" >{{ errors['end'] ? errors['end'][0] : undefined }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg form-group">
                <label>Shift start (example: 5.5 is 5:30am and 5:30pm) <span style="color:red;">*</span></label>
                <input type="number" v-model="shiftStart" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('shiftStart') > -1}" @input="handleShiftInput" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('shiftStart') > -1" >{{ errors['shiftStart'] ? errors['shiftStart'][0] : undefined }}</div>
            </div>
            <div class="col-12 col-lg form-group">
                <label>Hour offset to UTC (example: -6 for Alberta in summer) <span style="color:red;">*</span></label>
                <input type="number" v-model="hourOffset" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('hourOffset') > -1}" @input="handleOffsetInput" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('hourOffset') > -1" >{{ errors['hourOffset'] ? errors['hourOffset'][0] : undefined }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-6 form-group">
                <label>Projected completed stages (per day)<span style="color:red;">*</span></label>
                <input type="number" v-model="stagesCompletedProjection" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('stagesCompletedProjection') > -1}" @input="handleProjectedCompletedStagesInput" :disabled="isReadOnly()">
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('stagesCompletedProjection') > -1" >{{ errors['stagesCompletedProjection'] ? errors['stagesCompletedProjection'][0] : undefined }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 col-lg form-group">
                <label>Company <span style="color:red;">*</span></label>
                <select id="company" v-model="company" :class="{'form-control': true, 'is-invalid': Object.keys(errors).indexOf('company') > -1}" @input="handleCompanyInput" :disabled="isReadOnly()">
                    <option v-for="cust in customers" :value="cust.id" :key="cust.id">{{cust.name}}</option>
                </select>
                <div class="invalid-feedback" v-if="Object.keys(errors).indexOf('company') > -1" >{{ errors['company'] ? errors['company'][0] : undefined }}</div>
            </div>
            <div class="col-12 col-lg form-group">
                <label>Technician (daytime)</label>
                <select id="techUserDaytime" v-model="techUserDaytime" class="form-control">
                    <option value="">None</option>
                    <option v-for="user in techUsers" :value="user.id" :key="user.id">{{user.username}}</option>
                </select>
            </div>
            <div class="col-12 col-lg form-group">
                <label>Technician (night)</label>
                <select id="techUserNight" v-model="techUserNight" class="form-control">
                    <option value="">None</option>
                    <option v-for="user in techUsers" :value="user.id" :key="user.id">{{user.username}}</option>
                </select>
            </div>
        </div>
        <hr class="bg-secondary">
        <div class="d-flex justify-content-between pb-1">
            <h3>Job site contractors</h3>
            <button class="btn btn-primary" @click="addContractor">Add Contractor</button>
        </div>
        <div v-if="assignedContractors.length == 0">
            No contractors
        </div>
        <ul v-else class="list-group pt-1">
            <li v-for="(assignedContractor, index) in assignedContractors" :key="index" class="list-group-item d-flex align-items-center">
                <div class="d-flex flex-grow-1">
                    <select id="contractorType" v-model="assignedContractor.id" class="form-control mr-1">
                        <option value="-1" disabled>Select one...</option>
                        <option v-for="(contractor, jndex) in contractorsForSelectedCustomer" :key="jndex" :value="contractor.id">{{contractor.name}}, {{contractor.type}}</option>
                    </select>
                </div>
                <input type="checkbox" class="mx-2 my-1" style="width: 30px; height: 30px;" v-model="assignedContractor.isDefault">
                <label class="mr-5 my-1 text-secondary">
                Default</label>
                <button class="btn btn-primary ml-auto" @click="deleteContractor(index)">X</button>
            </li>
        </ul>
        <hr class="bg-secondary">
        <div class="d-flex justify-content-between pb-1">
            <h3>Site status messages</h3>
            <button class="btn btn-primary" @click="addMessage" :disabled="isReadOnly()">Add Message</button>
        </div>
        <div v-if="messages.length == 0">
            No messages
        </div>
        <ul v-else class="list-group pt-1">
            <li v-for="(message, index) in messages" :key="index" class="list-group-item d-flex justify-content-between align-items-center">
                <div class="d-flex flex-grow-1">
                    <input type="text" class="form-control mr-1" placeholder="Title" v-model="message.header">
                    <input type="text" class="form-control mr-1" placeholder="Message" v-model="message.body">
                </div>
                <button class="btn btn-primary" @click="deleteMessage(index)">X</button>
            </li>
        </ul>
        <div>
        <hr class="bg-secondary">
            <div class="d-flex justify-content-between pb-1">
                <h3>Wells</h3>
                <button v-if="wellCountEditable" class="btn btn-primary" @click="addWell">Add Well</button>
                <div v-else>Wells can no longer be added or removed</div>
            </div>
            <div class="row">
                <well-setup-cloud-component class="col-6 col-lg-3" v-for="(well, index) in wells" 
                    :wellInfo="well" @well="updateWellInfo" :index="index" @delete="deleteWell"
                    :key="index" @order="changeWellOrder" :allowEdit="wellCountEditable"
                    :wellCount="wells.length" :wellConfigs="wellConfigs" :isReadOnly="isReadOnly">
                </well-setup-cloud-component>
            </div>
        </div>
        <button class="btn btn-primary float-right my-5" v-if="action=='create'" :disabled="busy" @click="newJob">
            <b-spinner small v-if="busy" class="mr-1"></b-spinner> Create new job
        </button>
        <button class="btn btn-primary float-right my-5" v-else :disabled="busy" @click="updateJob">
            <b-spinner small v-if="busy" class="mr-1"></b-spinner> Save job
        </button>
        <button v-if="showLatestRemoteDataButton" v-tooltip:top="`Warning: This feature only works on remote copied jobs`" 
        class="btn btn-outline-primary float-right my-5 mr-2 ml-2 pr-2 pl-2" :disabled="busy" @click="getLatestData">
            <b-spinner small v-if="busy" class="mr-1"></b-spinner> Get Latest Data For Job
        </button>

        
        <label class="float-right p-5" v-if="jobUpdatedSuccessfully"><span style="color:#00ff00;">Job was saved successfully, redirecting in {{redirectCounter}} seconds.</span></label>
        <label class="float-right p-5" v-if="jobCreatedSuccessfully"><span style="color:#00ff00;">Job was created successfully, redirecting in {{redirectCounter}} seconds.</span></label>
    </div>
</template>
<script>
    import colorPicker from '@caohenghu/vue-colorpicker';

    const DEFAULT_LOCATION = 'Unknown';
    const DEFAULT_LAT = 0;
    const DEFAULT_LON = 0;
    const DEFAULT_SHIFT_START = 5.5;
    const DEFAULT_HOUR_OFFSET = -6;
    const DEFAULT_TOTAL_STAGES = 100;

    // register modal component
    Vue.component('modal', {
        template: '#modal-template'
    })

    export default {
        components: {
            colorPicker
        },
        data() {
            return {
                jobNumber: this.initialJobNumber,
                location: this.initialLocation,
                system: this.initialSystem,
                database: this.initialDatabase,
                latitude: this.initialLatitude,
                longitude: this.initialLongitude,
                start: this.initialStart ? this.initialStart.replace(" ", "T") : null,
                end: this.initialEnd ? this.initialEnd.replace(" ", "T") : null,
                scheduledStart: this.initialScheduledStart ? this.initialScheduledStart.replace(" ", "T") : null,
                scheduledEnd: this.initialScheduledEnd ? this.initialScheduledEnd.replace(" ", "T") : null,
                company: this.initialCompany,
                assignedContractors: this.initialAssignedContractors ? this.convertContractorsArray(this.initialAssignedContractors) : [],
                techUserDaytime: this.initialDayTech,
                techUserNight: this.initialNightTech,
                usersArray: [],
                shiftStart: this.initialShiftStart,
                hourOffset: this.initialHourOffset,
                stagesCompletedProjection: this.initialProjectedCompletedStages,
                messages: this.initialMessages ? this.initialMessages : [],
                color: "#000000",
                wells: this.initialWells ? this.initialWells.sort(function(a, b) { return a.index - b.index; }) : [],
                errors: [],
                busy: false,
                jobUpdatedSuccessfully: false,
                jobCreatedSuccessfully: false,
                redirectCounter: 3,
            }
        },
        computed: {
            showLatestRemoteDataButton: function() {
                return (this.action == "edit") && this.isRemoteJob;
            },
            contractorsForSelectedCustomer: function () {
                return this.contractors.filter((contractor) => contractor.customer_id == this.company);
            },
            totalStages: function () {
                if(!this.wells) {
                    return null;
                }

                let t = 0;
                for (let i = 0; i < this.wells.length; i++) {
                    t = t + parseInt(this.wells[i].numberOfStages);
                }
                return t;
            },
            jobData: function () {
                return {
                        "_token": this.csrfToken,
                        "jobNumber": this.jobNumber,
                        location: this.location,
                        system: this.system,
                        database: this.database,
                        latitude: this.latitude,
                        longitude: this.longitude,
                        start: this.start,
                        end: this.end,
                        scheduledStart: this.scheduledStart,
                        scheduledEnd: this.scheduledEnd,
                        wells: this.wells,
                        company: this.company,
                        techUserDaytime: this.techUserDaytime,
                        techUserNight: this.techUserNight,
                        shiftStart: this.shiftStart,
                        totalStages: this.totalStages,
                        hourOffset: this.hourOffset,
                        messages: this.messages,
                        assignedContractors: this.assignedContractors,
                        stagesCompletedProjection: this.stagesCompletedProjection,
                    };
            }
        },
        props: [
            'newJobData',
            'isRemoteJob',
            'wellDetailsForJob',
            'isDebug',
            'initialJobNumber', 
            'action', 
            'csrfToken', 
            'initialLocation', 
            'initialSystem', 
            'initialDatabase',
            'initialLatitude', 
            'initialLongitude', 
            'initialStart', 
            'initialEnd', 
            'initialTotalStages', 
            'initialShiftStart', 
            'wellConfigs',
            'initialScheduledStart', 
            'initialScheduledEnd', 
            'initialCompany', 
            'initialEnd', 
            'customers', 
            'contractors', 
            'initialAssignedContractors',
            'initialHourOffset', 
            'initialMessages', 
            'initialDayTech', 
            'initialNightTech', 
            'initialWells', 
            'techUsers', 
            'wellCountEditable',
            'initialProjectedCompletedStages',
            'input',
            'clusterConfigByCompany'
        ],
        mounted() {
            this.darkMode = this.$root.darkMode;
            if(this.initialProjectedCompletedStages == null){
                this.stagesCompletedProjection = 9.5;
            }
            
            if(this.input) {
                //parameters are being passed in to be prefilled
                this.jobNumber = this.input.jobNumber;
                this.system = this.input.systemNumber;
                this.location = this.input.location ?? DEFAULT_LOCATION;
                this.latitude = this.input.latitude ?? DEFAULT_LAT;
                this.longitude = this.input.longitude ?? DEFAULT_LON;
                this.scheduledStart = this.input.scheduledStart? this.input.scheduledStart: null;
                this.start = this.input.start ?? (new Date()).toLocaleString('sv').replace(" ", "T");
                this.end = this.input.end ? this.input.end : null;
                this.shiftStart = this.input.shiftStart ?? DEFAULT_SHIFT_START;
                this.hourOffset = this.input.hourOffset ?? DEFAULT_HOUR_OFFSET;
                this.company = this.input.company;
                
                if (this.wellDetailsForJob?.length){
                    this.wells = [...this.wellDetailsForJob];
                } else {
                    this.wells = [];
                    for(let i = 0; i < this.input.wellCount ?? 0; i++) {
                        const randomColor = Math.floor(Math.random()*16777215).toString(16);
                        this.wells[i] = {
                            name: this.input['well'+i+'Name'],
                            nameLong: this.input['well'+i+'Name'],
                            color: "#" + randomColor,
                            numberOfStages: DEFAULT_TOTAL_STAGES,
                            index: i
                        }
                    }
                }
            }
        },
        methods: {
            isReadOnly: function(data) {
                return this.action != "create" && !this.isDebug;
            },
            redirectPage: function() {
                let self = this;
                let iTimerId = setInterval(function () {
                    self.redirectCounter--;
                    if (self.redirectCounter === 0) {
                        // now, redirect page.
                        window.location.href = "/home";
                        clearInterval(iTimerId);		// clear time interval.
                    }
                }, 1000);
            },
            updateWellInfo: function(data){
                Vue.set(this.wells[data.index], data.name, data.value);
            },
            changeColor(color) {
                const { r, g, b, a } = color.rgba;
                this.color = `rgba(${r}, ${g}, ${b}, ${a})`;
            },
            openSucker(isOpen) {
                if (isOpen) {
                    // ... canvas be created
                    this.suckerCanvas = canvas
                    this.suckerArea = [x1, y1, x2, y2]
                } else {
                    this.suckerCanvas && this.suckerCanvas.remove
                }
            },
            handleShiftInput(input) {
                let newValue = input.target.value;
                if (newValue < 0 ) {
                    newValue = 0;
                } else if (newValue >= 13) {
                    newValue = 12.5;
                }

                this.shiftStart = newValue;
            },
            handleOffsetInput(input) {
                //Based on https://en.wikipedia.org/wiki/List_of_UTC_time_offsets
                let newValue = input.target.value;
                if (newValue < -12 ) {
                    newValue = -12;
                } else if (newValue >= 14) {
                    newValue = 14;
                }

                this.hourOffset = newValue;
            },
            handleCompanyInput(input){
                const targetCustomerKpiSettings = this.customers.find((c) => c.id == input.target.value).customerkpisettings;

                if(targetCustomerKpiSettings && targetCustomerKpiSettings.stagesCompletedProjection){
                    this.stagesCompletedProjection = targetCustomerKpiSettings.stagesCompletedProjection;
                }else{
                    this.stagesCompletedProjection = 9.5;
                }

                this.company = input.target.value;
            },
            handleProjectedCompletedStagesInput(input) {
                let newValue = input.target.value;
                if (newValue < 0 ) {
                    newValue = 0;
                }
                this.stagesCompletedProjection = newValue;
            },
            deleteWell(index){
                this.wells.splice(index,1);
            },
            addWell(){
                var well = {}
                var randomColor = Math.floor(Math.random()*16777215).toString(16);
                well.color="#" + randomColor;
                if(this.company && this.clusterConfigByCompany && this.clusterConfigByCompany[this.company]) {
                    const clusterInfo = JSON.parse(this.clusterConfigByCompany[this.company].cluster_info);
                    well.clusterSpacing = clusterInfo.clusterSpacing;
                    well.clusterSpacingPositive = clusterInfo.clusterPositiveVariance;
                    well.clusterSpacingNegative = clusterInfo.clusterNegativeVariance;
                    well.stageSpacing = clusterInfo.stageSpacing;
                    well.stageSpacingPositive = clusterInfo.stagePositiveVariance;
                    well.stageSpacingNegative = clusterInfo.stageNegativeVariance;
                }
                this.wells.push(well);
            },
            deleteMessage(index){
                this.messages.splice(index,1);
            },
            addMessage(){
                let message = {}
                message.header = "";
                message.body = "";
                message.type = "whiteboard";
                this.messages.push(message);
            },
            deleteContractor(index){
                this.assignedContractors.splice(index,1);
            },
            addContractor(){
                let contractor = {}
                contractor.id = -1;
                contractor.isDefault = false;
                this.assignedContractors.push(contractor);
            },
            convertContractorsArray(assignedContractorsWithPivot) {
                let retArray = [];
                for(let index in assignedContractorsWithPivot) {
                    let assignedContractorWithPivot = assignedContractorsWithPivot[index];
                    let contractor = {}
                    contractor.id = assignedContractorWithPivot.pivot.contractor_id;
                    contractor.isDefault = assignedContractorWithPivot.default;
                    retArray.push(contractor);
                }

                return retArray;
            },
            changeWellOrder(data){
                //alert("Change well order with command: "+data.command+" for index: "+data.index);
                if(data.command == 'moveUp' && data.index > 0){
                    //alert("will move the item up");
                    var well = this.wells[data.index];
                    this.wells.splice(data.index,1);
                    this.wells.splice(data.index-1,0,well);
                }
                if(data.command == 'moveDown' && data.index < this.wells.length - 1){
                    //alert("will move the item down");
                    var well = this.wells[data.index];
                    this.wells.splice(data.index,1);
                    this.wells.splice(data.index+1,0,well);
                }
            },
            emptyMessageCheck() {
                //Remove empty Messages
                this.messages = this.messages.filter(message => {
                    return (message.body != '' || message.header != '');
                });

                //Check for any partially completed messages
                const partial_messages = this.messages.filter(message => {
                    return (message.body == '' || message.header == '');
                });

                if(partial_messages.length > 0){
                    alert("Please fill out all titles and messages for site status messages before saving the job.");
                    return true;
                }else{
                    return false;
                }
            },
            getLatestData: function () {
                this.busy = true;
                const self = this;

                axios({
                    method: "POST",
                    url: "/saveRemoteJobData/" + this.jobNumber,
                    data: this.jobData,
                    dataType: 'json'
                })
                .then((response) => {
                    if (response.data.success) {
                        alert(response.data.message); //success alert
                        //Update input values for latest prod data
                        this.latitude = this.newJobData.latitude;
                        this.longitude = this.newJobData.longitude;
                        this.location = this.newJobData.location;
                        this.start = this.newJobData.start;
                        this.end = this.newJobData.end;
                        this.hourOffset = this.newJobData.hourOffset;
                        this.company = this.newJobData.company;
                        this.scheduledStart = this.newJobData.scheduledStart;
                        this.scheduledEnd = this.newJobData.scheduledEnd;
                        this.system = this.newJobData.system;
                        this.shiftStart = this.newJobData.shiftStart;
                        if (this.wellDetailsForJob.length){
                            this.wells = [...this.wellDetailsForJob];
                        }
                    }
                    if (response.data.error) {
                        alert(response.data.message);
                    }
                }).finally(() => {
                    this.busy = false;
                });
            },
            newJob: function () {
                this.busy = true;
                const self = this;

                const error = this.emptyMessageCheck();
                if(error){
                    return;
                }

                let jobData = this.jobData;
                if(this.input) {
                    jobData.insertId = this.input.job_id;
                }
                
                $.post(
                    '/jobs',
                    jobData,
                    function (data) {
                        if (data.error) {
                            alert(JSON.stringify(data.message));
                            self.errors = data.errors;
                        }
                        else {
                            //TODO: disable button on click and re-enable on fail
                            //improve user feedback
                            self.jobCreatedSuccessfully = true;
                            self.redirectPage();
                        }
                    },
                    'json'
                ).always(() => {
                    this.busy = false;
                });
            },
            updateJob: function () {
                this.busy = true;
                const self = this;

                const error = this.emptyMessageCheck();
                if(error){
                    return;
                }

                $.ajax({
                    method: "PUT",
                    url: "/jobs/" + this.jobNumber,
                    data: this.jobData,
                    dataType: 'json'
                }).done(function (data) {
                    if (data.error) {
                        alert(JSON.stringify(data.message));
                        self.errors = data.errors;
                    }
                    else {
                        self.jobUpdatedSuccessfully = true;
                        self.redirectPage();
                    }
                }).always(() => {
                    this.busy = false;
                });
            }
        }
    }
</script>

<style scoped>
.form-control:disabled {
  background-color: #BABDBF;
}
</style>
