<template>
  <svg
    :style="{fill: color, stroke: color}"
    id="Layer_1"
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-50 0 1010 750"
  >
    <rect class="cls-3" x="15" y="564" width="980" height="130" rx="55" />
    <path
      class="cls-3"
      d="M473,15h64a30,30,0,0,1,30,30V563a0,0,0,0,1,0,0H443a0,0,0,0,1,0,0V45A30,30,0,0,1,473,15Z"
    />
    <path
      class="cls-3"
      d="M526,705c0-291.29,166.83-527,373-527"
      transform="translate(-455 -141)"
    />
    <path
      class="cls-3"
      d="M1395,705c0-286.87-167.83-527-374-527"
      transform="translate(-455 -141)"
    />
  </svg>
</template>

<style>
  .cls-2 {
    letter-spacing: -0.02em;
  }
  .cls-3 {
    fill: none;
    stroke-miterlimit: 10;
    stroke-width: 30px;
  }
</style>

<script>
export default {
  props: ["color"],
};
</script>