var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex justify-content-center",
      style: "height:" + _vm.height + "px",
    },
    [
      _vm.editMode
        ? _c("div", { staticClass: "flex-column", attrs: { id: "overlay" } }, [
            _c(
              "div",
              {
                staticClass: "col-12 input-group ml-1",
                staticStyle: { top: "10%" },
              },
              [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "select",
                  {
                    staticClass: "form-control mr-2",
                    attrs: { id: "inputGroupSelect02" },
                    domProps: { value: _vm.dropdownValue },
                    on: {
                      change: function ($event) {
                        return _vm.onSelectWell($event.target.value)
                      },
                    },
                  },
                  [
                    _vm._l(_vm.wells, function (well, index) {
                      return _c(
                        "option",
                        {
                          key: "well_" + index,
                          domProps: { value: well.index },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(well.name) +
                              " - " +
                              _vm._s(well.status) +
                              "\n                "
                          ),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "option",
                      { domProps: { value: _vm.activeTypes.wireline } },
                      [
                        _vm._v(
                          "\n                    Lock to active wireline well\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "option",
                      { domProps: { value: _vm.activeTypes.frac } },
                      [
                        _vm._v(
                          "\n                    Lock to active frac well\n                "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "option",
                      { domProps: { value: _vm.activeTypes.wirelineTrucks } },
                      [
                        _vm._v(
                          "\n                    Lock to selected wireline truck\n                "
                        ),
                      ]
                    ),
                  ],
                  2
                ),
              ]
            ),
            _vm._v(" "),
            _vm.showTruckOptions
              ? _c(
                  "div",
                  {
                    staticClass: "col-12 input-group ml-1",
                    staticStyle: { top: "10%" },
                  },
                  [
                    _vm._m(1),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedTruck,
                            expression: "selectedTruck",
                          },
                        ],
                        staticClass: "form-control mr-2",
                        attrs: { id: "inputGroupSelect03" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.selectedTruck = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.onSelectedTruck($event.target.value)
                            },
                          ],
                        },
                      },
                      _vm._l(
                        _vm.wirelineSystems,
                        function (wirelineSystem, index) {
                          return _c(
                            "option",
                            {
                              key: index,
                              domProps: { value: wirelineSystem.number },
                            },
                            [_vm._v(_vm._s(wirelineSystem.name))]
                          )
                        }
                      ),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.loading
        ? _c("div", { staticClass: "d-flex algin-self-center" }, [
            _vm._v("Loading...."),
          ])
        : _vm.selectedWell
        ? _c("well-small-component", {
            staticStyle: {
              width: "100% display: flex",
              "justify-content": "center",
            },
            attrs: {
              well: _vm.selectedWell,
              name: _vm.selectedWell.name,
              color: _vm.selectedWell.color,
              duration: _vm.selectedWell.duration,
              currentStage: _vm.selectedWell.currentStage,
              numberOfStages: _vm.selectedWell.numberOfStages,
              crownPressures: _vm.pressure.crownPressures,
              index: _vm.selectedWell.index,
              stretch: true,
              activity: _vm.selectedWell.activity,
              status: _vm.selectedWell.status,
              "is-multi-wireline": _vm.isMultiWireline,
              "wireline-systems": _vm.wirelineSystems,
              casingPressures: _vm.pressure.casingPressures,
              pressureWell:
                _vm.pressure.casingPressures[_vm.selectedWell.index],
              showWellLockToggle: true,
              stageStart: _vm.selectedWell.start,
            },
            on: { "wireline-duration": _vm.getWirelineDuration },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading &&
      !_vm.editMode &&
      _vm.dropdownValue == this.activeTypes.wireline &&
      !_vm.wirelineWell
        ? _c(
            "div",
            { staticClass: "overlay-center", attrs: { id: "overlay" } },
            [
              _c("div", { staticClass: "align-self-center font-weight-bold" }, [
                _vm._v("Wireline not set."),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading &&
      !_vm.editMode &&
      _vm.dropdownValue == this.activeTypes.frac &&
      !_vm.fracWell
        ? _c(
            "div",
            { staticClass: "overlay-center", attrs: { id: "overlay" } },
            [
              _c("div", { staticClass: "align-self-center font-weight-bold" }, [
                _vm._v("Frac not set."),
              ]),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.loading &&
      !_vm.editMode &&
      _vm.dropdownValue == this.activeTypes.wirelineTrucks &&
      !_vm.wellWithTruck &&
      !_vm.selectedWell
        ? _c(
            "div",
            { staticClass: "overlay-center", attrs: { id: "overlay" } },
            [
              _c("div", { staticClass: "align-self-center font-weight-bold" }, [
                _vm._v("Truck not active on well."),
              ]),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "label",
        {
          staticClass: "input-group-text",
          staticStyle: { width: "75px" },
          attrs: { for: "inputGroupSelect02" },
        },
        [_vm._v("Well:")]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c(
        "label",
        {
          staticClass: "input-group-text",
          staticStyle: { width: "75px" },
          attrs: { for: "inputGroupSelect03" },
        },
        [_vm._v("Truck:")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }