var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "h-100 w-100" }, [
    _c("div", {
      ref: _vm.chartId,
      style: "height:" + _vm.height + "px;",
      attrs: { id: _vm.chartId },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }