<template>
    <div ref="StageSummaryHeatMap" id="stageSummaryHeatMap" class="w-100" style="font-size:0.8rem; background-color: #fff;">
        <notifications ref="dataFetchNotification" id="chartNotifications" :group="'dataFetch-'+item.i" position="top center"/>
        <notifications ref="errorNotification" id="errorNotification" :group="'error-'+item.i" position="top center"/>
    <div>
            <div id="filtersContainer" class="m-1 w-100">
                <div v-if="isLoadingPads" class="d-flex justify-content-center align-items-center">
                        <div class="spinner-border spinner-border-sm" role="status" style="color:black; position: absolute; z-index: 100; top: 10%;"></div>
                </div>
                <!-- Area One -->
                <div id="heatmapFilters" ref="heatmapFilters" class="row px-2 pb-2 pt-2" style="margin-right: 0px;" :style="isLoadingPads || isSaving ? 'background-color:rgba(0,0,0,0.5);' : ''">
                    <div class="col-12">
                        <div class="row w-100">
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Pad
                                    </div>
                                    <div>
                                        <!-- Control goes here -->
                                        <multi-select id="selectedPads"
                                            :options="receivedPads"
                                            :value="selectedPads"
                                            label='label'
                                            :disabled="isLoadingPads || isLoading"
                                            :multiSelect="false"
                                            :maxHeight="300"
                                            :placeholderText="'Pick One'"
                                            :sortByKey="sortPadDropdownByKey"
                                            :isReverseSort="isReverseSort"
                                            @selectedupdated="padsSelected"
                                            :key="refreshTicker"/>
                                    </div>
                                    <div>
                                        <b-form-checkbox
                                            style=""
                                            class="mt-2"
                                            v-model="sortByJobStartDate"
                                            :value="true"
                                            :unchecked-value="false"
                                        >
                                            Sort by Start Date
                                        </b-form-checkbox>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Well(s)
                                    </div>
                                    <div>
                                        <!-- Wells data -->
                                        <multi-select id="selectedWells"
                                                    :options="selectableWells"
                                                    :value="selectedWells"
                                                    :hasSubLabels="false"
                                                    :disabled="selectedPads.length == 0 || isLoadingPads"
                                                    :label="'wellName'"
                                                    @selectedupdated="wellsSelected"
                                                    :key="refreshTicker"/>

                                        <!--To do : determine if select all / clear all buttons are necessary-->
                                        <!-- <b-button @click="selectedWells = [...selectableWells[0].valueArray]"
                                                size="sm"
                                                class="my-1"
                                                :disabled="selectableWells.length == 0 || selectedPads.length == 0"
                                                variant='secondary'>All</b-button>
                                        <b-button @click="selectedWells = []"
                                                size="sm"
                                                class="my-1 ml-1"
                                                :disabled="selectedPads.length == 0 || selectedWells.length == 0"
                                                variant='secondary'>Clear</b-button>-->
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Start Stage
                                    </div>
                                     <div class="controlBorder">
                                        <select class="form-control" id="aggregateSelect" :disabled="isLoadingPads" v-model.number="selectedStageStart" @change="startStageSelected($event.target.value)">
                                            <option v-for="(option,index) in selectableStageNumbers" :key="index" :value="option">{{option}}</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select End Stage
                                    </div>
                                    <div>
                                        <div class="controlBorder">
                                        <select class="form-control" id="aggregateSelect" :disabled="isLoadingPads" v-model.number="selectedStageEnd" @change="endStageSelected($event.target.value)">
                                            <option v-for="(option,index) in selectableStageNumbers" :key="index" :value="option">{{option}}</option>
                                        </select>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Category
                                    </div>
                                    <div>
                                        <div class="controlBorder">
                                            <select class="form-control" id="aggregateSelect" v-model="selectedCategory" @change="categorySelected($event.target.value)">
                                                <option value="stage_frac">Frac</option>
                                                <option value="stage_wireline">Wireline</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Channel
                                    </div>
                                    <div>
                                        <div class="controlBorder">
                                            <select class="form-control" id="channelSelect" :disabled="isLoadingPads || isLoadingChannels" v-model="selectedDisplayName">
                                                <option v-for="(option,index) in selectableChannels" :key="index" :value="option.displayName">{{option.displayName}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col">
                                <div>
                                    <div class="pb-2 selectlabel">
                                        Select Expression
                                    </div>
                                    <div>
                                        <div class="controlBorder">
                                            <select class="form-control" id="aggregateSelect"
                                            v-model="selectedExpression"
                                            @change="expressionSelected($event.target.value)"
                                            :disabled="!selectedExpression">
                                                <option v-for="(expression,index) in selectableExpressions"
                                                :value="expression" :key="index"
                                                >{{getExpressionDisplayName(expression)}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 ml-4">
                                <div class="d-flex justify-content-end pr-3 pb-2">
                                </div>
                                <div class="d-flex row justify-content-left w-100 gx-2">
                                    <div>
                                        <button :disabled="isLoadingPads" class="btn btn-secondary grey-button mt-2 mr-2" @click="clearSelections()">Clear All Filters</button>
                                        <button :disabled="isLoadingPads" class="btn btn-secondary grey-button mt-2 mr-2" @click="openEditTemplate()">Template Settings</button>
                                        <button :disabled="isLoadingPads" class="btn btn-secondary grey-button mt-2 mr-2" @click="validateAndLoadData()">Load Chart</button>
                                    </div>
                                </div>
                                <div class="mt-2">
                                    <b-form-checkbox :disabled="isLoadingPads" style="color:black;" v-model="showLegend">
                                        Show Legend
                                    </b-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="isLoading" class="d-flex justify-content-center">
                <div class="spinner-border spinner-border-sm mt-2" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div id="chart-area">
                <!--Area two heat map-->
                <heat-map-lightning-chart
                    ref="heatMapLightningChart"
                    :job="templateSelectedJobs"
                    :height="canvasHeight"
                    :data="chartData"
                    :wellData="templateWells"
                    :stageMin="templateStartStage"
                    :stageMax="templateEndStage"
                    :propShowLegend="getLegendStatus()"
                    @showLegendChanged="saveLegendStatus"
                    @error="lightningChartErrorHandler"
                    >
                </heat-map-lightning-chart>
            </div>
        </div>

        <stage-comparison-settings-modal
            ref="settingsModal"
            v-show="!isLoadingPads"
            :modalVisible="modalVisible"
            :chartType="chartType.description"
            :templateData="templateData"
            :dashboardInfo="dashboardInfo"
            :isAdmin="isAdmin"
            :userid="userid"
            @onDismiss="modalVisible = false"
            @onLoadTemplate="loadedTemplate"
            @onCreatedTemplate="createdTemplate"
            @onUpdatedTemplate="updatedTemplate"
            @onNewTemplate="newTemplate"
            @onCopyTemplate="copyChartTemplate"
            @onDeleteTemplate="deleteChartTemplate"
            :targetTemplateId="item.targetTemplateId ? item.targetTemplateId : null">
        </stage-comparison-settings-modal>
    </div>
</template>

<style scoped>
    .grid {
        display: grid;
        grid-template-columns: auto 260px;
        grid-auto-rows: auto;
        grid-template-areas:
            "padControls timeControls"
            "chartArea timeControls"
            "chartArea timeControls";
        height:100%;
        width:100%;

    }
    .scroll {
        overflow:scroll;
    }
    .scrollContainer {
        overflow: scroll;
        scrollbar-width: none;
        -ms-overflow-style: none;
    }
    .scrollContainer::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    .padControls {
        grid-area: padControls;
    }
    .timeControls {
        grid-area: timeControls;
    }
    .chartArea {
        grid-area: chartArea
    }
    select{
        font-family: FontAwesome, sans-serif;
    }
    .popover {
        max-width: 50vw !important;
        background-color: #373A3C;
        padding: 0px;
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
    }
    .white-text {
        color: white;
    }
    #chartjs-tooltip {
        opacity: 1;
        text-align: left;
        position: absolute;
        background: rgba(0, 0, 0, .7);
        color: white;
        border-radius: 3px;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        pointer-events: none;
    }

    .chartjs-tooltip-key {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin-right: 10px;
    }

    #timeline-wrapper {
        position: relative;
        height: 2rem;
        text-align: center;
        z-index: 1;
    }

    .not-live-tracking {
        opacity: 0.25;
    }

    .fake-button:hover {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }

    .fake-button:active {
        outline: none;
        border: none;
    }

    .fake-button:focus {
        outline: 0;
    }
    .w-60 {
        width: 60%!important;
    }
    .w-40 {
        width:40%!important;
    }

    @keyframes warning {
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }
    @-webkit-keyframes warning {
        0% { background-color: transparent; color: white }
        50% { background-color: transparent; color: white }
        51% { background-color: #f8fc03; color: black}
        100% { background-color: #f8fc03; color: black}
    }

    @keyframes critical {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }
    @-webkit-keyframes critical {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #fc0303 }
        100% { background-color: #fc0303 }
    }

    @keyframes failed {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    }
    @-webkit-keyframes failed {
        0% { background-color: transparent }
        50% { background-color: transparent }
        51% { background-color: #bababa }
        100% { background-color: #bababa }
    }
    .show-clicker-finger {
        cursor: pointer;
    }

    .dropdown {
        position: relative;
        display: inline-block;
    }

    .dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content {
        display: none;
        position: absolute;
        background-color: white;
        min-width: 140px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
    }

    .filter-dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .filter-dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {
        color: black;
        background-color: #ddd;
    }

    .show {
        display:block;
    }
    .swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
    }
    .controlBorder {
    border-color: black;
    border-style: solid;
    border-width: 2px;
    }

    .show-clicker-finger {
        cursor: pointer;
    }

    .multiselect{
        border-color:black;
        border-style:solid;
        border-width:2px;
    }
    .selectlabel{
        color:black;
    }
    .btnSizeOverride {
        min-width: 100px !important;
        font-size: 0.85rem;
        padding: 4px 2px !important;
        margin: 3px 1px;
    }
</style>

<script>
import moment from 'moment';
import _ from 'lodash';
import GlobalFunctions from '../GlobalFunctions.js';
import Notifications from 'vue-notification';
import SignalRMixin from '../mixins/SignalRMixin.js';
import AnalyticsMixin from '../mixins/AnalyticsMixin.js';
import {v4 as uuidv4} from 'uuid';
import { defaultEqualsFunction } from '@arction/lcjs';

//To Do: remove some constants that are not required
const MILLIS_PER_HR = 3600000;
const MILLIS_PER_DAY = 86400000;
const MILLIS_PER_QUARTER_DAY =  86400000 / 4; //25% of a day in milliseconds, reduced number to prevent php memory overflow
const INITIAL_WINDOW_HRS = 9; //hours of data to load on startup
const MINIMUM_COMPONENT_HEIGHT_PIXELS = 415;
const MAX_WINDOW_HRS = 24; //maximum amount to show on screen at once
const MAX_DATA_POINTS = 2500; //maximum number of data points on the graph
const MAX_FILL_IN_PAGE_HRS = 3; //hours of data to retrieve at a time when panning or zooming out
const MIN_PAGE_SIZE_MILLIS = 5000; //minimum diff between start and end when getting more data
const MAX_SECTION_HEIGHT_PERCENTAGE = 1.2/4;
const HEADER_OFFSET = 125;
const BOTTOM_LABEL_INSET = 50;
const STAGE_SUMMARY_EXPRESSIONS = {avg: 'Average', max: 'Maximum', min: 'Minimum'};
const CHART_HEIGHT_OFFSET = 50;
const DEFAULT_TITLE_HEIGHT = 25;

export default {
    inject: ['dashboardData','quickAddData'],
    created() {
        this.chartTypes = GlobalFunctions.analysisChartTypes();
    },
    mixins: [SignalRMixin, AnalyticsMixin],
    data() {
        return {
            canvasHeight: this.chartHeight,
            showLegend: false,
            filterHeight: 0,
            titleHeight: 0,
            isReverseSort: false,
            sortByJobStartDate: false,
            chartData: null,
            errorNotifications: {
                dataFetch: {
                    tags: {}
                }
            },
            job: null,
            jobNumber: null,
            systemName: null,
            startStage: null,
            endStage: null,
            refreshTicker: false,
            isLoading: false,
            isLoadComplete: false,
            isLoadingPads: true,
            isLoadingChannels: false,
            isSaving: false,
            modalVisible: false,
            templateData: null,
            templateDataDefault: {
                id: null,
                name: null,
                type: this.chartType,
                isUserDefault: false,
                loadLatestStage: false,
                data: {
                    jobs: [],
                    wells: [],
                    category: 'stage_frac',
                    expression: 'max',
                    channel: null,
                    startStage: null,
                    endStage: null,
                    showLegend: null
                }
            },
            filterOptionsDataIn: {},

            receivedPads: [],
            receivedWells: [],
            receivedStages: [],
            receivedIndices: [],
            receivedChannels: [],
            selectableWells: [],
            selectableStages: [],
            selectableStageNumbers: [],
            selectableStageStarts: [],
            selectableStageEnds: [],
            stageIntervalWellSelect: {},
            selectedPads: [],
            selectedWells: [],
            selectedStages: [],
            selectedStageStart: null,
            selectedStageEnd: null,
            selectedCategory: 'stage_frac',
            selectedExpression: 'max',
            selectedChannel: null,
            selectedDisplayName: null,
            selectedChannels: [],
        };
    },
    props: {
        chartType: {
            type: Symbol,
            required: false
        },
        filterOptionsData: {
            type: Object,
            required: true
        },
        height: {
            type: Number,
            required: true
        },
        item: {
            type: Object,
            require: true
        },
        sessionId: {
            type: [String, Object]
        },
        dashboardInfo: {
            type: Object
        },
        isAdmin: {
            type: [Boolean, Number]
        },
        userid: {
            type: [String]
        },
        userCompanyId: {
            type: String,
            required: true
        }
    },
    components: {
    },
    methods: {
        getSummaryTagRecordForTagname(tagName) {
            if (Object.keys(this.selectableChannels).length == 0) {
                return null;
            }
            const record = Object.values(this.selectableChannels).find(channel =>
                Object.values(channel.tagNames).indexOf(tagName) !== -1);
            if (record) {
                return record;
            } else {
                return null;
            }
        },
        getSummaryTagRecordForDisplayName(displayName) {
            if (Object.keys(this.selectableChannels).length == 0) {
                return null;
            }
            const channel = Object.values(this.selectableChannels).find(channel => channel.displayName == displayName);
            if (channel) {
                return channel;
            } else {
                return null;
            }
        },
        getDisplayNameForTag: function(tagName) {
            if (Object.keys(this.selectableChannels).length == 0) {
                return null;
            }
            const channel = Object.values(this.selectableChannels).find(channel =>
                Object.values(channel.tagNames).indexOf(tagName) !== -1);
            if (channel) {
                return channel.displayName;
            } else {
                return null;
            }
        },
        getChannelFromDisplayNameAndExp(displayName, exp) {
            if (Object.keys(this.selectableChannels).length == 0) {
                return null;
            }
            const channel = Object.values(this.selectableChannels).find(channel => channel.displayName == displayName);
            if (channel && channel.tagNames[exp]) {
                return channel.tagNames[exp];
            } else {
                return null;
            }
        },
        saveConfiguration: function() {
            const url = '/analysis/saveTemplate';
            const self = this;

            //if there is no template yet for this chart then complete the first save through the template modal
            if (self.templateData.id == null) {
                self.modalVisible = true;
                return;
            }
            const packetData = {
                _token: GlobalFunctions.getCSRFToken(),
                id: self.templateData.id ? self.templateData.id : null,
                name: self.templateData.name,
                type: self.templateData.type,
                data: self.templateData.data
            };
            this.isSaving = true;
            axios.post(url, packetData)
                .then(function (response) {
                    self.isSaving = false;
                    self.returnedDataset = response;
                    self.$emit('updateDashboardItem', {
                        item: self.item,
                        targetTemplateId: response.data.id
                    });
                    self.templateName = response.data.name;
                    self.templateData.name = response.data.name;
                    self.$refs['settingsModal'].fetchAvailableTemplates();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onResize() {
            let heatmapFiltersId = this.$refs.heatmapFilters;
            if (heatmapFiltersId) {
                let dataFilterIconHeight = 37;
                this.canvasHeight = this.height - heatmapFiltersId.offsetHeight - dataFilterIconHeight;
            }
        },
        fetchFilterData: function() {
            //Should fetch filter information needed for this chart
            const url = '/analysis/getGhostChartFilterData/';
            const self = this;
            $.get(
                url,
                {
                    indexBy: ['Place Frac', 'Remove Frac'],
                    customerId: this.dashboardInfo.customer_id || this.userCompanyId,
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.receivedPads = result.selectablePads.sort((a,b)=>{
                            if (a.location < b.location) {return -1;}
                            if (a.location > b.location) {return 1;}
                            return 0;
                        });
                        self.receivedWells = result.selectableWells;
                        self.receivedStages = result.selectableStages;

                        self.setSelections(self.templateData.data);
                    }
                    self.isLoadingPads = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                //TODO: handle this
                }
            });
        },
        openEditTemplate: function() {
            this.updateTemplateData();
            this.modalVisible = true;
        },
        validateFilterSelections(showErrorMessage) {
            //validate the stored template data
            const data = this.templateData.data;
            let isValid = true;
            if (!Array.isArray(data.jobs) || data.jobs.length == 0) {
                isValid = false;
            }
            if (!Array.isArray(data.wells) || data.wells.length == 0) {
                isValid = false;
            }
            if (!data.startStage || isNaN(data.startStage)) {
                isValid = false;
            }
            if (!data.endStage || isNaN(data.endStage)) {
                isValid = false;
            }
            if (!data.channel) {
                isValid = false;
            }
            return isValid;
        },
        validateAndLoadData: function(showErrorMessage=true) {
            if (!this.validateFilterSelections()) {
                if (showErrorMessage) {
                    this.$notify({
                        group: 'error-'+this.item.i,
                        title: 'Filter Selection Error',
                        text: 'All filter options must be populated prior to data retrieval',
                        duration: 5
                    });
                }
                return;
            };
            //dismiss current chart notifications
            this.$notify({
                group: 'dataFetch-'+this.item.i,
                clean: true
            });

            //get stage data for pad
            const url = '/stageSummary/getValueForStageSummaryTag';
            const self = this;
            const data = this.templateData.data;

            const packetData = {
                _token: GlobalFunctions.getCSRFToken(),
                jobNumber: data.jobs[0].name,
                wellNumbers: data.wells.map(well => well.index),
                startStage: data.startStage,
                endStage: data.endStage,
                category: data.category,
                tagName: data.channel
            };
            const errors = [];
            this.isLoading = true;
            axios.post(url, packetData)
                .then(function (response) {
                    self.chartData = response.data;
                })
                .catch(function (error) {
                    console.error('error', error);
                    self.$notify({
                        group: 'dataFetch-'+self.item.i,
                        title: 'Stage Data Request Error',
                        text: 'An error ocurred fetching stage summary data. If the problem persists please contact your administrator.',
                        duration: 5
                    });
                })
                .then(function() {
                    self.isLoading = false;
                });
        },

        // //Selection Handlers
        padsSelected: function(newValue) {
            this.selectedStageStart = null;
            this.selectedStageEnd = null;
            if (newValue != null) {
                //if multiselect, use array. If single select, put object in array of one for similar handling
                this.selectedPads = Array.isArray(newValue)? newValue : [newValue];
                this.templateData.data.jobs = this.selectedPads;
            }
        },
        wellsSelected: function(newValue) {
            this.selectedWells = newValue;
            this.templateData.data.wells = newValue;
        },
        startStageSelected: function(newValue) {
            this.templateData.data.startStage = parseInt(newValue);
        },
        endStageSelected: function(newValue) {
            this.templateData.data.endStage = parseInt(newValue);
        },
        categorySelected: function(newValue) {
            this.templateData.data.category = newValue;
        },
        expressionSelected: function(newValue) {
            this.templateData.data.expression = newValue;
            this.templateData.data.channel = this.selectedTagName;
        },
        updateTemplateData: function() {
            this.templateData.data.jobs = this.selectedPads;
            this.templateData.data.wells = this.selectedWells;
            this.templateData.data.startStage = parseInt(this.selectedStageStart);
            this.templateData.data.endStage = parseInt(this.selectedStageEnd);
            this.templateData.data.expression = this.selectedExpression;
            this.templateData.data.category = this.selectedCategory;
            this.templateData.data.channel = this.selectedChannel;
        },

        // //Selection Handlers ends here
        loadedTemplate: function(incomingTemplateData) {
            this.loadTemplate(incomingTemplateData);
            if (!this.isLoadingPads) {
                this.$nextTick(() => {
                    this.setSelections(JSON.parse(incomingTemplateData.data));
                });
            }
        },
        createdTemplate: function(incomingTemplateData) {
            //Takes the returned data and updated the local state so we now have id available for subsequent updates to the object
            this.loadTemplate(incomingTemplateData);
            this.$emit('updateDashboardItem', {
                item: this.item,
                targetTemplateId: incomingTemplateData.id,
                saveDashboard: true
            });
        },
        updatedTemplate: function(incomingTemplateData) {
            this.$emit('updateDashboardItem', {
                item: this.item,
                targetTemplateId: incomingTemplateData.id,
                saveDashboard: true
            });
        },
        loadTemplate: function(incomingTemplateData) {
            this.templateData.id = incomingTemplateData.id;
            this.templateData.name = incomingTemplateData.name;
            this.templateData.type = incomingTemplateData.type;
            this.templateData.data = JSON.parse(incomingTemplateData.data);

            // Emit an event here to be captured by the analytics dashboard to set the appropriate component level template persistance option
            this.$emit('updateDashboardItem', {
                item: this.item,
                targetTemplateId: this.templateData.id
            });
            this.validateAndLoadData(false);
        },
        newTemplate: function() {
            this.templateData = _.cloneDeep(this.templateDataDefault);
        },
        copyChartTemplate: function() {
            this.templateData = _.cloneDeep(this.templateData);
            this.templateData.name = this.templateData.name + ' (Copy)';
        },
        deleteChartTemplate: function() {
            const self = this;
            //need to delete template from db, and then change the local template back to the default
            const url = '/analysis/deleteTemplate';
            const data = {
                targetTemplateID: this.templateData.id
            };
            axios.post(url, data)
                .then(function (response) {
                    self.templateData = _.cloneDeep(self.templateDataDefault);
                    self.$refs.settingsModal.fetchAvailableTemplates();//refresh the template list
                    // Emit an event here to be captured by the analytics dashboard to set the appropriate component level template persistance option
                    self.$emit('updateDashboardItem', {
                        item: self.item,
                        targetTemplateId: null
                    });
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        clearSelections: function() {
            //Clear all the current setting data
            this.selectedPads = [];
            this.selectedWells = [];
            this.selectedStageStart = null;
            this.selectedStageEnd = null;
            this.selectedCategory = 'stage_frac';
            this.selectedExpression = null;
            this.selectedChannel = null;
            this.selectedDisplayName = null;
            this.receivedChannels = null;
            this.templateData = _.cloneDeep(this.templateDataDefault);
        },
        setSelections: function(incomingTemplateData) {
            //Should set all of the active selection to the defined values
            this.selectedPads = Array.isArray(incomingTemplateData.jobs) ? incomingTemplateData.jobs : [incomingTemplateData.jobs]; //passed in as an array for now
            this.selectedWells = incomingTemplateData.wells;
            this.selectedStageStart = Number.parseInt(incomingTemplateData.startStage);
            this.selectedStageEnd = Number.parseInt(incomingTemplateData.endStage);
            this.selectedChannel = incomingTemplateData.channel;
            this.selectedCategory = incomingTemplateData.category;
            this.selectedExpression = incomingTemplateData.expression;

            if (this.selectedChannel) {
                this.selectedDisplayName = this.getDisplayNameForTag(this.selectedChannel);
            }
        },
        getStageSummaryTagsForCustomer: function() {
            const url = '/analysis/getStageSummaryTagsForCustomer';
            const self = this;
            this.isLoadingChannels = true;

            $.get(
                url,
                {
                    customerId: this.dashboardInfo.customer_id,
                    category: 'stage_all'//get all stag summary tags at once
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.receivedChannels = result;

                        self.selectedDisplayName = null;
                        if (self.templateData?.data?.channel) {
                            self.$nextTick(() => {
                                self.selectedDisplayName = self.getDisplayNameForTag(self.templateData?.data?.channel);
                            });
                        }
                    }
                    self.isLoadingChannels = false;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
                self.isLoadingChannels = false;
            });
        },
        lightningChartErrorHandler(error) {
            this.$notify({
                group: 'error-'+this.item.i,
                title: error.title || '',
                text: error.message,
                duration: -1
            });
        },
        quickAddComponentHandler(defaultTemplateData) {
            const templateData = this.templateData.data;
            let validWells = [];
            if (defaultTemplateData.pads) {
                let templatePad = defaultTemplateData.pads[0]? defaultTemplateData.pads[0].jobNumber: null; //Should be array of one pad since can only select one pad for MWT
                if (templatePad) {
                    validWells = this.receivedWells.find(well => well.subCatLabel == templatePad).valueArray;
                    templateData.jobs = [this.receivedPads.find(pad => pad.name == templatePad)];
                    this.padsSelected(templateData.jobs)
                }
            }

            let selectedWellIDs;
            if (defaultTemplateData?.wells.length > 0) {
                selectedWellIDs = defaultTemplateData.wells.map(well => well.id);
                templateData.wells = validWells.filter(well => selectedWellIDs.includes(well.id));
            }
            const validStages = selectedWellIDs? this.receivedStages.filter(stage => selectedWellIDs.includes(stage.well_id)) : this.receivedStages;
            const maxStageNumber = Math.max(...validStages.map(stage => stage.maxStage));
            const minStageNumber = Math.min(...validStages.map(stage => stage.minStage));

            templateData.endStage = maxStageNumber;
            if (defaultTemplateData.stageSelect == 'latestStages') {
                templateData.startStage = maxStageNumber - defaultTemplateData.latestStageAmount < minStageNumber ?
                    minStageNumber : maxStageNumber - defaultTemplateData.latestStageAmount;
            } else if (defaultTemplateData.stageSelect == 'all') {
                templateData.startStage = minStageNumber;
            }

            templateData.category = defaultTemplateData.category;
            templateData.expression = defaultTemplateData.expression;
            this.selectedExpression =  templateData.expression;
            templateData.channel = defaultTemplateData.summaryChannels.displayName;
            this.selectedDisplayName = templateData.channel;
            this.templateData.isUserDefault = true; //set as a userDefault template for during save

            this.setSelections(templateData);

            this.$nextTick(() => {
                this.$refs.settingsModal.saveTemplate(); //save the default template values in the newly added chart
                this.validateAndLoadData(); //get Data for chart if possible
            });
        },
        getExpressionDisplayName(expression) {
            if (STAGE_SUMMARY_EXPRESSIONS[expression]) {
                return STAGE_SUMMARY_EXPRESSIONS[expression];
            } else {
                return expression;
            }
        },
        saveLegendStatus(newStatus) {
            this.templateData.data.showLegend = newStatus;
        },
        getLegendStatus() {
            return this.templateData?.data?.showLegend != null ? this.templateData.data.showLegend : this.showLegend;
        }
    },
    computed: {
        chartHeight() {
            return this.height - this.filterHeight - this.titleHeight - CHART_HEIGHT_OFFSET;
        },
        selectableChannels() {
            //will remove expression identifiers (Max, Min, etc.) from display name
            const displayNameRegex = /\b(?=\w)(?!(?:Maximum|Max|Average|Avg|Ave|Minimum|Min)\b).*?(?=\s+(?:Maximum|Max|Average|Avg|Ave|Minimum|Min)\b|\s*$)/;

            //will sort the stage summary tag name out into relevant groups of data
            const channelRegex = /(?<stage>^[a-z]+)_(?<type>[a-zA-Z]*)_(?<expression>[a-z]+)(?<tagName>[A-Za-z0-9\_]+)/;
            const channels = {};

            if (this.receivedChannels && this.receivedChannels[this.selectedCategory]) {
                this.receivedChannels[this.selectedCategory].forEach(channel => {
                    //break channel tag name into it's groups of data
                    const matches = channel.tagName.match(channelRegex);
                    let groups;
                    //get generic display name of the tag, without any expression identifiers
                    const matchedDisplayName = channel.displayName.match(displayNameRegex)[0] || null;

                    if (matches) {
                        groups = matches.groups;
                        const expression = groups.expression;
                        const key = groups.type + groups.tagName;
                        if (channels[key] && channels[key].expressions.indexOf(expression) == -1) {
                            channels[key].expressions.push(expression);
                            channels[key].tagNames[expression] = channel.tagName;
                        } else {
                            channels[key] = {
                                tagNames: {[expression]: channel.tagName},
                                expressions: [expression],
                                displayName: matchedDisplayName ? matchedDisplayName : channel.displayName
                            };
                        }
                    }
                });
            }
            return channels || [];
        },
        selectableExpressions() {
            if (this.selectedDisplayName) {
                const record = this.getSummaryTagRecordForDisplayName(this.selectedDisplayName);
                if (record) {
                    return record?.expressions;
                } else {
                    return [];
                }
            } else {
                return [];
            }
        },
        selectedTagName() {
            if (this.selectedChannel && this.selectedExpression) {
                const record = this.getSummaryTagRecordForTagname(this.selectedChannel);
                if (record && record?.tagNames[this.selectedExpression]) {
                    return record.tagNames[this.selectedExpression];
                }
                return null;
            }
            return null;
        },
        templateSelectedJobs() {
            //shortcut to job data stored in the chart template.
            //This may share the same reference to selected pad, however since this is derived from the
            //stored template it can be a source of data when selectedPad is not assigned a value
            if (this?.templateData?.data?.jobs && this.templateData.data.jobs[0]) {
                return this.templateData.data.jobs[0];
            }
            return null;
        },
        templateWells() {
            if (this.templateData?.data?.wells && this.templateData.data.wells.length > 0) {
                return this.templateData.data.wells;
            } else {
                return [];
            }
        },
        templateStartStage() {
            if (this.templateData?.data?.startStage) {
                return parseInt(this.templateData.data.startStage);
            } else {
                return null;
            }
        },
        templateEndStage() {
            if (this.templateData?.data?.endStage) {
                return parseInt(this.templateData.data.endStage);
            } else {
                return null;
            }
        },
        templateSelectedChannels() {
            if (this?.templateData?.data?.channel) {
                return this.templateData.data.channel;
            } else {
                return [];
            }
        }
    },
    watch: {
        chartHeight: {
            handler(newVal, oldVal) {
                if (this.$refs.heatMapLightningChart) {
                    this.$refs.heatMapLightningChart.resizeLayout();
                }
            }
        },
        selectedPads: {
            deep: true, //watch for changes in properties if true
            immediate: this?.templateData.data.jobs[0]? true: false, //set to true to fire on start up (such as if template data is saved)
            handler (jobs, oldJobs) { //remove unselectable wells
                if (!jobs || !jobs[0] || jobs.length == 0) {
                    return;
                }
                //Fetch selectable channels for currently selected jobs and verify
                //previously selected channels are still in that list

                //selectable wells based on selected pads
                const targetPadNames = jobs.map(a => a.name);

                const targetWells = this.receivedWells.filter((well) => {
                    if(targetPadNames.includes(well.subCatLabel)) {
                        return well;
                    }
                });

                //sort the selectable wells by wellNumber
                this.selectableWells = _.sortBy(targetWells[0]?.valueArray || [], 'index');

                //set up default well stage config options for each well
                const self = this;

                this.selectableStages = this.receivedStages.filter(stage => {
                    if (stage.jobId == jobs[0].id) {
                        return stage;
                    }
                });

                //unselect wells that cannot be selected and select all wells by default
                const selectedJobIDs = jobs.map((job) => job.id);
                this.wellsSelected(this.selectableWells.filter((well)=> selectedJobIDs.includes(well.job_id)));

                let wellsForJobs = [];
                if (this.selectedPads.length > 0) {
                    this.selectableWells.forEach(wellInfo => {
                        wellsForJobs.push(wellInfo);
                    })
                    this.selectedWells = wellsForJobs.flat();
                }

                //reset stage and channel selections as the options change for each job
                if (oldJobs && oldJobs.length > 0 && this.templateSelectedJobs.name != jobs[0].name) {
                    this.selectedStageStart = null;
                    this.selectedStageEnd = null;
                    this.selectedChannel = null;
                }
            }
        },
        selectedWells: function (wells, oldVal) {
            //selectable stages based on selected wells
            const targetWells = wells.map(a => a.wellName);

            const targetStages = this.receivedStages.filter((stage) => {
                if(targetWells.includes(stage.wellName)) {
                    return stage;
                }
            });
            this.selectableStages = targetStages;

            //unselect stages that cannot be selected
            const selectedWellIDs = wells.map((well) => well.id );
            this.selectedStages = this.selectedStages.filter((stage)=> selectedWellIDs.includes(stage.well_id));

            if (this.selectableStages.length > 0) {
                const highestStage = Math.max(...this.selectableStages.map(wellIndex => {return wellIndex.maxStage;}));
                const lowestStage = Math.min(...this.selectableStages.map(wellIndex => {return wellIndex.minStage;}));
                this.selectableStageNumbers = _.range(lowestStage,highestStage+1);
            }
        },
        selectedDisplayName: {
            handler: function(newVal, oldVal) {
                if (!this.selectedExpression || (this.selectedExpression && this.selectableExpressions[0]
                    && this.selectableExpressions.indexOf(this.selectedExpression) == -1)) {
                    this.selectedExpression = this.selectableExpressions[0];
                }

                const record = this.getSummaryTagRecordForDisplayName(newVal);
                if (record && record?.tagNames[this.selectedExpression]) {
                    this.templateData.data.channel = record?.tagNames[this.selectedExpression];
                    this.selectedChannel = record?.tagNames[this.selectedExpression];
                }
            }
        },
        selectedExpression: {
            handler: function(newVal, oldVal) {
                if (this.selectedDisplayName && newVal) {
                    const newTagName = this.getChannelFromDisplayNameAndExp(this.selectedDisplayName, newVal);
                    this.selectedChannel = newTagName;
                    this.templateData.data.channel - newTagName;
                }
            }
        },
        isLoadingPads: function(newVal, oldVal) {
            if (!newVal && this.defaultTemplateData) {
                this.quickAddComponentHandler(this.defaultTemplateData);
            }
        },
        quickAddData: {
            handler(newVal, oldVal) {
                if (this.item.i == this.quickAddData.targetItemId) {
                    this.defaultTemplateData = this.quickAddData.templateDefaults;
                    this.$emit('updateDashboardItem', {
                        item: this.item,
                        targetTemplateId: null,
                        saveDashboard: true
                    });
                }
            },
            immediate: true
        }
    },
    mounted() {
        this.templateData = _.cloneDeep(this.templateDataDefault); //ensure default values for chart template
        this.fetchFilterData();
        this.getStageSummaryTagsForCustomer();
        const filterDiv = document.getElementById('heatmapFilters');
        const titles = document.getElementsByClassName('item_title');

        this.filterHeight = filterDiv.clientHeight;
        this.titleHeight = titles[0]?.clientHeight ?? DEFAULT_TITLE_HEIGHT;

        let resizeObject = new ResizeObserver(this.onResize);
        resizeObject.observe(this.$refs.StageSummaryHeatMap);

        //when the component or window changes size, recalculate the filter height
        //so the chart can fill the remaining area.
        const self = this;
        new ResizeObserver(function() {
            this.filterHeight = document.getElementById('heatmapFilters').clientHeight;
            this.titleHeight = document.getElementsByClassName('item_title')[0].clientHeight ?? DEFAULT_TITLE_HEIGHT;
            if (self.$refs.heatMapLightningChart) {
                self.$refs.heatMapLightningChart.resizeLayout();
            }
        }).observe(filterDiv);
    }

};
</script>
