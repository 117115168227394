<!--
    * Component Description
    Base structure for addEdit modals used in Gun / Plug libraries
-->

<template>
    <div class="dark-modal modal"
        :class="{ 'extended-dialog': addMoreSelection === true}"
        :id="`library_add_edit_${id}`"
        :ref="`library_add_edit_${id}`"
        role="document"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">
                        <slot name="title"></slot>
                    </h5>

                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>

                <div v-if="showBody" class="modal-body">
                    <div class="row">
                        <div :class="{ 'col-12 col-lg-6': addMoreSelection, 'col-12': !addMoreSelection }">
                            <slot name="leftBody" :testForm="testForm"></slot>

                            <div id="add-details-container">
                                <p>
                                    Would you like to add in more details *
                                </p>

                                <div id="add-details-control">
                                    <span class="clickable" :class="{ 'active': addMoreSelection === true }" @click="addMoreSelection = true">
                                        Yes, add more details <span class="circle"><i v-if="addMoreSelection === true" class="fas fa-check"></i></span>
                                    </span>
                                    <span class="clickable" :class="{ 'active': addMoreSelection === false }" @click="addMoreSelection = false">
                                        No, leave it as it is <span class="circle"><i v-if="addMoreSelection === false" class="fas fa-check"></i></span>
                                    </span>
                                </div>
                            </div>
                        </div>

                        <div v-if="addMoreSelection === true" class="col-12 col-lg-6">
                            <slot name="rightBody" :testForm="testForm"></slot>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-outline btn-secondary" data-dismiss="modal">
                        Cancel
                    </button>

                    <iws-button
                        :text="isEditMode(item) ? 'Save Changes' : 'Create'"
                        type="primary"
                        :click="onSaveClick"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isTruthy, isNullOrEmpty, toast } = GlobalFunctions;

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        
        item: Object,

        createAction: Function,
        updateAction: Function
    },


    data: () => ({
        showBody: false,
        customers: [],
        addMoreSelection: null,
        
        resolve: null
    }),

    methods: {	
        isEditMode(item) {
            return isTruthy(item?.id);
        },

        // Upon click of the submit button, we show the invalid fields
        // We don't show invalid states by the automatic calls
        testForm(showInvalidState=false, self=this.$parent) {
            let passedValidation = true;
            const _refs = Object.keys(self.$refs);

            // Validate all inputs to show all invalid inputs, but fail on any failed inputs
            if (!isNullOrEmpty(_refs)) {
                passedValidation = _refs.filter(ref => {
                    const _ref = self.$refs[ref];
                    return !!_ref && 'validateInput' in _ref && !_ref.validateInput();
                }).length === 0;
            } else {
                // First run the form may not be built yet
                // Form has required fields so can't be valid before user even has a chance to see the form
                passedValidation = false;
            }

            if (showInvalidState && !passedValidation)
                toast({
                    title: 'Please fill in all required fields!',
                    variant: 'danger'
                });

            return passedValidation;
        },

        async onSaveClick() {
            if (this.testForm(true)) {
                try {
                    if (this.isEditMode(this.item)) {
                        await this.updateAction();
                    } else {
                        await this.createAction();
                    }

                    this.resolve(this.item);
                } catch(_) {
                    toast({
                        title: 'Failed to save changes',
                        variant: 'danger'
                    });
                }
            }
        },

        open() {
            this.addMoreSelection = null;
            this.loading = false;
            this.showBody = true;

            const modalElem = $(`#library_add_edit_${this.id}`);
            const ref = this.$refs[`library_add_edit_${this.id}`];

            function initModal(cancelCallback) {
                modalElem.show();
                
                modalElem.find('.btn-secondary, .close').on('click', function() {
                    cancelCallback();
                });
                modalElem.on('click', function(event) {
                    // users can click the background to cancel out
                    if (ref === event.target)
                        cancelCallback();
                });
            }

            return new Promise( (resolve, reject) => {
                this.resolve = (val) => {
                    modalElem.hide();
                    this.showBody = false;
                    resolve(val);
                };

                initModal(
                    () => {
                        this.resolve(null);
                    }
                );
            });
        },


        fetchCustomers() {
            // Fetch the list of customers visible to the logged in account
            return $.ajax({
                method: 'GET',
                url: '/customers_fetch/all',
                data: {
                    '_token': GlobalFunctions.getCSRFToken(),
                    '_method': 'GET',
                    'originPage': `${location.origin}/plugs`
                },
                dataType: 'json'
            });
        }
    }
};
</script>

<style scoped>
    .modal .modal-dialog {
        width: 600px !important;
        max-width: 90vw !important;
    }
    .modal.extended-dialog .modal-dialog {
        width: 1200px !important;
    }
    .field-container {
        margin-bottom: 15px;
    }
    .field-container label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--primary-text-color);
    }
    .field-container input, .field-container select {
        background: #343A40;

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 4px;

        color: var(--primary-text-color);
    }
    /* bootstraps '.is-invalid' class couldn't override this for whatever reason */
    .field-container input:not(.is-invalid), .field-container select:not(.is-invalid) {
        border: 1px solid var(--form-color);
    }


    #edit-name-container {
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0em;

        color: #2F83FF;
        float: right;
    }


    #add-details-container p {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0em;
        text-align: left;
        color: var(--primary-text-color);

        margin-bottom: 0px !important;
    }

    #add-details-control {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;

        background: #242A30;

        margin: 15px 0px 25px;
    }
    #add-details-control>span:first-child {
        color: #7BAB86;

        border: 1px solid #4A535C;
        border-radius: 4px 0px 0px 4px;

        padding: 12px 16px 12px 16px;
    }
    #add-details-control>span:last-child {
        color: #FFD7D7;

        border: 1px solid #4A535C;
        border-radius: 0px 4px 4px 0px;

        padding: 12px 16px 12px 16px;

        position: relative;
        left: -3px;
    }
    #add-details-control>span:first-child.active {
        color: #2BD350 !important;
        border-color: #2BD350 !important;
    }
    #add-details-control>span:last-child.active {
        color: #FDA29B !important;
        border-color: #FDA29B !important;
    }

    .circle {
        display: inline-block;
        position: relative;
        top: 5px;
        left: 5px;

        height: 20px;
        width: 20px;
        
        background: linear-gradient(0deg, #343A40, #343A40), linear-gradient(0deg, var(--form-color), var(--form-color));
        border: 1px solid var(--form-color);
        border-radius: 50%;
    }
    .circle>i {
        position: absolute;
        top: 4px;
        left: 3px;

        color: var(--primary-text-color);
    }
    #add-details-control>span:first-child.active .circle {
        background: #2BD350 !important;
    }
    #add-details-control>span:last-child.active .circle {
        background: #FDA29B !important;
    }


    .modal-footer>span, .modal-footer>span>button {
        min-width: 100px;
    }
</style>