import moment from 'moment';
const { reactiveProp } = mixins;
import zoom from 'chartjs-plugin-zoom';
import { Scatter, mixins } from 'vue-chartjs';
import ChartAnnotations from 'chartjs-plugin-annotation';

let ctx;
let scale;
let overlayId;
let overlayLeft;
let minDate = 0;
let drag = false;
let dateTimeId = 0;
let canvas = null;
let tsiChart = null;
let tsiOptions = {};
let isVertical = false;
let maxDatePixel = 0;
let minDatePixel = 0;
const rects = new Map();
const rectTimes = new Map();
let selectionRect = { x: 0, y: 0, w: 0, h: 0 };

export default {
    extends: Scatter,
    mixins: [reactiveProp],
    props: ['chartData', 'options'],
    mounted () {
        const {
            mouseDown,
            mouseUp,
            mouseMove,
            mouseOut,
            drawRect
        } = this;

        const tsiAnalytics = {
            id: 'tsiAnalytics',
            beforeEvent: function(chart, args, options) {
                if(options.isChartActive && (options.enabled || (chart.scales['date-axis'] || chart.scales['custom-date-axis']))) {
                    overlayId = options.overlayId;
                }
            },
            afterUpdate: function(chart, args, options) {
                const overlayTime = rectTimes.get(overlayId);
                if(overlayId && overlayTime?.chartEvent?.selectionRect && !drag) {
                    const overlayTime = rectTimes.get(overlayId);
                    const chartLeft = overlayTime.overlayLeft;
                    let prevMinPixel = overlayTime.minPixel;
                    let newMinPixel = Math.ceil(scale.getPixelForValue(overlayTime.minDate));
                    let newMaxPixel = Math.ceil(scale.getPixelForValue(overlayTime.maxDate));
                    // accounting for left axis on horizontal charts
                    if(!isVertical) {
                        newMinPixel -= chartLeft;
                        newMaxPixel -= chartLeft;
                        prevMinPixel -= chartLeft;
                    }
                    if(prevMinPixel !== newMinPixel) {
                        // once the vertical chart y axis is less than two the next movement will clear the vertical charts, 
                        // not using chartArea.top because that doesn't account for the space on top of the chart
                        if((!isVertical && (newMinPixel < minDatePixel)) || (isVertical && overlayTime.chartEvent.selectionRect.y <= 2)) {
                            tsiOptions.closeStats();
                            rects.delete(overlayId);
                            rectTimes.delete(overlayId);
                        } else {
                            if(isVertical) {
                                overlayTime.chartEvent.selectionRect.y -= (prevMinPixel - newMinPixel);
                            } else {
                                overlayTime.chartEvent.selectionRect.x = newMinPixel;
                            }
                            rectTimes.set(overlayId, {...overlayTime, minPixel: newMinPixel, maxPixel: newMaxPixel});
                            rects.set(overlayId, {...overlayTime.chartEvent.selectionRect});
                            drawRect();
                            tsiOptions.onRepositionStats(null, null, overlayTime.chartEvent);
                        }
                    }
                }
            },
            afterDatasetsDraw: function(chart, args, options) {
                if(options.overlayId === overlayId) {
                    tsiChart = chart;
                    tsiOptions = options;
                    canvas = tsiChart.canvas;
                    isVertical = tsiOptions.isVertical;
                    const overlay = document.getElementById(overlayId);
                    scale = tsiOptions.useCustomScales ? tsiChart.scales['custom-date-axis'] : tsiChart.scales['date-axis'];
                    
                    if (!scale) {return;} //scale must exist
                    
                    maxDatePixel = Math.ceil(scale.getPixelForValue(tsiOptions.chartMax));
                    minDatePixel = Math.ceil(scale.getPixelForValue(tsiOptions.chartMin));
                    overlayLeft = minDatePixel;
                    if(overlay) {
                        ctx = overlay.getContext('2d');
                        overlay.height = tsiChart.chartArea.bottom;
                        if(!isVertical) { overlay.style.left = `${minDatePixel}px`; }
                        overlay.width = isVertical ? tsiChart.chartArea.right : maxDatePixel - overlayLeft;
                        dateTimeId = options.dateTimeId;
                    }
                    if(rects.has(overlayId) && !options.clear) {
                        drawRect(); 
                    } else if(rects.has(overlayId)) {
                        options.clear = false;
                        rects.delete(overlayId);
                        rectTimes.delete(overlayId);
                        selectionRect = { w: 0, x: 0, y: 0 };
                    }
                    if(options.enabled) {
                        canvas.addEventListener('mousedown', mouseDown);
                        canvas.addEventListener('mousemove', mouseMove);
                        canvas.addEventListener('mouseout', mouseOut);
                        canvas.addEventListener('mouseup', mouseUp);
                    } else {
                        tsiChart.canvas.removeEventListener('mousedown', mouseDown);
                        tsiChart.canvas.removeEventListener('mousemove', mouseMove);
                        tsiChart.canvas.removeEventListener('mouseup', mouseUp);
                        tsiChart.canvas.addEventListener('mouseout', mouseOut);
                    }
                }
            }
        };


        Chart.Tooltip.positioners.cursor = function(chartElements, coordinates) {
            return coordinates;
        };
        this.addPlugin(zoom);
        this.addPlugin(tsiAnalytics);
        this.addPlugin(ChartAnnotations);
        this.addPlugin({
            id: 'verticalLine',
            afterDatasetsDraw: function(chart) {
                if (chart.tooltip._active && chart.tooltip._active.length && chart.tooltip._eventPosition) {
                    const activePoints = chart.tooltip._active;
                    const eventPosition = chart.tooltip._eventPosition;
                    const cursorX = eventPosition.x;
                    const cursorY = eventPosition.y;
                    const chartBottomY = chart.chartArea.bottom;
                    const chartTopY = chart.chartArea.top;

                    //if multiple dataPoints come in with the same datasetIndex just use the first one
                    const renderedDatasets = [];
                    activePoints.forEach((activePoint, index) => {
                        const ctx = chart.ctx;
                        const x = activePoint.tooltipPosition().x;
                        const y = activePoint.tooltipPosition().y;
                        const leftX = chart.chartArea.left;
                        const rightX = chart.chartArea.right;
                        const y_axis = activePoint['_yScale'];
                        const topY = y_axis.top;
                        const bottomY = y_axis.bottom;

                        if(cursorX > leftX && cursorX < rightX && cursorY < chartBottomY && cursorY > chartTopY) {
                            const datasetIndex = activePoint._datasetIndex;
                            if(!renderedDatasets[datasetIndex]) {
                                // draw line
                                ctx.save();
                                ctx.beginPath();
                                ctx.moveTo(x, topY);
                                ctx.lineTo(x, bottomY);
                                ctx.moveTo(leftX, y);
                                ctx.lineTo(rightX, y);
                                ctx.lineWidth = 0.25;
                                ctx.strokeStyle = '#AAAAAA';
                                ctx.arc(x, y, 4, 0, 2 * Math.PI, true);
                                ctx.fillStyle = '#AAAAAA';
                                ctx.fill();

                                ctx.stroke();
                                ctx.restore();
                                renderedDatasets[datasetIndex] = true;
                            }
                        }
                    });
                }
            }
        });
        this.renderChart(this.chartData, this.options);
    },
    watch: { 
        chartData: function(newVal, oldVal) { // watch it
            // console.log("chart data changed (watched)");
            this.renderChart(this.chartData, this.options);
        }
    },
    data() {
        return {
            tsiOptions: null
        };
    },
    methods: {
        clearDraw() {
            if(rects && rectTimes) {
                rects.delete(overlayId);
                rectTimes.delete(overlayId);
                ctx.clearRect(0, 0, tsiChart.chartArea.right, tsiChart.chartArea.bottom);
            }
        },
        mouseDown(evt) {
            if(!tsiOptions.clear) {
                tsiOptions?.closeStats(false);
            }
            rects.delete(overlayId);
            rectTimes.delete(overlayId);
            tsiOptions?.setIsSelecting(true);
            selectionRect = { w: 0, x: 0, y: 0 };
            const rect = canvas.getBoundingClientRect();
            selectionRect.x = isVertical ? tsiChart.chartArea.left : Math.max((evt.clientX - rect.left > maxDatePixel ? maxDatePixel : evt.clientX - rect.left) - overlayLeft, 0);
            selectionRect.y = isVertical ? evt.clientY - rect.top : tsiChart.chartArea.top;
            drag = true;
            const min = this.getTimestamp({x: selectionRect.x, y: selectionRect.y}, true, true);
            minDate = tsiOptions.chartMax < min ? tsiOptions.chartMax : min;
        },
        updateDateTimeDisplay(minDate, maxDate, clearDisplay = false) {
            //set the time display to show
            const dateTimeDisplay = document.getElementById(dateTimeId);
            if(dateTimeDisplay) {
                const from = moment.utc(minDate).add({hours: tsiOptions.jobHourOffset});
                const to = moment.utc(maxDate).add({hours: tsiOptions.jobHourOffset});
                dateTimeDisplay.style.visibility = 'visible';
                //update display values
                dateTimeDisplay.innerHTML = `Statistics : &nbsp;<div style="color:grey;">${from.format('MM/DD/YYYY h:mm:ss A')}</div> &nbsp; to &nbsp; <div style="color:grey;">${to.format('MM/DD/YYYY h:mm:ss A')}</div>`;
                if(clearDisplay) {
                    setTimeout(function() {
                        const dateTimeDisplay = document.getElementById(dateTimeId);
                        if(dateTimeDisplay) {
                            dateTimeDisplay.style.visibility = 'hidden';
                        }
                    },4000);
                }
            }
        },
        getTimestamp(view, setTime = false, isMin = false) {
            // accounting for left axis on horizontal charts
            const pixel = isVertical ? view.y : view.x + overlayLeft;
            const timestamp = scale.getValueForPixel(pixel);
            if(setTime && isMin) {
                rectTimes.set(overlayId, {minDate: timestamp, minPixel: pixel});
            } else if(setTime) {
                const newObj = {...rectTimes.get(overlayId), maxDate: timestamp, maxPixel: pixel};
                rectTimes.set(overlayId, newObj);
            }
            return timestamp;
        },
        drawRect() {
            const toDraw = rects.get(overlayId) ? rects.get(overlayId) : selectionRect;
            ctx.beginPath();
            ctx.globalAlpha = 0.6;
            ctx.fillStyle = 'rgb(225,225,225)';
            ctx.clearRect(0, 0, tsiChart.chartArea.right, tsiChart.chartArea.bottom);
            const width = isVertical ? tsiChart.chartArea.right : toDraw.w;
            ctx.fillRect(toDraw.x, toDraw.y, width, toDraw.h);
            ctx.stroke();
        },
        canMove(height) {
            if(isVertical) {
                if(selectionRect.y > 0 && selectionRect.h > 0) {
                    return scale.getValueForPixel(selectionRect.y+height) <= tsiOptions.chartMax;
                } else {
                    return true;
                }
            } else {
                return scale.getValueForPixel(selectionRect.x+selectionRect.w) <= tsiOptions.chartMax;
            }
        },
        completeSelection(maxDate, hideStats) {
            this.drawRect();
            rects.set(overlayId, {...selectionRect});
            const chartEvent = {tsiChart, minDate, maxDate, selectionRect, overlayLeft};
            tsiOptions?.setIsSelecting(false);
            (!hideStats) && tsiOptions.onSelectComplete(chartEvent);
            this.updateDateTimeDisplay(minDate, maxDate, true);
            rectTimes.set(overlayId, {...rectTimes.get(overlayId), chartEvent, overlayLeft});
        },
        mouseMove(evt) {
            const rect = canvas.getBoundingClientRect();
            const height = (evt.clientY - rect.top) - selectionRect.y;
            if(drag && this.canMove(height)) {
                selectionRect.w = isVertical ? tsiChart.width - selectionRect.x : (evt.clientX - rect.left) - (selectionRect.x + overlayLeft);
                selectionRect.h = isVertical ? (evt.clientY - rect.top) - selectionRect.y : tsiChart.chartArea.bottom - tsiChart.chartArea.top;
                this.drawRect();
                this.updateDateTimeDisplay(minDate, this.getTimestamp({x: selectionRect.x+selectionRect.w, y: selectionRect.y+selectionRect.h}, false));
            } else if(drag) {
                this.drawRect();
            }
        },
        mouseUp(evt, hideStats = false) {
            if(minDate > 0 && drag) {
                const max = this.getTimestamp({x: selectionRect.x+selectionRect.w, y: selectionRect.y+selectionRect.h}, true);
                const maxDate = max > tsiOptions.chartMax ? tsiOptions.chartMax : max;
                this.completeSelection(maxDate, hideStats);
            }
            drag = false;
        },
        mouseOut(evt, hideStats = false) {
            if(drag && selectionRect.w > 0) {
                const max = this.getTimestamp({x: selectionRect.x+selectionRect.w, y: selectionRect.y+selectionRect.h}, true);
                const maxDate = max > tsiOptions.chartMax ? tsiOptions.chartMax : max;
                this.completeSelection(maxDate, hideStats);
            } else if(drag) {
                this.completeSelection(tsiOptions.chartMin, hideStats);
            }
            drag = false;
        }
    }
};