var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "header-bar" } },
        [
          _c("iws-button", {
            attrs: { type: "light" },
            on: {
              click: function ($event) {
                return _vm.addEditTag(_vm.activity == "npt" ? null : {}, null)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "text",
                fn: function () {
                  return [
                    _c("i", { staticClass: "fas fa-plus-circle" }),
                    _vm._v(" Add Tag\n            "),
                  ]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("div", { attrs: { id: "tag-count" } }, [
            _vm._v(
              "\n            " +
                _vm._s(_vm.data.tags.length) +
                " Tag" +
                _vm._s(_vm.data.tags.length > 1 ? "s" : null) +
                "\n        "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _vm._isNullOrEmpty(_vm.data.tags)
        ? _c("li", [
            _vm._v(
              "\n        No " +
                _vm._s(_vm.activity) +
                " tags. Hit add tag to start addings new tags\n    "
            ),
          ])
        : [
            _vm.activity == "npt"
              ? _c(
                  "draggable",
                  {
                    attrs: { handle: ".fas" },
                    on: { change: _vm.dropEvent },
                    model: {
                      value: _vm.data.tags,
                      callback: function ($$v) {
                        _vm.$set(_vm.data, "tags", $$v)
                      },
                      expression: "data.tags",
                    },
                  },
                  _vm._l(_vm.data.tags, function (tag, index) {
                    return _c(
                      "div",
                      { key: `${tag}_${index}`, staticClass: "draggable-item" },
                      [
                        _vm._isNullOrEmpty(Object.keys(_vm.backups))
                          ? _c("i", {
                              staticClass: "fas fa-bars",
                              staticStyle: { "margin-right": "5px" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              display: "inline-block",
                              width: "calc(100% - 120px)",
                            },
                          },
                          [
                            _c(
                              "span",
                              {
                                class: {
                                  "limit-width": _vm.hasBackupSet(index, "npt"),
                                },
                              },
                              [
                                _c("iws-input", {
                                  ref: _vm.buildBackupKey(index, "npt"),
                                  refInFor: true,
                                  attrs: {
                                    value: tag,
                                    placeholder: "Provide a tag name",
                                    required: "",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      tag = $event
                                    },
                                    "pre-input": function ($event) {
                                      return _vm.addBackup(index, "npt")
                                    },
                                    input: function ($event) {
                                      return _vm.backupChanges(
                                        $event,
                                        index,
                                        "npt"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.hasBackupSet(index, "npt")
                              ? _c(
                                  "span",
                                  { staticClass: "action-buttons-container" },
                                  [
                                    _c("iws-button", {
                                      attrs: {
                                        type: "outline-primary",
                                        disabled: tag === null || tag === "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.confirmChanges(
                                            index,
                                            "npt"
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _c("i", {
                                                  staticClass: "fas fa-check",
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                    _vm._v(" "),
                                    _c("iws-button", {
                                      attrs: { type: "outline-danger" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.discardChanges(
                                            index,
                                            "npt"
                                          )
                                        },
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "text",
                                            fn: function () {
                                              return [
                                                _c("i", {
                                                  staticClass: "fas fa-times",
                                                }),
                                              ]
                                            },
                                            proxy: true,
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _vm._v(" "),
                        _c("library-crud-actions", {
                          staticClass: "pt-2 text-center",
                          attrs: {
                            id: `npt_actions_${index}`,
                            "view-actions": {
                              edit: false,
                              view: false,
                              delete: true,
                            },
                          },
                          on: {
                            delete: function ($event) {
                              return _vm.deleteAction(index)
                            },
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  0
                )
              : _vm.activity == "frac"
              ? [
                  _c("iws-table", {
                    attrs: {
                      columns: [
                        "",
                        "displayName",
                        "tagName",
                        "abbreviation",
                        "stageStart",
                        "stageEnd",
                        "",
                      ],
                      isRowDraggable: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header_displayName",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Display Name\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_tagName",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Tag Name\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_abbreviation",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Abbreviation\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_stageStart",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Include in stage start\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_stageEnd",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Include in stage end\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c(
                              "draggable",
                              {
                                attrs: { tag: "tbody" },
                                model: {
                                  value: _vm.data.tags,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "tags", $$v)
                                  },
                                  expression: "data.tags",
                                },
                              },
                              _vm._l(_vm.data.tags, function (tag, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", [
                                    _c("i", {
                                      staticClass: "fas fa-bars",
                                      staticStyle: { "margin-right": "5px" },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "limit-width": _vm.hasBackupSet(
                                            index,
                                            "displayName"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { attrs: { id: "display-name" } },
                                          [_vm._v(_vm._s(tag.displayName))]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "limit-width": _vm.hasBackupSet(
                                            index,
                                            "tagName"
                                          ),
                                        },
                                      },
                                      [
                                        _c("iws-input", {
                                          attrs: {
                                            value: tag.tagName,
                                            placeholder: "Provide a tag name",
                                            required: "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                tag,
                                                "tagName",
                                                $event
                                              )
                                            },
                                            "pre-input": function ($event) {
                                              return _vm.addBackup(
                                                index,
                                                "tagName"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.hasBackupSet(index, "tagName")
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "action-buttons-container",
                                          },
                                          [
                                            _c("iws-button", {
                                              attrs: {
                                                type: "outline-primary",
                                                disabled:
                                                  tag.tagName === null ||
                                                  tag.tagName === "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmChanges(
                                                    index,
                                                    "tagName"
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "text",
                                                    fn: function () {
                                                      return [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-check",
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("iws-button", {
                                              attrs: { type: "outline-danger" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.discardChanges(
                                                    index,
                                                    "tagName"
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "text",
                                                    fn: function () {
                                                      return [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-times",
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "limit-width": _vm.hasBackupSet(
                                            index,
                                            "abbreviation"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { attrs: { id: "display-name" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                tag?.abbreviation
                                                  ? tag?.abbreviation
                                                  : "Not Set"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("iws-checkbox", {
                                        attrs: { value: tag.startReport },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              tag,
                                              "startReport",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("iws-checkbox", {
                                        attrs: { value: tag.endReport },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              tag,
                                              "endReport",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "p-1 text-center" },
                                    [
                                      _c("library-crud-actions", {
                                        attrs: {
                                          id: `npt_actions_${index}`,
                                          "view-actions": {
                                            edit: false,
                                            view: false,
                                            delete: true,
                                          },
                                        },
                                        on: {
                                          delete: function ($event) {
                                            return _vm.deleteAction(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body text-dark" },
                    [
                      _c("iws-button", {
                        attrs: { type: "primary" },
                        on: { click: _vm.addOutput },
                        scopedSlots: _vm._u([
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "fas fa-plus-circle" }),
                                _vm._v(" Add Outputs\n                    "),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.data.outputs, function (output, index) {
                        return _c(
                          "div",
                          {
                            key: _vm.activity + "_" + index,
                            staticClass: "p-2 mb-2",
                          },
                          [
                            index ? _c("hr") : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group pt-3" },
                              [
                                _c("iws-input", {
                                  attrs: {
                                    value: output.url,
                                    label: "Type (teams, custom, ...)",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(output, "url", $event)
                                    },
                                    change: function ($event) {
                                      return _vm.updateOutput(
                                        $event,
                                        index,
                                        "type"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group mb-4" },
                              [
                                _c("iws-input", {
                                  attrs: { value: output.type, label: "Url" },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(output, "type", $event)
                                    },
                                    change: function ($event) {
                                      return _vm.updateOutput(
                                        $event,
                                        index,
                                        "url"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("iws-button", {
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteOutput(index)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "text",
                                    fn: function () {
                                      return [
                                        _c("i", {
                                          staticClass: "fas fa-trash-alt",
                                        }),
                                        _vm._v(
                                          " Delete\n                        "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              : _vm.activity == "wireline"
              ? [
                  _c("iws-table", {
                    attrs: {
                      columns: [
                        "",
                        "displayName",
                        "tagName",
                        "abbreviation",
                        "stageStart",
                        "stageEnd",
                        "",
                      ],
                      isRowDraggable: true,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "header_displayName",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Display Name\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_tagName",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Tag Name\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_abbreviation",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Abbreviation\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_stageStart",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Include in stage start\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "header_stageEnd",
                        fn: function () {
                          return [
                            _vm._v(
                              "\n                    Include in stage end\n                "
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "body",
                        fn: function () {
                          return [
                            _c(
                              "draggable",
                              {
                                attrs: { tag: "tbody" },
                                model: {
                                  value: _vm.data.tags,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.data, "tags", $$v)
                                  },
                                  expression: "data.tags",
                                },
                              },
                              _vm._l(_vm.data.tags, function (tag, index) {
                                return _c("tr", { key: index }, [
                                  _c("td", [
                                    _c("i", {
                                      staticClass: "fas fa-bars",
                                      staticStyle: { "margin-right": "5px" },
                                    }),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "limit-width": _vm.hasBackupSet(
                                            index,
                                            "displayName"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { attrs: { id: "display-name" } },
                                          [_vm._v(_vm._s(tag.displayName))]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "limit-width": _vm.hasBackupSet(
                                            index,
                                            "tagName"
                                          ),
                                        },
                                      },
                                      [
                                        _c("iws-input", {
                                          attrs: {
                                            value: tag.tagName,
                                            placeholder: "Provide a tag name",
                                            required: "",
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                tag,
                                                "tagName",
                                                $event
                                              )
                                            },
                                            "pre-input": function ($event) {
                                              return _vm.addBackup(
                                                index,
                                                "tagName"
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.hasBackupSet(index, "tagName")
                                      ? _c(
                                          "span",
                                          {
                                            staticClass:
                                              "action-buttons-container",
                                          },
                                          [
                                            _c("iws-button", {
                                              attrs: {
                                                type: "outline-primary",
                                                disabled:
                                                  tag.tagName === null ||
                                                  tag.tagName === "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.confirmChanges(
                                                    index,
                                                    "tagName"
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "text",
                                                    fn: function () {
                                                      return [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-check",
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                            _vm._v(" "),
                                            _c("iws-button", {
                                              attrs: { type: "outline-danger" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.discardChanges(
                                                    index,
                                                    "tagName"
                                                  )
                                                },
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "text",
                                                    fn: function () {
                                                      return [
                                                        _c("i", {
                                                          staticClass:
                                                            "fas fa-times",
                                                        }),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]),
                                  _vm._v(" "),
                                  _c("td", [
                                    _c(
                                      "span",
                                      {
                                        class: {
                                          "limit-width": _vm.hasBackupSet(
                                            index,
                                            "abbreviation"
                                          ),
                                        },
                                      },
                                      [
                                        _c(
                                          "p",
                                          { attrs: { id: "display-name" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                tag?.abbreviation
                                                  ? tag?.abbreviation
                                                  : "Not Set"
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("iws-checkbox", {
                                        attrs: { value: tag.startReport },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              tag,
                                              "startReport",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    [
                                      _c("iws-checkbox", {
                                        attrs: { value: tag.endReport },
                                        on: {
                                          "update:value": function ($event) {
                                            return _vm.$set(
                                              tag,
                                              "endReport",
                                              $event
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    { staticClass: "p-1 text-center" },
                                    [
                                      _c("library-crud-actions", {
                                        attrs: {
                                          id: `npt_actions_${index}`,
                                          "view-actions": {
                                            edit: false,
                                            view: false,
                                            delete: true,
                                          },
                                        },
                                        on: {
                                          delete: function ($event) {
                                            return _vm.deleteAction(index)
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]
                        },
                        proxy: true,
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "card-body text-dark" },
                    [
                      _c("iws-button", {
                        attrs: { type: "primary" },
                        on: { click: _vm.addOutput },
                        scopedSlots: _vm._u([
                          {
                            key: "text",
                            fn: function () {
                              return [
                                _c("i", { staticClass: "fas fa-plus-circle" }),
                                _vm._v(" Add Outputs\n                    "),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.data.outputs, function (output, index) {
                        return _c(
                          "div",
                          {
                            key: _vm.activity + "_" + index,
                            staticClass: "p-2 mb-2",
                          },
                          [
                            index ? _c("hr") : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group pt-3" },
                              [
                                _c("iws-input", {
                                  attrs: {
                                    value: output.url,
                                    label: "Type (teams, custom, ...)",
                                  },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(output, "url", $event)
                                    },
                                    change: function ($event) {
                                      return _vm.updateOutput(
                                        $event,
                                        index,
                                        "type"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "form-group mb-4" },
                              [
                                _c("iws-input", {
                                  attrs: { value: output.type, label: "Url" },
                                  on: {
                                    "update:value": function ($event) {
                                      return _vm.$set(output, "type", $event)
                                    },
                                    change: function ($event) {
                                      return _vm.updateOutput(
                                        $event,
                                        index,
                                        "url"
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c("iws-button", {
                              attrs: { type: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.deleteOutput(index)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "text",
                                    fn: function () {
                                      return [
                                        _c("i", {
                                          staticClass: "fas fa-trash-alt",
                                        }),
                                        _vm._v(
                                          " Delete\n                        "
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        )
                      }),
                    ],
                    2
                  ),
                ]
              : _vm._e(),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }