import { HubConnectionBuilder , LogLevel, HttpTransportType } from '@microsoft/signalr';
import GlobalFunctions from '../GlobalFunctions';
import { v4 as uuidv4 } from 'uuid';

const apiBaseUrl = process.env.MIX_SIGNALR_AZURE_FUNCTIONS_URL;
const isDevMode = process.env.MIX_SIGNALR_DEVMODE == 'true';
const devModeSecret = process.env.MIX_SIGNALR_DEVMODE_SECRET;
const MISSING_MESSAGES_THRESHOLD = process.env.MIX_MISSING_MESSAGES_THRESHOLD;
const MIX_IS_PREPROD = process.env.MIX_IS_PREPROD;


export default {
    data() {
        return {
            firstMessage: null,
            lastMessage: Date.now(),
            messageDelay: 0,
            signalrClientId: '',
            realtimedata: {},
            connection: null,
            connectionStopped: false,
            messageBuffer: [],
            lastProcessedCountNumber: null,
            isProcessingBufferedMessages: false,
            newMessageHandler: null,
            noModalOpen: true,
            latestReceivedCounter: null,
            currentlyFetchingRanges: new Set(),
            checkInterval: null,
            isFetching: false,
            currentFetchingRange : new Set(),
            fetchedRanges: new Set(),
            fetchAttempts: {},
            seconds:10,
            pageRefreshInterval: null,

        };
    },
    mounted() {
        // Setting up the interval when the component using this mixin is mounted
        this.checkInterval = setInterval(this.handleCheck, 1000);
    },

    beforeDestroy() {
        // Clearing the interval when the component using this mixin is about to be destroyed
        clearInterval(this.checkInterval);
    },
    methods: {
        async initializeSignalRConnection(sessionId, newMessageHandler, connectedHandler, connectionFailedHandler) {
            if(typeof apiBaseUrl === 'undefined' || !apiBaseUrl) {
                console.log("SignalR negotiation URL not found");
                return;
            }

            this.newMessageHandler = newMessageHandler;
            const self = this;
            this.signalrClientId = this.makeId();
            let connectionFailed = false;
            this.connectionStopped = false;
            try {
                const info = await this.getConnectionInfo(sessionId);
                if(info.error) {
                    //by default if there's no session or the customer does not have access to this job, this page cannot be loaded.
                    console.error(info.error);
                    return;
                }
                else if(!info) {
                    console.error('SignalR Connection Failed');
                    return;
                }

                info.accessToken = info.accessToken || info.accessKey;
                info.url = info.url || info.endpoint;
                const options = {
                    accessTokenFactory: () => info.accessToken,
                    skipNegotiation: true,
                    transport: HttpTransportType.WebSockets
                };

                const connection = new HubConnectionBuilder()
                    .withUrl(info.url, options)
                    .configureLogging(LogLevel.None)
                    .build();
                this.connection = connection;
                connection.on('newMessage', this.onReceiveMessage);
                connection.onclose(() => {
                    // eslint-disable-next-line no-console
                    console.log('signalR disconnected');
                    if (connectionFailedHandler && !this.connectionStopped) {
                        self.initializeSignalRConnection(sessionId, newMessageHandler, connectedHandler, connectionFailedHandler);
                        connectionFailedHandler(null,[sessionId, newMessageHandler, connectedHandler, connectionFailedHandler]);
                    }
                });
                await connection.start({ waitForPageLoad: false});
                connectedHandler();
                connectionFailed = false;
            } catch (error) {
                console.log('error connecting signalR', error);
                if (connectionFailedHandler) {
                    connectionFailed = true;
                    connectionFailedHandler(error, [sessionId, newMessageHandler, connectedHandler, connectionFailedHandler]);
                }
            }

            if(connectionFailed) {
                self.initializeSignalRConnection(sessionId, newMessageHandler, connectedHandler, connectionFailedHandler);
            }

            this.addToGroupRequest();
        },
        onReceiveMessage(message) {
            if(message?.data?.counter) {
                const counter = message.data.counter;
                this.latestReceivedCounter = Math.max(this.latestReceivedCounter || 0, counter);
                // If the message is in sequence or is the first one, process it.
                if (counter === this.lastProcessedCountNumber + 1 || this.lastProcessedCountNumber == null) {
                    this.newMessageHandler(message);
                    this.lastProcessedCountNumber = counter;
                } else {
                    // Buffer out-of-order messages
                    this.bufferMessage(message);
                }
            }else {
                this.newMessageHandler(message);
            }
        },
        bufferMessage(message) {
            if (!this.messageBuffer.some(msg => msg.data.counter === message.data.counter)) {
                this.messageBuffer.push(message);
                this.messageBuffer.sort((a, b) => a.data.counter - b.data.counter);
            }
        },
        processBufferedMessages() {
            while (this.messageBuffer.length && this.messageBuffer[0].data.counter === this.lastProcessedCountNumber + 1) {
                const message = this.messageBuffer.shift();
                // Process the message...
                this.newMessageHandler(message);
                this.lastProcessedCountNumber = message.data.counter;
            }
        },
        async fetchMissingMessages(startCounter, endCounter) {
            //check if difference between start and end is greater than threshold
            if(endCounter - startCounter > MISSING_MESSAGES_THRESHOLD) {
                this.refreshPage();
            }
            let rangeKey = `${startCounter}-${endCounter}`;
            if (this.currentFetchingRange.has(rangeKey)) {
                return;  // Skip if this range is already being fetched
            }
            this.currentFetchingRange.add(rangeKey);
            const url = `/missing-events/${this.jobNumber}`;
            const data = {
                '_token': GlobalFunctions.getCSRFToken(),
                minCount: startCounter,
                maxCount: endCounter
            };

            try {
                const result = await $.get(url, data);
                if (!result || result.length === 0) {
                    // Increase the fetch attempt count for this range
                    this.fetchAttempts[rangeKey] = (this.fetchAttempts[rangeKey] || 0) + 1;
                    // We can introduce a delay here if required before the next fetch attempt
                    setTimeout(() => {
                        this.fetchMissingMessages(startCounter, endCounter);
                    }, this.fetchAttempts[rangeKey]* 5000);  //  5 seconds delay * # of fetches, to taper off request load

                } else {
                    this.fetchedRanges.add(rangeKey);
                    result.forEach(msg => this.bufferMessage(msg));
                    this.processBufferedMessages();
                }
                this.currentFetchingRange.delete(rangeKey);
                return result;
            } catch (error) {
                console.error("Error fetching missing messages:", error);
            }
        },
        getConnectionInfo(currentSessionId) {
            let negotiateUrl = `${apiBaseUrl}/api/negotiate`;
            if(isDevMode) {
                negotiateUrl = `${apiBaseUrl}/api/negotiate-development`;
            }

            return axios.post(negotiateUrl, null , this.getAxiosConfig(currentSessionId))
                .then(resp => resp.data);
        },
        getAxiosConfig(currentSessionId) {
            const config = {
                headers: {
                    'session-id': currentSessionId,
                    'job-number': this.jobNumber,
                    'x-ms-client-principal-id': this.signalrClientId
                }
            };

            if(isDevMode && devModeSecret) {
                config.headers["development-key"] = devModeSecret;
            }

            // console.log("**** config", config)
            return config;
        },
        makeId() {
            const pageLoadId = uuidv4();
            const userId = this.userId ?? '00000000-0000-0000-0000-000000000000';
            const customerId = this.customerId ?? '00000000-0000-0000-0000-000000000000';
            const id = `${userId}:${this.jobNumber}:${customerId}:${pageLoadId}:desktop`;

            if(typeof MIX_IS_PREPROD !== 'undefined' && MIX_IS_PREPROD) {
                return id + '-preprod';
            }

            return id;
        },
        addToGroupRequest() {
            const self = this;
            const startTime = Date.now();
            // console.log("addToGroupRequest", self.signalrClientId, self.jobNumber, self.systemName)
            axios.post(
                apiBaseUrl+'/api/addToGroup',
                {
                    userId: self.signalrClientId,
                    group: self.jobNumber
                }
            ).then(resp=>{
                // console.log("addToGroup response", resp);
            });
        },
        endSignalRConnection() {
            if (this.connection != null) {
                this.connectionStopped = true;
                this.connection.stop().then(function() {
                    console.log('signalR connection closed');
                });
            }
        },
        async handleCheck() {
            while (this.isFetching) {
               await new Promise(resolve => setTimeout(resolve, 100)); // Wait for 100ms before checking again
           }
           if (this.lastProcessedCountNumber < this.latestReceivedCounter) {
               const missingRanges = this.getMissingRanges();
               for (const [start, end] of missingRanges) {
                   // Only fetch if the end of the range is greater than the last processed counter
                   if (end > this.lastProcessedCountNumber) {
                       const rangeKey = `${start}-${end}`;

                       if (!this.fetchedRanges.has(rangeKey)) {
                           this.fetchedRanges.add(rangeKey);
                           await this.fetchMissingMessages(start, end);
                       }
                   }
                   this.processBufferedMessages();
               }
           }

       },
       getMissingRanges() {
            let missingRanges = [];
            let lastEnd = this.lastProcessedCountNumber;
            for (let i = 0; i < this.messageBuffer.length; i++) {
                const counter = this.messageBuffer[i].data.counter;
                // If there's a gap between lastEnd and the current counter, push the range
                if (counter > lastEnd + 1) {
                    missingRanges.push([lastEnd + 1, counter - 1]);
                }
                lastEnd = counter;
            }

            return missingRanges;
        },
        generateLocalStorageKey() {
            let baseUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
            let encodedUrl = encodeURIComponent(baseUrl);
            return `signalRPageRefresh-${encodedUrl}`;
        },
        refreshPage(){
            const lastCalledTime  = localStorage.getItem(this.generateLocalStorageKey());
             // If there's a timestamp in localStorage for the current URL and it's within the last 20 minutes
            if (lastCalledTime && (Date.now() - lastCalledTime) <= 20 * 60 * 1000) {
                return;
            }
            GlobalFunctions.iwsConfirm({
                title: 'Page Refresh Required',
                body: 'It appears that a few updates were not received successfully. The page will refresh in 10 seconds to retrieve the missing data unless you confirm to stay.',
                confirmText: 'Refresh',
                rejectText: 'Stay',
            }).then(_answer => {
                if(!_answer) {
                    this.stay();
                }else{
                    localStorage.setItem(this.generateLocalStorageKey(), Date.now().toString());
                    window.location.reload();
                }
            })

            this.startCountdown();
        },
        startCountdown() {
            this.pageRefreshInterval = setInterval(() => {
                this.seconds--;

                if (this.seconds === 0) {
                    clearInterval(this.pageRefreshInterval);
                    localStorage.setItem(this.generateLocalStorageKey(), Date.now().toString());
                    window.location.reload();
                    this.noModalOpen = true;
                }
            }, 1000);
        },
        stay() {
            clearInterval(this.pageRefreshInterval);
            this.noModalOpen = true;
        }
    }
}
