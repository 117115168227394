var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-kpi-template", {
    ref: "baseKpiTemplate",
    attrs: {
      title: "Metrics Per Stage",
      item: _vm.item,
      "inital-options": _vm.initalOptions,
      "edit-mode": _vm.editMode,
      "create-export-data": _vm.createExportData,
      "analytics-data": _vm.analyticsData,
      errors: _vm.errors,
      "show-not-enough-data-warning": _vm.showNotEnoughDataWarning,
      "show-chart": _vm.showChart,
    },
    on: {
      analyticsChanges: function ($event) {
        return _vm.buildAnalytics()
      },
      settingsChanges: function ($event) {
        return _vm.buildData()
      },
      revertChanges: function ($event) {
        return _vm.buildData()
      },
    },
    scopedSlots: _vm._u(
      [
        {
          key: "settings",
          fn: function () {
            return [
              !_vm._isNullOrEmpty(_vm.metadataTags)
                ? [
                    _c("iws-select", {
                      attrs: {
                        options: [
                          { displayName: "None", tagName: -2 },
                          ..._vm.metadataTags,
                        ],
                        value: _vm.item.options.scatterTag,
                        label: "Scatter Tag",
                        "display-name": (tag) => tag.displayName || tag.tagName,
                        "value-name": "tagName",
                        "form-spacing": "",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(
                            _vm.item.options,
                            "scatterTag",
                            $event
                          )
                        },
                        "pre-change": ($event) => _vm.clearTagError($event),
                        change: function ($event) {
                          return _vm.handleTagChange(
                            _vm.item.options.scatterTag,
                            null,
                            "scatterJSON"
                          )
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("iws-select", {
                      attrs: {
                        options: [
                          { displayName: "None", tagName: -2 },
                          ..._vm.metadataTags,
                        ],
                        value: _vm.item.options.barTag,
                        label: "Bar Tag",
                        "display-name": (tag) => tag.displayName || tag.tagName,
                        "value-name": "tagName",
                        "form-spacing": "",
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(_vm.item.options, "barTag", $event)
                        },
                        "pre-change": ($event) => _vm.clearTagError($event),
                        change: function ($event) {
                          return _vm.handleTagChange(
                            _vm.item.options.barTag,
                            null,
                            "barJSON"
                          )
                        },
                      },
                    }),
                  ]
                : _c("div", [
                    _vm._v("\n            No Customer Tags Found\n        "),
                  ]),
              _vm._v(" "),
              _vm.showLimitStageMetrics
                ? _c("div", { staticClass: "stages-limit-container" }, [
                    _c(
                      "span",
                      { staticClass: "stages-limit-main" },
                      [
                        _c("iws-input", {
                          attrs: {
                            label: "Stages Shown",
                            placeholder: "Limit the stages shown",
                            value: _vm.item.options.limitStagesMetricsPerStage,
                            min: "0",
                            type: "number",
                            "form-spacing": "",
                          },
                          on: {
                            "update:value": function ($event) {
                              return _vm.$set(
                                _vm.item.options,
                                "limitStagesMetricsPerStage",
                                $event
                              )
                            },
                            input: function ($event) {
                              return _vm.buildData()
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "span",
                      { staticClass: "stages-limit-actions" },
                      [
                        _c("iws-button", {
                          attrs: { text: "Clear", type: "dark" },
                          on: { click: _vm.clearLimitMetrics },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("iws-button", {
                    staticStyle: { "margin-top": "15px" },
                    attrs: { text: "Limit Stages Shown", type: "dark" },
                    on: {
                      click: function ($event) {
                        _vm.showLimitStageMetrics = true
                      },
                    },
                  }),
              _vm._v(" "),
              _c("iws-checkbox", {
                attrs: {
                  label: "Average Wells",
                  value: _vm.item.options.averageWellDataMetricsPerStage,
                  "form-spacing": "",
                  "enable-click-label": "",
                },
                on: {
                  "update:value": function ($event) {
                    return _vm.$set(
                      _vm.item.options,
                      "averageWellDataMetricsPerStage",
                      $event
                    )
                  },
                  change: function ($event) {
                    return _vm.buildData()
                  },
                },
              }),
            ]
          },
          proxy: true,
        },
        _vm.showChart
          ? {
              key: "content",
              fn: function () {
                return [
                  _c("bar-chart", {
                    ref: "kpi",
                    attrs: { "chart-data": _vm.kpi, options: _vm.options },
                  }),
                ]
              },
              proxy: true,
            }
          : null,
      ],
      null,
      true
    ),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }