<template>
    <div class="d-flex border border-danger rounded px-2 mx-2 mb-2 mt-1" ref="infoBox" style="height:26px; width: 171px;">
        <div class="align-self-center">
            Latency :
        </div>
        <div class="d-flex align-items-center">
            <div  :id="'missed-tag-' + index" v-for="(circle, index) in circleStatus" :key="index" class="rounded-circle mx-1 circle-css" :class="circle.isFlashing? 'blink_me': '' " :style="{ ...cssVars, backgroundColor: circle.color}">
            </div>
        </div>
        <div class="align-self-center">
            <i id="latency-tags-dropdown" class="fas fa-caret-down"></i>
        </div>
        <b-popover target="latency-tags-dropdown" triggers="hover" placement="bottom" :delay="{show: 0, hide: 500}" boundary="viewport">
            <div class="text-white h6 mb-0">
                Active wells <span><small> ( Frac or Wireline tags will be tracked based on well status ) </small></span>
            </div>
            <div class="pl-2 pb-2 text-white">
                <div v-for="(aWell, index) in activeWells" :key="index">
                    {{ aWell.name }} - {{ aWell.activity }}
                </div>
                <div  v-if="activeWells.length == 0"> No wells are active</div>
            </div>
            <div class="d-flex flex-column mt-2">
                <div>
                    <div v-if="fracTags.length === 0" class="text-white pl-2"> <small>No active frac tags</small> </div>
                    <checkbox-list
                        v-else
                        :enableSelectAllOption="true"
                        :enableSearch="true"
                        label="Frac tags"
                        :valueKey="'prioritizedTagName'"
                        :outputKey="'name'"
                        height="200"
                        :options="fracTags"
                        v-bind:selectedOptions="selectedFracTags"
                        v-on:update:selectedOptions="selectedFracTags = $event">
                    </checkbox-list>
                    <div v-if="wirelineTags.length === 0" class="text-white pl-2"><small> No active wireline tags.</small> </div>
                    <checkbox-list
                        v-else
                        :enableSearch="true"
                        :enableSelectAllOption="true"
                        label="Wireline tags"
                        :valueKey="'prioritizedTagName'"
                        :outputKey="'name'"
                        height="200"
                        :options="wirelineTags"
                        v-bind:selectedOptions="selectedWirelineTags"
                        v-on:update:selectedOptions="selectedWirelineTags = $event">
                    </checkbox-list>
                </div>
            </div>
            <div class="d-flex flex-row-reverse mt-3 mb-2">
                <div>
                    <button v-if="latencySaved" type="button" class="btn btn-success green-button float-right ml-2" @click.prevent="onSaveLatencyTags($event)">
                        Saved!
                    </button>
                    <button v-else-if="!latencySaving" type="button" class="btn btn-success green-button float-right ml-2" @click.prevent="onSaveLatencyTags($event)">
                        Save
                    </button>
                    <button v-else type="button" class="btn btn-success green-button float-right ml-2" disabled>
                        Saving...
                    </button>
                </div>
                <div>
                    <button type="button" class="btn btn-secondary grey-button float-right ml-2" @click.prevent="onClearLatencyTags($event)">
                        Clear
                    </button>
                </div>
            </div>
        </b-popover>
        <b-popover  target="missed-tag-0" triggers="hover" placement="bottom" :delay="{show: 0, hide: 500}" boundary="viewport" @show="(bvEvent)=>onPopoverShow(bvEvent,'level1')">
            <div class="d-flex flex-column mt-2 text-white">
                <h6>15 Seconds Indicator</h6>
                <div v-for="(tags, index) in channelsMissingData.level1" :key="index">
                    {{tags.friendlyTagname}}
                </div>
                <div v-if="channelsMissingData.level1.length == 0">
                    --
                </div>
            </div>
        </b-popover>
        <b-popover  target="missed-tag-1" triggers="hover" placement="bottom" :delay="{show: 0, hide: 500}" boundary="viewport" @show="(bvEvent)=>onPopoverShow(bvEvent,'level2')">
            <div class="d-flex flex-column mt-2 text-white">
                <h6>30 Seconds Indicator</h6>
                <div v-for="(tags, index) in channelsMissingData.level2" :key="index">
                    {{tags.friendlyTagname}}
                </div>
                <div v-if="channelsMissingData.level2.length == 0">
                    --
                </div>
            </div>
        </b-popover>
        <b-popover  target="missed-tag-2" triggers="hover" placement="bottom" :delay="{show: 0, hide: 500}" boundary="viewport" @show="(bvEvent)=>onPopoverShow(bvEvent,'level3')">
            <div class="d-flex flex-column mt-2 text-white">
                <h6>60 Seconds Indicator</h6>
                <div v-for="(tags, index) in channelsMissingData.level3" :key="index">
                    {{tags.friendlyTagname}}
                </div>
                <div v-if="channelsMissingData.level3.length == 0">
                    --
                </div>
            </div>
        </b-popover>
    </div>
</template>

<style scoped>
    .circle-css {
        height: var(--circle-height);
        width: var(--circle-height) ;
    }
    .blink_me {
        opacity: var(--circle-opacity) ;
    }
    .checkbox-list-container {
        width: 100% !important
    }
</style>
<style>
    .select-all-container {
        align-items: center;
        display: flex;
    }
</style>
<script>
import moment from 'moment';
const CIRCLE_COLORS = {
    red: '#ff001b',
    yellow: '#fff739',
    green: '#00ff37'
};
const CIRCLE_OFFSET = 5;
const ITEM_TYPE = 'latency-component';
export default {
    props: {
        tags: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        latestDataCollection: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        items: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        wells: {
            type: [Array],
            default: ()=> [],
            required: true
        },
        utcDifference: {
            type: [Number],
            default: ()=> 0,
            required: true
        },
        latencySaving: {
            type: Boolean,
            default: false,
            required: true
        },
        latencySaved: {
            type: Boolean,
            defualt: false,
            required: true
        }
    },
    data() {
        return {
            data: null,
            currentActivity: [],
            currentLevel: 0,
            tagDetails: {},
            circleHeight: 0,
            blinkIntervals: [0, 0.2, 0.4, 0.6, 0.8, 1],
            circleStatus: [
                { color: CIRCLE_COLORS.green, isFlashing: false },
                { color: CIRCLE_COLORS.green, isFlashing: false },
                { color: CIRCLE_COLORS.green, isFlashing: false }
            ],
            selectedTags: [],
            circleOpacity: 1,
            channelsMissingData: {
                level1: [],
                level2: [],
                level3: [],
                level4: []
            }
        };
    },
    watch: {
        'tags': {
            handler: function (newValue, oldValue) {
                const availableTags = newValue.map((tag)=>tag.name);
                if(availableTags.length > 0 && this.initSelectedTags) {
                    this.selectedTags = this.initSelectedTags.filter((tag)=> {
                        return availableTags.includes(tag.name)? true : false;
                    });
                } else {
                    this.selectedTags = [];
                }
            },
            deep: true
        },
        'latestDataCollection': {
            handler: function (newValue, oldValue) {
                let currentMoment = moment.utc();
                currentMoment = moment.utc(currentMoment.valueOf() + this.utcDifference);
                let selectedTagList = [];

                this.currentActivity.forEach(activity => {
                    if(activity === 'frac') {
                        selectedTagList.push(...this.selectedFracTags);
                    }

                    if(activity === 'wireline') {
                        selectedTagList.push(...this.selectedWirelineTags);
                    }
                });

                selectedTagList.forEach(tag => {
                    const newData = newValue.find((data)=> data.tagName == tag);
                    const tagObject = this.tags.find((data)=> data.name == tag);
                    if(typeof newData !== 'undefined' && tagObject ) {
                        let currentMoment = moment.utc();
                        currentMoment = moment.utc(currentMoment.valueOf() + this.utcDifference);
                        const tagMoment = moment.utc(newData.dateTimestamp);
                        const delay = currentMoment.diff(tagMoment, 'seconds');

                        if(this.tagDetails[tag]) {
                            this.tagDetails[tag].timeoutVar.forEach(element => {
                                clearTimeout(element);
                            });
                        }

                        if(delay < 15) {
                            for (const [key, value] of Object.entries(this.channelsMissingData)) {
                                this.channelsMissingData[key] = this.channelsMissingData[key].filter((tagData)=>{
                                    return tagData.name !== tag;
                                });
                            }

                            this.tagDetails[tag] = { 
                                ...this.tagDetails[tag],
                                lastUpdate: newData.dateTimestamp,
                                currentLevel: 0,
                                timeoutVar: [
                                    setTimeout(()=> {
                                        this.channelsMissingData.level1.push(tagObject);
                                        this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                                    }, 15000),
                                    setTimeout(()=> {
                                        this.channelsMissingData.level2.push(tagObject);
                                        this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                                    }, 30000),
                                    setTimeout(()=> {
                                        this.channelsMissingData.level3.push(tagObject);
                                        this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                                    }, 45000),
                                    setTimeout(()=> {
                                        this.channelsMissingData.level4.push(tagObject);
                                        this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                                    }, 60000)
                                ]
                            };
                        } else if(delay >= 15 && delay < 30) {
                            this.tagDetails[tag] = { 
                                ...this.tagDetails[tag],
                                lastUpdate: newData.dateTimestamp,
                                currentLevel: 1,
                                timeoutVar: [
                                    setTimeout(()=> {
                                        this.channelsMissingData.level2.push(tagObject);
                                        this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                                    }, 15000)
                                ]
                            };
                        } else if (delay >= 30 && delay < 45) {
                            this.tagDetails[tag] = { 
                                ...this.tagDetails[tag],
                                lastUpdate: newData.dateTimestamp,
                                currentLevel: 2,
                                timeoutVar: [
                                    setTimeout(()=> {
                                        this.channelsMissingData.level3.push(tagObject);
                                        this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                                    }, 15000)
                                ]
                            };
                        } else if (delay >= 45 && delay < 60) {
                            this.tagDetails[tag] = { 
                                ...this.tagDetails[tag],
                                lastUpdate: newData.dateTimestamp,
                                currentLevel: 3,
                                timeoutVar: [
                                    setTimeout(()=> {
                                        this.channelsMissingData.level4.push(tagObject);
                                        this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                                    }, 15000)
                                ]
                            };
                        } else if (delay >= 60) {
                            this.tagDetails[tag] = { 
                                ...this.tagDetails[tag],
                                lastUpdate: newData.dateTimestamp,
                                currentLevel: 4,
                                timeoutVar: []
                            };
                        }

                        for (let index = 1; index <= this.tagDetails[tag].currentLevel; index++) {
                            const isAlreadyExist = this.channelsMissingData['level' + index].findIndex((tagData)=> tagData.name == tag);
                            if(isAlreadyExist < 0) {
                                this.channelsMissingData['level' + index].push(tagObject);
                            }
                        }
                    }
                });
            },
            deep: true
        },
        'channelsMissingData': {
            handler: function (newValue, oldValue) {
                if(newValue.level4.length > 0 ) {
                    this.currentLevel = 4;
                } else if(newValue.level3.length > 0 ) {
                    this.currentLevel = 3;
                } else if(newValue.level2.length > 0 ) {
                    this.currentLevel = 2;
                }  else if(newValue.level1.length > 0 ) {
                    this.currentLevel = 1;
                } else {
                    this.currentLevel = 0;
                }
            },
            deep: true
        },
        'currentLevel': {
            handler: function (newValue, oldValue) {
                if(newValue === 0) { //all clear
                    this.circleStatus = [
                        { color: CIRCLE_COLORS.green, isFlashing: false },
                        { color: CIRCLE_COLORS.green, isFlashing: false },
                        { color: CIRCLE_COLORS.green, isFlashing: false }
                    ];
                } else if(newValue === 1) { // 1 circle flash (red)
                    this.circleStatus = [
                        { color: CIRCLE_COLORS.red, isFlashing: true },
                        { color: CIRCLE_COLORS.green, isFlashing: false },
                        { color: CIRCLE_COLORS.green, isFlashing: false }
                    ];                    
                } else if(newValue === 2) { // 1 & 2 flash (red)
                    this.circleStatus = [
                        { color: CIRCLE_COLORS.red, isFlashing: true },
                        { color: CIRCLE_COLORS.red, isFlashing: true },
                        { color: CIRCLE_COLORS.green, isFlashing: false }
                    ];
                } else if(newValue === 3) { // 1 & 2 & 3 flash (red and yellow )
                    this.circleStatus = [
                        { color: CIRCLE_COLORS.red, isFlashing: true },
                        { color: CIRCLE_COLORS.red, isFlashing: true },
                        { color: CIRCLE_COLORS.yellow, isFlashing: true }
                    ];
                } else if(newValue === 4) { // 1 & 2 & 3 flash (red )
                    this.circleStatus = [
                        { color: CIRCLE_COLORS.red, isFlashing: true },
                        { color: CIRCLE_COLORS.red, isFlashing: true },
                        { color: CIRCLE_COLORS.red, isFlashing: true }
                    ];
                }
            },
            deep: true
        },
        'activeWells': {
            handler: function (newValue, oldValue) {
                this.setCurrentActivity();
                this.$nextTick(()=>{
                    this.resetTagTimeoutsLevels();
                    this.setTagIntervals();
                });
            },
            deep: true
        }
    },
    computed: {
        initSelectedTags() {
            const itemIndex = this.items.findIndex((item)=> item.type === ITEM_TYPE);
            if(itemIndex > -1 && Array.isArray(this.items[itemIndex].options) && this.items[itemIndex].options.length) {
                return this.items[itemIndex].options;
            }else{
                return  [...this.fracTags.map((tag)=>tag.name),...this.wirelineTags.map((tag)=>tag.name)];
            }
        },
        activeWells() {
            return this.wells.filter((well)=> (well.activity == 'wireline' || well.activity == 'frac'));
        },
        cssVars() {
            return {
                '--circle-height': (this.circleHeight - CIRCLE_OFFSET) + 'px',
                '--circle-opacity': this.circleOpacity
            };
        },
        fracTags() {
            const filteredFracTags = this.tags.filter((tag)=>(tag.name.startsWith('frac_') || tag.name.startsWith('wellhead_')  || tag.name.startsWith('fracStage')));
            const sortedFracTags = filteredFracTags.sort((a, b) => a.prioritizedTagName.localeCompare(b.prioritizedTagName));
            return sortedFracTags;
        },
        wirelineTags() {
            const filteredWirelineTags = this.tags.filter((tag)=>(tag.name.startsWith('wireline_') || tag.name.startsWith('pumpdown_')));
            const sortedWirelineTags = filteredWirelineTags.sort((a, b) => a.prioritizedTagName.localeCompare(b.prioritizedTagName));
            return sortedWirelineTags;
        },
        selectedFracTags: {
            get: function () {
                return this.selectedTags.filter((tag)=>{
                    if(tag.name){
                        return tag.name.startsWith('frac_') || tag.name.startsWith('wellhead_')  || tag.name.startsWith('fracStage');
                    }
                });
            },
            set: function (newFracTags) {
                this.selectedTags = [...this.selectedWirelineTags, ...newFracTags];
            }
        },
        selectedWirelineTags: {
            get: function () {
                return this.selectedTags.filter((tag)=>{
                    if(tag.name){
                        return tag.name.startsWith('wireline_') || tag.name.startsWith('pumpdown_');
                    }
                });
            },
            set: function (newWirelineTags) {
                this.selectedTags = [...this.selectedFracTags, ...newWirelineTags];
            }
        }
    },
    mounted() {
        this.$nextTick(()=>{
            this.circleHeight = this.$refs.infoBox.clientHeight;
        });

        setInterval(()=>{
            const index = this.blinkIntervals.findIndex(interval => interval === this.circleOpacity);
            const nextIndex = (index+1 > this.blinkIntervals.length)? 0 : index + 1;
            this.circleOpacity = this.blinkIntervals[nextIndex];
        }, 100);

        const itemIndex = this.items.findIndex((item)=> item.type === ITEM_TYPE);
        if(itemIndex > -1 && Array.isArray(this.items[itemIndex].options) && this.items[itemIndex].options.length) {
            this.selectedTags = this.items[itemIndex].options
        }else{
            //On first viewing of this component it should default to selecting all of the tags
            //Subsequently we should just use the actual set ones
            this.selectedTags = [...this.fracTags.map((tag)=>tag.name),...this.wirelineTags.map((tag)=>tag.name)];
        }
        this.setCurrentActivity();
        this.$nextTick(()=>{
            this.resetTagTimeoutsLevels();
            this.setTagIntervals();
        });
    },
    methods: {
        setTagIntervals() {
            const selectedTagList = [];
            this.currentActivity.forEach(activity => {
                if(activity === 'frac') {
                    selectedTagList.push(...this.selectedFracTags);
                }

                if(activity === 'wireline') {
                    selectedTagList.push(...this.selectedWirelineTags);
                }
            });

            selectedTagList.forEach(tag => {
                const tagObject = this.tags.find((data)=> data.name == tag);
                if(tagObject) {
                    this.tagDetails[tag] = { 
                        lastUpdate: moment.utc().valueOf(), 
                        timeoutVar: [
                            setTimeout(()=> {
                                this.channelsMissingData.level1.push(tagObject);
                                this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                            }, 15000),
                            setTimeout(()=> {
                                this.channelsMissingData.level2.push(tagObject);
                                this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                            }, 30000),
                            setTimeout(()=> {
                                this.channelsMissingData.level3.push(tagObject);
                                this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                            }, 45000),
                            setTimeout(()=> {
                                this.channelsMissingData.level4.push(tagObject);
                                this.tagDetails[tag].currentLevel = this.tagDetails[tag].currentLevel+1;
                            }, 60000)
                        ],
                        currentLevel: 0 
                    };
                }
            });
        },
        resetTagTimeoutsLevels() {
            for (const [key, tag] of Object.entries(this.tagDetails)) {
                if (tag) {
                    tag.timeoutVar.forEach(element => {
                        clearTimeout(element);
                    });

                    this.tagDetails[key] = {
                        ...tag,
                        currentLevel: 0
                    };
                }
            }
        },
        setCurrentActivity() {
            this.currentActivity = [];
            this.activeWells.forEach(well => {
                if(well.activity === 'frac') {
                    this.currentActivity.push('frac');
                } else if(well.activity === 'wireline') {
                    this.currentActivity.push('wireline');
                }   
            });

            this.currentActivity = [...new Set(this.currentActivity)];
        },
        onPopoverShow(event, level) {
            if(this.channelsMissingData[level].length === 0) {
                event.preventDefault();
            }
        },
        onSaveLatencyTags() {
            const itemIndex = this.items.findIndex((item)=> item.type === ITEM_TYPE);
            if(itemIndex < 0) { //item not found
                const newItem = {
                    type: ITEM_TYPE,
                    options: [...this.selectedTags]
                };
                this.items.push(newItem);
            } else {
                this.items[itemIndex] = {
                    ...this.items[itemIndex],
                    options: [...this.selectedTags]
                };
            }
            this.$emit('onSavePressed');
        },	
        onClearLatencyTags() {
            this.selectedTags = [];
        }					
    }
};
</script>
