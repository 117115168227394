var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row", attrs: { id: "job-control-bar" } }, [
    (!_vm.isHome && !_vm.isClips) || !_vm._isNullOrEmpty(_vm.cameras)
      ? _c(
          "div",
          { staticClass: "col-sm-12 col-md-7" },
          [
            !_vm.isHome && !_vm.isClips
              ? _c(
                  "a",
                  {
                    staticStyle: {
                      display: "inline-block",
                      position: "relative",
                      top: "-3px",
                      width: "38px",
                    },
                    attrs: { href: `/cameras/viewer/${_vm.workOrderId}` },
                  },
                  [
                    !_vm.isHome && !_vm.isClips
                      ? _c("iws-button", {
                          attrs: {
                            type: "outline-light",
                            "prepend-icon": "fas fa-chevron-left",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm._isNullOrEmpty(_vm.cameras)
              ? _c("iws-select", {
                  staticClass: "ml-2",
                  attrs: {
                    placeholder: _vm.cameraPlaceholder,
                    options: _vm.cameras,
                    "visible-options": 10,
                    searchable: false,
                    "full-placeholder": "",
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "option",
                        fn: function ({ data, option }) {
                          return [
                            _c(
                              "div",
                              {
                                style: `padding: ${
                                  !data?.index ? "5px" : "15px"
                                } 0px 0px 7.5px; font-size: 20px; font-weight: 700;`,
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(
                                      option?.displayName ||
                                        option?.name ||
                                        `Camera: ${data.index + 1}`
                                    ) +
                                    "\n                "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _vm._l(
                              option?.streamConfigurations,
                              function (stream, streamIndex) {
                                return _c(
                                  "option",
                                  {
                                    staticClass: "pl-4",
                                    class: {
                                      selected:
                                        stream.cameraStreamConfigId ==
                                        _vm.cameras[_vm.cameraIndex]
                                          ?.primaryStream?.cameraStreamConfigId,
                                      "no-border-option": !streamIndex,
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onStreamChange(
                                          option,
                                          stream
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                    • " +
                                        _vm._s(
                                          stream?.name ||
                                            `Camera: ${streamIndex}`
                                        ) +
                                        "\n                "
                                    ),
                                  ]
                                )
                              }
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    1304157001
                  ),
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "col-sm-12 col-md" }, [
      _c("span", [
        _vm.iwsUser
          ? _c(
              "a",
              {
                attrs: {
                  href: `/cameras/settings/${_vm.workOrderId}/${_vm.device.deviceId}`,
                },
              },
              [
                _c("iws-button", {
                  attrs: {
                    type: "text-light",
                    "prepend-icon": "fas fa-cog",
                    text: "Configure",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isClips
          ? _c(
              "a",
              { attrs: { href: `/cameras/clips/${_vm.workOrderId}` } },
              [
                _c("iws-button", {
                  attrs: {
                    type: "text-light",
                    "prepend-icon": "fas fa-photo-video",
                    text: "Manage Clips",
                  },
                }),
              ],
              1
            )
          : _c(
              "a",
              { attrs: { href: `/cameras/viewer/${_vm.workOrderId}` } },
              [
                _c("iws-button", {
                  attrs: {
                    type: "text-light",
                    "prepend-icon": "fas fa-video",
                    text: "SiteVision",
                  },
                }),
              ],
              1
            ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }