var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "view-control-container" } },
    [
      _c(
        "div",
        { attrs: { id: "camera-positioning" } },
        [
          _c(
            "div",
            {
              class: { disabled: !_vm.tiltEnabled },
              attrs: { id: "pan-tilt-control" },
            },
            [
              _c("div", { staticClass: "slice", attrs: { id: "up" } }, [
                _c(
                  "div",
                  {
                    staticClass: "slice-contents clickable",
                    on: {
                      click: function ($event) {
                        return _vm.ptzCommand("up")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-chevron-up clickable" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "slice", attrs: { id: "left" } }, [
                _c(
                  "div",
                  {
                    staticClass: "slice-contents clickable",
                    on: {
                      click: function ($event) {
                        return _vm.ptzCommand("left")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-chevron-up clickable" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "slice", attrs: { id: "right" } }, [
                _c(
                  "div",
                  {
                    staticClass: "slice-contents clickable",
                    on: {
                      click: function ($event) {
                        return _vm.ptzCommand("right")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-chevron-up clickable" })]
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "slice", attrs: { id: "down" } }, [
                _c(
                  "div",
                  {
                    staticClass: "slice-contents clickable",
                    on: {
                      click: function ($event) {
                        return _vm.ptzCommand("down")
                      },
                    },
                  },
                  [_c("i", { staticClass: "fas fa-chevron-up clickable" })]
                ),
              ]),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "clickable",
                  attrs: { id: "home" },
                  on: {
                    click: function ($event) {
                      return _vm.goHome()
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-home" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c("iws-slider", {
            staticClass: "mt-2 full-width",
            attrs: {
              value: _vm.cameraDetails.speedValue,
              label: "Pan / Tilt Speed",
              max: _vm.cameraDetails.speedMax,
              min: _vm.cameraDetails.speedMin,
              step:
                (_vm.cameraDetails.speedMax - _vm.cameraDetails.speedMin) / 20,
              disabled: !_vm.tiltEnabled,
            },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.cameraDetails, "speedValue", $event)
              },
              "disabled-click": function ($event) {
                return _vm.onDisabledClick(
                  "Please contact your admin",
                  !!_vm.cameraDetails?.tiltEnabled
                    ? "Tilt is not enabled on this device"
                    : "You do not have access to use pan / tilt"
                )
              },
              change: _vm.postTiltSpeed,
            },
            scopedSlots: _vm._u([
              {
                key: "min",
                fn: function () {
                  return [_vm._v("\r\n                Min\r\n            ")]
                },
                proxy: true,
              },
              {
                key: "max",
                fn: function () {
                  return [_vm._v("\r\n                Max\r\n            ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("iws-slider", {
            staticClass: "mt-4 full-width",
            attrs: {
              value: _vm.zoomValue,
              label: "Zoom",
              max: _vm.cameraDetails.zoomMax,
              min: _vm.cameraDetails.zoomMin,
              step:
                (_vm.cameraDetails.zoomMax - _vm.cameraDetails.zoomMin) / 20,
              disabled: !_vm.zoomEnabled,
            },
            on: {
              "update:value": function ($event) {
                _vm.zoomValue = $event
              },
              "disabled-click": function ($event) {
                return _vm.onDisabledClick(
                  "Please contact your admin",
                  !!_vm.cameraDetails?.zoomEnabled
                    ? "Zoom is not enabled on this device"
                    : "You do not have access to use zoom"
                )
              },
              change: _vm.postZoom,
            },
            scopedSlots: _vm._u([
              {
                key: "min",
                fn: function () {
                  return [_vm._v("\r\n                Min\r\n            ")]
                },
                proxy: true,
              },
              {
                key: "max",
                fn: function () {
                  return [_vm._v("\r\n                Max\r\n            ")]
                },
                proxy: true,
              },
            ]),
          }),
          _vm._v(" "),
          _c("iws-slider", {
            staticClass: "mt-4 full-width",
            attrs: {
              value: _vm.cameraDetails.zoomSpeedValue,
              label: "Zoom Speed",
              max: _vm.cameraDetails.zoomSpeedMax,
              min: _vm.cameraDetails.zoomSpeedMin,
              step:
                (_vm.cameraDetails.zoomSpeedMax -
                  _vm.cameraDetails.zoomSpeedMin) /
                20,
              disabled: !_vm.zoomEnabled,
            },
            on: {
              "update:value": function ($event) {
                return _vm.$set(_vm.cameraDetails, "zoomSpeedValue", $event)
              },
              "disabled-click": function ($event) {
                return _vm.onDisabledClick(
                  "Please contact your admin",
                  !!_vm.cameraDetails?.zoomEnabled
                    ? "Zoom is not enabled on this device"
                    : "You do not have access to use zoom speed"
                )
              },
              change: _vm.postZoomSpeed,
            },
            scopedSlots: _vm._u([
              {
                key: "min",
                fn: function () {
                  return [_vm._v("\r\n                Min\r\n            ")]
                },
                proxy: true,
              },
              {
                key: "max",
                fn: function () {
                  return [_vm._v("\r\n                Max\r\n            ")]
                },
                proxy: true,
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.presets !== null
        ? [
            _c("hr"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-2", staticStyle: { "font-size": "18px" } },
              [_vm._v("\r\n            Presets\r\n        ")]
            ),
            _vm._v(" "),
            _c("iws-select", {
              ref: "presetSelect",
              attrs: {
                value: _vm.activePreset,
                placeholder: _vm.presetPlaceholder,
                options: _vm.presets,
                "display-name": "name",
                disabled: _vm._isNullOrEmpty(_vm.presets) || _vm.isFixed,
              },
              on: { change: _vm.gotoPreset },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option_text",
                    fn: function ({ data }) {
                      return [
                        !_vm._isNullOrEmpty(data?.option?.redzones)
                          ? [_vm._v("🟥")]
                          : _vm._e(),
                        _vm._v(
                          "\r\n                " +
                            _vm._s(data?.option?.name) +
                            "\r\n            "
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                625593025
              ),
            }),
            _vm._v(" "),
            _vm.permissions?.edit
              ? _c(
                  "div",
                  { staticClass: "mt-1" },
                  [
                    !_vm.presetIsActive
                      ? _c("iws-button", {
                          attrs: {
                            type: "text-primary",
                            text: "Save as Preset",
                          },
                          on: {
                            click: function ($event) {
                              _vm.addNewPreset = true
                            },
                          },
                        })
                      : _c("iws-button", {
                          attrs: {
                            type: "text-danger",
                            text: "Delete",
                            click: () => _vm.deletePreset(_vm.activePreset),
                          },
                        }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.permissions?.admin
        ? [
            _c("hr"),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "mb-2", staticStyle: { "font-size": "18px" } },
              [_vm._v("\r\n            Redzone\r\n        ")]
            ),
            _vm._v(" "),
            !_vm.drawingMode
              ? [
                  _c("iws-button", {
                    attrs: {
                      text: "Add Redzone",
                      type: "primary",
                      disabled: !_vm.camera?.primaryStream?.isAIEnabled,
                    },
                    on: {
                      click: _vm.onAddRedzoneClicked,
                      disabledClick: function ($event) {
                        return _vm._toast({
                          title: "Not Enabled",
                          body: "AI Redzone is not enabled on this stream",
                          variant: "danger",
                        })
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("iws-button", {
                    staticClass: "float-right",
                    attrs: {
                      text: `Manage (${
                        _vm.activePreset?.redzones?.length || 0
                      })`,
                      type: "light",
                      disabled: !_vm.redzoneIsActive,
                    },
                    on: {
                      click: function ($event) {
                        _vm.manageRedzoneModal = true
                      },
                    },
                  }),
                ]
              : [
                  _c("iws-button", {
                    attrs: {
                      text: "Save Redzone",
                      type: "primary",
                      disabled: !_vm.camera?.primaryStream?.isAIEnabled,
                    },
                    on: {
                      click: function ($event) {
                        _vm.nameRedzoneModal = true
                      },
                      disabledClick: function ($event) {
                        return _vm._toast({
                          title: "Not Enabled",
                          body: "AI Redzone is not enabled on this stream",
                          variant: "danger",
                        })
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("iws-button", {
                    staticClass: "float-right",
                    attrs: { text: "Cancel", type: "light" },
                    on: { click: _vm.cancelRedzone },
                  }),
                ],
          ]
        : _vm._e(),
      _vm._v(" "),
      [
        _c("hr"),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-1", staticStyle: { "font-size": "18px" } },
          [
            _vm._v("\r\n            Patrol Mode\r\n            "),
            _vm.permissions?.admin
              ? _c("iws-button", {
                  attrs: {
                    type: "text-primary",
                    text: "Edit",
                    disabled: _vm.isFixed,
                  },
                  on: {
                    click: function ($event) {
                      _vm.showPatrolModal = true
                    },
                    disabledClick: function ($event) {
                      return _vm._toast({
                        title: "Not Enabled",
                        body: "Stream position is fixed",
                        variant: "danger",
                      })
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "mb-2", staticStyle: { "font-size": "16px" } },
          [
            _vm._v(
              "\r\n            Wait Time: " +
                _vm._s(_vm.patrolWaitTime) +
                " seconds\r\n        "
            ),
          ]
        ),
        _vm._v(" "),
        _c("iws-switch", {
          key: _vm.patrolSwitch,
          staticClass: "mb-2",
          attrs: {
            labels: ["Off", "On"],
            value: _vm.patrolMode,
            onPress: _vm.setPatrolMode,
            disabled: _vm.isFixed,
          },
          on: {
            disabledClick: function ($event) {
              return _vm._toast({
                title: "Not Enabled",
                body: "Stream position is fixed",
                variant: "danger",
              })
            },
          },
        }),
      ],
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          showModal: _vm.showPatrolModal,
          title: "Edit Patrol Mode",
          "max-width": "550px",
        },
        on: {
          close: _vm.cancelEditPatrolMode,
          "secondary-action": _vm.cancelEditPatrolMode,
          "primary-action": _vm.editPatrolMode,
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "mb-4" }, [
                  _vm._v(
                    '\r\n                Update wait time and patrol order, then click on "Save" to apply the changes.\r\n            '
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "mb-2", staticStyle: { "font-size": "16px" } },
                  [
                    _vm._v(
                      "\r\n                Wait Time (seconds):\r\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("iws-input", {
                  attrs: { value: _vm.newWaitTime, placeholder: "seconds" },
                  on: {
                    "update:value": function ($event) {
                      _vm.newWaitTime = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mb-2 mt-4",
                    staticStyle: { "font-size": "16px" },
                  },
                  [_vm._v("\r\n                Patrol Order:\r\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "draggable",
                  {
                    attrs: { group: "patrol-list", handle: ".draggable" },
                    model: {
                      value: _vm.patrolList,
                      callback: function ($$v) {
                        _vm.patrolList = $$v
                      },
                      expression: "patrolList",
                    },
                  },
                  _vm._l(_vm.patrolList, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.name,
                        staticClass:
                          "patrol-item border border-secondary rounded m-2 p-2",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "draggable" },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(index + 1) + ". " + _vm._s(item.name)
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.permissions?.admin
                              ? _c("iws-button", {
                                  staticClass: "patrol-button",
                                  attrs: {
                                    type: "text-primary",
                                    text: "Remove",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.RemoveFromPatrolOrder(item)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "mb-2 mt-4",
                    staticStyle: { "font-size": "16px" },
                  },
                  [
                    _vm._v(
                      "\r\n                Presets Not In Patrol:\r\n            "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm._l(_vm.presetsNotInPatrol, function (item) {
                  return _c(
                    "div",
                    { staticClass: "border border-secondary rounded m-2 p-2" },
                    [
                      _c("span", [_vm._v(_vm._s(item.name))]),
                      _vm._v(" "),
                      _vm.permissions?.admin
                        ? _c("iws-button", {
                            staticClass: "patrol-button",
                            attrs: { type: "text-primary", text: "Add" },
                            on: {
                              click: function ($event) {
                                return _vm.addToPatrolOrder(item)
                              },
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          title: "Save As Preset",
          "show-modal": _vm.addNewPreset,
          "max-width": "500px",
        },
        on: {
          close: function ($event) {
            _vm.addNewPreset = false
          },
          "secondary-action": function ($event) {
            _vm.addNewPreset = false
          },
          "primary-action": function ($event) {
            return _vm.addPreset(_vm.newPresetName)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("iws-input", {
                  staticClass: "new-preset-name",
                  attrs: { label: "Preset Name", value: _vm.newPresetName },
                  on: {
                    "update:value": function ($event) {
                      _vm.newPresetName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _vm._v(
                    "\r\n                This will create a new preset with the name you enter and will be available to access on the preset selection dropdown\r\n            "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          title: "Manage Redzones",
          "show-modal": _vm.manageRedzoneModal,
          "secondary-button-text": "Close",
          "primary-button-visible": false,
          "max-width": "500px",
        },
        on: {
          close: function ($event) {
            _vm.manageRedzoneModal = false
          },
          "secondary-action": function ($event) {
            _vm.manageRedzoneModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                !_vm._isNullOrEmpty(_vm.activePreset?.redzones)
                  ? [
                      _vm._l(
                        _vm.activePreset?.redzones,
                        function (redzone, index) {
                          return _c(
                            "div",
                            {
                              staticClass: "manage-redzone-row",
                              class: { "mt-2": !!index },
                            },
                            [
                              _c("iws-input", {
                                attrs: { value: redzone.name, required: "" },
                                on: {
                                  "update:value": function ($event) {
                                    return _vm.$set(redzone, "name", $event)
                                  },
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "span",
                                [
                                  _c("iws-button", {
                                    attrs: {
                                      icon: "fas fa-save",
                                      type: "primary",
                                      click: () => _vm.renameRedzone(redzone),
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("iws-button", {
                                    staticClass: "ml-1",
                                    attrs: {
                                      icon: "fas fa-trash-alt",
                                      type: "outline-danger",
                                      click: () => _vm.deleteRedzone(redzone),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "mt-3" }, [
                        _vm._v(
                          "\r\n                    Rename or delete the redzones assigned to this cameras preset\r\n                "
                        ),
                      ]),
                    ]
                  : _c("h5", [
                      _vm._v(
                        "\r\n                No Redzones for this preset\r\n            "
                      ),
                    ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          showModal: _vm.nameRedzoneModal,
          title: "Name Redzone",
          "primary-button-click": _vm.saveRedzone,
          "max-width": "500px",
        },
        on: {
          close: function ($event) {
            _vm.nameRedzoneModal = false
          },
          "secondary-action": function ($event) {
            _vm.nameRedzoneModal = false
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("iws-input", {
                  staticClass: "new-preset-name",
                  attrs: {
                    label: "Redzone Name",
                    value: _vm.newRedzoneName,
                    required: "",
                  },
                  on: {
                    "update:value": function ($event) {
                      _vm.newRedzoneName = $event
                    },
                  },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "mt-3" }, [
                  _vm._v(
                    "\r\n                This will create a new redzone for the current preset ('" +
                      _vm._s(_vm.activePreset?.name || "Redzone") +
                      "') with the name you enter.\r\n            "
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }