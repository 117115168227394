<template>
    <!-- class activelySpeaking only is roipActiveSpeaking is true -->
<div :class="{ 'activeSpeaking': roipActivelySpeaking }">
    <div class="roipBanner" v-if="roipActivelySpeaking">
       {{ roipPad }} : {{ roipActivelySpeakingUsers.join(', ') + ' currently speaking' }}
    </div>

    <div>
        <div>
            <div id="transparent-popover-container"></div>
            <div v-if="dashboards.length == 0">
                <center class="mt-5 h1">
                    No dashboards currently exist,
                    <b>
                        <a class="nav-link text-white" type="button" @click="createNewDashboard()">
                            click here to create one.
                        </a>
                    </b>
                </center>
            </div>
            <div v-else-if='noDefaultDashboardError' class="col-12 align-content-end px-4 py-2">
                <div class="flex-grow-1 p-1 rounded pl-3" style="font-weight:bold; vertical-align:bottom; color:red; border:1px solid red;">
                    A customer default dashboard has not been created yet, please contact an administrator for assistance.
                </div>
            </div>
            <div v-else-if='isDashboardLoading' class="spinner-border m-5" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <div v-else>
                <div class="d-flex flex-row">
                </div>
                
                <notifications group="notifications" position="top center"/>

                <grid-layout v-if="selectedDashboard.layout.length != 0"
                    :layout.sync="selectedDashboard.layout"
                    :is-draggable="editMode"
                    :is-resizable="editMode"
                    :responsive="isResponsive"
                    :col-num="12"
                    :row-height="30"
                    :is-mirrored="false"
                    :vertical-compact="true"
                    :margin="[5, 5]"
                    :use-css-transforms="true"
                    @layout-updated="layoutUpdatedEvent">

                    <div v-for="(item,index) in selectedDashboard.layout" :key="item.i">
                        <grid-item :x="item.x"
                                :y="item.y"
                                :w="item.w"
                                :h="item.h"
                                :i="item.i"
                                :minW="getDimensionsForItem(item)?.minW"
                                :minH="getDimensionsForItem(item)?.minH"
                                :maxH="getDimensionsForItem(item)?.maxH"
                                :maxW="getDimensionsForItem(item)?.maxW"
                                    @resize="handleResizeEvent"
                                    @resized="handleResizedEvent"
                                    dragAllowFrom=".item_title"
                                >

                            <div class='item_title' v-if="editMode">
                                <div class="pl-2" style="display:inline-block;">
                                    <b-form-select v-model="getDashboardItemFromLayoutItem(item).type" :options="availableDisplayItems" class="select_form" @change="handleComponentTypeChanged(getDashboardItemFromLayoutItem(item), index)"></b-form-select>
                                </div>
                                <div class='closer' @click="removeDashboardItem(item, index)">X</div>
                            </div>
                            <display-item-component
                                :editMode='editMode'
                                :item="getDashboardItemFromLayoutItem(item)"
                                :componentExists="checkComponentExists(getDashboardItemFromLayoutItem(item))"
                                :iwsUser="!!iwsUser"
                                :ref="'grid-item-' + item.i"
                                :defaultChartColumnCount="defaultChartColumnCount"
                                :layout="selectedDashboard.layout[index]"
                                :componentSpecificPermissions="componentSpecificPermissions"
                                :friendlyName="availableDisplayItems.find(target => target.value == getDashboardItemFromLayoutItem(item).type)?.friendlyName"
                                @pushToActiveTags="pushToActiveTags"
                                :wells="wells"
                                :utcDifference="utcDifference"
                                :latencyComponentTags="latencyComponentTags"
                                :items="selectedDashboard.items"
                                :latestDataCollection="latestDataCollection"
                                :latencySaving="latencySaving"
                                :latencySaved="latencySaved"
                                @onSavePressed="onLatencyComponentSave"
                            >
                            </display-item-component>
                        </grid-item>
                    </div>
                </grid-layout>

                <center class="mt-5 h1" v-if="selectedDashboard.layout.length == 0 && !isDashboardLoading">
                    Add components to the dashboard by clicking the <div class="fas fa-pen fa-md px-1" /> to the right.
                </center>
            </div>
            <critical-alert-modal-component :jobNumber="jobNumber" :isLimited="true"/>
        </div>

        <b-sidebar
            id="sidebar-right"
            v-model="isSelectedDashboardOptionsVisible"
            backdrop-variant="dark"
            :width="windowSize.width <= 768 ? '100vw' : '400px'"
            backdrop
            no-close-on-backdrop
            shadow
            right
            no-header
            sidebar-class="border-left border-primary"
            @change="handleDashboardOptionsVisibilityChange"
            @hidden="isSelectedDashboardOptionsVisible = false;">
            <div class="d-flex flex-column p-2 justify-content-center mt-4">
                <div class="align-self-stretch">
                    <div><h2>Dashboard Options</h2></div>
                    <div class='p-1'>
                        <b-form @submit="onDashboardOptionsSubmit" @reset="onDashboardOptionsReset" >
                        <b-form-group
                            id="t-group-1"
                            label="Dashboard name:"
                            label-for="input-1"
                        >
                        <b-form-input
                            id="input-1"
                            v-model="dashboardOptionsForm.dashboardName"
                            @input="dashboardOptionsForm.changed = true;"
                            @focus="isDashboardNameTaken=false"
                            @blur="checkIsDashboardNameTaken(dashboardOptionsForm.dashboardName)"
                            required
                            ></b-form-input>
                        </b-form-group>
                        <b-popover
                        target="input-1"
                        varient="light"
                        :show.sync="isDashboardNameTaken"
                        triggers=""
                        placement="bottom">
                            <div style="color:white;">
                                Name is already used or unavailable
                            </div>
                        </b-popover>

                        <b-form-checkbox
                            class="mt-4 mb-1"
                            v-model='dashboardOptionsForm.sharedSettings'
                            value='default'
                            unchecked-value='private'>
                            Company Default
                        </b-form-checkbox>
                        <b-form-checkbox
                            class="mt-1 mb-1"
                            v-model='dashboardOptionsForm.syncCharts'
                            value="true"
                            unchecked-value='false'>
                            Sync Charts
                        </b-form-checkbox>
                        <b-form-checkbox
                            v-if="canChangeDashboardTruckConfigs"
                            class="mt-1 mb-1"
                            v-model='dashboardOptionsForm.syncTrucks'
                            @change="syncTrucksToggle($event)"
                            value="true"
                            unchecked-value='false'>
                            Set Primary Wireline Truck to Use:
                        </b-form-checkbox>
                        <div v-if="dashboardOptionsForm.syncTrucks === 'true' && canChangeDashboardTruckConfigs">
                            <select class="form-control w-50" v-model="dashboardOptionsForm.defaultTruck"  @click="onDefaultTruckChange($event.target.value)">
                                <option v-for="(truck, index) in wirelineSystems" :value="truck.number" :key="index" >
                                    {{ truck.name }}
                                </option>
                            </select>
                        </div>
                        <b-form-checkbox
                            class="mt-1 mb-1"
                            :disabled="!isTooltipSyncingValid()"
                            v-model='dashboardOptionsForm.syncChartTooltips'
                            value="true"
                            unchecked-value='false'>
                            Sync Chart Tooltips
                        </b-form-checkbox>
                        <b-form-group id="input-group-4" class="mt-1 mb-4">
                            <div class="d-flex justify-content-between">
                                <div class="w-50">Chart Tooltip Mode: </div>
                                <b-form-select v-model="dashboardOptionsForm.chartTooltipMode"
                                    class="select_form w-50 text-center"
                                    style="padding-top: 2px;"
                                    @click="dashboardOptionsForm.changed=true;">
                                    <b-form-select-option value="nearest" >Show Nearest</b-form-select-option>
                                    <b-form-select-option value="all" >Show All</b-form-select-option>
                                </b-form-select>
                            </div>
                        </b-form-group>

                        <b-form-group id="input-group-4" v-if='isAdmin || isCompanyAdmin'
                                    label='Shared with: '
                                    class="mt-4 mb-4">
                            <b-form-select v-model="dashboardOptionsForm.sharedSettings"
                                        :disabled="dashboardOptionsForm.sharedSettings == 'default'"
                                        v-bind:class="{'text-muted': dashboardOptionsForm.sharedSettings == 'default'}"
                                        class="select_form"
                                        @click="dashboardOptionsForm.changed=true;">
                                <b-form-select-option v-if="isDashboardOwner || isAdmin" value="private">No one</b-form-select-option>
                                <b-form-select-option value="default" >All {{customer}} users</b-form-select-option>
                                <b-form-select-option value="shared-roles" >Certain {{customer}} roles</b-form-select-option>
                                <b-form-select-option value="shared-users" >Certain {{customer}} users</b-form-select-option>
                            </b-form-select>
                        </b-form-group>

                        <div v-if='dashboardOptionsForm.sharedSettings == "shared-roles"'>
                            <table class="table table-dark text-center" id='#usertable'>
                                <thead class="thead-dark text-center">
                                    <th>Role</th>
                                    <th>Permissions</th>
                                </thead>
                                <tr v-for='(role) in dashboardOptionsForm.allRoles' :key="role.id">
                                    <td v-bind:class="{ 'text-muted': role.isDisabled }">{{role.name}}</td>
                                    <td>
                                        <b-form-select :value="role.shared_settings" class="select_form ml-4 pl-2" :disabled="role.isDisabled" @change="(value)=>onRolePermissionChange(value, role)">
                                            <b-form-select-option value="none">No access</b-form-select-option>
                                            <b-form-select-option value="readonly" v-if="role.name!=='admin' && role.name!=='companyAdmin'">Read-only</b-form-select-option>
                                            <b-form-select-option value="readwrite" >Read/write</b-form-select-option>
                                        </b-form-select>

                                    </td>

                                </tr>
                                </table>

                        </div>
                        <div v-else-if='dashboardOptionsForm.sharedSettings == "shared-users"'>
                            <div class='flex-parent pb-2'>
                                <div class='pr-3'>
                                    Search:
                                </div>
                                <div class='pr-3'>
                                    <autocomplete
                                        :search="searchShareableUsernames"
                                        @submit="searchUsernameSelected"
                                        :auto-select="true"
                                        :get-result-value="getUserResultValue"
                                        placeholder='Search for a username'
                                        ref='userAutocomplete'
                                        >
                                        <template
                                            #default="{
                                                    rootProps,
                                                    inputProps,
                                                    inputListeners,
                                                    noResults,
                                                    focused,
                                                    resultListProps,
                                                    resultListListeners,
                                                    results,
                                                    resultProps
                                                    }"
                                            >
                                            <div v-bind="rootProps">
                                                <div class="input-group" >
                                                    <input role="combobox"
                                                        autocomplete="off"
                                                        autocapitalize="off"
                                                        autocorrect="off"
                                                        spellcheck="false"
                                                        aria-autocomplete="list"
                                                        aria-haspopup="listbox"
                                                        aria-owns="autocomplete-result-list-1"
                                                        aria-expanded="true"
                                                        placeholder="Search for a username"
                                                        v-bind="inputProps"
                                                        v-on="inputListeners"
                                                        :class="['form-control',
                                                                'autocomplete-input',
                                                                { 'autocomplete-input-no-results': noResults },
                                                                { 'autocomplete-input-focused': focused }
                                                                ]" />
                                                    <div class="input-group-append">
                                                        <button v-if='dashboardOptionsForm.searchedUsername !== ""'
                                                                type="button" class="btn btn-primary dropdown-toggle-split"  style='min-width: 15%'
                                                                @click="clearUserAutocomplete()">
                                                            x
                                                        </button>
                                                        <button v-else type="button" class="btn btn-primary dropdown-toggle dropdown-toggle-split" style='min-width: 15%'
                                                                data-toggle="dropdown"
                                                                aria-expanded="false"
                                                                aria-autocomplete="list"
                                                                aria-haspopup="listbox"
                                                                aria-owns="autocomplete-result-list-1"
                                                                @click='searchShareableUsernames()'
                                                                v-on="inputListeners">
                                                        </button>
                                                    </div>
                                                </div>
                                                <ul v-bind="resultListProps" v-on="resultListListeners">
                                                    <a href='#' v-for="(result, index) in results" :key="resultProps[index].id" v-bind="resultProps[index]"
                                                    class="list-group-item list-group-item-action text-secondary p-1 text-left">
                                                        <li>{{ result.username }}</li>
                                                    </a>
                                                </ul>
                                            </div>
                                        </template>
                                    </autocomplete>
                                </div>
                                <div class='pr-3'>
                                    <b-button @click="addToOptionsFormUsers(dashboardOptionsForm.validSearchedUser)"  size="sm"
                                            :disabled="!dashboardOptionsForm.validSearchedUser"
                                            :variant='dashboardOptionsForm.validSearchedUser != null ? "success" : "secondary"'>Add</b-button>
                                </div>
                            </div>

                            <div v-if='dashboardOptionsForm.sharedUsers.length > 0'>
                                <table class="table table-dark text-center" id='#usertable'>
                                    <thead class="thead-dark text-center">
                                        <th>User</th>
                                        <th>Permissions</th>
                                        <th>Remove</th>
                                    </thead>
                                    <tr v-for='(user, index) in dashboardOptionsForm.sharedUsers' :key="index">
                                        <td class="max-text-width text-truncate" v-bind:class="{ 'text-muted': user.isDisabled }" >{{user.username}}</td>

                                        <td>
                                            <b-form-select :value="user.pivot.is_writable? 'readwrite' : 'readonly' " class="select_form" :disabled="user.isDisabled" @change="(value)=>{ dashboardOptionsForm.changed = true; onUserPermissionChange(value, user)}">
                                                <b-form-select-option value="readonly">Read-only</b-form-select-option>
                                                <b-form-select-option value="readwrite" >Read/write</b-form-select-option>
                                            </b-form-select>
                                        </td>

                                        <td>
                                            <span v-bind:class="{ 'user-remover': !user.isDisabled, 'user-remover-disabled': user.isDisabled }" ><i class="fas fa-user-times" v-on:click='(!user.isDisabled) && removeUserFromDashboardSharedOptions(user)'></i></span>
                                        </td>
                                    </tr>
                                </table>
                            </div>

                        </div>

                        <div class="d-flex w-100 mt-2">
                            <div class='mr-auto text-left'>
                                <b-button v-if="!newDashboardDraft && (selectedDashboard.created_by === userId || isAdmin || isCompanyAdmin)"
                                        @click="deleteSelectedDashboard"
                                        variant="secondary">Delete</b-button>
                            </div>

                            <b-button @click='newDashboardDraft = false' class="mx-1 text-right" variant="light" v-b-toggle.sidebar-right>Cancel</b-button>
                            <b-button type="submit" class="text-right" variant="primary" :disabled="isDashboardNameTaken">Save</b-button>
                        </div>

                        </b-form>


                        <!-- <b-card class="mt-3" header="Form Data Result">
                        <pre class="m-0">{{ dashboardOptionsForm }}</pre>
                        </b-card> -->
                    </div>
                </div>
            </div>
        </b-sidebar>

        <auth-fail-component v-show="hasAuthError"></auth-fail-component>
        <!-- if the job is ended don't render this -->
        <chart-config-modal
        :tags="tags"
        ref="chartConfigModal"
        :chartConfiguration="chartConfiguration"
        :jobNumber="jobNumber"
        :isAdmin="isAdmin"
        :isCompanyAdmin="isCompanyAdmin"
        :userId="userId"
        :iwsUser="iwsUser"
        :dashboard-id="selectedDashboard.id"
        :customer="customer"
        :defaultChartColumnCount="defaultChartColumnCount"
        :wells="wells"
        :jobHourOffset="jobHourOffset"
        :userRoles="userRoles.map(o => o.name)"
        chartType="frac" />
        <signalr-error-modal
            v-if="jobEnd == null"
            :modalVisible="signalRModalVisible"
            :resolutionPackage="resolutionPackage"
            @onDismissSignalRErrorModal="onSignalRReconnect"
            @modalClose="signalRConnected = true; signalRModalVisible = false"
        />
        <div v-if="initActivityCallEnded && selectedDashboard.items.filter(item=>item.type=='wireline-controls-component').length > 0">
            <comment-component ref="commentModal" :jobData="getUpdatedJobData()"/>
            <plug-gun-status-component ref="missRunModal" :jobData="getUpdatedJobData()" noResultMessage="No shots fired" :isFilterPlugs="false" title="Shots fired:"/>
            <plug-gun-status-component ref="conditionModal" :jobData="getUpdatedJobData()" noResultMessage="No plug set" :isFilterPlugs="true" title="Plug set:"/>
        </div>
    </div>
</div>

</template>

<script>
import _ from 'lodash'
import GlobalFunctions from '../GlobalFunctions.js';
const { isNullOrEmpty, isFalsy, getUnixTimeStampMs, isFeatureFlagged } = GlobalFunctions;

import wellFunctions from './WellSmallComponent.vue';
import moment from 'moment';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import VueGridLayout from 'vue-grid-layout';
import Notifications from 'vue-notification';
import SignalRMixin from '../mixins/SignalRMixin.js';
import {v4 as uuidv4} from "uuid";
import eventBus from '../eventBus';

const pingInterval = parseInt(process.env.MIX_SYSTEM_PING) || -1;

Vue.use(Notifications);

const DEFAULT_SUMMARY_BAR_TIME_FRAME = 3; //hours to show by default on the summary bar chart
const TIME_SERIES_STALE_THRESHOLD_MS = 60000; //this many seconds pass before indicating time series data is not reporting
const DEFAULT_CHART_DISPLAY_COLUMN_COUNT = 12; //determines the resolution of chart item width resizing and is passed to both the chart config modal and the charts.
const COMPONENTS_TYPES_WITH_TRUCK_SELECTION = ['dashboard-frac-chart', 'wireline-controls-component', 'wireline-diameter-component', 'single-well-display-component', 'wireline-info-component'];

export default {
    provide: function() {
        return {
            dashboardData: this
        };
    },

    components: {
        Autocomplete,
        GridLayout: VueGridLayout.GridLayout,
        GridItem: VueGridLayout.GridItem
    },

    mixins: [SignalRMixin],

    props: {
        initialCompletedStages: {
            type: Number,
        },
        jobNumber: {
            type: String,
            required: true
        },
        authUserRoles: {
            type: Array,
            required: true
        },
        userRoles: {
            type: Array,
            required: true
        },
        eventActivities: {
            type: Array,
            required: true
        },
        eventReasons: {
            type: Array,
            required: true
        }, 
        chartcomments: {
            type: Array,
            required: true
        },
        isAdmin: {
            type: [Boolean, Number],
            required: true
        },
        isCompanyAdmin: {
            type: [Boolean, Number],
            required: true
        },
        tags: {
            type: Array,
            required: true
        },
        latestDataCollectionProp: {
            type: Array,
            required: true
        },
        stepDescriptions: {
            type: Array
        },
        nptOutcomes: {
            type: Array
        },
        customerVendors: {
            type: Array
        },
        allCustomerUsers: {
            type: Array,
            required: true
        },
        allRoles: {
            type: Array,
            required: true
        },
        eventActivityEventReasons: {
            type: Array,
            required: true
        },
        userId: {
            type: [String, Number],
            required: true
        },
        iwsUser: {
            type: [Boolean, Number],
            required: true
        },
        isMultiWireline: {
            type: [Boolean, Number],
            required: true
        },
        isSimuFrac: {
            type: [Boolean, Number],
            required: true
        },
        isMultiFrac: {
            type: [Boolean, Number],
            required: true
        },
        isContinuousFrac: {
            type: [Boolean, Number],
            required: true
        },
        wirelineSystems: {
            type: Array,
            require: true
        },
        fracSystems: {
            type: Array,
            require: true
        },
        sessionId: {
            type: String,
            required: true
        },
        activeCompanyJobs: {
            type: Array,
            required: false
        },
        componentSpecificPermissions: {
            type: Object,
            required: true
        },
        completedStagesPerWell:{
            type: [Object, Array],
            required: true
        },
        customerId: {
            type: [String, Number],
            require: true
        },
        customerObj: {
            type: Object,
            require: true
        },

        availableDisplayItems: {
            type: Array,
            required: true
        },
        displayItemDimensions: {
            type: Object,
            required: true
        },
    },

    data() {
        return {
            updatedWells: [],
            inProgressActivities: [],
            latestActivityBySystem: {},
            componentsWithTruckSelection: COMPONENTS_TYPES_WITH_TRUCK_SELECTION,
            selectedDashboardTruck: null,
            latestStageMarkerData: null,
            signalRConnected: true, //start true so init doesn't count as reconnect
            previousSignalRStageWellPairs: [],
            initWirelineFetch: false,
            latestHandshake: [],
            activeTags: [],
            utcDifference: 0,
            latestWellSummary: [],
            currentWellIndex: -1,
            flagSignalRData: {},
            rawFlagTelemetryData: {
                streamData: {},
                activeFlags: [],
                completedRef: [],
                deletedRef: []
            },
            latestHandshakeEvent: null,
            totalCompletedStages: this.initialCompletedStages,
            initActivityCallEnded: false,
            resolutionPackage: null,
            defaultChartColumnCount: DEFAULT_CHART_DISPLAY_COLUMN_COUNT,	//Needs to be reactive to pass as prop, otherwise console error
            signalRModalVisible: false,
            okayCallback: null,
            clusterAlerts: [],
            eventAlerts: [],
            eventAlertHistory: [],
            summaryBarEndTime: null,
            latestActivityData: [],
            activitiesFetched: [],
            summaryBarStartDate: null,
            latestDataCollection: this.latestDataCollectionProp,
            latestSignalRMessage: null,
            userNotifications: [],
            chartcommentsList: [],
            messagesArray: [],
            isDisabledEdit: !(this.isAdmin || this.isCompanyAdmin),
            isDesktopSize: window.innerWidth > 768,
            activitiesStartTime: null,
            activitiesEndTime: null,
            initialDashboardName: null,
            dashboardOptionsForm: {
                dashboardName: '',
                checked: [],
                sharedSettings: 'private',
                searchedUsername: '',
                validSearchedUser: null,
                sharedUsers: [],
                sharedRoles: [],
                changed: false,
                syncCharts: 'false',
                syncTrucks: 0,
                defaultTruck: null,
                syncChartTooltips: 'false',
                chartTooltipMode: 'nearest'
            },
            latestAlert: {
                message: '<<No Alerts>>',
                alarmType: 'green'
            },
            activeWirelineWellIndex: -1,
            baseChartType: 'frac',
            currentValue: {
                header: 'Pressure',
                showHeader: true,
                descriptions: [
                    'Zipper Pressure',
                    'Well One',
                    'Well 2',
                    'Well III'
                ],
                values: [
                    12.3, 23.4, 34.5, 45.6
                ],
                numCols: 3
            },
            lastEventTime: null,
            selectedDashboard: {
                id: null,
                name: '',
                layout: [],
                items: [],
                layout_options: {},
                customer_id: null,
                created_by: null,
                is_customer_default: false,
                is_user_shared: false,
                is_role_shared: false,
                shared_users: [],
                shared_roles: [],
                is_writable: false,
                are_trucks_synced: 0,
                default_truck_number: null
            },
            newDashboardDraft: null,
            newItemAdded: false,
            editMode: false,
            isResponsive: true,
            isDashboardLoading: true,
            isSelectedDashboardOptionsVisible: false,
            isDashboardNameTaken: false,
            jobEnd: null,
            jobStart: null,
            job: {},
            jobHourOffset: 0,
            wells: [],
            customer: '',
            customer_id: null,
            alerts: [],
            siteMessages: [],
            summaryWireline: [],
            summaryFrac: [],
            wirelineDataObj: {
                placed: false,
                diameter: 0.00,
                depth: 0,
                tension: 0,
                speed: 0,
                number: 1,
                duration: 0,
                name: ''
            },
            wirelineSystemsData: [],
            pressure: {
                pumpdown: 0,
                zipper: 0,
                casingPressures: [],
                crownPressures: []
            },
            frac: {
                placed: false,
                items: {}
            },
            lastActivities: [],
            sandWeights: [],
            pumpdownWeights: [],
            waterStorageWeights: [],
            impoundmentWeights: [],
            gasBustersWeights: [],
            magnumASTWeights: [],
            events: [],
            handshakes: [],
            edgeLevelAst: null,
            edgeLevelFreshOsmosis: null,
            edgeLevelPercAst: null,
            edgeLevelPercFreshOsmosis: null,
            edgeLevelWasteOsmosis: null,
            edgeLevelPumpdownWeights: [],
            dataReceived: false,
            ajax: true,
            hasAuthError: false,
            noDefaultDashboardError: false,
            chartConfiguration: null,
            chartItems: null,
            chartYAxes: null,
            lastSignalRTimestamp: null,
            globalChartSynced: {
                chartSourceId: undefined,
                panXMin: undefined,
                panXMax: undefined,
                tooltipX: undefined,
                hideTooltips: undefined
            },
            depthShift: 0,
            logEntries: [],
            componentsWithTruck: {},
            latencySaving: false,
            latencySaved: false,
            summaryBarTimeFrame: null,
            lastPing:{
              system:{
                reference: null, //uuid, example: e72e0eae-4189-40bd-b8ff-cb7e7e4cdae5
                requestTime:null, //example: 2023-06-23T12:43:00.000Z
                dataReceivedTime:null, //example: 2023-06-23T12:43:00.900Z
                dt: -1, //example in milliseconds: 850, -1 for no response
              },
              webServer:{
                requestTime: null, //example: 2023-06-23T12:43:00.000Z
                dt: null, //example in milliseconds: 350
              }
            },
            roipActivelySpeaking: false,
            roipActivelySpeakingUsers: [],
            roipPad: '',
            updateEndTimeInterval: null,
            dashboards: []
        };
    },

    computed: {
        canChangeDashboardTruckConfigs() {
            return this.iwsUser || this.isAdmin || this.isCompanyAdmin;
        },
        isDashboardOwner() {
            return this.selectedDashboard?.created_by === this.userId;
        },
        isWirelineRequired() {
            return !isFalsy(this.selectedDashboard.items.find(item => item.type == 'wireline-controls-component' || item.type == 'wireline-info-component'));
        },
        latencyComponentTags() {
            return this.tags.filter(tag => this.activeTags.includes(tag.name));
        },
        windowSize() {
            return {
                height: window.innerHeight,
                width: window.innerWidth
            };
        },
        wellName() {
            return this.wells[this.currentWellIndex]?.name || this.wells[0].name;
        },
        currentStage() {
            return this.wells[this.currentWellIndex]?.currentStage;
        },
        system() {
            return this.activeCompanyJobs.find(job => job.jobNumber === this.jobNumber)?.system || '';
        }
    },

    methods: {
        getDashboardItemFromLayoutItem(layoutItem) {
            return this.selectedDashboard.items.find(dashboardItem => dashboardItem.i == layoutItem.i);
        },  
        checkComponentExists(item) {
            // checks if component exists in availableDisplayItems
            // deprecated components are not in availableDisplayItems
            return !isFalsy(this.availableDisplayItems.find(availableItem => availableItem?.name === item?.type));
        },
        async ping() {
            let timestamp = new Date()

            //ping web server
            await axios.get(`/VERSION.txt?timestamp=${timestamp.valueOf()}`)
            this.lastPing.webServer.requestTime = timestamp.toISOString()
            this.lastPing.webServer.dt = Date.now() - timestamp.valueOf()

            //send data ping request to system
            timestamp = new Date();
            let ref = uuidv4();

            let lastSystemPingRequestTime = this.lastPing.system.requestTime;
            let lastSystemPingDataReceivedTime = this.lastPing.system.dataReceivedTime;
            let lastSystemPingDt = this.lastPing.system.dt;

            //set now. In case signalR beats the system direct method!
            this.lastPing.system.reference = ref;
            this.lastPing.system.requestTime = timestamp.toISOString();
            this.lastPing.system.dataReceivedTime = null;
            this.lastPing.system.dt = -1;

            return axios.post('/systemPing', {
                ref,
                system:this.system,
                timestamp: timestamp.toISOString(),
                webServerRequestTime:this.lastPing.webServer.requestTime,
                webServerDt:this.lastPing.webServer.dt,
                lastSystemPingRequestTime,
                lastSystemPingDataReceivedTime,
                lastSystemPingDt
            });
        },
        filterSelectedDashboardItems(componentType) {
            if (componentType === 'dashboard-frac-chart')
                return this.selectedDashboard.items.filter(item => item?.isWireline && item?.type == 'dashboard-frac-chart');
            return this.selectedDashboard.items.filter(item => item?.type == componentType);
        },
        componentWithResetTrucks(componentType) {
            const filteredComponents = this.filterSelectedDashboardItems(componentType);
            const orderedLayout = _.orderBy(this.selectedDashboard?.layout, ['y', 'x'], ['asc', 'asc']);
            filteredComponents.sort((a,b) => orderedLayout.indexOf(a.i) - orderedLayout.indexOf(b.i));

            //reset the selected truck for the first component found on the dashboard
            if (filteredComponents[0] && filteredComponents[0].hasOwnProperty('selectedTruckId'))
                filteredComponents[0].selectedTruckId = null;

            return filteredComponents;
        },
        orderedComponentsByType(componentType) {
            const filteredComponents = this.filterSelectedDashboardItems(componentType);
            const orderedLayout = _.orderBy(this.selectedDashboard?.layout, ['y', 'x'], ['asc', 'asc']);
            filteredComponents.sort((a,b) => orderedLayout.indexOf(a.i) - orderedLayout.indexOf(b.i));
            return filteredComponents;
        },
        checkAndMoveToFront(arr, val) {
            //adds val to first position in array
            const index = arr.indexOf(val);

            if (index === -1) {
                arr.unshift(val);
            } else if (index !== 0) {
                arr.splice(index, 1);
                arr.unshift(val);
            }

            return arr;
        },
        updateTruckToComponentItems(dashboardComponents, unassignedTrucks, truckNumbers) {
            let updateSavedItems = false;
            
            if (unassignedTrucks.length > 0) {
                dashboardComponents.forEach(chart => {
                    if (!chart.selectedTruckId) {
                        chart.selectedTruckId = unassignedTrucks.shift(); //pop from front of array
                        updateSavedItems = true;
                    }

                    if (isNullOrEmpty(unassignedTrucks))
                        return;
                });

                if (updateSavedItems) {
                    this.$nextTick(() => {
                        this.saveDashboardItems(this.selectedDashboard);
                    });
                }
            }

            //if assigned Trucks are not valid for this job, or components are missing trucks
            // default to using the first available truck for each
            const componentsWithInvalidTrucks = dashboardComponents.filter(
                    comp => !comp.selectedTruckId ||
                            !truckNumbers.includes(parseInt(comp.selectedTruckId)));

            if (componentsWithInvalidTrucks.length > 0) {
                componentsWithInvalidTrucks.forEach(comp => {
                    comp.selectedTruckId = this.wirelineSystems[0].number;
                });
            }
        },
        syncTrucksToggle(event) {
            //convert event var from string to bool
            if (event == 'true') {
                this.onDefaultTruckChange(this.dashboardOptionsForm.defaultTruck);
            } else {//if turning off, reset all assigned truck values on next load after save
                this.selectedDashboard.items.forEach(item => {
                    if (item?.selectedTruckId)
                        this.$set(item, 'selectedTruckId', null);
                });
            }
        },
        onDefaultTruckChange(truckNumber) {
            this.dashboardOptionsForm.defaultTruck = parseInt(truckNumber);
            this.dashboardOptionsForm.changed = true;
        },
        updatedWellValves() {
            for (const well of this.wells)
                well.valves = well.valves.slice(0, well.valves.length);
        },
        initTruckSelectionByComponent(componentType) {
            let unassignedTrucks = [];
            const canSyncComponentTrucks = this.selectedDashboard?.default_truck_number &&
                this.wirelineSystems.findIndex(system => system.number == this.selectedDashboard?.default_truck_number) >= 0

            if (this.selectedDashboard?.are_trucks_synced && canSyncComponentTrucks) {
                let componentItemWithNoTrucksSelected = this.componentWithResetTrucks(componentType);
                if (componentItemWithNoTrucksSelected) {
                    const assignedTrucks = componentItemWithNoTrucksSelected.map(chart => parseInt(chart.selectedTruckId));
                    const truckNumbers = this.wirelineSystems.map(system => system.number);
                    unassignedTrucks = truckNumbers.filter(truck => assignedTrucks.indexOf(truck) === -1);
                    let sortedUnassignedTrucks = unassignedTrucks.sort((a, b) => a - b);
                    this.checkAndMoveToFront(sortedUnassignedTrucks, this.selectedDashboard.default_truck_number);
                    this.updateTruckToComponentItems(componentItemWithNoTrucksSelected, sortedUnassignedTrucks, truckNumbers);
                }
            } else if (this.selectedDashboard?.are_trucks_synced && !canSyncComponentTrucks) {
                //if an invalid truck number has been selected for mass assignment on the job
                //default to showing the first available truck as the synced truck instead
                this.selectedDashboard.items.forEach(item => {
                    if (item?.selectedTruckId)
                        this.$set(item, 'selectedTruckId', this.wirelineSystems[0]?.number || 1);
                });
            } else {
                let orderedComponents = this.orderedComponentsByType(componentType);
                if (orderedComponents) {
                    const assignedTrucks = orderedComponents.map(chart => parseInt(chart.selectedTruckId));
                    const truckNumbers = this.wirelineSystems.map(system => system.number);
                    unassignedTrucks = truckNumbers.filter(truck => assignedTrucks.indexOf(truck) === -1);
                    this.updateTruckToComponentItems(orderedComponents, unassignedTrucks, truckNumbers);
                }
            }
        },
        initTruckSelection() {
            if (!this.wirelineSystems || this.wirelineSystems.length == 0) {
                return;
            }

            //auto assign wireline trucks to wireline charts in sequence
            //as a default for each truck component
            this.componentsWithTruckSelection.forEach(componentType => {
                this.initTruckSelectionByComponent(componentType);
            });
        },
        onSignalRReconnect() {
            //things to refetch on signalR reconnect
            this.updateActivities();
        },
        pushToActiveTags(tags) {
            this.activeTags.push(...tags);
        },
        onLatencyComponentSave() {
            //Save to a new endpoint on the customizable dashboard controller
            //This route will handle the actual saving an we can just pass the latency indictor data to it
            const url = '/dashboards/update-latency/';
            const self = this;
            this.latencySaving = true;
            this.latencySaved = false;
            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    id: self.newDashboardDraft ? null : self.selectedDashboard.id,
                    items: JSON.stringify(self.selectedDashboard.items),
                },
                dataType: 'json'
            })
            .done((data) => {
                self.latencySaved = true;
                setTimeout(
                    function() {
                        self.latencySaved = false;
                    }, 5000
                );
            })
            .fail((msg) => {
                console.warn('Failed to update latency indicator for dashboard:' + JSON.stringify(msg));
                alert('Something went wrong saving your changes! Please contact an administrator.');
            })
            .always((msg) => {
                self.latencySaving = false;
            });
        },
        indexFrac(fracSystemNumber) {
            const index = this.wells.map((_well, index) => index).filter(index => this.wells[index].activity === 'frac' && (fracSystemNumber === undefined || this.wells[index].activityData?.service_id === fracSystemNumber));
            
            this.frac.placed = !isNullOrEmpty(index);

            return index;
        },
        indexWireline(wirelineSystemNumber) {
            const index = this.wells.map((_well, index) => index).filter(index => this.wells[index].activity === 'wireline' && (wirelineSystemNumber === undefined || this.wells[index].activityData?.service_id === wirelineSystemNumber));

            this.wirelineDataObj.placed = !isNullOrEmpty(index);

            return index;
        },
        getDefaultChartConfigTemplate(jobNumber, callback) {
            const self = this;
            const url = '/user/config/defaultchartconfig/' + jobNumber;
            $.get(
                url,
                {},
                callback,
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.log('fail downloadData', errorThrown);
                if(jqXHR.status == 401) {
                    console.log('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        updateWirelineHistory() {
            //this method will show list of plugs and shots, and fill in depth shift offset based on current stage
            //should be called when current stage number changes
            const url = '/wireline-history/all/'+this.jobNumber;
            const self = this;
            $.get(
                url,
                {},
                function(data,status,xr) {
                    const plug = data.find(element => element.type == 'plug');
                    if(!plug)
                        return;
                    self.depthShift = plug.depthShift;
                    self.logEntries = []; //Clear log entries and then repopulate

                    for(let i in data) {
                        const element = data[i];
                        const time = self.convertDateTimeWithOffset(element.created_at).format("YYYY-MM-DD HH:mm:ss");
                        let offsetDistance = 0;
                        if (element.toolstring_detail?.distance) 
                            offsetDistance = element.toolstring_detail.distance
                        if (element.type == 'plug') {
                            self.logEntries.push({ type: 'plug', text: 'Plug set: '+ (element.depth + offsetDistance) + 'ft KB', time, wellNumber: element.wellIndex, stageNumber: element.stageNumber});
                        } else if (element.type == 'shot') {
                            self.logEntries.push({type: 'shot', text: 'Shot fired: '+ (element.depth + offsetDistance) + 'ft KB', time, wellNumber: element.wellIndex, stageNumber: element.stageNumber});
                        }
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.log('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        getUpdatedJobData() {
            return {
                jobNumber: this.jobNumber,
                wellName: this.wellName ?? '',  //no name when not perforating
                stage: this.currentStage ?? 1, //default to stage one
                wellIndex: this.currentWellIndex >= 0 ? this.currentWellIndex : 0, //perforating well index
                customerId: this.customer_id,
                hourOffset: this.job.hourOffset
            };
        },
        onAlertPress(data) {
            GlobalFunctions.onAlertPressLogic(data, this.jobNumber);
        },
        onRolePermissionChange(value, roleObject) {
            this.dashboardOptionsForm.allRoles = this.dashboardOptionsForm.allRoles.map((role)=>{
                if (role.id == roleObject.id) {
                    return {
                        ...role,
                        shared_settings: value
                    };
                } else {
                    return role;
                }
            });
        },
        onUserPermissionChange(value, userObject) {
            this.dashboardOptionsForm.sharedUsers = this.dashboardOptionsForm.sharedUsers.map((user)=>{
                if (user.id == userObject.id) {
                    return {
                        ...user,
                        pivot: {
                            ...user.pivot,
                            is_writable: value !== 'readonly'
                        }
                    };
                } else {
                    return user;
                }
            });
        },
        addToOptionsFormUsers(newUser) {
            if (!newUser)
                return;

            newUser.pivot = {};
            newUser.pivot.is_writable = false;

            this.dashboardOptionsForm.sharedUsers.push(newUser);
            this.dashboardOptionsForm.changed = true;

            this.clearUserAutocomplete();
        },
        removeUserFromDashboardSharedOptions(user) {
            if(!confirm(`Are you sure you want to stop sharing this dashboard with ${user.username}?`))
                return;
            
            this.dashboardOptionsForm.sharedUsers = this.dashboardOptionsForm.sharedUsers.filter(u => u.id != user.id);
            this.dashboardOptionsForm.changed = true;
        },
        clearUserAutocomplete() {
            this.$refs.userAutocomplete.value = '';
            this.dashboardOptionsForm.searchedUsername = '';
            this.dashboardOptionsForm.validSearchedUser = null;
        },
        searchUsernameSelected(user) {
            this.dashboardOptionsForm.searchedUsername = user.username;
            this.dashboardOptionsForm.validSearchedUser = user;
        },
        searchShareableUsernames(input) {
            this.dashboardOptionsForm.searchedUsername = input;

            let validUsers = this.allCustomerUsers.filter(user =>
                !this.dashboardOptionsForm.sharedUsers.find(u => u.id == user.id)
                    && this.userId != user.id);

            if (input)
                validUsers = validUsers.filter(user => user.username.toLowerCase().startsWith(input.toLowerCase()));

            this.dashboardOptionsForm.validSearchedUser = validUsers.length == 1 ? validUsers[0] : null;

            return validUsers;
        },
        onEditClicked() {
            this.setDisplayItemHandleColor();
        },
        getUserResultValue(result) {
            return result.username;
        },
        handleResizeEvent(i, newHeight, newWidth, newHeightInPx, newWidthInPx) {
            const index = this.selectedDashboard.items.findIndex(e => e.i == i);
            this.$refs['grid-item-' + index][0].$data.height = newHeightInPx;
        },
        handleResizedEvent(i, newHeight, newWidth, newHeightInPx, newWidthInPx) {
            const index = this.selectedDashboard.items.findIndex(e => e.i == i);
            this.$refs['grid-item-' + index][0].$data.height = newHeightInPx;
        },
        mountListeners() {
            this.$root.$on('OPEN_CHART_CONFIG_MODAL', (params) => {
                this.$refs['chartConfigModal'].changeModalVisibility(true, params);
            });
            this.$root.$on('updateDashboards', (dashboards) => {
                this.dashboards = dashboards;
            });
            this.$root.$on('updateSelectedDashboard', (selectedDashboard) => {
                this.selectedDashboard = selectedDashboard;
                 if (this.wirelineSystems?.length > 0 && !this.selectedDashboard?.default_truck_number) {
                    //set default for default dashboard truck so dropdown isn't blank
                    this.selectedDashboard.default_truck_number = this.wirelineSystems[0].number;
                }
                this.initTruckSelection();
                if (this.isWirelineRequired) {
                    const self = this;
                    this.mountWirelineHistoryListeners();
                    //TODO: Need to find an alternative way to keep wireline history logs
                    //updated without having to resort to database polling.
                    setInterval(function() {
                        self.updateWirelineHistory();
                    },10000); //fetches new data every 10s
                }
                this.isDashboardLoading = false;
            });
            this.$root.$on('noDefaultDashboardError', () => {
                this.noDefaultDashboardError = true;
            });
            this.$root.$on('createNewDashboard', (newDashboard) => {
                this.$root.$emit('bv::toggle::collapse', 'sidebar-right');
                this.newDashboardDraft = newDashboard;
            });
            this.$root.$on('toggleChartCommentsTimeline', (changedChartItem) => {
                const dashboardChart = this.selectedDashboard.items.find(item => item.i == changedChartItem.i);
                dashboardChart.options.hideCommentsTimeline = changedChartItem.options.hideCommentsTimeline;
            });
            this.$root.$on('toggleShowChartHeader', (changedChartItem) => {
                const dashboardChart = this.selectedDashboard.items.find(item => item.i == changedChartItem.i);
                dashboardChart.options.showChartHeaderConfig = changedChartItem.options.showChartHeaderConfig;
                this.saveDashboardItems(this.selectedDashboard);
            });
            window.addEventListener('resize', () => {
                this.isDesktopSize = window.innerWidth > 768;
            });
            eventBus.$on('dashboard-edit-mode-change', (value) => {
                this.editMode = value;

                if (value) {
                    this.onEditClicked();
                } else {
                    this.getSelectedDashboardDetails();
                }
            });
            eventBus.$on('dashboard-save-changes', () => {
                this.saveCurrentDashboard();
            });
            eventBus.$on('dashboard-add-component', value => {
                this.createDashboardItem(value);
            });
            eventBus.$on('dashboard-toggle-manage-dashboard', value => {
                this.isSelectedDashboardOptionsVisible = value;
            });
            eventBus.$on('roipActiveSpeaking', (data) => {
                this.roipActivelySpeaking = true;
                this.roipActivelySpeakingUsers = data.users;
                this.roipPad = data.pad;
            });
            eventBus.$on('roipInactiveSpeaking', () => {
                this.roipActivelySpeaking = false;
                this.roipActivelySpeakingUsers = [];
                this.roipPad = '';
            });
            this.$root.$on('setDashboards', value => {
                this.dashboards = value;    // todo: maybe race condition? not my fav system anyway
            });
        },
        mountWirelineHistoryListeners() {
            this.$root.$on('toggleWirelineCommentsModal',() => {
                this.$refs.commentModal.changeModalVisibility(true);
            });
            this.$root.$on('toogleToolstringJobConfigModal', () => {
                window.open(`/toolstring_config/${this.jobNumber}/${this.wirelineDataObj.number}`, '_self');
            });
            this.$root.$on('toggleMissRunModal', () => {
                this.$refs.missRunModal.changeModalVisibility(true);
            });
            this.$root.$on('toggleconditionModal', () => {
                this.$refs.conditionModal.changeModalVisibility(true);
            });
            this.$root.$on('forceUpdateWirelineHistory', () => {
                this.updateWirelineHistory(); //push for new wireline history updates
            });
        },
        createNewDashboard(copySelected) { // if copySelected is true, copy current layout
            this.$root.$emit('createNewDashboard', {
                id: null,
                name: copySelected ? this.selectedDashboard.name + " (Copy)" : "",
                layout: copySelected ? this.selectedDashboard.layout : [],
                items: copySelected ? this.selectedDashboard.items : [],
                layout_options: {},
                is_customer_default: false,
                is_user_shared: false,
                is_role_shared: false,
                shared_users: [],
                shared_roles: [],
                customer_id: this.customerId,
            });
        },
        saveDashboardItems(changedDashboard) {
            const url = '/dashboards/update/items';
            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    id: changedDashboard.id,
                    updatedItems: changedDashboard.items
                },
                dataType: 'json'
            })
            .done((msg) => {
                if (msg.error) {
                    console.warn('Failed to save chart header state:' + JSON.stringify(msg));
                    if(msg.status == 401){
                        console.warn('unauthorized');
                    }
                }
            })
        },
        getDimensionsForItem(item) {
            const itemData = this.selectedDashboard.items.find(e => e.i == item.i);
            const itemType = itemData ? itemData.type : null;

            if (itemType && this.displayItemDimensions[itemType])
                return this.displayItemDimensions[itemType];
        },
        layoutUpdatedEvent() {
            if (this.newItemAdded) {
                // Timeout is needed for the animation to complete
                setTimeout(() => window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: 'smooth'}), 250);
                this.newItemAdded = false;
            }
        },
        checkIsDashboardNameTaken(name) {
            if (!isFalsy(this.dashboards.find(dashboard => dashboard.name === name)) && this.initialDashboardName !== name) {
                this.isDashboardNameTaken = true;
            } else {
                this.isDashboardNameTaken = false;
            }

            return this.isDashboardNameTaken;
        },
        onDashboardOptionsSubmit(evt) {
            const self = this;
            evt.preventDefault();

            this.selectedDashboard.name = this.dashboardOptionsForm.dashboardName;
            this.selectedDashboard.are_trucks_synced = this.dashboardOptionsForm.syncTrucks == 'true';
            this.selectedDashboard.default_truck_number = this.dashboardOptionsForm.defaultTruck;
            this.selectedDashboard.shared_settings = this.dashboardOptionsForm.sharedSettings;
            this.selectedDashboard.are_charts_synced = this.dashboardOptionsForm.syncCharts == 'true';
            this.selectedDashboard.are_tooltips_synced = this.dashboardOptionsForm.syncChartTooltips == 'true';
            this.selectedDashboard.chart_tooltip_mode = this.dashboardOptionsForm.chartTooltipMode;

            if (!this.selectedDashboard.are_trucks_synced) {
                //if truck syncing was toggled off, we clear set dashboard truck settings
                this.componentsWithTruckSelection.forEach(componentType => {
                    this.componentWithResetTrucks(componentType);
                });
            }

            if (this.dashboardOptionsForm.sharedSettings == 'default') { //shared with all company users
                this.selectedDashboard.is_customer_default = true;
                this.selectedDashboard.is_role_shared = false;
                this.selectedDashboard.is_user_shared = false;
                this.selectedDashboard.shared_users = [];
                this.selectedDashboard.shared_roles = [];
            } else if (this.dashboardOptionsForm.sharedSettings == 'shared-roles') {
                this.selectedDashboard.is_role_shared = true;
                this.selectedDashboard.is_customer_default = false;
                this.selectedDashboard.is_user_shared = false;
                this.selectedDashboard.shared_users = [];
                this.dashboardOptionsForm.sharedRoles = [];

                this.dashboardOptionsForm.allRoles.forEach(r => {
                    r.pivot = {};

                    if (r.shared_settings == 'readonly') {
                        r.pivot.is_writable = false;
                        self.dashboardOptionsForm.sharedRoles.push(r);
                    } else if (r.shared_settings == 'readwrite') {
                        r.pivot.is_writable = true;
                        self.dashboardOptionsForm.sharedRoles.push(r);
                    }
                });

                this.selectedDashboard.shared_roles = this.dashboardOptionsForm.sharedRoles;
            } else if (this.dashboardOptionsForm.sharedSettings == 'shared-users') {
                this.selectedDashboard.is_user_shared = true;
                this.selectedDashboard.is_customer_default = false;
                this.selectedDashboard.is_role_shared = false;
                this.selectedDashboard.shared_roles = [];
                this.selectedDashboard.shared_users = this.dashboardOptionsForm.sharedUsers;
            } else {
                this.selectedDashboard.is_customer_default = false;
                this.selectedDashboard.is_role_shared = false;
                this.selectedDashboard.is_user_shared = false;
                this.selectedDashboard.shared_users = [];
                this.selectedDashboard.shared_roles = [];
            }

            if (this.newDashboardDraft) {
                this.selectedDashboard.layout = this.newDashboardDraft.layout;
                this.selectedDashboard.items = this.newDashboardDraft.items;
            }

            this.saveCurrentDashboard(() => self.$root.$emit('bv::toggle::collapse', 'sidebar-right'), true);
        },
        onDashboardOptionsReset(evt) {
            evt.preventDefault();
            this.dashboardOptionsForm.dashboardName = this.selectedDashboard.name;
            this.dashboardOptionsForm.checked = [];
        },
        handleDashboardOptionsVisibilityChange(isVisible) {
            if (isVisible) {
                this.dashboardOptionsForm.checked = [];
                this.dashboardOptionsForm.changed = false;
                if (this.newDashboardDraft) {
                    this.dashboardOptionsForm.dashboardName = this.newDashboardDraft.name;
                    this.dashboardOptionsForm.sharedSettings = 'private';
                } else {
                    this.dashboardOptionsForm.dashboardName = this.selectedDashboard.name;
                    this.dashboardOptionsForm.sharedRoles = this.selectedDashboard.shared_roles ? this.selectedDashboard.shared_roles : [];
                    this.dashboardOptionsForm.chartTooltipMode = this.selectedDashboard.chart_tooltip_mode;

                    if (this.selectedDashboard.default_truck_number)
                        this.dashboardOptionsForm.defaultTruck = this.selectedDashboard.default_truck_number;
                    
                    if (this.selectedDashboard.are_trucks_synced) {
                        this.dashboardOptionsForm.syncTrucks = 'true';
                    } else {
                        this.dashboardOptionsForm.syncTrucks = 'false';
                    }

                    if (this.selectedDashboard.are_charts_synced)
                        this.dashboardOptionsForm.syncCharts = 'true';

                    if (this.selectedDashboard.are_tooltips_synced)
                        this.dashboardOptionsForm.syncChartTooltips = 'true';

                    if (this.selectedDashboard.is_customer_default) {
                        this.dashboardOptionsForm.sharedSettings = 'default';
                    } else if (this.selectedDashboard.is_role_shared) {
                        this.dashboardOptionsForm.sharedSettings = 'shared-roles';
                        const self = this;

                        this.dashboardOptionsForm.sharedRoles = this.selectedDashboard.shared_roles.filter(shared_role => {
                            return self.allRoles.find(r => r.id == shared_role.id);
                        });
                    } else if (this.selectedDashboard.is_user_shared) {
                        this.dashboardOptionsForm.sharedSettings = 'shared-users';
                        this.dashboardOptionsForm.sharedUsers = this.selectedDashboard.shared_users;
                    } else {
                        this.dashboardOptionsForm.sharedSettings = 'private';
                    }
                }

                this.initialDashboardName = this.dashboardOptionsForm.dashboardName;
                this.dashboardOptionsForm.allRoles = this.allRoles.filter(r => r.has_dashboard_sharing);

                if (!this.isDashboardOwner && !isNullOrEmpty(this.allCustomerUsers)) { //add logged in customer to the shared user permision list
                    let authUser = this.allCustomerUsers.find((user)=>user.id===this.userId);

                    //authUser is undefined if viewing a job for a company different than the
                    //current user's company on a shared dashboard, so pull data from sharedUsers
                    if (authUser === undefined)
                        authUser = this.dashboardOptionsForm.sharedUsers.find((user)=>user.id===this.userId);
                    this.dashboardOptionsForm.sharedUsers = [
                        { 
                            ...authUser, 
                            isDisabled: true, 
                            pivot: { is_writable: true } 
                        }, 
                        ...this.dashboardOptionsForm.sharedUsers 
                    ];

                    this.dashboardOptionsForm.sharedUsers = this.dashboardOptionsForm.sharedUsers.filter((item,index,sharedUsersArray) =>
                        sharedUsersArray.findIndex(user=>(user.id === item.id)) === index);
                }

                if (!this.isDashboardOwner && !isNullOrEmpty(this.authUserRoles)) { //add logged in customer roles to role permission list
                    const authUserPermissions = this.authUserRoles.map((role)=> {
                        return {
                            ...role,
                            isDisabled: true,
                            pivot: { is_writable: true }
                        };
                    });

                    this.dashboardOptionsForm.sharedRoles = [ ...authUserPermissions, ...this.dashboardOptionsForm.sharedRoles ];
                }

                this.dashboardOptionsForm.allRoles = this.dashboardOptionsForm.allRoles.map(customerRole => {
                    const role = this.dashboardOptionsForm.sharedRoles.find(role => customerRole.id == role.id);
                    if (role) {
                        return {
                            ...customerRole,
                            isDisabled: role.isDisabled,
                            shared_settings: role.pivot.is_writable ? 'readwrite' : 'readonly'
                        };
                    } else {
                        return {
                            ...customerRole,
                            shared_settings: 'none'
                        };
                    }
                });
            }
        },
        handleComponentTypeChanged(item, index) {
            const gridItemRef = this.$refs['grid-item-' + index][0]? this.$refs['grid-item-' + index][0].getParentComponentRef() : null;
            switch(item.type) {
                case 'dashboard-frac-chart':
                    this.isDashboardLoading = true;
                    this.getDefaultChartConfigTemplate(this.jobNumber, result => {
                        item.options.configId = result.id;
                        this.isDashboardLoading = false;
                        item.isWireline = false;
                        if (result.header_style == 'wireline') {
                            item.isWireline = true;
                            this.initTruckSelection();
                        }
                    });
                    break;
                case 'kpi-progress':
                    item.options.type = 'kpi-progress';
                    break;
                case 'dashboard-comparison-chart':
                    item.options.type = 'frac';
                    item.options.filterOptionsData = {};
                    break;
                case 'summary-bar-component':
                    item.options.timeFormat = '12H';
                    break;
                default:
                    item.options = {};
                    break;
            }

            gridItemRef && gridItemRef.autoSize();
        },
        removeDashboardItem(item, index) {
            this.selectedDashboard.layout.splice(index,1);
            this.selectedDashboard.items = this.selectedDashboard.items.filter(e => e.i != item.i);
        },
        createDashboardItem(item) {
            this.dashboardComponentsValidator();
            const layout = this.selectedDashboard.layout;
            const newItem = { ...layout[layout.length-1] };

            const dimensions = this.displayItemDimensions[item.name];

            if (isNullOrEmpty(this.selectedDashboard.layout)) {
                newItem.i = 0;
            } else {
                newItem.i = Math.max(...this.selectedDashboard.layout.map(o => o.i)) + 1;
            }

            newItem.w = dimensions.initW;
            newItem.h = dimensions.initH;

            newItem.x = 0;
            newItem.y = (newItem.y || 0 )+ newItem.h;

            layout.push(newItem);

            const items = this.selectedDashboard.items;
            items.push({ type: item.name });

            items[items.length-1].i = newItem.i;

            switch (items[items.length-1].type) {
                case 'dashboard-frac-chart':
                    items[items.length-1].options = { hideCommentsTimeline: false,
                                                    showChartHeaderConfig: true };
                    this.isDashboardLoading = true;

                    this.getDefaultChartConfigTemplate(this.jobNumber, result => {
                        items[items.length-1].options.configId = result.id;
                        this.isDashboardLoading = false;
                        items[items.length-1].isWireline = false;
                        if (result.header_style == 'wireline') {
                            items[items.length-1].isWireline = true;
                            this.initTruckSelection();
                        }
                    });
                    break;
                case 'kpi-progress':
                    this.$set(items[items.length-1], 'options', {})
                    this.$set(items[items.length-1].options, 'type', 'kpi-progress')
                    break;
                case 'dashboard-comparison-chart':
                    items[items.length-1].options = { configId: 1, type: 'frac', filterOptionsData:{} };
                    break;
                case 'summary-bar-component':
                    items[items.length-1].options = {timeFormat: '12H'}
                    break;
                default:
                    items[items.length-1].options = {};
                    break;
            }

            this.newItemAdded = true;
            this.setDisplayItemHandleColor();
        },
        dashboardComponentsValidator() {
            // find layout and items mismatch and fix ( happens in older templates )
            if (this.selectedDashboard.layout.length > this.selectedDashboard.items.length)
                this.selectedDashboard.layout = this.selectedDashboard.layout.slice(0, this.selectedDashboard.items.length);
        },
        setDisplayItemHandleColor() {
            this.$nextTick(() => {
                const displayElements = document.getElementsByClassName('vue-resizable-handle');
                if(displayElements) {
                    const displayElementsAttay = Array.prototype.slice.call(displayElements);
                    displayElementsAttay.forEach( function(element) {
                        element.style.filter = 'invert(43%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(100%) contrast(83%)';
                        element.style.zIndex = '1000';
                    });
                }
            });
        },
        saveCurrentDashboard(onComplete, isPermissionUpdate) {
            const url = '/dashboards/update/';
            const self = this;
            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    id: self.newDashboardDraft ? null : self.selectedDashboard.id,
                    name: self.selectedDashboard.name,
                    layout: JSON.stringify(self.selectedDashboard.layout),
                    items: JSON.stringify(self.selectedDashboard.items),
                    layout_options: JSON.stringify(self.selectedDashboard.layout_options),
                    is_customer_default: self.selectedDashboard.is_customer_default,
                    are_charts_synced: self.selectedDashboard.are_charts_synced,
                    are_tooltips_synced: self.selectedDashboard.are_tooltips_synced,
                    chart_tooltip_mode: self.selectedDashboard.chart_tooltip_mode,
                    is_role_shared: self.selectedDashboard.is_role_shared,
                    is_user_shared: self.selectedDashboard.is_user_shared,
                    shared_roles: JSON.stringify(self.selectedDashboard.shared_roles),
                    shared_users: JSON.stringify(self.selectedDashboard.shared_users),
                    are_trucks_synced: self.selectedDashboard.are_trucks_synced,
                    default_truck_number: self.selectedDashboard.default_truck_number,
                    isPermissionUpdate: isPermissionUpdate,
                    customer_id: self.customer_id
                },
                dataType: 'json'
            })
            .done((data) => {
                self.editMode = false;
                self.selectedDashboard.id = data.id;

                self.getSelectedDashboardDetails();
                if(onComplete) {
                    onComplete();
                }
                localStorage.setItem('LastViewedDashboard' + self.userId, self.selectedDashboard.id);

                location.reload();
            })
            .fail((msg) => {
                console.warn('Failed to update dashboard:' + JSON.stringify(msg));
                alert('Something went wrong saving your changes! Please contact an administrator.');
            })
        },
        getSelectedDashboardDetails() {
            if(!this.selectedDashboard.id)
                return;

            this.isDashboardLoading = true;

            const url = '/dashboards/get/' + this.selectedDashboard.id;
            const self = this;
            $.get(
                url,
                {},
                function(data) {
                    if(data[0]) {
                        self.selectedDashboard.name = data[0].name;

                        // The original reference to layout must be maintained because
                        // We are passing it with a .sync modifier to grid layout
                        self.selectedDashboard.layout.length = 0;
                        data[0].layout.forEach(el => self.selectedDashboard.layout.push(el));

                        self.selectedDashboard.layout_options = data[0].layout_options;
                        self.selectedDashboard.items = data[0].items;
                        self.selectedDashboard.customer_id = data[0].customer_id;
                        self.selectedDashboard.created_by = data[0].created_by;


                        //backend stores bools as 1 or 0 in database, so convert to back to true/false
                        self.selectedDashboard.is_customer_default = data[0].is_customer_default === 1? true:false;
                        self.selectedDashboard.are_charts_synced = data[0].are_charts_synced === 1? true:false;
                        self.selectedDashboard.are_tooltips_synced = data[0].are_tooltips_synced === 1? true:false;
                        self.selectedDashboard.is_user_shared = data[0].is_user_shared === 1? true:false;
                        self.selectedDashboard.is_role_shared = data[0].is_role_shared === 1? true:false;
                        self.selectedDashboard.are_trucks_synced = data[0].are_trucks_synced;
                        self.selectedDashboard.default_truck_number = data[0].default_truck_number;
                        self.selectedDashboard.shared_users = data[0].users;
                        self.selectedDashboard.shared_roles = data[0].roles;

                        if(self.selectedDashboard.created_by != self.userId) {
                            self.selectedDashboard.is_shared = !self.selectedDashboard.is_customer_default ;

                            if(self.selectedDashboard.is_user_shared) {
                                const u = self.selectedDashboard.shared_users.find(u => u.id == self.userId);
                                self.selectedDashboard.is_writable = u ? !!u.pivot.is_writable : false;
                            }
                            else if(self.selectedDashboard.is_role_shared) {
                                /*
                                  The backend gave us this dashboard because one of the roles it has been shared with
                                  applies to our user. We need apply the highest permission given to us.

                                  We use .filter to see if any of the shared roles satisfied the two conditions:
                                   - belonging to our user
                                   - having read/write permissions

                                  If no truthy is_writable is found, the user has read-only permission.
                                */


                                const roles = self.selectedDashboard.shared_roles.filter(shared_role => {
                                    if(self.userRoles.find(user_role => user_role.id == shared_role.id)) {
                                        return shared_role.pivot.is_writable;
                                    }
                                });

                                self.selectedDashboard.is_writable = !!roles.length;
                            }
                        }
                        self.$root.$emit('updateNavSelectedDashboard', self.selectedDashboard);
                        self.isDashboardLoading = false;
                    }
                    else{
                        console.warn('malformed data:' + data);
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
                else if(jqXHR.status == 404 && localStorage.getItem('LastViewedDashboard' + self.userId)) {
                    // The dashboard ID stored in localStorage is not valid on this deployment
                    // Remove it from localStorage and try again
                    localStorage.removeItem('LastViewedDashboard' + self.userId);
                    self.getSelectedDashboardDetails();
                }
            });
        },
        deleteSelectedDashboard() {
            if(!confirm('Are you sure you want to delete this dashboard?'))
                return ;

            const url = '/dashboards/delete/';
            const self = this;

            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    id: self.selectedDashboard.id
                },
                dataType: 'json'
            })
            .done(() => {
                localStorage.removeItem('LastViewedDashboard' + self.userId);
                location.reload();
            })
            .fail((msg) => { alert('Failed to delete dashboard' + JSON.stringify(msg));})
        },
        convertDateTimeWithOffset(datetimeStr) {
            const date = moment(datetimeStr);
            date.add({ hours: this.job.hourOffset });

            return date;
        },
        updateChartComments() {
            const self = this;
            $.get('/chart-comments/' + this.jobNumber, {
                    '_token': $('meta[name="csrf-token"]').attr('content')
                },
                function (data) {
                    if(data.error) {
                        console.warn(data.error);
                    } else{
                        self.chartcommentsList = data;
                    }

                    setTimeout(self.updateChartComments, 1000);
                },
                'json'
            );
        },
        getJobInfo() {
            const url = '/jobs/' + this.jobNumber;
            const self = this;
            if (self.ajax) {
                $.get(
                    url,
                    function (data) {
                        if (data.error) {
                            self.jobAccessError = true;
                        } else {
                            self.job.padName = data.location;
                            document.title = "IWS - " + self.job.padName;
                            self.customer = data.customer.name;
                            self.customer_id = data.customer.id;
                            self.job.hourOffset = data.hourOffset;
                            self.job.shiftStart = data.shiftStart;
                            self.$set(self.job, 'id', data.id);
                            self.job.jobNumber = data.jobNumber
                            self.jobHourOffset = data.hourOffset;
                            self.jobEnd = data.end;
                            self.jobStart = data.start;
                            self.jobId = data.id;

                            self.job.start = self.convertDateTimeWithOffset(data.start).format('YYYY-MM-DD HH:mm:ss');
                            self.job.end = data.end ? self.convertDateTimeWithOffset(data.end).format('YYYY-MM-DD HH:mm:ss') : null;
                            const wells = [];
                            $.each(data.wells, function (index, value) {
                                value.activity = 'none';
                                value.currentStage = 0;
                                value.stageDuration = 'n/a';
                                value.status = 'Not updated';
                                value.process = [];
                                value.valves = [];
                                value.pressures = {};
                                value.pressures.casing = {
                                    value: 1.0,
                                    lastUpdate: new Date(),
                                    nodeIndex: index
                                };
                                value.configurationId = value.configuration.config_number;
                                wells.push(value);
                            });
                            self.wells = wells;
                            self.updateActivities();
                            self.getActivities();
                            self.getLatestActivitiesBySystem();
                            // removing this for now but leaving it for reference
                            //self.updateHandshakes();
                            self.updateEvents();

                            // disabling the call to alerts until the feature is improved
                            self.updateAlerts();
                            // chart comments usage was deprecated in 3.3.1, removing until revisited
                            // self.updateChartComments();
                            self.updateWirelineHistory();
                        }
                    },
                    'json'
                );
            }
        },

        updateActivities() {
            const url = '/activities/current/'+this.jobNumber;
            const self = this;
            if(self.ajax) {
                $.get(
                    url,
                    {},
                    function(data) {
                        self.inProgressActivities = data;
                        self.handleInProgressActivities(data);
                    },
                    'json'
                ).fail(function( jqXHR, textStatus, errorThrown ) {
                    if(jqXHR.status == 401) {
                        console.warn('unauthorized');
                        self.hasAuthError = true;
                    }
                    else {
                        setTimeout(self.updateActivities, 2000);
                    }
                });
            }
        },
        onInProgressActivity(activity) {
            const wellNumber = activity.wellNumber;
            const updatedActivities = this.inProgressActivities.map((act) => {
                if(act.wellNumber == wellNumber) {
                    return { //fixing signal activity schema to match frontend activity schema
                        ...act,
                        ...activity,
                        startTime: activity.data?.startTime,
                        endTime: activity.data?.endTime,
                        statusString: activity.data?.statusString,
                        activity: activity.data?.activity,
                        id: activity._id,
                        job_id: activity.jobId
                    }
                } else {
                    return act
                }

            });
            this.inProgressActivities = updatedActivities;
            this.handleInProgressActivities(updatedActivities);
        },
        handleInProgressActivities(data) {
            const self = this;
            const wells = [];
            for (let i = 0; i < data.length; i++) {
                self.initActivityCallEnded = true;
                const wellNumber = data[i].wellNumber;
                if(wells.find(item => item===wellNumber)) { //this means a duplicate
                    console.error(`error with data : duplicate activities in well number ${wellNumber} \n response :`,  data);
                }
                wells.push(wellNumber);
                const wellIndex = self.wells.findIndex(w => w?.index === wellNumber);
                if (wellIndex > -1) {
                    self.wells[wellIndex].activity = data[i].activity;
                    self.wells[wellIndex].currentStage = data[i].stageNumber;
                    let dataStatus = data[i].statusString;
                    let activityStatus = data[i].data ? data[i].data.statusString : dataStatus;
                    self.wells[wellIndex].status = dataStatus ? dataStatus : activityStatus;

                    self.wells[wellIndex].start = moment.parseZone(data[i].startTime);

                    self.wells[wellIndex].stageDuration = data[i].value? data[i].value : data[i].duration;
                    self.wells[wellIndex].activityData = data[i].data;

                    //Clear cf flags on this well and let them be regenerated
                    self.wells[wellIndex].cfChangeoverActive = false;
                    self.wells[wellIndex].cfChangeoverState = null;
                }
            }

            for (let i = 0; i < data.length; i++) {
                let activityData = null;
                try {
                    activityData = JSON.parse(data[i].data);
                } catch (ex) {}

                // initWirelineFetch
                if(data[i].statusString == 'Perforating' && (activityData == null || self.initWirelineFetch == false)) {
                    self.currentWellIndex  = data[i].wellNumber;
                    if (self.isWirelineRequired) {
                        self.initWirelineFetch = true;
                        self.updateWirelineHistory();
                    }
                    break;
                }
            }

            //Should now check if there are any CF changeovers
            if(self.isContinuousFrac) {
                //Filter down to only wells in the frac state
                const fracingWells = self.wells.filter(targetWell => targetWell.activity == 'frac');
                //Need to determine if the job is multifrac or not
                if(this.isMultiFrac) {
                    const systemGroupedFracingWells = _.groupBy(fracingWells, targetWell => {
                        return targetWell.activityData?.service_id ? targetWell.activityData.service_id : 'NA';
                    });
                    //Any set other then NA set that has more then one elements is in a continuous frac changeover state and should be flagged as such
                    Object.keys(systemGroupedFracingWells).forEach((targetKey) => {
                        const targetGroup = systemGroupedFracingWells[targetKey];
                        if(targetGroup.length == 2 && targetKey != 'NA') {
                            const sortedGroup = targetGroup.sort((a,b) => {
                                return moment(a.activityData.startTime).isSameOrBefore(moment(b.activityData.startTime)) ? -1 : 1;
                            });

                            const fromWell = sortedGroup[0];
                            const toWell = sortedGroup[1];

                            self.assignContinuousFracInfoToWells(fromWell, toWell);
                        }
                    });
                }else if(fracingWells.length == 2) {
                    const sortedGroup = fracingWells.sort((a,b) => {
                        return moment(a.activityData.startTime).isSameOrBefore(moment(b.activityData.startTime)) ? -1 : 1;
                    });

                    const fromWell = sortedGroup[0];
                    const toWell = sortedGroup[1];

                    self.assignContinuousFracInfoToWells(fromWell, toWell);
                }else if (fracingWells.length > 2) {
                    console.error("Indeterminate case cannot do continuous frac calculation when 3 wells are in frac and no system_id's exist");
                }
            }
        },
        assignContinuousFracInfoToWells(fromWell, toWell) {
            let targetFromWellIndex = this.wells.findIndex((foundWell) => {
                return foundWell.id == fromWell.id;
            });

            this.wells[targetFromWellIndex].cfChangeoverActive = true;
            this.wells[targetFromWellIndex].cfChangeoverState = 'from';

            let targetToWellIndex = this.wells.findIndex((foundWell) => {
                return foundWell.id == toWell.id;
            });

            this.wells[targetToWellIndex].cfChangeoverActive = true;
            this.wells[targetToWellIndex].cfChangeoverState = 'to';
        },
        lastNotification() {
            if (!this.userNotifications.length)
                return;
            return this.userNotifications[this.userNotifications.length-1];
        },
        getNotifications() {
            const url = '/notifications';
            const self = this;

            if(self.ajax) {
                $.get(url,
                    {},
                    function(data) {
                        if(Object.keys(data).length > 0) {
                            self.userNotifications = data;
                            const notification = self.lastNotification();
                            self.$notify({
                                group: 'notifications',
                                title: 'Dashboard shared',
                                text: notification.message,
                                duration: 5000
                            });
                            self.closeNotification(notification.id);
                        }
                        setTimeout(self.getNotifications, 5000);
                    },
                    'json'
                ).fail(function( jqXHR, textStatus, errorThrown ) {
                    if(jqXHR.status == 401) {
                        console.warn('unauthorized');
                        self.hasAuthError = true;
                    }
                    else {
                        setTimeout(self.getNotifications, 5000);
                    }
                });
            }
        },
        closeNotification(notification_id) {
            const url = '/notifications/close';

            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    id: notification_id
                },
                dataType: 'json'
            })
            .fail((msg) => { alert('Failed to close notification' + JSON.stringify(msg));});
        },
        updateEvents() {
            const url = '/events/'+this.jobNumber;
            const self = this;
            if(self.ajax) {
                $.get(
                    url,
                    {
                        startDateTime: self.lastEventTime
                    },
                    function(eventData) {
                        if(eventData.length>0) {
                            self.lastEventTime = eventData[0].utc_start_time;
                            const localTimeEventData = eventData.map((event, index) => {
                                return {
                                    ...event,
                                    start_time: event.start_time? moment(event.start_time).format('YYYY-MM-DD HH:mm:ss') : null,
                                    end_time: event.end_time ? moment(event.end_time).format('YYYY-MM-DD HH:mm:ss') : null
                                };
                            });

                            self.events = localTimeEventData;
                        }
                    },
                    'json'
                ).fail(function( jqXHR, textStatus, errorThrown ) {
                    if(jqXHR.status == 401) {
                        console.warn('unauthorized');
                        self.hasAuthError = true;
                    }
                    else {
                        setTimeout(self.updateEvents, 2000);
                    }
                });
            }
        },

        updateAlerts() {
            const url = '/messagesForJob/'+this.jobNumber;
            const self = this;
            if(self.ajax) {
                $.get(
                    url,
                    {
                        type: 'alert',
                        subType: 'alarm',
                        clusterAlertInfo: {
                            getAllAlerts: true
                        },
                        //To do: Limit can be removed once the issue with too many alert records
                        //causing page lag is resolved.
                        limit: 10
                    },
                    function(data) {
                        self.messagesArray = [...data.events, ...data.shotAlerts, ...data.plugAlerts, ...data.cluster];
                        self.eventAlertHistory = data.alertHistory;
                        self.eventAlerts = data.events;
                        setTimeout(self.updateAlerts, 20000);
                    },
                    'json'
                ).fail(function( jqXHR, textStatus, errorThrown ) {
                    if(jqXHR.status == 401) {
                        console.warn('unauthorized');
                        self.hasAuthError = true;
                    }
                    else {
                        setTimeout(self.updateAlerts, 20000);
                    }
                });
            }
        },
        onSummaryXAxisChange({min, max}) {
            this.getActivities(min, max);
        },
        onSyncedViewChanged({chartSourceId, panXMin, panXMax, tooltipX, customAxisTagName, hideTooltips, moveComplete, popZoomHistory}) {
            this.globalChartSynced = {chartSourceId, panXMin, panXMax, tooltipX, customAxisTagName, hideTooltips, moveComplete, popZoomHistory};
        },
        async getLatestActivitiesBySystem() {
            const url = '/system-activities-latest/' + this.jobNumber;
            this.latestActivityBySystem = await $.get(url, { '_token': GlobalFunctions.getCSRFToken() });
        },
        async getActivities(startUTC=null, endUTC=null) {
            const url = '/activities-summary/'+this.jobNumber;
            // check is it a completed job
            const jobEndTime = this.jobEnd? moment.utc(this.jobEnd, 'YYYY-MM-DD HH:mm:ss').valueOf() : moment.utc().valueOf();
            const startTime = startUTC? moment.utc(startUTC).valueOf() : moment.utc(jobEndTime).subtract(this.summaryBarTimeFrame, 'hours').valueOf();
            const endTime = endUTC? moment.utc(endUTC).valueOf() : jobEndTime;
            this.summaryBarEndTime = jobEndTime;
            this.summaryBarStartDate = moment.utc(startTime).valueOf();
            const wellIndexes = this.wells.map(well => Number(well.index));
            const data = {
                isMultiFrac: this.isMultiFrac,
                isMultiWireline: this.isMultiWireline,
                wirelineSystems: this.wirelineSystems,
                isSimuFrac: this.isSimuFrac,
                fracSystems: this.fracSystems,
                startTime,
                endTime,
                wellIndexes,
                getUppperLowerBound: startUTC? false: true
            };
            const activitiesResponse = await $.get(url, data);

            if(activitiesResponse?.activitiesStartTime) {
                this.activitiesStartTime = activitiesResponse.activitiesStartTime;
                this.activitiesEndTime = activitiesResponse.activitiesEndTime;
            }

            const activities = activitiesResponse.activities;
            const newActivities = [...this.activitiesFetched, ...activities].reduce((acc, current) => {
                const x = acc.find(item => item.id === current.id);
                if (!x) {
                    return acc.concat([current]);
                } else {
                    return acc;
                }
            }, []);

            this.activitiesFetched = newActivities;
            if (this.updateEndTimeInterval)
                clearInterval(this.updateEndTimeInterval);
            this.updateEndTimeInterval = setInterval(this.updateEndTime, 2000);
            return;
        },
        updateEndTime() {
            // send signal to summary bar chart to increase end time
            this.summaryBarEndTime = this.jobEnd ? moment.utc(this.jobEnd, 'YYYY-MM-DD HH:mm:ss').valueOf() : moment.utc().valueOf();
            this.latestActivityData = [];
        },
        signalrConnectedHandler() {
            this.signalRModalVisible = false;
            this.signalRConnected = true;
        },
        signalrConnectionFailedHandler(error, resolutionPackage) {
            this.resolutionPackage = resolutionPackage;
            this.signalRModalVisible = true;
            this.signalRConnected = false;
        },
        signalrMessageHandler(message) {
            eventBus.$emit('signalr-updated', message);
            if(message.category =='roip'){
                eventBus.$emit('new-roip-message', message);
                return;
            }

            //if frac complete event comes in on any well, add 1 to completed stages
            if (message.category == 'wellActivity' && message.subCategory == 'inProgress'
                && message.data.statusString == 'Stage Complete' && message.data.manualIntervention == null) {
                this.totalCompletedStages++;
                if (message.wellNumber) {
                    this.previousSignalRStageWellPairs.push({'wellNumber:': message.wellNumber, 'stageNumber:': message.stageNumber});
                    this.previousSignalRStageWellPairs = this.previousSignalRStageWellPairs.map(function (currentObject) {
                        if (currentObject['wellNumber'] == message.wellNumber) {
                            return {
                                'wellNumber': message.wellNumber,
                                'stageNumber': message.stageNumber
                            }
                        }
                        else {
                            return currentObject;
                        }
                    });
                }
            }

            if (message.category == 'wellActivity') {
                this.getLatestActivitiesBySystem();
                this.currentEvent = message;
                if(message.subCategory == 'inProgress') {
                    this.onInProgressActivity(message)
                }
            }

            if (message.wellNumber) {
                for (let i = 0; i < this.previousSignalRStageWellPairs.length; i++) {
                    if (this.previousSignalRStageWellPairs[i]['wellNumber'] == message.wellNumber && this.previousSignalRStageWellPairs[i]['stageNumber'] == message.stagenumber && message.subCategory != 'completed' && message.category == 'wellActivity' &&  message.data.activity == 'frac') {
                        this.totalCompletedStages--;
                    }
                }
            }

            if(message.category == 'handshake') {
                const eventToUpdate = this.events.findIndex((event)=> event.reference === message.reference);
                if(eventToUpdate > -1) {
                    let eventUpdate = {};
                    switch (message.subCategory) {
                    case 'approval':
                        switch (message.data.requesterRole) {
                        case 'wsm':
                            eventUpdate = {
                                wsmUser: message.data.requesterUser,
                                wsmTimestamp: message.data.startTime,
                                end_time: message.timestamp? moment.utc(message.timestamp).add({ hours: this.jobHourOffset }).format('YYYY-MM-DD HH:mm:ss') : null
                            };
                            break;
                        case 'frac':
                            eventUpdate = {
                                wsmUser: message.data.requesterUser,
                                wsmTimestamp: message.data.startTime
                            };
                            break;
                        case 'wireline':
                            eventUpdate = {
                                fracUser: message.data.requesterUser,
                                fracTimestamp: message.data.startTime
                            };
                            break;
                        case 'tech':
                            eventUpdate = {
                                techUser: message.data.requesterUser,
                                techTimestamp: message.data.startTime
                            };
                            break;
                        default:
                            break;
                        }
                        break;
                    case 'completed':
                        eventUpdate = {
                            status: message.subCategory,
                            end_time: message.timestamp? moment.utc(message.timestamp).add({ hours: this.jobHourOffset }).format('YYYY-MM-DD HH:mm:ss') : null
                        };
                        break;
                    case 'rejected':
                        eventUpdate = {
                            status: message.subCategory,
                            end_time: message.timestamp? moment.utc(message.timestamp).add({ hours: this.jobHourOffset }).format('YYYY-MM-DD HH:mm:ss') : null,
                            rejectUser: message.data.requesterUser,
                            rejectRole: message.data.requesterRole,
                            rejectReason: message.data.rejectReason,
                            rejectTime: message.data.endTime,
                        };
                        break;
                    default:
                        break;
                    }

                    const newEvent = {
                        ...this.events[eventToUpdate],
                        ...eventUpdate,
                        remarks: message.data.statusMessage
                                        + ', ' + message.subCategory
                                        + ', requested by ' + message.data.requesterUser
                                        + ', Stage ' + message.stageNumber
                    };

                    Vue.set(this.events, eventToUpdate, newEvent);
                } else {
                    const wellID = this.wells.find((well)=> well.index == message.wellNumber).id;
                    const eventFormat = {
                        reference: message.reference,
                        utc_start_time: message.data.startTime,
                        start_time: message.data.startTime? moment.utc(message.data.startTime).add({ hours: this.jobHourOffset }).format('YYYY-MM-DD HH:mm:ss') : null,
                        end_time: message.data.end_time ? moment.utc(message.data.end_time).add({ hours: this.jobHourOffset }).format('YYYY-MM-DD HH:mm:ss') : null,
                        reason: message.data.requestReason,
                        activity: null,
                        well_id: wellID,
                        requesterUser: message.data.requesterUser,
                        remarks: message.data.statusMessage
                                        + ', ' + message.subCategory
                                        + ', requested by ' + message.data.requesterUser
                                        + ', Stage ' + message.stageNumber,
                        type: 'handshake'
                    };

                    this.events = [eventFormat, ...this.events];
                }

                this.latestHandshake.push(message);
            }

            if(message.category == 'wellSummary') {
                this.latestWellSummary.push(message);
            }

            if (message.category == 'handshake' && message.subCategory == 'completed') {
                this.latestHandshakeEvent = message;
            }

            if (message.category == 'wellActivity' && message.subCategory == 'inProgress' && message.stageNumber > 0) {
                //a new stage was started on a well, so pass stage data to all dashboard charts
                if (message.data.statusString === 'Perforating' || message.data.statusString === 'Fracing') {
                    this.selectedDashboard.items.forEach(item=>{
                        if (item.type === 'dashboard-frac-chart') {
                            this.latestStageMarkerData = message;
                        }
                    });
                }
            }

            let isInProgress = message.category == 'wellActivity' && message.subCategory == 'inProgress';
            let isCompleted = message.category == 'wellActivity' && message.subCategory == 'completed';

            if ((isInProgress || isCompleted) && (message.data?.activity === 'frac' || message.data?.activity === 'wireline')) {
                let newActivityStartTime = message.data?.startTime;
                let newActivityEndTime = message.data?.endTime;
                let startDateNew = new Date(newActivityStartTime);
                let endDateNew = new Date(newActivityEndTime);
                let startDateOld = new Date(this.activitiesStartTime);
                let endDateOld = new Date(this.activitiesEndTime);
                let formattedStartTime = moment.utc(newActivityStartTime).format('YYYY-MM-DD HH:mm:ss');
                let formattedEndTime = isCompleted ? moment.utc(newActivityEndTime).format('YYYY-MM-DD HH:mm:ss') : null;

                this.summaryBarStartDate = newActivityStartTime ? moment.utc(newActivityStartTime, 'YYYY-MM-DD HH:mm:ss').valueOf() : this.summaryBarStartDate;
                this.summaryBarEndTime = newActivityEndTime ? moment.utc(newActivityEndTime, 'YYYY-MM-DD HH:mm:ss').valueOf() : this.summaryBarEndTime;
                this.activitiesStartTime = startDateNew < startDateOld ? formattedStartTime : this.activitiesStartTime;
                this.activitiesEndTime = endDateNew > endDateOld ? formattedEndTime : this.activitiesEndTime;

                // format signalr message to match the format of the data coming from getActivities()
                let formattedMessage = [{
                    ...message,
                    'id': uuidv4(),
                    'activity': message.data.activity,
                    'job_id': message.jobId,
                    'endTime': formattedEndTime,
                    'startTime': formattedStartTime,
                    'statusString': message.data.statusString,
                    data: {
                        ...message.data,
                    }
                }];
                this.updatedWells.push(message.wellNumber);
                this.latestActivityData = formattedMessage;
            }

            if(message.telemetry) {
                const flagKeys = Object.keys(message.telemetry).filter(key => {
                    return key.includes('flag_');
                });

                flagKeys.forEach(key => {
                    Vue.set(this.rawFlagTelemetryData.streamData, key, message.telemetry[key]);
                });
            }

            if(message.category == 'pumpFlag') {
                if(message.subCategory == 'placeFlag') {
                    this.rawFlagTelemetryData.activeFlags.push(message);
                }
                else if(message.subCategory == 'completed') {
                    this.rawFlagTelemetryData.completedRef.push(message.reference);
                }
                else if (message.subCategory == 'deleteFlag') {
                    this.rawFlagTelemetryData.deletedRef.push(message.reference);
                }

            }

            if(message.msTimestamp) {
                this.lastSignalRTimestamp = message.msTimestamp;

                //convert signalR response to data
                const newData = [];
                const timestamp = message.msTimestamp;

                //telemetry data has a single data point
                //wireline data is considered "high speed" and comes batched as an array
                if(message.telemetry) {
                    for(const telemetryDataTag in message.telemetry) {
                        newData.push({
                            'tagName': telemetryDataTag,
                            'dataVal': message.telemetry[telemetryDataTag],
                            'dateTimestamp': timestamp,
                            'jobNumber': this.jobNumber,
                            'lastReportedStr': 'Less than a second ago'
                        });
                    }
                }

                //TODO: handle all "high speed" data instead of just averaging the value
                if(message.wireline) {
                    for(const wirelineDataTag in message.wireline) {
                        const arrayOfData = message.wireline[wirelineDataTag];
                        const average = arrayOfData.reduce( ( p, c ) => p + c, 0 ) / arrayOfData.length;
                        newData.push({
                            'tagName': wirelineDataTag,
                            'dataVal': average,
                            'dateTimestamp': timestamp,
                            'jobNumber': this.jobNumber,
                            'lastReportedStr': 'Less than a second ago'
                        });
                    }
                }

                // Check existing data in latestDataCollection, update any entries with new data, add any new ones
                // Create a hashmap of all the existing tagnames to their current index
                const latestCollectionMap = {};
                this.latestDataCollection.forEach((existingEntry, index) => latestCollectionMap[existingEntry?.tagName] = index);

                for (const newEntry of newData) {
                    if (newEntry.tagName in latestCollectionMap) {
                        // Update existing entry
                        Vue.set(this.latestDataCollection, latestCollectionMap[newEntry.tagName], newEntry);
                    } else {
                        // Doesn't exist, add new entry
                        this.latestDataCollection.push(newEntry);
                    }
                }

                // check if existing entry has become stale by comparing dateTimestamp to TIME_SERIES_STALE_THRESHOLD_MS
                this.latestDataCollection.forEach((existingEntry, index) => {
                    const timeDiff = timestamp - existingEntry.dateTimestamp;

                    if (timestamp - existingEntry.dateTimestamp > TIME_SERIES_STALE_THRESHOLD_MS) {
                        this.latestDataCollection.splice(index, 1);
                    } else if (timeDiff > 1000) {
                        Vue.set(existingEntry, 'lastReportedStr', 'Last updated ' + (timeDiff/1000).toFixed(1) + 's ago');
                    }
                });

                this.dataReceived = true;

                this.sandWeights = [];
                this.pumpdownWeights = [];
                this.waterStorageWeights = [];
                this.impoundmentWeights = [];
                this.gasBustersWeights = [];
                this.magnumASTWeights = [];
                this.edgeLevelPumpdownWeights = [];

                this.pressure.crownPressures = [];

                const data = this.latestDataCollection;
                eventBus.$emit('updateLatestDataCollection', this.latestDataCollection); // Emit latest data collection for nav display

                for (let i = 0; i < data.length; i++) {
                    //New casing pressure tags
                    const regexWellheadTagMatch = /wellhead\_\d+\_pressure\_wellbore/;

                    const wellheadTagMatches = data[i].tagName.match(regexWellheadTagMatch);
                    if(wellheadTagMatches) {
                        const regexWellheadIdMatch = /\d+/;
                        const idMatch = data[i].tagName.match(regexWellheadIdMatch);
                        //wellhead indexing starts at 1 instead of 0 so need to shift by 1
                        const wellindex = parseInt(idMatch[0]) - 1;
                        this.pressure.casingPressures[wellindex] = parseFloat(data[i].dataVal).toFixed(1);
                    }

                    const regexWellCrownTagMatch = /wellhead\_\d+\_pressure\_crown/;
                    const wellcrownTagMatches = data[i].tagName.match(regexWellCrownTagMatch);

                    let isCrownPressureExistInTags = false;
                    if(this.tags && this.tags.length > 0) {
                        isCrownPressureExistInTags = this.tags.find((tag)=>{
                            return tag.name.match(regexWellCrownTagMatch)? true : false;
                        }) !== undefined;
                    }

                    if(wellcrownTagMatches) {
                        const regexWellheadIdMatch = /\d+/;
                        const idMatch = data[i].tagName.match(regexWellheadIdMatch);
                        //wellhead indexing starts at 1 instead of 0 so need to shift by 1
                        const wellindex = parseInt(idMatch[0]) - 1;
                        this.pressure.crownPressures[wellindex] = [parseFloat(data[i].dataVal).toFixed(1), isCrownPressureExistInTags];
                    }

                    switch (data[i].tagName) {
                    case 'pressureWell0':
                        this.pressure.casingPressures[0] = parseFloat(data[i].dataVal).toFixed(1);
                        break;
                    case 'pressureWell1':
                        if (this.pressure.casingPressures.length > 1)
                        {this.pressure.casingPressures[1] = parseFloat(data[i].dataVal).toFixed(1);}
                        break;
                    case 'pressureWell2':
                        if (this.pressure.casingPressures.length > 2)
                        {this.pressure.casingPressures[2] = parseFloat(data[i].dataVal).toFixed(1);}
                        break;
                    case 'pressureWell3':
                        if (this.pressure.casingPressures.length > 3)
                        {this.pressure.casingPressures[3] = parseFloat(data[i].dataVal).toFixed(1);}
                        break;
                    case 'pressureWell4':
                        if (this.pressure.casingPressures.length > 4)
                        {this.pressure.casingPressures[4] = parseFloat(data[i].dataVal).toFixed(1);}
                        break;
                    case 'pressureWell5':
                        if (this.pressure.casingPressures.length > 5)
                        {this.pressure.casingPressures[5] = parseFloat(data[i].dataVal).toFixed(1);}
                        break;
                    case 'pressureZipper':
                        this.pressure.zipper = parseFloat(data[i].dataVal).toFixed(1);
                        break;
                    case 'pressurePumpdown':
                        this.pressure.pumpdown = parseFloat(data[i].dataVal);
                        break;
                    case 'frac_wellheadTreatment':
                        //frac_wellheadTreatment data is swapped with frac_wellheadStage in the db
                        this.frac.currentWellheadStage = parseInt(data[i].dataVal);
                        break;
                    case 'frac_wellheadStage':
                        //frac_wellheadStage data is swapped with frac_wellheadTreatment in the db
                        this.frac.currentWellheadTreatment = parseInt(data[i].dataVal);
                        break;
                    case 'tank_1':
                        this.pumpdownWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_2':
                        this.pumpdownWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_3':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_4':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_5':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_6':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_7':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_8':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_9':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_10':
                        this.waterStorageWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_11':
                        this.impoundmentWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_12':
                        this.gasBustersWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_13':
                        this.gasBustersWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'tank_14':
                        this.magnumASTWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'edge_level_usable_usa_ast':
                        this.edgeLevelAst = parseFloat(data[i].dataVal);
                        break;
                    case 'edge_level_usable_fresh_osmosis':
                        this.edgeLevelFreshOsmosis = parseFloat(data[i].dataVal);
                        break;
                    case 'edge_level_perc_usa_ast':
                        this.edgeLevelPercAst = parseFloat(data[i].dataVal);
                        break;
                    case 'edge_level_perc_fresh_osmosis':
                        this.edgeLevelPercFreshOsmosis = parseFloat(data[i].dataVal);
                        break;
                    case 'edge_level_usable_waste_osmosis':
                        this.edgeLevelWasteOsmosis = parseFloat(data[i].dataVal);
                        break;
                    case 'edge_level_usable_usa_pumpdown_tank1':
                        this.edgeLevelPumpdownWeights.push(parseFloat(data[i].dataVal));
                        break;
                    case 'edge_level_usable_usa_pumpdown_tank2':
                        this.edgeLevelPumpdownWeights.push(parseFloat(data[i].dataVal));
                        break;
                    default:
                        break;
                    }
                    this.wirelineSystemsData.forEach(wirelineSystem => {
                        const wirelineTagPrefix = 'wireline_';
                        //Default to checking for tags with system number 1 if no wireline system data exists
                        const targetWirelineSystemNumber = wirelineSystem.number;
                        if(data[i].tagName.substring(0,wirelineTagPrefix.length) == wirelineTagPrefix) {
                            if(data[i].tagName == 'wireline_' + targetWirelineSystemNumber + '_tool_diameter') {
                                wirelineSystem.diameter = parseFloat(data[i].dataVal).toFixed(1);
                            }
                            else if(data[i].tagName == 'wireline_' + targetWirelineSystemNumber + '_depth_rt') {
                                wirelineSystem.depth = parseFloat(data[i].dataVal).toFixed(1);
                            }
                            else if(data[i].tagName == 'wireline_' + targetWirelineSystemNumber + '_line_speed') {
                                wirelineSystem.speed = parseFloat(data[i].dataVal).toFixed(1);
                            }
                            else if(data[i].tagName == 'wireline_' + targetWirelineSystemNumber + '_line_tension') {
                                wirelineSystem.tension = parseFloat(data[i].dataVal).toFixed(1);
                            }
                            else if(data[i].tagName == 'wireline_' + targetWirelineSystemNumber + '_line_current') {
                                wirelineSystem.lineCurrent = parseFloat(data[i].dataVal).toFixed(1);
                            }
                            else if(data[i].tagName == 'wireline_' + targetWirelineSystemNumber + '_line_voltage') {
                                wirelineSystem.lineVoltage = parseFloat(data[i].dataVal).toFixed(1);
                            }
                        }
                    });

                    if(data[i].tagName.startsWith('proppant_sand_weight_'))
                    {
                        this.sandWeights.push(parseFloat(data[i].dataVal));
                    }
                }

                wellFunctions.setWellConfiguration(this.wells, data);
                this.updatedWellValves(); //ensures correct number of valves
            }

            if(message.category == 'ping'){
                if(message.reference == this.lastPing.system.reference){
                  //got our data response for the last ping
                  let timestamp = new Date()
                  let timestampOrigin = new Date(this.lastPing.system.requestTime)
                  let dt = timestamp.valueOf() - timestampOrigin.valueOf()
                  this.lastPing.system.dataReceivedTime = timestamp.toISOString()
                  this.lastPing.system.dt = dt
                }
            }
        },
        handleCreateCommentButtonClicked() {
            if(this.$refs['dashboardFracComponent'])
            {this.$refs['dashboardFracComponent'].handleCreateCommentButtonClicked();}
        },
        isTooltipSyncingValid() {
            if (this.dashboardOptionsForm.syncCharts === 'false') {
                this.dashboardOptionsForm.syncChartTooltips = 'false';
                return false;
            }
            return true;
        }
    },

    created() {
        this.mountListeners();
    },
    mounted() {
        //ALWAYS FIRST
        this.initializeSignalRConnection(this.sessionId, this.signalrMessageHandler, this.signalrConnectedHandler, this.signalrConnectionFailedHandler);
        this.getJobInfo();
        getUnixTimeStampMs().then(res => this.utcDifference = res - moment.utc().valueOf());
        eventBus.$emit('updateUtcDifference', this.utcDifference); // Emit UTC difference for nav display

        if (this.chartcomments)
            this.chartcommentsList = this.chartcomments;

        //create an object to hold the data for each wireline system on the job
        if (this.wirelineSystems?.length > 0) {
            this.wirelineSystems.forEach(wirelineSystem => {
                const wireline = _.cloneDeep(this.wirelineDataObj);
                wireline.number = wirelineSystem.number;
                this.wirelineSystemsData.push(wireline);
            });
        } else {
            this.wirelineSystemsData[0] = _.cloneDeep(this.wirelineDataObj);
        }
        
        //12h is the old default, when the feature flag is removed, it's no longer needed
        this.summaryBarTimeFrame = isFeatureFlagged('NEW_SUMMARY_BAR') ? DEFAULT_SUMMARY_BAR_TIME_FRAME : 12;
        if (pingInterval != -1) { // ignore if variable set to -1
          setInterval(() => this.ping(), pingInterval)
          this.ping()
        }

        this.availableDisplayItems.forEach(item => {
            item.value = item.name;
            item.text = item.friendlyName;

            this.displayItemDimensions[item.name] = {
                minW: item.minW || 2,
                minH: item.minH || 6,
                maxW: item.maxW || 12,
                maxH: item.maxH || 100,
                initW: item.initW || item.minW,
                initH: item.initH || item.minH,
            };
        });

        const sortedComponents = this.availableDisplayItems
            .filter(_item => !(_item.name in this.componentSpecificPermissions) || this.componentSpecificPermissions[_item.name])
            .sort((a, b) => {
                if (a.text < b.text)
                    return -1;
                if (a.text > b.text)
                    return 1;
                return 0;
            });

        this.$root.$emit('setAvailableDisplayItems', sortedComponents);
    }
}
</script>

<style scoped>
#dashboardSaveButton {
    margin-right:10px !important;
    margin-left:18px !important;
}

:deep(#dropdown-1 .btn-outline-light), #dashboardDiscardButton  {
    border-color: #EAECF0 !important;
}
:deep(#dropdown-1 .dropdown-toggle:hover) {
    /*apparent bug in bootstrap means that the normal bootstrap color
    rules don't play nice with hover events on outline-light varients of b-dropdown */
    color: black !important;
  }
.iconTextProperties {
    font-weight:400;
    color:white;
}
.dashboardIconButton {
    margin: 0rem 0.2rem;
    background-color: transparent;
    color:white;
    border:none;
}

#dashboardSaveButton, #dashboardDiscardButton, .dashboardIconButton {
    /*Some hidden styling, perhaps due to flexbox, displays some dashboard buttons as different heights */
    height: 2rem;
}
.dashboardIconButton:hover {
    border-radius: 5px;
    background-color: #6c757d;
    -webkit-transition: background-color 200ms linear;
    -ms-transition: background-color 200ms linear;
    transition: background-color 200ms linear;
}
@media only screen and (max-width:1024px) {
    .iconTextVisibility {
        visibility: hidden;
        display:none;
    }
    .iconButtonPadding {
        padding: 0rem;
    }
    .iconPadding {
        padding: 0.1rem 0.3rem;
    }
}
@media only screen and (min-width: 1024px) and (max-width: 1920px) {
    .iconFontSize {
        font-size: 12px !important;
    }
    .iconTextVisibility {
        visibility: visible;
        display:inline;
    }
    .iconButtonPadding {
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    }
    .iconPadding {
        padding-bottom: 0.3rem;
    }
}
@media only screen and (min-width: 1920px) {
    .iconFontSize {
        font-size: 14px !important;
    }
    .iconTextVisibility {
        visibility: visible;
        display:inline;
    }
    .iconButtonPadding {
        padding: 0.25rem 0.5rem 0.25rem 0.25rem;
    }
    .iconPadding {
        padding-bottom: 0.3rem;
    }
}
.dropdown-toggle
{
    color:white !important;
}
.dropdown-toggle:hover
{
    color:rgba(255,255,250,.7) !important;
}


.autocomplete-result-list {
    height: 200px;
    overflow: auto;
    padding: 0px;
}
.bar_button{
  padding: 4px;
  font-size: 13px;
}

.info_group  {
  padding: 6px 6px;
  font-weight: bold;
}

.info_group_title{
  font-size: 1.5em;
  display: inline-block;
}

.select_form {
  display: inline;
  vertical-align: top;
  text-align:center;
  cursor: pointer;
  color: #3dcfff;
  font-weight: bold;
}
.drop-down-width {
    width: 250px;
}
.accent-text {
    color: #3dcfff !important;
}
.accent-text:hover {
    background-color: transparent !important;
    color: #3dcfff !important;
    font-weight: bold;
}
.no_text_select{
  user-select: none;
}

.flex-parent {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.max-text-width  {
    max-width: 160px
}

.flex-parent > div {

  margin-top: auto;
  margin-bottom: auto;
}

/* Inserting this collapsed row between two flex items will make
 * the flex item that comes after it break to a new row */
.break {
  flex-basis: 100%;
  height: 0;
}

#item3{
  width: 100%;
}

@media screen and (max-width: 870px) {
  div#item1 {
    order: 1;
  }
  div#item2 {
    order: 3;
  }
  div#item3 {
    order: 2;
  }
}

.vue-grid-item {
  overflow: hidden;
}

.vue-resizable {
  border: 1px solid #555;
  overflow: hidden;
}

.dashboard_navbar {
  width: 100%;
  display: inline-flex;
  justify-content: flex-start;
}


.dashboard_new_item {
  cursor: pointer;
  justify-content: center;
  text-align: center;
  height: 100px;
  border: 2px dashed #999;
  width: 90%;
  padding: 30px;
  margin-left: auto;
  margin-right: auto;

}

.dashboard_new_item:hover {
  background-color: #444;
}

.dashboard_navbar_item {
  transition: all 250ms ease-in-out;
}

.dashboard_navbar_item:after {
  display:block;
  content: '';
  color: #fff;
  border-bottom: solid 3px #fff;
  transform: scaleX(0);
  transition: all 250ms ease-in-out;
  /* transform-origin:  0% 50%; */
}

.dashboard_navbar_item:hover:after {
  transform: scaleX(1);
}

.dashboard_navbar_selected{
  cursor: auto;
  color: #fff;
  transform-origin:  0% 50%;
}

.dashboard_navbar_selected:after{
  display:block;
  content: '';
  border-bottom: solid 3px #0275d8;
  transform: scaleX(1);
  transition: transform 250ms ease-in-out;
  transform-origin:  0% 50%;
  color: #fff;
}

.user-remover {
  cursor: pointer;
  transition: all 250ms ease-in-out;
}

.user-remover-disabled {
    color: #6c757d!important;
}

.disabled-color {
    background-color: #6c757d!important;
}

.user-remover:hover {
  color: #ff2222;
}

.closer {
  z-index: 1000;
  padding: 0px 0px;
  opacity: 0.8;
  cursor: pointer;
  padding-left: 5px;
  padding-right: 5px;
  background-color: #222;
  color: white;
  display: inline-block;
  position: absolute;
  right: 0px;
}
.closer:hover{
  opacity: 1;
  text-decoration: underline;
}

.selected-dropdown {
  font-weight: bold;
  border-bottom: solid 3px #0275d8;
}

.item_title {
  background-color: rgba(65,65,65,0.8);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;

  z-index: 10;
}

.dropdown button {
  padding: 2px;
}

.customer_dashboard{
  font-weight: bold;
  color: white;
}

.flex-list li {
    border-right: 2px solid #444;
    padding-right: 0.5em;
    padding-left: 0.5em;
}

.flex-list li:last-child {
    border-right: 0;
}

#usertable td {
  text-align: center;
  vertical-align: middle;
}

.modal-footer{
  background-color: rgba(65,65,65,0.8);
}

.roipBanner{
    top: 0;
    width: 100%;
    text-align: center;
    background: #039855;
    position: sticky;
    left: 0;
    right: 0;
    margin: auto;
}
.activeSpeaking{
    min-height:91vh !important;
    box-shadow: 0 0 0 7px #039855 inset !important;

}
</style>
