<template>
    <div class="pb-2 accordion">
        <div class="card text-white bg-light">
            <div class="card-header" :id="'header' + _uid">
                <h5 class="mb-0" data-toggle="collapse" :data-target="'#collapseTarget' + _uid" :aria-expanded="toggleAriaExpanded" :aria-controls="'collapseTarget' + _uid">
                    <button class="btn link-txt-color">
                        {{ headerName }} <span id="tpi-com">{{ tpiComp }}</span>
                    </button>
                </h5>
            </div>
            <div :id="'collapseTarget' + _uid" :class="toggleClass" :aria-labelledby="'header' + _uid">
                <div class="card-body">
                    <table class="table table-striped table-hover mt-2" :class="{'table-dark': darkMode}">
                        <thead>
                            <th scope="col" class="col-1"><span class="mr-1 pointer-hover"></span>Order</th>
                            <th scope="col" class="col-3"><span @click="sortTable('rawTagNames')" :class="rawTagSortIcon" class="mr-1 pointer-hover"></span>Raw Tag Names</th>
                            <th v-if="showDefaultColumn" scope="col" class="col-3"><span @click="sortTable('friendlyTagNames')" class="mr-1 pointer-hover"></span>Default Tag Names</th>
                            <th scope="col" class="col-3"><span class="mr-1 pointer-hover"></span>Display Names</th>
                            <th scope="col" class="col-2">Latest Tag Value</th>
                        </thead>
                        <tbody>
                        <tr v-for="(tag, index) in sortedTags" :key="index">
                            <td> {{ tag.orderNumber }} </td>
                            <td>{{tag.name}}</td>
                            <td v-if="showDefaultColumn">{{tag.friendlyTagname}}</td>
                            <td>{{tag.displayName}} </td>                          
                            <td>{{latestTagValues[tag.name] == null? '--':latestTagValues[tag.name]}}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .bg-dark {
        background-color: black;
    }

    #tpi-com {
        color: red;
    }
</style>

<script>
export default {
    props: {
        darkMode: Boolean,
        sortedTags: Array,
        latestTagValues: Object,
        headerName: String,
        tpiComp: String,
        showDefaultColumn: Boolean,
        topLevel: String
    },
    data() {
        return {
            data: null,
            isNotSet: "true",
            showClass: 'collapse show'
        };
    },
    mounted() {
        // mounted
    },
    computed: {
        toggleAriaExpanded() {
            if (this.topLevel === "NOT SET") {
                this.isNotSet = false; 
                return this.isNotSet;           
            } else {
                this.isNotSet = true;
                return this.isNotSet;
            }
        },
        toggleClass() {
            if (this.topLevel === "NOT SET") {
                this.showClass = 'collapse';
                return this.showClass;          
            } else {
                this.showClass = 'collapse show';
                return this.showClass;  
            }
        }
    },
    methods: {	
        rawTagSortIcon: function() {
            if (this.sortCriteria.column !== 'rawTagNames') {
                return 'fas fa-sort';
            } else {
                if (this.sortCriteria.direction === 'asc') {
                    return 'fas fa-caret-up';
                }
                return 'fas fa-caret-down';
            }
        },
        friendlyNameSortIcon: function() {
            if (this.sortCriteria.column !== 'friendlyTagNames') {
                return 'fas fa-sort';
            } else {
                if (this.sortCriteria.direction === 'asc') {
                    return 'fas fa-caret-up';
                }
                return 'fas fa-caret-down';
            }
        }
    }
};
</script>