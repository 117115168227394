var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-column flex-fill" }, [
    _c("div", { staticClass: "d-flex justify-content-center mb-2" }, [
      _c("b", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _vm._v(" "),
    _vm._m(0),
    _vm._v(" "),
    _c("div", { staticClass: "flex-1 inner-bg" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "d-flex p-2 font-small inner-bg flex-fill" }, [
        _vm._v("\n\t\t\t1)Select the number of guns you are running "),
        _c("br"),
        _c("br"),
        _vm._v(
          "\n\t\t\t2) Record the distance between th CCL and the top of each gun and plug "
        ),
        _c("br"),
        _c("br"),
        _vm._v("\n\t\t\t3) Choose a Gun to run under Gun Specifications "),
        _c("br"),
        _c("br"),
        _vm._v("\n\t\t\t4) Choose a Plug to run under Plug Specifications "),
        _c("br"),
        _c("br"),
        _vm._v(
          '\n\t\t\t• in the event the gun or plug isn\'t listed, you can add a new one by selecting "Other..." \n\t\t'
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }