import { Bar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
import zoom from 'chartjs-plugin-zoom';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ChartAnnotations from 'chartjs-plugin-annotation';

const  totalizer = {
    id: 'totalizer',
    beforeUpdate: chart => {
        let totals = {};
        let utmost = 0;
        chart.data.datasets.forEach((dataset, datasetIndex) => {
            if (chart.isDatasetVisible(datasetIndex) && dataset?.type !== 'line') {
                const stack = dataset.stack ?? null;
                utmost = datasetIndex;
                dataset.data.forEach((value, index) => {
                    totals[index + stack] = (totals[index + stack] || 0) + value;
                });
            }
        });
        chart.$totalizer = {
            totals: totals,
            utmost: utmost
        };
    }
};

const paddingBelowLegends = {
    id: 'paddingBelowLegends',
    beforeInit: function(chart, options) {
        chart.legend.afterFit = function() {
            this.height = this.height + 25;
        };
    }
};

export default {
    name: 'BarChart',
    extends: Bar,
    mixins: [reactiveProp],
    props: ['options', 'customPlugins'],
    mounted() {
        this.addPlugin(zoom);
        this.addPlugin(ChartDataLabels);
        this.addPlugin(ChartAnnotations);
        this.addPlugin(totalizer);
        this.addPlugin(paddingBelowLegends);

        if(this.customPlugins) {
            for(let i = 0; i < this.customPlugins.length; i++) {
                this.addPlugin(this.customPlugins[i]);
            }
        }


        Chart.helpers.merge(Chart.defaults.global.plugins.datalabels, {
            display: false
        });
        this.renderChart(this.chartData, this.options)
    },
    watch: {
        chartData: function (newVal, oldVal) { // watch it
            this.renderChart(this.chartData, this.options);
        },
        options: {
            deep: true,
            handler(newOptions) {
              this.renderChart(this.chartData, this.options);
            }
        }
    }
}