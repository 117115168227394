export default {
    data() {
        return {
            jsonLocalStorage: {
                key: '',

                hasSavedState: function() {
                    return localStorage.getItem(this.key) != null;
                },
                save: function() {
                    localStorage.setItem(this.key, JSON.stringify(this));
                },
                load: function(id) {
                    this.key = id;
                    const savedStateRaw = localStorage.getItem(this.key);
                    if(savedStateRaw != null) {
                        return JSON.parse(savedStateRaw);
                    }
                }
            }
        };
    },
};