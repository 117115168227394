<template>
    <div >
        <messages-component style="position:fixed;" v-if="show" :key="componentKey" v-bind:allMessages="this.messages" v-on:read="markRead" v-on:delete="deleteMessage" :username="username"></messages-component>
        <warnings-component v-bind:allWarnings="this.warnings"></warnings-component>
        <new-warning-component v-bind:newWarning="this.newWarning"></new-warning-component>
        <div class="fixed-bottom">
            <div class="navbar bg-secondary p-1">
                <button v-bind:class="warningButtonClass" type="button" data-toggle="collapse" data-target="#warningsCollapsible" aria-expanded="false" aria-controls="warningsCollapsible">
                    {{warningLabel}}
                </button>
                <div class="alert p-1 mb-0 mt-0" :class="[jobStatus.status=='inProgress' ? 'alert-success' : 'alert-danger']">{{jobStatusString}}</div>
                <button class="btn btn-primary" type="button" data-toggle="collapse" data-target="#messagesCollapsible" aria-expanded="false" aria-controls="messagesCollapsible" @click="updateReadFlag(), forceRerender()">
                    {{messageLabel}}
                </button>
            </div>
        </div>
        <div class="modal fade" id="nodeModal" tabindex="-1" role="dialog">
          <div class="modal-dialog bg-light rounded" role="document">
            <div class="modal-header">
              <h5 class="modal-title">{{dialogHeader}}</h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              {{dialogText}}
            </div>
          </div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    import _ from 'lodash'
    export default {
        data() {
            return {
                warnings: {}, // stored keyed by path, eg "spool/0" => ["warning1","warning2"]
                newWarning: {},
                warningLabel: "No Warnings",
                warningCount: 0,
                
                messages: [],
                messageLabel: "No Messages",
                messageCount: 0,

                roles: Vue.util.extend({}, this.propRoles),
                nextMessageId: 0,
                dialogHeader: 'header here...',
                dialogText: 'Message here...',
                componentKey: 0,
                show: false
            }
        },
        props: ['propRoles','username'],
        computed: {
            warningButtonClass: function () {
                return {
                    'btn': true,
                    'btn-warning': this.warningCount > 0,
                    'btn-success': this.warningCount == 0,
                }
            },
            jobStatusString(){
              var status = 'Job number/status not available';
              if(this.jobStatus?.jobNumber && this.jobStatus?.status){
                status = this.jobStatus.jobNumber;
                switch(this.jobStatus.status){
                  case 'inProgress':
                    status += ' In progress';
                    break;
                  case 'completed':
                    status += ' Completed';
                    break;
                  case 'notStarted':
                    status += ' Not started';
                    break;
                  default:
                    status += ' Status error';
                    break;
                }
              }
              return status;
            },
            jobStatus(){
              return this.$root.jobStatus;
            },
            computedWarnings: function () {
              return Object.assign({}, this.warnings);
            }
        },
        methods: {
            recalculateCounts() {
                var newWarningCount = 0;
                for( var path in this.warnings ) {
                    var theseWarnings = this.warnings[path];
                    newWarningCount += theseWarnings.length;
                }

                if(newWarningCount == 0) {
                    this.warningLabel = "No Warnings";
                }
                else if(newWarningCount == 1) {
                    this.warningLabel = "1 Warning";
                }
                else {
                    this.warningLabel = newWarningCount + " Warnings";
                }

                this.warningCount = newWarningCount;

                var newMessageCount = 0;
                for( var i=0;i<this.messages.length;i++ ) {
                  if(!this.messages[i].read)
                    newMessageCount++;
                }

                if(newMessageCount == 0) {
                    this.messageLabel = "No New Messages";
                }
                else if(newMessageCount == 1) {
                    this.messageLabel = "1 New Message";
                }
                else {
                    this.messageLabel = newMessageCount + " New Messages";
                }

                this.messageCount = newMessageCount;
            },
            updateReadFlag() {
                /*for( var path in this.messages ) {
                    var theseMessages = this.messages[path];
                    for (var key in theseMessages) {
                        theseMessages[key].read = true;
                    }
                }*/
            },
            markRead(id){
              for(var i=0;i<this.messages.length;i++){
                if(this.messages[i].id == id)
                  this.messages[i].read = true;
              }
            },
            deleteMessage(id){
              var deleteIndex = -1;
              for(var i=0;i<this.messages.length;i++){
                if(this.messages[i].id == id)
                  deleteIndex = i;
              }
              if(deleteIndex != -1){
                this.messages.splice(deleteIndex,1);
              }
            },
            mqttInit(){
              //console.log("MessagesToolbar now working on mqtt init...");
              this.$root.$mqtt.subscribe('warning/#');
              this.$root.$mqtt.subscribe('message/+/+/all');
              this.$root.$mqtt.subscribe('message/+/+/'+this.username);
              this.$root.$mqtt.subscribe('dialog/+/+/'+this.username);
              var allRoles = false;
              for(var key in this.roles) {
                this.$root.$mqtt.subscribe('message/+/+/' + this.roles[key].name);
                if(this.roles[key].name == 'admin' || this.roles[key].name == 'wsm')
                  allRoles = true;
              }
              if(allRoles){
                this.$root.$mqtt.subscribe('message/+/+/wsm');
                this.$root.$mqtt.subscribe('message/+/+/frac');
                this.$root.$mqtt.subscribe('message/+/+/wireline');
                this.$root.$mqtt.subscribe('message/+/+/tech');
              }


              var self = this;
              this.$root.$mqtt.on("message", function(topic, message) {
                  var topicArray = topic.split("/");
                  if(topicArray.length > 0) {
                      switch (topicArray[0]) {
                          case "warning":
                              var path = topic.substr(8);
                              if(message == "") {
                                  Vue.delete(this.warnings, path);
                              }
                              else {
                                try{ 
                                  Vue.set(this.warnings, path, JSON.parse(message));
                                }catch(e){
                                  console.log("error parsing warning payload");
                                }
                              }
                              break;
                          case "message":
                            //console.log('topic: '+topic+', payload: '+message);
                            var msg = {};
                            if(topicArray[1]=='user')
                              msg.from = topicArray[2];
                            else
                              msg.from = topicArray[1]+'/'+topicArray[2];
                            msg.text = new TextDecoder("utf-8").decode(message);
                            msg.timestamp = moment();
                            msg.read = false;
                            msg.id = this.nextMessageId++;
                            this.messages.push(msg);
                            if(this.messages.length > 8)
                              this.messages.splice(0,1);
                            break;
                          case "dialog":
                            this.dialogHeader = 'Message from: '+topicArray[1]+'/'+topicArray[2];
                            this.dialogText = new TextDecoder("utf-8").decode(message);
                            $('#nodeModal').modal('show');
                            break;
                      }
                  }

                  self.recalculateCounts();
              }.bind(this));
            },
            forceRerender() {
              this.show = !this.show;
              this.componentKey += 1;
            }
        },
        watch: {
          computedWarnings: {
            deep: true,
            handler(newValue, oldValue) {
              function difference(object, base) {
                let arrayIndexCounter = 0
                return _.transform(object, function(result, value, key) {
                  if (!_.isEqual(value, base[key])) {
                    let resultKey = _.isArray(base) ? arrayIndexCounter++ : key
                    result[resultKey] = (_.isObject(value) && _.isObject(base[key])) ? difference(value, base[key]) : value;
                  }
                });
              }
              if (!_.isEmpty(oldValue) || this.warningCount <= 2) {
                this.newWarning = difference(newValue, oldValue);
              }
            }
          }
        },
        mounted() {
          var self = this;
          this.$root.$on('mqttConnected',function(){
            //console.log("messagesToolbar component got a connect message!");
            self.mqttInit();
          });
        }
    }
</script>