<template>
    <span>
        <b-button :disabled="count <= 0" v-on:click="applyFilter" v-bind:class="{'dot-clickable': true, 'dot-read': !this.hasUnreadMessages, 'transparent': this.count == 0 }" :id="popoverId">{{ this.count }}</b-button>
        <b-popover :target="popoverId" custom-class="popover-body-custom " triggers="click blur" placement="righttop" @show="onShow" id="test">
            <div class="scroll-container" id="scroller">
                <div v-for="comment,index in filteredComments" :key="comment.id">
                    <div class="comment-container ml-0 pr-1">
                        <div class="text-left">
                            {{ dateDisplayString(comment) }}
                        </div>
                        <div class="m-1 p-1">
                            {{comment.text}}
                        </div>
                        <div class="small text-right m-0 p-1">
                            {{ comment.user.first_name || comment.user.username }}
                        </div>
                        <div class="m-0 row mb-2" v-if="getActivityType(comment) || getReasonType(comment)">
                            <div class="small col-6 pl-1" v-if="getActivityType(comment)">
                                <div :id="'tooltip-activity' + index" class="badge badge-success">
                                    {{ getActivityType(comment) }}
                                </div>
                                <b-tooltip triggers="hover" :target="'tooltip-activity' + index">
                                    {{ getActivityType(comment) }}
                                </b-tooltip>
                            </div>
                            <div class="small col-6 pr-2" v-if="getReasonType(comment)">
                                <div :id="'tooltip-reason' + index" class="badge badge-primary float-right">
                                    {{ getReasonType(comment) }}
                                </div>
                                <b-tooltip triggers="hover" :target="'tooltip-reason' + index">
                                    {{ getReasonType(comment) }}
                                </b-tooltip>
                            </div>
                        </div>
                        <div class="m-0 row mb-2" v-if="getContractorName(comment)">
                            <div class="small col-6 pl-1">
                                <div :id="'tooltip-contractor' + index" class="badge badge-info">
                                    {{ getContractorName(comment) }}
                                </div>
                                <b-tooltip triggers="hover" :target="'tooltip-contractor' + index">
                                    {{ getContractorName(comment) }}
                                </b-tooltip>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </b-popover>
    </span>
</template>

<script>

import moment from 'moment';
export default {
    props: {
        comments: Array,
        count: Number,
        popoverId: String,
        jobHourOffset: Number,
        hasUnreadMessages: Boolean,
        eventActivities: Array,
        eventReasons: Array,
        contractors: Array,
    },
    data: function () {
        return {
            timeString: function(time)
            {
                return moment(time).add({hours: this.jobHourOffset}).format('h:mm A');
            },
            dateDisplayString: function(comment)
            {
                let output = this.timeString(comment.start_time);

                if(comment.end_time && comment.end_time != comment.start_time)
                    output += " to " + this.timeString(comment.end_time);

                return  output;
            },
            filteredComments: []
        }
    },
    methods: {
        applyFilter: function() {
            // sort in REVERSE
            const sorted = this.comments.sort((a, b) => (moment(a.start_time) > moment(b.start_time) ? -1 : 1));

            // now get a list of unique objects.  this will use the last unique timestamp in the 'sorted' array.
            // we also reverse the new array so that it is asc by start_time
            const unique = [...new Map(sorted.map(item =>[item["start_time"], item])).values()].reverse();

            this.filteredComments = unique;
        },
        onShow: function(evt) {
            // HACK: we dont get access to the style of a specific div we need to modify, so we wait for a fraction of a second then
            // swap to our custom popover body style
            setTimeout(() => {
                //document.getElementById("scroller").parentElement.classList.remove('popover-body');
                document.getElementById("scroller").parentElement.classList.add('popover-body-custom');
            }, 5);
            
        },
        getActivityType(targetComment){
            let activity = this.eventActivities.find(eventactivity => eventactivity.id == targetComment.eventactivity_id);
            return activity ? activity.type : null;
        },
        getReasonType(targetComment){
            let reason = this.eventReasons.find(eventreason => eventreason.id == targetComment.eventreason_id);
            return reason ? reason.type : null;
        },
        getContractorName(targetComment){
            let contractor = this.contractors.find(contract => contract.id == targetComment.contractor_id);
            return contractor ? contractor.name : null;
        },
    }
}
</script>

<style scoped>
    .popover {
        background-color: #373A3C;
        padding: 0px;
        margin: 0px;
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
        width: 450px;
    }

    .popover-body.popover-body-custom {
        padding: 0.5rem 0px 0.5rem 0.75rem;
        margin: 0px;
    }

    .dot-clickable {
        height: 1.4rem;
        width: 1.4rem;
        padding: 0;
        margin: 0;
        border-radius: 50%;
        border: none;
        position: absolute;
        background-color:#d9534f;
    }

    .dot-read {
        background-color: #95989A;
    }

    .scroll-container {
        max-height: 250px;
        min-height: 60px;
        color: #FFFFFF;
        background-color: #373A3C;
        scrollbar-color: #95989A #373A3C;
        margin: 0px;
        padding: 0px 8px 0px 0px;
        overflow: scroll;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .comment-container {
        border-style: solid;
        border-radius: 3.5px;
        border-color: #95989A;
        border-width: thin;
        background-color: #333333;
        color: #FFFFFF;
        padding: 8px;
        margin: 8px 0px 8px 12px;
    }

    .transparent {
        background-color: transparent;
        border-color: transparent;
        color: transparent;
    }

    .badge {
        display: inline-block;
        max-width: 90px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        vertical-align: middle;
    }
</style>