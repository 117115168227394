<!--
    * Example Usage

    <checkbox-list
        label="Ghost Channel(s) Select"
        height="100"
        :options="tags"
        v-bind:selectedOptions="ghostLineChannels"
        v-on:update:selectedOptions="ghostLineChannels = $event">
    </checkbox-list>
-->

<template>
<div :style="getContainerStyle()" class="checkbox-list-container">
    <input
        v-if="enableSearch"
        class="form-control form-control-sm my-2 searchInput"
        :class="backGroundColorClass"
        type="text"
        placeholder="Search..."
        v-model="searchTerm"
    />
    <div v-if="filteredOptions.length === 0 || options.length === 0">
        <p class="text-center m-0 " :style="`color: ${isDark? '#fff' : '#000'}`">{{ emptyText }}</p>
    </div>

    <div v-else :style="getListStyle()">
        <div class="checkboxLabel">
            <b v-if="label.length > 0">{{label}}</b>
            <div class="pl-2"></div>
        </div>
        <div v-if="enableSelectAllOption && (filteredOptions.length > 0 || options.length > 0)" class="d-flex flex-row w-100 align-items-center checkboxList" >
            <input type="checkbox"
                    class="mr-2"
                    v-model="selectAll"
                    @change="toggleSelectAll"
                    :class="backGroundColorClass"
                    >
            <span>
                    {{'Select all'}}
            </span>

        </div>
        <div v-if="valueKey">
            <div v-for="(option, index) in filteredOptions" :key="index" class=" d-flex flex-row w-100 align-items-center checkboxList">
                <input type="checkbox"
                            @focus="toggleFocus()"
                            @blur="toggleFocus()"
                            :id="option[outputKey]"
                            class="mr-2"
                            v-model="optionStates[option[outputKey]]"
                            @change="toggleState($event)"
                            :disabled="isOptionDisabled(option[outputKey])"
                            :class="backGroundColorClass"
                            >
                <span class="">
                    {{ option[valueKey] }}</span>

            </div>
        </div>
        <div v-else>
            <div v-for="(option, index) in options" :key="index" class="pl-1 pr-1">
                <label><input type="checkbox"
                            @focus="toggleFocus()"
                            @blur="toggleFocus()"
                            :id="option"
                            v-model="optionStates[option]"
                            @change="toggleState($event)"
                            :disabled="isOptionDisabled(option)"
                            :class="backGroundColorClass"
                            >
                    {{ alternateOptionLabels? alternateOptionLabels[index] : option }}
                </label>
            </div>
        </div>
    </div>
</div>
</template>

<script>
const DARK_CONTAINER_BG_COLOR = '#33333af0';

export default {
    props: {
        valueKey: {
            default: null,
            type: [String],
            required: false
        },
        outputKey: {
            default: null,
            type: [String],
            required: false
        },
        label: {
            type: [String],
            default: '',
            required: false
        },
        height: {
            default: 200,
            required: false
        },
        maxHeight: {
            type: Number,
            required: false
        },
        options: {
            type: [Array],
            required: true
        },
        alternateOptionLabels: {
            type: [Array],
            required: false
        },
        selectedOptions: {
            type: [Array],
            required: true
        },
        isDark: {
            default: true,
            required: false
        },
        enableSelectAllOption: {
            default: false,
            required: false
        },
        enableSearch: {
            default: false,
            required: false
        },
        //props to provide unqiue functionality for specific checkboxlist instances
        customToggleOnEvent: {
            default: null,
            type: [Function],
            required: false
        },
        customToggleOffEvent: {
            default: null,
            type: [Function],
            required: false
        },
        customData: {
            default: null,
            type: [Object],
            requried: false
        },
        disabledOptions: {
            default: ()=>[],
            type: [Array],
            requried: false
        },
        emptyText: {
            type: String,
            required: false,
            default: 'There are no options to select from'
        },
        fullWidth: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            searchTerm: '',
            selectAll: false,
            optionStates: {}
        };
    },
    mounted() {
        //initial check to load initial values
        //and determine selectAll value
        this.optionStates = {};
        this.selectedOptions.forEach(option => {
            const opt = this.getOptionValue(option);
            this.optionStates[opt] = true;
        });
        this.selectAllFunctionality();
    },
    computed: {
        filteredOptions() {
            if(this.searchTerm) {
                return this.options.filter((option)=>{
                    if(this.valueKey) {
                        return option[this.valueKey].toLowerCase().includes(this.searchTerm.toLowerCase());
                    } else {
                        return option.toLowerCase().includes(this.searchTerm.toLowerCase());
                    }
                });
            } else {
                return this.options;
            }
        },
        backGroundColorClass() {
            return this.isDark ? 'backgroundDark': 'backgroundLight';
        }
    },
    watch: {
        selectedOptions: function(val) {
            this.optionStates = {};
            val.forEach(option => {
                const opt = this.getOptionValue(option);
                Vue.set(this.optionStates, opt, true);
            });
            this.selectAllFunctionality();
        }
    },
    methods: {
        selectAllFunctionality: function() {
             if (this.disabledOptions) {
                let selectedNonDisabledOptions = this.selectedOptions.filter(option => !this.disabledOptions.includes(option));
                //for select all to be true, options = (selected non-disabled)+(selected diabled)+(non-selected disabled)+(non-disabled non-selected = 0)
                //selectAll boolean is found through rearranging the above equation:
                this.selectAll = -this.options.length + this.disabledOptions.length + selectedNonDisabledOptions.length == 0;
            } else {
                this.selectAll = Object.values(this.optionStates).length == this.options.length;
            }
        },
        getListStyle: function() {
            const style = {
                overflow: 'auto',
                color: this.isDark ? '#FFFFFF' : '#111111',
                border: '1px solid #29a5ff',
                borderRadius: '5px',
            };

            if (this.maxHeight) {
                style['max-height'] = `${this.maxHeight}px`;
            } else {
                style['height'] = `${this.height}px`;
            }

            return style;
        },
        getContainerStyle: function() {
            return {
                borderRadius: '5px',
                padding: '2px',
                border: this.isFocused ? '2px solid #8888ff' : '2px solid #888888',
                backgroundColor: this.isDark ? DARK_CONTAINER_BG_COLOR : '#FFF',
                width: this.fullWidth ? '100% !important' : null
            };
        },
        getOptionValue(option) {
            let opt = null;
            if(typeof option === 'object') {
                opt = option[this.outputKey];
            } else {
                opt = option;
            }
            return opt;
        },
        getChecked: function() {
            // optionStates is stored as an object containing option:boolean pairs.
            // We will return an array of strings corresponding to the currently active options.
            const checked = [];
            for(const [key, value] of Object.entries(this.optionStates)) {
                if(value) {
                    checked.push(key);
                }
            }
            return checked;
        },
        toggleSelectAll() {
            this.options.forEach(option => {
                const opt = this.getOptionValue(option);
                Vue.set(this.optionStates, opt, this.selectAll);
            });
            const checked = this.getChecked();
            let result;
            if (this.outputKey) {
                result = this.options.filter(option => checked.includes(option[this.outputKey]));
            } else {
                result = checked;
            }
            this.$emit('update:selectedOptions', result);
        },
        toggleState: function(event) {
            if (event.currentTarget.checked && this?.customToggleOnEvent) {
                this?.customToggleOnEvent(event, this.customData);
            } else if (!event.currentTarget.checked && this?.customToggleOffEvent) {
                this?.customToggleOffEvent(event, this.customData);
            }
            const checked = this.getChecked();
            let result;
            if (this.outputKey) {
                result = this.options.filter(option => checked.includes(option[this.outputKey]));
            } else {
                result = checked;
            }

            this.$emit('update:selectedOptions', result);
        },
        toggleFocus: function() {
            this.isFocused = !this.isFocused;
        },
        isOptionDisabled: function(option) {
            if (this.disabledOptions.length === 0) {
                return false;
            }
            return this.disabledOptions.includes(option) ? true : false;
        }
    }
};


</script>
<style scoped>

.checkboxLabel{
    display:flex;
    align-items: center;
    padding-left:10px;
    padding-right:5px;
    padding-top:7px;
    padding-bottom: 7px;
}
.checkboxList{
    padding: 8px;
    border-bottom: 1px solid #7B8A98;

}
/* style the check box with background and border */
.checkboxList input[type="checkbox"] {
    -webkit-appearance: none !important;
    -moz-appearance: none    !important;
    appearance: none !important;
    display: inline-block !important;
    width: 16px !important;
    height: 16px !important;
    background-clip: content-box !important;
    border: 1px solid #D0D5DD !important;
    border-radius: 3px !important;
    transition: all 0.3s ease-in-out 0s !important;
    cursor: pointer     !important;
    margin-right: 10px   !important;
    margin-top:3px !important;
    padding: 0px !important;
}
/* style the checked state */
.checkboxList input[type="checkbox"]:checked {
    border: 1px solid #29a5ff !important;

}
.checkboxList input[type="checkbox"]:checked::before {
    display: block;
    text-align: center;
    line-height: 16px;
    font-size: 12px;
    font-weight: bold;
    content: '✓' !important;
    color: #29a5ff !important;
}
.searchInput{
    border-radius: 4px;
    border: 1px solid #676E78;
    display: flex;
    padding: 10px 14px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    height: 44px;
}
.backGroundLight {
    background-color:#fff;
    color:#000;
}
.backgroundDark {
    background-color: #343A40;
    color: #fff;
}

</style>
