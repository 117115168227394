<template>
	<div class="d-flex flex-grow-1 justify-content-end primary-txt">
			<div class="px-2 ">
				Job Number <span>{{ jobNumber}}</span>
			</div>
			<div class="px-2 ">
				<select-component :title="'Well'" :options="wellOptions" :initSelected="selectedWellName" :onChange="(value)=>onDropDownChange(value, wellKey)"/>
			</div>
			<div class="px-2  justify-content-center">
				<select-component :title="'Stage'" :options="stageOptions" :initSelected="selectedStage" :onChange="(value)=>onDropDownChange(value, stageKey)"/>
			</div>
		</div>
</template>

<script>
export default {
    props: ['jobData', 'onChangeValues'],
    mounted() {
        this.jobNumber = this.jobData.jobNumber;
        this.getWells();		
    },
    data() {
        return {
            jobNumber: null,
            wellOptions: [],
            stageOptions: [],
            selectedWell: this.jobData.wellIndex,
            selectedWellName: this.jobData.wellName? this.jobData.wellName : 0, //if wireline is not active there will be no well name coming
            selectedStage: this.jobData.stage,
            nameIndexObject: [],
            wellKey: 'selectedWell',
            stageKey: 'selectedStage'
        };
    },
    methods: {
        getWells: function() {
            const self = this;
            const url = '/wells/'+ this.jobNumber;
            this.wellOptions = [];
            $.get(
                url,
                {},
                function(data,status,xr) {	
                    if(data.length>0) {
                        self.nameIndexObject = Object.assign({}, ...data.map(item=> ({[item.name]: item.index})));						
                        self.wellOptions = data.map((item)=>{ return item.name; });

                        const targetWell = data.reduce(function(prev, current) {
                            return (prev.numberOfStages > current.numberOfStages) ? prev : current;
                        });

                        self.stageOptions = [...Array.from({length: targetWell.numberOfStages}, (_, i) => i + 1)];
                    }
                }
            );
        },
        onDropDownChange(value, key) {
            let setValue = value;
            if(key===this.wellKey) { // need to save the well index not the name
                this.selectedWellName = value;
                setValue = this.nameIndexObject[value];
            }
            this[key] = setValue;
            this.onChangeValues && this.onChangeValues(this.selectedWell, this.selectedStage);
        }
    }
};
</script>

<style>
	.custom-select {
		border-width: 0px;
		background-color : transparent;
		padding : 0px;
		background: none !important;
		height : auto;
		font-size: 1rem;
		text-decoration : underline;
	}
	.custom-select:focus {
		border-width: 0px;
		box-shadow: none;
	}
</style>