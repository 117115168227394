var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "MetadataOperator" } },
    [
      _c(
        "div",
        { attrs: { id: "headers-row" } },
        [
          _c("iws-breadcrumbs", {
            attrs: {
              path: [
                {
                  title: "Operator List",
                  link: `/metadata`,
                },
                {
                  title: _vm.customer.name,
                  link: `/metadata/operator/${_vm.propid}`,
                },
              ],
            },
          }),
          _vm._v(" "),
          !!_vm.customer.name
            ? _c("div", { staticClass: "h5 page-title" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.customer.name) + "\n        "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { attrs: { id: "control-changes-container" } },
            [
              _c("iws-button", {
                staticStyle: { "margin-right": "10px" },
                attrs: {
                  type: "outline-light",
                  text: "Cancel",
                  click: _vm.discardChanges,
                  disabled: !_vm.isDirty(),
                },
              }),
              _vm._v(" "),
              _c("iws-button", {
                attrs: {
                  type: "light",
                  text: "Save",
                  click: _vm.saveChanges,
                  disabled: !_vm.isDirty(),
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.value.npt.frac.tags !== null
        ? _c("iws-tabs", {
            attrs: {
              tabs: [
                {
                  title: "NPT Frac Tags",
                  enabled: !_vm.containsUnsavedChanges(),
                },
                {
                  title: "Stage Report - Frac",
                  enabled: !_vm.containsUnsavedChanges(),
                },
                {
                  title: "Stage Report - Wireline",
                  enabled: !_vm.containsUnsavedChanges(),
                },
              ],
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "tab_0",
                  fn: function () {
                    return [
                      _c("metadata-operator-list", {
                        ref: "nptTags",
                        attrs: {
                          activity: "npt",
                          data: _vm.value.npt.frac,
                          refs: _vm.$refs,
                        },
                        on: {
                          "list-update": function ($event) {
                            _vm.value.npt.frac.tags = $event
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("iws-accordian", {
                        staticStyle: { "margin-top": "20px" },
                        attrs: {
                          cards: [
                            {
                              title: `NPT Outcome (${_vm.nptOutcomes.length})`,
                            },
                            { title: `NPT Vendors (${_vm.nptVendors.length})` },
                          ],
                          allowMultiTabs: true,
                          value: null,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "card-body_0",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "position-relative" },
                                    [
                                      _c("iws-search", {
                                        attrs: { value: _vm.outcomeFilter },
                                        on: {
                                          "update:value": function ($event) {
                                            _vm.outcomeFilter = $event
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        [
                                          _c("iws-button", {
                                            attrs: { text: "Add Outcome" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editAction(
                                                  { ..._vm.emptyItem },
                                                  _vm.nptOutcomes,
                                                  _vm.isRequired,
                                                  "outcomes"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("iws-table", {
                                    attrs: {
                                      columns: _vm.nptColumns,
                                      items: _vm.nptOutcomes,
                                      filter: _vm.outcomeFilter,
                                      sortByCol: "name",
                                      maxPageSize: 12,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell_actions",
                                          fn: function ({ data }) {
                                            return [
                                              _c("library-crud-actions", {
                                                attrs: {
                                                  id: `action${data.index}`,
                                                  viewActions: { view: false },
                                                },
                                                on: {
                                                  edit: function ($event) {
                                                    return _vm.editAction(
                                                      data,
                                                      _vm.nptOutcomes,
                                                      _vm.isRequired,
                                                      "outcomes"
                                                    )
                                                  },
                                                  delete: function ($event) {
                                                    return _vm.deleteAction(
                                                      data,
                                                      _vm.nptOutcomes,
                                                      "outcomes"
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      1804030129
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "card-body_1",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "position-relative" },
                                    [
                                      _c("iws-search", {
                                        attrs: { value: _vm.vendorFilter },
                                        on: {
                                          "update:value": function ($event) {
                                            _vm.vendorFilter = $event
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "span",
                                        [
                                          _c("iws-button", {
                                            attrs: { text: "Add Vendor" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.editAction(
                                                  { ..._vm.emptyItem },
                                                  _vm.nptVendors,
                                                  _vm.isUnique,
                                                  "vendors"
                                                )
                                              },
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("iws-table", {
                                    attrs: {
                                      columns: _vm.nptColumns,
                                      items: _vm.nptVendors,
                                      filter: _vm.vendorFilter,
                                      sortByCol: "name",
                                      maxPageSize: 12,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "cell_actions",
                                          fn: function ({ data }) {
                                            return [
                                              _c("library-crud-actions", {
                                                attrs: {
                                                  id: `action${data.index}`,
                                                  viewActions: { view: false },
                                                },
                                                on: {
                                                  edit: function ($event) {
                                                    return _vm.editAction(
                                                      data,
                                                      _vm.nptVendors,
                                                      _vm.isUnique,
                                                      "vendors"
                                                    )
                                                  },
                                                  delete: function ($event) {
                                                    return _vm.deleteAction(
                                                      data,
                                                      _vm.nptVendors,
                                                      "vendors"
                                                    )
                                                  },
                                                },
                                              }),
                                            ]
                                          },
                                        },
                                      ],
                                      null,
                                      false,
                                      443115467
                                    ),
                                  }),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3507857169
                        ),
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "tab_1",
                  fn: function () {
                    return [
                      _c("metadata-operator-list", {
                        ref: "fracTags",
                        attrs: {
                          activity: "frac",
                          data: _vm.value.reports.stage.frac,
                          refs: _vm.$refs,
                        },
                        on: {
                          "list-update": function ($event) {
                            _vm.value.reports.stage.frac.tags = $event
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "tab_2",
                  fn: function () {
                    return [
                      _c("metadata-operator-list", {
                        ref: "wirelineTags",
                        attrs: {
                          activity: "wireline",
                          data: _vm.value.reports.stage.wireline,
                          refs: _vm.$refs,
                        },
                        on: {
                          "list-update": function ($event) {
                            _vm.value.reports.stage.wireline.tags = $event
                          },
                        },
                      }),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              943120034
            ),
          })
        : _c("div", [_vm._v("\n        Loading...\n    ")]),
      _vm._v(" "),
      _c("metadata-add-tag-modal", {
        ref: "MetadataAddTagModal",
        attrs: { customerId: _vm.customer.id },
      }),
      _vm._v(" "),
      _c("outcome-vendor-modal", { ref: "OutcomeVendorModal" }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }