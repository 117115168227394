var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("input", {
    attrs: { type: "text" },
    domProps: { value: _vm.value },
    on: { change: (event) => _vm.emitValue(event) },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }