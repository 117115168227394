<template>
    <iws-modal
        title="Discover Cameras" 

        :showModal="showBaseModal"
        max-width="700px"

        @close="cancelAction"
        @secondary-action="cancelAction"
        :primary-button-disabled="_isFalsy(selectedCamera)"
        :primary-button-click="submitForm"
    >
        <template #content>
            <div v-if="discoveredCameras === null" id="loading-container" class="full-width">
                <b-spinner large class="align-middle"></b-spinner>
            </div>
            <template v-else-if="!discoveredCameras?.length" class="full-width">
                <h5>
                    No New Cameras
                </h5>

                <iws-button text="Re-run Network Scans" type="outline-primary" append-icon="fab fa-searchengin" :click="fetchCameras" class="mt-2" />
            </template>
            <template v-else-if="_isFalsy(selectedCamera)">
                <iws-button text="Re-run Network Scans" type="outline-primary" append-icon="fab fa-searchengin" :click="fetchCameras" style="float: right" class="mb-2" />

                <iws-table
                    :columns="columns"
                    :items="discoveredCameras"
                    :sort-by-col.sync="sortByCol"
                >      
                    <template #cell_actions="{ data }">
                        <iws-button 
                            type="outline-light"
                            icon="fas fa-plus" 
                            @click="selectedCamera = data?.item"
                        />
                    </template>
                </iws-table>
            </template>
            <iws-form v-else :data="selectedCamera" ref="selectedCamForm">
                <template #body="{ dataItem }">
                    <iws-input label="Name" :value="dataItem?.name" disabled form-spacing />

                    <iws-input label="Network Hostname" :value="dataItem?.model" disabled form-spacing />

                    <iws-input label="IP Address" :value="dataItem?.hostName" disabled form-spacing />

                    <iws-input label="Display Name" :value.sync="dataItem.displayName" required form-spacing ref="displayName" />

                    <div class="row">
                        <div class="col-6">
                            <iws-input label="Onvif Username" :value.sync="dataItem.onvifUsername" required form-spacing ref="onvifUsername" />
                        </div>

                        <div class="col-6">
                            <iws-input label="Onvif Password" :value.sync="dataItem.onvifPassword" type="password" required form-spacing ref="onvifPassword" />
                        </div>
                    </div>
                </template>
            </iws-form>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { toast, isFalsy } = GlobalFunctions;

export default {
    data: () => ({
        showBaseModal: false,
        resolve: null,

        device: null,
        discoveredCameras: null,

        columns: [ 
            {
                key: "name",
                label: "Name",
            }, {
                key: "model",
                label: "Model"
            }, {
                key: "hostName",
                label: "Hostname"
            }, {
                key: "iPv4Address",
                label: "IP Adress"
            }, {
                label: null,
                key: 'actions',
                showHeader: false,
                sortable: false
            }
        ],
        sortByCol: 'name',

        selectedCamera: null
    }),
    
    methods: {
        _isFalsy: (value) => isFalsy(value),

        createTraceId() {
            return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
                .replace(/[xy]/g, function (c) {
                    const r = Math.random() * 16 | 0, 
                        v = c == 'x' ? r : (r & 0x3 | 0x8);
                    return v.toString(16);
                });
        },

        cancelAction() {
            if (!isFalsy(this.selectedCamera))
                return this.selectedCamera = null;
            return this.resolve();
        },

        fetchCameras() {
            return axios.post(`/cameras/devices/${this.device.deviceID}/discover`).then(_result => {
                this.discoveredCameras = _result.data || [];
            }).catch(_error => {
                return toast({
                    title: 'Failed to fetch available cameras',
                    body: _error.message,
                    variant: 'error'
                });
            });
        },

        async open(device) {
            this.device = device;
            this.fetchCameras();

            return new Promise((resolve, reject) => {
                this.resolve = () => {
                    this.showBaseModal = false;
                    this.discoveredCameras = null;
                    this.selectedCamera = null;
                    this.device = null;
                    resolve();
                };

                this.showBaseModal = true;
            });
        },

        submitCamera(camera) {
            return axios.post(`/cameras/${this.device.deviceID}/camera`, {
                cameraName: camera.name,
                displayName: camera.displayName,
                hostname: camera.hostName,
                onvifUsername: camera.onvifUsername,
                onvifPassword: camera.onvifPassword,
                traceId: this.createTraceId()
            }).then(_result => {
                return location.reload();
            }).catch(_error => {
                return toast({
                    title: 'Failed to add camera',
                    body: _error.message,
                    variant: 'error'
                });
            });
        },
        submitForm() {
            // Hit submit passing the callback to hit post with the dynamic field refs
            // iwsForm will validate the refs and only call submitCamera if they all pass
            return this.$refs.selectedCamForm.submit(this.submitCamera, [ this.$refs.displayName, this.$refs.onvifUsername, this.$refs.onvifPassword ]);
        }
    }
};
</script>

<style scoped>
    #loading-container {
        text-align: center;
        padding: 35px 0px
    }
</style>