var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "collapse bg-secondary w-50",
      staticStyle: { "z-index": "11" },
      attrs: { id: "warningsCollapsible" },
    },
    [
      _vm._l(_vm.allWarnings, function (typedWarnings, path) {
        return _c(
          "div",
          { staticClass: "py-1 px-1" },
          [
            _c("span", { staticClass: "badge badge-secondary" }, [
              _vm._v("From: " + _vm._s(path)),
            ]),
            _vm._v(" "),
            _vm._l(typedWarnings, function (warning) {
              return _c(
                "div",
                {
                  key: warning.id,
                  staticClass:
                    "alert alert-warning alert-dismissible fade show m-1 p-1",
                  attrs: { role: "alert" },
                },
                [_c("strong", [_vm._v(_vm._s(warning))])]
              )
            }),
          ],
          2
        )
      }),
      _vm._v(" "),
      _vm._m(0),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar navbar-light bg-light" }, [
      _c(
        "button",
        { staticClass: "btn btn-warning", attrs: { type: "button" } },
        [_vm._v("\n            hidden\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }