<template>
    <div>
        <div id="controlsContainer" ref="controlsContainer" class="row no-gutters">
            <div class="col-11 d-flex flex-row align-items-center">
                <button :id="'chartButton'+item.i" class="btn btn-light btn-sm w-25 mx-2">{{ chartButtonLabel }}</button>
                <button :id="'activitiesButton'+item.i" class="btn btn-light btn-sm w-25 mx-2">{{ activityButtonLabel }}</button>
                <time-interval-select
                    style="white-space: nowrap;"
                    class="mr-3 ml-1"
                    :isCustomTimeOptions="true"
                    :job="job"
                    :item="item"
                    :resetToDefaults="resetToDefaults"
                    @selectedTimeMinChange="selectedTimeMin = $event.target.value; changeResetFilters()"
                    @selectedTimeMaxChange="selectedTimeMax = $event.target.value; changeResetFilters()"
                    @selectedDurationTypeChange="selectedIntervalType = $event.target? $event.target.value: $event; changeResetFilters()"
                    @selectedIntervalTypeChange="selectedIntervalTimeType = $event.target? $event.target.value: $event; changeResetFilters()"
                    @selectedDayMinChange="selectedDayMin = $event.target.value; changeResetFilters()"
                    @selectedDayMaxChange="selectedDayMax = $event.target.value; changeResetFilters()"
                    @onCalculatedMaxMinIntervalDateTime="firstAndLastDateOfInterval = $event"
                    @resetToDefaultsChange="resetToDefaults = $event"
                >
                </time-interval-select>
                <button class="btn btn-success btn-sm w-25 whiteText mx-2" style="width: 50%;" @click="saveConfig()">
                    <span v-if="isSaving" class="spinner-border spinner-border-sm pr-2" role="status" aria-hidden="true"></span>
                    Save Filters
                </button>
                <button class="btn btn-secondary btn-sm w-25 whiteText mx-2" @click="loadConfig(true)" :disabled="disableResetFilters">Reset Filters</button>
            </div>
            <div>
                <b-popover :target="'wellsButton'+item.i" triggers="click blur" placement="bottom">
                        <checkbox-list    
                        class="w-100 mr-1"       
                        :enableSelectAllOption="true"
                        :enableSearch="false"
                        label="Selected Wells"
                        :valueKey="'nameLong'"
                        :outputKey="'id'"
                        height="300"
                        :options="wells"
                        v-bind:selectedOptions="selectedWells"
                        v-on:update:selectedOptions="selectedWells = $event"
                        >
                    </checkbox-list>
                </b-popover>
                <b-popover :target="'activitiesButton'+item.i" triggers="click blur" placement="bottom">
                    <div class="d-flex flex-column whiteText mx-2">
                        <div class="d-flex justify-content-between">
                            <div class="mr-2"> Select Activities: </div>
                            <select v-model="selectedActivityTypes" @change="updateActivityDataBasedOnTypeChange">
                                <option v-for="(option, index) in Object.keys(availableActivities)" :key="index" :value="availableActivities[option]">{{availableActivities[option]}}</option>
                            </select>
                        </div>
                    </div>
                </b-popover>
                <b-popover :target="'chartButton'+item.i" triggers="click blur" placement="bottom">
                    <checkbox-list
                        class="w-100 mr-1"       
                        :enableSelectAllOption="true"
                        :enableSearch="false"
                        label="Select Charts"
                        height="125"
                        :options="availableCharts"
                        v-bind:selectedOptions="selectedCharts"
                        v-on:update:selectedOptions="selectedCharts = $event; changeResetFilters()"
                        >
                    </checkbox-list>
                </b-popover>
            </div>
            <div class="col-1 d-flex justify-content-start align-items-center ml-0">
                <button :id="'exportDataIcon'+item.i" v-tooltip:top="'Export Current View'" class="fake-button" style="backgroundColor:white; border-radius:5px;">
                    <i class="fas fa-download"></i>
                </button>
                <b-popover :target="'exportDataIcon'+item.i" placement="bottom" triggers="hover">
                    <div style="color:white;">
                        <div class="w-100 text-center" style="text-decoration:underline;">Export Data</div>
                        <div class="d-flex justify-content-between mt-2">
                            <div class="mx-2">
                                File Type: 
                            </div>
                            <select id="exportFileType" class="mx-2" v-model="exportFileType">
                                <option value="json" >JSON</option>
                                <option value="csv" selected>CSV</option>
                            </select>
                        </div>
                        <div class="d-flex justify-content-center mt-2">
                            <button type="button" class="btn btn-success green-button" 
                                @click.prevent="exportDataDownload()"
                                :disabled="!rawActivityData || (rawActivityData && !rawActivityData.dataForAllWells)">
                                <div class="d-flex justify-content-center">
                                        <span v-if="false" class="spinner-border spinner-border-sm pr-2" role="status" aria-hidden="true"></span>
                                    <div>
                                        Export
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </b-popover>
            </div>
        </div>
        <div class="d-flex flex-row w-100" style="overflow-x: auto; overflow-y: hidden">
            <div v-show="showPieChartMultiWell" v-for="(well, index) in wells" :key="index" id="pieChartContainer" :style="{height: `${height-chartHeightOffset}px`,width: chartWidthPieChartMultiWell}">
                <pie-chart :chart-data="pieChartDataMultiWell[well.index]" :style="{ position: 'relative', width: '100%', height: '100%'}" :options="pieChartOptionsMultiWell[well.index]" id="pie-chart" :ref="getPieChartRefNamesForMultiWell[well.index]" :customPlugins="chartPlugins"></pie-chart>
            </div>
            <div v-show="showPieChart" id="pieChartContainer" :style="{height: `${height-chartHeightOffset}px`,width: chartWidthPieChart}">
                <pie-chart :chart-data="pieChartData" :style="{ position: 'relative', width: '100%', height: '100%' }" :options="pieChartOptions" id="pie-chart" ref="pieChart" :customPlugins="chartPlugins"></pie-chart>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .whiteText {
        color: white;
    }
</style>

<script>

const DEFAULT_COLOR_ARRAY = ['#41B883', '#71716F', '#00D8FF'];
const DEFAULT_SELECTED_ACTIVITIES = ['frac', 'frac standby', 'frac overlap'];
const ACTIVITY_OPTION_LABELS = ['Frac', 'Wireline'];
const FRAC_OVERLAP_BACKGROUND_COLOR = '#939799';
const ACTIVITY_TYPES = {
    'Frac': ['frac', 'frac standby', 'frac overlap'],
    'Wireline': ['wireline', 'wireline standby'],
}

const DEFAULT_CHARTS = ['Totals Pie Chart', 'Pie Chart Multi-Well'];
const DEFAULT_ACTIVITY_COLORS = {
    frac: '#41B883',
    wireline: '#E46651',
    'frac standby': '#00D8FF',
    'wireline standby': '#C39797',
    total: '#FFC000'
};
const PRIMARY_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const SECOND_DATETIME_FORMAT = 'YYYY-MM-DDTHH:mm:ss';
const CHART_WIDTH_OFFSET = 10;
const CHART_HEIGHT_OFFSET = 60;
const CHART_MIN_WIDTH = 200;

import PieChart from '../PieChart.js';
import pattern from 'patternomaly';
import GlobalFunctions from '../GlobalFunctions.js';
const Moment = require('moment');
const MomentRange = require('moment-range');
const moment = MomentRange.extendMoment(Moment);
import _ from 'lodash';

export default {
    components: {
        PieChart
    },
    mounted() {
        this.selectedWells = this.wells;
        this.selectedIntervalType = 'startEnd';
        this.loadConfig();
        this.getFilteredActivityRecords();
    },
    props: {
        currentEvent: {
            type: [Object, Array]
        },
        job: {
            type: [Object]
        },
        height: {
            type: Number
        },
        width: {
            type: Number
        },
        latestActivityData: {
            type: [Object, Array]
        },
        activityData: {
            type: [Object, Array]
        },
        jobNumber: {
            type: [String],
            required: true
        },
        wells: {
            type: Array,
            required: true
        },
        item: {
            type: Object,
            required: true
        },
        dashboardId: {
            type: [String, Number,Object],
            required: true
        },
        latestHandshakeEvent: {
            type: Object
        }
    },
    data() {
        return {
            chartPlugins: [{
                callbackScope: this, // not a chartjs plugin property, just using this to pass scope
                id: 'noDataMessage',
                afterDraw: function(chart) {
                    if (chart.data.datasets.length === 0 || chart.data.datasets[0].data.length === 0) {
                        // No data is present
                        var ctx = chart.chart.ctx;
                        window.devicePixelRatio = 2; //gets rid of blurry text on canvas
                        var width = chart.chart.width;
                        var height = chart.chart.height;
                        ctx.save();
                        ctx.letterSpacing = '1px';
                        ctx.textAlign = 'center';
                        ctx.textBaseline = 'middle';
                        ctx.font = "20px normal 'Helvetica Neue'";
                        ctx.fillText('No Data', width / 2, height / 2);
                        ctx.restore();
                    }
                }
            }],
            fracOverlapBackgroundColor: FRAC_OVERLAP_BACKGROUND_COLOR,
            rawActivityData: [],
            disableResetFilters: true,
            piechartDataMultiwell: [],
            activityDataPerWell: {},
            pieChartPerWell: {},
            ifMultiWellPieCharts: false,
            activityDataPerWell: [],
            chartHeightOffset: CHART_HEIGHT_OFFSET,
            chartWidthOffset: CHART_WIDTH_OFFSET,
            resetToDefaults: false,
            firstAndLastDateOfInterval: [],
            isSaving: false,
            currentDataLength: 0,
            exportFileType: 'csv',
            selectedWells: [],
            selectedActivities: ['frac', 'frac standby', 'frac overlap'],
            selectedActivityTypes: ACTIVITY_OPTION_LABELS[0],
            selectedCharts: ['Totals Pie Chart', 'Pie Chart Multi-Well'],
            activityRecords: [],
            availableStagesMin: 0,
            availableStagesMax: 0,
            selectedTimeMin: moment().utc().subtract(7,'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            selectedTimeMax: moment().utc().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            selectedDayMin: moment().utc().subtract(7,'days').startOf('day').format('YYYY-MM-DD'),
            selectedDayMax: moment().startOf('day').format('YYYY-MM-DD'),
            selectedStageMin: 0,
            selectedStageMax: 0,
            startTime: 0,
            endTime: 0,
            selectedIntervalType: 'startEnd',
            selectedIntervalTimeType: null,
            pieChartOptions: {
                layout: {
                    padding: {
                        left: 30,
                        right: 30,
                        bottom: 10
                    }
                },
                tooltips: {
                    enabled: false,
                    position: 'nearest',
                    intersect: false,
                    isVertical: false,
                    custom: function(tooltipModel) {
                        const isHovered = this._chart.canvas.matches(':hover');
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-' + this._chart.id);
                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-' + this._chart.id;
                            tooltipEl.classList.add('m-2');
                            tooltipEl.classList.add('chartjs-tooltip');
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }
                        if(isHovered) {
                            tooltipEl.classList.add('move-to-front');
                        } else {
                            tooltipEl.classList.remove('move-to-front');
                        }
                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }
                        //Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }
                        // Set Text
                        if (tooltipModel.body) {
                            tooltipModel.backgroundColor = FRAC_OVERLAP_BACKGROUND_COLOR;
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);
                            let innerHtml = '<thead>';
                            titleLines.forEach(function(title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';
                            //if multiple dataPoints come in with the same datasetIndex just use the first one
                            const renderedDatasets = [];
                            bodyLines.forEach(function(body, i) {
                                const datasetIndex = tooltipModel.dataPoints[i].datasetIndex;
                                if(!renderedDatasets[datasetIndex]) {
                                    const colors = tooltipModel.labelColors[i];
                                    if (typeof colors.backgroundColor !== 'string') {
                                        colors.backgroundColor = FRAC_OVERLAP_BACKGROUND_COLOR; //default background of tooltip when patternomaly is used
                                    }
                                    const style = 'background-color:' + colors.backgroundColor;
                                    const span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                                    innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                    renderedDatasets[datasetIndex] = true;
                                }
                            });
                            innerHtml += '</tbody>';
                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();
                        const positionOffset = 10;
                        let chartAreaTop = this._chart.chartArea.top; //offset position of top of chart (if they are axes here)
                        tooltipEl.style.left = 'unset';
                        tooltipEl.style.right = (window.innerWidth - (position.left + window.pageXOffset + tooltipModel.caretX - positionOffset)) + 'px';
                        tooltipEl.style.top =  position.top + window.pageYOffset + chartAreaTop + 'px';

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';

                        const isTooltipArea = document.getElementById('tooltip-area-'+ this._chart.id);
                        if(isTooltipArea) {
                            const positionRes = isTooltipArea.getBoundingClientRect();
                            tooltipEl.style.top = positionRes.top + window.scrollY -positionOffset  + 'px';
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = positionRes.left -positionOffset + 'px';
                            tooltipEl.style.right = 'unset';
                        }
                    },
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label;
                            let index = tooltipItem.index;
                            let initialLabel = data.labels[index];
                            label = initialLabel + ': ';

                            let secondsData = data.datasets[tooltipItem.datasetIndex].secondsData[index]
                            let minutes = (secondsData / 60);
                            let extraSeconds = secondsData % 60;
                            label += Math.round(minutes) + " min " + extraSeconds +" s";
                            return label;
                        },
                    }
                },
                title: {
                    display: true,
                    text: 'Total',
                    fontColor: '#ffffff',
                    padding: 25,
                    fontSize: 25,
                },
                plugins: {
                    datalabels: {
                        display: 'auto',
                        color: '#000000',
                        backgroundColor: function(context) {
                            let value = context.dataset.data[context.dataIndex]
                            let percentage = Math.round((value/context.chart.config?.data?.total)*100);
                            if (percentage < 10) {
                                return GlobalFunctions.getTitleColorForBG(context.chart.config.options.plugins.datalabels.color);
                            }
                        },
                        font: {
                            size: 14,
                            weight: 'bold'
                        },
                        borderRadius: 4,
                        opacity: 0.8,
                        padding: 1.5,
                        formatter: function(value, context) {
                            let datalabelColor = GlobalFunctions.getTitleColorForBG(context.chart.config.options.title.fontColor);
                            context.chart.config.options.plugins.datalabels.color = datalabelColor;
                            let string = `${context.chart.data.labels[context.dataIndex]}:`;
                            const hours = Math.floor(value / 60);
                            const minutesRemaining = GlobalFunctions.roundAccurately(value % 60, 1).toFixed(1);
                            string += `\n~ ${hours} hrs,\n${minutesRemaining} mins`;
                            if (context.chart.config?.data?.total) {
                                //get pie piece percentage of total circle
                                let percentage = Math.round((value/context.chart.config?.data?.total)*100);
                                string += `\n(${percentage}%)`;
                                if (percentage < 10) {
                                    string =  `${percentage}%`;
                                }
                            }
                            return string;
                        },
                        align: function(context) {
                        // set text align based on slice angle
                        let value = context.dataset.data[context.dataIndex]
                        let percentage = Math.round((value/context.chart.config?.data?.total)*100);
                            if (percentage < 10) {
                                return 'end';
                            } else {
                                return 'start';
                            }
                        },
                        offset: 0,
                        anchor: 'end'
                    },
                },
                legend: {
                    display: false,
                    labels: {
                        fontColor: '#fff'
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            pieChartOptionsForEachWell: {
                layout: {
                    padding: {
                        left: 25,
                        right: 25,
                        bottom: 10
                    }
                },
                tooltips: {
                    enabled: false,
                    position: 'nearest',
                    intersect: false,
                    isVertical: false,
                    custom: function(tooltipModel) {
                        const isHovered = this._chart.canvas.matches(':hover');
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-' + this._chart.id);
                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-' + this._chart.id;
                            tooltipEl.classList.add('m-2');
                            tooltipEl.classList.add('chartjs-tooltip');
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }
                        if(isHovered) {
                            tooltipEl.classList.add('move-to-front');
                        } else {
                            tooltipEl.classList.remove('move-to-front');
                        }
                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }
                        //Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }
                        // Set Text
                        if (tooltipModel.body) {
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);
                            let innerHtml = '<thead>';
                            titleLines.forEach(function(title) {
                                innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';
                            //if multiple dataPoints come in with the same datasetIndex just use the first one
                            const renderedDatasets = [];
                            bodyLines.forEach(function(body, i) {
                                const datasetIndex = tooltipModel.dataPoints[i].datasetIndex;
                                if(!renderedDatasets[datasetIndex]) {
                                    const colors = tooltipModel.labelColors[i];
                                    if (typeof colors.backgroundColor !== 'string') {
                                        colors.backgroundColor = FRAC_OVERLAP_BACKGROUND_COLOR;
                                    }
                                    const style = 'background-color:' + colors.backgroundColor;
                                    const span = '<span class="chartjs-tooltip-key" style="' + style + '"></span>';
                                    innerHtml += '<tr><td>' + span + body + '</td></tr>';
                                    renderedDatasets[datasetIndex] = true;
                                }
                            });
                            innerHtml += '</tbody>';
                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();
                        const positionOffset = 10;
                        let chartAreaTop = this._chart.chartArea.top; //offset position of top of chart (if they are axes here)
                        tooltipEl.style.left = 'unset';
                        tooltipEl.style.right = (window.innerWidth - (position.left + window.pageXOffset + tooltipModel.caretX - positionOffset)) + 'px';
                        tooltipEl.style.top =  position.top + window.pageYOffset + chartAreaTop + 'px';

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'absolute';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                        tooltipEl.style.pointerEvents = 'none';

                        const isTooltipArea = document.getElementById('tooltip-area-'+ this._chart.id);
                        if(isTooltipArea) {
                            const positionRes = isTooltipArea.getBoundingClientRect();
                            tooltipEl.style.top = positionRes.top + window.scrollY -positionOffset  + 'px';
                            tooltipEl.style.position = 'absolute';
                            tooltipEl.style.left = positionRes.left -positionOffset + 'px';
                            tooltipEl.style.right = 'unset';
                        }
                    },
                    callbacks: {
                        label: function(tooltipItem, data) {
                            let label;
                            let index = tooltipItem.index;
                            let initialLabel = data.labels[index];
                            label = initialLabel + ': ';

                            let secondsData = data.datasets[tooltipItem.datasetIndex].secondsData[index]
                            let minutes = (secondsData / 60);
                            let extraSeconds = secondsData % 60;
                            label += Math.round(minutes) + " min " + extraSeconds +" s";
                            return label;
                        }
                    }
                },
                title: {
                    display: true,
                    text: 'Pad Activity Breakdown',
                    fontColor: '#fff',
                    padding: 25,
                    fontSize: 25
                },
                plugins: {
                    datalabels: {
                        display: 'auto',
                        color: '#000000',
                        backgroundColor: function(context) {
                            let value = context.dataset.data[context.dataIndex]
                            let percentage = Math.round((value/context.chart.config?.data?.total)*100)
                            if (percentage < 10) {
                                return GlobalFunctions.getTitleColorForBG(context.chart.config.options.plugins.datalabels.color);
                            }
                        },
                        font: {
                            size: 14,
                            weight: 'bold'
                        },
                        borderRadius: 4,
                        opacity: 0.8,
                        padding: 1.5,
                        formatter: function(value, context) {
                            let datalabelColor = GlobalFunctions.getTitleColorForBG(context.chart.config.data.datasets[0].backgroundColor[0]);
                            context.chart.config.options.plugins.datalabels.color = datalabelColor;
                            let string = `${context.chart.data.labels[context.dataIndex]}:`;
                            const hours = Math.floor(value / 60);
                            const minutesRemaining = GlobalFunctions.roundAccurately(value % 60, 1).toFixed(1);
                            string += `\n~ ${hours} hrs,\n${minutesRemaining} mins`;
                            if (context.chart.config?.data?.total) {
                                //get pie piece percentage of total circle
                                let percentage =  Math.round((value/context.chart.config?.data?.total)*100);
                                string += `\n(${percentage}%)`;
                                if (percentage < 10) {
                                    string =  `${percentage}%`;
                                }
                            }
                            return string;
                        },
                        align: function(context) {
                        // set text align based on slice angle
                        let value = context.dataset.data[context.dataIndex]
                        let percentage = Math.round((value/context.chart.config?.data?.total)*100);
                            if (percentage < 10) {
                                return 'end';
                            } else {
                                return 'start';
                            }
                        },
                        offset: 0,
                        anchor: 'end'
                    },
                },
                legend: {
                    display: false,
                    labels: {
                        fontColor: '#fff'
                    }
                },
                responsive: true,
                maintainAspectRatio: false
            },
            pieChartData: {
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        secondsData: [],
                        label: 'Activity Time Breakdown',
                        backgroundColor: DEFAULT_COLOR_ARRAY,
                        data: []
                    }
                ]
            },
            pieChartDataForEachWell: {
                hoverBorderWidth: 10,
                labels: [],
                datasets: [
                    {
                        secondsData: [],
                        label: 'Activity Time Breakdown',
                        backgroundColor: DEFAULT_COLOR_ARRAY,
                        data: []
                    }
                ]
            },
        };
    },
    computed: {
        findIntervalStartAndEndDates() {
            let minTimeUnix, maxTimeUnix;
            switch(this.selectedIntervalType) {
                case 'startEnd':
                    minTimeUnix = moment(this.job.start, PRIMARY_DATETIME_FORMAT).unix();
                    maxTimeUnix = this.job.end? moment(this.job.end, PRIMARY_DATETIME_FORMAT).unix() : moment.utc().unix();
                    break;
                case 'times':
                    minTimeUnix = moment.utc(this.selectedTimeMin, [PRIMARY_DATETIME_FORMAT,SECOND_DATETIME_FORMAT]).unix();
                    maxTimeUnix = moment.utc(this.selectedTimeMax, [PRIMARY_DATETIME_FORMAT,SECOND_DATETIME_FORMAT]).unix();
                    break;
                case 'days':
                    if (this.firstAndLastDateOfInterval.length > 0 && this.firstAndLastDateOfInterval[0] && this.firstAndLastDateOfInterval[1]) {
                        minTimeUnix =  this.firstAndLastDateOfInterval[0];
                        maxTimeUnix = this.firstAndLastDateOfInterval[1];
                    }
                    break;
                case 'hrs24':
                    minTimeUnix = moment.utc().subtract(24, 'hours').unix();
                    maxTimeUnix =  moment.utc().unix();
                    break;
                case 'hrs48':
                    minTimeUnix = moment.utc().subtract(48, 'hours').unix();
                    maxTimeUnix =  moment.utc().unix();
                    break;
                case 'days7':
                    minTimeUnix = moment.utc().subtract(24*7, 'hours').unix();
                    maxTimeUnix =  moment.utc().unix();
                    break;
            }
            if (minTimeUnix < 0 || maxTimeUnix < 0) {
                return null;
            }
            return [minTimeUnix, maxTimeUnix];
        },
        pieChartOptionsMultiWell() {
            let optionsPerWell = [];
            this.wells.forEach(well => {
                let pieChartOptions = _.cloneDeep(this.pieChartOptionsForEachWell)
                optionsPerWell.push(pieChartOptions)
            })
            return optionsPerWell;
        },
        pieChartDataMultiWell() {
            let dataPerWell = [];
            this.wells.forEach(well => {
                let pieChartData = _.cloneDeep(this.pieChartDataForEachWell)
                dataPerWell.push(pieChartData)
            })
            return dataPerWell;
        },
        getPieChartRefNamesForMultiWell() {
            let refNames = {};
            this.wells.forEach(well => {
                refNames[well.index] = 'pieChart' + well.index;
            })
            return refNames;
        },
        chartButtonLabel() {
            return this.selectedCharts? 'Chart Types: ' + this.selectedCharts.join(", ") : 'Chart Types';
        },
        activityButtonLabel() {
            return this.selectedActivityTypes ? 'Activities: ' + this.selectedActivityTypes : 'Activities';
        },
        readableDurationForExportFileName() {
            let dateRangeInfo = '';
            switch(this.selectedIntervalType) {
            case 'startEnd':
                dateRangeInfo = 'entireJob';
                break;
            case 'times':
                let formattedStartTime =  moment(this.selectedTimeMin, SECOND_DATETIME_FORMAT).format('YYYY-MM-DD_HH:mm:ss');
                let formattedEndTime = moment(this.selectedTimeMax, SECOND_DATETIME_FORMAT).format('YYYY-MM-DD_HH:mm:ss');
                if (formattedStartTime && formattedEndTime) {
                    dateRangeInfo = formattedStartTime + '-to-' + formattedEndTime;
                }
                break;
            case 'days':
                let formattedStartDay =  moment(this.selectedDayMin).format('YYYY-MM-DD');
                let formattedEndDay = moment(this.selectedDayMax).format('YYYY-MM-DD');
                if (formattedStartDay && formattedEndDay) {
                    dateRangeInfo = formattedStartDay + '-to-' + formattedEndDay;
                }
                if (this.selectedIntervalTimeType) {
                    dateRangeInfo += '_'+this.selectedIntervalTimeType.toLowerCase().split(' ').join('_');
                }
                break;
            case 'hrs24':
                dateRangeInfo = 'Last24Hours'
                break;
            case 'hrs48':
                dateRangeInfo = 'Last48Hours'
                break;
            case 'days7':
                dateRangeInfo = 'Last7Days'
                break;
            }
            return dateRangeInfo;
        },
        showPieChart() {
            return this.selectedCharts.includes('Totals Pie Chart');
        },
        showPieChartMultiWell() {
            return this.selectedCharts.includes('Pie Chart Multi-Well');
        },
        availableCharts() {
            return DEFAULT_CHARTS || [];
        },
        availableActivities() {
            return ACTIVITY_OPTION_LABELS || [];
        },
        stageRange() {
            return _.range(this.availableStagesMin, this.availableStagesMax + 1);
        },
        chartWidthPieChartMultiWell() {
            let width;
            if (this.showPieChartMultiWell && this.showPieChart) {
                width = this.width/(this.wells.length+1) < CHART_MIN_WIDTH ? CHART_MIN_WIDTH : this.width/(this.wells.length+1);
                return width + 'px';
            } 
            if (this.showPieChartMultiWell && !this.showPieChart) {
                width = this.width/this.wells.length < CHART_MIN_WIDTH ? CHART_MIN_WIDTH : this.width/this.wells.length;
                return width  + 'px';
            }
        },
        chartWidthPieChart() {
            let width;
            if (this.showPieChart && this.showPieChartMultiWell) {
                width = this.width/(this.wells.length+1) < CHART_MIN_WIDTH ? CHART_MIN_WIDTH : this.width/(this.wells.length+1);
                return width + 'px';
            }
            if (this.showPieChart && !this.showPieChartMultiWell) {
                width = this.width < CHART_MIN_WIDTH ? CHART_MIN_WIDTH : this.width;
                return width + 'px'
            }
        },
    },
    watch: {
        currentEvent: {
            handler(newVal, oldVal) {
                //update data to current time
                this.getFilteredActivityRecords();
            }
        },
        firstAndLastDateOfInterval: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
            }
        },
        selectedTimeMin: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
            }
        },
        selectedTimeMax: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
            }
        },
        selectedIntervalType: {
            handler(newVal, oldVal) {
                if (newVal == 'stages') {
                    if (this.selectedStageMin == 0) {
                        this.selectedStageMin = this.availableStagesMin;
                    }
                    if (this.selectedStageMax == 0) {
                        this.selectedStageMax = this.availableStagesMax;
                    }
                }
                if (newVal == 'times') {
                    if (this.selectedTimeMin == 0) {
                        this.selectedTimeMin = moment.utc(this.job.start).format(PRIMARY_DATETIME_FORMAT);
                    }
                    if (this.selectedTimeMax == 0) {
                        const end = this.job.end ?? moment.utc();
                        this.selectedTimeMax = end.format(PRIMARY_DATETIME_FORMAT);
                    }
                }
                this.getFilteredActivityRecords();
            }      
        },
        wells: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
                if (oldVal.length == 0 && newVal.length !== 0) {
                    const savedWellNums = this.item?.activityBreakdownConfig?.selectedWellNumbers;
                    if (savedWellNums && savedWellNums.length > 0) {
                        this.selectedWells = newVal.filter(well => savedWellNums.includes(well.index));
                    } else {
                        this.selectedWells = newVal;
                        this.selectedWellNumbers = newVal.map(well => well.wellNumber);
                    }
                }
            }
        },
        height: {
            immediate: true,
            handler(newVal, oldVal) {
                this.resizeCharts();
            }
        },
        width: {
            handler(newVal, oldVal) {
                this.resizeCharts();
            }
        },
        selectedWells: {
            handler(newVal, oldVal) {
                //get min and max available stages of selectedWells
                this.selectedWellNumbers = newVal.map(well => well.index);
                this.getStageRangeForWells(newVal);
                this.getFilteredActivityRecords();
            }
        },
        selectedActivityTypes: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
            }
        },
        selectedActivities: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
            }
        },
        selectedCharts: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
            }
        },
        findIntervalStartAndEndDates: {
            handler(newVal, oldVal) {
                this.getFilteredActivityRecords();
            }
        },
    },
    methods: {
        adjustFontSizeFromChartSize(chart) {
            let fontSize = Math.min(chart.width, chart.height) / 20;
            chart.config.options.plugins.datalabels.font.size = fontSize;
        },
        changeResetFilters() {
            this.disableResetFilters = false;
        },
        fracOverlapPattern(stripeColor) {
            let backgroundGrey = this.fracOverlapBackgroundColor; //medium grey
            return pattern.draw('diagonal', backgroundGrey, stripeColor, 10);
        },
        setChartSliceBackGroundColours(pieChartData, pieChartLabels, isTotalsPieChart = false, wellColor = null) {
            let standbyColor = '#71716F';
            let wirelineAndFracTotalsColor = '#41B883';
            let fracOverlapIndex = pieChartLabels.indexOf('frac overlap') !== -1? pieChartLabels.indexOf('frac overlap'): null;
            if (fracOverlapIndex) {
                if (isTotalsPieChart) {
                    pieChartData.datasets[0].backgroundColor[fracOverlapIndex] =  this.fracOverlapPattern(wirelineAndFracTotalsColor);
                } else {
                    pieChartData.datasets[0].backgroundColor[fracOverlapIndex] = this.fracOverlapPattern(wellColor);
                }
            }
            let fracIndex = pieChartLabels.indexOf('frac') !== -1? pieChartLabels.indexOf('frac'): null;
            if (fracIndex) {
                if (isTotalsPieChart) {
                    pieChartData.datasets[0].backgroundColor[fracIndex] = wirelineAndFracTotalsColor;
                } else {
                    pieChartData.datasets[0].backgroundColor[fracIndex] = wellColor;
                }
            }
            let fracStandbyIndex = pieChartLabels.indexOf('frac standby') !== -1? pieChartLabels.indexOf('frac standby'): null;
            if (fracStandbyIndex) {
                pieChartData.datasets[0].backgroundColor[fracStandbyIndex] = standbyColor;
            }
            let wirelineIndex = pieChartLabels.indexOf('wireline') !== -1? pieChartLabels.indexOf('wireline'): null;
            if (wirelineIndex) {
                if (isTotalsPieChart) {
                    pieChartData.datasets[0].backgroundColor[wirelineIndex] = wirelineAndFracTotalsColor;
                } else {
                    pieChartData.datasets[0].backgroundColor[wirelineIndex] = wellColor;
                }
            }
            let wirelineStandbyIndex = pieChartLabels.indexOf('wireline standby') !== -1? pieChartLabels.indexOf('wireline standby'): null;
            if (wirelineStandbyIndex) {
                pieChartData.datasets[0].backgroundColor[wirelineStandbyIndex] = standbyColor;
            }
        },
        getPieChartPerWell() {
            let pieChart = [];
            this.wells.forEach(well => {
                let chart;
                let refName = 'pieChart'+well.index.toString();
                if (this.$refs[refName]) {
                   chart = this.$refs[refName][0].$data._chart;
                } else {
                    chart = null
                }
                if(chart) {
                    pieChart[well.index] = chart
                }
            })
            return pieChart;
        },
        updateActivityDataBasedOnTypeChange() {
            this.changeResetFilters();
            if ((this.selectedActivityTypes[this.selectedActivityTypes.length-1] == 'Wireline' || this.selectedActivityTypes[this.selectedActivityTypes.length-1] == 'Frac')) {
                this.selectedActivityTypes = [this.selectedActivityTypes[this.selectedActivityTypes.length-1]];
            }
            //translates activity labels in dropdown to arrays of selected activitiy keys to get the data by activity
            if (this.selectedActivityTypes.length > 0) {
                this.selectedActivities = []; 
                if (this.selectedActivityTypes?.includes('Frac')) {
                    this.selectedActivities = ACTIVITY_TYPES['Frac'];
                }
                if (this.selectedActivityTypes?.includes('Wireline')) {
                    this.selectedActivities = ACTIVITY_TYPES['Wireline'];
                }
                this.selectedActivities = _.uniqBy(this.selectedActivities); //remove duplicates
            }
        },
        resizeCharts() {
            if (this.showPieChart) {
                if(this.$refs['pieChart']) {
                    this.$refs['pieChart'].$data._chart.resize();
                }
            }
            if (this.showPieChartMuliWell) {
                this.wells.forEach(well => { 
                    if(this.$refs['pieChart' + well.index.toString()]) {
                        this.$refs['pieChart' + well.index.toString()][0].$data._chart.resize();
                    }
                });
            }
        },
        loadConfig(resetFilter=false) {
            if (this.item?.activityBreakdownConfig) {
                const savedConfig = this.item.activityBreakdownConfig;
                this.selectedWellNumbers = savedConfig.selectedWellNumbers || [];
                this.selectedWells = this.wells.filter(well => this.selectedWellNumbers.includes(well.index));
                this.selectedCharts = savedConfig.selectedCharts || DEFAULT_CHARTS;
                this.selectedActivities = savedConfig.selectedActivities || DEFAULT_SELECTED_ACTIVITIES;
                this.selectedActivityTypes = savedConfig.selectedActivityTypes || ACTIVITY_OPTION_LABELS[0];
                this.selectedTimeMin = savedConfig.customTimeMin ||  moment().utc().subtract(7,'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
                this.selectedTimeMax = savedConfig.customTimeMax || moment().utc().startOf('day').format('YYYY-MM-DD HH:mm:ss');        
                this.selectedDayMin = savedConfig.customDayMin || moment().utc().subtract(7,'days').startOf('day').format('YYYY-MM-DD');
                this.selectedDayMax = savedConfig.customDayMax || moment().utc().startOf('day').format('YYYY-MM-DD');
                this.selectedIntervalTimeType = savedConfig.selectedIntervalType || 'Midnight to Midnight';
                this.selectedIntervalType = savedConfig.selectedDurationType || 'startEnd';
                this.updateActivityDataBasedOnTypeChange();
            }

            this.disableResetFilters = true;
            if (resetFilter) {
                this.resetToDefaults = true;
            }
        },
        saveConfig() {
            const self = this;
            this.disableResetFilters = true;
            this.item.activityBreakdownConfig = {
                selectedWellNumbers: this.selectedWellNumbers,
                selectedCharts: this.selectedCharts,
                selectedActivityTypes: this.selectedActivityTypes,
                selectedDurationType: this.selectedIntervalType,
                selectedIntervalType: this.selectedIntervalTimeType,
                customStageMin: this.selectedStageMin,
                customStageMax: this.selectedStageMax,
                customTimeMin: this.selectedTimeMin,
                customTimeMax: this.selectedTimeMax,
                customDayMin: this.selectedDayMin,
                customDayMax: this.selectedDayMax,
            };
            const url = '/saveActivityBreakdownConfig';
            this.isSaving = true;
            axios.post(
                url,
                {
                    item: this.item,
                    dashboardId: this.dashboardId,
                    jobNumber: this.jobNumber
                }
            ).then(function(response) {
                self.isSaving = false;
            }).catch(function(error) {
                console.log('error - saveActivityBreakdownConfig', error);
            });
        },
        filterDataByEachWell(incomingData, wellIndex) {
            if (wellIndex && incomingData) {
                let activityData = incomingData.filter(activity => activity?.wellNumber == wellIndex);
                return activityData;
            } else {
                return [];
            }
        },
        filterDataByActivity(incomingData) {
            let activityData = incomingData.filter(activity => {           
                if (!this.selectedActivities.includes(activity.activity)) {
                    return false;
                }
                return true;
            });
            return activityData;
        },
        createChartForEachWell(activityDataForEachWell) {
            this.wells.forEach(well => {
                let pieChart = this.getPieChartMultiWell(well.index)
                for (let i = 0; i < this.wells.length; i++) {
                    if (!activityDataForEachWell[well.index]) {
                        activityDataForEachWell[well.index] = [];
                    }
                }
                let activityDataForWell = activityDataForEachWell[well.index];
                this.createPieChartDataMultiWell(activityDataForWell, well.index, pieChart);
            })
        },
        createPieChartDataMultiWell(activityDataForEachWell, wellIndex, pieChart) {
                if (activityDataForEachWell && pieChart && wellIndex !== null && wellIndex !== undefined) {
                    const data = {};
                    const newData = {};
                    let dataByTypeMultiWell = {};
                    let secondsDataByTypeMultiWell = {};
                    let total = 0; //minutes total
                    let secondsTotal = 0; //seconds total
                    activityDataForEachWell.forEach(activity => {
                        if (!dataByTypeMultiWell[activity.activity]) {
                            dataByTypeMultiWell[activity.activity] = 0;
                            secondsDataByTypeMultiWell[activity.activity] = 0;
                        }
                        dataByTypeMultiWell[activity.activity] += activity.durationMin;
                        secondsDataByTypeMultiWell[activity.activity] += activity.durationSec;
                        total += activity.durationMin;
                        secondsTotal += activity.durationSec;
                    });
                    //sort alphabetically by key
                    dataByTypeMultiWell = _.fromPairs(_.orderBy(_.toPairs(dataByTypeMultiWell), 0));
                    secondsDataByTypeMultiWell = _.fromPairs(_.orderBy(_.toPairs(secondsDataByTypeMultiWell), 0));

                    if (this.showPieChartMultiWell && pieChart) {
                        this.adjustFontSizeFromChartSize(pieChart);
                        pieChart.config.data.total = total; //store total value here so it can be used to get % of each piece for the labels
                        this.pieChartDataMultiWell[wellIndex].datasets.secondsData = Object.values(secondsDataByTypeMultiWell);
                        this.pieChartDataMultiWell[wellIndex].datasets.data = Object.values(dataByTypeMultiWell);
                        this.pieChartOptionsMultiWell[wellIndex].title.text = `${this.wells[wellIndex].name}`;
                        pieChart.config.options.title.text = `${this.wells[wellIndex].name}`;
                        this.pieChartDataMultiWell[wellIndex].datasets[0].backgroundColor[0] = this.wells[wellIndex].color;
                        let datalabelColor = GlobalFunctions.getTitleColorForBG(this.wells[wellIndex].color);
                        pieChart.config.options.plugins.datalabels.color = datalabelColor;
                        this.pieChartOptionsMultiWell[wellIndex].plugins.datalabels.color = datalabelColor;

                        let pieChartLabels = Object.keys(dataByTypeMultiWell).filter(label => label !== 'total');
                        this.setChartSliceBackGroundColours(this.pieChartDataMultiWell[wellIndex], pieChartLabels, false, this.wells[wellIndex].color);
        
                        pieChartLabels = pieChartLabels.map(label => GlobalFunctions.titleCase(label));
                        this.pieChartDataMultiWell[wellIndex].labels = pieChartLabels;
                        
                        pieChart.config.data.datasets[0].secondsData = Object.values(secondsDataByTypeMultiWell);
                        pieChart.config.data.datasets[0].data = Object.values(_.omit(dataByTypeMultiWell,'total'));
                        pieChart.config.data.datasets[0].labels = Object.keys(dataByTypeMultiWell).filter(label => label !== 'total');
                        pieChart.update();
                    }
                }
        },
        createChartData() {
            const pieChart = this.getPieChart();
            let activityDataForAllWells = this.rawActivityData?.dataForAllWells;
            let activityDataForEachWell = this.rawActivityData?.dataForEachWell;
            if (activityDataForAllWells && activityDataForEachWell && pieChart) {
                if (activityDataForEachWell) {
                    this.createChartForEachWell(activityDataForEachWell);
                }
                const data = {};
                const newData = {};
                let dataByType = {};
                let secondsDataByType = {};
                let total = 0; //minutes total
                let secondsTotal = 0; //seconds total
                Object.values(activityDataForAllWells).forEach(activity => {
                    if (activity) {
                        if (!dataByType[activity.activity]) {
                            dataByType[activity.activity] = 0;
                            secondsDataByType[activity.activity] = 0;
                        }
                        dataByType[activity.activity] += activity.durationMin;
                        secondsDataByType[activity.activity] += activity.durationSec;
                        total += activity.durationMin;
                        secondsTotal += activity.durationSec;
                    }
                });
                //sort alphabetically by key
                dataByType = _.fromPairs(_.orderBy(_.toPairs(dataByType), 0)); 
                secondsDataByType = _.fromPairs(_.orderBy(_.toPairs(secondsDataByType), 0));

                //pie chart data for all wells
                if (this.showPieChart && pieChart) {
                    this.adjustFontSizeFromChartSize(pieChart);
                    pieChart.config.data.total = total; //store total value here so it can be used to get % of each piece for the labels
                    newData.values = Object.values(dataByType);
                    this.pieChartData.datasets.secondsData = Object.values(secondsDataByType);
                    pieChart.config.data.datasets[0].secondsData = Object.values(secondsDataByType);

                    let pieChartLabels = Object.keys(dataByType).filter(label => label !== 'total');
                    this.setChartSliceBackGroundColours(this.pieChartData, pieChartLabels, true);
                    pieChartLabels = pieChartLabels.map(label => GlobalFunctions.titleCase(label));
                    this.pieChartData.labels = pieChartLabels;

                    pieChart.config.data.datasets[0].data = Object.values(_.omit(dataByType,'total'));
                    pieChart.config.data.datasets[0].labels = Object.keys(dataByType).filter(label => label !== 'total');
                    pieChart.update();
                }
                if (this.selectedActivities.includes('total')) {
                    dataByType['total'] = total;
                    secondsDataByType['total'] = secondsTotal;
                }
            }
        },
        getStageRangeForWells(wells) {
            if (wells?.length == 0 || this.activityRecords?.length == 0) {
                return;
            }
            let min,max;
            wells.forEach(well => {
                const wellActivities = this.activityRecords.filter(record => record.wellNumber == well.index);
                min = Math.min(...wellActivities.map(activity => activity.stageNumber));
                max = Math.max(...wellActivities.map(activity => activity.stageNumber));
            });
            this.availableStagesMin = min;
            this.availableStagesMax = max;
            if (this.selectedStageMin == null) {
                this.selectedStageMin = min;
            }
            if (this.selectedStageMax == null) {
                this.selectedStageMax = max;
            }
        },
        getPieChart() {
            if (this.$refs.pieChart) {
                return this.$refs['pieChart'].$data._chart;
            }
            return null;
        },
        getPieChartMultiWell(wellIndex) {
            let refName = 'pieChart'+wellIndex.toString();
            if (this.$refs[refName] && this.$refs[refName][0]) {
                return this.$refs[refName][0].$data._chart;
            }
            return null;
        },
        getFilteredActivityRecords() {
            const self = this;
            let minTimeUnix = null;
            let maxTimeUnix = null;
            const url = `/filtered-activity-data/${this.jobNumber}`;
            if (this.findIntervalStartAndEndDates?.length > 0) {
                minTimeUnix = this.findIntervalStartAndEndDates[0]? this.findIntervalStartAndEndDates[0]: null;
                maxTimeUnix = this.findIntervalStartAndEndDates[1]? this.findIntervalStartAndEndDates[1]: null;
            } 
            const data = {
                minTimeUnix: minTimeUnix,
                maxTimeUnix: maxTimeUnix,
                activityTypes: this.selectedActivities,
            };
            $.get(
                url,
                data,
                function (activities) {
                    if(activities.error) {
                        alert('Error occurred during data retrieval');
                    } else {
                        self.rawActivityData = activities;
                        self.createChartData();
                    }
                }
            );

        },
        convertJsonToCSV(jsonData) {
            const headerRow = Object.keys(jsonData[0]).join(',') + '\n';
            let csvValues = '';
            jsonData.forEach(record => {
                //omit the fromWell,toWell properties that sometimes appear in the record
                const validKeysObject = _.omit(record,['fromWell', 'toWell']);
                const valueRow = Object.values(validKeysObject).join(',') + '\n';
                csvValues += valueRow;
            });
            return headerRow + csvValues;
        },
        exportDataDownload() {
            const data = this.rawActivityData.dataForAllWells;
            // Compile export data and format:
            let blob;
            if (this.exportFileType == 'csv') {
                blob = new Blob([this.convertJsonToCSV(data)], {type: 'text/json'});
            } else {
                blob = new Blob([JSON.stringify(data)], {type: 'text/json'});
            }        
            //create a link element, initiate download with a click event, then remove the link
            const link = document.createElement('a');
            link.download = `ActivityBreakdownKPI-${this.job.padName}-${this.jobNumber}-${this.readableDurationForExportFileName}-${moment().unix()}.${this.exportFileType}`;
            link.href = window.URL.createObjectURL(blob);
            link.dataset.downloadurl = ['text/json', link.download, link.href].join(':');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    }
}
</script>