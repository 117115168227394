<!-- DEPRECIATED -->
<!-- iwsAccordian.vue SHOULD BE USED INSTEAD -->
<template>
    <div class="accordion user-select-none" @click="toggle">
        <div :ref="`accordion-${id}`" class="accordion-btn" :class="{open: isOpen}" role="tab" v-b-toggle="id" :aria-expanded="isOpen ? 'true' : 'false'">
            <div class="d-flex justify-content-between align-items-center w-100">
                <slot name="header">
                    <span>
                        <slot name="title">
                            {{ title }}
                        </slot>
                    </span>
                    <span>
                        <slot name="icon">
                            <img :src="icon" alt="">
                        </slot>
                    </span>
                </slot>
            </div>
        </div>
        <b-collapse :id="id" v-model="isOpen" role="tabpanel" class="accordion-dropdown">
            <div class="accordion-body">
                <slot name="body"></slot>
            </div>
        </b-collapse>
    </div>
</template>

<script>
import { v4 as uuidv4 } from "uuid";
export default {
    props: {
        title: String,
        open: {
            type: Boolean,
            default: false,
            required: false,
        },
        openIcon: {
            type: String,
            required: false,
            default: '/images/icons/chevron-down.svg'
        },
        closedIcon: {
            type: String,
            required: false,
            default: '/images/icons/chevron-right-dark.svg'
        }
    },
    data() {
        return {
            id: null,
            icon: null,
            isOpen: false
        }
    },
    mounted() {
        this.id = uuidv4();
        this.isOpen = this.open;
        this.icon = this.isOpen ? this.openIcon : this.closedIcon;
    },
    methods: {
        toggle() {
            this.icon = this.isOpen ? this.openIcon : this.closedIcon;
            this.$emit('update:open', this.isOpen);
        }
    }
}
</script>

<style scoped>
    .accordion-body {
        margin: 20px 12px;
    }

    .accordion-btn {
        font-size: 14px;
        cursor: pointer;
        padding: 8px 12px;
        border-radius: 4px;
        color: #676E78;
        background: #242A30;
        border: 1px solid #676E78;
    }

    .open {
        color: #EAECF0;
        border: 1px solid #EAECF0;
    }
</style>