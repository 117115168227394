<template>
    <div class="dark-modal modal" id="AddTagModal" ref="AddTagModal" tabindex="-1" role="dialog">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div class="modal-title">
                        <h5>
                            Add Tag
                        </h5>

                        <div>
                            You can easily add and edit tags here. Once you are done, click on 'Add Tag'.
                        </div>
                    </div>

                    <button type="button" class="close" @click="resolve(null)">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
        
                <div class="modal-body">
                    <div>
                        <iws-select
                            label="Top Level Tag"
                            :value.sync="topLevelTagId"
                            :options="topLevelTags"
                            display-name="name"
                            value-name="id"
                            @change="getStandardTags()"
                        />
                    </div>

                    <div>
                        <iws-select
                            label="Standard Tag"
                            :value.sync="standardTagId"
                            :options="standardTags"
                            display-name="name"
                            value-name="id"
                            @change="updateCompleteTagName()"
                        />
                    </div>
                    
                    <div>
                        <iws-checkbox :value.sync="spreadEnabled" label="Spread Number (* or integer)"/>
                        <iws-input
                            :value.sync="spreadNumber"
                            :disabled="!spreadEnabled"
                            @input="updateCompleteTagName"
                        />
                    </div>
                    
                    <div>
                        <iws-input
                            :value.sync="completeTagName"
                            label="Complete tag name"
                            required="true"
                        />
                    </div>
                </div>
    
                <div class="modal-footer">
                    <iws-button type="outline-light" text="Cancel" @click="resolve(null)" />
    
                    <iws-button text="Add Tag" :click="saveTag" />
                </div>
            </div>
        </div>
    </div>
</template>
        
<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, toast } = GlobalFunctions;
    
export default {
    props: {
        customerId: {
            type: String,
            default: null
        }
    },
    data: () => ({
        resolve: null,

        activities: [
            { value: 'npt', name: 'NPT Frac Tags' },
            { value: 'frac', name: 'Frac' },
            { value: 'wireline', name: 'Wireline' }
        ],

        topLevelTagId: null,
        topLevelTags: [],

        standardTagId: null,
        standardTags: [],

        spreadNumber: '1',

        isFullTag: false,
        completeTagName: null,
        displayName: '',
        entryType: '',
        decimal_places: null,
        abbreviation: '',
        spreadEnabled: true
    }),

    methods: {
        updateCompleteTagName() {
            const matchedTopLevel = this.topLevelTags.find(tag => tag.id == this.topLevelTagId);
            const matchedStandard = this.standardTags.find(tag => tag.id == this.standardTagId);
            this.completeTagName = this.spreadEnabled ? `${matchedTopLevel?.name}_${this.spreadNumber}_${matchedStandard?.name}`
                                    : `${matchedTopLevel?.name}_${matchedStandard?.name}`;
            this.displayName = matchedStandard?.displayName ? matchedStandard.displayName : '';
            this.entryType = matchedStandard?.entryType ? matchedStandard.entryType : '';
            this.decimal_places = matchedStandard?.decimal_places ? matchedStandard.decimal_places : '';
            this.abbreviation = matchedStandard?.abbreviation ? matchedStandard.abbreviation : '';
        },

        getStandardTags() {
            this.standardTags = [];
            this.standardTagId = null;
            this.completeTagName = '';
            return $.get(`/metadata/standardTagsForTopLevel/${this.topLevelTagId}/${this.customerId}`,'json').then(data => {
                this.standardTags = data;
                this.standardTagId = data[0].id;
                this.updateCompleteTagName();
            });
        },
        getTopLevelTags() {
            this.completeTagName = '';

            return $.get('/tags/topLevel','json').then(data => {
                this.topLevelTags = data;
                this.topLevelTagId = data[0].id;
                this.getStandardTags();
            });
        },

        saveTag() {
            // Since all fields other than completeTagName are used to generate the name, completeTagName is the only actually required field
            if (isFalsy(this.completeTagName)) {
                toast({
                    title: 'Please select an activty type and tag name',
                    variant: 'danger'
                });
                return;
            }

            let retVal = this.completeTagName;
            if (this.isFullTag) {
                retVal = {
                    tagName: this.completeTagName,
                    displayName: this.displayName,
                    entryType: this.entryType,
                    decimal_places: this.decimal_places,
                    abbreviation: this.abbreviation
                };
            }

            return this.resolve(retVal);
        },

        open(tag) {
            const modalElem = $('#AddTagModal');
            this.isFullTag = tag !== null; // Eseentially, is not an object
            this.spreadNumber = '1';
            
            this.getTopLevelTags();

            return new Promise((resolve, reject) => {
                modalElem.show();
                this.resolve = (val) => {
                    modalElem.hide();
                    resolve(val);
                }
            });
        }
    },
    
    watch: {
        spreadEnabled() {
            this.updateCompleteTagName();
        },
        topLevelTagId(newVal) {
            let topLevelTagName = this.topLevelTags.find(tag => tag.id == newVal)?.name;
            // Currently stage tags does not use spread number so disable it
            if (topLevelTagName == 'stage') {
                this.spreadEnabled = false;
            } else {
                this.spreadEnabled = true;
            }
        },
    }
};
</script>

<style scoped>
    .modal-dialog {
        max-width: 450px;
    }

    .modal-body>div>div {
        margin-bottom: 15px;
    }
    #modal-display-name {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #FFFFFF;
        margin: 0px;
    }
    .custom-checkbox {
        cursor: pointer;
        text-align: center;
        vertical-align: middle;
        width: 20px !important;
        height: 20px !important;
        border-radius: 20%;
        border: 1px solid #007bff;
        box-shadow: none;
        font-size: 1.5em;
        padding-top: 3px;
    }
</style>