<template>
    <div>
        <div class="row" v-if="showHeader">
            <div class="col-12 text-center text-white rounded mb-1 bg-secondary">
                {{header}}
            </div>
        </div>
        <div class="row">
            <div v-for="(descr, index) in descriptions" class="text-center border border-secondary rounded mb-1"
            :class="{'col-4':numberOfCols==3,'col-6':numberOfCols==2,'col-12':numberOfCols==1}">
                <div class="row">
                    <div class="col-12-sm col-6">
                        {{descr}}
                    </div>
                    <div class="col bg-light text-dark m-1">
                        {{values[index]}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                message:"current value component"
            }
        },
        props:['showHeader','header','descriptions','values','numberOfCols']
    }

</script>
