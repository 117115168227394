var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c(
          "div",
          { staticClass: "col-lg-10 col-12" },
          [
            _c("div", { staticClass: "row pt-2 pb-4" }, [
              _c("div", { staticClass: "col-flex page-title pr-2" }, [
                _vm._v("Tag Summary"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1" }),
              _vm._v(" "),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "col-3 h5",
                    attrs: { href: _vm.returnToDashboardURL },
                  },
                  [_vm._v("Return to Dashboards")]
                ),
              ]),
              _vm._v(" "),
              _c("span", {
                directives: [
                  {
                    name: "b-toggle",
                    rawName: "v-b-toggle.sidebar-right",
                    modifiers: { "sidebar-right": true },
                  },
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Table Config",
                    expression: "'Table Config'",
                    arg: "top",
                  },
                ],
                ref: "tableConfig",
                staticClass: "fas fa-cog fa-md pt-1",
                attrs: { role: "button" },
                on: { click: _vm.onGearIconPress },
              }),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _vm._l(_vm.tagGroupsMapData, function (tagGroup, index) {
                    return _c("tag-summary-table-slot", {
                      key: index,
                      attrs: {
                        headerName: tagGroup.baseTagName,
                        tpiComp: tagGroup.tpiComp,
                        darkMode: _vm.darkMode,
                        showDefaultColumn: _vm.showDefaultColumn,
                        sortedTags: tagGroup.tags,
                        latestTagValues: _vm.latestTagValues,
                        topLevel: tagGroup.topLevel,
                      },
                    })
                  }),
                  _vm._v(" "),
                  _vm.tagGroupsMapData.length === 0
                    ? _c("div", { staticClass: "text-white pl-2" }, [
                        _vm._v(" No tags information available"),
                      ])
                    : _vm._e(),
                ],
                2
              ),
            ]),
            _vm._v(" "),
            _c("signalr-error-modal", {
              attrs: {
                modalVisible: _vm.signalRModalVisible,
                resolutionPackage: _vm.resolutionPackage,
              },
              on: {
                okayPressed: _vm.initializeSignalRConnection,
                modalClose: function ($event) {
                  _vm.signalRConnected = true
                  _vm.signalRModalVisible = false
                },
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("tag-summary-config", {
        ref: "tableConfig",
        attrs: { jobNumber: _vm.jobNumber },
        on: { showDefault: (canShow) => (_vm.showDefaultColumn = canShow) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }