var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "on-clickaway",
          rawName: "v-on-clickaway",
          value: _vm.timelineClickAway,
          expression: "timelineClickAway",
        },
      ],
      ref: "timelineWrapperRef",
      attrs: { id: "timeline-wrapper" },
      on: {
        mousedown: _vm.mousedown,
        mousemove: _vm.mousemove,
        mouseup: _vm.mouseup,
      },
    },
    [
      _vm.min !== null && _vm.max !== null
        ? _c("vue-slider", {
            key: _vm.min,
            ref: "slider",
            attrs: {
              clickable: false,
              dragOnClick: false,
              duration: 0,
              min: _vm.min,
              max: _vm.max,
              tooltip: "none",
              disabled: _vm.disabled,
              order: true,
              dotSize: 18,
              enableCross: _vm.startNPT ? false : true,
            },
            on: {
              "drag-start": _vm.onDragStart,
              "drag-end": _vm.onDragEnd,
              change: _vm.onRangeChange,
              dragging: _vm.onDragging,
            },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.showPopup
        ? _c(
            "chart-comments-create",
            _vm._b(
              {
                ref: "comment-popup",
                attrs: {
                  activeDrawing: _vm.activeDrawing,
                  popupPosition: _vm.popupPosition,
                  wellStageIntervals: _vm.wellStageIntervals,
                  hasEditPermission: _vm.hasEditPermission,
                  timeseriesHeight: _vm.timeseriesHeight,
                  timeseriesWidth: _vm.timeseriesWidth,
                },
                on: {
                  onDateChange: _vm.onPopupDateChange,
                  onDismiss: _vm.removeSelection,
                },
              },
              "chart-comments-create",
              _vm.popupProps,
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }