<template>
    <div id="TimeKeeperComponent" class="px-2">
        <base-table-view
        :busy="busy"
        :filters="filters"
        @filter="openFilters()"
        :showFilters="filtersActive"
        :canClearAllFilters="false"
        :filter.sync="searchFilter">
            <template #button-extensions>
                <iws-button
                        class="btn btn-outline-light ml-3"
                        type="link"
                        v-bind:nptOnlyFilter="nptOnlyFilter"
                        @click="nptOnlyFilter()"
                        :disabled="busy || nptReport">
                    <template #text v-if="nptOnlyMode || nptReport"><i class="far fa-check-square mr-2"></i>NPT Events Only</template>
                    <template #text v-else><i class="far fa-square mr-2" />NPT Events Only</template>
                </iws-button>
            </template>
            <template #title>
              <div v-if="nptReport" class="mt-3"> NPT Report</div> 
              <div v-else class="mt-3"> Time Keeper</div> 
            </template>

            <template #right-option>
                <div class="d-flex">
                    <iws-button
                        type="link"
                        @click="closePopover('priority-popover'), showTableConfig = true"
                        :disabled="busy"
                    >
                        <template #text>
                            <i class="fas fa-cog"></i> Table configuration
                        </template>
                    </iws-button>

                    <b-table-export-component :items="preformattedDataSet" :fields="exportFields" :fileName="exportFileName" :jsonExportsAll="false" class="clickable"
                        :arrayFields="{'customerVendors': ['friendly_name']}"
                        :jsonFields="{'data.timekeeper.code.text': 'code', 'data.timekeeper.duration': 'durationSeconds', 'data.endTime': 'endTime', 'wellName': 'wellId', 'nptOutcome.friendly_name': 'nptOutcome', 'customerVendors': 'nptResponsibleVendors','data.comment':'comment'}"/>
                </div>
            </template>

            <template #active-filters>
                <template v-for="(display, well) in stagesDisplay">
                    <div v-if="display?.text" :key="`${well}-stages`" class="custom-badge clickable" @click="clearFilter('stages', well)">
                        {{ well }} - stages: {{ display.text }}
                        <div v-if="display.count > 0" class="filter-count">+{{ display.count }}</div>
                        <img v-if="!busy" src="/images/icons/badge-close.svg" class="clickable"/>
                    </div>
                </template>
                <template v-for="(filter, index) in dynamicFilters">
                    <div v-if="filter.canFilter && (filters[filter.name].length > 0 || (filter.includeUncoded && includeUncoded[filter.name]))" :key="index" class="custom-badge clickable" @click="clearFilter(filter.name)">
                        {{filter.header}}: {{ filters[filter.name].length > 0 ? getDisplayValue(index, filters[filter.name][0]) : 'Uncoded' }}
                        <div v-if="filters[filter.name].length > 1 || includeUncoded[filter.name]" class="filter-count">
                            +{{ (filters[filter.name].length > 1 ? filters[filter.name].length - 1 : 0) + (includeUncoded[filter.name] ? 1 : 0) }}
                        </div>
                        <img v-if="!busy" src="/images/icons/badge-close.svg" class="clickable"/>
                    </div>
                </template>
                <div v-if="filters.startTime || filters.endTime" class="custom-badge clickable" @click="clearFilter('datetime')">
                    {{ datetimeString(filters.startTime, filters.endTime) }}
                    <img v-if="!busy" src="/images/icons/badge-close.svg" class="clickable"/>
                </div>
            </template>

            <template #content>
                <b-overlay :show="busy" variant="light" blur="5px" opacity="0.80">
                    <template #overlay>
                        <div class="text-center">
                            <b-spinner large class="align-middle"></b-spinner>
                            <p class="mt-2">Updating Results...</p>
                        </div>
                    </template>
                    <iws-table
                    :busy="busy"
                    ref="mainTable"
                    :filter="searchFilter"
                    :columns="displayFields"
                    :items="filteredEventData"
                    @update:items="updateItems()"
                    @update:sortByCol="updateSortColumn"
                    :maxPageSize="parseInt(jsonLocalStorage.config?.rowsPerPage) != NaN ? parseInt(jsonLocalStorage.config?.rowsPerPage) : 5"
                    :tableDimension="{'max-height': 'calc(100vh - 240px)', 'overflow-y': 'auto'}"
                    :sortAsc.sync="sortAsc"
                    :sortByCol="sortBy"
                    paginationPosition="left"
                    id="main-table"
                    :key="nptValueKey"
                    class="m-0">
                        <template #header_priorityDescription="{ data }">
                            <div class="d-flex">
                                {{ data.label }}
                                <i class="fas fa-info-circle icon ml-1 clickable" id="priority-descriptions" @click="openPopover('priority-popover')"></i>
                            </div>
                        </template>
                        <template #cell_wellColor="{ data }">
                            <div :style="{ 'height': '20px', 'width': '100%', 'background-color': data.value}"></div>
                        </template>
                        <template #cell_customerVendors="{ data }">
                            <ul v-if="data.value?.length > 0">
                                <li v-for="(vendor, index) of data.value" :key="index">{{ vendor?.friendly_name }}</li>
                            </ul>
                            <span v-else>-</span>
                        </template>
                        <template v-for="col in editableColumns" v-slot:[`cell_${col}`]="{ data }">
                            <b-form-input v-if="!isApprovedStage(data.item)" v-model="data.value" class="darkmode-form" @change="updateNptValue(data)" >{{ data.value }}</b-form-input>
                        </template>
                        <template #cell_comment="{ data }">
                           <span v-if="data.item.data.comment">
                            <i :id="'popover-comment-' + data.item.id" class="fas fa-comment ml-2"></i>
                            <b-popover custom-class="popover-approvals" :target="'popover-comment-' + data.item.id" boundary="viewport" triggers="hover" placement="bottom" :delay="{show: 0, hide: 50}">
                                <b-row>
                                    <b-col>
                                        <div style="color: black; max-height: 200px;">
                                            <b-row class="px-3">
                                                <p>{{ data.item.data.comment }}</p>
                                            </b-row>
                                        </div>
                                    </b-col>
                                </b-row>
                            </b-popover>
                           </span>
                           <span v-else>-</span>
                        </template>
                        <template #footer>
                            <tr v-if="filteredEventData.length > 0">
                                <td v-for="(slot, index) in sumValuesToShow" :key="index">
                                    {{ slot }}
                                </td>
                            </tr>
                        </template>
                    </iws-table>
                </b-overlay>
            </template>
        </base-table-view>

        <time-keeper-filters-modal
            ref="filterModal"
            :dateMinMax="dateMinMax"
            :uniqueStages="stagesByWellDefaultBooleans"
            :dynamicFilters="dynamicFilters"
            :formatDatetime="datetimeString"
            :stagesByWell="stagesByWell"
            :getDisplayValue="getDisplayValue"/>

        <b-popover ref="priority-popover" custom-class="priority-popover" target="priority-descriptions" :placement="popoverPlacement" triggers="click">
            <template #title>
                <span @click="closePopover('priority-popover')" class="text-light d-flex justify-content-end">
                    <i class="fas fa-times-circle"></i>
                </span>
            </template>
            <b-table class="m-0" fixed small :dark="true" :items="priorityDescriptions"></b-table>
        </b-popover>

        <!-- Config Modal -->
        <iws-modal
            id="table-config"
            title="Table Configuration"
            :showModal="showTableConfig"
            primaryButtonText="Apply"
            maxWidth="400px"
            :primaryButtonDisabled="false"
            @close="showTableConfig = false"
            @primary-action="saveConfig()"
            :secondaryButtonVisible="false"
        >
            <template #content>
                <div style="margin-bottom: 20px">
                    <div class="config-container" v-if="isFeatureFlagged('TIMEKEEPER_PRIORITY')">
                        <p>Priority Handling</p>
                        <iws-switch size="medium" :value.sync="copiedUserPriorityHandlingConfig"/>
                    </div>
                    <div class="config-container align-items-baseline">
                        <label for="per-page">Rows per page:</label>
                        <input id="per-page" v-model="tableConfig.rowsPerPage" min="1" type="number" />
                    </div>
                </div>
                <div class="config-toggle-columns">
                    <p class="config-header">Show / Hide Columns</p>
                    <div class="config-container">
                        <p>Priority Description</p>
                        <iws-switch size="medium" :value.sync="tableConfig.showDescriptions"/>
                    </div>
                    <div class="config-container">
                        <p>NPT Outcome</p>
                        <iws-switch size="medium" :value.sync="tableConfig.showNptOutcomes"/>
                    </div>
                    <div class="config-container">
                        <p>NPT Responsible Vendor(s)</p>
                        <iws-switch size="medium" :value.sync="tableConfig.showResponsibleVendor"/>
                    </div>
                </div>
            </template>
        </iws-modal>

        <iws-modal title="NPT Edit Confirmation" :showModal="showNptModal" max-width="500px"
            @primary-action="confirmNptEdit()" @secondary-action="cancelNptEdit()">
            <template #content>
                Are you sure you want to change NPT value? This will trigger frac stage summary regeneration. Please confirm.
            </template>
        </iws-modal>
    </div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions';
import SignalRMixin from '../../mixins/SignalRMixin.js';
import JSONLocalStorageMixin from '../../mixins/JSONLocalStorageMixin';
import TimeKeeperFiltersModal from './TimeKeeperFiltersModal.vue';
import moment from 'moment';
const { isTruthy, toast } = GlobalFunctions;
import _ from 'lodash';

const NO_VALUE_PLACEHOLDER = '--';
const MIN_ITEMS_FOR_POPOVER_SWAP = 4;
export default {
    props: {
        jobNumber: {
            type: String,
            required: true
        },
        eventData: {
            type: [Array],
            required: true,
            default: () => []
        },
        thisJob: {
            type: Object,
            required: true
        },
        activeCompanyJobs: {
            type: [Array, Object],
            required: true,
            default: () => []
        },
        priorityDescriptions: {
            type: [Array, Object],
            required: true,
            default: () => []
        },
        wells: {
            type: [Array, Object],
            required: true,
            default: () => []
        },
        userPriorityHandling: {
            type: Boolean,
            required: true,
            default: false
        },
        filterCodeOptions: {
            type: [Array, Object],
            required: false,
            default: () => []
        },
        filterActivityOptions: {
            type: [Array, Object],
            required: false,
            default: () => []
        },
        filterNptVendorOptions: {
            type: [Array, Object],
            required: false,
            default: () => []
        },
        filterNptOutcomeOptions: {
            type: [Array, Object],
            required: false,
            default: () => []
        },
        filterStepDescOptions: {
            type: [Array, Object],
            required: false,
            default: () => []
        },
        iwsUser: {
            type: [Boolean,String],
            required: false,
            default: false
        },
        nptReport: {
            type: [Boolean],
            required: true,
            default: false
        },
        approvedStages:{
            type: [Object],
            required: false,
            default: []
        },
        sessionId: {
            type: String,
            required: true
        }
    },
    components: {
        TimeKeeperFiltersModal
    },
    mixins: [SignalRMixin, JSONLocalStorageMixin],
    data() {
        return {
            onlyNPTReport: this.nptReport,
            copiedUserPriorityHandlingConfig: false,
            setFilters: {},
            sortBy: 'data.startTime',
            sortAsc: false,
            busy: false,
            preformattedDataSet: null,
            timestampFormat: 'YYYY-MM-DD hh:mm:ss A',
            momentTimeFormat: 'YYYY-MM-DDTHH:mm:ss',
            dateMinMax: [],
            showTableConfig: false,
            tableConfig: {
                rowsPerPage: 100,
                showNptOutcomes: true,
                showDescriptions: false,
                usePriorityHandling: false,
                showResponsibleVendor: true
            },
            currentPage: 1,
            isFiltered: false,
            searchFilter: null,
            filteredEventData: [],
            displayedEventData: [],
            popoverPlacement: 'bottom',
            filters: {
                wells: [],
                codes: [],
                stages: {},
                endTime: null,
                activities: [],
                nptVendors: [],
                priorities: [],
                nptOutcomes: [],
                startTime: null,
                stepDescriptions: [],
                nptOnly: false,
            },
            includeUncoded: {
                wells: false,
                stepDescriptions: false,
                activities: false,
                codes: false,
                nptOutcomes: false,
                nptVendors: false
            },
            stagesDisplay: {},
            displayFields: [],
            dynamicFilters: [],
            displaySumValues: [],
            nptOnlyMode: false,
            tempNptData: {},
            showNptModal: false,
            nptValueKey: 0,
            fields: [
                {
                    key: 'wellColor',
                    label: 'Well Color',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    },
                    thStyle: { position: 'sticky','width': '125px', left: '0px', backgroundColor: '#2E353D', boxSizing: 'border-box' },
                    colStyle: { position: 'sticky','width': '125px', left: '0px', backgroundColor: '#252A2F', boxSizing: 'border-box'},
                    stickyColumn: true
                },
                {
                    ref: 'well-filter',
                    key: 'wellName',
                    label: 'Well ID',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    },
                    thStyle: { top: '-1px', 'width': '125px', position: 'sticky', left: '75px', backgroundColor: '#2E353D', boxSizing: 'border-box'},
                    colStyle: { position: 'sticky',  'width': '125px', left: '75px', backgroundColor: '#252A2F', boxSizing: 'border-box' },
                    stickyColumn: true
                },
                {
                    ref: 'stage-filter',
                    key: 'stageNumber',
                    label: 'Stage',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    },
                    thStyle: { position: 'sticky','width': '125px', left: '145px', backgroundColor: '#2E353D', boxSizing: 'border-box'},
                    colStyle: { position: 'sticky','width': '125px', left: '145px', backgroundColor: '#252A2F', boxSizing: 'border-box' },
                    stickyColumn: true
                },
                {
                    ref: 'activity-filter',
                    key: 'data.timekeeper.activity',
                    label: 'Activity',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    },
                    thStyle: { left: '375px'},
                    colStyle: { left: '375px' },
                },
                {
                    ref: 'step-filter',
                    key: 'stepDescription',
                    label: 'Step Description',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    }
                },
                {
                    ref: 'code-filter',
                    key: 'codeText',
                    label: 'Code',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    }
                },
                {
                    ref: 'outcome-filter',
                    key: 'nptOutcome.friendly_name',
                    label: 'NPT Outcome',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    }
                },
                {
                    ref: 'vendor-filter',
                    key: 'customerVendors',
                    label: 'NPT Responsible Vendor(s)',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    }
                },
                {
                    ref: 'priority-filter',
                    key: 'priorityDescription',
                    label: 'Priority Description',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? value : '-';
                    }
                },
                {
                    ref: 'start-filter',
                    key: 'data.startTime',
                    label: 'Start Time',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        return isTruthy(value) ? this.timestampFormatter(value) : '-';
                    }
                },
                {
                    ref: 'end-filter',
                    key: 'data.endTime',
                    label: 'End Time',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        if(item.data?.timekeeper?.step == "npt" && item.subCategory == "start"){
                            return "Ongoing";
                        }
                        return isTruthy(value) ? this.timestampFormatter(value) : '-';
                    }
                },
                {
                    key: 'data.timekeeper.duration',
                    label: 'Duration',
                    sortable: true,
                    disableHeaderClick: true,
                    formatter: (value, key, item) => {
                        if(item.data?.timekeeper?.step == "npt" && item.subCategory == "start"){
                            return "Ongoing";
                        }
                        return isTruthy(value) ? this.secondsToDhms(value) : "Ongoing";
                    }
                }
            ],
            metaFields: [],
            sumValues: [
                {
                    slot1: 'Total Counts',
                    slot2: '--', // Number of wells
                    slot3: '--', // Number of stages
                    slot4: '--', // Number of activities
                    slot5: '--', // Number of codes
                    slot6: '--', // Number of NPT outcomes
                    slot7: '--', // Number of NPT responsible vendors
                    slot8: '--', // Number of priority desc
                    slot9: '--', // Unused (start time)
                    slot10: '--', // Unused (end time)
                    slot11: '--', // Total duration
                    slot12: 'Total Duration :'
                }
            ],
            currentlyFiltering: false,
            signalRConnected:false,
            ignoreColumnList: ["wellColor", "wellName", "stageNumber", "data.timekeeper.activity", "stepDescription", "codeText", "nptOutcome.friendly_name", "customerVendors", "data.startTime", "data.endTime", "data.timekeeper.duration", "stage_frac_npt", "stage_frac_pumpingTime_npt"],
            durationColumnList: ["Stage Gross NPT Time", "Stage Net NPT Time"]
        };
    },
    methods: {
        nptOnlyFilter(NPTReport=false) {
            if (this.currentlyFiltering){
                return
            }
            this.currentlyFiltering = true;
            if (!NPTReport){
                //Send off a filter request that only returns NPT events
                if(this.filters['nptOnly'] === undefined) {
                    this.filters['nptOnly'] = this.nptOnlyMode;
                }
                this.filters['nptOnly'] = !this.filters['nptOnly'];
                this.nptOnlyMode = this.filters['nptOnly'];   
                 
            }else{
                this.filters['nptOnly'] = true;
                this.nptOnlyMode = true;
            }
            
            this.filterData();
            this.shareFilter();
            //Cache busting hacky fix to computed value not recomputing in time
            //Without this the uniqueMetaFields can sometimes not be recalculated in time for table rerender
            this.uniqueMetaFields;
            this.currentlyFiltering = false;
        },
        updateItems(items) {
            this.displayedEventData = items;
            this.preformatOnNextTick();
        },
        updateSortColumn(newSortCol) {
            this.sortBy = newSortCol;
        },
        openPopover(ref) {
            if (this.$refs.mainTable?.displayedItems?.length <= MIN_ITEMS_FOR_POPOVER_SWAP) {
                this.popoverPlacement = 'right';
            } else {
                this.popoverPlacement = 'bottom';
            }

            if (this.$refs[ref]?.length) {
                this.$refs[ref][0].$emit('open');
            } else {
                this.$refs[ref].$emit('open');
            }
        },
        closePopover(ref) {
            if (this.$refs[ref]?.length) {
                this.$refs[ref][0].$emit('close');
            } else {
                this.$refs[ref].$emit('close');
            }
        },
        openFilters() {
            this.closePopover('priority-popover');
            this.$refs.filterModal.open(this.filters, this.includeUncoded).then(({filters, uncoded}) => {
                if (!!filters && !!uncoded) {
                    Object.keys(filters.stages).forEach(well => {
                        this.stagesDisplay[well] = this.getStagesDisplay(filters.stages[well]);
                    });
                    this.filters = filters;
                    this.includeUncoded = uncoded;
                    this.filterData();
                    this.shareFilter(); // add filter params url name without reloading the page
                }
            });
        },
        shareFilter() {
            // gets url parameters to share selected filters
            Object.keys(this.setFilters).forEach(key => {
                if (this.setFilters[key] == null) {
                    delete this.setFilters[key];
                }
            });

            // only include uncoded filters that are true
            let urlIncludeUncoded = {};
            Object.keys(this.includeUncoded).forEach(key => {
                if (this.includeUncoded[key]) {
                    let newKey = 'uncoded' + key.charAt(0).toUpperCase() + key.slice(1);
                    urlIncludeUncoded[newKey] = this.includeUncoded[key];
                }
            });

            // stages url format example: ["wellName:stage1|stage2|stage6","wellName2:stage1|stage5"]
            // to show wellName1 has stages 1,2,6 selected
            let stages = [];
            for (const well in this.filters.stages) {
                const wellStages = Object.keys(this.filters.stages[well])
                    .filter(key => this.filters.stages[well][key])
                    .join('|');

                if (wellStages) {
                    stages.push(`${well}:${wellStages}`);
                }
            }

            let filterQueryParam = new URLSearchParams(this.setFilters);
            let stageQueryString = stages.length > 0 ? new URLSearchParams({stages}).toString() : null;
            let uncodedQueryString = new URLSearchParams(urlIncludeUncoded).toString();

            // remove invalid object type automatically set in stages in url string
            filterQueryParam.delete('stages');
            filterQueryParam = filterQueryParam.toString();

            // add parameters to url without reloading the page
            let allFilterParams = filterQueryParam ? `?${filterQueryParam}` : "";

            if (stageQueryString) {
                allFilterParams += filterQueryParam ? `&${stageQueryString}` : `?${stageQueryString}`;
            }

            if (uncodedQueryString) {
                allFilterParams += allFilterParams ? `&${uncodedQueryString}` : `?${uncodedQueryString}`;
            }

            let url = new URL(window.location.href);
            url = url.origin + url.pathname + allFilterParams;
            window.history.pushState({}, '', url);
        },
        filterData() {
            this.closePopover('priority-popover', true);

            let filters = {};
            this.busy = true;
            const handleDatetime = (datetime) => {
                if (!datetime)
                    return null;
                return moment.utc(datetime, this.momentTimeFormat)
                                .subtract(this.thisJob.hourOffset, 'h')
                                .toISOString();
            }

            for (const [field, fieldFilters] of Object.entries(this.filters)) {
                switch (field) {
                    case 'stages':
                        let stages = {};
                        for (const [wellName, wellStages] of Object.entries(fieldFilters)) {
                            const well = _.find(this.thisJob.wells, ['nameLong', wellName]);
                            stages[well.index] = Object.keys(Object.fromEntries(
                                Object.entries(wellStages).filter(([key, value]) => value === true)
                            ));
                        }
                        filters[field] = stages;
                        break;
                    case 'startTime':
                        filters[field] = handleDatetime(fieldFilters);
                        break;
                    case 'endTime':
                        filters[field] = handleDatetime(fieldFilters);
                        break;
                    case 'nptOnly':
                        filters[field] = fieldFilters;
                        break;
                    default:
                        filters[field] = fieldFilters.length > 0 ? fieldFilters : null;
                        break;
                }
            }

            const uncoded =  Object.keys(Object.fromEntries(
                                Object.entries(this.includeUncoded).filter(([key, value]) => value === true)
                            ));

            this.setFilters = filters;
            $.post(
                `/time-keeper/filter/${this.jobNumber}`,
                {
                    filters,
                    uncoded,
                    '_token': $('meta[name="csrf-token"]').attr('content')
                },
                (response) => {
                    this.handleResponse(response);
                }
            ).fail((jqXHR, textStatus, errorThrown) => {
                const errorMessage = 'Failed to update results';
                if (jqXHR.status == 401) {
                    console.warn('Unauthorized');
                } else {
                    console.warn(errorMessage, errorThrown);
                    alert(errorMessage);
                }
            }).always(() => {
                this.busy = false;
                this.currentPage = 1;
            });
        },
        handleResponse(filteredData) {
            this.isFiltered = false;
            this.filteredEventData = filteredData;
            this.preformatOnNextTick();
        },
        setFields() {
            const fields = {
                'NPT Outcome': this.jsonLocalStorage.config?.showNptOutcomes,
                'Priority Description': this.jsonLocalStorage.config?.showDescriptions,
                'NPT Responsible Vendor(s)': this.jsonLocalStorage.config?.showResponsibleVendor
            };
            this.displayFields = this.fieldsToShow.filter(f => !fields.hasOwnProperty(f.label) || fields.hasOwnProperty(f.label) && fields[f.label]);

            const values = {
                'slot7': this.jsonLocalStorage.config?.showNptOutcomes,
                'slot8': this.jsonLocalStorage.config?.showResponsibleVendor,
                'slot9': this.jsonLocalStorage.config?.showDescriptions,
            };
            const sumValues = [];
            Object.entries(this.sumValues[0]).forEach(([slot, value]) => {
                if (!values.hasOwnProperty(slot) || values.hasOwnProperty(slot) && values[slot])
                    sumValues.push(value);
            });
            this.displaySumValues = sumValues;
        },
        setupFilters() {
            this.dynamicFilters = [];
            const fields = {
                'nptOutcomes': this.jsonLocalStorage.config?.showNptOutcomes,
                'priorities': this.jsonLocalStorage.config?.showDescriptions,
                'nptVendors': this.jsonLocalStorage.config?.showResponsibleVendor
            };
            const excludedFilters = ['startTime', 'endTime', 'stages', 'nptOnly'];
            const settings = {
                nptOutcomes: {
                    header: 'NPT Outcome',
                    placeholder: 'npt outcomes',
                    options: this.filterNptOutcomeOptions
                },
                nptVendors: {
                    header: 'NPT Responsible Vendor',
                    placeholder: 'npt responsible vendors',
                    options: this.filterNptVendorOptions
                },
                codes: {
                    header: 'Code',
                    placeholder: 'codes',
                    options: this.filterCodeOptions
                },
                wells: {
                    header: 'Well ID',
                    placeholder: 'wells',
                    options: this.wells.map(well => {
                        return  {id: `${well.index}`, value: well.nameLong};
                    })
                },
                activities: {
                    header: 'Activity',
                    placeholder: 'activities',
                    options: this.filterActivityOptions
                },
                priorities: {
                    header: 'Priority Description',
                    placeholder: 'priority descriptions',
                    options: this.uniquePriorityDescriptions
                },
                stepDescriptions: {
                    header: 'Step Description',
                    placeholder: 'step descriptions',
                    options: this.filterStepDescOptions
                }
            }
            const filters = Object.keys(this.filters).filter(f => !excludedFilters.includes(f));
            filters.forEach((filter) => {
                this.dynamicFilters.push({
                    name: filter,
                    ...settings[filter],
                    includeUncoded: this.includeUncoded.hasOwnProperty(filter),
                    canFilter: !fields.hasOwnProperty(filter) || fields.hasOwnProperty(filter) && fields[filter]
                });
            });
        },
        setupSharedFilters(urlParameters) {
            // sets filter variables based on url parameters that were shared
            const convertDateTime = (datetime) => {
                return moment.utc(datetime, this.momentTimeFormat).add(this.thisJob.hourOffset, 'h').format(this.momentTimeFormat);
            }

            urlParameters = new URLSearchParams(urlParameters);
            const well = urlParameters.get('wells');
            const code = urlParameters.get('codes');
            const stage = urlParameters.get('stages');
            const endTime = urlParameters.get('endTime');
            const activity = urlParameters.get('activities');
            const nptVendor = urlParameters.get('nptVendors');
            const priority = urlParameters.get('priorities');
            const nptOutcome = urlParameters.get('nptOutcomes');
            const startTime = urlParameters.get('startTime');
            const stepDescription = urlParameters.get('stepDescriptions');
            const uncodedWell = urlParameters.get('uncodedWells');
            const uncodedCode = urlParameters.get('uncodedCodes');
            const uncodedStepDescription = urlParameters.get('uncodedStepDescriptions');
            const uncodedActivity = urlParameters.get('uncodedActivities');
            const uncodedNptVendor = urlParameters.get('uncodedNptVendors');
            const uncodedNptOutcome = urlParameters.get('uncodedNptOutcomes');

            const nptOnlyMode = urlParameters.get('nptOnly');
            this.nptOnlyMode = nptOnlyMode == 'true' ? true : false;

            let wellId = this.getFilterValue(well);

            this.filters = {
                wells: wellId || wellId === 0 ? well.split(',') : [],
                codes: this.getFilterValue(code) ? code.split(',') : [],
                activities: this.getFilterValue(activity) ? activity.split(',') : [],
                nptVendors: this.getFilterValue(nptVendor) ? nptVendor.split(',') : [],
                priorities: this.getFilterValue(priority) ? priority.split(',') : [],
                nptOutcomes: this.getFilterValue(nptOutcome) ? nptOutcome.split(',') : [],
                stepDescriptions: this.getFilterValue(stepDescription) ? stepDescription.split(',') : [],
                stages: this.handleStagesSharedData(stage),
                startTime: this.getFilterValue(startTime) ? convertDateTime(startTime) : null,
                endTime: this.getFilterValue(endTime) ? convertDateTime(endTime) : null,
            };

            this.includeUncoded = {
                wells: this.getFilterValue(uncodedWell),
                codes: this.getFilterValue(uncodedCode),
                activities: this.getFilterValue(uncodedActivity),
                nptVendors: this.getFilterValue(uncodedNptVendor),
                nptOutcomes: this.getFilterValue(uncodedNptOutcome),
                stepDescriptions: this.getFilterValue(uncodedStepDescription),
            };

            let filters = {};
            this.busy = true;
            const handleDatetime = (datetime) => {
                if (!datetime)
                    return null;
                return moment.utc(datetime, this.momentTimeFormat)
                                .subtract(this.thisJob.hourOffset, 'h')
                                .toISOString();
            }

            for (const [field, fieldFilters] of Object.entries(this.filters)) {
                switch (field) {
                    case 'stages':
                        let stages = {};
                        for (const [wellName, wellStages] of Object.entries(fieldFilters)) {
                            const well = _.find(this.thisJob.wells, ['nameLong', wellName]);
                            stages[well.index] = Object.keys(Object.fromEntries(
                                Object.entries(wellStages).filter(([key, value]) => value === true)
                            ));
                        }
                        filters[field] = stages;
                        break;
                    case 'startTime':
                        filters[field] = handleDatetime(fieldFilters);
                        break;
                    case 'endTime':
                        filters[field] = handleDatetime(fieldFilters);
                        break;
                    default:
                        filters[field] = fieldFilters.length > 0 ? fieldFilters : null;
                        break;
                }
            }

            this.setFilters = filters;
            this.busy = false;
        },
        getFilterValue(value) {
            try {
                return JSON.parse(value);
            } catch (e) {
                return JSON.parse(JSON.stringify(value));
            }
        },
        handleStagesSharedData(stages) {
            // formats stages url string into this.filters.stages object
            let sharedStageFilters = {};
            let newStageFilters = {};
            let eachStage = [];
            let maxStage = 0;
            let sharedStage = [];
            let originalStages = [];
            let originalStageFilters = this.filters.stages;

            if (!this.getFilterValue(stages)) {
                return originalStageFilters;
            }

            // create and format object of shared filter stages from url
            const allWellStages = stages.split(',');
            allWellStages.forEach((well) => {
                const [wellName, stageValues] = well.split(':');
                eachStage = stageValues.split('|'); // includes stages selected by user to share
                sharedStageFilters[wellName] = stageValues ? eachStage : [];
            });

            // combine shared filter stages and original filter stages to format new stages
            Object.keys(originalStageFilters).forEach((well) => {
                if (sharedStageFilters.hasOwnProperty(well) && sharedStageFilters[well].length > 0) {
                    // if shared filter stages has well, user has shared stages for that well
                    let stageInfo = {};
                    sharedStage = sharedStageFilters[well];
                    originalStages = Object.keys(originalStageFilters[well]).map(Number);
                    maxStage = Math.max(...originalStages);
                    for (let i = 0; i <= maxStage; i++) {
                        let index = i.toString();
                        stageInfo[index] = sharedStage.includes(index);
                        newStageFilters[well] = stageInfo;
                        this.stagesDisplay[well] = this.getStagesDisplay(stageInfo);
                    }
                } else {
                    // if shared filter stages does not have well, user did not select any stages to share
                    // use original stages that have all stages set to false
                    newStageFilters[well] = originalStageFilters[well];
                }
            });
            return newStageFilters;
        },
        clearFilter(name, well = null) {
            switch(name) {
                case 'datetime':
                    this.filters.endTime = null;
                    this.filters.startTime = null;
                    break;
                case 'stages':
                    this.filters.stages[well] = this.stagesByWellDefaultBooleans[well];
                    this.stagesDisplay[well] = {};
                    break;
                default:
                    this.filters[name] = [];
                    this.includeUncoded[name] = false;
                    break;
            }
            this.filterData();
            this.shareFilter();
        },
        getDisplayValue(index, id) {
            const filter = this.dynamicFilters[index]?.options.find(f => f.id == id);
            if (filter)
                return filter.value;
        },
        getStagesDisplay(stages) {
            let count = 0;
            const ranges = [];
            const values = [];
            let endRange = null;
            let startRange = null;
            const allSelectedStages = [];

            const formatRange = (start, end) => {
                return start === end ? start :`${start}-${end}`;
            }

            Object.entries(stages).forEach(([stage, selected] ) => {
                if (selected)
                    allSelectedStages.push(+stage);
            })

            for (const [stage, value] of Object.entries(stages)) {
                if (value) {
                    values.push(+stage)
                    if (!startRange)
                        startRange = stage;
                    endRange = stage;
                } else if (startRange) {
                    ranges.push(formatRange(startRange, endRange));
                    startRange = null;
                    endRange = null;
                }
                if (ranges.length == 2)
                    break;
            }

            if (startRange && endRange)
                ranges.push(formatRange(startRange, endRange));

            const index = allSelectedStages.indexOf(values[values.length - 1]);
            if (index > -1)
                count = allSelectedStages.slice(index+1).length

            return {text: ranges.join(','), count};
        },
        saveConfig() {
            this.jsonLocalStorage.config = {...this.tableConfig};
            this.jsonLocalStorage.save();

            if (this.copiedUserPriorityHandlingConfig != this.userPriorityHandling) {
                this.savePriorityHandlingConfigForUser();
                //TO DO : We may be able to get away with not doing a reload here anymore and just requesting the data gain
                //worth revisiting
                location.reload();
            }

            this.setFields();
            this.setupFilters();

            this.showTableConfig = false;
        },
        savePriorityHandlingConfigForUser(){
            let self = this;
            $.post(
                `/time-keeper/save-user-priority-handling`,
                {
                    'userPriorityHandlingEnabled' : this.copiedUserPriorityHandlingConfig,
                    '_token': $('meta[name="csrf-token"]').attr('content')
                },
                (response) => {
                    //No action required here
                }
            ).fail((jqXHR, textStatus, errorThrown) => {
                const errorMessage = 'Failed to apply filters';
                if (jqXHR.status == 401) {
                    console.warn('Unauthorized');
                } else {
                    console.warn(errorMessage, errorThrown);
                    alert(errorMessage);
                }
            }).always(() => {
                this.busy = false;
                this.currentPage = 1;
            });
        },
        preformatOnNextTick() {
            this.$nextTick(() => {
                this.preformattedDataSet = this.preFormatDataSet();
            });
        },
        setdateMinMax() {
            const reduceEvents = this.eventData.map((event) => event.data.startTime);

            if (reduceEvents.length == 0) {
                //TO DO : HANDLE THIS MORE GRACEFULLY
                return;
            }

            //Get the min date for events
            const min = reduceEvents.reduce(function (a, b) {
                return moment(a).isBefore(moment(b)) ? a : b;
            });
            //min = min.substring(0,min.indexOf(' '));

            //Get the max date for events
            const max = reduceEvents.reduce(function (a, b) {
                return moment(a).isAfter(moment(b)) ? a : b;
            });
            //max = max.substring(0,max.indexOf(' '));

            this.dateMinMax = this.getDates(min, max);

            if (this.dateMinMax.length > 0 && moment(this.dateMinMax[1]).isAfter(moment(max))) {
                this.dateMinMax = [this.dateMinMax[0], max];
            }
        },
        getDates(startDate, stopDate) {
            const dateArray = [];
            let currentDate = moment(startDate);
            stopDate = moment(stopDate);
            while (currentDate <= stopDate) {
                dateArray.push(moment(currentDate).format('YYYY-MM-DD'));
                currentDate = moment(currentDate).add(1, 'days');
            }

            return dateArray;
        },
        getUniqueStages: function(filtered = false) {
            const uniqueSet = {};
            const dataset = filtered ? this.activeDataSet : this.eventData;
            dataset.forEach((target) => {
                const label = target.wellNumber + '-' + target.stageNumber;
                if(uniqueSet[label] === undefined) {
                    uniqueSet[label] = true;
                }
            });
            return Object.keys(uniqueSet).length;
        },
        timestampFormatter(timestamp) {
            timestamp = moment.utc(timestamp).format(this.timestampFormat);
            return timestamp;
        },
        startTimestampFormatter(timestamp, duration) {
            timestamp = moment.utc(timestamp).subtract(duration, 'seconds').format(this.timestampFormat);
            return timestamp;
        },
        secondsToTimestamp(duration) {
            //Takes in a bunch of seconds and spits out a time string
            return new Date(duration * 1000).toISOString().substring(11, 19);
        },
        secondsToDhms(seconds) {
            if(seconds == null){
                return "Duration not set";
            }
            seconds = Number(seconds);
            const d = Math.floor(seconds / (3600 * 24));
            const h = Math.floor((seconds % (3600 * 24)) / 3600);
            const m = Math.floor((seconds % 3600) / 60);
            const s = Math.floor(seconds % 60);

            const dDisplay = d > 0 ? d + ' d ' : '';
            const hDisplay = h > 0 ? h + (h == 1 ? ' hr ' : ' hrs ') : '';
            const mDisplay = m > 0 ? m + ' m ' : '';
            const sDisplay = s >= 0 ? s + ' s'  : '';

            let returnString = dDisplay + hDisplay + mDisplay + sDisplay;
            return returnString;
        },
        sumDurations() {
            let result = 0;
            this.eventData.forEach((target) => {
                result += target.data.timekeeper.duration ? target.data.timekeeper.duration : 0;
            });
            return result;
        },
        preFormatDataSet: function () {
            const formattedDataSet = [];
            const targetDataSet = this.activeDataSet;

            if (targetDataSet != null) {
                targetDataSet.forEach((data) => {
                    if (data['startTime'] !== undefined) {
                        data['startTime'] = this.startTimestampFormatter(data['startTime']);
                    }
                    if (data['data']['timekeeper']['duration'] !== undefined) {
                        const duration = data['data']['timekeeper']['duration'];
                        data['data']['duration'] = this.secondsToTimestamp(duration);
                        data['durationMinutes'] = GlobalFunctions.roundAccurately((duration / 60), 2);
                    }
                    formattedDataSet.push(data);
                });
            }

            this.setSumValues(true);

            return formattedDataSet;
        },
        setSumValues(filtered = false) {
            if (!filtered) {
                //Wells
                this.sumValues[0]['slot2'] = this.uniqueWells.length;

                //Stages
                this.sumValues[0]['slot3'] = this.getUniqueStages();

                //Activities
                this.sumValues[0]['slot4'] = this.uniqueActivities.length;

                //Step Descriptions
                this.sumValues[0]['slot5'] = this.uniqueStepDescriptions.length;

                //Codes
                this.sumValues[0]['slot6'] = this.uniqueCodes.length;

                //NPT Outcomes
                this.sumValues[0]['slot7'] = this.uniqueNptOutcomes.length;

                //NPT Responsible Vendor(s)
                this.sumValues[0]['slot8'] = this.uniqueNptResponsibleVendors.length;

                //Priority Descriptions
                this.sumValues[0]['slot9'] = this.uniquePriorityDescriptions.length;

                //Duration
                this.sumValues[0]['slot12'] = this.secondsToDhms(this.sumDurations());
            } else {
                const targetDataSet = this.activeDataSet;
                const getUniqueSetSize = (key) => {
                    let uniqueSet = new Set(targetDataSet.map((value) => {
                        return _.get(value, key);
                    }));
                    uniqueSet = new Set(Array.from(uniqueSet).filter(value => {
                        if(typeof value == 'number') {
                            return value >= 0;
                        } else if (typeof value == 'string') {
                            return value !== '';
                        } else if (typeof value == 'boolean') {
                            return true;
                        } else if (value == null) {
                            return false;
                        } else {
                            return value;
                        }
                    }));
                    return uniqueSet.size;
                };

                //Wells
                this.sumValues[0]['slot2'] = getUniqueSetSize('wellNumber');

                //Stages
                this.sumValues[0]['slot3'] = this.getUniqueStages(true);

                //Activities
                this.sumValues[0]['slot4'] = getUniqueSetSize('data.timekeeper.activity');;

                //Step Descriptions
                this.sumValues[0]['slot5'] = getUniqueSetSize('stepDescription');

                //Codes
                this.sumValues[0]['slot6'] = getUniqueSetSize('codeText');

                //NPT Outcome
                this.sumValues[0]['slot7'] = getUniqueSetSize('nptOutcome.friendly_name');

                //NPT Responsible Vendor(s)
                const vendorSet = new Set();
                targetDataSet.forEach((value) => {
                    value.customerVendors.forEach(vendor => vendorSet.add(vendor?.friendly_name));
                });
                this.sumValues[0]['slot8'] = vendorSet.size;

                //Priority Descriptions
                this.sumValues[0]['slot9'] = getUniqueSetSize('priorityDescription');

                //Duration
                let filteredDuration = 0;
                targetDataSet.forEach((target) => {
                    filteredDuration += target.data.timekeeper.duration ? target.data.timekeeper.duration : 0;
                });
                this.sumValues[0]['slot12'] = this.secondsToDhms(filteredDuration);
            }
            this.recalculateSummations()
        },
        recalculateSummations(){
            const values = {
                'slot7': this.jsonLocalStorage.config?.showNptOutcomes,
                'slot8': this.jsonLocalStorage.config?.showResponsibleVendor,
                'slot9': this.jsonLocalStorage.config?.showDescriptions,
            };
            const sumValues = [];
            Object.entries(this.sumValues[0]).forEach(([slot, value]) => {
                if (!values.hasOwnProperty(slot) || values.hasOwnProperty(slot) && values[slot])
                    sumValues.push(value);
            });
            this.displaySumValues = sumValues;
        },
        getDuration(start, end) {
            return moment(end).diff(moment(start), 'seconds');
        },
        getUniqueData(id, key, compositeKey = false) {
            const set = [];
            const ids = [];
            this.eventData.forEach((target) => {
                const data = _.get(target, key);
                const dataId = _.get(target, id);
                if (isTruthy(data) && !ids.includes(dataId) && compositeKey) {
                    const index = set.findIndex(s => s.value == data);
                    if (index > -1)
                        set[index].id = `${set[index].id};${dataId}`
                    else
                        set.push({id: dataId, value: data});
                    ids.push(dataId);
                } else  if (isTruthy(data) && !ids.includes(dataId)) {
                    ids.push(dataId);
                    set.push({id: dataId, value: data});
                }
            });
            return _.orderBy(set, ['value'], ['asc']);
        },
        sortByKeys(object) {
            return Object.keys(object).sort().reduce((obj, key) => {
                obj[key] = object[key];
                return obj;
            }, {});
        },
        datetimeString(start, end) {
            const formatDatetime = (datetime) => {
                return moment(datetime).format(this.timestampFormat);
            }
            if (start && end) {
                return `${formatDatetime(start)} to ${formatDatetime(end)}`;
            } else if (start) {
                return formatDatetime(start);
            } else if (end) {
                return formatDatetime(end);
            }
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        signalrConnectedHandler() {
            this.signalRConnected = true;
        },
        signalrConnectionFailedHandler() {
            this.signalRConnected = false;
        },
        signalREventHandler(message){
            if (this.nptReport){
                if ((message.category == 'timekeeper' && message?.data?.timekeeper?.step == 'npt')
                    || message.category == 'nptValue' ){
                        // Data handling is done via the trait - Resubmit filter simplest way to update table
                        this.nptOnlyFilter(true);
                }
            }
        },
        //gather NPT data for post request + show confirmation modal
        updateNptValue(data){
            let subCategoryName='';
            for(let i=0; i<this.displayFields.length; i++){
                if(data.label == this.displayFields[i].label){
                    subCategoryName = this.displayFields[i].key;
                    break;
                }
            }
            if(data.value==null){
                data.value = "";
            }

            if(this.durationColumnList.includes(data.label)){
                data.type="duration"
            } else {
                data.type="number"
            }

            if(data.type=="duration"){
                let parsedDuration = data.value.split(':')
                let durationSeconds = (Number(parsedDuration[0])*3600)+(Number(parsedDuration[1])*60)+Number(parsedDuration[2])
                let newTimestamp = new Date().toISOString();
                let newEndTime = new Date(newTimestamp);
                newEndTime.setSeconds(newEndTime.getSeconds() + durationSeconds);
                this.tempNptData = {
                    timestamp: newTimestamp,
                    messageType: 'fieldEvent',
                    internal: false,
                    category: 'timekeeper',
                    subCategory: 'end',
                    jobId: data.item.jobId.toUpperCase(),
                    jobNumber: data.item.jobNumber,
                    wellId: data.item.wellId.toUpperCase(),
                    wellNumber: data.item.wellNumber,
                    stageNumber: data.item.stageNumber,
                    data: {
                        timekeeper: {priority: 6000, activity: 'frac', step:'npt'},
                        startTime: newTimestamp,
                        endTime: newEndTime.toISOString(),
                        reference: data.item.data.reference.toUpperCase(),
                        label: data.label,
                        type: data.type
                        },
                    _token: $('meta[name="csrf-token"]').attr('content')
                }
            } else {
                this.tempNptData = {
                    timestamp: new Date().toISOString(),
                    messageType: 'fieldEvent',
                    category: 'nptValue',
                    subCategory: subCategoryName,
                    jobId: data.item.jobId.toUpperCase(),
                    jobNumber: data.item.jobNumber,
                    wellId: data.item.wellId.toUpperCase(),
                    wellNumber: data.item.wellNumber,
                    stageNumber: data.item.stageNumber,
                    data: {value: data.value, reference: data.item.data.reference.toUpperCase(), label: data.label, type: data.type},
                    _token: $('meta[name="csrf-token"]').attr('content')
                }
            }

            if(this.validateNptEdit(data)){
                this.showConfirmNptModal();
            } else {
                return toast({title: 'Invalid input, please verify data format.', variant: 'danger'});
            }
        },
        //validates form input
        validateNptEdit(data){
            let inputValidated=false;
            const regex=/^((([0-9]+):[0-5][0-9]):([0-5][0-9]))$/;
            if((this.durationColumnList.includes(data.label) && regex.test(data.value))
                || (!this.durationColumnList.includes(data.label) && !isNaN(data.value))){
                inputValidated=true;
            } 
            return(inputValidated);
        },
        //posts new NPT value after modal confirmation
        confirmNptEdit(){
            $.post(
                '/update-npt-value',
                this.tempNptData,
                function (result) {
                    if (result?.hasOwnProperty('error') && result.error) {
                        alert(result.message);
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to update value';
                if (jqXHR.status == 401) {
                    console.warn('Unauthorized');
                } else {
                    console.warn(errorMessage, errorThrown);
                    alert(errorMessage);
                }
            });
            this.hideConfirmNptModal();
        },
        //reverts value when NPT modal cancelled
        cancelNptEdit(){
            this.nptValueKey += 1;
            this.hideConfirmNptModal();
        },
        //shows NPT edit confirmation modal
        showConfirmNptModal() {
            this.showNptModal = true;
        },
        //Hides NPT edit confirmation modal
        hideConfirmNptModal() {
            this.showNptModal = false;
        },
        isApprovedStage(data){
            console.log(data);
            if ( this.approvedStages[data.wellNumber] && (this.approvedStages[data.wellNumber][data.stageNumber])){
                return this.approvedStages[data.wellNumber][data.stageNumber]
            }
            return false
        }

    },
    computed: {
        sumValuesToShow() {
            if(this.nptOnlyMode){
                let regularPlusNpt = this.displaySumValues;
                this.uniqueMetaFields.forEach((targetField) => {
                    regularPlusNpt.push("--");
                });
                return regularPlusNpt;
            }else{
                return this.displaySumValues;
            }
        },
        getDisplayedRowAmount() {
            if (this.displayedEventData.length > 0) {
                return this.displayedEventData.length
            }
            return this.filteredEventData.length
        },
        exportFileName() {
            const currentTimeStamp = moment().utc().valueOf();
            return `time_keeper_${this.jobNumber}_${currentTimeStamp}`;
        },
        returnToDashboardURL: function () {
            //return to either last visited or default dashboard
            const previousURL = document.referrer;
            return (previousURL == null || previousURL.search('/dashboards') >= 0) ?
                document.referrer : '/dashboards/' + this.jobNumber;
        },
        activeDataSet() {
            return this.$refs.mainTable?.itemsCopy ? this.$refs.mainTable.itemsCopy :this.filteredEventData;
        },
        stagesByWell() {
            const set = {};
            this.wells.forEach((targetWell) =>{
                if(targetWell.nameLong == null || targetWell.nameLong == undefined || targetWell.nameLong == ''){
                    return;
                }else{
                    set[targetWell.nameLong] = [];
                }
                const numberOfStages = targetWell.numberOfStages;
                const stages = set[targetWell.nameLong];
                for(let i = 1; i <= numberOfStages; i++){
                    stages.push(i);
                }
                set[targetWell.nameLong] = stages.sort((a, b) => {return b - a});
            });
            return this.sortByKeys(set);
        },
        stagesByWellDefaultBooleans() {
            const set = {};
            this.wells.forEach((targetWell) =>{
                if(targetWell.nameLong == null || targetWell.nameLong == undefined || targetWell.nameLong == ''){
                    return;
                }else{
                    set[targetWell.nameLong] = {};
                }
                const numberOfStages = targetWell.numberOfStages;
                for(let i = 1; i <= numberOfStages; i++){
                    set[targetWell.nameLong][i] = false;
                }
            });
            return this.sortByKeys(set);
        },
        uniqueActivities: function() {
            return this.getUniqueData('data.timekeeper.activity', 'data.timekeeper.activity');
        },
        uniqueStepDescriptions: function () {
            return this.getUniqueData('stepDescriptionId', 'stepDescription', true);
        },
        uniquePriorityDescriptions: function () {
            return this.getUniqueData('data.timekeeper.priority', 'priorityDescription').map(pair => {
                return  {id: `${pair.id}`, value: pair.value};
            });
        },
        uniqueCodes: function () {
            return this.getUniqueData('data.timekeeper.codeId', 'codeText');
        },
        uniqueNptOutcomes: function () {
            return this.getUniqueData('data.nptOutcomeId', 'nptOutcome.friendly_name');
        },
        uniqueNptResponsibleVendors: function () {
            const set = [];
            const ids = [];
            this.eventData.forEach((target) => {
                target.customerVendors?.forEach((vendor) => {
                    if (vendor?.friendly_name && !ids.includes(vendor?.id)) {
                        ids.push(vendor.id);
                        set.push({id: vendor.id, value: vendor.friendly_name});
                    }
                })
            });
            return set;
        },
        uniqueWells: function () {
            return this.getUniqueData('wellNumber', 'wellName').map(pair => {
                return  {id: `${pair.id}`, value: pair.value};
            });
        },
        uniqueStages: function () {
            const set = {};
            this.eventData.forEach((target) => {
                if (target.wellNumber != undefined || target.wellNumber == 0) {
                    const longName = target.wellName;
                    //Ignore any null indexed options
                    if(longName == undefined || longName == '' || longName == null){
                        return;
                    }
                    if (!(longName in set)) {
                        set[longName] = {};
                    }

                    if((target.stageNumber || target.stageNumber == 0) && !(target.stageNumber in set[longName])) {
                        set[longName][target.stageNumber] = false;
                    }
                }
            });
            return this.sortByKeys(set);
        },
        uniqueMetaFields(){
            //Check all of the current packets for appropriate meta fields
            //Recreate the meta set
            if(this.nptOnlyMode){
                //Go through each packet (they are only npt packets and there shouldn't be enough of them that its prohibitive to parse)
                let newMeta = [];
                let usedTags = [];
                this.activeDataSet.forEach((target) => {
                    if(target["nptMetaValues"] != undefined && Array.isArray(target["nptMetaValues"])){
                        target["nptMetaValues"].forEach((targetMetaField) => {
                            if(!usedTags.includes(targetMetaField['subCategory'])){
                                newMeta.push({
                                    key: targetMetaField['subCategory'],
                                    label: targetMetaField['friendlyName'],
                                    sortable: true,
                                    disableHeaderClick: true,
                                    formatter: (value, key, item) => {
                                        //TO DO : Currently only supporting numbers and timestamps of the format "aa:bb:cc:dd:..."
                                        //if we want handling for other types they will need to be added here
                                        let displayValue = '-';

                                        //Check there is a value for this field
                                        if(value == undefined){
                                            return displayValue;
                                        }

                                        //Check if this field is a timeticker timestamp
                                        if(value.includes(':')){
                                            //This is a timeticker timestamp
                                            return value;
                                        }else{
                                            displayValue = parseFloat(value);
                                            if(displayValue === NaN){
                                                console.log("Type not supported : " + (typeof value));
                                                return 'unsupported'
                                            }else{
                                                //TO DO: Confirm prefered precision of values
                                                displayValue = displayValue.toFixed(3);
                                            }
                                        }

                                        return isTruthy(displayValue) ? displayValue : '-';
                                    }
                                });
                            }
                            usedTags.push(targetMetaField['subCategory']);
                        });
                    }
                    if(target.data.comment != undefined && !newMeta.some(meta => meta.key == 'comment')){
                        newMeta.push({
                            key: 'comment',
                            label: 'Comment',
                            sortable: false,
                            disableHeaderClick: true,
                        });
                    }
                });
                this.metaFields = newMeta;
            }
            this.setFields();
            return this.metaFields;
        },
        fieldsToShow() {
            if(this.metaFields.length > 0 && this.nptOnlyMode){
                let newFields = [];
                newFields = newFields.concat(this.fields);
                newFields = newFields.concat(this.metaFields);
                return newFields;
            }else{
                return this.fields;
            }
        },
        exportFields() {
            const fieldsCopy = _.cloneDeep(this.fieldsToShow);
            const formatter = (value, key, item) => {
                return value != null ? value : '-';
            };

            const durationIndex = fieldsCopy.findIndex(f => f.label === 'Duration');
            fieldsCopy[durationIndex].label = 'Duration (sec)';
            fieldsCopy[durationIndex].formatter = formatter;

            fieldsCopy.splice(durationIndex, 0, {
                key: 'durationMinutes',
                label: 'Duration (min)',
                formatter
            });
            //check if key comment exits in fieldsCopy and if so replace it with data.comment
            const commentIndex = fieldsCopy.findIndex(f => f.key === 'comment');
            if(commentIndex > -1){
                fieldsCopy[commentIndex].key = 'data.comment';
            }

            return fieldsCopy;
        },
        filtersActive() {
            return (Object.values(this.dynamicFilters).some(filter => {
                    return this.filters[filter.name].length > 0;
                }) || Object.values(this.includeUncoded).some(u =>  {
                    return u;
                }) || (Object.keys(this.filters.stages).some(key => {
                    return Object.values(this.filters.stages[key]).some(opt => {
                        return opt;
                    });
                })) || !!this.filters.startTime || !!this.filters.endTime
            );
        },
        editableColumns() {
            let editableColumnList = [];
            this.displayFields.forEach((entry) => {
                if(!this.ignoreColumnList.includes(entry.key)){
                    editableColumnList.push(entry.key);
                }
            });
            return(editableColumnList);
        }
    },
    watch: {
        searchFilter() {
            this.currentPage = 1;
        }
    },
    mounted() {
        this.initializeSignalRConnection(this.sessionId, this.signalREventHandler, this.signalrConnectedHandler, this.signalrConnectionFailedHandler);
        this.copiedUserPriorityHandlingConfig = this.userPriorityHandling;
        this.jsonLocalStorage.key = `timekeeper-${this.jobNumber}`;
        const config = this.jsonLocalStorage.load(this.jsonLocalStorage.key)?.config ?? this.tableConfig;
        this.jsonLocalStorage.config = {...config, rowsPerPage: parseInt(config?.rowsPerPage ?? 100)};
        this.tableConfig = {...config, rowsPerPage: parseInt(config?.rowsPerPage ?? 100)};
        this.setdateMinMax();
        this.setupFilters();
        this.setFields();

        this.filters.stages = _.cloneDeep(this.stagesByWellDefaultBooleans);
        const urlParameters = window.location.search;
        if (urlParameters) {
            this.setupSharedFilters(urlParameters);
        }
        if (this.nptReport){
            this.nptOnlyFilter(true);
        }else{
            this.filteredEventData = this.eventData;
        }
        this.setSumValues();
        this.preformatOnNextTick();
    },
    beforeDestroy() {
        if (this.nptReport && this.signalRConnected){
            this.endSignalRConnection();
        }
    },
};
</script>

<style scoped>
    #per-page {
        width: 100px;
        text-align: right;
        border-radius: 5px;
        background: #343A40;
        border: 1px solid #626770;
        color: var(--primary-text-color);
    }

    .config-container {
        display: flex;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        justify-content: space-between;
    }

    .config-header {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: #7B8A98;
        margin-bottom: 14px;
    }

    .config-toggle-columns {
        padding: 10px;
        border-radius: 5px;
        border: 1px solid #626770;
    }

    .pagination-page {
        width: 40px;
        height: 40px;
        text-align: center;
        padding: 0px Im !important;
        margin: 0px !important;
        background: #242A30;
    }
</style>

<style>
    #main-table tfoot {
        bottom: 0px !important;
        position: sticky !important;
        z-index: 4;
    }

    #pagination > li, #pagination > li > span, #pagination > li > button {
        border: 0;
        padding: 0;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        border-style: solid;
        color: #ffffff;
        height: 40px !important;
        background: #242A30;
        border-color: #6B7380 !important;
    }

    #pagination > li:first-child > * {
        width: 118px;
        border-width: 1px;
        border-radius: 8px 0px 0px 8px;
    }

    #pagination > li:first-child > *, #pagination > li:last-child > * {
        width: 100%;
        display: flex;
        padding: 8px 12px;
    }

    #pagination > li[role="separator"] > span {
        padding: 8px 12px;
    }

    #pagination > li > button, #pagination > li > span {
        width: 40px;
        border-width: 1px;
    }

    #pagination > li:last-child > * {
        width: 92px;
        border: 1px solid #6B7380;
        border-radius: 0px 8px 8px 0px;
    }

    #pagination > li.active > button {
        background: #004DBF;
    }

    #pagination > li.disabled {
        cursor: not-allowed;
    }

    #pad-info > h4 > span {
        display: flex;
        height: 44px;
        font-size: 16px;
        font-weight: 400;
        width: max-content;
        background: #343A40;
        align-items: center;
        margin: 0px !important;
        border: 1px solid #676E78;
    }

    #pad-info {
        margin-top: 12px;
    }

    #pad-info > h4 > span > div > li > div {
        top: unset !important;
        max-height: 360px;
        background: #343A40;
        border: 0.5px solid #D0D5DD;
        box-shadow: 0px 12px 16px rgb(16 24 40 / 15%);
        border-radius: 4px;
        padding: 0px 5px;
    }

    #pad-info > h4 > span > div > li > div > li {
        border-bottom: 1px solid #7B8A98;
    }

    .priority-popover > .popover-body, .priority-popover > .popover-header {
        padding: 1px !important;
        max-width: 700px !important;
    }

    #TimeKeeperComponent #pagination-control {
        bottom: 38px !important;
    }
    </style>
