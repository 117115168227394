<template>
<span>
    <template v-if="newUi">
        <!--TODO: find better width css for this that sizes to the content-->
        <iws-select style="max-width: 500px;  margin: 0px"
            :value.sync="selectedJob"
            :options="filteredJobs"
            display-name="jobOptionDisplay"
            value-name="jobNumber"
            :placeholder="!!selectedJob ? `${selectedJob.jobNumber} - ${jobMainTitle(selectedJob)}` : null"
            @change="$emit('jobSelected', $event)"
        />
    </template>
    <template v-else>
        <h4 style="display: inline;">
            <div class="badge badge-secondary ml-2">
                <li class="nav-item dropdown" text="Right align">
                    <span v-if="selectedJob" data-toggle="dropdown" role="button">
                        {{ jobNumber }} - {{ jobMainTitle(selectedJob) }} {{iwsUser && jobSystem ? ` - (${jobSystem})` : ''}}
                    </span>
                    <span v-else data-toggle="dropdown" role="button">
                        {{ jobNumber }} - Not Active
                    </span>

                    <div class="dropdown-menu bg-light">
                        <li v-for="job in filteredJobs" class="nav-item dashboard_navbar_item" :key="job.id">
                            <a class="nav-link text-white" type="button" @click="$emit('jobSelected', job.jobNumber)">
                                {{ job.jobOptionDisplay }}
                            </a>
                        </li>
                    </div>
                </li>
            </div>
        </h4>
    </template>
</span>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isNullOrEmpty } = GlobalFunctions;

import JobMixin from '../mixins/JobMixin.js';

export default {
    props: {
        jobNumber: {
            type: String,
            required: true
        },
        companyJobs: {
            type: Array
        },
        newUi: {
            type: Boolean,
            default: false
        },
        iwsUser: {
            type: Boolean,
            required: false,
            default: false
        },
        jobSystem: {
            type: String,
            required: false,
            default: null
        }
    },
    mixins: [JobMixin],
    data() {
        return {
            selectedJob: this.companyJobs.find((job) => {
                return job.jobNumber === this.jobNumber;
            })
        };
    },
    computed: {
        filteredJobs() {
            if (isNullOrEmpty(this.companyJobs))
                return [];
            return this.companyJobs
                .filter(job => !job.jobNumber || !job.jobNumber.includes('TS'))
                .map(job => {
                    let jobOptionDisplay = `${job.jobNumber} - ${this.jobMainTitle(job)}`;
                        if (this.iwsUser && job?.system) {
                            jobOptionDisplay += ` - (${job.system})`
                        }
                    return {
                        ...job,
                        jobOptionDisplay
                    }
                });
        }
    },
    watch: {
        selectedJob: {
            handler(newVal, oldVal) {
                const urlJobNumberRegex = /([a-zA-Z]{2,3}\d+)/;
                const jobNumberInURL = window.location.href.match(urlJobNumberRegex);
                if (jobNumberInURL[1]) {
                    const jobNumberURL = jobNumberInURL[1];
                    if (jobNumberURL !== newVal) {
                        this.selectedJob = jobNumberURL;
                    }
                }
            }
        }
    },
    methods: {
        getFracFleetVendorName: function(job) {
            if(job.frac_configs && job.frac_configs[0]) {
                let fracConfig = job.frac_configs[0];
                if(fracConfig.vendor_fleet) {
                    return fracConfig.vendor_fleet.name + ' - ' + job.location;
                }
            }

            return job.location;
        },
    },
    emits: ['jobSelected']
};
</script>

<style scoped>
    .dropdown-menu {
        overflow-y: auto;
        max-height: 300px;
        left: -7px !important;
    }
</style>
