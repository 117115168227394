<!--
    * Component Description
    A component intended to display the list of all dashboards to IWS users, whether
    they are shared or not.
-->

<template>
    <div>
        <div v-if='isLoading' class="text-center m-5" role="status">
            <b-spinner label="Loading..."></b-spinner>
        </div>
        <b-tabs ref="tabs" v-else v-model="currentTab" align="right" content-class="mt-4"  active-nav-item-class="border-light bg-transparent active-tab-bottom">
            <!-- All Dashboards -->
            <b-tab title="All Dashboards" :disabled="disableTab" title-link-class="text-white">
                <div class="mb-4 search-bar">
                    <b-input-group>
                        <b-form-input
                        id="filter-input"
                        v-model="filter"
                        type="search"
                        placeholder="Filter dashboards..."
                        ></b-form-input>

                        <b-input-group-append>
                        <b-button :disabled="!filter" @click="getDashboards()">Search</b-button>
                        </b-input-group-append>
                    </b-input-group>
                </div>

                <b-table
                    small striped hover dark
                    responsive="sm"
                    ref="dashboards-table-1"
                    :per-page="perPage"
                    :tbody-tr-class="selectedItemClass"
                    :items="dashboards"
                    :fields="dashboardFields" show-empty>

                    <!-- A custom formatted column -->
                    <template #cell(name)="data">
                        <a href='#' v-on:click="setSelectedDashboard(data.item.id, currentPage, true); $emit('close');">
                            <b :class="{ 'text-info' : data.item.id!=selectedDashboard.id, 'text-white': data.item.id==selectedDashboard.id }">
                                {{ data.item.name }}
                            </b>
                        </a>
                    </template>

                    <template #cell(edit)="data">
                        <i class="fas fa-trash clickable" v-on:click="deleteDashboard(data.item)"></i>
                    </template>
                    
                </b-table>
                
                <div class="d-flex justify-content-between" v-if='dashboards.length > 0'>
                    <div>
                        <b-button v-if="lastPage > 1" v-on:click="goToPage(1)"
                                variant="primary" size="sm" :disabled="currentPage == 1">First</b-button>
                        <b-button v-if="currentPage > 1"
                                v-on:click="previousPage" variant="primary" size="sm">Previous</b-button>
                    </div>
                    <p>{{currentPage}} / {{lastPage}}</p>
                    <div>
                        <b-button v-if="lastPage != currentPage && currentPage < lastPage"
                                v-on:click="nextPage" variant="primary" size="sm">Next</b-button>
                        <b-button v-if="lastPage > 1" v-on:click="goToPage(lastPage)" 
                                variant="primary" size="sm" :disabled="currentPage == lastPage">Last</b-button>
                    </div>
                </div>
                <div class="float-right">
                    <b-button class="mt-2 modal-default-button" variant="secondary"
                            @click="closeModal">Close
                    </b-button>
                </div>
            </b-tab>
            <!-- Fleet Defaults -->
            <b-tab title="Manage Fleet Defaults" :disabled="disableTab" title-link-class="text-white">
                <div class="mb-4">
                    <div class="d-flex mb-2">
                        <div class="form-group has-validation mr-3">
                            <label class="form-label" for="fleet">Fleet:</label>
                            <select v-model="selectedFleet" @change="fetchFleetDashboard" class="form-control" id="fleet" required>
                                <option :value="null" disabled selected>Select fleet</option>
                                <option v-for="fleet in fleets" :value="fleet.id" :key="fleet.id">{{ fleet.name }} - {{ fleet.vendor_name }}</option>
                            </select>
                        </div>
                        <div class="form-group has-validation">
                            <label class="form-label" for="job-type">Job Type:</label>
                            <select v-model="selectedJobType" @change="fetchFleetDashboard" class="form-control" id="job-type" required>
                                <option value="" disabled selected>Select job type</option>
                                <option value="FS">Standard Frac</option>
                                <option value="CF">Continuous Frac</option>
                                <option value="MF">Multifrac</option>
                                <option value="SF">Simulfrac</option>
                                <option value="TF">Trimulfrac</option>
                                <option value="CM">Camera Module</option>
                            </select>
                        </div>
                        <div v-if="fracFleetError" class="mx-3 my-3" style="text-align: center;" @click="openSupportModal()">
                            <div class="warning-text">WARNING : No frac fleet assigned to currently selected Job.</div>
                            <a class="text-white show-clicker-finger"><b>Please click here to contact support.</b></a>
                        </div>
                    </div>
                    <b-table
                        small striped hover dark
                        responsive="sm"
                        ref="fleet-table"
                        :items="fleetDashboards"
                        :sticky-header="tableHeight"
                        :fields="fleetDashboardFields" show-empty>

                        <template #cell(remove)="data">
                            <i class="fas fa-minus-circle clickable" @click="removeFromFleet(data.item)" v-tooltip:bottom="'Remove from fleet'"></i>
                        </template>

                        <template #cell(order)="data">
                            <i v-if="fleetDashboards.indexOf(data.item) > 0"
                            class="fas fa-arrow-up clickable" v-on:click="moveUp(data.item, fleetDashboards, 'fleet-table')"></i>
                            <i v-else class="fas fa-arrow-up disabled-icon"></i>
                            
                            <i v-if="fleetDashboards.indexOf(data.item) < fleetDashboards.length - 1"
                            class="fas fa-arrow-down clickable" v-on:click="moveDown(data.item, fleetDashboards, 'fleet-table')"></i>
                            <i v-else class="fas fa-arrow-down disabled-icon"></i>
                        </template>
                    </b-table>
                </div>
                <div>
                    <h4>All Dashboards</h4>
                    <div class="mb-4 search-bar">
                        <b-input-group>
                            <b-form-input
                            id="filter-input"
                            v-model="filter"
                            type="search"
                            placeholder="Filter dashboards..."
                            ></b-form-input>

                            <b-input-group-append>
                            <b-button :disabled="!filter" @click="getDashboards()">Search</b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </div>
                    <b-table
                        small striped hover dark
                        responsive="sm"
                        :per-page="dashboardsPerPage"
                        :sticky-header="tableHeight"
                        ref="dashboards-table-2"
                        :items="filteredDashboards"
                        :fields="dashboardFields" show-empty>

                        <template #empty="scope">
                            <p class="text-center">{{ dashboards.length > 0 ? 'All available dashboards for the current page have been assigned to the selected fleet' : scope.emptyText }}</p>
                        </template>

                        <template #cell(add)="data">
                            <i v-if="data.item.shared_with == 'All users'" class="fas fa-plus-circle clickable" @click="addToFleet(data.item)" v-tooltip:bottom="'Add to fleet'"></i>
                        </template>
                    </b-table>
                    <div class="d-flex justify-content-between" v-if='dashboards.length > 0'>
                        <div>
                            <b-button v-if="lastPage > 1" v-on:click="goToPage(1)"
                                    variant="primary" size="sm" :disabled="currentPage == 1">First</b-button>
                            <b-button v-if="currentPage > 1"
                                    v-on:click="previousPage" variant="primary" size="sm">Previous</b-button>
                        </div>
                        <p>{{currentPage}} / {{lastPage}}</p>
                        <div>
                            <b-button v-if="lastPage != currentPage && currentPage < lastPage"
                                    v-on:click="nextPage" variant="primary" size="sm">Next</b-button>
                            <b-button v-if="lastPage > 1" v-on:click="goToPage(lastPage)" 
                                    variant="primary" size="sm" :disabled="currentPage == lastPage">Last</b-button>
                        </div>
                    </div>
                    <div class="float-right mt-2">
                        <b-button v-if="dashboardsOrderChanged"
                                class="modal-default-button mr-2"
                                variant="danger"
                                @click="$emit('close')">Discard</b-button>
                        <b-button class="modal-default-button"
                                @click="updateFleetDashboards"
                                :variant="dashboardsOrderChanged ? 'success' : 'primary'">
                            {{dashboardsOrderChanged ? 'Save' : 'Close'}}</b-button>
                    </div>
                </div>
            </b-tab>
            <!-- Customer Defaults -->
            <b-tab title="Customer Defaults" :disabled="disableTab"  title-link-class="text-white">
                <div v-if="dashboards && dashboards.length < 1">
                    <div class="my-auto p-1">No customer default dashboards available</div>
                </div>
                <div v-else>
                    <b-table style="max-height: 60vh; overflow-y: auto;"
                        small striped hover dark
                        responsive="sm"
                        ref="customer-table"
                        :items="dashboards"
                        :fields="dashboardFields">

                        <template #cell(order)="data">
                            <div v-if="data.item.is_customer_default">
                                <i v-if="dashboards.indexOf(data.item) > 0"
                                class="fas fa-arrow-up clickable" v-on:click="moveUp(data.item, dashboards, 'customer-table')"></i>
                                <i v-else class="fas fa-arrow-up disabled-icon"></i>
                                
                                <i v-if="dashboards.indexOf(data.item) < defaultDashboardsCount()-1"
                                class="fas fa-arrow-down clickable" v-on:click="moveDown(data.item, dashboards, 'customer-table')"></i>
                                <i v-else class="fas fa-arrow-down disabled-icon"></i>
                            </div>
                        </template>
                    </b-table>
                </div>
                
                <div class="float-right mt-2">
                    <b-button v-if="dashboardsOrderChanged"
                            class="modal-default-button mr-2"
                            variant="danger"
                            @click="$emit('close')">Discard</b-button>
                    <b-button class="modal-default-button"
                            @click="updateDashboardsOrder()"
                            :variant="dashboardsOrderChanged ? 'success' : 'primary'">
                        {{dashboardsOrderChanged ? 'Save' : 'Close'}}</b-button>
                </div>
            </b-tab>
        </b-tabs>
    </div>
</template>


<style scoped>
.search-bar {
    width: 400px;
    min-width: 200px;
}
.clickable { 
    border-width: 0px;
    cursor: pointer;
    color: #eeeeee;
}    

.clickable:hover {
    color: #59acff;
}

.disabled-icon { 
    color: #666;
}

.popover {
    border-width: 0px;
    z-index: 10000;
    max-width: 50vw !important;
    
    background-color: rgba(0,0,0,0);
}

select {
    min-width: 200px;
}

.warning-text {
    color: red;
}
</style>

<style>
.active-tab-bottom {
    border-bottom: 2px solid #1E1E1E !important;
}
</style>

<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';

const TOP_TH_STYLE = { top: '-1px'};

export default {
    props: {
        job: {
            type: [Object],
            required: true
        },
        availableDisplayItems: {
            type: Array,
            required: true
        },
        orderedDashboardIds: {
            type: Array,
            required: true
        },
        fleetDashboardIds: {
            type: Array,
            required: true
        },
        setSelectedDashboard: {
            type: Function,
            required: true
        },
        reorderDashboards: {
            type: Function,
            required: true
        },
        selectedDashboard: {
            type: Object
        },
        initDashboardManagementPage: {
            type: Number
        }
    },
    data() {
        return {
            filter: '',
            currentTab: 0,
            dashboards: [],
            disableTab: false,
            selectedFleet: null,
            selectedJobType: '',
            tableHeight: '20vh',
            dashboardsOrderChanged: false,
            currentPage: 1,
            lastPage: 1,
            perPage: null,
            dashboardsPerPage: null,
            totalDashboards: 0,
            isLoading: false,
            fleets: [],
            fleetDashboards: [],
            removedDashboards: [],
            fracFleetError: false,
            fleetDashboardFields: [
                {
                    key: 'remove',
                    label: '',
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'order',
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'name',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'shared_with',
                    sortable: false
                },
                {
                    key: 'owner_username',
                    label: 'Owner',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'created_at',
                    sortable: false,
                    thStyle: TOP_TH_STYLE,
                    label: 'Created At (in Local Job Time)',
                    formatter: (value, key, item) => {
                        return this.formatDatetime(value);
                    }
                },
                {
                    key: 'items_length',
                    label: 'Layout',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                }
            ],
            dashboardFields: [
                {
                    key: 'name',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'shared_with',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'owner_username',
                    label: 'Owner',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'created_at',
                    sortable: false,
                    thStyle: TOP_TH_STYLE,
                    label: 'Created At (in Local Job Time)',
                    formatter: (value, key, item) => {
                        return this.formatDatetime(value);
                    }
                },
                {
                    key: 'items_length',
                    label: 'Layout',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                },
                {
                    key: 'edit',
                    label: 'Edit',
                    sortable: false,
                    thStyle: TOP_TH_STYLE
                }
            ]
        };
    },
    mounted() {
        if(this.initDashboardManagementPage!==1) {
            this.currentPage = this.initDashboardManagementPage;
        }

        this.selectedJobType = this.job.jobNumber.slice(0, 2);

        this.getFleets();
        this.getDashboards();
    },
    methods: {
        formatDatetime(datetime) {
            return datetime != null ? moment.utc(datetime)
                                            .add(this.job.hourOffset, 'hours')
                                            .format('YYYY-MM-DD HH:mm:ss') : '-';
        },
        openSupportModal: function() {
            GlobalFunctions.openSupportModal();
        },
        moveUp: function(item, data, ref) {
            const item_index = data.indexOf(item);

            data[item_index] = data[item_index-1];
            data[item_index-1] = item;

            this.dashboardsOrderChanged = true;
            this.$refs[ref].refresh();
        },
        moveDown: function(item, data, ref) {
            const item_index = data.indexOf(item);

            data[item_index] = data[item_index+1];
            data[item_index+1] = item;

            this.dashboardsOrderChanged = true;
            this.$refs[ref].refresh();
        },
        addToFleet(item) {
            const index = this.removedDashboards.indexOf(item.id);

            this.fleetDashboards.push(item);
            this.dashboardsOrderChanged = true;
            if (index > -1) {
                this.removedDashboards.splice(index, 1);
            }
        },
        removeFromFleet(item) {
            const index = this.fleetDashboards.findIndex(d => d.id === item.id);
            const dashboardsIndex = this.dashboards.findIndex(d => d.id === item.id);

            this.removedDashboards.push(item.id);
            this.dashboardsOrderChanged = true;
            if (index > -1) {
                this.fleetDashboards.splice(index, 1);
            }
            // item doesn't exist in current page
            if (dashboardsIndex < 0) {
                ++this.dashboardsPerPage;
                this.dashboards.push(item);
            }
        },
        selectedItemClass: function(item, type) {
            if (item?.id == this.selectedDashboard.id) {
                return 'bg-primary';
            } else {
                return;
            }
        },
        nextPage: function() {
            if(this.currentPage < this.lastPage) {
                this.currentPage++;
                this.getDashboards();
            }
        },
        previousPage: function() {
            if(this.currentPage > 1) {
                this.currentPage--;
                this.getDashboards();
            }
        },
        goToPage: function(page) {
            this.currentPage = page;
            this.getDashboards();
        },
        currentLayout: function() {
            return this.dashboards[0].layout;
        },
        currentItems: function() {
            return this.dashboards[0].items;
        },
        defaultDashboardsCount: function() {
            return this.dashboards.filter(d => d.is_customer_default).length;
        },
        closeModal: function() {
            this.$emit('close');
            location.reload();
        },
        fillDashboardsTable: function(dashboards) {
            return dashboards.map(d => {
                if (d.is_user_shared && d?.users) {
                    d.shared_with = d.users.length + ' user' + (d.users.length > 1 ? 's' : '');
                } else if(d.is_customer_default) {
                    d.shared_with = 'All users';
                } else if(d.is_role_shared) {
                    d.shared_with = d.roles.length + ' role' + (d.roles.length > 1 ? 's' : '');
                } else {
                    d.shared_with = 'No one';
                }

                d.owner_username = d.owner ? d.owner.username: null;

                d.items_length = d.items.length + ' items';

                return d;
            });
        },
        getDashboards: function() {
            let url;
            if ((this.dashboards.length - 1) === 0 && (this.currentPage === this.lastPage)) {
                this.currentPage = Math.max(this.currentPage - 1, 1);
            }
            
            if (!!this.filter) {
                url = `/dashboards/search/${this.job.jobNumber}?filter=${this.filter}&page=${this.currentPage}`;
            } else if (this.onCustomerDefaultTab) {
                url = `/dashboards/list-all-customer-defaults/${this.job.jobNumber}`;
            } else {
                url = `/dashboards/list-all/${this.job.jobNumber}?page=${this.currentPage}`;   
            }

            const self = this;
            this.isLoading = true;

            $.get(url,
                {},
                function(data) {
                    if(Object.keys(data).length < 1 && !self.onCustomerDefaultTab) {
                        console.error('Malformed data: ' + data);
                        return;
                    }

                    if (!self.onCustomerDefaultTab) {
                        self.dashboards = data.data;
                        self.perPage = data.per_page;
                        self.lastPage = data.last_page;
                        self.totalDashboards = data.total;
                        self.currentPage = data.current_page;
                        self.dashboardsPerPage = data.per_page;
                    } else {
                        self.dashboards = data;
                    }

                    if (self.fleetDashboardIds.length) {
                        self.dashboards = self.reorderDashboards(self.dashboards, self.fleetDashboardIds);
                    } else if (self.orderedDashboardIds.length) {
                        self.dashboards = self.reorderDashboards(self.dashboards, self.orderedDashboardIds);
                    } else {
                        self.dashboards = self.dashboards.sort((a, b) => {
                            return a.is_customer_default && !b.is_customer_default ? -1 : 1;
                        });
                    }
                    
                    self.dashboards = self.fillDashboardsTable(self.dashboards);
                    self.isLoading = false;
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                self.isLoading = false;
                console.warn('Failed to load dashboard list.');
            });
        },
        updateDashboardsOrder: function() {
            if(!this.dashboardsOrderChanged) {
                this.$emit('close');
                return ;
            }
            const url = '/dashboards/order/';
            const self = this;
            
            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    customer_id: self.selectedDashboard.customer_id,
                    ordered_dashboard_ids: self.dashboards
                        .filter(d => d.is_customer_default)
                        .map(d => d.id)
                },
                dataType: 'json'
            }).done(() => {
                self.$emit('close');
                location.reload();
            }).fail((msg) => { 
                console.warn('Failed to update dashboard order:' + JSON.stringify(msg));
                alert('Something went wrong saving your changes! Please contact an administrator.');
            }) .always((msg) => {
            });
        },
        deleteDashboard: function(dashboard) {
            if(!confirm('Are you sure you want to delete this dashboard?')) {
                return ;
            }
            
            const url = '/dashboards/delete/';
            const self = this;

            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    id: dashboard.id
                },
                dataType: 'json'
            }).done(() => {
                self.getDashboards();
            }).fail((msg) => {
                alert('Failed to delete dashboard' + JSON.stringify(msg));
            });
        },
        getFleets() {
            const self = this;
            this.isLoading = true;
            $.get(
                `/customer-fleet/${self.job.jobNumber}?type=frac`,
                {},
                (response) => {
                    if (response?.data?.length > 0) {
                        self.fleets = response.data;
                        if(response?.selectedFleet?.id != null){
                            self.selectedFleet = response.selectedFleet.fleet_id;
                            self.fracFleetError = false;
                        }else{
                            self.fracFleetError = true;
                        }
                    }else{
                        self.fracFleetError = true;
                    }
                },
                'json'
            ).fail(() => {
                console.warn('Failed to load fleet list.');
            }).always(() => {
                self.isLoading = false;
            });
        },
        fetchFleetDashboard() {
            if (this.selectedFleet && this.selectedDashboard?.customer_id) {
                const self = this;
                this.isLoading = true;
                $.get(
                    `/dashboards/list-all-fleet-dashboards/${self.selectedFleet}/${self.selectedJobType}/${self.selectedDashboard.customer_id}`,
                    {},
                    (fleetDashboards) => {
                        if (fleetDashboards?.length) {
                            self.fleetDashboards = self.fleetDashboardIds.length ? self.reorderDashboards(fleetDashboards, self.fleetDashboardIds) : fleetDashboards;
                            self.fleetDashboards = self.fillDashboardsTable(self.fleetDashboards);
                        } else {
                            self.fleetDashboards = [];
                        }
                    }
                ).fail(() => {
                    console.warn('Failed to load fleet dashboards.');
                }).always(() => {
                    self.isLoading = false;
                });
            }
        },
        updateFleetDashboards() {
            if (this.selectedFleet && (this.removedDashboards.length > 0 || this.fleetDashboards.length > 0)) {
                const self = this;
                this.isLoading = true;
                const data = {
                    fleetId: this.selectedFleet,
                    jobType: this.selectedJobType,
                    removedDashboardIds: this.removedDashboards,
                    customerId: self.selectedDashboard.customer_id,
                    dashboardIds: this.fleetDashboards.map((d) => {
                        return d.id;
                    })
                };
                $.ajax({
                    method: 'PUT',
                    url: '/dashboards/update-fleet-dashboards/',
                    data: {
                        ...data,
                        _token: $('meta[name="csrf-token"]').attr('content')
                    },
                    dataType: 'json'
                }).done((res)=> {
                    self.$emit('close');
                    location.reload();
                }).fail((msg) => { 
                    console.warn('Failed to update fleet dashboards:' + JSON.stringify(msg));
                    alert('Something went wrong saving your changes! Please contact an administrator.');
                }).always(() => {
                    self.isLoading = false;
                });
            } else if (!this.selectedFleet && this.fleetDashboards.length > 0) {
                GlobalFunctions.alertModal({
                    title: 'Select a fleet',
                    body: 'The selected dashboards need to be assigned to a fleet.',
                    variant: 'danger'
                });
            } else {
                this.$emit('close');
            }
        },
        removeField(field) {
            const index = this.dashboardFields.findIndex(fields => fields.key === field);
            if (index > -1) {
                this.dashboardFields.splice(index, 1);
            }
        }
    },
    computed: {
        onCustomerDefaultTab() {
            return this.currentTab == 2;
        },
        filteredDashboards() {
            return this.dashboards.filter((dashboard) => {
                return !(this.fleetDashboards.find((fleetDashboard) => {
                    return fleetDashboard.id === dashboard.id;
                }));
            });
        }
    },
    watch: {
        currentTab(newVal, oldVal) {
            switch (newVal) {
            case 0:
                this.filter = '';
                this.currentPage = 1;
                this.getDashboards();
                this.removeField('add');
                this.removeField('order');
                this.dashboardFields.push({key: 'edit', label: 'Edit', sortable: 'false', thStyle: TOP_TH_STYLE});
                break;
            case 1:
                this.filter = '';
                this.currentPage = 1;
                this.getDashboards();
                this.removeField('edit');
                this.removeField('order');
                this.fetchFleetDashboard();
                this.dashboardsOrderChanged = false;
                this.dashboardFields.unshift({key: 'add', label: '', thStyle: TOP_TH_STYLE});
                break;
            case 2:
                this.getDashboards();
                this.removeField('add');
                this.removeField('edit');
                this.dashboardsOrderChanged = false;
                this.dashboardFields.unshift({key: 'order', thStyle: TOP_TH_STYLE});
                break;
            default:
                break;
            }
        },
        filter(newVal, oldVal) {
            if (oldVal?.length > 0 && newVal?.length === 0) {
                this.getDashboards();
            }
        }
    }
};

</script>
