<template>
  <div>
    <canvas :id="graphId"></canvas>
  </div>
</template>

<script>
  import Chart from 'chart.js';
  
  export default {
    props:['values','names','maxPoints'],//values is an array of plot data arrays, names is an array of plot names
    data() {
      return {
        options:{
          responsive: true,
          animation: false,
          scales:{
            xAxes:[
              {
                display:false
              }
            ]
          }
        },
        datacollection:{
          labels:[],
          datasets:[]
        },
        colors:[
          'rgba(57,106,177,1)',
          'rgba(218,124,48,1)',
          'rgba(62,150,81,1)',
          'rgba(204,37,41,1)',
          'rgba(83,81,84,1)',
          'rgba(107,76,154,1)',
          'rgba(146,36,40,1)',
          'rgba(148,139,61,1)'
        ],
        graphInitialized: false,
        graphRef:{},
        graphId:''
      }
    },
    watch:{
      values: function(val){
        //console.log("Graph with no buffer sees a value change");
        this.updateGraph();
      }
    },
    created(){
      this.graphId = 'graph'+parseInt(Math.random() * 100000);
    },
    mounted(){
      this.initGraph();
      //setInterval(this.updateGraph,500);
    },
    methods:{
      initGraph(){
        var id = this.graphId;
        var ctx = document.getElementById(id).getContext('2d');
        this.graphRef = new Chart(ctx,{type:'line',data:this.datacollection,options:this.options});
        var self = this;
        self.datacollection.labels = [];
        self.datacollection.datasets = [];
        for(var i=0;i<this.names.length;i++){
          var dataset = {};
          dataset.label = this.names[i];
          dataset.data = [];
          dataset.lineTension = 0;
          dataset.fill = false;
          dataset.borderColor = self.colors[i % self.colors.length];
          dataset.pointRadius = 0;
          dataset.borderWidth = 1;
          this.datacollection.datasets.push(dataset);
        }
        self.graphInitialized = true;
        this.updateGraph();
      },
      updateGraph(){
        var self = this;
        if(self.graphInitialized){
          //console.log("trying to update graph...");
          var max = parseInt(self.maxPoints);
          self.datacollection.labels = this.values[0];
          for(var i=0;i<self.values.length;i++){
            self.datacollection.datasets[i].data = this.values[i];
          }
          this.graphRef.update();
        }
        
      }
    }
  }
</script>