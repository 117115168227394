<template>
    <div>
        <iws-breadcrumbs class="mt-4 mb-1"
            :path="[{
                title: 'Home',
                link: '/home'
            }, {
                title: 'Copy Remote Job',
                link: '/distinctJobs'
            }]"
        />
        <div class="page-title mb-4">
            Select Job to Sync
        </div>
        <b>**** Warning this is a developer tool and may overwrite your data, please use with caution. ****</b>
        <br>
        This is a list of Job Numbers that were found using the Mongo/Cosmos connection string in the .env
        <br>
        If you don't see a Job Number you expect, use the startTime URL parameter with UTC timestamp (milliseconds)

        <ul>
            <li v-for="jobNumber in jobNumbers">
                <a :href="`/createNewFromRemoteJob/${jobNumber}`">
                    {{ jobNumber }}
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: {
        jobNumbers: {
            type: [Array, Object],
            required: true,
            default: () => []
        }
    }
};
</script>