<template>
<base-kpi-template
    ref="baseKpiTemplate"
    title="Metrics Per Stage"
    :item="item"
    :inital-options="initalOptions"
    :edit-mode="editMode"
    :create-export-data="createExportData"
    :analytics-data="analyticsData"
    :errors="errors"
    :show-not-enough-data-warning="showNotEnoughDataWarning"
    :show-chart="showChart"

    @analyticsChanges="buildAnalytics()"
    @settingsChanges="buildData()"
    @revertChanges="buildData()"
>
    <template #settings>
        <template v-if="!_isNullOrEmpty(metadataTags)">
            <iws-select
                :options="[ {displayName: 'None', tagName: -2}, ...metadataTags ]"
                :value.sync="item.options.scatterTag"
                label="Scatter Tag"
                :display-name="tag => tag.displayName || tag.tagName"
                value-name="tagName"
                form-spacing
                @pre-change="$event => clearTagError($event)"
                @change="handleTagChange(item.options.scatterTag, null, 'scatterJSON')"
            />

            <iws-select
                :options="[ {displayName: 'None', tagName: -2}, ...metadataTags ]"
                :value.sync="item.options.barTag"
                label="Bar Tag"
                :display-name="tag => tag.displayName || tag.tagName"
                value-name="tagName"
                form-spacing
                @pre-change="$event => clearTagError($event)"
                @change="handleTagChange(item.options.barTag, null, 'barJSON')"
            />
        </template>
        <div v-else>
            No Customer Tags Found
        </div>

        <div v-if="showLimitStageMetrics" class="stages-limit-container">
            <span class="stages-limit-main">
                <iws-input
                    label="Stages Shown"
                    placeholder="Limit the stages shown"
                    :value.sync="item.options.limitStagesMetricsPerStage"
                    min="0"
                    type="number"
                    form-spacing
                    @input="buildData()"
                />
            </span>

            <span class="stages-limit-actions">
                <iws-button
                    text="Clear"
                    type="dark"
                    @click="clearLimitMetrics"
                />
            </span>
        </div>
        <iws-button v-else
            text="Limit Stages Shown"
            type="dark"
            @click="showLimitStageMetrics = true"
            style="margin-top: 15px"
        />

        <iws-checkbox
            label="Average Wells"
            :value.sync="item.options.averageWellDataMetricsPerStage"
            form-spacing
            enable-click-label
            @change="buildData()"
        />
    </template>

    <template v-if="showChart" #content>
        <bar-chart
            ref="kpi"
            :chart-data="kpi"
            :options="options"
        />
    </template>
</base-kpi-template>
</template>

<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, toast, getCSRFToken } = GlobalFunctions;

import constants from './constants.js';
const { ANALYTICS_COLORS, chartStyle } = constants;

import BaseKpi from './BaseKpi.vue';
import BaseKpiTemplate from './BaseKpiTemplate.vue';
import BarChart from '../../../barChart.js';

export default {
    extends: BaseKpi,
    components: { BaseKpiTemplate, BarChart },

    data() {
        return {
            ...this.BaseKpi({
                xAxisLabel: 'Stage Number',
                yAxisLabel: 'Total Value',
                datasets: null,

                datalabels: {
                    display: false
                },

                tooltip_callback: (tooltipItem, data) => {
                    return `
                        ${data?.datasets[tooltipItem.datasetIndex].label}
                        <br/>

                        ${
                            'wellNumber' in data?.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index]
                            ? `
                                Well Number: ${data?.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index]?.wellNumber+1}
                                <br/>`
                            : ''
                        }
                        Stage Number: ${data?.datasets[tooltipItem?.datasetIndex]?.data[tooltipItem?.index]?.x || tooltipItem.label}
                        <br/>

                        Value: ${tooltipItem.value}
                    `;
                },
                legend: this.tagIsSet(this.item.options.scatterTag) && this.tagIsSet(this.item.options.barTag)
                    ? {
                        display: true,
                        labels: {
                            usePointStyle: true,
                            fontColor: '#CCC'
                        }
                    }
                    : null,
            }),


            showLimitStageMetrics: false,

            metadataTags: null,
            barJSON: [],
            scatterJSON: [],

            // Some tags aren't compatible with this KPI and shouldn't be available
            forbiddenTags: [ 'stage_wellhead_openingTime_wellbore', 'stage_wellhead_closingTime_wellbore' ]
        }
    },

    computed: {
        // Generically named computed properties to handle changes specific to this KPI
        showNotEnoughDataWarning: function() {
            return this.kpi.datasets !== null && !this.showChart;
        },
        showChart: function() {
            return this.allowShowChart && this.kpi?.datasets?.reduce((_sum, a) => _sum + (a?.data?.length || 0), 0) > 0;
        }
    },

    methods: {
        // Genericly named function for BaseKPI.vue to call on mounted
        // This creates the KPI in a unique way to the current files needs
        setDefaults: function() {
            this.$set(this.item.options, 'scatterTag', this.item.options.scatterTag || 'stage_pressure_avgTreatmentZipper1');
            this.$set(this.item.options, 'barTag', this.item.options.barTag || 'stage_rate_avgTreatmentSlurry');
            this.$set(this.item.options, 'averageWellDataMetricsPerStage', this.item.options.averageWellDataMetricsPerStage || false);
            this.$set(this.item.options, 'limitStagesMetricsPerStage', this.item.options.limitStagesMetricsPerStage || null);

            this.showLimitStageMetrics = !isFalsy(this.item.options.limitStagesMetricsPerStage);
        },
        initKPI: function() {
            // Fetch customer tags (for selects and friendly names) and both tags data then render chart
            return Promise.all([
                this.fetchMetadataTags(),
                this.fetchData()
            ]).then(this.buildData);
        },
        fetchData: function() {
            // Fetch the data for both selected tags
            return Promise.all([
                this.fetchMetricsByTag(this.item.options.scatterTag, 'scatterJSON'),
                this.fetchMetricsByTag(this.item.options.barTag, 'barJSON')
            ]).then(_data => {
                this.scatterJSON = _data[0];
                this.barJSON = _data[1];
            });
        },
        buildData: function() {
            this.kpi.labels = [];
            this.options.scales.yAxes = [];
            let _datasets = [];

            const scatterName = this.findTagName(this.item.options.scatterTag);
            const barName = this.findTagName(this.item.options.barTag);

            const stagesList = new Set([ ...this.scatterJSON.map(_data => _data.stageNumber), ...this.barJSON.map(_data => _data.stageNumber)]);
            const wellsList = new Set([ ...this.scatterJSON.map(_data => _data.wellNumber), ...this.barJSON.map(_data => _data.wellNumber)]);

            const stageCount = Math.max(...stagesList)+1
            const wellCount = Math.max(...wellsList)+1;
            const lowestStage = isFalsy(this.item.options.limitStagesMetricsPerStage) || this.item.options.limitStagesMetricsPerStage < 1 
                ? 1 
                : Math.max(stageCount - this.item.options.limitStagesMetricsPerStage, 1);

            const prepareDataSets = (tagName, list, ogDataset, ogOptions, graphType) => {
                if (isNullOrEmpty(list))
                    return [];

                let min = null;
                let max = null;

                let datasets = [];

                if (this.item.options.averageWellDataMetricsPerStage) {
                    const colours = [ chartStyle.primaryLineGraphColour, chartStyle.primaryBarGraphColour ];
                    const groupedByStage = {};

                    // Group all values by matching stage numbers
                    list.forEach(_data => {
                        if (_data.stageNumber < lowestStage)
                            return;

                        // If new stage Number, default to []
                        if (!(`${_data.stageNumber}` in groupedByStage))
                            groupedByStage[_data.stageNumber] = [];

                        groupedByStage[_data.stageNumber].push(_data.value);
                    });

                    // Average all groupings then add the average mapped by the stage number
                    Object.keys(groupedByStage).forEach(_stageListKey => {
                        let x = _stageListKey;
                        let y = this.friendlyMetric(tagName, this.getAverage(groupedByStage[_stageListKey]));

                        // Track min and max of each graph to avoid overlap between chart types
                        if (min === null || y < min)
                            min = y;
                        if (max === null || y > max)
                            max = y;

                        ogDataset.data.push({ x, y });
                    })

                    // Only one dataset but return within a list to easily merge with the other graph type
                    datasets.push({
                        ...ogDataset,
                        backgroundColor: colours[graphType],
                        borderColor: colours[graphType],
                        yAxisID: `right-axis-${graphType}`
                    });
                } else {
                    // Create a local dataset per well
                    for (let i = 0; i < wellCount; i++)
                        datasets.push({
                            ...ogDataset,
                            data: [],
                            label: `${ogDataset.label} (${this.dashboardData?.wells[i]?.name})`,
                            backgroundColor: this.dashboardData?.wells[i]?.color,
                            borderColor: this.dashboardData?.wells[i]?.color,
                            pointBackgroundColor: this.dashboardData?.wells[i]?.color,
                            pointBorderColor: this.dashboardData?.wells[i]?.color,
                            yAxisID: `right-axis-${graphType}`
                        })

                    list.forEach(_data => {
                        if (_data.stageNumber < lowestStage)
                            return;

                        // Track min and max of each graph to avoid overlap between chart types
                        if (min === null || _data.value < min)
                            min = _data.value;
                        if (max === null || _data.value > max)
                            max = _data.value;

                        // Seperate the data between each well
                        datasets[_data.wellNumber].data.push({
                            x: _data.stageNumber,
                            y: this.friendlyMetric(tagName, _data.value),
                            wellNumber: _data.wellNumber
                        });
                    });
                }

                // To avoid the two graphs from overlapping, we set the suggested min
                // to 50% of the lowest value so the bar graph should only use the top 2/3 of the space
                if (!isFalsy(ogOptions?.ticks) && !isFalsy(min) && min >= 0)
                    ogOptions.ticks.suggestedMin = min * 0.70;

                // To avoid the two graphs from overlapping, we set the suggested max
                // to 50% of the highest value so the bar graph should only use the bottom 2/3 of the space
                if (graphType && !isFalsy(ogOptions?.ticks) && !isFalsy(max) && max >= 0)
                    ogOptions.ticks.suggestedMax = max * 1.3;

                return {
                    datasets: datasets,
                    options: ogOptions
                };
            }
            const addDataset = (tagName, list, ogDataset, ogOptions, graphType) => {
                // Only show datasets with data
                if (!isNullOrEmpty(list)) {
                    let { datasets, options } = prepareDataSets(tagName, list, ogDataset, ogOptions, graphType);

                    if (!isFalsy(options))
                        this.options.scales.yAxes.push(options);

                    if (!isNullOrEmpty(datasets))
                        datasets.forEach(dataset => _datasets.push(dataset));
                }
            };

            // When set, limitStagesMetricsPerStage controls how many (starting from the back) stage should be shown on the graph
            for (let i = lowestStage; i < stageCount; i++)
                this.kpi.labels.push(i);

            addDataset(
                this.item.options.scatterTag,
                this.scatterJSON,
                {
                    type: 'line',
                    label: scatterName,
                    fill: false,
                    data: [],
                    backgroundColor: chartStyle.primaryLineGraphColour,
                    borderColor: chartStyle.primaryLineGraphColour,
                    pointStyle: 'rectRot',
                    pointBorderColor: chartStyle.secondaryBorderGraphColour,
                    pointBackgroundColor: chartStyle.primaryLineGraphColour,
                    pointRadius: 6,
                    pointLabel: null,
                    labels: {
                        usePointStyle: true, // Use point style for legend symbol
                    },
                },
                {
                    id: 'right-axis-0',
                    scaleLabel: {
                        display: true,
                        fontColor: chartStyle.labelFontColor,
                        fontSize: 14,
                        labelString: scatterName
                    },
                    ticks: {
                        beginAtZero: false,
                        fontSize: 18,
                        fontColor: chartStyle.labelFontColor
                    },
                    gridLines: {
                        color: chartStyle.gridLinesColor
                    }
                },
                0
            )

            addDataset(
                this.item.options.barTag,
                this.barJSON,
                {
                    type: 'bar',
                    label: barName,
                    fill: true,
                    data: [],
                    backgroundColor: chartStyle.primaryBarGraphColour,
                    borderColor: chartStyle.primaryBarGraphColour
                },
                {
                    id: 'right-axis-1',
                    position: 'right',
                    scaleLabel: {
                        display: true,
                        fontColor: chartStyle.labelFontColor,
                        fontSize: 14,
                        labelString: barName
                    },
                    ticks: {
                        beginAtZero: false,
                        maxRotation: 0,
                        fontSize: 18,
                        fontColor: chartStyle.labelFontColor
                    },
                    gridLines: {
                        display: false
                    }
                },
                1
            );

            this.kpi.datasets = _datasets;
            this.allowShowChart = true;

            // Let the graph render before adding the annotations
            this.$nextTick(() => {
                this.buildAnalytics();
            });
        },
        buildAnalytics: function() {
            const chart = this.getChartRef();

            if (!isNullOrEmpty(chart?.options?.annotation?.annotations))
                chart.options.annotation.annotations = [];

            if (!isNullOrEmpty(this.item?.options?.analyticsType)) {
                const scatterName = this.findTagName(this.item.options.scatterTag);
                const barName = this.findTagName(this.item.options.barTag);
                const scatterData = this.kpi.datasets.filter(_dataSet => _dataSet.yAxisID == 'right-axis-0')?.map(_dataSet => _dataSet?.data || [])?.flat().map(_obj => +_obj.y);
                const barData = this.kpi.datasets.filter(_dataSet => _dataSet.yAxisID == 'right-axis-1')?.map(_dataSet => _dataSet?.data || [])?.flat().map(_obj => +_obj.y);

                // Draw analytics per graph type
                if (this.item?.options?.analyticsType?.includes('Average')) {
                    if (!isNullOrEmpty(scatterData))
                        this.drawHorizontalLine(`${scatterName} Average`, this.friendlyMetric(this.item.options.scatterTag, this.getAverage(scatterData)), 'right-axis-0', ANALYTICS_COLORS[0], (value) => value, false);
                    if (!isNullOrEmpty(barData))
                        this.drawHorizontalLine(`${barName} Average`, this.friendlyMetric(this.item.options.barTag, this.getAverage(barData)), 'right-axis-1', ANALYTICS_COLORS[1], (value) => value, false);
                }

                if (this.item?.options?.analyticsType?.includes('Median')) {
                    if (!isNullOrEmpty(scatterData))
                        this.drawHorizontalLine(`${scatterName} Median`, this.friendlyMetric(this.item.options.scatterTag, this.getMedian(scatterData)), 'right-axis-0', ANALYTICS_COLORS[2], (value) => value, false);
                    if (!isNullOrEmpty(barData))
                        this.drawHorizontalLine(`${barName} Median`, this.friendlyMetric(this.item.options.barTag, this.getMedian(barData)), 'right-axis-1', ANALYTICS_COLORS[3], (value) => value, false);
                }
            }
            
            this.buildAnalyticsData();

            this.$nextTick(() => {
                this.assignChartSize();
            });
        },
        createExportData: function() {
            const dataSets = _.cloneDeep(this.kpi.datasets);
            let fields = [ ];
            let items = [];
            
            const fileName = this.getExportFilename(this.item.options.type);
            const scatterTagHasData = !isNullOrEmpty(this.scatterJSON);
            const barTagHasData = !isNullOrEmpty(this.barJSON);

            if (this.item.options?.averageWellDataMetricsPerStage) {
                if (!scatterTagHasData)
                    dataSets.unshift({ data: [] });

                fields = [ 'Stage', this.findTagName(this.item.options.scatterTag), this.findTagName(this.item.options.barTag) ];

                const maxStage = Math.max(dataSets[0]?.data?.length || 0, dataSets[1]?.data?.length || 0) || 0;

                for (let stage = 0; stage < maxStage; stage++)
                    items.push([
                        dataSets[0]?.data[stage]?.x || dataSets[1]?.data[stage]?.x,
                        scatterTagHasData ? dataSets[0]?.data[stage]?.y || '' : '',
                        barTagHasData ? dataSets[1]?.data[stage]?.y || '' : ''
                    ]);
            } else {
                // Search the built datasets for the highest well number
                const wellCount = Math.max(...dataSets.map(_dataSet => _dataSet?.data || [])?.flat()?.map(_event => _event.wellNumber || 0));

                // When tags both are set, split the datasets in half to know how when the bar tags datasets start
                // When only one tag is set, no need for offsets
                const offset = scatterTagHasData && barTagHasData ? Math.floor(dataSets.length/2) : 0;

                fields = [ 'Well', 'Stage', this.findTagName(this.item.options.scatterTag), this.findTagName(this.item.options.barTag) ];

                // Go through each well data set adding the data for each tag at once
                for (let i = 0; i <= wellCount; i++) {
                    const maxStageForWell = Math.max(dataSets[i]?.data?.length || 0, dataSets[i+offset]?.data?.length || 0) || 0;

                    for (let stage = 0; stage < maxStageForWell; stage++) {
                        items.push([
                            i+1,
                            dataSets[i]?.data[stage]?.x || dataSets[i+offset]?.data[stage]?.x || '',
                            scatterTagHasData ? dataSets[i]?.data[stage]?.y || '' : '',
                            barTagHasData ? dataSets[i+offset]?.data[stage]?.y || '' : ''
                        ]);
                    }
                }
            }

            return { 
                fields, 
                items, 
                fileName
            };
        },
        handleTagChange: async function(newValue, oldValue, ref) {
            // Clear the dataset to send back to spinner state during promises and rebuild
            this.allowShowChart = false;

            this[ref] = await this.fetchMetricsByTag(newValue, ref);
            this.buildData();
        },
        clearLimitMetrics: function() {
            this.item.options.limitStagesMetricsPerStage = null;
            this.showLimitStageMetrics = false;
            this.buildData();
        },
        tagIsSet: function(tag) {
            return !isFalsy(tag) && !(tag < 0);
        },
        findTagName: function(tag) {
            // Not found, default or not set value
            if (!this.tagIsSet(tag))
                return '';
            const matchedTag = this.metadataTags?.find(_tag => _tag.tagName == tag);
            return matchedTag?.displayName || matchedTag?.tagName || tag;
        },
        friendlyMetric: function(tag, value) {
            if (isFalsy(value) || isNaN(value))
                return value;

            const tagName = tag?.toLowerCase();
            if (tagName?.includes('rate'))
                return +value.toFixed(1);
            if (tagName?.includes('pressure') || tagName?.includes('wireline') || tagName?.includes('vol'))
                return Math.floor(Math.round(value));
            return +value.toFixed(2);
        },
        fetchMetricsByTag: function(tag) {
            // If the tag is not in a valid state, avoid fetching
            // -1: denotes initial state (not yet selected)
            // -2: denotes the not selected option in settings
            if (isFalsy(tag) || tag < 0)
                return [];

            // when refetching tag info, clear errors for this tag (will get re added if error persists)
            this.clearTagError(tag);

            return $.get(`/job-kpi-metrics-by-tag/${this.jobNumber}/${tag}`).then(_res => {
                // If the list is empty or all values are null, show error instead of rendering graph
                if (!isNullOrEmpty(_res) && !isFalsy(_res.find(_value => !isFalsy(_value?.value) && !isNaN(_value.value)))) {
                    _res.sort((a, b) => a.stageNumber - b.stageNumber);
                    return _res;
                }
                
                this.errors.push({
                    tag,
                    message: `No data found for tag: ${this.findTagName(tag)}`
                });
                
                return [];
            }).catch(() => {
                this.errors.push({
                    tag,
                    message: `Failed to fetch data: ${this.findTagName(tag)}`
                });

                return [];
            });
        },
        // Fetch all tags available to the customer
        fetchMetadataTags: function() {
            if (isFalsy(this.customer?.id))
                return toast({
                    title: 'Failed to read customer id',
                    body: 'Id: ' + this.customer?.id,
                    variant: 'danger'
                });

            return $.get(`/job-tags-metrics-by-tag/${this.customer.id}`, { _token: getCSRFToken() }).then(result => {
                if (result.error) {
                    return toast({
                        title: 'Failed to fetch tokens',
                        body: result.message,
                        variant: 'danger'
                    });
                } else if (isNullOrEmpty(result?.frac) && isNullOrEmpty(result?.wireline)) {
                    return toast({
                        title: 'No Tags',
                        body: 'No Tags found for customer: ' + this.customer?.name,
                        variant: 'danger'
                    });
                } else {
                    let data = !isNullOrEmpty(result?.frac) ? [ ...result.frac ] : [];
                    const tagsMap = data.reduce((accumulator, tag) => ({ ...accumulator, [ tag.tagName ]: true }), {});

                    // Wireline tags could already exist in frac, don't add duplicates
                    if (!isNullOrEmpty(result?.wireline))
                        result.wireline.forEach(tag => {
                            if (!tagsMap[tag.tagName]) {
                                data.push(tag);
                                tagsMap[tag.tagName] = true;
                            }
                        })

                    if (!isNullOrEmpty(data)) {
                        data = data.filter(_tag => !this.forbiddenTags.includes(_tag.tagName))
                        data.sort((a, b) => (a.tagName > b.tagName) ? 1 : (b.tagName > a.tagName ? -1 : 0));
                        this.metadataTags = data;
                    } else {
                        return toast({
                            title: 'No Tags',
                            body: 'No Tags found for customer: ' + this.customer.name,
                            variant: 'danger'
                        });
                    }
                }
            }).catch(result => {
                return toast({
                    title: 'Failed to fetch tokens',
                    body: result.message,
                    variant: 'danger'
                });
            });
        },
        clearTagError: function(tag) {
            if (!isFalsy(tag) && !isNullOrEmpty(this.errors))
                this.errors = this.errors.filter(_error => _error.tag != tag);
        }
    }
}
</script>

<style scoped>
    .stages-limit-container>span {
        display: inline-block;
    }
    .stages-limit-main {
        width: calc(100% - 67px);
    }
    .stages-limit-actions {
        position: relative;
        bottom: 3px;
        
        width: 62px;
    }

    .kpi-item .kpi-line {
        display: inline-block;
        width: 14px;
        height: 5px;
        border-bottom: 2px solid #F0AD4E;
        -webkit-transform: translateY(-5px) translateX(-2px) rotate(-45deg);
    }
    .kpi-item .kpi-bar {
        display: inline-block;
        margin-right: 4px;
        width: 10px;
        height: 15px;

        position: relative;
        top: 2px;
    }
</style>