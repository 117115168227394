var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("iws-breadcrumbs", {
        staticClass: "mt-4 mb-1",
        attrs: {
          path: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "Alert Banner Management",
              link: "/alertBanner",
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "page-title mb-4" }, [
        _vm._v("\n        Alert Banner Management\n    "),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "row justify-content-left align-items-center mb-2 mt-3",
        },
        [
          _c("div", { staticClass: "col-1 list-label text-left" }, [
            _vm._v("Message"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newAlert.message,
                  expression: "newAlert.message",
                },
              ],
              staticClass: "form-control",
              attrs: { maxlength: "300", type: "text" },
              domProps: { value: _vm.newAlert.message },
              on: {
                keyup: _vm.countdown,
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.newAlert, "message", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [
            _vm._v(
              "\n            " + _vm._s(_vm.remainingCount) + "\n        "
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row justify-content-left align-items-center pb-2" },
        [
          _c("div", { staticClass: "col-1 list-label text-left" }, [
            _vm._v("Severity"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newAlert.severity,
                    expression: "newAlert.severity",
                  },
                ],
                staticClass: "form-control",
                on: {
                  change: [
                    function ($event) {
                      var $$selectedVal = Array.prototype.filter
                        .call($event.target.options, function (o) {
                          return o.selected
                        })
                        .map(function (o) {
                          var val = "_value" in o ? o._value : o.value
                          return val
                        })
                      _vm.$set(
                        _vm.newAlert,
                        "severity",
                        $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      )
                    },
                    function ($event) {
                      _vm.configChanged = true
                    },
                  ],
                },
              },
              _vm._l(_vm.severityTypes, function (severity) {
                return _c(
                  "option",
                  { key: severity, domProps: { value: severity } },
                  [_vm._v(_vm._s(severity))]
                )
              }),
              0
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "row justify-content-left align-items-center pb-2" },
        [
          _c("div", { staticClass: "col-1 list-label text-left" }, [
            _vm._v("Expiry"),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-6" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newAlert.expiry,
                  expression: "newAlert.expiry",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "number" },
              domProps: { value: _vm.newAlert.expiry },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.newAlert, "expiry", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col-1" }, [
            _vm._v("\n            hours\n        "),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("div", { staticClass: "row justify-content-left" }, [
        _c("div", { staticClass: "col-1" }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-6 align-items-right" },
          [
            _c(
              "b-button",
              {
                attrs: {
                  block: "",
                  disabled:
                    !_vm.newAlert.message ||
                    !_vm.newAlert.severity ||
                    !_vm.newAlert.expiry,
                  variant: "primary",
                },
                on: { click: _vm.submitAlert },
              },
              [_vm._v("Save and Publish")]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row mt-3" }, [
        _c("div", { staticClass: "col" }, [
          _c(
            "table",
            {
              staticClass: "table table-striped table-hover mt-2",
              class: { "table-dark": _vm.darkMode },
            },
            [
              _c("thead", [
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Message")]),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [_vm._v("Severity")]),
                _vm._v(" "),
                _c(
                  "th",
                  { attrs: { scope: "col" } },
                  [
                    _c(
                      "b-button",
                      {
                        attrs: { size: "sm", variant: "primary" },
                        on: {
                          click: function ($event) {
                            return _vm.refreshAlerts()
                          },
                        },
                      },
                      [
                        _c("b-icon", {
                          attrs: {
                            icon: "arrow-repeat",
                            "aria-label": "Reload",
                          },
                        }),
                        _vm._v(" Refresh\n                    "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("th", { attrs: { scope: "col" } }, [
                  _vm._v("Expiry (minutes)"),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.alertList, function (alert, index) {
                  return _c("tr", { key: alert.id }, [
                    _c("td", [_vm._v(_vm._s(alert.message))]),
                    _vm._v(" "),
                    _c("td", [_vm._v(_vm._s(alert.severity))]),
                    _vm._v(" "),
                    _c("td", [
                      !alert.display
                        ? _c(
                            "span",
                            {
                              staticClass: "badge badge-primary",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.publishAlert({
                                    ...alert,
                                    display: true,
                                  })
                                },
                              },
                            },
                            [_vm._v("Publish")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      alert.display
                        ? _c("span", { staticClass: "badge badge-secondary" }, [
                            _vm._v("Published"),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      alert.display
                        ? _c(
                            "span",
                            {
                              staticClass: "badge badge-danger",
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.stopDisplayingAlert({
                                    ...alert,
                                    display: false,
                                  })
                                },
                              },
                            },
                            [_vm._v("Unpublish")]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c("td", [
                      _vm._v(_vm._s((alert.expiryRedis / 60).toFixed(2))),
                    ]),
                  ])
                }),
                0
              ),
            ]
          ),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }