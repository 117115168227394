var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column p-3",
      staticStyle: { "border-bottom": "2px solid #666666" },
    },
    [
      _c(
        "a",
        {
          staticStyle: { "text-decoration": "none", color: "inherit" },
          attrs: { href: _vm.jobPath(_vm.job) },
        },
        [
          _c(
            "div",
            {
              staticClass: "d-flex flex-row justify-content-between",
              staticStyle: { "font-size": "large" },
            },
            [
              _c(
                "div",
                {
                  staticClass: "font-weight-bold",
                  attrs: { id: "jobLocationDiv-" + _vm.job.id },
                },
                [_vm._v(_vm._s(_vm.jobMainTitle(_vm.job)))]
              ),
              _vm._v(" "),
              _c("div", { staticClass: "font-weight-light" }, [
                _vm._v(_vm._s(_vm.job.companyName)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex flex-row justify-content-between",
              staticStyle: { "font-size": "small" },
            },
            [
              _c("div", { staticClass: "font-weight-bold" }, [
                _vm._v(_vm._s(_vm.jobSecondaryTitle(_vm.job))),
              ]),
              _vm._v(" "),
              _vm.isAdminOrIws === "1"
                ? _c(
                    "a",
                    {
                      staticStyle: {
                        "text-decoration": "none",
                        color: "inherit",
                      },
                      attrs: { href: "/jobs/" + _vm.job.jobNumber + "/edit" },
                    },
                    [
                      _c("span", { staticClass: "badge badge-primary" }, [
                        _vm._v("Edit"),
                      ]),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _vm._v(" "),
          _vm.job.start
            ? _c(
                "div",
                {
                  staticClass: "text-muted",
                  staticStyle: { "font-size": "small" },
                },
                [
                  _vm._v(
                    _vm._s(_vm.convertDate(_vm.job.start, _vm.job.hourOffset)) +
                      " to " +
                      _vm._s(_vm.convertDate(_vm.job.end, _vm.job.hourOffset))
                  ),
                  _vm.job.end == null
                    ? _c("span", { staticClass: "badge badge-success" }, [
                        _vm._v("On-going"),
                      ])
                    : _vm._e(),
                ]
              )
            : _vm.job.scheduledStart
            ? _c(
                "div",
                {
                  staticClass: "text-muted",
                  staticStyle: { "font-size": "small" },
                },
                [
                  _vm.job.end == null
                    ? _c("span", { staticClass: "badge badge-success" }, [
                        _vm._v("Projected"),
                      ])
                    : _vm._e(),
                  _vm._v(
                    "\n            " +
                      _vm._s(
                        _vm.convertDate(
                          _vm.job.scheduledStart,
                          _vm.job.hourOffset
                        )
                      ) +
                      "\n        "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.numberOfStagesAllWells(_vm.job) > 0
            ? _c(
                "div",
                {
                  staticClass: "progress my-3",
                  staticStyle: { height: "30px" },
                },
                [
                  _c(
                    "div",
                    {
                      class: [
                        "progress-bar",
                        _vm.job.completedStages ==
                        _vm.numberOfStagesAllWells(_vm.job)
                          ? "bg-success"
                          : "bg-primary",
                      ],
                      style: { width: _vm.completedPercent(_vm.job) + "%" },
                      attrs: {
                        role: "progressbar",
                        "aria-valuenow": _vm.completedPercent(_vm.job),
                        "aria-valuemin": "0",
                        "aria-valuemax": "100",
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "px-1 progress-bar-title-left" },
                        [
                          _c("b", [_vm._v(_vm._s(_vm.job.completedStages))]),
                          _vm._v(" of\n                    "),
                          _c("b", [
                            _vm._v(_vm._s(_vm.numberOfStagesAllWells(_vm.job))),
                          ]),
                          _vm._v(" Stages Completed\n                "),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "px-1 progress-bar-title-right" },
                        [_vm._v(_vm._s(_vm.completedPercent(_vm.job)) + "%")]
                      ),
                    ]
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.showWellInfo
            ? _c("div", [
                _vm.job.wells.length > 0 && !_vm.job.jobNumber.includes("CM")
                  ? _c(
                      "div",
                      _vm._l(_vm.job.wells, function (well) {
                        return _c(
                          "div",
                          {
                            key: well.id,
                            staticClass: "row my-3 mx-2",
                            staticStyle: { "font-size": "small" },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "col-2 text-truncate",
                                staticStyle: {
                                  "font-size": "large",
                                  "padding-left": "0px",
                                  "padding-right": "0px",
                                },
                              },
                              [_c("b", [_vm._v(_vm._s(well.name))])]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-sm-12 col-md-12 col-lg-10 col-xl-10",
                                staticStyle: {
                                  "padding-left": "0px",
                                  "padding-right": "0px",
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "progress",
                                    staticStyle: { height: "30px" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        class: ["progress-bar"],
                                        style: {
                                          width:
                                            _vm.completedPercentWell(well) +
                                            "%",
                                          "background-color": well.color,
                                        },
                                        attrs: {
                                          role: "progressbar",
                                          "aria-valuenow":
                                            _vm.completedPercentWell(well),
                                          "aria-valuemin": "0",
                                          "aria-valuemax": "100",
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "px-1 progress-bar-title-left",
                                            style: {
                                              "font-size": "small",
                                              color: _vm.getTitleColorForBG(
                                                well.color
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    " +
                                                _vm._s(
                                                  _vm.getActivityString(
                                                    well.activity
                                                  )
                                                ) +
                                                "\n                                "
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "px-1 progress-bar-title-right",
                                            style: {
                                              "font-size": "small",
                                              color: _vm.getTitleColorForBG(
                                                well.color
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                    Stage " +
                                                _vm._s(
                                                  well.currentStage || "--"
                                                ) +
                                                " / " +
                                                _vm._s(
                                                  well.numberOfStages || "--"
                                                ) +
                                                "\n                                    (" +
                                                _vm._s(
                                                  _vm.completedPercentWell(well)
                                                ) +
                                                "%)\n                                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.jobHasDayTech(_vm.job) || _vm.jobHasNightTech(_vm.job)
            ? _c("div", [
                _vm._m(0),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass: "row text-center",
                    staticStyle: { "font-size": "small" },
                  },
                  [
                    _vm.jobHasDayTech(_vm.job)
                      ? _c(
                          "a",
                          {
                            class: {
                              "col-6":
                                _vm.jobHasDayTech(_vm.job) &&
                                _vm.jobHasNightTech(_vm.job),
                              "col-12": !(
                                _vm.jobHasDayTech(_vm.job) &&
                                _vm.jobHasNightTech(_vm.job)
                              ),
                              "text-center": true,
                            },
                            staticStyle: {
                              "text-decoration": "none",
                              color: "inherit",
                            },
                            attrs: {
                              href:
                                "tel:" + _vm.job.techUserDaytime.phone_number,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.job.techUserDaytime.first_name) +
                                "\n                    " +
                                _vm._s(_vm.job.techUserDaytime.last_name)
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.job.techUserDaytime.phone_number) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.jobHasNightTech(_vm.job)
                      ? _c(
                          "a",
                          {
                            class: {
                              "col-6":
                                _vm.jobHasDayTech(_vm.job) &&
                                _vm.jobHasNightTech(_vm.job),
                              "col-12": !(
                                _vm.jobHasDayTech(_vm.job) &&
                                _vm.jobHasNightTech(_vm.job)
                              ),
                              "text-center": true,
                            },
                            staticStyle: {
                              "text-decoration": "none",
                              color: "inherit",
                            },
                            attrs: {
                              href: "tel:" + _vm.job.techUserNight.phone_number,
                              target: "_blank",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(_vm.job.techUserNight.first_name) +
                                "\n                    " +
                                _vm._s(_vm.job.techUserNight.last_name)
                            ),
                            _c("br"),
                            _vm._v(
                              _vm._s(_vm.job.techUserNight.phone_number) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "row text-center mx-3 mt-2",
        staticStyle: { "font-size": "small" },
      },
      [
        _c("div", { staticClass: "col-12 text-center font-weight-bold" }, [
          _vm._v("IWS Field Support"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }