<template>
<div id="MetadataTopLevel" class="position-relative">
    <iws-breadcrumbs class="breadcrumbs-margins"
        :path="[{
            title: 'Operator List',
            link: `/metadata`
        }]"
    />

    <div class="h5 page-title">
        Operator List
    </div>

    <div id="search">
        <iws-search :value.sync="searchKey" />
    </div>

    <iws-tabs :tabs="tabs" @tab-change="handleTabChange">
        <template #tab_0>
            <iws-table
                :columns="[ 
                    {
                        label: 'System Number',
                        key: 'system'
                    }, {
                        label: 'actions',
                        showHeader: false,
                        sortable: false
                    }
                 ]"
                :items="jobs"
                :filter="searchKey"

                :sortByCol.sync="sortByCol"

                :maxPageSize="12"
            >      
                <template #cell_actions="{ data }">
                    <a :href="`/metadata/job/${data.item.id}`">
                        Select <i class="fas fa-chevron-right ml-1"></i>
                    </a>
                </template>
            </iws-table>
        </template>

        <template #tab_1>
            <iws-table
                :columns="[ 
                    {
                        label: 'ID',
                        key: 'id'
                    }, {
                        label: 'Name',
                        key: 'name'
                    }, {
                        label: 'Customer',
                        key: 'customer'
                    }, {
                        label: 'Pads',
                        key: 'pads',
                        thStyle: 'width: 150px'
                    }
                 ]"
                :items="systems"
                :filter="filterSystem"

                :sortByCol.sync="sortByCol"

                :maxPageSize="12"
            >
                <template v-for="system in systems" v-slot:[`row_${system.id}`]>
                    <td>
                        {{ system.id }}
                    </td>
                    <td>
                        {{ system.data.name }}
                    </td>
                    <td>
                        <iws-select style="display: inline-block; width: calc(100% - 51px); max-width: 175px; margin: 0px"
                            :value.sync="system.data.customer.id"
                            :options="customers"
                            display-name="name"
                            value-name="id"
                            @change="system.data.customer.isDirty = true"
                        />
                        <iws-button v-if="system.data.customer.isDirty"
                            text="Save"
                            type="outline-primary"
                            style="position: relative; top: -3px;"
                            @click="updateCustomer(system)"
                        />
                    </td>
                    <td>
                        <span v-if="!hasCustomer(system)" class="badge badge-pill badge-warning">
                            Must specifiy customer
                        </span>
                        <template v-else>
                            <iws-button
                                :text="makePadsButtonText(system)"
                                type="outline-light"
                                @click="openSelectPads(system)"
                            />
                        </template>
                    </td>
                </template>
            </iws-table>
        </template>

        <template #tab_2>
            <iws-table
                :columns="[ 
                    {
                        label: 'Operator',
                        key: 'name'
                    }, {
                        label: 'actions',
                        showHeader: false,
                        sortable: false
                    }
                 ]"
                :items="customers"
                :filter="searchKey"

                :sortByCol.sync="sortByCol"

                :maxPageSize="12"
            >      
                <template #cell_actions="{ data }">
                    <a :href="`/metadata/operator/${data.item.id}`">
                        Select <i class="fas fa-chevron-right ml-1"></i>
                    </a>
                </template>
            </iws-table>
        </template>
    </iws-tabs>

    <metadata-top-level-pads-modal :fieldTypeId="fieldtypeid" :padTypeId="padtypeid" ref="MetadataTopLevelPadsModal" />
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, toast } = GlobalFunctions;

import MetadataTopLevelPadsModal from './MetadataTopLevelPadsModal.vue'

export default {
    props: {
        systemstypeid: String,
        fieldtypeid: String,
        padtypeid: String,
        jobsdata: String,
        systemsdata: String,
        customersdata: String
    },
    components: { MetadataTopLevelPadsModal },

    data() {
        return {
            tabs: [ 
                { title: 'Jobs' },
                { title: 'Systems' },
                { title: 'Customers' }
            ],

            jobs: JSON.parse(this.jobsdata),
            systems: JSON.parse(this.systemsdata).map(_system => {
                const json = JSON.parse(_system.data);

                // To avoid NPEs, make sure the customer object is set
                if (!('customer' in json))
                    json.customer = {};

                return {
                    ..._system,
                    data: json
                }
            }),
            customers: JSON.parse(this.customersdata),

            activeTab: 0,
            searchKey: null,
            sortByCol: 'system'
        };
    },

    methods: {
        filterSystem(item) {
            if (isFalsy(this.searchKey))
                return true;
            return item?.data?.name?.toLowerCase().includes(this.searchKey.toLowerCase());
        },
        hasCustomer(system) {
            return !isFalsy(system?.data?.customer?.id);
        },
        makePadsButtonText(system) {
            if (isFalsy(system?.data?.pads))
                return 'Assign';
            return `Edit: ${system.data.pads.split(',').length} pads`;
        },

        handleTabChange($event) {
            this.activeTab = $event;

            // Reset filter and sorting options whenever we change tabs
            this.searchKey = null; 
            this.sortByCol = this.activeTab == 0 ? 'system' : 'name'; 
            this.currentPage = 0;
        },

        updateCustomer(system) {
            if (system.data.customer != null) {
                system.data._token = GlobalFunctions.getCSRFToken();
                system.data.customer = this.customers.find(_customer => _customer.id == system.data.customer.id)

                return $.post(`/metadata/system/${system.id}`, system.data, 'json').then(_res => {
                    delete system.data._token;
                    system.isDirty = false;
                    toast({
                        title: 'Successfully assigned customer',
                        variant: 'success'
                    });
                }).catch(error => {
                    console.log('error', error);
                    toast({
                        title: 'Failed to assigned customer',
                        variant: 'error'
                    });
                });
            } else {
                toast({
                    title: 'Must select a customer',
                    variant: 'error'
                });
            }
        },

        openSelectPads(system) {
            if (isFalsy(system?.data?.customer?.id))
                return toast({
                    title: 'Invalid system details: missing customer id',
                    variant: 'error'
                });

            return this.$refs.MetadataTopLevelPadsModal.open(system);
        }
    }
};
</script>

<style>
    #MetadataTopLevel tr:not(#pagination-control) > td:last-of-type {
        width: 100px;
    }
</style>
<style scoped>
    .page-title {
        margin-bottom: 25px;
    }

    #search {
        position: absolute;
        top: 5px;
        right: 0px;

        width: 250px;
        max-width: calc(100% - 130px);
    }
</style>