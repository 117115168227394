var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "collapse panel-collapse bg-white w-50 pull-right",
      attrs: { id: "messagesCollapsible" },
    },
    [
      _c(
        "div",
        { staticClass: "panel-body" },
        _vm._l(_vm.allMessages, function (message) {
          return _c(
            "div",
            {
              key: message.id,
              staticClass: "left clearfix alert alert-secondary p-1 mb-1",
            },
            [
              _c(
                "div",
                {
                  staticClass: "bg-dark text-light rounded p-1",
                  staticStyle: {
                    position: "absolute",
                    top: "2px",
                    right: "2px",
                    cursor: "pointer",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.deleteMessage(message.id)
                    },
                  },
                },
                [_vm._v("X")]
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  on: {
                    click: function ($event) {
                      return _vm.markRead(message.id)
                    },
                  },
                },
                [
                  _c("p", { staticClass: "mb-0" }, [
                    !message.read
                      ? _c(
                          "span",
                          { staticClass: "badge badge-pill badge-danger" },
                          [_vm._v("New")]
                        )
                      : _vm._e(),
                    _vm._v(
                      "\n                            " +
                        _vm._s(message.text) +
                        "\n                      "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("span", { staticClass: "header m-0 p-0" }, [
                    _c("strong", { staticStyle: { "font-size": "0.7em" } }, [
                      _vm._v(_vm._s(message.from)),
                    ]),
                    _vm._v(" "),
                    _c(
                      "small",
                      {
                        staticClass: "text-muted",
                        staticStyle: { "font-size": "0.7em" },
                      },
                      [_vm._v(_vm._s(message.timestamp.format("LLL")))]
                    ),
                  ]),
                ]
              ),
            ]
          )
        }),
        0
      ),
      _vm._v(" "),
      _c("div", { staticClass: "panel-footer" }, [
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.messageText,
                expression: "messageText",
              },
            ],
            staticClass: "form-control input-sm",
            attrs: {
              id: "messageText",
              type: "text",
              placeholder: "Type your message here...",
            },
            domProps: { value: _vm.messageText },
            on: {
              change: function ($event) {
                return _vm.sendMessage()
              },
              click: function ($event) {
                return _vm.input($event)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.messageText = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("span", { staticClass: "input-group-btn" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { id: "btn-chat" },
                on: {
                  click: function ($event) {
                    return _vm.sendMessage()
                  },
                },
              },
              [_vm._v("\n                        Send\n                    ")]
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _vm.showKeyboard
        ? _c("keyboard-component", {
            staticStyle: {
              "z-index": "1040",
              position: "fixed",
              right: "0",
              left: "0",
              bottom: "500px",
            },
            on: { key: _vm.keyPress },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "navbar navbar-light bg-light" }, [
      _c(
        "button",
        { staticClass: "btn btn-warning", attrs: { type: "button" } },
        [_vm._v("\n            hidden\n        ")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }