<template>
<div id="MetadataOperator">
    <div id="headers-row">
        <iws-breadcrumbs
            :path="[{
                title: 'Operator List',
                link: `/metadata`
            }, {
                title: customer.name,
                link: `/metadata/operator/${propid}`
            }]"
        />

        <div v-if="!!customer.name" class="h5 page-title">
            {{ customer.name }}
        </div>

        <div id="control-changes-container">
            <iws-button style="margin-right: 10px"
                type="outline-light"
                text="Cancel"
                :click="discardChanges"
                :disabled="!isDirty()"
            />

            <iws-button
                type="light"
                text="Save"
                :click="saveChanges"
                :disabled="!isDirty()"
            />
        </div>
    </div>

    <!-- Do not allow any tabs to render until the data structure has been processed in mounted() -->
    <iws-tabs v-if="value.npt.frac.tags !== null"
        :tabs="[
            { title: 'NPT Frac Tags', enabled: !containsUnsavedChanges() },
            { title: 'Stage Report - Frac', enabled: !containsUnsavedChanges() },
            { title: 'Stage Report - Wireline', enabled: !containsUnsavedChanges() }
        ]"
    >
        <template #tab_0>
            <metadata-operator-list
                ref="nptTags"
                activity="npt"
                :data="value.npt.frac"
                :refs="$refs"

                @list-update="value.npt.frac.tags = $event"
            />

            <iws-accordian style="margin-top: 20px"
                :cards="[
                    { title: `NPT Outcome (${nptOutcomes.length})` },
                    { title: `NPT Vendors (${nptVendors.length})` }
                ]"
                :allowMultiTabs="true"
                :value="null"
            >
                <template #card-body_0>
                    <div class="position-relative">
                        <iws-search :value.sync="outcomeFilter" />

                        <span>
                            <iws-button text="Add Outcome" @click="editAction({ ...emptyItem }, nptOutcomes, isRequired,'outcomes')" />
                        </span>
                    </div>

                    <iws-table
                        :columns="nptColumns"
                        :items="nptOutcomes"
                        :filter="outcomeFilter"
                        :sortByCol="'name'"
                        :maxPageSize="12"
                    >
                        <template #cell_actions="{ data }">
                            <library-crud-actions
                                :id="`action${data.index}`"
                                :viewActions="{ view: false }"

                                @edit="editAction(data, nptOutcomes, isRequired,'outcomes')"
                                @delete="deleteAction(data, nptOutcomes,'outcomes')"
                            />
                        </template>
                    </iws-table>
                </template>

                <template #card-body_1>
                    <div class="position-relative">
                        <iws-search :value.sync="vendorFilter" />

                        <span>
                            <iws-button text="Add Vendor" @click="editAction({ ...emptyItem }, nptVendors, isUnique,'vendors')" />
                        </span>
                    </div>

                    <iws-table
                        :columns="nptColumns"
                        :items="nptVendors"
                        :filter="vendorFilter"
                        :sortByCol="'name'"
                        :maxPageSize="12"
                    >
                        <template #cell_actions="{ data }">
                            <library-crud-actions
                                :id="`action${data.index}`"
                                :viewActions="{ view: false }"

                                @edit="editAction(data, nptVendors, isUnique,'vendors')"
                                @delete="deleteAction(data, nptVendors,'vendors')"
                            />
                        </template>
                    </iws-table>
                </template>
            </iws-accordian>
        </template>

        <template #tab_1>
            <metadata-operator-list
                ref="fracTags"
                activity="frac"
                :data="value.reports.stage.frac"
                :refs="$refs"

                @list-update="value.reports.stage.frac.tags = $event"
            />
        </template>

        <template #tab_2>
            <metadata-operator-list
                ref="wirelineTags"
                activity="wireline"
                :data="value.reports.stage.wireline"
                :refs="$refs"

                @list-update="value.reports.stage.wireline.tags = $event"
            />
        </template>
    </iws-tabs>
    <div v-else>
        Loading...
    </div>

    <metadata-add-tag-modal ref="MetadataAddTagModal" :customerId="customer.id"/>
    <outcome-vendor-modal ref="OutcomeVendorModal" />
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty, isFalsy, toast, flushToasts, iwsConfirm } = GlobalFunctions;

import MetadataOperatorList from './MetadataOperatorList.vue';
import MetadataAddTagModal from './MetadataAddTagModal.vue';
import OutcomeVendorModal from './OutcomeVendorModal.vue';

import LibraryCrudActions from '../LibraryCrudActions.vue';

export default {
    props: {
		propid: String,
        propcustomers: String,
        customerdata: String,
        metadata: String,
        type: String,
        csrfToken: String,
        typeId: String,
        parentTypeId: String,
        propChildTypes: String,
        parentId: String,
        syncIdFromParent: Boolean,
        numberOfRevisions: Number
	},

    components: {
        MetadataOperatorList,
        MetadataAddTagModal,
        OutcomeVendorModal,
        LibraryCrudActions
    },

    data() {
        return {
            value: {
                name:'',
                npt: {
                    frac: {
                        tags: null
                    },
                    outcomes: [

                    ],
                    vendors: [

                    ]
                },
                reports: {
                    stage: {
                        frac: {
                            outputs: [],
                            tags: null
                        },
                        wireline: {
                            outputs: [],
                            tags: null
                        }
                    }
                }
            },
            // Written value is a copy of value that represents what is currently written to the db
            // We can use this to detect if unsaved changes have been made in the frontend
            writtenValue: null,

            customer: {},

            outcomeFilter: null,
            vendorFilter: null,

            nptColumns: [
                {
                    label: 'Display Name',
                    key: 'friendly_name'
                }, {
                    label: 'Name',
                    key: 'name'
                }, {
                    label: 'actions',
                    showHeader: false,
                    sortable: false
                }
            ],
            emptyItem: { item: { id: null, name: null, friendly_name: null } },
            nptOutcomes: [],
            nptVendors: []
        }

    },

    methods: {
        isDirty() {
            return JSON.stringify(this.value) !== JSON.stringify(this.writtenValue);
        },
        containsUnsavedChanges() {
            return !isNullOrEmpty(Object.keys(this.$refs?.nptTags?.backups || {}))
                || !isNullOrEmpty(Object.keys(this.$refs?.fracTags?.backups || {}))
                || !isNullOrEmpty(Object.keys(this.$refs?.wirelineTags?.backups || {}));
        },

        discardChanges() {
            return iwsConfirm({
                title: 'Cancel all changes?',
                body: `Are you sure you want to cancel all changes without saving?<br>This action cannot be undone!`,
                width: '320px'
            }).then(_answer => {
                if (_answer === true) {
                    this.value = _.cloneDeep(this.writtenValue);

                    this.$refs?.nptTags?.fullDiscard();
                    this.$refs?.fracTags?.fullDiscard();
                    this.$refs?.wirelineTags?.fullDiscard();
                }
            });
        },
        saveChanges() {
            // If the tab has un saved or denied changes, the user cannot save or switch tabs
            if (this.containsUnsavedChanges())
                return toast({
                    title: 'Please confirm or deny all changes in order to save',
                    variant: 'danger'
                });

            this.value._token = this.csrfToken;
            this.value.type_id = this.typeId;
            this.value.customer_id = this.customer.id;
            //check if value.npt.outcomes is present and if it has remove the outcomes key
            if(this.value.npt.hasOwnProperty('outcomes')){
                delete this.value.npt.outcomes;
            }
            //check if value.npt.vendors is present and if it has remove the vendors key
            if(this.value.npt.hasOwnProperty('vendors')){
                delete this.value.npt.vendors;
            }
            //unset outcomes


            return $.post(`/metadata/operator/${this.propid}`, this.value, 'json').then(_res => {
                flushToasts();
                toast({
                    title: 'Changes saved',
                    variant: 'success'
                });

                this.value = this.processObject(JSON.stringify(_res.request));
                this.writtenValue = this.processObject(JSON.stringify(_res.request));
            });
        },

        isRequired(item, index, key) {
            if (isFalsy(item)) {
                toast({
                    title: `${key == 'name' ? 'Name' : 'Display name'} is required`,
                    variant: 'danger'
                });

                return false;
            }

            return true;
        },
        isUnique(item, index, key) {
            if (this.isRequired(item, index, key)) {
                // If the item passes the isRequired check, check that it is also unique
                const matchFound = !isFalsy(this.nptVendors.find((_item, _index) => _item[key] === item && _index !== index));

                if (matchFound) {
                    toast({
                        title: `${key == 'name' ? 'Name' : 'Display name'} is already in use! Please provide a unique vendor name`,
                        variant: 'danger'
                    });

                    return false;
                }

                return true;
            }

            return false;
        },
        editAction(data, list, validator,type) {
            this.$refs.OutcomeVendorModal.open(data, validator).then(_res => {
                // Make sure the modal wasn't cancelled
                if (!isFalsy(_res)) {
                    _res.customer_id = this.customer.id;
                    if(type === 'vendors'){
                        if(_res.id === null){
                            //new entry
                            $.post(`/metadata/operator/vendors/create`, {type: type, data: _res, _token: this.csrfToken,customerId:this.customer.id}, 'json').then(_res => {
                                this.fetchVendors();
                                toast({
                                    title: 'Vendor added successfully',
                                    variant: 'success'
                                });
                            });
                        }else{
                            //existing entry
                            $.post(`/metadata/operator/vendors/update`, {type: type, data: _res, _token: this.csrfToken,customerId:this.customer.id}, 'json').then(_res => {
                                this.fetchVendors();
                                toast({
                                    title: 'Vendor updated successfully',
                                    variant: 'success'
                                });
                            });
                        }

                    }else{
                        if(_res.id === null){
                            //new entry
                            $.post(`/metadata/operator/outcomes/create`, {type: type, data: _res, _token: this.csrfToken,customerId:this.customer.id}, 'json').then(_res => {
                                this.fetchOutcomes();
                                toast({
                                    title: 'Outcome added successfully',
                                    variant: 'success'
                                });
                            });
                        }else{
                            //existing entry
                            $.post(`/metadata/operator/outcomes/update`, {type: type, data: _res, _token: this.csrfToken,customerId:this.customer.id}, 'json').then(_res => {
                                this.fetchOutcomes();
                                toast({
                                    title: 'Outcome updated successfully',
                                    variant: 'success'
                                });
                            });
                        }
                    }
                    // If an index was provided, the item exists and should replace the previous one



                }
            })
        },
        deleteAction(data, list, type) {
            return iwsConfirm({
                title: 'Are you sure?',
                body: `Are you sure you want to delete this item?<br>Hit cancel to undo before saving.`,
                width: '320px',
                confirmColour: 'danger'
            }).then(_answer => {
                if (_answer === true)
                    if(type === 'vendors'){
                        if(data.id === null){
                            list.splice(data.index, 1);
                        }else{
                            //make a post request to delete the item
                            $.post(`/metadata/operator/vendors/delete`, {type: type, id: data.item.id, _token: this.csrfToken,customerId:this.customer.id}, 'json').then(_res => {
                                //remove the item from the list
                                this.fetchVendors();
                            });
                        }
                    }else{
                        if(data.id === null){
                            list.splice(data.index, 1);
                        }else{
                            //make a post request to delete the item
                            $.post(`/metadata/operator/outcomes/delete`, {type: type, id: data.item.id, _token: this.csrfToken,customerId:this.customer.id}, 'json').then(_res => {
                                //remove the item from the list
                                this.fetchOutcomes();
                            });
                        }
                    }
            });
        },

        processObject(data) {
            const value = JSON.parse(data);

            if (value?.npt?.frac?.tags) {
                if (typeof(value.npt.frac.tags) == 'string')
                    value.npt.frac.tags = JSON.parse(value.npt.frac.tags);
            } else {
                if(!value.hasOwnProperty('npt'))
                    value.npt = {};
                if(!value.npt.hasOwnProperty('frac'))
                    value.npt.frac = {};
                if(!value.npt.frac.hasOwnProperty('tags'))
                    value.npt.frac.tags = [];
            }


            if(!value.hasOwnProperty('name'))
                value.name = 'NOT SET';

            if(!value.hasOwnProperty('reports'))
                value.reports = {};
            if(!value.reports.hasOwnProperty('stage'))
                value.reports.stage = {};
            if(!value.reports.stage.hasOwnProperty('frac'))
                value.reports.stage.frac = {};
            if(!value.reports.stage.frac.hasOwnProperty('outputs'))
                value.reports.stage.frac.outputs = [];
            if(!value.reports.stage.frac.hasOwnProperty('tags'))
                value.reports.stage.frac.tags = [];
            value.reports.stage.frac.tags = value.reports.stage.frac.tags.map(tag => {
                let startReport = typeof(tag.startReport) == 'string' ? JSON.parse(tag.startReport) : tag.startReport;
                let endReport = typeof(tag.endReport) == 'string' ? JSON.parse(tag.endReport) : tag.endReport;
                if (startReport === undefined) startReport = false;
                if (endReport === undefined) endReport = false;
                return ({
                    ...tag,
                    startReport,
                    endReport
                })
            })
            if(typeof(value.reports.stage.frac.outputs) == 'string')
                value.reports.stage.frac.outputs = JSON.parse(value.reports.stage.frac.outputs);
            if(typeof(value.reports.stage.frac.tags) == 'string')
                value.reports.stage.frac.tags = JSON.parse(value.reports.stage.frac.tags);
            for(const tag of value.reports.stage.frac.tags) {
                if (!tag.hasOwnProperty('displayName'))
                    tag.displayName = 'No display name';
            }

            if(!value.reports.stage.hasOwnProperty('wireline'))
                value.reports.stage.wireline = {};
            if(!value.reports.stage.wireline.hasOwnProperty('outputs'))
                value.reports.stage.wireline.outputs = [];
            if(!value.reports.stage.wireline.hasOwnProperty('tags'))
                value.reports.stage.wireline.tags = [];
            value.reports.stage.wireline.tags = value.reports.stage.wireline.tags.map(tag => {
            let startReport = typeof(tag.startReport) == 'string' ? JSON.parse(tag.startReport) : tag.startReport;
            let endReport = typeof(tag.endReport) == 'string' ? JSON.parse(tag.endReport) : tag.endReport;
            if (startReport === undefined) startReport = false;
            if (endReport === undefined) endReport = false;
            return ({
                ...tag,
                startReport,
                endReport
            })
            })
            if(typeof(value.reports.stage.wireline.outputs) == 'string')
                value.reports.stage.wireline.outputs = JSON.parse(value.reports.stage.wireline.outputs);
            if(typeof(value.reports.stage.wireline.tags) == 'string')
                value.reports.stage.wireline.tags = JSON.parse(value.reports.stage.wireline.tags);

            return value;
        },

        beforeRouteLeave() {
            if (this.isDirty())
                return 'You have unsaved changes, are you sure you want to leave?';
            return null;
        },
        fetchOutcomes(){
            //make the get request with customer_id to /metadata/operator/outcomes
            $.get(`/metadata/operator/outcomes/${this.customer.id}`, 'json').then(_res => {
                this.nptOutcomes = _res;
            });
        },
        fetchVendors(){
            //make the get request with customer_id to /metadata/operator/vendors
            $.get(`/metadata/operator/vendors/${this.customer.id}`, 'json').then(_res => {
                this.nptVendors = _res;
            });
        }
    },

    mounted() {
        const _temp = this.processObject(this.metadata);
        this.value = _.cloneDeep(_temp);
        this.writtenValue = _.cloneDeep(_temp);
        this.customer = JSON.parse(this.customerdata);

        window.onbeforeunload = this.beforeRouteLeave;
        this.fetchOutcomes();
        this.fetchVendors();
    }
}
</script>

<style>
    #MetadataOperator #accordion table {
        margin-top: 20px !important;
        margin-bottom: 0px !important;
    }
</style>
<style scoped>
    #MetadataOperator {
        padding-bottom: 50px;
        max-width: 1400px;
        margin: auto;
        position: relative;
    }

    #headers-row {
        position: sticky;
        top: 0px;

        background-color: #1f2227;
        z-index: 100;
        padding: 10px 0px;
    }

    #control-changes-container {
        position: absolute;
        top: 15px;
        right: 15px;
    }

    .card-body #SearchInput {
        width: 350px;
        max-width: calc(100% - 135px);
    }
    .position-relative>span {
        position: absolute;
        top: 0px;
        right: 0px
    }
</style>
