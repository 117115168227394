var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "ToolstringDuplicateModal",
      staticClass: "dark-modal modal",
      attrs: { id: "ToolstringDuplicateModal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _vm._m(0),
          _vm._v(" "),
          _vm.toolstring
            ? _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c(
                    "div",
                    { staticStyle: { "margin-bottom": "15px" } },
                    [
                      _c("iws-input", {
                        ref: "nameInput",
                        attrs: {
                          label: "Name",
                          value: _vm.toolstring.name,
                          required: "true",
                          placeholder: "Give this toolstring a name",
                          "error-message": "Please provide a name",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.toolstring, "name", $event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      !_vm._isNullOrEmpty(_vm.wells)
                        ? _c(
                            "div",
                            { staticStyle: { "margin-top": "20px" } },
                            [
                              _c("iws-select", {
                                attrs: {
                                  label: "Well",
                                  value: _vm.selectedWell,
                                  options: _vm.wells,
                                  "display-name": "name",
                                  "value-name": "index",
                                },
                                on: {
                                  "update:value": function ($event) {
                                    _vm.selectedWell = $event
                                  },
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("iws-alert", {
                    attrs: { type: "info" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "body",
                          fn: function () {
                            return [
                              _vm._v(
                                "\n                        Please make sure to configure and customize once duplicated\n                    "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ],
                      null,
                      false,
                      501959353
                    ),
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c("iws-button", {
                attrs: {
                  id: "cancel_button",
                  type: "outline-light",
                  text: "Cancel",
                },
              }),
              _vm._v(" "),
              _c("iws-button", {
                attrs: { text: "Duplicate", click: _vm.onSaveClick },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("div", { staticClass: "modal-title" }, [
        _c("h5", [
          _vm._v(
            "\n                        Duplicate Toolstring\n                    "
          ),
        ]),
        _vm._v(" "),
        _c("div", [
          _vm._v(
            "\n                        Are you sure you want to duplicate this stage?\n                    "
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }