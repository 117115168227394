<template>
    <div class="d-flex flex-column w-100 border border-blue rounded" :style="'height:'+ height +'px'">
        <div id="overlay" class="flex-column" v-if="editMode">
            <div class="col-12 input-group ml-1" style="top:10%;">
                <span class="input-group-text" id="">Hide Duration</span>
                <div class="input-group-append">
                    <div class="input-group-text">
                        <input type="checkbox" aria-label="Checkbox for following text input" v-model="isHideDuration" @change="onHideDuration">
                    </div>
                </div>
            </div>
        </div>
        <div ref="wirelineControlContainer" v-show="showWirelineControls" class="px-2 py-1">
            <div class="row">
                <div class="col-4 pr-1 text-center">
                    <label for="wirelines">Selected Truck: </label>
                </div>
                <div class="col-8 pl-1">
                    <select id="wirelines" class="rounded text-center" style="width: inherit" v-model="selectedTruck" @change="getWirelineDiameter($event, true)">
                        <option v-for="(wirelineSystem, index) in wirelineSystems" :key="index" :value="wirelineSystem.number">{{getWirelineDisplay(wirelineSystem.number)}}</option>
                    </select>
                </div>
            </div>
        </div>
        <div style="flex:1" ref="diaSVG" class="d-flex align-items-center justify-content-center w-100">
            <svg v-bind="diaDimensions" viewBox="0 0 120 120" v-if="initRender">
                <circle cx="60" cy="65" r="50" stroke="#756D62" stroke-width="8" fill="none" />
                <circle v-if="diameterRad" cx="60" cy="65" :r="diameterRad" fill="lightblue" />
            </svg>
        </div>
        <div class="p-1"/>
        <div v-show="wirelineDiameter" ref="diaValue" class="px-3 align-self-center">{{wirelineDiameter}}"</div>
        <div class="p-1"/>
        <div v-show="!isHideDuration" class="d-flex flex-column border-top border-blue justify-content-center ">
            <div class="align-self-center" :style="'height:' + durationTextHeight + 'px'">Duration</div>
            <div class="h5 align-self-center" :style="'height:' + durationTextHeight + 'px'">{{timeElapsed}}</div>
        </div>
    </div>
</template>

<style scoped>
    .border-blue {
        border-color: #265C81!important;
    }
    #overlay {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(30,30,30,0.7);
        z-index: 2;
    }
    .overlay-center {
        display: flex !important;
        justify-content: center;
    }
</style>

<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';
import SignalRMixin from '../mixins/SignalRMixin.js';

const MAX_RAD_IN_PX =46;
const MAX_RAD_IN_INC = 4;
const DURATION_TEXT_HEIGHT = 24;

export default {
    props: {
        latestSignalRDataCollection: {
            type: Array,
            required: true
        },
        allJobWells: {
            type: Array,
            required: true
        },
        perforatingWellIndex: {
            type: Array,
            required: true
        },
        jobHourOffset: {
            type: Number,
            required: true,
            default: 0
        },
        wirelineSystems: {
            type: [Array],
            default: () => [],
        },
        wirelineSystemNumber: {
            type: Number,
            requried: false,
            default: 1
        },
        height: {
            type: Number
        },
        editMode: {
            type: [Boolean],
            required: true
        },
        item: {
            type: [Object],
            required: true
        },
        dashboardData: {
            type: Object,
            required: false
        },
    },
    mixins: [SignalRMixin],
    watch: {
        latestSignalRDataCollection: function(newValue, oldValue) {
            this.getWirelineDiameter();
        },
        wirelineDiameter: function(newValue, oldValue) {
            if(!oldValue && newValue) {
                this.diameterWasNull = true;
            }
            this.onResize();
        },
        showWirelineControls: function(newVal, oldVal) {
            this.onResize();
        }
    },
    data() {
        return {
            selectedTruck: null,
            selectedActiveWireline: null,
            isHideDuration: false,
            diameterRad: 0,
            initRender: false,
            timeElapsed: '',
            wirelineDiameter: null,
            noDataPlaceholder: '--',
            diaDimensions: {
                width: null,
                height: null
            },
            diameterWasNull: false,
            ro: null,
            durationTextHeight: DURATION_TEXT_HEIGHT
        };
    },
    mounted() {

        if (this.item?.selectedTruckId && this.wirelineSystems) {
            this.selectedTruck = this.wirelineSystems.find(truck => truck.number == this.item?.selectedTruckId).number;
        }

        if (this.perforatingWellIndex.length > 0) {
            // set the first wireline as the default
            this.selectedActiveWireline = this.perforatingWellIndex[0];
        }

        if(this.item?.options?.hideDuration) {
            this.isHideDuration = this.item.options.hideDuration;
        }

        if (this.latestSignalRDataCollection) {
            this.getWirelineDiameter();
        }
        this.ro = new ResizeObserver(this.onResize).observe(this.$refs.diaSVG);
        setInterval(this.calcDuration, 1000);
    },
    beforeDestroy () {
        if(this.ro) {
            this.ro.unobserve(this.$refs.diaSVG);
        }
    },
    computed: {
        showWirelineControls() {
            return this.perforatingWellIndex.length>0 && this.allJobWells.length>0 && this.wirelineSystems.length > 0;
        }
    },
    methods: {
        getWirelineControlHeight() {
            return this.showWirelineControls && this.$refs?.wirelineControlContainer ? this.$refs.wirelineControlContainer.clientHeight : 0;
        },
        getWirelineDisplay(event) {
            let truckNumber = event;
            let wirelineWell = this.allJobWells.find(well => {
                return well?.activityData?.service_id === truckNumber && well?.activity === 'wireline';
            })
            let wireline = this.wirelineSystems.find(wireline => wireline.number === truckNumber);
            if (wirelineWell) {
                return `${wireline.name} (${wirelineWell.name})`;
            } else {
                return `${wireline.name}`;
            }
        },
        onHideDuration() {
            this.item.options = {
                hideDuration: this.isHideDuration
            };
        },
        onResize() {
            if(this.$refs.diaSVG) {
                const height = this.$refs.diaSVG.offsetHeight;
                const width = this.$refs.diaSVG.offsetWidth;
                this.initRender = true;
                if(height >= width ) {
                    this.diaDimensions = { width: width - this.getWirelineControlHeight()};
                } else {
                    if(this.diameterWasNull) {
                        this.$nextTick(()=>{
                            const heightReduce = this.$refs.diaValue.clientHeight + this.getWirelineControlHeight();
                            this.diaDimensions = { height: height - heightReduce};
                        });
                    } else {
                        this.$nextTick(() => {
                            this.diaDimensions = { height: height - this.getWirelineControlHeight()};
                        });
                    }
                }
            }
        },
        getWirelineDiameter(event = null, saveDashboardItems = false) {
            let toolNumber = null;
            if (event) {
                let wirelineTruckNumber = event;
                toolNumber = wirelineTruckNumber;
            } else {
                toolNumber = this.wirelineSystemNumber;
            }

            const wireLineData = this?.latestSignalRDataCollection.find(dataPoint => dataPoint.tagName == 'wireline_' + toolNumber + '_tool_diameter');

            if (wireLineData) {
                this.wirelineDiameter = +parseFloat(wireLineData.dataVal).toFixed(1); //to fixed returns a string which is cast back to a number
                this.getDiaRadius(this.wirelineDiameter);
            } else {
                this.wirelineDiameter = null;
                this.diameterRad = null;
            }

            if (saveDashboardItems && this.dashboardData && this.item && (this.dashboardData.isIwsUser || this.dashboardData.isAdmin || this.dashboardData.isCompanyAdmin)) {
                if (this.item.selectedTruckId && this.selectedTruck) {
                    this.item.selectedTruckId = this.selectedTruck;
                    this.dashboardData.selectedDashboard.items.find(item => item.i == this.item.i).selectedTruckId = this.selectedTruck;
                    this.dashboardData.saveDashboardItems(this.dashboardData.selectedDashboard);
                }
            }
        },
        getDiaRadius(dia) {
            this.diameterRad = dia*(MAX_RAD_IN_PX/MAX_RAD_IN_INC);
        },
        calcDuration: function () {
            if (this.selectedActiveWireline == null && this.perforatingWellIndex.length > 0) {
                this.selectedActiveWireline = this.perforatingWellIndex[0];
            }

            if (this.selectedActiveWireline != null && this.allJobWells[this.selectedActiveWireline] != null && this.allJobWells[this.selectedActiveWireline]?.start) {
                const now = moment.utc();
                const jobStartUtc = moment(this.allJobWells[this.selectedActiveWireline].start);
                const duration = now.diff(jobStartUtc);

                if(!duration || duration < 0) { //if no duration or negative, something went wrong
                    this.timeElapsed = this.noDataPlaceholder;
                    return;
                }

                // get hours/minutes/seconds
                let seconds = Math.floor((duration / 1000) % 60);
                let minutes = Math.floor((duration / (1000 * 60)) % 60);
                const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

                // add preceding 0's to minutes and seconds
                minutes = (minutes < 10) ? '0' + minutes : minutes;
                seconds = (seconds < 10) ? '0' + seconds : seconds;

                this.timeElapsed = `${hours}:${minutes}:${seconds}`;
            } else {
                this.timeElapsed = this.noDataPlaceholder;
            }
        }
    }
};
</script>
