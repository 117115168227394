<template>
  <div v-if="config" class="text-light" style="position:absolute;top:0px;height:100%;width:100%;"  :ref="myRef">
    <img class="img-fluid" :src="imageOpen(position)" v-if="position != null" :style="valveStyle" ref="openImage">
    <img id="lockImage" class="img-fluid" :src="imageLock(unlocked)" :style="locksStyle" v-if="showWellLocks && unlocked != null" ref="lockImage">
    <img id="dotsImage" class="img-fluid" :src="imageLock(unlocked)" :style="dotsStyle" v-if="showWellLocks && unlocked != null" ref="dotsImage">
  </div>
</template>

<script>
import GlobalFunctions from '../GlobalFunctions';
export default {
    data() {
        return {
            myRef: '',
            top: 0,
            left: 0
        };
    },
    props: ['position','unlocked','config',
        'imgId','currentStep','index','showWellLocks'],
    computed: {
        active() {
            if(this.currentStep) {
                let found = false;
                for(let i=0;i<this.currentStep.valves.length;i++) {
                    if(this.index == this.currentStep.valves[i].index)
                    {found = true;}
                }
                return found;
            } else
            {return false;}
        },
        dotsStyle() {
            const style = {
                'top': '0px',
                'left': '0',
                'right': '0',
                'margin': 'auto',
                'position': 'absolute',
                'height': '100%',
                '-webkit-mask-image': 'url(' + window.location.origin + '/get-wellhead-image?config_number='+this.config+')',
                '-webkit-mask-size': '100% 100%',
                'mask-image': 'url(' + window.location.origin + '/get-wellhead-image?config_number='+this.config+')',
                'mask-size': '100% 100%'
            };
            return style;
        },
        locksStyle() {
            const style = {
                'top': '0px',
                'left': '0',
                'right': '0',
                'margin': 'auto',
                'position': 'absolute',
                'height': '100%',
                '-webkit-filter': 'invert(1) hue-rotate(180deg)',
                'filter': 'invert(1) hue-rotate(180deg)'
            };
            return style;
        },
        myWidth() {
        // if reference is undefined that means component not mounted width will be 0
            return this.$refs[this.myRef]? this.$refs[this.myRef].clientWidth : 0; 
        },
        myHeight() {
        // if reference is undefined that means component not mounted height will be 0
            return this.$refs[this.myRef]? this.$refs[this.myRef].clientHeight : 0;
        },
        valveStyle() {
            const style =  {
                'top': '0px',
                'left': '0',
                'right': '0',
                'margin': 'auto',
                'position': 'absolute',
                'height': '100%',
                'z-index': ''
            };
            if(this.position < 0) {
                style['z-index'] = '1';
            }
            return style;
        }
    },
    methods: {
        imageOpen(statePosition) {
            let valveState =  'unknown';
            if(statePosition === 0) {
                valveState = 'open';
            } else if(statePosition === 100) {
                valveState = 'close';
            } else if(statePosition > 0 & statePosition < 100) {
                valveState = 'trans';
            }
            return `/get-wellhead-image?config_number=${this.config}&valve_index=${this.index}&valve_state=${valveState}`;
        },
        imageLock(stateUnlocked) {
            let valveState = 'lock';
            if(stateUnlocked) {
                valveState = 'unlock';
            }
            return `/get-wellhead-image?config_number=${this.config}&valve_index=${this.index}&valve_state=${valveState}`;
        }
    },
    created() {
        //console.log("component created");
        let t='';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        for(let i=0;i<20;i++) {
            t += characters.charAt(Math.floor(Math.random() * characters.length));
        }
        //console.log(t);
        this.myRef = t;
    }
};
</script>