var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "TableBase" } },
    [
      _vm._t("breadcrumbs", function () {
        return [
          _c("iws-breadcrumbs", {
            staticStyle: { margin: "25px 0px 5px" },
            attrs: { path: _vm.breadCrumbPath },
          }),
        ]
      }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "page-title" },
        [
          _vm._t("title", function () {
            return [_vm._v("\n            " + _vm._s(_vm.title) + "\n        ")]
          }),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "w-100 d-flex justify-content-between align-items-baseline flex-wrap",
          attrs: { id: "control-row" },
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-items-baseline" },
            [
              _c(
                "div",
                { attrs: { id: "search-bar-container" } },
                [
                  _c("iws-search", {
                    attrs: { value: _vm.searchFilter, disabled: _vm.busy },
                    on: {
                      "update:value": function ($event) {
                        _vm.searchFilter = $event
                      },
                      input: function ($event) {
                        return _vm.filtering()
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-light",
                  attrs: { id: "filters-button", disabled: _vm.busy },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("filter")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "fas fa-filter" }),
                  _vm._v(" Filters\n            "),
                ]
              ),
              _vm._v(" "),
              _vm._t("button-extensions"),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "d-flex align-items-baseline",
              attrs: { id: "right-option" },
            },
            [_vm._t("right-option")],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _vm.filtersAreActive
        ? _c(
            "div",
            {
              staticClass: "w-100 user-select-none",
              attrs: { id: "filter-row" },
            },
            [
              _c(
                "div",
                { attrs: { id: "filters-badges-container" } },
                [
                  _vm._t("active-filters", function () {
                    return [
                      _vm._l(_vm.filters, function (list) {
                        return _vm._l(list, function (_value, index) {
                          return _c(
                            "div",
                            { staticClass: "badge badge-outline m-0" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(_value) +
                                  " "
                              ),
                              !_vm.busy
                                ? _c("i", {
                                    staticClass: "fas fa-times clickable",
                                    on: {
                                      click: function ($event) {
                                        return _vm.removeFromFilter(list, index)
                                      },
                                    },
                                  })
                                : _vm._e(),
                            ]
                          )
                        })
                      }),
                    ]
                  }),
                ],
                2
              ),
              _vm._v(" "),
              _vm.canClearAllFilters
                ? _c(
                    "button",
                    {
                      staticClass: "btn btn-outline primary-form",
                      attrs: { id: "clear-filters-button" },
                      on: { click: _vm.clearFilters },
                    },
                    [
                      _c("i", { staticClass: "fas fa-times" }),
                      _vm._v(" Clear Filters\n        "),
                    ]
                  )
                : _vm._e(),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("content", function () {
        return [
          _c("iws-table", {
            attrs: {
              busy: _vm.busy,
              items: _vm.items,
              filter: _vm.filter,
              sortAsc: _vm.sortAsc,
              columns: _vm.columns,
              sortByCol: _vm.sortByCol,
            },
          }),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }