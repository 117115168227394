var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-popover",
        {
          attrs: {
            target: "export-popover",
            triggers: "hover",
            placement: "left",
            delay: { show: 0, hide: 500 },
          },
        },
        [
          _c("div", { staticClass: "btn-group-vertical" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary grey-button mb-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onExportData("csv")
                  },
                },
              },
              [_vm._v("\n                Export as CSV\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary grey-button",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onExportData("json")
                  },
                },
              },
              [_vm._v("\n                Export as JSON\n            ")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.disabledZoomMessage,
              expression: "disabledZoomMessage",
            },
          ],
          style: {
            zIndex: 10,
            position: "absolute",
            bottom: "10px",
            right: "10px",
            backgroundColor: "#3490dc",
            fontSize: "0.8vw",
            padding: "10px",
          },
        },
        [_vm._v("Click the chart to enable scroll zoom")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "d-flex position-relative",
          attrs: { tabindex: "-1" },
          on: {
            mouseleave: function ($event) {
              _vm.hoveringChart = false
            },
            mouseover: function ($event) {
              _vm.hoveringChart = true
            },
            wheel: _vm.zoomDisabledMessage,
            "!focus": function ($event) {
              return _vm.enableZoomOnFocus()
            },
            blur: function ($event) {
              return _vm.disableZoomOnBlur()
            },
          },
        },
        [
          _c("div", {
            staticClass: "position-absolute",
            style:
              "height:" +
              _vm.labelCoveringOverlayHeight +
              "px; width:" +
              _vm.labelCoveringOverlayWidth +
              "px;",
          }),
          _vm._v(" "),
          _c("bar-chart", {
            ref: "barChart",
            style: { height: _vm.height + "px", width: "97%" },
            attrs: {
              "chart-data": _vm.summaryData,
              options: _vm.summaryBarOptions,
              id: "bar-chart",
            },
          }),
          _vm._v(" "),
          _c(
            "span",
            {
              directives: [
                {
                  name: "tooltip",
                  rawName: "v-tooltip:top",
                  value: "Export",
                  expression: "'Export'",
                  arg: "top",
                },
              ],
              staticStyle: { height: "20px" },
              attrs: { id: "export-popover" },
            },
            [_c("i", { staticClass: "fas fa-download" })]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("div", [_vm._m(0)]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm",
        staticStyle: {
          position: "absolute",
          top: "30px",
          right: "0",
          left: "0",
          margin: "auto",
        },
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "sr-only mb-5" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }