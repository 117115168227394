<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Layer">
        <path id="Layer_2" d="M0 0H11.4V11.4H0V0Z" fill="#F25022"/>
        <path id="Layer_3" d="M12.6001 0H24.0001V11.4H12.6001V0Z" fill="#7FBA00"/>
        <path id="Layer_4" d="M0 12.6H11.4V24H0V12.6Z" fill="#00A4EF"/>
        <path id="Layer_5" d="M12.5996 12.6H23.9996V24H12.5996V12.6Z" fill="#FFB900"/>
        </g>
    </svg>
</template>
<script>
    export default{

    }
</script>
<style scoped>
.signinwithmicrosoftbutton{
    display: flex;
    padding: 10px 18px;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    border-radius: 4px;
    border: 1px solid #6B7380;
    /* Shadow/xs */
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
