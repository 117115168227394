<template>
<div>
    <div v-if="showChildren" id="CameraViewer">
        <camera-navigation :user="user" :device="device" :work-order-id="workOrderId" is-home />

        <template v-if="!_isNullOrEmpty(device?.cameras)">
            <VideoScrubberV2
                :user="user"
                :workOrderId="workOrderId"
                :cameraList="device.cameras"
                :vodList="vods"
                :alertList="alerts"
                :clipList="clips"
                :hourOffset="job?.hourOffset"
                :permissions="permissions"
            />
            
            <div class="row">
                <template v-for="(camera, index) in device.cameras">
                    <div v-if="!_isFalsy(camera?.primaryStream)" class="col-6" :key='"player_" + index'>
                        <div class="full-height">
                            <div class="camera-details title-row">
                                {{ camera.displayName || 'name' }}
                            </div>

                            <video-player class="clickable"
                                style="height: calc(100% - 40px)"
                                :ref="`videoPlayer_${index}`"
                                :user="user"
                                :workOrderId="workOrderId"
                                :device="device"
                                :permissions="permissions"
                                :vods="vods"
                                :index="index"
                                :control="false"
                                :video-only="true"
                                @clicked="jumpToCamera(camera)"
                            />
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <div v-else class="error-message">
            No cameras found for this job!
        </div>
    </div>
    <div v-else class="player-spinner">
        <b-spinner label="Loading..."></b-spinner>
    </div>
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, isIterable } = GlobalFunctions;

import VideoScrubberV2 from './VideoScrubberV2.vue';

export default {
    props: [ 'user', 'workOrderId', 'device', 'permissions', 'job', 'vods', 'alerts', 'clips' ],

    components: {
        VideoScrubberV2
    },

    data: () => ({
        showChildren: true
    }),

    methods: {
        _isNullOrEmpty(value) {
            return isNullOrEmpty(value);
        },
        _isFalsy(value) {
            return isFalsy(value);
        },

        jumpToCamera(camera) {
            // Destroy active cams and enter loading state while next page loads (can take a while)
            if (isIterable(this.device?.cameras))
                this.device.cameras.forEach((camera, index) => {
                    if (!isFalsy(this.$refs[`videoPlayer_${index}`]) && !isFalsy(this.$refs[`videoPlayer_${index}`][0]?.player?.remove)) {
                        try {
                            this.$refs[`videoPlayer_${index}`][0].player.remove();
                            this.$refs[`videoPlayer_${index}`][0].player.remove = null;
                        } catch(e) {
                            console.log(e);
                        }
                    }
                });

            this.showChildren = false;
            window.location.href = `/cameras/viewer/${this.workOrderId}/${camera.cameraConfigId}/${camera.primaryStream.cameraStreamConfigId}`; 
        }
    },
    
    created() {
        this.device?.cameras?.forEach(camera => {
            camera.llhls = camera.url;
        });

        // Once we actually navigate away from this page, leave the loading state so cams can be reloaded upon back button
        window.onunload = function(){
            setTimeout(() => {
                this.showChildren = true;
            }, 2000)
        }
    }
}
</script>

<style scoped>
    #CameraViewer {
        padding: 20px;
    }

    .row:not(#job-control-bar) {
        margin-top: 10px;
        margin-right: -7.5px !important;
        margin-left: -7.5px !important;
    }
    .col-6 {
        padding-left: 7.5px !important;   
        padding-right: 7.5px !important;
        padding-bottom: 15px !important;
    }
    .col-6>div {
        background-color: #101828;
        border-radius: 10px;
    }

    .camera-details {
        padding: 5px 10px;
    }
    .camera-details>div {
        display: inline-block; 
    }
    .camera-details>div:last-of-type {
        float: right;
    }

    .title-row {
        font-size: 20px;
    }

    .error-message {
        font-size: 24px;
        color: var(--danger);
        text-align: center;
        margin: 20px auto;
    }
    .player-spinner {
        padding-top: 50px;
        padding-bottom: 50px;

        text-align: center;
    }
</style>