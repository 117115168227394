<template>
    <div 
        id="timeline-wrapper"
        ref="timelineWrapperRef"
        @mousedown="mousedown"  
        @mousemove="mousemove"
        @mouseup="mouseup"
        v-on-clickaway="timelineClickAway"
    >
        <vue-slider 
            v-if="min!==null && max!==null"
            :key="min"
            ref="slider"
            v-model="value"
            @drag-start="onDragStart"
            @drag-end="onDragEnd"
            @change="onRangeChange"
            @dragging="onDragging"
            :clickable="false"
            :dragOnClick="false"
            :duration="0"
            :min="min"
            :max="max"
            tooltip="none"
            :disabled="disabled"
            :order="true"
            :dotSize="18"
            :enableCross="startNPT? false: true"
        >
        </vue-slider>

        <chart-comments-create v-if="showPopup"
            v-bind="popupProps"
            ref="comment-popup"
            :activeDrawing="activeDrawing"
            @onDateChange="onPopupDateChange"
            :popupPosition="popupPosition"
            @onDismiss="removeSelection"
            :wellStageIntervals="wellStageIntervals"
            :hasEditPermission="hasEditPermission"
            :timeseriesHeight="timeseriesHeight"
            :timeseriesWidth = "timeseriesWidth"
        />
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
const EVENT_ACTIVITIES = [{ id: 1, type: 'none' }, { id: 2, type: 'maintenance' }, { id: 3, type: 'frac' }, { id: 4, type: 'wireline' }];
import { directive as onClickaway } from 'vue-clickaway';
import moment from 'moment';
import ChartCommentMarker from './ChartCommentMarker.vue';
import GlobalFunctions from '../../GlobalFunctions';
const { flushToasts, toast } = GlobalFunctions;
import _ from 'lodash'
import eventBus from '../../eventBus';

export default {
    props: {
        chartcomments: Object,
        chart: Object,
        jobHourOffset: Number,
        jobNumber: String,
        jobID: String,
        userID: String,
        componentSpecificPermissions: Object,
        eventReasons: Array,
        stepDescriptions: Array,
        nptOutcomes: Array,
        customerVendors: Array,
        contractors: Array,
        eventActivityEventReasons: Array,
        wells: Array,
        readOnly: Boolean,
        hideListHeader: Boolean,
        onHover: Function,
        headerStyle: String,
        signalRConnected: Boolean,
        wellStageIntervals: Object,
        config: Object,
        timeseriesHeight: Number,
        timeseriesWidth: Number
    },
    directives: {
        onClickaway: onClickaway,
    },
    components: {
        VueSlider
    },
    mounted() {
        if(this.isFeatureFlagged('DRAW_NPT')) {
            this.disabled = false;
        }
        this.$options.clickCount = [];
        this.$emit('changeStatisticMode'); //enables chart highlight feature
        this.createEventsLookup();
        window.addEventListener('mouseup', this.mouseup);
        window.addEventListener('mousemove', this.mousemove);

        if (!this.chartcomments.comments)
        {
            return;
        }

        this.setupComments();

        eventBus.$on('signalr-updated', this.signalREventHandler);

        this.$nextTick(() =>{
            this.timelineElement = this.$refs['timelineWrapperRef'];
            this.createAllMessageMarkers();
        });
    },
    beforeDestroy() {
        eventBus.$off('signalr-updated', this.signalREventHandler);
    },
    data() {
        return {
            value: [],
            isDragging: false,
            activeDrawing: false,
            min: null,
            max: null,
            popupProps: {},
            showPopup: false,
            popupCoordinate: 0,
            sharedState: {
                comments: []
            },
            messageMarkers: [],
            chartCommentLookupByTimestamp: {},
            eventActivityEventReasonsLookup: {},
            disabled: true,
            popupPosition: '',
            startNPT: null,
            fetchedNPTEvents: [],
            fetchedFlags: [],
            editingNPT: [],
            disableDismiss: false,
            lastSliderPosition: [],
            toastIsShowing: null
        };
    },
    computed: {
        hasEditPermission() {
            return this.componentSpecificPermissions['timeseries-edit'] === true;
        }
    },
    methods: {
        onDragging(val) {
            if(val.length == 2) {
                this.setSliderValues(val[0], val[1]);
            }
        },
        onRangeChange() {
            if (!this.hasEditPermission) {
                if (!this.toastIsShowing)
                    this.toastIsShowing = toast({
                        title: 'Missing permission: NPT Editor',
                        variant: 'danger'
                    }).then(_res => this.toastIsShowing = null);
                return;
            }

            //show popup
            if(this.value && this.value.length > 0 ) {
                let start = this.value[0];
                let end = this.value[1];

                if (start > end)
                    [start, end] = [end, start];

                const now = moment.utc().valueOf();
                if (start >= now)
                    //don't let the user select future times
                    start = now;
                if (end >= now)
                    //don't let the user select future times
                    end = now;

                    this.popupPosition = this.config?.controlsPosition == 'left' && this.$refs.timelineWrapperRef?.clientWidth > 520 ? `left: ${this.config?.controlsSize || 0} !important; top: 0px !important;` : ''
                this.openCreateCommentPopover(start, end);
                this.$nextTick(()=>{
                    this.showPopup = true;
                });
            } else {
                this.showPopup = false;
                this.$emit('onChange', null, null);
                this.lastSliderPosition = [];
            }

            this.$nextTick(()=>{
                this.drawArea();
            });
        },
        drawArea(){
            const dotElements = document.getElementsByClassName('vue-slider-dot-handle');
            this.$options.dotElements = dotElements;
            if(dotElements.length > 0){
                //draw area select
                const sEvent = this.getStartDotLocation();
                const eEvent = this.getEndDotLocation();
                this.$emit('onChange', sEvent, eEvent);
            } else {
                this.$emit('onChange', null, null);
            }
        },
        setSliderValues(start, end) {
            if(start == null && end == null) {
                this.$refs.slider.setValue([]);
            } else {
                const now = moment.utc().valueOf();

                if(start >= now) {
                    //don't let the user select future times
                    start = now;
                }

                if(end >= now) {
                    //don't let the user select future times
                    end = now;
                }
                this.$refs.slider.setValue([start, end]);
            }
        },
        onDragStart() {
            this.$options.slideEdit = true;
            this.isDragging = true;
            this.disableDismiss = true;
        },
        timelineClickAway(){
            this.shouldDismissModal('timeline-event');
        },
        onDragEnd() {
            this.$options.slideEdit = false;
            this.isDragging = false;
            //clamp start date to start event's date
            if(this.startNPT && this.value.length > 1) {
                const unixStart = moment.utc(this.startNPT.data.startTime).valueOf();
                this.setSliderValues(unixStart, this.value[1]);
            }
        },
        onNPTAddEndClick(npt) {
            const unixStart = moment.utc(npt.data.startTime).valueOf();
            const unixEnd =  moment.utc(npt.data.startTime).add({ seconds: 1}).valueOf();
            this.startNPT = npt;
            this.$nextTick(()=>{
                this.setSliderValues(unixStart, unixEnd);
            });
        },
        onNPTEditClick(npt) {
            let pair = this.sharedState.comments.find((item) => item?.type == 'npt' 
                                                                && item?.event.reference == npt.reference
                                                                && item?.event.subCategory != npt.subCategory);
            this.editingNPT = [npt];
            if (pair && pair.event.subCategory == 'end')
                this.editingNPT.push(pair.event);
            else if (pair && pair.event.subCategory == 'start')
                this.editingNPT.unshift(pair.event);
            
            const start = moment.utc(this.editingNPT[0].data.startTime);
            let end = start;
            if (pair)
                end = moment.utc(this.editingNPT[1].data.endTime);
       
            this.onPopupDateChange(start, end);
        },
        onPopupDateChange(start, end) {//UTC
            const minVal = start.clone().add({ hours: -1 });
            const maxVal = end.clone().add({ hours: 1 });
            const startT = start.valueOf();
            const endT = end.valueOf();
            if((startT > this.min) && (endT < this.max)) {
                this.setSliderValues(startT, endT);
            } else {
                const localStartTime = minVal.local().toDate();
                const localEndTime = maxVal.local().toDate();
                this.$emit('moveChart', localStartTime, localEndTime);
                this.setSliderValues(startT, endT);
                this.min = minVal.valueOf();
                this.max = maxVal.valueOf();
            }
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        mousemove: function(event) {
            if(this.disabled || this.isDragging || this.$options.slideEdit) {
                return;
            }
            
            if(this.activeDrawing) {
                this.registerSelectionEnd(event);
            }
        },
        mouseup: function(event) {
            if(this.disabled || this.isDragging) {
                return;
            }

            if(this.activeDrawing) {
                this.registerSelectionEnd(event);
            }
            this.activeDrawing = false;
        },
        mousedown: function(event) {
            if(this.disabled || this.isDragging || this.$options.slideEdit || this.showPopup) {
                return;
            }

            this.disableDismiss = true;

            if(!event.target.classList.contains('dot-clickable'))
            {
                this.$options.start = { //no need state management
                    event,
                    value: this.getSliderValueFromEvent(event)
                };
                this.setSliderValues(this.$options.start.value ,this.$options.start.value);
                this.activeDrawing = true;
            }
        },
        getStartDotLocation() {
            const startEvent = new CustomEvent('mouse-start', {});
            const dotElements = this.$options.dotElements;
            const clientRect1 = dotElements[0].getBoundingClientRect();
            const clientRect2 = dotElements[1].getBoundingClientRect();
            let clientRect = null
            let index = 0
            if(clientRect1.left > clientRect2.left) {
                index = 1;
                clientRect = clientRect2;
            } else {
                clientRect = clientRect1;
            }

            const offset = (clientRect.width/2)
            const clientX = clientRect.left + offset;
            const clientY = clientRect.top;
            startEvent.clientX = clientX;
            startEvent.clientY = clientY;
            startEvent.getBoundingClientRect = dotElements[index].getBoundingClientRect;
            return startEvent;
        },
        getEndDotLocation() {
            const endEvent = new CustomEvent('mouse-start', {});
            const dotElements = this.$options.dotElements;
            const clientRect1 = dotElements[0].getBoundingClientRect();
            const clientRect2 = dotElements[1].getBoundingClientRect();
            let clientRect = null
            let index = 1;
            if(clientRect1.left > clientRect2.left) {
                clientRect = clientRect1;
                index = 0;
            } else {
                clientRect = clientRect2;
            }

            const offset = (clientRect.width/2)
            const clientX = clientRect.left + offset;
            const clientY = clientRect.top;
            endEvent.clientX = clientX;
            endEvent.clientY = clientY;
            endEvent.getBoundingClientRect = dotElements[index].getBoundingClientRect;
            return endEvent;
        },
        shouldDismissModal: function(eventType) {
            if(this.disableDismiss) {
                this.disableDismiss = false;
                return;
            }
            //if this function gets called twice at once that means the user clicked outside the chart comment area and popup
            this.$options.clickCount.push(eventType);
            setTimeout(() =>{
                if(this.$options.clickCount.length%2 == 0 && 
                    (this.$options.clickCount[0] == 'timeline-event' && this.$options.clickCount[1] !== 'timeline-event') || 
                    (this.$options.clickCount[1] == 'timeline-event' && this.$options.clickCount[0] !== 'timeline-event')
                ) {
                    this.removeSelection();
                }
                this.$options.clickCount = [];
            }, 100);
        },
        removeSelection: function() {
            this.setSliderValues(null ,null);
            this.showPopup = false;
        },	
        registerSelectionEnd: function(event) {
            this.$options.end = { //no need state management
                event,
                value: this.getSliderValueFromEvent(event)
            };

            this.setSliderValues(this.$options.start.value, this.$options.end.value);
        },
        correctTimelineWidth: function(chart) {
            const axis = chart?.scales['date-axis'] ?? chart?.scales['custom-date-axis'];
            if(!axis) {
                return;
            }

            this.manageSliderPosition(parseInt(axis.min), parseInt(axis.max));
            
            this.min = parseInt(axis.min);
            this.max = parseInt(axis.max);

            const timeline = this.$refs['timelineWrapperRef'];

            timeline.style.left = chart.chartArea.left + 'px';
            timeline.style.width = String(chart.chartArea.right - chart.chartArea.left) + 'px';

            this.updateMessageMarkerPositions();
        },
        updateCommentTimelineData: function(chart=this.$parent.$refs.lineChart.$data._chart){
            const axis = chart?.scales['date-axis'] ?? chart?.scales['custom-date-axis'];
            if(!axis) {
                return;
            }   
            
            this.manageSliderPosition(parseInt(axis.min), parseInt(axis.max));
            
            this.min = parseInt(axis.min);
            this.max = parseInt(axis.max);

            this.setupNPTEvents(this.min, this.max);
            this.setupFlags(this.min, this.max);
            this.$nextTick(()=>{
                this.drawArea();
            })
        },
        async setupNPTEvents(startUnix, endUnix) {
            const url = `/npt/${this.jobNumber}`;
            const data = {
                startUnix,
                endUnix
            };
            const nptResponse = await $.get(url, data);
            this.fetchedNPTEvents = nptResponse;
            this.matchNPTPairs();
            this.deleteNPTMarkers();

            this.$nextTick(()=>{
                this.setupComments();
                this.$emit('createNPTAnnotations', this.fetchedNPTEvents);
            });
        },
        matchNPTPairs() {
            //according to Isaac it seems ADX reference numbers does not match with MongoDB reference numbers. Uppercase vs Lowercase. Converting all to lowercase to fix this bug.
            const eventsMap = {};
            this.fetchedNPTEvents.forEach((event) => {
                const reference = event.reference.toLowerCase(); // Convert to lowercase
                if (!eventsMap.hasOwnProperty(reference)) {
                    eventsMap[reference] = {};
                }

                if (event.subCategory === 'start') {
                    eventsMap[reference].start = event;
                } else if (event.subCategory === 'end') {
                    eventsMap[reference].end = event;
                }
            });

            //if there's mismatch information between a pair, match them
            _.forEach(eventsMap, (eventPair) => {
                const startEvent = _.find(eventPair, { subCategory: 'start' });
                const endEvent = _.find(eventPair, { subCategory: 'end' });

                if (startEvent && endEvent) {
                    startEvent.data = {
                        ...startEvent.data,
                        customerVendorIds: endEvent.data.customerVendorIds,
                        nptOutcomeId: endEvent.data.nptOutcomeId,
                        comment:  endEvent.data.comment
                    }
                }
            });

            const syncedNPTEvents = _.flatMap(eventsMap, (eventPair) => {
                const startEvent = _.find(eventPair, { subCategory: 'start' });
                const endEvent = _.find(eventPair, { subCategory: 'end' });

                if (!endEvent) {
                    return startEvent;
                }

                if (!startEvent) {
                    return endEvent;
                }

                return [startEvent, endEvent];
            });
            this.fetchedNPTEvents = syncedNPTEvents;
        },
        async setupFlags(startUnix, endUnix) {
            const url = `/flags/${this.jobNumber}`;
            const data = {
                startUnix,
                endUnix
            };
            const flagsResponse = await $.get(url, data);
            this.fetchedFlags = flagsResponse;

            this.deleteFlagMarkers();

            this.$nextTick(()=>{
                this.setupComments();
            });
        },
        deleteNPTMarkers: function()
        {
            const markersToDelete = this.messageMarkers.filter(m => m.element.nptEvents.length > 0);
            markersToDelete.forEach(marker =>
            {
                const keyToRemove = marker.timestamp;

                marker.element.$destroy();

                this.timelineElement.removeChild(marker.element.$el);
                const markerIndex = this.messageMarkers.indexOf(marker);

                if(markerIndex >= 0)
                {
                    this.messageMarkers.splice(markerIndex, 1);
                }

                delete this.chartCommentLookupByTimestamp[keyToRemove];
            });
        },
        deleteFlagMarkers: function()
        {
            const markersToDelete = this.messageMarkers.filter(m => m.element.flagsArray.length > 0);
            markersToDelete.forEach(marker =>
            {
                const keyToRemove = marker.timestamp;

                marker.element.$destroy();

                this.timelineElement.removeChild(marker.element.$el);
                const markerIndex = this.messageMarkers.indexOf(marker);

                if(markerIndex >= 0)
                {
                    this.messageMarkers.splice(markerIndex, 1);
                }

                delete this.chartCommentLookupByTimestamp[keyToRemove];
            });
        },
        getSliderValueFromEvent: function(event) {
            const componentLeftXPos = this.$refs['timelineWrapperRef'].getBoundingClientRect().left;
            const componentRightXPos = this.$refs['timelineWrapperRef'].getBoundingClientRect().right;
            const componentWidth = (componentRightXPos - componentLeftXPos);
            const xMousePosition = event.clientX - componentLeftXPos;
            const sliderPercentage = (xMousePosition/componentWidth)*100;
            return this.min + ((this.max - this.min) * (sliderPercentage/100));
        },
        openCreateCommentPopover(startUnix, endUnix)
        {
            const self = this;
            let mode = 'fullEvent';
            if((startUnix == endUnix && this.startNPT === null && this.editingNPT.length == 0) || this.editingNPT.length == 1) {
                mode = 'startEvent'
            }

            this.popupProps = {
                timestamp: endUnix,
                startTimestamp: startUnix,
                jobNumber: this.jobNumber,
                jobID: this.jobID,
                userID: this.userID,
                jobHourOffset: this.jobHourOffset,
                eventActivities: EVENT_ACTIVITIES,
                eventReasons: this.stepDescriptions,
                nptOutcomes: this.nptOutcomes,
                customerVendors: this.customerVendors,
                contractors: this.contractors,
                eventActivityEventReasonsLookup: this.eventActivityEventReasonsLookup,
                wells: this.wells,
                headerStyle: this.headerStyle,
                mode: mode,
                startNPT: this.startNPT,
                editingNPT: this.editingNPT,
                onCommentsUpdated: () => {
                    console.log("onCommentsUpdated")
                },
                onMarkerRemoved: () => 
                {
                    self.startNPT = null;
                    self.editingNPT = [];
                    console.log('onMarkerRemoved');
                },
                onPopoverShown: () => 
                {
                    console.log('onPopoverShown');
                },
                onPopoverHidden: () => 
                {
                    console.log('onPopoverHidden');
                },
            }
        },
        createCommentsArray(rawComments) {
            let updatedWithEvents = rawComments.comments;
            if(this.chartcomments?.alerts?.length > 0) {
                let alertComments = this.chartcomments.alerts.map(item => this.convertAlertToComment(item));
                alertComments = alertComments.filter(item => item?.type === 'alert' && item?.event?.subCategory === "threshold" );
                updatedWithEvents = [...updatedWithEvents, ...alertComments];
            }

            if(this.fetchedFlags?.length) {
                const flags = this.fetchedFlags.map(item => this.convertFlagToComment(item));
                updatedWithEvents = [...updatedWithEvents, ...flags]
            }

            if(this.fetchedNPTEvents?.length) {
                const nptEvents = this.fetchedNPTEvents.map(item => this.convertNPTToComment(item));
                updatedWithEvents = [...updatedWithEvents, ...nptEvents]
            }
            return updatedWithEvents;
        },
        convertAlertToComment(event) {       
            const comment = {
                'id': event.id,
                'start_time': event.created_at,
                'type': 'alert',
                'event': event
            };

            return comment;
        },
        convertFlagToComment(event) {
            const comment = {
                'id': event._id,
                'start_time': event.timestamp,
                'type': 'flag',
                'event': event
            };
            return comment;
        },
        convertNPTToComment(event) {
            let startTime = null;
            if(event.subCategory == 'start') {
                startTime = event.data.startTime;
            } else if(event.subCategory == 'end') {
                startTime = event.data.endTime;
            }
            
            //ensure references remain lowercased
            event.reference = event.reference.toLowerCase();

            const comment = {
                'id': event._id,
                'start_time': startTime,
                'type': 'npt',
                'event': event
            };
            return comment;
        },
        handleCommentsUpdated(comments) {
            // Reset related state whenever this is recalled
            this.messageMarkers.forEach(marker => {
                marker.element.$destroy();
                this.timelineElement?.removeChild(marker.element.$el);
            });
            this.messageMarkers = [];
            this.chartCommentLookupByTimestamp = {};

            // loop through all comments
            for (const comment of comments) {
                const key = String(moment.utc(comment.start_time).valueOf());
                
                // Initalize empty list if needed
                if (!this.chartCommentLookupByTimestamp[key])
                    this.chartCommentLookupByTimestamp[key] = [];

                // Always push or update every comment
                const refExistingIndex = this.chartCommentLookupByTimestamp[key]?.findIndex(c => c?.event?.reference === comment?.event?.reference);
                if (refExistingIndex < 0) {
                    this.chartCommentLookupByTimestamp[key].push(comment);

                    // We always push comments, but only create 1 marker per start_time
                    this.createMessageMarker(this.chartCommentLookupByTimestamp[key]);
                } else {
                    this.chartCommentLookupByTimestamp[key][refExistingIndex] = comment;
                }   
            }
        },
        createAllMessageMarkers()
        {
            // create a dictionary of comments using the timestamp as a key
            for(let i = 0; i < this.sharedState.comments.length; i++)
            {
                const t = moment.utc(this.sharedState.comments[i].start_time).valueOf();

                if(this.chartCommentLookupByTimestamp.hasOwnProperty(t))
                {               
                    this.chartCommentLookupByTimestamp[t].push(this.sharedState.comments[i]);
                }
                else
                {
                    this.chartCommentLookupByTimestamp[t] = [];
                    this.chartCommentLookupByTimestamp[t].push(this.sharedState.comments[i]);
                }
            }
            
            // for each key create a message marker
            for(const key of Object.keys(this.chartCommentLookupByTimestamp))
            {
                this.createMessageMarker(this.chartCommentLookupByTimestamp[key]);
            }

            this.$nextTick(() => {
                this.updateMessageMarkerPositions();
            });
        },
        onDeleteSuccess(npt) {
            const filterDeleteNPT = this.sharedState.comments.filter((comment)=> {
                if(comment.type == 'npt' && comment.event.reference == npt.reference) {
                    return false
                } else {
                    return true;
                }
            });
            this.$set(this.sharedState, 'comments', filterDeleteNPT);
        },
        createMessageMarker(comments)
        {
            const self = this;
            const ChartCommentMarkerClass = Vue.extend(ChartCommentMarker);
            const chart = this.chart.$data._chart;

            const timestamp = moment.utc(comments[0].start_time).valueOf();

            const isAlert = comments.find(item => item?.type == 'alert') ? true: false;
            const newMarker = 
            {
                positionX: 0,
                timestamp,
                chart,
                timeline: this.timelineElement,
                updatePosition: function() 
                { 
                    if (chart.scales['date-axis']) { //comments timeline only enabled for date axis
                        let normalizedPosition = this.getNormalizedGraphXPositionOfTimestamp(this.timestamp);
                        const clampedNormalizedPosition = normalizedPosition < 0 ? 0 : normalizedPosition > 1 ? 1 : normalizedPosition;
                        if(isNaN(normalizedPosition)) {
                            normalizedPosition = -1;
                        }
                        
                        this.element.$el.style.left = String(clampedNormalizedPosition * 100) + '%';
                        this.positionX = parseFloat(this.element.$el.style.left);

                        if(normalizedPosition < 0 || normalizedPosition > 1) {
                            this.element.$el.style.display = 'none';
                        } else {
                            this.element.$el.style.display = 'block';
                        }
                    }
                },
                getNormalizedGraphXPositionOfTimestamp: function(timestamp)
                {
                    const min = this.chart.scales['date-axis'].min;
                    const max = this.chart.scales['date-axis'].max;
                    return ((this.timestamp-min) / (max-min));
                }
            };
            const element = new ChartCommentMarkerClass(
                { 
                    propsData: { 
                        onNPTEditClick: this.onNPTEditClick,
                        onNPTAddEndClick: this.onNPTAddEndClick,
                        onDeleteSuccess: this.onDeleteSuccess,
                        nptEvents: this.fetchedNPTEvents,
                        flags: this.fetchedFlags,
                        isAlert: isAlert,
                        hideListHeader: this.hideListHeader,
                        readOnly: this.readOnly,
                        comments,
                        jobHourOffset: this.jobHourOffset,
                        jobNumber: this.jobNumber,
                        sharedState: this.sharedState,
                        onCommentsUpdated: this.handleCommentsUpdated,
                        onAllCommentsDeleted: function()
                        {
                            self.setChartControlsEnabled(true);
                            this.$destroy();

                            self.timelineElement.removeChild(this.$el);
                            const markerIndex = self.messageMarkers.indexOf(newMarker);

                            self.messageMarkers.splice(markerIndex, 1);
                        },
                        onPopoverShown: () => 
                        {
                            this.setChartControlsEnabled(false);
                        },
                        onPopoverHidden: () => 
                        {
                            this.setChartControlsEnabled(true);
                        },
                        eventActivities: EVENT_ACTIVITIES,
                        eventReasons: this.eventReasons,
                        contractors: this.contractors,
                        eventActivityEventReasonsLookup: this.eventActivityEventReasonsLookup,
                        nptOutcomes: this.nptOutcomes,
                        customerVendors: this.customerVendors,
                        wells: this.wells,
                        isFlagDeleting: false,
                        hasEditPermission: this.hasEditPermission
                    } 
                }
            );

            newMarker.element = element;
            element.timestamp = timestamp;

            element.$mount();
            this.timelineElement.appendChild(element.$el);

            newMarker.updatePosition();
            this.messageMarkers.push(newMarker);

            return newMarker;
        },
        setupComments() {
            const commentsArray = this.createCommentsArray(this.chartcomments);
            this.$set(this.sharedState, 'comments', commentsArray);
        },
        setChartControlsEnabled(newValue)
        {
            this.chart.$data._chart.options.plugins.zoom.pan.enabled = newValue;
            this.chart.$data._chart.options.plugins.zoom.zoom.enabled = newValue;
            
            this.chart.$data._chart.update();
        },
        updateMessageMarkerPositions()
        {
            for(let i = 0; i < this.messageMarkers.length; i++)
            {
                this.messageMarkers[i].updatePosition();
            }

            if(this.temporaryMarker)
            {
                this.temporaryMarker.updatePosition();
            }

            // recalculate left and right marker counts
            this.hasUnreadMessagesLeft = false;
            this.hasUnreadMessagesRight = false;
            this.markersLeftCount = 0;
            this.markersRightCount = 0;

            for(let i = 0; i < this.messageMarkers.length; i++)
            {
                if(this.messageMarkers[i].positionX <= 0)
                {
                    this.markersLeftCount++;
                    
                    // check if there are unread messages in the oob lists after updating marker positions
                    const unreadMessage = this.messageMarkers[i].element.$props.comments.find(c => c.read_status == 0);
                    if(unreadMessage)
                    {
                        this.hasUnreadMessagesLeft = true;
                    }
                }
                else if(this.messageMarkers[i].positionX >= 100)
                {
                    this.markersRightCount++;

                    const unreadMessage = this.messageMarkers[i].element.$props.comments.find(c => c.read_status == 0);
                    if(unreadMessage)
                    {
                        this.hasUnreadMessagesRight = true;
                    }
                }
            }
        },
        createEventsLookup() {
            for(let i = 0; i < this.eventActivityEventReasons.length; i++)
            {
                const eventActivityId = this.eventActivityEventReasons[i].eventactivity_id;
                const eventReasonId = this.eventActivityEventReasons[i].eventreason_id;

                if(this.eventActivityEventReasonsLookup.hasOwnProperty(eventActivityId))
                {
                    this.eventActivityEventReasonsLookup[eventActivityId].push(eventReasonId);
                } 
                else 
                {
                    this.eventActivityEventReasonsLookup[eventActivityId] = [];
                    this.eventActivityEventReasonsLookup[eventActivityId].push(eventReasonId);
                }
            }
        },
        manageSliderPosition(newMin, newMax)
        {
            //make sure values are ordered before comparing
            if (this.value?.length > 0 && (this.value[0] > this.value[1]))
            {
                this.value = [this.value[1], this.value[0]];
            }
            if (this.lastSliderPosition != [] && (this.lastSliderPosition[0] > this.lastSliderPosition[1]))
            {
                this.lastSliderPosition = [this.lastSliderPosition[1], this.lastSliderPosition[0]];
            }

            if (this.value?.length == 0 && 
                (this.lastSliderPosition?.length > 0 && this.lastSliderPosition[0] >= newMin && this.lastSliderPosition[1] <= newMax))
            {
                //bring back slider value
                this.value = this.lastSliderPosition;
                this.lastSliderPosition = [];
            }
            else if (this.value?.length > 0 && (this.value[0] < newMin || this.value[1] > newMax))
            {
                //temporarily hide slider value
                this.lastSliderPosition = this.value;
                this.value = [];
            }
        },
        signalREventHandler: function(message)
        {
            if (message.category == 'timekeeper' && message?.data?.timekeeper?.step == 'npt')
            {
                if (GlobalFunctions.isFeatureFlagged('NEW_NPT_DELETE'))
                {
                    //create or delete an NPT event
                    if (message.subCategory == 'start' || message.subCategory == 'end')
                    {
                        if (message.data.deleted_at != null)
                        {   
                            //delete npt event
                            const removedEvents = this.fetchedNPTEvents.filter(e => e.reference == message.reference.toLowerCase());
                            this.fetchedNPTEvents = this.fetchedNPTEvents.filter(e => e.reference != message.reference.toLowerCase());

                            this.$nextTick(()=>{
                                this.setupComments();
                                this.$emit('removeNPTAnnotations', removedEvents);
                            });
                        }
                        else
                        {
                            //create npt event
                            message.reference = message.reference.toLowerCase();
                            this.fetchedNPTEvents.push(message);
                            this.$nextTick(()=>{
                                this.setupComments();
                                this.$emit('createNPTAnnotations', this.fetchedNPTEvents);
                            });
                        }
                    }
                }
                else
                {
                    //create an NPT event
                    if (message.subCategory == 'start' || message.subCategory == 'end')
                    {
                        this.fetchedNPTEvents.push(message);
                        this.$nextTick(()=>{
                            this.setupComments();
                            this.$emit('createNPTAnnotations', this.fetchedNPTEvents);
                        });
                    }
                    //delete an NPT event
                    else if (message.subCategory == 'delete')
                    {
                        const removedEvents = this.fetchedNPTEvents.filter(e => e.reference == message.reference);
                        this.fetchedNPTEvents = this.fetchedNPTEvents.filter(e => e.reference != message.reference);

                        this.$nextTick(()=>{
                            this.setupComments();
                            this.$emit('removeNPTAnnotations', removedEvents);
                        });
                    }
                }

                this.matchNPTPairs();
            }
            else if (message.category == 'pumpFlag')
            {
                if (message.subCategory == 'placeFlag' || message.subCategory == 'completed')
                {
                    this.fetchedFlags.push(message);
                    this.$nextTick(()=>{
                        this.setupComments();
                    });
                }
                else if (message.subCategory == 'deleteFlag')
                {
                    this.fetchedFlags = this.fetchedFlags.filter(e => e.reference != message.reference);
                    this.$nextTick(()=>{
                        this.setupComments();
                    });
                }
            }
        },
    },
    watch: {
        value() {
            if (!!this.value?.length && !this.hasEditPermission)
                this.value = [];
        },
        activeDrawing: function (newVal, oldVal) {
            if(oldVal === true && newVal === false) { //drawing ended
                this.$refs['comment-popup']?.getEventByStartEndSelection();
            }
        },
        'sharedState.comments': {
            handler: function (newValue, oldValue) {   
                this.$nextTick(() => {
                    this.handleCommentsUpdated(newValue);
                });
            },
        },
        signalRConnected: function(newValue, oldValue)
        {
            //signalR disconnected and reconnected
            if (newValue && !oldValue)
            {
                //things to refetch on signalR reconnect
                this.updateCommentTimelineData();
            }
        }
    }
       
};
</script>
<style scoped>
    .vue-slider-disabled {
        opacity: 0.5;
        cursor: default;
    }
</style>
<style>
    #timeline-wrapper .vue-slider-process {
        background-color: red !important;
    }
</style>