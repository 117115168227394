<template>
    <div class="d-flex justify-content-center" :style="'height:' + height + 'px'">
        <div id="overlay" class="flex-column" v-if="editMode">
            <div class="col-12 input-group ml-1" style="top:10%">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect02" style="width: 75px;">Well:</label>
                </div>
                <select id="inputGroupSelect02" class="form-control mr-2"  @change="onSelectWell($event.target.value)" :value="dropdownValue">
                    <option v-for="(well, index) in wells"
                        :value="well.index"
                        :key="'well_'+index "
                    >
                        {{well.name}} - {{ well.status }}
                    </option>
                    <option :value="activeTypes.wireline">
                        Lock to active wireline well
                    </option>
                    <option :value="activeTypes.frac">
                        Lock to active frac well
                    </option>
                    <option :value="activeTypes.wirelineTrucks">
                        Lock to selected wireline truck
                    </option>
                </select>
            </div>
            <div v-if="showTruckOptions" class="col-12 input-group ml-1" style="top:10%">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="inputGroupSelect03" style="width: 75px;">Truck:</label>
                </div>
                <select id="inputGroupSelect03" class="form-control mr-2" v-model="selectedTruck" @change="onSelectedTruck($event.target.value)">
                    <option v-for="(wirelineSystem, index) in wirelineSystems" :key="index" :value="wirelineSystem.number">{{wirelineSystem.name}}</option>
                </select>
            </div>
        </div>
        <div v-if="loading" class="d-flex algin-self-center">Loading....</div>
        <well-small-component v-else-if="selectedWell"
            :well="selectedWell"
            :name="selectedWell.name" 
            :color="selectedWell.color" 
            :duration="selectedWell.duration"
            :currentStage="selectedWell.currentStage"
            :numberOfStages="selectedWell.numberOfStages"
            :crownPressures="pressure.crownPressures" 
            :index="selectedWell.index"
            :stretch="true"
            :activity="selectedWell.activity"
            :status="selectedWell.status" 
            :is-multi-wireline="isMultiWireline"
            :wireline-systems="wirelineSystems"
            :casingPressures="pressure.casingPressures"
            :pressureWell="pressure.casingPressures[selectedWell.index]"
            :showWellLockToggle="true"
            :stageStart="selectedWell.start" 
            @wireline-duration="getWirelineDuration"
            style="width:100% display: flex; justify-content: center;"
        >
        </well-small-component>
        <div id="overlay" v-if="!loading && !editMode && (dropdownValue == this.activeTypes.wireline && !wirelineWell)" class="overlay-center">
            <div class="align-self-center font-weight-bold">Wireline not set.</div>
        </div>
        <div id="overlay" v-if="!loading && !editMode && (dropdownValue == this.activeTypes.frac && !fracWell)" class="overlay-center">
            <div class="align-self-center font-weight-bold">Frac not set.</div>
        </div>
        <div id="overlay" v-if="!loading && !editMode && (dropdownValue == this.activeTypes.wirelineTrucks && !wellWithTruck && !selectedWell)" class="overlay-center">
            <div class="align-self-center font-weight-bold">Truck not active on well.</div>
        </div>
    </div>
</template>

<style scoped>
    #overlay {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        background-color: rgba(30,30,30,0.7);
        z-index: 2;
    }
    .overlay-center {
        display: flex !important;
        justify-content: center;
    }
</style>

<script>

export default {
    props: {
        item: {
            type: [Object],
            required: true
        },
        wells: {
            type: [Array],
            required: true
        },
        isMultiWireline: {
            type: [Boolean],
            required: true
        },
        wirelineSystems: {
            type: [Array],
            required: true
        },
        pressure: {
            type: [Object],
            required: true
        },
        dashboardData: {
            type: [Object],
            required: true
        },
        loading: {
            type: [Boolean],
            required: true
        },
        editMode: {
            type: [Boolean],
            required: true
        },
        height: {
            type: [Number],
            required: true
        }
    },
    watch: {
        'wells': {
            handler: function (newValue, oldValue) {
                this.handleActivityChange(newValue);  
            },
            deep: true
        }
    },
    created() {
        this.activeTypes = {
            wireline: 'active-wireline',
            frac: 'active-frac',
            wirelineTrucks: 'well-by-truck'
        };
    },
    data() {
        return {
            showTruckOptions: false,
            selectedTruck: null,
            selectedWell: null,
            wirelineDuration: '',
            dropdownValue: null
        };
    },
    mounted() {
        if (this.dashboardData?.selectedDashboard?.are_trucks_synced && this.dashboardData?.selectedDashboard?.default_truck_number && this.item?.selectedTruckId) {
            this.selectedTruck = this.wirelineSystems.find(truck => parseInt(truck.number) == parseInt(this.item?.selectedTruckId)).number;
            this.dropdownValue = 'well-by-truck';
            this.showTruckOptions = true;
            this.selectedWell = this.wells.find(well => {
                return well?.activityData?.service_id == this.selectedTruck && well?.activity === 'wireline';
            });
            this.item.options = { 
                'selectedWellIndex': this.activeTypes.wirelineTrucks
            };
        } else {
            if (this.wirelineSystems?.length > 0 && !this.selectedTruck) {
                if (this.item?.options?.selectedTruckNumber) {
                    this.selectedTruck = this.item.options.selectedTruckNumber;
                } else {
                    this.selectedTruck = this.wirelineSystems[0].number; 
                }
            }
        }
        this.handleActivityChange(this.wells); 

    },
    computed: {
        wirelineWell() {
            return this.wells.find((well)=> well.status === 'Perforating');
        },
        fracWell() {
            return this.wells.find((well)=> well.status === 'Fracing');
        },
        wellWithTruck() {
            if (this.selectedTruck) {
                return this.wells.find(well => {
                    return well?.activityData?.service_id == this.selectedTruck && well?.activity === 'wireline';
                });
            }
        }
    },
    methods: {
        onSelectedTruck(event) {
            this.selectedTruck = event;
            let truckNumber = event;
            this.selectedWell = this.wells.find(well => {
                return well?.activityData?.service_id == truckNumber && well?.activity === 'wireline';
            })
            this.item.options = { 
                'selectedWellIndex': this.activeTypes.wirelineTrucks,
                'selectedTruckNumber': this.selectedTruck
            };
        },
        onSelectWell(selectedOption) {
            this.showTruckOptions = false;
            this.dropdownValue = selectedOption;
            if(selectedOption === this.activeTypes.wireline) {
                this.selectedWell = this.wirelineWell;
                this.item.options = { 
                    'selectedWellIndex': this.activeTypes.wireline
                };
            } else if(selectedOption === this.activeTypes.frac) {
                this.selectedWell = this.fracWell;
                this.item.options = { 
                    'selectedWellIndex': this.activeTypes.frac
                };
            } else if(selectedOption === this.activeTypes.wirelineTrucks) {
                this.selectedWell = this.wellWithTruck;
                this.showTruckOptions = true;
                this.item.options = { 
                    'selectedWellIndex': this.activeTypes.wirelineTrucks,
                    'selectedTruckNumber': this.selectedTruck
                };
            } else {
                this.selectedWell = this.wells.find((well, index)=> index == selectedOption);
                this.item.options = { 
                    'selectedWellIndex': parseInt(this.selectedWell.index)
                };
            }
        },							
        handleActivityChange(wells) {
            if(wells && wells.length > 0) {
                let wellIndex = this.item?.options?.selectedWellIndex || 0;
                if (!isNaN(wellIndex)) {
                    wellIndex = parseInt(wellIndex);
                } 
                this.dropdownValue = wellIndex;
                if(wellIndex === this.activeTypes.wireline) {
                    this.selectedWell = this.wirelineWell;
                } else if(wellIndex === this.activeTypes.frac) {
                    this.selectedWell = this.fracWell;
                } else if(wellIndex === this.activeTypes.wirelineTrucks) {
                    this.showTruckOptions = true;
                    this.selectedWell = this.wells.find(well => {
                        return well?.activityData?.service_id == this.selectedTruck && well?.activity === 'wireline';
                    });
                } 
                else {
                    this.selectedWell = wells.find((well, index)=> index === wellIndex);
                }
            }
        },
        getWirelineDuration: function(val) {
            this.wirelineDuration = val;
        }
    }
};
</script>