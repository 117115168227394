var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { float: "right" } },
    [
      _vm.viewActions.edit !== false
        ? _c(
            "span",
            {
              staticClass: "table-row-action",
              class: { "text-primary": _vm.enableActions.edit },
              attrs: { id: `edit_${_vm.id}`, disabled: _vm.enableActions.edit },
              on: {
                click: function ($event) {
                  return _vm.$emit("edit")
                },
              },
            },
            [_c("i", { staticClass: "fas fa-pen" }), _vm._v(" Edit\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-tooltip",
        { attrs: { triggers: "hover", target: `edit_${_vm.id}` } },
        [_vm._v("\n        Cannot edit, item is in use\n    ")]
      ),
      _vm._v(" "),
      _vm.viewActions.view !== false
        ? _c(
            "span",
            {
              staticClass: "table-row-action",
              class: { "text-primary": _vm.enableActions.view },
              attrs: { id: `view_${_vm.id}`, disabled: _vm.enableActions.view },
              on: {
                click: function ($event) {
                  return _vm.$emit("view")
                },
              },
            },
            [_c("i", { staticClass: "far fa-eye" }), _vm._v(" View\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-tooltip",
        { attrs: { triggers: "hover", target: `view_${_vm.id}` } },
        [_vm._v("\n        Cannot view, item is in use\n    ")]
      ),
      _vm._v(" "),
      _vm.viewActions.delete !== false
        ? _c(
            "span",
            {
              staticClass: "table-row-action",
              class: { "text-danger": _vm.enableActions.delete },
              attrs: {
                id: `delete_${_vm.id}`,
                disabled: _vm.enableActions.delete,
              },
              on: {
                click: function ($event) {
                  return _vm.$emit("delete")
                },
              },
            },
            [
              _c("i", { staticClass: "fas fa-trash-alt" }),
              _vm._v(" Delete\n    "),
            ]
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "b-tooltip",
        { attrs: { triggers: "hover", target: `delete_${_vm.id}` } },
        [_vm._v("\n        Cannot delete, item is in use\n    ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }