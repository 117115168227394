<template>
    <b-modal
        hide-footer
        hide-header
        no-close-on-backdrop
        v-if="selectedConfig !== null"
        style="z-index: 1200"
        size="xl"
        :id="selectedConfig.type + 'modal'"
        content-class="modal-content main-container div-border modal-bg"
        body-class="px-2 py-2"
        title="Chart Configuration"
        @hide="modalHide"
    >
        <div role="document">
            <div v-if="!isLoading">
<!-- Edit Axis final page -->
                <div v-if="selectedAxis">
                    <div class="modal-body py-0">
                        <div class="row justify-content-center my-3">Edit Chart Axis</div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('label')}}</div>
                            <div class="col-9">
                            <input
                                ref="name"
                                class="form-control"
                                type="text"
                                @change="axisChanged = true"
                                v-model="selectedAxis.label"
                            />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="nameErrorSeen">
                            <span class="error-span">Name is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('min')}}</div>
                            <div class="col-9">
                            <input
                                ref="minimum"
                                class="form-control"
                                type="number"
                                @change="axisChanged = true"
                                v-model.number="selectedAxis.min"
                            />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="minErrorSeen">
                            <span class="error-span">Min is required</span>
                            </div>
                            <div class="col-9 text-left" v-if="minMaxErrorSeen">
                            <span class="error-span">Min must be a smaller value than Max</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('max')}}</div>
                            <div class="col-9">
                            <input
                                ref="maximum"
                                class="form-control"
                                type="number"
                                @change="axisChanged = true"
                                v-model.number="selectedAxis.max"
                            />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="maxErrorSeen">
                            <span class="error-span">Max is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('ticks')}}</div>
                            <div class="col-9">
                                <input
                                    ref="ticks"
                                    class="form-control"
                                    type="number"
                                    min="2"
                                    @change="selectedAxis.ticks = validateNumberInputs($event.target.value, 2); axisChanged = true"
                                    v-model.number="selectedAxis.ticks"
                                    :disabled="selectedAxis.alignToPrimaryAxis"
                                />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left">
                                <span><small>( Default to 10 tick intervals, minimum: 2 )</small></span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('position')}}</div>
                            <div class="col-9">
                            <select
                                ref="position"
                                class="form-control"
                                @change="axisChanged = true"
                                v-model="selectedAxis.position"
                            >
                                <option :selected="selectedAxis.position == 'left' ? 'selected' : ''">left</option>
                                <option :selected="selectedAxis.position == 'right' ? 'selected' : ''">right</option>
                            </select>
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="positionErrorSeen">
                            <span class="error-span">Position is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                                <div class="col-3 list-label text-right pl-0">{{inputLabelLookup('isPrimaryAxis')}}</div>
                                    <div class="col-3 text-left">
                                        <input
                                            ref="primaryAxis"
                                            class="check-box"
                                            type="checkbox"
                                            @change="changePrimaryAxis()"
                                            v-model="selectedAxis.isPrimaryAxis"
                                        />
                                    </div>
                            <div class="col-3 list-label text-right">{{inputLabelLookup('alignToPrimaryAxis')}}</div>
                                <div class="col-3 text-left">
                                    <input
                                        ref="useCustomGridlinesColor"
                                        class="check-box"
                                        type="checkbox"
                                        @change="axisChanged = true;"
                                        v-model="selectedAxis.alignToPrimaryAxis"
                                        :disabled="selectedAxis.isPrimaryAxis"
                                    />
                            </div>
                            <div class="col-6 text-right" v-show="getPrimaryAxis==null && selectedAxis.alignToPrimaryAxis" style="color:red; font-size:75%;">No primary axis is currently set</div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('hideYAxis')}}</div>
                            <div class="col-2 text-left">
                                <input
                                    ref="hideYAxis"
                                    class="check-box"
                                    type="checkbox"
                                    @change="axisChanged = true"
                                    v-model="selectedAxis.hideYAxis"
                                />
                            </div>
                            <div class="col-7"/>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('logarithmic')}}</div>
                            <div class="col-9 text-left">
                            <input
                                ref="logarithmicYAxis"
                                class="check-box"
                                type="checkbox"
                                @change="axisChanged = true"
                                v-model="selectedAxis.logarithmic"
                            />
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">Axis Color</div>
                            <div class="col-9 text-left">
                                <div class="swatch p-0" @click="showAxisColorPicker = !showAxisColorPicker">
                                    <div class="color" :style="{'background-color': selectedAxis.color}" />
                                </div>
                                <!-- TO DO : Is there a changed marker for axis ?? chartItemChanged = true; -->
                                <div v-if="showAxisColorPicker" class="popover">
                                    <div class="cover" @click="showAxisColorPicker = false;"/>
                                    <sketch-picker
                                        ref="defaultColor"
                                        :value="selectedAxis.color ? selectedAxis.color : '#FFFFFF'"
                                        id="color"
                                        :disableAlpha="true"

                                        @input="color => {selectedAxis.color = color.hex; axisChanged = true;}"
                                        :preset-colors="presetColors"/>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('displayGridlines')}}</div>
                            <div class="col-9 text-left">
                            <input
                                ref="displayGridlines"
                                class="check-box"
                                type="checkbox"
                                @change="displayGridlinesChanged"
                                v-model="selectedAxis.displayGridlines"
                            />
                            </div>
                        </div>
                        <div class="row align-items-center mb-2" v-if="selectedAxis.displayGridlines">
                            <div class="col-3 list-label text-right">
                                {{inputLabelLookup('customGridlinesColor')}}
                            </div>
                            <div class="col-9 text-left">
                                <div class="swatch p-0" @click="showCustomGridlinesColorPicker = !showCustomGridlinesColorPicker">
                                    <div class="color" :style="{'background-color': selectedAxis.customGridlinesColor}" />
                                </div>
                                <div v-if="showCustomGridlinesColorPicker" class="popover">
                                    <div class="cover" @click="showCustomGridlinesColorPicker = false;"/>
                                    <sketch-picker
                                            ref="customGridlinesColorPicker"
                                            :value="selectedAxis.customGridlinesColor ? selectedAxis.customGridlinesColor : '#FFFFFF'"
                                            id="customGridlinesColorPicker"
                                            :disableAlpha="true"
                                            @input="color => {selectedAxis.customGridlinesColor = color.hex; axisChanged = true;}"
                                            :preset-colors="presetColors"/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- don't remove the extra div, it prevents a weird issue with the modal dismissing -->
                    <div>
                    <div class="modal-footer d-flex flex-row" style="border-top-width:0px">
                        <button
                            type="button"
                            class="btn btn-secondary grey-button"
                            @click="backPressed('yaxis')"
                        >Back</button>
                        <button
                            type="button"
                            class="btn btn-secondary grey-button"
                            @click="backPressed('config')"
                        >Close</button>
                        <button
                            v-if="hasPermission()"
                            type="button"
                            class="btn btn-success green-button"
                            @click="saveConfig('yaxis')"
                            :disabled="(!configInputChanged() || !hasPermission())"
                        >Save Template</button>
                        <button v-else type="button" class="btn btn-success green-button" disabled>Save Template</button>
                    </div>
                    </div>
                </div>
<!-- Edit Chart Item 3rd page -->
                <div v-else-if="selectedChartItem">
                    <div class="modal-body py-0">
                    <div class="row justify-content-center my-3">Edit Data Source</div>
                    <!-- Should vary if adding or editing -->
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('tagName')}}</div>
                        <div class="col-9">
                        <autocomplete
                            ref="tagName"
                            class="form-control d-flex flex-grow-1"
                            @change="chartItemChanged = tagNameChanged = true"
                            @blur="selectedChartItem.tagName = $event.target.value"
                            @submit="onTagChanged"
                            :search="search"
                            placeholder="Search for a tag name"
                            :get-result-value="getResultValue"
                            :default-value="selectedChartItem.tagName"
                        >
                            <template #result="{ result, props }">
                            <a
                                href="#"
                                v-bind="props"
                                class="list-group-item list-group-item-action text-secondary p-1 text-left"
                            >{{ result.name }}</a>
                            </template>
                        </autocomplete>
                        </div>
                        <div class="col-3 text-right"></div>
                        <div class="col-9 text-left" v-if="tagErrorSeen">
                            <span class="error-span">Source Tag Name is required</span>
                        </div>
                        <div class="col-9 text-left" v-if="isTagAlreadyUsed">
                            <span class="error-span">Source Tag Name is already in use</span>
                        </div>
                    </div>
                    <div v-if="getWellboreWell !== false" class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('friendlyName')}}</div>
                        <div class="col-9">
                            <div class="row">
                                <div class="col-5 input-group pr-0">
                                    <span class="input-group-text mr-2">Prefix</span>
                                    <div v-if="isWellheadTag(selectedChartItem.tagName) && getWellheadPrefixForTag(selectedChartItem.tagName) !== ''" class="col-auto align-self-center">
                                        {{getWellheadPrefixForTag(selectedChartItem.tagName)}}
                                    </div>
                                    <input v-else
                                        ref="friendlyNamePrefix"
                                        class="form-control rounded col-auto"
                                        type="text"
                                        v-model="selectedChartItem.prefixName"
                                        @change="chartItemChanged = true"
                                    />
                                </div>
                                <div class="col-7 input-group">
                                     <div v-if="isWellheadTag(selectedChartItem.tagName) && getWellheadSuffixTitleForTag(selectedChartItem.tagName) !== ''" class="col-auto align-self-center">
                                        {{getWellheadSuffixTitleForTag(selectedChartItem.tagName)}}
                                    </div>
                                    <span class="input-group-text mr-2">Suffix</span>
                                    <input
                                        ref="friendlyNameSuffix"
                                        class="form-control rounded col-auto"
                                        type="text"
                                        v-model="selectedChartItem.suffixName"
                                        @change="chartItemChanged = true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('friendlyName')}}</div>
                        <div class="col-9">
                        <input
                            ref="friendlyName"
                            class="form-control"
                            type="text"
                            v-model="selectedChartItem.friendlyName"
                            @change="chartItemChanged = true"
                        />
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('unit')}}</div>
                        <div class="col-9">
                        <input
                            ref="unit"
                            class="form-control"
                            type="text"
                            v-model="selectedChartItem.unit"
                            @change="chartItemChanged = true"
                        />
                        </div>
                        <div class="col-3 text-right"></div>
                        <div class="col-9 text-left" v-if="unitErrorSeen">
                            <span class="error-span">Unit is required</span>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('chartAxis')}}</div>
                        <div class="col-6">
                            <autocomplete
                                ref="chartyaxis"
                                class="form-control d-flex flex-grow-1"
                                @submit="checkChartYAxis"
                                @change="checkChartYAxis"
                                :search="chartYAxisSearch"
                                placeholder="Search for an axis name"
                                :get-result-value="getChartYAxisValue"
                                :default-value="selectedChartItem.chartYAxis_key? findAxisLabel(selectedChartItem.chartYAxis_key) : ''"
                            >
                                <template #result="{ result, props }">
                                    <a
                                        href="#"
                                        v-bind="props"
                                        class="list-group-item list-group-item-action text-secondary p-1 text-left"
                                    >
                                        {{ result.label }}
                                    </a>
                                </template>
                            </autocomplete>
                            <div class="col-7 text-left" style="padding-left:0" v-if="chartYAxisErrorSeen">
                                <span class="error-span">Chart Axis is required</span>
                            </div>
                        </div>
                        <div class="col-3 list-label text-left p-0">
                            <button class="fake-button show-clicker-finger" @click="addAxis()" v-tooltip:top="'Add Y axis'"> <i class="fas fa-plus-circle" style="color: white"></i></button>
                            <button class="fake-button show-clicker-finger" @click="editAxis()" :disabled="chartYAxisMissing" v-tooltip:top="'Edit Y axis'"> <i class="fas fa-pen" style="color: white"></i> </button>
                            <button class="fake-button show-clicker-finger" @click="deleteAxis()" :disabled="chartYAxisMissing" v-tooltip:top="'Delete Y axis'"><i class="fas fa-trash-alt" style="color: white"></i></button>
                        </div>
                    </div>
                    <div>
                        <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('decimalPrecision')}}</div>
                        <div class="col-2">
                            <input
                                ref="decimals"
                                class="form-control"
                                type="number"
                                id="decimals"
                                name="decimals"
                                step="1"
                                min="0"
                                max="9"
                                v-model.number="selectedChartItem.decimalPrecision"
                                @change="selectedChartItem.decimalPrecision = validateNumberInputs($event.target.value,0,9); chartItemChanged = true"


                            />
                        </div>
                        <div class="col-7"/>
                    </div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('lineWidth')}}</div>
                        <div class="col-2">
                            <input
                                ref="lineWidth"
                                class="form-control"
                                type="number"
                                id="points"
                                name="points"
                                step="1"
                                min="1"
                                max="5"
                                v-model.number="selectedChartItem.lineWidth"
                                @change="selectedChartItem.lineWidth = validateNumberInputs($event.target.value,1,5); chartItemChanged = true"
                            />
                        </div>
                        <div class="col-7"/>
                    </div>
                    <div v-if="getWellboreWell !== false" class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('customLineColor')}}</div>
                        <div class="col-2 text-left">
                            <input
                                ref="customLineColor"
                                class="check-box"
                                type="checkbox"
                                :checked="customLineColor"
                                @change="customLineColor = $event.target.checked; selectedChartItem.customLineColor = $event.target.checked; chartItemChanged = true;"
                            />
                        </div>
                        <div class="col-7"/>
                    </div>
                    <div  v-if="(getWellboreWell !== false && selectedChartItem.customLineColor) || getWellboreWell == false"  class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">Line Color</div>
                        <div class="col-2 text-left">
                            <div class="swatch" @click="showDefaultColorPicker = !showDefaultColorPicker">
                                <div class="color" :style="{'background-color': selectedChartItem.color}" />
                            </div>
                            <div v-if="showDefaultColorPicker" class="popover">
                                <div class="cover" @click="showDefaultColorPicker = false;"/>
                                <sketch-picker
                                    ref="defaultColor"
                                    :value="selectedChartItem.color"
                                    id="color"
                                    :disableAlpha="true"
                                    @input="color => {selectedChartItem.color = color.hex; chartItemChanged = true;}"
                                    :preset-colors="presetColors"/>
                            </div>
                        </div>
                        <div class="col-2 text-left">
                            <span v-if="colorErrorSeen" class="error-span">Color is required</span>
                        </div>
                        <div class="col-5"/>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('bgColor')}}</div>
                        <div class="col-4 text-left">
                            <input
                                ref="customBgColor"
                                class="check-box"
                                type="checkbox"
                                :checked="chartItemBgColorEnabled"
                                @change="chartItemBgColorEnabled = Boolean($event.target.checked); selectedChartItem.bgColor = chartItemBgColorEnabled ? '#FFFFFF' : null; chartItemChanged = true;"
                            />
                            <div v-if="chartItemBgColorEnabled" class="ml-2 swatch" @click="showBGColorPicker = !showBGColorPicker">
                                <div class="color" :style="{'background-color': selectedChartItem.bgColor}" />
                            </div>

                            <div v-if="showBGColorPicker" class="popover">
                                <div class="cover" @click="showBGColorPicker = false;"/>
                                <sketch-picker
                                    ref="bgColor"
                                    :value="selectedChartItem.bgColor"
                                    id="bgColor"
                                    :disableAlpha="true"
                                    @input="color => {selectedChartItem.bgColor = color.hex; chartItemChanged = true;}"
                                    :preset-colors="presetColors"/>
                            </div>
                        </div>
                        <div class="col-5"/>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('showDataPoints')}}</div>
                        <div class="col-2 text-left">
                            <input
                                ref="showDataPoints"
                                class="check-box"
                                type="checkbox"
                                v-model="selectedChartItem.showDataPoints"
                                @change="selectedChartItem.showDataPoints = Boolean($event.target.checked); chartItemChanged = true;"
                            />
                        </div>
                        <div class="col-7"/>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('selectedByDefault')}}</div>
                        <div class="col-2 text-left">
                            <input
                                ref="selectedByDefault"
                                class="check-box"
                                type="checkbox"
                                v-model="selectedChartItem.selectedByDefault"
                                @change="selectedChartItem.selectedByDefault = Boolean($event.target.checked); chartItemChanged = true"
                            />
                        </div>
                        <div class="col-7"/>
                    </div>
                     <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('staticSource')}}</div>
                        <div class="col-2 text-left">
                            <input
                                ref="staticSource"
                                class="check-box"
                                type="checkbox"
                                v-model="selectedChartItem.staticSource"
                                @change="selectedChartItem.staticSource = Boolean($event.target.checked); chartItemChanged = true"
                            />
                        </div>
                        <div class="col-7"/>
                    </div>
                    <hr class="w-100" />
                </div>
                <!-- don't remove the extra div, it prevents a weird issue with the modal dismissing -->
                <div>
                    <div class="modal-footer d-flex flex-row" style="border-top-width:0px">
                        <button
                            type="button"
                            class="btn btn-secondary grey-button"
                            :disabled="quickEditModalAccess"
                            @click="backPressed('chartitem')"
                            >Back</button>
                            <button
                                type="button"
                                class="btn btn-secondary grey-button"
                                @click="backPressed('config')"
                            >Close</button>
                            <button
                                v-if="hasPermission"
                                type="button"
                                class="btn btn-success green-button"
                                :disabled="(!configInputChanged() || !hasPermission())"
                                @click="saveConfig('chartitem')"
                            >Save Template</button>
                            <button v-else type="button" class="btn btn-success green-button" disabled>Save Template</button>
                        </div>
                    </div>
                </div>
                <!-- Edit Section second page -->
                <div v-else-if="selectedSection">
                    <div class="modal-body py-0">
                        <div class="row justify-content-center my-3">Edit Section</div>
                        <!-- Should vary if adding or editing -->
                        <div class="row justify-content-left align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('name')}}</div>
                            <div class="col-9 text-left">
                                <input
                                    ref="sectionName"
                                    class="form-control"
                                    type="text"
                                    v-model="selectedSection.name"
                                    @change="sectionChanged = true"
                                />
                                <input type="hidden" ref="sectionID" value="">
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="sectionErrorSeen">
                                <span class="error-span">Name is required</span>
                            </div>
                        </div>
                        <hr class="w-100" />
                        <div class="row mt-2 mb-1 px-3 align-items-center">
                            Data Sources
                            <i class="fas fa-plus-circle ml-2 show-clicker-finger" @click="addChartItem()"></i>
                        </div>
                        <div v-if="selectedSection.orderedChartItems.filter((chartItem)=>chartItem).length> 0">
                            <div
                            class="mx-3 align-items-center"
                            style="overflow-y: auto; overflow-x: hidden; max-height: 300px;">
                                <div v-for="(chartItem,index) in selectedSection.orderedChartItems" :key="index" class="row">
                                    <!--tags that begin with 'wellhead_ get special treatment where parts / all of their displayed
                                        name are auto generated with well information and pre-defined descriptors-->
                                    <div v-if="isWellheadTag(chartItem.tagName)" class="col ml-4 text-left font-weight-light" style="display: inline">
                                        {{getWellheadTagNameForChartItem(chartItem)}}
                                    </div>
                                    <div v-else class="col ml-4 text-left font-weight-light" style="display: inline">
                                        {{ chartItem.friendlyName }} ( {{ chartItem.tagName }} )
                                    </div>
                                    <div class="col-2" style="display: inline; float: right; inline-size: fit-content;">
                                        <i class="fas fa-pen show-clicker-finger mr-2" @click="editChartItem(chartItem, index)"></i>
                                        <i class="fas fa-trash-alt show-clicker-finger mr-2" @click="deleteChartItem(chartItem, index)"></i>
                                    </div>
                                    <div v-if="getChartItemWellboreWell(chartItem) && getChartItemWellboreWell(chartItem).name"
                                        class="col-1"
                                        :style="{ width: '25px', height:'25px', 'background-color': chartItem.customLineColor ? chartItem.color : chartItem.wellColor ? chartItem.wellColor : getChartItemWellboreWell(chartItem).color, 'float': 'right', 'inline-size': 'fit-content' }"
                                    ></div>
                                    <div v-else
                                        class="col-1"
                                        :style="{ width: '25px', height:'25px', 'background-color': chartItem.color, 'float': 'right', 'inline-size': 'fit-content' }"
                                    ></div>
                                    <hr class="w-100 mx-4 my-1" />
                                </div>
                            </div>
                        </div>
                        <div v-else class="row justify-content-center">No Data Sources Added</div>
                    </div>
                    <!-- don't remove the extra div, it prevents a weird issue with the modal dismissing -->
                    <div>
                        <div class="modal-footer d-flex flex-row" style="border-top-width:0px">
                            <button
                                type="button"
                                class="btn btn-secondary grey-button"
                                @click="backPressed('section')"
                            >Back</button>
                            <button
                                type="button"
                                class="btn btn-secondary grey-button"
                                @click="backPressed('config')"
                                >Close</button>
                            <button
                                type="button"
                                class="btn btn-success green-button"
                                :disabled="((!configInputChanged() || !sectionChanged) || !hasPermission())"
                                @click="saveConfig('section')"
                            >Save Template</button>
                        </div>
                    </div>
                </div>
<!-- Edit CustomXAxis second page -->
                <div v-else-if="selectedCustomXAxis">
                    <div class="modal-body py-0">
                        <div class="row justify-content-center my-3">Edit Custom Axis</div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('tagName')}}</div>
                            <div class="col-9">
                            <autocomplete
                                ref="tagName"
                                class="form-control d-flex flex-grow-1"
                                @change="updateCustomXAxisTagname($event)"
                                :search="search"
                                @submit="onCustomAxisTagChange"
                                placeholder="Search for a tag name"
                                :get-result-value="getResultValue"
                                :default-value="selectedCustomXAxis.tagName"
                            >
                                <template #result="{ result, props }">
                                <a
                                    href="#"
                                    v-bind="props"
                                    class="list-group-item list-group-item-action text-secondary p-1 text-left"
                                >{{ result.name }}</a>
                                </template>
                            </autocomplete>
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="customAxisTagError">
                                <span class="error-span">Source Tag Name is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup(' label')}}</div>
                            <div class="col-9">
                            <input
                                ref="label"
                                class="form-control"
                                type="text"
                                placeholder="Enter a label"
                                @change="customXAxisChanged = true"
                                v-model="selectedCustomXAxis.label"
                            />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="nameErrorSeen">
                            <span class="error-span">Label is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('unit')}}</div>
                            <div class="col-9">
                            <input
                                ref="unit"
                                class="form-control"
                                type="text"
                                :value="selectedCustomXAxis.unit"
                                @change="selectedCustomXAxis.unit = $event.target.value; customXAxisChanged = true"
                            />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="unitErrorSeen">
                                <span class="error-span">Unit is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('min')}}</div>
                            <div class="col-9">
                            <input
                                ref="minimum"
                                class="form-control"
                                type="number"
                                @change="customXAxisChanged = true"
                                v-model.number="selectedCustomXAxis.min"
                            />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="minErrorSeen">
                            <span class="error-span">Min is required</span>
                            </div>
                            <div class="col-9 text-left" v-if="minMaxErrorSeen">
                            <span class="error-span">Min must be a smaller value than Max</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('max')}}</div>
                            <div class="col-9">
                            <input
                                ref="maximum"
                                class="form-control"
                                type="number"
                                @change="customXAxisChanged = true"
                                v-model.number="selectedCustomXAxis.max"
                            />
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="maxErrorSeen">
                            <span class="error-span">Max is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('position')}}</div>
                            <div class="col-9">
                            <select
                                ref="position"
                                class="form-control"
                                @change="customXAxisChanged = true"
                                v-model="selectedCustomXAxis.position"
                            >
                                <option :selected="selectedCustomXAxis.position == 'top' ? 'selected' : ''">top</option>
                                <option :selected="selectedCustomXAxis.position == 'bottom' ? 'selected' : ''">bottom</option>
                            </select>
                            </div>
                            <div class="col-3 text-right"></div>
                            <div class="col-9 text-left" v-if="positionErrorSeen">
                            <span class="error-span">Position is required</span>
                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center mb-2">
                            <div class="col-3 list-label text-right">{{inputLabelLookup('logarithmic')}}</div>
                            <div class="col-9 text-left">
                            <input
                                ref="logarithmicXAxis"
                                class="check-box"
                                type="checkbox"
                                @change="customXAxisChanged = true"
                                v-model="selectedCustomXAxis.logarithmic"
                            />
                            </div>
                        </div>
                    </div>
                    <!-- don't remove the extra div, it prevents a weird issue with the modal dismissing -->
                    <div>
                        <div class="modal-footer d-flex flex-row" style="border-top-width:0px">
                            <button
                                type="button"
                                class="btn btn-secondary grey-button"
                                @click="backPressed('xaxis')"
                                >Back</button>
                            <button
                                type="button"
                                class="btn btn-secondary grey-button"
                                @click="backPressed('config')"
                            >Close</button>
                            <button
                                v-if="hasPermission"
                                type="button"
                                class="btn btn-success green-button"
                                :disabled="(!configInputChanged() || !hasPermission())"
                                @click="saveConfig('xaxis')"
                            >Save Template</button>
                            <button v-else type="button" class="btn btn-success green-button" disabled>Save Template</button>
                        </div>
                    </div>
                </div>
                <!-- Config first page -->
                <div v-else-if="selectedConfig">
                    <div class="modal-body py-0">
                    <div class="row justify-content-center my-3">Chart Configuration</div>
                    <div class="row justify-content-center align-items-center mb-2">
                        <div class="col-3 list-label text-right">Selected Template</div>
                        <div class="col-7">
                        <div class="select-wrapper">
                            <div class="d-flex justify-content-between pl-3 p-1 align-items-center select form-control" :class="{ active: isActive }" @click="isActive = !isActive">
                                <div class="select-value">{{ selectedConfig.templateName }}</div>
                                <span class="h-100 d-flex align-items-center">
                                    <img src="/images/dropdown.png" class="select-arrow">
                                </span>
                            </div>
                            <div class="select-dropdown" :class="{ active: isActive }">
                                <div v-for="(group, index) in options" :key="index" class="select-group">
                                <div class="select-group-label">{{ group.label }}</div>
                                <div v-for="(option, index) in group.options" :key="index" class="select-option" style="cursor: default" @click="onTemplateDropdownChange(option)">{{ option.templateName }}
                                    <span class="selected-check ml-2" v-if="option.id === selectedConfig.id">
                                        <i class="fas fa-check"></i>
                                    </span>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        <div class="col-2 pl-0">
                        <button class="fake-button white-text p-1 " @click="addConfig()">
                            <i class="fas fa-plus-circle show-clicker-finger" v-tooltip:top="'New Configuration'"></i>
                        </button>
                        <button
                            @click="copyConfig(false)"
                            :disabled="selectedConfig.hasValidationError"
                            class="fake-button white-text p-1 ml-1" :class="{'text-secondary' : selectedConfig.hasValidationError, 'show-clicker-finger': !selectedConfig.hasValidationError}">
                                <i id="copyConfigIcon" class="fas fa-copy white-text" :class="{'text-secondary' : selectedConfig.hasValidationError}"></i>
                                <b-tooltip target="copyConfigIcon" triggers="hover">
                                    {{!selectedConfig.hasValidationError ? 'Clone Configuration' : 'Legacy Configuration Cannot Clone'}}
                                </b-tooltip>
                            </button>
                        <button v-if="(canDelete || isAdmin)"  class="fake-button white-text p-1 ml-1" :disabled="isGlobalConfigSelected" @click="deleteConfig()">
                            <i id="configTrashIcon" class="fas fa-trash-alt show-clicker-finger" :class="{'text-secondary' : isGlobalConfigSelected}"></i>
                            <b-tooltip target="configTrashIcon" triggers="hover">
                                {{!isGlobalConfigSelected ? 'Delete Configuration' : 'Cannot Delete Global Configuration'}}
                            </b-tooltip>
                        </button>
                        <!-- History icon trigger (only show if: feature enabled, config is not a default config, and there's a populated version history )-->
                        <button class="fake-button white-text ml-1" v-show="isFeatureFlagged('CHART_CONFIG_HISTORY')">
                            <i
                                class="fas fa-history show-clicker-finger "
                                id="history-popover-target-1"
                                v-tooltip:top="'Restore Chart Configuration'"
                            ></i>
                        </button>
                        <!-- Chart version history popover -->
                        <b-popover v-if="isFeatureFlagged('CHART_CONFIG_HISTORY')" custom-class="config-history-popover" target="history-popover-target-1" triggers="hover" placement="bottom">
                            <template #title>
                                <div class="history-popover-title">Version History</div>
                            </template>
                            <div v-if="changeLogData.length == 0">
                                <b-overlay :show="isLoadingVersionHistory" rounded="sm" bg-color="#f9f9fa">
                                    <div class="p-1">
                                        <div style="max-height: 600px; overflow-y: auto;">
                                            <dark-table
                                                :items="versionHistory"
                                                :columns="[...chartConfigHistoryColumns]"
                                                :sortByCol.sync="sortByCol"
                                                :sortAsc.sync="sortAsc"
                                                :busy="isLoadingVersionHistory"
                                            >
                                                <template #body>
                                                    <tr v-for="(item, index) in versionHistory" :key="index">
                                                        <td>{{item.created_at}}</td>
                                                        <td>{{item.templateName}}</td>
                                                        <td>{{item.owned_by}}</td>
                                                        <td>{{item.user_role}}</td>
                                                        <td class="p-1 text-center">
                                                            <button :disabled="(!companyConfigs || companyConfigs.length === 0) || !showVersionHistoryButtons || selectedConfig.id === item.id" class="show-clicker-finger grey-button btn-sm" @click="compareVersion(item)" style="color: white; min-width:80px;">
                                                                Compare
                                                            </button>
                                                        </td>
                                                        <td class="p-1 text-center">
                                                            <button class="fake-button show-clicker-finger" :disabled="selectedConfig.id === item.id" @click="restoreVersion(item)" style="color: white;">
                                                                <i :class="{'disabledIcon':selectedConfig.id === item.id}" class="fas fa-undo-alt show-clicker-finger"></i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </dark-table>
                                        </div>
                                        <div class="d-flex justify-content-between align-items-center mt-2">
                                            <div>
                                              <div class="text-left">
                                                Showing {{ versionHistory.length }} / {{ versionHistoryAll.length }} results
                                              </div>
                                            </div>
                                            <div class="d-flex">
                                              <div v-if="showVersionHistoryButtons" class="ml-2">
                                                <button class="btn btn-primary cobaltButton" :disabled="isHistoryButtonDisabled" @click="showMore">
                                                  Show More
                                                </button>
                                              </div>
                                              <div v-if="showVersionHistoryButtons" class="ml-2">
                                                <button class="btn btn-primary cobaltButton" :disabled="isHistoryButtonDisabled" @click="showAll">
                                                  Show All
                                                </button>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                </b-overlay>
                            </div>
                            <div v-else>
                                <div class="d-flex flex-column justify-content-center">
                                    <div class="w-100" style="overflow:scroll">
                                        <div style="max-height: 600px; overflow-y: auto;">
                                            <div v-for="(changeLog,index) in changeLogData" :key="index" style="padding:10px;">
                                                <h6>{{propertyDisplayNameLookup[changeLog.property].title}}</h6>
                                                <dark-table
                                                    :items="changeLog.changes"
                                                    :columns="['Identifier','Property', 'Old Value', 'Current Value','Unique Identifier']"
                                                    :fitContainerWidth="true"
                                                >
                                                    <template #body>
                                                        <tr v-for="(item, index) in changeLog.changes" :key="index">
                                                            <td>{{item.identityProperty}}</td>
                                                            <td :style="{'color':changeLogPropertyTextColor(item.readableProperty)}">{{item.readableProperty}}</td>
                                                            <td>{{item.versionValue}}</td>
                                                            <td>{{item.currentValue}}</td>
                                                            <td>{{item.uniqueKey}}</td>
                                                        </tr>
                                                    </template>
                                                </dark-table>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="d-flex justify-content-end">
                                        <button class="btn btn-secondary grey-button m-2" @click="changeLogData = [];">Return</button>
                                        <button class="btn btn-primary cobaltButton m-2" @click="restoreVersion(versionConfigData); changeLogData = [];">Restore</button>
                                    </div>
                                </div>

                            </div>
                         </b-popover>
                        </div>
                    </div>
                    <hr class="w-100" />
                    <div class="row justify-content-center align-items-center mb-2" v-if="hasPermission">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('templateName')}}</div>
                        <div class="col-7">
                        <!--Template name is validated by a watcher function since it can be changed in multiple ways-->
                        <input
                            ref="templateName"
                            class="form-control"
                            type="text"
                            v-model="selectedConfig.templateName"
                        />
                        <span v-if="missingTemplateNameError" class="error-span">A Template Name must be provided</span>
                        </div>
                        <div class="col-2 list-label text-right"></div>
                    </div>
                    <div class="row justify-content-center align-items-center pb-2" v-if="hasPermission && !(isWirelineChart)" >
                        <div class="col-3 list-label text-right">{{inputLabelLookup('header_style')}}</div>
                        <div class="col-7">
                            <select @change="configChanged = true;" v-model="selectedConfig.header_style" class="form-control" ref="headerStyle" :disabled="chartType == 'wireline' || selectedConfig.isDefault == 1">
                                <option value="frac" :selected="selectedConfig.header_style === 'frac'">Frac Status</option>
                                <option value="wireline" :selected="selectedConfig.header_style === 'wireline'">Wireline Status</option>
                                <option value="default" :selected="selectedConfig.header_style === 'default' || selectedConfig.header_style === null">Default Status</option>
                            </select>
                        </div>
                        <div class="col-2 list-label text-right"></div>
                    </div>
                    <div class="row justify-content-center align-items-center pb-2" v-if="hasPermission" >
                        <div class="col-3 list-label text-right">{{inputLabelLookup('controlsPosition')}}</div>
                        <div class="col-7">
                             <select @change="configChanged = true;" class="form-control" ref="chartControlsPosition" v-model="selectedConfig.controlsPosition">
                                <option
                                v-for="position in chartControlsPositions"
                                :value="position"
                                :key="position"
                                >{{position}}</option>
                            </select>
                        </div>
                        <div class="col-2 list-label text-right"></div>
                    </div>
                    <div class="row justify-content-center align-items-center pb-2" v-if="hasPermission" >
                        <div class="col-3 list-label text-right">{{inputLabelLookup('controlsSize') + ' (10-50%)'}}</div>
                        <div class="col-7">
                            <input
                                @change="onControlsSizeChange($event.target.value)"
                                type="number"
                                step="1"
                                :min="minControlsSize"
                                :max="maxControlsSize"
                                class="form-control"
                                :class="{'text-muted': selectedConfig.hideChart == 1}"
                                ref="chartControlsSize"
                                v-model.number="tempChartConfigData.controlsSize"
                                :disabled="selectedConfig.hideChart == 1">
                        </div>
                        <div class="col-2 list-label text-right"></div>
                    </div>
                    <div
                        class="row justify-content-center align-items-center mb-2"  v-if="hasPermission">
                        <div class="col-3 list-label text-right">Axis Type</div>
                        <div class="col-7 text-left">
                        <select
                            ref="xAxisType"
                            class="form-control"
                            @change="configChanged = true;"
                            v-model="xAxisType"
                        >
                            <option :selected="xAxisType == 'Date' ? 'selected' : ''">Date</option>
                            <option :selected="xAxisType != 'Date' ? 'selected' : ''">{{customAxisLabel()}}</option>
                        </select>
                        </div>
                        <div class="col-2 text-left">
                            <button
                                v-if="xAxisType != 'Date'"
                                type="button"
                                class="btn btn-secondary grey-button mr-auto"
                                @click="onChangeCustomXAxis()"
                            >Customize Axis</button>
                        </div>
                        <div class=" col-6 text-left" v-if="customAxisErrorSeen">
                            <span class="error-span">A custom axis must be created</span>
                        </div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2" v-if="hasPermission">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('rightSideMsOffset')}}</div> <!--rightSideMsOffset everywhere else-->
                        <div class="col-7">
                            <input
                                ref="rightSideHourOffset"
                                class="form-control"
                                type="number"
                                step="any"
                                min="0"
                                :disabled="xAxisType !== 'Date'"
                                @change="configChanged = true; selectedConfig.rightSideMsOffset = isNaN($event.target.value)? 0 :  $event.target.value*1000*60*60"
                                :value="!isNaN(selectedConfig.rightSideMsOffset) && selectedConfig.rightSideMsOffset >= 0 ? (selectedConfig.rightSideMsOffset / 1000 / 60 / 60) : 0.5 /* ms to hours  or default to half an hour */"
                            />
                        </div>
                        <div class="col-2 list-label text-right"></div>
                    </div>
                    <div class="row justify-content-center align-items-center mb-2" v-if="hasPermission">
                        <div class="col-3 list-label text-right">{{inputLabelLookup('resolutionZoomHours')}}</div> <!--width of grey bar below time select scrubber-->
                        <div class="col-7">
                            <!--Default value for resolution is 48 hours (2 days)-->
                            <input
                                ref="resolutionZoomHours"
                                class="form-control"
                                type="number"
                                step="any"
                                min="0"
                                :disabled="xAxisType !== 'Date'"
                                @change="selectedConfig.resolutionZoomHours = validateNumberInputs($event.target.value, 0); configChanged = true"
                                v-model.number="selectedConfig.resolutionZoomHours"
                            />
                        </div>
                        <div class="col-2 list-label text-right"></div>
                    </div>
                    <div class="row justify-content-center align-items-center pb-2" v-if="hasPermission && !(isWirelineChart)" >
                        <div class="col-3 list-label text-right">{{inputLabelLookup('defaultZoomAction')}}</div>
                        <div class="col-7">
                            <select @change="configChanged = true;" class="form-control" ref="defaultZoomAction">
                                <option value="dragToZoom" :selected="selectedConfig.defaultZoomAction === 'dragToZoom'">Drag to Zoom</option>
                                <option value="scrollToZoom" :selected="selectedConfig.defaultZoomAction === 'scrollToZoom'">Drag to Pan, Scroll to Zoom</option>
                            </select>
                        </div>
                        <div class="col-2 list-label text-right"></div>
                    </div>

                    <div class="mb-2">
                        <div class="row justify-content-center align-items-center" v-if="hasPermission">
                            <div class="col-4 list-label text-right">
                                {{inputLabelLookup('pinRightSideMsOffset')}}
                            </div>
                            <div class="col-2 text-left">
                                <input
                                    ref="pinRightSideMsOffset"
                                    class="check-box"
                                    type="checkbox"
                                    @change="configChanged = true;"
                                    v-model="selectedConfig.pinRightSideMsOffset"
                                />
                            </div>
                            <div class="col-3 list-label text-right">{{inputLabelLookup('showLegend')}}</div>
                            <div class="col-3 text-left">
                            <input
                                ref="showLegend"
                                class="check-box"
                                type="checkbox"
                                @change="configChanged = true"
                                v-model="selectedConfig.showLegend"
                            />
                            </div>
                            <div class="col-4 list-label text-right">
                                {{inputLabelLookup('isVertical')}}
                            </div>
                            <div class="col-2 text-left">
                                <input
                                    ref="isVertical"
                                    class="check-box"
                                    type="checkbox"
                                    @change="configChanged = true"
                                    v-model="selectedConfig.isVertical"
                                />
                            </div>
                            <div class="col-6">
                                <div v-if="(!isLegacyChart && !isWirelineChart)" class="row">
                                    <div class="col-6 list-label text-right">
                                        {{inputLabelLookup('hideChart')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        <input
                                            ref="hideChart"
                                            class="check-box"
                                            type="checkbox"
                                            @change="configChanged = true; selectedConfig.hideChart = $event.target.checked"
                                            v-model="selectedConfig.hideChart"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-8 list-label text-right">
                                        {{inputLabelLookup('isDateAxisHidden')}}
                                    </div>
                                    <div class="col-4 text-left">
                                        <input
                                            ref="isDateAxisHidden"
                                            class="check-box"
                                            type="checkbox"
                                            @change="configChanged = true; selectedConfig.isDateAxisHidden = $event.target.checked"
                                            v-model="selectedConfig.isDateAxisHidden"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-8 list-label text-right">
                                        {{xAxisType == 'Date'? 'Date Axis Gridlines' : xAxisType + ' Axis Gridlines'}}
                                    </div>
                                    <div class="col-4 text-left">
                                        <input
                                            ref="showVerticalGridlines"
                                            class="check-box"
                                            type="checkbox"
                                            @change="configChanged = true;"
                                            v-model="selectedConfig.showVerticalGridlines"
                                        />
                                    </div>
                                </div>
                                <div class="row" v-if="selectedConfig.showVerticalGridlines">
                                    <div class="col-8 list-label text-right">
                                        {{xAxisType == 'Date'? 'Date Axis Gridline Color' : xAxisType + ' Axis Gridline Color'}}
                                    </div>

                                    <div v-if="selectedConfig.showVerticalGridlines" class="list-label text-right">
                                        <div class="col-4 text-left">
                                            <div class="swatch p-0" @click="showGridLineColorPicker = !showGridLineColorPicker">
                                                <div class="color" :style="{'background-color': selectedConfig.verticalGridlinesColor}" />
                                            </div>
                                            <div v-if="showGridLineColorPicker" class="popover">
                                                <div class="cover" @click="showGridLineColorPicker = false;"/>
                                                <sketch-picker
                                                    ref="verticalGridlinesColor"
                                                    :value="selectedConfig.verticalGridlinesColor ? selectedConfig.verticalGridlinesColor : '#FFFFFFFF'"
                                                    id="verticalGridlinesColor"
                                                    :disableAlpha="false"
                                                    @input="color => {selectedConfig.verticalGridlinesColor = color.hex8; configChanged = true;}"
                                                    :preset-colors="presetColors"/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div class="row">
                                    <div class="col-8 list-label text-right">
                                        {{inputLabelLookup('showNPTLines')}}
                                    </div>
                                    <div class="col-4 text-left">
                                        <input
                                            ref="showNPTLines"
                                            class="check-box"
                                            type="checkbox"
                                            @change="configChanged = true; selectedConfig.showNPTLines = $event.target.checked"
                                            v-model="selectedConfig.showNPTLines"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="col-6" style="align-self: start">
                                <div class="row" >
                                    <div class="col-6 list-label text-right">
                                        {{inputLabelLookup('showStageLines')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        <input
                                            ref="showStageLines"
                                            class="check-box"
                                            type="checkbox"
                                            @change="configChanged = true; selectedConfig.showStageLines = $event.target.checked"
                                            v-model="selectedConfig.showStageLines"
                                        />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-6 list-label text-right">
                                        {{inputLabelLookup('stageLabelType')}}
                                    </div>
                                    <div class="col-6 text-left">
                                        <select
                                            ref="stageLabelType"
                                            class="form-control"
                                            style="width: 60%; height: 90%; font-size: 0.9rem;"
                                            @change="configChanged = true;"
                                            v-model="selectedConfig.stageLabelType"
                                        >
                                            <option value="label">Label</option>
                                            <option value="hover">Hover</option>
                                        </select>
                                    </div>
                                </div>

                                   <div class="">
                                        <div class="row">
                                            <div class="col-6 list-label text-right">
                                                {{inputLabelLookup('hideCommentsTimeline')}}
                                            </div>
                                            <div class="col-6 text-left">
                                                <input
                                                    ref="hideCommentsTimeline"
                                                    class="check-box"
                                                    type="checkbox"
                                                    @change="selectedConfig.hideCommentsTimeline = $event.target.checked"
                                                    v-model="selectedConfig.hideCommentsTimeline"
                                                />
                                            </div>
                                        </div>
                                    </div>

                            </div>
                        </div>
                        <div class="row justify-content-center align-items-center" v-if="hasPermission && (isAdmin || isCompanyAdmin) && selectedConfig.customer_id">
                            <div v-if="showCustomerDefaultToggle" class="col-4 list-label text-right">{{customerDefaultToggleText}}</div>
                            <div v-if="showCustomerDefaultToggle" class="col-2 text-left">
                                <input
                                    @change="onCustomerDefaultToggled($event)"
                                    ref="customerDefault"
                                    class="check-box"
                                    type="checkbox"
                                    v-model="selectedConfig.isDefault"
                                />
                            </div>
                            <div v-else class="col-6"></div>
                            <div v-if="selectedConfig.owned_by === this.userId" class="col-3 list-label text-right">{{inputLabelLookup('shared')}}</div>
                            <div v-if="selectedConfig.owned_by === this.userId" class="col-3 text-left">
                                <input
                                    ref="shareWithCompany"
                                    class="check-box"
                                    type="checkbox"
                                    @change="configChanged = true; selectedConfig.shared = $event.target.checked"
                                    v-model="selectedConfig.shared"
                                />
                            </div>
                            <div v-else class="col-6"></div>
                        </div>
                    </div>
                    <hr class="w-100" />
                    <div class="row mt-2 mb-1 px-3 align-items-center">
                        Sections
                        <i
                        class="fas fa-plus-circle ml-2 show-clicker-finger"
                        v-if="hasPermission"
                        @click="addSection()"
                        ></i>
                    </div>
                    <div
                        v-if="tempChartConfigData.orderedSections.length > 0"
                        class="mx-3 align-items-center"
                        style="overflow-y: auto; overflow-x: hidden; max-height: 300px;">
                        <div>
                            <div v-for="(section,index) in tempChartConfigData.orderedSections" :key="index" class="row">
                                <div class="col ml-4 text-left font-weight-light" style="display: inline">{{ section.name }}</div>
                                <div class="col-2" style="display: inline; float: right; inline-size: fit-content;" v-if="hasPermission">
                                    <i class="fas fa-pen show-clicker-finger mr-2" @click="editSection(section, index)"></i>
                                    <i class="fas fa-trash-alt show-clicker-finger mr-2" @click="deleteSection(section, index)"></i>
                                </div>
                                <div class="col-2" style="display: inline; float: right; inline-size: fit-content;" v-else>
                                    <i class="fas fa-pen show-clicker-finger mr-2" @click="copyConfig(true)"></i>
                                </div>
                                <hr class="w-100 mx-4 my-1" />
                            </div>
                        </div>
                    </div>
                    <div v-else class="mx-3 align-items-center">No sections added</div>
                    </div>
                    <div class="modal-footer d-flex flex-row" style="border-top-width:0px">
                        <button
                            type="button"
                            class="btn btn-secondary grey-button mr-auto"
                            @click="onResetSelectionsPressed()"
                        >Clear User Settings</button>
                        <button
                            type="button"
                            class="btn btn-secondary grey-button"
                            @click="backPressed('config')"
                        >Close</button>
                        <button
                            v-if="hasPermission()"
                            type="button"
                            class="btn btn-success green-button"
                            @click="saveConfig()"
                            :disabled="(!configInputChanged() || !hasPermission())  || errorSeen()"
                        >Save Template</button>
                        <button v-else type="button" class="btn btn-success green-button" disabled>Save Template</button>
                        <b-button
                            type="button"
                            class="btn btn-success green-button"
                            :id="'default-template-warning' + _uid"
                            @click="onUseTemplateSelected()"
                            :disabled="isUseTemplateDisabled"
                        >
                        Use Template
                      </b-button>
                      <b-tooltip v-if="showDefaultTemplateWarning" :target="'default-template-warning' + _uid" placement="bottom" :show="showDefaultTemplateWarning">
                        <span style="color:white;"> Global templates cannot be selected</span>
                      </b-tooltip>
                    </div>
                </div>
            </div>
            <div v-else>
                <div class="modal-body py-0">
                    <div class="mt-3">{{ isLoading }}</div>
                    <div class="spinner-border mt-3 mb-3"></div>
                </div>
            </div>
        </div>
    </b-modal>
</template>

<script>
import Autocomplete from '@trevoreyre/autocomplete-vue';
import GlobalFunctions from '../GlobalFunctions.js';
import moment from 'moment';
import { Sketch } from 'vue-color';
import _ from 'lodash';
import VueGridLayout from 'vue-grid-layout';
const DEFAULT_CONTROLS_SIZE= 30;
const MIN_CONTROLS_SIZE= 10;
const MAX_CONTROLS_SIZE= 50;
const DEFAULT_RESOLUTION_HOURS = 48;
const DEFAULT_DECIMAL_PRECISION = 2;

var deepDiffMapper = function () {
    return {
        VALUE_CREATED: 'created',
        VALUE_UPDATED: 'updated',
        VALUE_DELETED: 'deleted',
        VALUE_UNCHANGED: 'unchanged',
        map: function (obj1, obj2) {
            if (this.isFunction(obj1) || this.isFunction(obj2)) {
                throw 'Invalid argument. Function given, object expected.';
            }
            if (this.isValue(obj1) || this.isValue(obj2)) {
                return {
                    type: this.compareValues(obj1, obj2),
                    data: obj1 === undefined ? obj2 : obj1
                };
            }

            var diff = {};
            for (var key in obj1) {
                if (this.isFunction(obj1[key])) {
                    continue;
                }

                var value2 = undefined;
                if (obj2[key] !== undefined) {
                    value2 = obj2[key];
                }

                diff[key] = this.map(obj1[key], value2);
            }
            for (var key in obj2) {
                if (this.isFunction(obj2[key]) || diff[key] !== undefined) {
                    continue;
                }

                diff[key] = this.map(undefined, obj2[key]);
            }

            return diff;

        },
        compareValues: function (value1, value2) {
            if (value1 === value2) {
                return this.VALUE_UNCHANGED;
            }
            else if (this.isDate(value1) && this.isDate(value2) && value1.getTime() === value2.getTime()) {
                return this.VALUE_UNCHANGED;
            }
            else if (value1 === undefined) {
                return this.VALUE_CREATED;
            }
            else if (value2 === undefined) {
                return this.VALUE_DELETED;
            }
            return this.VALUE_UPDATED;
        },
        isFunction: function (x) {
            return Object.prototype.toString.call(x) === '[object Function]';
        },
        isArray: function (x) {
            return Object.prototype.toString.call(x) === '[object Array]';
        },
        isDate: function (x) {
            return Object.prototype.toString.call(x) === '[object Date]';
        },
        isObject: function (x) {
            return Object.prototype.toString.call(x) === '[object Object]';
        },
        isValue: function (x) {
            return !this.isObject(x) && !this.isArray(x);
        }
    }
}();

export default {
    created() {
        this.defaultControlsSize = DEFAULT_CONTROLS_SIZE;
        this.minControlsSize = MIN_CONTROLS_SIZE;
        this.maxControlsSize = MAX_CONTROLS_SIZE;
    },
    data() {
        return {
            changeLogData: [],
            changeLogOmitKeys: ['id', 'data', 'created_at', 'updated_at', 'deleted_at', //keys to omit from changelog analysis
                                'owned_by', 'user_role', 'index','oldTagname'],
            chartConfigHistoryColumns: [
                {
                    key: 'created_at',
                    label: 'Created At',
                    sortable: true
                },
                {
                    key: 'templateName',
                    label: 'Name',
                    sortable: true
                },
                {
                    key: 'owned_by',
                    label: 'User',
                    sortable: true
                },
                {
                    key: 'user_role',
                    label: 'Role',
                    sortable: true
                },
                {
                    key: null,
                    label: 'Compare To Selected',
                    sortable: false
                },
                {
                    key: null,
                    label: 'Restore',
                    sortable: false
                }
            ],
            propertyDisplayNameLookup: {
                chartSetup:
                            {
                                title: 'Chart Setup',
                                identityProperty: 'templateName'
                            },
                chartYAxes:
                            {
                                title: 'Chart Y Axes',
                                identityProperty: 'label'
                            },
                chartXAxis:
                            {
                                title: 'Custom X Axis',
                                identityProperty: 'label'
                            },
                orderedSections:
                            {
                                title: 'Section Changes',
                                identityProperty: 'name'
                            },
                chartItemChanges:
                            {
                                title: 'Chart Item Changes',
                                identityProperty: 'tagName'
                            },
            },
            perPage: 5,
            sortByCol: 'created_at',
            sortAsc: false,
            showCustomerDefaultToggle: true,
            wellheadTag: 'wellhead_',
            versionHistory: [],
            versionHistoryAll: [],
            showAllPressed: false,
            currentPage: 1,
            totalPages: 1,
            initConfig: null,
            initConfigData: null,
            isActive: false,
            options: [],
            tooltipText: 'Global templates cannot be selected',
            initialSelectedConfig: null,
            newSelectedConfig: null,
            isNewConfigSelected: false,
            chartYAxisMissing: true,
            customLineColor: false,
            chartItemBgColorEnabled: false,
            showGridLineColorPicker: false,
            dashboardItem: null,
            isModalVisible: false,
            confirmClose: false,
            isLoading: null,
            isLoadingVersionHistory: false,
            invalidInput: false,
            tempChartConfigData: null,
            chartControlsSize: null,
            viewSectionList: false,
            usedSections: [],
            selectedConfig: null,
            selectedCustomXAxis: null,
            selectedConfigsChartItems: null,
            selectedSectionsChartItems: null,
            selectedAxis: null,
            selectedChartItem: null,
            selectedSection: null,
            lastSelectedSection: null,
            configPayLoad: {
                templateName: '',
                customerDefault: false,
                showLegend: false,
                isVertical: false,
                chartType: '',
                rightSideMsOffset: 0,
                resolutionZoomHours: 48,
                defaultZoomAction: '',
                pinRightSideMsOffset: false,
                hideChart: false,
                isDateAxisHidden: false,
                chartControlsPosition: '',
                shareWithCompany: false,
                header_style: '',
                showVerticalGridlines: false,
                verticalGridlinesColor: null,
                showStageLines: false,
                showNPTLines: false,
                stageLabelType: 'label'
            },
            selectedCustomXAxisCopy: null,
            axisHolder: null,
            modifiedSections: [],
            chartitemsLayout: [],
            xAxisType: 'Date',
            configSelector: null,
            companyConfigs: [],
            shouldRefreshOnExit: false,
            configChanged: false,
            customXAxisChanged: false,
            axisChanged: false,
            axisIsNew: false,
            primaryAxisChanged: false,
            alignToPrimaryChanged: false,
            tagNameChanged: false,
            chartItemChanged: false,
            chartItemIsNew: false,
            previousFriendlyName: null,
            sectionChanged: false,
            sectionIsNew: false,
            loadingMessages: {
                newConfig: 'Creating new configuration...',
                updatingConfig: 'Updating configuration...',
                copyConfig: 'Copying configuration...',
                newAxis: 'Creating new axis...',
                updatingAxis: 'Updating axis...',
                deleteAxis: 'Deleting axis...',
                newChartItem: 'Creating new data source...',
                updatingChartItem: 'Updating chart item...',
                updatingChartItems: 'Updating chart items...',
                newSection: 'Creating new section...',
                updatingSection: 'Updating Section...',
                deleteSection: 'Deleting Section...'
            },
            tagErrorSeen: false,
            unitErrorSeen: false,
            colorErrorSeen: false,
            nameErrorSeen: false,
            customAxisTagError: false,
            minErrorSeen: false,
            maxErrorSeen: false,
            minMaxErrorSeen: false,
            positionErrorSeen: false,
            sectionErrorSeen: false,
            chartYAxisErrorSeen: false,
            customAxisErrorSeen: false,
            missingTemplateNameError: false,
            isTagAlreadyUsed: false,
            showDefaultColorPicker: false,
            showAxisColorPicker: false,
            showCustomGridlinesColorPicker: false,
            showBGColorPicker: false,
            presetColors: ['#D9251C', '#FFF500', '#281670', '#EA891B', '#01923F', '#7F2454', '#DE5F24', '#F5B900', '#69B92E', '#055D70', '#5A2064', '#A92740', '#000000', '#666666', '#AAAAAA', '#FFFFFF'],
            sectionFailedDelete: false,
            sectionNameSaved: false,
            sectionColumnCountDefault: 3,
            defaultGridItemObj: {	//default column count / 3 because the 'assumed' column size is 3, but need a multiple of that to resize chart item widths
                'x': 0,
                'y': 0,
                'w': this.defaultChartColumnCount / 3,
                'h': 2,
                'i': 0,
                'tagName': '',
                'labelTextOptions': {},
                'dataTextOptions': {}
            },
            chartControlsPositions: [
                'left',
                'right',
                'top',
                'bottom'
            ],
            quickEditModalAccess: false,
            defaultDecimalPrecision: DEFAULT_DECIMAL_PRECISION,
        };
    },
    computed: {
        userHasAdminRole: function() {
            return this.userRoles.includes('admin');
        },
        showVersionHistoryButtons: function() {
            return this.versionHistoryAll?.length > this.perPage;
        },
        isHistoryButtonDisabled: function() {
            return this.currentPage >= this.totalPages || this.showAllPressed;
        },
        isUseTemplateDisabled: function() {
            return !this.isNewConfigSelected || this.isGlobalConfigSelected || this.configInputChanged();
        },
        isGlobalConfigSelected: function() {
            return this.globalConfigs.some(config => config?.id === this.selectedConfig?.id);
        },
        showDefaultTemplateWarning: function() {
            return this.isGlobalConfigSelected && this.isUseTemplateDisabled;
        },
        resolutionZoomHoursValue: {
            get() {
                return !isNaN(this.selectedConfig.resolutionZoomHours) && this.selectedConfig.resolutionZoomHours >= 0 ? this.selectedConfig.resolutionZoomHours : DEFAULT_RESOLUTION_HOURS;
            },
            set(newValue) {
                this.selectedConfig.resolutionZoomHours = isNaN(newValue)? DEFAULT_RESOLUTION_HOURS :  newValue;
            }
        },
        getWellboreWell: function() {
            let isWellHead = this.selectedChartItem.tagName.includes(this.wellheadTag);
            const well = isWellHead ? this.getWell(this.selectedChartItem.tagName) : false;
            return well;
        },
        controlPanelSize: function() {
            if(this.chartControlsSize == null) {
                return DEFAULT_CONTROLS_SIZE;
            } else {
                return this.chartControlsSize;
            }
        },
        globalConfigsForDisplay: function() {
            const configs = this.companyConfigs.filter(c => c.customer_id == null);
            return configs;
        },
        globalConfigsForDisplayLabel: function() {
            return 'Global Templates';
        },
        globalConfigs: function() {
            return this.companyConfigs.filter(c => c.customer_id == null);
        },
        companyConfigsForDisplay: function() {
            const configs = this.companyConfigs.filter(c => (c.owned_by != this.userId || this.iwsUser ) && c.shared && !c.isDefault && c.customer_id != null);
            return configs;
        },
        companyConfigsForDisplayLabel: function() {
            return `${this.customer} Templates`;
        },
        companyFracDefaultConfigForDisplay: function() {
            const configs = this.companyConfigs.filter(c => c.isDefault && c.customer_id != null && c.header_style === 'frac');
            return configs;
        },
        companyFracDefaultConfigForDisplayLabel: function() {
            return `${this.customer} Default Frac Template`;
        },
        companyWirelineDefaultConfigForDisplay: function() {
            const configs = this.companyConfigs.filter(c => c.isDefault && c.customer_id != null && c.header_style === 'wireline');
            return configs;
        },
        companyWirelineDefaultConfigForDisplayLabel: function() {;
            return `${this.customer} Default Wireline Template`;
        },
        personalConfigsForDisplayLabel: function() {
            return 'My Templates';
        },
        personalConfigsForDisplay: function() {
            if(this.iwsUser) {
                const configs = this.companyConfigs.filter(c=>c.owned_by? true : false);

                if(configs.length> 0) {
                    const templatesByUsername =  _.groupBy(configs, 'username');
                    const chartConfigsWithSections = [];
                    const self = this;
                    const myTemplates = [];

                    Object.keys(templatesByUsername).forEach(function(key) {
                        if(templatesByUsername[key][0].owned_by===self.userId) {
                            myTemplates.push(...templatesByUsername[key]);
                        } else {
                            chartConfigsWithSections.push({ sectionName: `- ${key}`, disabled: true });
                            chartConfigsWithSections.push(...templatesByUsername[key]);
                        }
                    });
                    return [...myTemplates, ...chartConfigsWithSections];
                } else {
                    return [];
                }
            } else {
                const configs = this.companyConfigs.filter(c => c.owned_by == this.userId  && !c.shared);
                return configs;
            }
        },
        personalSharedConfigsForDisplayLabel: function() {
            return 'My Shared Templates';
        },
        personalSharedConfigsForDisplay: function() {
            const configs = this.companyConfigs.filter(c => c.owned_by === this.userId && c.shared);
            return configs;
        },
        canDelete: function () {
            return (
                this.selectedConfig.customer_id &&
                ((this.selectedConfig.user_id &&
                this.selectedConfig.user_id == this.userId) ||
                this.isAdmin ||
                this.isCompanyAdmin)
            );
        },
        getPrimaryAxis: function() {
            return this.tempChartConfigData.chartYAxes.find(axis=>axis.isPrimaryAxis);
        },
        customerDefaultToggleText() {
            if (this.showCustomerDefaultToggle) {
                if (this.selectedConfig?.header_style === 'frac') {
                    return 'Customer Frac Default';
                } else if (this.selectedConfig?.header_style === 'wireline') {
                    return 'Customer Wireline Default';
                } else {
                    return 'Customer Default';
                }
            }
            return '';
        }
    },
    watch: {
        sortAsc: {
            handler(newVal, oldval) {
                this.sortVersionHistory(newVal, this.sortByCol);
            }
        },
        sortByCol: {
            handler(newVal, oldval) {
                this.sortVersionHistory(this.sortAsc, newVal);
            }
        },
        companyConfigs: function() {
            if (this.companyConfigs && this.customer) {
                this.buildTemplateDisplayArray();
            }
        },
        selectedChartItem: function() {
            //enforce proper types for input fields
            if (this.selectedChartItem?.lineWidth) {
                this.selectedChartItem.lineWidth = parseInt(this.selectedChartItem?.lineWidth);
            }
            if (this.selectedChartItem?.showDataPoints) {
                this.selectedChartItem.showDataPoints = Boolean(this.selectedChartItem.showDataPoints);
            }
            if (this.selectedChartItem?.selectedByDefault) {
                 this.selectedChartItem.selectedByDefault = Boolean(this.selectedChartItem.selectedByDefault);
            }
        },
        sectionFailedDelete: function () {
            if (this.sectionFailedDelete) {
                alert('Section failed to delete.  It may be in use by another configuration.');
                this.sectionFailedDelete = false;
            }
        },
        sectionNameSaved: function () {
            if (this.sectionNameSaved) {
                alert('Section saved, may now add data sources.');
                this.sectionNameSaved = false;
            }
        },
        'selectedConfig.templateName': {
            handler: function() {
                this.validateTemplateName();
            }
        },
        selectedConfig: {
            deep: true,
            handler(newVal, oldval) {
                //whenever there is a change to configs, update in companyConfigs
                this.companyConfigs.forEach((config, index) => {
                    if (config?.id == this.selectedConfig?.id) {
                        config = this.selectedConfig;
                    }
                });

                const {
                    controlsPosition,
                    header_style,
                    hideChart,
                    isDefault,
                    rightSideMsOffset,
                    pinRightSideMsOffset,
                    shared,
                    showLegend,
                    isVertical,
                    templateName,
                    xaxis,
                    showVerticalGridlines,
                    verticalGridlinesColor,
                    defaultZoomAction,
                    hideCommentsTimeline
                } = this.selectedConfig;

                this.initPageConfigData = {
                    controlsPosition,
                    header_style,
                    hideChart,
                    isDefault,
                    rightSideMsOffset,
                    pinRightSideMsOffset,
                    shared,
                    showLegend,
                    isVertical,
                    templateName,
                    xaxis,
                    showVerticalGridlines,
                    verticalGridlinesColor,
                    defaultZoomAction,
                    hideCommentsTimeline
                };

                if(this.isFeatureFlagged('CHART_CONFIG_HISTORY')){
                    //reset version history variables
                    this.currentPage = 1;
                    this.versionHistory = [];
                    this.showAllPressed = false;
                    this.loadVersionHistoryPerPage(this.selectedConfig.id);
                    //only want to update versionHistoryAll to show count of template version history:
                    this.loadVersionHistory(this.selectedConfig.id, false);
                }
                if (this.selectedConfig.isDefault == false && this.selectedConfig.header_style !== 'default') {
                    this.showCustomerDefaultToggle = true;
                } else {
                    this.showCustomerDefaultToggle = false;
                }
            }
        }
    },
    mounted() {
        document.addEventListener('click', function(event) {
            let dropdown = document.querySelector('.select-dropdown');
            let select = document.querySelector('.select');
            if (select && dropdown) {
                if (!dropdown.contains(event.target) && !select.contains(event.target)) {
                    dropdown.classList.remove('active');
                }
            }
        });
        this.loadCustomerConfigTemplates();
    },
    updated() { //Access ref element values that are populated on render
        if (this.configChanged || this.configPayLoad.templateName === '') {
            this.$nextTick(function() {
                this.updateConfigTemplate();
            });
        }
    },
    props: [
        'wells',
        'isWirelineChart',
        'isLegacyChart',
        'tags',
        'jobNumber',
        'isAdmin',
        'isCompanyAdmin',
        'userId',
        'componentID',
        'iwsUser',
        'dashboardId',
        'customer',
        'chartType',
        'defaultChartColumnCount',
        'jobHourOffset',
        'userRoles'
    ],
    components: {
        Autocomplete,
        'sketch-picker': Sketch
    },
    methods: {
        sortVersionHistory(sortAsc, sortByCol) {
            if (sortByCol) {
                this.versionHistory = _.orderBy(this.versionHistory, sortByCol, sortAsc ? 'asc' : 'desc');
            }
        },
        showMore: function() {
            if (this.currentPage < this.totalPages) {
                this.currentPage++;
                this.loadVersionHistoryPerPage(this.selectedConfig.id);
            }
        },
        showAll: function() {
            this.showAllPressed = true;
            this.loadVersionHistory(this.selectedConfig.id);
        },
        getPrefixSuffixDisplay(chartItem) {
            // if none of these are set, return the friendly name
            if (chartItem.tagName.includes(this.wellheadTag)) {
                return chartItem.prefixName || chartItem.suffixName || chartItem.wellShortName || chartItem.suffixPositionName;
            } else {
                return false;
            }
        },
        getTagAbbreviation(tagName) {
            // adds VP or HP to the well name if the chart item is a valve position or handle position
            const valvePositionTag = '_valvePosition_';
            const handlePositionTag = '_handlePosition_';
            let isValvePosition = tagName.includes(valvePositionTag);
            let isHandlePosition = tagName.includes(handlePositionTag);
            let type = isValvePosition ? ' VP' : isHandlePosition ? ' HP' : '';
            return type;
        },
        getTagDataByTagName(tagName) {
            return this.tags.find(tag => tag.name == tagName);
        },
        isWellheadTag(tagName) {
            const tag = this.getTagDataByTagName(tagName);
            return !!tag?.isWellheadTag;
        },
        getWellheadPrefixForTag(tagName) {
            const tag = this.getTagDataByTagName(tagName);
            return tag?.wellheadTagName?.prefix ?? '';
        },
        getWellheadSuffixTitleForTag(tagName) {
            const tag = this.getTagDataByTagName(tagName);
            return tag?.wellheadTagName?.suffixTitle ?? '';
        },
        getWellheadTagNameForChartItem(chartItem) {
            if (chartItem.tagName == 'wellhead_3_pressure_intermediateCasing2') {
            }
            const tag = this.getTagDataByTagName(chartItem.tagName);
            let prefixName = chartItem?.prefixName || '';
            let suffixTitleName = '';
            if (tag && tag?.isWellheadTag) {
                //wellhead tag names should have data for special prefixes, but if not
                //fallback to the friendly name prefix input by the chart config modal
                prefixName = tag?.wellheadTagName?.prefix != '' ? tag.wellheadTagName.prefix : prefixName;
                suffixTitleName = tag?.wellheadTagName?.suffixTitle ?? ''
            }
            return `${prefixName} ${suffixTitleName} ${chartItem?.suffixName || ''} (${tag?.name || ''})`;
        },
        isFeatureFlagged(featureString) {
            return GlobalFunctions.isFeatureFlagged(featureString);
        },
        buildTemplateDisplayArray() {
            let options = [];

            if (this.iwsUser) {
                options.push({
                    label: this.globalConfigsForDisplayLabel,
                    options: this.globalConfigsForDisplay
                });
            }
            this.buildTemplateDisplayArrayHandler(options, this.companyFracDefaultConfigForDisplay, this.companyFracDefaultConfigForDisplayLabel);
            this.buildTemplateDisplayArrayHandler(options, this.companyWirelineDefaultConfigForDisplay, this.companyWirelineDefaultConfigForDisplayLabel);
            this.buildTemplateDisplayArrayHandler(options, this.companyConfigsForDisplay, this.companyConfigsForDisplayLabel);
            this.buildTemplateDisplayArrayHandler(options, this.personalSharedConfigsForDisplay, this.personalSharedConfigsForDisplayLabel);
            this.buildTemplateDisplayArrayHandler(options, this.personalConfigsForDisplay, this.personalConfigsForDisplayLabel);
            this.options = options;
        },
        buildTemplateDisplayArrayHandler(options, templates, label) {
            if (templates.length > 0) {
                options.push({
                    label: label,
                    options: templates,
                });
            }
        },
        customAxisLabel: function () {
            let resultString = 'Custom';
            //need to check both here so that updates to the selected axis re-compute this variable output
            if (this.tempChartConfigData.chartXAxis) {
                resultString += `: ${this.tempChartConfigData.chartXAxis?.label ?? 'No Label'}`;
            }
            else {
                resultString += ': No Axis Created';
            }
            return resultString;
        },
        onCustomerDefaultToggled($event) {
            if ($event.target.checked) {
                if (this.selectedConfig.header_style === 'default') {
                    this.selectedConfig.isDefault = 0;
                    $event.target.checked = 0;
                    alert('Header style must be frac or wireline for use as a company default template.')
                } else {
                    let confirmCustomerDefaultChange = `Are you sure you want to set ${this.selectedConfig.templateName} as ${this.selectedConfig.header_style} default template? Doing so will replace current ${this.selectedConfig.header_style} customer default template if there is one.`;
                    if (confirm(confirmCustomerDefaultChange)) {
                        this.configChanged = true;
                        this.selectedConfig.isDefault = 1;
                    } else {
                        this.selectedConfig.isDefault = 0;
                        $event.target.checked = 0;
                    }
                }
            } else {
                this.configChanged = true;
                this.selectedConfig.isDefault = 0;
            }
        },
        errorSeen: function() {
            return (this.missingTemplateNameError || this.isTagAlreadyUsed || this.tagErrorSeen || this.unitErrorSeen || this.colorErrorSeen || this.nameErrorSeen ||
                    this.customAxisTagError || this.minErrorSeen || this.maxErrorSeen || this.minMaxErrorSeen || this.positionErrorSeen ||
                    this.sectionErrorSeen || this.chartYAxisErrorSeen || this.customAxisErrorSeen);
        },
        //TO DO: Chart config history can release in a v1 state, without having to provide a count of changes made
        //There are some issues related to the curent compareChanges method that will be resolved in a v2. There issues are:
        //1. Some properties of the selectedConfig are only present at runtime, and are not in the db, so they should not
        //be considered as a part of the change count.
        //2. All changes within the config 'data' (orderedSections, chartYAxes etc) seem to be considered as 1 change due to how it is
        //structured as a json string

        // compareChanges: function(config) {
        //     let changes = deepDiffMapper.map(config, this.selectedConfig);

        //     let updated = [];
        //     let deleted = [];

        //     for (let prop in changes) {
        //         // if the prop is an object
        //         if (typeof changes[prop] === 'object') { // exclude values we don't care about in the diff
        //             if(['id', 'owned_by', 'created_at', 'updated_at', 'deleted_at', 'version_id', 'user_role'].includes(prop)) {
        //                 continue;
        //             }
        //             else if(changes[prop].type == "updated"){
        //                 updated.push(prop);
        //             }
        //             else if(changes[prop].type == "deleted"){
        //                 deleted.push(prop);
        //             }

        //         }
        //     }


        //     changes = { updated: updated, deleted: deleted };

        //     let str;
        //     if(changes.updated.length > 0){
        //         str = "<b v-b-tooltip.hover title='" + changes.updated + "'>" + changes.updated.length + "</b> properties changed <br/>";
        //     }
        //     else if(changes.deleted.length > 0){
        //         str += "<b v-b-tooltip.hover title='" + changes.deleted + "'>" + changes.deleted.length + "</b> properties changed <br/>";

        //         str += "<b>" + changes.deleted.length + "</b> properties deleted <br/>"
        //     }
        //     else{
        //         str = "No changes from current version";
        //     }
        //     return str;
        // },
        loadVersionHistory: function(config_id, adjustVersionHistoryToShow = true) {
            //function retrieves all chart config history for a template
            const url = '/user/config/history/' + config_id;
            this.loadVersionHistoryData(url, adjustVersionHistoryToShow);
        },
        loadVersionHistoryPerPage: function(config_id) {
            //function retrieves 5 chart config history versions for a template at a time
            const url = `/user/config/history/paginated/${config_id}?page=${this.currentPage}&perPage=${this.perPage}`;
            this.loadVersionHistoryData(url);
        },
        loadVersionHistoryData: function(url, adjustVersionHistoryToShow = false) {
            const self = this;
            this.isLoadingVersionHistory = true;
            $.get(
                url,
                {},
                function(result) {
                    self.isLoadingVersionHistory = false;
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        if (result.length && adjustVersionHistoryToShow) {
                            //version history that is shown in table is updated for selected config
                            self.versionHistory = self.sortAndConvertDates(result);
                        }
                        if (result.length) {
                            //update versionHistoryAll to show updated count in popover
                            self.versionHistoryAll = self.sortAndConvertDates(result);
                        }
                        //paginated version history from backend has form result.data
                        if (result.data && result.data.length) {
                            self.versionHistory = self.versionHistory.concat(self.sortAndConvertDates(result.data));
                            self.totalPages = result.last_page;
                        }
                    }
                },
                'json'
            ).fail(function(jqXHR, textStatus, errorThrown) {
                console.warn("error loading version history");
                self.historyExists = false;

                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                    //TODO: handle this
                }
            });
        },
        sortAndConvertDates: function(dataArray) {
            const self = this;
            const sortedArray = dataArray.sort(function(a, b) {
                return new Date(b.created_at) - new Date(a.created_at);
            });
            sortedArray.forEach(function(item) {
                item.created_at = moment.utc(item.created_at)
                .subtract(self.jobHourOffset, 'hours')
                .format('MM/DD/YYYY h:mm:ss a');
            });
            return sortedArray;
        },
        hasPermission: function () {
            let allowed = false;
            if (this.userHasAdminRole) {//this.isAdmin is true for all iws users throughout the project
                allowed = true;
            } else {
                if (this.selectedConfig.customer_id != null) {
                    //if not default check if company admin to edit
                    if (this.isCompanyAdmin || this.iwsUser) {
                        allowed = true;
                    } else if (this.selectedConfig.user_id && this.selectedConfig.user_id == this.userId) {//if not company admin check if user created to edit
                        allowed = true;
                    }
                }
            }
            return allowed;
        },
        configInputChanged: function () {
            return this.chartItemChanged || this.chartItemIsNew || this.axisChanged || this.axisIsNew
                || this.sectionChanged || this.sectionIsNew || this.customXAxisChanged || this.customXAxisIsNew || this.configChanged;
        },
        init() {
            this.assignValues();
        },
        mapConfig() {
            if(!this.selectedConfig?.sections) {
                this.selectedConfig.sections = [];
            }

            if (this.selectedConfig && (!this.selectedConfig.yaxes)) {
                const configData = JSON.parse(this.selectedConfig.data);
                if(typeof configData === 'object') {
                    const yAxes = [...configData.chartYAxes];
                    configData.orderedSections.forEach(section => {
                        yAxes.forEach(yAxis => {
                            section.orderedChartItems.forEach(chartItem => {
                                if(chartItem?.chartYAxis_key) {
                                    //save chartItem info and section key to temp item
                                    let tempChartItem = {};
                                    tempChartItem = Object.assign(tempChartItem, chartItem);
                                    tempChartItem.sectionKey = section.key;
                                    //if chart item has correct yAxis key add it to the axis chart item list
                                    if(chartItem.chartYAxis_key === yAxis.key) {
                                        //if no items exist populate with empty array
                                        if(typeof yAxis.chartItems === 'undefined') {
                                            yAxis.chartItems = [];
                                        }
                                        yAxis.chartItems.push(tempChartItem);
                                    }
                                }
                            });
                        });
                    });
                    yAxes.forEach(yAxis => {
                        if(typeof yAxis.chartItems === 'undefined') {
                            yAxis.chartItems = [];
                        }
                    });

                    this.selectedConfig.yaxes = yAxes;
                }
                if (configData.chartXAxis) {
                    this.tempChartConfigData.chartXAxis = _.cloneDeep(configData).chartXAxis;
                    this.xAxisType = this.customAxisLabel();
                }
            }
        },
        onControlsSizeChange(newVal) {
            this.configChanged = true;
            let chartControlsSize = parseInt(newVal);
            if(chartControlsSize > MAX_CONTROLS_SIZE) {
                chartControlsSize = MAX_CONTROLS_SIZE;
            } else if (chartControlsSize < MIN_CONTROLS_SIZE || isNaN(chartControlsSize) || chartControlsSize == null) {
                chartControlsSize = MIN_CONTROLS_SIZE;
            }
            this.tempChartConfigData.controlsSize = chartControlsSize;
        },
        assignValues() {
            this.selectedConfigsChartItems = [];
            this.tempChartConfigData = JSON.parse(this.selectedConfig.data);
            this.tempChartConfigData.chartYAxes = _.cloneDeep(this.selectedConfig.yaxes);
            this.tempChartConfigData.controlsSize = this.tempChartConfigData.controlsSize || DEFAULT_CONTROLS_SIZE;
            if (this.tempChartConfigData.chartXAxis) {
                this.xAxisType = this.customAxisLabel();
            } else {
                this.xAxisType = 'Date';
            }
            //store of initial config state since last save or page load
            this.initConfig = _.cloneDeep(this.selectedConfig);
            this.initConfigData = _.cloneDeep(this.tempChartConfigData);
        },
        sortList(list, idList) {
            list.sort( function (a, b) {
                const A = a.id;
                const B = b.id;

                if (idList.indexOf(A) > idList.indexOf(B)) {
                    return 1;
                } else {
                    return -1;
                }
            });

            return list;
        },
        getUsedTags(targetConfig) {
            //Returns an array of tagNames representing all of the tags attached to chart items on targetConfig
            const returnArray = [];
            targetConfig.yaxes.forEach(axis => {
                if(axis?.chartItems) {
                    axis.chartItems.forEach(chartitem => {
                        //Should add to the array only if it's not already in there
                        if(returnArray.indexOf(chartitem.tagName) === -1) {
                            returnArray.push(chartitem.tagName);
                        }
                    });
                }
            });

            return returnArray;
        },
        search(input) {
            //Unable to find documentation of the issue online but with section autocomplete on the page right before tag and chartyaxis autocomplete
            //  an issue occurs where the tag autocomplete uses the sections search method instead of its own, so to remedy this we combined tag and section search.
            //  Strangely enough tag uses its own get results and its own placeholder.  However it does take the default value from section initially.
            //  We check what page the search occurs on to determine what search function is required.  For some reason axis search didn't get effected by
            //  the original bug or the fix, so due to the fact that it would be hard to determine whether tag or axis search was being used as they are on the same page
            //  we just kept chartYAxisSearch search separate from this combined search.
            if (this.selectedChartItem) {
                const usedTags = this.getUsedTags(this.selectedConfig);

                //If we are editing an existing data source we don't want to remove it's tagName from the available list
                if(this.selectedChartItem.tagName.length > 0) {
                    const targetIndex = usedTags.indexOf(this.selectedChartItem.tagName);
                    usedTags.splice(targetIndex,1);
                }

                return this.tags.filter((tag) => {
                    const name = (tag.TagName || tag.name);
                    if(name && (usedTags.indexOf(name) === -1)) {
                        return name.toLowerCase().includes(input.toLowerCase());
                    } else {
                        return false;
                    }
                });
            }
            else if (this.selectedCustomXAxis) {
                return this.tags.filter((tag) => {
                    const name = (tag.TagName || tag.name);
                    if(name) {
                        return name.toLowerCase().includes(input.toLowerCase());
                    } else {
                        return false;
                    }
                });
            }
            else {
                let filteredSections = [];
                //Section search needs to display all sections the user is able to edit whether used on this config or not
                //Restrictions are: Unless config is default can not use default sections
                //                  Section must not be used on this config already
                //                  User must have edit permission on the section (regular user can't select a company section)

                //first check if config is default

                //looks like componentID decides is it a wireline chart or a frac chart
                //adding this flag to ignore section filter depending on the chart
                let ignoreComponentTypeCheck = false;

                if(!this.componentID) { //componentID comes null in customDashboard
                    ignoreComponentTypeCheck = true;
                }


                if (this.selectedConfig.customer_id === null) {
                    filteredSections = this.selectedConfig.sections.filter(section => {
                        return !section.customer_id && ( ignoreComponentTypeCheck || section.componenttype_id === this.componentID);
                    });
                } else {
                    //Find out if this is a company config or user config
                    if (this.selectedConfig.user_id && this.selectedConfig.user_id == this.userId) {
                        //user config
                        filteredSections = this.selectedConfig.sections.filter(section => {
                            return section.user_id === this.userId && ( ignoreComponentTypeCheck || section.componenttype_id === this.componentID);
                        });
                    } else {
                        //company config
                        filteredSections = this.selectedConfig.sections.filter(section => {
                            return !section.user_id && ( ignoreComponentTypeCheck || section.componenttype_id === this.componentID) && section.customer_id;
                        });
                    }
                }

                //Now remove any section already used on this config
                this.usedSections.forEach(section => {
                    const index = filteredSections.findIndex(
                        (s) => s.id === section.id
                    );

                    if (index != -1) {
                        filteredSections.splice(index, 1);
                    }
                });

                if (input.length < 1) {
                    return filteredSections;
                }
                return (filteredSections).filter((section) => {
                    return section.name.toLowerCase().includes(input.toLowerCase());
                });
            }
        },
        chartYAxisSearch(input) {
            this.chartItemChanged = false;
            if (input.length < 1) {
                return this.selectedConfig.yaxes;
            }
            return (this.selectedConfig.yaxes).filter((yAxis) => {
                return yAxis.label.toLowerCase().includes(input.toLowerCase());
            });
        },
        getResultValue(result) {
            return result.name;
        },
        getSectionValue(result) {
            this.$refs.sectionID.value = result.id;
            return result.name;
        },
        validateNumberInputs(inputData, minValue=null, maxValue=null) {
            let numberValue = Number(inputData);
            if (!isNaN(numberValue) || numberValue != null) {
                if (typeof minValue === 'number' && numberValue < minValue) {
                    numberValue = minValue;
                } else if (typeof maxValue === 'number' && numberValue > maxValue) {
                    numberValue = maxValue;
                }
                return numberValue;
            }
            return null;

        },
        getChartYAxisValue(result) {
            return result.label;
        },
        checkChartYAxis(input) {
            // checks if input from Chart Axis is valid when user types in input and when user selects from dropdown
            let found = false;
            this.invalidInput = false;

            if (!input) {
                // when hit enter, sends an empty object
                this.invalidInput = true;
                return;
            }
            if (input instanceof Event && input.target.value) {
                // true if the user types in the input for Chart Axis
                // checks if the user input is an actual Chart Axis option
                found = this.selectedConfig.yaxes.find(
                    (yAxis) => yAxis.label === input.target.value
                );
            }

            if (input.hasOwnProperty('key') || found && found.hasOwnProperty('key')) {
                // true if the user selects an option from the dropdown or typed valid Chart Axis option
                this.chartYAxisMissing = false;
                this.chartItemChanged = true;
                const axisKey = input.key || found.key;
                //update chart items assigned axis
                this.selectedChartItem.chartYAxis_key = axisKey;
            } else {
                this.invalidInput = true;
                this.chartYAxisMissing = true;
                this.chartItemChanged = false;
            }
        },
        onResetSelectionsPressed() {
            if (
                confirm(
                    'Are you sure you want to revert back to default data source selections?'
                )
            ) {
                const jsonLocalStorageKey =
                    this.jobNumber +
                    '.' + this.selectedConfig.id +
                    '.' + this.dashboardId +
                    '.' + this.dashboardItem.i;
                localStorage.removeItem(jsonLocalStorageKey);

                //Refresh the page
                location.reload();
            }
        },
        //Config Methods
        switchToConfig: function (config_id) {
            this.shouldRefreshOnExit = true;
            this.selectedConfig = this.companyConfigs.find(
                (config) => config.id == config_id
            );

            this.mapConfig();
            //Edit select for user
            const self = this;
            const url = '/user/config/selected/edit';

            this.isLoading = this.loadingMessages.updatingConfig;

            $.post(
                url,
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    jobNumber: self.jobNumber,
                    chartConfig_id: config_id,
                    chartType: self.selectedConfig.type,
                    dashboardId: self.dashboardId ?? null,
                    dashboardItemIndex: self.dashboardItem ? self.dashboardItem.i : null
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    }
                    else {
                        self.isLoading = null;
                        self.clearAllConfigErrors();
                        self.clearAllConfigChangedFlags();
                        self.assignValues();
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to update selected configuration';
                console.warn(errorMessage, errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                    //TODO: handle this
                }
            });
        },
        compareVersion: function(compareConfig) {
            this.versionConfigData = compareConfig;
            //get unaltered version of selected config
            const currentConfigData = this.companyConfigs.find(config => config.id === this.selectedConfig.id);
            const changeLog = [];
            //keys that will have their values modified from what is stored in the db for display in the change Log
            const customModifyValueKeys = ['rightSideMsOffset','hideChart','isDateAxisHidden','isDefault','isVertical','pinRightSideMsOffset','shared','showLegend','showNPTLines','showstageLines','showVerticalGridlines'];
            //get a unique set of all keys from the templates, minus any keys to be omitted
            const data = _.uniq([
                ...Object.keys(this.versionConfigData,
                ...Object.keys(currentConfigData))])
                .filter(key => !this.changeLogOmitKeys.includes(key));
            //get the nested JSON config data from each template
            const currentJSONData = JSON.parse(currentConfigData.data);
            const versionJSONData = JSON.parse(this.versionConfigData.data);

            //iterate through the column values of the templates looking for changes
            for(const key of data){
                if(!_.isEqual(currentConfigData[key], this.versionConfigData[key])){
                    if (changeLog.length > 0) {
                        const setup = changeLog.find(o => o.property == 'chartSetup');
                        setup?.changes.push({
                                identityProperty: currentConfigData[this.propertyDisplayNameLookup['chartSetup'].identityProperty] || null,
                                prop: key,
                                uniqueKey: null,
                                currentValue: currentConfigData[key] || null,
                                versionValue: this.versionConfigData[key] || null
                        });
                    } else {
                        changeLog.push({
                            property: 'chartSetup',
                            changes: [{
                                identityProperty: currentConfigData[this.propertyDisplayNameLookup['chartSetup'].identityProperty] || null,
                                prop: key,
                                uniqueKey: null,
                                currentValue: currentConfigData[key] || null,
                                versionValue: this.versionConfigData[key] || null
                            }]
                        });
                    }
                }
            }
            //iterate through the nested JSON data of the templates
            for(const key of Object.keys({...versionJSONData,...currentJSONData})) {
                if (_.isArray(versionJSONData[key]) || _.isArray(currentJSONData[key])) {
                    if (key == 'orderedSections') {
                        const justCurrentSectionData = [];
                        const justVersionSectionData = [];
                        const sectionChartItems = {};

                        currentJSONData[key].forEach(section => {
                            //analyze only section data here, chart items are checked after
                            const {orderedChartItems,chartItemLayout, ...justSectionData} = section;
                            //store chart item data for later analysis
                            justCurrentSectionData.push(justSectionData);
                            if (sectionChartItems[section?.key]) {
                                sectionChartItems[section?.key].current = section.orderedChartItems;
                            } else {
                                sectionChartItems[section?.key] = {};
                                sectionChartItems[section?.key].current = section.orderedChartItems;
                            }
                        });
                        versionJSONData[key].forEach(section => {
                            //analyze only section data here, chart items are checked after
                            const {orderedChartItems,chartItemLayout, ...justSectionData} = section;
                            justVersionSectionData.push(justSectionData);
                            //store chart item data for later analysis
                            if (sectionChartItems[section?.key]) {
                                sectionChartItems[section?.key].version = section.orderedChartItems;
                            } else {
                                sectionChartItems[section?.key] = {};
                                sectionChartItems[section?.key].version = section.orderedChartItems;
                            }
                        });
                        //check for any changes in the section between template versions
                        const newSectionChanges = this.checkTemplateArrayForChanges(key, 'key', justCurrentSectionData, justVersionSectionData);
                        if (newSectionChanges) {
                            changeLog.push(newSectionChanges);
                        }
                        //analyze chart items in each section for changes
                        Object.values(sectionChartItems).forEach(sectionItemArray => {
                            const newChartItemChanges = this.checkTemplateArrayForChanges('chartItemChanges','tagName',sectionItemArray.current,sectionItemArray.version);
                            if (newChartItemChanges) {
                                const chartItemChangesIndex = changeLog.findIndex(o => o.property === 'chartItemChanges');
                                if (chartItemChangesIndex == -1) {
                                    changeLog.push(newChartItemChanges);
                                } else {
                                    changeLog[chartItemChangesIndex].changes.push(...newChartItemChanges.changes);
                                }
                            }
                        });
                    }
                }
                else if (key == 'chartYAxes') {
                    const newChanges = this.checkTemplateArrayForChanges(key, 'key', currentJSONData[key], versionJSONData[key]);
                    if (newChanges) {
                        changeLog.push(newChanges);
                    }
                } else if (key == 'chartXAxis') {
                    const currentChartXAxis = currentJSONData.hasOwnProperty(key) ? [currentJSONData[key]] : null;
                    const versionChartXAxis = versionJSONData.hasOwnProperty(key) ? [versionJSONData[key]] : null;
                    const newChanges = this.checkTemplateArrayForChanges(key, 'tagName', currentChartXAxis, versionChartXAxis);
                    if (newChanges) {
                        changeLog.push(newChanges);
                    }
                }
            }
            changeLog.forEach((table,tableIndex) => {
                table.changes.forEach((change, changeIndex) => {
                    changeLog[tableIndex].changes[changeIndex].readableProperty = this.inputLabelLookup(change.prop);
                    if (customModifyValueKeys.includes(change.prop)) {
                        changeLog[tableIndex].changes[changeIndex].versionValue = this.modifyChangeLogPropertyValue(change.prop, change.versionValue);
                        changeLog[tableIndex].changes[changeIndex].currentValue = this.modifyChangeLogPropertyValue(change.prop, change.currentValue);
                    }
                });
            });
            this.changeLogData = changeLog;
            return null;
        },
        modifyChangeLogPropertyValue(property, value) {
            //converts a special value from what it is stored as in the db to what it is displayed as in the modal
            switch(property) {
                case 'rightSideMsOffset':
                    return value / 60 / 60 / 1000 // milliseconds to hours
                case 'hideChart':
                    return value === 1 || value ===  true ? true : false;
                case 'isDateAxisHidden':
                    return value === 1 || value ===  true ? true : false;
                case 'isDefault':
                    return value === 1 || value ===  true ? true : false;
                case 'isVertical':
                    return value === 1 || value ===  true ? true : false;
                case 'pinRightSideMsOffset':
                    return value === 1 || value ===  true ? true : false;
                case 'shared':
                    return value === 1 || value ===  true ? true : false;
                case 'showLegend':
                    return value === 1 || value ===  true ? true : false;
                case 'showNPTLines':
                    return value === 1 || value ===  true ? true : false;
                case 'showstageLines':
                    return value === 1 || value ===  true ? true : false;
                case 'showVerticalGridlines':
                    return value === 1 || value ===  true ? true : false;
                default:
                    return null;
            }
        },
        checkTemplateArrayForChanges(configKeyName, uniqueKey, current, version) {
            // local function handles case where either current or past version of element does not exist
            const handleNullDifference = (currentObj, versionObj, uniqueKey) => {
                const result = [];
                const action = currentObj == null ? 'delete' : 'create';
                const changes = {
                    prop: action,
                    uniqueKey: uniqueKey
                }
                    if (action === 'delete') {
                        changes.versionValue = versionObj[uniqueKey];
                        changes.identityProperty = versionObj[this.propertyDisplayNameLookup[configKeyName].identityProperty] || null;
                    } else {
                        changes.currentValue = currentObj[uniqueKey];
                        changes.identityProperty = currentObj[this.propertyDisplayNameLookup[configKeyName].identityProperty] || null;
                    }
                    result.push(changes);
                    return result;
            };

            const isEqual = _.isEqual(current, version);
            if (!isEqual) {
                const changes = {
                    property: configKeyName,
                    changes: []
                };
                if (current == null || version == null) {
                    const currentExists = current != null ? true : false;
                    if (currentExists) {
                        current.forEach(o => {
                            changes.changes.push(...handleNullDifference(o, null, uniqueKey))
                        })
                    } else {
                        version.forEach(o => {
                            changes.changes.push(...handleNullDifference(o, null, uniqueKey))
                        })
                    }
                } else {
                    //check if current or version is longer, and loop over the longer collection
                    const lengthDiff = (current?.length) - (version?.length);
                    if (lengthDiff >= 0) {//loop over current template collection
                        //find all objects that have changed between the versions, return differences in current
                        const diff = _.differenceWith(current, version, _.isEqual);
                        diff.forEach( currentObj => {
                            //find the corresponding obj in version
                            const versionObj = version.find(o => o[uniqueKey] == currentObj[uniqueKey]);

                            //check if either is null
                            if (currentObj == null || versionObj == null) {
                                changes.changes.push(...handleNullDifference(currentObj, versionObj, uniqueKey));
                            } else {
                                for(const testKey in currentObj) {//find the properties that changed between the versions, return items from current
                                    if (this.changeLogOmitKeys.includes(testKey)) {
                                        continue;
                                    }
                                    if (!_.isEqual(currentObj[testKey], versionObj[testKey])) {
                                        changes.changes.push({
                                            identityProperty: currentObj[this.propertyDisplayNameLookup[configKeyName].identityProperty] || null,
                                            prop: testKey,
                                            uniqueKey: currentObj[uniqueKey],
                                            currentValue: currentObj[testKey],
                                            versionValue: versionObj[testKey]
                                        });
                                    }
                                }
                            }
                        });
                    } else {//loop over past template version collection
                        //find all objects that have changed between the versions, return items from version
                        const diff = _.differenceWith(version, current, _.isEqual);
                        diff.forEach( versionObj => {
                            //find the corresponding obj in version
                            const currentObj = current.find(o => o[uniqueKey] == versionObj[uniqueKey]);

                            //check if either is null
                            if (currentObj == null || versionObj == null) {
                                changes.changes.push(...handleNullDifference(currentObj, versionObj, uniqueKey));
                            } else {
                                for(const testKey in versionObj) {//find the properties that changed between the versions
                                    if (this.changeLogOmitKeys.includes(testKey)) {
                                        continue;
                                    }
                                    if (!_.isEqual(versionObj[testKey], currentObj[testKey])) {
                                        changes.changes.push({
                                            prop: testKey,
                                            identityProperty: versionObj[this.propertyDisplayNameLookup[configKeyName].identityProperty] || null,
                                            uniqueKey: currentObj[uniqueKey],
                                            currentValue: currentObj[testKey],
                                            versionValue: versionObj[testKey]
                                        });
                                    }
                                }
                            }
                        });
                    }
                }
                if (changes.changes.length > 0) {
                    return changes;
                }
            }
            return null;
        },
        inputLabelLookup(key) {//converts a property name stored in the db to what is displayed as the label in the model
            const specialLabels = {//labels that differ from their db column or JSON key names in a non-predictable way
                //chart setup
                'controlsPosition': 'Control Panel Location',
                'controlsSize': 'Control Panel Size',
                'header_style': 'Header Style',
                'rightSideMsOffset': 'End Time Offset (hours)',
                'resolutionZoomHours': 'Default Resolution Zoom (hours)',
                'pinRightSideMsOffset': 'Pin to Latest Data',
                'isVertical': 'VerticalChart',
                'isDateAxisHidden': 'Hide Date Axis',
                'showVerticalGridlines': this.customAxisLabel() + ' Gridlines',
                'isDefault': 'Customer Default',
                //chart axes
                'min': 'Minimum',
                'max': 'Maximum',
                'ticks': '# of Tick Intervals',
                'isPrimaryAxis': 'PrimaryAxis',
                'logarithmic': 'Logarithmic Scale',
                'hideYAxis': 'Hide Axis',
                //chart Item
                'bgColor': 'Custom Background Color',
                'staticSource' : 'Static Tag Data Source',
                //misc
                'label': 'Name',
                'tagName': 'Source Tag Name',
                //Comments Timeline
                'hideCommentsTimeline': 'Hide Comments Timeline',
            };
            if (Object.keys(specialLabels).includes(key)) {
                return specialLabels[key];
            } else {
                return _.startCase(key);//converts camelCase key name to Title Case
            }
        },
        changeLogPropertyTextColor(property) {
            if (property === 'Delete') {
                return 'red';
            } else if (property === 'Create') {
                return 'green'
            } else {
                return 'white';
            }
        },
        restoreVersion: function (config) {
            if (config.isDefault == 0 && this.selectedConfig.isDefault == 1) {
                const confirmRestoringDefaultConfig = `Restoring chart configuration. This action will remove selected template as default and restore previous default configuration. Would you like to proceed?`;
                if (!confirm(confirmRestoringDefaultConfig)) {
                    return;
                }
            } else if (config.isDefault == 1 && this.selectedConfig.isDefault == 0) {
                const confirmReplacingCurrentWithOldDefault = `Restoring chart conguration. This action will replace current default with old default config that is being restored. Would you like to proceed?`;
                if (!confirm(confirmReplacingCurrentWithOldDefault)) {
                    return;
                }
            } else {
                const confirmMessage = `Do you want the chart configuration to be restored?`;
                if (!confirm(confirmMessage)) {
                    return;
                }
            }

            this.shouldRefreshOnExit = true;
            const self = this;
            const url = '/user/config/restore';
            this.isLoading = this.loadingMessages.updatingConfig;

            $.post(
                url,
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    jobNumber: self.jobNumber,
                    id: config.id,
                    version_id: config.version_id,
                    selectedConfigId: self.selectedConfig.id,
                    dashboardId: self.dashboardId ?? null,
                    dashboardItemIndex: self.dashboardItem ? self.dashboardItem.i : null
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                        self.isLoading = null;
                    }
                    else {
                        self.loadCustomerConfigTemplates();
                        self.selectedConfig = { ...result.newConfig };
                        self.companyConfigs = self.companyConfigs.filter(config=>config.version_id != self.selectedConfig.version_id);
                        self.companyConfigs.push(result.newConfig);

                        self.isLoading = null;
                        self.shouldRefreshOnExit = true;
                        self.onSelectedTemplateChange(result.newConfig.id);
                        self.isNewConfigSelected = false;
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to update selected configuration';
                console.warn(errorMessage, errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                }
            });
        },
        addConfig: function () {
            //Should create a new chart config on the server
            //Then repopulate everything
            const self = this;
            const url = '/user/config/chartconfig/new';

            $.post(
                url,
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    type: self.selectedConfig.type,
                    jobNumber: self.jobNumber,
                    isAdmin: self.isAdmin
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        //Should add the new configuration to the configuration list
                        self.companyConfigs.push(result.newConfig);

                        //Then should switch to the new configuration
                        self.onSelectedTemplateChange(result.newConfig.id);
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to create new chart configuration';
                console.warn(errorMessage, errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                    //TODO: handle this
                }
            });
        },
        editConfig: function (targetConfig) {
            //Should switch to target config edit view
            this.selectedConfig = targetConfig;
        },
        onUseTemplateSelected() {
            let confirmMessage = `Do you want the chart configuration to be set to ${this.selectedConfig.templateName}?`;
            if (confirm(confirmMessage)) {
                this.switchToConfig(this.selectedConfig.id);
                this.changeModalVisibility(false);
            }
        },
        compareNewConfigToInitial() {
            this.newSelectedConfig = this.selectedConfig;
            if (this.initialSelectedConfig.id !== this.newSelectedConfig.id) {
                this.isNewConfigSelected = true;
            } else {
                this.isNewConfigSelected = false;
            }
        },
        onTemplateDropdownChange(config) {
            this.onSelectedTemplateChange(config.id);
            this.isActive = false;
        },
        onSelectedTemplateChange(config_id) {
            this.selectedConfig = this.companyConfigs.find(
                (config) => config.id == config_id
            );
            this.showCustomerDefaultToggle = !GlobalFunctions.validateBoolean(this.selectedConfig.isDefault);


            this.mapConfig();
            this.clearAllConfigErrors();
            this.clearAllConfigChangedFlags();
            this.assignValues();
            this.compareNewConfigToInitial();
        },
        onCustomAxisTagChange(result) {
            this.customAxisTagError = false;
            this.selectedCustomXAxis.tagName = result?.name;
        },
        updateCustomXAxisTagname(event) {
            this.customAxisTagError = false;
            this.customXAxisChanged = this.tagNameChanged = true;
            this.selectedCustomXAxis.tagName = event.target.value.trim();
        },
        validateTemplateName() {
            if (!this.selectedConfig) {
                return;
            }
            const name = this.selectedConfig.templateName;
            if (!name || name.length === 0 || typeof name != 'string') {
                this.missingTemplateNameError = true;
            } else {
                this.missingTemplateNameError = false;
            }
            this.configChanged = true;
        },
        updateConfigTemplate: function () {
            this.configPayLoad.templateName = this.$refs?.templateName?.value ?? this.selectedConfig.templateName;
            this.configPayLoad.customerDefault = this.$refs.customerDefault
                ? this.$refs.customerDefault.checked
                : this.selectedConfig.isDefault;
            this.configPayLoad.showLegend = this.$refs.showLegend
                ? this.$refs.showLegend.checked
                : this.selectedConfig.showLegend;
            this.configPayLoad.isVertical = this.$refs.isVertical
                ? this.$refs.isVertical.checked
                : this.selectedConfig.isVertical;
            this.configPayLoad.chartType = this.selectedConfig.type ?? this.configPayLoad.chartType;
            this.configPayLoad.rightSideMsOffset = this.$refs?.rightSideHourOffset !== undefined
                ? this.$refs?.rightSideHourOffset?.value * 60 * 60 * 1000 // hours to milliseconds
                : this.selectedConfig.rightSideMsOffset;
            this.configPayLoad.resolutionZoomHours = this.$refs?.resolutionZoomHours != undefined
                ? this.$refs?.resolutionZoomHours?.value
                : this.selectedConfig.resolutionZoomHours;
            this.configPayLoad.defaultZoomAction = this.$refs.defaultZoomAction
                ? this.$refs.defaultZoomAction.value
                : this.selectedConfig.defaultZoomAction;
            this.configPayLoad.pinRightSideMsOffset = this.$refs.pinRightSideMsOffset
                ? this.$refs.pinRightSideMsOffset.checked
                : this.selectedConfig.pinRightSideMsOffset;
            this.configPayLoad.hideChart = this.$refs.hideChart
                ? this.$refs.hideChart.checked
                : this.selectedConfig.hideChart;
            this.configPayLoad.isDateAxisHidden = this.$refs.isDateAxisHidden
                ? this.$refs.isDateAxisHidden.checked
                : this.selectedConfig.isDateAxisHidden;
            this.configPayLoad.chartControlsPosition = this.$refs.chartControlsPosition !== undefined
                ? this.$refs.chartControlsPosition.value
                : this.selectedConfig.controlsPosition;
            this.configPayLoad.shareWithCompany = this.$refs.shareWithCompany
                ? this.$refs.shareWithCompany.checked
                : this.selectedConfig.shared;
            this.configPayLoad.header_style = this.$refs.headerStyle
                ? this.$refs.headerStyle.value
                : this.selectedConfig.header_style;
            this.configPayLoad.showVerticalGridlines = this.$refs.showVerticalGridlines
                ? this.$refs.showVerticalGridlines.checked
                : this.selectedConfig.showVerticalGridlines;
            this.configPayLoad.verticalGridlinesColor = this.selectedConfig.verticalGridlinesColor ?? '#FFFFFFFF';
            this.configPayLoad.showStageLines = this.$refs.showStageLines == null?
                this.selectedConfig.showStageLines : this.$refs.showStageLines.checked;
            this.configPayLoad.showNPTLines = this.$refs.showNPTLines == null?
                this.selectedConfig.showNPTLines : this.$refs.showNPTLines.checked;
            this.configPayLoad.stageLabelType = this.$refs.stageLabelType == null?
                this.selectedConfig.stageLabelType : this.$refs.stageLabelType.value;
        },
        saveConfig: function (location = '') {
            let noErrors = true;
            if (this.axisChanged || this.axisIsNew) {//update axis
                noErrors = this.setAxis();
            }
            if (this.customXAxisChanged || this.customXAxisIsNew) {//update axis
                noErrors = this.setCustomAxis();
            }
            if (this.chartItemChanged || this.chartItemIsNew || location == 'yaxis') {//update chart items
                noErrors = this.setChartItem();
            }
            if (this.sectionChanged || this.sectionIsNew) {	//update section
                if (location === 'section') {
                    noErrors = this.setSection();
                } else if (location === 'yaxis' || location === 'chartitem') {
                    noErrors = this.setSection('subObj');
                }
            }
            if (this.configChanged) { //update with any changes made to the main page
                this.updateConfigTemplate();
                //if a custom x axis has been selected to use, ensure that one exists to save
                this.customAxisErrorSeen = (this.xAxisType != 'Date' && this.tempChartConfigData.chartXAxis == null);
            }

            if(!noErrors || this.errorSeen()) {
                return;
            }

            const templateName = this.configPayLoad.templateName;
            const customerDefault = this.configPayLoad.customerDefault;
            const showLegend = this.configPayLoad.showLegend;
            const isVertical = this.configPayLoad.isVertical;
            const chartType = this.configPayLoad.chartType;
            const hideChart = this.configPayLoad.hideChart;
            const isDateAxisHidden = this.configPayLoad.isDateAxisHidden;
            const chartControlsPosition = this.configPayLoad.chartControlsPosition;
            const rightSideMsOffset = this.configPayLoad.rightSideMsOffset;
            const resolutionZoomHours = this.configPayLoad.resolutionZoomHours;
            const defaultZoomAction = this.configPayLoad.defaultZoomAction;
            const pinRightSideMsOffset = this.configPayLoad.pinRightSideMsOffset;
            const showVerticalGridlines = this.configPayLoad.showVerticalGridlines;
            const verticalGridlinesColor = this.configPayLoad.verticalGridlinesColor;
            const showStageLines = this.configPayLoad.showStageLines;
            const showNPTLines = this.configPayLoad.showNPTLines;
            const stageLabelType = this.configPayLoad.stageLabelType;
            const shareWithCompany = this.configPayLoad.shareWithCompany;
            const header_style = this.configPayLoad.header_style;
            const hideCommentsTimeline = this.selectedConfig.hideCommentsTimeline;

            if(this.xAxisType == 'Date' && this.tempChartConfigData.chartXAxis) {
                delete this.tempChartConfigData.chartXAxis;
            }

            //if y axes were added or deleted then update yaxes in the config data
            if (this.tempChartConfigData?.chartYAxes?.length) {
                //get a copy of the current y axes without duplicating the chart item data
                const yAxesCopy = _.cloneDeep(this.tempChartConfigData.chartYAxes);
                yAxesCopy.forEach(axis => {
                    delete axis?.chartItems;
                });
                this.tempChartConfigData.chartYAxes = yAxesCopy;
            }


            const self = this;
            const outputChartConfigData = {};

            Object.assign(outputChartConfigData, this.tempChartConfigData);

            if(outputChartConfigData?.sectionIndexes && outputChartConfigData.orderedSections?.length !== outputChartConfigData?.sectionIndexes?.length) {
                const sectionsLength = outputChartConfigData.orderedSections.length;
                const sectionsIndexes = [];

                outputChartConfigData.sectionIndexes.forEach(section => {
                    const sectionFound = outputChartConfigData?.orderedSections?.[section.index] ? true : false;
                    if(sectionFound) {
                        sectionsIndexes.push(section);
                    }
                });

                outputChartConfigData.sectionIndexes = sectionsIndexes;
            }

            const newData = JSON.stringify(outputChartConfigData);
            const modifiedSections = JSON.stringify(this.modifiedSections);
            const url = '/user/config/chartconfig/edit';

            if(rightSideMsOffset < 0)
            {
                alert('End Time Offset must be zero or greater.');
                return;
            }

            this.isLoading = this.loadingMessages.updatingConfig;
            $.post(
                url,
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    id: self.selectedConfig.id,
                    version_id: self.selectedConfig.version_id ? self.selectedConfig.version_id : self.selectedConfig.id,
                    templateName: templateName,
                    isDefault: customerDefault,
                    type: chartType,
                    jobNumber: self.jobNumber,
                    showLegend,
                    isVertical,
                    rightSideMsOffset,
                    resolutionZoomHours,
                    defaultZoomAction,
                    pinRightSideMsOffset,
                    hideChart,
                    isDateAxisHidden,
                    chartControlsPosition,
                    shareWithCompany,
                    header_style,
                    data: newData,
                    modifiedSections: modifiedSections,
                    showVerticalGridlines,
                    verticalGridlinesColor,
                    showStageLines,
                    showNPTLines,
                    stageLabelType,
                    hideCommentsTimeline
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                        confirm(result.message);
                    } else {
                        self.loadCustomerConfigTemplates();
                        const oldConfigId = self.selectedConfig.id;

                        self.shouldRefreshOnExit = true;
                        //Edit existing configuration
                        self.selectedConfig = {
                            ...self.selectedConfig,
                            id: result.newConfig.id,
                            version_id: result.newConfig.version_id,
                            data: newData,
                            templateName: templateName,
                            isDefault: customerDefault,
                            shared: result.newConfig.shared,
                            showLegend: showLegend,
                            isVertical: isVertical,
                            rightSideMsOffset,
                            resolutionZoomHours,
                            defaultZoomAction,
                            pinRightSideMsOffset,
                            hideChart,
                            isDateAxisHidden: isDateAxisHidden,
                            type: chartType,
                            controlsPosition: chartControlsPosition,
                            header_style,
                            showStageLines,
                            showNPTLines,
                            stageLabelType
                        };

                        self.showCustomerDefaultToggle = !GlobalFunctions.validateBoolean(self.selectedConfig.isDefault);

                        //Should edit existing configuration in available configs as well
                        const targetConfig_index = self.companyConfigs.findIndex(
                            (config) => {
                                return config.id == oldConfigId;
                            }
                        );

                        //force reset of computed functions derived from companyConfigs
                        Vue.set(self.companyConfigs, targetConfig_index, self.selectedConfig);
                    }
                    self.isLoading = null;
                    self.quickEditModalAccess = false;//data is now saved, config does not need to discern quick add items
                    self.clearAllConfigChangedFlags();
                    self.assignValues();
                    self.shouldRefreshOnExit = true;
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to update chart configuration';
                console.warn(errorMessage, errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                    //TODO: handle this
                }

                self.isLoading = null;
            });
        },
        copyConfig: function (forced) {
            //Should create a new chart config on the server that is a copy of the currently selected config
            const self = this;
            const url = '/user/config/chartconfig/newfromexisting';

            //forced means user did not select copy and were instead forced here
            if (forced &&
                confirm('Do not have permission to edit this template.  Would you like to create an editable copy?')) {
                //set to not forced if user confirms they would like to copy the default template
                forced = false;
            }

            if (!forced) {
                this.isLoading = this.loadingMessages.copyConfig;

                let user_id = null;
                if (!this.isAdmin && !this.isCompanyAdmin && !this.iwsUser) {
                    user_id = true;
                }
                $.post(
                    url,
                    {
                        _token: $('meta[name="csrf-token"]').attr('content'),
                        id: self.selectedConfig.id,
                        jobNumber: self.jobNumber,
                        isAdmin: self.isAdmin,
                        user_id: user_id,
                        componentID: self.componentID
                    },
                    function (result) {
                        if (result.error) {
                            console.warn(result.message);
                        } else {
                            self.companyConfigs.push(result.newConfig);
                            self.selectedConfig = result.newConfig;
                            self.mapConfig();
                            self.assignValues();
                            self.onSelectedTemplateChange(result.newConfig.id);
                            self.buildTemplateDisplayArray();
                        }

                        self.isLoading = null;
                    },
                    'json'
                ).fail(function (jqXHR, textStatus, errorThrown) {
                    const errorMessage = 'Failed to create new chart configuration';
                    console.warn(errorMessage, errorThrown);
                    if (jqXHR.status == 401) {
                        console.warn('unauthorized');
                        self.hasAuthError = true;
                    } else {
                        //TODO: handle this
                    }

                    self.isLoading = null;
                });
            }
        },
        deleteConfig: function () {
            if(this.selectedConfig.isDefault) { //deleting a default chart config
                alert('Cannot remove this default configuration. There should be at least one default configuration.');
                return;
            }

            if(this.selectedConfig.customer_id == null && this.selectedConfig.owned_by == null) { //deleting a default chart config
                alert('Cannot remove this global configuration.');
                return;
            }

            const nonDefaultCompanyConfigs = this.companyConfigs.filter((obj1) => {
                return this.globalConfigs.findIndex((obj2) => obj1.id === obj2.id) === -1;
            });

            if (!nonDefaultCompanyConfigs || nonDefaultCompanyConfigs.length == 1) {
                confirm('Cannot delete template. Users must have at least one personal or company template available.');
            } else {

                if (
                    confirm('Are you sure you want to delete this Chart Configuration?')
                ) {
                    const targetConfig_id = this.selectedConfig.id;
                    const targetUrl =
                    '/user/config/chartconfig/' + targetConfig_id + '/delete';
                    //Should delete the actual comment
                    $.ajax({
                        url: targetUrl,
                        method: 'POST',
                        data: {
                            _token: GlobalFunctions.getCSRFToken(),
                            jobNumber: this.jobNumber
                        },
                        dataType: 'json'
                    }).done((data) => {
                        //Remove the config from the list locally
                        const self = this;
                        this.companyConfigs = this.companyConfigs.filter((config) => {
                            return config.id !== self.selectedConfig.id;
                        });
                        const nonDefaultCompanyConfigs = this.companyConfigs.filter((obj1) => {
                            return this.globalConfigs.findIndex((obj2) => obj1.id === obj2.id) === -1;
                        });
                        if (nonDefaultCompanyConfigs.length > 0) {
                            this.selectedConfig = nonDefaultCompanyConfigs[0];
                            this.switchToConfig(this.selectedConfig.id);
                        }

                        this.$forceUpdate();

                        this.shouldRefreshOnExit = true;
                        this.assignValues();
                        this.clearAllConfigErrors();
                        this.clearAllConfigChangedFlags();
                        this.buildTemplateDisplayArray();
                        //set custom axis if present
                        if (!this.tempChartConfigData.chartXAxis) {
                            this.xAxisType = 'Date';
                        }
                        else {
                            this.xAxisType = self.customAxisLabel();
                        }
                    });
                }
            }
        },
        chartitemsLayoutUpdated: function(newLayout) {
            const self = this;
            newLayout.forEach(function(item) {
                if(item.i !== item.y) {
                    self.sectionChanged = true;
                }
            });
        },
        removeChartItemFromLayout: function (index) {
            this.selectedSection.chartItemLayout.splice(index, 1);
            //refresh unique id values
            for (let i = 0; i < this.selectedSection.chartItemLayout.length; i++) {
                this.selectedSection.chartItemLayout[i].i = i;
            }
        },
        addChartItemToLayout: function (newChartItem) {
            const layoutLength = this.selectedSection.chartItemLayout.length;
            if (layoutLength > 0) {
                //get the current last chart item layout in the grid (highest row and column value)
                const lastRowValue = Math.max(...this.selectedSection.chartItemLayout.map(layout => layout.y));
                const lastRowLayouts = this.selectedSection.chartItemLayout.filter(layout => layout.y === lastRowValue);
                const lastColumnInLastRow = Math.max(...lastRowLayouts.map(layout => layout.x));
                const lastGridItem = this.selectedSection.chartItemLayout.find(layout => layout.y === lastRowValue && layout.x === lastColumnInLastRow);
                const currentMaxIdentity = Math.max(...this.selectedSection.chartItemLayout.map(o => o.i));
                let newIdentity = currentMaxIdentity + 1;
                const gridItemObj = {};
                gridItemObj.i = newIdentity;
                gridItemObj.tagName = newChartItem.tagName;
                gridItemObj.h = lastGridItem.h;
                gridItemObj.w = lastGridItem.w;
                //determine how many columns the last grid item occupies in its row, and if there is space for the next item
                gridItemObj.x = lastGridItem.x + (lastGridItem.w - 1) + gridItemObj.w > this.defaultChartColumnCount - 1 ? 0 : lastGridItem.x + lastGridItem.w;
                //assigns the new grid item to either the current row if space exists, or to a new row
                gridItemObj.y = lastGridItem.x + (lastGridItem.w - 1) + gridItemObj.w > this.defaultChartColumnCount - 1 ? lastGridItem.y + 2 : lastGridItem.y ;
                gridItemObj.labelTextOptions = {};
                gridItemObj.dataTextOptions = {};
                this.selectedSection.chartItemLayout.push(gridItemObj);
            }
            else { //No previous chart item layout data, so use the default grid item object for first values
                const gridItemObj = Object.assign({},this.defaultGridItemObj);
                gridItemObj.tagName = newChartItem.tagName;
                this.selectedSection.chartItemLayout.push(gridItemObj);
            }
        },
        getChartItemWellboreWell: function(chartItem) {
            if(chartItem.tagName) {
                if(chartItem.tagName.includes(this.wellheadTag)) {
                    return this.getWell(chartItem.tagName);
                } else {
                    return false;
                }
            } else {
                return false;
            }
        },
        getWell: function(tagName) {
            const wellIndex = tagName.match(/\d+/)[0] - 1;
            const well = this.wells.find((well)=>well.index === wellIndex);
            return well;
        },
        displayGridlinesChanged: function() {
            if(this.selectedAxis.displayGridlines){
                this.selectedAxis.customGridlinesColor = this.selectedAxis.color ?? '#FFFFFFFF' ;
            }
            this.axisChanged = true;
        },
        //Axis Methods
        findAxisLabel: function(axis_key) {
            if (!this.invalidInput) {
                // enable edit and delete buttons if input label is valid
                this.chartYAxisMissing = false;
            }
            const matchingAxis = this.selectedConfig.yaxes.find(axis => {
                return axis.key === axis_key;
            });
            if (matchingAxis) {
                return matchingAxis.label;
            }
            return '';
        },
        addAxis: function () {
            //save the current state of the selected chart item
            this.updateSelectedChartItem();
            this.chartItemChanged = false;

            this.selectedAxis = {
                'label': 'New Axis',
                'min': 0,
                'max': 100,
                'ticks': 10,
                'position': 'left',
                'displayGridlines': false,
                'useCustomGridlinesColor': true,
                'customGridlinesColor': '#FFFFFFFF',
                'logarithmic': false,
                'hideYAxis': false,
                'color': '#FFFFFF',
                'isPrimaryAxis': false,
                'alignToPrimaryAxis': false,
                'key': `newYAxis-${ Date.now() }`
            };
            this.axisIsNew = true;
            this.isLoading = null;
            this.chartYAxisMissing = false; // enable edit and delete
            this.invalidInput = false;
            this.selectedConfig.yaxes.push(this.selectedAxis);
            this.tempChartConfigData.chartYAxes.push(this.selectedAxis);
        },
        changePrimaryAxis: function () {
            this.axisChanged = true;
            this.primaryAxisChanged = true;
            this.selectedAxis.alignToPrimaryAxis = false;
        },
        editAxis: function (axis=null) {
            let chartYAxis;
            if (axis) {
                chartYAxis = axis.label;
            } else {
                chartYAxis = this.$refs.chartyaxis.value;
            }
            this.axisHolder = this.selectedConfig.yaxes.find(axis => {
                return axis.label === chartYAxis;
            });

            if (!this.axisHolder) {
                return; //no axis to select
            }

            //wipe error messages
            this.nameErrorSeen = false;
            this.minErrorSeen = false;
            this.maxErrorSeen = false;
            this.positionErrorSeen = false;
            this.minMaxErrorSeen = false;

            this.selectedAxis = this.axisHolder;

            if (!this.selectedAxis?.useCustomGridlinesColor) {
                this.selectedAxis.useCustomGridlinesColor = true;
            }
            if (!this.selectedAxis?.customGridlinesColor) {
                this.selectedAxis.customGridlinesColor = this.selectedAxis?.color ?? '#ffffff';
            }
        },
        deleteAxis: function () {
            if (confirm('Warning: Deleting this axis will remove all data sources that use it.  Do you want to proceed?')) {
                this.selectedSection.orderedChartItems = this.selectedSection.orderedChartItems.filter(chartItem => {
                    return chartItem.chartYAxis_key !== this.selectedChartItem.chartYAxis_key;
                });
                //also delete chartItems that use this axis from selected config
                this.selectedConfig.yaxes = this.selectedConfig.yaxes.filter(axis => axis.key !== this.selectedChartItem.chartYAxis_key);
                this.tempChartConfigData.chartYAxes = this.selectedConfig.yaxes.filter(axis => axis.key !== this.selectedChartItem.chartYAxis_key);
                this.selectedChartItem = null;
                this.sectionChanged = true;
            }
        },
        setAxis: function () {
            if (!this.selectedAxis) {
                return false;
            }
            const name = this.selectedAxis.label;
            const min = parseInt(this.selectedAxis.min);
            const max = parseInt(this.selectedAxis.max);
            const ticks = this.selectedAxis.ticks && (parseInt(this.selectedAxis.ticks) > 1)? parseInt(this.selectedAxis.ticks) : 10;
            const position = this.selectedAxis.position;
            const displayGridlines = this.selectedAxis.displayGridlines;
            const logarithmic = this.selectedAxis.logarithmic;
            const hideYAxis = this.selectedAxis.hideYAxis;
            const axisColor = this.selectedAxis.color;
            const customGridlinesColor = this.selectedAxis.customGridlinesColor;
            const isPrimaryAxis = this.selectedAxis.isPrimaryAxis;
            const alignToPrimaryAxis = this.selectedAxis.alignToPrimaryAxis;

            //Validate the fields str not empty
            if (name && position && (min || min === 0) && (max || max === 0)) {
                if (min >= max) {
                    this.minMaxErrorSeen = true;
                    return false;
                } else {
                    this.minMaxErrorSeen = false;

                    const updatedChartyaxis = {};
                    updatedChartyaxis.label = name;
                    updatedChartyaxis.min = min;
                    updatedChartyaxis.max = max;
                    updatedChartyaxis.ticks = ticks;
                    updatedChartyaxis.position = position;
                    updatedChartyaxis.displayGridlines = displayGridlines;
                    updatedChartyaxis.logarithmic = logarithmic;
                    updatedChartyaxis.hideYAxis = hideYAxis;
                    updatedChartyaxis.color = axisColor;
                    updatedChartyaxis.useCustomGridlinesColor = true;
                    updatedChartyaxis.customGridlinesColor = customGridlinesColor;
                    updatedChartyaxis.isPrimaryAxis = isPrimaryAxis;
                    updatedChartyaxis.alignToPrimaryAxis = alignToPrimaryAxis;

                    if (this.primaryAxisChanged && isPrimaryAxis) { //if setting a primary axis, clear primary status from any other axis
                        this.tempChartConfigData.chartYAxes.forEach(axis => {
                            axis.isPrimaryAxis = false;
                        });
                        this.selectedConfig.yaxes.forEach(axis => {
                            axis.isPrimaryAxis = false;
                        });
                    }

                    if(this.axisIsNew) {
                        this.axisIsNew = false;
                        updatedChartyaxis.key = this.selectedAxis?.key || null;
                    } else {
                        const self = this;
                        updatedChartyaxis.key = this.axisHolder.key;
                        const index = this.tempChartConfigData.chartYAxes.findIndex(element => element.key === updatedChartyaxis.key);
                        this.tempChartConfigData.chartYAxes[index] = updatedChartyaxis;
                        const selectedConfigYIndex = this.selectedConfig.yaxes.findIndex(element => element.key === updatedChartyaxis.key);
                        this.selectedConfig.yaxes[selectedConfigYIndex] = updatedChartyaxis;
                    }
                    this.selectedChartItem.chartyaxis = updatedChartyaxis;
                    this.selectedChartItem.chartYAxis_key = updatedChartyaxis.key;

                    this.axisIsNew = false;
                    this.axisChanged = false;
                    this.primaryAxisChanged = false;
                    this.sectionChanged = this.quickEditModalAccess ? false : true;
                    this.configChanged = true;
                    this.chartItemChanged = false;

                    this.nameErrorSeen = false;
                    this.minErrorSeen = false;
                    this.maxErrorSeen = false;
                    this.positionErrorSeen = false;
                    this.minMaxErrorSeen = false;
                    this.chartYAxisErrorSeen = false;
                    return true;
                }
            } else {
                this.nameErrorSeen = !name ? true : false;
                this.minErrorSeen = !min ? true : false;
                this.maxErrorSeen = !max ? true : false;
                this.positionErrorSeen = !position ? true : false;
                return false;
            }
        },
        //Custom Axis Methods
        onChangeCustomXAxis: function() {
            if(this.tempChartConfigData.chartXAxis) {
                this.editCustomXAxis();
            }
            else {
                this.addCustomXAxis();
            }
        },
        addCustomXAxis: function () {
            this.tempChartConfigData.chartXAxis = {
                'label': '',
                'tagName': '',
                'min': 0,
                'max': 100,
                'position': 'bottom',
                'logarithmic': false,
                'unit': 'psi'
            };
            this.selectedCustomXAxis = this.tempChartConfigData.chartXAxis;
            //Then switch to it once data is loaded
            this.customXAxisIsNew = true;
        },
        editCustomXAxis: function () {
            //wipe error messages
            this.customAxisTagError = false;
            this.nameErrorSeen = false;
            this.minErrorSeen = false;
            this.maxErrorSeen = false;
            this.positionErrorSeen = false;
            this.minMaxErrorSeen = false;
            this.unitErrorSeen = false;

            //Should switch to target axis edit view
            this.selectedCustomXAxis = this.tempChartConfigData.chartXAxis;
            this.customXAxisChanged = false;
        },
        setCustomAxis: function () {
            if (this.tempChartConfigData.chartXAxis) {
                const xAxis = this.tempChartConfigData.chartXAxis;
                const tagName = this.selectedCustomXAxis ? this.selectedCustomXAxis.tagName : xAxis.tagName.trim();
                const name = this.selectedCustomXAxis ? this.selectedCustomXAxis.label : xAxis.label;
                const unit = xAxis.unit;
                const min = this.selectedCustomXAxis ? parseInt(this.selectedCustomXAxis.min) : parseInt(xAxis.min);
                const max = this.selectedCustomXAxis ? parseInt(this.selectedCustomXAxis.max) : parseInt(xAxis.max);
                const position = xAxis.position;
                const logarithmic = xAxis.logarithmic;

                //Validate the fields str not empty
                if (name && unit && position && tagName && (min || min === 0) && (max || max === 0)) {
                    if (min >= max) {
                        this.minMaxErrorSeen = true;
                        return false;
                    } else {
                        this.minMaxErrorSeen = false;

                        this.tempChartConfigData.chartXAxis = {
                            'tagName': tagName,
                            'label': name,
                            'min': min,
                            'max': max,
                            'position': position,
                            'unit': unit,
                            'logarithmic': logarithmic
                        };

                        this.customXAxisIsNew = false;
                        this.customXAxisChanged = false;
                        this.configChanged = true;
                        this.isLoading = null;

                        this.tagErrorSeen = false;
                        this.nameErrorSeen = false;
                        this.customAxisTagError = false;
                        this.unitErrorSeen = false;
                        this.minErrorSeen = false;
                        this.maxErrorSeen = false;
                        this.positionErrorSeen = false;
                        this.minMaxErrorSeen = false;
                        this.customAxisErrorSeen = false;

                        this.selectedCustomXAxis = this.tempChartConfigData.chartXAxis;
                        this.xAxisType = this.customAxisLabel();

                        return true;
                    }
                } else {
                    this.tagErrorSeen = !tagName ? true : false;
                    this.nameErrorSeen = !name ? true : false;
                    this.customAxisTagError = !tagName ? true : false;
                    this.unitErrorSeen = !unit ? true : false;
                    this.minErrorSeen = Number.isNaN(min) || min == null ? true : false;
                    this.maxErrorSeen = Number.isNaN(max) || max == null ? true : false;
                    this.positionErrorSeen = !position ? true : false;
                    this.selectedCustomXAxis = this.tempChartConfigData.chartXAxis;
                    this.xAxisType = this.customAxisLabel();
                    return false;
                }
            }
            else {
                this.customAxisErrorSeen = true;
                return false;
            }
        },
        clearAllConfigErrors: function () {
            this.tagErrorSeen = false;
            this.unitErrorSeen = false;
            this.nameErrorSeen = false;
            this.customAxisTagError = false;
            this.minErrorSeen = false;
            this.maxErrorSeen = false;
            this.minMaxErrorSeen = false;
            this.positionErrorSeen = false;
            this.sectionErrorSeen = false;
            this.chartYAxisErrorSeen = false;
            this.customAxisErrorSeen = false;
        },
        clearChartItemErrors: function () {
            this.isTagAlreadyUsed = false;
            this.tagErrorSeen = false;
            this.unitErrorSeen = false;
            this.colorErrorSeen = false;
            this.chartYAxisErrorSeen = false;
        },
        clearAllConfigChangedFlags: function () {
            this.configChanged = false;
            this.axisIsNew = false;
            this.axisChanged = false;
            this.sectionIsNew = false;
            this.sectionChanged = false;
            this.chartItemIsNew = false;
            this.chartItemChanged = false;
            this.customXAxisIsNew = false;
            this.customXAxisChanged = false;
            this.tagNameChanged = false;
        },
        clearAllSelectedConfigPages: function () {
            this.selectedCustomXAxis = null;	//remove any selected chart pages so it returns to the main page after close
            this.selectedSection = null;
            this.selectedChartItem = null;
            this.selectedAxis = null;
        },
        //Chart Item Methods
        addChartItem: function () {
            this.clearChartItemErrors(); //clear any errors for fresh chart item
            let create = false;
            let sectionNameExists = true;
            if (!!!this.selectedSection.name) {
                sectionNameExists = false;
                const sectionName = this.$refs.sectionName.value;
                if(!!!sectionName) {
                    alert('Section name cannot be empty ');
                    return;
                } else {
                    this.setSection();
                    create = true;
                }
            } else if (this.sectionIsNew && this.sectionChanged) {
                this.setSection('subObj');
                this.sectionChanged = true;
                create = true;
            }
            if (sectionNameExists && !this.sectionIsNew && this.sectionChanged) {
                this.setSection('subObj');
                create = true;
            }
            if (create || (!this.sectionIsNew && !this.sectionChanged)) {
                const chartconfig_id = this.selectedConfig.id;
                const jobNumber = this.jobNumber;
                this.chartItemIsNew = true;
                this.axisHolder = null;
                this.chartItemBgColorEnabled = false;
                const lineColor = this.getRandomColor();
                this.selectedChartItem = {
                    tagName: '',
                    friendlyName: '',
                    lineWidth: 1,
                    bgColor: null,
                    staticSource: false,
                    color: lineColor,
                    chartyaxis: '',
                    decimalPrecision: this.defaultDecimalPrecision,
                    showDataPoints: false,
                    selectedByDefault: false
                };
            }

            //Due to autocomplete bug outlined in search() have to set the section autocomplete for initial visual value
            if(this.$refs.sectionName) {
                this.$refs.sectionName.value = this.selectedChartItem.tagName;
            }
            this.selectedSection.orderedChartItems.push(this.selectedChartItem);
        },
        getRandomColor() {
            return '#'+Math.floor(Math.random()*16777215).toString(16);
        },
        editChartItem: function (targetItem, index) {
            if ((this.sectionChanged && this.validateSection()) || !this.sectionChanged) {
                //wipe error messages unless going directly to edit chart item page from chart edit menu
                if (!this.quickEditModalAccess) {
                    this.isTagAlreadyUsed = false;
                    this.tagErrorSeen = false;
                    this.unitErrorSeen = false;
                    this.colorErrorSeen = false;
                    this.chartYAxisErrorSeen = false;
                }
                //ensure that chart item has a color on edit, especially if it is a wellbore tag
                if (this.isWellheadTag(targetItem.tagName)) {
                    const well = this.getWell(targetItem.tagName);
                    targetItem.color = well?.color || 'fff';
                } else if (targetItem?.color == null) {
                    targetItem.color = 'fff';
                }

                //Should switch to target chart item edit view
                this.selectedChartItem = targetItem;
                this.selectedChartItem.index = index;
                this.selectedChartItem.oldTagname = targetItem.tagName;

                this.chartItemBgColorEnabled = this.selectedChartItem.bgColor ? true: false;
                this.customLineColor = this.selectedChartItem.customLineColor ? true: false;

                this.previousFriendlyName = targetItem.friendlyName;

                //Due to autocomplete bug outlined in search() have to set the section autocomplete for initial visual value
                if (this.$refs?.sectionName) {
                    this.$refs.sectionName.value = this.selectedChartItem.tagName;
                }
            }
        },
        deleteChartItem: function (targetItem, index) {
            if (confirm('Do you really want to delete this data source?')) {
                this.sectionChanged = true;
                this.selectedSection.orderedChartItems.splice(index, 1);
                this.selectedConfigsChartItems.splice(index, 1, targetItem);

                this.selectedConfig.yaxes.forEach(axis => {
                    if(axis?.chartItems) {
                        const id = axis.chartItems.findIndex(element => { return element.tagName == targetItem.tagName });
                        if(id != -1) {
                            axis.chartItems.splice(id, 1);
                        }
                    }
                });

                if (this.selectedSection.chartItemLayout) {
                    this.removeChartItemFromLayout(index);
                }
            }
        },
        onTagChanged(result) { //this will trigger only if the user selects from the tag dropdown
            // when change tag, reset custom line color
            this.customLineColor = false;
            this.selectedChartItem.customLineColor = false;
            const itemIndex = this.selectedSection.orderedChartItems.findIndex(item => item.tagName == this.selectedChartItem.tagName);
            if(result?.name ) {
                Vue.set(this.selectedChartItem, 'tagName', result.name);
                Vue.set(this.selectedChartItem, 'friendlyName', result.prioritizedTagName);
                this.previousFriendlyName = result.prioritizedTagName; // save the prioritized tag name in case friendly name is changed and then deleted
                let suffixPositionName = null;
                if (result?.valveName) {
                    Vue.set(this.selectedChartItem, 'wellShortName',`${result.valveName} ${this.getTagAbbreviation(result.name)}`);
                     //if a tag has a valve name it is using, show the well shortname after it
                    suffixPositionName = result.wellShortName;
                } else {
                    Vue.set(this.selectedChartItem, 'wellShortName', result.wellShortName);
                    suffixPositionName = result?.dynamicTagName;
                }
                Vue.set(this.selectedChartItem, 'suffixPositionName', suffixPositionName);
                Vue.set(this.selectedChartItem, 'wellColor', result.wellColor);
                this.selectedChartItem.color = result.wellColor ? result.wellColor : this.getRandomColor();

                if(result.unit) {
                    Vue.set(this.selectedChartItem, 'unit', result.unit);
                }
                else{
                    Vue.set(this.selectedChartItem, 'unit', null);
                }
                if(result.color) {
                    Vue.set(this.selectedChartItem, 'color', result.wellColor ? result.wellColor : result.color);
                }
                if (result.decimalPlaces != null) {
                    Vue.set(this.selectedChartItem, 'decimalPrecision', result.decimalPlaces);
                }
                //use default if none is set
                else {
                    Vue.set(this.selectedChartItem, 'decimalPrecision', this.defaultDecimalPrecision);
                }

                //set default axis here
                if (result.defaultAxis)
                {
                    let axis = {
                        'chartItems': [result],
                        "customGridlinesColor": "#FFFFFFFF",
                        "displayGridlines": false,
                        "hideYAxis": result.defaultAxis.hideAxis,
                        "isPrimaryAxis": false,
                        "key": result.defaultAxis.id,
                        "label": result.defaultAxis.label,
                        "logarithmic": result.scaleType == "logarithmic",
                        "max": result.defaultAxis.max,
                        "min": result.defaultAxis.min,
                        "position": result.defaultAxis.position,
                        "ticks": null,
                        "useCustomGridlinesColor": true
                    };

                    //add default axis to list of available axis' if not existing
                    let existingTempAxis = this.tempChartConfigData.chartYAxes.find(configAxis => {
                        return configAxis.key == axis.key;
                    });
                    if (!existingTempAxis)
                        Vue.set(this.tempChartConfigData, 'chartYAxes', [...this.tempChartConfigData.chartYAxes, axis] );
                    else
                        existingTempAxis.chartItems = [...existingTempAxis.chartItems, result];

                    let existingConfigAxis = this.selectedConfig.yaxes.find(configAxis => {
                       return configAxis.key == axis.key;
                    });
                    if (!existingConfigAxis)
                       Vue.set(this.selectedConfig, 'yaxes', [...this.selectedConfig.yaxes, axis]);
                    else
                        existingConfigAxis.chartItems = [...existingConfigAxis.chartItems, result];

                    //set key to axis that now exists in the available axis'
                    Vue.set(this.selectedChartItem,'chartYAxis_key', axis.key);
                    Vue.set(this.selectedChartItem, 'chartyaxis', existingTempAxis? existingTempAxis: axis);

                    //manually set autocomplete so value isn't empty
                    this.$refs['chartyaxis'].value = axis.label;
                }
                //clear axis if none found
                else
                {
                    //clear item out of axis' items list
                    if (this.selectedChartItem.chartYAxis_key)
                    {
                        const oldExistingAxis = this.tempChartConfigData.chartYAxes.find(configAxis => {
                            return configAxis.key == this.selectedChartItem.chartYAxis_key;
                        });
                        const existingIndex = oldExistingAxis.chartItems.findIndex(item => item.key == this.selectedChartItem.chartYAxis_key);
                        if (existingIndex)
                            oldExistingAxis.chartItems.splice(existingIndex, 1);

                        const oldConfigAxis = this.selectedConfig.yaxes.find(configAxis => {
                            return configAxis.key == this.selectedChartItem.chartYAxis_key;
                        });
                        const configIndex = oldConfigAxis.chartItems.findIndex(item => item.key == this.selectedChartItem.chartYAxis_key);
                        if (oldConfigAxis)
                            oldConfigAxis.chartItems.splice(configIndex, 1);
                    }

                    Vue.set(this.selectedChartItem,'chartYAxis_key', null);
                    Vue.set(this.selectedChartItem, 'chartyaxis', null);
                    this.$refs['chartyaxis'].value = '';
                    this.chartItemChanged = true;
                }
            }
        },
        setChartItem(mode) {
            if (this.selectedChartItem == null || !this.validateTag(this.selectedChartItem)) {//do nothing if no chart item is selected (backing out of a new chart item)
                return;
            }
            //$ref values are only available when an element is currently rendered,
            //so if not currently on the edit chart item page, selectedChartItem values must be used.
            let areRefsDefined = true;
            if (mode === 'subObj' || this.$refs?.tagName === undefined ) {
                areRefsDefined = false;
            }

            let tagName = areRefsDefined ? this.$refs.tagName.value.trim() : this.selectedChartItem.tagName;
            if (!tagName && this.$refs?.sectionName) {
                tagName = this.$refs.sectionName.value;
            }
            if(tagName) {
                const usedTags = this.getUsedTags(this.selectedConfig);
                if(this.tagNameChanged && usedTags.includes(tagName) && tagName !== this.selectedChartItem.oldTagname) {
                    this.isTagAlreadyUsed = true;
                    return false;
                } else {
                    this.isTagAlreadyUsed = false;
                }
            }
            let friendlyName = areRefsDefined && this.$refs.friendlyName? this.$refs.friendlyName.value : this.selectedChartItem.friendlyName;
            const unit = areRefsDefined ? this.$refs.unit.value : this.selectedChartItem.unit;
            const color = this.selectedChartItem.color;
            const bgColor = this.chartItemBgColorEnabled ? this.selectedChartItem.bgColor : null;
            const lineWidth = areRefsDefined ? parseInt(this.$refs.lineWidth.value) : parseInt(this.selectedChartItem.lineWidth);
            const showDataPoints = areRefsDefined ? this.selectedChartItem.showDataPoints == true : Boolean(this.selectedChartItem.showDataPoints);
            const selectedByDefault = areRefsDefined ? this.selectedChartItem.selectedByDefault == true : Boolean(this.selectedChartItem.selectedByDefault);
            const staticSource = areRefsDefined ? this.selectedChartItem.staticSource == true : Boolean(this.selectedChartItem.staticSource);
            const decimalPrecision = areRefsDefined ? parseInt(this.$refs.decimals.value) : parseInt(this.selectedChartItem.decimalPrecision);
            const customLineColor = this.customLineColor ? this.selectedChartItem.customLineColor : null;
            const wellShortName = this.selectedChartItem.wellShortName;
            const wellColor = this.selectedChartItem.wellColor;
            const suffixPositionName = this.selectedChartItem.suffixPositionName;

            let chartYAxis ;
            if(this.$refs?.chartyaxis?.value) {
                chartYAxis = this.$refs.chartyaxis.value;
            } else if(this.selectedChartItem?.chartyaxis?.label) {
                chartYAxis = this.selectedChartItem?.chartyaxis?.label;
            } else if(this.selectedAxis?.label) {
                chartYAxis = this.selectedAxis.label;
            } else {
                this.invalidInput = true;
                this.chartYAxisMissing = true;
            }

            const isNewItem = this.chartItemIsNew;

            //Validate that the fields are not empty or undefined
            if (tagName && tagName !== '' && unit && unit !== '' && (!customLineColor || color && color !== '') && chartYAxis && chartYAxis !== '') {
                if (this.previousFriendlyName && !friendlyName) {
                    // checks if input friendlyName has been deleted and if there was a previously set friendly name
                    friendlyName = this.previousFriendlyName;
                } else if (friendlyName && this.previousFriendlyName && friendlyName !== this.previousFriendlyName) {
                    // If there friendly name has been changed before saving, set the previous friendly name to it
                    this.previousFriendlyName = friendlyName;
                } else if (!friendlyName) {
                    // Use raw tag name if friendly name has never been set
                    friendlyName = tagName;
                }

                //update axis Holder with the value from chartYAxis
                this.axisHolder = this.selectedConfig.yaxes.find(axis => {
                    return axis.label === chartYAxis;
                });

                if (this.axisHolder) {
                    let tagPrefixAndSuffix = {};
                    if(!this.$refs.friendlyName) {
                        tagPrefixAndSuffix = {
                            prefixName: this.$refs?.friendlyNamePrefix?.value,
                            suffixName: this.$refs?.friendlyNameSuffix?.value,
                            customLineColor: customLineColor
                        };
                    }
                    const updatedChartItem = {
                        ...( typeof this.selectedChartItem.index == 'number' ? { index: this.selectedChartItem.index } : {} ),
                        tagName: tagName,
                        friendlyName: friendlyName,
                        ...tagPrefixAndSuffix,
                        unit: unit,
                        color: color,
                        bgColor: bgColor,
                        staticSource: staticSource,
                        lineWidth: lineWidth,
                        showDataPoints: showDataPoints,
                        selectedByDefault: selectedByDefault,
                        chartYAxis_key: this.axisHolder.key,
                        decimalPrecision: decimalPrecision,
                        wellShortName: wellShortName,
                        wellColor: wellColor,
                        suffixPositionName: suffixPositionName
                    };

                    this.selectedChartItem = updatedChartItem;	//update the selected chart item to show new changes
                    //update config data so any changes can be sent to the database for next load
                    this.tempChartConfigData.orderedSections.forEach(section => {
                        const itemIndex = section.orderedChartItems.findIndex(item => item.tagName == updatedChartItem.tagName);
                        //findIndex returns -1 if object does not exist in collection, otherwise index pos
                        if (itemIndex > -1) {
                            updatedChartItem.index = itemIndex;
                            section.orderedChartItems[itemIndex] = updatedChartItem;
                        }
                    });
                    if(isNewItem) {
                        if (this.selectedSection.chartItemLayout && !this.quickEditModalAccess) {
                            this.addChartItemToLayout(updatedChartItem);
                        }
                        //add this item to the selected config y axes group for tag search
                        const yAxis = this.selectedConfig.yaxes.find(axis => axis.key === updatedChartItem.chartYAxis_key);
                        if (yAxis.chartItems == undefined) {
                            yAxis.chartItems = [];
                        }
                        yAxis.chartItems.push(updatedChartItem);
                        //Set axis holder for search
                        this.axisHolder = chartYAxis;
                        this.chartItemIsNew = false;
                        this.sectionChanged = true;
                        this.configChanged = true;
                        this.chartItemChanged = false;
                    } else {
                        //save visible chartItem changes to the currently viewed modal
                        this.selectedSection.orderedChartItems[this.selectedChartItem.index] = updatedChartItem;

                        this.chartItemIsNew = false;
                        this.chartItemChanged = false;
                        this.sectionChanged = true;
                        this.configChanged = true;
                        this.tagErrorSeen = false;
                        this.isTagAlreadyUsed = false;
                        this.unitErrorSeen = false;
                        this.colorErrorSeen = false;
                        this.chartYAxisErrorSeen = false;
                        this.decimalPrecisionErrorSeen = false;
                    }
                }
                else {
                    alert('That chart axis does not exist, double check selection, or create a new Axis.');
                    this.isLoading = null;
                }
            } else {
                this.tagErrorSeen = !tagName || tagName == '' ? true : false;
                this.isTagAlreadyUsed = false;
                this.unitErrorSeen = !unit || unit == '' ? true : false;
                this.colorErrorSeen = !color ? true : false;
                this.chartYAxisErrorSeen = !chartYAxis ? true : false;
                this.decimalPrecisionErrorSeen  = !decimalPrecision ? true : false;
                if(!this.tagErrorSeen || !this.unitErrorSeen || !this.colorErrorSeen || !this.chartYAxisErrorSeen || this.decimalPrecisionErrorSeen) {
                    return false;
                }
            }
            this.clearChartItemErrors();
            return true;
        },
        updateSelectedChartItem: function () {
            //Note that lineColor and backgroundColor properties are saved and updated directly to selectedChartItem
            //on change, as $refs don't seem to apply effectively to 'sketch-picker' elements
            this.selectedChartItem.tagName = this.$refs?.tagName.value;
            this.selectedChartItem.friendlyName = this.$refs?.friendlyName?.value;
            this.selectedChartItem.unit = this.$refs?.unit.value;
            this.selectedChartItem.chartyaxis = this.$refs?.chartyaxis.value;
            this.selectedChartItem.decimals = parseInt(this.$refs?.decimals.value);
            this.selectedChartItem.lineWidth = parseInt(this.$refs?.lineWidth.value);
            this.selectedChartItem.showDataPoints = Boolean(this.$refs?.showDataPoints.checked);
            this.selectedChartItem.selectedByDefault = Boolean(this.$refs?.selectedByDefault.checked);
            const axisKey = this.selectedAxis?.key ?? this?.axisHolder?.key;
            if (axisKey) {
                this.selectedChartItem.chartYAxis_key = axisKey;
            }
        },
        resetChartConfigScreen() {
            if(this.initPageConfigData) {
                this.selectedConfig = { ...this.selectedConfig, ...this.initPageConfigData };
            }
        },
        //Section Methods
        addSection: function () {
            this.sectionErrorSeen = false;

            this.isLoading = this.loadingMessages.newSection;

            //check whether template is a user template or company template
            let user_id = null;
            if (this.selectedConfig.user_id && this.selectedConfig.user_id == this.user_id) {
                user_id = true;
            }

            const name = '';
            const customerID = this.selectedConfig.customer_id;
            const componentID = this.componentID;
            const userID = user_id;
            this.sectionIsNew = true;
            this.selectedSection = {
                key: `newSection-${ Date.now()}`,
                name: name,
                customer_id: customerID,
                componenttype_id: componentID,
                user_id: userID,
                columnCount: this.sectionColumnCountDefault,
                orderedChartItems: [],
                chartItemLayout: []
            };
            this.lastSelectedSection = _.cloneDeep(this.selectedSection);
            this.selectedConfig.sections.push(this.selectedSection);
            this.tempChartConfigData.orderedSections.push(this.selectedSection);
            this.isLoading = null;
        },
        editSection: function (targetItem, index) {
            this.lastSelectedSection = targetItem; //retains section data for saving on other menu pages

            this.selectedSection = targetItem;
            if (this.selectedSection.chartItemLayout) {
                this.chartitemsLayout = this.selectedSection.chartItemLayout;
            }
            this.selectedSection.columnCount = this.selectedSection.columnCount == null ? this.sectionColumnCountDefault : this.selectedSection.columnCount;
        },
        deleteSection: function (targetSection, index) {
            if (confirm('Deleting this section will result in losing all Data Sources in it. Do you want to proceed?')) {
                if(this.tempChartConfigData?.sectionIndexes?.length) {
                    this.tempChartConfigData.sectionIndexes = this.tempChartConfigData.sectionIndexes.filter((section)=>section.index!==index);
                }
                this.tempChartConfigData.orderedSections.splice(index, 1);
                this.configChanged = true;
            }
        },
        setSection(mode) {
            if(!this.selectedSection || !this.validateSection()) {
                return false;
            }

            let section = null;
            if(mode == 'subObj') {
                section = this.selectedSection.name;
            } else {
                section = this.$refs?.sectionName?.value == undefined ? this.lastSelectedSection.name : this.$refs.sectionName.value;
                if (!section && this.$refs.tagName) {
                    section = this.$refs.tagName.value;
                }
            }
            //Validate the fields are not empty
            if (section) {
                const latestSection = this?.selectedSection == null? this.lastSelectedSection: this.selectedSection;
                const index = this.tempChartConfigData.orderedSections.findIndex(element => element.key == latestSection.key);

                if (index == -1) {
                    latestSection.name = section;
                    this.modifiedSections.push(latestSection);
                    return true;
                } else {
                    this.sectionChanged = true;
                    this.configChanged = true;

                    this.tempChartConfigData.orderedSections[index] = latestSection;
                    const i = this.modifiedSections.findIndex(element => element.key === latestSection.key);
                    if (i >= 0) {
                        this.modifiedSections[i] = latestSection;
                    } else {
                        this.modifiedSections.push(latestSection);
                    }
                    return true;//this stops the page from going back to main config.
                }
            } else {
                this.sectionErrorSeen = !section ? true : false;
                return false;
            }
        },
        clearSection: function () {
            //Should remove the section from the config
            this.usedSections.splice(
                this.usedSections.length - 1,
                1
            );
            this.selectedConfig.sections.splice(
                this.selectedConfig.length - 1,
                1
            );

            this.sectionIsNew = false;
            this.sectionChanged = false;
        },
        //Data Fetching Methods
        loadCustomerConfigTemplates: function () {
            //Should fetch all of the config templates for the company
            const url =
                '/customer/config/chartconfigs/' +
                this.chartType +
                '/' +
                this.jobNumber;

            const self = this;
            $.get(
                url,
                {},
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.companyConfigs = result;
                        self.buildTemplateDisplayArray();
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                console.warn('fail downloadData', errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                //TODO: handle this
                }
            });
            //Use to populate the template list and for quickly changing between then
        },
        //Utilities
        changeModalVisibility: function (status, params) {
            if (params) {
                this.selectedConfig =  _.cloneDeep(params.chartConfiguration);
                                this.showCustomerDefaultToggle = !GlobalFunctions.validateBoolean(this.selectedConfig.isDefault);
                this.dashboardItem = params.dashboardItem;
                this.quickEditModalAccess = params?.quickEditModalAccess ?? false;
                if(status === true) {
                    this.isNewConfigSelected = false;
                    this.initialSelectedConfig = this.selectedConfig;
                }
            }

            if(status === true) {
                this.init();
            }
            //closing the config without save, so remove the chart item with missing data
            if (status == false && this.quickEditModalAccess == true && this.selectedChartItem) {
                const cancelData = {
                    chartItem: this.selectedChartItem,
                    dashboardItem: this.dashboardItem
                };
                this.$root.$emit('REMOVE_UNSAVED_CHARTITEM', cancelData);
            }
            this.$nextTick(() => {
                this.isModalVisible = status;
                this.isModalVisible
                    ? this.$bvModal.show(this.selectedConfig.type + 'modal') //$('#' + this.selectedConfig.type + 'modal').modal('show')
                    : this.$bvModal.hide(this.selectedConfig.type + 'modal'); //$('#' + this.selectedConfig.type + 'modal').modal('hide');

                // If a particular chart item ID was given, edit that chart item.
                if(status && params.sectionKey) {
                    this.selectedSection = this.selectedConfig.sections.find(s => s.key == params.sectionKey);
                    this.selectedChartItem = this.selectedSection?.orderedChartItems[params.chartItemIndex];
                    this.selectedChartItem.oldTagname = this.selectedChartItem.tagName;

                    if(this.selectedChartItem && this.quickEditModalAccess) {
                        //validate the data in the provided quick add chart item to look for required fields then go to it
                        this.chartItemChanged = true;
                        this.validateTag(this.selectedChartItem);
                        this.editChartItem(this.selectedChartItem, params.chartItemIndex);
                    }
                }
                if (status && params.selectedYAxis && this.quickEditModalAccess) {
                    this.editAxis(params.selectedYAxis);
                }
            });
        },
        modalHide(event) {
            const self = this;
            if(this.configInputChanged()) {
                if(self.confirmClose && !confirm('Are you sure you want to close this window without saving?')) {
                    event.preventDefault();
                    return;
                }
            } else if(this.shouldRefreshOnExit) {
                location.reload();
                return;
            }

            this.clearAllConfigChangedFlags();
            this.clearAllSelectedConfigPages();
            $('.tooltip').remove();
        },
        backPressed: function (currentModal) {
            const newItemErrorMessage = 'Required fields are missing for this new item. To prevent errors, going back in this state will remove this item, are you sure?';
            const changedItemErrorMessage = 'Required fields are missing for this item. To prevent errors, going back in this state will revert these changes, are you sure?';
            let override = false;
            let goBack = true;

            if(!this.selectedChartItem?.chartyaxis?.label && !this.selectedAxis?.label && !this.selectedAxis?.label) {
                this.chartYAxisMissing = true;
            }

            switch (currentModal) {
            case 'xaxis':
                goBack = this.validateCustomXAxis();
                if (this.customXAxisIsNew) {
                    if (goBack) {
                        this.setCustomAxis();
                        this.xAxisType = this.customAxisLabel();
                    } else {
                        override = confirm(newItemErrorMessage);
                        if (override) {
                            this.selectedConfig.xaxes = null;
                            this.tempChartConfigData.chartXAxis = null;
                            this.selectedCustomXAxis = null;
                            this.xAxisType = this.customAxisLabel();
                        }
                    }
                } else if (this.customXAxisChanged && this.selectedCustomXAxis) {
                    if (goBack) {
                        this.setCustomAxis();
                    }
                    else {
                        if (this.initConfigData.chartXAxis) {
                            override = confirm(changedItemErrorMessage);
                            if (override) {
                                this.tempChartConfigData.chartXAxis = _.cloneDeep(this.initConfigData.chartXAxis);
                                this.selectedConfig.xaxes = this.tempChartConfigData.chartXAxis;
                                this.xAxisType = this.customAxisLabel();
                                this.selectedCustomXAxis = null;
                            }
                        } else {
                            this.xAxisType = this.customAxisLabel();
                            this.selectedCustomXAxis = null;
                        }
                    }
                }
                if (goBack) {
                    this.selectedCustomXAxis = null;
                    this.xAxisType = this.customAxisLabel();
                }
                break;
            case 'section':
                goBack = this.validateSection();
                if (this.sectionIsNew) {
                    if (goBack) {
                        this.setSection();
                    } else {
                        override = confirm(newItemErrorMessage);
                        if (override) {
                            this.tempChartConfigData.orderedSections = this.tempChartConfigData.orderedSections.filter(section => section.key != this.selectedSection.key);
                            this.selectedConfig.sections = this.selectedConfig.sections.filter(section => section.key != this.selectedSection.key);
                            this.sectionErrorSeen = false;
                            this.selectedSection = null;
                        }
                    }
                } else if (this.sectionChanged && this.selectedSection) {
                    if(goBack) {
                        this.setSection();
                    } else {
                        override = confirm(changedItemErrorMessage);
                        if (override) {
                            const oldSectionIndex = this.initConfigData.orderedSections.findIndex(section => section.key == this.selectedSection.key);
                            const oldSectionData = _.cloneDeep(this.initConfigData.orderedSections[oldSectionIndex]);
                            this.tempChartConfigData.orderedSections[oldSectionIndex] = oldSectionData;
                            this.selectedSection = null;
                            this.sectionErrorSeen = false;
                        }
                    }
                }
                if (goBack) {
                    this.selectedSection = null;
                }
                break;
            case 'chartitem':
                goBack = this.validateTag();
                if (this.chartItemIsNew && this.selectedChartItem) {
                    if (goBack) {
                        this.setChartItem();
                    }
                    //bring up any form input error messages, but let back occur if override is confirmed
                    else {
                        override = confirm(newItemErrorMessage);
                        if (override) {
                            this.selectedSection.orderedChartItems =
                                this.selectedSection.orderedChartItems.filter(item => item.tagName != this.selectedChartItem.tagName);
                            this.selectedChartItem = null;
                            this.clearChartItemErrors();
                        }
                    }
                } else if (this.chartItemChanged && this.selectedChartItem) {
                    if (goBack) {
                        this.setChartItem();
                    } else {
                        override = confirm(changedItemErrorMessage);
                        if (override) {
                            const initSectionIndex = this.initConfigData.orderedSections.findIndex(section => section.key == this.selectedSection.key);
                            const initDataSourceIndex =
                                this.initConfigData.orderedSections[initSectionIndex].orderedChartItems.findIndex(item => item.tagName == this.selectedChartItem.oldTagname);
                            const initDataSourceData = _.cloneDeep(this.initConfigData.orderedSections[initSectionIndex].orderedChartItems[initDataSourceIndex]);

                            this.tempChartConfigData.orderedSections[initSectionIndex].orderedChartItems[initDataSourceIndex] = initDataSourceData;
                            this.clearChartItemErrors();
                            if (this.selectedChartItem.oldTagname) {
                                delete this.selectedChartItem.oldTagname;
                            }
                            this.selectedChartItem = null;
                        }
                    }
                }
                if (goBack) {
                    if (this.selectedChartItem.oldTagname) {
                        delete this.selectedChartItem.oldTagname;
                    }
                    this.selectedChartItem = null;
                    //Due to autocomplete bug outlined in search() have to reset the section autocomplete as it now thinks it is the tag search
                    this.$refs.tagName.value = this.selectedSection.name;
                    this.previousFriendlyName = null;
                }
                break;
            case 'yaxis':
                goBack = this.validateAxis();
                if (this.axisIsNew) {
                    if ((this.selectedAxis || this.axisHolder)) {
                        if (goBack) {
                            this.setAxis();
                            this.selectedAxis = null;
                        } else {
                            override = confirm(newItemErrorMessage);
                            if (override) {
                                this.selectedConfig.yaxes =
                                    this.selectedConfig.yaxes.filter(axis => axis.key != this.selectedAxis.key);
                                this.tempChartConfigData.chartYAxes =
                                    this.tempChartConfigData.chartYAxes.filter(axis => axis.key != this.selectedAxis.key);
                                this.selectedAxis = null;
                            }
                        }
                    }
                    goBack = true;
                } else if (this.axisChanged && this.selectedAxis) {
                    if (goBack) {
                        this.setAxis();
                    } else {
                        override = confirm(changedItemErrorMessage);
                        if (override) {
                            const yAxisIndex = this.initConfigData.chartYAxes.findIndex(axis => axis.key == this.selectedAxis.key);
                            const oldYAxisData = _.cloneDeep(this.initConfigData.chartYAxes[yAxisIndex]);

                            this.tempChartConfigData.chartYAxes[yAxisIndex] = oldYAxisData;
                            this.selectedConfig.yaxes[yAxisIndex] = oldYAxisData;
                            this.selectedAxis = null;
                        }
                    }
                }
                if (goBack) {
                    this.selectedAxis = null;
                }
                break;
            default: //close the modal
                let closeModal = true;
                if (!this.isUseTemplateDisabled) { //when user has switched templates and tries to close modal
                    if (confirm('Do you want to proceed without applying selected template to chart?')) {
                        this.clearAllConfigChangedFlags();
                        this.clearAllSelectedConfigPages();
                    }
                    else {
                        closeModal = false;
                    }
                } else if (this.configInputChanged()) {
                    if (!confirm('Closing the modal without saving will remove all your changes. Are you sure you want to proceed?')) {
                        closeModal = false;
                    }
                }
                if (closeModal) {
                    this.changeModalVisibility(false);
                }
            }
        },
        validateTag: function (testItem=null) {
            //testItem is relevant for quickAdd chart items otherwise values are pulled from modal fields
            let goBack = true;
            this.clearChartItemErrors();
            let tagName, unit, color, bgColor, chartYAxis;
            if (!testItem) {
                tagName = this.$refs.tagName.value;
                unit = this.$refs.unit.value;
                color = this.selectedChartItem.color;
                bgColor = this.selectedChartItem.bgColor;
                chartYAxis = this.$refs.chartyaxis.value;
            } else {
                tagName = testItem.tagName;
                unit = testItem.unit;
                color = testItem.color;
                bgColor = testItem.bgColor;
                chartYAxis = testItem.chartYAxis_key;
            }

            if (!tagName) {
                this.tagErrorSeen = true;
                goBack = false;
            }
            if (!unit) {
                this.unitErrorSeen = true;
                goBack = false;
            }
            if (!color) {
                this.colorErrorSeen = true;
                goBack = false;
            }
            if (!chartYAxis) {
                this.chartYAxisErrorSeen = true;
                goBack = false;
            }
            return goBack;
        },
        validateCustomXAxis: function () {
            let goBack = true;
            const tagName = this.selectedCustomXAxis.tagName;
            const name = this.selectedCustomXAxis.label;
            const min = this.selectedCustomXAxis.min;
            const max = this.selectedCustomXAxis.max;
            const position = this.selectedCustomXAxis.position;

            if (!tagName) {
                this.customAxisTagError = true;
                goBack = false;
            }
            if (!name) {
                this.nameErrorSeen = true;
                goBack = false;
            }
            if (min != 0 && !min) {
                this.minErrorSeen = true;
                goBack = false;
            }
            if (max != 0 && !max) {
                this.maxErrorSeen = true;
                goBack = false;
            }
            if (!position) {
                this.positionErrorSeen = true;
                goBack = false;
            }
            if (min >= max) {
                this.minMaxErrorSeen = true;
                goBack = false;
            } else {
                this.minMaxErrorSeen = false;
            }

            return goBack;
        },
        validateAxis: function () {
            if (!this.selectedAxis) {
                return false;
            }
            let goBack = true;
            const name = this.selectedAxis.label;
            const min = this.selectedAxis.min;
            const max = this.selectedAxis.max;
            const position = this.selectedAxis.position;

            if (!name) {
                this.nameErrorSeen = true;
                goBack = false;
            }
            if (min != 0 && !min || min === '') {
                this.minErrorSeen = true;
                goBack = false;
            }
            if (max != 0 && !max || max === '') {
                this.maxErrorSeen = true;
                goBack = false;
            }
            if (!position) {
                this.positionErrorSeen = true;
                goBack = false;
            }
            if (min >= max) {
                this.minMaxErrorSeen = true;
                goBack = false;
            } else {
                this.minMaxErrorSeen = false;
            }

            return goBack;
        },
        validateSection: function () {
            if (!this.selectedSection) {
                return false;
            }
            if (this.selectedSection?.name || this.$refs?.sectionName) {
                const name = this.selectedSection.name || this.$refs?.sectionName?.value;
                if (!name) {
                    this.sectionErrorSeen = true;
                    return false;
                } else {
                    this.sectionErrorSeen = false;
                    return true;
                }
            }
            return false;
        },
        saveSectionOrder: function () {
            const self = this;
            const url = '/user/config/columnsorting/sections';
            const sectionsArray = [];
            this.selectedConfig.sections.forEach(section => {
                sectionsArray.push(section.id);
            });

            $.post(
                url,
                {
                    _token: $('meta[name="csrf-token"]').attr('content'),
                    chartconfig_id: self.selectedConfig.id,
                    sections_array: sectionsArray
                },
                function (result) {
                    if (result.error) {
                        console.warn(result.message);
                    } else {
                        self.viewSectionList = false;
                    }
                },
                'json'
            ).fail(function (jqXHR, textStatus, errorThrown) {
                const errorMessage = 'Failed to save sorted sections';
                console.warn(errorMessage, errorThrown);
                if (jqXHR.status == 401) {
                    console.warn('unauthorized');
                    self.hasAuthError = true;
                } else {
                    //TODO: handle this
                }
            });
        },
        saveChartItemOrder: function () {}
    }
};
</script>

<style>

.config-history-popover > .popover-body, .config-history-popover > .popover-header  {
    padding: 1px !important;
    background-color: #242A30;
    box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.1), 0px 8px 8px -4px rgba(16, 24, 40, 0.04);
    transition: all 0.3s ease;
    color: white;
    min-width: 686px;
}

.history-popover-title {
    font-size: 20px !important;
    padding: 10px !important;
}

.config-history-popover table {
    width: auto;
    table-layout: auto;
}

.config-history-popover thead {
    top: -2px !important;
}

.config-history-popover thead,
.config-history-popover th,
.config-history-popover td {
    border: none !important;
}

.config-history-popover td,
.config-history-popover th {
    white-space: normal;
}

.cobaltButton {
    background-color: #004DBF;
    width: 115px;
}

.main-container {
    border-radius: 20px;
}

.div-border {
    border-width: 1px !important;
    border-color: #000 !important;
}

.autocomplete {
    width: 100%;
}

.autocomplete-input {
    width: 100%;
    border-width: 0px;
}

.autocomplete-result-list {
    max-height: 300px;
    height: auto !important;
    overflow: auto;
    padding: 0px;
}

.swatch {
    padding: 5px;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0,0,0,.1);
    display: inline-block;
    cursor: pointer;
}

.popover {
    position: absolute;
    top: 30px;
    z-index: 2;
}

.cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.color {
    width: 36px;
    height: 14px;
    border-radius: 2px;
}

</style>

<style scoped>

    .white-text {
        color: white;
    }

    .selected-check {
        margin-top: 2px;
    }

    .selected-check i {
    color: black;
    }

    .select-wrapper {
        position: relative;
    }

    .select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    cursor: pointer;
    }

    .select.active {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    }

    .select-value {
    color: #495057;
    font-family: inherit;
    font-size: 1rem;
    font-weight: 400;
    }

    .select-arrow {
    width: 0.8em;
    height: 0.8em;
    /* filter changes color of arrow image to be #495057 : */
    filter: invert(21%) sepia(50%) saturate(561%) hue-rotate(158deg) brightness(91%) contrast(94%);
    }

    .select-dropdown .select-group-label {
    font-weight: bold;
    color: black;
    padding-left: 10px;
    }

    .select-dropdown .select-option {
    padding-left: 30px;
    color: black;
    }

    .select-dropdown .select-option:hover {
    background-color: #007bff;
    color: white;
    }

    .select-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    border-top: none;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background-color: #fff;
    z-index: 999;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
    max-height: calc(25 * 1rem);
    overflow-y: auto;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.7);
    }

    .select-dropdown.active {
    opacity: 1;
    visibility: visible;
    border-bottom-left-radius: 0;
    }

    .error-span {
        color: red;
        font-weight: bold;
    }
    button:disabled {
        opacity: 0.5;
    }
    .disabledIcon {
        opacity: 0.5;
    }
</style>
