<template>
  <div class="row ml-5 mb-1" :class="{'p-1 bg-light':structure.name == 'name' || structure.name == 'number'}">
    <div class="col" v-if="leafNode">
      {{structure.name}}
    </div>
    <div class="col" v-else>
      {{structure.name}}
      <structure-node-component v-for="item of structure.children" :structure="item" :key="item.name+parseInt(Math.random()*1000000)" :parents="breadcrumb"
      @valueChange="onValueChange" :data="data"></structure-node-component>
    </div>
    <div class="col-3" v-if="leafNode">
      <template v-if="leafNode">
        <input v-if="structure.dataType=='number'" type="number" class="form-control form-control-sm" @change="valueChange($event)" v-model="value">
        <input v-if="structure.dataType=='string'" type="text" class="form-control form-control-sm" @change="valueChange($event)" v-model="value">
        <div v-if="structure.dataType=='boolean'" class="form-group form-check">
          <input  type="checkbox" class="form-check-input" @change="valueChange($event)" v-model="value">
          <label class="form-check-label"></label>
        </div>
        <select v-if="structure.dataType=='choice'" class="form-control form-control-sm" @change="valueChange($event)" v-model="value">
          <option>-</option>
          <option v-for="choice of structure.choices" :key="'choice'+choice+parseInt(Math.random()*10000000000)">{{choice}}</option>
        </select>
        <div class="row" v-if="structure.dataType=='linkToExisting'">
          <a class="col btn btn-primary btn-sm m-1" v-if="value" :href="'/metadata/'+value">Open</a>
          <div class="col btn btn-primary btn-sm m-1" @click="editLink">
            <span v-if="gettingOptions">Getting options...</span>
            <span v-else>Edit</span>
          </div>
        </div>
      </template>
    </div>
    <div class="modal fade" id="selectLinkModal" tabindex="-1" aria-labelledby="selectLinkModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header text-light">
            <h5 class="modal-title" id="exampleModalLabel">Select item to link</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <select class='form-control' v-model="selectedLink">
              <option v-for="option of linkOptions" :key="option.id" :value="option.id">{{option.data}}</option>
            </select>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="updateLink">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      gettingOptions:false,
      message:'hi',
      linkOptions:[],
      selectedLink:null,
      value:null
    }
  },
  methods:{
    editLink: async function(){
      let self = this
      if(this.linkOptions.length == 0){
        self.gettingOptions = true
        this.linkOptions = await this.getLinkOptions();
        self.gettingOptions = false
      }
      $('#selectLinkModal').modal('show');
    },
    getLinkOptions: async function(){
      let self = this
      let options = await $.get(`/metadataGetLinkOptions/${self.id}/${self.structure.linkToExisting}`)
      return options
    },
    onValueChange(value){
      this.$emit('valueChange',value)
    },
    updateLink(){
      console.log(`should link to id: ${this.selectedLink}`)
      this.value = this.selectedLink;
      $('#selectLinkModal').modal('hide');
      this.$emit('valueChange',{value:this.value,breadcrumb:this.breadcrumb})
    },
    valueChange(event){
      let value = event.target.value
      if(event.target.type=='checkbox')
        value = event.target.checked
      if(this.structure.dataType=='number')
        value = parseFloat(value)
      console.log(`value:${value}, breadcrumb: ${JSON.stringify(this.breadcrumb)}`)
      this.$emit('valueChange',{value:value,breadcrumb:this.breadcrumb})
    }
  },
  mounted(){
    if(this.leafNode){
      console.log(`this is a leaf node and should calculate value`)
      let val = null
      switch(this.breadcrumb.length){
        case 5:
          if(this.data.hasOwnProperty(this.breadcrumb[0]) && this.data[this.breadcrumb[0]].hasOwnProperty(this.breadcrumb[1]) && this.data[this.breadcrumb[0]][this.breadcrumb[1]].hasOwnProperty(this.breadcrumb[2]) && this.data[this.breadcrumb[0]][this.breadcrumb[1]][this.breadcrumb[2]].hasOwnProperty(this.breadcrumb[3]) && this.data[this.breadcrumb[0]][this.breadcrumb[1]][this.breadcrumb[2]][this.breadcrumb[3]].hasOwnProperty(this.breadcrumb[4]))
            val = this.data[this.breadcrumb[0]][this.breadcrumb[1]][this.breadcrumb[2]][this.breadcrumb[3]][this.breadcrumb[4]]
          break
        case 4:
          if(this.data.hasOwnProperty(this.breadcrumb[0]) && this.data[this.breadcrumb[0]].hasOwnProperty(this.breadcrumb[1]) && this.data[this.breadcrumb[0]][this.breadcrumb[1]].hasOwnProperty(this.breadcrumb[2]) && this.data[this.breadcrumb[0]][this.breadcrumb[1]][this.breadcrumb[2]].hasOwnProperty(this.breadcrumb[3]))
            val = this.data[this.breadcrumb[0]][this.breadcrumb[1]][this.breadcrumb[2]][this.breadcrumb[3]]
          break
        case 3:
          if(this.data.hasOwnProperty(this.breadcrumb[0]) && this.data[this.breadcrumb[0]].hasOwnProperty(this.breadcrumb[1]) && this.data[this.breadcrumb[0]][this.breadcrumb[1]].hasOwnProperty(this.breadcrumb[2]))
            val = this.data[this.breadcrumb[0]][this.breadcrumb[1]][this.breadcrumb[2]]
          break
        case 2:
          if(this.data.hasOwnProperty(this.breadcrumb[0]) && this.data[this.breadcrumb[0]].hasOwnProperty(this.breadcrumb[1]))
            val = this.data[this.breadcrumb[0]][this.breadcrumb[1]]
          break
        default:
          if(this.data.hasOwnProperty(this.breadcrumb[0]))
            val = this.data[this.breadcrumb[0]]
          break
      }
      this.value = val
    }
  },
  props : {
    data:Object,
		structure:Object,
    parents:Array,
    id:String
	},
  computed:{
    leafNode(){
      return this.structure.hasOwnProperty('dataType')
    },
    breadcrumb(){
      let parents = []
      for(const p of this.parents){
        parents.push(p)
      }
      parents.push(this.structure.name)
      return parents
    }
  }
}
</script>