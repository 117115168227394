var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        "b-button",
        {
          class: {
            "dot-clickable": true,
            "dot-read": !this.hasUnreadMessages,
            transparent: this.count == 0,
          },
          attrs: { disabled: _vm.count <= 0, id: _vm.popoverId },
          on: { click: _vm.applyFilter },
        },
        [_vm._v(_vm._s(this.count))]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: _vm.popoverId,
            "custom-class": "popover-body-custom ",
            triggers: "click blur",
            placement: "righttop",
            id: "test",
          },
          on: { show: _vm.onShow },
        },
        [
          _c(
            "div",
            { staticClass: "scroll-container", attrs: { id: "scroller" } },
            _vm._l(_vm.filteredComments, function (comment, index) {
              return _c("div", { key: comment.id }, [
                _c("div", { staticClass: "comment-container ml-0 pr-1" }, [
                  _c("div", { staticClass: "text-left" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.dateDisplayString(comment)) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "m-1 p-1" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(comment.text) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "small text-right m-0 p-1" }, [
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          comment.user.first_name || comment.user.username
                        ) +
                        "\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.getActivityType(comment) || _vm.getReasonType(comment)
                    ? _c("div", { staticClass: "m-0 row mb-2" }, [
                        _vm.getActivityType(comment)
                          ? _c(
                              "div",
                              { staticClass: "small col-6 pl-1" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "badge badge-success",
                                    attrs: { id: "tooltip-activity" + index },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.getActivityType(comment)) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      triggers: "hover",
                                      target: "tooltip-activity" + index,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.getActivityType(comment)) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _vm.getReasonType(comment)
                          ? _c(
                              "div",
                              { staticClass: "small col-6 pr-2" },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "badge badge-primary float-right",
                                    attrs: { id: "tooltip-reason" + index },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.getReasonType(comment)) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-tooltip",
                                  {
                                    attrs: {
                                      triggers: "hover",
                                      target: "tooltip-reason" + index,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.getReasonType(comment)) +
                                        "\n                            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.getContractorName(comment)
                    ? _c("div", { staticClass: "m-0 row mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "small col-6 pl-1" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "badge badge-info",
                                attrs: { id: "tooltip-contractor" + index },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.getContractorName(comment)) +
                                    "\n                            "
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  triggers: "hover",
                                  target: "tooltip-contractor" + index,
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(_vm.getContractorName(comment)) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]),
              ])
            }),
            0
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }