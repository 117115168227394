<template>
    <iws-modal
        :title="'Stream Quality ' + `(${this.streamData.name})`"
        :primary-button-text="'Save'"

        :showModal="showBaseModal"
        max-width="500px"

        @close="cancelAction"
        @secondary-action="cancelAction"
        @primary-action="confirmAction"
    >
        <template #content>
            <iws-tabs
                :tabs="[
                { title: 'Field Quality'},
                { title: 'Cloud Quality'}
                ]"
                >
                <template #tab_0>
                    <div class="row">
                        <iws-select class="mb-2 stream-field"
                            label="Quality Preset"
                            :options="qualityPresetOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.localQualityPreset"
                            ref="localQualityPreset"
                        />
                    </div>
                    <div class="row d-flex justify-content-between">
                        <iws-select class="mb-2 stream-field"
                            label="Resolution"
                            :placeholder="streamData.localResolution ? '' : 'Use source'"
                            :options="resolutionOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.localResolution"
                        />
                        <iws-select class="mb-2 stream-field"
                            label="FPS"
                            :options="fpsOptions"
                            :value.sync="streamData.localFPS"
                        />
                    </div>
                    <div class="row d-flex justify-content-between">
                        <iws-select class="mb-2 stream-field"
                            label="Bitrate (kbps)"
                            :options="bitrateOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.localBitrate"
                        />
                        <iws-select class="mb-2 stream-field"
                            label="Key Frame Interval (secs)"
                            :options="[1, 2, 3, 4]"
                            :value.sync="streamData.localKeyFrameIntervalFactor"
                        />
                    </div>
                    <div class="row">
                        <iws-select class="mb-2 stream-field"
                            label="Encoder Preset"
                            :options="encoderPresetOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.localPreset"
                        />
                    </div>
                </template>
                <template #tab_1>
                    <div class="row">
                        <iws-select class="mb-2 stream-field"
                            label="Quality Preset"
                            :options="qualityPresetOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.cloudQualityPreset"
                            ref="cloudQualityPreset"
                        />
                    </div>
                    <div class="row d-flex justify-content-between">
                        <iws-select class="mb-2 stream-field"
                            label="Resolution"
                            :options="resolutionOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.cloudResolution"
                        />
                        <iws-select class="mb-2 stream-field"
                            label="FPS"
                            :options="fpsOptions"
                            :value.sync="streamData.cloudFPS"
                        />
                    </div>
                    <div class="row d-flex justify-content-between">
                        <iws-select class="mb-2 stream-field"
                            label="Bitrate (kbps)"
                            :options="bitrateOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.cloudBitrate"
                        />
                        <iws-select class="mb-2 stream-field"
                            label="Key Frame Interval (secs)"
                            :options="[1, 2, 3, 4]"
                            :value.sync="streamData.cloudKeyFrameIntervalFactor"
                        />
                    </div>
                    <div class="row">
                        <iws-select class="mb-2 stream-field"
                            label="Encoder Preset"
                            :options="encoderPresetOptions"
                            display-name="text"
                            value-name="value"
                            :value.sync="streamData.cloudPreset"
                        />
                    </div>
                </template>
            </iws-tabs>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { flushToasts, isFalsy } = GlobalFunctions;

export default {
    data() {
        return {
            showBaseModal: false,
            resolve: null,
            data: {
                localQualityPreset: null,
                cloudQualityPreset: null,
                cloudResolution: null,
                localResolution: null,
                cloudFPS: null,
                localFPS: null,
                cloudBitrate: null,
                localBitrate: null,
                cloudKeyFrameIntervalFactor: null,
                localKeyFrameIntervalFactor: null,
                cloudPreset: null,
                localPreset: null,
            },
            qualityPresetOptions: [
                {text: 'High', value: 'high'},
                {text: 'Medium', value: 'medium'},
                {text: 'Low', value: 'low'},
                {text: 'Custom', value: 'Custom'},
            ],
            resolutionOptions: [
                { text: 'Use Source', value: null},
                { text: '480', value: 480},
                { text: '576', value: 576},
                { text: '720', value: 720},
                { text: '960', value: 960},
                { text: '1080', value: 1080},
            ],
            bitrateOptions: [
                { text: '1,000', value: 1000000},
                { text: '1,500', value: 1500000},
                { text: '2,500', value: 2500000},
                { text: '4,000', value: 4000000},
                { text: '5,000', value: 5000000},
                { text: '7,500', value: 7500000},
                { text: '8,000', value: 8000000},
                { text: '12,000', value: 12000000},
            ],
            encoderPresetOptions: [
                { text: 'Slower', value: 'slower' },
                { text: 'Slow', value: 'slow' },
                { text: 'Medium', value: 'medium' },
                { text: 'Fast', value: 'fast' },
                { text: 'Faster', value: 'faster' },
            ],
            fpsOptions: [5, 10, 15, 20, 25],
            streamData: {
                name: null,
                localQualityPreset: null,
                cloudQualityPreset: null,
                cloudResolution: null,
                localResolution: null,
                cloudFPS: null,
                localFPS: null,
                cloudBitrate: null,
                localBitrate: null,
                cloudKeyFrameIntervalFactor: null,
                localKeyFrameIntervalFactor: null,
                cloudPreset: null,
                localPreset: null,
            },
            nullStreamData: {
                name: null,
                localQualityPreset: null,
                cloudQualityPreset: null,
                cloudResolution: null,
                localResolution: null,
                cloudFPS: null,
                localFPS: null,
                cloudBitrate: null,
                localBitrate: null,
                cloudKeyFrameIntervalFactor: null,
                localKeyFrameIntervalFactor: null,
                cloudPreset: null,
                localPreset: null,
            }
        };
    },
    
    methods: {
        cancelAction() {
            this.resolve(null)
        },
        confirmAction() {
            flushToasts();
            if (this.streamData.resolutionData) delete this.streamData.resolutionData;
            if (this.streamData.bitrateData) delete this.streamData.bitrateData;
            if (this.streamData.fpsData) delete this.streamData.fpsData;
            this.resolve({ ...this.streamData });
        },

        open(stream) {
            if (isFalsy(stream)) {
                this.streamData = {...this.nullStreamData};
            } else {
                this.streamData = {
                    ...stream,
                    name: stream.name || null,
                    localQualityPreset: stream.localQualityPreset || null,
                    cloudQualityPreset: stream.cloudQualityPreset || null,
                    cloudResolution: stream.cloudResolution || null,
                    localResolution: stream.localResolution || null,
                    cloudFPS: stream.cloudFPS || null,
                    localFPS: stream.localFPS || null,
                    cloudBitrate: stream.cloudBitrate || null,
                    localBitrate: stream.localBitrate || null,
                    cloudKeyFrameIntervalFactor: stream.cloudKeyFrameIntervalFactor || null,
                    localKeyFrameIntervalFactor: stream.localKeyFrameIntervalFactor || null,
                    cloudPreset: stream.cloudPreset || null,
                    localPreset: stream.localPreset || null,
                }
            }

            return new Promise( (resolve, reject) => {
                this.resolve = (value) => {
                    this.showBaseModal = false;
                    this.streamData = {
                        ...this.nullStreamData
                    }
                    resolve(value);
                };
                this.showBaseModal = true;
            });
        }
    }
};
</script>
<style>
.stream-field {
    width: 200px;
}
</style>