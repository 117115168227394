<template>
    <div>
        <iws-breadcrumbs class="mt-4 mb-1"
            :path="[{
                title: 'Home',
                link: '/home'
            }, {
                title: 'Alert Banner Management',
                link: '/alertBanner'
            }]"
        />
        <div class="page-title mb-4">
            Alert Banner Management
        </div>
        
        <div class="row justify-content-left align-items-center mb-2 mt-3">
            <div class="col-1 list-label text-left">Message</div>
            <div class="col-6">
            <input
                class="form-control"
                maxlength="300"
                type="text"
                v-model="newAlert.message"
                v-on:keyup="countdown"
            />
            </div>
            <div class="col-1">
                {{remainingCount}}
            </div> 
        </div>
        <div class="row justify-content-left align-items-center pb-2" >
            <div class="col-1 list-label text-left">Severity</div>
            <div class="col-6">
                    <select @change="configChanged = true;" class="form-control" v-model="newAlert.severity">
                    <option
                    v-for="severity in severityTypes"
                    :value="severity"
                    :key="severity"
                    >{{severity}}</option>
                </select>
            </div>
        </div>
        <div class="row justify-content-left align-items-center pb-2" >
            <div class="col-1 list-label text-left">Expiry</div>
            <div class="col-6">
                <input
                class="form-control"
                type="number"
                v-model="newAlert.expiry"
                />
            </div>
            <div class="col-1">
                hours
            </div> 
        </div>
        <div class="row justify-content-left">
            <div class="col-1">
            </div>
            <div class="col-6 align-items-right">
                <b-button block :disabled="!newAlert.message || !newAlert.severity || !newAlert.expiry" 
                variant="primary" @click="submitAlert">Save and Publish</b-button>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col">
                <table class="table table-striped table-hover mt-2" :class="{'table-dark': darkMode}">
                    <thead>
                    <th scope="col">Message</th>
                    <th scope="col">Severity</th>
                    <th scope="col">
                        <b-button @click="refreshAlerts()" size="sm" variant="primary">
                            <b-icon icon="arrow-repeat" aria-label="Reload"></b-icon> Refresh
                        </b-button>
                    </th>
                    <th scope="col">Expiry (minutes)</th>
                    </thead>
                    <tbody>
                    <tr v-for="(alert, index) in alertList" v-bind:key="alert.id">
                        <td>{{alert.message}}</td>
                        <td>{{alert.severity}}</td>
                        <td> <span class="badge badge-primary" @click="publishAlert({...alert, display: true})" style="cursor:pointer;"
                                v-if="!alert.display">Publish</span>
                            <span class="badge badge-secondary"
                                v-if="alert.display">Published</span>
                            <span class="badge badge-danger" @click="stopDisplayingAlert({...alert, display: false})" style="cursor:pointer;"
                                v-if="alert.display">Unpublish</span>
                        </td>
                        <td>{{(alert.expiryRedis / 60).toFixed(2)}}</td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            alertList: [],
            severityTypes: [
                'high',
                'medium',
                'low'
            ],
            newAlert: {
                message: '',
                severity: '',
                expiry: 1,
                display: true
            },
            maxCount: 300,
            remainingCount: 300,
        }
    },
    async created() {
        const alerts = await this.getAlerts();
        this.alertList = alerts ? alerts : [];
    },
    methods: {
        async getAlerts() {
            return await axios({
                url: '/alertBanner/getAlerts',
                method: 'GET'
            })
            .then(res => res.data)
            .catch(e => console.error('error retrieving alerts list', e))
        },
        async refreshAlerts() {
            this.alertList = await this.getAlerts();
        },
        async submitAlert() {
            await this.publishAlert(this.newAlert)
            this.newAlert = {
                message: '',
                severity: '',
                expiry: 1,
                display: true
            }
        },
        async publishAlert(alert) {
            console.log('about to publish new alert')
            axios({
                url: '/alertBanner/addAlert',
                method: 'POST',
                data: {
                    data: alert
                }
            })
            .then(async res => {
                const previousDisplayedAlert = this.alertList.find(alert => alert.display);
                if (previousDisplayedAlert) {
                    await this.stopDisplayingAlert({...previousDisplayedAlert, display: false});
                }
                else {
                    this.alertList = await this.getAlerts();
                }
            })
            .catch(e => console.log('Error Adding new Alert', e));
        },
        /**
         * returns the alerts list after stopping the alert passed into it
         * @param {*} alert 
         */
        async stopDisplayingAlert(alert) {
            await axios({
                url: '/alertBanner/stopAlert',
                method: 'POST',
                data: {
                    data: alert
                }
            })
            .then(res => {
                if (res.data) this.alertList = res.data;
            })
            .catch(e => console.log('Error stopping previous Alert', e));
        },
        countdown() {
        this.remainingCount = this.maxCount - this.newAlert.message.length;
        }
    },
    computed: {
        darkMode: function () {
            return this.$root.darkMode;
        }
    }
}
</script>
<style>
    
</style>