var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex flex-column",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("div", {
        staticStyle: { width: "100%", height: "100%" },
        attrs: { id: "availability" + _vm.dashboardItem.i },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "curtain", style: _vm.enabledStyle }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }