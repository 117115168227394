var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("iws-modal", {
    attrs: {
      title: "Device Keychain",
      showModal: _vm.showBaseModal,
      "max-width": "650px",
      "primary-button-click": _vm.confirmAction,
    },
    on: { close: _vm.closeAction, "secondary-action": _vm.closeAction },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function () {
          return [
            _vm.fieldUserAccounts === null
              ? _c(
                  "div",
                  {
                    staticClass: "full-width",
                    attrs: { id: "loading-container" },
                  },
                  [
                    _c("b-spinner", {
                      staticClass: "align-middle",
                      attrs: { large: "" },
                    }),
                  ],
                  1
                )
              : !_vm.fieldUserAccounts?.length
              ? _c("h5", { staticClass: "full-width" }, [
                  _vm._v("\n            No accounts\n        "),
                ])
              : _c("iws-table", {
                  attrs: {
                    columns: _vm.columns,
                    items: _vm.fieldUserAccounts,
                    "sort-by-col": "username",
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "cell_password",
                      fn: function ({ data }) {
                        return [
                          _c("iws-input", {
                            attrs: {
                              value: data.item.password,
                              type: "password",
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(data.item, "password", $event)
                              },
                              input: function ($event) {
                                data.item.isDirty = true
                              },
                            },
                          }),
                        ]
                      },
                    },
                    {
                      key: "cell_actions",
                      fn: function ({ data }) {
                        return [
                          _c("iws-button", {
                            staticClass: "mx-2",
                            attrs: {
                              type: "outline-light",
                              icon: "far fa-copy",
                              "data-toggle": "tooltip",
                              title: "Copy to Clipboard",
                              click: () =>
                                _vm.copyPassword(data?.item?.password),
                            },
                          }),
                          _vm._v(" "),
                          _c("iws-button", {
                            attrs: {
                              type: "outline-light",
                              icon: "fas fa-random",
                              "data-toggle": "tooltip",
                              title: "Generate Random Password",
                              click: () =>
                                _vm.onClickKeychainGenerate(data?.item),
                            },
                          }),
                        ]
                      },
                    },
                  ]),
                }),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }