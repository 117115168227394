<template>
<base-kpi-template ref="baseKpiTemplate"
    :title="`Pumping Hours/${item.options.dayType === 'Stage' ? 'Stage' : 'Day'}`"
    :item="item"
    :inital-options="initalOptions"
    :edit-mode="editMode"
    :create-export-data="createExportData"
    :analytics-data="analyticsData"
    :errors="errors"
    :show-not-enough-data-warning="showNotEnoughDataWarning"
    :show-chart="showChart"

    @analyticsChanges="buildAnalytics()"
    @settingsChanges="initKPI()"
    @revertChanges="initKPI()"
>
    <template #settings>
        <iws-select
            label="Data Source"
            :options="dataSources"
            :value.sync="item.options.selectedPumpTimeSource"
            display-name="label"
            value-name="value"
            form-spacing
            @change="initKPI()"
        />

        <iws-checkbox
            label="Stack by Well"
            :value.sync="item.options.isStackedByWell"
            form-spacing
            enable-click-label
            @change="buildData()"
        />
    </template>

    <template #extra-info>
        <div class="extra-info">
            Time Range: {{ getTimeRangeDescription }}
        </div>
    </template>

    <template v-if="showChart" #content>
        <bar-chart
            ref="kpi"
            :chart-data="kpi"
            :options="options"
        />
    </template>
</base-kpi-template>
</template>

<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isNullOrEmpty } = GlobalFunctions;

import constants from './constants.js';
const { DEFAULT_DATE_FORMAT, SECONDARY_DATE_FORMAT, chartStyle } = constants;

const moment = require('moment');

import BaseKpi from './BaseKpi.vue';
import BaseKpiTemplate from './BaseKpiTemplate.vue';

import BarChart from '../../../barChart.js';

export default {
    extends: BaseKpi,
    components: { 
        BaseKpiTemplate,
        BarChart
    },

    data() {
        return {
            ...this.BaseKpi({
                yAxisLabel: 'Pump Time (Hours)',
                datasets: [],

                legend: this.item.options.dayType === 'Day shift and night shift' || this.item.options.isStackedByWell
                    ? {
                        display: true,
                        labels: {
                            fontColor: '#CCC',
                            boxWidth: 15,
                            generateLabels: chart => {
                                const labels = Chart.defaults.global.legend.labels.generateLabels(chart);
                                
                                return labels.map(_label => {
                                    _label.text = _label.text.charAt(0).toUpperCase() + _label.text.slice(1);
                                    return _label;
                                });
                            }
                        }
                    }
                    : null,

                xTick_callback: value => this.item.options.dayType === 'Stage' ? value : moment.utc(value, SECONDARY_DATE_FORMAT).format('MMM D'),
                tooltip_callback: this.item.options.dayType === 'Day shift and night shift'
                    ? (tooltipItem, data) => `Pumping Hours (Day): ${data?.datasets[0].data[tooltipItem.index]}<br>Pumping Hours (Night): ${data?.datasets[1].data[tooltipItem.index]}`
                    : tooltipItem => `Pumping Hours: ${tooltipItem?.value || tooltipItem}`,

                y_stepSize: 2,
                suggestedMax: 24
            }),

            defaultDataSetObj: {
                label: 'Pump Time (Hours)',
                labelColor: '#CCCCCC',
                fill: false,
                backgroundColor: chartStyle.primaryBarGraphColour,
                borderColor: chartStyle.primaryBarGraphColour,
                data: []
            },
            dataSources: [
                { label: 'Mongo DB', value: 'mongoDB' },
                { label: 'ADX DB', value: 'ADXDB' },
            ]
        }
    },

    computed: {
        // Generically named computed properties to handle changes specific to this KPI
        showNotEnoughDataWarning: function() {
            return this.allowShowChart && this.isNotEnoughDataMsgPerKPIType(this.kpi);
        },
        showChart: function() {
            return this.allowShowChart && !isNullOrEmpty(this.kpi?.datasets[0]?.data);
        }
    },

    methods: {
        // Genericly named function for BaseKPI.vue to call on mounted
        // This creates the KPI in a unique way to the current files needs
        setDefaults: function() {
            this.$set(this.item.options, 'zeroStageType', this.item.options.zeroStageType || 'zeroStage-include');
            this.$set(this.item.options, 'dayType', this.item.options.dayType || 'Shift start to shift start');
            this.$set(this.item.options, 'selectedPumpTimeSource', this.item.options.selectedPumpTimeSource || 'mongoDB');
            this.$set(this.item.options, 'isStackedByWell', this.item.options.isStackedByWell || false);
        },
        initKPI: function() {
            this.allowShowChart = false;
            return this.fetchData().then(this.buildData);
        },
        fetchData: function() {
            return $.get(`/job-kpi-pump-times/${this.jobNumber}`, {
                timeRangeType: this.item.options.dayType,
                dataSource: this.item.options.selectedPumpTimeSource
            }).then(response => this.data = this.pumpHoursToXDecimalPoints(response, 2));
        },
        buildData: function() {
            //package async call into a function so new data can be acquired during watcher handler on item.options.dayType
            this.processCalculatedKPIResponse();
            this.setupPumpHoursPerDay();
            
            this.allowShowChart = true;

            this.$nextTick(() => {
                // this.createJobTimeAnnotationLines();
                this.buildAnalytics();
            })
        },
        buildAnalytics: function() {
            this.onAnalyticsTypeChange(this.item.options.analyticsType);
            this.buildAnalyticsData();

            this.$nextTick(() => {
                this.assignChartSize();
            });
        },
        createExportData: function() {
            const isDayNight = this.item.options.dayType === 'Day shift and night shift';

            const dataSets = this.kpi.datasets;
            const labels = this.kpi.exportLabels;
            const fileName = this.getExportFilename(isDayNight ? this.item.options.type + '-dayNight' : this.item.options.type);

            if (this.item.options.isStackedByWell) {
                var fields = this.processStackedExportFields(dataSets);
                var items = this.processStackedExportItems(dataSets, labels);
            } else {
                var fields = isDayNight ? ['Date', 'Day', 'Night'] : this.item.options.dayType === 'Stage' ? ['Stage', 'Hours'] : ['Date', 'Hours'];
                var items = this.processExportItems(dataSets, labels, isDayNight);
            }

            return { 
                fields, 
                items, 
                fileName
            };
        },
        pumpHoursToXDecimalPoints(res, X) {
            return _.mapValues(res, (nestedObj) => _.mapValues(nestedObj, value => parseFloat(GlobalFunctions.roundAccurately(value, X).toFixed(X))));
        },
        processCalculatedKPIResponse() {
            if (this.data?.error) {
                console.log(this.data?.message);
                return;
            }

            // If timeRange is dayNight, then split the lateral length data into seperate collections based on shift times
            if (this.item.options.dayType === 'Day shift and night shift') {
                this.kpi.datasets = [
                    { ...this.defaultDataSetObj, data: [], label: 'day' },
                    { ...this.defaultDataSetObj, data: [], label: 'night', backgroundColor: chartStyle.secondaryBarGraphColour }
                ];

                //shiftData start times only required for day-night calculations currently
                const shiftStartTimes = this.getShiftStartTimes();
                const days = {};
                const nights = {};
                const labels = new Set(); //collection of only unique values for the date axis labels

                Object.entries(this.data).forEach(record => {//record[0] => dateTime, record[1] => lateral length value
                    //add unique dates to the set of date labels
                    const date = moment(record[0]).format('YYYY-MM-DD');
                    const time = moment(record[0]).format('HH:mm:ss');
                    labels.add(date);

                    //compare time of the record to which shift threshold it occurs on
                    if (time >= shiftStartTimes.nightShift || time < shiftStartTimes.dayShift) {
                        nights[date] = record[1];
                    } else {
                        days[date] = record[1];
                    }
                });

                //turn date labels into an array without duplicates dates, use $set to force chart re-render
                this.$set(this.kpi, 'labels', Array.from(labels));

                //populate datasets with filtered data (rounded to 2 decimals but kept as a  Number type)
                this.data =  {};
                this.data.day = days;
                this.data.night = nights;
                this.kpi.datasets[0].data = Object.values(days).map(value=>value.duration);
                this.kpi.datasets[1].data = Object.values(nights).map(value=>value.duration);
            } else {
                this.kpi.datasets = [ { ...this.defaultDataSetObj, data: [] } ];

                //Add values based on dates already calculated on the backend, use $set to ensure re-render
                //use unary (+) to enforce Number type of data
                this.$set(this.kpi, 'labels', Object.keys(this.data));

                this.data = this.data;
                this.kpi.datasets[0].data = Object.values(this.data).map(value=>value.duration);
            }

            this.analyticDatasets = _.cloneDeep(this.kpi.datasets);
            this.appendZeroEntriesToEnd(this.kpi);
        },
        setupPumpHoursPerDay() {
            const isDayNight = this.item.options.dayType === 'Day shift and night shift';
            let labels
            if(isDayNight && (!this.data.day || !this.data.night)) {
                return;
            }

            this.kpi = {
                datasets: [{
                    label: 'Pump Time (Hours)',
                    backgroundColor: chartStyle.primaryBarGraphColour
                }]
            };
            // still using this incase we decide to switch back to using wellId instead of wellNumber
            const wellsById = _.keyBy(this.dashboardData.wells, 'index');
            const numberOfWells = this.dashboardData.wells.length;

            // If selectedType is kpi-pumpingHours and item.options.dayType is Stage we sort the labels by stage number
            if (this.item.options.dayType === 'Stage') {
                const keys = Object.keys(this.data);

                // Step 1: Filter numeric keys
                const numericKeys = keys.filter(key => !isNaN(key));

                // Step 2: Sort numeric keys in ascending order
                labels = numericKeys.sort((a, b) => Number(a) - Number(b)).map(key => Number(key));
            } else if (isDayNight) {
                labels = [...new Set([...Object.keys(this.data.day), ...Object.keys(this.data.night)])].sort()
            } else {
                labels = Object.keys(this.data).sort()
            }

            this.kpi.labels = labels;
            this.kpi.datasets = [{ label: 'Pump Time (Hours)', backgroundColor: chartStyle.primaryBarGraphColour, data: [] }];

            if(this.item.options?.isStackedByWell) {
                this.options.scales.xAxes[0].stacked = true;
                this.options.scales.yAxes[0].stacked = true;
                this.options.plugins.datalabels.display = function(ctx) {
                    if (!ctx.dataset.stack)
                        return ctx.datasetIndex === ctx.chart.$totalizer.utmost;

                    // grouped stack bar chart ( coded for only 2 groups / example. day and night )
                    let numberOfDataSets, headGroupEndIndex, tailGroupEndIdex, datasetIndex, barDatasets, lineDatasets;
                    barDatasets = ctx.chart.config.data.datasets.filter(dataset => dataset.type === 'bar' || !dataset.type);
                    lineDatasets = ctx.chart.config.data.datasets.filter(dataset => dataset.type === 'line');

                    numberOfDataSets = ctx.chart.config.data.datasets.length;
                    datasetIndex = ctx.datasetIndex;

                    headGroupEndIndex = (numberOfDataSets/2) - 1;
                    tailGroupEndIdex = numberOfDataSets - 1;

                    return datasetIndex === headGroupEndIndex || datasetIndex === tailGroupEndIdex;
                };
                this.options.plugins.datalabels.formatter = (value, ctx) => {
                    const stack = ctx.dataset.stack ?? null;
                    const total = ctx.chart.$totalizer.totals[ctx.dataIndex + stack];
                    if (typeof total == 'number') {
                        return total > 0 ? +total.toFixed(2) : 0;
                    } else {
                        return '';
                    }
                };
            } else {
                this.options.scales.xAxes[0].stacked = false;
                this.options.scales.yAxes[0].stacked = false;
            }

            if (isDayNight) {
                if(this.item.options.isStackedByWell) {
                    this.kpi.datasets = [];
                    for (let dayIndex = 0; dayIndex < 2; dayIndex++) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            this.kpi.datasets.push({ label: wellInfo.name + (dayIndex? ' night' : ' day') + ' Pump Hours(s)', backgroundColor: wellInfo.color, data: [] });
                        }
                    }
                } else {
                    this.kpi.datasets = [
                        { label: 'day', backgroundColor: chartStyle.primaryBarGraphColour, data: [] },
                        { label: 'night', backgroundColor: chartStyle.secondaryBarGraphColour, data: [] }
                    ];
                }
            } else {
                if (this.item.options.isStackedByWell) {
                    this.kpi.datasets = [];
                    for (const [index, wellInfo] of Object.entries(wellsById)) {
                        this.kpi.datasets.push({ label: wellInfo.name + ' Pump Hour(s)', backgroundColor: wellInfo.color, data: [] });
                    }
                }
            }

            for(const key of labels) {
                if(isDayNight) {
                    if(this.item.options.isStackedByWell) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            const datasetIndex = Number(index);
                            const hasKey = this.data.day.hasOwnProperty(key);
                            const pumpTime = hasKey ? this.data.day[key][wellInfo.index] ?? 0 : 0;
                            this.kpi.datasets[datasetIndex].data.push(pumpTime === 0 || Number.isNaN(+pumpTime) ? 0 : pumpTime);
                            this.kpi.datasets[datasetIndex].stack = 'day';
                        }
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            const datasetIndex = Number(index) + numberOfWells;
                            const hasKey = this.data.night.hasOwnProperty(key);
                            const pumpTime = hasKey ? this.data.night[key][wellInfo.index] ?? 0 : 0;
                            this.kpi.datasets[datasetIndex].data.push(pumpTime === 0 || Number.isNaN(+pumpTime) ? 0 : pumpTime);
                            this.kpi.datasets[datasetIndex].stack = 'night';
                        }
                    } else {
                        this.kpi.datasets[0].data.push(this.data.day[key]?.duration ?? 0);
                        this.kpi.datasets[1].data.push(this.data.night[key]?.duration ?? 0);
                    }
                } else {
                    if(this.item.options.isStackedByWell) {
                        for (const [index, wellInfo] of Object.entries(wellsById)) {
                            const datasetLength = this.kpi.datasets.length - 1;
                            const datasetIndex = Number(index > datasetLength ? datasetLength : index);
                            const pumpTime = this.data[key][wellInfo.index] ?? 0;
                            this.kpi.datasets[datasetIndex].data.push(Number.isNaN(pumpTime) ? 0 : pumpTime);
                        }
                    } else {
                        this.kpi.datasets[0].data.push(this.data[key]?.duration ?? 0);
                    }
                }
            }

            // if selectedType is kpi-pumpingHours and item.options.dayType is stage then no need to format the labels
            // as they are already in the correct format, assign labels and exit the function.
            if (this.item.options.dayType === 'Stage') {
                this.kpi.labels = labels;
                this.kpi.exportLabels = labels;
                return;
            }

            let endDate = moment.utc().add({ hours: this.job.hourOffset }).startOf('day');
            if (this.job.end){
                endDate = moment.utc(this.job.end).add({ hours: this.job.hourOffset }).startOf('day')
            }

            function getDates(startDate, stopDate) {
                var dateArray = [];
                var currentDate = moment(startDate);
                var stopDate = moment(stopDate);
                while (currentDate <= stopDate) {
                    dateArray.push( moment(currentDate) )
                    currentDate = moment(currentDate).add({days: 1});
                }
                //append dates to beginning so UI always has at least 10 results
                while (dateArray.length < 10) {
                    dateArray.push(moment(dateArray[dateArray.length-1]).add({days: 1}));
                }

                return dateArray;
            }

            if(!this.job?.start) { return; }

            let startDate = moment.utc(this.job.start).add({ hours: this.job.hourOffset }).startOf('day');
            let dateRange = getDates(startDate, endDate); //returns days between job start and end or job start and present day

            let kpiDataDays = this.kpi.labels.map(n => {
                return moment(n).utc()
                    .format('YYYY-MM-DD')
                    .toString();
            });

            //adds zeroes to datasets and labels to fill in spaces
            for(let i = 0; i < dateRange.length; i++) {
                dateRange = dateRange.reverse();
                if (moment(dateRange[i]).isBefore(moment(kpiDataDays[0]), 'days')) {
                    kpiDataDays.splice(0, 0, dateRange[i]);
                    for (let index = 0; index < this.kpi.datasets.length; index++) {
                        this.kpi.datasets[index].data.splice(0, 0, 0);
                    }
                }
                dateRange = dateRange.reverse();
                if (moment(dateRange[i]).isAfter(moment(kpiDataDays[kpiDataDays.length-1]), 'days')) {
                    kpiDataDays.push(dateRange[i]);
                    for (let index = 0; index < this.kpi.datasets.length; index++) {
                        this.kpi.datasets[index].data.splice(kpiDataDays.length-1, 0, 0);
                    }
                }
            }

            this.kpi.labels = kpiDataDays.map(t => {
                return moment(t)
                    .format(SECONDARY_DATE_FORMAT)
                    .toString();
            });

            this.kpi.exportLabels = kpiDataDays.map(t => {
                const {hours, minutes} = this.getShiftStartTimeInHourAndMinutes();
                const timestamp = moment(t);
                if (isDayNight || (this.dayTypes[0] && this.item.options.dayType === this.dayTypes[0])){
                    timestamp
                        .hour(hours)
                        .minute(minutes);

                }
                return timestamp.format(DEFAULT_DATE_FORMAT);
            })
        }
    }
}
</script>