import moment from 'moment';

export default {
    data() {
        return {
            axiosQueue: [],
            axiosInstance: null,
            lightningChart: null,
            cancelTokenAdxDownload: null,
            abortControllerAdxDownload: null
        };
    },
    mounted() {
        this.axiosInstance =  axios.create();
        delete this.axiosInstance.defaults.headers.common['X-CSRF-TOKEN'];
    },
    methods: {
        downloadDataDirectFromADX(customerDB, tags, jobNumber, wellName, stageNumber, adxUrl, adxAccessToken, fromDate, toDate, interval, dataHandler, startTimeOffset = 0, cancelOutstandingRequests = false) {
            let requestPayload;

            let query = "telemetry\n| where timestamp between (todatetime('" + moment.utc(fromDate).toISOString() + "') .. todatetime('" + moment.utc(toDate).toISOString() + "')) and jobNumber == '" + jobNumber + "'\n| extend ";
            query += this.tagStringReplace(tags, "value:i = toreal(data[':tagName'])");
            query += "\n| make-series";
            query += this.tagStringReplace(tags, "\n\ttag:i = max(value:i) default=long(null)");

            if(!interval) {
                query += "\non timestamp step 1s\n";
            }
            else {
                if(interval.length > 2) {
                    let ptSignifier = interval.substr(0, 2);
                    let rawInterval = interval.substr(2);

                    if(ptSignifier == "PT") {
                        let convertedInterval = rawInterval.toLowerCase();
                        query += "\non timestamp step " + convertedInterval + "\n";
                    }
                }
            }

            query += "| mv-expand timestamp, ";
            query += this.tagStringReplace(tags, "tag:i");
            query += "\n| project\n\t['dateTimestamp'] = (todatetime(timestamp) - datetime(1970-01-01)) / 1ms,";
            query += this.tagStringReplace(tags, "\n\t[':tagName'] = toreal(tag:i)");
            query += "\n| sort by dateTimestamp asc";

            // console.log("ADX Query", query);

            requestPayload = {
                'db': customerDB,
                'csl': query,
                "properties": {
                    "Options": {
                      "servertimeout": "00:04:00",
                      "queryconsistency": "strongconsistency",
                      "query_language": "csl",
                      "request_readonly": true,
                      "request_readonly_hardline": true
                    }
                  }
            };

            // console.log("ADX Payload", JSON.stringify(requestPayload));
           
            const headers = {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + adxAccessToken
            };

            const dataObject = {
                method: 'post',
                url: adxUrl,
                data: requestPayload,
                headers: headers,
                dataHandler: dataHandler,
                dataHandlerProps: [tags, jobNumber, wellName, stageNumber, fromDate, toDate, interval, startTimeOffset]
            };

            this.getADXData(dataObject, cancelOutstandingRequests);
        },
        getStatisticsFromADX(customerDB, tags, jobNumber, adxUrl, adxAccessToken, fromDate, toDate, dataHandler, isGhostData) {
            let requestPayload;

            let tagNames = tags.map(tag => tag.tag);
            let query = "telemetry\n| where timestamp between (todatetime('" + moment.utc(fromDate).toISOString() + "') .. todatetime('" + moment.utc(toDate).toISOString() + "')) and jobNumber == '" + jobNumber + "'\n| extend ";
            query += this.tagStringReplace(tagNames, "value:i = toreal(data[':tagName'])");
            query += "\n| summarize";
            query += this.tagStringReplace(tagNames, "\n\t['min_:tagName'] = min(value:i)");
            query += ",";
            query += this.tagStringReplace(tagNames, "\n\t['max_:tagName'] = max(value:i)");
            query += ",";
            query += this.tagStringReplace(tagNames, "\n\t['avg_:tagName'] = avg(value:i)");
            query += ",";
            query += this.tagStringReplace(tagNames, "\n\t(['first_timestamp:tagName'], ['first_:tagName']) = arg_min(iif(isnotempty(value:i), timestamp, todatetime('" + moment.utc(toDate).toISOString() + "')), value:i)");
            query += ",";
            query += this.tagStringReplace(tagNames, "\n\t(['last_timestamp:tagName'], ['last_:tagName']) = arg_max(iif(isnotempty(value:i), timestamp, todatetime('" + moment.utc(fromDate).toISOString() + "')), value:i)");

            // console.log("ADX Query", query);

            requestPayload = {
                'db': customerDB,
                'csl': query,
                "properties": {
                    "Options": {
                      "servertimeout": "00:04:00",
                      "queryconsistency": "strongconsistency",
                      "query_language": "csl",
                      "request_readonly": true,
                      "request_readonly_hardline": true
                    }
                  }
            };

            // console.log("ADX Payload", JSON.stringify(requestPayload));
           
            const headers = {
                'content-type': 'application/json',
                'authorization': 'Bearer ' + adxAccessToken
            };

            const dataObject = {
                method: 'post',
                url: adxUrl,
                data: requestPayload,
                headers: headers,
                dataHandler: dataHandler,
                dataHandlerProps: [tags, isGhostData]
            };

            this.getADXData(dataObject);
        },
        getADXData: function(request, cancelOutstandingRequests = false) {
            const self = this;
            const dataHandler = request.dataHandler;
            const dataHandlerProps = request.dataHandlerProps;
            request = _.omit(request, ['dataHandler', 'dataHandlerProps']);

            if (cancelOutstandingRequests) {
                //Check if a previous request is in progress and cancel it if it exists
                if (this.cancelTokenAdxDownload) {
                    this.cancelTokenAdxDownload.cancel('cancel outstanding adx download');
                } else if (this.abortControllerAdxDownload) {
                    this.abortControllerAdxDownload.abort();
                }
                // Create a new cancelToken object and abortController instance
                this.abortControllerAdxDownload = new AbortController();
                this.cancelTokenAdxDownload = axios.CancelToken.source();
                request.cancelToken = this.cancelTokenAdxDownload.token;
            }

            this.axiosInstance(request).then(
                (response) => {   
                    dataHandler(response, ...dataHandlerProps);
                }
            ).catch(function (error) {
                if (!axios.isCancel(error)) {
                    console.error('getADXData error...', error);
                    dataHandler(error, ...dataHandlerProps);
                }
            }).finally(() => {
                if (cancelOutstandingRequests) {
                    this.cancelTokenAdxDownload = null;
                    this.abortControllerAdxDownload = null;
                }
            });
        },
        adxDataParser: function(tags, resultData) {
            const data = [];

            for(let tagNameIndex in tags) {
                //initialize the response for each tag
                data[tags[tagNameIndex]] = [];
            }

            for(let tableIndex in resultData){
                if(resultData[tableIndex]['FrameType'] == 'DataTable'){
                    if(resultData[tableIndex]['TableKind'] == 'PrimaryResult') {
                        for(let tableRowIndex in resultData[tableIndex]['Rows']) {
                            let tableRow = resultData[tableIndex]['Rows'][tableRowIndex];
                            if(tableRow.length == 1 + tags.length) {
                                //each tableRow is [timestamp,tagValue0,tagValue1...]
                                let dateTimestamp = tableRow[0];

                                let i = 0;
                                for(let tagNameIndex in tags) {
                                    if(tableRow[1 + i] !== undefined) {
                                        data[tags[tagNameIndex]].push( {
                                            "dateTimestamp": dateTimestamp,
                                            "dataVal": tableRow[1 + i]
                                        });
                                    }
                                    i++;
                                }
                            }
                        }
                    }
                }
            }

            return data;
        },
        adxStatsDataParser: function(tags, resultData, isGhostData) {
            const data = [];

            let tagNames = tags.map(tag => tag.tag);
            for(let tagNameIndex in tagNames) {
                //initialize the response for each tag
                data[tagNames[tagNameIndex]] = tags.find(tag => tag.tag == tagNames[tagNameIndex]);
            }

            for(let tableIndex in resultData){
                if(resultData[tableIndex]['FrameType'] == 'DataTable'){
                    if(resultData[tableIndex]['TableKind'] == 'PrimaryResult') {
                        let columns = resultData[tableIndex]['Columns'];
                        for(let tableRowIndex in resultData[tableIndex]['Rows']) {
                            let tableRow = resultData[tableIndex]['Rows'][tableRowIndex];

                            const statTypes = ['min', 'max', 'avg', 'first', 'last', 'ghostPlotLabel'];

                            for(let tagNameIndex in tagNames) {
                                let tagName = tagNames[tagNameIndex];
                                for(let statTypeIndex in statTypes) {
                                    let statType = statTypes[statTypeIndex];
                                    if (statType == 'ghostPlotLabel') {
                                        let label = isGhostData ? '(ghost)' : ' ';
                                        data[tagNames[tagNameIndex]][statType] = label;
                                    } else {
                                        let columnIndex = columns.findIndex(column => column.ColumnName == statType + '_' + tagName);
                                        data[tagNames[tagNameIndex]][statType] = tableRow[columnIndex]?.toFixed(2) ?? 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            return data;
        },
        tagStringReplace: function(tags, formatStr) {
            this.tagStringReplaceIndex = 0;
            let mappedTags = tags.map(tagName => this.mappingFunction(tagName, formatStr));
            return mappedTags.join(', ');
        },
        mappingFunction: function(tagName, formatStr) {
            let target = formatStr.replaceAll(':i', this.tagStringReplaceIndex).replaceAll(':tagName', tagName);
            this.tagStringReplaceIndex++;
            return target; 
        }
    }
}