<template>
    <span v-if="featureFlagEnabled">
        <iws-button :type="`outline-${statusColour}`" id="commsWidget" @click="modalIsOpen = true">
            <template #text>
                <i :class="`fas ${statusIcon}`"></i>
            </template>
        </iws-button>

        <comms-modal
            :modal-is-open="modalIsOpen"
            :job="job"
            :logs="logs"
            :users="users"
            :pushToTalkInProgress="pushToTalkInProgress"
            :pushToTalkConnecting="pushToTalkConnecting"
            :callIsMuted="callIsMuted"
            :connected="connected"
            :isMinimized="isMinimized"
            @close="onClose"
            @endCallFromButton="endCallFromButton"
            @pushToTalk="pushToTalk($event)"
            @toggleMute="toggleMute"
            @openPosition="openPositionModal"
            @toggleSize="toggleSize"
            :joinCall="joinCall"
            :commsRole="userCommsPermission"
            :activeSpeakerUserId="activeSpeakerUserId"
            @fetchLogs="fetchLogs"
            :activeChannels="activeChannels"
            :user="user"
            :storedActiveChannel="getActiveChannelForRoIPFromLocalStorage()"
            :loading="loading"
            :hasUserEndedTheCall="checkIfUserEndedTheCall()"
            :commsModalPositionTop="commsModalPositionTop"
            :commsModalPositionLeft="commsModalPositionLeft"

        />

        <comms-position-modal ref="CommsPositionModal" :jobNumber="jobNumber" :isAdmin="isAdmin" />
    </span>
</template>

<script>
import { CallClient, Features } from "@azure/communication-calling";
import { AzureCommunicationTokenCredential } from '@azure/communication-common';

import GlobalFunctions from '../../GlobalFunctions.js';
const { isFalsy, toast, isFeatureFlagged } = GlobalFunctions;

import CommsModal from './CommsModal.vue';
import CommsPositionModal from './CommsPositionModal.vue';

import eventBus from "../../eventBus";
export default {
    components: { CommsModal, CommsPositionModal },

    props: {
        user: {
            type: Object,
            required: true
        },
        jobNumber: {
            type: String,
            required: true
        }
    },

    data: () => ({
        apiKey: null, //'5l5ugcqehk9ilgr4jwfuuyz6iwco26tbuh1uhf1vsj3bzkk3ie9hmo34u9qc', //''1234',
        job: null,
        loading: true,
        logs: [],
        users: [],
        call: null,
        callAgent: null,
        callClient: null,
        callEnded: false,
        communicationUserId: null,
        pushToTalkInProgress: false,
        ctrlPressed: false,
        pPressed: false,
        pushToTalkTimer: null,
        roomId: null,
        sessionId: null,
        token: '',
        tokenCredential: null,
        pushToTalkConnecting: false,
        modalIsOpen: false,
        callIsMuted: false,
        usersInterval: null,
        isMinimized: false,
        activeSpeakerUserId: [],
        speakingStartTime: null,
        speakingEndTime: null,
        totalTimeSpoken: 0,
        callDominantSpeakersApi: null,
        roipDeviceId: null,
        roipActiveSpeakers: [],
        roipPad: '',
        audioOutputId: null,
        deviceManager: null,
        activeChannels:[],
        activeChannelRoIP: null,
        isAdmin: false,
        commsModalPositionTop: 0,
        commsModalPositionLeft: 0
    }),
    created() {
            const jobId = this.jobNumber;
            const userId = this.user.id;
            const localStorageKey = `commsWidgetData-${jobId}-${userId}`;
            const storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            if (typeof storedData.commsModalIsOpen !== 'undefined') {
                this.modalIsOpen = storedData.commsModalIsOpen;
            }
            if(typeof storedData.isMinimized !== 'undefined'){
                this.isMinimized = storedData.isMinimized;
            }
            if(typeof storedData.audioOutputId !== 'undefined'){
                this.audioOutputId = storedData.audioOutputId;
            }
            if(typeof storedData.top !== 'undefined' && typeof storedData.left !== 'undefined'){
                this.commsModalPositionTop = storedData.top;
                this.commsModalPositionLeft = storedData.left;
            }else{
                //get the right bottom corner of the screen
                this.commsModalPositionLeft = window.innerWidth - 450;
                this.commsModalPositionTop = window.innerHeight - 200;

            }
            eventBus.$on('channelChange', (roipName) => {
                this.activeChannelRoIP = roipName;
                this.setActiveChannelForRoIP(roipName);
            });
            eventBus.$on('refreshActiveChannels', (roipName) => {
                this.fetchActiveChannels();
                if(this.activeChannelRoIP === roipName){
                    if(this.connected){
                        this.endCall();
                    }
                }
            });
            eventBus.$on('commsModalPosition',(position) => {
               //store in local storage
                let jobId = this.jobNumber;
                let userId = this.user.id;
                let localStorageKey = `commsWidgetData-${jobId}-${userId}`;
                let storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
                storedData.top = position.top;
                storedData.left = position.left;
                this.commsModalPositionLeft = position.left;
                this.commsModalPositionTop = position.top;
                localStorage.setItem(localStorageKey, JSON.stringify(storedData));
            });

    },


    computed: {
        featureFlagEnabled() {
            return isFeatureFlagged('ROIP') && this.userCommsPermission !== 'none';
        },
        connected() {
            return !isFalsy(this.token) && !isFalsy(this.communicationUserId) && !isFalsy(this.roomId) && !isFalsy(this.call) && !this.callEnded;
        },

        statusIcon() {
            if (!this.connected)
                return 'fa-phone-slash';
            if (this.loading || this.callIsMuted)
                return 'fa-volume-mute';
            return ' fa-microphone';
        },
        statusColour() {
            if (!this.connected)
                return 'danger';
            if (this.loading || this.callIsMuted)
                return 'warning';
            return 'success';
        },
        userCommsPermission(){
            let dashboardPermission = this.user.dashboard_permissions;
            if(dashboardPermission.includes('SCR None')){
                return 'none';
            }
            if(dashboardPermission.includes('SCR Talk')){
                return 'talk';
            }
            if(dashboardPermission.includes('SCR Listen')){
                return 'listen';
            }
            return 'none';
        }
    },

    methods: {
        fetchJobDetails(jobNumber) {
            return axios.get(`/job/${jobNumber}`).then(_job => {
                if (!isFalsy(_job?.data))
                    this.job = _job?.data;
            })
        },
        onClose() {
            this.modalIsOpen = false;

            if (this.connected)
                toast({
                    title: 'Modal closed',
                    body: this.userCommsPermission !== 'talk' ? 'You can still listen to this call, hit the microphone icon to see the details':'You can still listen to this call, hit the microphone icon again to chat',
                    variant: 'success'
                });
        },

        async init() {

            if (this.connected)
                return;

            if(this.userCommsPermission === 'none'){
                return;
            }


            try {
                this.loading = true;
                await this.getToken();
                await this.connectToRoom();
            } catch (err) {
                toast({
                    title: 'Failed to init call',
                    body: err.toString(),
                    variant: 'danger'
                });
            } finally {
                this.loading = false;
            }
        },
        async connectToRoom() {
            if (!this.apiKey)
                await this.requestRoomAccess();

            if (this.roomId) {
                this.callClient = new CallClient();
                this.tokenCredential = new AzureCommunicationTokenCredential(this.token);
                this.callAgent = await this.callClient.createCallAgent(this.tokenCredential);
                this.deviceManager = await this.callClient.getDeviceManager();
                const speakers = await this.deviceManager.getSpeakers();
                //find the speaker which has id matching audioOutputId prefixed by 'spaker:'
                const speaker = speakers.find(speaker => speaker.id === 'speaker:'+this.audioOutputId);
                if(speaker){
                    await this.deviceManager.selectSpeaker(speaker);
                }
                this.joinCall();
            }
        },

        async joinCall() {
            if (isFalsy(this.callAgent)) {
                return this.init();
            } else {
                this.sessionId === null ? await this.requestRoomAccess() : null;
                this.call = this.callAgent.join({ roomId: this.roomId }, { audioOptions: { muted: true } });
                this.callEnded = false;
                let deviceManager  = await this.callClient.getDeviceManager();
                window.addEventListener('keydown', this.onKeyPress);
                window.addEventListener('keyup', this.onKeyPressUp);
                toast({
                    title: 'Joined Call',
                    body: 'Connected to ROIP, hit the microphone icon in your navbar to see call details.',
                    variant: 'success'
                });
                this.fetchUsers();
                let mediaStatsFeature = this.call.feature(Features.MediaStats);
                let mediaStatsCollector = mediaStatsFeature.createCollector();
                mediaStatsCollector.on('sampleReported',(sample)=>{
                    if(sample.audio.receive[0].bitrate > 0 && this.activeSpeakerUserId.length === 0){
                        this.activeSpeakerUserId.push(this.roipDeviceId);
                        this.roipActiveSpeakers.push('Radio');
                    }
                    if(sample.audio.receive[0].bitrate === 0 && this.activeSpeakerUserId.includes(this.roipDeviceId)){
                        this.activeSpeakerUserId = this.activeSpeakerUserId.filter(id => id !== this.roipDeviceId);
                        this.roipActiveSpeakers = this.roipActiveSpeakers.filter(name => name !== 'Radio');
                    }
                });
            }
            this.setUserEndedCallToFalse();
        },
        endCall() {
            // Reset to default state when hanging up
            if (this.callIsMuted) {
                this.call.muteIncomingAudio();
                this.callIsMuted = false;
            }

            this.call.hangUp({ forEveryone: false });
            this.callEnded = true;
            eventBus.$emit('roipInactiveSpeaking');
            window.removeEventListener('keydown', this.onKeyPress);
            window.removeEventListener('keyup', this.onKeyPressUp);

            return axios.get(`/comms/removeUserFromRoom?jobId=${this.job.id}&userId=${this.user.id}&sessionId=${this.sessionId}&roipName=${this.activeChannelRoIP}`).then(_res => {
                this.sessionId = null;
                this.fetchLogs();
                return this.fetchUsers();
            });
        },

        async pushToTalk(action,alreadyConnected = false) {
            if (!this.connected)
                return;

            switch (action) {
                case 'start':
                    // Guard clause for button spam / double click limit >1 request at a time
                    if (this.speakingStartTime) return;
                    this.speakingStartTime = new Date();
                    break;
                case 'end':
                    // Guard clause for button spam / double click limit >1 request at a time
                    if (this.speakingEndTime) return;
                    this.speakingEndTime = new Date();
                    this.totalTimeSpoken = (this.speakingEndTime - this.speakingStartTime);
                    if (this.pushToTalkTimer)
                        clearTimeout(this.pushToTalkTimer);
                    if (!this.call.isMuted)
                        this.call.mute();
                    break;
                default:
                    return;
            }

            try {
                alreadyConnected === false ? this.pushToTalkConnecting = true: '';
                action == 'start' ? this.call.unmute() : null;
                let url  = `/comms/pushToTalk?action=${action}&jobId=${this.job.id}&timeSpoken=${this.totalTimeSpoken}&speakingStartTime=${this.speakingStartTime}&speakingEndTime=${this.speakingEndTime}&roipName=${this.activeChannelRoIP}`;
                const resp = await axios.get(url)
                .then(_resp => {
                    alreadyConnected === false ? this.pushToTalkConnecting = false:''
                    if(action === 'end'){
                        this.speakingStartTime = null;
                        this.speakingEndTime = null;
                        this.totalTimeSpoken = 0;
                    }
                    return _resp;
                });

                if (action == 'start') {
                    this.pushToTalkInProgress = true;
                    this.call.isMuted ? this.call.unmute() : null;
                    this.pushToTalkTimer = setTimeout(() => this.checkForButtonPressed(), resp?.data?.timeout || 10000);
                } else {
                    !this.call.isMuted ? this.call.mute() : null;
                    this.pushToTalkInProgress = false;
                }
            } catch (err) {
                this.pushToTalkInProgress = false;
                this.pushToTalkConnecting = false;
                if (this.pushToTalkTimer){
                    clearTimeout(this.pushToTalkTimer);
                }
                toast({
                    title: 'Failed to push to talk',
                    body: 'Error communicating with server',
                    variant: 'warning'
                });
            }
        },
        checkForButtonPressed(){
            if(this.pushToTalkInProgress){
                //if button still pressed, keep going
                this.pushToTalk('start',true);
                this.pushToTalkTimer = setTimeout(() => this.checkForButtonPressed(), 10000);
            }else{
                //if button not pressed, end push to talk
                this.pushToTalk('end');
            }
        },

        toggleMute() {
            this.callIsMuted = !this.callIsMuted;

            if (this.callIsMuted) {
                this.call.muteIncomingAudio();
            } else {
                this.call.unmuteIncomingAudio();
            }
        },

        async getToken() {
            try {
                let resp;
                if (this.apiKey) {
                    resp = await axios.get(`/comms/getRoomToken?apiKey=${this.apiKey}`);
                    this.roomId = resp?.data?.roomId;
                } else {
                    resp = await axios.get('/comms/getUserToken');
                }

                this.token = resp?.data?.token;
                this.communicationUserId = resp?.data?.user?.communicationUserId;
            } catch (err) {
                this.loading = false;
                 toast({
                    title: 'Failed to join call',
                    body: 'Error fetching token: '+err.toString(),
                    variant: 'danger'
                });
            }
        },

        async requestRoomAccess() {
            if(this.featureFlagEnabled){
                try {
                    const resp = await axios.get(`/comms/requestRoomAccess?jobId=${this.job.id}&roipName=${this.activeChannelRoIP}`);
                    if (!isFalsy(resp?.data?.roomId) && !isFalsy(resp?.data?.sessionId)) {
                        this.roomId = resp.data.roomId;
                        this.sessionId = resp.data.sessionId;
                    } else {
                        this.loading = false;
                        toast({
                            title: 'Room access request denied',
                            variant: 'danger'
                        });
                    }
                } catch (err) {
                    this.loading = false;
                    toast({
                        title: 'Failed to join call',
                        body: 'Error requesting room access: '+err.toString(),
                        variant: 'danger'
                    });
                }
            }

        },

        onKeyPress(e) {
            // Control + space (in either order) will trigger push to talk, avoid calling pushToTalk when already active
            if (e.key == 'Control') {
                this.ctrlPressed = true;

                if (!this.pushToTalkInProgress && this.pPressed)
                    this.pushToTalk('start');
            } else if (e.key == ' ') {
                this.pPressed = true;

                if (!this.pushToTalkInProgress && this.ctrlPressed)
                    this.pushToTalk('start');
            }
        },
        onKeyPressUp(e) {
            if (e.key == 'Control') {
                this.ctrlPressed = false;
                if (this.pushToTalkInProgress)
                    this.pushToTalk('end');
            } else if (e.key == ' ') {
                this.pPressed = false;
                if (this.pushToTalkInProgress)
                    this.pushToTalk('end');
            }
        },

        openPositionModal() {
            this.modalIsOpen = false;
            this.$refs.CommsPositionModal.open(this.audioOutputId).then((selectedData) => {
                if (!isFalsy(selectedData)){
                    this.audioDeviceChanged(selectedData.audioDevice);
                }
                this.modalIsOpen = true;
            });
        },

        fetchUsers() {
            if(this.activeChannelRoIP)
            {
                return axios.get(`/comms/listUsers?jobId=${this.job.id}&roipName=${this.activeChannelRoIP}`).then(_res => {
                    // Set regardless of size of the list, as long as the data is there
                    if (_res?.data && 'users' in _res.data)
                        this.users = _res.data.users.sort((a, b) => {return a.first_name.localeCompare(b.first_name)});
                        let presenter = this.users.find(user => user.role === 'Presenter');
                        if(presenter){
                            this.roipDeviceId = presenter.id;
                        }
                })
            }

        },
        fetchLogs(){
            return axios.get(`/comms/getLogs?jobNumber=${this.job.jobNumber}&roipName=${this.activeChannelRoIP}`).then(_res => {
                if (_res?.data)
                    this.logs = _res.data;
            })

        },
        toggleSize(isMinimized) {
            const jobId = this.jobNumber;
            const userId = this.user.id;
            const localStorageKey = `commsWidgetData-${jobId}-${userId}`;
            const storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            storedData.isMinimized = isMinimized;
            localStorage.setItem(localStorageKey, JSON.stringify(storedData));
        },
        handleSignalRMessage(message){
            if(message.data.roomId === this.roomId){
                if(message.subCategory == 'talkStart'){
                let user = this.users.find(user => user.id === message.data.userId);
                    message.data.userName = user.first_name + ' ' + user.last_name;
                    if(!this.activeSpeakerUserId.includes(message.data.userId)){
                        this.activeSpeakerUserId.push(message.data.userId);
                    }
                    if(this.activeSpeakerUserId.includes(this.roipDeviceId)){
                        this.activeSpeakerUserId = this.activeSpeakerUserId.filter(id => id !== this.roipDeviceId);
                    }
                    //add message.data.userName to active speakers
                    if(!this.roipActiveSpeakers.includes(message.data.userName)){
                        this.roipActiveSpeakers.push(message.data.userName);
                    }
                    //fitler out Radio from active speakers
                    if(this.roipActiveSpeakers.includes('Radio')){
                        this.roipActiveSpeakers = this.roipActiveSpeakers.filter(name => name !== 'Radio');
                    }
                    this.connected ? eventBus.$emit('roipActiveSpeaking',{'pad': this.job.location,'users':this.roipActiveSpeakers}) : '';
                }
                if(message.subCategory == 'talkEnd'){
                    if(this.activeSpeakerUserId.includes(message.data.userId)){
                        this.activeSpeakerUserId = this.activeSpeakerUserId.filter(id => id !== message.data.userId);
                    }
                    let user = this.users.find(user => user.id === message.data.userId);
                    let userName = user.first_name + ' ' + user.last_name;
                    if(this.roipActiveSpeakers.includes(userName)){
                        this.roipActiveSpeakers = this.roipActiveSpeakers.filter(name => name !== userName);
                    }
                }
            }
        },
        async audioDeviceChanged(device){
            const jobId = this.jobNumber;
            const userId = this.user.id;
            const localStorageKey = `commsWidgetData-${jobId}-${userId}`;
            const storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            storedData.audioOutputId = device;
            localStorage.setItem(localStorageKey, JSON.stringify(storedData));
            if(this.deviceManager){
                const speakers = await this.deviceManager.getSpeakers();
                const speaker = speakers.find(speaker => speaker.id === 'speaker:'+this.audioOutputId);
                if(speaker){
                        await this.deviceManager.selectSpeaker(speaker);

                }
            }
            this.audioOutputId = device;
            await this.setSinkId();

        },
       async setSinkId(){
            if(this.audioOutputId !== null){
            //check if audio output Id is present is list of audio output devices
            navigator.mediaDevices.getUserMedia({audio: true, video: false})
                .then(async (s) => {
                    const audioOutputDevices = await navigator.mediaDevices.enumerateDevices();
                    if(!audioOutputDevices.find(device => device.deviceId === this.audioOutputId)){
                        this.audioOutputId = null;
                    }
                    if (this.audioOutputId !== null) {
                        const audioElements = document.querySelectorAll('audio');
                        if (navigator.mediaDevices.selectAudioOutput) {
                            await navigator.mediaDevices.selectAudioOutput({deviceId: this.audioOutputId})
                            .then((device) => {})
                            .catch((err) => {
                            console.error(`${err.name}: ${err.message}`);
                            });
                        }
                        audioElements.forEach(async (audio) => {
                            try {
                                await audio.setSinkId(this.audioOutputId);
                            } catch (e) {
                                console.error('Error setting audio output device: ', e);
                            }
                        });
                    }
                });
            }
        },
        async fetchActiveChannels(){
            return await axios.get(`/comms/getActiveChannels?jobNumber=${this.jobNumber}`).then(_res => {
                if (_res?.data)
                    this.activeChannels = _res.data;
                    if(this.activeChannels.length > 0 && !this.activeChannelRoIP){
                        let storedRoIP = this.getActiveChannelForRoIPFromLocalStorage();
                        if(storedRoIP){
                            this.activeChannelRoIP = storedRoIP;
                        }else{
                            this.activeChannelRoIP = this.activeChannels[0].name;
                            this.setActiveChannelForRoIP(this.activeChannelRoIP);
                        }

                    }
            })
        },
        setActiveChannelForRoIP(roipName){
            this.activeChannelRoIP = roipName;
            let localStorageKey = `commsWidgetData-${this.jobNumber}-${this.user.id}`;
            let storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            storedData.activeChannelRoIP = roipName;
            localStorage.setItem(localStorageKey, JSON.stringify(storedData));
        },
        getActiveChannelForRoIPFromLocalStorage(){
            let localStorageKey = `commsWidgetData-${this.jobNumber}-${this.user.id}`;
            let storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            return storedData.activeChannelRoIP || null;
        },
        endCallFromButton(){
            let jobId = this.jobNumber;
            let userId = this.user.id;
            let localStorageKey = `commsWidgetData-${jobId}-${userId}`;
            let storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            storedData.userEndedTheCall = true;
            localStorage.setItem(localStorageKey, JSON.stringify(storedData));
            this.endCall();
        },
        checkIfUserEndedTheCall(){
            let jobId = this.jobNumber;
            let userId = this.user.id;
            let localStorageKey = `commsWidgetData-${jobId}-${userId}`;
            let storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            return storedData.userEndedTheCall || false;
        },
        setUserEndedCallToFalse(){
            let jobId = this.jobNumber;
            let userId = this.user.id;
            let localStorageKey = `commsWidgetData-${jobId}-${userId}`;
            let storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');
            storedData.userEndedTheCall = false;
            localStorage.setItem(localStorageKey, JSON.stringify(storedData));
        }
    },

    async mounted() {
        //check for microphone permissions
        try{
            navigator.permissions.query({name: 'microphone'}).then(function(result) {
                if (result.state == 'denied') {
                    toast({
                        title: 'Microphone permissions denied',
                        body: 'Please allow microphone permissions to use ROIP',
                        variant: 'danger'
                    });
                }

                result.onchange = function() {
                    if (result.state == 'denied') {
                        //if denied, show toast
                        toast({
                            title: 'Microphone permissions denied',
                            body: 'Please allow microphone permissions to use ROIP',
                            variant: 'danger'
                        });
                    }
                }
            })
            .catch(err => {
                console.log('Microphone permissions not supported');
            });
        }catch(err){
            console.log('Microphone permissions not supported');
        }
        this.setSinkId();
        //check the user role for multichannel support
        if(this.user.roles.some(role => role.name === 'admin')){
            this.isAdmin = true;
        }
        // Need to convert the job number into the job id for comms apis
        this.fetchJobDetails(this.jobNumber).then(res => {
            this.fetchActiveChannels().then(_res => {
                this.fetchLogs().then(resp => {
                    this.fetchUsers().then(_res => {

                    // Due to vues hot reload constantly triggering this and not needed to talk to eachother when developing, only auto connect on any server other than localhost
                    // We do not auto join multiple times to avoid echo, if the is in the room they can manually join if they really want to
                     if (this.featureFlagEnabled && !this.checkIfUserEndedTheCall()){
                        this.init();
                     }

                    });
                });
            });
        });


        const self = this;
        window.onbeforeunload = () => {
            if (self.connected)
                self.endCall();
        }
        eventBus.$on('new-roip-message',(message) => {
           this.handleSignalRMessage(message);
        });
    },
    beforeDestroyed() {
        if (this.connected)
            this.endCall();
    },

    watch: {
        jobNumber() {
            if (!isFalsy(this.jobNumber)) {
                // When the job number changes, we need to hang up and join the new room for the current job number
                this.loading = true;

                Promise.all([ this.fetchJobDetails(this.jobNumber), this.endCall() ]).then(res => {
                    this.connectToRoom();
                });
            } else if (this.connected) {
                // If we are on a page not specific to any single job, leave the existing job
                this.endCall();
            }
        },
        //watch roipActiveSpeakers and emit event
        roipActiveSpeakers(){
            if(this.connected){
                if(this.activeSpeakerUserId.length === 0){
                    eventBus.$emit('roipInactiveSpeaking');
                }else{
                    eventBus.$emit('roipActiveSpeaking',{'pad': this.job.location,'users':this.roipActiveSpeakers});
                }
            }

        },

        modalIsOpen(newStatus) {
            if (this.modalIsOpen) {
                this.usersInterval = setInterval(() => {
                    this.fetchUsers();
                }, 10000)
            } else if (!isFalsy(this.usersInterval)) {
                clearInterval(this.usersInterval);
                this.usersInterval = null;
            }
            const jobId = this.jobNumber;
            const userId = this.user.id;
            const localStorageKey = `commsWidgetData-${jobId}-${userId}`;
            const storedData = JSON.parse(localStorage.getItem(localStorageKey) || '{}');

            storedData.commsModalIsOpen = newStatus;
            localStorage.setItem(localStorageKey, JSON.stringify(storedData));
        },
    }
};

</script>

<style>
    #commsWidget.btn {
        padding: 2px 9px !important;
    }
    #comms-control .modal-container {
        z-index: 999999999 !important;
    }
</style>
