<template>
    <div id="wellCol" class="d-flex flex-grow-1 h-100">
        <div v-if="well" :class="'row justify-content-center' + stretch? 'w-100' : ''">
            <div id="header" class="text-center rounded p-1 m-0 py-2" :class="colSize" :style="'height:' + headerHeight">
                <div v-if="well.activity == 'wireline'" class="d-flex flex-column">
                    <h6 v-if="isMultiWireline" class="text-center mt-0">{{wirelineTruckName}}</h6>
                    <wireline-activity :spoolColor="wirelineTruckColor" :style="wellActivityIconSize"></wireline-activity>
                    <h6 style="text-transform: uppercase;" class="text-center mt-0">{{well.activity}}</h6>
                </div>
                <div v-else-if="well.activity == 'frac' && well.cfChangeoverState == 'to'" class="d-flex flex-column">
                    <h6 v-if="isMultiFrac" class="text-center mt-0">{{fracTruckName}}</h6>
                    <continuous-frac-to-activity :color="'#FFFFFF'" :style="wellActivityIconSize"></continuous-frac-to-activity>
                    <h6 style="text-transform: uppercase;" class="text-center mt-0 pt-1">Cont. Frac</h6>
                </div>
                <div v-else-if="well.activity == 'frac'" class="d-flex flex-column">
                    <h6 v-if="isMultiFrac" class="text-center mt-0">{{fracTruckName}}</h6>
                    <frac-activity :color="'#FFFFFF'" :style="wellActivityIconSize"></frac-activity>
                    <h6 style="text-transform: uppercase;" class="text-center mt-0">{{well.activity}}</h6>
                </div>
                <div v-else-if="well.activity == 'maintenance'" class="d-flex flex-column">
                    <maintenance-activity :color="'#FFFFFF'" :style="wellActivityIconSize"></maintenance-activity>
                    <h6 style="text-transform: uppercase;" class="text-center mt-0">{{well.activity}}</h6>
                </div>
                <img v-else class="m-0 pb-2" :class="{ 'black-tint' : !this.$root.darkMode}" v-bind:src="image"  :style="wellActivityCheckSize" >
                <lock-button v-if="showWellLockToggle" 
                    activeTooltipText='Hide Valve Locks' 
                    inactiveTooltipText='Show Valve Locks'
                    localStorageKey='ShowWellLocks'
                    :onValueChanged='(value)=>showWellLocksLocal=value'/>
            </div>
            <div class="text-center p-0 m-0" :style="wellImageSize" :class="colSize">
                <div v-if="well.configuration.components.length" class="h-100">
                    <well 
                        ref="well" 
                        :well="well" 
                        :showWellLocks="showWellLocks || showWellLocksLocal" 
                        :canvasHeight="wellImageSize['max-height']"
                        :useDynamicOrigin="false"
                        :maxStretch="2"
                    ></well>
                </div>
                <div v-else class="png-well-component d-flex justify-content-center pl-2" :ref="imgId">
                    <img v-if="well.configurationId" class="img-fluid" :src="'/get-wellhead-image?config_number='+well.configurationId" 
                        :id="imgId" 
                        :key="imgId" style="max-height:inherit;" 
                        ref="wellConfigImage"
                    >
                    <img v-else :style="{'background-color':color}" :src="wellImage">
                    <valve-component 
                        v-for="(valve, index) in well.valves" 
                        :position="valve?.position?.value" 
                        :unlocked="valve?.unlocked?.value" 
                        :config="well.configurationId"
                        :imgId="imgId" 
                        :key="index" 
                        :index="index" 
                        :showWellLocks="showWellLocks || showWellLocksLocal"
                    ></valve-component>
                </div>
                <div v-if="pressureCrown !== false" style="position:absolute;top:0px;right:0px;">
                    <div class="border rounded p-1">
                        <div>{{pressureCrown}}</div>
                        <div style="font-size:0.7em;">{{pressureUnit}}</div>
                    </div>
                </div>
                <div style="position:absolute;bottom:0px;right:0px;">
                    <div class="border rounded p-1 mb-1">
                        <div>{{pressure}}</div>
                        <div style="font-size:0.7em;">{{pressureUnit}}</div>
                    </div>
                </div>
            </div>
            <div class="d-flex flex-column text-center rounded pr-1 pl-1 m-0 pb-2 text-dark font-weight-bold" :id="displayIdName + index"
                :class="{'border': !well.configurationId, 'border-secondary': !well.configurationId, 'bg-light': !well.configurationId, ...colSize}"
                :style="{'background-color': well.configurationId ? color : undefined, 'overflow': 'hidden', 'white-space': 'nowrap', 'max-height': getMaxHeight}">
                <h5 :style="{'color':well.configurationId ? getTitleColorForBG(color) : undefined}" class="m-0 pb-2">{{name}}</h5>
                <div class="well-label text-wrap" :style="{'color':well.configurationId ? getTitleColorForBG(color) : undefined}">{{status}}: {{currentStage}}/{{numberOfStages}}</div>
                <div v-if="stageStart" class="well-label text-wrap" :style="{'color':well.configurationId ? getTitleColorForBG(color) : undefined}">Duration: {{duration}}</div>
            </div>
        </div>
    </div>
</template>
<style scoped>
.black-tint {
  filter: brightness(10%)
}
.well-label{
  font-size:0.8em;
  overflow: hidden; 
}
.png-well-component{
    position:relative;
    max-height:inherit;
}

</style>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
import moment from 'moment';

const defaultWellGroupHeightPixels = 405;
const strechdefaultWellGroupHeightPixels = 345;
const defaultWellImgHeightPixels = 216;
const defaultWellActivityCheckSizePixels = 36;
const defaultWellActivityIconSizePixels = 30;

export default {
    inject: ['dashboardData'],
    setWellConfiguration(wells, data) {
        //this method will update the configurationId on each well based on valve positions
        //received via the latestData call.
        //
        // The convention for identifying the valve is {locationType}_{wellNumber}_{valveIndex}. 
        // So, the tag for a valve on a well (locationType==well) on the second well (index 1) 
        // in the first valve position (index 0) would be 'well_1_0'.

        for (let i = 0; i < data.length; i++) {
            let wellIndex = -1;
            let valveIndex = -1;
            let position = -1;
            if(data[i].tagName.startsWith('valvePosition_well_')) {
                const valveIndexString = data[i].tagName.split('valvePosition_well_')[1];
                const valveIndexArray = valveIndexString.split('_');
                    
                //index 0 should be well number, index 1 should be valve number
                if(valveIndexArray.length = 2) {
                    wellIndex = parseInt(valveIndexArray[0]);
                    valveIndex = parseInt(valveIndexArray[1]);
                    position = parseFloat(data[i].dataVal);

                    if(valveIndex >= 0 && wellIndex >= 0 && wells[wellIndex]) {
                        //found a valve position update for a known well
                        if(wells[wellIndex].valves[valveIndex]) {
                            Vue.set(wells[wellIndex].valves[valveIndex], 'position', position);
                        }
                        else {
                            wells[wellIndex].valves[valveIndex] = {
                                position: position
                            };
                        }
                    }
                }
            }
        }

        //HACKED IN LAST MINUTE - TODO: REVIEW THIS
        //tagNames for valve position changed
        //{locationType}_{wellNumber}_{valveIndex} became wellhead_{wellNumber+1}_valve_{valveIndex+1}_position
        //was 0 based index and now is 1 based, which does not match with the database or image files

        for (let i = 0; i < data.length; i++) {
            let wellIndex = -1;
            let valveIndex = -1;
            let position = -1;
            let unlocked = 0;
            
            //TODO: regex probably better suited for this
            // console.log(data[i].tagName);
            if(data[i].tagName.startsWith('wellhead_') && data[i].tagName.endsWith('_position') && data[i].tagName.includes('_valve_')) {
                //chop off wellhead_ from first part, then split on _valve_
                //the array that returns should have wellNumber (1 based index) in first position, valve index in second position
                const choppedTagName = data[i].tagName.substring(9, data[i].tagName.length - 9);
                const valveIndexArray = choppedTagName.split('_valve_');
                    
                //index 0 should be well number (1 based index), index 1 should be valve number (1 based index)
                if(valveIndexArray.length = 2) {
                    wellIndex = parseInt(valveIndexArray[0]) - 1;
                    valveIndex = parseInt(valveIndexArray[1]) - 1;
                    position = parseFloat(data[i].dataVal);

                    if(valveIndex >= 0 && wellIndex >= 0 && wells[wellIndex]) {
                        //found a valve position update for a known well
                        if(wells[wellIndex].valves[valveIndex]) {
                            Vue.set(wells[wellIndex].valves[valveIndex], 'position', { value: position });
                        }
                        else {
                            wells[wellIndex].valves[valveIndex] = {
                                position: { value: position }
                            };
                        }
                    }
                }
            }

            if(data[i].tagName.startsWith('wellhead_') && data[i].tagName.includes('_valvePosition_')) {
                //chop off wellhead_ from first part, then split on _valvePosition_
                //the array that returns should have wellNumber (1 based index) in first position, valve index in second position
                const choppedTagName = data[i].tagName.substring(9);
                const valveIndexArray = choppedTagName.split('_valvePosition_');
                    
                //index 0 should be well number (1 based index), index 1 should be valve number (1 based index)
                if(valveIndexArray.length = 2) {
                    wellIndex = parseInt(valveIndexArray[0]) - 1;
                    valveIndex = parseInt(valveIndexArray[1]) - 1;
                    position = parseFloat(data[i].dataVal);

                    if(valveIndex >= 0 && wellIndex >= 0 && wells[wellIndex]) {
                        //found a valve position update for a known well
                        if(wells[wellIndex].valves[valveIndex]) {
                            Vue.set(wells[wellIndex].valves[valveIndex], 'position', { value: position });
                        }
                        else {
                            wells[wellIndex].valves[valveIndex] = {
                                position: { value: position }
                            };
                        }
                    }
                }
            }

            //Some jobs report handlePosition instead of valvePosition
            if(data[i].tagName.startsWith('wellhead_') && data[i].tagName.includes('_handlePosition_')) {
                //chop off wellhead_ from first part, then split on _handlePosition_
                //the array that returns should have wellNumber (1 based index) in first position, valve index in second position
                const choppedTagName = data[i].tagName.substring(9);
                const valveIndexArray = choppedTagName.split('_handlePosition_');
                    
                //index 0 should be well number (1 based index), index 1 should be valve number (1 based index)
                if(valveIndexArray.length = 2) {
                    wellIndex = parseInt(valveIndexArray[0]) - 1;
                    valveIndex = parseInt(valveIndexArray[1]) - 1;
                    position = parseFloat(data[i].dataVal);

                    if(valveIndex >= 0 && wellIndex >= 0 && wells[wellIndex]) {
                        //found a valve position update for a known well
                        if(wells[wellIndex].valves[valveIndex]) {
                            Vue.set(wells[wellIndex].valves[valveIndex], 'position', { value: position });
                        }
                        else {
                            wells[wellIndex].valves[valveIndex] = {
                                position: { value: position }
                            };
                        }
                    }
                }
            }

            //Respond to the valveUnlocked tag
            if(data[i].tagName.startsWith('wellhead_') && data[i].tagName.includes('_valveUnlocked_')) {
                //chop off wellhead_ from first part, then split on _valveUnlocked_
                //the array that returns should have wellNumber (1 based index) in first position, valve index in second position
                const choppedTagName = data[i].tagName.substring(9);
                const valveIndexArray = choppedTagName.split('_valveUnlocked_');
                    
                //index 0 should be well number (1 based index), index 1 should be valve number (1 based index)
                if(valveIndexArray.length = 2) {
                    wellIndex = parseInt(valveIndexArray[0]) - 1;
                    valveIndex = parseInt(valveIndexArray[1]) - 1;

                    //Check if coming in as int
                    if(data[i].dataVal == 0 || data[i].dataVal == 1){
                        unlocked = data[i].dataVal;
                    }
                    //Check if coming in as string
                    else if(typeof data[i].dataVal === "string" || data[i].dataVal instanceof String){
                        unlocked = ("true" == data[i].dataVal.toLowerCase()) ? 1 : 0;
                    }
                    //Otherwise it's coming in as a boolean
                    else{
                        unlocked = data[i].dataVal == true ? 1 : 0;
                    }

                    if(valveIndex >= 0 && wellIndex >= 0 && wells[wellIndex] && wells[wellIndex].valves) {
                        //found a valve unlock update for a known well
                        if(wells[wellIndex].valves[valveIndex]) {
                            Vue.set(wells[wellIndex].valves[valveIndex], 'unlocked', {value: unlocked});
                        }
                        else {
                            wells[wellIndex].valves[valveIndex] = {
                                unlocked: {value: unlocked}
                            };
                        }
                    }
                }
            }
        }
    },
    data() {
        return {
            message: 'Well component',
            start: '2019-01-01 00:00:00.000',
            noDataPlaceholder: '--',
            pressure: '--',
            pressureCrown: '--',
            pressureUnit:'psi',
            duration: '--',
            imgId: 'imageId',
            showWellLocksLocal: false,
            headerHeight: '20%',
            displayIdName: 'infoDisplayBox-',
            originalWellComponentHeight: 0,
            pressureMetadataTypeId:'46C0B69A-3A7F-EE11-8179-000D3AE994BC',
        };
    },
    props: {
        name: String,
        color: String,
        activity: String,
        currentStage: Number,
        numberOfStages: Number,
        status: String,
        pressureWell: [String, Number],
        totalDuration: Number,
        casingPressures: Array,
        crownPressures: [Array, Boolean],
        index: Number,
        stageStart: Object,
        job: Object,
        well: Object,
        jobEnd: String,
        isMultiWireline: Boolean,
        isMultiFrac: Boolean,
        wirelineSystems: Array,
        fracSystems: Array,
        showWellLocks: Boolean,
        showWellLockToggle: Boolean,
        lockImageSize: Boolean,
        stretch: Boolean
    },
    mounted() {
        this.start = this.stageStart;
        setInterval(this.calcDuration, 1000);
        setInterval(this.updatePressure, 1000);
        setInterval(this.updatePressureCrown, 1000);
        let resizeDisplayBox = new ResizeObserver(this.emitResizeEvent);
        let infoDisplayBox = document.getElementById(this.displayIdName + this.index);
        resizeDisplayBox.observe(infoDisplayBox);
        this.originalWellComponentHeight = this.$parent.$parent.height;
        this.fetchPressureUnit();
    },
    methods: {
        emitResizeEvent() {
            let currentDisplayHeight = document.getElementById(this.displayIdName + this.index).clientHeight;
            if (currentDisplayHeight && (this.status !== 'Not updated')) {
                this.$emit('resize', currentDisplayHeight, this.index, this.displayIdName);
            }
        },
        getDimensions(id) {
            let element = document.getElementById(id);
            return element ? element.clientHeight : 0;
        },
        calcDuration: function () {
            if(this.jobEnd) {
                this.duration = this.noDataPlaceholder;
                return;
            }

            const now = moment.utc();
            const jobStartUtc = moment(this.stageStart);

            const duration = now.diff(jobStartUtc);

            if(!duration) {
                this.duration = this.noDataPlaceholder;
            }

            // get hours/minutes/seconds
            let seconds = Math.floor((duration / 1000) % 60);
            let minutes = Math.floor((duration / (1000 * 60)) % 60);
            const hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

            // add preceding 0's to minutes and seconds
            minutes = (minutes < 10) ? '0' + minutes : minutes;
            seconds = (seconds < 10) ? '0' + seconds : seconds;

            this.duration = `${hours}:${minutes}:${seconds}`;

            if (this.activity == 'wireline') {
                this.$parent.$emit('wireline-duration', this.duration);
            }
        },
        updatePressure: function () {
            this.pressure = this.casingPressures[this.index] ?? this.noDataPlaceholder;
        },
        updatePressureCrown: function () {
            if(this.crownPressures == false || this.crownPressures == undefined ) {
                this.pressureCrown = false;
            }else {
                let hasCrownPressureTuple = (this.crownPressures[this.index] !== undefined) && (this.crownPressures[this.index].length == 2);
                if(hasCrownPressureTuple && this.crownPressures[this.index][1]) {
                    if(this.crownPressures[this.index][0]) {
                        this.pressureCrown = this.crownPressures[this.index][0];
                    }
                    else {
                        this.pressureCrown = this.noDataPlaceholder
                    }
                }
                else {
                    this.pressureCrown = false;
                }
            }
        },
        getTitleColorForBG: function(bgColor) {
            return GlobalFunctions.getTitleColorForBG(bgColor);
        },
        fetchPressureUnit: function(){
            let object_id = this.dashboardData.customer_id;
            let url = '/metadata/object/' + object_id +`?type_id=${this.pressureMetadataTypeId}`;
            $.get(url, { '_token': GlobalFunctions.getCSRFToken() }).then((res) => {
                if (res && "unit" in res){
                    this.pressureUnit = res.unit
                }
            }).catch((jqXHR, textStatus, errorThrown) => {
                if (jqXHR.status != 404){
                    console.error('An error occurred fetching pressure unit:', errorThrown);
                }
             });
        }
    },
    computed: {
        colSize: function() {
            if(this.stretch) {
                return { 'col-12': true };
            } else {
                return { 'col-11': true };
            }
        },
        getMaxHeight: function() {
            // gets the max possible height for the display box
            // decreases text size if the box is too small
            let displayElement = document.getElementById(this.displayIdName + this.index);
            let displayHeight = displayElement ? displayElement.clientHeight : 0;
            let wellColHeight = this.getDimensions('wellCol');
            let headerHeight = this.getDimensions('header');
            let pressureCrownItemHeight = this.pressureCrown && (this.pressureCrown !== '--') ? this.getDimensions('pressureCrownItem') : 0;
            let wellImageHeight = this.wellImageSize['max-height'].split("px")[0];
            let pressureItemHeight = this.getDimensions('pressureItem');
            let maxHeight = wellColHeight - headerHeight - pressureCrownItemHeight - wellImageHeight - pressureItemHeight;

            if (this.originalWellComponentHeight !== this.$parent.$parent.height) {
                // when the well group is being resized, remove max height
                this.originalWellComponentHeight = this.$parent.$parent.height;
                return null;
            }
            if (displayElement && displayHeight > maxHeight && this.status==='Stage Complete') {
                displayElement.style.fontSize = '0.9em';
            }
            return maxHeight + 'px';
        },
        wellActivityIconSize: function () {
            const sizingCSS = {};
            if (this.lockImageSize) {
                sizingCSS['max-height'] = defaultWellActivityIconSizePixels + 'px';
                sizingCSS['min-height'] = defaultWellActivityIconSizePixels + 'px';
            }
            else {
                sizingCSS['height'] = defaultWellActivityIconSizePixels * this.imageGrowthFactor + 'px';
            }
            return sizingCSS;
        },
        wellActivityCheckSize: function () {
            const sizingCSS = {};
            if (this.lockImageSize) {
                sizingCSS['max-height'] = defaultWellActivityCheckSizePixels + 'px';
                sizingCSS['min-height'] = defaultWellActivityCheckSizePixels + 'px';
            }
            else {
                sizingCSS['height'] = defaultWellActivityCheckSizePixels * this.imageGrowthFactor + 'px';
            }
            return sizingCSS;
        },
        wellImageSize: function () {
            const sizingCSS = {};
            if (this.lockImageSize) {
                sizingCSS['max-height'] = Math.floor(defaultWellImgHeightPixels) + 'px';
                sizingCSS['min-height'] = Math.floor(defaultWellImgHeightPixels) + 'px';
            }
            else {
                sizingCSS['max-height'] = Math.floor((defaultWellImgHeightPixels * this.imageGrowthFactor)) + 'px';
                sizingCSS['min-height'] = Math.floor((defaultWellImgHeightPixels * this.imageGrowthFactor)) + 'px';
            }
            return sizingCSS;
        },
        imageGrowthFactor: function () {
            const divideFactor = this.stretch? strechdefaultWellGroupHeightPixels : defaultWellGroupHeightPixels;
            return this.$parent.$parent.height / divideFactor;
        },
        image: function () {
            let i = 'images/check_transparent.png';
            const a = this.activity;
            switch (a) {
            case 'frac':
                i = '../images/frac.png';
                break;
            case 'wireline':
                i = '../images/wireline.png';
                break;
            case 'maintenance':
                i = '../images/maintenance.png';
                break;
            default:
                i = '../images/check_transparent.png';
                break;
            }

            return i;
        },
        wellImage() {
            	if(this.$root.darkMode)
            {return '../images/WellheadTemplate_darker.png';}
            else
            {return '../images/WellheadTemplate_white_theme.png';}
        },
        wirelineTruckName() {
            if(this.isMultiWireline && this.well.activityData && this.wirelineSystems && this.wirelineSystems.length > 0) {
                const wirelineSystem = this.wirelineSystems.find(system => system.number == this.well.activityData.service_id);
                if(wirelineSystem) {
                    return wirelineSystem.name;
                }
            }
            return 'Unknown';
        },
        fracTruckName(){
            if(this.isMultiFrac && this.well.activityData && this.fracSystems && this.fracSystems.length > 0) {
                const fracSystem = this.fracSystems.find(system => system.number == this.well.activityData.service_id);
                if(fracSystem) {
                    return fracSystem.name;
                }
            }
            return 'Unknown';
        },
        wirelineTruckColor() {
            if(this.isMultiWireline  && this.wirelineSystems && this.wirelineSystems.length > 0) {
                const wirelineSystem = this.wirelineSystems.find(system => system.number == this.well.activityData.service_id);
                if(wirelineSystem) {
                    try {
                        const spoolData = JSON.parse(wirelineSystem.spool);
                        if(spoolData) {
                            return spoolData.color;
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                }
            }

            return '#FFFFFF';
        },
        //NOTE : Currently frac truck colours are not present in dynamics so this code won't work yet.
        fracTruckColor(){
            if(this.isMultiFrac  && this.fracSystems && this.fracSystems.length > 0) {
                const fracSystem = this.fracSystems.find(system => system.number == this.well.activityData.service_id);
                if(fracSystem) {
                    try {
                        const spoolData = JSON.parse(fracSystem.spool);
                        if(spoolData) {
                            return spoolData.color;
                        }
                    } catch (ex) {
                        console.error(ex);
                    }
                }
            }
            return '#FFFFFF';
        },
        startTimestamp: function () {
            return new Date(this.stageStart);
        }
    }
};
</script>
