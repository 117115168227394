<template>
    <div>
        <div class="form-group pt-1 col-12">
            <label >Select Companies</label>
            <multi-select-checkbox
                    id="dashboardPermissions"
                    :selectedOptions="selectedCompanies"
                    valueKey="name"
                    :options="companies"
                    outputKey="id"
                    placeholder="Select Company"
                    selectByKey= "id"
                    :isDark="true"
                    label="Company"
                    @updateSelectedOptions="processMultiselectChange($event)"
                >
                </multi-select-checkbox>
        </div>
        <div class="row">
            <div v-for="(company) in selectedCompanies" :key="company.id" style="width:100%;margin-left:20px;margin-right:20px;margin-bottom:20px;">
                <div  class="customer-api-permissions">
                <UserPermissionsCompany
                    ref="UserPermissionsCompany"
                    :user="user"
                    :customer="company"
                    :isIwsAdmin="isIwsUser && role == 'admin'"
                    :key="company.id"
                />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="form-group float-right col-12" style="text-align:end;" v-if="selectedCompanies.length > 0">
                <iws-button text="Submit" @click="submitForm" />
            </div>
        </div>
    </div>
</template>
<script>
import eventBus from '../eventBus';
import UserPermissionsCompany from './UserPermissionsCompany';

export default{
    components:{
        UserPermissionsCompany,
    },
    props:{
        companies: {
            type: [Array, String],
            required: true,
        },
        user: {
            type: [Object],
        },
        role: {
            type: String,
            required: true,
        },
        isIwsUser: {
            type: [Boolean, Number],
            required: true,
        },
        customersPermission: {
            type: [Array, String],
            required: true,
        },
    },
    data(){
        return{
            selectedCompanies: [],
        }
    },
    mounted(){
        if(this.customersPermission.length > 0){
            this.selectedCompanies = this.customersPermission;
        }
    },
    methods:{
        processMultiselectChange(selectedCompanies){
            this.selectedCompanies = selectedCompanies;
        },
        submitForm(){
            eventBus.$emit('submitPermissions');
        }
    }
}

</script>
