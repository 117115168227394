<!--
    * Component Description
    Pad name and stage count
-->
<template>

<div class="flex-shrink">
    <select-job-component
        :companyJobs="activeCompanyJobs"
        :jobNumber="jobNumber"
        @jobSelected="(job, index) => onJobChanged(job, index)"
        :newUi="newUi"
        :iwsUser="iwsUser"
        :jobSystem="jobSystem"
    />

    <h4 style="display: inline;">
        <span v-if='percentCompleted > 0' class="badge badge-secondary">
            Stages Completed: {{totalCompletedStages}}/{{numberOfStagesAllWells}} ({{ percentCompleted }}%)
        </span>
    </h4>
</div>
</template>

<script>
export default {
    props: {
        jobNumber: {
            type: String,
            required: true
        },
        totalCompletedStages: {
            type: [Array, Object, Number]
        },
        activeCompanyJobs: {
            type: Array,
            required: true
        },
        wells: {
            type: Array,
            required: true
        },
        route: {
            type: String,
            required: true
        },
        routeType: {
            type: String
        },

        newUi: {
            type: Boolean,
            default: false
        },
        iwsUser: {
            type: Boolean, 
            required: false, 
            default: false
        },
        jobSystem: {
            type: String, 
            required: false, 
            default: null
        }
    },
    data() {
        return {
            countCompletedStages: 0,
            completedPercent: 0,
            currentStageUpdated: 0,
        };
    },
    computed: {
        numberOfStagesAllWells: function () {
            return this.wells.reduce((a, b) => a + b.numberOfStages, 0);
        },
         percentCompleted: function () {
            return (this.totalCompletedStages / this.numberOfStagesAllWells * 100).toFixed(1);
        },
    },
    methods: {
        initSelected: function() {
            const result = this.activeCompanyJobs.find((target) => {
                return target['jobNumber'] == this.jobNumber;
            });
            return result;
        },
        onJobChanged(jobNumber) {
            switch (this.routeType) {
                case 'perf-report':
                    window.location.href = `${this.route}/${jobNumber}/history/0/1/5`;
                    break;
                default:
                    window.location.href = `${this.route}/${jobNumber}`;
                    break;
            }
        }
    }
};

</script>
