var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "error-modal",
    attrs: {
      "hide-footer": "",
      "no-close-on-backdrop": "",
      size: "xl",
      "header-class": "border-bottom-0 justify-content-center  p-0",
      "body-class": "pb-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "border-3 modal-bg",
    },
    on: {
      hidden: function ($event) {
        return _vm.$emit("onDismissSignalRErrorModal")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "col w-100" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "font-large w-100 text-center" }, [
                  _vm._v(" Your connection has timed out. "),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              { staticClass: "d-flex align-items-center pt-2 pb-4" },
              [
                _c("b-spinner", {
                  attrs: { variant: "primary", label: "Spinning" },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "px-2" }, [
                  _vm._v("Please wait - Attempting to reestablish connection"),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.isVisible,
      callback: function ($$v) {
        _vm.isVisible = $$v
      },
      expression: "isVisible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }