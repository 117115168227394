<template>
    <b-modal 
        v-model="modalVisible"
        ref="resolve-modal" 
        @hidden="$emit('onDismiss')"
        no-close-on-backdrop
        size="xl"
        header-class="border-bottom-0 justify-content-center  p-0"
        body-class="pb-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg">
        <template #modal-header>
            <div class="col w-100">
                <div class="modal-header">
                    <div class="font-large w-100 text-center"> Template settings </div>
                </div>
            </div>
        </template>
        <template #default>
            <div class="row justify-content-center align-items-center mb-2">
                <div class="col-3 list-label text-right">Selected Template : </div>
                <div class="col-7">
                <select class="form-control" ref="templateSelect" v-model="selectedTemplateId" @change="onLoad($event.target.value)">
                    <option :value="null">--Select a template--</option>
                    <option
                        v-for="(target, index) in templateOptions"
                        :value="target.id"
                        :key="index"
                        :selected="target.id == templateData.id ? true : false">
                            {{target.name}}
                    </option>
                </select>
                </div>
                <div class="col-2 pl-0">
                        <i v-tooltip:top="'Create a new teamplate'" class="fas fa-plus-circle show-clicker-finger mr-2" @click="createNewTemplate()"></i>
                        <i v-tooltip:top="'Copy this template'" class="fas fa-copy show-clicker-finger mr-2" :class="{'icon-disabled':!selectedTemplateId}" @click="copyTemplate()"></i>
                        <i v-tooltip:top="'Delete this template'" 
                            class="fas fa-trash show-clicker-finger" 
                            :class="[{'icon-disabled':isCompanyTemplates || templateData.isUserDefault || !selectedTemplateId}]" 
                            @click="deleteTemplate()"></i>
                </div>
            </div>
            <hr>
            <div v-if="!templateLoading && !isSaving" class=" mb-2">
                <div class="row justify-content-center align-items-center">
                    <div class="col-3 list-label text-right">Template Name : </div>
                    <div class="col-7">
                        <div class="d-flex flex-column">
                            <div class="w-100">
                                <input
                                    placeholder="Enter template name here"
                                    ref="templateName"
                                    class="form-control"
                                    type="text"
                                    v-model="templateName"
                                    :readonly="isCompanyTemplates && selectedTemplateId!=null"
                                    :disabled="templateData.isUserDefault"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="col-2"><!--Space formatting div-->
                    </div>
                </div>
                <div v-show="templateData.isUserDefault" class="row">
                    <div class="col-3"></div><!--Space formatting div-->
                    <div class="col-9">
                        <p class="redText">
                            Changes to default templates cannot be saved
                            <br> To preserve any changes, please make a copy of this template
                        </p>
                    </div>
                </div>
            </div>
            <div v-else class="pt-4 pb-5">
                <div class="spinner-border spinner-border-sm" role="status" style="color:black; position: absolute; z-index: 100; left: 50%; margin-left: -5px;">
                    <span class="sr-only mb-5">Loading...</span>
                </div>
            </div>
            <div v-if="!templateLoading && !isSaving && processedTemplateData" class="row justify-content-center align-items-start mt-3 mb-2">
                <div class="col-3 list-label text-right">Series Configurations :</div>
                <div class="col-9">
                    <b-table v-if="processedTemplateData.length != 0" striped hover :items="processedTemplateData"></b-table>
                    <div v-else>Please add a data series to view configurations.</div>
                </div>
            </div>
        </template>
        <template #modal-footer>
            <div class="d-flex w-100  justify-content-center align-items-center px-2 pb-2">
                <div class="flex-grow-1"></div>
                <button type="button" class="btn btn-secondary grey-button" @click="()=>{ $emit('onDismiss') }" >Close</button>
                <div class="px-2"></div>
                <button v-if="!templateLoading && !isSaving" type="button" class="btn btn-success" @click.prevent="saveTemplate" :disabled="isSaveDisabled">
                    Save
                </button>
                <button v-if="!templateLoading && isSaving" type="button" class="btn btn-success">
                    Saving...
                </button>
                <button v-if="templateLoading && !isSaving" type="button" class="btn btn-success">
                    Loading...
                </button>
            </div>
        </template>
    </b-modal>
</template>
<style scoped>
    .form-control:disabled {
        background-color: lightgray;
    }
    .icon-disabled {
        opacity: 0.25;
        pointer-events: none; 
    }
    .redText {
        color:red;
    }
</style>
<script>
import moment from 'moment';
import GlobalFunctions from '../GlobalFunctions.js';

export default {
    //TODO : This can be made generic and should be. Naming can just be analysistemplatesettingsmodal
    //And the can leverage the type passed in via prop
    data() {
        return {
            dropDownValue: false,
            isSaving: false,
            returnedDataset: null,
            templateOptions: [],
            rerenderFlipper: true,
            templateName: null,
            templateLoading: false,
            processedTemplateData: null,
            selectedTemplateId: null
        };
    },
    props: {
        modalVisible: {
            type: Boolean,
            default: false
        },
        templateData: {
            type: Object,
            default: null
        },
        chartType: {
            type: [String, Symbol],
            required: true
        },
        targetTemplateId: {
            type: Number,
            required: false
        },
        isAdmin: {
            type: [Boolean, Number]
        },
        dashboardInfo: {
            type: Object
        },
        userid: {
            type: [String]
        }
    },
    mounted() {
        this.fetchAvailableTemplates();
        if(this.templateData?.name) {
            this.templateName = this.templateData.name;
        }
        if(this.targetTemplateId) {
            this.selectedTemplateId = this.targetTemplateId;
            this.onLoad(this.targetTemplateId);
        }
    },
    computed: {
        isCompanyTemplates() {
            //this function returns true
            //when a template belongs to the company
            //but not the user viewing it
            if (!this.isAdmin) {
                let match='';
                let returnValue=false;
                this.templateOptions.forEach(template => {
                    if (template.id == this.selectedTemplateId) {
                        let userIdMatch = template.createdBy;
                        match = match + userIdMatch;
                    }
                });
                if (match == this.userid) {
                    returnValue=false;
                    return returnValue;
                } else {
                    returnValue=true;
                    return returnValue;
                }
            } else {
                return false
            }
        },
        isSaveDisabled() {
            return this.templateName=='' || this.templateName==null || this.templateData.isUserDefault == true;
        },
        isEditable() {
            if (this.templateData.isUserDefault) {
                return this.templateData.createdBy == this.userid;
            } else {
                return true;
            }
        }
    },
    methods: {
        saveTemplate() {
            const url = '/analysis/saveTemplate';
            const self = this;
            
            const packetData = {
                _token: GlobalFunctions.getCSRFToken(),
                id: self.templateData.id ? self.templateData.id : null,
                name: self.templateName,
                type: self.chartType,
                data: self.templateData.data,
                isUserDefault: self.templateData.isUserDefault || false,
                loadLatestStage: self.templateData.loadLatestStage || false,
                customer_id: self?.dashboardInfo?.customer_id || null
            };
            this.isSaving = true;
            axios.post(url, packetData)
                .then(function (response) {
                    self.isSaving = false;
                    self.returnedDataset = response;
                    self.templateName = response.data.name;
                    self.templateData.name = response.data.name;
                    self.selectedTemplateId = response.data.id;
                    self.fetchAvailableTemplates();

                    if(response.data.state == "created") {
                        self.$emit('onCreatedTemplate', response.data);
                    }
                    if(response.data.state == "updated") {
                        self.$emit('onUpdatedTemplate', response.data);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        onLoad(targetTemplateID) {
            //Bail out early if the default element is selected
            if(targetTemplateID == "") {
                this.templateName = '';
                this.$emit('onNewTemplate');
                this.dropDownValue = false;
                return;
            }

            this.dropDownValue = true;

            this.templateLoading = true;

            const url = '/analysis/getTemplate';
            const self = this;

            this.wellOptions = [];
            
            $.get(
                url,
                {
                    targetTemplateID: targetTemplateID
                },
                function(response) {
                    self.$emit('onLoadTemplate', response);
                    self.templateLoading = false;
                    self.processTemplateData();
                    self.templateName = self.templateData?.name || '';
                }
            );
        },
        fetchAvailableTemplates() {
            //Should fetch all of the available configs given their type and populate the list
            const url = '/analysis/getTemplates';
            const self = this;

            this.wellOptions = [];
            $.get(
                url,
                {
                    type: self.chartType
                },
                function(returnSet) {
                    self.templateOptions = returnSet;
                    self.rerenderFlipper = !self.rerenderFlipper;
                }
            );
        },
        createNewTemplate() {
            //This asks the parent to handle setting up a new template
            this.selectedTemplateId = null;
            this.templateName = '';
            this.$emit('onNewTemplate');
        },
        copyTemplate() {
            if (this.selectedTemplateId == null) {
                return;
            }
            this.templateData.id = null;
            this.templateData.name = this.templateData.name + ' (Copy)';
            this.templateName = this.templateData.name;
            if (this.templateData.isUserDefault) {
                this.templateData.isUserDefault = false;
            }
            this.saveTemplate();
        },
        deleteTemplate() {
            if (this.selectedTemplateId == null || this.isCompanyTemplates || this.templateData.isUserDefault) {
                return;
            }
            if (confirm('Deleting this template will remove all configured settings. Continue?')) {
                this.$emit('onDeleteTemplate');
                this.templateOptions = this.templateOptions.filter(template => template.id !== this.templateData.id);
                this.templateName = '';
                this.selectedTemplateId = null;
            }
        },
        processTemplateData() {
            this.processedTemplateData = [];
            if (this.chartType == 'SCD') {        
                this.templateData.data.jobs.forEach(targetJob => {
                    const targetAssociatedWells = this.templateData.data.wells.filter(well => {
                        return well.job_id == targetJob.id;
                    });
                    targetAssociatedWells.forEach(targetWell => {
                        const targetAssociatedStages = this.templateData.data.stages.filter(stage => {
                            return stage.well_id == targetWell.id;
                        });
                        targetAssociatedStages.forEach(targetStage => {
                            this.templateData.data.channels.forEach(targetChannel => {
                                this.processedTemplateData.push({
                                    job: targetJob.name,
                                    well: targetWell.wellName,
                                    stage: targetStage.stage,
                                    index: this.templateData.data.indexBy[0].name,
                                    channel: targetChannel.prioritizedTagName
                                });
                            });
                        });
                    });
                });
            } else if (this.chartType == 'MWT') {
                this.templateData.data.jobs.forEach(job => {
                    this.templateData.data.channels.forEach(channel => {
                        const interval = this.templateData.data.interval;
                        const newRow = {
                            job: job.location,
                            channel: channel.prioritizedTagName,
                            timeType: interval.timeType
                        };
                        if (interval.timeType == 'stage' && interval?.startStage?.stage && interval?.endStage?.stage) {
                            newRow.startStage = interval.startStage.stage;
                            newRow.endStage = interval.endStage.stage;
                        } else if (interval.timeType == 'time') {
                            newRow.startTime = interval.startTime;
                            newRow.endTime = interval.endTime;
                        }
                        this.processedTemplateData.push(newRow);
                    }); 
                });
            }
        }
    },
    watch: {
        templateData: {
            handler(newTemplateData, oldTemplateData) {
                this.processTemplateData();
            },
            deep: true
        }
    }
};
</script>
