var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "alert alert-secondary text-left" }, [
    _vm._v(
      "\n    " +
        _vm._s(_vm.message.header) +
        ": " +
        _vm._s(_vm.message.body) +
        "\n    "
    ),
    _c("img", {
      staticStyle: {
        height: "20px",
        position: "absolute",
        right: "5px",
        bottom: "5px",
      },
      attrs: {
        src: "/images/edit.png",
        "data-toggle": "modal",
        "data-target": "#whiteboardModal" + _vm.index,
      },
      on: {
        click: function ($event) {
          return _vm.openDialog()
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "modal",
        attrs: {
          tabindex: "-1",
          role: "dialog",
          id: "whiteboardModal" + _vm.index,
          "data-backdrop": "static",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.newBody,
                      expression: "newBody",
                    },
                  ],
                  staticClass: "form-control",
                  attrs: { type: "text" },
                  domProps: { value: _vm.newBody },
                  on: {
                    change: function ($event) {
                      return _vm.saveChanges()
                    },
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.newBody = $event.target.value
                    },
                  },
                }),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    on: {
                      click: function ($event) {
                        return _vm.saveChanges()
                      },
                    },
                  },
                  [_vm._v("Save")]
                ),
              ]),
            ]),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c("h5", { staticClass: "modal-title" }, [_vm._v("Edit message")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }