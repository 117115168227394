var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: {
        id: "HandshakeModal",
        tabindex: "-1",
        role: "dialog",
        "data-keyboard": "false",
        "data-backdrop": "static",
      },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header d-flex flex-column p-1" }, [
              _c("div", { staticClass: "d-flex" }, [
                _c("img", { staticClass: "px-2", attrs: { src: _vm.image } }),
                _vm._v(" "),
                _vm.state == "inProgress" || _vm.state == "rejected"
                  ? _c("h4", { staticClass: "m-1" }, [
                      _c(
                        "span",
                        {
                          staticClass: "p-1",
                          style: {
                            "background-color": _vm.wellColor,
                            color: _vm.getTitleColorForBG(_vm.wellColor),
                          },
                        },
                        [_vm._v(_vm._s(_vm.statusMessage))]
                      ),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _vm.state == "rejected"
                ? _c(
                    "div",
                    {
                      staticClass: "alert alert-warning m-0",
                      staticStyle: {
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                      },
                    },
                    [
                      _vm._v(
                        "\n                  " +
                          _vm._s(_vm.rejectMessage) +
                          "\n                  "
                      ),
                      _vm._m(0),
                    ]
                  )
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "modal-body p-1", style: { color: "#ffffff" } },
              [
                _vm.jobStatus != "inProgress"
                  ? _c("div", { staticClass: "row m-1" }, [
                      _c("div", { staticClass: "alert alert-danger m-0" }, [
                        _vm._v(
                          "\n                    JOB HAS NOT BEEN STARTED\n                  "
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", { staticClass: "row ml-1 mr-1" }, [
                  _c("h5", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticClass: "p-1 text-center",
                        style: {
                          "background-color": _vm.wsmColor,
                          display: "block",
                          height: "100%",
                        },
                      },
                      [
                        _vm.wsmApproved
                          ? [
                              _vm._v(
                                "\n                          COMPANY REP APPROVED\n                        "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                          COMPANY REP PENDING\n                        "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h5", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticClass: "p-1 text-center",
                        style: {
                          "background-color": _vm.fracColor,
                          display: "block",
                          height: "100%",
                        },
                      },
                      [
                        _vm.fracApproved
                          ? [
                              _vm._v(
                                "\n                          FRAC APPROVED\n                        "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                          FRAC PENDING\n                        "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row ml-1 mr-1" }, [
                  _c("h5", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticClass: "p-1 text-center",
                        style: {
                          "background-color": _vm.wirelineColor,
                          display: "block",
                          height: "100%",
                        },
                      },
                      [
                        _vm.wirelineApproved
                          ? [
                              _vm._v(
                                "\n                          WIRELINE APPROVED\n                        "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                          WIRELINE PENDING\n                        "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                  _vm._v(" "),
                  _c("h5", { staticClass: "col" }, [
                    _c(
                      "span",
                      {
                        staticClass: "p-1 text-center",
                        style: {
                          "background-color": _vm.techColor,
                          display: "block",
                          height: "100%",
                        },
                      },
                      [
                        _vm.techApproved
                          ? [
                              _vm._v(
                                "\n                          TECH APPROVED\n                        "
                              ),
                            ]
                          : [
                              _vm._v(
                                "\n                          TECH PENDING\n                        "
                              ),
                            ],
                      ],
                      2
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row p-2 mt-0 mb-0" }, [
                  _c(
                    "div",
                    { staticClass: "col text-center text-dark" },
                    [
                      _vm.reason != "placeMaintenance" &&
                      _vm.reason != "manualSelectUnlock"
                        ? [
                            _c("img", {
                              staticClass: "img-fluid",
                              staticStyle: { height: "180px" },
                              style: { "background-color": _vm.wellColor },
                              attrs: { src: _vm.wellImage },
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  style: {
                                    "background-color": _vm.wellColor,
                                    color: _vm.getTitleColorForBG(
                                      _vm.wellColor
                                    ),
                                  },
                                },
                                [_vm._v(_vm._s(_vm.wellName))]
                              ),
                            ]),
                          ]
                        : [
                            _vm.wells[_vm.wellNumber].valves.length > 1
                              ? _c("valve-select-component", {
                                  attrs: {
                                    well: _vm.wells[_vm.wellNumber],
                                    readOnly: true,
                                    selectedValves: _vm.selectValveUnlock,
                                  },
                                })
                              : _vm._e(),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col" },
                    [_c("enter-pin-component", { ref: "pinInput" })],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  {
                    staticClass:
                      "row d-flex p-2 justify-content-between align-items-baseline",
                    style: { color: "#000000" },
                  },
                  [
                    _vm.showRejectReason
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.rejectReason,
                              expression: "rejectReason",
                            },
                          ],
                          staticClass: "form-control ml-1 mr-2",
                          attrs: {
                            type: "text",
                            id: "rejectReason",
                            placeholder: "Reject reason...",
                          },
                          domProps: { value: _vm.rejectReason },
                          on: {
                            click: function ($event) {
                              return _vm.input($event)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.rejectReason = $event.target.value
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _c("h5", { staticClass: "ml-auto m-2" }, [
                      _vm._v(
                        "\n                      Please enter your PIN \n                      "
                      ),
                      _vm.reason == "placeFrac" ||
                      _vm.reason == "placeMaintenance" ||
                      _vm.reason == "placeWireline"
                        ? _c("span", [_vm._v(" and select the well below")])
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.showRejectReason && _vm.rejectReason == ""
                        ? _c("div", { staticClass: "alert alert-warning" }, [
                            _vm._v("Must enter a reject reason"),
                          ])
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _vm.canMinimize
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary m-2 btn-lg",
                            on: {
                              click: function ($event) {
                                return _vm.minimize()
                              },
                            },
                          },
                          [_vm._v("Hide")]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-secondary m-2 btn-lg",
                        on: {
                          click: function ($event) {
                            return _vm.response("reject")
                          },
                        },
                      },
                      [_vm._v("Reject")]
                    ),
                    _vm._v(" "),
                    !(
                      _vm.reason == "placeFrac" ||
                      _vm.reason == "placeMaintenance" ||
                      _vm.reason == "placeWireline"
                    )
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary m-2 btn-lg",
                            on: {
                              click: function ($event) {
                                return _vm.response("accept")
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n                      Accept\n                    "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
                _vm._v(" "),
                _vm.showKeyboard
                  ? _c("keyboard-component", {
                      staticClass: "fixed-bottom",
                      staticStyle: { "z-index": "1040" },
                      on: { key: _vm.keyPress },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _vm.reason == "placeFrac" ||
            _vm.reason == "placeMaintenance" ||
            _vm.reason == "placeWireline"
              ? _c(
                  "div",
                  { staticClass: "modal-footer d-flex justify-content-center" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-center" },
                      _vm._l(_vm.wells, function (well, index) {
                        return _c(
                          "div",
                          {
                            key: well.index,
                            staticClass: "col text-center",
                            staticStyle: { cursor: "pointer" },
                            on: {
                              click: function ($event) {
                                return _vm.checkAccept(well, index)
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "img-fluid",
                              staticStyle: { height: "80px" },
                              style: { "background-color": well.color },
                              attrs: { src: _vm.wellImage },
                            }),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _c("h5", [
                              _c(
                                "span",
                                {
                                  staticClass: "badge",
                                  style: {
                                    "background-color": well.color,
                                    color: _vm.getTitleColorForBG(well.color),
                                  },
                                },
                                [_vm._v(_vm._s(well.name))]
                              ),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }