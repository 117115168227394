<!--
    * Component Description
        A re-usable component for implementing tabs
        See props for an explanation of all supported functions

    * Side Effects
        Will emit 1 signal
            update:value    <- syncs with the 'value' prop to comunicate upwards what the last selection made was
            tab-change      <- Communicates upwards when the tab has been changed and the new index

    * Slots
        #tab_<index>        <- slot in the body for each tab

    * Example Usage
        <iws-tabs
            :tabs="[ { title: 'Tab1' }, { title: 'Tab2', enabled: false } ]"
            :value.sync="activeTab"
        >
            <template #tab_0>
                ...
            </template>

            <template #tab_1>
                ...
            </template>
        </iws-tabs>
-->
<template>
<div>
    <ul class="nav nav-pills" id="pills-tab" role="tablist">
        <li v-for="(tab, index) in tabs" :ref="`tab_${index}`" class="nav-item" @click="handleClick(tab, index)">
            <a 
                class="nav-link"
                :class="{
                    'active': index == openIndex,
                    'disabled': tab.enabled === false
                }"
                :id="`#tab_title_${index}`"
                data-toggle="pill"
                :href="`#tab_body_${index}`"
                role="tab"
                :aria-controls="`#tab_body_${index}`"
                :aria-selected="index == openIndex"
            >
                {{ tab.title }}
            </a>
        </li>
    </ul>
    
    <div v-if="showBody" class="tab-content" id="pills-tabContent">
        <div v-for="(tab, index) in tabs"
            class="tab-pane fade"
            :class="{ 'show active': index == openIndex }"
            :id="`tab_body_${index}`"
            role="tabpanel"
            :aria-labelledby="`#tab_title_${index}`"
        >
            <div v-if="!lazyLoad || openIndex === index">
                <slot :name="`tab_${index}`">
                    Tab {{ index }}
                </slot>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { isNullOrEmpty, toast } = GlobalFunctions;

export default {
    props: {
        // List of objects describing the tab cards to display
        // Example: [ { title: `<UserFriendlyTitle>` }, { title: `<UserFriendlyTitle>`, enabled: false } ]
        tabs: {
            type: Array,
            required: true
        },

        // Synced value of input field
        value: {
			type: String | Number,
            default: 0
		},

        // Will only allow for rendering of the tab when it is selected
        // Allows for better inital load times and avoid NPE issues for tabs not in view but could slow down rendering when switching between tabs
        lazyLoad: {
            type: Boolean,
            default: false
        },


        // Gross way to make the duplicate tabs component conform to our system
        // Avoid using, will one day be depreciated
        showBody: {
            type: Boolean,
            default: true
        }
    },

    data: () => ({
        openIndex: null
    }),

    methods: {
        handleClick(tab, index) {
            if (tab.enabled === false)
                return toast({
                    title: 'This tab is currently disabled',
                    variant: 'warning'
                });
            
            this.$emit('update:value', index);
            this.$emit('tab-change', index);
            this.openIndex = index;
        },

        openByIndex(index) {
            this.openIndex = index; 
        }
    },

    mounted() {
        if (this.value !== null && !isNullOrEmpty(this.$refs[`tab_${this.value}`]))
            this.$refs[`tab_${this.value}`][0]?.click();
    }
};
</script>

<style scoped>
    .nav {
        background: #2E353D;
        border-radius: 5px 5px 0px 0px;
        border-bottom: #C9C9C9 1px solid;
    }

    .nav-link {
        color: #C9C9C9;
    }

    .nav-link.active {
        background-color: white;
        color: black;
        border-radius: 5px 5px 0px 0px;
    }

    .tab-content {
        background: #242A30;
        padding: 20px;
        border-radius: 0px 0px 5px 5px;
    }
</style>