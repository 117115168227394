<template>
    <div class="collapse panel-collapse bg-white w-50 pull-right" id="messagesCollapsible">
            <div class="panel-body">
              
                    <div class="left clearfix alert alert-secondary p-1 mb-1" v-for="message in allMessages" :key="message.id">
                      <div style="position:absolute;top:2px;right:2px;cursor:pointer;" class="bg-dark text-light rounded p-1" @click="deleteMessage(message.id)">X</div>
                        <div @click="markRead(message.id)">
                          <p class="mb-0">
                              <span class="badge badge-pill badge-danger" v-if="!message.read">New</span>
                                {{message.text}}
                          </p>
                            <span class="header m-0 p-0">
                                <strong style="font-size:0.7em;">{{message.from}}</strong> <small style="font-size:0.7em;" class="text-muted">{{message.timestamp.format('LLL')}}</small>
                            </span>
                            
                        </div>
                    </div>
              
            </div>
            <div class="panel-footer">
                <div class="input-group">
                    <input id="messageText" type="text" v-model="messageText"  
                      @change="sendMessage()" class="form-control input-sm" placeholder="Type your message here..."
                      @click="input($event)" />
                    <span class="input-group-btn">
                        <button class="btn btn-primary" id="btn-chat" @click="sendMessage()">
                            Send
                        </button>
                    </span>
                </div>
            </div>
            
        <div class="navbar navbar-light bg-light">
            <button class="btn btn-warning" type="button">
                hidden
            </button>
        </div>
        <keyboard-component v-on:key="keyPress" v-if="showKeyboard" style="z-index:1040;position:fixed;right:0;left:0;bottom:500px;"></keyboard-component>
    </div>
</template>

<style>
    #messagesCollapsible { 
        position: absolute;
        right: 0; 
        bottom: 0;
        z-index: 6;
    }
</style>

<script>
    export default {
        data() {
            return {
                messageText: "",
                showKeyboard:false,
                activeInput:''
            }
        },
        props:  ['allMessages','username'],
        methods : {
            sendMessage: function() {
                //TODO: where does this go?
                this.$root.$mqtt.publish('message/user/'+this.username+'/all',this.messageText,0);
                this.messageText = "";
            },
          markRead: function(id){
            this.$emit('read',id);
          },
          deleteMessage: function(id){
            this.$emit('delete',id);
          },
          keyPress(val){
            var self = this;
            switch(val){
              case 'Back':
                self[self.activeInput] = self[self.activeInput].substring(0,self[self.activeInput].length-1);
                break;
              case 'Enter':
                this.showKeyboard = false;
                var event = new Event('change');
                document.getElementById(self.activeInput).dispatchEvent(event);
                break;
              default:
                self[self.activeInput] = self[self.activeInput]+val;
                break;
            }  
          },
          input(event){
            this.activeInput = event.currentTarget.id;
            this.showKeyboard = true;
          }
        }
    }
</script>