var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "table",
      {
        staticClass: "table table-sm table-striped text-left",
        class: _vm.tableColor,
        staticStyle: { display: "block" },
      },
      [
        _c("thead", { staticStyle: { display: "block" } }, [
          _c("th", { staticStyle: { width: "20%" } }, [_vm._v("Time")]),
          _vm._v(" "),
          _c("th", { staticStyle: { width: "35%" } }, [_vm._v("Event")]),
          _vm._v(" "),
          _c("th", { staticStyle: { width: "15%" } }, [_vm._v("Well")]),
          _vm._v(" "),
          _c("th", { staticStyle: { width: "10%" } }, [_vm._v("Stage")]),
          _vm._v(" "),
          _c("th", { staticStyle: { width: "25%" } }, [_vm._v("Requested by")]),
          _vm._v(" "),
          _vm.detailed ? _c("th", [_vm._v("Requester Role")]) : _vm._e(),
          _vm._v(" "),
          _vm.detailed ? _c("th", [_vm._v("Company Approval")]) : _vm._e(),
          _vm._v(" "),
          _vm.detailed ? _c("th", [_vm._v("Frac User")]) : _vm._e(),
          _vm._v(" "),
          _vm.detailed ? _c("th", [_vm._v("Wireline User")]) : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          {
            staticClass: "scrollbar-color",
            staticStyle: { display: "block", "overflow-y": "auto" },
            style: {
              height: _vm.externalHeight ? _vm.externalHeight : "230px",
            },
          },
          _vm._l(_vm.handshakes, function (handshake, index) {
            return _vm.wells[handshake.wellNumber]
              ? _c("tr", [
                  _c("td", { staticStyle: { width: "18%" } }, [
                    _vm._v(_vm._s(_vm._f("shortTime")(handshake.endTime))),
                  ]),
                  _vm._v(" "),
                  _c("td", { staticStyle: { width: "35%" } }, [
                    _vm._v(_vm._s(handshake.statusMessage)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticStyle: { width: "15%" },
                      style: {
                        "background-color":
                          _vm.wells[handshake.wellNumber].color,
                        color: _vm.textColors[handshake.wellNumber],
                      },
                    },
                    [_vm._v(_vm._s(_vm.wells[handshake.wellNumber].name))]
                  ),
                  _vm._v(" "),
                  _c("td", { staticStyle: { width: "10%" } }, [
                    _vm._v(_vm._s(handshake.stageNumber)),
                  ]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(handshake.requesterUser))]),
                  _vm._v(" "),
                  _vm.detailed
                    ? _c("td", [_vm._v(_vm._s(handshake.requesterRole))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailed
                    ? _c("td", [_vm._v(_vm._s(handshake.wsmUser))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailed
                    ? _c("td", [_vm._v(_vm._s(handshake.fracUser))])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.detailed
                    ? _c("td", [_vm._v(_vm._s(handshake.wirelineUser))])
                    : _vm._e(),
                ])
              : _vm._e()
          }),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }