var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "alarmsWrapper" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column justify-content-between" },
      _vm._l(_vm.alarms, function (alarm) {
        return _c("div", { staticClass: "alarmBanner" }, [
          _c(
            "h2",
            { staticClass: "alarmText" },
            [
              _c("span", { staticClass: "badge badge-secondary" }, [
                _vm._v(_vm._s(alarm.source)),
              ]),
              _vm._v(" "),
              _vm._l(alarm.messages, function (msg) {
                return _c("span", { staticClass: "badge badge-warning mr-2" }, [
                  _vm._v(_vm._s(msg)),
                ])
              }),
            ],
            2
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }