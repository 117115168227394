<template>
<base-kpi-template
    ref="baseKpiTemplate"
    :title="`${swapTypeLabel?.upper} Times`"
    :item="item"
    :inital-options="initalOptions"
    :edit-mode="editMode"
    :create-export-data="createExportData"
    :analytics-data="analyticsData"
    :errors="errors"
    :show-not-enough-data-warning="showNotEnoughDataWarning"
    :show-chart="showChart"

    @analyticsChanges="buildAnalytics()"
    @settingsChanges="buildData()"
    @revertChanges="buildData()"
>
    <template #settings>
        <iws-select
            :options="swapTypes"
            :value.sync="item.options.selectedSwapType"
            label="Swap Type"
            display-name="label"
            value-name="value"
            form-spacing
            @change="buildData()"
        />

        <iws-select v-if="requireWirelineCheck"
            :options="dashboardData.wirelineSystems"
            :value.sync="item.options.wirelineSwapFilter"
            label="Wireline"
            display-name="name"
            value-name="number"
            form-spacing
            @change="buildData()"
        />

        <div class="swap-range-container">
            <span class="swap-range-main">
                <iws-select v-if="!customMinSwap"
                    label="Swap Time Minimum"
                    :value.sync="item.options.minSwapTimeFilter"
                    :options="minSwapFilterOptions"
                    display-name="label"
                    value-name="value"
                    form-spacing
                    @change="buildData()"
                />
                <iws-input v-else
                    label="Swap Time Minimum"
                    :value="secondsToMinutes(item.options.minSwapTimeFilter)"
                    @input="item.options.minSwapTimeFilter = minutesToSeconds($event?.target?.value)"
                    @blur="buildData()"
                    min="0"
                    placeholder="In Minutes"
                    type="number"
                    form-spacing
                />
            </span>

            <span class="swap-range-actions">
                <iws-button v-if="item.options.minSwapTimeFilter !== null"
                    class="clear-filter full-width"
                    text="X"
                    type="dark"
                    @click="item.options.minSwapTimeFilter = null; buildData()"
                    :disabled="item.options.minSwapTimeFilter === null"
                />
                <iws-button v-else-if="!customMinSwap"
                    class="switch-filter-type full-width"
                    text="Custom"
                    type="dark"
                    @click="customMinSwap=true"
                />
                <iws-button v-else
                    class="switch-filter-type full-width"
                    text="Preset"
                    type="dark"
                    @click="customMinSwap=false"
                />
            </span>
        </div>

        <div class="swap-range-container">
            <span class="swap-range-main">
                <iws-select v-if="!customMaxSwap"
                    label="Swap Time Maximum"
                    :value.sync="item.options.maxSwapTimeFilter"
                    :options="maxSwapFilterOptions"
                    display-name="label"
                    value-name="value"
                    form-spacing
                    :visible-options="6"
                    @change="buildData()"
                    :flipDirection="true"
                />
                <iws-input v-else
                    label="Swap Time Maximum"
                    :value="secondsToMinutes(item.options.maxSwapTimeFilter)"
                    @input="item.options.maxSwapTimeFilter = minutesToSeconds($event?.target?.value)"
                    @blur="buildData()"
                    min="0"
                    placeholder="In Minutes"
                    type="number"
                    form-spacing
                />
            </span>

            <span class="swap-range-actions">
                <iws-button v-if="item.options.maxSwapTimeFilter !== null"
                    class="clear-filter full-width"
                    text="Clear"
                    type="dark"
                    @click="item.options.maxSwapTimeFilter = null; buildData()"
                    :disabled="item.options.maxSwapTimeFilter === null"
                />
                <iws-button v-else-if="!customMaxSwap"
                    class="switch-filter-type full-width"
                    text="Custom"
                    type="dark"
                    @click="customMaxSwap=true"
                />
                <iws-button v-else
                    class="switch-filter-type full-width"
                    text="Preset"
                    type="dark"
                    @click="customMaxSwap=false"
                />
            </span>
        </div>
    </template>

    <template #extra-info>
        <div class="extra-info">
            {{ selectedSwapType?.label }}

            <template v-if="requireWirelineCheck && !_isFalsy(selectedWireline)">
                ({{ selectedWireline?.name }})
            </template>

            <span v-if="filterInfoDetails != null || !_isNullOrEmpty(filteredItems)" style="float: right">
                <div :id="`popover-target-${_uuid}`">
                    {{ filterInfoDetails }}
                </div>

                <b-popover v-if="!_isNullOrEmpty(filteredItems)"
                    :target="`popover-target-${_uuid}`"
                    triggers="hover"
                    placement="top"
                    :boundary-padding="0"
                    custom-class="filtered-popover"
                    variant="dark"
                >
                    <template #title>
                        Filtered {{ swapTypeLabel.upper }} Times
                    </template>

                    <div v-for="(filteredItem, index) in filteredItems" :class="{ 'pt-2': index > 0 }">
                        <div> 
                            <b>{{ swapTypeLabel.upper }} Number: {{ filteredItem.index }}</b>
                        </div>
                        {{ readableSeconds(filteredItem.swapTime) }}
                        <div v-html="formatStartEndTime(filteredItem.meta.from, filteredItem.meta.to)"></div>
                    </div>
                </b-popover>
            </span>
        </div>
    </template>

    <template v-if="showChart" #content>
        <scatter-line-chart
            ref="kpi"
            :chart-data="kpi"
            :options="options"
        />
    </template>
</base-kpi-template>
</template>

<script>
import GlobalFunctions from '../../../GlobalFunctions.js';
const { isFalsy, isNullOrEmpty, toast } = GlobalFunctions;

import DateFunctions from '../../../DateFunctions.js';
const { dateTimeDisplay, applyTimeOffset } = DateFunctions;

import constants from './constants.js';
const { ANALYTICS_COLORS, chartStyle } = constants;

const moment = require('moment');

import BaseKpi from './BaseKpi.vue';
import BaseKpiTemplate from './BaseKpiTemplate.vue';
import ScatterLineChart from '../../../scatterLineChart.js';

export default {
    extends: BaseKpi,
    components: { BaseKpiTemplate, ScatterLineChart },

    data() {
        return {
            ...this.BaseKpi({
                xAxisLabel: 'Swap Order',
                yAxisLabel: 'Swap Duration (Minutes)',
                datasets: [{
                    label: 'Swap Duration (Minutes)',
                    backgroundColor: chartStyle.primaryBarGraphColour,
                    data: []
                }],

                tooltip_callback: (tooltipItem, data) => {
                    const { from, to } = data?.datasets[tooltipItem.datasetIndex]?.data[tooltipItem.index]?.meta;

                    return `
                        ${this.swapTypeLabel.upper} ${tooltipItem.label}
                        <br/>

                        ${from.name}, ${from.stageNumber} to ${to.name}, ${to.stageNumber}
                        <br/>

                        Duration: ${this.readableSeconds(tooltipItem.value)}
                        <br/>

                        ${this.formatStartEndTime(from, to)}
                    `;
                },
                
                yTick_callback: value => `${Math.floor(value/60)}`
            }),

            filteredItems: [],
            customMinSwap: false,
            customMaxSwap: false,

            swapTypes: [
                { label: 'Wireline to Frac', value: 'wlToFrac' },
                { label: 'Wireline to Wireline', value: 'wlToWl' },
                { label: 'Frac to Wireline', value: 'fracToWl' },
                { label: 'Frac to Frac', value: 'fracToFrac' }
            ],
            minSwapFilterOptions: [
                { label: 'No Limit', value: null },
                { label: '1 Minute', value: 60 },
                { label: '2 Minutes', value: 120 },
                { label: '5 Minutes', value: 300 },
                { label: '10 Minutes', value: 600 }
            ],
            maxSwapFilterOptions: [
                { label: 'No Limit', value: null },
                { label: '30 Minutes', value: 1800 },
                { label: '45 Minutes', value: 2700 },
                { label: '1 Hour', value: 3600 },
                { label: '1.5 Hours', value: 5400 },
                { label: '2 Hours', value: 7200 }
            ]
        }
    },

    computed: {
        // Generically named computed properties to handle changes specific to this KPI
        showNotEnoughDataWarning: function() {
            return this.data !== null && isNullOrEmpty(this.kpi?.datasets[0]?.data);
        },
        showChart: function() {
            return !isNullOrEmpty(this.kpi?.datasets[0]?.data);
        },
        requireWirelineCheck: function() {
            return this.jobNumber?.startsWith('SF') && this.item.options.selectedSwapType == 'wlToWl' && this.dashboardData?.wirelineSystems?.length > 1;
        },
        selectedWireline: function() {
            if (this.requireWirelineCheck && !isNullOrEmpty(this.dashboardData?.wirelineSystems))
                return this.dashboardData?.wirelineSystems?.find(wireline => wireline.number == this.wirelineSwapFilter);
            return null;
        },
        selectedSwapType: function() {
            return this.swapTypes.find(_type => _type.value == this.item.options.selectedSwapType) || {};
        },
        filterInfoDetails: function() {
            if (!isNullOrEmpty(this.kpi?.datasets[0]?.data)) {
                // When applying filters, show how many were filtered out vs the full list
                if (!isNullOrEmpty(this.filteredItems)) {
                    let details = `${this.filteredItems.length} / ${this.filteredItems.length+this.kpi.datasets[0].data.length} ${this.swapTypeLabel.lower} events were filtered out (`;

                    if (!isFalsy(this.item?.options?.minSwapTimeFilter))
                        details += `>= ${Math.floor(this.item?.options?.minSwapTimeFilter/60)} mins`;
                    if (!isFalsy(this.item?.options?.minSwapTimeFilter) && !isFalsy(this.item?.options?.maxSwapTimeFilter))
                        details += ` and `;
                    if (!isFalsy(this.item?.options?.maxSwapTimeFilter))
                        details += `<= ${Math.floor(this.item?.options?.maxSwapTimeFilter/60)} mins`;
                    details += ')';

                    return details;
                }
                return `${this.kpi.datasets[0].data.length} total ${this.swapTypeLabel.lower} events`;
            }

            // No data, just show nothing
            return '';
        },
        swapTypeLabel: function() {
            if (this.item.options.selectedSwapType == 'wlToFrac' || this.item.options.selectedSwapType == 'fracToWl') {
                this.kpi.datasets[0].label = `Transition Duration (Minutes)`;
                this.options.scales.yAxes[0].scaleLabel.labelString = `Transition Duration (Minutes)`;
                this.options.scales.xAxes[0].scaleLabel.labelString = `Transition Order`;
                return {
                    upper: 'Transition',
                    lower: 'transition'
                };
            }

            this.kpi.datasets[0].label = `Standby Duration (Minutes)`;
            this.options.scales.yAxes[0].scaleLabel.labelString = `Standby Duration (Minutes)`;
            this.options.scales.xAxes[0].scaleLabel.labelString = `Standby Order`;
            return {
                upper: 'Standby',
                lower: 'standby'
            };
        }
    },

    methods: {
        // Genericly named function for BaseKPI.vue to call on mounted
        // This creates the KPI in a unique way to the current files needs
        setDefaults: function() {
            this.$set(this.item.options, 'selectedSwapType', this.item.options.selectedSwapType || 'wlToFrac')
            this.$set(this.item.options, 'scatterTag', this.item.options.scatterTag || 'stage_pressure_avgTreatmentZipper1')
            this.$set(this.item.options, 'barTag', this.item.options.barTag || 'stage_rate_avgTreatmentSlurry')
            this.$set(this.item.options, 'minSwapTimeFilter', this.item.options.minSwapTimeFilter || null);
            this.$set(this.item.options, 'maxSwapTimeFilter', this.item.options.maxSwapTimeFilter || null);
            this.$set(this.item.options, 'wirelineSwapFilter', this.item.options.wirelineSwapFilter || 1);
            this.$set(this.item.options, 'limitStagesMetricsPerStage', this.item.options.limitStagesMetricsPerStage || false);

            // If a min or max swap time filter is active but is not one of our presets, switch to custom type
            if (!isFalsy(this.item?.options?.minSwapTimeFilter) && !this.minSwapFilterOptions.find(_filter => _filter.value == this.item.options.minSwapTimeFilter))
                this.customMinSwap = true;
            if (!isFalsy(this.item?.options?.maxSwapTimeFilter) && !this.maxSwapFilterOptions.find(_filter => _filter.value == this.item.options.maxSwapTimeFilter))
                this.customMaxSwap = true;
        },
        initKPI: function() {
            return this.fetchData().then(this.buildData);
        },
        fetchData: function() {
            return $.get('/activities-summary/'+this.jobNumber, {
                startTime: new Date(this.job.start).getTime(),
                endTime: new Date().getTime(),
                getUppperLowerBound: true
            }).then(response => this.data = response?.activities);
        },
        buildData: async function() {
            if (isNullOrEmpty(this.data))
                return;

            this.kpi.labels = [];
            this.kpi.datasets[0].data = [];
            this.filteredItems = [];

            let wlToFrac = false, fracToWl = false, wlToWl = false, fracToFrac = false;
            let activities = [ ...this.data ].sort((x, y) => new Date(x.startTime).getTime() - new Date(y.startTime).getTime());
            const wells = this.dashboardData.wells.reduce((accumulator, obj) => ({ ...accumulator, [obj.id]: obj}), {});

            // Each scenario will require specific handling, convert to simple bools
            if (this.item.options.selectedSwapType == 'wlToFrac') {
                wlToFrac = true;
            } else if (this.item.options.selectedSwapType == 'fracToWl') {
                fracToWl = true;
            } else if (this.item.options.selectedSwapType == 'wlToWl') {
                wlToWl = true;
            } else if (this.item.options.selectedSwapType == 'fracToFrac') {
                fracToFrac = true;
            }

            const isMatching = wlToWl || fracToFrac;

            // CF jobs can allow partial, but not full overlaps in fracToFrac. Should show the overlap as a swap of 0 as opposed to a negative swap time
            const allowPartialOverlap = fracToFrac && this.jobNumber.startsWith('CF')
            const isSimultaneousFrac = this.jobNumber?.startsWith('SF')

            let i = 0;
            let currentActivity
            let lastFinishedEvent;

            // On top of sorting, we do a bit of pre-processing of the events list to make our lives easier down the road
            // When the swap type is matching, we only care about that activity type, filter out the opposite to reduce computation time
            if (fracToFrac) {
                activities = activities.filter(activity => activity.activity == 'frac');
            } else if (wlToWl) {
                if (this.requireWirelineCheck) {
                    activities = activities.filter(activity => activity.activity == 'wireline' && activity?.data?.service_id == this.wirelineSwapFilter);
                } else {
                    activities = activities.filter(activity => activity.activity == 'wireline');
                }
            }

            if (isNullOrEmpty(activities))
                return toast({
                    title: 'Failed filtering of activities: '+this.item.options.selectedSwapType,
                    variant: 'danger'
                });

            // run through once, adding properties that are needed later
            activities.forEach((activity, i) => {
                // Old events could exist from before we stored wellId on the activity. If not set, try to add wellId for later
                if (!('wellId' in activity && wells[activity.wellId]) && activity.wellNumber in this.dashboardData.wells)
                    activity.wellId = this.dashboardData.wells[activity.wellNumber].id;

                activity.name = wells[activity.wellId]?.name || wells[activity.wellId]?.nameLong || '';
                activity.index = i;

                activity.isSimalFrac = false;

                if (isSimultaneousFrac && activity.activity == 'frac') {
                    for (let j=i+1; j < activities.length; j++) {
                        if (activities[j].activity == 'frac' && activities[j].startTime < activity.endTime) {
                            // Look ahead several events for simul fraccing
                            // If both events are frac and the future is running alongside the current it is considered simal and will require special handling later
                            activity.isSimalFrac = true;
                        } else if (activities[j].wellId == activity.wellId) {
                            // Once we find an activity for the same well that is not simul frac, stop the search
                            break;
                        }
                    }
                }
            });


            const addSwapTime = (activityA, activityB) => {
                let swapTime = (new Date(activityB.startTime).getTime() - new Date(activityA.endTime).getTime()) / 1000;

                if (allowPartialOverlap && swapTime < 0) {
                    // Should show swap time as a positive number and swap the timestamps to show start and end of overlap section
                    swapTime = Math.abs(swapTime);

                    const _temp = activityA.endTime;
                    activityA.endTime = activityB.startTime;
                    activityB.startTime = _temp;
                }

                // Ignore negative times as that's a bug
                if (swapTime >= 0) {
                    // Label is the swap index. The index does not change when applying filters
                    // Ensure label is stored as a string so other methods (like export) can perform actions on it
                    const index = `${this.kpi.labels.length+this.filteredItems.length+1}`;

                    const meta = {
                        from: {
                            activity: activityA.activity,
                            name: activityA.name,
                            endTime: activityA.endTime,
                            startTime: activityA.startTime,
                            stageNumber: activityA.stageNumber
                        },
                        to: {
                            activity: activityB.activity,
                            name: activityB.name,
                            endTime: activityB.endTime,
                            startTime: activityB.startTime,
                            stageNumber: activityB.stageNumber
                        },
                    };

                    if (
                        (isFalsy(this.item.options.minSwapTimeFilter) || swapTime >= this.item.options.minSwapTimeFilter) 
                        && (isFalsy(this.item.options.maxSwapTimeFilter) || swapTime <= this.item.options.maxSwapTimeFilter)
                    ) {
                        this.kpi.labels.push(index);
                        this.kpi.datasets[0].data.push({
                            x: index,
                            y: swapTime,
                            meta: meta
                        });
                    } else {
                        this.filteredItems.push({
                            index: index,
                            swapTime: swapTime,
                            meta: meta
                        });
                    }
                }
            }

            // Depending on the widgets settings, we only care about specific event type pairings
            const isValidEventSet = (activityA, activityB) => {
                // Both properties must be set to calculate swap time
                if (isFalsy(activityB.startTime) || isFalsy(activityA.endTime))
                    return false;

                if (allowPartialOverlap) {
                    // CF fraccing can overlap but only partially
                    if (moment.utc(activityB.startTime) > moment.utc(activityA.startTime) && moment.utc(activityB.endTime) < moment.utc(activityA.endTime))
                        return false;
                } else {
                    if (moment.utc(activityB.startTime) < moment.utc(activityA.endTime))
                        return false;
                }

                // In simul fraccing, we pair with the last simul frac to start in the set
                if (isSimultaneousFrac && activityB.isSimalFrac)
                    return false;

                // (Following logic applies to fracToWl in reverse activity order)
                // 1. needs to be wireline -> frac set (order specific)
                // 2. The frac needs to be waiting for wireline to finish perforating. i.e. wireline needs to be the last activity to end
                if (wlToFrac)
                    return activityA.activity == 'wireline' && activityB.activity == 'frac' && activityA.endTime >= lastFinishedEvent.endTime;
                if (fracToWl)
                    return activityA.activity == 'frac' && activityB.activity == 'wireline' && activityA.endTime >= lastFinishedEvent.endTime;

                return activityA.activity == activityB.activity;
            }
            const findNextMatchingEvent = (matchType=currentActivity?.activity) => {
                // Find the next similiarly typed event
                while (i in activities) {
                    if (isFalsy(lastFinishedEvent?.endTime) || activities[i].endTime > lastFinishedEvent.endTime)
                        lastFinishedEvent = { ...activities[i] };

                    // look for an event that matches this event type
                    if (activities[i].activity == matchType)
                        break;

                    i++;
                }

                if (i in activities)
                    currentActivity = activities[i++];
            }


            // Start from the first event relevent to the setting
            findNextMatchingEvent(wlToFrac || wlToWl ? 'wireline' : 'frac');

            // If no relevent starting event could be found, abort
            if (!(i in activities) || isFalsy(currentActivity))
                return;

            while (i in activities) {
                // Go one by one, looking at the next event in the list for all valid sets
                const pairedActivity = activities[i++];

                if (isFalsy(pairedActivity))
                    break;

                const isPair = isValidEventSet(currentActivity, pairedActivity);

                // We need to track the end of the last seen event
                // when swapping wireline -> frac, wireline needs to be the last event to finish (except the paired frac activity)
                if (currentActivity.endTime > lastFinishedEvent.endTime)
                    lastFinishedEvent = { ...currentActivity };
                if (pairedActivity.endTime > lastFinishedEvent.endTime && (!pairedActivity.isSimalFrac || isMatching))
                    lastFinishedEvent = { ...pairedActivity };

                if (isSimultaneousFrac && pairedActivity.isSimalFrac) {
                    // Don't actually need to do anything, just avoid the remaining block from replacing currentActivity.
                    // Once we reach the last simal frac in the pairing, this condition will not match and allow current to move one
                    continue;
                }

                // If the event pairing is what we care about, add the swap time to our map
                if (isPair) {
                    addSwapTime(currentActivity, pairedActivity);

                    if (isSimultaneousFrac && isMatching) {
                        if (lastFinishedEvent.endTime > currentActivity.endTime) {
                            // If the simal frac pair was made, jump to the last event to end
                            currentActivity = { ...lastFinishedEvent };
                            i = lastFinishedEvent.index+1;
                            continue;
                        } else {
                            findNextMatchingEvent();
                        }
                    }
                }

                // If the swap was successfully paired or the currentActivity is no longer the most recent to finish, adjust our starting point
                if (isPair || pairedActivity.endTime > currentActivity.endTime) {
                    if (currentActivity.activity === pairedActivity.activity) {
                        // The swap source needs to be the most recent event to end
                        currentActivity = { ...pairedActivity };
                    } else {
                        findNextMatchingEvent();
                    }
                }

                // Once we reach live events, just break
                if (isFalsy(currentActivity.endTime) || isFalsy(pairedActivity.endTime))
                    break;
            }

            if (!isNullOrEmpty(this.kpi?.datasets[0]?.data)) {
                this.setChartSmartStepSize(this.kpi?.datasets[0]?.data?.map(_item => Math.floor(_item.y)), 10*60, 1*60, 20*60);

                // Let the graph render before adding the annotations
                this.$nextTick(() => {
                    this.buildAnalytics();
                });
            }
        },
        buildAnalytics: function() {
            const swapTimes = this.kpi?.datasets[0]?.data?.map(_item => Math.floor(_item.y)).filter(_num => !isFalsy(_num)) || [];
            const chart = this.getChartRef();
            const yAxisId = chart?.options?.scales?.yAxes[0]?.id;

            if (!isNullOrEmpty(chart?.options?.annotation?.annotations))
                chart.options.annotation.annotations = [];

            if (!isNullOrEmpty(this.item?.options?.analyticsType)) {
                if (this.item?.options?.analyticsType?.includes('Average'))
                    this.drawHorizontalLine('Average', Math.floor(this.getAverage(swapTimes)), yAxisId, ANALYTICS_COLORS[0], this.readableSeconds, false, chart);
                if (this.item?.options?.analyticsType?.includes('Median'))
                    this.drawHorizontalLine('Median', Math.floor(this.getMedian(swapTimes)), yAxisId, ANALYTICS_COLORS[1], this.readableSeconds, false, chart);
            }

            this.buildAnalyticsData();
            chart.update();
            
            this.$nextTick(() => {
                this.assignChartSize();
            });
        },
        createExportData: function() {
            const fields = [
                'Index', 'Swap Description (include stage and Activity)', 'Start', 'End', 'From (Well)', 'From (Stage)', 'From (Activity)',
                'To (Well)', 'To (Stage)', 'to (Activity)', `${this.swapTypeLabel.upper} Duration (Minutes)`, 'Duration (Seconds)'
            ];

            // Scatter Lines have a different structure, restructure to fit the export function
            const items = this.kpi.datasets[0].data.map(_data => {
                const minutes = Math.floor(_data.y/60);
                const seconds = +((_data.y - (minutes * 60)).toFixed(2));

                return [
                    _data.x,
                    `${_data?.meta?.from?.name} ${_data?.meta?.from?.stageNumber} ${_data?.meta?.from?.activity} to ${_data?.meta?.to?.name} ${_data?.meta?.to?.stageNumber} ${_data?.meta?.to?.activity}`,
                    applyTimeOffset(_data?.meta?.from?.endTime, this.job.hourOffset),
                    applyTimeOffset(_data?.meta?.to?.startTime, this.job.hourOffset),
                    _data?.meta?.from?.name,
                    _data?.meta?.from?.stageNumber,
                    _data?.meta?.from?.activity,
                    _data?.meta?.to?.name,
                    _data?.meta?.to?.stageNumber,
                    _data?.meta?.to?.activity,
                    `${minutes}.${seconds}`,
                    _data.y
                ];
            });    

            let preName = this.item.options.type+'-'+this.item.options.selectedSwapType;
            if (!isFalsy(this.item?.options?.minSwapTimeFilter))
                preName += `MoreThan${Math.floor(this.item?.options?.minSwapTimeFilter/60)}Mins`;
            if (!isFalsy(this.item.options.maxSwapTimeFilter))
                preName += `LessThan${Math.floor(this.item.options.maxSwapTimeFilter/60)}Mins`;

            const fileName = this.getExportFilename(preName);

            return { 
                fields, 
                items, 
                fileName
            };
        },
        readableSeconds: function(value) {
            const mins = Math.floor(value/60);

            if (mins > 0) {
                const seconds = Math.floor(value - (mins*60));

                if (seconds > 0)
                    return `${mins} minutes, ${seconds} seconds`;
                return `${mins} minutes`;
            }
            return `${value} seconds`;
        },
        formatStartEndTime: function(from, to) {
            return `
                Start: ${dateTimeDisplay(applyTimeOffset(from.endTime, this.job.hourOffset))}
                <br/>
                End: ${dateTimeDisplay(applyTimeOffset(to.startTime, this.job.hourOffset))}
            `;
        },
        secondsToMinutes: function(val) {
            if (!isFalsy(val) && val > 0)
                return val / 60;
            return null;
        },
        minutesToSeconds: function(val) {
            if (!isFalsy(val) && val > 0)
                return val * 60;
            return null;
        }
    }
}
</script>

<style scoped>
    .swap-range-container>span {
        display: inline-block;
    }
    .swap-range-main {
        width: calc(100% - 86px);
    }
    .swap-range-actions {
        position: relative;
        bottom: 2px;
        
        width: 81px;
    }
</style>