<template>
    <div class="justify-content-center process-logs-page">
        <!-- User Interface controls -->
        <div class="page-title process-logs-page-title">Process Logs</div>
        <process-logs-filter
            :disabled="busy"
            :processes="allProcesses"
            :shifts="allShifts"
            :wells="wells"
            :search.sync="search"
            :filters.sync="filters"
            :sortBy="sortBy.label"
            :sortDirection="sortDirection"
        />


        <iws-table :busy="busy" :columns="fields" :items="filteredItems">

            <template #loading>
                <i class="fa fa-spinner fa-spin fa-2x ml-2"></i> <span class="loading-text"> Loading... </span>
            </template>
            <template #cell_name="{ data }">
                <div
                    class="well-name-column"
                    :style="{ backgroundColor: data.item.color, color: nameColor(data.item) }">
                    {{ data.item.name }}
                </div>
            </template>

            <template #cell_processStatus="{ data }">
                <div
                    v-show="['In Progress', 'request', 'bypassActuate'].includes(data.item.processStatus) || (data.item.processStatus === 'resolved' && data.item.events.length > 1)">
                    <i class="fa fa-spinner fa-spin fa-2x ml-1"></i>
                </div>
                <div
                    v-show="['completed'].includes(data.item.processStatus) || (data.item.processStatus === 'resolved' && data.item.events.length === 1)">
                    <i class="fa fa-check fa-2x ml-1"></i>
                </div>
                <div
                    v-show="!['In Progress', 'completed', 'resolved', 'request', 'bypassActuate'].includes(data.item.processStatus)">
                    <i class="fa fa-times fa-2x ml-1"></i>
                </div>
            </template>
            <template #cell_show_details="{ data }">
                <b-button size="sm" class="mr-2" @click="toggleDetails(data)">
                    {{ !!data.item.showEmbed ? '▲' : '▼' }}
                </b-button>
            </template>
            <template #embed="{ data }">
                <div class="stepContainer">
                    <ProcessLogStepComponent
                        v-for="(event,index) in data.item.events"
                        :event="event"
                        :key="index"
                        :offset="job.hourOffset"
                        :wells="wells"
                        :processName=" data.item.process"/>
                </div>
            </template>
        </iws-table>
    </div>
</template>

<script>
import moment from 'moment';
import _ from 'lodash';
import SignalRMixin from '../../mixins/SignalRMixin';
import ProcessLogStepComponent from './ProcessLogStepComponent.vue'
import GlobalFunctions from '../../GlobalFunctions';
import ProcessLogsFilter from "./ProcessLogsFilter.vue";

export default {
    mixins: [SignalRMixin],
    data() {
        return {
            fields: [
                {
                    key: 'statusMessage',
                    label: 'Process',
                    sortable: false,
                    clicked: this.onHeaderClicked,
                    clickedDir: this.clickedHeaderDir
                },
                {
                    key: 'name',
                    label: 'Well',
                    sortable: false,
                    colStyle: {position: 'sticky', 'width': '125px', left: '0px', boxSizing: 'border-box'},
                    stickyColumn: true,
                    clicked: this.onHeaderClicked,
                    clickedDir: this.clickedHeaderDir
                },
                {
                    key: 'stageNumber',
                    label: 'Stage',
                    sortable: false,
                    clicked: this.onHeaderClicked,
                    clickedDir: this.clickedHeaderDir
                },
                {
                    key: 'date',
                    label: 'Date',
                    sortable: false,
                    formatter: this.formatDate,
                    clicked: this.onHeaderClicked,
                    clickedDir: this.clickedHeaderDir
                },
                {
                    key: 'processStartTime',
                    label: 'Start Time',
                    sortable: false,
                    formatter: this.formatStartTime,
                    clicked: this.onHeaderClicked,
                    clickedDir: this.clickedHeaderDir
                },
                {
                    key: 'finalProcessTime',
                    label: 'End Time',
                    sortable: false,
                    formatter: this.formatEndTime
                },
                {
                    key: 'completedBy',
                    label: 'Completed By',
                    sortable: false,
                },
                {
                    key: 'shift',
                    label: 'Shift',
                    sortable: false,
                    clicked: this.onHeaderClicked,
                    clickedDir: this.clickedHeaderDir
                },
                {
                    key: 'processStatus',
                    label: 'Status',
                    sortable: false,
                },
                {
                    key: 'handshake_duration',
                    label: 'Handshake Duration',
                    sortable: false,
                    colStyle: {textAlign: 'center'},
                },
                {
                    key: 'process_duration',
                    label: 'Process Duration',
                    colStyle: {textAlign: 'center'},
                    sortable: false,
                },
                {
                    key: 'show_details',
                    label: 'Show Details',
                    thStyle: {textAlign: 'center'},
                    colStyle: {textAlign: 'center'},
                    sortable: false,
                },
            ],
            sortBy:
                {
                    key: 'processStartTime',
                    label: 'Start Time',
                },
            sortDescending: true,
            search: '',
            webServerLocalTimestamp: '',
            numberOfShifts: 10,
            filters: {
                wells: [],
                shifts: [],
                processes: []
            },
            items: [],
            filteredItems: [],
            wsSearchRejectedItems: [],
            busy: true,
            jobNumber: this.job.jobNumber, // needed to join signalR group
            disableFutherLoad: false
        }
    },
    computed: {
        allShifts() {
            return this.shifts.map((shift) => ({value: shift, label: shift}));
        },
        allProcesses() {
            return this.processes.map((process) => ({value: process, label: process}));
        },
        sortDirection() {
            return this.sortDescending ? 'Descending' : 'Ascending';
        }
    },
    mounted() {
        this.initializeSignalRConnection(this.sessionId, this.signalrMessageHandler, this.signalrConnectedHandler, this.signalrConnectionFailedHandler);
        this.updateOngoingTimes();
        setInterval(this.updateOngoingTimes, 10000);
        this.fetchLogs(0, ({events}) => {
            this.items = events;
            this.filteredItems = [...this.items.map((item) => ({...item, showEmbed: false}))];
        });
        window.addEventListener('scroll', this.onScroll);
    },
    beforeDestroy() {
        window.removeEventListener('scroll', this.onScroll);
    },
    methods: {
        toggleDetails(data) {
            this.filteredItems[data.index].showEmbed = !this.filteredItems[data.index].showEmbed;
        },
        onHeaderClicked(sortBy) {
            let newSortBy = sortBy;
            if (!['statusMessage', 'name', 'stageNumber', 'processStartTime', 'shift', 'date'].includes(sortBy)) {
                return;
            }
            if (newSortBy === 'date') {
                newSortBy = 'processStartTime';
            }
            if (newSortBy === this.sortBy.key) {
                this.toggleSortDir()
            } else {
                this.sortBy = this.fields.find(field => field.key === newSortBy);
                this.sortDescending = false;
            }
            this.updateFilterItems();
        },
        clickedHeaderDir(clickedHeader) {
            let newClickedColHeader = clickedHeader;
            if (!['statusMessage', 'name', 'stageNumber', 'processStartTime', 'shift', 'date'].includes(clickedHeader)) {
                return undefined;
            }
            if (newClickedColHeader === 'date') {
                newClickedColHeader = 'processStartTime';
            }
            if (newClickedColHeader !== this.sortBy.key) {
                return undefined;
            } else {
                return !this.sortDescending;
            }
        },
        toggleSortDir() {
            this.sortDescending = !this.sortDescending;
        },
        updateFilterItems() {
            this.wsSearchRejectedItems = []
            const isSortDefault = this.sortBy.key === 'processStartTime' && this.sortDescending;
            const areAllFiltersCleared = !this.filters.wells.length && !this.filters.processes.length && !this.filters.shifts.length;
            const isSearchEmpty = !this.search;

            if (isSortDefault && areAllFiltersCleared && isSearchEmpty) {
                this.filteredItems = [...this.items.map((item) => ({...item, showEmbed: false}))]
            } else {
                this.fetchLogs(0, ({events}) => {
                    this.filteredItems = [...events.map(event => ({...event, showEmbed: false}))];
                });
                this.disableFutherLoad = false;
            }
        },
        onScroll() {
            if (this.busy || this.disableFutherLoad) {
                return;
            }
            const {scrollTop, clientHeight, scrollHeight} = document.documentElement;
            if (scrollTop + clientHeight >= scrollHeight - 1) {
                this.fetchLogs(this.filteredItems.length, ({events}) => {
                    this.filteredItems = [...this.filteredItems, ...events.map(event => ({
                        ...event,
                        showEmbed: false
                    }))];
                });
            }
        },
        signalrMessageHandler(message) {
            if (!message.data.process_instance_id) {
                return;
            }
            let timestamp = moment();
            this.insertNewEvent(message, timestamp);
        },
        signalrConnectedHandler() {
            console.debug("Connected to ws server");
        },
        signalrConnectionFailedHandler(error) {
            console.error("Error connecting to ws server: ", error);
        },
        formatStartTime(value, key, item) {
            if (typeof item.processStartTime !== 'undefined') {
                return moment(item.processStartTime).utcOffset(this.job.hourOffset * 60).format('HH:mm:ssA');
            } else {
                return null;
            }
        },
        formatEndTime(value, key, item) {
            if (typeof item.finalProcessTime !== 'undefined' && item.finalProcessTime !== null) {
                return moment(item.finalProcessTime).utcOffset(this.job.hourOffset * 60).format('HH:mm:ssA');
            } else {
                return null;
            }
        },
        formatDate(value, key, item) {
            return item == null ? null : moment(item.processStartTime).utcOffset(this.job.hourOffset * 60).format('YYYY-MM-DD');
        },
        determineShift(timestamp) {
            let amShift = moment(timestamp).hour(this.job.shiftStart).minute(0);
            let pmShift = moment(amShift).add(12, 'hours');
            let dayPriorPMShift = moment(pmShift).subtract(1, 'days');
            if (pmShift.isBefore(timestamp)) {
                return pmShift.format("YYYY-MM-DD A")
            } else if (amShift.isBefore(timestamp)) {
                return amShift.format("YYYY-MM-DD A")
            }
            return dayPriorPMShift.format("YYYY-MM-DD A")
        },
        determineCompletedBy(event) {
            let completedBy = event.data.users?.map(user => user.user).join(", ")
            if (event.category === 'emergencyBypass' && event['subCategory'] === "request")
                completedBy = event.data.requesterUser;
            return completedBy;
        },
        insertNewProcess(event, timestamp) {
            let processInstanceId = event.data.process_instance_id.toUpperCase();
            let relevantWell = this.wells.find(well => well.index === event.wellNumber)

            let shift = this.determineShift(timestamp);
            let completedBy = this.determineCompletedBy(event);

            let process = {
                processInstanceId: processInstanceId,
                events: [],
                color: relevantWell.color,
                name: relevantWell.name,
                stageNumber: event.stageNumber,
                process: event.data.requestReason,
                statusMessage: event.data.statusMessage,
                processStartTime: timestamp.toISOString(),
                finalProcessTime: null,
                handshake_duration: null,
                process_duration: null,
                handshakeStatus: "In Progress",
                processStatus: "In Progress",
                completedBy: completedBy === undefined ? "" : completedBy,
                shift: shift,
                showEmbed: false
            };
            event.timestamp = {date: timestamp.toISOString(), timezone: null};
            if (event.category === 'inspectValves') {
                switch (event.subCategory) {
                    case 'mismatched':
                        process.handshakeStatus = event.subCategory
                        break;
                    case 'resolved':
                        process.processStatus = process.handshakeStatus = event.subCategory
                        process.process_duration = '00:00:00'
                        process.finalProcessTime = process.processStartTime
                        break;
                    default:
                        break;
                }
            }
            process.events.push(event);
            this.items.unshift(process);

            const isProcessNotWithinSearchCriteria = !_.isEmpty(this.search) && !process.statusMessage?.toLowerCase().includes(this.search.toLowerCase()) &&
                !process.name.toLowerCase().includes(this.search.toLowerCase()) &&
                !process.completedBy?.toLowerCase().includes(this.search.toLowerCase());
            const isRequestReasonNotWithinFilter = !!this.filters.processes.length && !this.filters.processes.includes(process.process);
            const isWellNameNotWithinFilter = !!this.filters.wells.length && !this.filters.wells.includes(relevantWell.name);
            const isShiftNotWithinFilter = !!this.filters.shifts.length && !this.filters.shifts.includes(process.shift);

            if (isProcessNotWithinSearchCriteria || isRequestReasonNotWithinFilter || isWellNameNotWithinFilter || isShiftNotWithinFilter) {
                // If search is the only reason we didnt get a matching criteria, we add it in the search rejected items list.
                // since during a handshake its possible that newer events may meet the search criteria (completedBy is a dynamic field) and we want
                // to preserve the old events for the dropdown
                if (isProcessNotWithinSearchCriteria && (!isRequestReasonNotWithinFilter && !isWellNameNotWithinFilter && !isShiftNotWithinFilter)) {
                    this.wsSearchRejectedItems.push(process);
                }
                return;
            }
            this.addProcessToTable(process);
        },
        addProcessToTable(process) {
            let index = null;
            switch (this.sortBy.key) {
                case 'processStartTime':
                    index = this.filteredItems.findIndex(item => (
                        this.sortDescending ?
                            new Date(item.processStartTime) <= new Date(process.processStartTime) :
                            new Date(item.processStartTime) > new Date(process.processStartTime)
                    ));
                    if (index >= 0 || this.disableFutherLoad) {
                        if (index === -1) {
                            index = this.filteredItems.length;
                        }
                        this.filteredItems.splice(index, 0, process);
                        return true;
                    }
                    break;
                case 'name':
                    index = this.filteredItems.findIndex(item => (
                        this.sortDescending ?
                            _.lte(item.name, process.name) :
                            _.gt(item.name, process.name)
                    ));
                    if (index >= 0 || this.disableFutherLoad) {
                        if (index === -1) {
                            index = this.filteredItems.length;
                        }
                        this.filteredItems.splice(index, 0, process);
                        return true;
                    }
                    break;
                case 'statusMessage':
                    index = this.filteredItems.findIndex(item => (
                        this.sortDescending ?
                            _.lte(item.statusMessage, process.statusMessage) :
                            _.gt(item.statusMessage, process.statusMessage)
                    ));
                    if (index >= 0 || this.disableFutherLoad) {
                        if (index === -1) {
                            index = this.filteredItems.length;
                        }
                        this.filteredItems.splice(index, 0, process);
                        return true;
                    }
                    break;
                case 'shift':
                    index = this.filteredItems.findIndex(item => (
                        this.sortDescending ?
                            _.lte(item.shift, process.shift) :
                            _.gt(item.shift, process.shift)
                    ));
                    if (index >= 0 || this.disableFutherLoad) {
                        if (index === -1) {
                            index = this.filteredItems.length;
                        }
                        this.filteredItems.splice(index, 0, process);
                        return true;
                    }
                    break;
                case 'stageNumber':
                    index = this.filteredItems.findIndex(item => (
                        this.sortDescending ?
                            item.stageNumber <= process.stageNumber :
                            item.stageNumber > process.stageNumber
                    ));
                    if (index >= 0 || this.disableFutherLoad) {
                        if (index === -1) {
                            index = this.filteredItems.length;
                        }
                        this.filteredItems.splice(index, 0, process);
                        return true;
                    }
                    break;
                default:
                    break;

            }
            return false;
        },
        addEventToProcess(event, timestamp, process) {
            let prevEvent = process.events[process.events.length - 1]
            event.duration = this.durationDiff(prevEvent.timestamp.date, timestamp);
            event.timestamp = {date: timestamp.toISOString(), timezone: null};
            process.events.push(event);
            // See if there is any changes to process & handshake status from this event
            if (['processEvent', 'emergencyBypass'].includes(event.category) || event['subCategory'] === "rejected") {
                process.processStatus = event.subCategory;
                process.finalProcessTime = timestamp.toISOString();
                process.process_duration = this.durationDiff(process.processStartTime, timestamp)
                if (process.handshakeStatus === "In Progress") {
                    process.handshake_duration = process.process_duration;
                    process.handshakeStatus = "rejected";
                    process.completedBy = event.data.users?.map(user => user.user).join(", ")
                }
                if (event.category === 'emergencyBypass' && event['subCategory'] === "completed" && event.data.hasOwnProperty('cancelledBy')) {
                    process.completedBy = event.data.requesterUser + ', ' + event.data.cancelledBy
                }
            } else if (event['category'] === 'handshake' && process.handshakeStatus === "In Progress") {
                process.completedBy = event.data.users?.map(user => user.user).join(", ")
                if (['rejected', 'completed'].includes(event.subCategory)) {
                    process.handshakeStatus = event.subCategory;
                    process.handshake_duration = this.durationDiff(process.processStartTime, timestamp);
                }
            }
        },
        insertNewEvent(event, timestamp) {
            let processInstanceId = event.data.process_instance_id.toUpperCase();
            let process = this.filteredItems.find(item => item.processInstanceId === processInstanceId);
            if (process) {
                this.addEventToProcess(event, timestamp, process);
                return;
            }

            // if the process isnt found in the filtered items, check if any of the rejected meet the criteria
            let processIndex = this.wsSearchRejectedItems.findIndex(item => {
                return item.processInstanceId === processInstanceId;
            });
            if (processIndex === -1) {
                this.insertNewProcess(event, timestamp);
                return;
            }
             process = this.wsSearchRejectedItems[processIndex];
             // we would've only had the process in the ws rejected list if it didn't meet the search criteria yet but could possibly meet it
             // in future. This may be that 'future' which puts it back into the filtered items list.
             let completedBy = this.determineCompletedBy(event);
             const isProcessNotWithinSearchCriteria = !_.isEmpty(this.search) &&
                 !completedBy?.toLowerCase().includes(this.search.toLowerCase());

            if (!isProcessNotWithinSearchCriteria) {
                 // search criteria is finally met. Remove it from the rejected list,
                 // attempt adding it to the table and if successful, add the event to this process.
                 this.wsSearchRejectedItems.splice(processIndex, 1);
                 if (this.addProcessToTable(process)) {
                     this.addEventToProcess(event, timestamp, process);
                 }
             }
        },
        durationDiff(oldTimestamp, newTimestamp = null) {
            let oldMoment = moment(oldTimestamp);
            let newMoment = newTimestamp ? moment(newTimestamp) : moment()
            let duration = moment.duration(newMoment.diff(oldMoment));
            // Looks somewhat hack-y but this is a simple way to display the HH:mm:ss duration, HS should never exceed 1 day
            return moment('2000-01-01 00:00:00').add(duration).format('HH:mm:ss')
        },
        updateOngoingTimes() {
            Object.values([...this.filteredItems, ...this.items]).forEach(item => {
                if (item.processStatus === "In Progress") {
                    let ongoingDuration = this.durationDiff(item.processStartTime);
                    item.process_duration = ongoingDuration;
                    if (item.handshakeStatus === "In Progress" && item.category !== 'inspectValves') {
                        item.handshake_duration = ongoingDuration;
                    }
                }
            });
        },
        nameColor(well) {
            return GlobalFunctions.getTitleColorForBG(well.color);
        },
        fetchLogs(skip, success) {
            this.busy = true;
            $.ajax({
                url: `/process-logs/filter/${this.job.jobNumber}`,
                type: 'GET',
                data: {
                    searchString: this.search,
                    processes: this.filters.processes,
                    wells: this.filters.wells,
                    shifts: this.filters.shifts,
                    skip,
                    sortBy: this.sortBy.key,
                    sortDescending: this.sortDescending
                },
                success: (response) => {
                    // We got less than 50 records, which means that there are no further records to scroll load.
                    if ((response.events?.length ?? 0) < 50) {
                        this.disableFutherLoad = true;
                    }
                    success(response)
                },
                error: (xhr, status, error) => {
                    console.error('Error:', error);
                }
            }).always(() => {
                this.busy = false;
            });
        }
    },
    watch: {
        search() {
            this.updateFilterItems();
        },
        filters() {
            this.fetchLogs(0, ({events}) => {
                this.filteredItems = [...events.map((item) => ({...item, showEmbed: false}))];
            });
            this.disableFutherLoad = false;
        },
    },
    props: {
        wells: {
            type: Array,
            default: []
        },
        job: {
            type: Object,
            default: {}
        },
        sessionId: {
            type: String,
            required: true
        },
        userId: {
            type: [String, Number],
            required: true
        },
        processes: {
            type: Array,
            default: []
        },
        shifts: {
            type: Array,
            default: []
        }
    },
    components: {
        ProcessLogsFilter,
        ProcessLogStepComponent
    }
}
</script>

<style scoped>

.process-logs-page {
    margin: 2rem;
}

.process-logs-page-title {
    padding-bottom: 10px;
}

.stepContainer {
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
}

.loading-text {
    margin-left: 10px;
}

.well-name-column {
    height: 20px;
    width: 100%;
    border-radius: 5px;
    padding: 0 5px;
}
</style>
