var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.newUi
        ? [
            _c("iws-select", {
              staticStyle: { "max-width": "500px", margin: "0px" },
              attrs: {
                value: _vm.selectedJob,
                options: _vm.filteredJobs,
                "display-name": "jobOptionDisplay",
                "value-name": "jobNumber",
                placeholder: !!_vm.selectedJob
                  ? `${_vm.selectedJob.jobNumber} - ${_vm.jobMainTitle(
                      _vm.selectedJob
                    )}`
                  : null,
              },
              on: {
                "update:value": function ($event) {
                  _vm.selectedJob = $event
                },
                change: function ($event) {
                  return _vm.$emit("jobSelected", $event)
                },
              },
            }),
          ]
        : [
            _c("h4", { staticStyle: { display: "inline" } }, [
              _c("div", { staticClass: "badge badge-secondary ml-2" }, [
                _c(
                  "li",
                  {
                    staticClass: "nav-item dropdown",
                    attrs: { text: "Right align" },
                  },
                  [
                    _vm.selectedJob
                      ? _c(
                          "span",
                          {
                            attrs: {
                              "data-toggle": "dropdown",
                              role: "button",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.jobNumber) +
                                " - " +
                                _vm._s(_vm.jobMainTitle(_vm.selectedJob)) +
                                " " +
                                _vm._s(
                                  _vm.iwsUser && _vm.jobSystem
                                    ? ` - (${_vm.jobSystem})`
                                    : ""
                                ) +
                                "\n                    "
                            ),
                          ]
                        )
                      : _c(
                          "span",
                          {
                            attrs: {
                              "data-toggle": "dropdown",
                              role: "button",
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.jobNumber) +
                                " - Not Active\n                    "
                            ),
                          ]
                        ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "dropdown-menu bg-light" },
                      _vm._l(_vm.filteredJobs, function (job) {
                        return _c(
                          "li",
                          {
                            key: job.id,
                            staticClass: "nav-item dashboard_navbar_item",
                          },
                          [
                            _c(
                              "a",
                              {
                                staticClass: "nav-link text-white",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit(
                                      "jobSelected",
                                      job.jobNumber
                                    )
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                                " +
                                    _vm._s(job.jobOptionDisplay) +
                                    "\n                            "
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                ),
              ]),
            ]),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }