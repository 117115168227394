import { render, staticRenderFns } from "./UserPermissionsCompany.vue?vue&type=template&id=578f96a2&scoped=true&"
import script from "./UserPermissionsCompany.vue?vue&type=script&lang=js&"
export * from "./UserPermissionsCompany.vue?vue&type=script&lang=js&"
import style0 from "./UserPermissionsCompany.vue?vue&type=style&index=0&id=578f96a2&lang=css&"
import style1 from "./UserPermissionsCompany.vue?vue&type=style&index=1&id=578f96a2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578f96a2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/srv/project/releases/10162/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('578f96a2')) {
      api.createRecord('578f96a2', component.options)
    } else {
      api.reload('578f96a2', component.options)
    }
    module.hot.accept("./UserPermissionsCompany.vue?vue&type=template&id=578f96a2&scoped=true&", function () {
      api.rerender('578f96a2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/UserPermissionsCompany.vue"
export default component.exports