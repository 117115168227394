var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pb-5" },
    [
      _c("iws-breadcrumbs", {
        staticClass: "mt-4 mb-1",
        attrs: {
          path: [
            {
              title: "Home",
              link: "/home",
            },
            {
              title: "Copy Remote Data",
              link: "/copy-data",
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "page-title mb-4" }, [
        _vm._v("\n        Sync Remote Data\n    "),
      ]),
      _vm._v(" "),
      _c("b", [
        _vm._v(
          "**** Warning this is a developer tool and may overwrite your data, please use with caution. ****"
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "This tool is primarily for copying over data not related to a specific job into your local environment."
        ),
      ]),
      _vm._v(" "),
      _c("p", { staticClass: "mb-0" }, [
        _vm._v(
          "The customer list dropdown is temporarily retrieved from the database specified in your .env Mongo string. Customers aren't copied down until requested."
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "mt-5" }, [
        _c("h4", [_vm._v("Company Data")]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group has-validation" }, [
          _c(
            "label",
            { staticClass: "form-label", attrs: { for: "company" } },
            [_vm._v("Select company you want data synced for:")]
          ),
          _vm._v(" "),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.company,
                  expression: "company",
                },
              ],
              class: {
                "form-control": true,
                "is-invalid": _vm.errors["company"],
              },
              attrs: {
                id: "company",
                disabled: _vm.busy,
                placeholder: "Select a company",
                required: "",
              },
              on: {
                change: function ($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function (o) {
                      return o.selected
                    })
                    .map(function (o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.company = $event.target.multiple
                    ? $$selectedVal
                    : $$selectedVal[0]
                },
              },
            },
            [
              _c(
                "option",
                { attrs: { value: "", disabled: "", selected: "" } },
                [_vm._v("Select company")]
              ),
              _vm._v(" "),
              _vm._l(_vm.customers, function (customer) {
                return _c(
                  "option",
                  { key: customer.id, domProps: { value: customer.id } },
                  [_vm._v(_vm._s(customer.name))]
                )
              }),
            ],
            2
          ),
          _vm._v(" "),
          _vm.errors["company"]
            ? _c("div", { staticClass: "text-danger" }, [
                _c("small", [
                  _vm._v(
                    _vm._s(
                      _vm.errors["company"]
                        ? _vm.errors["company"][0]
                        : undefined
                    )
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "pt-3" }, [
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("p", [_vm._v("Select tables to be synced:")]),
              _vm._v(" "),
              _vm._l(_vm.companyOptions, function (option) {
                return _c(
                  "div",
                  { key: option.table, staticClass: "mb-4" },
                  [
                    _c(
                      "b-form-checkbox",
                      {
                        attrs: { disabled: _vm.busy },
                        model: {
                          value: option.copy,
                          callback: function ($$v) {
                            _vm.$set(option, "copy", $$v)
                          },
                          expression: "option.copy",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        " +
                            _vm._s(option.table) +
                            "\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "ml-4",
                        attrs: { disabled: _vm.busy },
                        model: {
                          value: option.value.includeUsers,
                          callback: function ($$v) {
                            _vm.$set(option.value, "includeUsers", $$v)
                          },
                          expression: "option.value.includeUsers",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Include users\n                    "
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-form-checkbox",
                      {
                        staticClass: "ml-4",
                        attrs: { disabled: _vm.busy },
                        model: {
                          value: option.value.includeDeleted,
                          callback: function ($$v) {
                            _vm.$set(option.value, "includeDeleted", $$v)
                          },
                          expression: "option.value.includeDeleted",
                        },
                      },
                      [
                        _vm._v(
                          "\n                        Include deleted entries\n                    "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _vm.errors["tables"] && _vm.syncCompanyData
                ? _c("div", { staticClass: "text-danger" }, [
                    _c("small", [
                      _vm._v(
                        _vm._s(
                          _vm.errors["tables"]
                            ? _vm.errors["tables"][0]
                            : undefined
                        )
                      ),
                    ]),
                  ])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "d-flex justify-content-end mt-5" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary",
                attrs: { disabled: _vm.busy },
                on: {
                  click: function ($event) {
                    return _vm.copyData(
                      "/customer-data",
                      _vm.companyOptions,
                      true
                    )
                  },
                },
              },
              [
                _vm.busy
                  ? _c("b-spinner", {
                      staticClass: "mr-1",
                      attrs: { small: "" },
                    })
                  : _vm._e(),
                _vm._v(" Sync Company Data\n                "),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c("section", { staticClass: "mt-5" }, [
        _c("h4", [_vm._v("General Data")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "pt-3" },
          [
            _c("p", [_vm._v("Select tables to be synced:")]),
            _vm._v(" "),
            _vm._l(_vm.generalOptions, function (option) {
              return _c(
                "div",
                { key: option.table, staticClass: "mb-4" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      attrs: { disabled: _vm.busy },
                      model: {
                        value: option.copy,
                        callback: function ($$v) {
                          _vm.$set(option, "copy", $$v)
                        },
                        expression: "option.copy",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(option.table) +
                          "\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: _vm.busy },
                      model: {
                        value: option.value.includeUsers,
                        callback: function ($$v) {
                          _vm.$set(option.value, "includeUsers", $$v)
                        },
                        expression: "option.value.includeUsers",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Include users\n                "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-checkbox",
                    {
                      staticClass: "ml-4",
                      attrs: { disabled: _vm.busy },
                      model: {
                        value: option.value.includeDeleted,
                        callback: function ($$v) {
                          _vm.$set(option.value, "includeDeleted", $$v)
                        },
                        expression: "option.value.includeDeleted",
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Include deleted entries\n                "
                      ),
                    ]
                  ),
                ],
                1
              )
            }),
            _vm._v(" "),
            _vm.errors["tables"] && !_vm.syncCompanyData
              ? _c("div", { staticClass: "text-danger" }, [
                  _c("small", [
                    _vm._v(
                      _vm._s(
                        _vm.errors["tables"]
                          ? _vm.errors["tables"][0]
                          : undefined
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "d-flex justify-content-end mt-5" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-primary",
                  attrs: { disabled: _vm.busy },
                  on: {
                    click: function ($event) {
                      return _vm.copyData("/general-data", _vm.generalOptions)
                    },
                  },
                },
                [
                  _vm.busy
                    ? _c("b-spinner", {
                        staticClass: "mr-1",
                        attrs: { small: "" },
                      })
                    : _vm._e(),
                  _vm._v(" Sync General Data\n                "),
                ],
                1
              ),
            ]),
          ],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }