<template>
	<table class="table table-striped mt-2 scroll-view" :class="{'table-dark': darkMode}">
		<tr v-for="(log, index) in logEntries" v-bind:key="index">
			<td class="col-6 p-1 m-1 text-left text-nowrap">{{ log.text }}</td>
			<td class="col-6 p-1 m-1 text-right text-nowrap">{{ log.time }}</td>
		</tr>
	</table>
</template>

<script>
export default {
	props : {
		logEntries: Array
	},
	computed: {
		darkMode: function () {
			return this.$root.darkMode;
		}
    }
}

/* logEntries expects an array of objects like this:
/ {
/ 	text: "text for log entry",
/ 	time: "string timestamp"
/ }
*/

</script>

<style>
	.scroll-view {
		max-height: 300px;
		border-radius: 5px;
		border-width: 1px;
		font-size : 0.76rem;
	}
</style>