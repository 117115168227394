<template>
<LibraryFiltersBaseModal :id="id" ref="libraryFilters"
    :items="guns"
    :filters="filters"
    title="Filter Guns"
    @clearFilters="filters = $event"
>
    <template #body="{ findUniqueValues, pushToFilter, removeFromFilter, handleEvent }">
        <div class="filter-container">
            <iws-select
                label="Name"
                :value="filters.name"
                :options="findUniqueValues('name')"
                @change="handleEvent(filters.name, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.name" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times clickable" style="margin-right: 5px" @click="removeFromFilter(filters.name, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Description"
                :value="filters.description"
                :options="findUniqueValues('description')"
                @change="handleEvent(filters.description, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.description" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times" style="margin-right: 5px" @click="removeFromFilter(filters.description, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container container-border">
            <label>Gun Size</label>

            <div class="row">
                <div class="col-6">
                    <iws-select ref="gunRangeMin"
                        label="Select Min Gun Size"
                        :value.sync="sizeMin"
                        :options="findUniqueValues('size')"
                        @change="checkForFullRange"
                    />
                </div>

                <div class="col-6">
                    <iws-select ref="gunRangeMax"
                        label="Select Max Gun Size"
                        :value.sync="sizeMax"
                        :options="findUniqueValues('size')"
                        @change="checkForFullRange(pushToFilter)"
                    />
                </div>
            </div>

            <div>
                <span v-for="(option, index) in filters.size" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times" style="margin-right: 5px" @click="removeFromFilter(filters.size, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Carrier"
                :value="filters.carrier_make"
                :options="findUniqueValues('carrier_make')"
                @change="handleEvent(filters.carrier_make, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.carrier_make" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times" style="margin-right: 5px" @click="removeFromFilter(filters.carrier_make, index)"></i>
                </span>
            </div>
        </div>

        <div class="filter-container">
            <iws-select
                label="Charge"
                :value="filters.charge_make"
                :options="findUniqueValues('charge_make')"
                @change="handleEvent(filters.charge_make, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.charge_make" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times" style="margin-right: 5px" @click="removeFromFilter(filters.charge_make, index)"></i>
                </span>
            </div>
        </div>

        <div v-if="enableCustomerCol" class="filter-container">
            <iws-select
                label="Customer"
                :value="filters.customerName"
                :options="findUniqueValues('customerName')"
                @change="handleEvent(filters.customerName, $event)"
                :maintain-on-select="true"
                :multiselect="true"
            />

            <div>
                <span v-for="(option, index) in filters.customerName" class="badge badge-outline">
                    {{ option }} <i class="fas fa-times" style="margin-right: 5px" @click="removeFromFilter(filters.customerName, index)"></i>
                </span>
            </div>
        </div>
    </template>
</LibraryFiltersBaseModal>
</template>

<script>
import LibraryFiltersBaseModal from './LibraryFiltersBaseModal.vue';

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        guns: [Array, Object],
        enableCustomerCol: Boolean
    },

    components: {
        LibraryFiltersBaseModal
    },

    data() {
        return {
            filters: {
                name: [],
                description: [],
                size: [],
                carrier_make: [],
                charge_make: [],
                customerName: []
            },

            sizeMin: null,
            sizeMax: null
        }
    },

    methods: {
        checkForFullRange(pushToFilter) {
            // Once both values have been entered, push the full range and reset the dropdowns
            if (this.sizeMin !== null && this.sizeMax !== null) {
                pushToFilter(this.filters.size, `${this.sizeMin} - ${this.sizeMax}`);
                
                // After a very short delay, clear the selection (allows the user to see it worked)
                setTimeout(() => {
                    this.sizeMin = null;
                    this.sizeMax = null;
                    this.$refs.gunRangeMin.clearOption(false);
                    this.$refs.gunRangeMax.clearOption(false);
                }, 150);
            }
        },

        open(filters) {
            this.filters = JSON.parse(JSON.stringify(filters));

            return this.$refs.libraryFilters.open();
        }
    }
}
</script>