var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "resolve-modal",
    attrs: {
      "no-close-on-backdrop": "",
      size: "xl",
      "header-class": "border-bottom-0 justify-content-center  p-0",
      "body-class": "pb-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg",
    },
    on: {
      hidden: function ($event) {
        return _vm.$emit("onDismiss")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "col w-100" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("div", { staticClass: "font-large w-100 text-center" }, [
                  _vm._v(" Template settings "),
                ]),
              ]),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "row justify-content-center align-items-center mb-2",
              },
              [
                _c("div", { staticClass: "col-3 list-label text-right" }, [
                  _vm._v("Selected Template : "),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-7" }, [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectedTemplateId,
                          expression: "selectedTemplateId",
                        },
                      ],
                      ref: "templateSelect",
                      staticClass: "form-control",
                      on: {
                        change: [
                          function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.selectedTemplateId = $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          },
                          function ($event) {
                            return _vm.onLoad($event.target.value)
                          },
                        ],
                      },
                    },
                    [
                      _c("option", { domProps: { value: null } }, [
                        _vm._v("--Select a template--"),
                      ]),
                      _vm._v(" "),
                      _vm._l(_vm.templateOptions, function (target, index) {
                        return _c(
                          "option",
                          {
                            key: index,
                            domProps: {
                              value: target.id,
                              selected:
                                target.id == _vm.templateData.id ? true : false,
                            },
                          },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(target.name) +
                                "\n                "
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  ),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col-2 pl-0" }, [
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip:top",
                        value: "Create a new teamplate",
                        expression: "'Create a new teamplate'",
                        arg: "top",
                      },
                    ],
                    staticClass: "fas fa-plus-circle show-clicker-finger mr-2",
                    on: {
                      click: function ($event) {
                        return _vm.createNewTemplate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip:top",
                        value: "Copy this template",
                        expression: "'Copy this template'",
                        arg: "top",
                      },
                    ],
                    staticClass: "fas fa-copy show-clicker-finger mr-2",
                    class: { "icon-disabled": !_vm.selectedTemplateId },
                    on: {
                      click: function ($event) {
                        return _vm.copyTemplate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip:top",
                        value: "Delete this template",
                        expression: "'Delete this template'",
                        arg: "top",
                      },
                    ],
                    staticClass: "fas fa-trash show-clicker-finger",
                    class: [
                      {
                        "icon-disabled":
                          _vm.isCompanyTemplates ||
                          _vm.templateData.isUserDefault ||
                          !_vm.selectedTemplateId,
                      },
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.deleteTemplate()
                      },
                    },
                  }),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            !_vm.templateLoading && !_vm.isSaving
              ? _c("div", { staticClass: "mb-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-content-center align-items-center",
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "col-3 list-label text-right" },
                        [_vm._v("Template Name : ")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-7" }, [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("div", { staticClass: "w-100" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.templateName,
                                  expression: "templateName",
                                },
                              ],
                              ref: "templateName",
                              staticClass: "form-control",
                              attrs: {
                                placeholder: "Enter template name here",
                                type: "text",
                                readonly:
                                  _vm.isCompanyTemplates &&
                                  _vm.selectedTemplateId != null,
                                disabled: _vm.templateData.isUserDefault,
                              },
                              domProps: { value: _vm.templateName },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.templateName = $event.target.value
                                },
                              },
                            }),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-2" }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.templateData.isUserDefault,
                          expression: "templateData.isUserDefault",
                        },
                      ],
                      staticClass: "row",
                    },
                    [
                      _c("div", { staticClass: "col-3" }),
                      _vm._v(" "),
                      _c("div", { staticClass: "col-9" }, [
                        _c("p", { staticClass: "redText" }, [
                          _vm._v(
                            "\n                        Changes to default templates cannot be saved\n                        "
                          ),
                          _c("br"),
                          _vm._v(
                            " To preserve any changes, please make a copy of this template\n                    "
                          ),
                        ]),
                      ]),
                    ]
                  ),
                ])
              : _c("div", { staticClass: "pt-4 pb-5" }, [
                  _c(
                    "div",
                    {
                      staticClass: "spinner-border spinner-border-sm",
                      staticStyle: {
                        color: "black",
                        position: "absolute",
                        "z-index": "100",
                        left: "50%",
                        "margin-left": "-5px",
                      },
                      attrs: { role: "status" },
                    },
                    [
                      _c("span", { staticClass: "sr-only mb-5" }, [
                        _vm._v("Loading..."),
                      ]),
                    ]
                  ),
                ]),
            _vm._v(" "),
            !_vm.templateLoading && !_vm.isSaving && _vm.processedTemplateData
              ? _c(
                  "div",
                  {
                    staticClass:
                      "row justify-content-center align-items-start mt-3 mb-2",
                  },
                  [
                    _c("div", { staticClass: "col-3 list-label text-right" }, [
                      _vm._v("Series Configurations :"),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-9" },
                      [
                        _vm.processedTemplateData.length != 0
                          ? _c("b-table", {
                              attrs: {
                                striped: "",
                                hover: "",
                                items: _vm.processedTemplateData,
                              },
                            })
                          : _c("div", [
                              _vm._v(
                                "Please add a data series to view configurations."
                              ),
                            ]),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
      {
        key: "modal-footer",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass:
                  "d-flex w-100 justify-content-center align-items-center px-2 pb-2",
              },
              [
                _c("div", { staticClass: "flex-grow-1" }),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary grey-button",
                    attrs: { type: "button" },
                    on: {
                      click: () => {
                        _vm.$emit("onDismiss")
                      },
                    },
                  },
                  [_vm._v("Close")]
                ),
                _vm._v(" "),
                _c("div", { staticClass: "px-2" }),
                _vm._v(" "),
                !_vm.templateLoading && !_vm.isSaving
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button", disabled: _vm.isSaveDisabled },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.saveTemplate.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("\n                Save\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                !_vm.templateLoading && _vm.isSaving
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                      },
                      [_vm._v("\n                Saving...\n            ")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.templateLoading && !_vm.isSaving
                  ? _c(
                      "button",
                      {
                        staticClass: "btn btn-success",
                        attrs: { type: "button" },
                      },
                      [_vm._v("\n                Loading...\n            ")]
                    )
                  : _vm._e(),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.modalVisible,
      callback: function ($$v) {
        _vm.modalVisible = $$v
      },
      expression: "modalVisible",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }