var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showWidget
    ? _c(
        "div",
        { staticClass: "omnichannel-chat-button", on: { click: _vm.openChat } },
        [
          _c(
            "svg",
            {
              staticClass: "support-icon",
              attrs: {
                xmlns: "http://www.w3.org/2000/svg",
                width: "20.4",
                height: "24",
                viewBox: "0 0 20.4 24",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "contact_support_FILL0_wght400_GRAD0_opsz48",
                  d: "M131.85,200l-.3-3.3h-1.2a10.35,10.35,0,1,1,4-19.935,9.589,9.589,0,0,1,3.165,2.145,10,10,0,0,1,2.115,3.255,10.739,10.739,0,0,1,.765,4.1,13.915,13.915,0,0,1-.585,3.96,15.593,15.593,0,0,1-1.7,3.78,16.993,16.993,0,0,1-2.7,3.33A18.269,18.269,0,0,1,131.85,200Zm1.5-3.24a15.117,15.117,0,0,0,3.855-4.875,12.53,12.53,0,0,0,1.395-5.625,8.33,8.33,0,0,0-2.325-6.09,7.872,7.872,0,0,0-5.865-2.37,8.55,8.55,0,1,0-.06,17.1h3Zm-2.94-3.45a1.127,1.127,0,1,0-.81-.33A1.1,1.1,0,0,0,130.41,193.31Zm-.81-4.08h1.5a2.7,2.7,0,0,1,.255-1.245,5.985,5.985,0,0,1,1.065-1.305,5.841,5.841,0,0,0,1.14-1.485,3.268,3.268,0,0,0,.33-1.455,2.93,2.93,0,0,0-.915-2.22,3.4,3.4,0,0,0-2.445-.87,3.946,3.946,0,0,0-3.72,2.46l1.38.57a2.455,2.455,0,0,1,.915-1.155,2.419,2.419,0,0,1,1.335-.375,2.1,2.1,0,0,1,1.41.435,1.443,1.443,0,0,1,.51,1.155,2.338,2.338,0,0,1-.33,1.155,7.825,7.825,0,0,1-1.17,1.455,5.344,5.344,0,0,0-1.035,1.35A3.974,3.974,0,0,0,129.6,189.23ZM130.2,187.28Z",
                  transform: "translate(-120 -176)",
                  fill: "#fff",
                },
              }),
            ]
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }