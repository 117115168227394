<!--
    * Component Description
        Displays the breadcrumbs component for easy navigation
        See props for an explanation of all supported functions

    * Example Usage
        <iws-breadcrumbs
            :path="[{
                title: 'Dashboard',
                link: '/home'
            }, {
                title: 'Gun Library',
                link: '/guns'
            }]"
        />
-->

<template>
    <nav v-if="!!path && !!path.length" aria-label="breadcrumb">
        <ol class="breadcrumb">
            
            <li v-for="(page, index) in path" :key="`${index}_${page.name}`"
                class="breadcrumb-item"
                :class="{ 'active': isActiveItem(index) }"
            >
            <a v-if="page.link"
                    :href="page.link"
                    :class="{
                        'secondary-text-color': !isActiveItem(index),
                        'primary-text-color': isActiveItem(index)
                    }"
                >
                    {{ page.title }}
                </a>
                <span v-else
                    :class="{
                        'secondary-text-color': !isActiveItem(index),
                        'primary-text-color': isActiveItem(index)
                    }"
                >
                    {{ page.title }}
                </span>
            </li>
        </ol>
    </nav>
</template>

<script>
export default {
    props: {
        // Array of objects detailing breadcrumb items
        // Data Structure: {
        //     title: String,
        //     link: String
        // }
        path: {
            type: Array,
            required: true
        }
    },

    methods: {
        isActiveItem(index) {
            return index == this.path.length-1;
        }
    }
};
</script>

<style scoped>
    .breadcrumb {
        background-color: transparent !important;
        padding: 0px !important;
        margin: 0px !important;
    }

    .breadcrumb {
        font-size: 12px;
        font-weight: 400;
        line-height: 18px;
        letter-spacing: 0em;
        text-align: left;
    }
</style>