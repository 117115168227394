<template>

<div v-if="isCustomTimeOptions">
    <button :id="'timeRangeButton' + item.i" class="btn btn-light btn-sm mx-2 px-2 w-100">{{'Duration: '+buttonText}}</button>
    <div class="d-flex flex-column whiteText mx-2">
        <b-popover :target="'timeRangeButton'+ item.i" triggers="click blur" placement="bottom">
            <div class="d-flex flex-column whiteText mx-2">
                <div class="d-flex justify-content-between mb-2">
                    <div class="mr-2"> Select Duration: </div>
                    <select v-model="selectedDurationType" @input="selectedDurationTypeUpdated($event)" @change="checkSavedDates($event)">
                        <option v-for="(option, index) in Object.keys(durationOptions)" :key="index" :value="option">{{durationOptions[option]}}</option>
                    </select>
                </div>
                <div v-if="selectedDurationType == 'times'" class="mt-2">
                    <div class="d-flex justify-content-between mb-2">
                        <div>Start Time: </div>
                        <input id="selectedTimeMin" type="datetime-local" v-model="selectedTimeMin" @input="selectedTimeMinUpdated($event)" :max="setStartDateMax(true)"></div>
                    <div class="d-flex justify-content-between">
                        <div>End Time: </div>
                        <input id="selectedTimeMax" class="input" type="datetime-local" v-model="selectedTimeMax" @input="selectedTimeMaxUpdated($event)" :min="setEndDateMin(true)" :max="getTodayDate(true)"></div>
                </div>
                <div v-if="selectedDurationType == 'days'">
                    <div class="d-flex justify-content-between mb-2">
                        <div>Start Date: </div>
                        <input id="selectedDayMin" type="date" v-model="selectedDayMin" @change="emitCalculatedIntervalRange($event)" @input="selectedDayMinUpdated($event)" :max="setStartDateMax(false)"></div>
                    <div class="d-flex justify-content-between mb-2">
                        <div>End Date: </div>
                        <input id="selectedDayMax" type="date" v-model="selectedDayMax" @change="emitCalculatedIntervalRange($event)" @input="selectedDayMaxUpdated($event)" :min="setEndDateMin(false)" :max="getTodayDate(false)"></div>
                        <div class="d-flex justify-content-between">
                            <div class="mr-2"> Select Time Range Type: </div>
                            <select v-model="selectedIntervalType" @input="selectedIntervalTypeUpdated($event)" @change="emitCalculatedIntervalRange($event)">
                                <option v-for="(option, index) in intervalOptions" :key="index" :value="option">{{option}}</option>
                            </select>
                        </div>
                </div>
                <div v-if="displayStartEndTimeSameError" class="text-danger font-weight-bold d-flex justify-content-between mt-2">
                    Start and end time cannot be the same
                </div>
                <div v-if="displayStartAfterEndTimeError" class="text-danger font-weight-bold d-flex justify-content-between mt-2">
                    Start time cannot be after end time
                </div>
            </div>
        </b-popover>
    </div>
</div>
<div v-else>
    <div class="d-flex flex-row align-items-center">
    <button :id="'timeRangeButton' + item.i" class="btn btn-light btn-sm mx-2 px-2 w-100">{{'Duration: '+buttonText}}</button>
    <div class="d-flex flex-column whiteText mx-2">
        <b-popover :target="'timeRangeButton' + item.i" triggers="click blur" placement="bottom">
            <div class="d-flex flex-column whiteText mx-2">
                    <div class="d-flex justify-content-between mb-2">
                        <div>Start Date: </div>
                        <input id="selectedDayMin" type="date" v-model="selectedDayMin" @change="emitCalculatedIntervalRange($event)" @input="selectedDayMinUpdated($event)" :max="setStartDateMax(false)">
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <div>End Date: </div>
                        <input id="selectedDayMax" type="date" v-model="selectedDayMax" @change="emitCalculatedIntervalRange($event)" @input="selectedDayMaxUpdated($event)"  :min="setEndDateMin(false)" :max="getTodayDate(false)">
                    </div>
                    <div class="d-flex justify-content-between mb-2">
                        <div class="mr-2"> Select Time Interval Type: </div>
                        <select v-model="selectedIntervalType" @input="selectedIntervalTypeUpdated($event)" @change="emitCalculatedIntervalRange($event)">
                            <option v-for="(option, index) in intervalOptions" :key="index" :value="option">{{option}}</option>
                        </select>
                    </div>
            </div>
        </b-popover>
    </div>
    </div>
</div>
</template>

<style scoped>
.whiteText {
    color: white;
}
 
</style>

<script>

const DATETIME_FORMAT = 'YYYY-MM-DDThh:mm';
const DATE_FORMAT = 'YYYY-MM-DD';

import GlobalFunctions from '../GlobalFunctions.js';
import moment, { duration } from 'moment';
import _ from 'lodash';

export default {
    components: {
    },
    mounted() {
        this.loadConfig();
    },
    props: {
        resetToDefaults: {
            type: Boolean,
            default: false
        },
        item: {
            type: [Object, Array]
        },
        job: {
            type: [Array, Object],
            required: true
        },
        isCustomTimeOptions: {
            type: [Boolean, Number],
            default: false
        },
        styleDurationButton: {
            type: String
        }
    },
    data() {
        return {
            isSavingBoolean: null,
            saveObject: {},
            activityBreakdownConfig: {},
            displayStartEndTimeSameError: false,
            displayStartAfterEndTimeError: false,
            selectedDurationType: 'startEnd',
            selectedIntervalType: 'Midnight to Midnight',
            selectedTimeMin: moment().subtract(7,'days').startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            selectedTimeMax: moment().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
            selectedDayMin: moment().subtract(7,'days').startOf('day').format('YYYY-MM-DD'),
            selectedDayMax: moment().startOf('day').format('YYYY-MM-DD'),
            firstAndLastDateOfInterval: [],
            durationOptions: {
                startEnd: 'Job Start to End' ,
                hrs24: 'Last 24 Hours',
                hrs48: 'Last 48 Hours',
                days7: 'Last 7 Days',
                times: 'Custom Times',
                days: 'Custom Date Range'
            },
            intervalOptions: ['Shift Start to Shift Start', 'Midnight to Midnight', 'Dayshift and Nightshift'],
        }
    },
    computed: {
        buttonText() {
            if (!this.isCustomTimeOptions) {
                let minDateReadable = this.readableTime(this.selectedDayMin);
                let maxDateReadable = this.readableTime(this.selectedDayMax);
                return minDateReadable + ' - ' + maxDateReadable + ', ' + this.selectedIntervalType;
            } else {
                if (this.selectedDurationType === 'days') {
                    let minDateReadable = this.readableTime(this.selectedDayMin);
                    let maxDateReadable = this.readableTime(this.selectedDayMax);
                    return minDateReadable + ' - ' + maxDateReadable + ', ' + this.selectedIntervalType;
                } 
                if (this.selectedDurationType === 'times') {
                    let minDateReadable = this.readableTime(this.selectedTimeMin, true);
                    let maxDateReadable = this.readableTime(this.selectedTimeMax, true);
                    return minDateReadable + ' - ' + maxDateReadable; 
                } else {
                    return this.durationOptions[this.selectedDurationType];
                }
            }
        }
    },
    methods: {
        readableTime(time, includeTime = false) {
            let dateString = moment(time, 'YYYY-MM-DD HH:mm:ss').toDate().toLocaleDateString('en-us', { month:"short", day:"numeric", time:"long"});
            if (includeTime) {
                let timeString = moment(time, 'YYYY-MM-DD HH:mm:ss').format('HH:mm');
                return dateString + ' ' + timeString;
            } else {
                return dateString
            }
        },
        checkSavedDates($event) {
            // checks if previously saved dates are valid
            if ($event.target.value === 'times') {
                this.validateTime(true);
            } else if ($event.target.value === 'days') {
                this.validateTime(false);
            } else {
                this.displayStartEndTimeSameError = false;
                this.displayStartAfterEndTimeError = false;
            }
        },
        getTodayDate(isDateTime) {
            //get today's date in the format YYY-MM-DDThh:mm
            let today = new Date();
            let day = String(today.getDate()).padStart(2, '0');
            let month = String(today.getMonth() + 1).padStart(2, '0');
            let year = today.getFullYear();
            let hour = String(today.getHours()).padStart(2, '0');
            let minute = String(today.getMinutes()).padStart(2, '0');
            
            if (isDateTime) {
                return year + '-' + month + '-' + day + 'T' + hour + ':' + minute;
            } else {
                return year + '-' + month + '-' + day;
            }
        },
        setStartDateMax(isDateTime) {
            // get earlier of the two dates
            const today = this.getTodayDate(isDateTime);
            const unixSelectedTimeMax = moment.utc(this.selectedTimeMax, DATETIME_FORMAT).unix();
            const unixSelectedDayMax = moment.utc(this.selectedDayMax, DATE_FORMAT).unix();
            const unixToday = moment.utc(today, DATE_FORMAT).unix();

            if (unixSelectedTimeMax < unixToday && isDateTime) {
                return this.selectedTimeMax;
            } else if (unixSelectedDayMax < unixToday && !isDateTime) {
                return this.selectedDayMax;
            } else {
                return today;
            }
        },
        setEndDateMin(isDateTime) {
            if (this.selectedTimeMin && isDateTime) {
                return moment(this.selectedTimeMin).format(DATETIME_FORMAT);
            } else if (this.selectedDayMin && !isDateTime) {
                return moment(this.selectedDayMin).format(DATE_FORMAT);
            } else {
                // there is no min set if there is no start date
                return null;
            }
        },
        validateTime(isDateTime) {

            let selectedTimeMinFormatted;
            let selectedTimeMaxFormatted;
            if (isDateTime) {
                selectedTimeMinFormatted = moment.utc(this.selectedTimeMin).format(DATETIME_FORMAT);
                selectedTimeMaxFormatted = moment.utc(this.selectedTimeMax).format(DATETIME_FORMAT);
            } else {
                selectedTimeMinFormatted = moment.utc(this.selectedDayMin).format(DATE_FORMAT);
                selectedTimeMaxFormatted = moment.utc(this.selectedDayMax).format(DATE_FORMAT);
            }
            
            // reset error messages
            this.displayStartEndTimeSameError = false;
            this.displayStartAfterEndTimeError = false;
            
            if (selectedTimeMinFormatted === selectedTimeMaxFormatted) {
                this.displayStartEndTimeSameError = true;
                return false;
            } else if (this.selectedTimeMin > this.selectedTimeMax) {
                this.displayStartAfterEndTimeError = true;
                return false;
            } else {
                return true;
            }
        },
        emitCalculatedIntervalRange: function ($event) {
            if (!this.isCustomTimeOptions) {
                this.selectedDurationType = 'days';
                this.selectedDurationTypeUpdated('days');
            }
            this.firstAndLastDateOfInterval = this.getFirstAndLastDatesFromShiftStartTimes(this.selectedDayMin, this.selectedDayMax, this.selectedIntervalType);
            if (this.validateTime(false)) {
                this.$emit('onCalculatedMaxMinIntervalDateTime', this.firstAndLastDateOfInterval);
            }
        },
        getFirstAndLastDatesFromShiftStartTimes(startDateInterval, endDateInterval, selectedIntervalType) {
            let startDateOfRangeMidnightUnix = moment.utc(startDateInterval, 'YYYY-MM-DD').unix() 
            let endDateOfRangeMidnightUnix = moment.utc(endDateInterval,'YYYY-MM-DD').unix()
            if (selectedIntervalType === 'Midnight to Midnight') {
                return [startDateOfRangeMidnightUnix, endDateOfRangeMidnightUnix]
            } else {
                //convert shiftStart to seconds and add to unix value in seconds (3600 seconds = 1 hour)
                let startDateOfRangeUnix = startDateOfRangeMidnightUnix + (this.job.shiftStart * 3600);
                let endDateOfRangeUnix = endDateOfRangeMidnightUnix + (this.job.shiftStart * 3600);
                return [startDateOfRangeUnix, endDateOfRangeUnix]
            }
        },
        selectedDurationTypeUpdated($event) {
            this.$emit('selectedDurationTypeChange', $event);
        },
        selectedIntervalTypeUpdated($event) {
            this.$emit('selectedIntervalTypeChange', $event)
        },
        selectedDayMinUpdated($event) {
            if (this.validateTime(false)) {
                this.$emit('selectedDayMinChange', $event);
            }
        },
        selectedDayMaxUpdated($event) {
            if (this.validateTime(false)) {
                this.$emit('selectedDayMaxChange', $event);
            }
        },
        selectedTimeMinUpdated($event) {
            if (this.validateTime(true)) {
                this.$emit('selectedTimeMinChange', $event);
            }
        },
        selectedTimeMaxUpdated($event) {
            if (this.validateTime(true)) {
                this.$emit('selectedTimeMaxChange', $event);
            }
        },
        loadConfig() {
            if (this.item?.activityBreakdownConfig) {
                const savedConfig = this.item.activityBreakdownConfig;
                this.selectedTimeMin = savedConfig.customTimeMin ||  moment().utc().subtract(7,'days').startOf('day').format('YYYY-MM-DD HH:mm:ss');
                this.selectedTimeMax = savedConfig.customTimeMax || moment().utc().startOf('day').format('YYYY-MM-DD HH:mm:ss');
                this.selectedDayMin = savedConfig.customDayMin || moment().utc().subtract(7,'days').startOf('day').format(DATE_FORMAT);
                this.selectedDayMax = savedConfig.customDayMax || moment().utc().startOf('day').format(DATE_FORMAT);
                this.selectedDurationType = savedConfig.selectedDurationType || 'days';
                this.selectedIntervalType = savedConfig.selectedIntervalType || 'Midnight to Midnight';
            }
        },
    },
    watch: {
        selectedDayMin: {
            handler(newVal, oldVal) {
                this.emitCalculatedIntervalRange()
            },
            deep: true
        },
        selectedDayMax: {
            handler(newVal, oldVal) {
                this.emitCalculatedIntervalRange()
            },
            deep: true
        },
        selectedIntervalType: {
            handler(newVal, oldVal) {
                this.emitCalculatedIntervalRange()
            },
            deep: true,
            immediate: true
        },
        isCustomTimeOptions: {
            handler(newVal, oldVal) {
                if (this.isCustomTimeOptions == false) {
                    this.selectedDurationType = 'days';
                    this.selectedDurationTypeUpdated('days');
                }
            }
        },
        resetToDefaults: {
            handler(newVal, oldVal) {
              if (newVal) {
                this.loadConfig();
                if (this.resetToDefaults) {
                    this.$emit('resetToDefaultsChange', false);
                }
              }
            }
        },

    }
}



</script>