<!--
    Show the the status of the current job
    Accept the message and alert colors
     />
-->

<template>
    <div class="flex-grow-1" :style="'height:' + height+ 'px;'">
        <div v-if="messagesArray == null"
            class="rounded pl-1 alert-font" 
            :style="'color:'+ latestAlert.alarmType + ';border:1px solid ' + latestAlert.alarmType"
        >
            Alert Status: {{latestAlert ? latestAlert.message : "&lt;&lt;No Alerts&gt;&gt;"}}
        </div>
        <div v-else
            class="rounded pl-1 alert-font d-flex flex-1 h-100 align-items-center justify-content-between"
            :style="'border:1px solid ' + getSelectedColor" 
        >
            <transition name="fade" mode="out-in">
                <div class="truncate" :style="'color:' + getSelectedColor" v-bind:key="`${selected}-alert`">
                    {{ getSelectedText }}
                </div>
            </transition>
             <div v-if="showDropdown" class="dropdown custom-dropdown"> 
                <a class="btn btn-link dropdown-toggle btn-sm h-auto pb-1" :style="'color:' + getSelectedColor + '!important; '" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Show all alerts
                </a>

                <div class="dropdown-menu custom-dropdown-menu dropdown-menu-right scrollable-menu bg-light" aria-labelledby="dropdownMenuLink">
                    <a v-for="(option, index) in options" :key="index" class="dropdown-item cursor-point dropdown-focus-dark"  @click="()=>onClick(option)" :style="alertColor(option)">{{option.text}}</a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .alert-font {
        font-weight:bold;
    }
    .test-border {
         border : 1px solid red
    }
    .overflow-test {
        overflow: visible !important;
    }
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
    .cursor-point {
        cursor: pointer;
    }
    .scrollable-menu {
        height: auto;
        max-height: 175px;
        overflow-x: hidden;
    }
    .dropdown-focus-dark:focus, .dropdown-focus-dark:hover {
        background-color: rgba(255,255,255,.15) !important;;
    }
	.truncate {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>

<script>
export default {
    data() {
        return {
            fetchedData: [],
            selected: null,
            options: null,
            alertArray: [],
            enabledDropdownEvents: false,
            isSwappingMessages: false
        };
    },
    mounted() {
        if(this.serverCall) {
            this.fetchFromServerCall();
        } else {
            this.setDropdownOptions(this.filteredAlertHistory);
        }
    },
    props: {
        message: {
            type: [String],
            default: '<<No Alerts>>'
        },
        serverCall: {
            type: Function
        },
        alarmType: {
            type: [String],
            default: 'green'
        },
        height: {
            type: [Number, String],
            default: 'auto'
        },
        messagesArray: {
            type: [Array, Boolean],
            default: ()=>[]
        },
        onAlertPress: {
            type: Function
        },
        alertHistory: {
            type: [Array, Boolean],
            default: ()=>[]
        },
        clusterAlerts: {
            type: [Array, Boolean],
            default: ()=>[]
        }
    },
    methods: {
        alertColor: function (option) {
            return { 
                color: option.color
            };
        },
        fetchFromServerCall() {
            const self = this;
            this.serverCall && this.serverCall().then((data)=>{
                this.fetchedData = data;
                self.setDropdownOptions(data);
                setTimeout(self.fetchFromServerCall, 5000);
            });
        },
        onClick(option) {
            this.onAlertPress && this.onAlertPress(option);
        },
        setDropdownOptions(messagesArray) {
            this.alertArray = messagesArray;
            const options = messagesArray.map((item, index)=>{
                return {
                    ...item,
                    value: index,
                    text: item.message
                };
            });
            this.options = options;
            this.setLatestAlert();
        },
        setLatestAlert() {
            let latestAlertIndex = null;
            let latestAlertObject = null;
            
        
            this.messagesArray.forEach((element, index) => {
                if( latestAlertObject === null) {
                    latestAlertObject = element;
                    latestAlertIndex = index; 
                } else if (latestAlertObject.unix_timestamp && element.unix_timestamp &&  latestAlertObject.unix_timestamp < element.unix_timestamp) {
                    latestAlertObject = element;
                    latestAlertIndex = index; 
                }
            });

            this.selected = latestAlertIndex? latestAlertIndex : 0;
        }
    },
    watch: {
        messagesArray: function () { 
            this.setDropdownOptions(this.filteredAlertHistory);
        },
        options: function (current, old) { 
            if(current.length > 0 && this.enabledDropdownEvents === false) {
                this.enabledDropdownEvents = true;
                let dropdownMenu;                                     
                $(window).on('show.bs.dropdown', function(e) {        
                    dropdownMenu = $(e.target).find('.custom-dropdown-menu');
                    $('body').append(dropdownMenu.detach());          
                    dropdownMenu.css('display', 'block');             
                    dropdownMenu.position({                           
                        'my': 'right top',                            
                        'at': 'right bottom',                         
                        'of': $(e.relatedTarget)                      
                    });                                                
                });

                $(window).on('hide.bs.dropdown', function(e) {        
                    $(e.target).append(dropdownMenu.detach());        
                    dropdownMenu.hide();                              
                }); 
            };
        }
    },
    computed: {
        latestAlert() {
            return {
                alarmType: this.alarmType,
                message: this.message
            };
        },
        showDropdown() {
            if(this.options?.length > 0) {          
                return true;
            } else {
                return false;
            }
        },
        filteredAlertHistory() {
            //This method returns the alert history without 'okay' alerts sorted by timestamp desc
            let filteredAlerts = this.alertHistory.filter((targetAlert) => {
                if(targetAlert.data?.level != 'okay') {
                    return targetAlert;
                }
            });
            
            filteredAlerts = filteredAlerts.reverse();
            return [...filteredAlerts, ...this.clusterAlerts];
        },
        getSelectedColor() {
            if(this.messagesArray && this.messagesArray.length === 0 && this.fetchedData && this.fetchedData.length > 0) {
                return this.fetchedData[0].color;
            }

            return (typeof this.selected !== 'undefined') && this.messagesArray[this.selected]?.color? this.messagesArray[this.selected].color : 'green';
        },
        getSelectedText() {
            if(this.messagesArray && this.messagesArray.length === 0 && this.fetchedData && this.fetchedData.length > 0) {
                return this.fetchedData[0].message;
            }

            return (typeof this.selected !== 'undefined') && this.messagesArray[this.selected]?.message? this.messagesArray[this.selected].message : '<< No alerts >>';
        }
    }
};
</script>