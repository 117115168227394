import { Line, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Line,
  mixins: [reactiveProp],
  props: ['chartData', 'options'],
  mounted () {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
    
    //force chart refresh... Sheldon did this since his chart wasn't updating!
    //setInterval(this.refresh,1000);
  },
  methods:{
    refresh: function(){
      this.renderChart(this.chartData, this.options);
    }
  }
}