var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal",
      attrs: { id: "commentModal", tabindex: "-1", role: "dialog" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-lg", attrs: { role: "document" } },
        [
          _c(
            "div",
            {
              staticClass:
                "modal-content main-container div-border px-2 modal-bg",
            },
            [
              _c(
                "div",
                {
                  staticClass: "modal-header",
                  staticStyle: { "border-bottom-width": "0px" },
                },
                [
                  _c("job-info-header", {
                    ref: "header_ref",
                    attrs: {
                      jobData: _vm.jobData,
                      onChangeValues: _vm.getComments,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body py-0" }, [
                !_vm.isCommentsVisible
                  ? _c("div", { staticClass: "form-group" }, [
                      _c(
                        "label",
                        {
                          staticClass: "primary-txt",
                          attrs: { for: "commentInput" },
                        },
                        [
                          _vm._v("Please enter the comment below "),
                          _c("br"),
                          _c("span", { staticClass: "text-danger" }, [
                            _vm._v(_vm._s(_vm.error)),
                          ]),
                          _c("span", { staticClass: "text-success" }, [
                            _vm._v(_vm._s(_vm.success)),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("textarea", {
                        staticClass: "form-control div-border",
                        attrs: { id: "textarea", rows: "6" },
                      }),
                    ])
                  : _c(
                      "div",
                      { staticClass: "scrollmenu pt-2 px-2 pb-0 div-border" },
                      [
                        _vm._l(_vm.commentData, function (comment, index) {
                          return _c("comment-card", {
                            key: index,
                            attrs: {
                              commentInfo: comment,
                              isEditable: _vm.username === comment.user,
                              onSavePressed: (commentObject) =>
                                _vm.onCommentPost(commentObject),
                            },
                          })
                        }),
                        _vm._v(" "),
                        _vm.loading
                          ? _c("div", { staticClass: "pb-2 txt-dark" }, [
                              _vm._v("Loading..."),
                            ])
                          : _vm.commentData.length === 0
                          ? _c("div", { staticClass: "pb-2 txt-dark" }, [
                              _vm._v("No comments"),
                            ])
                          : _vm._e(),
                      ],
                      2
                    ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal-footer",
                  staticStyle: { "border-top-width": "0px" },
                },
                [
                  !_vm.showingAllComments
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "text-primary pointer-cursor link-txt-color",
                          on: {
                            click: () => {
                              if (_vm.isCommentsVisible == false) {
                                _vm.onViewAllCommentsPressed()
                              } else {
                                _vm.onGetJobCommentsPressed()
                              }
                            },
                          },
                        },
                        [
                          _c("u", [
                            _vm._v(
                              _vm._s(
                                _vm.isCommentsVisible
                                  ? "Show all comments for Job"
                                  : "View All Comments"
                              )
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-grow-1" }),
                  _vm._v(" "),
                  !_vm.isCommentsVisible
                    ? _c("div", [
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-secondary grey-button",
                            attrs: { type: "button", "data-dismiss": "modal" },
                          },
                          [_vm._v("Cancel")]
                        ),
                        _vm._v(" "),
                        _c(
                          "button",
                          {
                            staticClass: "btn btn-success green-button",
                            attrs: { type: "button" },
                            on: { click: () => _vm.onCommentPost() },
                          },
                          [_vm._v("Save")]
                        ),
                      ])
                    : _c("div", [
                        _c(
                          "div",
                          {
                            on: {
                              click: () => {
                                if (_vm.showingAllComments) {
                                  _vm.onViewAllCommentsPressed()
                                } else {
                                  _vm.isVisibleComments(false)
                                }
                              },
                            },
                          },
                          [
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-success green-button",
                                attrs: { type: "button" },
                              },
                              [_vm._v("Back")]
                            ),
                          ]
                        ),
                      ]),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }