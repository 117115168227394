<template>
    <div>
        <table class="table table-sm table-striped text-left" v-bind:class="tableColor" style="display:block;">
            <thead style="display:block;">
                <th style="width:20%;">Time</th>
                <th style="width:35%;">Event</th>
                <th style="width:15%;">Well</th>
                <th style="width:10%;">Stage</th>
                <th style="width:25%;">Requested by</th>
                <th v-if="detailed">Requester Role</th>
                <th v-if="detailed">Company Approval</th>
                <th v-if="detailed">Frac User</th>
                <th v-if="detailed">Wireline User</th>
            </thead>
            <tbody style="display:block;overflow-y:auto;" v-bind:style="{'height' : externalHeight? externalHeight :'230px'}" class="scrollbar-color">
                <tr v-if="wells[handshake.wellNumber]" v-for="(handshake, index) in handshakes">
                    <td style="width:18%;">{{handshake.endTime | shortTime}}</td>
                    <td style="width:35%;">{{handshake.statusMessage}}</td>
                    <td  style="width:15%;" :style="{'background-color':wells[handshake.wellNumber].color,'color':textColors[handshake.wellNumber]}">{{wells[handshake.wellNumber].name}}</td>
                    <td style="width:10%;">{{handshake.stageNumber}}</td>
                    <td>{{handshake.requesterUser}}</td>
                    <td v-if="detailed">{{handshake.requesterRole}}</td>
                    <td v-if="detailed">{{handshake.wsmUser}}</td>
                    <td v-if="detailed">{{handshake.fracUser}}</td>
                    <td v-if="detailed">{{handshake.wirelineUser}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                message:"this is the handshake table component"
            };
		},
        props:['handshakes','detailed','wells', 'externalHeight'],
        computed:{
            textColors: function(){
                var c = [];
                var self = this;
                for(var i=0;i<self.wells.length;i++){
                    c.push(self.getTitleColorForBG(self.wells[i].color));
                }
                return c;
						},
						tableColor: function () {
							return {
								'table-dark': this.$root.darkMode
							}
						}
        },
        methods: {
            getColorLuminosity: function(hexCode) {
                var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hexCode);
                var rgb = result && result[1] && result[2] && result[3] ? [
                    parseInt(result[1], 16),
                    parseInt(result[2], 16),
                    parseInt(result[3], 16)
                ] : null;

                if(!rgb) {
                    //can't determine luminosity
                    return null;
                }

                var lrgb = [];
                rgb.forEach(function(c) {
                    c = c / 255.0;
                    if (c <= 0.03928) {
                        c = c / 12.92;
                    } else {
                        c = Math.pow((c + 0.055) / 1.055, 2.4);
                    }
                    lrgb.push(c);
                });
                
                //returns luminosity in first index, raw rgb in second index
                return [0.2126 * lrgb[0] + 0.7152 * lrgb[1] + 0.0722 * lrgb[2], rgb];
            },
            getTitleColorForBG: function(hexCode) {
                var lum = this.getColorLuminosity(hexCode);
                return !lum || (lum[0] > 0.179) ? '#000000' : '#ffffff';
            }
        },
        filters:{
            shortTime: function(string){
                if(string)
                    return string.substring(0,19);
                return '';
            }
        }
    }
</script>