var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      {
        staticClass: "nav nav-pills",
        attrs: { id: "pills-tab", role: "tablist" },
      },
      _vm._l(_vm.tabs, function (tab, index) {
        return _c(
          "li",
          {
            ref: `tab_${index}`,
            refInFor: true,
            staticClass: "nav-item",
            on: {
              click: function ($event) {
                return _vm.handleClick(tab, index)
              },
            },
          },
          [
            _c(
              "a",
              {
                staticClass: "nav-link",
                class: {
                  active: index == _vm.openIndex,
                  disabled: tab.enabled === false,
                },
                attrs: {
                  id: `#tab_title_${index}`,
                  "data-toggle": "pill",
                  href: `#tab_body_${index}`,
                  role: "tab",
                  "aria-controls": `#tab_body_${index}`,
                  "aria-selected": index == _vm.openIndex,
                },
              },
              [
                _vm._v(
                  "\r\n                " +
                    _vm._s(tab.title) +
                    "\r\n            "
                ),
              ]
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _vm.showBody
      ? _c(
          "div",
          { staticClass: "tab-content", attrs: { id: "pills-tabContent" } },
          _vm._l(_vm.tabs, function (tab, index) {
            return _c(
              "div",
              {
                staticClass: "tab-pane fade",
                class: { "show active": index == _vm.openIndex },
                attrs: {
                  id: `tab_body_${index}`,
                  role: "tabpanel",
                  "aria-labelledby": `#tab_title_${index}`,
                },
              },
              [
                !_vm.lazyLoad || _vm.openIndex === index
                  ? _c(
                      "div",
                      [
                        _vm._t(`tab_${index}`, function () {
                          return [
                            _vm._v(
                              "\r\n                    Tab " +
                                _vm._s(index) +
                                "\r\n                "
                            ),
                          ]
                        }),
                      ],
                      2
                    )
                  : _vm._e(),
              ]
            )
          }),
          0
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }