var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "row ml-5 mb-1",
      class: {
        "p-1 bg-light":
          _vm.structure.name == "name" || _vm.structure.name == "number",
      },
    },
    [
      _vm.leafNode
        ? _c("div", { staticClass: "col" }, [
            _vm._v("\n    " + _vm._s(_vm.structure.name) + "\n  "),
          ])
        : _c(
            "div",
            { staticClass: "col" },
            [
              _vm._v("\n    " + _vm._s(_vm.structure.name) + "\n    "),
              _vm._l(_vm.structure.children, function (item) {
                return _c("structure-node-component", {
                  key: item.name + parseInt(Math.random() * 1000000),
                  attrs: {
                    structure: item,
                    parents: _vm.breadcrumb,
                    data: _vm.data,
                  },
                  on: { valueChange: _vm.onValueChange },
                })
              }),
            ],
            2
          ),
      _vm._v(" "),
      _vm.leafNode
        ? _c(
            "div",
            { staticClass: "col-3" },
            [
              _vm.leafNode
                ? [
                    _vm.structure.dataType == "number"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.value,
                              expression: "value",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: { type: "number" },
                          domProps: { value: _vm.value },
                          on: {
                            change: function ($event) {
                              return _vm.valueChange($event)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.value = $event.target.value
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.structure.dataType == "string"
                      ? _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.value,
                              expression: "value",
                            },
                          ],
                          staticClass: "form-control form-control-sm",
                          attrs: { type: "text" },
                          domProps: { value: _vm.value },
                          on: {
                            change: function ($event) {
                              return _vm.valueChange($event)
                            },
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.value = $event.target.value
                            },
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.structure.dataType == "boolean"
                      ? _c("div", { staticClass: "form-group form-check" }, [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.value,
                                expression: "value",
                              },
                            ],
                            staticClass: "form-check-input",
                            attrs: { type: "checkbox" },
                            domProps: {
                              checked: Array.isArray(_vm.value)
                                ? _vm._i(_vm.value, null) > -1
                                : _vm.value,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  var $$a = _vm.value,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 && (_vm.value = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.value = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.value = $$c
                                  }
                                },
                                function ($event) {
                                  return _vm.valueChange($event)
                                },
                              ],
                            },
                          }),
                          _vm._v(" "),
                          _c("label", { staticClass: "form-check-label" }),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.structure.dataType == "choice"
                      ? _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.value,
                                expression: "value",
                              },
                            ],
                            staticClass: "form-control form-control-sm",
                            on: {
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.value = $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.valueChange($event)
                                },
                              ],
                            },
                          },
                          [
                            _c("option", [_vm._v("-")]),
                            _vm._v(" "),
                            _vm._l(_vm.structure.choices, function (choice) {
                              return _c(
                                "option",
                                {
                                  key:
                                    "choice" +
                                    choice +
                                    parseInt(Math.random() * 10000000000),
                                },
                                [_vm._v(_vm._s(choice))]
                              )
                            }),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.structure.dataType == "linkToExisting"
                      ? _c("div", { staticClass: "row" }, [
                          _vm.value
                            ? _c(
                                "a",
                                {
                                  staticClass: "col btn btn-primary btn-sm m-1",
                                  attrs: { href: "/metadata/" + _vm.value },
                                },
                                [_vm._v("Open")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "col btn btn-primary btn-sm m-1",
                              on: { click: _vm.editLink },
                            },
                            [
                              _vm.gettingOptions
                                ? _c("span", [_vm._v("Getting options...")])
                                : _c("span", [_vm._v("Edit")]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]
                : _vm._e(),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: {
            id: "selectLinkModal",
            tabindex: "-1",
            "aria-labelledby": "selectLinkModalLabel",
            "aria-hidden": "true",
          },
        },
        [
          _c("div", { staticClass: "modal-dialog" }, [
            _c("div", { staticClass: "modal-content" }, [
              _vm._m(0),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedLink,
                        expression: "selectedLink",
                      },
                    ],
                    staticClass: "form-control",
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.selectedLink = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  _vm._l(_vm.linkOptions, function (option) {
                    return _c(
                      "option",
                      { key: option.id, domProps: { value: option.id } },
                      [_vm._v(_vm._s(option.data))]
                    )
                  }),
                  0
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button", "data-dismiss": "modal" },
                  },
                  [_vm._v("Cancel")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: { click: _vm.updateLink },
                  },
                  [_vm._v("Okay")]
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header text-light" }, [
      _c(
        "h5",
        { staticClass: "modal-title", attrs: { id: "exampleModalLabel" } },
        [_vm._v("Select item to link")]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: {
            type: "button",
            "data-dismiss": "modal",
            "aria-label": "Close",
          },
        },
        [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }