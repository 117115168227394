var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mb-2" }, [
    _c("div", { staticClass: "col-6" }, [
      _vm._v("\n        " + _vm._s(_vm.descriptionDisplayed) + "\n    "),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "col-6" }, [
      (this.dataType == "number" || this.dataType == "string") && !this.select
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.newValue,
                expression: "newValue",
              },
            ],
            staticClass: "form-control",
            attrs: { type: "text" },
            domProps: { value: _vm.newValue },
            on: {
              change: function ($event) {
                return _vm.handleChange()
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.newValue = $event.target.value
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      this.dataType == "boolean"
        ? _c("span", [_vm._v("Current setting:")])
        : _vm._e(),
      _vm._v(" "),
      this.dataType == "boolean" && this.value
        ? _c("span", { staticClass: "alert alert-success" }, [_vm._v("ON")])
        : _vm._e(),
      _vm._v(" "),
      this.dataType == "boolean" && !this.value
        ? _c("span", { staticClass: "alert alert-danger" }, [_vm._v("OFF")])
        : _vm._e(),
      _vm._v(" "),
      this.dataType == "boolean" && !this.value
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  return _vm.on()
                },
              },
            },
            [_vm._v(" Turn ON")]
          )
        : _vm._e(),
      _vm._v(" "),
      this.dataType == "boolean" && this.value
        ? _c(
            "button",
            {
              staticClass: "btn btn-primary",
              on: {
                click: function ($event) {
                  return _vm.off()
                },
              },
            },
            [_vm._v(" Turn OFF")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.select
        ? _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.newValue,
                  expression: "newValue",
                },
              ],
              staticClass: "form-control",
              on: {
                change: [
                  function ($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function (o) {
                        return o.selected
                      })
                      .map(function (o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.newValue = $event.target.multiple
                      ? $$selectedVal
                      : $$selectedVal[0]
                  },
                  function ($event) {
                    return _vm.handleChange()
                  },
                ],
              },
            },
            _vm._l(_vm.options, function (option) {
              return _c("option", { domProps: { value: option } }, [
                _vm._v(_vm._s(option)),
              ])
            }),
            0
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }