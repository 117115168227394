var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkbox-list-container", style: _vm.getContainerStyle() },
    [
      _vm.enableSearch
        ? _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchTerm,
                expression: "searchTerm",
              },
            ],
            staticClass: "form-control form-control-sm my-2 searchInput",
            class: _vm.backGroundColorClass,
            attrs: { type: "text", placeholder: "Search..." },
            domProps: { value: _vm.searchTerm },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchTerm = $event.target.value
              },
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.filteredOptions.length === 0 || _vm.options.length === 0
        ? _c("div", [
            _c(
              "p",
              {
                staticClass: "text-center m-0",
                style: `color: ${_vm.isDark ? "#fff" : "#000"}`,
              },
              [_vm._v(_vm._s(_vm.emptyText))]
            ),
          ])
        : _c("div", { style: _vm.getListStyle() }, [
            _c("div", { staticClass: "checkboxLabel" }, [
              _vm.label.length > 0
                ? _c("b", [_vm._v(_vm._s(_vm.label))])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "pl-2" }),
            ]),
            _vm._v(" "),
            _vm.enableSelectAllOption &&
            (_vm.filteredOptions.length > 0 || _vm.options.length > 0)
              ? _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-row w-100 align-items-center checkboxList",
                  },
                  [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.selectAll,
                          expression: "selectAll",
                        },
                      ],
                      staticClass: "mr-2",
                      class: _vm.backGroundColorClass,
                      attrs: { type: "checkbox" },
                      domProps: {
                        checked: Array.isArray(_vm.selectAll)
                          ? _vm._i(_vm.selectAll, null) > -1
                          : _vm.selectAll,
                      },
                      on: {
                        change: [
                          function ($event) {
                            var $$a = _vm.selectAll,
                              $$el = $event.target,
                              $$c = $$el.checked ? true : false
                            if (Array.isArray($$a)) {
                              var $$v = null,
                                $$i = _vm._i($$a, $$v)
                              if ($$el.checked) {
                                $$i < 0 && (_vm.selectAll = $$a.concat([$$v]))
                              } else {
                                $$i > -1 &&
                                  (_vm.selectAll = $$a
                                    .slice(0, $$i)
                                    .concat($$a.slice($$i + 1)))
                              }
                            } else {
                              _vm.selectAll = $$c
                            }
                          },
                          _vm.toggleSelectAll,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c("span", [
                      _vm._v(
                        "\n                    " +
                          _vm._s("Select all") +
                          "\n            "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.valueKey
              ? _c(
                  "div",
                  _vm._l(_vm.filteredOptions, function (option, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass:
                          "d-flex flex-row w-100 align-items-center checkboxList",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.optionStates[option[_vm.outputKey]],
                              expression: "optionStates[option[outputKey]]",
                            },
                          ],
                          staticClass: "mr-2",
                          class: _vm.backGroundColorClass,
                          attrs: {
                            type: "checkbox",
                            id: option[_vm.outputKey],
                            disabled: _vm.isOptionDisabled(
                              option[_vm.outputKey]
                            ),
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.optionStates[option[_vm.outputKey]]
                            )
                              ? _vm._i(
                                  _vm.optionStates[option[_vm.outputKey]],
                                  null
                                ) > -1
                              : _vm.optionStates[option[_vm.outputKey]],
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.toggleFocus()
                            },
                            blur: function ($event) {
                              return _vm.toggleFocus()
                            },
                            change: [
                              function ($event) {
                                var $$a =
                                    _vm.optionStates[option[_vm.outputKey]],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.optionStates,
                                        option[_vm.outputKey],
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.optionStates,
                                        option[_vm.outputKey],
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.optionStates,
                                    option[_vm.outputKey],
                                    $$c
                                  )
                                }
                              },
                              function ($event) {
                                return _vm.toggleState($event)
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c("span", {}, [
                          _vm._v(
                            "\n                    " +
                              _vm._s(option[_vm.valueKey])
                          ),
                        ]),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  _vm._l(_vm.options, function (option, index) {
                    return _c("div", { key: index, staticClass: "pl-1 pr-1" }, [
                      _c("label", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.optionStates[option],
                              expression: "optionStates[option]",
                            },
                          ],
                          class: _vm.backGroundColorClass,
                          attrs: {
                            type: "checkbox",
                            id: option,
                            disabled: _vm.isOptionDisabled(option),
                          },
                          domProps: {
                            checked: Array.isArray(_vm.optionStates[option])
                              ? _vm._i(_vm.optionStates[option], null) > -1
                              : _vm.optionStates[option],
                          },
                          on: {
                            focus: function ($event) {
                              return _vm.toggleFocus()
                            },
                            blur: function ($event) {
                              return _vm.toggleFocus()
                            },
                            change: [
                              function ($event) {
                                var $$a = _vm.optionStates[option],
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.optionStates,
                                        option,
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.optionStates,
                                        option,
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(_vm.optionStates, option, $$c)
                                }
                              },
                              function ($event) {
                                return _vm.toggleState($event)
                              },
                            ],
                          },
                        }),
                        _vm._v(
                          "\n                    " +
                            _vm._s(
                              _vm.alternateOptionLabels
                                ? _vm.alternateOptionLabels[index]
                                : option
                            ) +
                            "\n                "
                        ),
                      ]),
                    ])
                  }),
                  0
                ),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }