var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "col" }, [
    _vm._v("\n  remote control\n  "),
    _c("div", { staticClass: "form-group" }, [
      _c("label", [_vm._v("Job")]),
      _vm._v(" "),
      _c(
        "select",
        {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.jobId,
              expression: "jobId",
            },
          ],
          staticClass: "form-control",
          on: {
            change: function ($event) {
              var $$selectedVal = Array.prototype.filter
                .call($event.target.options, function (o) {
                  return o.selected
                })
                .map(function (o) {
                  var val = "_value" in o ? o._value : o.value
                  return val
                })
              _vm.jobId = $event.target.multiple
                ? $$selectedVal
                : $$selectedVal[0]
            },
          },
        },
        _vm._l(_vm.jobs, function (job) {
          return _c(
            "option",
            { key: "job" + job.id, domProps: { value: job.id } },
            [_vm._v(_vm._s(job.jobNumber) + " - " + _vm._s(job.location))]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _vm.jobId
      ? _c("div", { staticClass: "card bg-light m-2" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("\n      Request remote control token\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Actions (csv)")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.actions,
                    expression: "actions",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.actions },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.actions = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.actions.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.requestToken },
                  },
                  [_vm._v("Request")]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.jobId
      ? _c("div", { staticClass: "card bg-light m-2" }, [
          _c("div", { staticClass: "card-header" }, [
            _vm._v("\n      Remote command\n    "),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "card-body" }, [
            _c("div", { staticClass: "form-group" }, [
              _c("label", [_vm._v("Action")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.action,
                    expression: "action",
                  },
                ],
                staticClass: "form-control",
                attrs: { type: "text" },
                domProps: { value: _vm.action },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.action = $event.target.value
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _vm.action.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "btn btn-primary",
                    on: { click: _vm.sendCommand },
                  },
                  [_vm._v("Send command")]
                )
              : _vm._e(),
          ]),
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.response
      ? _c("div", { staticClass: "alert alert-secondary m-2" }, [
          _vm._v("\n    " + _vm._s(_vm.response) + "\n  "),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }