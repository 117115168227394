var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: "containerHeight",
      staticClass: "d-flex flex-column",
      style: "background-color:white; height:" + _vm.height + "px",
    },
    [
      _c("div", [
        _c(
          "div",
          {
            ref: "filtersHeader",
            staticClass: "row px-2 pb-2 pt-2",
            style: _vm.isLoadingPads ? "background-color:rgba(0,0,0,0.5);" : "",
          },
          [
            _c("div", { staticClass: "col-10" }, [
              _c("div", { staticClass: "row w-100" }, [
                _c("div", { staticClass: "col" }, [
                  _c("div", [
                    _c("div", { staticClass: "pb-2 selectlabel" }, [
                      _vm._v(
                        "\n                                Select Pad(s)\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("multi-select", {
                          key: _vm.refreshTicker,
                          attrs: {
                            id: "selectedPads",
                            options: _vm.receivedPads,
                            value: _vm.selectedPads,
                            label: "label",
                            disabled: _vm.isLoadingPads,
                            maxHeight: _vm.dropdownHeight,
                            sortByKey: _vm.sortPadDropdownByKey,
                            isReverseSort: _vm.isReverseSort,
                          },
                          on: { selectedupdated: _vm.padsSelected },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c(
                          "b-form-checkbox",
                          {
                            staticClass: "mt-2",
                            attrs: { value: true, "unchecked-value": false },
                            model: {
                              value: _vm.sortByJobStartDate,
                              callback: function ($$v) {
                                _vm.sortByJobStartDate = $$v
                              },
                              expression: "sortByJobStartDate",
                            },
                          },
                          [
                            _vm._v(
                              "\n                                    Sort by Job Start Date\n                                "
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", [
                    _c("div", { staticClass: "pb-2 selectlabel" }, [
                      _vm._v(
                        "\n                                Select Well(s)\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("multi-select", {
                          key: _vm.refreshTicker,
                          attrs: {
                            id: "selectedWells",
                            options: _vm.selectableWells,
                            value: _vm.selectedWells,
                            hasSubLabels: true,
                            disabled: _vm.selectedPads.length == 0,
                            label: "wellName",
                            maxHeight: _vm.dropdownHeight,
                          },
                          on: { selectedupdated: _vm.wellsSelected },
                        }),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "my-1",
                            attrs: {
                              size: "sm",
                              disabled:
                                _vm.selectableWells.length == 0 ||
                                _vm.selectedPads.length == 0,
                              variant: "secondary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectAllWells()
                              },
                            },
                          },
                          [_vm._v("All")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "my-1 ml-1",
                            attrs: {
                              size: "sm",
                              disabled:
                                _vm.selectedPads.length == 0 ||
                                _vm.selectedWells.length == 0,
                              variant: "secondary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedWells = []
                              },
                            },
                          },
                          [_vm._v("Clear")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", [
                    _c("div", { staticClass: "pb-2 selectlabel" }, [
                      _vm._v(
                        "\n                                Index Data By\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("multi-select", {
                          key: _vm.refreshTicker,
                          attrs: {
                            id: "selectedIndices",
                            options: _vm.receivedIndices,
                            value: _vm.selectedIndices,
                            selected: _vm.selectedIndices,
                            disabled: _vm.selectedPads.length == 0,
                            multiSelect: false,
                            allowEmpty: false,
                          },
                          on: { selectedupdated: _vm.indicesSelected },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", [
                    _c("div", { staticClass: "pb-2 selectlabel" }, [
                      _vm._v(
                        "\n                                Select Stage(s)\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("multi-select", {
                          key: _vm.refreshTicker,
                          attrs: {
                            id: "selectedStages",
                            options: _vm.stageOptions,
                            multiSelect: true,
                            value: _vm.selectedStages,
                            maxHeight: _vm.dropdownHeight,
                            hasSubLabels: true,
                            disabled:
                              _vm.selectedWells.length == 0 ||
                              _vm.selectedIndices.length == 0 ||
                              _vm.templateData.loadLatestStage == 1,
                          },
                          on: { selectedupdated: _vm.stagesSelected },
                        }),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "my-1",
                            attrs: {
                              size: "sm",
                              disabled:
                                _vm.stageOptions.length == 0 ||
                                _vm.selectedWells.length == 0,
                              variant: "secondary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.selectAllStages()
                              },
                            },
                          },
                          [_vm._v("All")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "my-1 ml-1",
                            attrs: {
                              size: "sm",
                              disabled:
                                _vm.stageOptions.length == 0 ||
                                _vm.selectedWells.length == 0,
                              variant: "secondary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedStages = []
                              },
                            },
                          },
                          [_vm._v("Clear")]
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "label",
                      { staticClass: "font-90", attrs: { for: "" } },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.templateData.loadLatestStage,
                              expression: "templateData.loadLatestStage",
                            },
                          ],
                          staticClass: "mr-1",
                          attrs: {
                            type: "checkbox",
                            disabled:
                              _vm.selectedWells.length == 0 ||
                              _vm.selectedIndices.length == 0,
                          },
                          domProps: {
                            checked: Array.isArray(
                              _vm.templateData.loadLatestStage
                            )
                              ? _vm._i(_vm.templateData.loadLatestStage, null) >
                                -1
                              : _vm.templateData.loadLatestStage,
                          },
                          on: {
                            change: [
                              function ($event) {
                                var $$a = _vm.templateData.loadLatestStage,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        _vm.templateData,
                                        "loadLatestStage",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        _vm.templateData,
                                        "loadLatestStage",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(
                                    _vm.templateData,
                                    "loadLatestStage",
                                    $$c
                                  )
                                }
                              },
                              function ($event) {
                                return _vm.selectLatestStages()
                              },
                            ],
                          },
                        }),
                        _vm._v("\n                            Last Complete"),
                      ]
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", [
                    _c("div", { staticClass: "pb-2 selectlabel" }, [
                      _vm._v(
                        "\n                                Select Channel(s)\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      "div",
                      [
                        _c("multi-select", {
                          key: _vm.refreshTicker,
                          attrs: {
                            id: "selectedChannels",
                            options: _vm.receivedChannels,
                            value: _vm.selectedChannels,
                            label: "prioritizedTagName",
                            selectAllDuplicates: true,
                            removeDuplicatesKey: "name",
                            maxHeight: _vm.dropdownHeight,
                            uniqueId: "id",
                            trackByVal: "name",
                            disabled:
                              _vm.selectedPads.length == 0 ||
                              _vm.isLoadingChannels,
                          },
                          on: { selectedupdated: _vm.channelsSelected },
                        }),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "my-1",
                            attrs: {
                              size: "sm",
                              disabled:
                                _vm.isLoadingChannels ||
                                _vm.selectedPads.length == 0 ||
                                _vm.receivedChannels.length == 0,
                              variant: "secondary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedChannels = [..._vm.receivedChannels]
                              },
                            },
                          },
                          [_vm._v("All")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "my-1 ml-1",
                            attrs: {
                              size: "sm",
                              disabled:
                                _vm.isLoadingChannels ||
                                _vm.selectedPads.length == 0 ||
                                _vm.receivedChannels.length == 0 ||
                                _vm.selectedChannels.length == 0,
                              variant: "secondary",
                            },
                            on: {
                              click: function ($event) {
                                _vm.selectedChannels = []
                              },
                            },
                          },
                          [_vm._v("Clear")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "col" }, [
                  _c("div", [
                    _c("div", { staticClass: "pb-2 selectlabel" }, [
                      _vm._v(
                        "\n                                Aggregate Interval\n                            "
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "controlBorder" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.aggregateInterval,
                              expression: "aggregateInterval",
                            },
                          ],
                          staticClass: "form-control",
                          attrs: {
                            id: "aggregateSelect",
                            disabled: _vm.isLoadingPads || _vm.isLoading,
                          },
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.aggregateInterval = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                          },
                        },
                        _vm._l(
                          _vm.dashboardData.aggregateOptions,
                          function (option, index) {
                            return _c(
                              "option",
                              { key: index, domProps: { value: option.value } },
                              [_vm._v(_vm._s(option.name))]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "col-2" }, [
              _c("div", {
                staticClass: "d-flex justify-content-end pr-3 pb-2",
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "d-flex row justify-content-left w-100" },
                [
                  _c("div", { staticClass: "d-flex flex-column mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-secondary grey-button btnSizeOverride mr-2",
                        attrs: { disabled: _vm.isLoadingPads },
                        on: {
                          click: function ($event) {
                            return _vm.clearSelections()
                          },
                        },
                      },
                      [_vm._v("Clear All Filters")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-secondary grey-button btnSizeOverride mr-2",
                        attrs: {
                          disabled: _vm.isLoadingPads,
                          "data-backdrop": "static",
                          "data-keyboard": "false",
                        },
                        on: {
                          click: function ($event) {
                            _vm.modalVisible = true
                          },
                        },
                      },
                      [_vm._v("Template Settings")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-flex flex-column mt-3" }, [
                    _c(
                      "button",
                      {
                        staticClass:
                          "btn btn-secondary grey-button btnSizeOverride mr-2",
                        attrs: { disabled: _vm.isLoadingPads },
                        on: { click: _vm.validateAndFetchData },
                      },
                      [_vm._v("Load Chart")]
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mt-2" },
                [
                  _c(
                    "b-form-checkbox",
                    {
                      staticStyle: { color: "black" },
                      attrs: { disabled: _vm.isLoadingPads },
                      model: {
                        value: _vm.showLegend,
                        callback: function ($$v) {
                          _vm.showLegend = $$v
                        },
                        expression: "showLegend",
                      },
                    },
                    [
                      _vm._v(
                        "\n                        Show Legend\n                    "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _vm._v(" "),
      _vm.isLoadingPads
        ? _c("div", [
            _c("div", {
              staticClass: "spinner-border spinner-border-sm",
              staticStyle: {
                color: "black",
                position: "absolute",
                "z-index": "100",
                top: "5%",
                left: "50%",
                "margin-left": "-5px",
              },
              attrs: { role: "status" },
            }),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex flex-column flex-grow-1" }, [
        _vm.isLoading ? _c("div", [_vm._m(0)]) : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            ref: "settingsHeader",
            staticClass: "d-flex justify-content-left w-50",
          },
          [
            _c("div", { staticClass: "show-clicker-finger" }, [
              _c("i", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip:top",
                    value: "Return to Default Axis Settings",
                    expression: "'Return to Default Axis Settings'",
                    arg: "top",
                  },
                ],
                staticClass: "fa fa-undo px-2",
                class: { "icon-disabled": _vm.isLoadingPads },
                attrs: { disabled: _vm.isLoadingPads },
                on: {
                  click: function ($event) {
                    return _vm.returnToDefaultsMethod()
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "show-clicker-finger",
                  on: {
                    click: function ($event) {
                      return _vm.openAxisOptionsModal()
                    },
                  },
                },
                [
                  _c("i", {
                    directives: [
                      {
                        name: "tooltip",
                        rawName: "v-tooltip:top",
                        value: "Y-axis Options",
                        expression: "'Y-axis Options'",
                        arg: "top",
                      },
                    ],
                    staticClass: "fas fa-cog px-2",
                    class: { "icon-disabled": _vm.isLoadingPads },
                    attrs: {
                      disabled: _vm.isLoadingPads && !_vm.selectedChannels,
                    },
                  }),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              [
                _c("analytic-options-modal", {
                  attrs: {
                    modalVisible: _vm.chartAxisModalVisible,
                    modalData: _vm.axisOptionsModalData,
                    isSaving: _vm.isSaving,
                  },
                  on: {
                    onDismiss: function ($event) {
                      _vm.chartAxisModalVisible = false
                    },
                    onApplyOptionChanges: _vm.applyOptionChanges,
                  },
                }),
              ],
              1
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          { style: _vm.chartStyle },
          [
            _c("lightning-chart", {
              ref: "lightningChart",
              attrs: {
                height: _vm.canvasHeight,
                showLegend: _vm.showLegend,
                selectedIndex: _vm.selectedIndex,
                chartOptions: _vm.chartOptions,
                lockToMinChannelsIds: _vm.lockToMinChannelIds,
              },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("stage-comparison-settings-modal", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.isLoadingPads,
            expression: "!isLoadingPads",
          },
        ],
        ref: "settingsModal",
        attrs: {
          modalVisible: _vm.modalVisible,
          chartType: "SCD",
          templateData: _vm.templateData,
          isAdmin: _vm.isAdmin,
          userid: _vm.userid,
          dashboardInfo: _vm.dashboardInfo,
          targetTemplateId: _vm.item.targetTemplateId
            ? _vm.item.targetTemplateId
            : null,
        },
        on: {
          onDismiss: function ($event) {
            _vm.modalVisible = false
          },
          onLoadTemplate: _vm.loadedTemplate,
          onCreatedTemplate: _vm.createdTemplate,
          onUpdatedTemplate: _vm.updatedTemplate,
          onNewTemplate: _vm.newTemplate,
          onDeleteTemplate: _vm.deleteChartTemplate,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "spinner-border spinner-border-sm",
        staticStyle: {
          color: "black",
          position: "absolute",
          "z-index": "100",
          top: "47%",
          left: "50%",
          "margin-left": "-5px",
        },
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "sr-only mb-5" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }