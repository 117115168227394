<!--
    * Component Description
    Wellbore diagram, giving users the ability to view various parts of system (flag) progress down the wellbore

    - Can add points to the diagram that show up from 0 -> some target(variable)
    - Points have a specific tag (See letter in diagram) depending on the point being tracked.
    - As the value for the depth of the point goes down it proceeds down the vertical path of the diagram
    - Once a point hits bottom the point is removed from the diagram* (Functionality may change but good enough for now)
    
-->

<template>
<div>
    <div id="overlay" v-if="!isReady()">
        <div class="errorText rounded" v-if="componentLoading">Loading...</div>
        <div class="errorText rounded" v-else-if="toolstringError">No toolstring set.</div>
        <div class="errorText rounded" v-else-if="noWellInFracError">No well is currently in the frac state.</div>
        <div class="errorText rounded" v-else-if="simFracError">This component is not supported on jobs with simultaneous frac.</div>
        <div class="errorText rounded flex-column" v-else-if="noGunError">
            <div class="pb-2">No shots are fired.</div>
            <b-button  type="button" size="sm" variant="primary" v-on:click="onSetGunPress">Set missed shot</b-button>
        </div>
    </div>
    
    <resolve-shot-modal :isVisible="showShotResolveModal" v-bind="shotResolveModalProps" @onDismiss="()=>{ showShotResolveModal = false }" @onSubmit="onShotResolved" @onDeclineSubmit="()=>{}"/>
    <!-- Header -->
    <div class="badge badge-secondary d-block margin-auto">Wellbore {{isNarrow() ? '' : 'Diagram'}}</div>

    <!-- Add flag drop down -->
    <div class="d-flex pt-2 pb-2" >
        <b-button id="add-flag-popover" size="sm" :disabled="!isReady()">
            Add Flag
        </b-button>

        <b-popover target="add-flag-popover" triggers="hover" placement="bottom">
            <div v-for="(flag,index) in allFlags"
                 :key="flag.text"
                 @click="placeFlag(flag)"
                 class="mb-1">
                <a href='javascript:void(0)'><b>{{flag.text}}</b></a>
            </div>
        </b-popover>

        <div v-if="isFlagLoading" class="pl-2">
            Loading placed flag...
        </div>
        
    </div>

    <!-- Well diagram -->
    <b-row class="text-center" style="overflow-y: auto">
        <b-col :style="{maxWidth: col2maxwidth + '%'}" @mouseenter="isMouseEntered = true" @mouseleave="isMouseEntered = false">
            <div class="wellDiagram rounded" :style="wellDiagramStyle()">
                <!-- Zero-feet label -->
                <div :style="{textAlign:'left', fontSize: '11px', color: 'black', opacity: isReady() ? 1 : 0.5}" class="pl-1">0 ft</div>
                <!-- Flag symbol within circle -->
                <div @mouseenter="isMouseEntered = true"
                     class="flagCircle"
                     v-for="(flag, index) in flagsWithPixelDepth"
                     :id="_uid + 'flag-popover-target-' + index"
                     :key="_uid + 'flag-popover-target-' + index"
                     :style="getFlagStyle(flag)">
                    {{flag.symbol}}
                </div>
            </div>
        </b-col>
        <b-col cols="9" @mouseenter="isMouseEntered = true" @mouseleave="isMouseEntered = false" v-show="!isNarrow()">
            <!-- Marker/text next to flag circle -->
            <div class="rounded flagMarker px-1"
                v-for="(flag, index) in flagsWithPixelDepth"
                :key="'active_flag' + index"
                :id="'marker_' + flag.symbol"
                :style="getMarkerStyle(flag)"
            >
                {{getMarkerText(flag)}}
            </div>
        </b-col>
        
        <!-- Volume label -->
        <div v-if="flushVolume != null"
                :style="{opacity: isReady() ? 1 : 0.5}"
                class="flushVolumeLabel">Wellbore Volume :</div>
        <div v-if="flushVolume != null"
                :style="{opacity: isReady() ? 1 : 0.5}"
                class="flushVolumeValue">{{ flushVolume ? flushVolume.toFixed(0) : "N/A" }} bbl</div>
        <!-- Max-feet label -->
        <div v-if="maxDepth > 0"
                :style="{opacity: isReady() ? 1 : 0.5}"
                class="maxDepthLabel">Top Perf MD :</div>
        <div v-if="maxDepth > 0"
                :style="{opacity: isReady() ? 1 : 0.5}"
                class="maxDepthValue">{{maxDepth? maxDepth.toFixed(0) : 0 }} ft</div>
    </b-row>

    <b-popover 
        v-for="(flag, index) in flagsWithPixelDepth"
        :disabled="!isNarrow()"
        triggers="hover"
        variant="transparent"
        container="transparent-popover-container"
        placement="right"
        :no-fade="true"
        boundary="window"
        :target="_uid + 'flag-popover-target-' + index"
        :key="_uid + 'flag-popover-target-' + index">
        <div :style="{ color:'black', backgroundColor:'#ffffff', padding: '5px', borderRadius: '8px'}">{{getMarkerText(flag)}}</div>
    </b-popover>

</div>
    
</template>

<script>
import GlobalFunctions from '../GlobalFunctions.js';
import { wellboreFlagData } from '../wellboreFlags.js';
import _ from 'lodash';
//https://www.drillingformulas.com/how-does-1029-4-come-from/
//1029.4 is the unit conversion used to convert square inch into bbl/ft.
const SQUARE_INCHES_TO_BBL_PER_FT = 1029.4;

export default {
    props: [
        'height', 
        'width', 
        'dashboardData', 
        'layout'
    ],
    mounted() {
        this.update();
        if(this.boreSections == null) {
            this.fetchBoreSections();
        }
    },
    watch: {
        'dashboardWells': {
            handler: function (newValue, oldValue) {
                const isEqual = _.isEqual(newValue, oldValue);
                if(this.dashboardData.initActivityCallEnded) {
                    const frac = newValue.filter(w => w.activity == 'frac');
                    if(frac.length == 0) {
                        this.noWellInFracError = true;
                    }
                    else if(frac.length > 1) {
                        this.simFracError = true;
                    }
                    else if(frac.length == 1) {
                        this.noWellInFracError = false;
                        this.simFracError = false;
                        this.currentFracWell = frac[0];

                        //Recalc bore and flush volumes
                        if(!isEqual) {
                            this.fetchBoreSections();
                        }

                        if(this.maxDepth != null && this.boreSections != null) {
                            this.flushVolume = this.calcVolumeToBottom(this.maxDepth, this.boreSections);
                        }
                    }
                    
                    if(!isEqual) {
                        this.getMaxDepth();
                    }
                }
            },
            deep: true
        },
        'dashboardData.rawFlagTelemetryData.streamData': {  
            handler: function (newValue, oldValue) {
                this.updateBySignalR();
            },
            deep: true
        },
        'dashboardData.rawFlagTelemetryData': {  
            handler: function (newValue, oldValue) {
                this.updateBySignalR();
            },
            deep: true
        },
        'currentFracWell': {
            handler: function (newValue, oldValue) {
                const isEqual = _.isEqual(newValue, oldValue);
                if(!isEqual && this.placedFlagsDetails.length === 0) {
                    this.getPlacedFlags();
                }
            },
        }
    },
    computed: {
        dashboardWells () {
            return JSON.parse(JSON.stringify(this.dashboardData.wells));
        },
        flagsWithPixelDepth() {
            const componentHeight = this.height - this.heightOffset - this.addButtonHeight;
            const maxDepth = this.maxDepth;
            const copyOfActiveFlags = [...this.activeFlags];
            copyOfActiveFlags.sort((a, b) => a.depth - b.depth );
            let previousTranslateYLabel = null;
            const flagsWithPixelDepth = copyOfActiveFlags.map((item, index)=>{
                const translateY = (item.depth/maxDepth)*componentHeight;
                let translateYLabel = (item.depth/maxDepth)*componentHeight;
                if(index > 0) {
                    const distanceBetweenLabels = (translateYLabel - previousTranslateYLabel) 
                    if(distanceBetweenLabels < 25) {
                        translateYLabel = translateYLabel + (25 - distanceBetweenLabels);
                    }
                }

                previousTranslateYLabel = translateYLabel;

                return {
                    ...item,
                    translateY,
                    translateYLabel
                };
            });
            return flagsWithPixelDepth;
        }
    },
    methods: {
        fetchBoreSections: function() {
            const self = this;
            let url = '/metadata-wells/';

            if(!this.currentFracWell) {
                return ;
            }else{
                url = url + this.currentFracWell.id;            
            }

            $.get(
                url,
                function (data) {
                    if(!data.error) {
                        const dataObject = JSON.parse(data);
                        function toDepthAscSort( a, b ) {
                            if ( a.toDepth < b.toDepth ) {
                                return -1;
                            }
                            if ( a.toDepth > b.toDepth ) {
                                return 1;
                            }
                            return 0;
                        }
                        self.boreSections = dataObject.wellBoreSections ? dataObject.wellBoreSections.sort(toDepthAscSort) : null;
                    }
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                console.warn('Wellbore sections unavailable.');
            });
        },
        calcVolumeToBottom: function(endDepth,boreSections) {
            let vol = 0;
            let depth = 0;
            let prevToDepth = 0;
            boreSections.forEach(section => {
                if(depth>section.toDepth || endDepth<prevToDepth) {
                //skip since the current depth is below this section or endDepth is above this section
                }else{
                    let bottom = section.toDepth
                    if(endDepth < section.toDepth) {
                        bottom = endDepth;
                    }
                    const length = bottom - depth;
                    vol = vol + (section.diameter * section.diameter / SQUARE_INCHES_TO_BBL_PER_FT) * length;
                    depth = section.toDepth;
                }
                prevToDepth = section.toDepth;
            });
            return vol;
        },
        onSetGunPress: function () {
            this.showShotResolveModal = true;

            const {
                name,
                currentStage,
                index
            } = this.currentFracWell;

            //get plug toolstring
            const toolstring = this.toolstring;
            const firstGunToFire = toolstring.toolstring_details.find((gunInfo)=> gunInfo.gunOrPlugIndex == 1);
            toolstring.toolstring_details = firstGunToFire;
            toolstring.toolstring_details.plug = {};
            toolstring.plug = {};

            this.shotResolveModalProps = {
                shot: null,
                isDisabledHeader: true,
                toolstring: toolstring,
                stageNumber: currentStage,
                wellName: name,
                wellIndex: index,
                gunNumber: 1,
                modalType: 'missed-shot',
                plugs: [],
                guns: this.guns,
                hourOffset: this.dashboardData.hourOffset,
                stageToolstring: null
            };
        },
        onShotResolved: function (type, data) {
            if(type === 'missed-shot') {
                const url = '/wireline-history/missed-shot/' + this.dashboardData.jobNumber;
                const body = {
                    '_token': GlobalFunctions.getCSRFToken(),
                    ...data
                };

                $.ajax({
                    method: 'POST',
                    url: url,
                    data: body,
                    dataType: 'json'
                }).done(()=>{
                    location.reload();
                });
            }
        },
        updateBySignalR: async function() {
            if(this.dashboardData.rawFlagTelemetryData.activeFlags.length > 0) {
                this.placedFlagsDetails = [...this.placedFlagsDetails,
                    ...this.dashboardData.rawFlagTelemetryData.activeFlags].filter((value, index, self) => self.indexOf(value) === index); 
            }

            if(this.placedFlagsDetails.length === 0) {
                await this.getPlacedFlags();
            }

            if(this.dashboardData.rawFlagTelemetryData.deletedRef.length > 0) { 
                this.placedFlagsDetails = this.placedFlagsDetails.filter((item) => {
                    return this.dashboardData.rawFlagTelemetryData.deletedRef.find((ref) => ref === item.reference)? false : true;
                });
            }

            if(this.dashboardData.rawFlagTelemetryData.completedRef.length > 0 ) { 
                this.placedFlagsDetails = this.placedFlagsDetails.filter((item) => {
                    return this.dashboardData.rawFlagTelemetryData.completedRef.find((ref) => ref === item.reference)? false : true;
                });
            }

            const liveData = this.placedFlagsDetails.map((item)=>{
                const flagIndexData = this.allFlags.find((flag)=> flag.text.toUpperCase() === item.data.description.toUpperCase());
                const keys = Object.keys(this.dashboardData.rawFlagTelemetryData.streamData).filter((key) => key.includes(item.reference));
                const depthKey = keys.find((key)=> key.includes('_depth'));
                const ttpKey = keys.find((key)=> key.includes('_timeToBottom'));
                return {
                    depthKey,
                    ttpKey,
                    shortLabel: flagIndexData.shortText,
                    color: flagIndexData.color,
                    symbol: flagIndexData.symbol,
                    depth: this.dashboardData.rawFlagTelemetryData.streamData[depthKey],
                    isActive: true,
                    ttp: this.dashboardData.rawFlagTelemetryData.streamData[ttpKey],
                    ...item
                };
            });
            
            this.activeFlags = liveData;
        },
        getPlacedFlags: async function() {
            if(!this.currentFracWell) {
                return;
            }

            const url = '/flags/getPlacedFlags/'
                  + this.dashboardData.jobNumber + '/'
                  + this.currentFracWell.index + '/'
                  + this.currentFracWell.currentStage;

            const self = this;
            
            await $.get(
                url,
                function (data) {
                    self.placedFlagsDetails = data;
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                if(jqXHR.status == 401) {
                    console.warn('unauthorized');
                }
                else {
                    console.error('getPlacedFlags error', errorThrown);                
                    setTimeout(self.getMaxDepth, 2000);
                }
            });
        },
        getMaxDepth: function() {
            if(this.currentFracWell == null) {
                this.componentLoading = this.dashboardData.initActivityCallEnded? false : true;
                return ;
            }
            
            const url = '/flags/getMaxDepth/'
                  + this.dashboardData.jobNumber + '/'
                  + this.currentFracWell.index + '/'
                  + this.currentFracWell.currentStage;

            const self = this;
            $.get(
                url,
                function (data) { 
                    self.noGunError = false;
                    self.toolstringError = false;
                    self.maxDepth = data.firedShot;
                    if(data.error) {
                        self.toolstringError = true;
                    }
                    if(data.firedShot == null) {
                        self.noGunError = true;
                    } else {
                        self.noGunError = false;
                    }
                    self.toolstring = data.toolstring;
                    self.guns = data.guns;
                    self.componentLoading = false;
                },
                'json'
            ).fail(function( jqXHR, textStatus, errorThrown ) {
                self.noGunError = true;
                self.componentLoading = false;
                if(jqXHR.status == 401) {
                    console.warn('unauthorized');
                }
                else {
                    setTimeout(self.getMaxDepth, 2000);
                }
            });
        },
        placeDummyFlag: function(depth) {
            // Do not remove method. Used for debugging for when SignalR or flag processor is down.
            
            this.activeFlags.push({
                shortLabel: "Fake flag",
                color: "#FF0000",
                symbol: "Z",
                depth: depth,
                isActive: true,
                ttp: 200,
            });

            this.maxDepth = 21000;
        },
        placeFlag: function(flag) {
            // this.placeDummyFlag(10000);
            // this.placeDummyFlag(10500);
            
            const url = '/flags/place';
            const self = this;

            if(!this.maxDepth || !this.currentFracWell) {
                return ;
            }
            this.isFlagLoading = true;

            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    'jobNumber': self.dashboardData.jobNumber,
                    'wellNumber': self.currentFracWell.index,
                    'stageNumber': self.currentFracWell.currentStage,
                    'tagName': flag.name,
                    'description': flag.text,
                    'shortDescription': flag.text.split(' ')[0],
                    'endDepth': self.maxDepth
                },
                dataType: 'json'
            })
                .done((data) => {
                    self.isFlagLoading = false;
                });
        },
        isNarrow: function(){
            return this.layout.w < 3;
        },
        getFlagStyle: function(flag) {
            const shouldSpread = this.isMouseEntered;
            
            return {
                color: flag.color,
                zIndex: 100,
                borderColor: flag.color,
                transform: 'translateY(' + (shouldSpread ? flag.translateYLabel : flag.translateY) + 'px)',
                transition: 'transform 330ms ease-in-out'
            };
        },
        getMarkerStyle: function(flag) {
            return {
                whiteSpace: 'nowrap',
                fontSize: '14px',
                marginTop: '5px',
                backgroundColor: flag.color,
                zIndex: 100,
                transform: 'translateY(' + ( this.isMouseEntered ? flag.translateYLabel : flag.translateY ) + 'px)',
                transition: 'transform 330ms ease-in-out'
            };
        },
        getMarkerText: function(flag) {
            return flag.shortLabel + ' ' + (typeof flag?.depth !== 'undefined'? (+flag?.depth)?.toFixed(2) : 0) + 'ft' +
                ' - TTP: ' + (typeof flag?.ttp !== 'undefined'? (+flag?.ttp)?.toFixed(2) : 0)  + 'm';   
        },
        update: function() {
            if(this.maxDepth == null) {
                this.getMaxDepth();
            }

            if(this.flushVolume == null && this.maxDepth != null && this.boreSections != null) {
                this.flushVolume = this.calcVolumeToBottom(this.maxDepth, this.boreSections);
            }

            setTimeout(() => {
                this.update();
            }, 2000);
        },
        isReady: function() {
            // return true;
            return !(this.noGunError || this.noWellInFracError || this.simFracError || !this.maxDepth || this.componentLoading);
        },
        wellDiagramStyle: function() {
            return {
                minHeight: this.height - this.heightOffset + 'px',
                opacity: !this.isReady() ? '0.5' : '1'
            }
        }
    },
    data() {
        return {
            toolstring: null,
            toolstringError: false,
            guns: null,
            placedFlagsDetails: [],
            showShotResolveModal: false,
            shotResolveModalProps: {},
            componentLoading: true,
            isMouseEntered: false,
            noWellInFracError: false,
            isFlagLoading: false,
            noGunError: false,
            simFracError: false,
            currentFracWell: null,
            heightOffset: 70,
            addButtonHeight: 22,
            col2maxwidth: 16.6,
            maxDepth: null,
            flushVolume: null,
            boreSections: null,
            activeFlags: [],
            allFlags: wellboreFlagData
        };
    }
};

</script>

<style scoped>
#transparent-popover-container .popover {
    background-color: transparent;
    border-color: transparent;
}

.maxDepthLabel {
    position: absolute;
    bottom: 32px;
    right: 0px;
    text-align: right;
    font-size: 11px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
}

.maxDepthValue {
    position: absolute;
    bottom: 16px;
    right: 0px;
    text-align: right;
    font-size: 11px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
}

.flushVolumeLabel {
    position: absolute;
    bottom: 64px;
    right: 0px;
    text-align: right;
    font-size: 11px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
}

.flushVolumeValue {
    position: absolute;
    bottom: 48px;
    right: 0px;
    text-align: right;
    font-size: 11px;
    color: white;
    overflow: hidden;
    white-space: nowrap;
}

.gap {
    transform: var(--transform);
}

.gap:hover  {
    transform: var(--transform-hover);
}

.wellDiagram {
    background: #bdbdbd;
    margin-left: 5px;
    width: 50px;
}

.flagCircle {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    font-weight: bold;
    background: #ffffff;
    border: 4px solid #666;
    color: #666;
    text-align: center;
    position: absolute;
    top: 2px;
    margin-left: 10px;
}

.flagCircleHidden {
    opacity: 0;
}

.flagMarker {
    position: absolute;
    top: 2px;
    padding: 2px;
    overflow: hidden;
}

.errorText{
    background-color: rgba(30,30,30,0.7);
    z-index: 500;
    position: absolute;
    top: 50%;
    left: 50%;
    font-weight: bold;
    color: white;
    transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    text-align: center;
    border: 1px solid #a00;
    padding: 20px;
}

</style>

