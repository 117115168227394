var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dark-modal modal",
      attrs: { id: "ToolstringStageModal", tabindex: "-1", role: "dialog" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(
                "\n                    Configure Stage\n\n                    "
              ),
              _c("div", [
                _vm._v(
                  "\n                        This well has " +
                    _vm._s(_vm.max) +
                    " stages\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm._m(0),
          ]),
          _vm._v(" "),
          _vm.from !== undefined && _vm.to !== undefined
            ? _c(
                "div",
                { staticClass: "modal-body" },
                [
                  _c("div", { staticClass: "row justify-content-between" }, [
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("iws-input", {
                          ref: "stageFromInput",
                          attrs: {
                            label: "From *",
                            value: _vm.from,
                            required: "true",
                            type: "number",
                            "error-message": _vm.fromErrorMessage,
                            min: "1",
                            max: _vm.to || _vm.max,
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.from = $event
                            },
                            input: _vm.validateForm,
                            blur: _vm.validateForm,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-6" },
                      [
                        _c("iws-input", {
                          ref: "stageToInput",
                          attrs: {
                            label: "To *",
                            value: _vm.to,
                            required: "true",
                            type: "number",
                            "error-message": _vm.toErrorMessage,
                            min: _vm.from || 1,
                            max: _vm.max,
                          },
                          on: {
                            "update:value": function ($event) {
                              _vm.to = $event
                            },
                            input: _vm.validateForm,
                            blur: _vm.validateForm,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  !!_vm.conflictsWith && !!_vm.conflictsWith.length
                    ? _c("iws-alert", {
                        staticStyle: { "margin-top": "20px" },
                        attrs: { type: "warning" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "body",
                              fn: function () {
                                return [
                                  _c("div", { staticClass: "title" }, [
                                    _vm._v(
                                      "\n                            This action affects " +
                                        _vm._s(_vm.conflictsWith.length) +
                                        " toolstring" +
                                        _vm._s(
                                          _vm.conflictsWith.length > 1
                                            ? "s"
                                            : null
                                        ) +
                                        "\n                        "
                                    ),
                                  ]),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "sub_title" }, [
                                    _vm._v(
                                      "\n                            These settings will affect " +
                                        _vm._s(
                                          _vm.conflictsWith
                                            .map(
                                              (_toolstring) =>
                                                `'${
                                                  _toolstring.name ||
                                                  _toolstring.id
                                                }'`
                                            )
                                            .join(", ")
                                        ) +
                                        ".\n                            Are you sure you want to apply?\n                        "
                                    ),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3864212512
                        ),
                      })
                    : _vm._e(),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "modal-footer" },
            [
              _c("iws-button", {
                attrs: {
                  id: "cancel_button",
                  type: "outline-light",
                  text: "Cancel",
                },
              }),
              _vm._v(" "),
              _c("iws-button", {
                attrs: { text: "Apply", click: _vm.saveAction },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }