var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.show
        ? _c("messages-component", {
            key: _vm.componentKey,
            staticStyle: { position: "fixed" },
            attrs: { allMessages: this.messages, username: _vm.username },
            on: { read: _vm.markRead, delete: _vm.deleteMessage },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("warnings-component", { attrs: { allWarnings: this.warnings } }),
      _vm._v(" "),
      _c("new-warning-component", { attrs: { newWarning: this.newWarning } }),
      _vm._v(" "),
      _c("div", { staticClass: "fixed-bottom" }, [
        _c("div", { staticClass: "navbar bg-secondary p-1" }, [
          _c(
            "button",
            {
              class: _vm.warningButtonClass,
              attrs: {
                type: "button",
                "data-toggle": "collapse",
                "data-target": "#warningsCollapsible",
                "aria-expanded": "false",
                "aria-controls": "warningsCollapsible",
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.warningLabel) +
                  "\n            "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "alert p-1 mb-0 mt-0",
              class: [
                _vm.jobStatus.status == "inProgress"
                  ? "alert-success"
                  : "alert-danger",
              ],
            },
            [_vm._v(_vm._s(_vm.jobStatusString))]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-primary",
              attrs: {
                type: "button",
                "data-toggle": "collapse",
                "data-target": "#messagesCollapsible",
                "aria-expanded": "false",
                "aria-controls": "messagesCollapsible",
              },
              on: {
                click: function ($event) {
                  _vm.updateReadFlag(), _vm.forceRerender()
                },
              },
            },
            [
              _vm._v(
                "\n                " +
                  _vm._s(_vm.messageLabel) +
                  "\n            "
              ),
            ]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal fade",
          attrs: { id: "nodeModal", tabindex: "-1", role: "dialog" },
        },
        [
          _c(
            "div",
            {
              staticClass: "modal-dialog bg-light rounded",
              attrs: { role: "document" },
            },
            [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.dialogHeader)),
                ]),
                _vm._v(" "),
                _vm._m(0),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "modal-body" }, [
                _vm._v("\n          " + _vm._s(_vm.dialogText) + "\n        "),
              ]),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }