var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("LibraryFiltersBaseModal", {
    ref: "libraryFilters",
    attrs: {
      id: _vm.id,
      items: _vm.plugs,
      filters: _vm.filters,
      title: "Filter Plugs",
    },
    on: {
      clearFilters: function ($event) {
        _vm.filters = $event
      },
    },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function ({
          findUniqueValues,
          pushToFilter,
          removeFromFilter,
          handleEvent,
        }) {
          return [
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Description",
                    value: _vm.filters.description,
                    options: findUniqueValues("description"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.description, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.description, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times clickable",
                        on: {
                          click: function ($event) {
                            return removeFromFilter(
                              _vm.filters.description,
                              index
                            )
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Icon",
                    value: _vm.filters.icon,
                    options: findUniqueValues("icon"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.icon, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.icon, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times clickable",
                        on: {
                          click: function ($event) {
                            return removeFromFilter(_vm.filters.icon, index)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Outer Diameter (in)",
                    value: _vm.filters.outer_diameter,
                    options: findUniqueValues("outer_diameter"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.outer_diameter, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.outer_diameter, function (option, index) {
                    return _c(
                      "span",
                      { attrs: { outer_diameter: "badge badge-outline" } },
                      [
                        _vm._v(
                          "\r\n                    " + _vm._s(option) + " "
                        ),
                        _c("i", {
                          staticClass: "fas fa-times clickable",
                          on: {
                            click: function ($event) {
                              return removeFromFilter(
                                _vm.filters.outer_diameter,
                                index
                              )
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Inner Diameter (in)",
                    value: _vm.filters.inner_diameter,
                    options: findUniqueValues("inner_diameter"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.inner_diameter, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.inner_diameter, function (option, index) {
                    return _c(
                      "span",
                      { attrs: { inner_diameter: "badge badge-outline" } },
                      [
                        _vm._v(
                          "\r\n                    " + _vm._s(option) + " "
                        ),
                        _c("i", {
                          staticClass: "fas fa-times clickable",
                          on: {
                            click: function ($event) {
                              return removeFromFilter(
                                _vm.filters.inner_diameter,
                                index
                              )
                            },
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Make",
                    value: _vm.filters.make,
                    options: findUniqueValues("make"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.make, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.make, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times clickable",
                        on: {
                          click: function ($event) {
                            return removeFromFilter(_vm.filters.make, index)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Model",
                    value: _vm.filters.model,
                    options: findUniqueValues("model"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.model, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.model, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times clickable",
                        on: {
                          click: function ($event) {
                            return removeFromFilter(_vm.filters.model, index)
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "filter-container" },
              [
                _c("iws-select", {
                  attrs: {
                    label: "Customer",
                    value: _vm.filters.customerName,
                    options: findUniqueValues("customerName"),
                    "maintain-on-select": true,
                    multiselect: true,
                  },
                  on: {
                    change: function ($event) {
                      return handleEvent(_vm.filters.customerName, $event)
                    },
                  },
                }),
                _vm._v(" "),
                _c(
                  "div",
                  _vm._l(_vm.filters.customerName, function (option, index) {
                    return _c("span", { staticClass: "badge badge-outline" }, [
                      _vm._v("\r\n                    " + _vm._s(option) + " "),
                      _c("i", {
                        staticClass: "fas fa-times clickable",
                        on: {
                          click: function ($event) {
                            return removeFromFilter(
                              _vm.filters.customerName,
                              index
                            )
                          },
                        },
                      }),
                    ])
                  }),
                  0
                ),
              ],
              1
            ),
          ]
        },
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }