<template>
<div class="container-fluid" id="ToolstringConfigComponent">
    <iws-breadcrumbs class="breadcrumbs-margins"
        :path="[{
            title: 'Dashboard',
            link: '/home'
        }, {
            title: jobNumber,
            link: `/wireline-op/${jobNumber}`
        }, {
            title: `Wireline ${wirelineSystemNumber}`,
            link: `/toolstring_config/${jobNumber}/${wirelineSystemNumber}`
        }]"
    />

    <div class="h5 page-title">
        Job Number {{  jobNumber }} - Wireline {{ wirelineSystemNumber }}
    </div>

    <iws-button id="save-all-wells"
        text="Save All"
        :disabled="!containsDirtyWell()"
        :click="saveAllDirtyWells"
    />

    <div v-if="_isNullOrEmpty(wells)">
        No Wells
    </div>
    <div>
        <template v-for="(well, index) in wells">
            <div :id="`accordion_${well.id}`" class="accordian-parent">
                <div class="card">
                    <div
                        :id="!well.isDirty ? `heading_${well.id}` : null"
                        class="card-header clickable"
                        data-toggle="collapse"
                        :data-target="!well.isDirty ? `#collapse${well.id}` : null"
                        aria-expanded="true"
                        :aria-controls="!well.isDirty ? `collapse${well.id}` : null"
                        @click="toggleWellData(well)"
                    >
                        <h5 class="mb-0">
                            {{ well.name }}
                        </h5>

                        <i :class="isWellOpen(well) ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i>
                    </div>

                    <div
                        :id="`collapse${well.id}`"
                        class="collapse"
                        :class="{ 'show': index === 0 }"
                        :aria-labelledby="`heading_${well.id}`"
                        :data-parent="!well.isDirty ? `#accordion_${well.id}` : null"
                    >
                        <div class="card-body">
                            <div class="title_row">
                                <h3>
                                    Toolstring Configuration - {{ well.name }}
                                </h3>

                                <div v-if="!!openWellData[well.id]" id="well_actions">
                                    <iws-button
                                        text="Cancel"
                                        type="outline-light"
                                        :disabled="!well.isDirty"
                                        @click="discardWellChanges(well)"
                                    />

                                    <iws-button
                                        text="Duplicate to other wells"
                                        type="outline-light"
                                        :disabled="_isNullOrEmpty(well.toolstrings)"
                                        :click="() => duplicateWell(well)"
                                    />

                                    <iws-button
                                        text="Save"
                                        type="primary"
                                        :disabled="!well.isDirty || !wellIsValid(well)"
                                        :click="() => saveWell(well)"
                                    />
                                </div>
                            </div>

                            <div v-if="_isNullOrEmpty(well.toolstrings)" id="no-toolstrings">
                                No Toolstrings
                            </div>
                            <iws-table v-else :columns="toolStringColumns">
                                <template #header_null1>
                                </template>
                                <template #header_stage>
                                    Stage
                                </template>
                                <template #header_name>
                                    Toolstring Name
                                </template>
                                <template #header_gun_type>
                                    Gun Type
                                </template>
                                <template #header_phasing>
                                    Phasing
                                </template>
                                <template #header_num_guns>
                                    No. of Guns
                                </template>
                                <template #header_plug_type>
                                    Plug Type
                                </template>

                                <template #body>
                                    <template v-for="(toolstring, toolstring_index) in well.toolstrings">
                                        <tr :key="toolstring.id" :class="{ 'hide-bottom-border': toolstringDataIsOpen(well, toolstring_index) }">
                                            <td>
                                                <iws-button id="toolstring-data-toggle"
                                                    type="outline-light"
                                                    @click="toggleToolstringData(well, toolstring_index)"
                                                >
                                                    <template #text>
                                                        <i :class="toolstringDataIsOpen(well, toolstring_index) ? 'fas fa-chevron-down' : 'fas fa-chevron-right'"></i>
                                                    </template>
                                                </iws-button>
                                            </td>
                                            <td class="stage-number">                                           
                                                <iws-button
                                                    :type="`outline-${!!toolstring.hasError ? 'danger' : 'light'}`"
                                                    @click="openStageNumber(well, toolstring_index)"
                                                >
                                                    <template #text>
                                                        <template v-if="toolstring.stageNumber === null">
                                                            N/A
                                                        </template>
                                                        <template v-else-if="toolstring.stageNumber === toolstring.stageNumberClosing">
                                                            {{ toolstring.stageNumber }}
                                                        </template>
                                                        <template v-else>
                                                            {{ toolstring.stageNumber }} - {{ toolstring.stageNumberClosing }}
                                                        </template>

                                                        <i class="fas fa-chevron-down"></i>
                                                    </template>
                                                </iws-button>
                                            </td>
                                            <td>
                                                {{ toolstring.name }}
                                            </td>
                                            <td>
                                                {{ convertToolstringList(toolstring.gunType, ' Gun Types') }}
                                            </td>
                                            <td>
                                                {{ convertToolstringList(toolstring.phasing, ' Phasing Options') }}
                                            </td>
                                            <td>
                                                {{ toolstring.gunCount }}
                                            </td>
                                            <td>
                                                {{ convertToolstringList(toolstring.plugType, ' Plug Types') }}
                                            </td>
                                            <td>
                                                <div>
                                                    <span class="table-row-action text-primary mr-2" @click="openAddEditToolstring(well, toolstring)">
                                                        <i class="fas fa-edit"></i> Edit
                                                    </span>
                                                    
                                                    <span class="table-row-action text-primary mr-2" @click="duplicateToolstring(well, toolstring)">
                                                        <i class="fas fa-copy"></i> Duplicate
                                                    </span>
                                                    
                                                    <span class="table-row-action text-danger" @click="deleteToolstring(toolstring)">
                                                        <i class="fas fa-trash-alt"></i> Delete
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>

                                        <tr v-if="toolstringDataIsOpen(well, toolstring_index)">
                                            <td colspan="8" valign="top">
                                                <iws-table :columns="toolstringConfigColumns">
                                                    <template #header_component>
                                                        Component
                                                    </template>
                                                    <template #header_topShot>
                                                        CCL to Top Shot
                                                    </template>
                                                    <template #header_description>
                                                        Description
                                                    </template>
                                                    <template #header_spf>
                                                        SPF
                                                    </template>
                                                    <template #header_phasing>
                                                        Phasing
                                                    </template>
                                                    <template #header_chargeType>
                                                        Charge Type
                                                    </template>
                                                    <template #header_null>
                                                        
                                                    </template>

                                                    <template #body>
                                                        <tr v-for="(toolDetail, index) in toolstring.toolstringDetails" :key="toolDetail.id">
                                                            <td>
                                                                {{ toolDetail.name }}
                                                            </td>
                                                            <td>
                                                                {{ toolDetail.distance }}
                                                            </td>
                                                            <td>
                                                                {{ toolDetail.description }}
                                                            </td>
                                                            <td>
                                                                {{ toolDetail.spf }}
                                                            </td>
                                                            <td>
                                                                {{ toolDetail.phasing }}
                                                            </td>
                                                            <td>
                                                                {{ toolDetail.chargeType }}
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </iws-table>
                                            </td>
                                        </tr>
                                    </template>
                                </template>
                            </iws-table>

                            <div id="error-body">
                                <div v-if="containsErrorToolstring(well)">
                                    <iws-alert type="danger">
                                        <template #body>
                                            <template v-for="toolstring in well.toolstrings">
                                                <div v-if="toolstring.hasError && toolstring.hasError !== true">
                                                    {{ toolstring.hasError }}
                                                </div>
                                            </template>
                                        </template>
                                    </iws-alert>
                                </div>

                                <button class="btn btn-outline-light" @click="newToolstring(well)">
                                    <i class="fas fa-plus-circle mr-1" />Add Toolstring
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>


        <well-duplicate-modal ref="wellDuplicateModal" />

        <toolstring-construction-modal ref="toolstringConstructionModal"
            :job-number="jobNumber"
            :well-number="wirelineSystemNumber"
        />

        <toolstring-duplicate-modal ref="toolstringDuplicateModal"
            :job-number="jobNumber"
            :wells="wells"
        />

        <toolstring-stage-modal ref="toolstringStageModal" />
    </div>
</div>
</template>


<script>
import GlobalFunctions from '../GlobalFunctions.js';
const { isFalsy, isTruthy, isNullOrEmpty, toast } = GlobalFunctions;

export default {
    props: {
        jobNumber: {
            type: String | Number,
            required: true
        },
        wirelineSystemNumber: {
            type: String | Number,
            required: true
        },
        wells: {
            type: Array,
            required: true
        }
    },

    data: () => ({
        openWellData: {},

        emptyToolstring: {
            id: 0,
            name: null,
            toolstring_details: []
        },
        toolStringColumns: [
            {
                key: 'null1',
                label: 'null1',
                sortable: false
            },
            {
                key: 'stage',
                label: 'Stage',
                sortable: false
            },
            {
                key: 'name',
                label: 'Name',
                sortable: false
            },
            {
                key: 'gun_type',
                label: 'Gun Type',
                sortable: false
            },
            {
                key: 'phasing',
                label: 'Phasing',
                sortable: false
            },
            {
                key: 'num_guns',
                label: 'No. of Guns',
                sortable: false
            },
            {
                key: 'plug_type',
                label: 'Plug Type',
                sortable: false
            },
            {
                key: 'null',
                label: 'null',
                sortable: false
            },
        ],
        toolstringConfigColumns: [
            {
                key: 'component',
                label: 'Component',
                sortable: false
            },
            {
                key: 'topShot',
                label: 'CCL to Top Shot',
                sortable: false
            },
            {
                key: 'description',
                label: 'Description',
                sortable: false
            },
            {
                key: 'spf',
                label: 'SPF',
                sortable: false
            },
            {
                key: 'phasing',
                label: 'Phasing',
                sortable: false
            },
            {
                key: 'chargeType',
                label: 'Charge Type',
                sortable: false
            },
            {
                key: 'null',
                label: '',
                sortable: false
            },
        ]
    }),

    methods: {
        _isNullOrEmpty(val) {
            return isNullOrEmpty(val);
        },
        containsErrorToolstring(well) {
            return !isNullOrEmpty(well?.toolstrings) && !!well.toolstrings.find(_toolstring => _toolstring.hasError);
        },
        convertToolstringList(list, fallback) {
            if (isNullOrEmpty(list))
                return null;
            if (list.length == 1)
                return list[0];
            return `${list.length}${fallback}`;
        },

        containsDirtyWell() {
            return !isNullOrEmpty(this.wells) && !!this.wells.find(well => well.isDirty);
        },
        
        openAddEditToolstring(well, toolstring) {
            if (isTruthy(toolstring))
                return this.$refs.toolstringConstructionModal.open({
                    ...toolstring,
                    wellNumber: well?.index,
                }).then(response => {
                    if (isTruthy(response)) {
                        const saveToUi = (newToolstring) => {
                            // Insert the updated toolstring into our list of toolstrings
                            const well = this.wells.find(_well => _well.index == newToolstring.wellNumber);
                            const index = well?.toolstrings.findIndex(_toolstring => _toolstring.id == newToolstring.id);
                            if (index >= 0) {
                                // Maintain certain properties the modal doesn't save
                                well.toolstrings[index] = {
                                    ...newToolstring,
                                    stageNumber: well.toolstrings[index].stageNumber,
                                    stageNumberClosing: well.toolstrings[index].stageNumberClosing,
                                    hasError: well.toolstrings[index].hasError
                                };

                                // Cannot backup well if not open (also don't need to, this happens when it is opened)
                                if (this.openWellData && this.openWellData[well.id])
                                    this.openWellData[well.id].originalData = _.cloneDeep(well);
                            } else {
                                well.toolstrings.unshift(newToolstring);
                            }
                        }

                        if (Array.isArray(response)) {
                            // When using save all, the response will be all updated toolstrings
                            response.forEach(_toolstring => {
                                saveToUi(_toolstring);
                            })
                        } else {
                            // In standard save, it is just the current toolstring
                            saveToUi(response);
                        }

                        this.$forceUpdate();
                    }

                    return well.toolstrings;
                })
        },

        newToolstring(well) {
            return this.openAddEditToolstring(well, {
                ...this.emptyToolstring,
                isDirty: false,
                stageNumber: null
            });
        },

        duplicateToolstring(well, toolstring) {
            if (isTruthy(toolstring) && !isNullOrEmpty(toolstring.toolstring_details))
                return this.$refs.toolstringDuplicateModal.open(well.index,
                {
                    ...toolstring,
                    id: 0,
                    name: `${toolstring.name} - Copy`,
                    jobNumber: this.jobNumber,
                    stageNumber: null,
                    toolstringDetails: toolstring.toolstring_details.map(_toolDetail => {
                        return {
                            ..._toolDetail,
                            id: 0,
                            index: _toolDetail.gunOrPlugIndex,
                            value:  _toolDetail.distance,
                            gunID:  _toolDetail.gun_id,
                            plugID:  _toolDetail.plug_id,
                        }
                    })
                }).then(response => {
                    if (isTruthy(response)) {
                        this.wells[response.wellNumber].toolstrings.unshift(response);
                        this.$forceUpdate();
                    }
                });
        },

        deleteToolstring(toolstring) {
            if (isTruthy(toolstring)) {
                return GlobalFunctions.iwsConfirm({
                    title: 'Delete toolstring',
                    body: `Are you sure you want to delete "${toolstring.name || 'this toolstring'}"? This action cannot be undone!`,
                    confirmColour: 'danger',
                    width: '400px'
                }).then(_answer => {
                    if (_answer)
                        return $.ajax({
                            method: 'DELETE',
                            url: `/toolstring/${toolstring.id}`,
                            data: { '_token': GlobalFunctions.getCSRFToken() },
                            dataType: 'json',
                        }).then(_res => {
                            const well = this.wells.find(_well => _well.index == toolstring.wellNumber);
                            const index = well.toolstrings.findIndex(_toolstring => _toolstring.id == toolstring.id);
                            if (index >= 0)
                                well.toolstrings.splice(index, 1);

                            this.backupWell(well);
                            this.checkForToolstringErrors(well);
                            this.$forceUpdate();
                        })
                })
            }
        },

        findToolstringClosingRange(toolstring) {
            if (isNullOrEmpty(this.wells) || isFalsy(toolstring?.wellNumber))
                return;

            const well = this.wells.find(_well => _well.index == toolstring.wellNumber);
            const current_index = well?.toolstrings?.indexOf(toolstring);

            // If there is a next toolstring, the closing range value is 1 before where the next starts
            if (current_index !== null && current_index >= 0 && current_index+1 in well.toolstrings)
                return well.toolstrings[current_index+1]?.stageNumber-1;
            return well?.numberOfStages || 0;
        },
        findToolstringStageRange(toolstring) {
            if (isFalsy(toolstring?.stageNumber))
                return { to: null, from: null };
            return {
                from: toolstring.stageNumber,
                to: toolstring.stageNumberClosing || this.findToolstringClosingRange(toolstring)
            };
        },
        checkForToolstringErrors(well) {
            if (isFalsy(well) || isNullOrEmpty(well?.toolstrings))
                return;

            // Reset errors
            for (let i = 0; i < well?.toolstrings?.length; i++)
                well.toolstrings[i].hasError = false;

            const firstSetToolstring = well?.toolstrings?.find(_toolstring => isTruthy(_toolstring?.stageNumber));
            if (isTruthy(firstSetToolstring) && firstSetToolstring?.stageNumber !== 1)
                firstSetToolstring.hasError = `Missing first ${firstSetToolstring.stageNumber-1} stage(s)`;
            
            const lastSetToolstring = well?.toolstrings[well?.toolstrings.length-1];
            if (lastSetToolstring?.stageNumberClosing !== well?.numberOfStages)
                lastSetToolstring.hasError = `Missing last ${well?.numberOfStages - lastSetToolstring.stageNumberClosing} stage(s)`;

            // Check for toolstrings in an error state
            for (let i = 0; i < well?.toolstrings.length; i++) {
                const currentToolstring = well?.toolstrings[i];
                
                // Somehow empty index?
                if (isFalsy(currentToolstring))
                    continue;

                // Skip any toolstrings that already have errors
                if (!!currentToolstring?.hasError)
                    continue;

                // Don't mark toolstrings for error until they are set
                if (isFalsy(currentToolstring?.stageNumber))
                    continue;

                // Check if this toolstring has errors with the following toolstring
                if (i+1 in well?.toolstrings) {
                    const nextToolstring = well?.toolstrings[i+1];
                    const currentDisplay = currentToolstring.name || currentToolstring.index;    // name is a new property, fallback on index if not set yet
                    const nextDisplay = nextToolstring.name || nextToolstring.index;             // name is a new property, fallback on index if not set yet

                    // If there are any other toolstrings with the same opening or closing stage, show this and the matching as invalid
                    if (currentToolstring.stageNumber == nextToolstring.stageNumber || currentToolstring.stageNumberClosing == nextToolstring.stageNumberClosing) {
                        currentToolstring.hasError = `'${currentDisplay}' and '${nextDisplay}' have a conflict.`;
                        nextToolstring.hasError = true; // mark the second one as true. This allows us to skip duplicate messages
                    }

                    // If there are any spaces unaccounted for, show gaps
                    if (nextToolstring.stageNumber - currentToolstring.stageNumberClosing > 1) {
                        currentToolstring.hasError = `'${currentDisplay}' and '${nextDisplay}' have a gap.`;
                        nextToolstring.hasError = true; // mark the second one as true. This allows us to skip duplicate messages
                    }
                }
            }

            if (!!well?.toolstrings?.find(_toolstring => _toolstring.hasError))
                toast({
                    title: 'Please resolve errors in stages configuration',
                    variant: 'danger'
                });
        },
        openStageNumber(well, index) {
            if (isFalsy(well) || isNullOrEmpty(well?.toolstrings))
                return;

            const toolstring = well.toolstrings[index];
            const { from, to } = this.findToolstringStageRange(toolstring);

            return this.$refs?.toolstringStageModal?.open(toolstring, from, to, well.numberOfStages, well.toolstrings).then(_range => {
                // When _range === null, user canceled
                if (isTruthy(_range)) {
                    well.toolstrings[index].stageNumber = _range.from;
                    well.toolstrings[index].stageNumberClosing = _range.to;

                    // Mark the well as 'dirty'. Dirty means it has changes that still need saving
                    this.$set(well, 'isDirty', true);

                    // Do simple auto bumping to fit the range into place without overlap
                    // Avoid making too many decisions on the users behalf to make use easier
                    for (let i = 0; i < well.toolstrings.length; i++) {
                        const currentToolstring = well.toolstrings[i];

                        // The current toolstring needs no adjusting
                        if (currentToolstring.id == well.toolstrings[index].id)
                            continue;

                        if (_range.from > currentToolstring.stageNumber && _range.from <= currentToolstring.stageNumberClosing)
                            currentToolstring.stageNumberClosing = _range.from-1;
                        if (_range.to >= currentToolstring.stageNumber && _range.to < currentToolstring.stageNumberClosing)
                            currentToolstring.stageNumber = _range.to+1;

                        // Re-balance invalid ranges
                        if (currentToolstring.stageNumber > currentToolstring.stageNumberClosing)
                            currentToolstring.stageNumberClosing = currentToolstring.stageNumber;
                    }

                    well.toolstrings.sort((a,b) => a.stageNumber-b.stageNumber);

                    this.checkForToolstringErrors(well);

                    this.$forceUpdate();
                }
            })
        },
        wellIsValid(well) {
            return !isNullOrEmpty(well?.toolstrings) && !well?.toolstrings?.find(_toolstring => !!_toolstring.hasError);
        },

        discardWellChanges(well) {
            if (this.isWellOpen(well)) {
                const index = this.wells.findIndex(_well => _well.id == well.id);

                if (index >= 0) {
                    this.backupWell(well);
                    this.$forceUpdate();
                }
            }
        },
        saveWell(well, showSuccess=true) {
            if (well?.isDirty === true) {
                if (!well.toolstrings.find(_toolstring => _toolstring.hasError)) {
                    return $.ajax({
                        method: 'POST',
                        url: `/toolstring/stages/all`,
                        data: { 
                            '_token': GlobalFunctions.getCSRFToken(),
                            'toolstrings': well.toolstrings.map(_toolstring => {
                                return {
                                    id: _toolstring.id,
                                    stageNumber: _toolstring.stageNumber
                                }
                            })
                        },
                        dataType: 'json',
                    }).then(_res => {
                        // Skip showings this on the close well confirmation
                        if (showSuccess)
                            toast({
                                title: 'Changes Saved',
                                variant: 'success'
                            });

                        well.isDirty = false;
                        this.backupWell(well);
                        this.$forceUpdate();
                    })
                } else {
                    toast({
                        title: 'Please correct stage numbers',
                        variant: 'danger'
                    });
                }
            }
        },
        saveAllDirtyWells() {
            if (!isNullOrEmpty(this.wells))
                return Promise.all(
                    this.wells
                        .filter(well => well.isDirty)
                        .map(well => this.saveWell(well, false))
                );
            return [];
        },

        duplicateWell(well) {
            return this.$refs.wellDuplicateModal.open(well, this.wells).then(duplicatedWells => {
                // The api response includes all of the updated wells
                // To avoid refreshing, we replace the updated wells with the returned objects (merge with the existing to maintain frontend only data)
                duplicatedWells.forEach(duplicatedWell => {
                    const index = this.wells.findIndex(_well => _well.id == duplicatedWell.id);

                    if (index in this.wells) {
                        this.wells[index] = {
                            ...this.wells[index],
                            ...duplicatedWell
                        };
    
                        this.wells[index].toolstrings.forEach(_toolstring => {
                            this.$set(_toolstring, 'stageNumberClosing', this.findToolstringClosingRange(_toolstring));
                            this.$set(_toolstring, 'hasError', false);
                        });
                    }
                });

                this.$forceUpdate();
            });
        },

        backupWell(well) {
            this.openWellData[well.id].originalData = _.cloneDeep(well);
        },
        toggleToolstringData(well, index) {
            if (this.toolstringDataIsOpen(well, index)) {
                this.openWellData[well.id]?.openToolstringData.splice(this.openWellData[well.id]?.openToolstringData.indexOf(index), 1);
            } else {
                this.openWellData[well.id]?.openToolstringData.push(index);
            }
        },
        toolstringDataIsOpen(well, index) {
            return !isNullOrEmpty(this.openWellData[well.id]?.openToolstringData) && this.openWellData[well.id]?.openToolstringData.includes(index);
        },

        toggleWellData(well) {
            if (this.isWellOpen(well)) {
                this.$set(this.openWellData, well.id, {
                    isOpen: false,
                    openToolstringData: [],
                    originalData: null
                });
            } else {
                this.$set(this.openWellData, well.id, {
                    isOpen: true,
                    openToolstringData: [],
                    originalData: _.cloneDeep(well)
                });
            }
        },
        isWellOpen(well) {
            return !!this.openWellData[well?.id]?.isOpen;
        }
    },

    created() {
        // Set client side only properties
        if (!isNullOrEmpty(this.wells)) {
            this.wells.forEach(_well => {
                this.$set(_well, 'isDirty', false)
                if (!isNullOrEmpty(_well?.toolstrings)) {
                    _well.toolstrings.forEach(_toolstring => {
                        this.$set(_toolstring, 'stageNumberClosing', this.findToolstringClosingRange(_toolstring));
                        this.$set(_toolstring, 'hasError', false);
                    });
        
                    this.checkForToolstringErrors(_well);
                }
            });
            
            this.toggleWellData(this.wells[0]);
        }
    }
};
</script>

<style>
    #ToolstringConfigComponent {
        position: relative !important;
        padding-bottom: 25px;
    }
    #ToolstringConfigComponent .card-body table {
        margin-top: 0px;
        margin-bottom: 10px;
    }

    #ToolstringConfigComponent .card-body>div>table thead th:first-child { border-top-left-radius: 0 !important; }
    #ToolstringConfigComponent .card-body>div>table thead th:last-child { border-top-right-radius: 0 !important; }
    #ToolstringConfigComponent .card-body>div>table tr:last-child td:first-child { border-bottom-left-radius: 0 !important; }
    #ToolstringConfigComponent .card-body>div>table tr:last-child td:last-child { border-bottom-right-radius: 0 !important; }
</style>
<style scoped>
    .page-title {
        margin-bottom: 15px;
    }

    #no-toolstrings {
        padding: 5px 1.25rem;
    }

    #save-all-wells {
        position: absolute;
        top: 0px;
        right: 15px;
    }

    .accordian-parent .card {
        background: #242A30 !important;
        color: #FFFFFF !important;
        
        margin-top: 15px;
        border: 1px solid white;
    }

    .card-header i {
        position: absolute;
        top: 18px;
        right: 30px;
    }

    .card-body {
        background: #2E353D !important;

        border-top: 1px solid white;
    }

    .card-body .title_row {
        width: 100%;
        position: relative;
        padding: 20px 1.25rem;
    }
    .card-body .title_row h3 {
        margin-bottom: 0px !important;
    }

    #well_actions {
        position: absolute;
        top: 13px;
        right: 30px;

        height: 49px;
        padding: 5px 0px;
    }
    #well_actions .btn-outline-light {
        margin-right: 5px;
    }
    #well_actions i {
        position: relative;
        top: 5px;
        left: 10px;
    }

    #toolstring-data-toggle {
        height: 40px;
        width: 40px;
    }

    .stage-number .btn {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: var(--primary-text-color);

        height: 40px;
        width: 100px !important;
        padding: 7px 10px;
    }
    .stage-number .btn i {
        color: var(--secondary-text-color);
        margin-left: 5px;
    }
    .stage-number .btn:hover,
    .stage-number .btn:hover i {
        color: #212529 !important;
    }

    .card-header .h5 {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;

        color: #FFFFFF;
    }

    .card-body {
        padding: 0px 0px 1.25rem 0px !important;
    }

    .hide-bottom-border, .hide-bottom-border td {
        border-bottom: none !important;
    }

    #error-body {
        padding: 15px 15px 0px 15px;
    }
</style>