var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "align-items-stretch mt-4" }, [
    _vm._m(0),
    _vm._v(" "),
    _c(
      "div",
      {
        key: _vm.selectedStage,
        staticClass:
          "flex-wrap d-flex align-items-stretch align-content-start justify-content-center",
      },
      _vm._l(_vm.totalStageCount, function (n, index) {
        return _c(
          "div",
          {
            key: n,
            class: {
              "m-1 d-flex justify-content-center awesome awesome-standard": true,
              "selected-color": _vm.isShown(index),
              "unselected-color": !_vm.isShown(index),
            },
            staticStyle: { width: "20px" },
            on: {
              click: function ($event) {
                return _vm.changeShow(index)
              },
            },
          },
          [_c("div", [_vm._v(_vm._s(index))])]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("div", [_vm._v("Stage number")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }