var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-popover",
        {
          attrs: {
            target: "export-popover" + _vm._uid,
            triggers: "hover",
            placement: "left",
            delay: { show: 0, hide: 500 },
          },
        },
        [
          _c("div", { staticClass: "btn-group-vertical" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary grey-button mb-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onExportData("csv")
                  },
                },
              },
              [_vm._v("\n                Export as CSV\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary grey-button",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.onExportData("json")
                  },
                },
              },
              [_vm._v("\n                Export as JSON\n            ")]
            ),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-popover",
        {
          attrs: {
            target: "info-tooltip" + _vm._uid,
            triggers: "hover",
            placement: "left",
          },
        },
        [
          _c("div", { staticClass: "text-white" }, [
            _c("div", [
              _vm._v(
                "This Summary Bar component displays the service activities of frac and wireline per well."
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "The activity bar will match the well color.  Hover over an activity to display further details on the well, stage, duration and times.  Click and drag left or right to pan, use the mouse roller to zoom in and out."
              ),
            ]),
            _vm._v(" "),
            _c("br"),
            _vm._v(" "),
            _c("div", [
              _vm._v(
                "Use the quick filter buttons to promptly define a timeframe.  Once a filtered view is selected, the quick filter button will be highlighted, and the component will not be updating with live data.  Click the red return to 3Hr live view button to return to the default live view."
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("iws-modal", {
        attrs: {
          title: "Custom Filter",
          showModal: _vm.showCustomFilterModal,
          primaryButtonText: "Filter",
          secondaryButtonText: "Reset",
          secondaryButtonDisabled: _vm.secondaryModalButtonDisabled,
          primaryButtonDisabled: _vm.primaryModalButtonDisabled,
          maxWidth: "45em",
        },
        on: {
          close: function ($event) {
            _vm.showCustomFilterModal = false
          },
          "secondary-action": function ($event) {
            return _vm.clearCustomFilter()
          },
          "primary-action": function ($event) {
            return _vm.saveCustomFilter()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("div", { staticClass: "mb-3" }, [
                  _vm._v(
                    "Select the date and time range you wish to filter for"
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "d-flex flex-column rounded grey-border" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "user-select-none clickable p-2 grey-border-bottom",
                        on: {
                          click: function ($event) {
                            _vm.tempModalFilterType = "custom"
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            class: `d-flex ${
                              _vm.tempModalFilterType != "custom"
                                ? "align-items-center"
                                : "align-items-baseline"
                            }`,
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.tempModalFilterType,
                                  expression: "tempModalFilterType",
                                },
                              ],
                              staticClass: "mr-2",
                              attrs: { type: "radio", value: "custom" },
                              domProps: {
                                checked: _vm._q(
                                  _vm.tempModalFilterType,
                                  "custom"
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.tempModalFilterType = "custom"
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c("div", { staticClass: "d-flex flex-column" }, [
                              _c("div", [_vm._v("Define a Custom Time Range")]),
                              _vm._v(" "),
                              _vm.tempModalFilterType == "custom"
                                ? _c("div", [
                                    _c("div", [
                                      _c(
                                        "div",
                                        { staticClass: "d-flex-column my-2" },
                                        [
                                          _c(
                                            "div",
                                            [
                                              _c("div", [_vm._v("Start Date")]),
                                              _vm._v(" "),
                                              _c("calendar", {
                                                attrs: {
                                                  prompt:
                                                    "Select start date and time",
                                                  min: _vm.minStartFilter,
                                                  max: _vm.endDate,
                                                  dateValue:
                                                    _vm.tempFilterStartTime,
                                                  utcHourOffset:
                                                    _vm.jobHourOffset,
                                                  showErrorState:
                                                    _vm.errors
                                                      .filterStartTimeMissing,
                                                },
                                                on: {
                                                  change: (value) =>
                                                    _vm.updateTempStartFilter(
                                                      value
                                                    ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.filterStartTimeMissing
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "error-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "Start date and time"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "mt-2" },
                                            [
                                              _c("div", [_vm._v("End Date")]),
                                              _vm._v(" "),
                                              _c("calendar", {
                                                attrs: {
                                                  prompt:
                                                    "Select end date and time",
                                                  min: _vm.minEndFilter,
                                                  max: _vm.endDate,
                                                  dateValue:
                                                    _vm.tempFilterEndTime,
                                                  utcHourOffset:
                                                    _vm.jobHourOffset,
                                                  showErrorState:
                                                    _vm.errors
                                                      .filterEndTimeMissing,
                                                },
                                                on: {
                                                  change: (value) =>
                                                    _vm.updateTempEndFilter(
                                                      value
                                                    ),
                                                },
                                              }),
                                              _vm._v(" "),
                                              _vm.errors.filterEndTimeMissing
                                                ? _c(
                                                    "div",
                                                    {
                                                      staticClass: "error-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "End date and time"
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center user-select-none clickable p-2 grey-border-bottom",
                        on: {
                          click: function ($event) {
                            _vm.tempModalFilterType = "midnight"
                          },
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tempModalFilterType,
                              expression: "tempModalFilterType",
                            },
                          ],
                          staticClass: "mr-2",
                          attrs: { type: "radio", value: "midnight" },
                          domProps: {
                            checked: _vm._q(
                              _vm.tempModalFilterType,
                              "midnight"
                            ),
                          },
                          on: {
                            change: function ($event) {
                              _vm.tempModalFilterType = "midnight"
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("Previous 24hr Day Midnight to Midnight"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center user-select-none clickable p-2",
                        on: {
                          click: function ($event) {
                            _vm.tempModalFilterType = "shiftStart"
                          },
                        },
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.tempModalFilterType,
                              expression: "tempModalFilterType",
                            },
                          ],
                          staticClass: "mr-2",
                          attrs: { type: "radio", value: "shiftStart" },
                          domProps: {
                            checked: _vm._q(
                              _vm.tempModalFilterType,
                              "shiftStart"
                            ),
                          },
                          on: {
                            change: function ($event) {
                              _vm.tempModalFilterType = "shiftStart"
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c("div", [
                          _vm._v("Previous 24hr Shift Start to Shift Start"),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.disabledZoomMessage,
              expression: "disabledZoomMessage",
            },
          ],
          style: {
            zIndex: 10,
            position: "absolute",
            bottom: "10px",
            right: "10px",
            backgroundColor: "#3490dc",
            fontSize: "0.8vw",
            padding: "10px",
          },
        },
        [_vm._v("Click the chart to enable scroll zoom")]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "w-full d-flex flex-column",
          attrs: { tabindex: "-1" },
          on: {
            mouseleave: function ($event) {
              _vm.hoveringChart = false
            },
            mouseover: function ($event) {
              _vm.hoveringChart = true
            },
            wheel: _vm.zoomDisabledMessage,
            "!focus": function ($event) {
              return _vm.enableZoomOnFocus()
            },
            blur: function ($event) {
              return _vm.disableZoomOnBlur()
            },
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "w-full border-bottom d-flex justify-content-end pb-1 smaller-font",
            },
            [
              _c("div", { staticClass: "mr-auto d-flex align-items-center" }, [
                _c("div", [_vm._v(_vm._s(_vm.timeRangeText))]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass:
                    "pt-1 d-flex align-items-center justify-content-center mr-2",
                },
                [
                  _c(
                    "div",
                    {
                      class: `clickable d-flex align-items-center rounded ${
                        _vm.modalFilterType != ""
                          ? "custom-filter-active"
                          : "custom-filter-inactive"
                      }`,
                      on: {
                        click: function ($event) {
                          _vm.showCustomFilterModal = true
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "mr-1",
                        attrs: { src: "/images/icons/toggles.svg" },
                      }),
                      _vm._v(" "),
                      _c("div", [_vm._v("Custom filter")]),
                    ]
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "mr-2 d-flex align-items-center",
                  attrs: { id: "export-popover" + _vm._uid },
                },
                [
                  _c("img", { attrs: { src: "/images/icons/download.svg" } }),
                  _vm._v(" "),
                  _c("div", [_vm._v("Export")]),
                ]
              ),
              _vm._v(" "),
              _c("span", { attrs: { id: "info-tooltip" + _vm._uid } }, [
                _c("img", { attrs: { src: "/images/icons/info-circle.svg" } }),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "w-full d-flex relative" }, [
            _c("div", {
              staticClass: "position-absolute",
              style:
                "height:" +
                _vm.labelCoveringOverlayHeight +
                "px; width:" +
                _vm.labelCoveringOverlayWidth +
                "px;",
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "flex-grow-1",
                staticStyle: { "margin-right": "55px", width: "85%" },
              },
              [
                _c("bar-chart", {
                  ref: "barChart",
                  style: { height: _vm.height + "px", width: "99%" },
                  attrs: {
                    "chart-data": _vm.summaryData,
                    options: _vm.summaryBarOptions,
                    id: "bar-chart",
                  },
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "p-2 flex-shrink-0",
                staticStyle: { "margin-left": "-55px" },
              },
              [
                _c(
                  "table",
                  { staticClass: "w-full" },
                  [
                    _vm._l(_vm.filters, function (filterRow, parentIndex) {
                      return _c(
                        "tr",
                        _vm._l(filterRow, function (filter, index) {
                          return _c(
                            "td",
                            {
                              key: parentIndex + ":" + index,
                              class: `badge badge-pill ${
                                filter == _vm.selectedFilter
                                  ? "filter-selected"
                                  : "filter"
                              } clickable mb-1 ${
                                index < filterRow.length - 1 ? "mr-1" : ""
                              }`,
                              on: {
                                click: function ($event) {
                                  return _vm.setFilter(filter)
                                },
                              },
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(filter) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        0
                      )
                    }),
                    _vm._v(" "),
                    _c(
                      "tr",
                      {
                        class: `w-full rounded ${
                          _vm.filterActive || !_vm.scrollOnNewData
                            ? "return-live clickable"
                            : "return-live-disabled"
                        } d-flex align-items-center justify-content-center mt-1 user-select-none`,
                        on: {
                          click: function ($event) {
                            return _vm.followLiveData()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "mr-2",
                          attrs: { src: "/images/icons/undo.svg" },
                        }),
                        _vm._v(" "),
                        _c("div", [_vm._v("3Hr live view")]),
                      ]
                    ),
                  ],
                  2
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _vm.editMode
            ? _c("div", [
                _c(
                  "div",
                  { staticClass: "input-group", attrs: { id: "optionMenu" } },
                  [
                    _vm._m(0),
                    _vm._v(" "),
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.timeFormatSelected,
                            expression: "timeFormatSelected",
                          },
                        ],
                        staticClass: "form-control mr-2",
                        attrs: { id: "inputGroupSelect02" },
                        on: {
                          change: [
                            function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.timeFormatSelected = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function ($event) {
                              return _vm.onSelectTimeFormat($event.target.value)
                            },
                          ],
                        },
                      },
                      _vm._l(_vm.timeFormatOptions, function (time) {
                        return _c("option", [
                          _vm._v(
                            "\n                        " +
                              _vm._s(time) +
                              "\n                    "
                          ),
                        ])
                      }),
                      0
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]
      ),
      _vm._v(" "),
      _vm.isLoading ? _c("div", [_vm._m(1)]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-prepend" }, [
      _c("label", { staticClass: "input-group-text" }, [
        _vm._v("Time Format:"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass:
          "spinner-border spinner-border-sm loading-spinner-position",
        attrs: { role: "status" },
      },
      [_c("span", { staticClass: "sr-only mb-5" }, [_vm._v("Loading...")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }