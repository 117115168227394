var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-popover",
        {
          attrs: {
            target: "export-popover",
            triggers: "hover",
            placement: "left",
            delay: { show: 0, hide: 500 },
          },
        },
        [
          _c("div", { staticClass: "btn-group-vertical" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-secondary grey-button mb-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.exportTable("csv")
                  },
                },
              },
              [_vm._v("\n                Export as CSV\n            ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-secondary grey-button mb-2",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    return _vm.exportTable("json")
                  },
                },
              },
              [_vm._v("\n                Export as JSON\n            ")]
            ),
            _vm._v(" "),
            _vm.haswvxmlExport
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary grey-button",
                    attrs: { type: "button" },
                    on: {
                      click: function ($event) {
                        return _vm.onWvxmlExport()
                      },
                    },
                  },
                  [_vm._v("\n                Export as WVXML\n            ")]
                )
              : _vm._e(),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("iws-button", {
        attrs: { type: "link", id: "export-popover" },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function () {
              return [_c("export-icon"), _vm._v(" Export\n        ")]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }