var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      [
        _vm.userApiToken
          ? [
              _c(
                "div",
                {
                  staticClass: "full-width position-relative",
                  staticStyle: { "margin-bottom": "10px" },
                },
                [
                  _vm.showWarning
                    ? _c("div", { staticClass: "warning-text" }, [
                        _c("i", { staticClass: "fas fa-info-circle" }),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "mx-2",
                            attrs: { id: "roip-map-warning-text" },
                          },
                          [
                            _vm._v(
                              "Warning - Make sure you copy the below token now. We don't store it and you will not be able to see it again."
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("iws-input", {
                    staticStyle: {
                      display: "inline-block",
                      width: "calc(100% - 200px)",
                      "margin-right": "5px",
                    },
                    attrs: { value: _vm.userApiToken, readonly: "" },
                  }),
                  _vm._v(" "),
                  _vm.showWarning
                    ? _c("iws-button", {
                        staticStyle: {
                          height: "38px",
                          position: "relative",
                          top: "-3px",
                        },
                        attrs: {
                          icon: "far fa-copy",
                          type: "primary",
                          click: _vm.copyTokenToClipboard,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.isIwsAdmin
                    ? _c("iws-button", {
                        staticStyle: {
                          height: "38px",
                          position: "relative",
                          top: "-3px",
                        },
                        attrs: {
                          text: "Regenerate",
                          type: "primary",
                          click: _vm.regenerateApiToken,
                        },
                      })
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.isIwsAdmin
                    ? _c("iws-button", {
                        attrs: {
                          text: "Revoke",
                          type: "danger",
                          click: _vm.revokeApiAccess,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          : [
              !_vm.isIwsAdmin
                ? _c(
                    "div",
                    { staticClass: "full-width text-center" },
                    [
                      _c("iws-button", {
                        attrs: {
                          text: "Generate API Token",
                          type: "primary",
                          click: _vm.regenerateApiToken,
                        },
                      }),
                    ],
                    1
                  )
                : _c("div", { staticClass: "full-width" }, [_vm._m(0)]),
            ],
      ],
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "warning-text" }, [
      _c("i", { staticClass: "fas fa-info-circle" }),
      _vm._v(" "),
      _c(
        "span",
        { staticClass: "mx-2", attrs: { id: "roip-map-warning-text" } },
        [_vm._v("No valid API token for this user")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }