var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isCustomTimeOptions
    ? _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-light btn-sm mx-2 px-2 w-100",
            attrs: { id: "timeRangeButton" + _vm.item.i },
          },
          [_vm._v(_vm._s("Duration: " + _vm.buttonText))]
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex flex-column whiteText mx-2" },
          [
            _c(
              "b-popover",
              {
                attrs: {
                  target: "timeRangeButton" + _vm.item.i,
                  triggers: "click blur",
                  placement: "bottom",
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-column whiteText mx-2" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex justify-content-between mb-2" },
                      [
                        _c("div", { staticClass: "mr-2" }, [
                          _vm._v(" Select Duration: "),
                        ]),
                        _vm._v(" "),
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedDurationType,
                                expression: "selectedDurationType",
                              },
                            ],
                            on: {
                              input: function ($event) {
                                return _vm.selectedDurationTypeUpdated($event)
                              },
                              change: [
                                function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedDurationType = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                                function ($event) {
                                  return _vm.checkSavedDates($event)
                                },
                              ],
                            },
                          },
                          _vm._l(
                            Object.keys(_vm.durationOptions),
                            function (option, index) {
                              return _c(
                                "option",
                                { key: index, domProps: { value: option } },
                                [_vm._v(_vm._s(_vm.durationOptions[option]))]
                              )
                            }
                          ),
                          0
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.selectedDurationType == "times"
                      ? _c("div", { staticClass: "mt-2" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between mb-2",
                            },
                            [
                              _c("div", [_vm._v("Start Time: ")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedTimeMin,
                                    expression: "selectedTimeMin",
                                  },
                                ],
                                attrs: {
                                  id: "selectedTimeMin",
                                  type: "datetime-local",
                                  max: _vm.setStartDateMax(true),
                                },
                                domProps: { value: _vm.selectedTimeMin },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.selectedTimeMin = $event.target.value
                                    },
                                    function ($event) {
                                      return _vm.selectedTimeMinUpdated($event)
                                    },
                                  ],
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", [_vm._v("End Time: ")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedTimeMax,
                                    expression: "selectedTimeMax",
                                  },
                                ],
                                staticClass: "input",
                                attrs: {
                                  id: "selectedTimeMax",
                                  type: "datetime-local",
                                  min: _vm.setEndDateMin(true),
                                  max: _vm.getTodayDate(true),
                                },
                                domProps: { value: _vm.selectedTimeMax },
                                on: {
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.selectedTimeMax = $event.target.value
                                    },
                                    function ($event) {
                                      return _vm.selectedTimeMaxUpdated($event)
                                    },
                                  ],
                                },
                              }),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.selectedDurationType == "days"
                      ? _c("div", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between mb-2",
                            },
                            [
                              _c("div", [_vm._v("Start Date: ")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedDayMin,
                                    expression: "selectedDayMin",
                                  },
                                ],
                                attrs: {
                                  id: "selectedDayMin",
                                  type: "date",
                                  max: _vm.setStartDateMax(false),
                                },
                                domProps: { value: _vm.selectedDayMin },
                                on: {
                                  change: function ($event) {
                                    return _vm.emitCalculatedIntervalRange(
                                      $event
                                    )
                                  },
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.selectedDayMin = $event.target.value
                                    },
                                    function ($event) {
                                      return _vm.selectedDayMinUpdated($event)
                                    },
                                  ],
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between mb-2",
                            },
                            [
                              _c("div", [_vm._v("End Date: ")]),
                              _vm._v(" "),
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedDayMax,
                                    expression: "selectedDayMax",
                                  },
                                ],
                                attrs: {
                                  id: "selectedDayMax",
                                  type: "date",
                                  min: _vm.setEndDateMin(false),
                                  max: _vm.getTodayDate(false),
                                },
                                domProps: { value: _vm.selectedDayMax },
                                on: {
                                  change: function ($event) {
                                    return _vm.emitCalculatedIntervalRange(
                                      $event
                                    )
                                  },
                                  input: [
                                    function ($event) {
                                      if ($event.target.composing) return
                                      _vm.selectedDayMax = $event.target.value
                                    },
                                    function ($event) {
                                      return _vm.selectedDayMaxUpdated($event)
                                    },
                                  ],
                                },
                              }),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c("div", { staticClass: "mr-2" }, [
                                _vm._v(" Select Time Range Type: "),
                              ]),
                              _vm._v(" "),
                              _c(
                                "select",
                                {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.selectedIntervalType,
                                      expression: "selectedIntervalType",
                                    },
                                  ],
                                  on: {
                                    input: function ($event) {
                                      return _vm.selectedIntervalTypeUpdated(
                                        $event
                                      )
                                    },
                                    change: [
                                      function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.selectedIntervalType = $event.target
                                          .multiple
                                          ? $$selectedVal
                                          : $$selectedVal[0]
                                      },
                                      function ($event) {
                                        return _vm.emitCalculatedIntervalRange(
                                          $event
                                        )
                                      },
                                    ],
                                  },
                                },
                                _vm._l(
                                  _vm.intervalOptions,
                                  function (option, index) {
                                    return _c(
                                      "option",
                                      {
                                        key: index,
                                        domProps: { value: option },
                                      },
                                      [_vm._v(_vm._s(option))]
                                    )
                                  }
                                ),
                                0
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.displayStartEndTimeSameError
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-danger font-weight-bold d-flex justify-content-between mt-2",
                          },
                          [
                            _vm._v(
                              "\n                    Start and end time cannot be the same\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.displayStartAfterEndTimeError
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-danger font-weight-bold d-flex justify-content-between mt-2",
                          },
                          [
                            _vm._v(
                              "\n                    Start time cannot be after end time\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ])
    : _c("div", [
        _c("div", { staticClass: "d-flex flex-row align-items-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-light btn-sm mx-2 px-2 w-100",
              attrs: { id: "timeRangeButton" + _vm.item.i },
            },
            [_vm._v(_vm._s("Duration: " + _vm.buttonText))]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex flex-column whiteText mx-2" },
            [
              _c(
                "b-popover",
                {
                  attrs: {
                    target: "timeRangeButton" + _vm.item.i,
                    triggers: "click blur",
                    placement: "bottom",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex flex-column whiteText mx-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between mb-2" },
                        [
                          _c("div", [_vm._v("Start Date: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedDayMin,
                                expression: "selectedDayMin",
                              },
                            ],
                            attrs: {
                              id: "selectedDayMin",
                              type: "date",
                              max: _vm.setStartDateMax(false),
                            },
                            domProps: { value: _vm.selectedDayMin },
                            on: {
                              change: function ($event) {
                                return _vm.emitCalculatedIntervalRange($event)
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.selectedDayMin = $event.target.value
                                },
                                function ($event) {
                                  return _vm.selectedDayMinUpdated($event)
                                },
                              ],
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between mb-2" },
                        [
                          _c("div", [_vm._v("End Date: ")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.selectedDayMax,
                                expression: "selectedDayMax",
                              },
                            ],
                            attrs: {
                              id: "selectedDayMax",
                              type: "date",
                              min: _vm.setEndDateMin(false),
                              max: _vm.getTodayDate(false),
                            },
                            domProps: { value: _vm.selectedDayMax },
                            on: {
                              change: function ($event) {
                                return _vm.emitCalculatedIntervalRange($event)
                              },
                              input: [
                                function ($event) {
                                  if ($event.target.composing) return
                                  _vm.selectedDayMax = $event.target.value
                                },
                                function ($event) {
                                  return _vm.selectedDayMaxUpdated($event)
                                },
                              ],
                            },
                          }),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "d-flex justify-content-between mb-2" },
                        [
                          _c("div", { staticClass: "mr-2" }, [
                            _vm._v(" Select Time Interval Type: "),
                          ]),
                          _vm._v(" "),
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedIntervalType,
                                  expression: "selectedIntervalType",
                                },
                              ],
                              on: {
                                input: function ($event) {
                                  return _vm.selectedIntervalTypeUpdated($event)
                                },
                                change: [
                                  function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.selectedIntervalType = $event.target
                                      .multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                  function ($event) {
                                    return _vm.emitCalculatedIntervalRange(
                                      $event
                                    )
                                  },
                                ],
                              },
                            },
                            _vm._l(
                              _vm.intervalOptions,
                              function (option, index) {
                                return _c(
                                  "option",
                                  { key: index, domProps: { value: option } },
                                  [_vm._v(_vm._s(option))]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ]
                  ),
                ]
              ),
            ],
            1
          ),
        ]),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }