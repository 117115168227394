<!--
    * Component Description
        A simple, re-usable switch wrapper that applies IWS styling and other small features
        See props for an explanation of all supported functions

    * Side Effects
		Will emit 2 signals
			update:value           	<- triggers upon selection of the switch
			change                 	<- triggers upon change of the switch value
			disabledClick			<- triggers when the user clicks and the switch is disabled (Useful to explain why the switch is disabled)

    * Example Usage
        <iws-switch
            labels="[ 'Off', 'On' ]"
            :value.sync="value"
        />
-->
<template>
    <div :id="id" class="iws-switch" :class="{ 'form-input-spacing': formSpacing !== false }">
        <label class="switch" :class="`${size} ${checked ? 'active' : ''} ${disabled ? 'disabled' : ''}`" @click="disabled ? $emit('disabledClick') : null">
            <input :checked="checked" @click="statusChanged" :disabled="disabled" type="checkbox" id="switchComponent">
            <span class="slider round"></span>

            <div v-if="!!labels && labels.length == 2" id="switch-label" :class="{ 'pull-left': checked }">
                {{ value ? labels[1] : labels[0] }}
            </div>
        </label>

        <label v-if="hint" class="secondary-text-color">
            {{ hint }}
        </label>
    </div>
</template>

<script>
export default {
    props: {
        id: {
            type: String
        },

		// Labels for input (should always give TWO). Apears on either side of the switch input
		labels: {
			type: Array
		},
		// Placeholder/hint that appears BELOW input (is ALWAYS visible when provided)
		hint: {
			type: String
		},

		// Synced value of input field
		// False is left (off), True is right (on)
		value: {
            type: Boolean
        },

		// Function that calls after value change (passing new value)
		onPress: {
			type: Function,
			required: false
		},

		// Switch size
        // Example: sm, small, md, medium, lg, large
        // Supports either short form or full wording
		size: {
			type: String,
			default: 'large'
		},

        disabled: {
            type: Boolean,
            default: false
        },

		// Provides a consistent margin between fields across forms
		formSpacing: {
            type: Boolean,
            default: false
        }
    },

	data() {
		return {
			checked: this.value
		}
	},

    methods: {
        statusChanged() {
            this.checked = !this.checked;
            this.$emit('update:value', this.checked);
            this.$emit('change', this.checked);

            if (this.onPress)
                this.onPress(this.checked);
        }
    }
};
</script>

<style>
	.switch {
		position: relative;
		display: inline-block;

		margin-bottom: 0px;

		cursor: pointer;

		border: 1px solid var(--form-color);
		border-radius: 34px;
	}
	.switch.active {
		border-color: var(--primary-color);
	}

	.switch.small {
		width: 30px;
		height: 17px;
	}
	.switch.medium {
		width: 40px;
		height: 23px;
	}
	.switch.large {
		width: 60px;
		height: 34px;
	}

	.switch.sm .slider:before ,
	.switch.small .slider:before {
		height: 13px;
		width: 13px;
		top: 0px;
	}
	.switch.md .slider:before,
	.switch.medium .slider:before {
		height: 17px;
		width: 17px;
		top: 2px;
	}
	.switch.lg .slider:before,
	.switch.large .slider:before {
		height: 22px;
		width: 22px;
		top: 5px;
		left: 5px;
	}

	.switch.small #switch-label {
		font-size: 10px;
	}
	.switch.medium #switch-label {
		font-size: 10px;
	}
	.switch.large #switch-label {
		font-size: 14px;
	}

	#switch-label {
		position: absolute;
		top: 20%;
		right: 10%;

		-webkit-transition: .4s;
		transition: .4s;
	}
	#switch-label.pull-left {
		right: none;
		left: 10%;
	}


	/* Hide default HTML checkbox */
	.switch input {
		opacity: 0;
		width: 0;
		height: 0;
	}

	/* The slider */
	.slider {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		-webkit-transition: .4s;
		transition: .4s;
	}

	.slider:before {
		position: absolute;
		content: "";

		left: 4px;
		bottom: 4px;
		background-color: white;
		-webkit-transition: .4s;
		transition: .4s;
	}

	input:checked + .slider {
		background-color: var(--primary-color);
	}

	input:focus + .slider {
		box-shadow: 0 0 1px var(--primary-color);
	}

	input:checked + .slider:before {
		-webkit-transform: translateX(110%);
		-ms-transform: translateX(110%);
		transform: translateX(110%);
	}

	/* Rounded sliders */
	.slider.round {
		border-radius: 34px;
	}

	.slider.round:before {
		border-radius: 50%;
	}

	.switch.disabled .slider {
		border-color: #7B8A98;
	}
	.switch.disabled .slider:before {
		background-color: #7B8A98;
	}
	.switch.disabled #switch-label {
		color: #7B8A98;
	}
</style>