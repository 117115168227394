<!--
    * Component Description
    For filtering Gun Library table. Indended use only for GunLibraryComponent.vue
-->

<template>
    <div :id="id">
        <iws-modal
            :title="title"
            :showModal="showBaseModal"
            id="LibraryFilters"
            :max-width="maxWidth"
            :max-height="maxHeight"
            @close="showBaseModal = false"
            :ref="`library_filters_${this.id}`"
            :closeOnClickaway="closeOnClickaway"
            :primaryButtonText="primaryButtonText"
            @secondary-action="clearFilters"
            :secondaryButtonText="secondaryButtonText"
            :primaryButtonDisabled="primaryButtonDisabled"
            :secondaryButtonDisabled="secondaryButtonDisabled">

            <template #content>
                <slot name="body"
                    :handleEvent="handleEvent"
                    :pushToFilter="pushToFilter"
                    :removeFromFilter="removeFromFilter"
                    :findUniqueValues="findUniqueValues"
                ></slot>
            </template>
        </iws-modal>
    </div>
</template>

<script>
import app from '../app.js';
import iwsModal from '../components/Common/iwsModal.vue';
import GlobalFunctions from '../GlobalFunctions.js';

export default {
    props: {
        id: {
            type: String,
            required: true
        },
        items: [Array, Object],
        filters: Object,
        ...iwsModal.props,
        primaryButtonText: {
            type: String,
            default: 'Filter'
        },
        secondaryButtonText: {
            type: String,
            default: 'Clear'
        },
        primaryButtonDisabled: {
            type: Boolean,
            default: false
        },
        secondaryButtonDisabled: {
            type: Boolean,
            default: false
        },
        maxHeight: {
            type: String,
            default: '60em'
        }
    },
    data() {
        return {
            showBaseModal: false
        }
    },
    methods: {
        findUniqueValues(column) {
            if (GlobalFunctions.isNullOrEmpty(this.items))
                return [];
            
            // 1. Convert the list of objects to a list of strings (defined by a single property)
            // 2. Filter Falsy values (i.e. empty strings, null, undefined) from the list
            // 3. Convert the list of strings to a set to remove duplicates, then back to a list
            return Array.from(new Set(this.items.map(_gun => _gun[column]).filter(_value => !GlobalFunctions.isFalsy(_value))));
        },
        pushToFilter(list, value) {
            if (!list.includes(value))
                list.push(value);
        },
        removeFromFilter(list, index) {
            if (!GlobalFunctions.isNullOrEmpty(list) && index >= 0 && index < list.length)
                list.splice(index, 1);
        },
        handleEvent(list, value) {
            if (list !== null) {
                const _index = list.indexOf(value);

                if (_index < 0) {
                    this.pushToFilter(list, value);
                } else {
                    this.removeFromFilter(list, _index);
                }
            }
        },
        clearFilters() {
            const filters = _.cloneDeep(this.filters);
            for (const [field, filter] of Object.entries(filters)) {
                if (filter != null) {
                    if (typeof filter == 'object')
                        filters[field] = Array.isArray(filter) ? [] : {};
                    else
                        filters[field] = null;
                }
            }
            this.$emit('clearFilters', filters);
        },
        open() {
            const self = this;
            
            function initModal(confirmCallback, cancelCallback) {
                self.showBaseModal = true;

                app.$on('modal-primary-action', () => {
                    self.showBaseModal = false;
                    confirmCallback();
                });

                app.$on('modal-close', () => {
                    self.showBaseModal = false;
                    cancelCallback();
                });
            }

            return new Promise( (resolve, reject) => {
                initModal(
                    () => resolve(this.filters),
                    () => resolve(null)
                );
            });
        }
    },
    computed: {
        modalWidth() {
            let width;
            if (typeof this.width === 'number')
                width = `${this.width}px`;
            return width;
        }
    }
};
</script>

<style>
    #LibraryFilters .filter-container {
        margin-bottom: 15px;
    }
    #LibraryFilters .filter-container.container-border {
        border: 1px solid #626770;
        border-radius: 5px;
        padding: 10px 20px 15px;
    }
    #LibraryFilters .filter-container label {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: var(--primary-text-color);
    }
    #LibraryFilters .filter-container select {
        background: #343A40;
        border: 1px solid #626770;

        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;

        color: var(--primary-text-color);
    }

    #LibraryFilters .fas.fa-times.clickable {
        margin-right: 5px;
    }
</style>