var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "d-flex justify-content-center" }, [
      _c("b", [_vm._v(_vm._s(_vm.title))]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "inner-bg py-1 mt-2" },
      [
        _c(
          "div",
          {
            staticClass: "font-small pt-2 d-flex flex-row",
            staticStyle: { "justify-content": "space-between" },
          },
          [
            _c("select-component", {
              attrs: {
                title: "",
                initSelected: _vm.initSelected,
                options: _vm.options,
                onChange: (value) => _vm.onDropdownValueChange(value),
                externalInputStyles: { fontSize: "0.9rem" },
              },
            }),
            _vm._v(" "),
            _c("div", [
              this.onEditClicked != null && this.dropdownselected != "None"
                ? _c(
                    "span",
                    {
                      staticClass: "btn btn-dark m-1 p-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { "aria-label": "Edit" },
                      on: { click: () => this.onEditClicked() },
                    },
                    [
                      _c("i", {
                        staticClass: "fas fa-pen",
                        attrs: { "aria-hidden": "true" },
                      }),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              this.onNewClicked != null
                ? _c(
                    "span",
                    {
                      staticClass: "btn btn-dark m-1 p-2",
                      staticStyle: { cursor: "pointer" },
                      attrs: { "aria-label": "New" },
                      on: { click: () => this.onNewClicked() },
                    },
                    [_c("i", { staticClass: "fas fa-plus" })]
                  )
                : _vm._e(),
            ]),
          ],
          1
        ),
        _vm._v(" "),
        _vm._l(_vm.descriptionValues, function (info, index) {
          return _c(
            "div",
            { key: index, staticClass: "d-flex flex-row m-2 px-1 font-small" },
            [
              _c("div", { staticClass: "flex-1 sub-txt" }, [
                _vm._v("\n\t\t\t\t" + _vm._s(info.name) + "\n\t\t\t"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex-grow-1" }),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "flex-1",
                  staticStyle: { "text-align": "right" },
                },
                [_vm._v("\n\t\t\t\t" + _vm._s(info.value) + "\n\t\t\t")]
              ),
            ]
          )
        }),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }