<template>
    <iws-modal
        :title="`${isEdit ? 'Edit' : 'Add'} Stream`"
        :primary-button-text="`${isEdit ? 'Edit' : 'Add'} Stream`"

        :showModal="showBaseModal"
        max-width="500px"

        @close="cancelAction"
        @secondary-action="cancelAction"
        @primary-action="confirmAction"
    >
        <template #content>
            <iws-input
                :value.sync="data.name"
                label="Stream ID"
                required
                ref="streamID"
            />

            <div style="margin-top: 30px">
                <iws-input
                    :value.sync="data.connectionURL"
                    label="RTSP URL"
                    required
                    ref="url"
                />
            </div>

            <div class="row" style="margin-top: 30px">
                <div class="col-6">
                    <iws-input
                        :value.sync="data.username"
                        label="Username"
                        required
                        ref="onvifUsername"
                        autocomplete="username"
                    />
                </div>

                <div class="col-6">
                    <iws-input
                        :value.sync="data.password"
                        label="Password"
                        type="password"
                        required
                        ref="onvifPassword"
                        autocomplete="new-password"
                    />
                </div>
            </div>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { flushToasts, isFalsy } = GlobalFunctions;

export default {
    data() {
        return {
            showBaseModal: false,
            resolve: null,
            isEdit: false,
            data: {
                name: null,
                connectionURL: null,
                username: null,
                password: null
            }
        };
    },
    
    methods: {
        cancelAction() {
            this.resolve(null)
        },
        confirmAction() {
            flushToasts();

            // Always run both validators to visually show if both are invalid
            const testResults = [
                this?.$refs?.streamID?.validateInput(),
                this?.$refs?.url?.validateInput(),
                this?.$refs?.onvifUsername?.validateInput(),
                this?.$refs?.onvifPassword?.validateInput()
            ];

            if (testResults[0] && testResults[1] && testResults[2] && testResults[3])
                this.resolve({ ...this.data });
        },

        open(stream) {
            if (isFalsy(stream)) {
                this.isEdit = false;
                this.data.name = null;
                this.data.connectionURL = null;
                this.data.username = null;
                this.data.password = null;
            } else {
                this.isEdit = true;
                this.data.name = stream.name || null;
                this.data.connectionURL = stream.connectionURL || null;
                this.data.username = stream.username || null;
                this.data.password = stream.password || null;
            }

            return new Promise( (resolve, reject) => {
                this.resolve = (value) => {
                    this.showBaseModal = false;
                    this.isEdit = false;
                    this.data.name = null;
                    this.data.connectionURL = null;
                    this.data.username = null;
                    this.data.password = null;
                    resolve(value);
                };

                this.showBaseModal = true;
            });
        }
    }
};
</script>