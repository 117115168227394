var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "table-responsive mb-0",
        style: { paddingTop: _vm.editMode ? "30px" : "0px" },
      },
      [
        _c(
          "table",
          {
            staticClass: "table table-sm text-left fixed-header",
            class: _vm.tableColor,
          },
          [
            _c("thead", [
              _c("tr", [
                _c(
                  "th",
                  { staticStyle: { width: "4%" } },
                  [
                    _c(
                      "b-popover",
                      {
                        attrs: {
                          target: "event-filter",
                          placement: "top",
                          boundary: "window",
                          triggers: "click blur",
                        },
                      },
                      [
                        _c("div", { staticClass: "row mb-1 mt-1" }, [
                          _c("div", { staticClass: "col-12" }, [
                            _c("div", { staticClass: "row mb-1 ml-1 mr-1" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-6 d-flex align-items-center",
                                  staticStyle: { "white-space": "nowrap" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.slideMinMax[0],
                                        expression: "slideMinMax[0]",
                                      },
                                    ],
                                    staticClass: "rounded w-100",
                                    staticStyle: { "border-style": "solid" },
                                    attrs: {
                                      min: _vm.slideData[0] + "T00:00:00",
                                      max:
                                        _vm.slideData[
                                          _vm.slideData.length - 1
                                        ] + "T23:59:00",
                                      type: "datetime-local",
                                      step: "1",
                                    },
                                    domProps: { value: _vm.slideMinMax[0] },
                                    on: {
                                      change: (startDate, endDate) =>
                                        _vm.setDateFilter(
                                          _vm.slideMinMax[0],
                                          endDate
                                        ),
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.slideMinMax,
                                          0,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-6 d-flex align-items-center",
                                  staticStyle: { "white-space": "nowrap" },
                                },
                                [
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value: _vm.slideMinMax[1],
                                        expression: "slideMinMax[1]",
                                      },
                                    ],
                                    staticClass: "rounded w-100",
                                    staticStyle: { "border-style": "solid" },
                                    attrs: {
                                      min: _vm.slideData[0] + "T00:00:00",
                                      max:
                                        _vm.slideData[
                                          _vm.slideData.length - 1
                                        ] + "T23:59:00",
                                      type: "datetime-local",
                                      step: "1",
                                    },
                                    domProps: { value: _vm.slideMinMax[1] },
                                    on: {
                                      change: (startDate, endDate) =>
                                        _vm.setDateFilter(
                                          startDate,
                                          _vm.slideMinMax[1]
                                        ),
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.slideMinMax,
                                          1,
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                ]
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "row mb-2 mr-1 ml-1" }, [
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterWell,
                                    expression: "filterWell",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.filterWell = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { domProps: { value: null } }, [
                                  _vm._v("--Filter By Well--"),
                                ]),
                                _vm._v(" "),
                                _vm._l(_vm.wells, function (well) {
                                  return _c(
                                    "option",
                                    {
                                      key: well.id,
                                      domProps: { value: well.id },
                                    },
                                    [_vm._v(_vm._s(well.name))]
                                  )
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass: "btn btn-primary btn-sm mt-2",
                                attrs: {
                                  type: "button",
                                  disabled: _vm.isFilterCleared,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.clearTableFilters()
                                  },
                                },
                              },
                              [_vm._v("Clear Filters")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterActivity,
                                    expression: "filterActivity",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.filterActivity = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { domProps: { value: null } }, [
                                  _vm._v("--Filter By Code--"),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.availableActivities,
                                  function (item) {
                                    return _c(
                                      "option",
                                      {
                                        key: item.activity_id,
                                        domProps: { value: item.activity_id },
                                      },
                                      [_vm._v(_vm._s(item.activity))]
                                    )
                                  }
                                ),
                              ],
                              2
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "col-4" }, [
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.filterRequester,
                                    expression: "filterRequester",
                                  },
                                ],
                                staticClass: "form-control form-control-sm",
                                on: {
                                  change: function ($event) {
                                    var $$selectedVal = Array.prototype.filter
                                      .call(
                                        $event.target.options,
                                        function (o) {
                                          return o.selected
                                        }
                                      )
                                      .map(function (o) {
                                        var val =
                                          "_value" in o ? o._value : o.value
                                        return val
                                      })
                                    _vm.filterRequester = $event.target.multiple
                                      ? $$selectedVal
                                      : $$selectedVal[0]
                                  },
                                },
                              },
                              [
                                _c("option", { domProps: { value: null } }, [
                                  _vm._v("--Filter By User--"),
                                ]),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.availableHandshakeRequesters,
                                  function (item, index) {
                                    return _c("option", { key: index }, [
                                      _vm._v(_vm._s(item)),
                                    ])
                                  }
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "button",
                              {
                                staticClass:
                                  "btn btn-danger mt-2 btn-sm float-right",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.$root.$emit("bv::hide::popover")
                                  },
                                },
                              },
                              [_vm._v("Close")]
                            ),
                          ]),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c("div", { attrs: { id: "event-filter" } }, [
                      _c(
                        "span",
                        {
                          directives: [
                            {
                              name: "tooltip",
                              rawName: "v-tooltip:top",
                              value: "Filter",
                              expression: "'Filter'",
                              arg: "top",
                            },
                          ],
                        },
                        [
                          _c("i", {
                            staticClass: "fas fa-filter clickable",
                            class: { green: !_vm.isFilterCleared },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "17%" } }, [_vm._v("Start")]),
                _vm._v(" "),
                _c("th", { staticStyle: { width: "17%" } }, [_vm._v("End")]),
                _vm._v(" "),
                _vm.editMode || _vm.shouldShowCodeColumn
                  ? _c("th", { staticStyle: { width: "15%" } }, [
                      _vm._v(
                        "\n                        Code\n                        "
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editMode && !_vm.shouldShowCodeColumn,
                            expression: "editMode && !shouldShowCodeColumn",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip:top",
                            value: "Show code column",
                            expression: "'Show code column'",
                            arg: "top",
                          },
                        ],
                        staticClass: "fas fa-eye-slash pl-1",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleColumn("code")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editMode && _vm.shouldShowCodeColumn,
                            expression: "editMode && shouldShowCodeColumn",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip:top",
                            value: "Hide code column",
                            expression: "'Hide code column'",
                            arg: "top",
                          },
                        ],
                        staticClass: "fas fa-eye pl-1",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleColumn("code")
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.editMode || _vm.shouldShowReasonColumn
                  ? _c("th", { staticStyle: { width: "15%" } }, [
                      _vm._v(
                        "\n                        Reason\n                        "
                      ),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editMode && !_vm.shouldShowReasonColumn,
                            expression: "editMode && !shouldShowReasonColumn",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip:top",
                            value: "Show reason column",
                            expression: "'Show reason column'",
                            arg: "top",
                          },
                        ],
                        staticClass: "fas fa-eye-slash pl-1",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleColumn("reason")
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("i", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.editMode && _vm.shouldShowReasonColumn,
                            expression: "editMode && shouldShowReasonColumn",
                          },
                          {
                            name: "tooltip",
                            rawName: "v-tooltip:top",
                            value: "Hide reason column",
                            expression: "'Hide reason column'",
                            arg: "top",
                          },
                        ],
                        staticClass: "fas fa-eye pl-1",
                        staticStyle: { cursor: "pointer" },
                        on: {
                          click: function ($event) {
                            return _vm.toggleColumn("reason")
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("th", { staticClass: "pl-3" }, [_vm._v("Remarks")]),
                _vm._v(" "),
                _c("th", [
                  _vm.isFeatureFlagged("DRAW_NPT")
                    ? _c("div", { staticClass: "d-inline float-right" }, [
                        _c(
                          "span",
                          {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip:top",
                                value: "New Entry",
                                expression: "'New Entry'",
                                arg: "top",
                              },
                            ],
                            on: { click: _vm.handleNewCommentButtonClicked },
                          },
                          [
                            _c("i", {
                              staticClass: "fa fa-plus-square clickable",
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "d-inline float-right mr-2" }, [
                    _c(
                      "span",
                      {
                        directives: [
                          {
                            name: "tooltip",
                            rawName: "v-tooltip:top",
                            value: "Export",
                            expression: "'Export'",
                            arg: "top",
                          },
                        ],
                        on: { click: _vm.exportTable },
                      },
                      [_c("i", { staticClass: "fas fa-download clickable" })]
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "tbody",
              {
                staticClass: "scrollbar-color small",
                style: { height: _vm.height - _vm.heightOffset + "px" },
              },
              [
                _vm.isLoading
                  ? _c(
                      "div",
                      {
                        staticClass: "h-100 row align-items-center text-center",
                      },
                      [_vm._m(0)]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "TransitionGroup",
                  { attrs: { name: "list" } },
                  _vm._l(_vm.filteredEventsData, function (event, index) {
                    return _c(
                      "tr",
                      { key: index },
                      [
                        !_vm.editComments.find(
                          (comment) => comment.comment_id == event.comment_id
                        )
                          ? [
                              _c("td", {
                                style: {
                                  width: "2%",
                                  backgroundColor: _vm.getWellColor(
                                    event.well_id
                                  ),
                                },
                              }),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "19%" } }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("shortTime")(event.start_time)
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticStyle: { width: "19%" } }, [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(
                                      _vm._f("shortTime")(event.end_time)
                                    ) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _vm.editMode || _vm.shouldShowCodeColumn
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "text-truncate",
                                      staticStyle: { width: "15%" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(event.activity) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.editMode || _vm.shouldShowReasonColumn
                                ? _c("td", { staticStyle: { width: "15%" } }, [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(event.reason) +
                                        "\n                        "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", [
                                _vm._v(
                                  "\n                            " +
                                    _vm._s(event.remarks) +
                                    "\n                        "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "align-middle",
                                  staticStyle: {
                                    width: "5%",
                                    "white-space": "nowrap",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "float-right" }, [
                                    event.type == "comment"
                                      ? _c("i", {
                                          staticClass: "fas fa-pen clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.editComment(
                                                event,
                                                index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    event.type == "comment"
                                      ? _c("i", {
                                          staticClass:
                                            "fas fa-trash-alt clickable",
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteEditComment(
                                                event,
                                                index
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                  ]),
                                ]
                              ),
                            ]
                          : _vm.getNewComment(event)
                          ? [
                              _c(
                                "td",
                                {
                                  style: {
                                    width: "2%",
                                    backgroundColor: _vm.getWellColor(
                                      _vm.getNewComment(event).well_id
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onWellSelectClicked(index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-caret-down",
                                        style: {
                                          color: _vm.getTitleColorForBG(
                                            _vm.getWellColor(
                                              _vm.getNewComment(event).well_id
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "dropdown" }, [
                                    _c(
                                      "div",
                                      {
                                        ref: "well_dropdown_" + index,
                                        refInFor: true,
                                        staticClass: "dropdown-content",
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                _vm.onWellSelected(
                                                  _vm.getNewComment(event),
                                                  null,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" --No Well Set--")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(_vm.wells, function (well) {
                                          return _c(
                                            "a",
                                            {
                                              staticStyle: {
                                                display: "flex",
                                                "align-items": "center",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  _vm.onWellSelected(
                                                    _vm.getNewComment(event),
                                                    well.id,
                                                    index
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass: "mr-2",
                                                  staticStyle: {
                                                    display: "inline-block",
                                                  },
                                                },
                                                [_vm._v(_vm._s(well.name))]
                                              ),
                                              _vm._v(" "),
                                              _c("div", {
                                                style: {
                                                  width: "25px",
                                                  height: "25px",
                                                  border: "solid #000000",
                                                  display: "inline-block",
                                                  marginLeft: "auto",
                                                  marginRight: "0px",
                                                  backgroundColor:
                                                    _vm.getWellColor(well.id),
                                                },
                                              }),
                                            ]
                                          )
                                        }),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { width: "19%" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group-sm white-text",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getNewComment(event).start_d,
                                            expression:
                                              "getNewComment(event).start_d",
                                          },
                                        ],
                                        ref: "start_date_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "date",
                                          step: "1",
                                          id: "popover-start" + _vm._uid,
                                        },
                                        domProps: {
                                          value:
                                            _vm.getNewComment(event).start_d,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onStartDateChanged(
                                              _vm.getNewComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getNewComment(event),
                                              "start_d",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getNewComment(event).start_t,
                                            expression:
                                              "getNewComment(event).start_t",
                                          },
                                        ],
                                        ref: "start_time_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "time",
                                          step: "1",
                                          id: "startTimePicker",
                                        },
                                        domProps: {
                                          value:
                                            _vm.getNewComment(event).start_t,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onStartTimeChanged(
                                              _vm.getNewComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getNewComment(event),
                                              "start_t",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        show: _vm.errorStartTimeInvalid,
                                        target: "popover-start" + _vm._uid,
                                        boundary: "window",
                                        triggers: "manual",
                                        placement: "bottom",
                                        variant: "danger",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.errorStartTimeInvalid = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Start time is invalid.\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        show: _vm.errorStartTimeLater,
                                        target: "popover-start" + _vm._uid,
                                        boundary: "window",
                                        triggers: "manual",
                                        variant: "danger",
                                        placement: "bottom",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.errorStartTimeLater = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Start time cannot be later than end time.\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { width: "19%" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group-sm white-text",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getNewComment(event).end_d,
                                            expression:
                                              "getNewComment(event).end_d",
                                          },
                                        ],
                                        ref: "end_date_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "date",
                                          step: "1",
                                          id: "popover-end" + _vm._uid,
                                        },
                                        domProps: {
                                          value: _vm.getNewComment(event).end_d,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onEndDateChanged(
                                              _vm.getNewComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getNewComment(event),
                                              "end_d",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getNewComment(event).end_t,
                                            expression:
                                              "getNewComment(event).end_t",
                                          },
                                        ],
                                        ref: "end_time_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "time",
                                          step: "1",
                                          id: "endTimePicker",
                                        },
                                        domProps: {
                                          value: _vm.getNewComment(event).end_t,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onEndTimeChanged(
                                              _vm.getNewComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getNewComment(event),
                                              "end_t",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        show: _vm.errorEndTimeInvalid,
                                        target: "popover-end" + _vm._uid,
                                        boundary: "window",
                                        triggers: "manual",
                                        variant: "danger",
                                        placement: "bottom",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.errorEndTimeInvalid = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Invalid end time.\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.shouldShowCodeColumn
                                ? _c("td", { staticStyle: { width: "15%" } }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getNewComment(event)
                                                .activity_id,
                                            expression:
                                              "getNewComment(event).activity_id",
                                          },
                                        ],
                                        ref: "activity_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { width: "100%" },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.getNewComment(event),
                                                "activity_id",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            (value) =>
                                              _vm.onActivityChanged(
                                                event,
                                                index
                                              ),
                                          ],
                                        },
                                      },
                                      _vm._l(
                                        _vm.eventActivities,
                                        function (item) {
                                          return _c(
                                            "option",
                                            {
                                              key: item.id,
                                              domProps: { value: item.id },
                                            },
                                            [_vm._v(_vm._s(item.type))]
                                          )
                                        }
                                      ),
                                      0
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.shouldShowReasonColumn
                                ? _c("td", { staticStyle: { width: "15%" } }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getNewComment(event)
                                                .reason_id,
                                            expression:
                                              "getNewComment(event).reason_id",
                                          },
                                        ],
                                        ref: "reason_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { width: "100%" },
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.getNewComment(event),
                                                "reason_id",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            (value) =>
                                              _vm.onReasonChanged(event, index),
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [_vm._v(" --Not Set--")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.visibleEventReasons(
                                            _vm.getNewComment(event)
                                          ),
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.id,
                                                domProps: { value: item.id },
                                              },
                                              [_vm._v(_vm._s(item.type))]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", [
                                _c("textarea", {
                                  ref: "remark_" + index,
                                  refInFor: true,
                                  staticClass: "form-control form-control-sm",
                                  staticStyle: { width: "100%" },
                                  attrs: { rows: "3" },
                                  domProps: {
                                    value: _vm.getNewComment(event).remarks,
                                  },
                                  on: {
                                    input: (value) =>
                                      _vm.onRemarkChanged(
                                        _vm.getNewComment(event),
                                        index
                                      ),
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "align-middle",
                                  staticStyle: {
                                    width: "5%",
                                    "white-space": "nowrap",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "float-right" }, [
                                    _c("i", {
                                      staticClass: "fas fa-check clickable",
                                      on: {
                                        click: function ($event) {
                                          _vm.saveComment(
                                            _vm.getNewComment(event),
                                            index
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fas fa-times clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelEditComment(
                                            event,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          : _vm.getExistingComment(event)
                          ? [
                              _c(
                                "td",
                                {
                                  style: {
                                    width: "2%",
                                    backgroundColor: _vm.getWellColor(
                                      _vm.getExistingComment(event).well_id
                                    ),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onWellSelectClicked(index)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    { staticStyle: { "text-align": "center" } },
                                    [
                                      _c("i", {
                                        staticClass: "fas fa-caret-down",
                                        style: {
                                          color: _vm.getTitleColorForBG(
                                            _vm.getWellColor(
                                              _vm.getExistingComment(event)
                                                .well_id
                                            )
                                          ),
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("div", { staticClass: "dropdown" }, [
                                    _c(
                                      "div",
                                      {
                                        ref: "well_dropdown_" + index,
                                        refInFor: true,
                                        staticClass: "dropdown-content",
                                        attrs: { tabindex: "0" },
                                      },
                                      [
                                        _c(
                                          "a",
                                          {
                                            on: {
                                              click: function ($event) {
                                                _vm.onWellSelected(
                                                  _vm.getExistingComment(event),
                                                  null,
                                                  index
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" --No Well Set--")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.wells,
                                          function (well, index) {
                                            return _c(
                                              "a",
                                              {
                                                key: index,
                                                staticStyle: {
                                                  display: "flex",
                                                  "align-items": "center",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    _vm.onWellSelected(
                                                      _vm.getExistingComment(
                                                        event
                                                      ),
                                                      well.id,
                                                      index
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "mr-2",
                                                    staticStyle: {
                                                      display: "inline-block",
                                                    },
                                                  },
                                                  [_vm._v(_vm._s(well.name))]
                                                ),
                                                _vm._v(" "),
                                                _c("div", {
                                                  style: {
                                                    width: "25px",
                                                    height: "25px",
                                                    border: "solid #000000",
                                                    display: "inline-block",
                                                    marginLeft: "auto",
                                                    marginRight: "0px",
                                                    backgroundColor:
                                                      _vm.getWellColor(well.id),
                                                  },
                                                }),
                                              ]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { width: "19%" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group-sm white-text",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getExistingComment(event)
                                                .start_d,
                                            expression:
                                              "getExistingComment(event).start_d",
                                          },
                                        ],
                                        ref: "start_date_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "date",
                                          step: "1",
                                          id: "popover-edit-start" + _vm._uid,
                                        },
                                        domProps: {
                                          value:
                                            _vm.getExistingComment(event)
                                              .start_d,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onStartDateChanged(
                                              _vm.getExistingComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getExistingComment(event),
                                              "start_d",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getExistingComment(event)
                                                .start_t,
                                            expression:
                                              "getExistingComment(event).start_t",
                                          },
                                        ],
                                        ref: "start_time_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "time",
                                          step: "1",
                                          id: "startTimePicker",
                                        },
                                        domProps: {
                                          value:
                                            _vm.getExistingComment(event)
                                              .start_t,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onStartTimeChanged(
                                              _vm.getExistingComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getExistingComment(event),
                                              "start_t",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        show: _vm.errorStartTimeInvalid,
                                        target: "popover-edit-start" + _vm._uid,
                                        boundary: "window",
                                        triggers: "manual",
                                        variant: "danger",
                                        placement: "bottom",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.errorStartTimeInvalid = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Start time is invalid.\n                            "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        show: _vm.errorStartTimeLater,
                                        target: "popover-edit-start" + _vm._uid,
                                        boundary: "window",
                                        triggers: "manual",
                                        variant: "danger",
                                        placement: "bottom",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.errorStartTimeLater = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Start time cannot be later than end time.\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "td",
                                { staticStyle: { width: "19%" } },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group-sm white-text",
                                    },
                                    [
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getExistingComment(event)
                                                .end_d,
                                            expression:
                                              "getExistingComment(event).end_d",
                                          },
                                        ],
                                        ref: "end_date_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "date",
                                          step: "1",
                                          id: "popover-edit-end" + _vm._uid,
                                        },
                                        domProps: {
                                          value:
                                            _vm.getExistingComment(event).end_d,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onEndDateChanged(
                                              _vm.getExistingComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getExistingComment(event),
                                              "end_d",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getExistingComment(event)
                                                .end_t,
                                            expression:
                                              "getExistingComment(event).end_t",
                                          },
                                        ],
                                        ref: "end_time_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        staticStyle: { "max-width": "150px" },
                                        attrs: {
                                          type: "time",
                                          step: "1",
                                          id: "endTimePicker",
                                        },
                                        domProps: {
                                          value:
                                            _vm.getExistingComment(event).end_t,
                                        },
                                        on: {
                                          change: (value) =>
                                            _vm.onEndTimeChanged(
                                              _vm.getExistingComment(event),
                                              index
                                            ),
                                          input: function ($event) {
                                            if ($event.target.composing) return
                                            _vm.$set(
                                              _vm.getExistingComment(event),
                                              "end_t",
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "b-popover",
                                    {
                                      attrs: {
                                        show: _vm.errorEndTimeInvalid,
                                        target: "popover-edit-end" + _vm._uid,
                                        boundary: "window",
                                        triggers: "manual",
                                        variant: "danger",
                                        placement: "bottom",
                                      },
                                      on: {
                                        "update:show": function ($event) {
                                          _vm.errorEndTimeInvalid = $event
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                Invalid end time.\n                            "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm.shouldShowCodeColumn
                                ? _c(
                                    "td",
                                    {
                                      staticClass: "text-truncate",
                                      staticStyle: { width: "15%" },
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.getExistingComment(event)
                                                  .activity_id,
                                              expression:
                                                "getExistingComment(event).activity_id",
                                            },
                                          ],
                                          ref: "activity_" + index,
                                          refInFor: true,
                                          staticClass:
                                            "form-control form-control-sm",
                                          on: {
                                            change: [
                                              function ($event) {
                                                var $$selectedVal =
                                                  Array.prototype.filter
                                                    .call(
                                                      $event.target.options,
                                                      function (o) {
                                                        return o.selected
                                                      }
                                                    )
                                                    .map(function (o) {
                                                      var val =
                                                        "_value" in o
                                                          ? o._value
                                                          : o.value
                                                      return val
                                                    })
                                                _vm.$set(
                                                  _vm.getExistingComment(event),
                                                  "activity_id",
                                                  $event.target.multiple
                                                    ? $$selectedVal
                                                    : $$selectedVal[0]
                                                )
                                              },
                                              (value) =>
                                                _vm.onActivityChanged(
                                                  event,
                                                  index
                                                ),
                                            ],
                                          },
                                        },
                                        _vm._l(
                                          _vm.eventActivities,
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.id,
                                                domProps: { value: item.id },
                                              },
                                              [_vm._v(_vm._s(item.type))]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.shouldShowReasonColumn
                                ? _c("td", { staticStyle: { width: "15%" } }, [
                                    _c(
                                      "select",
                                      {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.getExistingComment(event)
                                                .reason_id,
                                            expression:
                                              "getExistingComment(event).reason_id",
                                          },
                                        ],
                                        ref: "reason_" + index,
                                        refInFor: true,
                                        staticClass:
                                          "form-control form-control-sm",
                                        on: {
                                          change: [
                                            function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.getExistingComment(event),
                                                "reason_id",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                            (value) =>
                                              _vm.onReasonChanged(event, index),
                                          ],
                                        },
                                      },
                                      [
                                        _c(
                                          "option",
                                          { domProps: { value: null } },
                                          [_vm._v(" --Not Set--")]
                                        ),
                                        _vm._v(" "),
                                        _vm._l(
                                          _vm.visibleEventReasons(
                                            _vm.getExistingComment(event)
                                          ),
                                          function (item) {
                                            return _c(
                                              "option",
                                              {
                                                key: item.id,
                                                domProps: { value: item.id },
                                              },
                                              [_vm._v(_vm._s(item.type))]
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ])
                                : _vm._e(),
                              _vm._v(" "),
                              _c("td", [
                                _c("textarea", {
                                  ref: "remark_" + index,
                                  refInFor: true,
                                  staticClass: "form-control form-control-sm",
                                  staticStyle: { width: "100%" },
                                  attrs: { rows: "3" },
                                  domProps: {
                                    value:
                                      _vm.getExistingComment(event).remarks,
                                  },
                                  on: {
                                    input: (value) =>
                                      _vm.onRemarkChanged(
                                        _vm.getExistingComment(event),
                                        index
                                      ),
                                  },
                                }),
                              ]),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass: "align-middle",
                                  staticStyle: {
                                    width: "5%",
                                    "white-space": "nowrap",
                                  },
                                },
                                [
                                  _c("div", { staticClass: "float-right" }, [
                                    _c("i", {
                                      staticClass: "fas fa-check clickable",
                                      on: {
                                        click: function ($event) {
                                          _vm.saveComment(
                                            _vm.getExistingComment(event),
                                            index
                                          )
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("i", {
                                      staticClass: "fas fa-times clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.cancelEditComment(
                                            event,
                                            index
                                          )
                                        },
                                      },
                                    }),
                                  ]),
                                ]
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ],
              1
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col" }, [
      _c("div", { staticClass: "spinner-border", attrs: { role: "status" } }, [
        _c("span", { staticClass: "sr-only" }, [_vm._v("Loading...")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }