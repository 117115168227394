var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: `gun_view_${_vm.id}`,
      staticClass: "dark-modal modal",
      attrs: { id: `gun_view_${_vm.id}`, role: "document" },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "h5",
              {
                staticClass: "modal-title",
                attrs: { id: "exampleModalCenterTitle" },
              },
              [_vm._v("\n                    View Gun\n                ")]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "close",
                attrs: { type: "button" },
                on: { click: _vm.hide },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("div", { staticClass: "field-container" }, [
              _c("label", [
                _vm._v("\n                        Name\n                    "),
              ]),
              _vm._v(" "),
              _c("div", [
                _vm._v(
                  "\n                        " +
                    _vm._s(_vm.gun.name) +
                    "\n                    "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _vm.enableCustomerCol
              ? _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                        Customer Name\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.gun.customerName) +
                        "\n                    "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.enableCustomerCol ? _c("hr") : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Size (in)\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.size) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Gun Barrel Length (in)\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.length) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Cluster Length (in)\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.cluster_length) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Shot Density (Shots/ft)\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.shot_density) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Number of Shots Planned\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.shots_planned) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Nominal Penetration (in)\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.nominal_penetration) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Description\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.description) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Estimated Hole Diameter (in)\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.estimated_hole_diameter) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Metallurgy\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.metallurgy) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Centralized\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.centralized || "No") +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Conveyance Method\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.conveyance_method) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Carrier Description\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.carrier_description) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Carrier Make\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.carrier_make) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Charge Type\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.carrier_type) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Charge Size\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.charge_size) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Charge Make\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.charge_make) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Explosive Type\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.explosive_type) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Phasing\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.phasing) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Orientation\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.orientation) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col-6" }, [
                _c("div", { staticClass: "field-container" }, [
                  _c("label", [
                    _vm._v(
                      "\n                                Orientation Method\n                            "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      "\n                                " +
                        _vm._s(_vm.gun.orientation_method) +
                        "\n                            "
                    ),
                  ]),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }