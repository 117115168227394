var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "text-center m-5", attrs: { role: "status" } },
            [_c("b-spinner", { attrs: { label: "Loading..." } })],
            1
          )
        : _c(
            "b-tabs",
            {
              ref: "tabs",
              attrs: {
                align: "right",
                "content-class": "mt-4",
                "active-nav-item-class":
                  "border-light bg-transparent active-tab-bottom",
              },
              model: {
                value: _vm.currentTab,
                callback: function ($$v) {
                  _vm.currentTab = $$v
                },
                expression: "currentTab",
              },
            },
            [
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "All Dashboards",
                    disabled: _vm.disableTab,
                    "title-link-class": "text-white",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-4 search-bar" },
                    [
                      _c(
                        "b-input-group",
                        [
                          _c("b-form-input", {
                            attrs: {
                              id: "filter-input",
                              type: "search",
                              placeholder: "Filter dashboards...",
                            },
                            model: {
                              value: _vm.filter,
                              callback: function ($$v) {
                                _vm.filter = $$v
                              },
                              expression: "filter",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "b-input-group-append",
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: { disabled: !_vm.filter },
                                  on: {
                                    click: function ($event) {
                                      return _vm.getDashboards()
                                    },
                                  },
                                },
                                [_vm._v("Search")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-table", {
                    ref: "dashboards-table-1",
                    attrs: {
                      small: "",
                      striped: "",
                      hover: "",
                      dark: "",
                      responsive: "sm",
                      "per-page": _vm.perPage,
                      "tbody-tr-class": _vm.selectedItemClass,
                      items: _vm.dashboards,
                      fields: _vm.dashboardFields,
                      "show-empty": "",
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "cell(name)",
                        fn: function (data) {
                          return [
                            _c(
                              "a",
                              {
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    _vm.setSelectedDashboard(
                                      data.item.id,
                                      _vm.currentPage,
                                      true
                                    )
                                    _vm.$emit("close")
                                  },
                                },
                              },
                              [
                                _c(
                                  "b",
                                  {
                                    class: {
                                      "text-info":
                                        data.item.id !=
                                        _vm.selectedDashboard.id,
                                      "text-white":
                                        data.item.id ==
                                        _vm.selectedDashboard.id,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            " +
                                        _vm._s(data.item.name) +
                                        "\n                        "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: "cell(edit)",
                        fn: function (data) {
                          return [
                            _c("i", {
                              staticClass: "fas fa-trash clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.deleteDashboard(data.item)
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ]),
                  }),
                  _vm._v(" "),
                  _vm.dashboards.length > 0
                    ? _c(
                        "div",
                        { staticClass: "d-flex justify-content-between" },
                        [
                          _c(
                            "div",
                            [
                              _vm.lastPage > 1
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        size: "sm",
                                        disabled: _vm.currentPage == 1,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToPage(1)
                                        },
                                      },
                                    },
                                    [_vm._v("First")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.currentPage > 1
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary", size: "sm" },
                                      on: { click: _vm.previousPage },
                                    },
                                    [_vm._v("Previous")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("p", [
                            _vm._v(
                              _vm._s(_vm.currentPage) +
                                " / " +
                                _vm._s(_vm.lastPage)
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            [
                              _vm.lastPage != _vm.currentPage &&
                              _vm.currentPage < _vm.lastPage
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: { variant: "primary", size: "sm" },
                                      on: { click: _vm.nextPage },
                                    },
                                    [_vm._v("Next")]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.lastPage > 1
                                ? _c(
                                    "b-button",
                                    {
                                      attrs: {
                                        variant: "primary",
                                        size: "sm",
                                        disabled:
                                          _vm.currentPage == _vm.lastPage,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.goToPage(_vm.lastPage)
                                        },
                                      },
                                    },
                                    [_vm._v("Last")]
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "float-right" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mt-2 modal-default-button",
                          attrs: { variant: "secondary" },
                          on: { click: _vm.closeModal },
                        },
                        [_vm._v("Close\n                ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Manage Fleet Defaults",
                    disabled: _vm.disableTab,
                    "title-link-class": "text-white",
                  },
                },
                [
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("div", { staticClass: "d-flex mb-2" }, [
                        _c(
                          "div",
                          { staticClass: "form-group has-validation mr-3" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "fleet" },
                              },
                              [_vm._v("Fleet:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedFleet,
                                    expression: "selectedFleet",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { id: "fleet", required: "" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedFleet = $event.target.multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.fetchFleetDashboard,
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: { disabled: "", selected: "" },
                                    domProps: { value: null },
                                  },
                                  [_vm._v("Select fleet")]
                                ),
                                _vm._v(" "),
                                _vm._l(_vm.fleets, function (fleet) {
                                  return _c(
                                    "option",
                                    {
                                      key: fleet.id,
                                      domProps: { value: fleet.id },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(fleet.name) +
                                          " - " +
                                          _vm._s(fleet.vendor_name)
                                      ),
                                    ]
                                  )
                                }),
                              ],
                              2
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "form-group has-validation" },
                          [
                            _c(
                              "label",
                              {
                                staticClass: "form-label",
                                attrs: { for: "job-type" },
                              },
                              [_vm._v("Job Type:")]
                            ),
                            _vm._v(" "),
                            _c(
                              "select",
                              {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.selectedJobType,
                                    expression: "selectedJobType",
                                  },
                                ],
                                staticClass: "form-control",
                                attrs: { id: "job-type", required: "" },
                                on: {
                                  change: [
                                    function ($event) {
                                      var $$selectedVal = Array.prototype.filter
                                        .call(
                                          $event.target.options,
                                          function (o) {
                                            return o.selected
                                          }
                                        )
                                        .map(function (o) {
                                          var val =
                                            "_value" in o ? o._value : o.value
                                          return val
                                        })
                                      _vm.selectedJobType = $event.target
                                        .multiple
                                        ? $$selectedVal
                                        : $$selectedVal[0]
                                    },
                                    _vm.fetchFleetDashboard,
                                  ],
                                },
                              },
                              [
                                _c(
                                  "option",
                                  {
                                    attrs: {
                                      value: "",
                                      disabled: "",
                                      selected: "",
                                    },
                                  },
                                  [_vm._v("Select job type")]
                                ),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "FS" } }, [
                                  _vm._v("Standard Frac"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "CF" } }, [
                                  _vm._v("Continuous Frac"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "MF" } }, [
                                  _vm._v("Multifrac"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "SF" } }, [
                                  _vm._v("Simulfrac"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "TF" } }, [
                                  _vm._v("Trimulfrac"),
                                ]),
                                _vm._v(" "),
                                _c("option", { attrs: { value: "CM" } }, [
                                  _vm._v("Camera Module"),
                                ]),
                              ]
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm.fracFleetError
                          ? _c(
                              "div",
                              {
                                staticClass: "mx-3 my-3",
                                staticStyle: { "text-align": "center" },
                                on: {
                                  click: function ($event) {
                                    return _vm.openSupportModal()
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "warning-text" }, [
                                  _vm._v(
                                    "WARNING : No frac fleet assigned to currently selected Job."
                                  ),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "a",
                                  {
                                    staticClass:
                                      "text-white show-clicker-finger",
                                  },
                                  [
                                    _c("b", [
                                      _vm._v(
                                        "Please click here to contact support."
                                      ),
                                    ]),
                                  ]
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]),
                      _vm._v(" "),
                      _c("b-table", {
                        ref: "fleet-table",
                        attrs: {
                          small: "",
                          striped: "",
                          hover: "",
                          dark: "",
                          responsive: "sm",
                          items: _vm.fleetDashboards,
                          "sticky-header": _vm.tableHeight,
                          fields: _vm.fleetDashboardFields,
                          "show-empty": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "cell(remove)",
                            fn: function (data) {
                              return [
                                _c("i", {
                                  directives: [
                                    {
                                      name: "tooltip",
                                      rawName: "v-tooltip:bottom",
                                      value: "Remove from fleet",
                                      expression: "'Remove from fleet'",
                                      arg: "bottom",
                                    },
                                  ],
                                  staticClass: "fas fa-minus-circle clickable",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeFromFleet(data.item)
                                    },
                                  },
                                }),
                              ]
                            },
                          },
                          {
                            key: "cell(order)",
                            fn: function (data) {
                              return [
                                _vm.fleetDashboards.indexOf(data.item) > 0
                                  ? _c("i", {
                                      staticClass: "fas fa-arrow-up clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveUp(
                                            data.item,
                                            _vm.fleetDashboards,
                                            "fleet-table"
                                          )
                                        },
                                      },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "fas fa-arrow-up disabled-icon",
                                    }),
                                _vm._v(" "),
                                _vm.fleetDashboards.indexOf(data.item) <
                                _vm.fleetDashboards.length - 1
                                  ? _c("i", {
                                      staticClass:
                                        "fas fa-arrow-down clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.moveDown(
                                            data.item,
                                            _vm.fleetDashboards,
                                            "fleet-table"
                                          )
                                        },
                                      },
                                    })
                                  : _c("i", {
                                      staticClass:
                                        "fas fa-arrow-down disabled-icon",
                                    }),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    [
                      _c("h4", [_vm._v("All Dashboards")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "mb-4 search-bar" },
                        [
                          _c(
                            "b-input-group",
                            [
                              _c("b-form-input", {
                                attrs: {
                                  id: "filter-input",
                                  type: "search",
                                  placeholder: "Filter dashboards...",
                                },
                                model: {
                                  value: _vm.filter,
                                  callback: function ($$v) {
                                    _vm.filter = $$v
                                  },
                                  expression: "filter",
                                },
                              }),
                              _vm._v(" "),
                              _c(
                                "b-input-group-append",
                                [
                                  _c(
                                    "b-button",
                                    {
                                      attrs: { disabled: !_vm.filter },
                                      on: {
                                        click: function ($event) {
                                          return _vm.getDashboards()
                                        },
                                      },
                                    },
                                    [_vm._v("Search")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("b-table", {
                        ref: "dashboards-table-2",
                        attrs: {
                          small: "",
                          striped: "",
                          hover: "",
                          dark: "",
                          responsive: "sm",
                          "per-page": _vm.dashboardsPerPage,
                          "sticky-header": _vm.tableHeight,
                          items: _vm.filteredDashboards,
                          fields: _vm.dashboardFields,
                          "show-empty": "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "empty",
                            fn: function (scope) {
                              return [
                                _c("p", { staticClass: "text-center" }, [
                                  _vm._v(
                                    _vm._s(
                                      _vm.dashboards.length > 0
                                        ? "All available dashboards for the current page have been assigned to the selected fleet"
                                        : scope.emptyText
                                    )
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "cell(add)",
                            fn: function (data) {
                              return [
                                data.item.shared_with == "All users"
                                  ? _c("i", {
                                      directives: [
                                        {
                                          name: "tooltip",
                                          rawName: "v-tooltip:bottom",
                                          value: "Add to fleet",
                                          expression: "'Add to fleet'",
                                          arg: "bottom",
                                        },
                                      ],
                                      staticClass:
                                        "fas fa-plus-circle clickable",
                                      on: {
                                        click: function ($event) {
                                          return _vm.addToFleet(data.item)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                              ]
                            },
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm.dashboards.length > 0
                        ? _c(
                            "div",
                            { staticClass: "d-flex justify-content-between" },
                            [
                              _c(
                                "div",
                                [
                                  _vm.lastPage > 1
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            size: "sm",
                                            disabled: _vm.currentPage == 1,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToPage(1)
                                            },
                                          },
                                        },
                                        [_vm._v("First")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.currentPage > 1
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            size: "sm",
                                          },
                                          on: { click: _vm.previousPage },
                                        },
                                        [_vm._v("Previous")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", [
                                _vm._v(
                                  _vm._s(_vm.currentPage) +
                                    " / " +
                                    _vm._s(_vm.lastPage)
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "div",
                                [
                                  _vm.lastPage != _vm.currentPage &&
                                  _vm.currentPage < _vm.lastPage
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            size: "sm",
                                          },
                                          on: { click: _vm.nextPage },
                                        },
                                        [_vm._v("Next")]
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.lastPage > 1
                                    ? _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            variant: "primary",
                                            size: "sm",
                                            disabled:
                                              _vm.currentPage == _vm.lastPage,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goToPage(_vm.lastPage)
                                            },
                                          },
                                        },
                                        [_vm._v("Last")]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "float-right mt-2" },
                        [
                          _vm.dashboardsOrderChanged
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "modal-default-button mr-2",
                                  attrs: { variant: "danger" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit("close")
                                    },
                                  },
                                },
                                [_vm._v("Discard")]
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "b-button",
                            {
                              staticClass: "modal-default-button",
                              attrs: {
                                variant: _vm.dashboardsOrderChanged
                                  ? "success"
                                  : "primary",
                              },
                              on: { click: _vm.updateFleetDashboards },
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.dashboardsOrderChanged
                                      ? "Save"
                                      : "Close"
                                  )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                "b-tab",
                {
                  attrs: {
                    title: "Customer Defaults",
                    disabled: _vm.disableTab,
                    "title-link-class": "text-white",
                  },
                },
                [
                  _vm.dashboards && _vm.dashboards.length < 1
                    ? _c("div", [
                        _c("div", { staticClass: "my-auto p-1" }, [
                          _vm._v("No customer default dashboards available"),
                        ]),
                      ])
                    : _c(
                        "div",
                        [
                          _c("b-table", {
                            ref: "customer-table",
                            staticStyle: {
                              "max-height": "60vh",
                              "overflow-y": "auto",
                            },
                            attrs: {
                              small: "",
                              striped: "",
                              hover: "",
                              dark: "",
                              responsive: "sm",
                              items: _vm.dashboards,
                              fields: _vm.dashboardFields,
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "cell(order)",
                                fn: function (data) {
                                  return [
                                    data.item.is_customer_default
                                      ? _c("div", [
                                          _vm.dashboards.indexOf(data.item) > 0
                                            ? _c("i", {
                                                staticClass:
                                                  "fas fa-arrow-up clickable",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveUp(
                                                      data.item,
                                                      _vm.dashboards,
                                                      "customer-table"
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "fas fa-arrow-up disabled-icon",
                                              }),
                                          _vm._v(" "),
                                          _vm.dashboards.indexOf(data.item) <
                                          _vm.defaultDashboardsCount() - 1
                                            ? _c("i", {
                                                staticClass:
                                                  "fas fa-arrow-down clickable",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.moveDown(
                                                      data.item,
                                                      _vm.dashboards,
                                                      "customer-table"
                                                    )
                                                  },
                                                },
                                              })
                                            : _c("i", {
                                                staticClass:
                                                  "fas fa-arrow-down disabled-icon",
                                              }),
                                        ])
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "float-right mt-2" },
                    [
                      _vm.dashboardsOrderChanged
                        ? _c(
                            "b-button",
                            {
                              staticClass: "modal-default-button mr-2",
                              attrs: { variant: "danger" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("close")
                                },
                              },
                            },
                            [_vm._v("Discard")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "b-button",
                        {
                          staticClass: "modal-default-button",
                          attrs: {
                            variant: _vm.dashboardsOrderChanged
                              ? "success"
                              : "primary",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateDashboardsOrder()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n                    " +
                              _vm._s(
                                _vm.dashboardsOrderChanged ? "Save" : "Close"
                              )
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }