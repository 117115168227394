<template>
    <div class="col" :style="wellStyle()" @click="wellSelectedEvent()" @touchend="wellSelectedEvent()">
      <div v-if="well.emergencyBypass"  class="row row-cols-1">
        <div class="text-center h4 mb-1 py-1" style="color:#ffffff;background-color:#ce5835;">EMERGENCY BYPASS</div>
      </div>
      <process-step-component :currentStep="currentStep" 
          :valveStates="well.valves" :lastStep="lastStep" 
          :wellNumber="index" :activity="well.activity" 
          v-if="processActive"></process-step-component>
        <div class="row justify-content-center mb-0 pb-0">
          <h5 class="text-center mb-0">{{well.wirelineTruck}}</h5>
        </div>
        <div class="row mb-1 mt-0 pt-0">
          <div class="col mt-0 pr-0 pl-0 pt-0 d-flex justify-content-center">
            <wireline-activity v-if="well.activity == 'wireline'" :spoolCol="spoolCol" style="max-height: 40px"></wireline-activity>
            <frac-activity v-if="well.activity == 'frac'" style="max-height: 40px"></frac-activity>
            <maintenance-activity v-if="well.activity == 'maintenance'" style="max-height: 40px"></maintenance-activity>
          </div>
        </div>
        <div class="row justify-content-center mt-0 pb-0">
          <h5 v-if="well.activity != 'none'" style="text-transform: uppercase;" class="text-center mt-0">{{well.activity}}</h5>
        </div>
        <div class="row mb-1">
            <div class="col text-center">
              <div style="position:relative;height:100%;" @click="action()" @touchend="action()" :ref="imgId">
                <img class="img-fluid" :src="'../images/well_'+well.config_number+'.png'" 
                :id="imgId" 
                :key="imgId"
                :style="{'max-height':maxWellHeight+'px'}">
                <valve-component v-for="(valve, index) in well.valves" :state="valve" :config="well.config_number"
                :imgId="imgId" :currentStep="currentStep" :key="index" :index="index"></valve-component>
              </div>
              <div style="position:absolute;right:2px;bottom:2px;width:30%;z-index:1;" class="border" @click="pressureAction('casing')"
                  @touchend="pressureAction('casing')" :class="classCasingPressure">
                  <div style="font-size:1.2em;">{{casingPressureValue}}</div>
                  <div style="font-size:0.8em;">psi</div>
                </div>
            </div>
        </div>
        <div class="row mb-1" v-if="!well.config_number">
            <div v-if="well.unlocked" style="background-color:#00ff00;" class="col m-1">
                <h5 class="text-center mb-0">UNLOCKED</h5>
            </div>
            <div v-if="!well.unlocked" style="background-color:#ff0000;" class="col m-1">
                <h5 class="text-center mb-0">LOCKED</h5>
            </div>
        </div>
        <div class="row row-cols-1 " >
          <div :style="{'background-color':well.color,'color':nameColor}" class="col">
            <h5 class="text-center mb-0 h1 py-2">{{well.name}}</h5>
          </div>
          <div class="col text-center mb-1 h5" :style="{height:statusHeight+'px','background-color':well.color,'color':nameColor}">         
            Stage {{well.currentStage}}/{{well.numberOfStages}} 
            <br>
            <div class="col mb-4 h5">{{well.statusString}}</div>
          </div>
          </div>
        <div>
          <b-modal :ref="'pressureModal'+index" title="Pressure">
            <div class="btn btn-lg btn-primary btn-block py-3" @click="setPressureEnable('true')" @touchend="setPressureEnable('true')">Enable</div>
            <div class="btn btn-lg btn-primary btn-block py-3" @click="setPressureEnable('false')" @touchend="setPressureEnable('false')">Disable</div>
            <div class="btn btn-lg btn-primary btn-block py-3" @click="zeroPressure()" @touchend="zeroPressure()">Zero</div>
            <div slot="modal-footer" class="btn btn-lg btn-secondary btn-block py-3" @click="hidePressureModal()" @touchend="hidePressureModal()">Cancel</div>
          </b-modal>
        </div>
    </div>
</template>

<script>
  import GlobalFunctions from '../GlobalFunctions.js'
    export default {
        data() {
            return {
                selectedPressure:"none",
                imgId:'',
                statusHeight:70,
                classCasingPressure:'',
                casingPressureValue: this.well.pressures.casing.value,
                spoolCol:'#000000'
            }
        },
        props: ['index','wireline','role','username',
        'well','width','maxWellHeight','fracThreshold','handshakeWellComp','selectedWellIndex','valveSelectWell'],
        computed:{
          wellImage(){
            if(this.$root.darkMode)
              return '../images/WellheadTemplate.png';
            else
              return '../images/WellheadTemplate_dark.png';
          },
          nameColor(){
            return GlobalFunctions.getTitleColorForBG(this.well.color);
          },
          processActive(){
            var active = false;
            if(this.well.process.status && this.well.process.status.state == 'inProgress' && !this.handshakeWellComp){
              active = true;
            }
            return active;
          },
          currentStep(){
            if(this.processActive){
              return this.well.process.steps[this.well.process.status.stepNumber];
            }else
              return null;
          },
          lastStep(){
            if(this.processActive && this.currentStep){
              return (this.currentStep.step_number+1 == this.well.process.steps.length);
            }else
              return null;
          }
        },
        mounted() {
            setInterval(this.checkUpdateTimes,500);
        },
        created(){
          var t='';
          var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
          for(var i=0;i<20;i++){
            t += characters.charAt(Math.floor(Math.random() * characters.length));
          }
          this.imgId = t;
        },
        watch: { 
          'well.spoolColor': function(newVal) {
            this.spoolCol = newVal;
          }
        },
        methods: {
            action: function(){
              this.touchEventPreventDefault(event);
              if (!this.handshakeWellComp) {
                currentName = this.well.name;
                currentIndex = this.index;
                var wellSettings = {};
                wellSettings.name = this.well.name;
                wellSettings.index = this.index;
                wellSettings.activity = this.well.activity;
                this.$emit('wellIndexChange',wellSettings.index, true);
              }
                
            },
            wellSelectedEvent() {
              this.touchEventPreventDefault(event);
              if (this.handshakeWellComp) {
                this.$emit('wellIndexChange')
              }
            },
            checkUpdateTimes(){
              if(new Date()-this.well.pressures.casing.lastUpdate < 2000) {
                if (this.well.pressures.casing.value > this.fracThreshold) {
                  this.classCasingPressure = 'bg-warning border border-warning';
                  this.casingPressureValue = this.well.pressures.casing.value;
                } else {
                  this.classCasingPressure = '';
                  this.casingPressureValue = this.well.pressures.casing.value;
                  }
              }
              else {
                this.classCasingPressure = 'bg-secondary border border-danger';
                this.casingPressureValue = 'N/A'
              }
            },
            pressureAction(selection){
              this.touchEventPreventDefault(event);
              if(this.role == 'admin' || this.role == 'iwsTech' || this.role == 'fieldTech'){
                this.selectedPressure = selection;
                this.$refs["pressureModal"+this.index].show();
              }
              
            },
            setPressureEnable(value){
              this.touchEventPreventDefault(event);
              var pressureNodeIndex = this.well.pressures.casing.nodeIndex;
              var wellNum = this.index + 1;
              var payload = 'wellhead_'+wellNum+'_pressure_wellbore,'+value+','+this.username;
              var topic = 'pressure/'+pressureNodeIndex+'/set/2/enable';
              this.$root.$mqtt.publish(topic,payload,{qos:1,retain:false});
              this.$refs["pressureModal"+this.index].hide();
            },
            zeroPressure(){
              this.touchEventPreventDefault(event);
              var pressureNodeIndex = this.well.pressures.casing.nodeIndex;
              var wellNum = this.index + 1;
              var payload = 'wellhead_'+wellNum+'_pressure_wellbore,'+this.username;
              var topic = 'pressure/'+pressureNodeIndex+'/cmd/zero';
              this.$root.$mqtt.publish(topic,payload,{qos:1,retain:false});
              this.$refs["pressureModal"+this.index].hide();
            },
            hidePressureModal() {
              this.touchEventPreventDefault(event);
              this.$refs["pressureModal"+this.index].hide();
            },
            touchEventPreventDefault(event) {
              if (event.type == 'touchend') {
                event.preventDefault();
              }
            },
            wellStyle() {
              var style = {
                'position': 'relative',
                'max-width': '350px',
              }
              if (this.handshakeWellComp && this.index != this.selectedWellIndex) {
                style['opacity'] = '0.65';
              }
              if (this.valveSelectWell) {
                style['opacity'] = '0.65';
              }
              if (this.handshakeWellComp && this.index == this.selectedWellIndex) {
                style['box-shadow'] = '0 0 10px 4px green';
              }
              return style;
            }
        }
    }

</script>
