var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "d-flex flex-row align-items-center" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-grow-1 dark-text align-items-center px-2 border border-dark",
        staticStyle: { "background-color": "white", height: "34px" },
      },
      [
        _c("div", [
          _vm._v(
            "\n\t\t\t" +
              _vm._s(_vm.shot.type === "shot" ? "Shot fired" : "Plug set") +
              " at " +
              _vm._s(_vm.shot.depth) +
              "ft KB, time: " +
              _vm._s(_vm.convertDateTimeWithOffset(_vm.shot.created_at)) +
              "\n\t\t"
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "px-2 align-items-center" },
      [
        _c("iws-switch", {
          attrs: { onPress: _vm.onToggle, value: _vm.shotStatus },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "primary-txt align-items-center status-container" },
      [
        _c("span", [
          _vm._v(
            _vm._s(_vm.shotStatus ? _vm.trueStatusText : _vm.falseStatusText)
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }