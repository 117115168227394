<!--
    * Component Description
    Dropdown component that follows the design requirements

    * Dependencies
    - vue-clickaway package

    * Side Effects
    A "valueSelected" event is emitted with the value from the component

    * Example Usage
    (From CalendarComponent.vue)
    <dropdown :padAmount="2"
        :defaultValue="DEFAULT_HOURS"
        :value="hours"
        :values="Array.from({length: 12}, (_, i) => i + 1)"
        @valueSelected="value => hours = value"
        class="mr-2"
-->

<template>
<div :class="`relative ${dropdownActive? 'light-blue-border' : 'grey-border'} input-box rounded`">
    <div class="w-100 h-100 d-flex justify-content-between align-items-center px-2"
        @click="toggleDropown">
        <div class="text-muted" v-if="value == null">{{ this.formatValue(defaultValue) }}</div>
        <div class="text-white" v-else ref="formattedValue">{{ this.formatValue(value) }}</div>
        
        <div :class="`${dropdownActive? 'upside-down' : ''} d-flex align-items-center justify-content-center`">
            <img src="/images/icons/chevron-down-small.svg"/>
        </div>
    </div>
    <div v-if="dropdownActive" 
        v-on-clickaway="deactivateDropdown"
        class="position-absolute overflow-auto grey-border rounded clickable dd">
        <template v-for="value in values">
            <div @click="valueSelected(value)" class="w-100 d-flex justify-content-center align-items-center dd-item white">
                <div class="py-1">{{ value }}</div>
            </div>
        </template>
    </div>
</div>            
</template>

<style scoped>

.input-box {
    min-width: 3.4rem; 
    height: 2.4rem;
}

.grey-border {
    border: 1px solid #676E78;
}
.light-blue-border {
    border: 1px solid #29A5FF;
}

.text-muted {
    color: #667085;
}

.dd {
    margin-top:5px;
    z-index:100;
    height:12rem;
    background-color:#343A40;
    min-width:3.4rem;
}

.dd-item:hover {
    background-color: #004DBF;
}

.text-white {
    color: #FFFFFF;
}

.upside-down {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1);
}

</style>

<script>
import { directive as onClickaway } from 'vue-clickaway';

export default {
    directives: {
        onClickaway: onClickaway
    },
    props: {
        values: [],         //list of values to pick from
        defaultValue: null, //value to show when no value displayed
        value: null,        //current value of the component
        padAmount: {        //amount of spaces to pad a number to (null = no pad)
            type: Number,
            default: null
        } 
    },
    data() {
        return {
            dropdownActive: false
        };
    },
    methods: {
        formatValue: function(value) {
            if (this.padAmount)
                return this.pad(value);

            return this.value;
        },
        //pads number to make sure value is at least as long
        //as the pad amount specified in props
        pad: function(number){
            if ((number != 0 && !number) || number < 0)
                return number;

            let numString = number.toString();

            while (numString.length < this.padAmount)
                numString = '0' + numString;

            return numString;
        },
        valueSelected: function(value)
        {
            this.$emit('valueSelected', value);
            this.dropdownActive = false;
        },
        toggleDropown()
        {
            this.dropdownActive = !this.dropdownActive;
        },
        deactivateDropdown()
        {
            this.dropdownActive = false;
        }
    }

};
</script>