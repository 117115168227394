var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "modalBackground" } }, [
    _c("div", { attrs: { id: "modalContainer" } }, [
      _c("div", { staticClass: "modal-dialog text-secondary" }, [
        _c("div", { staticClass: "modal-content bg-dark" }, [
          _vm._m(0),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body bg-dark" }, [
            _vm._v(
              "\n                    Please log in to continue\n                "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer bg-dark" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-primary btn-block",
                attrs: { type: "button", "data-dismiss": "modal" },
                on: { click: _vm.logout },
              },
              [_vm._v("Login")]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header bg-dark" }, [
      _c("h4", [_vm._v("Authentication Error")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }