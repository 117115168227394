var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "GenericForm" } }, [
    _c("div", { staticClass: "h3" }, [
      _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
    ]),
    _vm._v(" "),
    !!_vm.fields && !!_vm.fields.length
      ? _c(
          "div",
          { staticClass: "row formOuterBox" },
          [
            _vm._l(_vm.fields, function (field, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "form-group pt-3",
                  class: { "col-6": index < 4, "col-12": index >= 4 },
                },
                [
                  field.id === _vm.fieldIdForSingleSignOn ||
                  (_vm.permissions === "admin" &&
                    field.id === _vm.fieldIdForAPICheckbox)
                    ? _c("iws-checkbox", {
                        attrs: {
                          label: field.labelText,
                          value: field.value,
                          "enable-click-label": "",
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(field, "value", $event)
                          },
                        },
                      })
                    : _c("div", [
                        !field.isComponent
                          ? _c(
                              "div",
                              [
                                field.elementType === "password"
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "input-group",
                                        class: {
                                          "d-none": _vm.passwordDisabled,
                                        },
                                        attrs: { id: field.id },
                                      },
                                      [
                                        _c("iws-input", {
                                          attrs: {
                                            type: field.elementType,
                                            label: field.labelText,
                                            placeholder: field.placeholder,
                                            value: _vm.formValues[field.id],
                                            required: field.required,
                                            disabled: _vm.passwordDisabled,
                                          },
                                          on: {
                                            "update:value": function ($event) {
                                              return _vm.$set(
                                                _vm.formValues,
                                                field.id,
                                                $event
                                              )
                                            },
                                            input: function ($event) {
                                              return _vm.inputData($event)
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                field.elementType === "input"
                                  ? _c("iws-input", {
                                      attrs: {
                                        type: field.elementType,
                                        label: field.labelText,
                                        placeholder: field.placeholder,
                                        value: _vm.formValues[field.id],
                                        required: field.required,
                                        disabled: field.disabled,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.formValues,
                                            field.id,
                                            $event
                                          )
                                        },
                                        input: function ($event) {
                                          return _vm.inputData($event)
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                field.elementType === "select"
                                  ? _c("iws-select", {
                                      attrs: {
                                        options: field.options,
                                        value: _vm.formValues[field.id],
                                        label: field.labelText,
                                        id: field.id,
                                        "display-name": "label",
                                        "value-name": "value",
                                        disabled: field.propData?.disableField,
                                      },
                                      on: {
                                        "update:value": function ($event) {
                                          return _vm.$set(
                                            _vm.formValues,
                                            field.id,
                                            $event
                                          )
                                        },
                                        change: function ($event) {
                                          return _vm.dropDownChanged(
                                            _vm.formValues[field.id],
                                            field.id
                                          )
                                        },
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    directives: [
                                      {
                                        name: "show",
                                        rawName: "v-show",
                                        value: _vm.isFieldError(field.id),
                                        expression: "isFieldError(field.id)",
                                      },
                                    ],
                                    staticClass: "text-danger",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.errors[field.id]) +
                                        "\n          "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c("div", [
                              field.id == "dashboard_permissions"
                                ? _c(
                                    "div",
                                    [
                                      _c("iws-select", {
                                        attrs: {
                                          options: field.propData.options,
                                          value: field.value,
                                          label: "Permissions",
                                          placeholder: field.value?.length
                                            ? `${field.value?.length} selected`
                                            : "Select Permissions",
                                          id: field.id,
                                          "display-name":
                                            field.propData.selectByKey,
                                          "value-name": field.propData.label,
                                          disabled:
                                            field.propData.disabled ||
                                            field.propData.disableField,
                                          multiselect: true,
                                          "maintain-on-select": true,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.proccessMultiSelectSelection(
                                              field.value,
                                              $event,
                                              field
                                            )
                                          },
                                        },
                                      }),
                                      _vm._v(" "),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value: _vm.isFieldError(field.id),
                                              expression:
                                                "isFieldError(field.id)",
                                            },
                                          ],
                                          staticClass: "text-danger",
                                        },
                                        [
                                          _vm._v(
                                            "\n              " +
                                              _vm._s(_vm.errors[field.id]) +
                                              "\n            "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      field.propData.disabled ||
                                      field.propData?.disableField
                                        ? _c(
                                            "div",
                                            {
                                              staticClass:
                                                "secondary-text-color",
                                              staticStyle: {
                                                "font-size": "14px",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                '\n              Reach out to 24/7 support to adjust any user permissions via the "Support" option under your user options in the navbar\n            '
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                : field.id == "apiPermissions"
                                ? _c(
                                    "div",
                                    [
                                      _c("iws-select", {
                                        attrs: {
                                          options: field.propData.options,
                                          value: field.value,
                                          label:
                                            "Further Company API Permissions",
                                          placeholder: field.value?.length
                                            ? `${field.value?.length} selected`
                                            : "Select Further Company API Permissions",
                                          id: field.id,
                                          "display-name": "name",
                                          "value-name":
                                            field.propData.selectByKey,
                                          disabled:
                                            field.propData.disabled ||
                                            field.propData.disableField,
                                          multiselect: true,
                                          "maintain-on-select": true,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.proccessMultiSelectSelection(
                                              field.value,
                                              $event,
                                              field
                                            )
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ]),
                      ]),
                ],
                1
              )
            }),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "form-group float-right col-12",
                staticStyle: { "text-align": "end" },
              },
              [
                _vm.formSubmitted && _vm.title === "Add User"
                  ? _c(
                      "span",
                      {
                        staticClass: "savingIndicator",
                        staticStyle: { color: "#00ff00" },
                      },
                      [_vm._v("Saved successfully. Redirecting...")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c("iws-button", {
                  attrs: { text: "Submit", click: _vm.submitForm },
                }),
              ],
              1
            ),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }