var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "accordion user-select-none", on: { click: _vm.toggle } },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.id,
              expression: "id",
            },
          ],
          ref: `accordion-${_vm.id}`,
          staticClass: "accordion-btn",
          class: { open: _vm.isOpen },
          attrs: {
            role: "tab",
            "aria-expanded": _vm.isOpen ? "true" : "false",
          },
        },
        [
          _c(
            "div",
            {
              staticClass:
                "d-flex justify-content-between align-items-center w-100",
            },
            [
              _vm._t("header", function () {
                return [
                  _c(
                    "span",
                    [
                      _vm._t("title", function () {
                        return [
                          _vm._v(
                            "\n                        " +
                              _vm._s(_vm.title) +
                              "\n                    "
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _vm._t("icon", function () {
                        return [
                          _c("img", { attrs: { src: _vm.icon, alt: "" } }),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              }),
            ],
            2
          ),
        ]
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        {
          staticClass: "accordion-dropdown",
          attrs: { id: _vm.id, role: "tabpanel" },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [_c("div", { staticClass: "accordion-body" }, [_vm._t("body")], 2)]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }