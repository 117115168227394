var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "primary-txt justify-content-center",
      staticStyle: { height: "100%" },
    },
    [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "inner-bg div-border p-2 font-small",
          staticStyle: { height: "95%" },
        },
        [
          _c("div", { staticClass: "d-flex flex-row py-2" }, [
            _c(
              "div",
              {
                staticClass: "d-flex justify-content-center align-items-center",
              },
              [
                _c("span", [_vm._v("Number of Guns")]),
                _c("span", { staticClass: "px-1" }, [
                  _vm._v(_vm._s(_vm.currentGunCount)),
                ]),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "flex-grow-1" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "px-3" },
              [
                _c("add-remove-buttons", {
                  attrs: {
                    minValue: 1,
                    currentValue: _vm.currentGunCount,
                    onChange: (value) => _vm.onGunNumberChange(value, true),
                  },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticStyle: {} },
              _vm._l(_vm.toolDetailSet, function (toolDetail, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "input-group flex-nowrap my-2" },
                  [
                    _c("div", { staticClass: "d-flex align-items-center" }, [
                      _c("div", [_vm._v(_vm._s(toolDetail.name))]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "flex-grow-1" }),
                    _vm._v(" "),
                    _c("input", {
                      staticStyle: { width: "70px", "text-align": "right" },
                      attrs: { type: "number" },
                      domProps: { value: toolDetail.value },
                      on: {
                        change: (event) => _vm.onValueChanged(index, event),
                      },
                    }),
                    _vm._v(" "),
                    _vm._m(1, true),
                  ]
                )
              }),
              0
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pb-2 d-flex justify-content-center" }, [
      _c("b", [_vm._v("Tool-String Measurements")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "input-group-append" }, [
      _c(
        "span",
        { staticClass: "input-group-text", attrs: { id: "basic-addon2" } },
        [_vm._v("ft")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }