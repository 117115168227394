<!--
    * Example Usage

    <checkbox-list
        label="Ghost Channel(s) Select"
        height="100"
        :options="tags"
        v-bind:selectedOptions="ghostLineChannels"
        v-on:update:selectedOptions="ghostLineChannels = $event">
    </checkbox-list>
-->

<template>
<div :style="getContainerStyle()" class="checkbox-list-container">
    <div :style="getListStyle()">
        <div class="d-flex align-items-center">
            <b v-if="label.length > 0">{{label}}</b>
            <div class="pl-2"></div>
        </div>
        <input
            v-if="enableSearch"
            class="form-control form-control-sm my-2"
            type="text"
            placeholder="Search..."
            v-model="searchTerm"
        />

        <div  v-if="valueKey" class="main-container">
            <div v-for="(packets, key, index) in filteredOptions" :key="key" class="accordion">
                <div class="d-flex align-items-center user-select-none p-1 accordion-btn">
                    <input v-if="enableSelectAllOption && Object.keys(filteredOptions).length > 0" class="mr-2"
                    v-tooltip:top="'Select all'" type="checkbox" v-model="selectAll[key]" @click="selectAllSubOptions(key, true)">
                    <div v-b-toggle="'accordion-'+index" role="tab" class="d-flex justify-content-between align-items-center w-100">
                        <p class="m-0 text-light">{{ key }}</p>
                        <span class="h-100 d-flex align-items-center ml-2">
                            <img src="/images/dropdown.png">
                        </span>
                    </div>
                </div>
                <b-collapse :id="'accordion-'+index" visible role="tabpanel" class="px-1 accordion-dropdown">
                    <div class="container">
                        <div v-if="enableSlider" class="row justify-content-center">
                            <vue-slider
                                v-if="minMaxValues"
                                :key="index"
                                :duration="0"
                                :order="true"
                                :dotSize="15"
                                :clickable="false"
                                class="col-10 my-2"
                                :dragOnClick="false"
                                :ref="key+'-slider'"
                                :min="minMaxValues[key]['min']"
                                :max="minMaxValues[key]['max']"
                                @drag-end="onSliderDragEnd(key)"
                                v-model="minMaxValues[key]['value']"
                            >
                            </vue-slider>
                        </div>
                        <div class="row">
                            <div v-for="(option, subIndex) in packets" :key="subIndex" class="col-xl-4 col-lg-6 col-sm-12">
                                <label class="m-0 p-0">
                                    <input type="checkbox"
                                        @focus="toggleFocus()"
                                        @blur="toggleFocus()"
                                        :id="option[outputKey]"
                                        v-model="optionStates[option[outputKey]]"
                                        @change="toggleState($event, option[outputKey])">
                                    {{ option[valueKey] }}
                                </label>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
        <div v-else class="main-container">
            <div v-for="(packets, key, index) in options" :key="key" class="accordion">
                <div class="d-flex align-items-center user-select-none p-1 accordion-btn">
                    <input v-if="enableSelectAllOption && Object.keys(options).length > 0" class="mr-2"
                    v-tooltip:top="'Select all'" type="checkbox" v-model="selectAll[key]" @click="selectAllSubOptions(key)">
                    <div v-b-toggle="'accordion-'+index" role="tab" class="d-flex justify-content-between align-items-center w-100">
                        <p class="m-0 text-light">{{ key }}</p>
                        <span class="h-100 d-flex align-items-center ml-2">
                            <img src="/images/dropdown.png">
                        </span>
                    </div>
                </div>
                <b-collapse :id="'accordion-'+index" visible role="tabpanel" class="px-1 accordion-dropdown">
                    <div class="container">
                        <div v-if="enableSlider" class="row justify-content-center">
                            <vue-slider
                                v-if="minMaxValues"
                                :key="index"
                                :duration="0"
                                :order="true"
                                :dotSize="15"
                                :clickable="false"
                                class="col-10 my-2"
                                :dragOnClick="false"
                                :ref="key+'-slider'"
                                :min="minMaxValues[key]['min']"
                                :max="minMaxValues[key]['max']"
                                @drag-end="onSliderDragEnd(key)"
                                v-model="minMaxValues[key]['value']"
                            >
                            </vue-slider>
                        </div>
                        <div class="row">
                            <div v-for="(option, subIndex) in packets" :key="subIndex" class="col-xl-4 col-lg-6 col-sm-12">
                                <label class="m-0 p-0">
                                    <input v-if="optionStates" type="checkbox"
                                        @focus="toggleFocus()"
                                        @blur="toggleFocus()"
                                        :id="option"
                                        v-model="optionStates[key][option]"
                                        @change="toggleState($event, key)">
                                    {{ alternateOptionLabels ? alternateOptionLabels[subIndex] : option }}
                                </label>
                            </div>
                        </div>
                    </div>
                </b-collapse>
            </div>
        </div>
    </div>
</div>
</template>

<style scoped>
.main-container {
    width: fit-content;
}
.accordion {
    margin-bottom: 1px;
}

.accordion-btn {
    height: 38px; 
    cursor: pointer;
    background-color: #ffffff; 
    border: 1px solid #F0F0F0;
}

.accordion-dropdown {
    background-color: #F0F0F0;
}
</style>

<script>
import _ from 'lodash';
import VueSlider from 'vue-slider-component';

export default {
    components: {
        VueSlider
    },
    props: {
        valueKey: {
            default: null,
            type: [String],
            required: false
        },
        outputKey: {
            default: null,
            type: [String],
            required: false
        },
        label: {
            type: [String],
            default: '',
            required: false
        },
        height: {
            default: 200,
            required: false
        },
        options: {
            type: [Array, Object],
            required: true
        },
        alternateOptionLabels: {
            type: [Array, Object],
            required: false
        },
        selectedOptions: {
            type: [Array, Object],
            required: true
        },
        isDark: {
            default: false,
            required: false
        },
        enableSelectAllOption: {
            default: false,
            required: false
        },
        enableSearch: {
            default: false,
            required: false
        },
        enableSlider: {
            default: false,
            required: false
        },
        //props to provide unqiue functionality for specific checkboxlist instances
        customToggleOnEvent: {
            default: null,
            type: [Function],
            required: false
        },
        customToggleOffEvent: {
            default: null,
            type: [Function],
            required: false
        },
        customData: {
            default: null,
            type: [Object],
            requried: false
        },
        disabledOptions: {
            default: ()=>[],
            type: [Array],
            requried: false
        }
    },
    data() {
        return {
            selectAll: {},
            searchTerm: '',
            optionStates: null,
            minMaxValues: null
        };
    },
    computed: {
        filteredOptions() {
            if(this.searchTerm) {
                return this.options.filter((option)=>{
                    if(this.valueKey) {
                        return option[this.valueKey].toLowerCase().includes(this.searchTerm.toLowerCase());
                    } else {
                        return option.toLowerCase().includes(this.searchTerm.toLowerCase());
                    }
                });
            } else {
                return this.options;
            } 
        }
    },
    mounted() {
        this.createKeyStates();
        this.determineSelectAllState();
    },
    watch: {
        selectedOptions: function(val) {
            for(const [key, values] of Object.entries(val)) {
                for(const [subKey, target] of Object.entries(values)) {
                    this.optionStates[key][subKey] = target;
                }
            }
            this.determineSelectAllState();
        }
    },
    methods: {
        selectAllSubOptions(key, isFiltered = false) {
            this.selectAll[key] = !this.selectAll[key];
            const options = (isFiltered ? this.filteredOptions : this.optionStates)[key];
            Object.keys(options).forEach(value => options[value] = this.selectAll[key]);
            if (this.enableSlider) {
                const max = this.selectAll[key] ? this.minMaxValues[key].max : this.minMaxValues[key].min;
                this.$refs[`${key}-slider`][0].setValue([this.minMaxValues[key].min, max]);
            }
            this.$emit('update:selectedOptions', this.optionStates);
        },
        onSliderDragEnd(key) {
            const keyMin = this.minMaxValues[key]['min'];
            const keyMax = this.minMaxValues[key]['max'];
            const min = this.minMaxValues[key]['value'][0];
            const max = this.minMaxValues[key]['value'][1];
            for (const target of _.range(min, max+1)) {
                this.optionStates[key][target] = true;
            }
            for (const target of _.range(keyMin, min)) {
                this.optionStates[key][target] = false;
            }
            for (const target of _.range(max+1, keyMax+1)) {
                this.optionStates[key][target] = false;
            }
            this.selectAll[key] = keyMin == min && max == keyMax ? true : false;
            this.$emit('update:selectedOptions', this.optionStates);
        },
        createKeyStates: function() {
            //Create a section for each key
            const tempMinMax = {};
            const tempOptionStates = {};
            for (const [key, values] of Object.entries(this.options)) {
                //Create section
                if (!(key in tempOptionStates)) {
                    tempOptionStates[key] = {};
                    if (this.enableSlider) {
                        let selectedValues = [...values];
                        if (this.selectedOptions[key]) {
                            const options = this.selectedOptions[key];
                            selectedValues = Object.keys(options).filter(value => options[value]).map(op => +op);
                        }
                        const min = Math.min(...values);
                        const max = Math.max(...values);
                        let minValue = min, maxValue = min;
                        if (selectedValues.length > 0) {
                            minValue = selectedValues.length === values.length ? min : Math.min(...selectedValues);
                            maxValue = selectedValues.length === values.length ? max : Math.max(...selectedValues);
                        }
                        tempMinMax[key] = {
                            min,
                            max,
                            value: [minValue, maxValue]
                        };
                    }
                }

                //Create it options
                for (const target of values) {
                    tempOptionStates[key][target] = this.selectedOptions[key] ? this.selectedOptions[key][target] : false;
                }
            }

            this.minMaxValues = tempMinMax;
            this.optionStates = tempOptionStates;
        },
        getListStyle: function() {
            return {
                padding: '5px',
                height: this.height + 'px',
                overflow: 'scroll',
                'overflow-x': 'auto',
                color: this.isDark ? '#FFFFFF' : '#111111'
            };
        },
        getContainerStyle: function() {
            return {
                borderRadius: '5px',
                padding: '2px',
                border: this.isFocused ? '2px solid #8888ff' : '2px solid #888888',
                backgroundColor: this.isDark ? '#111111' : '#FFFFFF'
            };
        },
        getChecked: function() {
            // optionStates is stored as an object containing option:boolean pairs.
            // We will return an array of strings corresponding to the currently active options. 
            const checked = [];
            for(const [key, value] of Object.entries(this.optionStates)) {
                if(value) {
                    checked.push(key);
                }
            }
            return checked;
        },
        determineSelectAllState() {
            Object.keys(this.selectedOptions).forEach(key => {
                this.selectAll[key] = Object.values(this.selectedOptions[key]).every(opt => {
                    return opt;
                });
            });
        },
        toggleState: function(event, key) {
            if (event.currentTarget.checked && this?.customToggleOnEvent) {
                this?.customToggleOnEvent(event, this.customData);             
            } else if (!event.currentTarget.checked && this?.customToggleOffEvent) {
                this?.customToggleOffEvent(event, this.customData);
            }

            if (this.enableSlider) {
                const selected = Object.keys(this.optionStates[key]).filter(k => this.optionStates[key][k]).map(op => +op);
                const min = Math.min(...selected);
                const max = Math.max(...selected);
                this.minMaxValues[key].value = [min, max];
            }

            this.determineSelectAllState();
            this.$emit('update:selectedOptions', this.optionStates);
        },
        toggleFocus: function() {
            this.isFocused = !this.isFocused;
        },
        isOptionDisabled: function(option) {
            return this.disabledOptions.includes(option)? true:false;
        } 
    }
};


</script>
