var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "row justify-content-between" }, [
      _c("div", { staticClass: "h3 pr-0 mb-0" }, [
        _vm._v("Project Management"),
      ]),
      _vm._v(" "),
      _c("div", [
        _c(
          "button",
          {
            staticClass: "btn btn-primary",
            on: {
              click: function ($event) {
                return _vm.create()
              },
            },
          },
          [_vm._v("Create New Project")]
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "d-flex row w-100 pl-1 pt-3" }, [
      _c(
        "ul",
        { staticClass: "navbar-nav d-flex flex-row flex-list" },
        _vm._l(_vm.filterTabs, function (tabName, index) {
          return _c(
            "li",
            {
              key: index,
              staticClass: "nav-item text-nowrap",
              class: {
                dashboard_navbar_selected:
                  _vm.darkMode && _vm.selectedTabName == tabName,
                dashboard_navbar_item:
                  _vm.darkMode && _vm.selectedTabName !== tabName,
                dashboard_navbar_selected_light:
                  !_vm.darkMode && _vm.selectedTabName == tabName,
                dashboard_navbar_item_light:
                  !_vm.darkMode && _vm.selectedTabName !== tabName,
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "nav-link",
                  class: {
                    "text-white": _vm.darkMode,
                    "text-dark": !_vm.darkMode,
                  },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      return _vm.setFilter(tabName)
                    },
                  },
                },
                [
                  _vm._v(
                    " \n                    " +
                      _vm._s(tabName) +
                      "\n                "
                  ),
                ]
              ),
            ]
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    !_vm.loading
      ? _c("div", { staticClass: "pt-2" }, [
          _vm.displayList[_vm.selectedTabName].length > 0
            ? _c(
                "table",
                {
                  staticClass: "table table-striped table-hover mt-2",
                  class: { "table-dark": _vm.darkMode },
                },
                [
                  _c("thead", [
                    _c(
                      "th",
                      {
                        staticStyle: { cursor: "pointer" },
                        attrs: { scope: "col" },
                        on: {
                          click: function ($event) {
                            return _vm.order("name")
                          },
                        },
                      },
                      [
                        _c("span", { staticClass: "fas fa-sort" }),
                        _vm._v("Name"),
                      ]
                    ),
                    _vm._v(" "),
                    _vm.iwsUser
                      ? _c(
                          "th",
                          {
                            staticStyle: { cursor: "pointer" },
                            attrs: { scope: "col" },
                            on: {
                              click: function ($event) {
                                return _vm.order("customer")
                              },
                            },
                          },
                          [
                            _c("span", { staticClass: "fas fa-sort" }),
                            _vm._v("Customer"),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm._m(0),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
                    _vm._v(" "),
                    _c("th", { attrs: { scope: "col" } }, [_vm._v(" ")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.displayList[_vm.selectedTabName],
                      function (activeListItem, index) {
                        return _c("tr", { key: index }, [
                          _c(
                            "td",
                            { staticClass: "d-flex align-items-center" },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(activeListItem.name) +
                                  "\n                        "
                              ),
                              activeListItem.isDefault
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "ml-2 badge badge-secondary",
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Default\n                        "
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.iwsUser
                            ? _c("td", [
                                _vm._v(_vm._s(activeListItem.customer.name)),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              staticStyle: { cursor: "pointer" },
                              on: {
                                click: function ($event) {
                                  return _vm.onPinnedClick(activeListItem)
                                },
                              },
                            },
                            [
                              activeListItem.isPinned
                                ? _c("i", { staticClass: "fas fa-star" })
                                : _c("i", { staticClass: "far fa-star" }),
                            ]
                          ),
                          _vm._v(" "),
                          _c("td"),
                          _vm._v(" "),
                          _c("td", [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex justify-content-end align-items-center h-100 w-100",
                              },
                              [
                                !activeListItem.isDefault
                                  ? _c(
                                      "span",
                                      {
                                        staticClass: "badge badge-secondary",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.makeDefault(
                                              activeListItem.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                Make Default\n                            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge badge-primary ml-1",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.view(activeListItem.id)
                                      },
                                    },
                                  },
                                  [_vm._v("View")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass: "badge badge-danger ml-1",
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.remove(activeListItem.id)
                                      },
                                    },
                                  },
                                  [_vm._v("Delete")]
                                ),
                              ]
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]
              )
            : _c("div", [_vm._v("\n            No results\n        ")]),
          _vm._v(" "),
          _vm._m(1),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex row justify-content-center" },
            [
              _c("b-pagination", {
                attrs: {
                  "total-rows": _vm.availableDashboardList.total,
                  "per-page": _vm.availableDashboardList.per_page,
                },
                on: { change: _vm.onPageClicked },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
            ],
            1
          ),
        ])
      : _c(
          "div",
          { staticClass: "d-flex align-items-center justify-content-center" },
          [
            _c("b-spinner", {
              attrs: { variant: "primary", label: "Spinning" },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", { attrs: { scope: "col" } }, [
      _c("span", { staticClass: "fas" }),
      _vm._v("Pinned"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "pt-4" }, [
      _c("small", [
        _vm._v("*Projects will be archived after 30 days of inactivity"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }