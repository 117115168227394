var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      style: { fill: _vm.color, stroke: _vm.color },
      attrs: {
        id: "Layer_1",
        "data-name": "Layer 1",
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 850 800",
      },
    },
    [
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M1318.5,376.5h-56.77c-58.47,0-87.74-70.68-46.4-112l43.07-43.08a12,12,0,0,0,0-17l-72.83-72.83a12,12,0,0,0-17,0l-33.08,33.07c-41.34,41.34-112,12.07-112-46.4V71.5a12,12,0,0,0-12-12h-103a12,12,0,0,0-12,12v46.77c0,58.47-70.68,87.74-112,46.4L751.4,131.6a12,12,0,0,0-17,0L661.6,204.43a12,12,0,0,0,0,17l43.08,43.08c41.33,41.34,12.06,112-46.41,112H601.5a12,12,0,0,0-12,12v103a12,12,0,0,0,12,12h36.77c58.47,0,87.74,70.68,46.4,112L661.6,638.6a12,12,0,0,0,0,17l72.83,72.83a12,12,0,0,0,17,0l33.08-33.07c41.34-41.34,112-12.07,112,46.4V788.5a12,12,0,0,0,12,12h103a12,12,0,0,0,12-12V741.73c0-58.47,70.68-87.74,112-46.4l33.08,33.07a12,12,0,0,0,17,0l72.83-72.83a12,12,0,0,0,0-17l-23.08-23.08c-41.33-41.34-12.06-112,46.41-112h36.77a12,12,0,0,0,12-12v-103A12,12,0,0,0,1318.5,376.5Z",
          transform: "translate(-536.02 -44.5)",
        },
      }),
      _vm._v(" "),
      _c("circle", {
        staticClass: "cls-3",
        attrs: { cx: "423.98", cy: "385.5", r: "200" },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: { d: "M1016,528", transform: "translate(-536.02 -44.5)" },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: { d: "M1016,616", transform: "translate(-536.02 -44.5)" },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M889.5,542l.5,73c0,7.84,6.86,15.36,15,15s15.06-6.59,15-15l-.5-73c0-7.84-6.86-15.36-15-15s-15.06,6.59-15,15Z",
          transform: "translate(-536.02 -44.5)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: { d: "M960,555", transform: "translate(-536.02 -44.5)" },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: { d: "M905,501", transform: "translate(-536.02 -44.5)" },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M835,430a125,125,0,0,0,70,112.27",
          transform: "translate(-536.02 -44.5)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M1016,541.78A125,125,0,0,0,1085,430",
          transform: "translate(-536.02 -44.5)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M904.16,318V443.83c0,7.82,7.78,14.17,17.38,14.17h76.25c9.59,0,17.37-6.35,17.37-14.17V318",
          transform: "translate(-536.02 -44.5)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M835,430c0-63,37.57-114,84-114",
          transform: "translate(-536.02 -44.5)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        staticClass: "cls-3",
        attrs: {
          d: "M1085,430c0-61.91-38-112-85-112",
          transform: "translate(-536.02 -44.5)",
        },
      }),
      _vm._v(" "),
      _c("path", {
        attrs: {
          d: "M1000.5,542l.5,73c0,7.84,6.86,15.36,15,15s15.06-6.59,15-15l-.5-73c0-7.84-6.86-15.36-15-15s-15.06,6.59-15,15Z",
          transform: "translate(-536.02 -44.5)",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }