<template>
    <div>
        <b-popover target="export-popover" triggers="hover" placement="left" :delay="{show: 0, hide: 500}">
            <div class="btn-group-vertical">
                <button type="button" class="btn btn-secondary grey-button mb-2" @click="onExportData('csv')">
                    Export as CSV
                </button>
                <button type="button" class="btn btn-secondary grey-button" @click="onExportData('json')">
                    Export as JSON
                </button>
            </div>
        </b-popover>
        <div v-show="disabledZoomMessage" 
            :style="{zIndex: 10, position: 'absolute', bottom:'10px', right: '10px', backgroundColor: '#3490dc', fontSize: '0.8vw', padding: '10px'}"
            >Click the chart to enable scroll zoom</div>
        <div class="d-flex position-relative" @mouseleave="hoveringChart=false" @mouseover="hoveringChart=true" @wheel="zoomDisabledMessage" tabindex="-1" @focus.capture="enableZoomOnFocus()" @blur="disableZoomOnBlur()">
            <!-- overlay that covers y axis labels to prevent tooltips 
                showing while hovering over them (causes bugs with tooltip display from the left) -->
            <div class="position-absolute" :style="'height:'+ labelCoveringOverlayHeight + 'px; width:'+ labelCoveringOverlayWidth+'px;'"></div>

            <bar-chart  :chart-data="summaryData" :style="{height: height + 'px', width: '97%'}" :options="summaryBarOptions" id="bar-chart" ref="barChart"></bar-chart>
            <span style="height: 20px" v-tooltip:top="'Export'" id="export-popover">
                <i class="fas fa-download"></i>
            </span>
        </div>
        <div v-if="isLoading">
            <div class="spinner-border spinner-border-sm" role="status" style="position:absolute; top:30px; right:0; left:0; margin: auto;">
                <span class="sr-only mb-5">Loading...</span>
            </div>
        </div>
    </div>
</template>
<style>
    #chartjs-tooltip-summary-bar {
        opacity: 1;
        text-align: left;
        position: absolute;
        background: rgba(0, 0, 0, .7);
        color: white;
        border-radius: 3px;
        -webkit-transition: all .1s ease;
        transition: all .1s ease;
        pointer-events: none;
    }
</style>

<script>
import BarChart from '../barChart2.js';
import GlobalFunctions from '../GlobalFunctions.js';
import moment from 'moment';
import _ from 'lodash';
Chart.Tooltip.positioners.cursor = function(chartElements, coordinates) {
    return coordinates;
};

const SUMMARY_BAR_TIME_FRAME = 12;
const GENERAL_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
const MILLIS_PER_HR = 3600000;
const PAN_TO_LIVE_DATA_THRESHOLD = 2000; //milliseconds
export default {
    mounted() {
        if(this.$refs['barChart']) {
            this.$refs['barChart'].$data._chart.resize();
            this.labelCoveringOverlayWidth = this.$refs['barChart'].$data._chart?.scales['service-axis']?.right ?? '0';
            this.labelCoveringOverlayHeight = this.$refs['barChart'].$data._chart?.scales['service-axis']?.bottom ?? '0';
        }
        if(this.activityData?.length !== 0 ) {
            this.barChartData(this.activityData);
        }
    },
    watch: { 
        activityData: function(newVal, oldVal) { // new data
            this.isLoading = false;
            this.handleNewActivities(newVal);
        },
        latestActivityData: function(newVal, oldVal) { //polling
            this.handleNewActivities(newVal);
        },
        bounds: function(newVal, oldVal) { 
            const { lower, upper } = newVal;
            this.changeMinMaxValues(lower, upper);
        },
        height: {
            immediate: true,
            handler(newVal, oldVal) {
                if(this.$refs['barChart']) {
                    this.$refs['barChart'].$data._chart.resize();
                }
            }
        }
    },
    components: {
        BarChart
    },
    methods: {
        onExportData(type) {
            const self = this;
            const url = `/export-summary/${this.jobNumber}`;
            $.get(
                url,
                function (activities) {
                    if(activities.error) {
                        alert("Export failed: " + activities.message.toLowerCase());
                    } else {
                        const headers = ['Start Time', 'Start Time (ISO)', 'Start Time (UNIX)', 'End Time', 'End Time (ISO)', 'End Time (UNIX)', 'Duration (Min)', 'Duration (Sec)', 'Activity', 'Status', 'Stage Number', 'Well Name', 'Well Number', 'Well UWI', 'From Well', 'To Well'];
                        const csvData = [
                            headers.join(', ')
                        ];
                        if(type === 'csv') {
                            activities.forEach(activity => {
                                csvData.push(Object.values(activity));
                            });
                        }
                        const fileName = `summary_data_export_${self.jobNumber}_${moment().utc().valueOf()}.${type}`;
                        const downloadUrl = type === 'csv' ? window.URL.createObjectURL(new Blob([csvData.join('\n')])) : 
                            `data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(activities))}`;
                        const link = document.createElement('a');
                        link.href = downloadUrl;
                        link.setAttribute('download', fileName);
                        document.body.appendChild(link);
                        link.click();
                        link.remove();
                    }
                },
            );
        },
        mapDataWithCrossOvers(activitiesByLane, crossOverLaneKeys) {
            const updatedActivitiesByLane = { ...activitiesByLane};  
            crossOverLaneKeys.forEach(laneKey => {
                const laneValues = [];
                if(activitiesByLane[laneKey].length > 0) {
                    const inProgressOverlaps = activitiesByLane[laneKey].filter((activity)=> activity.endTime === null);                 
                    const completedOverlaps = activitiesByLane[laneKey].filter((activity)=> activity.endTime !== null); 
                    
                    if(inProgressOverlaps.length < 2) {
                        completedOverlaps.push(...inProgressOverlaps);
                    }
                    
                    completedOverlaps.forEach((currentValue, index) => {
                        let overlap = null;
                        let valueToFix = {...currentValue};
                        const prevValue = activitiesByLane[laneKey][index-1]? activitiesByLane[laneKey][index-1] : null;
                        const nextValue = activitiesByLane[laneKey][index+1]? activitiesByLane[laneKey][index+1] : null;

                        const previousEndTime = prevValue? moment.utc(prevValue.endTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : null;
                        const currentStartTime = moment.utc(currentValue.startTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf();
                        const currentEndTime = moment.utc(currentValue.endTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf();
                        const nextStartTime = nextValue? moment.utc(nextValue.startTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : null;

                        if(nextStartTime !== null && (currentValue.endTime == null || currentEndTime > nextStartTime)) { //fixing end overlap
                            overlap = { 
                                ...currentValue, 
                                startTime: nextValue.startTime, 
                                endTime: currentValue.endTime, 
                                type: 'overlap',
                                currentValue,
                                nextValue 
                            };
                            valueToFix = {
                                ...valueToFix,
                                endTime: nextValue.startTime
                            };
                        }

                        if(previousEndTime !== null && previousEndTime > currentStartTime) { //fixing front overlap
                            valueToFix = {
                                ...valueToFix,
                                startTime: prevValue.endTime
                            };
                        }

                        //don't add the current value if it is entirely overlapped
                        if(!(currentValue.endTime == null && (prevValue == null || prevValue.endTime == null))) {
                            laneValues.push(valueToFix);
                        }

                        if(overlap) {
                            laneValues.push(overlap);
                        }
                    });

                    //for a lane there will be 2 continues overlaps

                    if(inProgressOverlaps.length === 2) {
                        if(laneValues.length > 0) {
                            const lastLaneActivity = laneValues[laneValues.length - 1];
                            laneValues.push({
                                ...inProgressOverlaps[0],
                                startTime: lastLaneActivity.endTime,
                                endTime: inProgressOverlaps[1].startTime
                            });
                        } else {
                            laneValues.push({
                                ...inProgressOverlaps[0],
                                endTime: inProgressOverlaps[1].startTime
                            });
                        }



                        laneValues.push({ //overlap
                            ...inProgressOverlaps[0],
                            startTime: inProgressOverlaps[1].startTime,
                            endTime: null,
                            type: 'overlap',
                            currentValue: inProgressOverlaps[0],
                            nextValue: inProgressOverlaps[1]
                        });
                    }
                }
                updatedActivitiesByLane[laneKey] = laneValues;
            });
            return updatedActivitiesByLane;
        },
        setLoading( min, max ) {
            if(min < this.alreadyFetchedStart) {
                this.isLoading = true;
                this.alreadyFetchedStart = min;
            }

            if(max > this.alreadyFetchedEnd) {
                this.isLoading = true;
                this.alreadyFetchedEnd = max;
            }
        },
        handleNewActivities(newData) {        
            const oldData = this.rawActivities;
            const newActivities= newData.filter((activity)=>{
                return (oldData.find((oldActivities)=>oldActivities.id== activity.id)) ? false : true;
            });

            this.rawActivities = [...newActivities, ...oldData].sort((a, b) => {
                if ( moment.utc(a.startTime, GENERAL_TIME_FORMAT).valueOf() < moment.utc(b.startTime, GENERAL_TIME_FORMAT).valueOf()) {
                    return -1;
                }
                if ( moment.utc(a.startTime, GENERAL_TIME_FORMAT).valueOf() > moment.utc(b.startTime, GENERAL_TIME_FORMAT).valueOf() ) {
                    return 1;
                }
                return 0;
            });

            this.rawActivities = this.rawActivities.reduce((acc, current) => {
                const existing = acc.find(item => item.reference === current.reference);
                //if an inProgress activity was found with the same reference id
                //then it should be replaced with the complete activity
                if(existing) {
                    if(existing.subCategory == 'inProgress' && current.subCategory == 'completed') {
                        //remove existing
                        acc = acc.filter(function( obj ) {
                            return obj.reference !== existing.reference;
                        });
                    }
                    else if(current.subCategory == 'inProgress' && existing.subCategory == 'completed') {
                        //don't add current
                        return acc;
                    }
                }

                return acc.concat([current]);
            }, []);
            
            this.barChartData(this.rawActivities);

            if(!this.isJobCompleted) {
                this.changeMinMaxValues(this.bounds.lower, this.bounds.upper);
            }
        },
        changeMinMaxValues(lower = null, upper = null) {
            const xMin = moment.utc(lower).add({hours: this.jobHourOffset});
            const xMax = this.isJobCompleted? moment.utc(this.isJobEndTime).add({hours: this.jobHourOffset}) : moment.utc().add({hours: this.jobHourOffset});
            const chart = this.getBarChart();
            chart.options.plugins.zoom.pan.rangeMin.x = xMin.valueOf();
            chart.options.plugins.zoom.pan.rangeMax.x = xMax.valueOf();
            chart.options.plugins.zoom.zoom.rangeMin.x = xMin.valueOf();
            chart.options.plugins.zoom.zoom.rangeMax.x = xMax.valueOf();
        },
        calculateDuration(startUnix, endUnix) {
            const start = moment(startUnix);
            const end = moment(endUnix);
            const durationObject = moment.duration(start.diff(end)).abs();
            const inDays = Math.abs(durationObject.days());
            const inHours = durationObject.hours();
            const inMinutes = durationObject.minutes();
            const inSeconds = durationObject.seconds();
            return {
                inDays,
                inHours,
                inSeconds,
                inMinutes
            };
        },
        getLabels() {
            const yAxisLabels = [];

            if(this.wirelineSystems && this.wirelineSystems.length > 0) {
                const wirelineLabels = this.wirelineSystems.map((item)=>{
                    return `( Wireline ) ${item.name}`;
                });
                yAxisLabels.push(...wirelineLabels);
            } else {
                yAxisLabels.push('Wireline');
            }

            if(this.isMultiFrac && this.fracSystems && this.fracSystems.length > 0) {
                const fracLabels = this.fracSystems.map((item)=>{
                    return `( Frac ) ${item.name}`;
                });
                yAxisLabels.push(...fracLabels);
            } else if(this.isSimuFrac) {
                const fracLabels = this.wells.map((well)=> `( Frac ) Well - ${this.truncate(well.name, 10)}`);
                yAxisLabels.push(...fracLabels);
            } else {
                yAxisLabels.push('Frac');
            }
        
            return yAxisLabels;
        },
        truncate(str, n) {
            return (str.length > n) ? str.substr(0, n-1) + '...' : str;
        },
        renderChart: function() {
            const chart = this.getBarChart();

            this.labelCoveringOverlayWidth = chart.scales['service-axis']?.right ?? '0';
            this.labelCoveringOverlayHeight = chart.scales['service-axis']?.bottom ?? '0';

            if(this.scrollOnNewData) {
                chart.options.scales.xAxes[0].ticks.max = this.isJobEndTime? moment.utc(this.isJobEndTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : moment.utc().add({hours:this.jobHourOffset}).valueOf();
            }
            chart.update();
        },
        getBarChart() {
            if(this.$refs['barChart']) { //this means the component is mounted;
                return this.$refs['barChart'].$data._chart;
            } else {
                return null;
            }
        },
        formatScale: function(value) {
            const datetimeValue = moment.utc(value);
            return datetimeValue.format('MMM Do, h:mm a');
        },
        generateActivityBarData(activity, labelIndex, backgroundColorArray, currentData, currentExtraData) {
            const startTime = moment.utc(activity.startTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf();
            const endTime = activity.endTime? moment.utc(activity.endTime, GENERAL_TIME_FORMAT).add({hours:this.jobHourOffset}).valueOf() : moment.utc(this.endDate).add({hours:this.jobHourOffset}).valueOf();
            const well = this.wells.find((well)=>well.index===activity.wellNumber);
            if (!well) { 
                return false; 
            }
            backgroundColorArray[labelIndex] = well.color;
            const duration = this.calculateDuration(startTime, endTime);


            if ((startTime - endTime) !== 0) {
                const durationLabel = this.getDurationLabel(duration);

                currentData[labelIndex] = [startTime, endTime];
                let label = '';
                let color = well.color;
                if(this.isContinuousFrac && activity.type === 'overlap') {
                    const pixelOffset = 10;
                    const nextWell = this.wells.find((well)=>well.index===activity.nextValue.wellNumber);
                    activity.well = well;
                    activity.nextValue.well = nextWell;
                    const bar_ctx = this.$refs['barChart'].$refs.canvas.getContext('2d');
                    const chart = this.getBarChart();
                    const scale = chart.scales['service-axis'];
                    const midBarPixel = scale.getPixelForValue(labelIndex);
                    const background_1 = bar_ctx.createLinearGradient(0, midBarPixel-pixelOffset, 0, midBarPixel+pixelOffset);
                    background_1.addColorStop(0, well.color);
                    background_1.addColorStop(1, nextWell.color);
                    label = 'CF: '+ durationLabel;
                    backgroundColorArray[labelIndex] = background_1;
                    color = GlobalFunctions.blendColors(well.color, nextWell.color, 0.5);
                } else {
                    label = well.name +',stg' + activity.stageNumber + ' : ' + durationLabel;
                }


                currentExtraData[labelIndex] = {
                    startTime: startTime,
                    duration: duration,
                    endTime: endTime,
                    jobHourOffset: this.jobHourOffset,
                    label: label,
                    durationLabel,
                    well: well,
                    color: color,
                    activity: activity,
                };
    
                const barObject = {
                    data: currentData,
                    borderWidth: 1,
                    borderColor: '#000000',
                    backgroundColor: backgroundColorArray,
                    extraData: currentExtraData
                };
    
                return barObject;
            } else {
                return false;
            }
        },
        generateMiddleGapBarData(currentActivity, previousActivity, labelIndex, refData, refExtraData, gapBackgroundColor) {
            const gapStartTime = moment.utc(previousActivity.endTime, GENERAL_TIME_FORMAT).add({hours: this.jobHourOffset}).valueOf();
            const gapEndTime = moment.utc(currentActivity.startTime, GENERAL_TIME_FORMAT).add({hours: this.jobHourOffset}).valueOf();
            const duration = this.calculateDuration(gapStartTime, gapEndTime);
            if((gapStartTime - gapEndTime) !== 0) {
                const  durationLabel = this.getDurationLabel(duration);
                refData[labelIndex] = [gapStartTime, gapEndTime];
                refExtraData[labelIndex] = {
                    startTime: gapStartTime,
                    duration: duration,
                    endTime: gapEndTime,
                    jobHourOffset: self.jobHourOffset,
                    label: durationLabel,
                    durationLabel
                };
                const gapBarData = {
                    data: refData,
                    borderWidth: 1,
                    borderColor: '#000000',
                    backgroundColor: gapBackgroundColor,
                    extraData: refExtraData
                };
                return gapBarData;
            } else {
                return null;
            }
        },
        generateEndGapBarData(latestActivity, labelIndex, refData, refExtraData, gapBackgroundColor) {
            const gapStartTime = moment.utc(latestActivity.data.endTime, GENERAL_TIME_FORMAT).add({hours: this.jobHourOffset}).valueOf();
            const gapEndTime = this.isJobCompleted? moment.utc(this.isJobEndTime).add({hours: this.jobHourOffset}).valueOf() : moment.utc().add({hours: this.jobHourOffset}).valueOf();
            const duration = this.calculateDuration(gapStartTime, gapEndTime);
            if((gapStartTime - gapEndTime) !== 0) {
                const  durationLabel = this.getDurationLabel(duration);
                refData[labelIndex] = [gapStartTime, gapEndTime];
                refExtraData[labelIndex] = {
                    startTime: gapStartTime,
                    duration: duration,
                    endTime: gapEndTime,
                    jobHourOffset: self.jobHourOffset,
                    label: durationLabel,
                    durationLabel
                };
                const gapBarData = {
                    data: refData,
                    borderWidth: 1,
                    borderColor: '#000000',
                    backgroundColor: gapBackgroundColor,
                    extraData: refExtraData
                };
                return gapBarData;
            } else {
                return null;
            }
        },
        getDurationLabel(duration) {
            return (duration.inDays? duration.inDays +'d ' : '') + (duration.inHours? duration.inHours + 'h ': '') + (duration.inMinutes? duration.inMinutes + 'm ': '') + (duration.inSeconds? duration.inSeconds + 's' : '');
        },
        barChartData(activityData) {
            //first divide data into wireline and frac
            let groupByActivity = _.groupBy(activityData, 'activity');

            //by any chance if activityData does not have wireline/frac data, we need to create the lanes
            if(!groupByActivity.hasOwnProperty('wireline')) {
                groupByActivity = { ...groupByActivity, wireline: []}
            }

            if(!groupByActivity.hasOwnProperty('frac')) {
                groupByActivity = { ...groupByActivity, frac: []}
            }

            let activitiesByLanes = {};
            const crossOverLaneKeys = [];
            if(this.isMultiWireline) {
                const wirelineData = _.groupBy(groupByActivity.wireline, 'data.service_alias');

                const activitiesByTruck = {};
                this.wirelineSystems.forEach(system => {
                    if (!system?.name) {
                        console.error('SummaryBarChart Error: Missing required wireline system name');
                    } else {
                        activitiesByTruck[system.name] = wirelineData[system.name] != null ? wirelineData[system.name] : [];
                    }    
                });

                activitiesByLanes = { ...activitiesByTruck };
            } else {
                activitiesByLanes['wireline'] = groupByActivity.wireline ? groupByActivity.wireline : [];
            }

            if(this.isMultiFrac) {
                const fracData = _.groupBy(groupByActivity.frac, 'data.service_alias');

                const activitiesByTruck = this.fracSystems.reduce((prevValue ,item)=>{
                    return { [prevValue.name]: fracData[prevValue.name] || [], [item.name]: fracData[item.name] || []};
                });

                crossOverLaneKeys.push(...Object.keys(activitiesByTruck));

                activitiesByLanes = { ...activitiesByLanes, ...activitiesByTruck };
            } else if(this.isSimuFrac) {
                const fracData = _.groupBy(groupByActivity.frac, 'wellNumber');
                const activitiesByWell = {};

                this.wells.forEach(well => {
                    activitiesByWell[`frac${well.index}`] = fracData[well.index] || [];
                });

                activitiesByLanes = { ...activitiesByLanes, ...activitiesByWell };
            } else {
                activitiesByLanes.frac = groupByActivity.frac? groupByActivity.frac : [];
                crossOverLaneKeys.push('frac');
            }
        
            const labels = Object.keys(activitiesByLanes);

            if(this.isContinuousFrac) {
                activitiesByLanes = this.mapDataWithCrossOvers(activitiesByLanes, crossOverLaneKeys);
            }

            const dataset = [];

            const lengths = Object.values(activitiesByLanes).map(a=>a.length);
            const longestActivityArrayLength = Math.max(...lengths);
            const self = this;
             
            for (let index = 0; index < longestActivityArrayLength; index++) {
                let barData = null;
                let gapBarData = null;
                const data = new Array(labels.length).fill(null);
                const extraData = new Array(labels.length).fill(null);
                const backgroundColor = new Array(labels.length).fill('#c0c0c0');

                const gapData = new Array(labels.length).fill(null);
                const gapExtraData = new Array(labels.length).fill(null);
                const gapBackgroundColor = new Array(labels.length).fill('#c0c0c0');


                labels.forEach(function(label, labelIndex) {
                    if( !activitiesByLanes[label]) {
                        return;
                    }

                    if(activitiesByLanes[label][index]) {
                        //current activity bar
                        const activity = activitiesByLanes[label][index];
                        const barDataRes = self.generateActivityBarData(activity, labelIndex, backgroundColor, data, extraData);
                        if(barDataRes) {
                            barData = barDataRes;
                        }

                        if(activitiesByLanes[label][index-1]) { // activity gaps
                            const previousActivity = activitiesByLanes[label][index-1];
                            const gapBarDataRes = self.generateMiddleGapBarData(activity, previousActivity, labelIndex, gapData, gapExtraData, gapBackgroundColor);
                            if(gapBarDataRes) {
                                gapBarData = gapBarDataRes;
                            }
                        }
                    }
                });

                if(barData) {
                    dataset.push(barData);
                }

                if(gapBarData) {
                    dataset.push(gapBarData);
                }
            }

            const latestActivitiesByLane = { ...this.latestActivityBySystem.wirelineData, ...this.latestActivityBySystem.fracData }
            labels.forEach(function(label, labelIndex) {
                if(latestActivitiesByLane[label] && latestActivitiesByLane[label].subCategory == 'completed') {
                    const latestActivity = latestActivitiesByLane[label];
                    const gapData = new Array(labels.length).fill(null);
                    const gapExtraData = new Array(labels.length).fill(null);
                    const gapBackgroundColor = new Array(labels.length).fill('#c0c0c0');
                    const gapBarDataRes = self.generateEndGapBarData(latestActivity, labelIndex, gapData, gapExtraData, gapBackgroundColor);
                    if(gapBarDataRes) {
                        dataset.push(gapBarDataRes);
                    }
                }
            });
                

            this.summaryData.labels = this.getLabels();
            this.summaryData.datasets = dataset;
            this.renderChart();
            if(this.isFirstRender) {
                this.isFirstRender = false;
                this.barChartData(activityData);
            }
        },
        getDuration(from, to) {
            const duration = this.calculateDuration(from, to);
            const days = Number.isNaN(duration.inDays) || duration.inDays === 0 ? '' : `${duration.inDays}d:`;
            const hours = Number.isNaN(duration.inHours) || duration.inHours === 0  ? '' : `${duration.inHours}h:`;
            const minutes = Number.isNaN(duration.inMinutes) || duration.inMinutes === 0  ? '' : `${duration.inMinutes}m:`;
            const seconds = Number.isNaN(duration.inSeconds) || duration.inSeconds === 0  ? '' : `${duration.inSeconds}s`;
            return `${days}${hours}${minutes}${seconds}`;
        },
        zoomDisabledMessage: function(event) {
            if (this.hoveringChart && !this.chartFocused) {
                this.disabledZoomMessage = true;
                setTimeout(() => this.disabledZoomMessage = false, 3000);
            }
        },
        enableZoomOnFocus: function() {
            const barChart = this.getBarChart();
            barChart.options.plugins.zoom.zoom.enabled = true;
            this.chartFocused = true;
            barChart.update();
        },
        disableZoomOnBlur: function() {
            const barChart = this.getBarChart();
            barChart.options.plugins.zoom.zoom.enabled = false;
            this.chartFocused = false;
            barChart.update();
        }
    },
    data() {
        return {
            isFirstRender: true,
            labelCoveringOverlayWidth: '0',
            labelCoveringOverlayHeight: '0',
            scrollOnNewData: true,
            currentMinXValue: null,
            currentMaxXValue: null,
            alreadyFetchedStart: null,
            alreadyFetchedEnd: null,
            rawActivities: [],
            isLoading: false,
            hoveringChart: false,
            chartFocused: false,
            disabledZoomMessage: false,
            summaryData: {
                labels: [],
                datasets: []
            },
            summaryBarOptions: {
                responsive: true,
                maintainAspectRatio: false,
                indexAxis: 'x',
                hover: { animationDuration: 0 },
                responsiveAnimationDuration: 0,
                animation: {
                    duration: 0 // general animation time
                },
                layout: {
                    padding: {
                        left: 0,
                        right: 25,
                        top: 0,
                        bottom: 0
                    }
                },
                customLabels: {
                    afterDatasetsDraw: function(chart, options) {
                        const hPadding = 2;
                        const chartInstance = chart;
                        const ctx = chartInstance.ctx;
                        ctx.font = Chart.helpers.fontString(Chart.defaults.global.defaultFontSize, Chart.defaults.global.defaultFontStyle, Chart.defaults.global.defaultFontFamily);
                        ctx.textBaseline = 'bottom';

                        chart.data.datasets.forEach(function (dataset, i) {
                            const currentMinXValue = chart?.options?.scales?.xAxes[0]?.ticks?.min;
                            const meta = chartInstance.controller.getDatasetMeta(i);
                            meta.data.forEach(function (bar, index) {
                                const startTime = dataset?.data[bar._index]?.[0];
                                if (startTime && currentMinXValue <= startTime && dataset.extraData[bar._index]) {
                                    const maxWidth = meta.data[index]._model.x - meta.data[index]._model.base - (2*hPadding);
                                    const data = dataset.extraData[bar._index].label;
                                    if(dataset?.extraData?.[bar._index]?.activity?.nextValue) {
                                        ctx.fillStyle = GlobalFunctions.getTitleColorForBG(dataset.extraData[bar._index].color);
                                    } else {
                                        ctx.fillStyle = GlobalFunctions.getTitleColorForBG(dataset.backgroundColor[bar._index]);
                                    }

                                    ctx.textAlign = 'start';
                                    ctx.fillText(data, meta.data[index]._model.base + hPadding, bar._model.y + 5, maxWidth);
                                }
                            });
                        });
                    }
                },
                plugins: {
                    zoom: {
                        pan: {
                            enabled: true,
                            mode: 'x',
                            onPanComplete: ({chart})=>{
                                let { min, max } = chart.scales['date-axis'];
                                this.currentMinXValue = min;
                                this.currentMaxXValue = max;
                                this.setLoading(min, max);

                                this.scrollOnNewData = false;
                                if(Math.abs(max - moment.utc().valueOf()) < PAN_TO_LIVE_DATA_THRESHOLD) {
                                    this.scrollOnNewData = true;
                                }

                                //backend is expecting utc not job local
                                let utcMin = moment.utc(min).subtract({hours: this.jobHourOffset}).valueOf();
                                let utcMax = moment.utc(max).subtract({hours: this.jobHourOffset}).valueOf();
                                this.onXAxisChange && this.onXAxisChange({min: utcMin, max: utcMax});
                            },
                            rangeMin: {
                                x: null
                            },
                            rangeMax: {
                                x: null
                            }
                        },
                        zoom: {
                            enabled: false,
                            mode: 'x',
                            onZoomComplete: ({chart}) => { 
                                let { min, max } = chart.scales['date-axis'];
                                this.currentMinXValue = min;
                                this.currentMaxXValue = max;
                                this.setLoading(min, max);

                                //backend is expecting utc not job local
                                let utcMin = moment.utc(min).subtract({hours: this.jobHourOffset}).valueOf();
                                let utcMax = moment.utc(max).subtract({hours: this.jobHourOffset}).valueOf();
                                this.onXAxisChange && this.onXAxisChange({min: utcMin, max: utcMax});
                            },
                            rangeMin: {
                                x: null
                            },
                            rangeMax: {
                                x: null
                            }
                        }
                    }
                },
                scales: {
                    yAxes: [{
                        id: 'service-axis',
                        gridLines: {
                            display: false,
                            color: '#fff',
                            zeroLineColor: '#fff',
                            zeroLineWidth: 0
                        },
                        ticks: {
                            fontColor: '#FFFFFF'
                        },
                        stacked: true
                    }],
                    xAxes: [{
                        id: 'date-axis',
                        type: 'linear', //time type does not work with zoom library
                        display: true,
                        ticks: {
                            callback: this.formatScale,
                            autoSkipPadding: 20,
                            maxRotation: 0,
                            minRotation: 0,
                            fontColor: '#FFFFFF',
                            min: moment.utc(this.endDate).add({hours: this.jobHourOffset}).add({hours: -SUMMARY_BAR_TIME_FRAME}).valueOf(),
                            max: moment.utc(this.endDate).add({hours: this.jobHourOffset}).valueOf()
                        }
                    }]
                },
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false,
                    mode: 'nearest',
                    intersect: true,
                    position: 'cursor',
                    animationDuration: 0,
                    backgroundColor: '#000',
                    callbacks: {
                        label: function(tooltipItem, data) {
                            const tooptipData = data.datasets[tooltipItem.datasetIndex].extraData[tooltipItem.index];
                            if(tooptipData?.activity?.nextValue) {
                                const startMoment = moment.utc(tooptipData.startTime);
                                const endMoment = moment.utc(tooptipData.endTime);
                                return `Continuous frac well ${tooptipData.activity.well.name} to ${tooptipData.activity.nextValue.well.name}<br/>Duration: ${tooptipData.durationLabel}<br/>${(startMoment? 'Start time: ' + startMoment.format('LTS')+'<br/>' : '')}End time: ${endMoment.format('LTS')}`;
                            }
                
                            if(tooptipData?.activity && tooptipData.well) {
                                const startMoment = moment.utc(tooptipData.startTime);
                                const endMoment = moment.utc(tooptipData.endTime);
                                return `Well name: ${tooptipData.well.name}<br/>Stage number: ${tooptipData.activity.stageNumber}<br/>Duration: ${tooptipData.durationLabel}<br/>${(startMoment? 'Start time: ' + startMoment.format('LTS')+'<br/>' : '')}End time: ${endMoment.format('LTS')}`;
                            } else {  
                                return `Standby<br/>Duration: ${tooptipData.durationLabel}`;
                            }                               
                        }
                    },
                    custom: function(tooltipModel) {
                        // Tooltip Element
                        let tooltipEl = document.getElementById('chartjs-tooltip-summary-bar');

                        // Create element on first render
                        if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip-summary-bar';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                        }

                        // Hide if no tooltip
                        if (tooltipModel.opacity === 0) {
                            tooltipEl.style.opacity = 0;
                            return;
                        }

                        // Set caret Position
                        tooltipEl.classList.remove('above', 'below', 'no-transform');
                        if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                        } else {
                            tooltipEl.classList.add('no-transform');
                        }

                        function getBody(bodyItem) {
                            return bodyItem.lines;
                        }

                        // Set Text
                        if (tooltipModel.body) {    
                            const titleLines = tooltipModel.title || [];
                            const bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead>';

                            titleLines.forEach(function(title) {
                                const style = 'width : 10px; height: 10px; border-width : 1px;';
                                innerHtml += '<tr><th><div class="d-flex pr-3"><div class="mx-2 mt-1" style="' + style + '"></div>' + title + '</div></th></tr>';
                            });
                            innerHtml += '</thead><tbody>';


                            bodyLines.forEach(function(body, i) {
                                const colors = tooltipModel.labelColors[i];
                                if(body && body.length>0) {
                                    const style = `background: ${typeof colors.backgroundColor === 'string'? colors.backgroundColor : 'transparent'}; width : 9px; height: 9px; border-width : 1px; border-color: ${typeof colors.backgroundColor === 'string'? '#FFFFFF' : 'transparent'}; border-style: solid`;
                                    innerHtml += '<tr><td><div class="d-flex pr-3"> <div class="mx-2 mt-1" style="' + style + '"></div>' + body + ' </div></td></tr>';                                           
                                }
                            });
                            innerHtml += '</tbody>';

                            const tableRoot = tooltipEl.querySelector('table');
                            tableRoot.innerHTML = innerHtml;
                        }

                        // `this` will be the overall tooltip
                        const position = this._chart.canvas.getBoundingClientRect();

                        //mouse position
                        let offset = tooltipModel.caretX;
                        
                        //when the tooltip tries to render at the right edge
                        //of the screen, give it more space to the left
                        const averageTooltipWidth = 150; 
                        if (tooltipModel.caretX > this._chart.width - averageTooltipWidth)
                        {offset = this._chart.width - averageTooltipWidth;}

                        // Display, position, and set styles for font
                        tooltipEl.style.opacity = 1;
                        tooltipEl.style.position = 'fixed';
                        tooltipEl.style.left = position.left + offset + 'px';
                        tooltipEl.style.top = position.top + tooltipModel.caretY + 'px';
                        tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                        tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                        tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                        tooltipEl.style.padding = 2 + 'px ' + 0 + 'px';
                        tooltipEl.style.pointerEvents = 'none';
                    }
                }
            }
        };
    },
    props: 
    [
        'jobHourOffset',
        'latestActivityData',
        'activityData',
        'wells',
        'endDate',
        'bounds',
        'jobNumber',
        'isJobCompleted',
        'onXAxisChange',
        'isMultiWireline',
        'wirelineSystems',
        'isMultiFrac',
        'fracSystems',
        'isContinuousFrac',
        'isSimuFrac',
        'isJobEndTime',
        'height',
        'latestActivityBySystem'
    ]
};
</script>
