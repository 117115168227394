<!-- DEPRECIATED -->
<!-- iwsInput.vue SHOULD BE USED INSTEAD -->
<script>
import GlobalFunctions from '../GlobalFunctions.js';

//TODO: when updated to Vue3, this can be reworked
//https://vuejs.org/guide/components/v-model.html#handling-v-model-modifiers
export default {
  props: {
    modelValue: [String, Number],
    modelModifiers: Array
  },
  data() {
    return {
      value: null
    }
  },
  emits: ['update'],
  mounted() {
    this.value = this.processValue(this.modelValue);
  },
  methods: {
    processValue(value)
    {
      if (this.modelModifiers.includes('round')) {
        value = GlobalFunctions.roundAccurately(Number(value), 2);
      } 
      return value.toFixed(2);
    },
    emitValue(e) {
      this.value = this.processValue(e.target.value);
      e.target.value = this.value;
      this.$emit('update', e);
    }
  }
}
</script>

<template>
  <input type="text" :value="value" @change="event => emitValue(event)" />
</template>