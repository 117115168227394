var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "text-light p-1 pt-3 pb-3",
      class: [_vm.clicked ? "bg-secondary" : "bg-primary"],
      staticStyle: {
        "margin-right": "3px",
        "margin-bottom": "3px",
        display: "inline",
        "text-align": "center",
        cursor: "pointer",
      },
      style: _vm.keyStyle(),
      attrs: { unselectable: "on", onselectstart: "return false" },
      on: {
        click: function ($event) {
          return _vm.click()
        },
      },
    },
    [
      _vm.shift
        ? _c("span", [_vm._v(_vm._s(_vm.secondary))])
        : _c("span", [_vm._v(_vm._s(_vm.primary))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }