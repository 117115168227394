var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("api-token-enabler", {
        attrs: {
          "api-token": _vm.userApiToken,
          "user-id": _vm.userId,
          "auth-user": _vm.authUser,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }