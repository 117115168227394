<template>
    <div>
        <notifications ref="dashboardNotification" id="dashboardNotification" group="dashboardNotification" position="top center"/>
        <analytics-create-modal :iwsUser="iwsUser" :customers="customers" :show="createModalShow" :dashboardInfo="dashboardInfo" @onCreateOrSavePressed="onCreateOrSavePressed" @dismissModal="dismissModal"/>
            <!-- Sidebar -->
            <div v-if="isFeatureFlagged('ANALYSIS_QUICK_ADD')" class="left-sidebar" style="width:60px; background-color: #bdbdbd;" id="left-sidebar">
                <!-- Header -->
                <div class="p-1 m-1 d-flex align-items-center" style="border-bottom: 4px solid #f1f1f1;" 
                    :class="{'justify-content-center': !sidebarIsOpen, 'justify-content-between': sidebarIsOpen }">
                    <div class="horizontal-label p-1" v-show="sidebarIsOpen">Quick Select</div>
                    <div class="m-0 p-1 sidebar-trigger" style="align-self: center;">
                        <a href="#" @click="sidebarToggle()">
                        <!-- to reduce flickering, use one element for the trigger instead of multiple divs with if/else: -->
                            <i class="pt-1 font-32" :class="{ 'fa': true,
                                     'fa-arrow-circle-left':  sidebarIsOpen,
                                     'fa-arrow-circle-right': !sidebarIsOpen }"></i>
                        </a>
                    </div>
                </div>
                <!-- Sidebar items -->
                <div v-show="sidebarIsOpen">
                    <div v-for='(displayItem, index) in getSidebarItems()'
                         class="pb-2 sidebar-item"
                         :key='index'>

                        <div class="d-flex justify-content-between p-1">
                            <div class="pl-1" @click="createDashboardItem(displayItem, true)">{{displayItem.friendlyName}}</div>

                            <div class="d-flex float-right">
                                <div class="pointer-cursor" @click="createDashboardItem(displayItem, true)">
                                    <i class="fas fa-plus-circle icon"></i>
                                </div>
                                <div class="pl-1"></div>
                                <div class="pointer-cursor" @click="setDefaults(displayItem)">
                                    <i class="fas fa-cog icon"></i>
                                </div>
                            </div>
                    
                        </div>
                    </div>
                </div>
                <div v-show="!sidebarIsOpen" class="vertical-label">Quick Select</div>
                <!--Sidebar footer-->
                <div class="d-flex w-100 justify-content-between px-2 sidebar-footer">
                    <div class="d-flex justify-content-start" v-if="sidebarIsOpen">
                        <!--Hide save button for now, as quick add items save automatically for the time being-->
                        <button v-if="false" class="btn btn-primary" :disabled="!isQuickAddUnsaved" @click.prevent="saveCurrentDashboard(true,true)"
                            style="width:111px"
                        >
                        Save Layout
                        </button>
                    </div>
                </div>
            </div>
        <div class="ml-auto d-flex justify-content-end px-3 pt-3">
            <div v-if="showUserDefaultsModal">
                <user-analysis-defaults-modal
                    :modalVisible="showUserDefaultsModal"
                    :initialSelectedComponent="selectedDisplayItem"
                    :userId="userid"
                    :customerId="dashboardInfo.customer_id"
                    :analysisDashboard="this"
                    :userComponentDefaults="userComponentDefaults"
                    :availableDisplayItems="availableDisplayItems"
                    @onDismiss="showUserDefaultsModal=false"
                    @update="updateUserDefaultTemplates"
                >
                </user-analysis-defaults-modal>
            </div>
            <div v-if="editMode" style="padding-left:60px;" v-bind:class="{ activeNewComponent: sidebarIsOpen }">
                <b-dropdown id="dropdown-1" text="Add Component"  size='sm' variant="primary" class='newComponentDropdown'>
                    <b-dropdown-item
                        @click="createDashboardItem(displayItem)"
                        v-for='displayItem in availableDisplayItems'
                        :key='displayItem.id'>
                        {{displayItem.friendlyName}}
                    </b-dropdown-item>
                </b-dropdown>
            </div>
            <div v-if="dashboardInfo && dashboardInfo.name" class="absolute-center pt-2 font-weight-bold">
                {{dashboardInfo.name}}
            </div>
            <div class="d-flex flex-grow-1"/>
            <span v-if='!editMode' class="fas fa-pen fa-md px-1"
                style='margin: auto;'
                role="button"
                @click="editMode = true"
                v-tooltip:top="'Edit Layout'"
            >
            </span>
            <span v-if='!editMode' class="fas fa-cog fa-md px-1 mr-2"
                style='margin: auto;'
                role="button"
                v-tooltip:top="'Project Options'"
                @click="createModalShow = true;"
            ></span>                 
            <div v-else>
                <div class="d-flex flex-row">
                    <button role="button"
                            class="btn btn-primary bar_button mr-2 ml-3"
                            @click.prevent="saveCurrentDashboard()"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Save the current layout">
                        Save Layout
                    </button>
                    <button role="button"
                            class="btn btn-secondary bar_button mr-2"
                            @click="editMode = false; setupDashboard()">
                        Discard
                    </button>
                </div>
            </div>
        </div>
        <grid-layout 
            :class="{'sidebar-spacer' : isFeatureFlagged('ANALYSIS_QUICK_ADD')}"
            v-if="selectedDashboard.layout.length != 0"
            :layout.sync="selectedDashboard.layout"
            :is-draggable="editMode"
            :is-resizable="editMode"
            :responsive="true"
            :col-num="12"
            :row-height="30"
            :is-mirrored="false"
            :vertical-compact="true"
            :margin="[5, 5]"
            :use-css-transforms="true"
            @layout-updated="layoutUpdatedEvent"
        >
            <div v-for="(item,index) in selectedDashboard.layout" :key="item.i">
                <grid-item :x="item.x"
                    :y="item.y"
                    :w="item.w"
                    :h="item.h"
                    :i="item.i"
                    :minW="getDimensionsForItem(item,'minW')"
                    :minH="getDimensionsForItem(item,'minH')"
                    :maxH="getDimensionsForItem(item,'maxH')"
                    :maxW="getDimensionsForItem(item,'maxW')"
                    @resize="handleResizeEvent"
                    @resized="handleResizedEvent"
                    dragAllowFrom=".item_title"
                >
                    
                    <div class='item_title' v-if="editMode">
                        <div class="pl-2" style="display:inline-block;">
                            <b-form-select v-model="selectedDashboard.items[index].type" :options="availableDisplayItems" class="select_form" @change="handleComponentTypeChanged(selectedDashboard.items[index], index)"></b-form-select>
                        </div>
                        <div class='closer' @click="removeDashboardItem(item, index)">X</div>
                    </div>
                    <div class='item_title' v-else>
                        <div class="pl-2" style="display:inline-block;color:white;" >
                            {{availableDisplayItems.find(target=>target.name == selectedDashboard.items[index].type).friendlyName}}
                        </div>
                    </div>
                    <!-- <div class="border w-100 h-100 d-flex justify-content-center align-items-center">
                        <div>{{selectedDashboard.items[index].type}}</div>
                    </div> -->
                    <display-item-component
                        :editMode='editMode'
                        :item='selectedDashboard.items[index]'
                        :ref="'grid-item-' + index"
                        :defaultChartColumnCount="defaultChartColumnCount"
                        :layout="selectedDashboard.layout[index]"
                        :isAdmin="isAdmin"
                        :dashboardInfo="dashboardInfo"
                        :userid="userid"
                        :customers="customers"
                        :userCompanyId="userCompanyId"
                        :componentSpecificPermissions="componentSpecificPermissions"
                        :friendlyName="selectedComponentFriendlyName(index)"
                        @updateDashboardItem="updateDashboardItem"
                    >   
                    </display-item-component>
                </grid-item>
            </div>
        </grid-layout>
        <center v-else class="mt-5 h1">Add components to the project by clicking the <div class="fas fa-pen fa-md px-1" /> to the right.</center>
    </div>
</template>

<style scoped>
    .icon {
        cursor: pointer;
        border: 0px;
        color: #444444;
    }
    .icon:hover {
        color: #FFFFFF;
    }
    .left-sidebar {
        background-color: #bdbdbd;
        transition: all .3s ease;
        text-align: center;
        height:100%;
        width:200px;
        background-color:#fff;
        position:fixed !important;
        z-index:1;
        overflow:auto;
        opacity: 0.8;
    }
    .left-sidebar:hover {
        opacity: 1;
    }
    .sidebar-item { 
        font-size: 18px; cursor: pointer;
    }
    .sidebar-item:hover {
        text-decoration: underline;
    }
    .sidebar-trigger a:link, a:visited, a:active {
        color: rgba(255, 255, 255, 0.6);
        transition: all .25s ease;
    }
    .sidebar-trigger a:hover {
        color: #ffffff;
    }
    .vertical-label {        
        color: #ffffff;
        font-size: 24px;
        writing-mode: tb-rl;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }
    .horizontal-label{
        color: #ffffff;
        font-size: 24px;
    }
    .absolute-center {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .activeNewComponent {
      margin-left: 280px;
    }
    .closer {
        z-index: 1000;
        padding: 0px 0px;
        opacity: 0.8;
        cursor: pointer;
        padding-left: 5px;
        padding-right: 5px;
        background-color: #222;
        color: white;
        display: inline-block;
        position: absolute;
        right: 0px;
    }
    .select_form {
        display: inline;
        vertical-align: top;
        cursor: pointer;
        color: #3dcfff;
        font-weight: bold;
    }
    .closer:hover{
        opacity: 1;
        text-decoration: underline;
    }
    .bar_button{
        padding: 4px; 
        font-size: 13px;
    }
    .vue-grid-item {
        overflow-y: visible;
    }
    .vue-resizable {
        border: 1px solid #555;
        overflow: hidden;
    }
    .item_title {
        background-color: rgba(65,65,65,0.8);
        width: 100%;
    }
    .fake-button:hover {
        background: transparent;
        box-shadow: 0px 0px 0px transparent;
        border: 0px solid transparent;
        text-shadow: 0px 0px 0px transparent;
    }

    .fake-button:active {
        outline: none;
        border: none;
    }

    .fake-button:focus {
        outline: 0;
    }
    .arrow-icon {
        font-size:32px;
    }
    .font-32 {
        font-size:32px;
    }
    .sidebar-footer {
        position:absolute;
        bottom:90px;
    }
    .sidebar-spacer {
        margin-left:60px;
    }
</style>

<script>
import GlobalFunctions from '../../GlobalFunctions';
import Notifications from 'vue-notification';
import _ from 'lodash';

const TOP_PADDING = 80;
const SIDEBAR_WIDTH_CLOSED = 60;
const SIDEBAR_WIDTH_OPEN = 320;

const AGGREGATION_OPTIONS = [
    { name: 'Raw Data', value: ''},
    { name: '1s', value: 'PT1S'} , 
    { name: '5s', value: 'PT5S'} , 
    { name: '10s', value: 'PT10S'}, 
    { name: '30s', value: 'PT30S'} , 
    { name: '1M', value: 'PT1M'} , 
    { name: '5M', value: 'PT5M'} , 
    { name: '10M', value: 'PT10M'} , 
    { name: '30M', value: 'PT30M'}
];

export default {
    provide: function() {
        return {
            dashboardData: this,
            //make quickAddData a reactive provided property using the vue composition API, so that it updates 
            //the injected data in child components when the value is updated by the parent
            quickAddData: this.quickAddData
        };
    },
    props: {
        id: {
            type: [Number],
            required: true
        },
        availableDisplayItems: {
            type: [Array],
            required: true
        },
        dashboardInfo: {
            type: [Object]
        },
        customers: {
            type: [Array]
        },
        sessionId: {
            type: [String, Object]
        },
        isAdmin: {
            type: [Boolean, Number]
        },
        iwsUser: {
            type: [Boolean, Number]
        },
        userid: {
            type: [String]
        },
        userCompanyId: {
            type: String
        },
        userComponentDefaultsProp: {
            type: [String,Object]
        },
        componentSpecificPermissions: {
            type: Object,
            required: false
        }

    },
    data() {
        return {
            quickAddData: {
                targetItemId: null,
                templateDefaults: null,
            },
            isQuickAddUnsaved: false,
            sidebarIsOpen: false,
            editMode: false,
            newItemAdded: true,
            showUserDefaultsModal: false,
            data: null,
            projectName: null,
            createModalShow: false,
            displayItemDimensions: {},
            selectedDashboard: {
                layout: [],
                items: [],
                layout_options: {}
            },
            defaultChartColumnCount: 12,
            aggregateOptions: AGGREGATION_OPTIONS,
            userComponentDefaults: null,
        };
    },
    mounted() {
        this.setupDashboard();
        const savedDashboardInfo = _.cloneDeep(this.dashboardInfo);
    },
    created(){
        window.addEventListener('scroll', this.handleScroll);
    },
    computed: {
        isNewDashboard() {
            return this.id === 0;
        }
    },
    methods: {
        selectedComponentFriendlyName(index) {
            let selectedComponents = null;
            let componentName = null;
            let nameFromDashboardLayout = this.selectedDashboard.layout[index]? this.selectedDashboard.layout[index]?.name : null;
            let nameFromDashboardItems = this.selectedDashboard.items[index]? this.selectedDashboard.items[index].type : null;
            if (nameFromDashboardLayout) {
                componentName = nameFromDashboardLayout;
                selectedComponents = this.availableDisplayItems.find(target => target.name == nameFromDashboardLayout);
            } else if (nameFromDashboardItems){
                componentName = nameFromDashboardItems;
                selectedComponents = this.availableDisplayItems.find(target => target.name == nameFromDashboardItems);
            }
            
            if (selectedComponents?.friendlyName) {
                return selectedComponents.friendlyName;
            } else {
                let friendlyName = null;
                switch(componentName) {
                    case "dashboard-comparison-chart":
                        friendlyName = "Stage Comparison"
                        break;
                    case "well-comparison":
                        friendlyName = "Multi-well Comparison"
                        break;
                    case "stage-comparison-heatmap":
                        friendlyName = "Stage Summary Heat Map"
                        break;
                }
                return friendlyName;
            }
        },
        setDefaults(item) {
            this.showUserDefaultsModal = true;
            this.selectedDisplayItem = item;
        },
        setupDashboard() {
            const savedDashboardInfo = _.cloneDeep(this.dashboardInfo);
            const filteredDashboardInfo = Object.keys(savedDashboardInfo).filter((k) => savedDashboardInfo[k] != null).reduce((a, k) => ({ ...a, [k]: savedDashboardInfo[k] }), {});
            this.selectedDashboard = {
                ...this.selectedDashboard,
                ...filteredDashboardInfo
            };
            this.createModalShow = this.isNewDashboard;
            const self = this;
            this.availableDisplayItems.forEach(item => {
                item.value = item.name;
                item.text = item.friendlyName;
                self.displayItemDimensions[item.name] = {
                    minW: item.minW ? item.minW : 2,
                    minH: item.minH ? item.minH : 6,
                    maxW: item.maxW ? item.maxW : 12,
                    maxH: item.maxH ? item.maxH : 100
                };
            });
            this.userComponentDefaults = this.userComponentDefaultsProp;
        },
        isFeatureFlagged(feature) {
            return GlobalFunctions.isFeatureFlagged(feature);
        },
        clickHandler: function(e) {
            if (!document.getElementById('left-sidebar').contains(e.target)){
                this.sidebarToggle();
            }
        },
        sidebarToggle: function () {
            if(this.sidebarIsOpen) {
                document.getElementById('left-sidebar').style.width = SIDEBAR_WIDTH_CLOSED + 'px';
                window.removeEventListener('click', this.clickHandler);
            }
            else{
                document.getElementById('left-sidebar').style.width = SIDEBAR_WIDTH_OPEN + 'px';
                window.addEventListener('click', this.clickHandler);
            }
            this.sidebarIsOpen = !this.sidebarIsOpen;
        },
        getSidebarItems: function() {
            let items = [];
            this.availableDisplayItems.forEach(i => {
                items.push(i);
            });
            return items;

        },
        handleComponentTypeChanged(item, index) {
            // const gridItemRef = this.$refs['grid-item-' + index]?.[0]? this.$refs['grid-item-' + index][0].getParentComponentRef() : null;
            // gridItemRef && gridItemRef.autoSize();
        },
        removeDashboardItem: function(item, index) {
            this.selectedDashboard.layout.splice(index,1);
            this.selectedDashboard.items.splice(index,1);
        },
        handleResizeEvent(i, newHeight, newWidth, newHeightInPx, newWidthInPx) {
            const index = this.selectedDashboard.items.findIndex(e => e.i == i);
            this.$refs['grid-item-' + index][0].$data.height = newHeightInPx;
        },
        handleResizedEvent(i, newHeight, newWidth, newHeightInPx, newWidthInPx) {
            const index = this.selectedDashboard.items.findIndex(e => e.i == i);
            this.$refs['grid-item-' + index][0].$data.height = newHeightInPx;
        },
		handleScroll(event) {
            if(window.scrollY > TOP_PADDING){
                document.getElementById('left-sidebar').style.top = 0 + 'px';
            }
            else {
                document.getElementById('left-sidebar').style.top = TOP_PADDING - window.scrollY + 'px';
            }
        },
        createDashboardItem(item, populateWithDefaults=false) {
            const layout = this.selectedDashboard.layout;
            const newItem = { ...layout[layout.length-1] };
            const dimensions = this.displayItemDimensions[item.name];
            if(this.selectedDashboard.layout.length == 0) {
                newItem.i = 0;
            }else{
                newItem.i = Math.max(...this.selectedDashboard.layout.map(o => o.i)) + 1;
            }
            
            newItem.w = dimensions.maxW;
            newItem.h = dimensions.minH;
            
            newItem.x = 0;
            newItem.y = (newItem.y || 0 )+ newItem.h;
            newItem.name = item.name;
            
            layout.push(newItem);

            const items = this.selectedDashboard.items;
            items.push({ type: item.name });
            items[items.length-1].i = newItem.i;
            this.newItemAdded = true;
            this.setDisplayItemHandleColor();
            if (populateWithDefaults) {
                this.populateTemplateDefaults(newItem);
            }
            if (this.sidebarIsOpen) { //close sidebar
                this.sidebarToggle();
            }
        },
        populateTemplateDefaults(item) {
            //provided property that will be injected to subscribed child components automatically
            this.quickAddData.targetItemId = item.i;
            this.quickAddData.templateDefaults = this.userComponentDefaults[item.name];

            this.$notify({
                group: 'dashboardNotification',
                title: 'A new item was saved to the dashboard',
                text: 'To edit or delete this item, click the edit layout button.',
                duration: 4000
            });
            this.isQuickAddUnsaved = true;
        },
        layoutUpdatedEvent: function(newLayout) {
            if(this.newItemAdded) {
                // Timeout is needed for the animation to complete
                setTimeout(() => window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: 'smooth'}), 250);
                this.newItemAdded = false;
            }
        }, 
        setDisplayItemHandleColor() {
            this.$nextTick(() => {
                const displayElements = document.getElementsByClassName('vue-resizable-handle');
                if(displayElements) {
                    const displayElementsAttay = Array.prototype.slice.call(displayElements);
                    displayElementsAttay.forEach( function(element) {
                        element.style.filter = 'invert(43%) sepia(0%) saturate(0%) hue-rotate(182deg) brightness(100%) contrast(83%)'; 
                    });
                }
            });
        },
        getDimensionsForItem: function(item, dimension=null) {
            const itemType = this.selectedDashboard.items.find(e => e.i == item.i).type;
            if(itemType && this.displayItemDimensions[itemType]) {
                if (!dimension) {
                    return this.displayItemDimensions[itemType];
                } else {
                    if (this.displayItemDimensions[itemType][dimension]) {
                        return this.displayItemDimensions[itemType][dimension];
                    } else {
                        return 0;
                    }
                }              
            } else {
                return 0;
            }
        },				
        async onCreateOrSavePressed(dashboardData) {
            const self = this;
            const data = {
                '_token': GlobalFunctions.getCSRFToken(),
                id: dashboardData.id,
                name: dashboardData.name,
                customer_id: dashboardData.customer_id,
                layout: JSON.stringify(dashboardData.layout),
                items: JSON.stringify(dashboardData.items),
                layout_options: JSON.stringify(dashboardData.layout_options),
            };
            const res = await $.ajax({
                method: 'POST',
                url: '/analytics',
                data,
                dataType: 'json'
            });

            window.location = '/analytics/' + res.id;
        },
        dismissModal(isProjectCreated) {
            if (isProjectCreated) {
                this.createModalShow = false;
            } else {
                window.location = '/analytics-list/recent';
            }
        },
        saveCurrentDashboard: function(blockPageReload = false, resetisQuickAddUnsaved=false) {
            const self = this;
            const url = '/analytics/' + self.selectedDashboard.id;

            $.ajax({
                url,
                method: 'PUT',
                data:
                {
                    '_token': GlobalFunctions.getCSRFToken(),
                    name: self.selectedDashboard.name,
                    layout: JSON.stringify(self.selectedDashboard.layout),
                    items: JSON.stringify(self.selectedDashboard.items),
                    layout_options: JSON.stringify(self.selectedDashboard.layout_options)
                },
                dataType: 'json'
            })
                .done((data) => {
                    //Don't reload if we are just updating item level configurations or if adding components through quick add
                    if(!blockPageReload) {
                        location.reload();
                    }
                    if (resetisQuickAddUnsaved) {
                        this.isQuickAddUnsaved = false;
                    }
                })
                .fail((msg) => { 
                    console.log('Failure during request: ' + url, msg);
                    alert('Something went wrong saving your changes! Please contact an administrator.');
                })
                .always((msg) => {
                });
        },
        updateDashboardItem: function(eventData) {
            const targetItemIndex = this.selectedDashboard.items.findIndex(item => {
                return item.i == eventData.item.i;
            });
            if(targetItemIndex != -1) {
                Object.assign(this.selectedDashboard.items[targetItemIndex], { 
                    targetTemplateId: eventData.targetTemplateId
                });
            }else{
                console.error("Error : Cannot find own dashboard item. Something has gone terribly wrong please contact an administrator.")
            }
            if (eventData.saveDashboard) {
                this.saveCurrentDashboard(true);
            }
        },
        updateUserDefaultTemplates: function(updatedUserComponentDefaults) {
            this.userComponentDefaults = updatedUserComponentDefaults;
        }
    }
};
</script>
