var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", {
        staticClass: "fas fa-lock fa-md px-1",
        style: _vm.interlockButtonStyle,
        attrs: { id: "interlockButton", role: "button" },
        on: {
          click: function ($event) {
            return _vm.toggleValue()
          },
        },
      }),
      _vm._v(" "),
      _c(
        "b-tooltip",
        { attrs: { triggers: "hover", target: "interlockButton" } },
        [
          _vm._v(
            "\n\t\t" +
              _vm._s(
                _vm.isActive ? _vm.activeTooltipText : _vm.inactiveTooltipText
              ) +
              "\n\t"
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }