var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.tooltipExists,
          expression: "tooltipExists",
        },
      ],
    },
    [
      _c(
        "b-popover",
        {
          attrs: {
            target: "tooltip-" + _vm._uid,
            "custom-class": "white-popover",
            triggers: "hover",
            placement: "right",
            "boundary-padding": 0,
            delay: { show: 0, hide: 0 },
            boundary: "viewport",
          },
        },
        [
          _c("div", { staticClass: "tooltipText" }, [
            _vm._v(_vm._s(_vm.tooltipText)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("h4", { staticClass: "ml-2", attrs: { id: "tooltip-" + _vm._uid } }, [
        _c("i", { staticClass: "fas fa-info-circle" }),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }