var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "form-group pt-1 col-12" },
      [
        _c("label", [_vm._v("Select Companies")]),
        _vm._v(" "),
        _c("multi-select-checkbox", {
          attrs: {
            id: "dashboardPermissions",
            selectedOptions: _vm.selectedCompanies,
            valueKey: "name",
            options: _vm.companies,
            outputKey: "id",
            placeholder: "Select Company",
            selectByKey: "id",
            isDark: true,
            label: "Company",
          },
          on: {
            updateSelectedOptions: function ($event) {
              return _vm.processMultiselectChange($event)
            },
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.selectedCompanies, function (company) {
        return _c(
          "div",
          {
            key: company.id,
            staticStyle: {
              width: "100%",
              "margin-left": "20px",
              "margin-right": "20px",
              "margin-bottom": "20px",
            },
          },
          [
            _c(
              "div",
              { staticClass: "customer-api-permissions" },
              [
                _c("UserPermissionsCompany", {
                  key: company.id,
                  ref: "UserPermissionsCompany",
                  refInFor: true,
                  attrs: {
                    user: _vm.user,
                    customer: company,
                    isIwsAdmin: _vm.isIwsUser && _vm.role == "admin",
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
      0
    ),
    _vm._v(" "),
    _c("div", { staticClass: "row" }, [
      _vm.selectedCompanies.length > 0
        ? _c(
            "div",
            {
              staticClass: "form-group float-right col-12",
              staticStyle: { "text-align": "end" },
            },
            [
              _c("iws-button", {
                attrs: { text: "Submit" },
                on: { click: _vm.submitForm },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }