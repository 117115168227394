var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      ref: `iws_global_confirm_modal_${_vm.id}`,
      staticClass: "dark-modal modal",
      attrs: {
        id: `iws_global_confirm_modal_${_vm.id}`,
        tabindex: "-1",
        role: "dialog",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog",
          style: `max-width: ${_vm.details.width}`,
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _c("div", { staticClass: "modal-header" }, [
              _c("h5", { staticClass: "modal-title" }, [
                _vm._v(
                  "\r\n                    " +
                    _vm._s(_vm.details.title) +
                    "\r\n                "
                ),
              ]),
              _vm._v(" "),
              _vm._m(0),
            ]),
            _vm._v(" "),
            !!_vm.details.body
              ? _c("div", { staticClass: "modal-body" }, [
                  _c("div", {
                    staticClass: "modal-text",
                    domProps: { innerHTML: _vm._s(_vm.details.body) },
                  }),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "modal-footer" }, [
              _vm.details.allowReject
                ? _c(
                    "button",
                    {
                      class: `btn btn-outline-${_vm.details.rejectColour}`,
                      attrs: { id: "reject-button", "data-dismiss": "modal" },
                    },
                    [
                      _vm._v(
                        "\r\n                    " +
                          _vm._s(_vm.details.rejectText) +
                          "\r\n                "
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c(
                "button",
                {
                  class: `btn btn-${_vm.details.confirmColour}`,
                  attrs: {
                    id: "confirm-button",
                    "data-dismiss": "modal",
                    "data-response": "ok",
                  },
                },
                [
                  _vm._v(
                    "\r\n                    " +
                      _vm._s(_vm.details.confirmText) +
                      "\r\n                "
                  ),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }