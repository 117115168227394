var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "tag-config",
    attrs: {
      "header-class": "border-bottom-0 justify-content-center  pb-0",
      "footer-class": "border-top-0 pt-0",
      "content-class": "modal-bg",
      "ok-only": "",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function () {
          return [
            _c("div", { staticClass: "font-large" }, [
              _vm._v(" Tag Summary Configuration "),
            ]),
          ]
        },
        proxy: true,
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "b-card",
              {
                staticClass: "inner-bg round-top round-bottom border-bottom-0",
              },
              [
                _c("div", [
                  _c("label", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.showDefaultColumn,
                          expression: "showDefaultColumn",
                        },
                      ],
                      attrs: { type: "checkbox", name: "showDefaultName" },
                      domProps: {
                        checked: Array.isArray(_vm.showDefaultColumn)
                          ? _vm._i(_vm.showDefaultColumn, null) > -1
                          : _vm.showDefaultColumn,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.showDefaultColumn,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.showDefaultColumn = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.showDefaultColumn = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.showDefaultColumn = $$c
                          }
                        },
                      },
                    }),
                    _vm._v(
                      ' \n                    Show "Default Tag Names"\n                '
                    ),
                  ]),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }