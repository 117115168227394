export const wellboreFlagData = [
    {
        symbol: 'F',
        shortText: 'FR',
        isActive: false,
        name: 'frac_1_rate_treatmentSlurry',
        text: 'Changes in FR Loading',
        color: '#1f96f3',
        depth: 0,
        ttp: ''
    },
    {
        symbol: 'G',
        shortText: 'Gel',
        isActive: false,
        name: 'frac_1_rate_treatmentSlurry',
        text: 'Changes in Gel Loading',
        color: '#9c26b0',
        depth: 0,
        ttp: ''
    },
    {
        symbol: 'S',
        shortText: 'Sand',
        isActive: false,
        name: 'frac_1_rate_treatmentSlurry',
        text: 'Sand Concentrations',
        color: '#b08826',
        depth: 0,
        ttp: ''
    },
    {
        symbol: 'D',
        shortText: 'DD',
        isActive: false,
        name: 'frac_1_rate_treatmentSlurry',
        text: 'Dropped Diverter',
        color: '#d51936',
        depth: 0,
        ttp: ''
    },
    {
        symbol: 'T',
        shortText: 'TF',
        isActive: false,
        name: 'frac_1_rate_treatmentSlurry',
        text: 'Test Flag',
        color: '#32cd32', //lime green
        depth: 0,
        ttp: ''
    },
    {
        symbol: 'A',
        shortText: 'APD',
        isActive: false,
        name: 'frac_1_rate_treatmentSlurry',
        text: 'Abnormal Pressure Drop',
        color: '#6a6c6e', //grey
        depth: 0,
        ttp: ''
    },
    {
        symbol: 'O',
        shortText: 'OWH',
        isActive: false,
        name: 'frac_1_rate_treatmentSlurry',
        text: 'Offset Well Frac Hit',
        color: '#fadadd', //light pink
        depth: 0,
        ttp: ''
    }
];
