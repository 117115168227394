var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.isReady()
        ? _c("div", { attrs: { id: "overlay" } }, [
            _vm.componentLoading
              ? _c("div", { staticClass: "errorText rounded" }, [
                  _vm._v("Loading..."),
                ])
              : _vm.toolstringError
              ? _c("div", { staticClass: "errorText rounded" }, [
                  _vm._v("No toolstring set."),
                ])
              : _vm.noWellInFracError
              ? _c("div", { staticClass: "errorText rounded" }, [
                  _vm._v("No well is currently in the frac state."),
                ])
              : _vm.simFracError
              ? _c("div", { staticClass: "errorText rounded" }, [
                  _vm._v(
                    "This component is not supported on jobs with simultaneous frac."
                  ),
                ])
              : _vm.noGunError
              ? _c(
                  "div",
                  { staticClass: "errorText rounded flex-column" },
                  [
                    _c("div", { staticClass: "pb-2" }, [
                      _vm._v("No shots are fired."),
                    ]),
                    _vm._v(" "),
                    _c(
                      "b-button",
                      {
                        attrs: {
                          type: "button",
                          size: "sm",
                          variant: "primary",
                        },
                        on: { click: _vm.onSetGunPress },
                      },
                      [_vm._v("Set missed shot")]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "resolve-shot-modal",
        _vm._b(
          {
            attrs: { isVisible: _vm.showShotResolveModal },
            on: {
              onDismiss: () => {
                _vm.showShotResolveModal = false
              },
              onSubmit: _vm.onShotResolved,
              onDeclineSubmit: () => {},
            },
          },
          "resolve-shot-modal",
          _vm.shotResolveModalProps,
          false
        )
      ),
      _vm._v(" "),
      _c("div", { staticClass: "badge badge-secondary d-block margin-auto" }, [
        _vm._v("Wellbore " + _vm._s(_vm.isNarrow() ? "" : "Diagram")),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex pt-2 pb-2" },
        [
          _c(
            "b-button",
            {
              attrs: {
                id: "add-flag-popover",
                size: "sm",
                disabled: !_vm.isReady(),
              },
            },
            [_vm._v("\n            Add Flag\n        ")]
          ),
          _vm._v(" "),
          _c(
            "b-popover",
            {
              attrs: {
                target: "add-flag-popover",
                triggers: "hover",
                placement: "bottom",
              },
            },
            _vm._l(_vm.allFlags, function (flag, index) {
              return _c(
                "div",
                {
                  key: flag.text,
                  staticClass: "mb-1",
                  on: {
                    click: function ($event) {
                      return _vm.placeFlag(flag)
                    },
                  },
                },
                [
                  _c("a", { attrs: { href: "javascript:void(0)" } }, [
                    _c("b", [_vm._v(_vm._s(flag.text))]),
                  ]),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.isFlagLoading
            ? _c("div", { staticClass: "pl-2" }, [
                _vm._v("\n            Loading placed flag...\n        "),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-row",
        { staticClass: "text-center", staticStyle: { "overflow-y": "auto" } },
        [
          _c(
            "b-col",
            {
              style: { maxWidth: _vm.col2maxwidth + "%" },
              on: {
                mouseenter: function ($event) {
                  _vm.isMouseEntered = true
                },
                mouseleave: function ($event) {
                  _vm.isMouseEntered = false
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass: "wellDiagram rounded",
                  style: _vm.wellDiagramStyle(),
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pl-1",
                      style: {
                        textAlign: "left",
                        fontSize: "11px",
                        color: "black",
                        opacity: _vm.isReady() ? 1 : 0.5,
                      },
                    },
                    [_vm._v("0 ft")]
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.flagsWithPixelDepth, function (flag, index) {
                    return _c(
                      "div",
                      {
                        key: _vm._uid + "flag-popover-target-" + index,
                        staticClass: "flagCircle",
                        style: _vm.getFlagStyle(flag),
                        attrs: {
                          id: _vm._uid + "flag-popover-target-" + index,
                        },
                        on: {
                          mouseenter: function ($event) {
                            _vm.isMouseEntered = true
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(flag.symbol) +
                            "\n                "
                        ),
                      ]
                    )
                  }),
                ],
                2
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "b-col",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.isNarrow(),
                  expression: "!isNarrow()",
                },
              ],
              attrs: { cols: "9" },
              on: {
                mouseenter: function ($event) {
                  _vm.isMouseEntered = true
                },
                mouseleave: function ($event) {
                  _vm.isMouseEntered = false
                },
              },
            },
            _vm._l(_vm.flagsWithPixelDepth, function (flag, index) {
              return _c(
                "div",
                {
                  key: "active_flag" + index,
                  staticClass: "rounded flagMarker px-1",
                  style: _vm.getMarkerStyle(flag),
                  attrs: { id: "marker_" + flag.symbol },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(_vm.getMarkerText(flag)) +
                      "\n            "
                  ),
                ]
              )
            }),
            0
          ),
          _vm._v(" "),
          _vm.flushVolume != null
            ? _c(
                "div",
                {
                  staticClass: "flushVolumeLabel",
                  style: { opacity: _vm.isReady() ? 1 : 0.5 },
                },
                [_vm._v("Wellbore Volume :")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.flushVolume != null
            ? _c(
                "div",
                {
                  staticClass: "flushVolumeValue",
                  style: { opacity: _vm.isReady() ? 1 : 0.5 },
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.flushVolume ? _vm.flushVolume.toFixed(0) : "N/A"
                    ) + " bbl"
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.maxDepth > 0
            ? _c(
                "div",
                {
                  staticClass: "maxDepthLabel",
                  style: { opacity: _vm.isReady() ? 1 : 0.5 },
                },
                [_vm._v("Top Perf MD :")]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.maxDepth > 0
            ? _c(
                "div",
                {
                  staticClass: "maxDepthValue",
                  style: { opacity: _vm.isReady() ? 1 : 0.5 },
                },
                [
                  _vm._v(
                    _vm._s(_vm.maxDepth ? _vm.maxDepth.toFixed(0) : 0) + " ft"
                  ),
                ]
              )
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.flagsWithPixelDepth, function (flag, index) {
        return _c(
          "b-popover",
          {
            key: _vm._uid + "flag-popover-target-" + index,
            attrs: {
              disabled: !_vm.isNarrow(),
              triggers: "hover",
              variant: "transparent",
              container: "transparent-popover-container",
              placement: "right",
              "no-fade": true,
              boundary: "window",
              target: _vm._uid + "flag-popover-target-" + index,
            },
          },
          [
            _c(
              "div",
              {
                style: {
                  color: "black",
                  backgroundColor: "#ffffff",
                  padding: "5px",
                  borderRadius: "8px",
                },
              },
              [_vm._v(_vm._s(_vm.getMarkerText(flag)))]
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }