<template>
    <b-modal 
        ref="my-modal" 
        size="xl"
        hide-header-close
        header-class="border-bottom-0 justify-content-center  p-0"
        footer-class="border-top-0 pt-0"
        content-class="modal-bg"
    >
        <template #modal-header>
            <div class="col w-100">
                <div class="modal-header">
                    <div class="font-large w-100 text-center">{{ modalTitle }}</div>
                </div>
                <div v-if="type==='shot'" class="pt-4 pb-2 row w-100">
                    <div class="col">
                        <div class="py-1">Well: {{wellName}}</div>
                        <div class="py-1" >Interval: {{stageNumber}}</div>
                        <div class="py-1" >Cluster number: {{ gunNumber }}</div>
                        <div class="py-1" >Cluster depth (ft): {{ wirelineDepth || '--' }}</div>
                    </div>
                    <!-- stage spacing  -->
                    <div v-if="gunNumber === 1" class="col"> 
                        <div class="py-1" >Spacing (ft): {{ actualSpacing || '--' }}</div>
                        <div class="py-1">Minimum alert (ft): {{ minSpaceAllowed || '--' }}</div>
                        <div class="py-1">Maximum alert (ft): {{ maxSpaceAllowed || '--' }}</div>
                        <div class="py-1">Previous stage top depth(ft): {{ lastShotDepth || '--' }}</div>
                        <div class="py-1">Variance from acceptable(ft): {{ calculateSpaceMismatch() || '--' }}</div>
                    </div>
                    <!-- cluster spacing -->
                    <div v-else-if="gunNumber > 1" class="col">
                        <div class="py-1" >Spacing (ft): {{ actualSpacing }}</div>
                        <div class="py-1">Minimum alert (ft): {{ minSpaceAllowed || '--' }}</div>
                        <div class="py-1">Maximum alert (ft): {{ maxSpaceAllowed || '--'}}</div>
                        <div class="py-1">Previous cluster top depth(ft): {{ lastShotDepth || '--' }}</div>
                        <div class="py-1">Variance from acceptable(ft): {{ calculateSpaceMismatch() || '--' }}</div>
                    </div>
                </div>
            </div>
        </template>
        <template #default>
            <label for="commentInput" class="primary-txt">Please enter the comment below </label>
            <textarea class="form-control div-border pre-formatted" id="textarea" rows="6" v-model="commentValue"></textarea>
        </template>
        <template #modal-footer>
            <div class="d-flex w-100  pl-3 justify-content-center align-items-center">
                <div v-if="isAlert" class="flex-shrink-1 justify-content-center">
                    <div class="row "
                        style="justify-content: center; align-items: center;">
                        <input
                            type="checkbox"
                            v-model="isShotSheetReferenced"
                        />
                        <div class="px-2">Shot sheet referenced</div>
                    </div>
                </div>
                <div class="flex-grow-1"></div>
                <button type="button" class="btn btn-secondary grey-button" @click="()=>changeModalVisibility(false)" >Cancel</button>
                <div class="px-2"></div>
                <button type="button" class="btn btn-success green-button" @click.prevent="onSavePressed" :disabled="isFetching">
                    <div>
                        {{ isAlert? 'Resolve': 'Save comment'}}
                    </div>
                </button>
            </div>
        </template>
    </b-modal>
</template>
<script>
export default {
    data() {
        return {
            data: null,
            isFetching: false,
            commentValue: '',
            minSpaceAllowed: null,
            maxSpaceAllowed: null,
            isShotSheetReferenced: false
        };
    },
    props: {
        jobNumber: String,
        isAlert: Boolean,
        lastShotDepth: [Number, String],
        value: String,
        gunNumber: Number,
        stageNumber: Number,
        clusterInfo: Array,
        actualSpacing: Number,
        shotSheetReferenced: Boolean,
        wirelineId: Number,
        wirelineDepth: Number,
        colIndex: Number,
        rowIndex: Number,
        wellIndex: String,
        wellName: String,
        isTableChanged: Boolean,
        type: String
    },
    watch: {
        value: function (newVal, oldVal) { 
            this.commentValue = this.value.replace(/\\n/g,'\n');
        },
        shotSheetReferenced: function (newVal, oldVal) { 
            this.isShotSheetReferenced = newVal;
        }
    },
    computed: {
        modalTitle() {
            if(this.isAlert) {
                if(this.gunNumber === 1) {
                    return 'Resolve Stage Spacing Alert';
                } else {
                    return 'Resolve Cluster Spacing Alert';
                }
            } else {
                return 'Add comment';
            }
        }
    },
    mounted() {
        this.commentValue = this.value.replace(/\\n/g,'\n');
    },
    methods: {
        calculateSpaceMismatch() {
            if(this.clusterInfo[0].cluster_info) {
                const clusterInfoObject = JSON.parse(this.clusterInfo[0].cluster_info);
                const { clusterSpacing, stageSpacing, clusterPositiveVariance, clusterNegativeVariance, stagePositiveVariance, stageNegativeVariance } = clusterInfoObject;
                const lowerBound = this.gunNumber===1? (stageSpacing+stageNegativeVariance) : (clusterSpacing + clusterNegativeVariance);
                const upperBound = this.gunNumber===1? (stageSpacing+ stagePositiveVariance) : (clusterSpacing + clusterPositiveVariance);
                this.minSpaceAllowed =  lowerBound;
                this.maxSpaceAllowed =  upperBound;

                if((this.actualSpacing > lowerBound && this.actualSpacing < upperBound) || this.actualSpacing == null) {
                    return 0;
                }
                const spaceGap = [lowerBound, upperBound];
                const actualSpacing = this.actualSpacing;
                const closestBound = spaceGap.reduce(function(prev, curr) {
                    return (Math.abs(curr - actualSpacing) < Math.abs(prev - actualSpacing) ? curr : prev);
                });

                if(closestBound === lowerBound) { //lower than lowerbound
                    return this.calculateDiff(closestBound, actualSpacing).toFixed(2) * -1;
                } else {
                    return this.calculateDiff(closestBound, actualSpacing).toFixed(2);
                }
            } else {
                return this.gunNumber===1? 'Missing stage spacing data' : 'Missing cluster spacing data';
            }
        },
        calculateDiff (num1, num2) {
            if (num1 > num2) {
                return Math.abs(num1 - num2);
            } else {
                return Math.abs(num2 - num1);
            }
        },
        updateCommentValue(value) {
            this.commentValue =  value.replace(/\\n/g,'\n');
        },							
        changeModalVisibility(status) {
            const self = this;
            self.isModalVisible = status;
            self.isModalVisible ? this.$refs['my-modal'].show(): this.$refs['my-modal'].hide();
        },
        onSavePressed() {
            const data = {
                _token: $('meta[name="csrf-token"]').attr('content'),
                wellNumber: this.wellIndex,
                wirelineHistoryId: this.wirelineId,
                isShotSheetReferenced: this.isShotSheetReferenced,
                jobNumber: this.jobNumber,
                stageNumber: this.stageNumber,
                description: this.commentValue,
                gunNumber: this.type === 'plug'? null : this.gunNumber,
                type: this.type
            };

            if(this.isAlert) { //update the resolved status if it's an alert
                data['isResolved'] = true;
            }

            this.$emit('onSavePressed',this.rowIndex, this.colIndex, this.commentValue, this.type, this.isAlert, this.gunNumber, this.stageNumber, this.isShotSheetReferenced);

            const url = '/wireline-report-comments';
            $.post(
                url,
                data,
                function (result) {},
                'json'
            );

            this.changeModalVisibility(false);
        }
            
    }
};
</script>