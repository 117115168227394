<template>
    <iws-modal
        title="Device Keychain" 

        :showModal="showBaseModal"
        max-width="650px"

        @close="closeAction"
        @secondary-action="closeAction"
        :primary-button-click="confirmAction"
    >
        <template #content>
            <div v-if="fieldUserAccounts === null" id="loading-container" class="full-width">
                <b-spinner large class="align-middle"></b-spinner>
            </div>
            <h5 v-else-if="!fieldUserAccounts?.length" class="full-width">
                No accounts
            </h5>
            <iws-table v-else
                :columns="columns"
                :items="fieldUserAccounts"
                sort-by-col="username"
            >      
                <template #cell_password="{ data }">
                    <iws-input :value.sync="data.item.password" @input="data.item.isDirty = true;" type="password" />
                </template>

                <template #cell_actions="{ data }">
                    <iws-button 
                        type="outline-light"
                        icon="far fa-copy" 
                        data-toggle="tooltip" title="Copy to Clipboard" 
                        class="mx-2" 
                        :click="() => copyPassword(data?.item?.password)"
                    />

                    <iws-button 
                        type="outline-light"
                        icon="fas fa-random" 
                        data-toggle="tooltip" title="Generate Random Password"
                        :click="() => onClickKeychainGenerate(data?.item)"
                    />
                </template>
            </iws-table>
        </template>
    </iws-modal>
</template>

<script>
import GlobalFunctions from '../../GlobalFunctions.js';
const { toast } = GlobalFunctions;

export default {
    data: () => ({
        showBaseModal: false,
        resolve: null,

        device: null,
        fieldUserAccounts: null,

        columns: [ 
            {
                key: "username",
                label: "Username",
            }, {
                key: "password",
                label: "Password",
                sortable: false
            }, {
                label: null,
                key: 'actions',
                showHeader: false,
                sortable: false
            }
        ]
    }),
    
    methods: {
        closeAction() {
            return this.resolve();
        },
        confirmAction() {
            // Only need to post the accounts when a change has been made
            if (!!this.fieldUserAccounts.find(_account => _account.isDirty))
                return axios.post(`/cameras/devices/${this.device.deviceID}/userAccounts`, this.fieldUserAccounts).then(this.resolve);
            this.resolve();
        },

        fetchAccounts() {
            return axios.get(`/cameras/devices/${this.device.deviceID}/userAccounts`).then(_result => {
                this.fieldUserAccounts = _result.data || [];
            }).catch(_error => {
                return toast({
                    title: 'Failed to fetch account details',
                    body: _error.message,
                    variant: 'error'
                });
            });
        },

        async open(device) {
            this.device = device;
            this.fetchAccounts();

            return new Promise((resolve, reject) => {
                this.resolve = () => {
                    this.showBaseModal = false;
                    this.fieldUserAccounts = null;
                    this.device = null;
                    resolve();
                };

                this.showBaseModal = true;
            });
        },

        copyPassword(password) {
            return navigator.clipboard.writeText(password).then(() => {
                toast({
                    title: 'Password copied to clipboard',
                    variant: 'success'
                });
            });
        },
        onClickKeychainGenerate(account) {
            // From: https://stackoverflow.com/questions/9719570/generate-random-password-string-with-requirements-in-javascript
            let numChars = "0123456789";
            let capChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
            let lowChars = "abcdefghijklmnopqrstuvwxyz";
            let specialChars = "!@#$%^&*()_+-=[]{}|;:,.<>?";
            let pwdChars = numChars + capChars + lowChars + specialChars;
            var generate = function (pwdChars, pwdLen) {
                return new Array(pwdLen).fill(0).map(x => (function(chars) { let umax = Math.pow(2, 32), r = new Uint32Array(1), max = umax - (umax % chars.length); do { crypto.getRandomValues(r); } while(r[0] > max); return chars[r[0] % chars.length]; })(pwdChars)).join('');
            }
            // Generate a password with 12 random characters and 4 from each set of required characters to pass validation
            let password = generate(pwdChars, 12);
            password += generate(numChars, 1);
            password += generate(capChars, 1);
            password += generate(lowChars, 1);
            password += generate(specialChars, 1);

            // const index = this.fieldUserAccounts.findIndex(_account => _account.id == account.id);
            account.password = password;
            account.isDirty = true;
        }
    }
};
</script>

<style scoped>
    #loading-container {
        text-align: center;
        padding: 35px 0px
    }
</style>