<!-- 
  Instructions for use:
  1. Add keyboard component to parent vue component. Use bootstrap classes to size the keyboard (col width, fixed-bottom...)
    example: <keyboard-component v-on:key="keyPress" v-if="showKeyboard" class="fixed-bottom" style="z-index:1040;"></keyboard-component>
  2. Add the following properties to the parent vue component data:
    showKeyboard:false, //boolean to show/hide keyboard
    activeInput:'', //string containing the id of the active input element
  3. Add the following methods to the parent vue component:
    // handle key press events
    keyPress(val){
        var self = this;
        switch(val){
          case 'Back':
            self[self.activeInput] = self[self.activeInput].substring(0,self[self.activeInput].length-1);
            break;
          case 'Enter':
            this.showKeyboard = false;
            var event = new Event('change');
            document.getElementById(self.activeInput).dispatchEvent(event);
            break;
          default:
            self[self.activeInput] = self[self.activeInput]+val;
            break;
        }  
      },
      // handle click of input element
      input(event){
        this.activeInput = event.currentTarget.id;
        this.showKeyboard = true;
      }
  4. Give each input field a unique id (id="somethingUnique")
  5. Bind each input field (using v-model) to a property with a key matching the input id (example: somethingUnique)
  6. Add the click handler @click="input($event)" to each input element
  Example: <input type="text" id="somethingUnique" v-model="somethingUnique" @click="input($event)">
-->

<template>
  <div ref="keyboardRef" class="bg-light">
    <div class="row">
      <key-component primary="`" secondary="~" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="1" secondary="!" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="2" secondary="@" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="3" secondary="#" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="4" secondary="$" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="5" secondary="%" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="6" secondary="^" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="7" secondary="&" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="8" secondary="*" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="9" secondary="(" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="0" secondary=")" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="-" secondary="_" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="=" secondary="+" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
    </div>
    <div class="row">
      <key-component primary="q" secondary="Q" :shift="shift" :width="keyWidth" v-on:key="keyPress" ml="0.33"></key-component>
      <key-component primary="w" secondary="W" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="e" secondary="E" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="r" secondary="R" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="t" secondary="T" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="y" secondary="Y" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="u" secondary="U" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="i" secondary="I" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="o" secondary="O" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="p" secondary="P" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="[" secondary="{" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="]" secondary="}" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="\" secondary="|" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
    </div>
    <div class="row">
      <key-component primary="a" secondary="A" :shift="shift" :width="keyWidth" v-on:key="keyPress" ml="0.66"></key-component>
      <key-component primary="s" secondary="S" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="d" secondary="D" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="f" secondary="F" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="g" secondary="G" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="h" secondary="H" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="j" secondary="J" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="k" secondary="K" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="l" secondary="L" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary=";" secondary=":" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="'" secondary='"' :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="Enter" :width="keyWidth" v-on:key="keyPress" ml="0.5"></key-component>
    </div>
    <div class="row">
      <key-component primary="Shift" secondary="Shift" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="z" secondary="Z" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="x" secondary="X" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="c" secondary="C" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="v" secondary="V" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="b" secondary="B" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="n" secondary="N" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="m" secondary="M" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="," secondary="<" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="." secondary=">" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="/" secondary="?" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="Shift" secondary="Shift" :shift="shift" :width="keyWidth" v-on:key="keyPress"></key-component>
      <key-component primary="Back" :width="keyWidth" v-on:key="keyPress"></key-component>
    </div>
    <div class="row">
      <key-component primary="Space" :width="keyWidth*10" v-on:key="keyPress" ml="0.12"></key-component>
    </div>
  </div>
</template>

<script>
  export default {
    data(){
      return {
        shift:false,
        viewWidth:'',
        keyWidth:'',
        keyboardWidth:''
      }
    },
    mounted(){
      this.keyboardWidth = this.$refs.keyboardRef.clientWidth;
      this.keyWidth = Math.floor(this.keyboardWidth / 13.5) - 3;
    },
    methods:{
      keyPress(val){
        this.keyboardWidth = this.$refs.keyboardRef.clientWidth;
        switch(val){
          case 'Shift':
            this.shift = !this.shift;
            break;
          case 'Back':
            this.$emit('key','Back');
            break;
          case 'Space':
            this.shift = false;
            this.$emit('key'," ");
            break;
          case 'Enter':
            this.$emit('key','Enter');
            break;
          default:
            this.shift = false;
            this.$emit('key',val);
            break;
        };
        
      }
    }
  }
</script>