var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm.showHeader
      ? _c("div", { staticClass: "row" }, [
          _c(
            "div",
            {
              staticClass:
                "col-12 text-center text-white rounded mb-1 bg-secondary",
            },
            [_vm._v("\n            " + _vm._s(_vm.header) + "\n        ")]
          ),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "row" },
      _vm._l(_vm.descriptions, function (descr, index) {
        return _c(
          "div",
          {
            staticClass: "text-center border border-secondary rounded mb-1",
            class: {
              "col-4": _vm.numberOfCols == 3,
              "col-6": _vm.numberOfCols == 2,
              "col-12": _vm.numberOfCols == 1,
            },
          },
          [
            _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12-sm col-6" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(descr) +
                    "\n                "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "col bg-light text-dark m-1" }, [
                _vm._v(
                  "\n                    " +
                    _vm._s(_vm.values[index]) +
                    "\n                "
                ),
              ]),
            ]),
          ]
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }