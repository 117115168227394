var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal vertical-align-center",
      staticStyle: { "z-index": "1200" },
      attrs: { tabindex: "-1", role: "dialog", id: "critical-alert-modal" },
    },
    [
      _c(
        "div",
        { staticClass: "modal-dialog modal-xl", attrs: { role: "document" } },
        [
          _c(
            "div",
            {
              staticClass: "modal-content main-container div-border px-2",
              style: _vm.containerStyle,
            },
            [
              _c("div", { staticClass: "modal-body py-0" }, [
                _c(
                  "div",
                  {
                    staticClass: "text-center mt-3 mb-3",
                    style: _vm.titleStyle,
                  },
                  [
                    _c("h3", [
                      _vm._v(
                        _vm._s(
                          _vm.alerts.length == 1
                            ? "CRITICAL ALERT"
                            : "CRITICAL ALERTS " +
                                (_vm.index + 1) +
                                " of " +
                                _vm.alerts.length
                        )
                      ),
                    ]),
                  ]
                ),
                _vm._v(" "),
                _c("hr", { style: _vm.hrStyle }),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _vm.alerts.length > 1
                    ? _c("div", { staticClass: "col-1" }, [
                        _c("i", {
                          staticClass: "fas fa-chevron-left",
                          style: _vm.chevronStyle,
                          on: {
                            click: function ($event) {
                              return _vm.modIndex(-1)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      class: {
                        "col-10": _vm.alerts.length > 1,
                        "col-12": _vm.alerts.length == 1,
                      },
                    },
                    [
                      _c(
                        "h1",
                        {
                          staticClass: "text-center",
                          staticStyle: { color: "black" },
                        },
                        [
                          _vm._v(
                            "\n                            FRAC HIT ALERT - PLEASE FLUSH AND GO TO NEXT INTERVAL\n                        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "h4",
                        {
                          staticClass: "text-center",
                          staticStyle: { color: "black" },
                        },
                        [
                          _vm._v(
                            "\n                            Tag Name : " +
                              _vm._s(
                                _vm.alerts.length > 0
                                  ? _vm.alerts[_vm.index].tagName
                                  : ""
                              ) +
                              "\n                        "
                          ),
                        ]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _vm.alerts.length > 1
                    ? _c("div", { staticClass: "col-1" }, [
                        _c("i", {
                          staticClass: "fas fa-chevron-right",
                          style: _vm.chevronStyle,
                          on: {
                            click: function ($event) {
                              return _vm.modIndex(1)
                            },
                          },
                        }),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("hr", { style: _vm.hrStyle }),
              ]),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "modal-footer d-flex flex-row",
                  staticStyle: { "border-top-width": "0px" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary grey-button flex-end",
                      staticStyle: { color: "black" },
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          return _vm.hideModal()
                        },
                      },
                    },
                    [_vm._v("\n                    Hide\n                ")]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }