var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.createUser
        ? [
            _c("iws-breadcrumbs", {
              staticClass: "mt-4 mb-1",
              attrs: {
                path: [
                  {
                    title: "Dashboard",
                    link: "/home",
                  },
                  {
                    title: "User Access Management",
                    link: "/userManagement",
                  },
                  {
                    title: "Create User",
                    link: "/register",
                  },
                ],
              },
            }),
            _vm._v(" "),
            _c("div", { staticClass: "page-title mb-4" }, [
              _vm._v("\n            Create User\n        "),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("iws-form", {
        ref: "form",
        attrs: { data: _vm.user },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function ({ dataItem, submit }) {
              return [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("iws-input", {
                        ref: "validateField",
                        attrs: {
                          label: "First Name",
                          placeholder: "Provide the users first name",
                          value: dataItem.first_name,
                          required: "",
                          "error-message": "Please provide a name",
                          readonly: !_vm.checkForIWSUser,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "first_name", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("iws-input", {
                        ref: "validateField1",
                        attrs: {
                          label: "Last Name",
                          placeholder: "Provide the users last name",
                          value: dataItem.last_name,
                          required: "",
                          "error-message": "Please provide a name",
                          readonly: !_vm.checkForIWSUser,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "last_name", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 mt-4" },
                    [
                      _c("iws-input", {
                        ref: "validateField2",
                        attrs: {
                          label: "Email",
                          placeholder:
                            "Provide a valid email for the user to sign in with",
                          value: dataItem.email,
                          type: "email",
                          validator: _vm.validateEmail,
                          "error-message":
                            "E-mail is invalid or already in use",
                          required: "",
                          readonly: !_vm.checkForIWSUser,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "email", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6 mt-4" },
                    [
                      _c("iws-input", {
                        ref: "validateField3",
                        attrs: {
                          label: "Phone",
                          placeholder:
                            "Provide the users mobile telephone (optional)",
                          value: dataItem.phone_number,
                          type: "tel",
                          readonly: !_vm.checkForIWSUser,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "phone_number", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.createUser
                    ? _c(
                        "div",
                        { staticClass: "col mt-4" },
                        [
                          _c("iws-input", {
                            ref: "validateField4",
                            attrs: {
                              label: "PIN",
                              placeholder: "Provide a 4 digit pin",
                              value: dataItem.pin,
                              type: "password",
                              validator: _vm.validatePIN,
                              "error-message":
                                dataItem.pin?.length < 4
                                  ? "Must be at least 4 characters"
                                  : "Pin is already in use",
                              readonly: _vm.readonly,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(dataItem, "pin", $event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("iws-select", {
                        ref: "validateField5",
                        staticClass: "mt-4",
                        attrs: {
                          label: "Company",
                          value: dataItem.customer_id,
                          options: _vm.companies,
                          "display-name": "name",
                          "value-name": "id",
                          required: "",
                          disabled: !_vm.checkForIWSUser,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "customer_id", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "col-6" },
                    [
                      _c("iws-select", {
                        ref: "validateField6",
                        staticClass: "mt-4",
                        attrs: {
                          label: "Role",
                          value: dataItem.roleId,
                          options: _vm.filteredRoles,
                          "display-name": "name",
                          "value-name": "id",
                          required: "",
                          disabled: !_vm.checkForIWSUser,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "roleId", $event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c("iws-select", {
                  ref: "validateField7",
                  staticClass: "mt-4",
                  attrs: {
                    label: "Permissions",
                    value: dataItem.permissions,
                    options: _vm.permissionOptions,
                    placeholder: !_vm._isNullOrEmpty(dataItem?.permissions)
                      ? `${dataItem?.permissions?.join(", ")}`
                      : "Search for permissions",
                    disabled: !_vm.checkForIWSUser,
                    multiselect: true,
                    "maintain-on-select": true,
                    "visible-options": 8,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.onPermissionSelect(dataItem, $event)
                    },
                    "select-all": function ($event) {
                      return _vm.onPermissionSelectAll(dataItem)
                    },
                  },
                }),
                _vm._v(" "),
                (_vm.createUser ||
                  dataItem.pin === null ||
                  dataItem.pin === "") &&
                _vm.checkForIWSUser
                  ? _c("iws-checkbox", {
                      ref: "validateField8",
                      staticClass: "mt-4",
                      attrs: {
                        label: "Login via Microsoft",
                        value: dataItem.single_sign_on,
                        disabled: !_vm.userIsAdmin(),
                        enableClickLabel: true,
                      },
                      on: {
                        "update:value": function ($event) {
                          return _vm.$set(dataItem, "single_sign_on", $event)
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.createUser
                  ? [
                      _c("iws-input", {
                        ref: "validateField9",
                        staticClass: "mt-4",
                        staticStyle: { display: "block" },
                        attrs: {
                          label: "Password",
                          value: dataItem.password,
                          type: "password",
                          required: "",
                          validator: () =>
                            dataItem.password?.length >= 10 &&
                            (_vm._isFalsy(dataItem.confirmPassword) ||
                              dataItem.password == dataItem.confirmPassword),
                          "error-message":
                            dataItem.password?.length < 10
                              ? "Must be at least 10 characters"
                              : "Password and Confirm Password must match",
                          readonly: _vm.readonly,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "password", $event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c("iws-input", {
                        ref: "validateField10",
                        staticClass: "mt-4",
                        staticStyle: { display: "block" },
                        attrs: {
                          label: "Confirm Password",
                          value: dataItem.confirmPassword,
                          type: "password",
                          required: "",
                          validator: () =>
                            dataItem.confirmPassword?.length >= 10 &&
                            dataItem.password == dataItem.confirmPassword,
                          "error-message":
                            dataItem.confirmPassword?.length < 10
                              ? "Must be at least 10 characters"
                              : "Password and Confirm Password must match",
                          readonly: _vm.readonly,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(dataItem, "confirmPassword", $event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _vm.passwordStrengthRequired
                        ? _c("password-strength-component", {
                            attrs: {
                              password: dataItem.password ?? "",
                              firstName: dataItem.first_name,
                              lastName: dataItem.last_name,
                              email: dataItem.email,
                            },
                            on: {
                              requirementsMet: (value) =>
                                (_vm.passRequirementsMet = value),
                            },
                          })
                        : _vm._e(),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.errorOcurred
                  ? _c("div", { staticClass: "text-danger" }, [
                      _vm._v(
                        "\n                Failed to create new user. Please check password requirements\n            "
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.checkForIWSUser
                  ? _c("iws-button", {
                      staticClass: "mt-4 mb-4",
                      staticStyle: { padding: ".375rem 1.5rem" },
                      attrs: {
                        text: "Submit",
                        type: "primary",
                        click: ($event) => {
                          $event.preventDefault()
                          submit(_vm.onSubmit, Object.values(_vm.$refs))
                        },
                        disabled:
                          _vm.passwordStrengthRequired &&
                          _vm.createUser &&
                          !_vm.passRequirementsMet,
                      },
                    })
                  : _vm._e(),
              ]
            },
          },
        ]),
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }