var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "checkbox-list-container", style: _vm.getContainerStyle() },
    [
      _c("div", { style: _vm.getListStyle() }, [
        _c("div", { staticClass: "d-flex align-items-center" }, [
          _vm.label.length > 0
            ? _c("b", [_vm._v(_vm._s(_vm.label))])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "pl-2" }),
        ]),
        _vm._v(" "),
        _vm.enableSearch
          ? _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.searchTerm,
                  expression: "searchTerm",
                },
              ],
              staticClass: "form-control form-control-sm my-2",
              attrs: { type: "text", placeholder: "Search..." },
              domProps: { value: _vm.searchTerm },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.searchTerm = $event.target.value
                },
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _vm.valueKey
          ? _c(
              "div",
              { staticClass: "main-container" },
              _vm._l(_vm.filteredOptions, function (packets, key, index) {
                return _c(
                  "div",
                  { key: key, staticClass: "accordion" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center user-select-none p-1 accordion-btn",
                      },
                      [
                        _vm.enableSelectAllOption &&
                        Object.keys(_vm.filteredOptions).length > 0
                          ? _c("input", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value: "Select all",
                                  expression: "'Select all'",
                                  arg: "top",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectAll[key],
                                  expression: "selectAll[key]",
                                },
                              ],
                              staticClass: "mr-2",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.selectAll[key])
                                  ? _vm._i(_vm.selectAll[key], null) > -1
                                  : _vm.selectAll[key],
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectAllSubOptions(key, true)
                                },
                                change: function ($event) {
                                  var $$a = _vm.selectAll[key],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.selectAll,
                                          key,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.selectAll,
                                          key,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.selectAll, key, $$c)
                                  }
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: "accordion-" + index,
                                expression: "'accordion-'+index",
                              },
                            ],
                            staticClass:
                              "d-flex justify-content-between align-items-center w-100",
                            attrs: { role: "tab" },
                          },
                          [
                            _c("p", { staticClass: "m-0 text-light" }, [
                              _vm._v(_vm._s(key)),
                            ]),
                            _vm._v(" "),
                            _vm._m(0, true),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "px-1 accordion-dropdown",
                        attrs: {
                          id: "accordion-" + index,
                          visible: "",
                          role: "tabpanel",
                        },
                      },
                      [
                        _c("div", { staticClass: "container" }, [
                          _vm.enableSlider
                            ? _c(
                                "div",
                                { staticClass: "row justify-content-center" },
                                [
                                  _vm.minMaxValues
                                    ? _c("vue-slider", {
                                        key: index,
                                        ref: key + "-slider",
                                        refInFor: true,
                                        staticClass: "col-10 my-2",
                                        attrs: {
                                          duration: 0,
                                          order: true,
                                          dotSize: 15,
                                          clickable: false,
                                          dragOnClick: false,
                                          min: _vm.minMaxValues[key]["min"],
                                          max: _vm.minMaxValues[key]["max"],
                                        },
                                        on: {
                                          "drag-end": function ($event) {
                                            return _vm.onSliderDragEnd(key)
                                          },
                                        },
                                        model: {
                                          value: _vm.minMaxValues[key]["value"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.minMaxValues[key],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "minMaxValues[key]['value']",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row" },
                            _vm._l(packets, function (option, subIndex) {
                              return _c(
                                "div",
                                {
                                  key: subIndex,
                                  staticClass: "col-xl-4 col-lg-6 col-sm-12",
                                },
                                [
                                  _c("label", { staticClass: "m-0 p-0" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model",
                                          value:
                                            _vm.optionStates[
                                              option[_vm.outputKey]
                                            ],
                                          expression:
                                            "optionStates[option[outputKey]]",
                                        },
                                      ],
                                      attrs: {
                                        type: "checkbox",
                                        id: option[_vm.outputKey],
                                      },
                                      domProps: {
                                        checked: Array.isArray(
                                          _vm.optionStates[
                                            option[_vm.outputKey]
                                          ]
                                        )
                                          ? _vm._i(
                                              _vm.optionStates[
                                                option[_vm.outputKey]
                                              ],
                                              null
                                            ) > -1
                                          : _vm.optionStates[
                                              option[_vm.outputKey]
                                            ],
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return _vm.toggleFocus()
                                        },
                                        blur: function ($event) {
                                          return _vm.toggleFocus()
                                        },
                                        change: [
                                          function ($event) {
                                            var $$a =
                                                _vm.optionStates[
                                                  option[_vm.outputKey]
                                                ],
                                              $$el = $event.target,
                                              $$c = $$el.checked ? true : false
                                            if (Array.isArray($$a)) {
                                              var $$v = null,
                                                $$i = _vm._i($$a, $$v)
                                              if ($$el.checked) {
                                                $$i < 0 &&
                                                  _vm.$set(
                                                    _vm.optionStates,
                                                    option[_vm.outputKey],
                                                    $$a.concat([$$v])
                                                  )
                                              } else {
                                                $$i > -1 &&
                                                  _vm.$set(
                                                    _vm.optionStates,
                                                    option[_vm.outputKey],
                                                    $$a
                                                      .slice(0, $$i)
                                                      .concat(
                                                        $$a.slice($$i + 1)
                                                      )
                                                  )
                                              }
                                            } else {
                                              _vm.$set(
                                                _vm.optionStates,
                                                option[_vm.outputKey],
                                                $$c
                                              )
                                            }
                                          },
                                          function ($event) {
                                            return _vm.toggleState(
                                              $event,
                                              option[_vm.outputKey]
                                            )
                                          },
                                        ],
                                      },
                                    }),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(option[_vm.valueKey]) +
                                        "\n                                "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            )
          : _c(
              "div",
              { staticClass: "main-container" },
              _vm._l(_vm.options, function (packets, key, index) {
                return _c(
                  "div",
                  { key: key, staticClass: "accordion" },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-center user-select-none p-1 accordion-btn",
                      },
                      [
                        _vm.enableSelectAllOption &&
                        Object.keys(_vm.options).length > 0
                          ? _c("input", {
                              directives: [
                                {
                                  name: "tooltip",
                                  rawName: "v-tooltip:top",
                                  value: "Select all",
                                  expression: "'Select all'",
                                  arg: "top",
                                },
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectAll[key],
                                  expression: "selectAll[key]",
                                },
                              ],
                              staticClass: "mr-2",
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.selectAll[key])
                                  ? _vm._i(_vm.selectAll[key], null) > -1
                                  : _vm.selectAll[key],
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.selectAllSubOptions(key)
                                },
                                change: function ($event) {
                                  var $$a = _vm.selectAll[key],
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        _vm.$set(
                                          _vm.selectAll,
                                          key,
                                          $$a.concat([$$v])
                                        )
                                    } else {
                                      $$i > -1 &&
                                        _vm.$set(
                                          _vm.selectAll,
                                          key,
                                          $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1))
                                        )
                                    }
                                  } else {
                                    _vm.$set(_vm.selectAll, key, $$c)
                                  }
                                },
                              },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle",
                                value: "accordion-" + index,
                                expression: "'accordion-'+index",
                              },
                            ],
                            staticClass:
                              "d-flex justify-content-between align-items-center w-100",
                            attrs: { role: "tab" },
                          },
                          [
                            _c("p", { staticClass: "m-0 text-light" }, [
                              _vm._v(_vm._s(key)),
                            ]),
                            _vm._v(" "),
                            _vm._m(1, true),
                          ]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "b-collapse",
                      {
                        staticClass: "px-1 accordion-dropdown",
                        attrs: {
                          id: "accordion-" + index,
                          visible: "",
                          role: "tabpanel",
                        },
                      },
                      [
                        _c("div", { staticClass: "container" }, [
                          _vm.enableSlider
                            ? _c(
                                "div",
                                { staticClass: "row justify-content-center" },
                                [
                                  _vm.minMaxValues
                                    ? _c("vue-slider", {
                                        key: index,
                                        ref: key + "-slider",
                                        refInFor: true,
                                        staticClass: "col-10 my-2",
                                        attrs: {
                                          duration: 0,
                                          order: true,
                                          dotSize: 15,
                                          clickable: false,
                                          dragOnClick: false,
                                          min: _vm.minMaxValues[key]["min"],
                                          max: _vm.minMaxValues[key]["max"],
                                        },
                                        on: {
                                          "drag-end": function ($event) {
                                            return _vm.onSliderDragEnd(key)
                                          },
                                        },
                                        model: {
                                          value: _vm.minMaxValues[key]["value"],
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.minMaxValues[key],
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression:
                                            "minMaxValues[key]['value']",
                                        },
                                      })
                                    : _vm._e(),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "row" },
                            _vm._l(packets, function (option, subIndex) {
                              return _c(
                                "div",
                                {
                                  key: subIndex,
                                  staticClass: "col-xl-4 col-lg-6 col-sm-12",
                                },
                                [
                                  _c("label", { staticClass: "m-0 p-0" }, [
                                    _vm.optionStates
                                      ? _c("input", {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value:
                                                _vm.optionStates[key][option],
                                              expression:
                                                "optionStates[key][option]",
                                            },
                                          ],
                                          attrs: {
                                            type: "checkbox",
                                            id: option,
                                          },
                                          domProps: {
                                            checked: Array.isArray(
                                              _vm.optionStates[key][option]
                                            )
                                              ? _vm._i(
                                                  _vm.optionStates[key][option],
                                                  null
                                                ) > -1
                                              : _vm.optionStates[key][option],
                                          },
                                          on: {
                                            focus: function ($event) {
                                              return _vm.toggleFocus()
                                            },
                                            blur: function ($event) {
                                              return _vm.toggleFocus()
                                            },
                                            change: [
                                              function ($event) {
                                                var $$a =
                                                    _vm.optionStates[key][
                                                      option
                                                    ],
                                                  $$el = $event.target,
                                                  $$c = $$el.checked
                                                    ? true
                                                    : false
                                                if (Array.isArray($$a)) {
                                                  var $$v = null,
                                                    $$i = _vm._i($$a, $$v)
                                                  if ($$el.checked) {
                                                    $$i < 0 &&
                                                      _vm.$set(
                                                        _vm.optionStates[key],
                                                        option,
                                                        $$a.concat([$$v])
                                                      )
                                                  } else {
                                                    $$i > -1 &&
                                                      _vm.$set(
                                                        _vm.optionStates[key],
                                                        option,
                                                        $$a
                                                          .slice(0, $$i)
                                                          .concat(
                                                            $$a.slice($$i + 1)
                                                          )
                                                      )
                                                  }
                                                } else {
                                                  _vm.$set(
                                                    _vm.optionStates[key],
                                                    option,
                                                    $$c
                                                  )
                                                }
                                              },
                                              function ($event) {
                                                return _vm.toggleState(
                                                  $event,
                                                  key
                                                )
                                              },
                                            ],
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n                                    " +
                                        _vm._s(
                                          _vm.alternateOptionLabels
                                            ? _vm.alternateOptionLabels[
                                                subIndex
                                              ]
                                            : option
                                        ) +
                                        "\n                                "
                                    ),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "h-100 d-flex align-items-center ml-2" }, [
      _c("img", { attrs: { src: "/images/dropdown.png" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "h-100 d-flex align-items-center ml-2" }, [
      _c("img", { attrs: { src: "/images/dropdown.png" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }