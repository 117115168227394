<template>
    <div class="d-flex" v-bind:style="{ 'height' : height? height+'px': 'auto' }" >
        <div v-for="well in orderedWells" :key="well.index" class="col p-0" :style="{'max-width' : getMaxWidth}"> 
            <well-small-component
                :well="well"
                :name="well.name" 
                :color="well.color" 
                :currentStage="well.currentStage"
                :numberOfStages="well.numberOfStages" 
                :activity="well.activity" 
                :pressureWell="casingPressures[well.index]"
                :status="well.status" 
                :key="well.index" 
                :index="well.index"
                :casingPressures="casingPressures" 
                :crownPressures="crownPressures" 
                :stageStart="well.start" 
                :duration="well.duration" @wireline-duration="getWirelineDuration"
                :jobEnd="jobEnd"
                :job="job"
                :is-multi-wireline="isMultiWireline"
                :wireline-systems="wirelineSystems"
                :is-multi-frac="isMultiFrac"
                :frac-systems="fracSystems"
                :showWellLocks="showWellLocks"
                style="width:100%; display: flex; justify-content: center;"
                @resize="checkDisplayHeight"
            >
            </well-small-component>
        </div>
        <lock-button
            activeTooltipText='Hide Valve Locks' 
            inactiveTooltipText='Show Valve Locks'
            localStorageKey='ShowWellLocks'
            :onValueChanged='(value)=>showWellLocks=value'/>
    </div>
</template>
<script>
export default {
    data() {
        return {
            showWellLocks: false,
            message: 'this is the well group',
            wirelineDuration: '',
            wireline: {
                depth: 0,
                speed: 0,
                tension: 0,
                spool: {diameter: 0,toolState: 'No spool connected'}
            },
            pressures: {
                zipper: 0,
                pumpdown: 0
            },
            orderedWells: this.wells ? this.wells.sort(function(a, b) { return a.index - b.index; }) : [],
            displayInfoMaxHeight: 0,
            displayInfoWellHeights: null
        };
    },
    props: ['job', 'wells', 'casingPressures', 'jobEnd', 'height', 'isMultiWireline', 'wirelineSystems', 'isMultiFrac', 'fracSystems', 'crownPressures'],
    methods: {
        getWirelineDuration: function(val) {
            this.wirelineDuration = val;
        },
        checkDisplayHeight: function(displayHeight, wellIndex, idName) {
            // sets all well display boxes to the same height
            if (this.displayInfoWellHeights.length > wellIndex) {
                this.displayInfoWellHeights[wellIndex] = displayHeight;
            } else {
                this.displayInfoWellHeights.push(displayHeight);
            }

            // once all well display box heights are received, set all heights to the max height
            if (this.displayInfoWellHeights.indexOf(0) === -1) {
                this.displayInfoMaxHeight = Math.max(...this.displayInfoWellHeights);
                
                // get all indexes which has value smaller than maxHeight
                let indexes = this.displayInfoWellHeights.reduce((indexArray, currentDisplayBoxHeight, index) => {
                    if (currentDisplayBoxHeight < this.displayInfoMaxHeight) indexArray.push(index);
                    return indexArray;
                },[]);

                // set all elements with index smaller than maxHeight to maxHeight
                let wellDisplayElement;
                indexes.forEach(index => {
                    wellDisplayElement = document.getElementById(idName + index);
                    wellDisplayElement.style.height = this.displayInfoMaxHeight + 'px';
                });
                
                this.setWellNameFontSize(idName);
            }
        },
        setWellNameFontSize: function(idName) {
            // adjust well name font size to fit in well display box
            let wellCount = this.orderedWells.length;
            for (let wellIndex = 0; wellIndex < wellCount; wellIndex++) {
                let wellDisplayElement = document.getElementById(idName + wellIndex);
                let wellNameTags = wellDisplayElement ? wellDisplayElement.getElementsByTagName('h5') : null;
                let wellNameElement = wellNameTags?.length > 0 ? wellNameTags[0] : null;
                let wellNameFontSize = wellNameElement?.style.fontSize ? parseFloat(wellNameElement.style.fontSize) : 1.25; // tag is h5 which has a default font size of 1.25rem
                let wellNameClientWidth = wellNameElement ? wellNameElement.clientWidth : 0;
                let wellNameScrollWidth = wellNameElement ? wellNameElement.scrollWidth : 0;
                let smallestFontSize = 0.6;
                
                while (wellNameScrollWidth > wellNameClientWidth && wellNameFontSize > smallestFontSize) {
                    // decreases well name font size if overflows
                    wellNameFontSize = wellNameFontSize - 0.01;
                    wellNameElement.style.fontSize = wellNameFontSize + 'em';
                    wellNameScrollWidth = wellNameElement.scrollWidth;
                    wellNameClientWidth = wellNameElement.clientWidth;
                }
            }
        }
    },
    computed: {
        getMaxWidth: function() {
            // set max width of the well info box
            return this.wells.length > 0 ? 100 / this.wells.length + '%' : '100%';
        },
    },
    mounted() {
        this.displayInfoWellHeights = new Array(this.orderedWells.length).fill(0); // initialize array with 0's
    }
};
</script>
