<template>
		<div class="row">
			<button type="button" class="btn btn-outline-dark btn-sm primary-border-color" :disabled="isDisabledRemove" @click.prevent="()=>onAddRemovePressed(false)"><b class="sub-txt">&#x2212;</b></button>
			<div class="px-1"></div>
			<button type="button" class="btn btn-outline-dark btn-sm primary-border-color" @click="()=>onAddRemovePressed(true)"><b class="sub-txt">&#x2b;</b></button>
    </div>
</template>

<script>
export default {
	props : ['minValue', 'currentValue', 'onChange'],
	data() {
		return {
			isDisabledRemove : this.currentValue===this.minValue? true : false
		}
	},
	methods: {
		getDisableStatus(currentValue){
			this.isDisabledRemove = this.minValue<currentValue? false : true;
		},
		onAddRemovePressed(isAddPressed){
			let afterPressValue = this.currentValue;
			if(isAddPressed){
				afterPressValue++;
			} else {
				afterPressValue--;
			}
			this.getDisableStatus(afterPressValue)
			this.onChange && this.onChange(afterPressValue)
		}
	},
}
</script>

<style>

</style>