<template>
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_26_10882)">
    <path d="M28 19H14.83L17.41 16.41L16 15L11 20L16 25L17.41 23.59L14.83 21H28V19Z" :fill="color"/>
    <path d="M24 14V10C24.0008 9.86841 23.9755 9.73795 23.9258 9.61611C23.876 9.49427 23.8027 9.38346 23.71 9.29002L16.71 2.29002C16.6166 2.19734 16.5057 2.12401 16.3839 2.07425C16.2621 2.02448 16.1316 1.99926 16 2.00002H6C5.46957 2.00002 4.96086 2.21073 4.58579 2.5858C4.21071 2.96088 4 3.46958 4 4.00002V28C4 28.5305 4.21071 29.0392 4.58579 29.4142C4.96086 29.7893 5.46957 30 6 30H22C22.5304 30 23.0391 29.7893 23.4142 29.4142C23.7893 29.0392 24 28.5305 24 28V26H22V28H6V4.00002H14V10C14 10.5305 14.2107 11.0392 14.5858 11.4142C14.9609 11.7893 15.4696 12 16 12H22V14H24ZM16 10V4.41002L21.59 10H16Z" :fill="color"/>
    </g>
    <defs>
    <clipPath id="clip0_26_10882">
    <rect width="32" height="32" :fill="color"/>
    </clipPath>
    </defs>
  </svg>
</template>

<script>

export default {
  computed: {
    color() {
      return this.$root.darkMode? 'white': 'black'
    }
  },
}
</script>
