import { HorizontalBar, mixins } from 'vue-chartjs';
const { reactiveProp } = mixins;
import zoom from 'chartjs-plugin-zoom';
export default {
    extends: HorizontalBar,
    mixins: [reactiveProp],
    props: ['chartData','options',''],
    mounted () {
        this.addPlugin(zoom);
        if(this?.options?.customLabels) {
            this.addPlugin(this.options.customLabels);
        }
        this.renderChart(this.chartData, this.options);
    }
};